import { Callback } from "./dispatcher";

export interface IVector2Like {
    x: number;
    y: number;
}
export interface IVector3Like {
    x: number;
    y: number;
    z: number;
}
export interface IVector4Like {
    x: number;
    y: number;
    z: number;
    w: number;
}

declare global {
    module Laya {
        interface Vector2 {
            normalize(): void;
            clone(): Vector2;
            cloneTo(value: IVector2Like): IVector2Like;
            cloneFrom(value: IVector2Like): void;
            set(x: number, y: number): void;
            vadd<T extends IVector2Like>(b: T, out: T): T;
            vsub<T extends IVector2Like>(b: T, out: T): T;

            get length(): number;
        }

        module Vector2 {
            export function transformCoordinate(
                coordinate: Vector2,
                transform: Matrix3x3,
                result: Vector2
            ): void;
            export function distance(p1: IVector2Like, p2: IVector2Like): number;
        }

        interface Sprite3D {
            clone(): Sprite3D;
        }

        interface UIComponent {
            onClick(callback: Callback): void;
        }

        interface Vector3 {
            clone(): Vector3;
            cloneTo(value: IVector3Like): IVector3Like;
            cloneFrom(value: IVector3Like): void;
            distance(p: IVector3Like): number;
            distanceXZ(p: IVector3Like): number;
        }

        module Vector3 {
            export function distanceXZ(v1: IVector3Like, v2: IVector3Like): number;
        }

        interface Node {
            getChildByPath(path: string): Node | null;
            getComponent<T extends Component>(componentType: new () => T): T | null;
        }

        module Render {
            export function getTimestamp(): number;
        }
    }
}

Laya.Render.getTimestamp = function () {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    return ((Laya.Render as any).lastFrm * 1000) / Laya.Config.FPS;
};

Laya.Vector2.prototype.normalize = function () {
    Laya.Vector2.normalize(this, this);
};

Laya.Vector2.prototype.cloneFrom = function (value) {
    this.x = value.x;
    this.y = value.y;
};

Laya.Vector2.prototype.set = Laya.Vector2.prototype.setValue;

Laya.Vector2.prototype.vadd = function <T extends IVector2Like>(b: T, out: T) {
    out.x = this.x + b.x;
    out.y = this.y + b.y;
    return out;
};

Laya.Vector2.prototype.vsub = function <T extends IVector2Like>(b: T, out: T) {
    out.x = this.x - b.x;
    out.y = this.y - b.y;
    return out;
};

Object.defineProperty(Laya.Vector2.prototype, "length", {
    get() {
        const { x, y } = this as Laya.Vector2;
        return Math.sqrt(x * x + y * y);
    },
    configurable: true,
});

Laya.Vector2.transformCoordinate = function (
    coordinate: Laya.Vector2,
    transform: Laya.Matrix3x3,
    result: Laya.Vector2
) {
    const { x, y } = coordinate;
    const transformElem = transform.elements;
    result.x = x * transformElem[0] + y * transformElem[3] + transformElem[6];
    result.y = x * transformElem[1] + y * transformElem[4] + transformElem[7];
};

Laya.Vector2.distance = function (p1, p2) {
    return Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);
};

Laya.UIComponent.prototype.onClick = function (callback) {
    this.on(Laya.Event.CLICK, callback);
};

Laya.Vector3.prototype.cloneFrom = function (value) {
    this.x = value.x;
    this.y = value.y;
    this.z = value.z;
};

Laya.Vector3.prototype.distance = function (p) {
    return Math.sqrt((this.x - p.x) ** 2 + (this.y - p.y) ** 2 + (this.z - p.z) ** 2);
};

Laya.Vector3.distanceXZ = function (v1, v2) {
    return Math.sqrt((v1.x - v2.x) ** 2 + (v1.z - v2.z) ** 2);
};

Laya.Vector3.prototype.distanceXZ = function (p) {
    return Laya.Vector3.distanceXZ(this, p);
};

Laya.Node.prototype.getChildByPath = function (path) {
    const names = path.match(/[^/]+/g);
    if (names) {
        let child: Laya.Node | null = this as Laya.Node;
        for (const n of names) {
            child = child.getChildByName(n);
            if (!child) {
                break;
            }
        }
        return child;
    } else {
        return null;
    }
};
