import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { SystemEvent } from "../../misc/system-event";
import { CharacterSelectTab } from "../hero/ui-runtime/character/CharacterSelectTab";
import { BagItem } from "./ui-runtime/BagItem";
import { UIBag } from "./ui-runtime/UIBag";
import { ItemVo } from "./vo/ItemVo";

const { regClass, property } = Laya;

@regClass('0aEWOMMbTrOXbH0YidJYlQ')
export class UIBagMediator extends Mediator {
    declare owner: UIBag;
    itemListData!: ItemVo[];

    override onAwake(): void {
        super.onAwake();
        this.initUIEvent();
        this.initServiceEvent();
        this.updateList();
    }

    //初始化UI事件监听
    initUIEvent() {
        this.owner.imgMask.on(Laya.Event.CLICK, this.owner, this.owner.destroy);
        this.owner.listItem.renderHandler = new Laya.Handler(this, this.updateItem);
        this.owner.listItem.mouseHandler = new Laya.Handler(this, this.onListClick);
        this.owner.listSwitch.renderHandler = new Laya.Handler(this, this.onSwitchRender);
        this.owner.listSwitch.selectHandler = new Laya.Handler(this, this.onSwitchSelect);
        this.owner.listSwitch.array = ["道具", "碎片"];
        this.owner.bgwin.setTitle("背包");
    }

    //初始化Service事件监听
    initServiceEvent() {
        this.$(app).on(SystemEvent.UI.ITEM_SVR_UPDATE, () => {
            this.updateList();
        });
    }

    private onSwitchRender(item: CharacterSelectTab, index: number) {
        const data = item.dataSource;
        item.lblList.text = data;
    }

    //列表点击回调
    onListClick(evn: Laya.Event, index: number) {
        //TODO
        // if (evn.type === Laya.Event.CLICK) {
        // }
    }

    //标签点击回调
    onSwitchSelect(index: number) {
        this.updateList();
    }

    //listItem更新回调
    updateItem(cell: BagItem, index: number) {
        const cellData = this.itemListData[index] as ItemVo;
        cell.updateGoods(cellData);
        cell.itemNumber = cellData.count.toString();
    }

    //刷新列表
    updateList() {
        const itemVoMap = app.service.bag.itemVoMap;
        this.itemListData = [];
        if (this.owner.listSwitch.selectedIndex === 0) {
            const itemList = itemVoMap.toArray().filter((value) => !value.composite);
            itemList.forEach((lo) => {
                this.itemListData.push(lo);
            });
        } else {
            const los = itemVoMap.toArray().filter((value) => value.composite);
            los.forEach((lo) => {
                this.itemListData.push(lo);
            });
        }
        this.itemListData.sort((a, b) => {
            if (a.id === b.id) {
                return b.quality - a.quality;
            } else {
                return b.id - a.id;
            }
        });
        this.owner.listItem.array = this.itemListData;
    }
}
