import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { AnimName, Animator } from "../../../base/Animator";
import { P1v1Context } from "../../P1v1Context";
import { P1v1AnimationComponent } from "../components/P1v1AnimationComponent";
import { P1v1MovementComponent } from "../components/P1v1MovementComponent";
import { P1v1TransformComponent } from "../components/P1v1TransformComponent";

export class P1v1RenderSystem extends ecs.System {
    declare context: P1v1Context;

    override onCreate(): void {
        this.registerHandler(
            P1v1AnimationComponent,
            this._onAddP1v1AnimationComponent,
            this._onDelP1v1AnimationComponent
        );
    }

    private async _onAddP1v1AnimationComponent(anim: P1v1AnimationComponent) {
        const checker = anim.checker;
        const prefab = await app.loader.loadPrefab(anim.res);
        if (!checker()) {
            return;
        }

        anim.view = prefab.create() as Laya.Sprite3D;
        anim.animator = anim.view.getComponent(Animator);
        if (anim.animator) {
            // TOOD: 配置缩放大小？
            const owner = anim.animator.owner as Laya.Sprite3D;
            owner.transform.localScaleX = 1.5;
            owner.transform.localScaleY = 1.5;
            owner.transform.localScaleZ = 1.5;
        }
        anim.mounter.addChild(anim.view);
        this.context.updateLayer(anim.view);

        const movement = anim.getComponent(P1v1MovementComponent)!;
        if (movement.speed > 0) {
            this.context.playAnim(anim.eid, AnimName.MOVE);
        }
    }

    private _onDelP1v1AnimationComponent(anim: P1v1AnimationComponent) {
        anim.view?.destroy();
        anim.view = null;
        anim.animator = null;
        anim.mounter.removeChildren();
        anim.mounter.destroy();
    }

    override update(dt: number) {
        this.ecs.getComponents(P1v1AnimationComponent).forEach((anim) => {
            this._updatePosition(anim);
        });
    }

    private _updatePosition(anim: P1v1AnimationComponent) {
        const transform = anim.getComponent(P1v1TransformComponent)!;
        const mounterTransform = anim.mounter.transform;

        if (transform.flags & P1v1TransformComponent.POSITION) {
            // 更新部队位置
            mounterTransform.position = transform.position;
            transform.flags &= ~P1v1TransformComponent.POSITION;
        }

        if (transform.flags & P1v1TransformComponent.ROTATION) {
            mounterTransform.localRotationEulerY = transform.rotation;
            transform.flags &= ~P1v1TransformComponent.ROTATION;
        }
    }
}
