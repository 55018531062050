import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { TroopState } from "../../../../../def/auto/world";
import { SystemEvent } from "../../../../../misc/system-event";
import { AnimName } from "../../../base/Animator";
import { PvpContext } from "../../PvpContext";
import { PvpMoveTargetVFXComponent } from "../components/PvpMoveTargetComponent";
import { PvpMovementComponent } from "../components/PvpMovementComponent";
import { PvpNaviArrowComponent } from "../components/PvpNaviArrowComponent";
import { PathArrowComponent } from "../components/PvpPathArrowComponent";
import { PvpStateComponent } from "../components/PvpStateComponent";
import { PvpTransformComponent } from "../components/PvpTransformComponent";

export class PvpMovementSystem extends ecs.System {
    declare context: PvpContext;

    override onCreate(): void {
        this.registerHandler(
            PvpMovementComponent,
            this._onAddPvpMovementComponent,
            this._onDelPvpMovementComponent
        );
    }

    private _onAddPvpMovementComponent(movement: PvpMovementComponent) {}

    private _onDelPvpMovementComponent(movement: PvpMovementComponent) {
        movement.removeComponent(PvpNaviArrowComponent);
        movement.removeComponent(PathArrowComponent);
        movement.removeComponent(PvpMoveTargetVFXComponent);
    }

    private _setup(movement: PvpMovementComponent) {
        const index = movement.index;
        const p0 = movement.paths[index];
        const p1 = movement.paths[index + 1];
        movement.target.cloneFrom(p1);
        const transform = movement.getComponent(PvpTransformComponent)!;
        transform.rotation = Math.toDegree(Math.atan2(p1.x - p0.x, p1.z - p0.z));
        transform.flag |= PvpTransformComponent.ROTATION;
    }

    override update(dt: number) {
        this.ecs.getComponents(PvpMovementComponent).forEach((movement) => {
            if (movement.speed <= 0) {
                return;
            }

            const transform = movement.getComponent(PvpTransformComponent)!;
            const position = transform.position;
            const target = movement.target;

            if (movement.flag & PvpMovementComponent.UPDATE) {
                movement.flag &= ~PvpMovementComponent.UPDATE;
                this._setup(movement);
            }

            const totalDis = Laya.Vector3.distance(position, target);
            const dtDis = movement.speed * dt;

            if (dtDis < totalDis) {
                Laya.Vector3.lerp(position, target, dtDis / totalDis, position);
            } else {
                position.cloneFrom(target);
                if (++movement.index < movement.paths.length - 1) {
                    this._setup(movement);
                } else {
                    movement.speed = 0;
                    const stateComp = movement.getComponent(PvpStateComponent);
                    if (
                        stateComp &&
                        stateComp.state !== TroopState.IDLE &&
                        stateComp.state !== TroopState.FIGHTING // 非IDLE状态，但又可以播放IDLE动画的状态
                    ) {
                        app.event(SystemEvent.PVP.UPDATE_TROOP_ANIM, [movement.eid, AnimName.IDLE]);
                    }
                    movement.removeComponent(PvpMovementComponent);
                }
            }
            transform.flag |= PvpTransformComponent.POSITION;
        });
    }
}
