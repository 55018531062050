const { regClass } = Laya;
import {
    InteractiveBubbleActionType,
    InteractiveBubbleViewType,
} from "../../../pve-server/PveDefs";
import { PveContext } from "../../../pve/PveContext";
import { PveUINpcOperationConfirmComponent } from "../../../pve/ecs/components/PveUINpcOperationConfirmComponent";
import { IInteractiveBubbleView } from "../IInteractiveBubbleView";
import { NpcOperationConfirmUIBase } from "./NpcOperationConfirmUI.generated";

@regClass('2VzqLp_5SVmuPMw47txQEg')
export class NpcOperationConfirmUI
    extends NpcOperationConfirmUIBase
    implements IInteractiveBubbleView
{
    comp: PveUINpcOperationConfirmComponent | undefined;

    context: PveContext | undefined;

    get viewType(): InteractiveBubbleViewType {
        return InteractiveBubbleViewType.NPC_INTERACTIVE_VIEW;
    }

    get actionTypes(): InteractiveBubbleActionType[] {
        return [InteractiveBubbleActionType.NPC_INTERACTIVE_ACTION_1];
    }

    override onAwake(): void {
        super.onAwake();
        this.button1.on(Laya.Event.CLICK, this, this.onBtn1Click);
    }

    async onBtn1Click() {
        if (!this.comp || !this.context) return;

        const plotID = this.comp.plotID;
        this.context.sender.interactiveBubbleAction(
            this.comp.eid,
            this.viewType,
            this.actionTypes[0],
            plotID
        );

        this.comp.removeComponent(PveUINpcOperationConfirmComponent);
    }
}
