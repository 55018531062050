import { ButtonBase } from "./ButtonBase";
import { LabelStyle } from "./LabelStyle";

const { regClass, property } = Laya;

@Laya.regClass('ML5YHZ6cRUakQdgqTSHtvw')
@Laya.classInfo({
    menu: "自定义",
    icon: "plugins/type-icons/node/Button.svg",
})
export class UIButton extends ButtonBase {
    @Laya.property({ type: LabelStyle, inspector: null, serializable: false })
    override get selectedLabelStyle(): LabelStyle {
        return super.selectedLabelStyle;
    }

    override set selectedLabelStyle(value: LabelStyle) {}

    @Laya.property({ inspector: null, serializable: false })
    declare toggle: boolean;

    @Laya.property({ inspector: null, serializable: false })
    override get selected(): boolean {
        return super.selected;
    }

    override set selected(value: boolean) {}
}
