import { TMElement } from "./tm-element";

export interface TMWorld {
    maps: TMMapInfo[];
}

export interface TMMapInfo {
    fileName: string;
    x: number;
    y: number;
    width: number;
    height: number;
    worldMap?: TMWorldMap;
}

export interface TMWorldMap {
    width: number;
    height: number;
    tilewidth: number;
    tileheight: number;
    layers: TMLayer[];
    tilesets: TMTileSet[];
}

export interface TMLayer {
    id: number;
    name: string;
    type: TMLayerType;
    width: number;
    height: number;
    data?: number[];
    objects?: { [k: string]: TMObj };
}

export interface TMObj {
    id: number;
    name: string;
    x: number;
    y: number;
    width: number;
    height: number;
    properties?: { [key: string]: unknown };
}

export enum TMLayerType {
    TileLayer = "tilelayer",
    ObjectGroup = "objectgroup",
    ImageLayer = "imagelayer",
    Group = "group",
}

export enum TMLayerName {
    None = "none",
    // 编辑器里的层
    Ground = "ground",
    Road = "road",
    River = "river",
    Block = "block",
    Static = "static",
    Marker = "marker",
    Cloud = "cloud",
    Building = "building",
    Monster = "monster",
    Npc = "npc",
    Event = "event",
    Custom = "custom",
    // 调试层
    Debug = "debug",
    // 自定义对象层
    Object = "object",
}

export const TMLayerNames = [
    TMLayerName.None,
    TMLayerName.Ground,
    TMLayerName.Road,
    TMLayerName.River,
    TMLayerName.Block,
    TMLayerName.Static,
    TMLayerName.Marker,
    TMLayerName.Cloud,
    TMLayerName.Building,
    TMLayerName.Monster,
    TMLayerName.Npc,
    TMLayerName.Event,
    TMLayerName.Custom,
    TMLayerName.Debug,
    TMLayerName.Object,
];

export const TMLayerIndex = TMLayerNames.reduce(
    (obj, value, index) => Object.assign(obj, { [value]: index }),
    {} as { [k in TMLayerName]: number }
);

export interface TMTileSet {
    name: string;
    firstgid: number;
    count: number;
    path: string;
    source: string;
}

export enum TMAtlasName {
    Ground = "ground",
    Road = "road",
    River = "river",
}

export enum TMTextureName {
    Static = "static",
    Object = "object",
    Cloud = "cloud",
}

export interface TMTextureCfg {
    tileX?: number;
    tileY?: number;
    tileW: number;
    tileH: number;
    offsetX: number;
    offsetY: number;
    offsetZ: number;
    scale: number;
}

export enum TMPropKey {
    // 外部变量
    TID = "id",
    // 外部变量
    ROTATION = "rot",
    // 内部变量
    Gid = "__gid",
    TextureKey = "__texture_key",
}

export enum TMMode {
    PVE = 1,
    PVP = 2,
}

export interface TMKey {
    readonly layer: TMLayerName;
    readonly x: number;
    readonly z: number;
}

export interface TMMapArea {
    readonly id: number;
    readonly x: number;
    readonly z: number;
    readonly width: number;
    readonly height: number;
}

export interface ITMContext {
    get scene3D(): Laya.Scene3D;
    get camera(): Laya.Camera;
    get cameraNode(): Laya.Sprite3D;
    get mapDir(): string;
    get mode(): TMMode;

    onAddElement(element: TMElement): void;
    onDelElement(element: TMElement): void;
}
