const { regClass } = Laya;
import { app } from "../../../../../app";
import { StringUtil } from "../../../../../core/utils/string-util";
import { Reward } from "../../../../../def/table";
import { AreaProgressBarRewardItemUIBase } from "./AreaProgressBarRewardItemUI.generated";

export type AreaProgressBarRewardItemData = {
    index: number;
    areaId: number;
    reward: Reward;
    achieve: boolean;
    persent: number;
    curPerc: number;
};

@regClass('IGVi1rewQOea3XOVRr_WQQ')
export class AreaProgressBarRewardItemUI extends AreaProgressBarRewardItemUIBase {
    public data?: AreaProgressBarRewardItemData;

    override onAwake(): void {
        super.onAwake();
        this.img_icon.onClick(this.onClickIcon.bind(this));
    }

    private onClickIcon(): void {
        if (!this.data) return;
        if (this.data.achieve) {
            if (!this.rewardItemDataIsDraw(this.data)) {
                const progressData = app.service.pve.mainLineProgressData;
                const areaConfig = app.service.table.battlePveMapTable.area[this.data.areaId];
                const canDrawIndex = progressData.getCanDrawIndex(areaConfig, this.data.curPerc);
                if (canDrawIndex >= 0) {
                    const hasDrawIndex = progressData.getHasDrawIndex(areaConfig);
                    if (canDrawIndex > hasDrawIndex) {
                        app.service.pve.requestAreaProgressDraw(this.data.areaId, canDrawIndex);
                    }
                }
            }
        }
    }

    /** 奖励是否被领取 */
    private rewardItemDataIsDraw(data: AreaProgressBarRewardItemData): boolean {
        const list = app.service.pve.mainLineProgressData.areaProgressList;
        for (let i = 0; i < list.length; i++) {
            const item = list[i];
            if (item.area === data.areaId) {
                const drawIndex = item.awardedIndex ?? -1;
                if (drawIndex >= data.index) {
                    return true;
                }
                break;
            }
        }
        return false;
    }

    public updateShow(): void {
        if (!this.data) return;
        this.persentText.text = `${this.data.persent}`;
        const itemLo = app.service.bag.itemLoMap.get(this.data.reward.id);
        let quality_url = "resources/atlas/map-transfer/img_tsd_panel_{0}.png";
        quality_url = StringUtil.format(quality_url, itemLo.quality);
        this.img_quality_bg.skin = quality_url;

        this.img_icon.skin = itemLo.iconUrl;
        if (this.data.achieve) {
            // 可领取或者已领取
            if (this.rewardItemDataIsDraw(this.data)) {
                // 已领取
                this.img_gou.visible = true;
                this.img_redpoint.visible = false;
            } else {
                // 可领取
                this.img_gou.visible = false;
                this.img_redpoint.visible = true;
            }
            this.img_on.visible = true;
            this.img_off.visible = false;
            this.img_mask.visible = false;
        } else {
            // 不可领取
            this.img_gou.visible = false;
            this.img_redpoint.visible = false;
            this.img_on.visible = false;
            this.img_off.visible = true;
            this.img_mask.visible = true;
        }
    }
}
