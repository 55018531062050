const { regClass } = Laya;
import { app } from "../../../app";
import { BattleSkillRow } from "../../../def/table";
import { ImageRef } from "../../../misc/ImageRef";
import { ui } from "../../../misc/ui";
import { SkillMapItemUIBase } from "./SkillMapItemUI.generated";

@regClass('QJ0i03OyQwexJZsuzyiXeg')
export class SkillMapItemUI extends SkillMapItemUIBase {
    public isShowFirst: boolean = true;
    public m_data!: BattleSkillRow;

    override onAwake(): void {
        super.onAwake();
        this.btnGet.on(Laya.Event.CLICK, this, this.onClickGet);
        this.labBtn.on(Laya.Event.CLICK, this, this.onClickLab);
    }

    private onClickLab(e: Laya.Event) {
        e.stopPropagation();
        this.isShowFirst = !this.isShowFirst;
        this.showDesc();
    }

    private onClickGet(e: Laya.Event) {
        e.stopPropagation();
        const data = this.m_data;
        const isCanAct = app.service.skill.checkSkillCanAct(data);
        if (isCanAct) {
            app.ui.show(ui.SKILL_ACTIVE, data);
        } else {
            //此时点击前往获得跳转
        }
    }

    override set_dataSource(value: BattleSkillRow): void {
        const skillData = value;
        this.m_data = value;
        this.labDesc.text = skillData.desc;
        this.labName.text = skillData.name;
        this.skill_icon.initIcon(value);
        this.isShowFirst = true;
        this.isShowDetails(false);
        const isOwn = app.service.skill.checkSkillOwn(value.id);
        this.boxBase.gray = this.boxGray.gray = !isOwn;
        const isActed = app.service.skill.checkIsActed(value.id);
        this.btnGet.visible = !isActed;
        this.labType.text = this.labType2.text = app.service.skill.getSkillTypeText(
            skillData.type_icon || 1
        );
        this.labType.color = this.labType2.color = app.service.skill.getTypeTextColor(
            skillData.type_icon || 1
        );
        const imgQualityList = this.imgQuality.getComponent(ImageRef)!.texturePaths;
        const quality = skillData.quality || 1;
        this.labName.color = app.service.skill.getQualityTextColor(quality);
        this.imgQuality.skin = imgQualityList[quality - 1];
        const texturepaths = this.imgType.getComponent(ImageRef)!.texturePaths;
        this.imgType.skin = this.imgType2.skin = texturepaths[(skillData.type_icon || 1) - 1];
        this.btnGet.label = app.service.skill.checkSkillCanAct(value) ? "激活" : "前往获得";
    }

    public showDesc() {
        const isFirst = this.isShowFirst;
        const data = this.m_data as BattleSkillRow;
        this.labDesc.text = isFirst
            ? app.service.skill.getFirstLevelDesc(data)
            : app.service.skill.getFullLevelDesc(data);
        this.labBtn.text = isFirst ? `满级效果` : "一级效果";
        this.lablevelDesc.text = isFirst ? "一级效果" : "满级效果";
    }

    isShowDetails(isShow: boolean) {
        this.boxBase.visible = !isShow;
        this.boxDetails.visible = isShow;
        this.height = isShow ? 260 : 149;
        this.isShowFirst = true;
        this.showDesc();
    }
}
