/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIList } from "../../../../core/ui/UIList";

/**
 * resources/prefab/character/character/UICharacter.lh
 */
export class UICharacterBase extends Laya.Dialog {
    public imgResIcon0!: Laya.Image;
    public lblResValue0!: Laya.Label;
    public boxFame!: Laya.Box;
    public lblFameDesc!: Laya.Label;
    public proFame!: Laya.ProgressBar;
    public lblFameProgress!: Laya.Label;
    public boxFameReward!: Laya.Box;
    public sprFameRedPoint!: Laya.Sprite;
    public boxLeader!: Laya.Box;
    public imgLeader!: Laya.Image;
    public lblLeaderJob!: Laya.Label;
    public lblLeaderName!: Laya.Label;
    public lblLeaderLv!: Laya.Label;
    public imgLeaderStar0!: Laya.Image;
    public imgLeaderStar1!: Laya.Image;
    public imgLeaderStar2!: Laya.Image;
    public imgLeaderStar3!: Laya.Image;
    public imgLeaderStar4!: Laya.Image;
    public listCharacter!: UIList;
    public imgHandbookTitle!: Laya.Image;
    public lblCollectProgress!: Laya.Label;
    public btnHandbookReward!: Laya.Button;
    public listSelect!: UIList;
    public listSwitch!: UIList;
}

