/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

/**
 * resources/prefab/mail/mail-info.lh
 */
export class MailInfoUIBase extends Laya.Dialog {
    Image!: Laya.Image;
    listItem!: Laya.List;
    btnClose!: Laya.Button;
    labelTitle!: Laya.Label;
    labelContent!: Laya.Label;
    btnDelete!: Laya.Button;
    labelFrom!: Laya.Label;
    labelDeliveryTime!: Laya.Label;
    btnReceive!: Laya.Button;
}
