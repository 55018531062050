/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/home/HomeTaskBox.lh
 */
export class HomeTaskBoxBase extends Laya.Box {
    public boxTaskInfo!: Laya.Box;
    public labelDesc!: Laya.Label;
    public labelValue!: Laya.Label;
    public boxComplete!: Laya.Box;
    public imgIcon!: Laya.Image;
}

