const { regClass } = Laya;
import {
    CollectionAmountData,
    InteractiveBubbleActionType,
    InteractiveBubbleViewType,
} from "../../../pve-server/PveDefs";
import { PveContext } from "../../../pve/PveContext";
import { PveUIBuildConfirmComponent } from "../../../pve/ecs/components/PveUIBuildConfirmComponent";
import { IInteractiveBubbleView } from "../IInteractiveBubbleView";
import { BuildConfirmUIBase } from "./BuildConfirmUI.generated";

@regClass('O-rGpuCOQQCNOxXiddEeNQ')
export class BuildConfirmUI extends BuildConfirmUIBase implements IInteractiveBubbleView {
    get viewType(): InteractiveBubbleViewType {
        return InteractiveBubbleViewType.FOUNDATION_BUILD_VIEW;
    }

    get actionTypes(): InteractiveBubbleActionType[] {
        return [InteractiveBubbleActionType.FOUNDATION_BUILD_ACTION_1];
    }

    comp: PveUIBuildConfirmComponent | undefined;

    context: PveContext | undefined;

    worldPosition: Laya.Vector3 = new Laya.Vector3();

    tid: number = 0;

    amountObj: CollectionAmountData | undefined;

    /** 需要的资源数量 */
    needAmount: number = 0;

    notEnoughCost: boolean = false;

    override onAwake(): void {
        super.onAwake();
        this.buildButton.on(Laya.Event.CLICK, this, this.onBuildBtnClick);
    }

    async onBuildBtnClick() {
        if (this.context && this.comp) {
            this.context.sender.interactiveBubbleAction(
                this.comp.eid,
                this.viewType,
                this.actionTypes[0],
                null
            );
            this.comp.removeComponent(PveUIBuildConfirmComponent);
        }
    }
}
