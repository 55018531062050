import { VoMap } from "../../../../misc/vo/VoMap";
import { BattleBuildingVo } from "./BattleBuildingVo";

export class BattleBuildingVoMap extends VoMap<number, BattleBuildingVo> {
    /** 检查是否是生产类型的建筑 */
    // public checkIsProductionBuilding(battle_entity: number): boolean {
    //     const arr = BattleConf.PVE.PRODUCTION_BATTLE_ENTITY;
    //     for (let i = 0; i < arr.length; i++) {
    //         const be_id = arr[i];
    //         if (be_id === battle_entity) {
    //             return true;
    //         }
    //     }
    //     return false;
    // }

    public getVoWithBattleEntityIdAndLevel(
        battle_entity: number,
        default_level: number
    ): BattleBuildingVo | undefined {
        if (default_level < 0) default_level = 0;
        return this.toArray().find((value) => {
            return value.battleEntity === battle_entity && value.level === default_level;
        });
    }
}
