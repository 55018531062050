import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { PveSvrCreatureComponent } from "../../ecs/components/PveSvrCreatureComponent";
import { SkillTreeEnv } from "../../ecs/components/PveSvrSkillLauncherComponent";

interface NodeArgs {
    readonly add?: number;
}

type NodeInput = [number];

export class Heal extends Process {
    override init(node: Node) {}

    override run(node: Node, env: SkillTreeEnv): Status {
        const [targetEid] = env.input as NodeInput;
        const target = env.context.ecs.getComponent(targetEid!, PveSvrCreatureComponent);
        if (!target) {
            node.warn(`invalid targetEid: ${targetEid}`);
            return "failure";
        }
        const args = node.args as unknown as NodeArgs;
        const ratio = args.add ?? 1;
        env.context.heal(env.skill, target, ratio);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "Heal",
            type: "Action",
            desc: "造成治疗",
            args: [{ name: "add", type: "float?", desc: "治疗加成(百分比)" }],
            input: ["目标单位"],
        };
    }
}
