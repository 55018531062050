const { regClass } = Laya;
import { app } from "../../../app";
import { ImageRef } from "../../../misc/ImageRef";
import { ui } from "../../../misc/ui";
import { UICharacterDetailArgs } from "../../hero/ui-runtime/detail/UICharacterDetail";
import { SkillVo } from "../vo/SkillVo";
import { SkillOwnListItemUIBase } from "./SkillOwnListItemUI.generated";

@regClass('Dqc9qtRwTJKXjBesoElReA')
export class SkillOwnListItemUI extends SkillOwnListItemUIBase {
    public isShowFirst: boolean = true;
    private skillVo!: SkillVo;

    override onAwake(): void {
        super.onAwake();
        this.btnActive.on(Laya.Event.CLICK, this, this.onClickGet);
        this.labBtn.on(Laya.Event.CLICK, this, this.onClickLab);
        this.btnActive.on(Laya.Event.CLICK, this, this.onClickActive);
        this.btnUp.on(Laya.Event.CLICK, this, this.onClickUp);
        this.btnOff.on(Laya.Event.CLICK, this, this.onClickOff);
    }

    private onClickLab(e: Laya.Event) {
        e.stopPropagation();
        this.isShowFirst = !this.isShowFirst;
        this.showDesc();
    }

    private onClickActive(e: Laya.Event) {
        e.stopPropagation();
        const data = this.skillVo!;
        app.ui.show(ui.SKILL_ACTIVE, app.service.table.battleSkill.skill[data.key]);
    }

    private onClickUp(e: Laya.Event) {
        e.stopPropagation();
        app.ui.closeTo(ui.SKILL_MAIN);
        const leaderVo = app.service.hero.getLeaderHero();
        if (!leaderVo) {
            console.error("no leader");
            return;
        }
        const data: UICharacterDetailArgs = {
            type: "character",
            character: leaderVo,
        };
        app.ui.show(ui.CHARACTER_DETAIL, data);
    }

    private onClickOff(e: Laya.Event) {
        e.stopPropagation();
        const data = this.skillVo!;
        app.ui.show(ui.SKILL_REMOVE, data);
    }

    private onClickGet(e: Laya.Event) {
        e.stopPropagation();
    }

    override set_dataSource(value: SkillVo): void {
        const skillData = value;
        this.skillVo = value;
        this.labType.text = this.labType2.text = "主动";
        this.labDesc.text = skillData.desc;
        this.labName.text = skillData.name;
        this.skill_icon.initIcon(app.service.table.battleSkill.skill[value.key]);
        this.btnOff.visible = skillData.isActed && skillData.heroIdList.length > 0;
        this.btnActive.visible = skillData.isCanAct && !skillData.isActed;
        this.btnUp.visible = skillData.isActed && !skillData.heroIdList.length;
        this.isShowFirst = true;
        this.showDesc();
        this.labType.text = this.labType2.text = app.service.skill.getSkillTypeText(
            skillData.typeskill
        );
        this.labType.color = this.labType2.color = app.service.skill.getTypeTextColor(
            skillData.typeskill
        );
        const imgQualityList = this.getComponent(ImageRef)!.texturePaths;
        this.imgQuality.skin = imgQualityList[skillData.quality - 1];
        this.labName.color = app.service.skill.getQualityTextColor(skillData.quality);
        this.labPro.visible = !skillData.isActed && !skillData.isCanAct;
        if (this.labPro.visible) {
            const actCost = value.activecost;
            const ownerCount = app.service.user.getMoneyCount(actCost.id);
            this.labPro.text = `已收集${ownerCount}/${actCost.num}`;
        }
        const texturepaths = this.imgType.getComponent(ImageRef)!.texturePaths;
        this.imgType.skin = this.imgType2.skin = texturepaths[skillData.typeskill - 1];
    }

    public showDesc() {
        const isFirst = this.isShowFirst;
        const data = this.skillVo as SkillVo;
        this.labDesc.text = isFirst
            ? app.service.skill.getFirstLevelDesc(app.service.table.battleSkill.skill[data.key])
            : app.service.skill.getFullLevelDesc(app.service.table.battleSkill.skill[data.key]);
        this.labBtn.text = isFirst ? `满级效果` : "一级效果";
        this.lablevelDesc.text = isFirst ? "一级效果" : "满级效果";
    }

    isShowDetails(isShow: boolean) {
        this.boxBase.visible = !isShow;
        this.boxDetails.visible = isShow;
        this.height = isShow ? 260 : 149;
        this.btnActive.y = this.btnOff.y = this.btnUp.y = isShow ? 54 : 68;
    }
}
