import * as ecs from "../../../../../core/ecs";
import { Animator } from "../../../base/Animator";

export class PnvnAnimationComponent extends ecs.Component {
    readonly mounter: Laya.Sprite3D = new Laya.Sprite3D();
    view?: Laya.Sprite3D | null;
    res!: string;

    // 冲锋状态
    rushing: boolean = false;

    animator?: Animator | null;
}
