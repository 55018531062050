/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/alliance/UIAllianceInvite.lh
 */
export class UIAllianceInviteBase extends Laya.Dialog {
    public searchText!: Laya.TextInput;
    public btnSearch!: Laya.Button;
}

