/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { BaseRoundWindowBg } from "../../common/ui-runtime/dialog/BaseRoundWindowBg";
import { LeaderSkinItem } from "./LeaderSkinItem";

/**
 * resources/prefab/leader-skin/UILeaderSkinUpgrade.lh
 */
export class UILeaderSKinUpgradeBase extends Laya.Dialog {
    public imgMask!: Laya.Image;
    public bgwin!: BaseRoundWindowBg;
    public listAtt!: Laya.List;
    public leaderSkinItem!: LeaderSkinItem;
    public btnUpgrade!: Laya.Button;
}

