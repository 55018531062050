const { regClass } = Laya;
import { UIDrawCardPreviewBase } from "./UIDrawCardPreview.generated";

@regClass('kUMXS3WbQrmmDjqJFYF6Uw')
export class UIDrawCardPreview extends UIDrawCardPreviewBase {
    public poolId: number = 0;

    override open(closeOther?: boolean, param?: number): void {
        this.poolId = param as number;
        super.open(closeOther, param);
    }
}
