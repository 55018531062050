/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIImageBar } from "../../../../core/ui/UIImageBar";

/**
 * resources/prefab/battle/p1v1-ui/info-enemy.lh
 */
export class P1v1TroopInfoUIBase extends Laya.Box {
    public avatar!: Laya.Image;
    public hp!: UIImageBar;
    public username!: Laya.Label;
    public hpTxt!: Laya.Label;
}
