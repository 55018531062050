import { ButtonBase } from "./ButtonBase";

const { regClass, property } = Laya;

@Laya.regClass('Z5FIqgUbQ6Cgx07JeAHhCA')
@Laya.classInfo({
    menu: "自定义",
    icon: "plugins/type-icons/node/Radio.svg",
})
export class UIRadioButton extends ButtonBase {}
