const { regClass } = Laya;
import { app } from "../../../../../app";
import { StringUtil } from "../../../../../core/utils/string-util";
import { BattlePveMapAreaRow } from "../../../../../def/table";
import { AreaProgressItemData } from "../../../pve-server/PveDefs";
import { AreaProgressBarRewardItemData } from "./AreaProgressBarRewardItemUI";
import { AreaProgressBarUIBase } from "./AreaProgressBarUI.generated";

@regClass('lbDbT21pRtWMIWWWnptCtA')
export class AreaProgressBarUI extends AreaProgressBarUIBase {
    public updateShow(
        areaItem: BattlePveMapAreaRow,
        areaName: string,
        datas: AreaProgressItemData[]
    ): void {
        const progressData = app.service.pve.mainLineProgressData;
        const totalProgress = progressData.getTotalProgressWith(datas);
        const curPerc = progressData.getTotalProgressPersent(totalProgress);
        let tsdStr = "[color=#c7e3f5][{0}] [/color][color=#f2f9fd]探索度 {1}%[/color]";
        tsdStr = StringUtil.format(tsdStr, areaName, curPerc);
        this.text_tsd.text = tsdStr;

        const progress_bar_width = 587;

        const curPercBi: number = curPerc / 100;
        this.img_bar.width = progress_bar_width * curPercBi;

        const minX = this.img_bar.x;
        const persents = areaItem.progress_persent;
        const rewards = areaItem.progress_reward;
        const itemDatas: AreaProgressBarRewardItemData[] = [];
        for (let i = 0; i < persents.length; i++) {
            const perc = persents[i];
            const reward = { id: rewards[i][0], num: rewards[i][1] };
            const achieve = curPerc >= perc;
            const itemData: AreaProgressBarRewardItemData = {
                index: i,
                areaId: areaItem.id,
                reward: reward,
                achieve: achieve,
                persent: perc,
                curPerc: curPerc,
            };
            itemDatas.push(itemData);
        }

        const items = [this.item_1, this.item_2, this.item_3, this.item_4];
        for (let i = 0; i < itemDatas.length; i++) {
            const itemData = itemDatas[i];
            if (i < items.length) {
                items[i].visible = true;
                items[i].data = itemData;
                items[i].x = minX + (itemData.persent / 100) * progress_bar_width;
                items[i].updateShow();
            } else {
                items[i].visible = false;
            }
        }
    }
}
