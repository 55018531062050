const { regClass } = Laya;
import { PvpServerVo } from "../vo/PvpServerVo";
import { UILoginPvpSelectorBase } from "./UILoginPvpSelector.generated";

export interface LoginPvpSelectorArgs {
    list: PvpServerVo[];
    callback: (server: PvpServerVo) => void;
}

@regClass('rbUeaFZkQXKOuWuFHVEy7Q')
export class UILoginPvpSelector extends UILoginPvpSelectorBase {
    args!: LoginPvpSelectorArgs;

    override open(closeOther?: boolean | undefined, param?: LoginPvpSelectorArgs): void {
        this.args = param!;
        super.open(closeOther, param);
    }
}
