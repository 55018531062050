import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";

export class MoveToAtkPos extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "MoveToAtkPos",
            type: "Action",
            desc: "移动到普攻范围内",
            input: ["目标"],
            doc: `
                + 目标可以是单个实体，也可以是数组`,
        };
    }
}
