import { Callback, ConstructorType } from "../dispatcher";
import { Bezier3D } from "./besier-3d";

export type EaseFunc = (t: number, b: number, c: number, d: number, ...any: any[]) => number;

class TweenVaule {
    private _ratio: number = 0;

    set ratio(t: number) {
        this._ratio = t;
    }

    get ratio() {
        return this._ratio;
    }
}

export class TweenUtil {
    static to<T>(target: T, props: ConstructorType<T>, duration: number, ease?: EaseFunc) {
        return Laya.Tween.to(target, props, duration, ease);
    }

    /**
     * 2D贝塞尔曲线动画
     * @param target 执行动作的目标
     * @param speed 速度
     * @param points 用于计算贝塞尔曲线的坐标，2次贝塞尔3个point 3次4个point，目前最大支持3次
     * @param backFunc 回调函数
     * @param inSertCount 运动轨迹的坐标数量 >=5,值越大 轨迹越明显
     */
    static toBezier(
        target: any,
        speed: number,
        points: Laya.Point[],
        onComplete?: Callback,
        inSertCount: number = 5
    ) {
        const _points = [];
        for (const point of points) {
            _points.push(point.x);
            _points.push(point.y);
        }
        const bezierPoints = Laya.Bezier.I.getBezierPoints(
            _points,
            inSertCount,
            points.length > 3 ? 3 : 2
        );
        const to = (num: number) => {
            const distance = new Laya.Point(bezierPoints[num], bezierPoints[num + 1]).distance(
                bezierPoints[num + 2],
                bezierPoints[num + 3]
            );
            return Laya.Tween.to(
                target,
                {
                    x: bezierPoints[num + 2],
                    y: bezierPoints[num + 3],
                },
                distance / speed,
                null,
                Laya.Handler.create(
                    null,
                    () => {
                        if (num + 4 >= bezierPoints.length) {
                            onComplete?.();
                            return;
                        } else {
                            to(num + 2);
                        }
                    },
                    null,
                    true
                )
            );
        };
        to(0);
    }

    static toBezier2(
        target: any,
        speed: number,
        points: Laya.Point[],
        onComplete?: Callback,
        ease?: Callback | null,
        inSertCount: number = 5
    ) {
        const _points = [];
        for (const point of points) {
            _points.push(point.x);
            _points.push(point.y);
        }
        const bezierPoints = Laya.Bezier.I.getBezierPoints(
            _points,
            inSertCount,
            points.length > 3 ? 3 : 2
        );
        const to = (num: number) => {
            const distance = new Laya.Point(bezierPoints[num], bezierPoints[num + 1]).distance(
                bezierPoints[num + 2],
                bezierPoints[num + 3]
            );
            const __target = new TweenVaule();
            return Laya.Tween.to(
                __target,
                {
                    ratio: 1,
                    update: new Laya.Handler(this, () => {
                        const t = __target.ratio;
                        const t1 = (1 - t) * (1 - t);
                        const t2 = 2 * t * (1 - t);
                        const t3 = t * t;
                        target.x = t1 * points[0].x + t2 * points[1].x + t3 * points[2].x;
                        target.y = t1 * points[0].y + t2 * points[1].y + t3 * points[2].y;
                    }),
                },
                speed,
                ease,
                Laya.Handler.create(
                    null,
                    () => {
                        onComplete?.();
                        return;
                    },
                    null,
                    true
                )
            );
        };
        to(0);
    }

    /**
     * 3D贝塞尔曲线动画
     * @param target 执行动作的目标
     * @param speed 速度
     * @param points 用于计算贝塞尔曲线的坐标，2次贝塞尔3个point 3次4个point，目前最大支持3次
     * @param backFunc 回调函数
     * @param inSertCount 运动轨迹的坐标数量 >=5,值越大 轨迹越明显
     */
    static toBezier3D(
        target: any,
        speed: number,
        points: Laya.Vector3[],
        backFunc?: Callback,
        inSertCount: number = 5
    ) {
        const _points = [];
        for (const point of points) {
            _points.push(point.x);
            _points.push(point.y);
            _points.push(point.z);
        }
        const bezierPoints = Bezier3D.I.getBezierPoints(
            _points,
            inSertCount,
            points.length > 3 ? 3 : 2
        );
        function to(idx: number) {
            const fromPos = new Laya.Vector3(
                bezierPoints[idx],
                bezierPoints[idx + 1],
                bezierPoints[idx + 2]
            );
            const toPos = new Laya.Vector3(
                bezierPoints[idx + 3],
                bezierPoints[idx + 4],
                bezierPoints[idx + 5]
            );
            const distance = Laya.Vector3.distance(fromPos, toPos);
            return Laya.Tween.to(
                target,
                { localPositionX: toPos.x, localPositionY: toPos.y, localPositionZ: toPos.z },
                distance / speed,
                null,
                Laya.Handler.create(null, () => {
                    if (idx + 6 >= bezierPoints.length) {
                        if (backFunc) {
                            return backFunc();
                        }
                        return;
                    } else {
                        to(idx + 3);
                    }
                })
            );
        }
        to(0);
    }
}
