import { Mediator } from "../../core/ui-mediator";
import { UIAllianceHome } from "./ui-runtime/UIAllianceHome";

const { regClass, property } = Laya;

@regClass('DZZ_eupXTPuBx2y9mBcRRA')
export class UIAllianceHomeMediator extends Mediator {
    declare owner: UIAllianceHome;

    override onAwake(): void {
        super.onAwake();
    }
}
