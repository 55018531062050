/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/alliance/AllianceManageItem.lh
 */
export class AllianceManageItemBase extends Laya.Box {
    public bg!: Laya.Image;
    public icon!: Laya.Image;
    public title!: Laya.Label;
}

