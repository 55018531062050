import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AnimName } from "../../../base/Animator";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";

export class MoveStop extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        env.context.moveStop(env.owner.eid);
        env.context.playAnim(env.owner.eid, AnimName.IDLE);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "MoveStop",
            type: "Action",
            desc: "停止移动",
        };
    }
}
