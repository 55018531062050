/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { HomeTaskBox } from "./HomeTaskBox";
import { HomeUserBoxUI } from "./HomeUserBoxUI";
import { HomeChatBox } from "./HomeChatBox";
import { HomeNaviMenu } from "./HomeNaviMenu";
import { HomeRightMenu } from "./HomeRightMenu";

/**
 * scenes/home.ls
 */
export class HomeUIBase extends Laya.Scene {
    public battle!: Laya.Box;
    public btnMoreMenu!: Laya.Box;
    public taskBox!: HomeTaskBox;
    public boxUser!: HomeUserBoxUI;
    public "chat-box"!: HomeChatBox;
    public naviMenu!: HomeNaviMenu;
    public rightMenu!: HomeRightMenu;
    public btnDrawCard!: Laya.Box;
    public btnActivity!: Laya.Box;
    public btnNewbie!: Laya.Box;
    public btn7Sign!: Laya.Box;
}

