import { Mediator } from "../../core/ui-mediator";
import { UIAllianceInfo } from "./ui-runtime/UIAllianceInfo";

const { regClass, property } = Laya;

@regClass('FqlZSp6cTE6cDDHkdAnUag')
export class UIAllianceInfoMediator extends Mediator {
    declare owner: UIAllianceInfo;

    override onAwake(): void {
        super.onAwake();
    }
}
