/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/skill/skill_map.lh
 */
export class SkillMapUIBase extends Laya.Box {
    public panalScroller!: Laya.Panel;
    public vboxContent!: Laya.VBox;
}

