import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { ItemRowArgsComposite } from "../../def/table";
import { UIItemCompose } from "./ui-runtime/UIItemCompose";

const { regClass, property } = Laya;

@regClass('hqBhDV4hRN-BpFlZVZX-xQ')
export class UIItemComposeMediator extends Mediator {
    declare owner: UIItemCompose;

    //组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
    override onAwake(): void {
        super.onAwake();
        this.initEvent();
        this.initInfo();
    }

    initEvent() {
        this.owner.imgMask.on(Laya.Event.CLICK, this, this.onCloseBtn);
        this.owner.btnUse.on(Laya.Event.CLICK, this, this.onClickComposite);
    }

    initInfo() {
        const itemData = this.owner.itemData;
        const ownCount = app.service.bag.itemVoMap.getItemAmount(itemData.id);

        const itemLo = app.service.bag.itemLoMap.get(itemData.id);
        this.owner.iconTop.updateGoods(itemLo);
        this.owner.labelName.text = itemData.name;
        this.owner.labelDesc.text = itemData.desc;

        const args = itemData.args as ItemRowArgsComposite;

        const costCount = args.cost;

        this.owner.labOwn.text = `${ownCount}/${costCount}`;
        this.owner.labCount.text = `数量:${1}`;

        const max = Math.floor(ownCount / costCount);
        this.owner.hsliderCom.setSlider(0, max, 0);
        this.owner.hsliderCom.onSliderChange = () => {
            const value = this.owner.hsliderCom.value;
            this.owner.labCount.text = `数量:${value}`;
        };
    }

    onClickComposite() {
        const itemData = this.owner.itemData;
        const itemId = itemData.id;
        const value = this.owner.hsliderCom.value;
        if (value) {
            app.service.bag.requestCompositeItem({ itemId: itemId, times: value });
            this.owner.destroy();
        } else {
            app.ui.toast("请选择物品数量");
        }
    }

    onCloseBtn() {
        this.owner.destroy();
    }
}
