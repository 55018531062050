import { app } from "../../../../app";
import { VoMap } from "../../../../misc/vo/VoMap";
import { BattleSpoilsLo } from "./BattleSpoilsLo";

export class BattleSpoilsLoMap extends VoMap<number, BattleSpoilsLo> {
    override get(id: number) {
        let lo = super.get(id);
        if (!lo) {
            lo = new BattleSpoilsLo(app.service.table.spoils[id]);
            this.set(id, lo);
        }
        return lo;
    }

    private _allLo: BattleSpoilsLo[] | undefined;

    public getAllLo(): BattleSpoilsLo[] {
        if (this._allLo) {
            return this._allLo;
        }
        this._allLo = [];
        const items = app.service.table.spoils;
        for (const key in items) {
            const lo = this.get(Number(key));
            this._allLo.push(lo);
        }
        return this._allLo;
    }
}
