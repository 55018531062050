import { chat } from "../../../def/auto/proto";
import { Vo } from "../../../misc/vo/Vo";

/**
 * Item
 * 道具
 */
export class ChatMsgVo extends Vo<never, chat.ChatMessage> {
    protected declare _data: chat.ChatMessage;

    private _id: number = 0;

    constructor(id: number, data: chat.ChatMessage) {
        super(undefined!);
        this._id = id;
        this._data = data;
    }

    override clone(): ChatMsgVo {
        return new ChatMsgVo(this._id, this._data);
    }

    override get key() {
        return this._id;
    }

    get rid() {
        return this._data.rid;
    }

    get text() {
        return this._data.text;
    }

    get time() {
        return this._data.time;
    }
}
