import { TreeEnv, TreeRunner } from "../../../../../core/behavior3";
import * as ecs from "../../../../../core/ecs";
import { IReusable, Pool } from "../../../../../core/pool";
import { PveServer } from "../../PveServer";
import { PveSvrElementComponent } from "./PveSvrElementComponent";

export class AiTreeEnv extends TreeEnv<PveServer> {
    owner: PveSvrElementComponent;

    constructor(context: PveServer, owner: PveSvrElementComponent) {
        super(context);
        this.owner = owner;
    }
}

@Pool.reusable
export class PveSvrAiComponent extends ecs.Component implements IReusable {
    private _active = true;

    public get active() {
        return this._active;
    }

    public set active(value) {
        this._active = value;
    }

    lastUpdate: number = 0;
    btree: string = "";
    tick: number = 0;
    tree: TreeRunner<AiTreeEnv> | null = null;

    __unuse() {
        this.btree = "";
        this.tree = null;
        this.tick = 0;
        this.active = true;
        this.lastUpdate = 0;
    }

    __reuse() {}
}
