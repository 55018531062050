import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { BuffTreeEnv } from "../../ecs/data/buff/BuffBase";

export class GetBuffCreator extends Process {
    override run(node: Node, env: BuffTreeEnv): Status {
        env.output.push(env.skill.owner.eid);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "GetBuffCreator",
            type: "Action(Buff)" as "Action",
            desc: "获取buff的创建者",
            output: ["Buff创建者"],
            doc: `
                + 返回Buff创建者，只能在Buff行为树里使用`,
        };
    }
}
