const { regClass } = Laya;
import { PnvnUIBase } from "./PnvnUI.generated";

export type PnvnUIArgs = {
    battleUid: number;
};

@regClass('cn7qMyBZSoC-NpJuxxMqzg')
export class PnvnUI extends PnvnUIBase {
    args!: PnvnUIArgs;

    override get scene3D(): Laya.Scene3D {
        return this._scene3D;
    }

    override open(closeOther?: boolean | undefined, param?: PnvnUIArgs) {
        this.args = param!;
        super.open(closeOther, param);
    }
}
