import { Quad } from "../../../../../core/render/mesh-render";
import * as ecs from "../../../../../core/ecs";
import { ArrowColor } from "./PvpArrowsRenderCompnent";

export class PvpNaviArrowComponent extends ecs.Component {
    color: ArrowColor = ArrowColor.BLUE;
    quads: Quad[] = [];
    start: Laya.Vector3 = new Laya.Vector3();
    dest: Laya.Vector3 = new Laya.Vector3();
}
