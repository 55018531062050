import * as ecs from "../../../../../core/ecs";
import { BattleEventLo } from "../../../pve/vo/BattleEventLo";

export type EventTrigger = "enter" | "leave";

export class PveSvrEventComponent extends ecs.Component {
    data!: BattleEventLo;

    /** 伴生对象，一般是由Event事件类型不同，需要生成的对象 */
    readonly relatedEids: number[] = [];
}
