import { app } from "../../app";
import { Service } from "../../core/service";
import proto, { hero } from "../../def/auto/proto";
import { errcode, opcode } from "../../def/auto/protocol";
import { HeroFameStageRow, HeroRow, HeroStageRow } from "../../def/table";
import { SystemEvent } from "../../misc/system-event";
import { ui } from "../../misc/ui";
import { TableUtil } from "../table/TableUtil";
import { HeroHandbookVo } from "./vo/HeroHandbookVo";
import { HeroHandbookVoMap } from "./vo/HeroHandbookVoMap";
import { HeroLoMap } from "./vo/HeroLoMap";
import { HeroVo } from "./vo/HeroVo";
import { HeroVoMap } from "./vo/HeroVoMap";

export interface IUpStageResult {
    nextStage: number;
    upLvLimit: number;
    upAtk: number;
    upDefence: number;
    upHp: number;
}

export interface IUpStageMatHeroes {
    stage: number;
    requireHeroConf: [number, number][];
    matHeroes: HeroVo[];
}

export interface IUpStarMatHeroes {
    star: number;
    requireSelfCnt: number;
    selfMatHeroes: HeroVo[];
    requireHeroConf: [number, number][];
    otherMatHeroes: HeroVo[];
}

export class HeroService extends Service {
    private _commonInfo: hero.ICommonInfo | null = null;

    public get fame() {
        return this._commonInfo?.fame ?? 0;
    }

    public get receivedFameStage() {
        return this._commonInfo?.receivedFameStage ?? 0;
    }

    readonly heroVoMap = new HeroVoMap();
    readonly heroHandbookVoMap = new HeroHandbookVoMap();
    readonly heroLoMap = new HeroLoMap();

    private _bLoaded = false;

    get bLoaded() {
        return this._bLoaded;
    }

    override onCreate(): void {
        this.handle(opcode.hero.s2c_load, this._onLoad);
        this.handle(opcode.hero.s2c_upgrade_star, this._onUpgradeStar);
        this.handle(opcode.hero.s2c_upgrade_level, this._onUpgradeLevel);
        this.handle(opcode.hero.s2c_switch_lock_hero, this._onSwitchLockHero);
        this.handle(opcode.hero.s2c_reset_hero, this._onResetHero);
        this.handle(opcode.hero.s2c_upgrade_stage, this._onHeroUpStage);
        this.handle(opcode.hero.notify_hero_update, this._onNotifiedHeroUpdate);
        this.handle(opcode.hero.notify_hero_handbook_update, this._onNotifiedHandbookUpdate);
        this.handle(opcode.hero.notify_common_info, this._onNotifiedCommonInfo);
    }

    override onStartInit(): void {}

    override onDestroy(): void {}

    //#region rpc response
    private _onLoad(info: hero.s2c_load, req: hero.c2s_load) {
        if (info.err !== errcode.OK) {
            console.error(`load hero failed: ${info.err}`);
            return;
        }

        const cachedHeroVoArr = this.heroVoMap.toArray();
        this.heroVoMap.clear();
        for (const data of info.heroList) {
            this._tryUpdateHeroVo(data as hero.Hero, cachedHeroVoArr);
        }

        this.heroHandbookVoMap.clear();
        const allNormalConfigHeroRow = Object.values(app.service.table.hero.hero).filter(
            (v) => !v.leader
        );
        allNormalConfigHeroRow.forEach((config) => {
            const handbook = info.heroHandbook.find(
                (he) => he.id === config.id
            ) as proto.hero.HeroHandbookItem;
            let vo = this.heroHandbookVoMap.get(config.id);
            if (vo) {
                vo.__setData(handbook);
            } else {
                vo = new HeroHandbookVo(config, handbook);
                this.heroHandbookVoMap.set(vo.key, vo);
            }
        });

        this._commonInfo = info.commonInfo!;

        this._bLoaded = true;
        app.event(SystemEvent.CHARACTER.SVR_LOAD);
    }

    private _onUpgradeStar(resp: hero.s2c_upgrade_star, req: hero.c2s_upgrade_star) {
        app.ui.toast(resp.err ? `升星失败${resp.err}` : "升星成功");
    }

    private _onUpgradeLevel(resp: hero.s2c_upgrade_level, req: hero.c2s_upgrade_level) {}

    private _onSwitchLockHero(resp: hero.s2c_switch_lock_hero, req: hero.c2s_switch_lock_hero) {}

    private _onResetHero(resp: hero.s2c_reset_hero, req: hero.c2s_reset_hero) {
        app.ui.toast(resp.err ? `重生失败${resp.err}` : "重生成功");
    }

    private _onNotifiedHeroUpdate(resp: hero.notify_hero_update) {
        for (const delUid of resp.delHeroUidList) {
            this.heroVoMap.delete(delUid);
        }

        const cachedHeroVoArr = this.heroVoMap.toArray();
        for (const data of resp.heroList) {
            let heroVo = cachedHeroVoArr.find((v) => v.id === data.id);
            if (!heroVo) {
                //获得新英雄时弹出
                const id = data.id!;
                const config = TableUtil.getRow<HeroRow>(app.service.table.hero.hero, { id });
                if (!config) {
                    throw new Error(`hero config not found: ${id}`); //todo: use assert
                }
                heroVo = new HeroVo(config, data);
                app.ui.show(ui.DRAW_CARD_GAIN_HERO, heroVo);
            }
            this._tryUpdateHeroVo(data as hero.Hero, cachedHeroVoArr);
        }

        app.event(SystemEvent.CHARACTER.SVR_HERO_UPDATE, resp);
    }

    private _tryUpdateHeroVo(data: hero.Hero, cachedHeroVoArr: HeroVo[]) {
        let heroVo = cachedHeroVoArr.find((v) => v.uid === data.uid);
        if (heroVo) {
            heroVo.__setData(data);
        } else {
            const id = data.id!;
            const config = TableUtil.getRow<HeroRow>(app.service.table.hero.hero, { id });
            if (!config) {
                throw new Error(`hero config not found: ${id}`); //todo: use assert
            }
            heroVo = new HeroVo(config, data);
        }
        this.heroVoMap.set(heroVo.key, heroVo);
    }

    private _onNotifiedHandbookUpdate(resp: hero.notify_hero_handbook_update) {
        resp.heroHandbook.forEach((data) => {
            const vo = this.heroHandbookVoMap.get(data.id!)!;
            vo.__setData(data as proto.hero.HeroHandbookItem);
        });
        app.event(SystemEvent.CHARACTER.SVR_HANDBOOK_UPDATE, resp);
    }

    private _onNotifiedCommonInfo(resp: hero.notify_common_info) {
        this._commonInfo = resp.commonInfo!;
        app.event(SystemEvent.CHARACTER.SVR_FAME_UPDATE, resp);
    }

    private _onHeroUpStage(resp: hero.s2c_upgrade_stage, req: hero.c2s_upgrade_stage) {
        app.ui.toast(resp.err ? `升阶失败${resp.err}` : "升阶成功");
    }

    //#endregion rpc response

    //#region  rpc call
    /**
     *请求登录
     * @param username 登录账号
     * @returns
     */
    async load() {
        return app.service.network.call(hero.c2s_load.create(), hero.s2c_load);
    }

    async requestUpgradeStar(uid: number, materialHeroUids: number[]) {
        return app.service.network.call(
            hero.c2s_upgrade_star.create({
                uid,
                materialHeroUids,
            }),
            hero.s2c_upgrade_star
        );
    }

    async requestUpgradeLevel(uid: number, destLv: number) {
        return app.service.network.call(
            hero.c2s_upgrade_level.create({ uid, destLv }),
            hero.s2c_upgrade_level
        );
    }

    async requestSwitchLockHero() {
        return app.service.network.call(
            hero.c2s_switch_lock_hero.create(),
            hero.s2c_switch_lock_hero
        );
    }

    // 角色重生、重铸
    async requestResetHero(uid: number) {
        return app.service.network.call(hero.c2s_reset_hero.create({ uid }), hero.s2c_reset_hero);
    }

    async requestUpStage(uid: number, materialHeroUids: number[]) {
        return app.service.network.call(
            hero.c2s_upgrade_stage.create({
                uid,
                materialHeroUids,
            }),
            hero.s2c_upgrade_stage
        );
    }

    async requestHandbookReward(idList: number[]) {
        return app.service.network.call(
            hero.c2s_receive_handbook_reward.create({
                idList,
            }),
            hero.s2c_receive_handbook_reward
        );
    }

    async requestFameReward() {
        return app.service.network.call(
            hero.c2s_receive_fame_reward.create(),
            hero.s2c_receive_fame_reward
        );
    }

    //#endregion  rpc call

    getAllHeroList() {
        const list = this.getNormalHeroList([]);
        list.unshift(this.getLeaderHero());
        return list;
    }

    getLeaderHero() {
        return this.heroVoMap
            .toArray()
            .filter((v) => v.enable)
            .find((v) => v.isLeader)!;
    }

    getNormalHeroList(jobArr: number[]) {
        const list = this.heroVoMap
            .toArray()
            .filter((v) => {
                if (!v.enable) {
                    return false;
                }
                let ret = !v.isLeader;
                if (jobArr && jobArr.length > 0) {
                    ret &&= jobArr.includes(v.job);
                }
                return ret;
            })
            .sort((a, b) => {
                //  战力＞品质＞角色id
                if (a.power !== b.power) {
                    return b.power - a.power;
                }
                if (a.quality !== b.quality) {
                    return b.quality - a.quality;
                }
                return b.id - a.id;
            });
        return list;
    }

    //编队小兵列表
    getTroopSoliderListSort(jobArr: number[]) {
        const list = this.heroVoMap.toArray().filter((v) => {
            let ret = !v.isLeader;
            if (jobArr && jobArr.length > 0) {
                ret &&= jobArr.includes(v.job);
            }
            return ret;
        });

        const sameCfgIdList: { [key: number]: { uid: number; star: number; level: number } } = {};
        for (const soliderData of list) {
            const uid = soliderData.uid;
            const cfgIdx = soliderData.id;
            const star = soliderData.star;
            const level = soliderData.lv;
            if (
                !sameCfgIdList[cfgIdx] ||
                sameCfgIdList[cfgIdx].star < star ||
                (sameCfgIdList[cfgIdx].star === star && sameCfgIdList[cfgIdx].level < level)
            ) {
                sameCfgIdList[cfgIdx] = { uid: uid, star: star, level: level };
            }
        }
        const sameCfgIdSoliderData: HeroVo[] = [];
        for (const key in sameCfgIdList) {
            const uid = sameCfgIdList[key].uid;
            const heroData = this.heroVoMap.get(uid);
            if (heroData) {
                sameCfgIdSoliderData.push(heroData);
            }
        }
        sameCfgIdSoliderData.sort((a, b) => {
            if (a.quality !== b.quality) {
                return b.quality - a.quality;
            } else {
                return b.id - a.id;
            }
        });
        return sameCfgIdSoliderData;
    }

    getHandbookHeroList(jobArr: number[]) {
        let list = this.heroHandbookVoMap.toArray().filter((v) => v.enable);
        if (jobArr && jobArr.length > 0) {
            list = list.filter((v) => jobArr.includes(v.job));
        }
        return list.sort((a, b) => {
            //  品质＞角色id
            if (a.quality !== b.quality) {
                return b.quality - a.quality;
            }
            return b.id - a.id;
        });
    }

    calcuFameStatus(): {
        curFame: number; //curFame
        recvFameStage: number;
        nextRecvFameStageRow: HeroFameStageRow;
        curFameStageRow: HeroFameStageRow;
    } {
        const MAX_FAME_STAGE = app.service.table.hero.conf.MAX_FAME_STAGE;
        const fameStages = app.service.table.hero.fame_stage;
        const curFame = this.fame;
        const recvFameStage = this.receivedFameStage;
        const curFameStage = this.getCurFameStage(curFame);
        const curFameStageRow = fameStages.find((v) => v.id === curFameStage)!;

        const nextRecvFameStage = Math.min(recvFameStage + 1, MAX_FAME_STAGE);
        const nextRecvFameStageRow = fameStages.find((v) => v.id === nextRecvFameStage)!;

        return {
            curFame: curFame,
            recvFameStage: recvFameStage,
            nextRecvFameStageRow,
            curFameStageRow: curFameStageRow,
        };
    }

    private getCurFameStage(fame: number) {
        const fameStages = app.service.table.hero.fame_stage;
        if (fame <= fameStages[0].require_fame) {
            return fameStages[0].id;
        }

        for (let i = 0; i < fameStages.length; i++) {
            if (i === fameStages.length - 1) {
                return fameStages[i].id; //最后一个阶段
            }

            if (fame > fameStages[i].require_fame && fame <= fameStages[i + 1].require_fame) {
                return fameStages[i].id;
            }
        }
        console.error(`getCurFameStageIndex error!!: ${fame}`); //不可能走到这里，只是为了代码提示
        return 0;
    }

    // 计算升级需要的资源，先计算可升多少级，受资源和等级上限限制，再计算总共需要的资源
    calcuUpgradeLvNeedResource(lv: number, stage: number) {
        const conf = app.service.table.hero.conf;
        const itemId = conf.UPGRADE_LEVEL_ITEM_ID;
        const stageMaxLv = app.service.table.hero.hero_stage.lv_limit[stage];
        const itemResourceCount = app.service.user.getMoneyCount(itemId);
        let targetLv = lv;
        let needResCount = 0;
        let needUpStage = false;
        if (lv >= stageMaxLv) {
            needUpStage = true;
        } else {
            while (targetLv < stageMaxLv && needResCount < itemResourceCount) {
                const requireCnt = app.service.table.hero.hero_lv.require_cnt[targetLv - 1];
                if (needResCount + requireCnt > itemResourceCount) {
                    break;
                } else {
                    needResCount += requireCnt;
                    targetLv++;
                }
            }
        }

        return { itemId, needResCount, targetLv, needUpStage };
    }

    // 计算升级需要消耗的货币资源，包括升阶
    calcuUpLvNeedMoney(destLv: number) {
        destLv = Math.min(destLv, app.service.table.hero.conf.MAX_LEVEL);
        let ret = 0;
        for (let i = 1; i < destLv; i++) {
            const requireCnt = app.service.table.hero.hero_lv.require_cnt[i - 1];
            ret += requireCnt;
        }

        return ret;
    }

    // 计算角色升阶需要的资源，以及升阶带来的属性变化
    calcuUpStageResult(heroVo: HeroVo): IUpStageResult {
        const curStage = heroVo.stage;
        const nextStage = curStage + 1;

        const heroRow = TableUtil.getRow(app.service.table.hero.hero, { id: heroVo.id })!;
        const lvLimit = app.service.table.hero.hero_stage.lv_limit[curStage];

        if (heroVo.lv !== lvLimit) {
            throw new Error(`hero lv not reach limit, ${heroVo.lv}, ${lvLimit}`);
        }

        const heroStageSheet = app.service.table.hero.hero_stage;

        const attrAtk = TableUtil.getRow(app.service.table.attr, { define: "atk" })!;
        const attrHp = TableUtil.getRow(app.service.table.attr, { define: "hp" })!;
        const attrDefence = TableUtil.getRow(app.service.table.attr, { define: "defence" })!;

        const stageAtkKey = `attr_stage${attrAtk.id}` as keyof HeroRow;
        const stageHpKey = `attr_stage${attrHp.id}` as keyof HeroRow;
        const stageDefenceKey = `attr_stage${attrDefence.id}` as keyof HeroRow;

        const stageAtkName = heroRow[stageAtkKey];
        const stageHpName = heroRow[stageHpKey];
        const stageDefenceName = heroRow[stageDefenceKey];

        const attrNameAtk = `attr_grow${stageAtkName}` as keyof HeroStageRow;
        const attrNameHp = `attr_grow${stageHpName}` as keyof HeroStageRow;
        const attrNameDefence = `attr_grow${stageDefenceName}` as keyof HeroStageRow;

        const upAtk = heroStageSheet[attrNameAtk][nextStage] as number;
        const upHp = heroStageSheet[attrNameHp][nextStage] as number;
        const upDefence = heroStageSheet[attrNameDefence][nextStage] as number;
        if (!upAtk || !upDefence || !upHp) {
            console.error(
                `heroNextStageRow attr not found: `,
                attrNameAtk,
                attrNameDefence,
                attrNameHp
            );
        }

        return {
            nextStage,
            upLvLimit: heroStageSheet.lv_limit[nextStage] as number,
            upAtk,
            upDefence,
            upHp,
        };
    }

    getUpStageMatHeroes(uid: number): IUpStageMatHeroes {
        const targetHero = app.service.hero.heroVoMap.get(uid)!;

        const requireHero = app.service.table.hero.hero_stage.require_hero[targetHero.stage] as {
            [star: string]: number;
        };

        // 素材只需要显示同星级素材
        const matHeroes = app.service.hero.heroVoMap
            .toArray()
            .filter((v) => {
                if (!v.enable) {
                    return false;
                }
                return !v.isLeader && v.star in requireHero && v.uid !== targetHero.uid;
            })
            .sort((a, b) => {
                if (a.lv === b.lv) {
                    return a.job - b.job;
                }
                return a.lv - b.lv;
            });

        const requreHero: [number, number][] = [];
        for (const star in requireHero) {
            requreHero.push([parseInt(star), requireHero[star]]);
        }

        return {
            stage: targetHero.stage,
            requireHeroConf: requreHero,
            matHeroes,
        };
    }

    // 计算角色升阶需要的资源，以及升阶带来的变化
    calcuUpStarResult(starProgramme: number, star: number) {
        const starPrgmId = starProgramme;
        type keyOfHeroStar = keyof typeof app.service.table.hero.conf.PROGM_ID_2_MAX_STAR;
        const maxStar =
            app.service.table.hero.conf.PROGM_ID_2_MAX_STAR[`${starPrgmId}` as keyOfHeroStar] ?? -1;
        if (maxStar < 0) {
            throw new Error(`maxStar not found: ${starPrgmId}`);
        }
        if (star >= maxStar) {
            return null;
        }

        const nextStarRow = app.service.table.hero.hero_star[starPrgmId][star + 1];

        return {
            nextStar: star + 1,
            nextStarEntryDesc: nextStarRow.entry_desc,
        };
    }

    getUpStarMatHeroes(uid: number): IUpStarMatHeroes {
        const heroVo = app.service.hero.heroVoMap.get(uid)!;
        const starPrgmId = heroVo.starProgramme;
        const heroStarRow = app.service.table.hero.hero_star[starPrgmId].find(
            (v) => v.star === heroVo.star
        )!;
        const requireSelfCnt = heroStarRow.require_self_cnt;
        const selfMatHeroes = app.service.hero.heroVoMap
            .toArray()
            .filter((v) => {
                if (!v.enable) {
                    return false;
                }
                return (
                    !v.isLeader &&
                    v.uid !== heroVo.uid && //非自己
                    v.id === heroVo.id && //同名素材
                    v.star === v.baseStar //星级仍为基础星级
                );
            })
            .sort((a, b) => a.lv - b.lv);

        const require_hero = heroStarRow.require_hero ?? {};
        const requireHeroConf: [number, number][] = Object.keys(require_hero).map((star) => {
            return [parseInt(star), require_hero[star]];
        });

        // 素材只需要显示同星级素材
        const matHeroes =
            requireHeroConf.length > 0
                ? app.service.hero.heroVoMap
                      .toArray()
                      .filter((v) => {
                          if (!v.enable) {
                              return false;
                          }
                          return (
                              !v.isLeader && //非主角
                              v.id !== heroVo.id && //非自己
                              v.star === requireHeroConf[0][0] //同星级
                          );
                      })
                      .sort((a, b) => {
                          if (a.lv === b.lv) {
                              return a.job - b.job;
                          }
                          return a.lv - b.lv;
                      })
                : [];

        return {
            star: heroVo.star,
            requireSelfCnt,
            selfMatHeroes,
            requireHeroConf,
            otherMatHeroes: matHeroes,
        };
    }

    // 必须注意，awardedStar不一定从0算起，从baseStar-1算起。
    calcuHandbookStarReward(
        starProgramme: number,
        star: number,
        awardedStar: number
    ): { id: number; count: number }[] {
        if (star <= awardedStar) {
            return [];
        }
        const reward: { id: number; count: number }[] = [];
        const heroStarRowArr = app.service.table.hero.hero_star[starProgramme];
        for (let i = awardedStar + 1; i <= star; i++) {
            const row = heroStarRowArr.find((v) => v.star === i)!;
            row.handbook_reward.forEach((v) => {
                const re = reward.find((r) => r.id === v.id);
                if (re) {
                    re.count += v.num;
                } else {
                    reward.push({ id: v.id, count: v.num });
                }
            });
        }
        return reward;
    }
}
