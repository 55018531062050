import { app } from "../../app";
import { UIItemGetWay } from "./ui-runtime/UIItemGetWay";
import { UIItemGetWayItem } from "./ui-runtime/UIItemGetWayItem";
import { ItemRourceLo } from "./vo/ItemRourceLo";

const { regClass, property } = Laya;

@regClass('DcjVh16PSemSfbejDbX5Nw') //获取途径
export class UIItemGetWayMediator extends Laya.Script {
    //declare owner : Laya.Sprite3D;
    declare owner: UIItemGetWay;

    @property(String)
    public text: string = "";

    //组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
    override onAwake(): void {
        super.onAwake();
        this.owner.imgMask.on(Laya.Event.CLICK, this.owner, this.owner.destroy);
        this.owner.listway.renderHandler = new Laya.Handler(this, this.onListRender);
        this.initPanel();
    }

    private onListRender(item: UIItemGetWayItem, index: number) {
        const rourceLo = item.dataSource as ItemRourceLo;
        item.labName.text = rourceLo.name;
        item.boxGo.on(Laya.Event.CLICK, this, this.onClickGo);
    }

    private onClickGo(e: Laya.Event) {
        const target = e.currentTarget as Laya.Box;
        const item = target.parent as Laya.Box;
        const rourceLo = item.dataSource as ItemRourceLo;
        app.ui.jumpPanel(rourceLo.panelStr);
    }

    private initPanel() {
        const itemData = this.owner.itemData;
        this.owner.labelName.text = itemData.name;
        this.owner.labDesc.text = itemData.desc;
        const itemLo = app.service.bag.itemLoMap.get(itemData.id);
        this.owner.iconItem.updateGoods(itemLo);
        const itemSource = itemLo.itemSource;
        this.owner.listway.array = itemSource;
    }

    //组件被启用后执行，例如节点被添加到舞台后
    //onEnable(): void {}

    //组件被禁用时执行，例如从节点从舞台移除后
    //onDisable(): void {}

    //第一次执行update之前执行，只会执行一次
    //onStart(): void {}

    //手动调用节点销毁时执行
    //onDestroy(): void {}

    //每帧更新时执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onUpdate(): void {}

    //每帧更新时执行，在update之后执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onLateUpdate(): void {}

    //鼠标点击后执行。与交互相关的还有onMouseDown等十多个函数，具体请参阅文档。
    //onMouseClick(): void {}
}
