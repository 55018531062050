import * as ecs from "../../../../../core/ecs";
import { PveServer } from "../../PveServer";
import { PveSvrElementComponent } from "../components/PveSvrElementComponent";
import { PveSvrVFXComponent } from "../components/PveSvrVFXComponent";

const tempVec3 = new Laya.Vector3();

export class PveSvrVFXSystem extends ecs.System {
    declare context: PveServer;

    static readonly TICK = 0.1;

    override onCreate(): void {
        // this.registerHandler(
        //     PveSvrVFXComponent,
        //     this._onAddPveSvrVFXComponent,
        //     this._onDelPveSvrVFXComponent
        // );
    }

    override update(dt: number) {
        const time = this.context.time;
        this.ecs.getComponents(PveSvrVFXComponent).forEach((vfxComp) => {
            if (vfxComp.isAttach && vfxComp.attachEid) {
                const follow = this.ecs.getComponent(vfxComp.attachEid, PveSvrElementComponent);
                if (follow) {
                    tempVec3.cloneFrom(follow.transform.position);
                    tempVec3.vadd(vfxComp.offset, tempVec3);

                    this.context.setPosition(
                        vfxComp.getComponent(PveSvrElementComponent)!,
                        tempVec3
                    );
                }
            }
        });
    }

    private async _onAddPveSvrVFXComponent(vfx: PveSvrVFXComponent) {}

    private _onDelPveSvrVFXComponent(vfx: PveSvrVFXComponent) {}
}
