const { regClass } = Laya;
import { BDFuncProductionSpeedItemUIBase } from "./BDFuncProductionSpeedItemUI.generated";

@regClass('Jeytb-53Tkm9lLG5M7ffUQ')
export class BDFuncProductionSpeedItemUI extends BDFuncProductionSpeedItemUIBase {
    public updateShow(leftStr:string,rightStr:string):void
    {
        this.leb_01.text = leftStr;
        if(rightStr){
            this.img_jiantou.visible = true;
            this.leb_02.text = rightStr;
        }else{
            // 无下级
            this.img_jiantou.visible = false;
            this.leb_02.text = ``;
        }
    }
}