// 吞噬点击事件，防止事件穿透
@Laya.regClass('d_pMlLY0TPasoqAIalJBLA')
export class BlockClickEvent extends Laya.Script {
    override onAwake(): void {
        super.onAwake();
        this.owner.on(Laya.Event.CLICK, (event: Laya.Event) => {
            event.stopPropagation();
        });
    }
}
