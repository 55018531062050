/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { BaseBigWindowBg } from "../../common/ui-runtime/dialog/BaseBigWindowBg";
import { PvpTroopListHeroItemUI } from "./PvpTroopListHeroItemUI";

/**
 * resources/prefab/troop/UIPvpTroopList.lh
 */
export class UIPvpTroopListBase extends Laya.Dialog {
    public win_bg!: BaseBigWindowBg;
    public btn_close!: Laya.Button;
    public listteam!: Laya.List;
    public dragItem!: PvpTroopListHeroItemUI;
}
