import { bag } from "../../../def/auto/proto";
import { ItemVo, ItemVoBase } from "./ItemVo";

export class ItemLo extends ItemVoBase<never> {
    override clone(): ItemLo {
        return new ItemLo(this._config, this._data);
    }

    override get key(): number {
        return this.id;
    }

    toVo(data?: bag.IItem): ItemVo {
        return new ItemVo(this._config, data ? (data as bag.Item) : bag.Item.create());
    }

    /** 获取gm指令 */
    public getGMCode(amount: number = 10): string {
        // if (this.isMoneyItem) {
        //     const moneyVo = app.service.user.moneyVoMap.get(this.key)!;
        //     moneyVo;
        //     const str = `add_money ${moneyVo.key} ${amount}`;
        //     // const str = `add_${moneyVo.name} ${amount}`;
        //     return str;
        // }
        const str = `add_item ${this.key} ${amount}`;
        return str;
    }
}
