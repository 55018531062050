import * as ecs from "../../../../../core/ecs";
import { IVector3Like } from "../../../../../core/laya";

export class PvpTransformComponent extends ecs.Component {
    static readonly POSITION = 1 << 0;
    static readonly ROTATION = 1 << 1;

    flag: number = 0;
    position: Laya.Vector3 = new Laya.Vector3();
    rotation: number = 0;
}

