import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { PveSvrUtils } from "../../PveSvrUtils";
import { SkillTreeEnv } from "../../ecs/components/PveSvrSkillLauncherComponent";

interface NodeArgs {
    readonly buff: number;
    readonly duration?: number;
    readonly args?: number;
    readonly limit?: number;
}

type NodeInput = [number[] | number, number, number[] | number];

/**
 * 尝试扔一个buffTree到目标身上， 上面附带buff的运行数据。
 * buffTree附加到目标身上后，会自动加载buffTree的行为树，行为树的运行环境会被设置为buffTreeEnv
 * 行为树启动后会生成最终buff data附加到目标身上，当buffTree结束后，所生成的buff data会被清理掉
 */

export class TryLaunchBuff extends Process {
    override init(node: Node) {}

    override run(node: Node, env: SkillTreeEnv): Status {
        const nodeArgs = node.args as unknown as NodeArgs;
        const input = env.input as NodeInput;
        const buffId = nodeArgs.buff;
        const targetEids = input[0];
        const duration = input[1] ?? nodeArgs.duration ?? 0;
        const args1 = input[2] ?? nodeArgs.args;
        const buffArgs = args1 instanceof Array ? args1 : [args1];
        const limit = nodeArgs.limit ?? 0;

        const eids = targetEids instanceof Array ? targetEids : [targetEids];
        if (eids.length === 0) {
            console.error("TryLaunchBuff: target is empty");
            return "failure";
        }
        if (duration === 0) {
            console.error("TryLaunchBuff: duration is invalid", duration);
            return "failure";
        }

        eids.forEach((eid) => {
            PveSvrUtils.tryLaunchBuff(
                env.context,
                buffId,
                env.skill,
                eid,
                duration,
                buffArgs,
                limit
            );
        });
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "TryLaunchBuff",
            type: "Action",
            desc: "尝试启动Buff",
            args: [
                { name: "buff", type: "int", desc: "buff id" },
                { name: "duration", type: "float?", desc: "持续时间" },
                { name: "args", type: "float?", desc: "附加参数" },
                { name: "limit", type: "int?", desc: "限制本技能产生的个数" },
            ],
            input: ["目标", "持续时间?", "附加参数?"],
            doc: `
                + 尝试给目标加载buff
                + 只能在Skill行为树中使用
                + 目标可以是单个或多个
                + 持续时间和附加参数是可选的，优先使用input传入的值
                + 如果数量为0，返回失败，否则返回成功`,
        };
    }
}
