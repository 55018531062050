import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import proto from "../../def/auto/proto";
import { UIItemUseSelect } from "./ui-runtime/UIItemUseSelect";
import { UIItemUseSelectItem } from "./ui-runtime/UIItemUseSelectItem";

const { regClass, property } = Laya;
@regClass('6CZh2tTQST2g_sUm75rY-g') //道具自选
export class UIItemUseSelectMediator extends Mediator {
    declare owner: UIItemUseSelect;

    public itemMaxCount: number = 0;
    public selItemNumList: { [key: number]: number } = {};

    //组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
    override onAwake(): void {
        super.onAwake();
        this.initEvent();
        this.initPanel();
    }

    //初始化界面信息
    initPanel() {
        const itemData = this.owner.itemData;
        const args = itemData.args as number[][];
        for (const reward of args) {
            const itemId = reward[0];
            this.selItemNumList[itemId] = 0;
        }
        this.itemMaxCount = app.service.bag.itemVoMap.getItemAmount(itemData.id);
        this.owner.listItem.array = args;
        this.owner.labCount.text = `${itemData.count}`;
        const itemLo = app.service.bag.itemLoMap.get(itemData.id);
        this.owner.iconItem.updateGoods(itemLo);
        this.owner.labelName.text = itemData.name;
        this.updateSelMax();
    }

    private updateSelMax() {
        const totalCount = this.getTotalMax();
        this.owner.labUseCount.text = `${totalCount}/${this.itemMaxCount}`;
    }

    //初始化
    initEvent() {
        this.owner.imgMask.on(Laya.Event.CLICK, this.owner, this.owner.destroy);
        this.owner.btnUse.on(Laya.Event.CLICK, this, this.onClickUse);
        this.owner.listItem.renderHandler = new Laya.Handler(this, this.updateItem);
    }

    updateItem(cell: UIItemUseSelectItem, index: number) {
        const itemData = cell.dataSource as number[];
        const itemLo = app.service.bag.itemLoMap.get(itemData[0]);
        cell.itemIcon.updateGoods(itemLo);
        cell.labName.text = itemLo.name;
        cell.imgBg.visible = index % 2 === 1;
        const num = this.selItemNumList[itemData[0]];
        const itemShowCount = num * itemData[1];
        cell.itemIcon.itemNumber = `${itemShowCount}`;
        const maxCount = this.getInputComMax(itemData[0]);
        cell.inputCom.setData(num, maxCount);
        cell.inputCom.onInputChange = (value: number) => {
            this.selItemNumList[itemData[0]] = value;
            this.updateItemSliderData();
        };
    }

    private updateItemSliderData() {
        const box = this.owner.listItem.getChildAt(0) as Laya.Box;
        for (let i = 0; i < box.numChildren; i++) {
            const item = box.getChildAt(i) as UIItemUseSelectItem;
            const itemData = item.dataSource as number[];
            if (itemData) {
                const num = this.selItemNumList[itemData[0]];
                const itemShowCount = num * itemData[1];
                item.itemIcon.itemNumber = `${itemShowCount}`;
                const maxCount = this.getInputComMax(itemData[0]);
                item.inputCom.max = maxCount;
            }
        }
        this.updateSelMax();
    }

    public getInputComMax(itemId: number) {
        const numList = this.selItemNumList;
        let selCount = 0;
        for (const key in numList) {
            if (parseInt(key) === itemId) {
                continue;
            }
            selCount = selCount + numList[key];
        }
        const remainCount = this.itemMaxCount - selCount;
        return remainCount;
    }

    public getTotalMax() {
        const numList = this.selItemNumList;
        let totalCount = 0;
        for (const key in numList) {
            totalCount = totalCount + numList[key];
        }
        return totalCount;
    }

    onClickUse() {
        const itemData = this.owner.itemData;
        const optionalList: proto.bag.OptionalChestIdx[] = [];
        const numList = this.selItemNumList;
        let slotPos = 1;
        for (const key in numList) {
            const num = numList[key];
            if (num) {
                optionalList.push({ slotIdx: slotPos, num: num });
            }
            slotPos++;
        }
        if (optionalList.length) {
            app.service.bag.requestOpenOptional({
                itemId: itemData.id,
                idxList: optionalList,
            });
            this.owner.destroy();
        } else {
            app.ui.toast("请选择物品数量");
        }
    }
}
