/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/troop/TroopHeroItem.lh
 */
export class TroopheroItemBase extends Laya.Box {
    public imgBg!: Laya.Image;
    public imgType!: Laya.Image;
    public imgIcon!: Laya.Image;
    public boxTroop!: Laya.Box;
    public labbattle!: Laya.Label;
    public boxStar!: Laya.HBox;
    public labJob!: Laya.Label;
    public labstarnum!: Laya.Label;
    public labName!: Laya.Label;
}

