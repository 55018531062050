const { regClass } = Laya;
import { UISoliderKingBase } from "./UISoliderKingBase.generated";

@regClass('UFFb-eeaTsKcdrkUSDke-Q')
export class UISoliderKing extends UISoliderKingBase {
    openIdx: number = 0;

    override open(closeOther?: boolean | undefined, param?: any): void {
        this.openIdx = param || 0;
        super.open(closeOther, param);
    }
}
