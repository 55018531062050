import { Pool } from "../../../core/pool";
import { PveNpcStateType } from "../../../def/auto/battle";
import {
    ElementCreator,
    ExplodeSpoilsData,
    RecoverHp,
    UpdateHp,
    UpdateTruck,
} from "../pve-server/PveDefs";
import { EventTrigger } from "../pve-server/ecs/components/PveSvrEventComponent";

export interface IPveReceiver {
    setClearCloudKeys(keys: string[], needClearCloudKey: string | undefined): void;

    setCtrlHeroEid(eid: number): void;

    createElement(data: ElementCreator): void;
    removeElement(eid: number): void;

    rushStart(eid: number): void;

    moveStop(eid: number, position: Laya.Vector3): void;

    dispatch(eid: number, trigger: EventTrigger): void;

    updateHp(eid: number, info: UpdateHp): void;
    recoverHp(eid: number, info: RecoverHp): void;
    updateTruck(eid: number, info: UpdateTruck): void;
    updateTruckHeadView(eid: number): void;

    setRotation(eid: number, rotation: number, immediately: boolean): void;
    setPosition(eid: number, position: Laya.Vector3): void;

    playAnim(eid: number, anim: string, bForce?: boolean): void;

    drawDebug(x: number, z: number, radius: number, color: number): void;

    grubChest(eid: number): void;

    grubSpoils(eid: number): void;

    navigationArrowPointTo(
        eid: number,
        posO: Laya.Vector3,
        targetPosA: Laya.Vector3,
        targetPosB: Laya.Vector3
    ): void;

    hideNavigationArrow(eid: number): void;

    playExplodeSpoilsAnimation(
        diePosX: number,
        diePosZ: number,
        explodeSpoilsDatas: ExplodeSpoilsData[]
    ): void;

    gameDefeat(): void;

    updateTransform(
        eid: number,
        pos: Laya.Vector3,
        rotation: number | undefined,
        quaternion?: Laya.Quaternion
    ): void;

    onHeroEnterLeaveBuildingProbingRange(buildingEid: number, isEnter: boolean): void;

    onHeroEnterLeaveNpcProbingRange(npcEid: number, isEnter: boolean, state: PveNpcStateType): void;

    setEnterLeaveTerritory(isEnter: boolean): void;

    startUpgradeBuilding(buildingEid: number, upgradeTime: number): void;

    upgradeBuildingComplete(newLevel: number, eid: number): void;

    openCollectionFactoryWin(eid: number): void;

    openMapTransferWin(eid: number): void;

    onHarvestCollectionSuccess(eid: number): void;
}

export interface IPveCommandData<T extends keyof IPveReceiver> {
    name: T;
    args: Parameters<IPveReceiver[T]>;
}

const copyV3 = (v3: Laya.Vector3) => {
    const v = Pool.obtain(Laya.Vector3);
    v.cloneFrom(v3);
    return v;
};

const copyQ4 = (q4: Laya.Quaternion | undefined) => {
    if (!q4) return undefined;
    const q = Pool.obtain(Laya.Quaternion);
    q.set(q4.x, q4.y, q4.z, q4.w);
    return q;
};

export class PveReceiver implements IPveReceiver {
    constructor(private _commands: IPveCommandData<keyof IPveReceiver>[]) {}

    ///////////////////////

    private _push<T extends keyof IPveReceiver>(cmd: IPveCommandData<T>) {
        this._commands.push(cmd);
    }

    setClearCloudKeys(keys: string[], needClearCloudKey: string | undefined): void {
        this._push({ name: "setClearCloudKeys", args: [keys, needClearCloudKey] });
    }

    setCtrlHeroEid(eid: number) {
        this._push({ name: "setCtrlHeroEid", args: [eid] });
    }

    createElement(data: ElementCreator) {
        if (data.position) {
            data.position = copyV3(data.position);
        }
        this._push({ name: "createElement", args: [data] });
    }

    removeElement(eid: number) {
        this._push({ name: "removeElement", args: [eid] });
    }

    rushStart(eid: number) {
        this._push({ name: "rushStart", args: [eid] });
    }

    moveStop(eid: number, position: Laya.Vector3) {
        this._push({ name: "moveStop", args: [eid, copyV3(position)] });
    }

    dispatch(eid: number, trigger: EventTrigger): void {
        this._push({ name: "dispatch", args: [eid, trigger] });
    }

    updateHp(eid: number, info: UpdateHp): void {
        this._push({ name: "updateHp", args: [eid, info] });
    }

    recoverHp(eid: number, info: RecoverHp): void {
        this._push({ name: "recoverHp", args: [eid, info] });
    }

    updateTruck(eid: number, info: UpdateTruck): void {
        this._push({ name: "updateTruck", args: [eid, info] });
    }

    updateTruckHeadView(eid: number): void {
        this._push({ name: "updateTruckHeadView", args: [eid] });
    }

    setRotation(eid: number, rotation: number, immediately: boolean): void {
        this._push({ name: "setRotation", args: [eid, rotation, immediately] });
    }

    setPosition(eid: number, position: Laya.Vector3): void {
        this._push({ name: "setPosition", args: [eid, copyV3(position)] });
    }

    playAnim(eid: number, anim: string, bForce?: boolean): void {
        this._push({ name: "playAnim", args: [eid, anim, bForce] });
    }

    getCommands() {
        return this._commands;
    }

    drawDebug(x: number, z: number, radius: number, color: number): void {
        this._push({ name: "drawDebug", args: [x, z, radius, color] });
    }

    grubChest(eid: number): void {
        this._push({ name: "grubChest", args: [eid] });
    }

    grubSpoils(eid: number): void {
        this._push({ name: "grubSpoils", args: [eid] });
    }

    navigationArrowPointTo(
        eid: number,
        posO: Laya.Vector3,
        targetPosA: Laya.Vector3,
        targetPosB: Laya.Vector3
    ): void {
        this._push({
            name: "navigationArrowPointTo",
            args: [eid, copyV3(posO), copyV3(targetPosA), copyV3(targetPosB)],
        });
    }

    hideNavigationArrow(eid: number): void {
        this._push({ name: "hideNavigationArrow", args: [eid] });
    }

    playExplodeSpoilsAnimation(
        diePosX: number,
        diePosZ: number,
        explodeSpoilsDatas: ExplodeSpoilsData[]
    ): void {
        this._push({
            name: "playExplodeSpoilsAnimation",
            args: [diePosX, diePosZ, explodeSpoilsDatas],
        });
    }

    gameDefeat(): void {
        this._push({ name: "gameDefeat", args: [] });
    }

    updateTransform(
        eid: number,
        pos: Laya.Vector3,
        rotation: number | undefined,
        quaternion: Laya.Quaternion | undefined
    ): void {
        this._push({
            name: "updateTransform",
            args: [eid, copyV3(pos), rotation, copyQ4(quaternion)],
        });
    }

    onHeroEnterLeaveBuildingProbingRange(buildingEid: number, isEnter: boolean): void {
        this._push({
            name: "onHeroEnterLeaveBuildingProbingRange",
            args: [buildingEid, isEnter],
        });
    }

    onHeroEnterLeaveNpcProbingRange(
        npcEid: number,
        isEnter: boolean,
        state: PveNpcStateType
    ): void {
        this._push({
            name: "onHeroEnterLeaveNpcProbingRange",
            args: [npcEid, isEnter, state],
        });
    }

    setEnterLeaveTerritory(isEnter: boolean): void {
        this._push({
            name: "setEnterLeaveTerritory",
            args: [isEnter],
        });
    }

    startUpgradeBuilding(buildingEid: number, upgradeTime: number): void {
        this._push({
            name: "startUpgradeBuilding",
            args: [buildingEid, upgradeTime],
        });
    }

    upgradeBuildingComplete(newLevel: number, eid: number): void {
        this._push({
            name: "upgradeBuildingComplete",
            args: [newLevel, eid],
        });
    }

    openCollectionFactoryWin(eid: number): void {
        this._push({
            name: "openCollectionFactoryWin",
            args: [eid],
        });
    }

    openMapTransferWin(eid: number): void {
        this._push({
            name: "openMapTransferWin",
            args: [eid],
        });
    }

    onHarvestCollectionSuccess(eid: number): void {
        this._push({
            name: "onHarvestCollectionSuccess",
            args: [eid],
        });
    }
}
