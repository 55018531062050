const { regClass } = Laya;
import { PveTruckComponent } from "../../pve/ecs/components/PveTruckComponent";
import { PveUITruckHudComponent } from "../../pve/ecs/components/PveUITruckHudComponent";
import { TruckHeadInfoBase } from "./TruckHeadInfo.generated";

@regClass('USnKKSVXQ-KWgUh-k7Be2g')
export class TruckHeadInfo extends TruckHeadInfoBase {
    override onEnable(): void {}

    update(data: PveUITruckHudComponent): void {
        const truckComp = data.getComponent(PveTruckComponent)!;
        this.updateWithCollectCnt(truckComp.collectCnt);
    }

    updateWithCollectCnt(count: number): void {
        this.amountText.text = count + "";
    }
}
