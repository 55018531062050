const { regClass } = Laya;
import { HomeTaskBoxBase } from "./HomeTaskBox.generated";

@regClass('f7bOPxHoTZuFp5D_c5B0ag')
export class HomeTaskBox extends HomeTaskBoxBase {
    enableCom() {
        this.components.forEach((val: Laya.Component) => {
            val.enabled = true;
        });
    }

    stopCom() {
        this.components.forEach((val: Laya.Component) => {
            val.enabled = false;
        });
    }
}
