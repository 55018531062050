import { app } from "../../app";
import { Service } from "../../core/service";
import { UIAlertArgs } from "../../core/ui-manager";
import { ChatConf } from "../../def/auto/chat";
import { BagType } from "../../def/auto/item";
import { user } from "../../def/auto/proto";
import { opcode } from "../../def/auto/protocol";
import { TaskType } from "../../def/auto/task";
import { SystemEvent } from "../../misc/system-event";
import { ui } from "../../misc/ui";

export const LOGIN_USERNAME = "login:username";
export const LOGIN_URL = "login:url";
export const LOGIN_DOMAIN = "login:damain";
export const LOGIN_SERVER_SN = "login:server.sn";

type ConnectAction = "NONE" | "CONNECT" | "RECONNECT" | "SWITCH";

export class LoginService extends Service {
    private _connectAction: ConnectAction = "NONE";
    private _retryCount: number = 0;
    private maxRetryCount: number = 15;
    private retryDelayTime: number = 2;
    private _needReconnect: boolean = false;

    override onCreate(): void {
        this.handle(opcode.connection.connected, this.onConnected);
        this.handle(opcode.connection.ioerror, this.onConnectError);
        this.handle(opcode.connection.disconnected, this.onDisconnected);
    }

    override onStartInit(): void {}

    override onDestroy(): void {}

    get username() {
        return Laya.LocalStorage.getItem(LOGIN_USERNAME) || "";
    }

    set username(value: string) {
        Laya.LocalStorage.setItem(LOGIN_USERNAME, value);
    }

    get url() {
        return Laya.LocalStorage.getItem(LOGIN_URL) || "";
    }

    set url(value: string) {
        Laya.LocalStorage.setItem(LOGIN_URL, value);
    }

    get domain() {
        return Laya.LocalStorage.getItem(LOGIN_DOMAIN) || "";
    }

    set domain(value: string) {
        Laya.LocalStorage.setItem(LOGIN_DOMAIN, value);
    }

    get serverSn() {
        return Laya.LocalStorage.getItem(LOGIN_SERVER_SN) || "";
    }

    set serverSn(value: string) {
        Laya.LocalStorage.setItem(LOGIN_SERVER_SN, value);
    }

    /** 网络断开连接 */
    private onDisconnected(): void {
        // console.log("onDisconnected");
        this._connectAction = "RECONNECT";
        this._needReconnect = true;
        this._retryCount = 0;
        app.ui.show(ui.LOGIN_RECONNECT);
        Laya.timer.loop(this.retryDelayTime * 2000, this, this._tryReconnect);
    }

    private onConnectError(): void {
        this._needReconnect = true;
    }

    private async onConnected() {
        Laya.timer.clear(this, this._tryReconnect);
        this._retryCount = 0;

        // 登录获取玩家信息
        await app.service.network.call(
            user.c2s_login.create({
                account: this.username,
            }),
            user.s2c_login
        );

        if (this._connectAction === "RECONNECT") {
            app.event(SystemEvent.RECONNECT_LOGIN_SUCCESS);
        } else if (this._connectAction === "SWITCH") {
            await this.load();
            app.event(SystemEvent.SWITCH_PVP_SUCCESS);
        } else {
            // 打开Loading
            if (app.service.user.rid > 0) {
                app.ui.replace(ui.LOADING_SCENE);
            } else {
                app.ui.replace(ui.CREATOR_ROLE_SCENE);
            }
        }

        this._connectAction = "NONE";
    }

    /** 尝试重新连接 */
    private _tryReconnect(): void {
        if (this._retryCount >= this.maxRetryCount) {
            app.event(SystemEvent.RECONNECT_FAIL);
            Laya.timer.clear(this, this._tryReconnect);
            this._retryCount = 0;
            this._connectAction = "NONE";
            const callBack = this.onYesOrNo.bind(this);
            // 提示重连失败
            const args: UIAlertArgs = {
                title: "提示",
                content: "经过多次尝试, 无法连接到服务器，请确保网络正常后重新进入游戏！",
                yes: callBack,
                no: callBack,
            };
            app.ui.alert(args);
            return;
        }
        if (this._needReconnect) {
            this._retryCount = this._retryCount + 1;
            this._needReconnect = false;
            app.service.network.connect(app.service.login.url);
        }
    }

    private onYesOrNo(): void {
        // TODO: 改成直接打开登录界面
        window.location.reload();
    }

    connect(url: string) {
        this.url = url;
        this._connectAction = "CONNECT";
        app.service.network.connect(url);
    }

    switchToPvp(url: string) {
        this.url = url;
        this._connectAction = "SWITCH";
        app.service.network.connect(url);
    }

    // ------------------------------------------------------------------------
    // rpc call
    // ------------------------------------------------------------------------
    async load() {
        return Promise.all([
            app.service.bag.load({ bagId: BagType.ITEM }),
            app.service.bag.load({ bagId: BagType.CHEST }),
            app.service.chest.load(),
            app.service.task.load({ taskType: TaskType.MAIN }),
            app.service.task.load({ taskType: TaskType.BRANCH }),
            app.service.user.loadMonye(),
            app.service.mail.load(),
            app.service.chat.load({ channel: ChatConf.CHAT_CHANNEL.WORLD }),
            app.service.chest.load(),
            app.service.alliance.load(),
            app.service.hero.load(),
            app.service.trainCamp.load(),
            app.service.skill.load(),
            app.service.leaderSkin.load(),
            app.service.drawCard.load(),
        ]);
    }
}
