/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIList } from "../../../../core/ui/UIList";
import { CharacterBtnSkill } from "./CharacterBtnSkill";
import { UIHBoxStar } from "../UIHBoxStar";
import { CharacterMatDispBox } from "./CharacterMatDispBox";

/**
 * resources/prefab/character/detail/UICharacterDetail.lh
 */
export class UICharacterDetailBase extends Laya.Dialog {
    public imgResIcon0!: Laya.Image;
    public lblResValue0!: Laya.Label;
    public boxCharacter!: Laya.Box;
    public imgCharacter!: Laya.Image;
    public imgJobBg!: Laya.Image;
    public lblJob!: Laya.Label;
    public lblName!: Laya.Label;
    public imgStar0!: Laya.Image;
    public imgStar1!: Laya.Image;
    public imgStar2!: Laya.Image;
    public imgStar3!: Laya.Image;
    public imgStar4!: Laya.Image;
    public btnLeft!: Laya.Button;
    public btnRight!: Laya.Button;
    public btnRebuild!: Laya.Button;
    public btnLllusion!: Laya.Button;
    public listDetailTab!: UIList;
    public btnClose!: Laya.Button;
    public boxUpgrade!: Laya.Box;
    public imgLv!: Laya.Image;
    public lblLv!: Laya.Label;
    public imgAttr!: Laya.Image;
    public lblAtkValue!: Laya.Label;
    public lblHpValue!: Laya.Label;
    public lblDefendValue!: Laya.Label;
    public boxSkill!: Laya.Box;
    public btnSkill0!: CharacterBtnSkill;
    public btnSkill1!: CharacterBtnSkill;
    public btnSkill2!: CharacterBtnSkill;
    public btnSkill3!: CharacterBtnSkill;
    public boxResource!: Laya.Box;
    public imgUpgradeIcon!: Laya.Image;
    public lblUpgradeResource!: Laya.Label;
    public lblMaxLevel!: Laya.Label;
    public btnUpgrade!: Laya.Button;
    public boxUpStar!: Laya.Box;
    public preStarHBox!: UIHBoxStar;
    public imgPreStar4!: Laya.Image;
    public imgPreStar2!: Laya.Image;
    public imgPreStar0!: Laya.Image;
    public imgPreStar1!: Laya.Image;
    public imgPreStar3!: Laya.Image;
    public nextStarHBox!: UIHBoxStar;
    public imgNextStar4!: Laya.Image;
    public imgNextStar2!: Laya.Image;
    public imgNextStar0!: Laya.Image;
    public imgNextStar1!: Laya.Image;
    public imgNextStar3!: Laya.Image;
    public btnUpStarTips!: Laya.Button;
    public lblNextStar!: Laya.Label;
    public boxStarMatSame!: CharacterMatDispBox;
    public boxStarMatStar!: CharacterMatDispBox;
    public btnUpStar!: Laya.Button;
}

