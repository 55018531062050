/* eslint-disable @typescript-eslint/no-unsafe-member-access */
export const btreeCode = [
    {code: "(current_time - fight_time) > 2", evaluator: ($env: any) => ($env.current_time - $env.fight_time) > 2},
    {code: "buff_arg1 / 100", evaluator: ($env: any) => $env.buff_arg1 / 100},
    {code: "cur_hp * 100 / max_hp < skill_arg0", evaluator: ($env: any) => $env.cur_hp * 100 / $env.max_hp < $env.skill_arg0},
    {code: "cur_hp / max_hp * 100 < skill_arg0", evaluator: ($env: any) => $env.cur_hp / $env.max_hp * 100 < $env.skill_arg0},
    {code: "distance > 4", evaluator: ($env: any) => $env.distance > 4},
    {code: "distance > 5", evaluator: ($env: any) => $env.distance > 5},
    {code: "hurt_value * buff_arg0 / 100", evaluator: ($env: any) => $env.hurt_value * $env.buff_arg0 / 100},
    {code: "is_fighting != last_is_fighting", evaluator: ($env: any) => $env.is_fighting != $env.last_is_fighting},
    {code: "soldier_group != self", evaluator: ($env: any) => $env.soldier_group != $env.self},
    {code: "start_wheel_flag", evaluator: ($env: any) => $env.start_wheel_flag},
    {code: "(a.x - b.y) > c - b * c", evaluator: ($env: any) => ($env.a.x - $env.b.y) > $env.c - $env.b * $env.c},
];