const { regClass } = Laya;
import { AllianceAppointPostBase } from "./AllianceAppointPost.generated";

@regClass('NGvdIQVyRl-UY8MJ9IgaOQ')
export class AllianceAppointPost extends AllianceAppointPostBase implements Laya.ISelect {
    private _selected: boolean = false;
    private _clickHandler?: Laya.Handler | undefined;

    override onAwake(): void {
        super.onAwake();
        this.onClick(() => {
            this._clickHandler?.run();
        });
    }

    public get selected(): boolean {
        return this._selected;
    }

    public set selected(value: boolean) {
        this._selected = value;
        this.selectedBox.visible = value;
    }

    public get clickHandler(): Laya.Handler {
        return this._clickHandler!;
    }

    public set clickHandler(value: Laya.Handler) {
        this._clickHandler = value;
    }
}
