import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { SkillTreeEnv } from "../../ecs/components/PveSvrSkillLauncherComponent";
import { AnimNameOptions } from "../BtreeDef";

interface NodeArgs {
    readonly name: string;
}

export class PlayAnim extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        if (!(env instanceof SkillTreeEnv)) {
            node.error(`env is not a SkillTreeEnv`);
        }
        const args = node.args as unknown as NodeArgs;
        env.context.playAnim(env.owner.eid, args.name);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "PlayAnim",
            type: "Action",
            desc: "播放动画",
            args: [{ name: "name", type: "enum", desc: "动画名", options: AnimNameOptions }],
        };
    }
}
