import { InterpolationRate } from "../../../pve-server/ecs/components/PveSvrMovementComponent";
import { PveElementComponent } from "../components/PveElementComponent";
import { PveTransformComponent } from "../components/PveTransformComponent";

export class PveMoveUtils {
    static calcuRotation(x: number, z: number): number {
        const rad = Math.atan2(x, z);
        const dest = (rad * 180) / Math.PI;
        return dest;
    }

    static setRotation(character: PveElementComponent, rotation: number, immediately?: boolean) {
        const { transform } = character;
        if (immediately) {
            transform.rotationInterpolation.percent = 1;
            transform.rotation = rotation;
            transform.flag |= PveTransformComponent.ROTATION;
        } else {
            let offset = rotation - transform.rotation;
            // rotation 和 transform.rotation 都是0-360之间的角度
            // 计算最小旋转角度
            if (offset > 180) {
                offset -= 360;
            } else if (offset < -180) {
                offset += 360;
            }
            transform.rotationInterpolation.percent = 0;
            transform.rotationInterpolation.rate = InterpolationRate.ROTATION;
            transform.rotationInterpolation.rotation = offset;
            transform.flag &= ~PveTransformComponent.ROTATION;
            transform.flag &= ~PveTransformComponent.ROTATION_QUAT;
        }
    }
}
