const { regClass } = Laya;
import { P1v1UIBase } from "./P1v1UI.generated";

export type P1v1UIArgs = {
    battleUid?: number;
    url?: string;
};

@regClass('m1ftnCMWShCX7aofUtrZYQ')
export class P1v1UI extends P1v1UIBase {
    args!: P1v1UIArgs;

    override get scene3D(): Laya.Scene3D {
        return this._scene3D;
    }

    override open(closeOther?: boolean | undefined, param?: P1v1UIArgs) {
        this.args = param!;
        super.open(closeOther, param);
    }
}
