import { app } from "../../app";
import { Service } from "../../core/service";
import proto from "../../def/auto/proto";
import { errcode, opcode } from "../../def/auto/protocol";
import { SystemEvent } from "../../misc/system-event";
import { HeroVo } from "../hero/vo/HeroVo";
import { TroopTeamVo } from "./vo/TroopTeamVo";
import { TroopTeamVoMap } from "./vo/TroopTeamVoMap";
export class TroopService extends Service {
    public troopTeamVoMap = new TroopTeamVoMap();
    public curEditTeam: proto.troop.Troop | null = null;
    // public mainTroopIdx: number = 0;

    override onCreate() {
        this.handle(opcode.troop.s2c_load, this._onLoad);
        this.handle(opcode.troop.notify_troop_update, this._updateTroop);
        this.handle(opcode.troop.s2c_edit_troop, this._onEditTroop);
    }

    override onStartInit(): void {}

    private _onLoad(data: proto.troop.s2c_load) {
        if (data.err === errcode.OK) {
            this.troopTeamVoMap.clear();
            for (const voData of data.troopList) {
                const troopNew = new TroopTeamVo(voData as proto.troop.Troop);
                this.troopTeamVoMap.set(troopNew.key, troopNew);
            }
        }
        app.event(SystemEvent.UI.TROOP_INIT);
    }

    private _onEditTroop(data: proto.troop.s2c_edit_troop, request: proto.troop.c2s_edit_troop) {
        if (request.troop && request.troop.leaderSkinId === 0) {
            app.ui.toast("已卸下幻化");
            return;
        }
        app.ui.toast("编辑部队成功");
    }

    private _updateTroop(data: proto.troop.notify_troop_update) {
        for (const troop of data.troopList) {
            const troopNew = new TroopTeamVo(troop as proto.troop.Troop);
            this.troopTeamVoMap.set(troopNew.key, troopNew);
            this.curEditTeam = proto.troop.Troop.create(troop);
        }
        app.event(SystemEvent.UI.TROOP_EDIT);
        app.event(SystemEvent.UI.TROOP_INSTALL_SKIN);
    }

    // 根据小兵数据
    public getHeroDataByuid(uid: number): HeroVo {
        const heroData = app.service.hero.heroVoMap.get(uid)!;
        return heroData;
    }

    //获取小兵显示哪个队伍的上阵
    public getSoliderBattleTeam(soliderData: HeroVo) {
        let teamName = "";
        const teamIdx = this.getTeamIdxBySoliderIdx(soliderData.uid);
        if (teamIdx) {
            teamName = this.getbattleName(teamIdx);
            return teamName;
        }
        return teamName;
    }

    //获取队伍的队伍idx
    public getTeamIdxBySoliderIdx(uid: number) {
        const troopTeamArray = this.troopTeamVoMap.toArray();
        for (let i = 0; i < troopTeamArray.length; i++) {
            const teamData = troopTeamArray[i];
            const soliderData = teamData.heros!.find((v) => v.uid === uid);
            if (soliderData) {
                return teamData.idx;
            }
        }
        return 0;
    }

    public getTeamIdBySkinId(leaderSkinId: number): number {
        const troopTeamArray = this.troopTeamVoMap.toArray();
        for (let i = 0; i < troopTeamArray.length; i++) {
            const teamData = troopTeamArray[i];
            if (teamData.leaderskinid === leaderSkinId) {
                return teamData.idx;
            }
        }
        return 0;
    }

    public getTeamArrIdx(uid: number) {
        const pvpTeamList = this.getPvpTeamList();
        for (let i = 0; i < pvpTeamList.length; i++) {
            const teamData = pvpTeamList[i];
            const soliderData = teamData.heros!.find((v) => v.uid === uid);
            if (soliderData) {
                return i;
            }
        }
        return 0;
    }

    oneKeyEditSoldier() {
        if (!this.curEditTeam) {
            return;
        }
        const soliderList = app.service.hero.getTroopSoliderListSort([]); //战力排序后直接前三上阵
        let index = 0;
        for (const soliderData of soliderList) {
            if (index === 3) {
                break;
            }
            if (!this.getTeamIdxBySoliderIdx(soliderData.uid)) {
                this.updateEditTroop(index + 1, soliderData);
                index++;
            }
        }
    }

    onkeyOffSolider() {
        if (this.curEditTeam) {
            for (let i = 1; i < 4; i++) {
                const heroData = proto.troop.Hero.create();
                this.curEditTeam.heros![i] = heroData;
            }
        }
    }

    //检测是否一键下阵，当无小兵时避免
    checkTroopCanOff() {
        for (let i = 1; i < 4; i++) {
            if (this.curEditTeam?.heros[i]) {
                return true;
            }
        }
        return false;
    }

    public troopUp(soliderData: HeroVo) {
        const findIdx = this.getTroopEmptyIdx();
        if (findIdx !== -1) {
            this.curEditTeam!.heros![findIdx] = { maxHp: soliderData.maxHp, uid: soliderData.uid };
        }
    }

    public dragTroopUp(soliderData: HeroVo, dragIdx: number) {
        this.curEditTeam!.heros![dragIdx] = { maxHp: soliderData.maxHp, uid: soliderData.uid };
    }

    public troopOff(soliderData: HeroVo) {
        const findIdx = this.curEditTeam!.heros!.findIndex((v) => v.uid === soliderData.uid);
        if (findIdx !== -1) {
            const heroData = proto.troop.Hero.create();
            this.curEditTeam!.heros![findIdx] = heroData;
        }
    }

    public getTroopEmptyIdx() {
        const troop = this.curEditTeam!;
        for (let i = 0; i < troop.heros!.length; i++) {
            const heroData = troop.heros![i];
            if (!heroData.uid) {
                return i;
            }
        }
        return -1;
    }

    //打开编辑界面时赋值
    public initEditTroop(editTroop: TroopTeamVo) {
        const newHeros: proto.troop.Hero[] = [];
        for (const hero of editTroop.heros) {
            newHeros.push({ uid: hero.uid, maxHp: hero.maxHp });
        }
        this.curEditTeam = proto.troop.Troop.create({
            idx: editTroop.idx,
            eid: editTroop.eid,
            heros: newHeros,
            hps: editTroop.hp,
            leaderSkinId: editTroop.leaderskinid,
        });
    }

    public updateEditTroop(position: number, soliderData: HeroVo) {
        if (this.curEditTeam) {
            this.curEditTeam.heros![position].maxHp = soliderData.maxHp;
            this.curEditTeam.heros![position].uid = soliderData.uid;
        }
    }

    //皮肤幻化
    public async requestTroopLeaderSkin(troopId: number, skinId: number) {
        const troopTeamVo = this.troopTeamVoMap.get(troopId)!;
        return await app.service.network.call(
            proto.troop.c2s_edit_troop.create({
                troop: {
                    idx: troopTeamVo.idx,
                    eid: troopTeamVo.eid,
                    heros: troopTeamVo.heros,
                    hps: troopTeamVo.hp,
                    leaderSkinId: skinId,
                },
            }),
            proto.troop.notify_troop_update
        );
    }

    public async requestExchangSolider(data: proto.troop.Ic2s_exchange_soldier) {
        return await app.service.network.call(
            proto.troop.c2s_exchange_soldier.create(data),
            proto.troop.notify_troop_update
        );
    }

    public async requestEditTroop() {
        const data = { troop: this.curEditTeam };
        return await app.service.network.call(
            proto.troop.c2s_edit_troop.create(data),
            proto.troop.notify_troop_update
        );
    }

    // //主线编队数据
    // public getMainTroop(): TroopTeamVo {
    //     const mainTroopIdx = this.mainTroopIdx;
    //     const mainTroop = this.troopTeamVoMap.get(mainTroopIdx);
    //     return mainTroop!;
    // }

    //根据uid获取编队数据
    public getTroopDataByUid(uid: number): TroopTeamVo | undefined {
        const mainTroop = this.troopTeamVoMap.get(uid);
        return mainTroop;
    }

    //pvp编队数据
    public getPvpTeamList() {
        const pveTeamList = this.troopTeamVoMap.toArray();
        return pveTeamList;
    }

    public getbattleName(teamIdx: number) {
        if (teamIdx === this.curEditTeam!.idx) {
            return "已上阵";
        } else {
            // if (teamIdx === this.mainTroopIdx) {
            //     return "主线队伍";
            // } else {
            return "队伍" + teamIdx;
            //}
        }
    }

    public getTeamTroop(uid: number) {}

    async load() {
        return await app.service.network.call(proto.troop.c2s_load.create(), proto.troop.s2c_load);
    }

    override onDestroy() {}
}
