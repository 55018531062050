import * as ecs from "../../../../../core/ecs";
import { res } from "../../../../../misc/res";
import { BDUpgradeInfoUI } from "../../../ui-runtime/pve/build/BDUpgradeInfoUI";

/**
 * 建筑建造/升级中进度显示
 */
export class PveUIBDUpGradeComponent extends ecs.Component {
    readonly mounter: Laya.Sprite = new Laya.Sprite();

    view?: BDUpgradeInfoUI | null;

    buildingTid: number = 0;

    upgradeStartTime: number = 0;

    upgradeEndTime: number = 0;

    /** 资源路径 */
    public get res(): string {
        return res.BATTLE_BD_UPGRADE_CONFIRM;
    }
}
