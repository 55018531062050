const { regClass } = Laya;
import { app } from "../../../app";
import { StringUtil } from "../../../core/utils/string-util";
import { HeroFameStageRow, RoleLevelRow } from "../../../def/table";
import { res } from "../../../misc/res";
import { SoliderKingRewardItemBase } from "./SoliderKingRewardItem.generated";

export type SoliderKingRewardItemUIArgs =
    | {
          type: "solder_king";
          data: RoleLevelRow;
      }
    | {
          type: "character_fame";
          data: {
              conf: HeroFameStageRow;
              bGot: boolean;
              showBar: boolean;
              ratio: number;
          };
      };

@regClass('HbwaRYSJSRGLfiJkdqcsFw')
export class SoliderKingRewardItem extends SoliderKingRewardItemBase {
    updateInfo(args: SoliderKingRewardItemUIArgs, zOrder: number) {
        this.zOrder = zOrder;
        if (args.type === "solder_king") {
            this.updateSoliderKingInfo(args.data);
        } else if (args.type === "character_fame") {
            this.updateCharacterFrameInfo(
                args.data.conf,
                args.data.bGot,
                args.data.showBar,
                args.data.ratio
            );
        }
    }

    private updateSoliderKingInfo(data: RoleLevelRow) {
        // this.imgLvIcon.skin = data.icon;
        this.lblLevel.text = `${data.lv}级`;
        if (!data.reward) {
            this.imgItem.visible = false;
        } else {
            this.imgItem.visible = true;
            const configId = data.reward[0].id;
            const itemLo = app.service.bag.itemLoMap.get(configId);
            this.imgItem.skin = itemLo.iconUrl;
            this.lblCount.text = data.reward[0].num.toString();
        }

        const nextCfg = app.service.table.role.level[data.lv];
        let nextExp = 0;
        if (nextCfg) {
            nextExp = nextCfg.upgrade_exp;
        }
        this.boxBar.visible = nextCfg !== undefined;
        if (app.service.user.profileInfo.lv >= data.lv + 1) {
            this.imgBar.value = 1;
        } else {
            this.imgBar.value = app.service.user.profileInfo.exp / nextExp;
        }
        this.boxExp.visible = app.service.user.profileInfo.lv + 1 === data.lv;
        this.lblExp.text = data.upgrade_exp.toString();
        const receivedLv = app.service.user.commonInfo?.receivedLv || 0;
        this.imgGou.visible = this.imgGet.visible = receivedLv >= data.lv;
        this.imgSel.visible = app.service.user.profileInfo.lv >= data.lv && receivedLv < data.lv;
        this.imgRedpoint.visible =
            app.service.user.profileInfo.lv >= data.lv && receivedLv < data.lv;
        this.boxIcon.gray = this.boxLevel.gray = app.service.user.profileInfo.lv < data.lv;
        this.imgIcon.skin = StringUtil.format(res.SOLIDER_KING_ICON, data.icon);
    }

    private updateCharacterFrameInfo(
        data: HeroFameStageRow,
        bGot: boolean,
        showBar: boolean,
        ratio: number
    ) {
        this.imgRedpoint.visible = false;

        this.imgGet.visible = this.imgGou.visible = bGot;

        this.lblLevel.text = `${data.require_fame}`;

        this.boxExp.active = this.boxExp.visible = false;

        const reward = data.reward[0];
        const itemLo = app.service.bag.itemLoMap.get(reward.id);
        this.imgItem.skin = itemLo.iconUrl;
        this.lblCount.text = `${reward.num}`;

        this.boxBar.active = this.boxBar.visible = showBar;
        this.imgBar.value = ratio;
    }
}
