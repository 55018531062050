import { ConsoleLogger } from "./ConsoleLogger";
import { ILogger, IPureLogger, LogLevel } from "./ILogger";
import { LoggerConfig } from "./LoggerConfig";

export class LoggerManager {
    private static loggers: Map<string, ILogger> = new Map<string, ILogger>();

    private static logImpl: IPureLogger = console;
    private static logLevel: LogLevel = LogLevel.All;

    static Init(logLevel: LogLevel, logImpl: IPureLogger = console) {
        LoggerManager.logLevel = logLevel;
        LoggerManager.logImpl = logImpl;
        LoggerManager.loggers.forEach((logger) => {
            logger.updateLogLevel(logLevel);
            logger.updateLogImpl(logImpl);
        });
    }

    static updateLogLevel(logLevel: LogLevel) {
        LoggerManager.logLevel = logLevel;
        LoggerManager.loggers.forEach((logger) => {
            logger.updateLogLevel(logLevel);
        });
    }

    static GetLogger(tag?: string): ILogger {
        tag = LoggerConfig.GetLogTag(tag);
        let logger = LoggerManager.loggers.get(tag);
        if (logger) {
            return logger;
        }

        logger = new ConsoleLogger().init({
            logImpl: LoggerManager.logImpl,
            logLevel: LoggerManager.logLevel,
            tag: tag,
        });
        LoggerManager.loggers.set(tag, logger);
        return logger;
    }
}

export const logger = LoggerManager.GetLogger();
