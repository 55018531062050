import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { RecruitPoolDetailsRow } from "../../def/table";
import { IconUI } from "../../misc/ui-runtime/IconUI";
import { ItemLo } from "../bag/vo/ItemLo";
import { UIDrawCardPreview } from "./ui-runtime/UIDrawCardPreview";

const { regClass, property } = Laya;

@regClass('BZ8kX6dBT82mAH7_xhmLAA')
export class UIDrawCardPreViewMediator extends Mediator {
    //declare owner : Laya.Sprite3D;
    declare owner: UIDrawCardPreview;

    @property(String)
    public text: string = "";

    //组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
    override onAwake(): void {
        super.onAwake();
        this.initUIEvent();
        this.initPanel();
    }

    private initUIEvent() {
        this.owner.listReward.renderHandler = new Laya.Handler(this, this.onListRender);
        this.owner.imgMask.on(Laya.Event.CLICK, this.owner, this.owner.destroy);
    }

    onListRender(cell: IconUI, index: number) {
        const itemVo = cell.dataSource as ItemLo;
        cell.updateGoods(itemVo);
    }

    private initPanel() {
        this.owner.bgwin.setTitle("招募预览");
        const poolId = this.owner.poolId;
        const arrayData: RecruitPoolDetailsRow[][] = app.service.table.recruit.pool_detail[poolId];
        const itemLoList: ItemLo[] = [];
        for (const cfgList of arrayData) {
            for (const cfg of cfgList) {
                const itemLo = app.service.bag.itemLoMap.get(cfg.item_id);
                itemLoList.push(itemLo);
            }
        }
        itemLoList.sort((a, b) => {
            if (a.quality !== b.quality) {
                return b.quality - a.quality;
            } else {
                return b.id - a.id;
            }
        });
        this.owner.listReward.array = itemLoList;
    }

    //组件被启用后执行，例如节点被添加到舞台后
    //onEnable(): void {}

    //组件被禁用时执行，例如从节点从舞台移除后
    //onDisable(): void {}

    //第一次执行update之前执行，只会执行一次
    //onStart(): void {}

    //手动调用节点销毁时执行
    //onDestroy(): void {}

    //每帧更新时执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onUpdate(): void {}

    //每帧更新时执行，在update之后执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onLateUpdate(): void {}

    //鼠标点击后执行。与交互相关的还有onMouseDown等十多个函数，具体请参阅文档。
    //onMouseClick(): void {}
}
