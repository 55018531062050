import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import proto from "../../def/auto/proto";
import { ImageRef } from "../../misc/ImageRef";
import { SystemEvent } from "../../misc/system-event";
import { PvpTroopListHeroItemUI } from "../troop/ui-runtime/PvpTroopListHeroItemUI";
import { TroopTeamVo } from "../troop/vo/TroopTeamVo";
import { LeaderSkinTeamItem } from "./ui-runtime/LeaderSkinTeamItem";
import { UILeaderSkinTeam } from "./ui-runtime/UILeaderSkinTeam";

const { regClass, property } = Laya;

@regClass('QY4m8DcTSVeaKM9_TNc2Zg')
export class UILeaderSkinTeamMediator extends Mediator {
    declare owner: UILeaderSkinTeam;
    private curSelItem: LeaderSkinTeamItem | undefined;

    //组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
    override onAwake(): void {
        super.onAwake();
        this.initUIEvent();
        this.initServiceEvent();
        this.initInfo();
    }

    private initUIEvent() {
        this.owner.listTeam.renderHandler = new Laya.Handler(this, this.onTeamListRender);
        this.owner.listTeam.repeatY = 3;
        this.owner.listTeam.elasticEnabled = true;

        this.owner.imgMask.on(Laya.Event.CLICK, this.owner, this.owner.destroy);
        this.owner.btnsure.on(Laya.Event.CLICK, this, this.onClickSure);
    }

    private initServiceEvent() {
        this.$(app).on(SystemEvent.UI.TROOP_INSTALL_SKIN, this.updateList, this);
    }

    private onClickSure() {
        const cfgData = this.owner.cfgData;
        if (!cfgData) {
            return;
        }
        const selectedIndex = this.owner.listTeam.selectedIndex;
        if (selectedIndex <= -1) {
            app.ui.toast("当前未选择幻化的队伍");
            return;
        }
        const teamData = this.owner.listTeam.array[selectedIndex] as TroopTeamVo;
        app.service.troop.requestTroopLeaderSkin(teamData.idx, cfgData.id);
    }

    private initInfo() {
        this.updateList();
    }

    private updateList() {
        app.ui.toast("已幻化");
        const troopList = app.service.troop.getPvpTeamList();
        this.owner.listTeam.array = troopList;
    }

    private onTeamListRender(item: LeaderSkinTeamItem, index: number) {
        const data = item.dataSource as TroopTeamVo;
        const isUnlock = data.isUnlock;
        item.boxOpen.visible = isUnlock;
        item.boxLock.visible = !isUnlock;
        if (isUnlock) {
            item.imghero.skin = data.heroIcon;
            item.lab_power.text = data.power.toString();
            item.lab_team.text = `${index + 1}`;
            item.boxPower.visible = data.power > 0;
            item.lab_cond.text = "";
            const heroUid = data.getSoliderDataByIdx(0).uid!;
            const heroData = app.service.troop.getHeroDataByuid(heroUid);
            const starNum = heroData.star;
            for (let i = 0; i < 5; i++) {
                const star = item.boxStar.getChildAt(i) as Laya.Image;
                star.visible = i < starNum;
            }
            item.boxStar.forceUpdate();
            const heroHp = data.getSoliderHpByIdx(0);
            item.boxDamage.visible = heroHp === 0;
            item.listhero.renderHandler = new Laya.Handler(this, this.onHeroRender);
            const soliderArr: proto.troop.IHero[] = [];
            for (let i = 0; i < 3; i++) {
                const soliderData = data.getSoliderDataByIdx(i + 1);
                soliderArr.push(soliderData);
            }
            item.listhero.array = soliderArr;
            item.on(Laya.Event.CLICK, this, this.onClickTeam);
        } else {
            item.off(Laya.Event.CLICK, this.onClickTeam);
            item.lab_cond.text = data.unlockCond;
        }
    }

    private onClickTeam(e: Laya.Event) {
        const target = e.currentTarget as LeaderSkinTeamItem;
        if (this.curSelItem) {
            this.curSelItem.imgSelect.visible = false;
        }
        this.curSelItem = target;
        this.curSelItem.imgSelect.visible = true;
    }

    private onHeroRender(item: PvpTroopListHeroItemUI, index: number) {
        const data = item.dataSource as proto.troop.IHero;
        if (data?.uid) {
            item.boxItem.visible = true;
            item.imgAdd.visible = false;
            const heroData = app.service.troop.getHeroDataByuid(data.uid);
            const texturePaths = item.imgBg.getComponent(ImageRef)!.texturePaths;
            item.imgBg.skin = texturePaths[heroData.quality];
            const typeTextPaths = item.imgType.getComponent(ImageRef)!.texturePaths;
            item.imgType.skin = typeTextPaths[heroData.quality];
            item.labJob.text = heroData.jobDesc;

            for (let i = 0; i < 5; i++) {
                const star = item.boxStar.getChildAt(i) as Laya.Image;
                star.visible = i < heroData.star;
            }
            item.imgHero.skin = heroData.iconBig || "";
            item.boxStar.forceUpdate();
            item.labStar.text = `${heroData.star}`;
            item.labName.text = heroData.name;
            item.boxDamage.visible = heroData.hp === 0;
        } else {
            item.boxItem.visible = false;
            item.imgAdd.visible = true;
        }
    }

    //组件被启用后执行，例如节点被添加到舞台后
    //onEnable(): void {}

    //组件被禁用时执行，例如从节点从舞台移除后
    //onDisable(): void {}

    //第一次执行update之前执行，只会执行一次
    //onStart(): void {}

    //手动调用节点销毁时执行
    override onDestroy(): void {}

    //每帧更新时执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onUpdate(): void {}

    //每帧更新时执行，在update之后执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onLateUpdate(): void {}

    //鼠标点击后执行。与交互相关的还有onMouseDown等十多个函数，具体请参阅文档。
    //onMouseClick(): void {}
}
