/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/map-transfer/area-progress-bar-reward-item.lh
 */
export class AreaProgressBarRewardItemUIBase extends Laya.Box {
    public img_on!: Laya.Image;
    public img_off!: Laya.Image;
    public img_quality_bg!: Laya.Image;
    public img_icon!: Laya.Image;
    public img_mask!: Laya.Image;
    public img_gou!: Laya.Image;
    public img_redpoint!: Laya.Image;
    public persentText!: Laya.Label;
}

