import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { BattleConf, BattleEntityType } from "../../../../../def/auto/battle";
import { MainlineConf } from "../../../../../def/auto/mainline";
import { SystemEvent } from "../../../../../misc/system-event";
import { AnimName } from "../../../base/Animator";
import { PveServer } from "../../PveServer";
import { PveSvrCreatureComponent } from "../components/PveSvrCreatureComponent";
import { PveSvrElementComponent } from "../components/PveSvrElementComponent";
import { PveSvrOffsetComponent } from "../components/PveSvrOffsetComponent";
import { PveSvrSoldierComponent } from "../components/PveSvrSoldierComponent";
import { PveSvrSoldierGroupComponent } from "../components/PveSvrSoldierGroupComponent";
import { PveSvrTransformComponent } from "../components/PveSvrTransformComponent";
import { PveSvrTroopComponent } from "../components/PveSvrTroopComponent";
import { PveSvrSpoilsSystem } from "./PveSvrSpoilsSystem";

export class PveSvrFightingSystem extends ecs.System {
    declare context: PveServer;

    override onCreate(): void {
        this.registerHandler(
            PveSvrCreatureComponent,
            this._onAddPveSvrCreatureComponent,
            this._onDelPveSvrCreatureComponent
        );

        this.registerHandler(
            PveSvrTroopComponent,
            this._onAddPveSvrTroopComponent,
            this._onDelPveSvrTroopComponent
        );

        this.registerHandler(
            PveSvrSoldierComponent,
            this._onAddPveSvrSoldierComponent,
            this._onDelPveSvrSoldierComponent
        );
        this.registerHandler(
            PveSvrSoldierGroupComponent,
            this._onAddPveSvrSoldierGroupComponent,
            this._onDelPveSvrSoldierGroupComponent
        );

        // 断线要停止玩家移动
        app.on(SystemEvent.DISCONNECTED, this, this.onDisConnected);
    }

    private onDisConnected(): void {
        const ctrlHero = this.context.ctrlHero;
        if (ctrlHero) {
            this.context.moveStop(ctrlHero.eid);
            this.context.playAnim(ctrlHero.eid, AnimName.IDLE);
        }
    }

    override onDestroy(): void {
        app.off(SystemEvent.DISCONNECTED, this.onDisConnected);
        app.offAllCaller(this);
    }

    override update(dt: number): void {
        this.ecs.getComponents(PveSvrCreatureComponent).forEach((comp) => {
            const isOutOfFightOld = comp.isOutOfFight;
            const isOutOfFight = PveSvrCreatureComponent.checkIsOutOfFight(comp);
            // 之前没脱战，现在脱战了，应该发射事件
            if (!isOutOfFightOld && isOutOfFightOld !== isOutOfFight) {
                app.event(SystemEvent.PVE.ON_OUT_OF_FIGHT, comp.eid);
                this.context.dispatchAll(comp.eid, SystemEvent.BTREE.ON_OUT_OF_FIGHT, comp.eid);
            }
        });
    }

    private _onAddPveSvrCreatureComponent(comp: PveSvrCreatureComponent): void {}

    private _onDelPveSvrCreatureComponent(comp: PveSvrCreatureComponent): void {
        if (comp.etype === BattleEntityType.MONSTER || comp.etype === BattleEntityType.BOSS) {
            // 1. 从编队里移除
            const elementComp = comp.getComponent(PveSvrElementComponent, true)!;
            const troopEid = elementComp.troopEid;
            if (!troopEid) {
                return;
            }
            const troopComp = this.context.ecs.getComponent(troopEid, PveSvrTroopComponent)!;
            const troopElement = troopComp.getComponent(PveSvrElementComponent)!;
            const troopTFComp = troopElement.getComponent(PveSvrTransformComponent)!;
            const troopPos = troopTFComp.position;
            const nowTime = app.service.network.serverTime;
            if (troopComp.leaderEid === comp.eid) {
                app.service.pve.requestDoTask(
                    MainlineConf.TASK.KILL_MONSTER_TROOP,
                    1,
                    troopElement.tid
                );
                app.service.pve.requestDoTask(MainlineConf.TASK.KILL_ANY_MONSTER_TROOP, 1);
                PveSvrSpoilsSystem.handleMonsterTroopDeathDropSpoils(
                    this.context,
                    troopElement.tid,
                    troopPos.x,
                    troopPos.z,
                    nowTime
                );
                // 作为部队的leader死了，直接让整个部队死掉
                // todo: 后续要区分leader死亡和全部死亡才算部队死亡
                // console.log("leader死亡导致部队死亡", troopComp);
                troopComp.leaderEid = 0;
                this.doMonsterTroopDeath(this.context, troopComp);

                return;
            }
            const memberIdx = PveSvrTroopComponent.getMonsterIndex(troopComp, comp.eid);
            if (!memberIdx) {
                console.error("troop member not found", troopComp, comp);
                return;
            }
            delete troopComp.members[memberIdx];
            if (troopComp.leaderEid === 0 && Object.keys(troopComp.members).length === 0) {
                app.service.pve.requestDoTask(
                    MainlineConf.TASK.KILL_MONSTER_TROOP,
                    1,
                    troopElement.tid
                );
                app.service.pve.requestDoTask(MainlineConf.TASK.KILL_ANY_MONSTER_TROOP, 1);
                PveSvrSpoilsSystem.handleMonsterTroopDeathDropSpoils(
                    this.context,
                    troopElement.tid,
                    troopPos.x,
                    troopPos.z,
                    nowTime
                );

                // 所有单位死亡，部队解散
                this.doMonsterTroopDeath(this.context, troopComp);
                return;
            }

            // 2. 移除monster record data
            const pveStateData = app.service.pve.mlData.pveStateData;
            const monsterTroopRecordData = pveStateData.getMonsterTroopByKey(troopElement.key);
            if (monsterTroopRecordData) {
                monsterTroopRecordData.delMonsterData(memberIdx);
            }
        }
    }

    private _onAddPveSvrTroopComponent(comp: PveSvrTroopComponent): void {}

    private _onDelPveSvrTroopComponent(comp: PveSvrTroopComponent): void {
        if (!comp.isSelf) {
            // console.log("部队被移除", comp);
            // 1.移除编队里所有的怪物
            const removeElement = (eid: number) => {
                const ele = this.context.ecs.getComponent(eid, PveSvrElementComponent);
                if (ele) {
                    // console.log("移除部队成员", eid, ele);
                    ele.troopEid = 0; //先打断联系
                    this.context.removeElement(ele);
                } else {
                    console.error("troop member not found", eid, comp);
                }
            };

            if (comp.leaderEid) {
                removeElement(comp.leaderEid);
                comp.leaderEid = 0;
            }
            for (const idx in comp.members) {
                const soldierEid = comp.members[idx];
                removeElement(soldierEid);
                delete comp.members[idx];
            }
            for (const key in comp.truckEids) {
                const truckEid = comp.truckEids[key];
                removeElement(truckEid);
                delete comp.truckEids[key];
            }
        }
    }

    private _onAddPveSvrSoldierGroupComponent(comp: PveSvrSoldierGroupComponent): void {}

    private _onDelPveSvrSoldierGroupComponent(comp: PveSvrSoldierGroupComponent): void {}

    private _onAddPveSvrSoldierComponent(comp: PveSvrSoldierComponent): void {}

    private _onDelPveSvrSoldierComponent(comp: PveSvrSoldierComponent): void {
        const offset = comp.getComponent(PveSvrOffsetComponent, true)!;
        const eleComp = comp.getComponent(PveSvrElementComponent, true)!;
        const troopComp = this.ecs.getComponent(eleComp.troopEid, PveSvrTroopComponent, true)!;
        const index = offset.index;
        const station = 1 + Math.floor(index / BattleConf.PVE.SOLDIER_ONE_ROW_COUNT);
        if (troopComp.members[index] !== comp.eid) {
            return console.error(
                "soldier not found in solder group",
                comp.eid,
                troopComp.members,
                index
            );
        }
        delete troopComp.members[index];
        // 检查是否需要删除士兵组
        let isNeedDeleteGroup = true;
        for (
            let i = (station - 1) * BattleConf.PVE.SOLDIER_ONE_ROW_COUNT,
                max = station * BattleConf.PVE.SOLDIER_ONE_ROW_COUNT;
            i < max;
            i++
        ) {
            if (troopComp.members[i]) {
                isNeedDeleteGroup = false;
                break;
            }
        }

        if (isNeedDeleteGroup) {
            const solderGroupEle = this.ecs.getComponent(comp.groupEid, PveSvrElementComponent);
            if (solderGroupEle) {
                this.context.removeElement(solderGroupEle);
            } else {
                console.warn("solder group not found", comp.groupEid);
            }
        }
    }

    private doMonsterTroopDeath(pveServer: PveServer, troopComp: PveSvrTroopComponent) {
        //  移除monster state data
        const pveStateData = app.service.pve.mlData.pveStateData;
        const troopEle = troopComp.eleComp;

        pveStateData.setMonsterTroopDeath(troopEle.key, troopEle.tid);

        pveServer.removeElement(troopEle);
    }
}
