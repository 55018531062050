import * as ecs from "../../../../../core/ecs";
import { UpdateTruck } from "../../../pve-server/PveDefs";

interface FlyingCollect {
    idx: number;
    obj: Laya.Sprite3D;
    pointParent: Laya.Sprite3D;
    gatherParent: Laya.Sprite3D;
    finalPos: Laya.Vector3;
    speed: number;
}

export class PveTruckCollectComponent extends ecs.Component {
    truck!: number;
    data!: UpdateTruck;
    flyingCollect?: FlyingCollect;
}
