import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";

type NodeInput = [unknown];

export class CacheVariant extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const [input] = env.input as NodeInput;
        env.output.push(input);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "CacheVariant",
            type: "Action",
            desc: "缓存变量到给一个变量名中",
            input: ["变量名"],
            output: ["另一个变量名"],
            doc: `
            缓存变量到给一个变量名中
            `,
        };
    }
}
