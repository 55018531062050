import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";

export class GetOwner extends Process {
    override init(node: Node): void {}

    override run(node: Node, env: AiTreeEnv): Status {
        env.output.push(env.owner.eid);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "GetOwner",
            type: "Action",
            desc: "获取本AI树挂载的单位自身。",
            args: [],
            input: [],
            output: ["自己"],
            doc: `
            返回类型是 PveSvrElementComponent的Eid 。
            `,
        };
    }
}
