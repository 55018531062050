/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIHBoxStar } from "../UIHBoxStar";

/**
 * resources/prefab/character/character/CharacterCard.lh
 */
export class CharacterCardBase extends Laya.Box {
    public imgQuality!: Laya.Image;
    public imgHero!: Laya.Image;
    public lblName!: Laya.Label;
    public imgJobBg!: Laya.Image;
    public lblJob!: Laya.Label;
    public imgTeamBg!: Laya.Image;
    public lblTeam!: Laya.Label;
    public imgTeamBg2!: Laya.Image;
    public lblTeam2!: Laya.Label;
    public boxStars!: UIHBoxStar;
    public imgStar0!: Laya.Image;
    public imgStar1!: Laya.Image;
    public imgStar2!: Laya.Image;
    public imgStar3!: Laya.Image;
    public imgStar4!: Laya.Image;
    public lblLv!: Laya.Label;
    public boxReward!: Laya.Box;
    public lblReward!: Laya.Label;
}

