import { Mediator } from "../../core/ui-mediator";
import { UIAllianceAppoint } from "./ui-runtime/UIAllianceAppoint";

const { regClass, property } = Laya;

@regClass('InPQGVmYSC-BOpXaCJmc-A')
export class UIAllianceAppointMediator extends Mediator {
    declare owner: UIAllianceAppoint;

    override onAwake(): void {
        super.onAwake();
    }
}
