import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { ImageRef } from "../../misc/ImageRef";
import { HeroVo } from "../hero/vo/HeroVo";
import { DrawCardGainHeroSkill } from "./ui-runtime/DrawCardGainHeroSkill";
import { UIDrawCardGainHero } from "./ui-runtime/UIDrawCardGainHero";

const { regClass, property } = Laya;

@regClass('1R6kIt12S6y15mcMjDKifw')
export class UIDrawCardGainHeroMediator extends Mediator {
    //declare owner : Laya.Sprite3D;
    declare owner: UIDrawCardGainHero;

    @property(String)
    public text: string = "";

    //组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
    override onAwake(): void {
        super.onAwake();
        this.inintPanel();
        this.owner.skillItem1.on(Laya.Event.CLICK, this, this.onClickSkillItem);
        this.owner.skillItem2.on(Laya.Event.CLICK, this, this.onClickSkillItem);
        this.owner.skillItem3.on(Laya.Event.CLICK, this, this.onClickSkillItem);
        this.owner.skillItem4.on(Laya.Event.CLICK, this, this.onClickSkillItem);
        this.owner.boxMask.on(Laya.Event.CLICK, this, this.onClickMask);
        this.owner.on(Laya.Event.CLICK, this.owner, this.owner.destroy);
    }

    private onClickSkillItem(e: Laya.Event) {
        e.stopPropagation();
        this.owner.boxMask.mouseEnabled = true;
        this.owner.boxSkill.visible = true;
        let skillPos = 0;
        const target = e.currentTarget;
        switch (target) {
            case this.owner.skillItem1:
                skillPos = 1;
                break;
            case this.owner.skillItem2:
                skillPos = 2;
                break;
            case this.owner.skillItem3:
                skillPos = 3;
                break;
            case this.owner.skillItem4:
                skillPos = 4;
                break;
        }
        const skillData = this.owner.herovo.skills[skillPos];
        const config = app.service.skill.getSkillCfgById(skillData.id)!;
        this.owner.labSkillName.text = config.name;
        this.owner.labSkillDesc.text = config.desc;

        const boxSkillPosx =
            this.owner.hboxSkill.x +
            (skillPos - 1) * 115 +
            (skillPos - 1) * this.owner.hboxSkill.uspace +
            115 / 2 -
            this.owner.boxSkill.width / 2;
        this.owner.boxSkill.x = boxSkillPosx;
    }

    private onClickMask() {
        this.owner.boxMask.mouseEnabled = false;
        this.owner.boxSkill.visible = false;
    }

    private inintPanel() {
        const herVo = this.owner.herovo;
        this.owner.imgHero.skin = herVo.iconFull || "";
        this.owner.labName.text = herVo.name;

        const textPaths = this.owner.imgBg.getComponent(ImageRef)!.texturePaths;
        const quality = herVo.quality;

        this.owner.imgBg.skin = textPaths[quality - 2];

        const starNum = herVo.star;
        for (let i = 0; i < 5; i++) {
            const starImg = this.owner.boxStar.getChildAt(i) as Laya.Image;
            starImg.visible = i < starNum;
        }
        this.owner.boxStar.forceUpdate();
        this.owner.labType.text = herVo.jobDesc;
        this.refreshBoxSkill(herVo);
        this.owner.boxMask.mouseEnabled = false;
        this.owner.boxSkill.visible = false;
    }

    private refreshBoxSkill(heroVo: HeroVo) {
        for (let i = 0; i < this.owner.hboxSkill.numChildren; i++) {
            const boxSkil = this.owner.hboxSkill.getChildAt(i) as DrawCardGainHeroSkill;
            const skillData = heroVo.skills[i + 1];
            boxSkil.visible = skillData !== undefined;
            if (skillData) {
                boxSkil.imgSkill.skin = app.service.skill.getSKillIcon(skillData.id);
                boxSkil.labSkillLv.text = skillData.lv.toString();
            }
        }
        this.owner.hboxSkill.forceUpdate();
    }

    //组件被启用后执行，例如节点被添加到舞台后
    //onEnable(): void {}

    //组件被禁用时执行，例如从节点从舞台移除后
    //onDisable(): void {}

    //第一次执行update之前执行，只会执行一次
    //onStart(): void {}

    //手动调用节点销毁时执行
    //onDestroy(): void {}

    //每帧更新时执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onUpdate(): void {}

    //每帧更新时执行，在update之后执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onLateUpdate(): void {}

    //鼠标点击后执行。与交互相关的还有onMouseDown等十多个函数，具体请参阅文档。
    //onMouseClick(): void {}
}
