import { app } from "../../../app";
import { IVector3Like } from "../../../core/laya";
import { BattleEntityType } from "../../../def/auto/battle";
import { BattleBuffRow } from "../../../def/table";
import { PveServer } from "./PveServer";
import { PveSvrAiComponent } from "./ecs/components/PveSvrAiComponent";
import { PveSvrBuffComponent } from "./ecs/components/PveSvrBuffComponent";
import { PveSvrCreatureComponent } from "./ecs/components/PveSvrCreatureComponent";
import { PveSvrElementComponent } from "./ecs/components/PveSvrElementComponent";
import { PveSvrSkillLauncherComponent, Skill } from "./ecs/components/PveSvrSkillLauncherComponent";
import { PveSvrTransformComponent } from "./ecs/components/PveSvrTransformComponent";
import { PveSvrTroopComponent } from "./ecs/components/PveSvrTroopComponent";
import { PveSvrVFXComponent } from "./ecs/components/PveSvrVFXComponent";
import { BuffTree, BuffTreeState } from "./ecs/data/buff/BuffBase";

export enum BattleTroopPosType {
    Leader = 0,
    Front = 1,
    Middle = 2,
    Back = 3,
}

export class PveSvrUtils {
    static interuptAITree(ai: PveSvrAiComponent) {
        ai.tree?.interrupt();
        const launcherComp = ai.getComponent(PveSvrSkillLauncherComponent);
        if (launcherComp) {
            Object.values(launcherComp.atkSkills).forEach((skill) => {
                skill.tree?.interrupt();
            });
            Object.values(launcherComp.skills).forEach((skill) => {
                skill.tree?.interrupt();
            });
        }
    }

    /** 添加特效 */
    static launchVFXEffect(
        ctx: PveServer,
        ownerEid: number,
        battleEntityId: number,
        ownerPos: IVector3Like,
        ownerRot: number,
        isAttach: boolean,
        offsetV3?: IVector3Like,
        delayDelete?: number
    ): void {
        const battleEntityCfg = app.service.table.battleEntity[battleEntityId];
        if (!battleEntityCfg) {
            console.error(`launchVFXEffect: battleEntityId ${battleEntityId} not found`);
            return;
        }
        const entity = ctx.ecs.createEntity(ctx.obtainEid(), BattleEntityType.VFX);
        const element = entity.addComponent(PveSvrElementComponent);
        element.data = battleEntityCfg;
        element.tid = -1;

        const vfxComp = entity.addComponent(PveSvrVFXComponent);
        vfxComp.ownerEid = ownerEid;
        vfxComp.battleEntityId = battleEntityId;
        vfxComp.isAttach = !!isAttach;
        vfxComp.attachEid = vfxComp.isAttach ? ownerEid : 0;
        vfxComp.offset.setValue(offsetV3?.x ?? 0, offsetV3?.y ?? 0, offsetV3?.z ?? 0);

        const transform = entity.addComponent(PveSvrTransformComponent);
        transform.position.x = ownerPos.x + vfxComp.offset.x;
        transform.position.y = ownerPos.y + vfxComp.offset.y;
        transform.position.z = ownerPos.z + vfxComp.offset.z;
        transform.rotation = ownerRot;

        if (delayDelete) {
            ctx.ecs.delay(delayDelete, `vfx_${entity.eid}`, () => {
                ctx.removeElement(element);
            });
        }
        ctx.sender.createElement({
            eid: element.eid,
            etype: element.etype,
            key: element.key,
            aid: element.aid,
            teid: element.data.id,
            tid: element.tid,
            position: transform.position,
            rotation: transform.rotation,
            level: -1,
        });
    }

    static removeVFXEffect(ctx: PveServer, ownerEid: number, battleEntityId: number) {
        const vfxComponents = ctx.ecs.getComponents(PveSvrVFXComponent);
        for (const [k, v] of vfxComponents) {
            if (v.ownerEid === ownerEid && v.battleEntityId === battleEntityId) {
                const eleComp = v.getComponent(PveSvrElementComponent)!;
                // console.log("removeVFXEffect", v.eid, v.ownerEid, v.battleEntityId);
                ctx.removeElement(eleComp);
            }
        }
    }

    static getTroopByLeaderEid(
        ctx: PveServer,
        leaderEid: number
    ): PveSvrTroopComponent | undefined {
        const troopComponents = ctx.ecs.getComponents(PveSvrTroopComponent);
        for (const [k, v] of troopComponents) {
            if (v.leaderEid === leaderEid) {
                return v;
            }
        }
        return undefined;
    }

    static tryLaunchBuff(
        pveServer: PveServer,
        buffId: number,
        skill: Skill,
        targetEid: number,
        duration: number,
        buffArgs: number[] = [],
        limit: number = 0
    ) {
        /**
         * 1、根据buffId获取buff配置
         * 2、创建出对应的buff, 然后添加到对应的entity的BuffComponent中
         * 3、添加进去后激活buff的行为树，激活运行，可以运行一次，也可持续运行
         * 4、当buff结束时，清理buff的行为树, 触发清理逻辑
         */

        const buffRow: BattleBuffRow = app.service.table.battleSkill.buff[buffId]!;
        if (!buffRow) {
            console.error(`launchBuff: buffId ${buffId} not found`);
            return;
        }

        const creatureComp = pveServer.ecs.getComponent(targetEid, PveSvrCreatureComponent);
        if (creatureComp && creatureComp.isOutOfFight && buffRow.out_fight_cancel === 1) {
            return; // 脱战取消buff不能添加到脱战单位身上
        }

        const buffComp = pveServer.ecs.getComponent(targetEid, PveSvrBuffComponent);
        if (!buffComp) {
            console.error(`launchBuff: targetEid ${targetEid} buff component not found`);
            return;
        }

        const overdueTime = duration < 0 ? duration : pveServer.time + duration;
        const buffTree = new BuffTree(buffRow, skill, overdueTime, buffArgs);
        const allBuffTrees = buffComp.buffTrees;

        const sameBuffTrees = allBuffTrees.filter((tree) => tree.data.id === buffId);

        const sameSkillBuffTrees = sameBuffTrees.filter((tree) => tree.skill.id === skill.id);
        const diffSkillBuffTrees = sameBuffTrees.filter((tree) => tree.skill.id !== skill.id);
        // todo: 根据堆叠规则，判断是否可以堆叠buff
        if (limit > 0 && sameSkillBuffTrees.length >= limit) {
            // 超过限制，不添加buff
            // console.warn(
            //     `launchBuff: eid ${buffComp.eid} buffId ${buffId} skillId ${skill.id} exceed limit ${limit}`
            // );
            return;
        }

        buffTree.state = BuffTreeState.TRY_LOAD;
        buffComp.buffTrees.push(buffTree);
    }

    static removeBuffTree(pveServer: PveServer, buffId: number, skill: Skill, targetEid: number) {
        const buffComp = pveServer.ecs.getComponent(targetEid, PveSvrBuffComponent);
        if (!buffComp) {
            console.error(`launchBuff: targetEid ${targetEid} buff component not found`);
            return;
        }
        for (let i = buffComp.buffTrees.length - 1; i >= 0; i--) {
            const buffTree = buffComp.buffTrees[i];
            if (buffTree.data.id === buffId && buffTree.skill === skill) {
                PveSvrUtils.onClearOneRuningBuffTree(buffTree);
                buffComp.buffTrees.splice(i, 1);
            }
        }
    }

    static onClearOneRuningBuffTree(buffTree: BuffTree) {
        if (buffTree.state === BuffTreeState.RUNNING) {
            buffTree.tree!.run(); //清理buff的行为树
            buffTree.tree!.clear();
        }
        buffTree.tree = undefined;
    }
}
