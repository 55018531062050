/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/character/rebuild/CharacterRebuildItem.lh
 */
export class CharacterRebuildItemBase extends Laya.Box {
    public imgQuality!: Laya.Image;
    public imgIcon!: Laya.Image;
    public lblCount!: Laya.Label;
}

