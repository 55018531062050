const { regClass } = Laya;
import { BaseBigWindowBgBase } from "./BaseBigWindowBg.generated";

@regClass('gh6td4jdRKaxhn7WzfXCJA')
export class BaseBigWindowBg extends BaseBigWindowBgBase {
    /** 设置窗口标题 */
    setTitle(title: string) {
        this.title.text = title;
    }
}
