import { app } from "../../app";
import { Service } from "../../core/service";
import {
    AttrTable,
    BattleBuildingTable,
    BattleEntityTable,
    BattleEventTable,
    BattleNpcTable,
    BattlePveMapTable,
    BattleSkill,
    BattleSkinTable,
    BattleSceneTable as BattleTable,
    BoardTexture,
    ChestTable,
    ClusterTable,
    DefineTable,
    EmojiTable,
    FormationTable,
    HeroTable,
    ItemTable,
    LeadSkinTable,
    MailTable,
    MoneyTable,
    MonsterTable,
    PlotTable,
    PlotTheatreTable,
    RechargeTable,
    RecruitTable,
    RoleTable,
    ShopTable,
    SkillSelectorTable,
    SpoilsTable,
    TanlentTable,
    TaskTable,
    TroopTable,
    WorldEntityTable,
    itemRourceTable,
    worldWagonTable,
} from "../../def/table";
import { res } from "../../misc/res";

export class TableService extends Service {
    attr!: AttrTable;
    battleEntity!: BattleEntityTable;
    battleBuilding!: BattleBuildingTable;
    battleEvent!: BattleEventTable;
    battleNpc!: BattleNpcTable;
    battle!: BattleTable;
    battleSkill!: BattleSkill;
    battlePveMapTable!: BattlePveMapTable;
    plot!: PlotTable;
    plotTheatre!: PlotTheatreTable;
    chest!: ChestTable;
    emoji!: EmojiTable;
    hero!: HeroTable;
    item!: ItemTable;
    mail!: MailTable;
    money!: MoneyTable;
    role!: RoleTable;
    shop!: ShopTable;
    skillSelector!: SkillSelectorTable;
    task!: TaskTable;
    monster!: MonsterTable;
    spoils!: SpoilsTable;
    skin!: BattleSkinTable;
    boardTexture!: BoardTexture;
    worldEntity!: WorldEntityTable;
    worldWagon!: worldWagonTable;
    recharge!: RechargeTable;
    formation!: FormationTable;
    define!: DefineTable;
    troop!: TroopTable;
    talent!: TanlentTable;
    leaderskin!: LeadSkinTable;
    itemRource!: itemRourceTable;
    cluster!: ClusterTable;
    recruit!: RecruitTable;

    override onCreate() {}

    override onStartInit(): void {}

    override onDestroy() {}

    async load() {
        this.cluster = await app.loader.loadJson(res.TABLE_ClUSTER_TABLE);

        this.attr = await app.loader.loadJson(res.TABLE_ATTR_TABLE);
        this.battleBuilding = await app.loader.loadJson(res.TABLE_BATTLE_BUILDING_TABLE);
        this.battleEntity = await app.loader.loadJson(res.TABLE_BATTLE_ENTITY_TABLE);
        this.battleEvent = await app.loader.loadJson(res.TABLE_BATTLE_EVENT_TABLE);
        this.battleNpc = await app.loader.loadJson(res.TABLE_BATTLE_NPC_TABLE);
        this.battle = await app.loader.loadJson(res.TABLE_BATTLE_TABLE);
        this.battleSkill = await app.loader.loadJson(res.TABLE_BATTLE_SKILL_TABLE);
        this.battlePveMapTable = await app.loader.loadJson(res.TABLE_BATTLE_PVE_MAP_TABLE);
        this.plot = await app.loader.loadJson(res.TABLE_PLOT_TABLE);
        this.plotTheatre = await app.loader.loadJson(res.TABLE_PLOT_THEATRE_TABLE);
        this.spoils = await app.loader.loadJson(res.TABLE_SPOILS_TABLE);
        this.skin = await app.loader.loadJson(res.TABLE_SKIN_TABLE);

        this.chest = await app.loader.loadJson(res.TABLE_CHEST_TABLE);
        this.emoji = await app.loader.loadJson(res.TABLE_EMOJI_TABLE);
        this.hero = await app.loader.loadJson(res.TABLE_HERO_TABLE);
        this.item = await app.loader.loadJson(res.TABLE_ITEM_TABLE);
        this.mail = await app.loader.loadJson(res.TABLE_MAIL_TABLE);
        this.money = await app.loader.loadJson(res.TABLE_MONEY_TABLE);
        this.monster = await app.loader.loadJson(res.TABLE_MONSTER_TABLE);
        this.role = await app.loader.loadJson(res.TABLE_ROLE_TABLE);
        this.shop = await app.loader.loadJson(res.TABLE_SHOP_TABLE);
        this.skillSelector = await app.loader.loadJson(res.TABLE_SKILL_SELECTOR_TABLE);
        this.task = await app.loader.loadJson(res.TABLE_TASK_TABLE);
        this.boardTexture = await app.loader.loadJson(res.TABLE_BOARD_TEXTURE_TABLE);
        this.worldEntity = await app.loader.loadJson(res.TABLE_ENTITY_TABLE);
        this.worldWagon = await app.loader.loadJson(res.TABLE_WORLD_WAGON_TABLE);
        this.recharge = await app.loader.loadJson(res.TABLE_RECHARGE_TABLE);
        this.formation = await app.loader.loadJson(res.TABLE_FORMATION_TABLE);
        this.define = await app.loader.loadJson(res.TABLE_DEFINE_TABLE);
        this.troop = await app.loader.loadJson(res.TABLE_TROOP_TABLE);
        this.talent = await app.loader.loadJson(res.TABLE_TALENT_TABLE);
        this.leaderskin = await app.loader.loadJson(res.LEADER_SKIN_TABLE);
        this.itemRource = await app.loader.loadJson(res.TABLE_ITEM_ROURCE_TABLE);
        this.recruit = await app.loader.loadJson(res.TABLE_RECRUIT_TABLE);
    }
}
