const { regClass } = Laya;
import { app } from "../../../../app";
import { TableUtil } from "../../../table/TableUtil";
import { HeroVo } from "../../vo/HeroVo";
import { CharacterRebuildItem, CharacterRebuildItemData } from "./CharacterRebuildItem";
import { UICharacterRebuildBase } from "./UICharacterRebuild.generated";

export interface UICharacterRebuildArgs {
    uid: number;
}

@regClass('7oRSh-whRpK_HUgRxyccWg')
export class UICharacterRebuild extends UICharacterRebuildBase {
    private _openArgs: any;

    override open(closeOther?: boolean | undefined, param?: any): void {
        this._openArgs = param;
        super.open(closeOther, param);
    }

    override onAwake(): void {
        super.onAwake();
        const heroVo = app.service.hero.heroVoMap.get(this._openArgs.uid)!;

        // 1. 先计算所有货币
        const moneyCount = app.service.hero.calcuUpLvNeedMoney(heroVo?.lv);
        const moneyId = app.service.table.hero.conf.UPGRADE_LEVEL_ITEM_ID;
        const itemRow = TableUtil.getRow(app.service.table.item.item, { id: moneyId })!;

        // 2. 再计算所有材料
        const heroMats = heroVo.costedHeroMats;

        // 3. 最后将货币和材料合并为itemsVo的数组
        const data: CharacterRebuildItemData[] = [];
        data.push({
            quality: itemRow.quality!,
            iconSkin: "", //todo:完善data
            count: moneyCount,
        });

        data.push(
            ...heroMats.map((v) => {
                return <CharacterRebuildItemData>{
                    quality: HeroVo.GetQualityByStar(v.star!),
                    iconSkin: "", //todo:完善data
                    count: v.cnt,
                };
            })
        );

        this.listResource.array = [];
        this.listResource.renderHandler = new Laya.Handler(this, this.onRenderListResources);
        this.listResource.array = data;

        this.btnRebuild.clickHandler = new Laya.Handler(this, this.onBtnRebuildClick);
    }

    private onRenderListResources(cell: CharacterRebuildItem, index: number): void {
        const itemData = this.listResource.array[index];
        cell.onRender(itemData, index);
        if (index === this.listResource.array.length - 1) {
            //重计算listResource的content size
            if (this.listResource.content) {
                const length = Math.min(this.listResource.array.length, 4.5);
                const targetWidth =
                    (cell.width + this.listResource.spaceX) * length - this.listResource.spaceX;
                this.listResource.x =
                    ((this.listResource.parent as Laya.Sprite).width - targetWidth) / 2;
                console.log("this.listResource.x", this.listResource.x);
            }
        }
    }

    private onBtnRebuildClick(): void {
        app.service.hero.requestResetHero(this._openArgs.uid);
        this.close();
    }
}
