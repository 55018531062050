const { regClass } = Laya;
import { UIChestBoxChooseHeroMediator } from "../UIChestBoxChooseHeroMediator";
import { UIChestBoxChooseHeroBase } from "./UIChestBoxChooseHero.generated";

@regClass('CWpbkTMCSIm03sOu2JmX6Q')
export class UIChestBoxChooseHero extends UIChestBoxChooseHeroBase {
    public get mediator(): UIChestBoxChooseHeroMediator | null {
        return this.getComponent(UIChestBoxChooseHeroMediator);
    }

    override open(closeOther?: boolean | undefined): void {
        const med = this.mediator;
        if (med) {
            med.open();
        }
        super.open(closeOther);
    }
}
