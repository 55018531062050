import { IReusable } from "../pool";

export class Quad implements IReusable {
    __index: number = -1;

    private _uvs: { x: number; y: number }[] = [];
    private _vertices: Laya.Vector3[] = [];
    private _texture: Laya.Texture | null = null;

    constructor() {
        for (let i = 0; i < 4; i++) {
            this._uvs.push({ x: 0, y: 0 });
            this._vertices.push(new Laya.Vector3());
        }
    }

    __unuse() {
        this.__index = -1;
        this._texture = null;
    }

    __reuse(): void {}

    get index() {
        return this.__index;
    }

    /**
     * uv0      uv1
     * uv3      uv2
     */
    get uvs(): ReadonlyArray<(typeof this._uvs)[0]> {
        return this._uvs;
    }

    get vertices(): ReadonlyArray<Laya.Vector3> {
        return this._vertices;
    }

    get texture() {
        return this._texture;
    }

    /**
     * p0      p1
     * p3      p2
     */
    draw(
        p0: Laya.Vector3,
        p1: Laya.Vector3,
        p2: Laya.Vector3,
        p3: Laya.Vector3,
        texture: Laya.Texture,
        mat?: Laya.Matrix4x4
    ) {
        this._texture = texture;
        let uvidx = 0;
        for (let i = 0; i < 4; i++) {
            this._uvs[i].x = texture.uv[uvidx++];
            this._uvs[i].y = texture.uv[uvidx++];
        }
        if (mat) {
            Laya.Vector3.transformCoordinate(p0, mat, this._vertices[0]);
            Laya.Vector3.transformCoordinate(p1, mat, this._vertices[1]);
            Laya.Vector3.transformCoordinate(p2, mat, this._vertices[2]);
            Laya.Vector3.transformCoordinate(p3, mat, this._vertices[3]);
        } else {
            this._vertices[0].cloneFrom(p0);
            this._vertices[1].cloneFrom(p1);
            this._vertices[2].cloneFrom(p2);
            this._vertices[3].cloneFrom(p3);
        }
    }

    drawPlane(
        x: number,
        y: number,
        z: number,
        width: number,
        height: number,
        texture: Laya.Texture,
        mat?: Laya.Matrix4x4
    ) {
        const [p0, p1, p2, p3] = this.vertices;
        p0.set(x, y, z);
        p1.set(x + width, y, z);
        p2.set(x + width, y, z + height);
        p3.set(x, y, z + height);
        this.draw(p0, p1, p2, p3, texture, mat);
    }
}

interface IMeshPrivate {
    _vertexBuffer: Laya.VertexBuffer3D | null;
    _indexBuffer: Laya.IndexBuffer3D | null;
    _vertexCount: number;
    _needUpdateBounds: boolean;
    _setBuffer(vertexBuffer: Laya.VertexBuffer3D, indexBuffer: Laya.IndexBuffer3D): void;
    _setSubMeshes(subMeshes: Laya.SubMesh[]): void;
}

interface ISubMeshPrivate {
    _vertexBuffer: Laya.VertexBuffer3D;
    _indexBuffer: Laya.IndexBuffer3D;
    _subIndexBufferStart: number[];
    _subIndexBufferCount: number[];
    _setIndexRange(indexStart: number, indexCount: number, indexFormat: Laya.IndexFormat): void;
    _boneIndicesList: Uint16Array[];
}

export class Mesh extends Laya.Mesh {
    private _capacity: number;
    private _quads: Quad[] = [];
    private _subMesh: Laya.SubMesh;
    private _quadChanged: boolean = false;
    private _vertexDeclaration: Laya.VertexDeclaration;

    constructor(capacity: number = 16) {
        super();
        this._vertexDeclaration = Laya.VertexMesh.getVertexDeclaration("POSITION,NORMAL,UV");
        this._capacity = Math.max(16, capacity);
        this._subMesh = new Laya.SubMesh(this);

        const thisPrivate = this as unknown as IMeshPrivate;
        const subMeshPrivate = this._subMesh as unknown as ISubMeshPrivate;
        subMeshPrivate._subIndexBufferStart[0] = 0;
        thisPrivate._setSubMeshes([this._subMesh]);

        this._resize(this._capacity);
    }

    private _resize(capacity: number) {
        if (capacity >= 0xffff) {
            console.warn(`mesh is too full: ${this.name}`);
            return;
        }

        this._capacity = capacity;

        const thisPrivate = this as unknown as IMeshPrivate;
        thisPrivate._indexBuffer?.destroy();
        thisPrivate._vertexBuffer?.destroy();

        thisPrivate._vertexBuffer = Laya.Laya3DRender.renderOBJCreate.createVertexBuffer3D(
            this._quadVertexSize * capacity,
            Laya.BufferUsage.Dynamic,
            true
        );
        thisPrivate._vertexBuffer.vertexDeclaration = this._vertexDeclaration;
        thisPrivate._indexBuffer = Laya.Laya3DRender.renderOBJCreate.createIndexBuffer3D(
            Laya.IndexFormat.UInt16,
            capacity * this._quadIndexSize,
            Laya.BufferUsage.Dynamic,
            true
        );

        const subMeshPrivate = this._subMesh as unknown as ISubMeshPrivate;
        subMeshPrivate._vertexBuffer = thisPrivate._vertexBuffer;
        subMeshPrivate._indexBuffer = thisPrivate._indexBuffer;
    }

    override destroy() {
        this._quads = null!;
        this._subMesh = null!;
        super.destroy();
    }

    get vertexDeclaration() {
        return this._vertexDeclaration;
    }

    addQuad(quad: Quad) {
        const idx = quad.index;
        if (idx >= 0 && this._quads[idx] === quad) {
            return;
        }

        if (this._quads.length === this._capacity) {
            this._resize(this._capacity * 2);
        }

        if (this._quads.length < this._capacity) {
            this._quadChanged = true;
            quad.__index = this._quads.length;
            this._quads.push(quad);
        }
    }

    removeQuad(quad: Quad) {
        const idx = quad.index;
        if (idx >= 0 && this._quads[idx] === quad) {
            this._quadChanged = true;
            const last = this._quads.pop();
            if (last && last !== quad) {
                last.__index = idx;
                this._quads[idx] = last;
            }
        } else {
            console.warn(`quad missed in ${this.name}: index=${quad.index}`);
        }
    }

    clearQuads() {
        this._quadChanged = true;
        this._quads.length = 0;
    }

    get quadChanged() {
        return this._quadChanged;
    }

    drawQuads() {
        if (this._quadChanged) {
            this._quadChanged = false;

            let verticeCount: number = 0;
            let indiceIndex: number = 0;
            const thisPrivate = this as unknown as IMeshPrivate;
            const vertexBuffer = thisPrivate._vertexBuffer!;
            const indexBuffer = thisPrivate._indexBuffer!;
            const vertices: Float32Array = vertexBuffer.getFloat32Data()!;
            const indices: Uint16Array = indexBuffer.getData();
            for (let q = 0; q < this._quads.length; q++) {
                const quad = this._quads[q];
                for (let i = 0; i < 4; i++) {
                    const pos = quad.vertices[i];
                    const uv = quad.uvs[i];
                    // POSITION
                    vertices[verticeCount++] = pos.x;
                    vertices[verticeCount++] = pos.y;
                    vertices[verticeCount++] = pos.z;
                    // NORMAL
                    vertices[verticeCount++] = 0;
                    vertices[verticeCount++] = 1;
                    vertices[verticeCount++] = 0;
                    // UV
                    vertices[verticeCount++] = uv.x;
                    vertices[verticeCount++] = uv.y;
                }

                const idx = q * 4;

                indices[indiceIndex++] = idx + 0;
                indices[indiceIndex++] = idx + 1;
                indices[indiceIndex++] = idx + 2;

                indices[indiceIndex++] = idx + 0;
                indices[indiceIndex++] = idx + 2;
                indices[indiceIndex++] = idx + 3;
            }

            vertexBuffer.setData(vertices.buffer, 0, 0, this._quads.length * this._quadVertexSize);
            indexBuffer.setData(indices, 0, 0, indiceIndex);
            thisPrivate._vertexCount = verticeCount / (this._vertexDeclaration.vertexStride / 4);
            thisPrivate._setBuffer(vertexBuffer, indexBuffer);

            const subMeshPrivate = this._subMesh as unknown as ISubMeshPrivate;
            subMeshPrivate._setIndexRange(0, indiceIndex, Laya.IndexFormat.UInt16);
            subMeshPrivate._subIndexBufferCount[0] = indiceIndex;

            // TODO：是不是可以由外面调用都来决定包围的范围以提高效率？
            thisPrivate._needUpdateBounds = true;
            this.calculateBounds();

            const memorySize: number = vertexBuffer._byteLength + indexBuffer._byteLength;
            this._setCPUMemory(memorySize);
            this._setGPUMemory(memorySize);
        }
    }

    get quads() {
        return this._quads;
    }

    private get _quadVertexSize() {
        return this._vertexDeclaration.vertexStride * 4;
    }

    private get _quadIndexSize() {
        return 6;
    }
}

export class MeshFilter extends Laya.MeshFilter {
    override onUpdate(): void {
        const mesh = this.sharedMesh as Mesh;
        if (mesh.quadChanged) {
            mesh.drawQuads();

            const render = this.owner.getComponent(Laya.MeshRenderer);
            if (render) {
                render.boundsChange = true;
                render.enabled = mesh.quads.length > 0;
            }
        }
        super.onUpdate?.();
    }
}
