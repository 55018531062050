/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/battle/pvp-ui/battle-report.lh
 */
export class BattleReportUIBase extends Laya.Dialog {
    public list!: Laya.List;
}

