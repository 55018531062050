const { regClass, property } = Laya;

@regClass('V6we1dZ_QfWuRGqNdymeMw')
export class ImageRef extends Laya.Script {
    //declare owner : Laya.Sprite3D;
    //declare owner : Laya.Sprite;

    @property({ type: [Laya.Texture], caption: "图片资源" })
    private textures: Laya.Texture[] = [];

    get texturePaths(): string[] {
        return this.textures.map((t) => t.url);
    }
}
