/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { BaseRoundWindowBg } from "../../../../common/ui-runtime/dialog/BaseRoundWindowBg";
import { AreaProgressBarUI } from "./AreaProgressBarUI";

/**
 * resources/prefab/map-transfer/area-progress-main-win.lh
 */
export class UIAreaProgressBase extends Laya.Dialog {
    public baseRoundWindowBg!: BaseRoundWindowBg;
    public list01!: Laya.List;
    public progress_bar!: AreaProgressBarUI;
}
