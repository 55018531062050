const { regClass } = Laya;
import { app } from "../../../../../app";
import { StringUtil } from "../../../../../core/utils/string-util";
import { res } from "../../../../../misc/res";
import { BattlePveMapTransferItem } from "../../../pve-server/PveDefs";
import { UIMapTransferMediator } from "../../../pve/map-transfer/UIMapTransferMediator";
import { MapTransferItemUIBase } from "./MapTransferItemUI.generated";

@regClass('kSXiEgP2Rq2aMZIgSHvCVg')
export class MapTransferItemUI extends MapTransferItemUIBase {
    public mainWin: UIMapTransferMediator | undefined;

    public get data(): BattlePveMapTransferItem {
        return this.dataSource as BattlePveMapTransferItem;
    }

    override onAwake(): void {
        super.onAwake();
        this.img01.onClick(this.onClickThis.bind(this));
    }

    public updateShow(mainWin: UIMapTransferMediator): void {
        this.mainWin = mainWin;
        const data = this.data;
        this.text_name.text = data.cfg.name;
        this.text_zhan.text = data.cfg.fight.toString();

        const url = StringUtil.format(res.MAP_TRANSFER_ITEMS, data.cfg.bg);
        this.img01.skin = url;

        let isCurrentArea = false;
        if (this.mainWin.owner.context) {
            const curId = data.cfg.id;
            const closerTP = this.mainWin.owner.context.getCurrentCloserTransferPoint();
            if (closerTP && closerTP.mapTransfer && closerTP.mapTransfer.id === curId) {
                isCurrentArea = true;
            }
        }
        this.img_curpos.visible = isCurrentArea;

        const isOpen = app.service.pve.getTransferIdIsOpen(data.areaTransferId);
        this.setIsOpen(isOpen);
    }

    private setIsOpen(isOpen: boolean): void {
        if (isOpen) {
            this.img_black.visible = false;
            this.img_lock.visible = false;
        } else {
            this.img_black.visible = true;
            this.img_lock.visible = true;
        }
    }

    private onClickThis(): void {
        if (!this.mainWin) return;
        if (this.img_lock.visible) {
            app.ui.toast("未解锁");
            return;
        }
        this.mainWin.onClickTransferItem(this.data);
    }
}
