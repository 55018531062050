import { Node, NodeDef, Status } from "../../../../../core/behavior3";
import { BattleEntityType } from "../../../../../def/auto/battle";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrCreatureComponent } from "../../ecs/components/PveSvrCreatureComponent";
import { PveSvrElementComponent } from "../../ecs/components/PveSvrElementComponent";
import { PveSvrSoldierGroupComponent } from "../../ecs/components/PveSvrSoldierGroupComponent";
import { FindTargets } from "./FindTargets";

type NodeInput = [number?];

type NodeOutput = [string?];

export class IsTargetFighting extends FindTargets {
    override run(node: Node, env: AiTreeEnv): Status {
        let targetEle: PveSvrElementComponent | undefined;
        const inputEid = (env.input as NodeInput)[0];
        if (inputEid !== undefined) {
            targetEle = env.context.findElement(inputEid);
        } else {
            targetEle = env.owner;
        }

        let ret: Status = "failure";
        if (targetEle) {
            if (targetEle.etype === BattleEntityType.SOLDIER_GROUP) {
                const soldiderGroup = targetEle.getComponent(PveSvrSoldierGroupComponent)!;
                const soldiderEids = PveSvrSoldierGroupComponent.getSoldierEids(
                    env.context,
                    soldiderGroup
                );

                if (soldiderEids.length) {
                    for (const eid of soldiderEids) {
                        const creature = env.context.ecs.getComponent(
                            eid,
                            PveSvrCreatureComponent
                        )!;
                        if (creature && !creature.isOutOfFight) {
                            ret = "success";
                            break;
                        }
                    }
                }
            } else {
                const creature = env.context.ecs.getComponent(
                    targetEle.eid,
                    PveSvrCreatureComponent
                )!;
                if (!creature.isOutOfFight) {
                    ret = "success";
                }
            }
        }

        if (node.data.output) {
            const [outputKey] = node.data.output as unknown as NodeOutput;
            if (outputKey) {
                env.set(outputKey, ret === "success" ? true : false);
            }
        }
        return ret;
    }

    override get descriptor(): NodeDef {
        return {
            name: "IsTargetFighting",
            type: "Condition",
            desc: "目标是否对战中",
            input: ["目标单位?"],
            output: ["是否对战?"],
            doc: `
                + 返回目标是否对战中，如果时对战中，返回成功，否则返回失败
                + 不输入目标单位时，目标默认取本身
                + 如果目标是士兵组，只要有一个士兵在对战中，就返回成功
                + 如果目标单位非法，也返回失败
                + 结果可以通过output输出
                `,
        };
    }
}
