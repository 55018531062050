import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { SystemEvent } from "../../misc/system-event";
import { SkillQuality, USkillType } from "./SkillService";
import { SkillMapScreenUI } from "./ui-runtime/SkillMapScreenUI";

const { regClass, property } = Laya;

@regClass('F26tchpAR12SXFXUC-OlyA') //技能图鉴筛选弹窗
export class SkillMapScreenMediator extends Mediator {
    //declare owner : Laya.Sprite3D;
    declare owner: SkillMapScreenUI;

    @property(String)
    public text: string = "";

    override onAwake(): void {
        super.onAwake();
        this.initPanel();
        this.initUIEvent();
    }

    private initUIEvent() {
        this.owner.checkQulity1.boxClick.on(Laya.Event.CLICK, this, this.onClickQuality);
        this.owner.checkQulity2.boxClick.on(Laya.Event.CLICK, this, this.onClickQuality);
        this.owner.checkQulity3.boxClick.on(Laya.Event.CLICK, this, this.onClickQuality);
        this.owner.checkQulity4.boxClick.on(Laya.Event.CLICK, this, this.onClickQuality);

        this.owner.btnQulity.on(Laya.Event.CLICK, this, this.onClickBtnQuality);

        this.owner.checkType1.boxClick.on(Laya.Event.CLICK, this, this.onClickType);
        this.owner.checkType2.boxClick.on(Laya.Event.CLICK, this, this.onClickType);
        this.owner.checkType3.boxClick.on(Laya.Event.CLICK, this, this.onClickType);
        this.owner.checkType4.boxClick.on(Laya.Event.CLICK, this, this.onClickType);
        this.owner.checkType5.boxClick.on(Laya.Event.CLICK, this, this.onClickType);

        this.owner.boxClick.on(Laya.Event.CLICK, this, this.onClickOwn);
        this.owner.btnType.on(Laya.Event.CLICK, this, this.onClickBtnType);
        this.owner.imgClose.on(Laya.Event.CLICK, this.owner, this.owner.destroy);
    }

    private initPanel() {
        this.owner.bgwin.setTitle("技能图鉴筛选");
        this.owner.checkQulity1.setText("S");
        this.owner.checkQulity2.setText("A");
        this.owner.checkQulity3.setText("B");
        this.owner.checkQulity4.setText("C");
        this.owner.checkType1.setText("主动");
        this.owner.checkType2.setText("内政");
        this.owner.checkType3.setText("突击");
        this.owner.checkType4.setText("被动");
        this.owner.checkType5.setText("指挥");
        this.owner.checkQulity1.checkBoxSel.visible = app.service.skill.getMapQualitySel(
            SkillQuality.Red
        );
        this.owner.checkQulity2.checkBoxSel.visible = app.service.skill.getMapQualitySel(
            SkillQuality.Orange
        );
        this.owner.checkQulity3.checkBoxSel.visible = app.service.skill.getMapQualitySel(
            SkillQuality.Purple
        );
        this.owner.checkQulity4.checkBoxSel.visible = app.service.skill.getMapQualitySel(
            SkillQuality.Blue
        );

        this.owner.checkType1.checkBoxSel.visible = app.service.skill.getMapTypeSel(
            USkillType.Ability
        );
        this.owner.checkType2.checkBoxSel.visible = app.service.skill.getMapTypeSel(
            USkillType.Passive
        );
        this.owner.checkType3.checkBoxSel.visible = app.service.skill.getMapTypeSel(
            USkillType.AssAult
        );
        this.owner.checkType4.checkBoxSel.visible = app.service.skill.getMapTypeSel(
            USkillType.Cmmmand
        );
        this.owner.checkType5.checkBoxSel.visible = app.service.skill.getMapTypeSel(
            USkillType.Internal_Affairs
        );
        this.owner.checkBoxSel.visible = app.service.skill.skillMapOwn;
    }

    private onClickOwn() {
        const isSel = !this.owner.checkBoxSel.visible;
        this.owner.checkBoxSel.visible = isSel;
        app.service.skill.skillMapOwn = isSel;
        app.event(SystemEvent.SkILL.SKILL_MAP_SCREEN);
    }

    private onClickBtnQuality() {
        let isSel = false;
        isSel = this.owner.checkQulity1.checkBoxSel.visible =
            !this.owner.checkQulity1.checkBoxSel.visible;
        app.service.skill.updateMapQualitySel(SkillQuality.Red, isSel);

        isSel = this.owner.checkQulity2.checkBoxSel.visible =
            !this.owner.checkQulity2.checkBoxSel.visible;
        app.service.skill.updateMapQualitySel(SkillQuality.Orange, isSel);

        isSel = this.owner.checkQulity3.checkBoxSel.visible =
            !this.owner.checkQulity3.checkBoxSel.visible;
        app.service.skill.updateMapQualitySel(SkillQuality.Blue, isSel);

        isSel = this.owner.checkQulity4.checkBoxSel.visible =
            !this.owner.checkQulity4.checkBoxSel.visible;
        app.service.skill.updateMapQualitySel(SkillQuality.Purple, isSel);
        app.event(SystemEvent.SkILL.SKILL_MAP_SCREEN);
    }

    private onClickBtnType() {
        let isSel = false;
        isSel = this.owner.checkType1.checkBoxSel.visible =
            !this.owner.checkType1.checkBoxSel.visible;
        app.service.skill.updateMapTypeSel(USkillType.Ability, isSel);

        isSel = this.owner.checkType2.checkBoxSel.visible =
            !this.owner.checkType2.checkBoxSel.visible;
        app.service.skill.updateMapTypeSel(USkillType.Passive, isSel);

        isSel = this.owner.checkType3.checkBoxSel.visible =
            !this.owner.checkType3.checkBoxSel.visible;
        app.service.skill.updateMapTypeSel(USkillType.AssAult, isSel);

        isSel = this.owner.checkType4.checkBoxSel.visible =
            !this.owner.checkType4.checkBoxSel.visible;
        app.service.skill.updateMapTypeSel(USkillType.Cmmmand, isSel);

        isSel = this.owner.checkType5.checkBoxSel.visible =
            !this.owner.checkType5.checkBoxSel.visible;
        app.service.skill.updateMapTypeSel(USkillType.Internal_Affairs, isSel);
        app.event(SystemEvent.SkILL.SKILL_MAP_SCREEN);
    }

    private onClickQuality(e: Laya.Event) {
        const target = e.currentTarget;
        let isSel = false;
        let qulityKey = 0;
        switch (target) {
            case this.owner.checkQulity1.boxClick:
                isSel = !this.owner.checkQulity1.checkBoxSel.visible;
                this.owner.checkQulity1.checkBoxSel.visible = isSel;
                qulityKey = SkillQuality.Red;
                break;
            case this.owner.checkQulity2.boxClick:
                isSel = !this.owner.checkQulity2.checkBoxSel.visible;
                this.owner.checkQulity2.checkBoxSel.visible = isSel;
                qulityKey = SkillQuality.Orange;
                break;
            case this.owner.checkQulity3.boxClick:
                isSel = !this.owner.checkQulity3.checkBoxSel.visible;
                this.owner.checkQulity3.checkBoxSel.visible = isSel;
                qulityKey = SkillQuality.Purple;
                break;
            case this.owner.checkQulity4.boxClick:
                isSel = !this.owner.checkQulity4.checkBoxSel.visible;
                this.owner.checkQulity4.checkBoxSel.visible = isSel;
                qulityKey = SkillQuality.Blue;
                break;
            default:
                isSel = true;
                break;
        }
        app.service.skill.updateMapQualitySel(qulityKey, isSel);
        app.event(SystemEvent.SkILL.SKILL_MAP_SCREEN);
    }

    private onClickType(e: Laya.Event) {
        const target = e.currentTarget;
        let isSel = false;
        let typeKey = 0;
        switch (target) {
            case this.owner.checkType1.boxClick:
                isSel = !this.owner.checkType1.checkBoxSel.visible;
                this.owner.checkType1.checkBoxSel.visible = isSel;
                typeKey = USkillType.Ability;
                break;
            case this.owner.checkType2.boxClick:
                isSel = !this.owner.checkType2.checkBoxSel.visible;
                this.owner.checkType2.checkBoxSel.visible = isSel;
                typeKey = USkillType.Passive;
                break;
            case this.owner.checkType3.boxClick:
                isSel = !this.owner.checkType3.checkBoxSel.visible;
                this.owner.checkType3.checkBoxSel.visible = isSel;
                typeKey = USkillType.AssAult;
                break;
            case this.owner.checkType4.boxClick:
                isSel = !this.owner.checkType4.checkBoxSel.visible;
                this.owner.checkType4.checkBoxSel.visible = isSel;
                typeKey = USkillType.Cmmmand;
                break;
            case this.owner.checkType5.boxClick:
                isSel = !this.owner.checkType5.checkBoxSel.visible;
                this.owner.checkType5.checkBoxSel.visible = isSel;
                typeKey = USkillType.Internal_Affairs;
                break;
            default:
                isSel = true;
                break;
        }
        app.service.skill.updateMapTypeSel(typeKey, isSel);
        app.event(SystemEvent.SkILL.SKILL_MAP_SCREEN);
    }
}
