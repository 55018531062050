export const enum QualityValue {
    WHITE = 0,
    GREEN = 1,
    BLUE = 2,
    PURPLE = 3,
    ORANGE = 4,
    RED = 5,
}

export const enum QualityColor {
    WHITE = "#c6c6c7",
    GREEN = "#5dbead",
    BLUE = "#66afd7",
    PURPLE = "#8d71d0",
    ORANGE = "#cb7c41",
    RED = "#cf4646",
}

export const QualityColorArray = [
    QualityColor.WHITE,
    QualityColor.GREEN,
    QualityColor.BLUE,
    QualityColor.PURPLE,
    QualityColor.ORANGE,
    QualityColor.RED,
];

export const StyleConf = {
    // 消耗扣除，分别够和不够时的颜色显示
    COST_AMOUNT_COLOR: {
        NORMAL: "#ffffff", // 白色
        NOT_ENOUGH: "#ff7574", // 红色
        ENOUGH: "#88feae", // 绿色
    },
};
