import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { SystemEvent } from "../../misc/system-event";
import { UIPlotTheatreAlpha } from "./ui-runtime/UIPlotTheatreAlpha";

const { regClass, property } = Laya;

@regClass('jbOzzGkOQ-SEVhK3WdBW6A')
export class UIPlotTheatreAlphaMediator extends Mediator {
    declare owner: UIPlotTheatreAlpha;

    override onAwake(): void {
        super.onAwake();
        this.$(app).on(SystemEvent.ON_END_PLOT_THEATRE, this.onEndPlotTheatre, this);
    }

    private onEndPlotTheatre(): void {
        this.owner.close();
    }
}
