import * as ecs from "../../../../../core/ecs";
import { HeadInfoData, HeadInfoStyle, HeadInfoUI } from "../../../ui-runtime/pve/HeadInfoUI";

export class PveHeadInfoComponent extends ecs.Component {
    readonly mounter: Laya.Sprite = new Laya.Sprite();
    view?: HeadInfoUI | null;
    res?: string;

    data: HeadInfoData = {
        hp: 0,
        maxHp: 1,
        mp: 100,
        maxMp: 100,
        style: HeadInfoStyle.OUR,
        skinId: 0,
    };
}
