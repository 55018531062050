import * as ecs from "../../../../../core/ecs";
import { BattleEntityType } from "../../../../../def/auto/battle";
import { AnimName } from "../../../base/Animator";
import { PveServer } from "../../PveServer";
import { PveSvrUtils } from "../../PveSvrUtils";
import { PveSvrAiComponent } from "../components/PveSvrAiComponent";
import { PveSvrCreatureComponent } from "../components/PveSvrCreatureComponent";
import { ElementTag, PveSvrElementComponent } from "../components/PveSvrElementComponent";
import { PveSvrOffsetComponent } from "../components/PveSvrOffsetComponent";
import { PveSvrTransformComponent } from "../components/PveSvrTransformComponent";
import { PveSvrTroopComponent } from "../components/PveSvrTroopComponent";
import { PveSvrTruckComponent } from "../components/PveSvrTruckComponent";
import { PveSvrCollectSystem } from "./PveSvrCollectSystem";
import { PveSvrRevivalSystem } from "./PveSvrRevivalSystem";
import { PveSvrStateSystem } from "./PveSvrStateSystem";

export class PveSvrGameDefeatSystem extends ecs.System {
    /** 车上的资源进行入包 */
    public static truckAssetInPack(pveServer: PveServer): void {
        const troopComp = pveServer.ecs.getComponent(pveServer.troopEid, PveSvrTroopComponent)!;
        for (const etype in troopComp.truckEids) {
            const truckEid = troopComp.truckEids[etype];
            const truckComp = pveServer.ecs.getComponent(truckEid, PveSvrTruckComponent)!;
            if (truckComp.collectCnt > 0) {
                PveSvrCollectSystem.truckCollectionPutToPack(
                    pveServer,
                    pveServer.ctrlHeroEid,
                    truckComp.collectItemId,
                    truckComp.collectCnt
                );
            }
        }
    }

    public static heroRebornPos: Laya.Vector3 = new Laya.Vector3();

    /**
     * 英雄重生
     * @param resetPos 是否回到初始位置,不回到初始位置时，重生需要把已阵亡的士兵也给复活了，未死亡的士兵则补满血量
     *
     */
    public static heroReborn(pveServer: PveServer, resetPos: boolean) {
        // 主角回满血量
        const heroElement = pveServer.ecs.getComponent(
            pveServer.ctrlHeroEid,
            PveSvrElementComponent
        )!;
        const creatureComp = heroElement.getComponent(PveSvrCreatureComponent)!;
        creatureComp.hp = creatureComp.maxHp;
        const transformComp = heroElement.getComponent(PveSvrTransformComponent)!;
        if (resetPos) {
            PveSvrStateSystem.setHeroDefaultPositionAndRotation(pveServer, transformComp);
            this.heroRebornPos.cloneFrom(transformComp.position);
            pveServer.sender.updateTransform(
                pveServer.ctrlHeroEid,
                this.heroRebornPos,
                transformComp.rotation
            );
            pveServer.sender.playAnim(pveServer.ctrlHeroEid, AnimName.IDLE);
            pveServer.playAnim(heroElement.eid, AnimName.IDLE);
        }

        pveServer.sender.updateHp(pveServer.ctrlHeroEid, {
            hp: creatureComp.hp,
            maxHp: creatureComp.maxHp,
        });
        pveServer.playAnim(heroElement.eid, AnimName.IDLE);
        const ai = heroElement.addComponent(PveSvrAiComponent);
        ai.btree = `resources/data/btree/${heroElement.data.pve_ai}.json`;
        ai.active = false;

        // 重置位置时，和士兵资源车回到主角身边
        if (resetPos) {
            this.truckFlashToHeroPos(pveServer, heroElement);
            this.soldierFlashToHeroPos(pveServer, heroElement);
            this.truckAssetInPack(pveServer);
        } else {
            this.allSoldierRecoverHp(pveServer, heroElement);
            this.allSoldierReborn(pveServer, heroElement);
        }

        this.resumeAllAI(pveServer);
    }

    /** 暂停所有AI */
    public static pauseAllAI(pveServer: PveServer): void {
        const allAi = pveServer.ecs.getComponents(PveSvrAiComponent);
        const findTag = ElementTag.LEADER | ElementTag.MEMBER;
        allAi.forEach((ai) => {
            const ele = ai.getComponent(PveSvrElementComponent)!;
            if (ele.tag & findTag) {
                PveSvrUtils.interuptAITree(ai);
                ai.active = false;
            }
        });
    }

    /** 所有AI恢复 */
    public static resumeAllAI(pveServer: PveServer): void {
        const findTag = ElementTag.LEADER | ElementTag.MEMBER;
        const allAi = pveServer.ecs.getComponents(PveSvrAiComponent);
        allAi.forEach((ai) => {
            const ele = ai.getComponent(PveSvrElementComponent)!;
            if (ele.tag & findTag) {
                ai.active = true;
            }
        });
    }

    /**
     * 资源车回到主角的位置
     */
    public static truckFlashToHeroPos(pveServer: PveServer, heroEle: PveSvrElementComponent): void {
        const troopComp = PveSvrUtils.getTroopByLeaderEid(pveServer, heroEle.eid)!;
        const heroTransformComp = heroEle.getComponent(PveSvrTransformComponent)!;

        for (const etype in troopComp.truckEids) {
            const truckEid = troopComp.truckEids[etype];
            const truckComp = pveServer.ecs.getComponent(truckEid, PveSvrTruckComponent)!;

            const offsetComp = truckComp.getComponent(PveSvrOffsetComponent)!;
            const truckTransformComp = offsetComp.getComponent(PveSvrTransformComponent)!;

            pveServer.calcFollowerPosition(
                heroEle.transform,
                offsetComp.offset,
                truckTransformComp.position
            );
            truckTransformComp.rotation = heroTransformComp.rotation;
            const isBlock = pveServer.isBlockAt(
                truckTransformComp.position.x,
                truckTransformComp.position.z,
                false
            );
            if (isBlock) {
                truckTransformComp.position.cloneFrom(heroTransformComp.position);
            }

            pveServer.sender.updateTransform(
                truckTransformComp.eid,
                truckTransformComp.position,
                truckTransformComp.rotation
            );
            pveServer.sender.playAnim(truckTransformComp.eid, AnimName.IDLE);
        }
    }

    /** 士兵回到主角的位置 */
    public static soldierFlashToHeroPos(
        pveServer: PveServer,
        heroEle: PveSvrElementComponent
    ): void {
        const troopEid = heroEle.troopEid;
        const heroTransformComp = heroEle.getComponent(PveSvrTransformComponent)!;
        const offsets = pveServer.ecs.getComponents(PveSvrOffsetComponent);
        for (const [k, v] of offsets) {
            if (v.etype === BattleEntityType.SOLDIER && v.troopEid === troopEid) {
                // 士兵随处
                const soldierTransformComp = v.getComponent(PveSvrTransformComponent)!;
                pveServer.calcFollowerPosition(
                    heroEle.transform,
                    v.offset,
                    soldierTransformComp.position
                );
                soldierTransformComp.rotation = heroTransformComp.rotation;
                const isBlock = pveServer.isBlockAt(
                    soldierTransformComp.position.x,
                    soldierTransformComp.position.z,
                    false
                );
                if (isBlock) {
                    soldierTransformComp.position.cloneFrom(heroTransformComp.position);
                }
                pveServer.sender.updateTransform(
                    soldierTransformComp.eid,
                    soldierTransformComp.position,
                    soldierTransformComp.rotation
                );
                pveServer.sender.playAnim(soldierTransformComp.eid, AnimName.IDLE);
            }
        }
    }

    /** 所有士兵回血 */
    public static allSoldierRecoverHp(pveServer: PveServer, heroEle: PveSvrElementComponent): void {
        const offsets = pveServer.ecs.getComponents(PveSvrOffsetComponent);
        for (const [k, v] of offsets) {
            if (v.etype === BattleEntityType.SOLDIER && v.troopEid === heroEle.troopEid) {
                // 士兵随处
                const creatureComp = v.getComponent(PveSvrCreatureComponent)!;
                creatureComp.hp = creatureComp.maxHp;
                pveServer.sender.updateHp(k, {
                    hp: creatureComp.hp,
                    maxHp: creatureComp.maxHp,
                });
            }
        }
    }

    /** 复活所有阵亡士兵 */
    public static allSoldierReborn(pveServer: PveServer, heroEle: PveSvrElementComponent): void {
        const revivalSys = pveServer.ecs.getSystem(PveSvrRevivalSystem)!;
        const soldierArr = revivalSys.getNeedRebornSoldierArr();
        soldierArr.forEach((s) => {
            this.rebornSoldierOnHeroPos(pveServer, s.soldierId, heroEle, s.idx);
        });
    }

    /** 在英雄的位置复活士兵 */
    private static rebornSoldierOnHeroPos(
        pveServer: PveServer,
        soldierId: number,
        heroEle: PveSvrElementComponent,
        posIndex: number
    ): void {
        if (posIndex < 0) return;
        const transformComp = heroEle.getComponent(PveSvrTransformComponent)!;
        const px = transformComp.position.x;
        const pz = transformComp.position.z;
        pveServer.addSoldierWith(heroEle, posIndex, soldierId, px, pz);
    }
}
