/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/common/dialog/BaseRoundWindowBg.lh
 */
export class BaseRoundWindowBgBase extends Laya.Box {
    public title!: Laya.Label;
}

