const { regClass } = Laya;
import proto from "../../../def/auto/proto";
import { SkillDetailsUIBase } from "./SkillDetailsUI.generated";

export interface SkillDetailsUIArgs {
    skillData: proto.hero.SkillInfo;
    isReplace: boolean;
    heroUid: number;
    skillPos: number;
    sysType: number;
    isActive: boolean;
}
@regClass('wHV1o2qwQcqExx0h30JDuA')
export class SkillDetailsUI extends SkillDetailsUIBase {
    public data!: proto.hero.SkillInfo;
    public skillPos: number = 0;
    public isReplace: boolean = true;
    public heroUid: number = 0;
    public sysType: number = 0; //是哪个技能的系统
    public isActive: boolean = true;

    override open(closeOther?: boolean, param?: SkillDetailsUIArgs): void {
        this.data = param!.skillData;
        this.isReplace = param!.isReplace;
        this.heroUid = param!.heroUid;
        this.skillPos = param!.skillPos;
        this.sysType = param!.sysType;
        this.isActive = param!.isActive;
        super.open(closeOther, param);
    }
}
