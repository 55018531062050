/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/battle/pve-ui/build/bd_production_info.lh
 */
export class BDProductionInfoUIBase extends Laya.Box {
    public progressBar!: Laya.ProgressBar;
    public img_icon!: Laya.Image;
    public img_full!: Laya.Image;
}

