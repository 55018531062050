import * as ecs from "../../../../../core/ecs";
import { res } from "../../../../../misc/res";
import { NpcOperationConfirmUI } from "../../../ui-runtime/pve/npc/NpcOperationConfirmUI";

/**
 * 查看确认UI组件
 */
export class PveUINpcOperationConfirmComponent extends ecs.Component {
    readonly mounter: Laya.Sprite = new Laya.Sprite();

    /** 对应对白表的id */
    public plotID: number = 0;

    view?: NpcOperationConfirmUI | null;

    /** 资源路径 */
    public get res(): string {
        return res.BATTLE_NPC_OP_CONFIRM;
    }
}
