import { app } from "../../../../../app";
import { Callback } from "../../../../../core/dispatcher";
import * as ecs from "../../../../../core/ecs";
import { BattleConf } from "../../../../../def/auto/battle";
import { StyleConf } from "../../../../../def/style";
import { SystemEvent } from "../../../../../misc/system-event";
import { PlotDialogueWinMediator } from "../../../../plot/PlotDialogueWinMediator";
import { PveContext } from "../../PveContext";
import { PveElementComponent } from "../components/PveElementComponent";
import { PveUIRescueComponent } from "../components/PveUIRescueComponent";

const tempVector3 = new Laya.Vector3();
const tempVector4 = new Laya.Vector4();

/** 士兵救援系统 */
export class PveRescueConfirmSystem extends ecs.System {
    declare context: PveContext;

    override onCreate(): void {
        this._on(SystemEvent.PVE.ON_RESCUE_ENTER, this.onPveRescueEnter);
        this._on(SystemEvent.PVE.ON_RESCUE_LEAVE, this.onPveRescusLeave);
        this._on(SystemEvent.PVE.ON_UI_RESCUE_LOADED, this.onPveLoadRescueConfirmComp);
        this._on(SystemEvent.USER_MONEY_UPDATE, this.onUserMoneyUpdate);
        this.registerHandler(
            PveUIRescueComponent,
            this._onAddPveUIRescueComponent,
            this._onDelPveUIRescueComponent
        );
    }

    private lastShowPlotTime: number = 0;

    private async _onAddPveUIRescueComponent(comp: PveUIRescueComponent) {
        const cost = comp.eventVo.cost;
        if (comp.eventVo.key === BattleConf.PVE.RESCUS_INSUFFICIENT_RES_EVENT_ID) {
            if (cost) {
                const needAmount = cost[0].num;
                const rewardCode = cost[0].id;
                const amountObj = app.service.pve.mlData.getCurrentAssetAmount(rewardCode);
                const curHasAmount = amountObj.packAmount + amountObj.truckAmount;
                if (curHasAmount < needAmount) {
                    const curTime = this.context.time;
                    const timeDelta = curTime - this.lastShowPlotTime;
                    if (this.lastShowPlotTime === 0 || timeDelta >= 6) {
                        this.lastShowPlotTime = curTime;
                        // 不够资源进行救援,进行提示
                        const plotId = BattleConf.PVE.RESCUS_INSUFFICIENT_RES_PLOT;
                        const eid = this.context.ctrlHeroEid;
                        this.context.sender.joystickStop(eid, false);
                        PlotDialogueWinMediator.showPlot(plotId);
                    }
                }
            }
        }
    }

    private onUserMoneyUpdate(): void {
        const comps = this.ecs.getComponents(PveUIRescueComponent);
        comps.forEach((c) => {
            this.onPveLoadRescueConfirmComp(c.eid);
        });
    }

    private _onDelPveUIRescueComponent(comp: PveUIRescueComponent) {}

    private _on(event: string, callback: Callback) {
        this.context.$(app).on(event, callback, this);
    }

    private onPveLoadRescueConfirmComp(eid: number): void {
        // 初始化刷新显示UI
        const comp = this.ecs.getComponent(eid, PveUIRescueComponent);
        if (!comp || !comp.view) return;
        const v = comp.view;
        const cost = comp.eventVo.cost;
        if (cost) {
            const rewardCode = cost[0].id;
            const itemLo = app.service.bag.itemLoMap.get(rewardCode);
            v.costIcon.skin = itemLo.iconUrl; // costIcon 是 Image
            const needAmount = cost[0].num;
            // 通过身上的资源数量 改变文本颜色的显示
            v.needCostLabel.text = needAmount + "";

            const amountObj = app.service.pve.mlData.getCurrentAssetAmount(rewardCode);
            const curHasAmount = amountObj.packAmount + amountObj.truckAmount;
            if (curHasAmount >= needAmount) {
                // 足够
                v.needCostLabel.color = StyleConf.COST_AMOUNT_COLOR.ENOUGH;
                v.rescueButton.gray = false;
            } else {
                // 不够
                v.needCostLabel.color = StyleConf.COST_AMOUNT_COLOR.NOT_ENOUGH;
                v.rescueButton.gray = true;
            }
        }
    }

    private onPveRescueEnter(eid: number): void {
        const element = this.ecs.getComponent(eid, PveElementComponent);
        if (element) {
            const eventVo = app.service.pve.eventLoMap.get(element.tid)!;
            const comp = element.addComponent(PveUIRescueComponent);
            comp.eventVo = eventVo;
        }
    }

    private onPveRescusLeave(eid: number): void {
        const element = this.ecs.getComponent(eid, PveElementComponent);
        if (element) {
            element.removeComponent(PveUIRescueComponent);
        }
    }

    override update(dt: number): void {
        this.ecs.getComponents(PveUIRescueComponent).forEach((component) => {
            if (component.view) {
                const element = component.getComponent(PveElementComponent)!;
                const v = component.mounter;
                // 更新位置
                const pos = element.transform.position;
                tempVector3.cloneFrom(pos);
                tempVector3.y = 1.0;
                this.context.camera.worldToViewportPoint(tempVector3, tempVector4);
                v.pos(tempVector4.x, tempVector4.y);
            }
        });
    }
}
