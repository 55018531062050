import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrCreatureComponent } from "../../ecs/components/PveSvrCreatureComponent";

export class GetHp extends Process {
    override init(node: Node): void {}

    override run(node: Node, env: AiTreeEnv): Status {
        const [targetEid] = env.input as [number?];
        const eid = targetEid ?? env.owner.eid;
        const creature = env.context.ecs.getComponent(eid, PveSvrCreatureComponent);
        if (!creature) {
            node.warn(`creature not found ${eid}`);
            return "failure";
        }
        env.output.push(creature.hp, creature.maxHp);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "GetHp",
            type: "Action",
            desc: "获取目标的血量和最大血量。",
            args: [],
            input: ["输入目标?"],
            output: ["当前血量", "最大血量"],
            doc: `
                优先读取输入变量，否则读取本技能树所属实体。
            `,
        };
    }
}
