import * as ecs from "../../../../../core/ecs";
import { res } from "../../../../../misc/res";
import { RescueSoldierConfirmUI } from "../../../ui-runtime/pve/RescueSoldierConfirmUI";
import { BattleEventLo } from "../../vo/BattleEventLo";

/**
 * 救援确认UI组件
 */
export class PveUIRescueComponent extends ecs.Component {
    readonly mounter: Laya.Sprite = new Laya.Sprite();

    view?: RescueSoldierConfirmUI | null;

    eventVo!: BattleEventLo;

    /** 资源路径 */
    public get res(): string {
        return res.BATTLE_RESCUE_SOLDIER_CONFIRM;
    }
}
