import { app } from "../../../../../../app";
import { Pool } from "../../../../../../core/pool";
import { mainline } from "../../../../../../def/auto/proto";
import { BattleBuildingRow } from "../../../../../../def/table";
import { TableUtil } from "../../../../../table/TableUtil";

/** 城建 */
export class PveSvrBuildingStateData {
    key: number = 0; //需要记录

    /** 对应表 battle/battle_building.xlsx */
    tid: number = 0; // 建筑的初始tid

    inView: boolean = false;

    /** 根据等级的配置 */
    public get levelCfg(): BattleBuildingRow {
        const default_level = this.level;
        let cfg = app.service.table.battleBuilding[this.tid];
        if (default_level < 1) {
            return cfg;
        }
        const battle_entity = cfg.battle_entity;
        cfg = TableUtil.getRow(app.service.table.battleBuilding, { battle_entity, default_level })!;
        return cfg;
    }

    /** 该建筑当前等级 */
    level: number = 0;

    /** 开始升级的时间戳 */
    upgradeTime: number = 0;

    /** 上次收获的时间（生产类型的建筑使用） */
    harvestTime:number = 0;

    encode(): mainline.Building {
        const data = Pool.obtain(mainline.Building);
        data.key = this.key;
        data.tid = this.tid;
        data.level = this.level;
        data.upgradeTime = this.upgradeTime;
        data.harvestTime = this.harvestTime;
        return data;
    }

    decode(data: mainline.Building) {
        this.key = data.key;
        this.tid = data.tid ?? 0;
        this.level = data.level;
        this.upgradeTime = data.upgradeTime ?? 0;
        this.harvestTime = data.harvestTime ?? 0;
    }
}
