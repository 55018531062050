const { regClass } = Laya;
import { InteractiveBubbleActionType, InteractiveBubbleViewType } from "../../pve-server/PveDefs";
import { PveContext } from "../../pve/PveContext";
import { PveUICloudComponent } from "../../pve/ecs/components/PveUICloudComponent";
import { CloudConfirmUIBase } from "./CloudConfirmUI.generated";
import { IInteractiveBubbleView } from "./IInteractiveBubbleView";

@regClass('Ubj33CbLSheAFrsvGcXcTQ')
export class CloudConfirmUI extends CloudConfirmUIBase implements IInteractiveBubbleView {
    context: PveContext | undefined;

    comp: PveUICloudComponent | undefined;

    get viewType(): InteractiveBubbleViewType {
        return InteractiveBubbleViewType.CLOUD_INTERACTIVE_VIEW;
    }

    get actionTypes(): InteractiveBubbleActionType[] {
        return [InteractiveBubbleActionType.CLOUD_INTERACTIVE_ACTION_1];
    }

    override onAwake(): void {
        super.onAwake();
        this.button1.on(Laya.Event.CLICK, this, this.onButton1Click);
    }

    async onButton1Click() {
        if (!this.context || !this.comp) return;

        this.context.sender.interactiveBubbleAction(
            this.comp.eid,
            this.viewType,
            this.actionTypes[0],
            null
        );
        this.comp.removeComponent(PveUICloudComponent);
    }

    override onDestroy(): void {
        this.comp = undefined;
        this.context = undefined;
    }
}
