/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/battle/pve-ui/build/building-confirm.lh
 */
export class BuildingConfirmUIBase extends Laya.Box {
    public button1!: Laya.Button;
}

