import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { PveDef } from "../../PveDefs";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";

interface NodeArgs {
    readonly multi?: boolean;
}

export class GetSkillTarget extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const targets = env.get(PveDef.SKILL_TARGET_EIDS) as number[];
        const args = node.args as unknown as NodeArgs;
        if (args.multi) {
            env.output.push(targets);
        } else {
            env.output.push(targets[0]);
        }
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "GetSkillTarget",
            type: "Action",
            desc: "获取技能目标",
            args: [{ name: "multi", type: "boolean?", desc: "多个目标" }],
            output: ["目标单位"],
            doc: `
                + 此节点只能在技能行为树使用!
                + 默认返回单个目标
                + 如果勾选多个目标，返回数组
            `,
        };
    }
}
