import * as ecs from "../../../../../core/ecs";
import { ActionMenuUI } from "../../../ui-runtime/pvp/ActionMenuUI";
import { ResourceInfoUI } from "../../../ui-runtime/pvp/ResourceInfoUI";

export class PvpUIComponent extends ecs.SingletonComponent {
    actionMenu?: ActionMenuUI;
    resourceInfo?: ResourceInfoUI;

    selectedTile!: Laya.Sprite3D;
    debugFocus!: Laya.Sprite3D;
}
