/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/bag/UIItemGetWayItem.lh
 */
export class UIItemGetWayItemBase extends Laya.Box {
    public imgBg!: Laya.Image;
    public imgIcon!: Laya.Image;
    public labName!: Laya.Label;
    public boxGo!: Laya.Box;
}

