import { app } from "../../../../../app";
import { TreeRunner } from "../../../../../core/behavior3";
import * as ecs from "../../../../../core/ecs";
import { SystemEvent } from "../../../../../misc/system-event";
import { PveServer } from "../../PveServer";
import { PveSvrUtils } from "../../PveSvrUtils";
import { PveSvrBuffComponent } from "../components/PveSvrBuffComponent";
import { PveSvrCreatureComponent } from "../components/PveSvrCreatureComponent";
import { BuffTreeEnv, BuffTreeState } from "../data/buff/BuffBase";

export class PveSvrSkillSystem extends ecs.System {
    declare context: PveServer;

    override onCreate(): void {
        this.registerHandler(
            PveSvrBuffComponent,
            this._onAddPveSvrBuffComponent,
            this._onDelPveSvrBuffComponent
        );

        app.on(SystemEvent.PVE.ON_OUT_OF_FIGHT, this, this._onOutOfFight);
    }

    override onDestroy(): void {
        app.offAllCaller(this);
    }

    override update(dt: number) {
        const pveSvr = this.context;
        const time = pveSvr.time;
        const buffComps = pveSvr.ecs.getComponents(PveSvrBuffComponent);
        buffComps.forEach((buffComp) => {
            // buff data过期的直接干掉
            buffComp.buffs.forEach((buffArr) => {
                buffArr.forEach((buff) => {
                    if (buff.overdueTime >= 0 && time >= buff.overdueTime) {
                        buffArr.splice(buffArr.indexOf(buff), 1);
                    }
                });
            });

            buffComp.buffTrees
                .filter((v) => v.state === BuffTreeState.TRY_LOAD)
                .forEach(async (buffTree) => {
                    buffTree.state = BuffTreeState.LOADING;
                    const checker = buffComp.checker;
                    const tree = await pveSvr.loadAiTree(buffTree.btree);
                    if (
                        buffComp.buffTrees.indexOf(buffTree) < 0 ||
                        buffTree.state !== BuffTreeState.LOADING
                    ) {
                        // 异步回来后已经不合法了
                        return;
                    }
                    if (!checker()) {
                        // 检查是否还合法
                        return;
                    }
                    if (buffTree.data.out_fight_cancel === 1) {
                        // 脱战取消buff
                        const cretaureComp = buffComp.getComponent(PveSvrCreatureComponent);
                        if (cretaureComp && cretaureComp.isOutOfFight) {
                            return;
                        }
                    }

                    buffTree.tree = new TreeRunner(
                        new BuffTreeEnv(pveSvr, buffComp.eleComp, buffTree.skill, buffTree),
                        tree
                    );
                    buffTree.state = BuffTreeState.RUNNING;
                    buffTree.tree.run();
                });

            for (let i = buffComp.buffTrees.length - 1; i >= 0; i--) {
                const buffTree = buffComp.buffTrees[i];
                if (buffTree.overdueTime >= 0 && time >= buffTree.overdueTime) {
                    PveSvrUtils.onClearOneRuningBuffTree(buffTree);
                    buffComp.buffTrees.splice(i, 1);
                }
            }
        });
    }

    private _onOutOfFight(eid: number) {
        const cretureComp = this.context.ecs.getComponent(eid, PveSvrCreatureComponent);
        if (!cretureComp) {
            console.error("_onOutOfFight: creature not found", eid); //todo: 按理来说这个不会出现才对
            return;
        }
        // 清除所有脱战会清除的buffTree
        for (let i = cretureComp.buffComp.buffTrees.length - 1; i >= 0; i--) {
            const tree = cretureComp.buffComp.buffTrees[i];
            if (tree.data.out_fight_cancel === 1) {
                PveSvrUtils.onClearOneRuningBuffTree(tree);
                cretureComp.buffComp.buffTrees.splice(i, 1);
            }
        }
    }

    private _onAddPveSvrBuffComponent(comp: PveSvrBuffComponent): void {}

    private _onDelPveSvrBuffComponent(buffComp: PveSvrBuffComponent): void {
        // 清除所有buffTree
        for (let i = buffComp.buffTrees.length - 1; i >= 0; i--) {
            const tree = buffComp.buffTrees[i];
            PveSvrUtils.onClearOneRuningBuffTree(tree);
        }
    }
}
