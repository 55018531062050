import { app } from "../../../../app";
import { RewardProb, SpoilsRow } from "../../../../def/table";
import { Vo } from "../../../../misc/vo/Vo";
import { ItemLo } from "../../../bag/vo/ItemLo";
import { PveDef } from "../../pve-server/PveDefs";

export class BattleSpoilsLo extends Vo<SpoilsRow, never> {
    override get key(): number {
        return this._config.id;
    }

    override clone(): Vo<SpoilsRow, never> {
        return new BattleSpoilsLo(this._config);
    }

    public get reward(): RewardProb[] {
        return this._config.reward;
    }

    /** 是否是宝箱 */
    public get isChestBox(): boolean {
        const itemId = this._config.item_id;
        const chestRow = app.service.table.chest.chest[itemId];
        if (chestRow) {
            return true;
        }
        return false;
    }

    public get itemLo(): ItemLo {
        return app.service.bag.itemLoMap.get(this._config.item_id);
    }

    public get rewardAmount(): number {
        return this._config.reward[0].num;
    }

    /** 是否是石头 */
    public get isStone(): boolean {
        return this.itemLo.key === PveDef.COLLECT_STONE_ID;
    }
}
