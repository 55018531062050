import * as ecs from "../../../../../core/ecs";
import { IReusable, Pool } from "../../../../../core/pool";
import { BattleAid } from "../../../../../def/auto/battle";
import { BattleEntityRow } from "../../../../../def/table";
import { PveSvrTransformComponent } from "./PveSvrTransformComponent";

/**
 * 主要作用于目标快速筛选，如果对象不参与筛选，可以不用设置
 */
export const enum ElementTag {
    NONE = 0,
    /** 玩家单位 */
    PLAYER = 1 << 0,
    /** 首领 */
    LEADER = 1 << 1,
    /** 队员 */
    MEMBER = 1 << 2,
    /** 资源车 */
    TRUCK = 1 << 3,
    /** 木头 */
    WOOD = 1 << 4,
    /** 粮草 */
    FOOD = 1 << 5,
    /** 石头 */
    STONE = 1 << 6,
    /** 击杀boss/小怪掉落的战利品 */
    SPOILS = 1 << 7,
    // /** 精英怪 */
    // BOSS = 1 << 8,
}

/**
 * 需要被渲染的组件，该组件会传给PVE进行渲染
 * 生命周期跟随Entity
 *
 * 必然同时存在的组件：PveSvrTransformComponent
 */
@Pool.reusable
export class PveSvrElementComponent extends ecs.Component implements IReusable {
    override get dependencies() {
        return [PveSvrTransformComponent];
    }

    __unuse(): void {
        this.data = null!;
        this.tag = ElementTag.NONE;
        this.key = 0;
        this.ownerRid = -1;
        this.aid = BattleAid.NEUTRAL;
    }

    __reuse() {}

    tag: ElementTag = ElementTag.NONE;

    data!: BattleEntityRow;

    // 在表格中的定义
    tid: number = 0;

    // 阵营, 默认是中立
    aid: BattleAid = BattleAid.NEUTRAL;

    // 用于目标按距离排序
    tmpDistance: number = 0;

    troopEid: number = 0; //0表示没有部队，其他表示部队troop eid

    // 出生点
    readonly spawnpoint: Laya.Vector3 = new Laya.Vector3();

    // 按出点生成的关键信息
    key: number = 0;

    /**
     * 所属玩家角色id
     * -1 代表不属于玩家
     */
    ownerRid: number = -1;

    hasTag(tag: ElementTag): boolean {
        return (this.tag & tag) === tag;
    }

    // 有组件依赖测试的，可以使用这种方式，PveSvrElementComponent必然同时存在PveSvrTransformComponent
    get transform() {
        return this.getComponent(PveSvrTransformComponent)!;
    }

    get gridPos(): { xIndex: number; yIndex: number } {
        const pos = this.transform.position;
        return {
            xIndex: Math.floor(pos.x),
            yIndex: Math.floor(pos.z),
        };
    }
}
