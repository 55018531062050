/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { BaseMidItemIcon } from "../../common/ui-runtime/item-icon/BaseMidItemIcon";

/**
 * resources/prefab/bag/BagItem.lh
 */
export class BagItemBase extends Laya.Box {
    public itemIcon!: BaseMidItemIcon;
    public imgRedPoint!: Laya.Image;
    public labelNumber!: Laya.Label;
}

