import { app } from "../../../app";
import { VoMap } from "../../../misc/vo/VoMap";
import { TableUtil } from "../../table/TableUtil";
import { HeroLo } from "./HeroLo";

export class HeroLoMap extends VoMap<number, HeroLo> {
    override get(id: number): HeroLo {
        let ret = super.get(id);
        if (!ret) {
            const row = TableUtil.getRow(app.service.table.hero.hero, { id: id })!;
            if (!row) {
                throw new Error(`no hero prop id: ${id}`);
            }
            ret = new HeroLo(row);
            this.set(id, ret);
        }
        return ret;
    }
}
