import { app } from "../../../app";
import { VoMap } from "../../../misc/vo/VoMap";
import { BattleSceneConfigVo } from "./BattleSceneConfigVo";

export class BattleSceneConfigVoMap extends VoMap<number, BattleSceneConfigVo> {
    override get(key: number): BattleSceneConfigVo {
        let vo = super.get(key);
        if (!vo) {
            vo = new BattleSceneConfigVo(app.service.table.battle.scene[key - 1]);
            super.set(key, vo);
        }
        return vo;
    }
}
