const { regClass } = Laya;
import { PveContext } from "../../../pve/PveContext";
import { PveUICommonInfoComponent } from "../../../pve/ecs/components/PveUICommonInfoComponent";
import { BDCommonInfoUIBase } from "./BDCommonInfoUI.generated";

@regClass('758MN2DQRdqTmrVQeHw3aQ')
export class BDCommonInfoUI extends BDCommonInfoUIBase {
    context: PveContext | undefined;

    comp: PveUICommonInfoComponent | undefined;
}
