import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { BattleConf } from "../../../../../def/auto/battle";
import { PveServer } from "../../PveServer";
import { PveSvrBattle } from "../../PveSvrBattle";
import { SkillSystemUtils } from "../../utils/skill/SkillSystemUtils";
import { PveSvrElementComponent } from "../components/PveSvrElementComponent";
import { MovementState, PveSvrMovementComponent } from "../components/PveSvrMovementComponent";
import { PveSvrTransformComponent } from "../components/PveSvrTransformComponent";
import { PveSvrMapTransferSystem } from "./PveSvrMapTransferSystem";
export class PveSvrTerritorySystem extends ecs.System {
    declare context: PveServer;

    /** 检查是否在领地间隔 */
    private static readonly CHECK_IN_TERRITORY_DELAY = 1.0;
    private _lastTickCheckInTerritoryDelay: number = 0;

    override update(dt: number): void {
        this.checkInTerritoryUpdate();
    }

    /** 是否在领域内，未知为undefined */
    private _isInTerritory: boolean | undefined = undefined;

    /** 检查是否在领地的轮询 */
    private checkInTerritoryUpdate(): void {
        const time = this.context.time;
        if (
            time - this._lastTickCheckInTerritoryDelay <
            PveSvrTerritorySystem.CHECK_IN_TERRITORY_DELAY
        ) {
            return;
        }
        this._lastTickCheckInTerritoryDelay = time;

        const heroEleComp = this.context.ecs.getComponent(
            this.context.ctrlHeroEid,
            PveSvrElementComponent
        );
        if (!heroEleComp) {
            this._isInTerritory = undefined;
            return;
        }

        const transformComp = heroEleComp.getComponent(PveSvrTransformComponent)!;
        const x = transformComp.position.x;
        const z = transformComp.position.z;

        const pveStateData = app.service.pve.mlData.pveStateData;
        const isOpenTerritory = pveStateData.isOpenTerritory;
        if (!isOpenTerritory) {
            // 未开启领域
            this._isInTerritory = undefined;
            return;
        }

        const inTerritory = app.service.pve.minimap.checkInTerritory(x, z);
        if (this._isInTerritory !== inTerritory) {
            this._isInTerritory = inTerritory;
            this.context.sender.setEnterLeaveTerritory(inTerritory);
        }
    }

    public static async onClickBackToTerritory(pveServer: PveServer) {
        // app.ui.toast("TODO:点击回领域");
        const heroEleComp = pveServer.ecs.getComponent(
            pveServer.ctrlHeroEid,
            PveSvrElementComponent
        );
        if (!heroEleComp) {
            return;
        }
        const transformComp = heroEleComp.getComponent(PveSvrTransformComponent)!;
        const x = transformComp.position.x;
        const z = transformComp.position.z;
        const inTerritory = app.service.pve.minimap.checkInTerritory(x, z);
        if (inTerritory) {
            app.ui.toast("已处于领域内！");
            return;
        }

        const movementComp = heroEleComp.getComponent(PveSvrMovementComponent);
        if (movementComp && movementComp.state !== MovementState.STOP) {
            app.ui.toast("移动中不可回城！");
            return;
        }

        const isImprisonment = SkillSystemUtils.isImprisonment(pveServer, heroEleComp.eid);
        if (isImprisonment) {
            // 已经处于禁锢状态，当前不可操作。
            return;
        }

        // .脱战才可回城
        const isLeaveBattle = PveSvrBattle.checkIsOutOfFight(pveServer, heroEleComp.eid);
        if (!isLeaveBattle) {
            app.ui.toast("脱战后才可回城！");
            return;
        }

        const transferPos = app.service.pve.minimap.getTransferPos(
            BattleConf.PVE.TERRITORY_TRANSFER_ID
        );
        if (!transferPos) {
            app.ui.toast("领域传送点未配置！");
            return;
        }

        await PveSvrMapTransferSystem.tpTransfer(pveServer, heroEleComp, transferPos);
    }

    public static async TPWaitTime(pveServer: PveServer, time: number) {
        await new Promise((resolve) => {
            pveServer.ecs.delay(time, "TPWaitTime", () => {
                resolve(1);
            });
        });
    }
}
