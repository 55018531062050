/** 程序化动画二阶系统 */
export class SecondOrderDynamics {
    private xp: Laya.Vector2 = new Laya.Vector2(0, 0);
    private y: Laya.Vector2 = new Laya.Vector2(0, 0);
    private yd: Laya.Vector2 = new Laya.Vector2(0, 0);
    private k1: number = 0;
    private k2: number = 0;
    private k3: number = 0;

    /**
     * @param f 快慢
     * @param z 阻尼
     * @param r 初始响应
     * 参数配置参考 https://www.vp666.cn/maths/curve/essay08/
     */
    init(f: number, z: number, r: number, xp: number, py: number): void {
        const PI = Math.PI;
        this.k1 = z / (PI * f);
        this.k2 = 1 / (2 * PI * f * (2 * PI * f));
        this.k3 = (r * z) / (2 * PI * f);
        this.xp.x = xp;
        this.xp.y = py;
        this.y.x = xp;
        this.y.y = py;
        this.yd.x = 0;
        this.yd.y = 0;
    }

    /**
     * T:每帧经过的时间
     *
     */
    update(T: number, x_x: number, x_y: number, xd: Laya.Vector2 | null = null): Laya.Vector2 {
        const xd_x = (x_x - this.xp.x) / T;
        const xd_y = (x_y - this.xp.y) / T;
        this.xp.x = x_x;
        this.xp.y = x_y;

        const k2_stable = Math.max(this.k2, (T * T) / 2 + (T * this.k1) / 2, T * this.k1);
        this.y.x = this.y.x + T * this.yd.x;
        this.y.y = this.y.y + T * this.yd.y;
        this.yd.x =
            this.yd.x + (T * (x_x + this.k3 * xd_x - this.y.x - this.k1 * this.yd.x)) / k2_stable;
        this.yd.y =
            this.yd.y + (T * (x_y + this.k3 * xd_y - this.y.y - this.k1 * this.yd.y)) / k2_stable;
        return this.y;
    }
}

/** 程序化动画二阶系统 */
export class SecondOrderDynamicsV3 {
    private vp: Laya.Vector3 = new Laya.Vector3(0, 0, 0);
    private y: Laya.Vector3 = new Laya.Vector3(0, 0, 0);
    private yd: Laya.Vector3 = new Laya.Vector3(0, 0, 0);
    private k1: number = 0;
    private k2: number = 0;
    private k3: number = 0;

    /**
     * @param f 快慢
     * @param z 阻尼
     * @param r 初始响应
     * 参数配置参考 https://www.vp666.cn/maths/curve/essay08/
     */
    init(f: number, z: number, r: number, p_x: number, p_y: number, p_z: number): void {
        const PI = Math.PI;
        this.k1 = z / (PI * f);
        this.k2 = 1 / (2 * PI * f * (2 * PI * f));
        this.k3 = (r * z) / (2 * PI * f);
        this.vp.x = p_x;
        this.vp.y = p_y;
        this.vp.z = p_z;

        this.y.x = p_x;
        this.y.y = p_y;
        this.y.z = p_z;
        this.yd.x = 0;
        this.yd.y = 0;
        this.yd.z = 0;
    }

    /**
     * T:每帧经过的时间
     *
     */
    update(T: number, v_x: number, v_y: number, v_z: number): Laya.Vector3 {
        const xd_x = (v_x - this.vp.x) / T;
        const xd_y = (v_y - this.vp.y) / T;
        const xd_z = (v_z - this.vp.z) / T;
        this.vp.x = v_x;
        this.vp.y = v_y;
        this.vp.z = v_z;

        const k2_stable = Math.max(this.k2, (T * T) / 2 + (T * this.k1) / 2, T * this.k1);
        this.y.x = this.y.x + T * this.yd.x;
        this.y.y = this.y.y + T * this.yd.y;
        this.y.z = this.y.z + T * this.yd.z;
        this.yd.x =
            this.yd.x + (T * (v_x + this.k3 * xd_x - this.y.x - this.k1 * this.yd.x)) / k2_stable;
        this.yd.y =
            this.yd.y + (T * (v_y + this.k3 * xd_y - this.y.y - this.k1 * this.yd.y)) / k2_stable;
        this.yd.z =
            this.yd.z + (T * (v_z + this.k3 * xd_z - this.y.z - this.k1 * this.yd.z)) / k2_stable;
        return this.y;
    }
}
