import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { SystemEvent } from "../../misc/system-event";
import { ui } from "../../misc/ui";
import { CharacterSwitchTab } from "../hero/ui-runtime/character/CharacterSwitchTab";
import { UISkilMain } from "./ui-runtime/UISkilMain";

const { regClass, property } = Laya;

@regClass('OrD6NUQDRxyskEdKHLxVCA') //技能主界面
export class UISkilllMainMediator extends Mediator {
    declare owner: UISkilMain;

    override onAwake(): void {
        super.onAwake();
        this.owner.bgWin.setTitle("技能");
        this.owner.btn_close.on(Laya.Event.CLICK, this.owner, this.owner.destroy);
        this.initServiecEvent();
        this.initUIEvent();
        this.initPanel();
        this.updateCost();
    }

    private initUIEvent() {
        this.owner.listSwitch.renderHandler = new Laya.Handler(this, this.onSwitchRender);
        this.owner.listSwitch.selectHandler = new Laya.Handler(this, this.onSwitchSelect);
        this.owner.viewStack.selectedIndex = 0;
        this.owner.item0.visible = true;
        this.owner.item1.visible = false;
        this.owner.listSwitch.array = ["拥有技能", "图鉴"];
        this.owner.listSwitch.selectedIndex = 0;
        this.owner.btnSel.on(Laya.Event.CLICK, this, this.onClickScreen);
    }

    private updateCost() {
        // TODO: 获取消耗?
        const costId = 30301;
        const itemLo = app.service.bag.itemLoMap.get(costId);
        this.owner.imgCost.skin = itemLo.iconUrl;
        this.owner.labCount.text = `${app.service.user.getMoneyCount(costId)}`;
    }

    private onClickScreen() {
        if (this.owner.listSwitch.selectedIndex === 0) {
            app.ui.show(ui.SKILL_SCREEN);
        } else {
            app.ui.show(ui.SKILL_MAP_SCREEN);
        }
    }

    onSwitchSelect(index: number) {
        const childCount = this.owner.listSwitch.array.length;
        let bSelected = false;
        for (let i = 0; i < childCount; i++) {
            const cell = this.owner.listSwitch.getCell(i) as CharacterSwitchTab;
            bSelected = i === index;
            cell.lblList.color = bSelected ? "#DDE7EC" : "#3D4C60";
            cell.lblList.stroke = bSelected ? 2 : 0;
        }
        this.owner.viewStack.selectedIndex = index;
        this.owner.item0.visible = this.owner.listSwitch.selectedIndex === 0;
        this.owner.item1.visible = this.owner.listSwitch.selectedIndex === 1;
        if (!this.owner.item1.vboxContent.numChildren) {
            const skillMapList = app.service.skill.getSkillMapSort();
            this.owner.item1.initList(skillMapList);
        }
    }

    private onSwitchRender(item: CharacterSwitchTab, index: number) {
        const data = item.dataSource;
        item.lblList.text = data;
    }

    private initServiecEvent() {
        this.$(app).on(SystemEvent.SkILL.SKILL_SCREEN, () => {
            this.updateSkillList();
        });
        this.$(app).on(SystemEvent.SkILL.SKILL_MAP_SCREEN, () => {
            this.updateSkillMap();
        });

        this.$(app).on(SystemEvent.SkILL.SKILL_ACTIVE, () => {
            this.updateSkillMap();
            this.updateSkillList();
        });

        this.$(app).on(SystemEvent.SkILL.SKILL_MOUNT, () => {
            this.updateSkillList();
        });

        this.$(app).on(SystemEvent.SkILL.SKILL_UNMOUNT, () => {
            this.updateSkillList();
        });

        this.$(app).on(SystemEvent.USER_MONEY_UPDATE, this.updateCost, this);
    }

    private initPanel() {
        const skillListData = app.service.skill.getSkillListSort();
        this.owner.item0.initList(skillListData);
    }

    private updateSkillMap() {
        const skillMapList = app.service.skill.getSkillMapSort();
        this.owner.item1.initList(skillMapList);
    }

    private updateSkillList() {
        const skillListData = app.service.skill.getSkillListSort();
        this.owner.item0.initList(skillListData);
    }
}
