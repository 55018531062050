import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";

type TowardToTargetInput = [unknown];

export class TowardToTarget extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const [targetEid] = env.input as TowardToTargetInput;
        if (!targetEid || typeof targetEid !== "number") {
            node.warn(`invalid targetEid: ${targetEid}`);
            return "failure";
        }
        const target = env.context.findElement(targetEid);
        if (!target) {
            return "failure";
        }
        env.context.towardTo(env.owner, target);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "TowardToTarget",
            type: "Action",
            desc: "朝向目标",
            input: ["目标"],
        };
    }
}
