const { regClass } = Laya;
import { ImageRef } from "../../../../misc/ImageRef";
import { HeroVo } from "../../vo/HeroVo";
import { CharacterMatDispBoxBase } from "./CharacterMatDispBox.generated";

export interface CharacterMatDispBoxRenderArgs {
    type: "upstage" | "upstar_self" | "upstar_other";
    dispHeroVo: HeroVo;
    hasHeroCount: number;
    needHeroCount: number;
}

@regClass('h4Q4pSEjSWOCvaUf-VSZJw')
export class CharacterMatDispBox extends CharacterMatDispBoxBase {
    onRender(args: CharacterMatDispBoxRenderArgs) {
        console.log("CharacterMatDispBox.onRender", args);
        if (!args.dispHeroVo) {
            console.error("CharacterMatDispBox.onRender", args);
            return;
        }

        const needHeroStar =
            args.type === "upstar_self" ? args.dispHeroVo.baseStar : args.dispHeroVo.star;
        [this.imgStar0, this.imgStar1, this.imgStar2, this.imgStar3, this.imgStar4].forEach(
            (star, i) => {
                star.visible = i < needHeroStar;
            }
        );
        this.starLayout.forceUpdate();

        const texturePaths = this.imgQuality.getComponent(ImageRef)!.texturePaths;
        const qualityFrame = texturePaths[args.dispHeroVo.quality];
        this.imgQuality.skin = qualityFrame;

        this.imgIcon.skin = args.dispHeroVo.iconSmall ?? "";

        this.proMat.value = args.hasHeroCount / args.needHeroCount;

        // 不满足条件的显示红色，满足条件的显示白色
        this.lblMatPro.ubb = true;
        this.lblMatPro.text = `[color=${
            args.hasHeroCount >= args.needHeroCount ? "#FFFFFF" : "#FF0000"
        }]${args.hasHeroCount}[/color]/${args.needHeroCount}`;

        this.lblMatDesc.text =
            args.type === "upstar_self" ? args.dispHeroVo.name : `${needHeroStar}星角色`;
    }
}
