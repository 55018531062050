const { regClass } = Laya;
import { app } from "../../../app";
import { TalentTreeRow } from "../../../def/table";
import { ImageRef } from "../../../misc/ImageRef";
import { res } from "../../../misc/res";
import { NormalTalentItemUI } from "./NormalTalentItemUI";
import { TalentListItemUIBase } from "./TalentListItemUI.generated";

@regClass('BSD5wQ58TYuopnNhj-iObg')
export class TalentListItemUI extends TalentListItemUIBase {
    public normalItemList: { [key: number]: NormalTalentItemUI } = {};

    private m_normalDatas: TalentTreeRow[] = [];

    public async initPanel(normalDatas: TalentTreeRow[]) {
        this.boxNormal.removeChildren();
        this.boxNormal.removeChildren();
        const normalItemHeight = 180;
        let totalHeight = 0;
        this.m_normalDatas = normalDatas;
        const checker = () => !this.destroyed;
        for (const normalData of normalDatas) {
            const prefab = await app.loader.loadPrefab(res.trainCamp.TRAINCAMP_NORMAL_ITEM);
            if (!checker()) {
                return;
            }
            const itemBox = prefab.create() as NormalTalentItemUI;
            itemBox.initPanel(normalData);
            this.boxNormal.addChild(itemBox);
            itemBox.y = totalHeight;
            totalHeight = totalHeight + normalItemHeight;
            this.normalItemList[normalData.id] = itemBox;
        }
        const [star1, star2] = this.imgStar.getComponent(ImageRef)!.texturePaths;
        this.imgStar.skin =
            app.service.user.profileInfo.lv < normalDatas[0].role_lv ? star1 : star2;
        this.imgunlock.gray = app.service.user.profileInfo.lv < normalDatas[0].role_lv;
        this.imgunlock.visible = app.service.user.profileInfo.lv !== normalDatas[0].role_lv;
        this.boxNormal.height = totalHeight;
        const lv = normalDatas[0].role_lv;
        this.labLevel.text = lv >= 10 ? `${lv}` : `0${lv}`;
    }

    public updataPanel() {
        const normalDatas = this.m_normalDatas;
        for (const normalData of normalDatas) {
            const item = this.normalItemList[normalData.id];
            item.initPanel(normalData);
        }
    }
}
