/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIImageBar } from "../../../core/ui/UIImageBar";
import { IconUI } from "../../../misc/ui-runtime/IconUI";

/**
 * resources/prefab/soliderking/SoliderKingUpItem.lh
 */
export class SoliderKingUpItemBase extends Laya.Box {
    public img_bg!: Laya.Image;
    public item_icon!: IconUI;
    public img_bar!: UIImageBar;
    public btn_go!: Laya.Button;
    public btn_finish!: Laya.Button;
    public labelDesc!: Laya.Label;
    public lab_bar!: Laya.Label;
}
