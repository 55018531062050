import { TroopState } from "../../../../def/auto/world";
import { PvpCampStyle } from "../../pvp/PVPUtils";
import { PvpHeadInfoUIBase } from "./PvpHeadInfoUI.generated";

const { regClass } = Laya;

export interface PvpHeadInfoData {
    name: string;
    avatar?: string;
    hp: number;
    maxHp: number;
    style: PvpCampStyle;
    state: TroopState;
    costTime?: number; //剩余移动时间，state为MOVE时有效，单位秒
    offset: number; //todo: 这个offset值应该根据部队的位置与camera的距离来计算
}

const DIR = "resources/atlas/battle-pvp-troop/";
const RES_SELF_BG = "ui_world_RoleInfoBg1.png";
const RES_ENEMY_BG = "ui_world_RoleInfoBg2.png";

const RES_FLAG_MOVING = "icon_world_armymove.png";
const RES_FLAG_FIGHTING = "icon_world_armygarrison.png";

@regClass('MqVuAbouSFyH_Ia-klJY-A')
export class PvpHeadInfoUI extends PvpHeadInfoUIBase {
    private _data?: PvpHeadInfoData;
    private _lastRefreshTime: number = 0;

    public get lastRefreshTime(): number {
        return this._lastRefreshTime;
    }

    override onEnable(): void {
        if (this._data) {
            this.refresh(this._data);
        }
    }

    refresh(data: PvpHeadInfoData) {
        this._lastRefreshTime = Date.now();

        this._data = data;

        data.avatar && (this.avatar.skin = data.avatar);
        this.lblTroopName.text = data.name ?? "未知-队";
        this.sprPro.value = data.maxHp == 0 ? 0 : Math.max(0, Math.min(1, data.hp / data.maxHp));

        this.imgBg.skin = DIR + (data.style === PvpCampStyle.SELF ? RES_SELF_BG : RES_ENEMY_BG);
        this.imgType.skin =
            DIR + (data.state === TroopState.MOVE ? RES_FLAG_MOVING : RES_FLAG_FIGHTING);

        if (data.state != TroopState.MOVE) {
            this.lblCost.text = "--:--";
        } else {
            const costTime = data.costTime ?? 0;
            const min = Math.floor(costTime / 60);
            const sec = costTime % 60;
            this.lblCost.text = `${min < 10 ? "0" + min : min}:${sec < 10 ? "0" + sec : sec}`;
        }
    }
}
