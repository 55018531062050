export class IconResHelper {
    private static QualityStrokeColor = {
        white: "#B0B6B5",
        green: "##2DC385",
        Blue: "#28ABCC",
        Purple: "#846BD1",
        Orange: "#DC7728",
        Red: "#E24B4C",
    };

    static GetStrokeColorByQuality(quality: number) {
        return (
            [
                this.QualityStrokeColor.Blue,
                this.QualityStrokeColor.Purple,
                this.QualityStrokeColor.Orange,
                this.QualityStrokeColor.Red,
            ][quality - 1] ?? this.QualityStrokeColor.white
        );
    }
}
