import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { AStar } from "../astar/astar";
import { PveSvrElementComponent } from "./PveSvrElementComponent";

/** PVE里A星寻路的组件 */
export class PveSvrAStarComponent extends ecs.SingletonComponent {
    /** pveA星数据总封装 */
    pveAStarData!: PveAStarData;
}

export class PveAStarData {
    astar!: AStar;

    /** 动态云数据 */
    dynamicCloudBlocks: number[][] | undefined;

    /**
     * 指定位置是否阻挡块
     * @param x X坐标
     * @param z Z坐标
     * @param isAndCloud true的话则包含云
     */
    isBlockAt(x: number, z: number, isAndCloud: boolean): boolean {
        if (app.service.pve.minimap.isStaticBlockAt(x, z)) {
            return true;
        }

        if (isAndCloud) {
            const gridX = x << 0;
            const gridY = z << 0;
            if (this.dynamicCloudBlocks && this.dynamicCloudBlocks[gridX]?.[gridY]) {
                return true;
            }
        }
        return false;
    }

    /** 期望的目标位置指向直角点 */
    private expectPos2rightAnglePos: Laya.Vector3 = new Laya.Vector3();

    /** 期望的目标位置指向直角点的距离 */
    private expectPos2rightAnglePosLen: number = 0;

    /** 直角位置到英雄位置 */
    private rightAnglePos2heroPos: Laya.Vector3 = new Laya.Vector3();

    /** 每次检查增加的距离 */
    private checkOneStepLength: number = 0.5;

    /** 检查的临时位置 */
    private checkTempPos: Laya.Vector3 = new Laya.Vector3();

    /**
     * 找到最接近的一个可以过去的寻路点
     * @param hero 主角
     * @param expectPos 期望的目标位置，但是是阻挡格
     * @param rightAnglePos 直角点的位置
     * @param out 随从需要移动的目标位置
     * @returns 是否找到一个有效的寻路点
     */
    findCloserMoveVaildPoint(
        hero: PveSvrElementComponent,
        expectPos: Laya.Vector3,
        rightAnglePos: Laya.Vector3,
        out: Laya.Vector3
    ): boolean {
        // 先往内侧找
        Laya.Vector3.subtract(rightAnglePos, expectPos, this.expectPos2rightAnglePos);
        this.expectPos2rightAnglePosLen = this.expectPos2rightAnglePos.length();
        this.expectPos2rightAnglePos.normalize();
        // 步数
        let stepCount = Math.round(this.expectPos2rightAnglePosLen / this.checkOneStepLength);
        for (let i = 1; i <= stepCount; i++) {
            const tempLen = i * this.checkOneStepLength;
            this.checkTempPos.x = expectPos.x + this.expectPos2rightAnglePos.x * tempLen;
            this.checkTempPos.z = expectPos.z + this.expectPos2rightAnglePos.z * tempLen;
            if (!this.isBlockAt(this.checkTempPos.x, this.checkTempPos.z, true)) {
                // 找到有效非阻挡的格子
                out.x = this.checkTempPos.x;
                out.z = this.checkTempPos.z;
                return true;
            }
        }

        // 往靠近主角方向找10个格子距离
        this.rightAnglePos2heroPos.x = hero.transform.position.x - rightAnglePos.x;
        this.rightAnglePos2heroPos.z = hero.transform.position.z - rightAnglePos.z;
        this.rightAnglePos2heroPos.normalize();
        stepCount = 10;
        for (let i = 1; i <= stepCount; i++) {
            const tempLen = i * this.checkOneStepLength;
            this.checkTempPos.x = rightAnglePos.x + this.rightAnglePos2heroPos.x * tempLen;
            this.checkTempPos.z = rightAnglePos.z + this.rightAnglePos2heroPos.z * tempLen;
            if (!this.isBlockAt(this.checkTempPos.x, this.checkTempPos.z, true)) {
                // 找到有效非阻挡的格子
                out.x = this.checkTempPos.x;
                out.z = this.checkTempPos.z;
                return true;
            }
        }

        return false;
    }
}
