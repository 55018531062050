import { HomeUIBase } from "./HomeUI.generated";

const { regClass } = Laya;

@regClass('rrmFQqKQSueJuiXBc0qUrQ')
export class HomeUI extends HomeUIBase {
    override onAwake(): void {
        super.onAwake();
    }
}
