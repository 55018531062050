export class BezierHelper {
    static getCtrlPoint(p0: Laya.Vector3, p1: Laya.Vector3, p2: Laya.Vector3): Laya.Vector3 {
        // PC = P1 - 1/2(√|P0-P1||P2-P1|)(P0-P1/|P0-P1| + P2-P1/|P2-P1|)
        const p0p1 = new Laya.Vector3();
        Laya.Vector3.subtract(p0, p1, p0p1);
        const p2p1 = new Laya.Vector3();
        Laya.Vector3.subtract(p2, p1, p2p1);
        const p0p1Length = p0p1.length();
        const p2p1Length = p2p1.length();
        const sqrtdist = Math.sqrt(p0p1Length * p2p1Length);
        const cp = new Laya.Vector3(
            p1.x - 0.5 * sqrtdist * (p0p1.x / p0p1Length + p2p1.x / p2p1Length),
            p1.y - 0.5 * sqrtdist * (p0p1.y / p0p1Length + p2p1.y / p2p1Length),
            p1.z - 0.5 * sqrtdist * (p0p1.z / p0p1Length + p2p1.z / p2p1Length)
        );
        return cp;
    }

    static getBezierPoint(
        p0: Laya.Vector3,
        cp: Laya.Vector3,
        p2: Laya.Vector3,
        t: number,
        out?: Laya.Vector3
    ): Laya.Vector3 {
        const p = out ?? new Laya.Vector3();
        const mt = 1 - t;
        const t1 = mt * mt;
        const t2 = 2 * t * mt;
        const t3 = t * t;

        p.x = t1 * p0.x + t2 * cp.x + t3 * p2.x;
        p.y = t1 * p0.y + t2 * cp.y + t3 * p2.y;
        p.z = t1 * p0.z + t2 * cp.z + t3 * p2.z;
        return p;
    }
}
