import { Vo } from "./Vo";

export abstract class VoMap<K, V extends Vo<unknown, unknown>> extends Map<K, V> {
    toArray(): Array<V> {
        const ret = new Array<V>();
        for (const [_, v] of this) {
            ret.push(v);
        }
        return ret;
    }

    addOrUpdate(v: V) {
        const key = v.key as K;
        if (key === null || key === undefined) {
            console.error("key is null", key, v);
            return;
        }
        this.set(key, v);
    }

    remove(v: V) {
        const key = v.key as K;
        if (!key) {
            console.error("key is null", key, v);
            return;
        }
        this.delete(key);
    }
}
