import { PveServer } from "../../PveServer";
import { PveSvrBuffComponent } from "../../ecs/components/PveSvrBuffComponent";
import { BuffImmuneNegative } from "../../ecs/data/buff/BuffImmuneNegative";
import { BuffImprisonment } from "../../ecs/data/buff/BuffImprisonment";

export class SkillSystemUtils {
    static startHurt(pveSvr: PveServer, from: unknown, to: unknown) {}

    // 设置免疫负面效果
    static setImmuneNegative(pveSvr: PveServer, eid: number, overdueTime: number) {
        const buffComp = pveSvr.ecs.getComponent(eid, PveSvrBuffComponent);
        if (!buffComp) {
            return;
        }

        let buffArr = buffComp?.buffs.get(BuffImmuneNegative);
        if (!buffArr || buffArr.length === 0) {
            const buff = new BuffImmuneNegative(overdueTime);
            buffArr = [buff];
            buffComp.buffs.set(BuffImmuneNegative, buffArr);
        }
        buffArr.length = 1;
        buffArr[0].overdueTime = overdueTime;
    }

    // 设置禁锢效果
    static setImprisonment(pveSvr: PveServer, eid: number, overdueTime: number) {
        const buffComp = pveSvr.ecs.getComponent(eid, PveSvrBuffComponent);
        if (!buffComp) {
            return;
        }

        let buffArr = buffComp?.buffs.get(BuffImprisonment);
        if (!buffArr || buffArr.length === 0) {
            const buff = new BuffImprisonment(overdueTime);
            buffArr = [buff];
            buffComp.buffs.set(BuffImprisonment, buffArr);
        }
        buffArr.length = 1;
        buffArr[0].overdueTime = overdueTime;
    }

    static isImmuneNegative(pveServer: PveServer, eid: number): boolean {
        const buffComp = pveServer.ecs.getComponent(eid, PveSvrBuffComponent);
        if (!buffComp) {
            return false;
        }

        const buffArr = buffComp.buffs.get(BuffImmuneNegative);
        if (!buffArr || buffArr.length === 0) {
            return false;
        }

        return true;
    }

    static isImprisonment(pveServer: PveServer, eid: number): boolean {
        const buffComp = pveServer.ecs.getComponent(eid, PveSvrBuffComponent);
        if (!buffComp) {
            return false;
        }

        const buffArr = buffComp.buffs.get(BuffImprisonment);
        if (!buffArr || buffArr.length === 0) {
            return false;
        }

        return true;
    }
}
