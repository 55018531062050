import * as ecs from "../../../../../core/ecs";
import { Tilemap } from "../../../tilemap/tilemap";
import { PvpContext } from "../../PvpContext";
import { PvpCameraComponent } from "../components/PvpCameraComponent";
import { PvpMapComponent } from "../components/PvpMapComponent";

export class PvpMapSystem extends ecs.System {
    declare context: PvpContext;

    override onCreate() {
        const map = this.ecs.getSingletonComponent(PvpMapComponent);
        map.tilemap = new Tilemap(this.context);
        map.tilemap.onCreate();
    }

    override onDestroy() {
        const map = this.ecs.getSingletonComponent(PvpMapComponent);
        map.tilemap.destroy();
    }

    override update(dt: number) {
        const map = this.ecs.getSingletonComponent(PvpMapComponent);
        const camera = this.ecs.getSingletonComponent(PvpCameraComponent);
        map.tilemap.update(camera.focus);
    }
}
