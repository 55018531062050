import { app } from "../../../../app";
import { Mediator } from "../../../../core/ui-mediator";
import { mainline } from "../../../../def/auto/proto";
import { opcode } from "../../../../def/auto/protocol";
import { BattlePveMapAreaRow } from "../../../../def/table";
import { BattlePveMapTransferItem } from "../../pve-server/PveDefs";
import { MapTransferAreaBtnUI } from "../../ui-runtime/pve/map-transfer/MapTransferAreaBtnUI";
import { MapTransferItemUI } from "../../ui-runtime/pve/map-transfer/MapTransferItemUI";
import { UIMapTransfer } from "../../ui-runtime/pve/map-transfer/UIMapTransfer";
import { AreaProgressEnterBtnMediator } from "./AreaProgressEnterBtnMediator";
const { regClass, property } = Laya;

@regClass('GJkaRNJ1SJm--dpbsy76Qw')
export class UIMapTransferMediator extends Mediator {
    declare owner: UIMapTransfer;

    override onAwake(): void {
        super.onAwake();
        this.$(app).on(opcode.mainline.s2c_area_progress_draw, this.onDrawProgressReward, this);

        this.owner.btnList01.renderHandler = new Laya.Handler(this, this.onListItemRenderer);
        this.owner.btnList02.renderHandler = new Laya.Handler(this, this.onListItemRenderer2);
        this.owner.baseRoundWindowBg.setTitle("传送列表");
        this.owner.btnList01.selectHandler = new Laya.Handler(
            this,
            this.onListSelectedChange.bind(this)
        );
    }

    /** 领取完成进度奖励的返回 */
    private onDrawProgressReward(
        s2c: mainline.s2c_area_progress_draw,
        c2s: mainline.c2s_area_progress_draw
    ): void {
        this.updateAreaProgressBtn();
    }

    private onListSelectedChange(index: number): void {
        // console.log("选择变化:" + index);
        this.updateAreaProgressBtn();
        this.updateShow();
    }

    private onListItemRenderer(item: MapTransferAreaBtnUI, index: number): void {
        if (!item.dataSource) return;
        const areaItem: BattlePveMapAreaRow = item.dataSource;
        item.setButtonName(areaItem.name);
        const isSelected = this.owner.btnList01.selectedIndex === index;
        item.updateSelected(isSelected);
    }

    private onListItemRenderer2(item: MapTransferItemUI, index: number): void {
        if (!item.dataSource) return;
        item.updateShow(this);
    }

    private updateShow(): void {
        const areaItem: BattlePveMapAreaRow = this.owner.btnList01.selectedItem;
        const transferItems = this.getTransfersWithAreaId(areaItem.id);
        if (transferItems) {
            this.owner.btnList02.array = transferItems;
        } else {
            this.owner.btnList02.array = [];
        }
    }

    private transfersMap: Map<number, BattlePveMapTransferItem[]> | undefined;

    private getTransfersWithAreaId(areaId: number): BattlePveMapTransferItem[] | undefined {
        if (!this.transfersMap) {
            const transfer = app.service.table.battlePveMapTable.transfer;
            this.transfersMap = new Map<number, BattlePveMapTransferItem[]>();
            for (const key in transfer) {
                const transferItem = transfer[key];
                const idStr = transferItem.id.toString();
                const transferIdStr = idStr.slice(idStr.length - 2);
                const transferId = Number(transferIdStr);
                const areaIdStr = idStr.slice(0, idStr.length - transferIdStr.length);
                const tempAreaId = Number(areaIdStr);
                if (!this.transfersMap.has(tempAreaId)) {
                    this.transfersMap.set(tempAreaId, []);
                }
                this.transfersMap.get(tempAreaId)!.push({
                    areaId: tempAreaId,
                    transferId: transferId,
                    areaTransferId: Number(key),
                    cfg: transferItem,
                });
            }
        }
        return this.transfersMap.get(areaId);
    }

    override async onEnable() {
        // this.owner.contentBox.visible = false;
        this.updateAreaList();
        this.updateAreaProgressBtn();
        this.updateShow();

        // 等到获取了区域奖励之后，再刷新一次按钮
        await app.service.pve.requestAreaProgressReward();
        this.updateAreaProgressBtn();
    }

    private updateAreaList(): void {
        const areaCfg = app.service.table.battlePveMapTable.area;
        const listArr = [];
        for (const key in areaCfg) {
            const areaItem = areaCfg[key];
            listArr.push(areaItem);
        }
        this.owner.btnList01.array = listArr;
    }

    private updateAreaProgressBtn(): void {
        const mediator: AreaProgressEnterBtnMediator | null =
            this.owner.areaProgressEnterBtn.mediator;
        if (mediator) {
            const areaItem: BattlePveMapAreaRow = this.owner.btnList01.selectedItem;
            mediator.updateShow(areaItem, this.owner.context);
        }
    }

    public onClickTransferItem(data: BattlePveMapTransferItem): void {
        // app.ui.toast("正在前往:" + data.cfg.name);
        this.owner.close();
        if (this.owner.context) {
            this.owner.context.sender.mapTransfer(data);
        }
    }
}
