import { app } from "../../app";
import { Service } from "../../core/service";
import { TimeFormatUtils } from "../../core/utils/time-format-util";
import proto from "../../def/auto/proto";
import { errcode, opcode } from "../../def/auto/protocol";
import { SystemEvent } from "../../misc/system-event";
import { ui } from "../../misc/ui";
import { DrawCardLo } from "./vo/DrawCardLo";
import { DrawCardLoMap } from "./vo/DrawCardLoMap";
import { DrawCardVo } from "./vo/DrawCardVo";
import { DrawCardVoMap } from "./vo/DrawCardVoMap";

export class DrawCardService extends Service {
    public poolVoMap: DrawCardVoMap = new DrawCardVoMap();

    public poolLoMap: DrawCardLoMap = new DrawCardLoMap();

    public commonInfo!: proto.recruit.CommonInfo;

    public isSkipAnim: boolean = false;

    public clickDrawCardType: number = 0; //抽卡类型

    override onCreate() {
        this.handle(opcode.recruit.s2c_load, this._onLoad);
        this.handle(opcode.recruit.s2c_recruit, this._onRecruit);
        this.handle(opcode.recruit.s2c_receive_credit_award, this._onCreditAward);

        this.handle(opcode.recruit.notify_pool_info_update, this._onUpdatePool);
        this.handle(opcode.recruit.notify_common_info_update, this._onCommonInfo);
        this.handle(opcode.recruit.s2c_buy_ticket, this._onBuyTicket);
    }

    private _onBuyTicket(data: proto.recruit.s2c_buy_ticket, requst: proto.recruit.c2s_buy_ticket) {
        if (data.err === errcode.OK) {
            app.event(SystemEvent.DRAWCARD.BUYITEM);
        }
    }

    override onStartInit() {
        const poolCfgs = app.service.table.recruit.pool_base;
        for (const key in poolCfgs) {
            const config = poolCfgs[key];
            const lo = new DrawCardLo(config);
            this.poolLoMap.set(config.id, lo);
        }
    }

    private _onLoad(data: proto.recruit.s2c_load) {
        if (data.err === errcode.OK) {
            for (const poolData of data.poolInfoList) {
                const id = poolData.poolId;
                const poolVo = new DrawCardVo(poolData);
                this.poolVoMap.set(id, poolVo);
            }
            this.commonInfo = data.commonInfo!;
        }
    }

    //抽奖
    private _onRecruit(data: proto.recruit.s2c_recruit) {
        if (data.err === errcode.OK) {
            const rewardList = data.rewards;
            app.event(SystemEvent.DRAWCARD.DrawCard, [rewardList]);
        }
    }

    private _onCreditAward(data: proto.recruit.s2c_receive_credit_award) {
        if (data.err === errcode.OK) {
            const rewardList = data.rewards;
            app.ui.show(ui.REWARD, rewardList);
            app.event(SystemEvent.DRAWCARD.GAINREWARD);
        }
    }

    private _onUpdatePool(data: proto.recruit.notify_pool_info_update) {
        if (data.poolInfoList.length) {
            for (const poolData of data.poolInfoList) {
                const id = poolData.poolId;
                const poolVo = new DrawCardVo(poolData);
                this.poolVoMap.set(id, poolVo);
            }
            app.event(SystemEvent.DRAWCARD.UPDATEPOOL);
        }
    }

    private _onCommonInfo(data: proto.recruit.notify_common_info_update) {
        if (data.commonInfo) {
            this.commonInfo = data.commonInfo;
            app.event(SystemEvent.DRAWCARD.UPDATECOMMONINFO);
        }
    }

    override onDestroy() {}

    public async load() {
        await app.service.network.call(proto.recruit.c2s_load.create(), proto.recruit.s2c_load);
    }

    public async requestRecruit(poolId: number, type: number) {
        await app.service.network.call(
            proto.recruit.c2s_recruit.create({ poolId: poolId, recruitType: type }),
            proto.recruit.s2c_recruit
        );
    }

    public async requestReceiveReward() {
        await app.service.network.call(
            proto.recruit.c2s_receive_credit_award.create(),
            proto.recruit.s2c_receive_credit_award
        );
    }

    public async requestBuyTicket(count: number) {
        await app.service.network.call(
            proto.recruit.c2s_buy_ticket.create({ num: count }),
            proto.recruit.s2c_buy_ticket
        );
    }

    public getRewardCreditPro(): number {
        const credit = this.commonInfo.credit;
        const pro = credit / 100;
        return pro;
    }

    public getPoolList(): DrawCardLo[] {
        const poolVoList = this.poolLoMap.toArray();
        const poolShowList: DrawCardLo[] = [];
        for (const poolData of poolVoList) {
            if (poolData.checkisShow()) {
                poolShowList.push(poolData);
            }
        }
        return poolShowList;
    }

    /**
     * 格式化时间显示
     * @param dateArr 数组年月日
     * @param timeArr 数组时分秒
     * 例如：
     */
    public timeFormatByArray(dateArr: number[], timeArr: number[]) {
        const dateTime = this.getDateTime(dateArr, timeArr);
        const sec = dateTime - app.service.network.serverTime;
        const dataStr = TimeFormatUtils.totalTimeFormat(sec);
        return dataStr;
    }

    //秒
    public getDateTime(dateArr: number[], timeArr: number[]) {
        const year = dateArr[0];
        const month = dateArr[1];
        const day = dateArr[2];
        const hour = timeArr[0];
        const minute = timeArr[1];
        const second = timeArr[2];
        const date = new Date(year, month, day, hour, minute, second);
        const sec = Math.floor(date.getTime() / 1000);
        return sec;
    }
}
