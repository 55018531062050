import { app } from "../../app";
import { Service } from "../../core/service";
import { StringUtil } from "../../core/utils/string-util";
import proto from "../../def/auto/proto";
import { errcode, opcode } from "../../def/auto/protocol";
import { TalentTreeRow } from "../../def/table";
import { res } from "../../misc/res";
import { SystemEvent } from "../../misc/system-event";
import { TableUtil } from "../table/TableUtil";

export class TrainCampService extends Service {
    public normalIdx: number = 0; //普通线索引
    public strongIdx: number = 0; //加强线索引

    override onCreate(): void {
        this.handle(opcode.talent.s2c_load, this._onLoad);
        this.handle(opcode.talent.s2c_active_normal, this._onActNormal);
        this.handle(opcode.talent.s2c_active_strong, this._onActStrong);
    }

    override onStartInit(): void {}

    private _onLoad(data: proto.talent.s2c_load) {
        if (data.err === errcode.OK) {
            this.normalIdx = data.normalIdx;
            this.strongIdx = data.strongIdx;
            app.event(SystemEvent.TRAINCAMP.TAINCAMP_INIT);
        }
    }

    private _onActNormal(data: proto.talent.s2c_active_normal) {
        if (data.err === errcode.OK) {
            this.normalIdx = this.normalIdx + 1;
            app.event(SystemEvent.TRAINCAMP.TAINCAMP_ACTIVE_NOMAL);
        }
    }

    private _onActStrong(data: proto.talent.s2c_active_strong) {
        if (data.err === errcode.OK) {
            this.strongIdx = this.strongIdx + 1;
            app.event(SystemEvent.TRAINCAMP.TAINCAMP_ACTIVE_STRONG);
        }
    }

    override onDestroy(): void {}

    public getTalentLvDatas() {
        const talentTreeTables = app.service.table.talent.trees.normal_tree;
        const lvList: number[] = [];
        for (const key in talentTreeTables) {
            const tanlentTreeData = talentTreeTables[key];
            const tabLevel = tanlentTreeData.role_lv;
            if (lvList.indexOf(tabLevel) === -1) {
                lvList.push(tabLevel);
            }
        }
        return lvList.reverse();
    }

    public getStrongCfgDatas(): TalentTreeRow[] {
        const talentListData = app.service.table.talent.trees.strong_tree;
        const strongList: TalentTreeRow[] = [];
        for (let i = talentListData.length - 1; i >= 0; i--) {
            strongList.push(talentListData[i]);
        }
        return strongList;
    }

    public getNormalListByLv(lv: number): TalentTreeRow[] {
        const talentListData = TableUtil.getRows(app.service.table.talent.trees.normal_tree, {
            role_lv: lv,
        });
        return talentListData.reverse();
    }

    public getStrongListByLv(lv: number): TalentTreeRow[] {
        const talentListData = TableUtil.getRows(app.service.table.talent.trees.strong_tree, {
            role_lv: lv,
        });
        return talentListData.reverse();
    }

    public getTalentIcon(talentId: number) {
        const talentEffectTable = TableUtil.getRow(app.service.table.talent.talent_effect, {
            id: talentId,
        });
        if (talentEffectTable) {
            return StringUtil.format(res.trainCamp.TRAINCAMP_IMAGE, talentEffectTable.icon);
        }
        return "";
    }

    async load() {
        return await app.service.network.call(
            proto.talent.c2s_load.create(),
            proto.talent.s2c_load
        );
    }

    async requestActNormal() {
        return await app.service.network.call(
            proto.talent.c2s_active_normal.create(),
            proto.talent.s2c_active_normal
        );
    }

    async requestActStrong() {
        return await app.service.network.call(
            proto.talent.c2s_active_strong.create(),
            proto.talent.s2c_active_strong
        );
    }

    public checkIsCanActive(talentCfg: TalentTreeRow): boolean {
        const rolelv = app.service.user.profileInfo.lv;
        if (rolelv < talentCfg.role_lv) {
            return false;
        }
        const lastId = this.getTalentLastId(talentCfg);
        const actStrongIdx = this.getCurActStrongId();
        const actNormalIdx = this.getCurActNomalId();
        const curActId = talentCfg.strong === 1 ? actStrongIdx : actNormalIdx;
        if (curActId >= talentCfg.id) {
            return false;
        }
        if (curActId < lastId) {
            return false;
        }
        const reward = talentCfg.cost;
        const id = reward[0].id;
        if (app.service.user.getMoneyCount(id) < reward[0].num) {
            return false;
        }
        return true;
    }

    public getUnlockCond(talentCfg: TalentTreeRow) {
        const rolelv = app.service.user.profileInfo.lv;
        if (rolelv < talentCfg.role_lv) {
            return ` 请先提升玩家等级到${talentCfg.role_lv}`;
        }
        const actStrongIdx = this.getCurActStrongId();
        const actNormalIdx = this.getCurActNomalId();
        const proIdx = talentCfg.strong === 1 ? actStrongIdx : actNormalIdx;
        const lastId = this.getTalentLastId(talentCfg);
        if (proIdx < lastId) {
            return `请先激活前置的天赋`;
        }
        return "";
    }

    //获取上一个索引
    public getTalentLastId(talentCfg: TalentTreeRow) {
        const isStrong = talentCfg.strong === 1;
        const cfgs = isStrong
            ? app.service.table.talent.trees.strong_tree
            : app.service.table.talent.trees.normal_tree;
        for (let j = cfgs.length - 1; j >= 0; j--) {
            const config = cfgs[j];
            if (config.id < talentCfg.id) {
                return config.id;
            }
        }
        return 0;
    }

    public checkIsActived(talentCfg: TalentTreeRow): boolean {
        const proIdx = talentCfg.strong === 1 ? this.getCurActStrongId() : this.getCurActNomalId();
        return proIdx >= talentCfg.id;
    }

    public getEffectCfg(talentCfg: TalentTreeRow) {
        const effectId = talentCfg.effect_id;
        const talentEffectTable = TableUtil.getRow(app.service.table.talent.talent_effect, {
            id: effectId,
        });
        return talentEffectTable;
    }

    public getScroolLevel() {
        const normalIdx = this.normalIdx;
        const total = this.getTalentLvDatas();
        const cfg = app.service.table.talent.trees.normal_tree[normalIdx - 1];
        if (cfg) {
            return cfg.role_lv;
        }
        return total[total.length - 1];
    }

    public getCurActNomalId(): number {
        const normalIdx = this.normalIdx;
        const normalCfg = app.service.table.talent.trees.normal_tree[normalIdx - 1];
        if (normalCfg) {
            return normalCfg.id;
        }
        return 0;
    }

    public getCurActStrongId(): number {
        const strongIdx = this.strongIdx;
        const strongCfg = app.service.table.talent.trees.strong_tree[strongIdx - 1];
        if (strongCfg) {
            return strongCfg.id;
        }
        return 0;
    }

    public getNormalCostId(): number {
        const costId = app.service.table.talent.trees.normal_tree[0].cost[0].id;
        return costId;
    }

    public getStrongCostId(): number {
        const costId = app.service.table.talent.trees.strong_tree[0].cost[0].id;
        return costId;
    }

    public getNormalIsStartPos(cfg: TalentTreeRow) {
        const cfgList = app.service.table.talent.trees.normal_tree;
        const findIndex = cfgList.findIndex((v) => v.id === cfg.id);
        return findIndex === 0;
    }

    public getStrongIsStartPos(cfg: TalentTreeRow) {
        const cfgList = app.service.table.talent.trees.strong_tree;
        const findIndex = cfgList.findIndex((v) => v.id === cfg.id);
        return findIndex === 0;
    }

    public getStrongInterVal(cfg: TalentTreeRow) {
        const index = app.service.table.talent.trees.strong_tree.findIndex((v) => v.id === cfg.id);
        const curId = cfg.id;
        let lastId = 0;
        if (index - 1 >= 0) {
            lastId = app.service.table.talent.trees.strong_tree[index - 1].id;
        }
        return curId - lastId - 1;
    }
}
