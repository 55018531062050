/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

/**
 * resources/prefab/shop/shop.lh
 */
export class ShopUIBase extends Laya.Dialog {
    Image!: Laya.Image;
    listItem!: Laya.List;
    btnClose!: Laya.Button;
}
