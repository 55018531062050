/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { IconUI } from "../../../misc/ui-runtime/IconUI";

/**
 * resources/prefab/common/dialog/UIReard.lh
 */
export class UIRewardBase extends Laya.Dialog {
    public imgMask!: Laya.Image;
    public listItem!: Laya.List;
    public iconNode!: IconUI;
}

