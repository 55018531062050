/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/battle/pvp-ui/PvpTroopItem.lh
 */
export class PvpTroopItemBase extends Laya.Box {
    public avatar!: Laya.Image;
    public status!: Laya.Label;
    public noTroop!: Laya.Box;
}

