import { TreeRunner } from "../../../../../core/behavior3";
import * as ecs from "../../../../../core/ecs";
import { PveServer } from "../../PveServer";
import { PveSvrElementComponent } from "../components/PveSvrElementComponent";
import {
    PveSvrSkillLauncherComponent,
    Skill,
    SkillTreeEnv,
} from "../components/PveSvrSkillLauncherComponent";

// 技能加载和发射系统，与技能系统区分开来。
export class PveSvrSkillLauncherSystem extends ecs.System {
    declare context: PveServer;

    static readonly TICK = 0.1;

    override onCreate(): void {
        this.registerHandler(
            PveSvrSkillLauncherComponent,
            this._onAddPveSvrLauncherComponent,
            this._onDelPveSvrLauncherComponent
        );
    }

    override update(dt: number) {
        const time = this.context.time;

        this.ecs.getComponents(PveSvrSkillLauncherComponent).forEach((launcher) => {
            if (!launcher.checker()) {
                return;
            }

            for (const idx in launcher.atkSkills) {
                const v = launcher.atkSkills[idx];

                if (v.tree && time - v.lastUpdate > PveSvrSkillLauncherSystem.TICK) {
                    if (v.running) {
                        if (v.tree.status !== "interrupted") {
                            v.tree.run();
                            v.lastUpdate = time;
                        }
                        v.running = v.tree.status === "running";
                    }
                }
            }
            for (const idx in launcher.skills) {
                const v = launcher.skills[idx];

                if (v.tree && time - v.lastUpdate > PveSvrSkillLauncherSystem.TICK) {
                    if (v.running) {
                        if (v.tree.status !== "interrupted") {
                            v.tree.run();
                            v.lastUpdate = time;
                        }
                        v.running = v.tree.status === "running";
                    }
                }
            }
        });
    }

    private async _onAddPveSvrLauncherComponent(launcher: PveSvrSkillLauncherComponent) {
        for (const idx in launcher.skills) {
            const v = launcher.skills[idx];
            this._loadSingleSkill(launcher, v);
        }
        for (const idx in launcher.atkSkills) {
            const v = launcher.atkSkills[idx];
            this._loadSingleSkill(launcher, v);
        }
    }

    private _onDelPveSvrLauncherComponent(component: PveSvrSkillLauncherComponent): void {
        Object.values(component.skills).forEach((v) => {
            v.tree?.clear();
            v.tree = undefined;
        });
    }

    private async _loadSingleSkill(launcher: PveSvrSkillLauncherComponent, skill: Skill) {
        const checker = launcher.checker;
        const tree = await this.context.loadAiTree(skill.btree);
        if (!checker()) {
            return;
        }
        const owner = launcher.getComponent(PveSvrElementComponent)!;
        skill.tree = new TreeRunner(new SkillTreeEnv(this.context, owner, skill), tree);
        skill.running = false;
        skill.lastLaunch = this.context.time;
    }
}
