import { Mediator } from "../../core/ui-mediator";
import { UIAllianceJoin } from "./ui-runtime/UIAllianceJoin";

const { regClass, property } = Laya;

@regClass('UoywiprZR72VTA6dy8odHg')
export class UIAllianceJoinMediator extends Mediator {
    declare owner: UIAllianceJoin;

    override onAwake(): void {
        super.onAwake();
    }
}
