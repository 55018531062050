import { app } from "../../app";
import { Service } from "../../core/service";
import proto from "../../def/auto/proto";
import { errcode, opcode } from "../../def/auto/protocol";
import { SystemEvent } from "../../misc/system-event";
import { TableUtil } from "../table/TableUtil";
import { LeaderSkinLo } from "./vo/LeaderSkinLo";
import { LeaderSkinLoMap } from "./vo/LeaderSkinLoMap";
import { LeaderSkinVo } from "./vo/LeaderSkinVo";
import { LeaderSkinVoMap } from "./vo/LeaderSkinVoMap";

export class LeaderSkinService extends Service {
    public leaderSkinVoMap: LeaderSkinVoMap = new LeaderSkinVoMap();
    public leaderSkinLoMap!: LeaderSkinLoMap;

    override onCreate() {
        this.handle(opcode.leader_skin.s2c_load, this._onLoad);
        this.handle(opcode.leader_skin.s2c_upgrade_lv, this._onGradeLv);
        this.handle(opcode.leader_skin.notify_leader_skin_update, this._onUpdateLeaderSkin);
    }

    private _onUpdateLeaderSkin(data: proto.leader_skin.notify_leader_skin_update) {
        for (const skinData of data.leaderSkinList) {
            const id = skinData.id;
            const leaderSkinCfg = TableUtil.getRow(app.service.table.leaderskin.skin, {
                id: id,
            });
            if (leaderSkinCfg) {
                const skillVo = new LeaderSkinVo(skinData);
                this.leaderSkinVoMap.set(id, skillVo);
            }
        }
        app.event(SystemEvent.LEADERSKIN.LEADERSKINUPDATE);
    }

    public getSkinListSort(): LeaderSkinLo[] {
        const showSkinList: LeaderSkinLo[] = this.leaderSkinLoMap.toArray();
        showSkinList.sort((a, b) => {
            const askinData = this.getSkinDataById(a.id);
            const bskinData = this.getSkinDataById(b.id);
            const aisCanAct = a.checkIsCanActive() && askinData === undefined ? 0 : 1;
            const bisCanAct = b.checkIsCanActive() && bskinData === undefined ? 0 : 1;
            if (aisCanAct !== bisCanAct) {
                return aisCanAct - bisCanAct;
            } else {
                const aisActed = askinData !== undefined ? 0 : 1;
                const bisActed = bskinData !== undefined ? 0 : 1;
                if (aisActed !== bisActed) {
                    return aisActed - bisActed;
                } else {
                    if (a.quality !== b.quality) {
                        return b.quality - a.quality;
                    } else {
                        const aLevel = this.getSkinLevelById(a.id);
                        const blevel = this.getSkinLevelById(b.id);
                        return blevel - aLevel;
                    }
                }
            }
        });
        return showSkinList;
    }

    override onStartInit(): void {
        this.leaderSkinLoMap = new LeaderSkinLoMap();
        const skinTable = app.service.table.leaderskin.skin;
        for (const key in skinTable) {
            const cfg = skinTable[key];
            const lo: LeaderSkinLo = new LeaderSkinLo(cfg);
            this.leaderSkinLoMap.set(cfg.id, lo);
        }
    }

    override onDestroy() {}

    private _onLoad(data: proto.leader_skin.s2c_load) {
        if (data.err === errcode.OK) {
            for (const skinData of data.leaderSkinList) {
                const id = skinData.id;
                const leaderSkinCfg = TableUtil.getRow(app.service.table.leaderskin.skin, {
                    id: id,
                });
                if (leaderSkinCfg) {
                    const skillVo = new LeaderSkinVo(skinData);
                    this.leaderSkinVoMap.set(id, skillVo);
                }
            }
        }
    }

    private _onGradeLv(
        data: proto.leader_skin.s2c_upgrade_lv,
        request: proto.leader_skin.c2s_upgrade_lv
    ) {
        if (data.err === errcode.OK) {
            const skinId = request.id;
            const skinVoData = this.leaderSkinVoMap.get(skinId);
            const lv = skinVoData?.lv;
            if (lv === 1) {
                app.ui.toast("激活成功");
            } else {
                app.ui.toast("升级成功");
            }
            app.event(SystemEvent.LEADERSKIN.LEADERSKINUPGRADE);
        }
    }

    public async requestUpgradeLv(skinUid: number) {
        await app.service.network.call(
            proto.leader_skin.c2s_upgrade_lv.create({ id: skinUid }),
            proto.leader_skin.s2c_upgrade_lv
        );
    }

    public getSkinDataById(id: number): LeaderSkinVo | undefined {
        const LeaderSkinArr = this.leaderSkinVoMap;
        const skinData = LeaderSkinArr.get(id);
        return skinData;
    }

    public getSkinLevelById(id: number): number {
        let lv: number = 1;
        const skinData = this.getSkinDataById(id);
        if (skinData) {
            lv = skinData.lv;
        }
        return lv;
    }

    public async load() {
        await app.service.network.call(
            proto.leader_skin.c2s_load.create(),
            proto.leader_skin.s2c_load
        );
    }
}
