import { app } from "../../../../../../app";
import { IReusable, Pool } from "../../../../../../core/pool";
import { mainline } from "../../../../../../def/auto/proto";
import { ReliveableRecordData } from "./PveSvrBaseStateData";

/** 触发事件记录的数据 */
export class PveSvrEventStateData extends ReliveableRecordData implements IReusable {
    constructor() {
        super();
        this.__reuse();
    }

    __unuse(): void {}

    __reuse(): void {
        this.tid = 0;
        this.xIdx = 0;
        this.yIdx = 0;
        this.key = 0;
        this.inView = false;
    }

    key: number = 0;

    /** 对应表 battle/battle_event.xlsx */
    tid: number = 0;

    /** 被触发时的时间戳，单位秒(领宝箱、解救士兵) */
    // triggerTime: number = 0;

    /** 对应的事件配置 */ // 这个真不一定必有，因为策划会改配置，导致之前缓存的事件数据tid失效
    public get eventVo() {
        return app.service.pve.eventLoMap.get(this.tid);
    }

    /** 所在地图上的x轴索引 */
    xIdx: number = 0;

    /** 所在地图上的y轴索引 */
    yIdx: number = 0;

    inView: boolean = false;

    decode(data: mainline.Event) {
        this.tid = data.tid;
        this.key = data.key;
        this.deathTime = data.triggerTime;
    }

    /** 获取服务端需要的数据 */
    encode(): mainline.Event {
        const data = Pool.obtain(mainline.Event);
        data.tid = this.tid;
        data.key = this.key;
        data.triggerTime = this.deathTime;
        return data;
    }
}
