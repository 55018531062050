/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * scenes/pve.ls
 */
export class PveUIBase extends Laya.Scene {
    public debug!: Laya.Sprite;
    public troops!: Laya.Box;
    public labels!: Laya.Box;
    public mapClickArea!: Laya.Box;
    public battleui!: Laya.Box;
    public joystickGroup!: Laya.Box;
    public joystickArea!: Laya.Box;
    public joystick!: Laya.Box;
    public indicator!: Laya.Image;
    public btnEditTroop!: Laya.Box;
    public btnBackTown!: Laya.Box;
}

