/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/traincamp/TalentListItem.lh
 */
export class TalentListItemUIBase extends Laya.Box {
    public imgunlock!: Laya.Image;
    public imgStar!: Laya.Image;
    public labLevel!: Laya.Label;
    public boxNormal!: Laya.Box;
}

