import * as ecs from "../../../../../core/ecs";
import { SkillSelectorArgs } from "../../../pve-server/PveDefs";

export class PveSkillWarningComponent extends ecs.Component {
    readonly mounter: Laya.Sprite3D = new Laya.Sprite3D("PveSkillWarningComp");
    view?: Laya.Sprite3D | null;
    res!: string;

    skillSelectorArgs!: SkillSelectorArgs;

    /** 开始时间(单位秒) */
    startTime: number = 0;
}
