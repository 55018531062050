import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { SkillType } from "../../../../../def/auto/skill";
import { PveDef } from "../../PveDefs";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrSkillLauncherComponent } from "../../ecs/components/PveSvrSkillLauncherComponent";

const SkillOption = [
    { name: "近战普攻", value: -1 },
    { name: "远程普攻", value: -2 },
    { name: "技能1", value: 1 },
    { name: "技能2", value: 2 },
    { name: "技能3", value: 3 },
    { name: "技能4", value: 4 },
];

interface NodeArgs {
    readonly skill: number;
}

type NodeInput = [number[] | number];

export class TryLaunchSkill extends Process {
    override init(node: Node) {}

    override run(node: Node, env: AiTreeEnv): Status {
        const args = node.args as unknown as NodeArgs;
        const launcher = env.owner.getComponent(PveSvrSkillLauncherComponent);
        if (!launcher) {
            console.error(`launcher not found`, env.owner.eid);
            return "failure";
        }
        if (typeof args.skill !== "number") {
            console.error(`skill Id not number: ${args.skill}`);
            return "failure";
        }

        // if (args.skill < 0) {
        //     console.log(`normal atk skill: ${args.skill}`);
        // }

        const skill =
            args.skill < 0 ? launcher.atkSkills[Math.abs(args.skill)] : launcher.skills[args.skill];
        if (!skill) {
            // console.error(`skill not found: ${args.skill}`);
            return "failure";
        }
        if (!skill.tree) {
            // console.error(`skill tree not found: ${args.skill}`, skill);
            return "failure";
        }
        if (skill.running) {
            return "failure";
        }

        /**
         * 普攻与普攻之间不计算公共cd
         * 普攻与技能，技能与技能之间计算公共cd
         * 计算公共cd时，需要同时满足技能内部cd以及公共cd，才能释放技能
         * 被动技能不计算cd，触发间隔由技能行为树内部控制
         */
        let isCd = false;
        const cd = Math.max(0, skill.data.cd ?? 0);
        if (skill.data.type === SkillType.ATTACK) {
            const isAtkCd = launcher.lastNormalAtkLaunchTime + cd > env.context.time;
            const isPublicCd =
                launcher.lastSkillLaunchTime + PveSvrSkillLauncherComponent.PUBLIC_SKILL_CD >
                env.context.time;
            isCd = isAtkCd || isPublicCd;
        } else if (skill.data.type === SkillType.SKILL) {
            const isIntervalCd = skill.lastLaunch + cd > env.context.time;
            const isPublicCd =
                Math.max(launcher.lastNormalAtkLaunchTime, launcher.lastSkillLaunchTime) +
                    PveSvrSkillLauncherComponent.PUBLIC_SKILL_CD >
                env.context.time;
            isCd = isIntervalCd || isPublicCd;
        } else {
            // 被动不检查cd
        }
        if (isCd) {
            return "failure";
        }

        let [targetEids] = env.input as NodeInput;
        targetEids = targetEids instanceof Array ? targetEids : [targetEids];

        // 直接透传，即使targetEids里有非法eid也无所谓
        skill.tree.env.set(PveDef.SKILL_TARGET_EIDS, targetEids);
        const status = skill.tree.run();
        if (status === "failure") {
            return "failure";
        } else {
            skill.running = status === "running";
            const curTime = env.context.time;
            skill.lastLaunch = curTime; //todo：是否运行失败了也算cd, 还要区分是普攻还是技能
            if (skill.data.type === SkillType.ATTACK) {
                launcher.lastNormalAtkLaunchTime = curTime;
            } else if (skill.data.type === SkillType.SKILL) {
                launcher.lastSkillLaunchTime = curTime;
            }
            return "success";
        }
    }

    override get descriptor(): NodeDef {
        return {
            name: "TryLaunchSkill",
            type: "Action",
            desc: "尝试启动技能",
            args: [{ name: "skill", type: "enum", desc: "技能id", options: SkillOption }],
            input: ["目标?"],
            doc: `
                + 检查技能 CD
                + 目标可以是单个或多个
                + 根据技能范围筛选敌人
                + 如果没有技能、技能运行中、没有敌人、CD时间未到返回 FAILURE`,
        };
    }
}
