import * as ecs from "../../../../../core/ecs";

/** 士兵救援/回血建筑(医疗所) */
export class PveSvrRevivalComponent extends ecs.Component {
    /** 士兵复活点的X轴偏移(建筑位置为基础点) */
    // public static BORN_OFFSET_X = 0;
    // /** 士兵复活点的Z轴偏移(建筑位置为基础点) */
    // public static BORN_OFFSET_Z = 1;
    public static TERRITORY_REVIVAL_TID = 11004;
    /**
     * 当前救援中的角色id
     */
    // public currentRevivalRoleEid: number | undefined;
}
