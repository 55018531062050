import {
    Node,
    NodeDef,
    NodeVars as NodeVarsBase,
    Process,
    Status,
} from "../../../../../core/behavior3";
import {
    BaseShapeSelector,
    FanShapeSelector,
    RectShapeSelector,
    ShapeType,
} from "../../../../../misc/utils/ShapeSelectorUtils";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrCreatureComponent } from "../../ecs/components/PveSvrCreatureComponent";
import { ElementTag, PveSvrElementComponent } from "../../ecs/components/PveSvrElementComponent";
import { PveSvrTransformComponent } from "../../ecs/components/PveSvrTransformComponent";

interface NodeArgs {
    readonly hero?: boolean;
    readonly soldier?: boolean;

    readonly shape: ShapeType;
    readonly target_type: TargetType;
    readonly distance: number;
    readonly width: number;
    readonly max_num: number;
}

interface NodeVars extends NodeVarsBase {
    tag: number;
}

type NodeInput = [Laya.Vector3 | undefined, number | undefined];

const ShapeTypeOptions = [
    { name: "扇形", value: ShapeType.FAN },
    { name: "矩形", value: ShapeType.RECT },
    { name: "圆形", value: ShapeType.CIRCLE },
];
const enum TargetType {
    ENEMY = "enemy", //enemy 包括敌对单位和中立势力
    ALLY = "ally",
    ALLY_EXCEPT_ME = "ally_except_me",
}

const TargetTypeOptions = [
    { name: "所有敌对单位/中立势力", value: TargetType.ENEMY },
    { name: "所有友方单位", value: TargetType.ALLY },
    { name: "排除自己的所有友方单位", value: TargetType.ALLY_EXCEPT_ME },
];

export class GetTargetsInShape extends Process {
    override init(node: Node): void {
        const vars = node.vars as NodeVars;
        const args = node.args as unknown as NodeArgs;
        vars.tag = 0;
        if (args.hero) {
            vars.tag |= ElementTag.LEADER;
        }
        if (args.soldier) {
            vars.tag |= ElementTag.MEMBER;
        }
    }

    override run(node: Node, env: AiTreeEnv): Status {
        const args = node.args as unknown as NodeArgs;
        const vars = node.vars as NodeVars;
        let [position, rotation] = env.input as NodeInput;

        // 不输入时，默认使用自己的位置和旋转
        position = position ?? env.owner.transform.position;
        rotation = rotation ?? env.owner.transform.rotation;

        if (!(position instanceof Laya.Vector3)) {
            node.error("position error");
        }

        let shapeSelector: BaseShapeSelector | undefined;
        if (args.shape === ShapeType.FAN || args.shape === ShapeType.CIRCLE) {
            // 扇形/圆形
            const fanShapeSelector: FanShapeSelector = new FanShapeSelector();
            fanShapeSelector.distance = args.distance;
            fanShapeSelector.position.set(position.x, position.z);
            fanShapeSelector.setRotation(rotation ?? 0);
            fanShapeSelector.angle = args.shape === ShapeType.CIRCLE ? 360 : args.width;
            shapeSelector = fanShapeSelector;
        } else {
            // 矩形
            const rectShapeSelector: RectShapeSelector = new RectShapeSelector();
            rectShapeSelector.distance = args.distance;
            rectShapeSelector.position.set(position.x, position.z);
            rectShapeSelector.setRotation(rotation ?? 0);
            rectShapeSelector.width = args.width;
            shapeSelector = rectShapeSelector;
        }

        const maxLimit = args.max_num ?? 999;
        const outputEidArr: number[] = [];
        // 所有Element
        const creatureComps = env.context.ecs.getComponents(PveSvrCreatureComponent);
        const ownerEid = env.owner.eid; // 自己的实体id
        const except_me = args.target_type === "ally_except_me"; // 排除自己
        const owner_aid = env.owner.aid;

        creatureComps.forEach((creature) => {
            if (creature.isDie) {
                return; // 死亡
            }
            const eleComp = creature.getComponent(PveSvrElementComponent)!;

            if ((eleComp.tag & vars.tag) === 0) {
                return; // 不是目标类型
            }
            if (args.target_type === TargetType.ENEMY) {
                if (eleComp.aid === owner_aid) {
                    return;
                }
            } else {
                if (eleComp.aid !== owner_aid) {
                    return;
                }
                if (creature.eid === ownerEid && except_me) {
                    return; // 排除自己
                }
            }

            const transform = creature.getComponent(PveSvrTransformComponent);
            if (!transform) return;
            const isinternal = shapeSelector.checkInternal(
                transform.position.x,
                transform.position.z
            );
            if (isinternal) {
                outputEidArr.push(creature.eid);
            }
        });

        if (outputEidArr.length > maxLimit) {
            outputEidArr.length = maxLimit;
        }

        env.output.push(outputEidArr);
        return outputEidArr.length > 0 ? "success" : "failure";
    }

    override get descriptor(): NodeDef {
        return {
            name: "GetTargetsInShape",
            type: "Condition",
            desc: "获取指定扇形/矩形范围内的所有匹配单位。找不到合适单位返回失败",
            args: [
                { name: "hero", type: "boolean?", desc: "找英雄" }, //队伍里的leader，怪物只有一个时，就是队伍里的leader
                { name: "soldier", type: "boolean?", desc: "找士兵" },
                { name: "shape", type: "enum", desc: "形状", options: ShapeTypeOptions },
                { name: "target_type", type: "enum", desc: "目标类型", options: TargetTypeOptions },
                { name: "distance", type: "float", desc: "距离" },
                { name: "width", type: "float", desc: "宽度/角度" },
                { name: "max_num", type: "int?", desc: "限制最多获取目标数量" },
            ],
            input: ["位置?", "旋转角度?"],
            output: ["选中的目标单位数组"],
            doc: `
            获取指定扇形/矩形范围内的所有匹配单位
            + 位置和旋转角度不输入时，则取树当前归属的实体的位置和旋转。
            + 需要圆形时可以选扇形，角度设置360度。
            + 目标类型一般造成伤害类型的选敌对单位，医疗队友的选友方单位。
            `,
        };
    }
}
