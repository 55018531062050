import { app } from "../../app";
import { Service } from "../../core/service";
import { ServerType } from "../../def/auto/cluster";
import proto from "../../def/auto/proto";
import { errcode, opcode } from "../../def/auto/protocol";
import { SystemEvent } from "../../misc/system-event";
import { MoneyVo } from "./vo/MoneyVo";
import { MoneyVoMap } from "./vo/MoneyVoMap";

export class UserService extends Service {
    readonly moneyVoMap: MoneyVoMap = new MoneyVoMap();

    account: string | undefined | null = null;

    // 玩角服务器信息
    rid: number = -1;
    serverType: ServerType = ServerType.PVE;
    serverName: string = "";
    serverId: number = 0;

    profileInfo!: proto.profile.ProfileInfo;
    commonInfo?: proto.profile.CommonInfo;

    override onCreate() {
        this.handle(opcode.user.s2c_login, this._onLogin);
        this.handle(opcode.money.s2c_load, this._onLoadMoney);
        this.handle(opcode.profile.s2c_load, this._onLoadProfile);
        this.handle(opcode.money.notify_items, this._onMoneyNotify); //资源信息广播
        this.handle(opcode.profile.notify_profile, this._onProfileNotify);
        this.handle(opcode.profile.s2c_receive_reward, this._onReceiveReward);
        this.handle(opcode.user.s2c_create_role, this._onCreateRole);
    }

    override onDestroy() {}

    override onStartInit(): void {
        Object.values(app.service.table.money).forEach((value) => {
            const vo = new MoneyVo(value);
            this.moneyVoMap.set(vo.id, vo);
        });
    }

    private _onCreateRole(data: proto.user.s2c_create_role): void {
        if (data.err === errcode.OK) {
            this.account = data.info?.account;
            this._updateRoleInfo(data.role!);
        }
    }

    private async _onLogin(data: proto.user.s2c_login) {
        if (data.err === errcode.OK) {
            this.account = data.info?.account;
            this._updateRoleInfo(data.role!);
        }
    }

    private _updateRoleInfo(data: proto.user.RoleInfo) {
        this.rid = data.rid;
        this.serverType = data.serverType;
        this.serverName = data.serverName;
        this.serverId = data.serverId;
    }

    private _onLoadMoney(resp: proto.money.s2c_load) {
        if (resp.err !== errcode.OK) {
            return;
        }

        (resp.items as proto.money.MoneyItem[]).forEach((value) => {
            const vo = this.moneyVoMap.get(value.id)!;
            vo.__setData(value);
        });

        app.event(SystemEvent.USER_MONEY_UPDATE);
    }

    private _onLoadProfile(data: proto.profile.s2c_load) {
        if (data.err === errcode.OK) {
            this.profileInfo = data.profile as proto.profile.ProfileInfo;
            this.commonInfo = data.commonInfo as proto.profile.CommonInfo;
        }
    }

    private _onMoneyNotify(data: proto.money.notify_items) {
        (data.items as proto.money.MoneyItem[]).forEach((value) => {
            const vo = this.moneyVoMap.get(value.id)!;
            vo.__setData(value);
        });
        app.event(SystemEvent.USER_MONEY_UPDATE);
    }

    private _onProfileNotify(data: proto.profile.notify_profile) {
        this.profileInfo = data.profile as proto.profile.ProfileInfo;

        app.event(SystemEvent.USER_PROFILE_UPDATE);
    }

    private _onReceiveReward(data: proto.profile.s2c_receive_reward) {
        if (data.err === errcode.OK) {
            this.commonInfo =
                this.commonInfo ||
                ({ receivedLv: this.profileInfo.lv } as proto.profile.CommonInfo);
            this.commonInfo.receivedLv = this.profileInfo.lv;
            app.event(SystemEvent.USER_COMMONINFO_UPDATE);
        }
    }

    getMoneyCount(id: number) {
        const vo = this.moneyVoMap.get(id);
        if (!vo) {
            console.warn(`money id:${id} not found`);
        }
        return vo?.count ?? 0;
    }

    // ------------------------------------------------------------------------
    // RPC call
    // ------------------------------------------------------------------------

    async loadMonye() {
        return await app.service.network.call(proto.money.c2s_load.create(), proto.money.s2c_load);
    }

    async loadProfile() {
        return await app.service.network.call(
            proto.profile.c2s_load.create(),
            proto.profile.s2c_load
        );
    }

    async requestCreateRole(data: proto.user.Ic2s_create_role) {
        return app.service.network.call(
            proto.user.c2s_create_role.create(data),
            proto.user.s2c_create_role
        );
    }

    async requestRandomName(data: proto.user.Ic2s_random_name) {
        return app.service.network.call(
            proto.user.c2s_random_name.create(data),
            proto.user.s2c_random_name
        );
    }

    async requestRename(data: proto.user.Ic2s_rename) {
        return app.service.network.call(proto.user.c2s_rename.create(data), proto.user.s2c_rename);
    }

    async requestReceiveReward() {
        return app.service.network.call(
            proto.profile.c2s_receive_reward.create(),
            proto.profile.s2c_receive_reward
        );
    }

    async requestLoadPvpServers() {
        return app.service.network.call(
            proto.user.c2s_load_pvp_servers.create(),
            proto.user.s2c_load_pvp_servers
        );
    }

    async requestJoinPvpServer(data: proto.user.Ic2s_join_pvp_server) {
        return app.service.network.call(
            proto.user.c2s_join_pvp_server.create(data),
            proto.user.s2c_join_pvp_server
        );
    }
}
