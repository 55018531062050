/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { IconUI } from "../../../misc/ui-runtime/IconUI";

/**
 * resources/prefab/draw-card/UIDrawCardGainReward.lh
 */
export class UIDrawCardGainRewardBase extends Laya.Dialog {
    public imgMask!: Laya.Image;
    public imgCost!: Laya.Image;
    public listItem!: Laya.List;
    public iconNode!: IconUI;
    public btnDraw!: Laya.Button;
    public labCost!: Laya.Label;
    public labCount!: Laya.Label;
}

