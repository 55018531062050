import { app } from "../../app";
import { Service } from "../../core/service";
import proto from "../../def/auto/proto";
import { errcode, opcode } from "../../def/auto/protocol";
import { CharMsgVoMap } from "./vo/CharMsgVoMap";
import { ChatRoleVoMap } from "./vo/CharRoleVoMap";
import { ChatMsgVo } from "./vo/ChatMsgVo";
import { ChatRoleVo } from "./vo/ChatRoleVo";
export interface ChatCellData {
    msg: ChatMsgVo;
    role: ChatRoleVo;
}
export class ChatService extends Service {
    private static CharMsgIndex = 1;

    static readonly CHAT_UPDATE = "chat-update";

    readonly chatMsgVoMap = new CharMsgVoMap();
    readonly chatRoleVoMap = new ChatRoleVoMap();

    override onCreate() {
        this.handle(opcode.chat.s2c_load, this._onLoad);
        this.handle(opcode.chat.notify, this._onNotify);
    }

    override onStartInit(): void {}

    override onDestroy() {}

    private _onLoad(response: proto.chat.s2c_load) {
        if (response.err === errcode.OK) {
            this._doUpdateChatRole(response.chatRoles as proto.chat.ChatRole[]);
            this._doUpdateChatMsg(response.messages as proto.chat.ChatMessage[]);
        }
    }

    private _onNotify(response: proto.chat.notify) {
        this._doUpdateChatRole(response.chatRoles as proto.chat.ChatRole[]);
        this._doUpdateChatMsg(response.messages as proto.chat.ChatMessage[]);
    }

    private _doUpdateChatRole(chatRoles: proto.chat.ChatRole[]) {
        chatRoles?.forEach((role) => {
            const roleVo = new ChatRoleVo(role);
            this.chatRoleVoMap.addOrUpdate(roleVo);
        });
    }

    private _doUpdateChatMsg(chatMsgs: proto.chat.ChatMessage[]) {
        const oldIndex = ChatService.CharMsgIndex;
        chatMsgs?.forEach((msg) => {
            const msgVo = new ChatMsgVo(ChatService.CharMsgIndex++, msg);
            this.chatMsgVoMap.addOrUpdate(msgVo);
        });
        if (ChatService.CharMsgIndex > oldIndex) {
            app.event(ChatService.CHAT_UPDATE, [oldIndex, ChatService.CharMsgIndex]);
        }
    }

    // ------------------------------------------------------------------------
    // rpc call
    // ------------------------------------------------------------------------
    /**
     *请求
     * @param username 登录账号
     * @returns
     */
    async load(data: proto.chat.Ic2s_load) {
        return await app.service.network.call(
            proto.chat.c2s_load.create(data),
            proto.chat.s2c_load
        );
    }

    async requestSend(data: proto.chat.Ic2s_send) {
        return await app.service.network.call(
            proto.chat.c2s_send.create(data),
            proto.chat.s2c_send
        );
    }
}
