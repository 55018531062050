import { Constructor } from "./dispatcher";

interface IPoolObject {
    __inPool: boolean | undefined;
    __unuse?(): void;
    __reuse?(...args: unknown[]): void;
}

export interface IReusable {
    __unuse(): void;
    __reuse(...args: unknown[]): void;
}

export class Pool {
    private static _poolDic: Map<string | Constructor<unknown>, unknown[]> = new Map();

    private static _reusableMap: Map<Constructor<unknown>, boolean> = new Map();

    static reusable<T>(cls: Constructor<T>) {
        Pool._reusableMap.set(cls, true);
        if (!(cls.prototype as IPoolObject).__unuse) {
            throw new Error(`${cls.name}: __unuse method not defined`);
        }
        if (!(cls.prototype as IPoolObject).__reuse) {
            throw new Error(`${cls.name}: __reuse method not defined`);
        }
    }

    static isReusable<T>(cls: Constructor<T>): boolean {
        return Pool._reusableMap.has(cls);
    }

    static obtain<T, A extends unknown[]>(cls: Constructor<T, A>, ...args: A): T;

    static obtain<T>(sign: string, creator: () => T): T;

    static obtain<T>(clsOrSign: string | Constructor<T>, ...args: unknown[]) {
        const pool = Pool._getPool(clsOrSign);
        let obj: T;
        if (pool.length) {
            obj = pool.pop()!;
            if (typeof clsOrSign !== "string") {
                (obj as IPoolObject).__reuse?.(...args);
            }
        } else if (typeof clsOrSign === "string") {
            obj = (args[0] as () => T)();
        } else {
            obj = new clsOrSign(...args);
        }
        const poolObj = obj as IPoolObject;
        poolObj.__inPool = false;
        return obj;
    }

    static free<T>(obj: T): void;

    static free<T>(obj: T[]): void;

    static free<T>(sign: string, obj: T): void;

    static free<T>(sign: string, obj: T[]): void;

    static free<T extends object>(objOrSign: string | T | T[], obj?: T | T[]): void {
        let pool: T[];
        if (!objOrSign) {
            return;
        } else if (typeof objOrSign === "string") {
            pool = Pool._getPool(objOrSign);
        } else if (objOrSign instanceof Array) {
            obj = objOrSign;
            if (obj.length === 0) {
                return;
            }

            pool = Pool._getPool(obj[0].constructor as Constructor<T>);
        } else {
            obj = objOrSign;
            pool = Pool._getPool(obj.constructor as Constructor<T>);
        }
        if (obj instanceof Array) {
            obj.forEach((v) => Pool._tryFree(pool, v));
            obj.length = 0;
        } else {
            Pool._tryFree(pool, obj);
        }
    }

    static clear<T>(clsOrSign: string | Constructor<T>, callback?: (obj: T) => void) {
        const pool = Pool._getPool(clsOrSign);
        if (callback) {
            pool.forEach(callback);
        }
        pool.length = 0;
    }

    private static _getPool<T>(clsOrSign: string | Constructor<T>): T[] {
        let pool = this._poolDic.get(clsOrSign) as T[] | undefined;
        if (!pool) {
            pool = [];
            this._poolDic.set(clsOrSign, pool);
        }
        return pool;
    }

    private static _tryFree<T>(pool: T[], obj: T | undefined) {
        const poolObj = obj as IPoolObject;
        if (obj && poolObj.__inPool === false) {
            poolObj.__inPool = true;
            poolObj.__unuse?.();
            pool.push(obj);
            if (obj instanceof Laya.Node) {
                obj.removeSelf();
            }
        }
    }
}
