import { PvpCampStyle } from "../PVPUtils";

const { regClass, classInfo } = Laya;

@regClass('hhWLjxg7TrWQu_6iiTZCag')
@classInfo({
    menu: "Battle",
})
export class VFXTroopSelectComp extends Laya.Component {
    // 用来设置VFX的颜色
    static readonly VFX_SELF_COLOR: [number, number, number] = [255 / 255, 227 / 255, 79 / 255]; //"#ffe55c";//yellow
    static readonly VFX_ENEMY_COLOR: [number, number, number] = [255 / 255, 44 / 255, 44 / 255]; //"#ff2c2c";//red
    static readonly VFX_FRIEND_COLOR: [number, number, number] = [255 / 255, 255 / 255, 255 / 255]; //"#ffffff";//white

    setupColor(style: PvpCampStyle) {
        const colors: [number, number, number] =
            style === PvpCampStyle.SELF
                ? VFXTroopSelectComp.VFX_SELF_COLOR
                : style === PvpCampStyle.FRIEND
                ? VFXTroopSelectComp.VFX_FRIEND_COLOR
                : VFXTroopSelectComp.VFX_ENEMY_COLOR;
        if (!this.owner) {
            return;
        }
        const anNode = this.owner.getChildByName("an") as Laya.Sprite3D;
        if (!anNode) {
            return;
        }
        const ring001 = anNode.getChildByName("ring001") as Laya.Sprite3D;
        if (ring001) {
            const ring001Particle = ring001.getComponent(
                Laya.ShurikenParticleRenderer
            ) as Laya.ShurikenParticleRenderer;
            if (ring001Particle?.particleSystem?.startColorConstant) {
                ring001Particle.particleSystem.startColorConstant.setValue(
                    ...colors,
                    ring001Particle.particleSystem.startColorConstant.w
                );
                const maxParticles = ring001Particle.particleSystem.maxParticles;
                // 重新设置maxParticles，触发粒子重置
                ring001Particle.particleSystem.maxParticles = maxParticles;
            }
        }
        const glow = anNode.getChildByName("glow") as Laya.Sprite3D;
        if (glow) {
            const glowParticle = glow.getComponent(
                Laya.ShurikenParticleRenderer
            ) as Laya.ShurikenParticleRenderer;
            if (glowParticle?.particleSystem?.startColorConstant) {
                glowParticle.particleSystem.startColorConstant.setValue(
                    ...colors,
                    glowParticle.particleSystem.startColorConstant.w
                );
                const maxParticles = glowParticle.particleSystem.maxParticles;
                // 重新设置maxParticles，触发粒子重置
                glowParticle.particleSystem.maxParticles = maxParticles;
            }
        }
    }
}
