import { PlotTheatreActionType } from "../../../def/auto/battle";
import { PlotTheatreActionCustomData, PlotTheatreActionsRow } from "../../../def/table";
import { Vo } from "../../../misc/vo/Vo";

export class PlotTheatreActionsLo extends Vo<PlotTheatreActionsRow, never> {
    override get key(): number {
        return this._config.id;
    }

    override clone(): Vo<PlotTheatreActionsRow, never> {
        return new PlotTheatreActionsLo(this._config);
    }

    private _customData: PlotTheatreActionCustomData | undefined;

    public get customData(): PlotTheatreActionCustomData | undefined {
        if (!this._customData) {
            if (this._config.custom_data) {
                this._customData = JSON.parse(this._config.custom_data);
            }
        }
        return this._customData;
    }

    /** 持续时间 */
    get duraction(): number {
        return this._config.duraction ?? -1;
    }

    /** 行为类型 */
    get type(): PlotTheatreActionType {
        // TODO: 枚举怎么根据字符串直接转换出来?
        if (this._config.type === "PLAY_UI_EFFECT") {
            return PlotTheatreActionType.PLAY_UI_EFFECT;
        } else if (this._config.type === "PVE_MOVE_CAMERA") {
            return PlotTheatreActionType.PVE_MOVE_CAMERA;
        } else if (this._config.type === "SHOW_PLOT") {
            return PlotTheatreActionType.SHOW_PLOT;
        } else if (this._config.type === "WAIT") {
            return PlotTheatreActionType.WAIT;
        }
        return PlotTheatreActionType.WAIT;
    }

    /** UI特效id */
    get uiEffectId(): number {
        return this.customData?.ui_effect_id ?? 0;
    }

    /** 剧情对话id */
    get plotId(): number {
        return this.customData?.plot_id ?? 0;
    }
}
