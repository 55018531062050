const { regClass } = Laya;
import { app } from "../../../../app";
import proto from "../../../../def/auto/proto";
import { SlotSysType } from "../../../../def/auto/skill";
import { HeroRow } from "../../../../def/table";
import { ui } from "../../../../misc/ui";
import { SkillDetailsUIArgs } from "../../../skill/ui-runtime/SkillDetailsUI";
import { SkillFastListUIArgs } from "../../../skill/ui-runtime/SkillFastListUI";
import { TableUtil } from "../../../table/TableUtil";
import { UICharacterBtnSkillBase as CharacterBtnSkillBase } from "./CharacterBtnSkill.generated";

@regClass('N1h3hZQRRMK57OAol-otHw')
export class CharacterBtnSkill extends CharacterBtnSkillBase {
    private skillPos: number = 0;
    private skillData: proto.hero.ISkillInfo | undefined;
    private heroUid: number = 0;

    override onAwake(): void {
        super.onAwake();
        this.on(Laya.Event.CLICK, this, this.onClickItem);
    }

    // todo： 后续有技能了再修正逻辑
    onRender(heroUid: number, pos: number) {
        this.skillPos = pos;
        this.heroUid = heroUid;
        this.skillData = this.getSKillData(pos);
        const unLockStar =
            app.service.table.battleSkill.skill_slot_unlock[this.skillPos - 1].require_star;
        const newHeroData = app.service.hero.heroVoMap.get(heroUid);
        const curStar = newHeroData!.star;
        this.imgPlus.active = this.imgPlus.visible =
            this.skillData === undefined && (!newHeroData!.isLeader || curStar >= unLockStar);
        this.imgLock.active = this.imgLock.visible =
            pos > 1 && newHeroData!.isLeader && curStar < unLockStar;

        this.imgSkill.active = this.imgSkill.visible = this.skillData !== undefined;
        this.imgSkillLv.active = this.imgSkillLv.visible = this.skillData !== undefined;
        if (this.skillData) {
            this.lblSkillLv.text = `${this.skillData.lv}`;
            this.imgSkill.skin = app.service.skill.getSKillIcon(this.skillData.id!);
        }
    }

    private getSKillData(skillPos: number): proto.hero.ISkillInfo | undefined {
        const heroUid = this.heroUid;
        const newHeroData = app.service.hero.heroVoMap.get(heroUid);
        if (!newHeroData) {
            return;
        }
        const skillData: proto.hero.ISkillInfo = newHeroData.skills[skillPos];
        return skillData;
    }

    private onClickItem() {
        if (this.imgLock.visible) {
            const unLockStar =
                app.service.table.battleSkill.skill_slot_unlock[this.skillPos - 1].require_star;
            app.ui.toast(`需要统帅达到${unLockStar}星`);
            return;
        }
        const config = TableUtil.getRow<HeroRow>(app.service.table.hero.hero, { id: this.heroUid });
        if (!config) {
            return;
        }
        const leader = config.leader;
        if (this.imgPlus.visible && leader) {
            const skillList = app.service.skill.getSkillFastListSort();
            if (skillList.length > 0) {
                app.ui.show(ui.SKILL_FAST_LIST, {
                    uid: this.heroUid,
                    skillPos: this.skillPos,
                    sysType: SlotSysType.HERO,
                } as SkillFastListUIArgs);
            } else {
                app.ui.toast("当前无可穿戴技能");
            }
            return;
        }
        if (this.skillData) {
            const newHeroData = app.service.hero.heroVoMap.get(this.heroUid);
            const isReplace =
                newHeroData!.skill1 !== this.skillData.id && leader && this.skillPos !== 1; //固定技能skill1和固定槽位1不允许被替换
            app.ui.show(ui.SKILL_DETAILS, {
                skillData: this.skillData,
                heroUid: this.heroUid,
                skillPos: this.skillPos,
                isReplace: isReplace,
                sysType: SlotSysType.HERO,
                isActive: true,
            } as SkillDetailsUIArgs);
        }
    }
}
