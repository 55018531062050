/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/home/HomeNaviMenuItem.lh
 */
export class HomeNaviMenuItemBase extends Laya.Box {
    public selectBox!: Laya.Image;
    public imgIcon!: Laya.Image;
    public labName!: Laya.Label;
}

