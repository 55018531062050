import { Constructor } from "../../../../../core/dispatcher";
import * as ecs from "../../../../../core/ecs";
import { PvpAnimationComponent } from "./PvpAnimationComponent";
import { PvpMovementComponent } from "./PvpMovementComponent";
import { PvpShadowComponent } from "./PvpShadowComponent";
import { PvpTransformComponent } from "./PvpTransformComponent";
import { PvpTroopComponent } from "./PvpTroopComponent";

export class PvpElementComponent extends ecs.Component {
    // 缓存组件方便快速访问？
    private _movement?: PvpMovementComponent;
    private _transform?: PvpTransformComponent;
    private _animation?: PvpAnimationComponent;
    private _troop?: PvpTroopComponent;
    private _shadow?: PvpShadowComponent;

    override get dependencies(): Constructor<ecs.Component>[] {
        return [PvpTransformComponent];
    }

    get transform() {
        return (this._transform ||= this.getComponent(PvpTransformComponent)!);
    }

    get animation() {
        return (this._animation ||= this.getComponent(PvpAnimationComponent));
    }

    get troop() {
        return (this._troop ||= this.getComponent(PvpTroopComponent));
    }

    get shadow() {
        return (this._shadow ||= this.getComponent(PvpShadowComponent));
    }

    entityId: number = 0;
    tableId: number = 0;
}
