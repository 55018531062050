/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIHBoxStar } from "../../hero/ui-runtime/UIHBoxStar";

/**
 * resources/prefab/leader-skin/LeaderSkinTeamItem.lh
 */
export class LeaderSkinTeamItemBase extends Laya.Box {
    public boxOpen!: Laya.Box;
    public img_bg!: Laya.Image;
    public imgSelect!: Laya.Image;
    public imghero!: Laya.Image;
    public boxPower!: Laya.Box;
    public lab_power!: Laya.Text;
    public boxDamage!: Laya.Box;
    public listhero!: Laya.List;
    public boxMainTeam!: Laya.Box;
    public lab_team!: Laya.Label;
    public boxStar!: UIHBoxStar;
    public boxLock!: Laya.Box;
    public lab_cond!: Laya.Label;
}

