const { regClass } = Laya;
import { Callback } from "../../../core/behavior3";
import { UIDrawCardBuyBase } from "./UIDrawCardBuy.generated";

export interface UIDrawCardBuyArgs {
    costId: number;
    contentStr: string;
    contentVars?: { [k: string]: unknown };
    dayisNoTipsKey: string;
    buyCallBack: Callback;
}

@regClass('Ja6p7MPARxKaCxibt8QpQw')
export class UIDrawCardBuy extends UIDrawCardBuyBase {
    args!: UIDrawCardBuyArgs;

    override open(closeOther?: boolean, param?: UIDrawCardBuyArgs): void {
        this.args = param!;
        super.open(closeOther, param);
    }
}
