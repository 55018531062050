const { regClass } = Laya;
import { InteractiveBubbleActionType, InteractiveBubbleViewType } from "../../pve-server/PveDefs";
import { PveContext } from "../../pve/PveContext";
import { PveUIRescueComponent } from "../../pve/ecs/components/PveUIRescueComponent";
import { IInteractiveBubbleView } from "./IInteractiveBubbleView";
import { RescueSoldierConfirmUIBase } from "./RescueSoldierConfirmUI.generated";

@regClass('yvEADiXFTiS-n_QASjsI1w')
export class RescueSoldierConfirmUI
    extends RescueSoldierConfirmUIBase
    implements IInteractiveBubbleView
{
    context: PveContext | undefined;

    comp: PveUIRescueComponent | undefined;

    get viewType(): InteractiveBubbleViewType {
        return InteractiveBubbleViewType.RESCUS_INTERACTIVE_VIEW;
    }

    get actionTypes(): InteractiveBubbleActionType[] {
        return [InteractiveBubbleActionType.RESCUS_INTERACTIVE_ACTION_1];
    }

    override onAwake(): void {
        super.onAwake();
        this.rescueButton.on(Laya.Event.CLICK, this, this.onRescueButtonClick);
    }

    /** 点击救援按钮 */
    async onRescueButtonClick() {
        if (!this.comp || !this.context) return;

        this.context.sender.interactiveBubbleAction(
            this.comp.eid,
            this.viewType,
            this.actionTypes[0],
            null
        );

        this.comp.removeComponent(PveUIRescueComponent);
    }

    override onDestroy(): void {
        this.comp = undefined;
        this.context = undefined;
    }
}
