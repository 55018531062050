import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { BattleEntityType } from "../../../../../def/auto/battle";
import { MainlineConf } from "../../../../../def/auto/mainline";
import { BagService } from "../../../../bag/BagService";
import { PveServer } from "../../PveServer";
import { PveSvrUtils } from "../../PveSvrUtils";
import { PveSvrElementComponent } from "../components/PveSvrElementComponent";
import { PveSvrTransformComponent } from "../components/PveSvrTransformComponent";
import { PveSvrBuildingStateData } from "../data/state-data/PveSvrBuildingStateData";
import { PveSvrCollectSystem } from "./PveSvrCollectSystem";
import { PveSvrStateSystem } from "./PveSvrStateSystem";

/** 建造系统 */
export class PveSvrBuildSystem extends ecs.System {
    public static readonly UPGRADE_ANIMATION_TIME: number = 3.8;

    declare context: PveServer;

    override get interval() {
        /** 每0.3秒检查一次 */
        return 0.3;
    }

    override onCreate(): void {}

    override onDestroy(): void {}

    override update(dt: number): void {
        const pveStateData = app.service.pve.mlData.pveStateData;
        if (!pveStateData.buildings.length) {
            return;
        }
        const curSvrTime = app.service.network.serverTime;
        let needSave = false;
        this.context.ecs.getComponents(PveSvrElementComponent).forEach((elementComp) => {
            if (elementComp.etype !== BattleEntityType.BUILDING) {
                return; //只处理建筑
            }
            const recBuilding = pveStateData.getBuildingByKey(elementComp.key);
            if (!recBuilding || recBuilding.upgradeTime <= 0) {
                return;
            }
            const basecfg = app.service.table.battleBuilding[elementComp.tid];
            const curLevel = recBuilding.level;

            const curLo = app.service.pve.buildingVoMap.getVoWithBattleEntityIdAndLevel(
                basecfg.battle_entity,
                curLevel
            )!;

            const upNeedTime = curLo.upgradeNeedTime;
            if (upNeedTime) {
                const upEndTime = recBuilding.upgradeTime + upNeedTime;
                const upAnimationTime = upEndTime - PveSvrBuildSystem.UPGRADE_ANIMATION_TIME;
                if (curSvrTime >= upEndTime) {
                    // 升级完成
                    needSave = true;
                    PveSvrBuildSystem.onBuildingUpgradeComplete(
                        this.context,
                        recBuilding,
                        upEndTime
                    );
                    this.context.sender.upgradeBuildingComplete(recBuilding.level, elementComp.eid);
                } else if (curSvrTime >= upAnimationTime) {
                    // 播放建造成功动画
                    this.playUpgradeAnimation(elementComp.eid, curSvrTime);
                }
            }
        });

        if (needSave) {
            // 存在建筑升级，立刻进行保存
            PveSvrStateSystem.save(this.context.ecs);
        }
    }

    private playUpgradeAnimationTimeMap: Map<number, number> = new Map<number, number>();

    /** 播放升级动画 */
    private playUpgradeAnimation(eid: number, curSvrTime: number): void {
        let canPlay = false;
        if (this.playUpgradeAnimationTimeMap.has(eid)) {
            const upTime = this.playUpgradeAnimationTimeMap.get(eid)!;
            const upEndTime = upTime + PveSvrBuildSystem.UPGRADE_ANIMATION_TIME + 0.1;
            if (curSvrTime >= upEndTime) {
                canPlay = true;
            }
        } else {
            canPlay = true;
        }
        if (canPlay) {
            this.playUpgradeAnimationTimeMap.set(eid, curSvrTime);
            const tfcomp = this.context.ecs.getComponent(eid, PveSvrTransformComponent);
            if (tfcomp) {
                const x = tfcomp.position.x;
                const z = tfcomp.position.z;
                const yOffset = 0;
                PveSvrUtils.launchVFXEffect(
                    this.context,
                    eid,
                    80032,
                    { x, y: yOffset, z },
                    0,
                    false,
                    undefined,
                    PveSvrBuildSystem.UPGRADE_ANIMATION_TIME
                );
            }
        }
    }

    /** 建筑升级完成 */
    public static onBuildingUpgradeComplete(
        context: PveServer,
        recBuilding: PveSvrBuildingStateData,
        upEndTime: number
    ): void {
        recBuilding.upgradeTime = 0;
        if (!recBuilding.level) {
            recBuilding.harvestTime = upEndTime << 0;
        }
        recBuilding.level++;

        // 做任务
        app.service.pve.requestDoTask(MainlineConf.TASK.UPGRADE_BUILDING, 1, recBuilding.tid);
    }

    /** 点击升级建筑 */
    public static async clickUpgradeBuilding(pveServer: PveServer, eid: number) {
        // app.ui.toast("点击建造按钮");
        const elementComp = pveServer.ecs.getComponent(eid, PveSvrElementComponent)!;
        const tfComp = elementComp.getComponent(PveSvrTransformComponent)!;

        const pveStateData = app.service.pve.mlData.pveStateData;
        const buildingData = pveStateData.getBuildingByKey(elementComp.key)!;
        const tid = elementComp.tid;
        const basecfg = app.service.table.battleBuilding[tid];

        const curLo = app.service.pve.buildingVoMap.getVoWithBattleEntityIdAndLevel(
            basecfg.battle_entity,
            buildingData.level
        )!;

        if (curLo.isMaxLevel) {
            app.ui.toast("已经是最高等级");
            return;
        }

        if (buildingData.upgradeTime) {
            app.ui.toast("正在升级中");
            return;
        }

        const levelEnough = curLo.checkUpgradeLevelEnough(true);
        if (!levelEnough) {
            return;
        }

        const cost = curLo.upgradeCost;
        if (cost && cost.length > 0) {
            if (buildingData.level === 0) {
                if (curLo.upgradeCostFromTruck) {
                    const itemId = curLo.upgradeCost![0].id;
                    const needAmount = curLo.upgradeCost![0].num;
                    const amountObj = app.service.pve.mlData.getCurrentAssetAmount(itemId);
                    const curHasAmount = amountObj.packAmount + amountObj.truckAmount;
                    if (curHasAmount < needAmount) {
                        // 不够
                        BagService.showNotEnoughCostTip(itemId, needAmount);
                        return;
                    } else {
                        // 使用资源车里的资源进行升级
                        await PveSvrCollectSystem.costFormTruck(
                            pveServer,
                            tfComp.position,
                            amountObj,
                            needAmount
                        );
                    }
                } else {
                    if (!curLo.ckeckEnoughUpgradeCost(true)) {
                        return;
                    }
                }
            } else {
                if (!curLo.ckeckEnoughUpgradeCost(true)) {
                    return;
                }
            }
        }

        // 向服务端请求进行扣除
        app.service.pve.requestUpgradeBuilding(curLo.key);

        // 升级中
        const curSvrTime = app.service.network.serverTime;
        const upgradeTime = Math.round(curSvrTime);
        buildingData.upgradeTime = upgradeTime;
        // app.ui.toast("开始建造/升级！");

        pveStateData.setBuildingUpgradeTime(elementComp.key, upgradeTime);
        pveServer.sender.startUpgradeBuilding(eid, upgradeTime);

        // 立刻进行保存
        PveSvrStateSystem.save(pveServer.ecs);
    }
}
