/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/skill/skill_checkbox.lh
 */
export class SkillCheckBoxUIBase extends Laya.Box {
    public boxClick!: Laya.Box;
    public checkBoxSel!: Laya.Image;
    public labName!: Laya.Label;
}

