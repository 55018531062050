/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/battle/pve-ui/building_func/bd_func_production_progress_block.lh
 */
export class BDFuncProductionProgressBlockUIBase extends Laya.Box {
    public progressBar!: Laya.ProgressBar;
    public lab_right!: Laya.Label;
    public lab_left!: Laya.Label;
    public img_icon!: Laya.Image;
}

