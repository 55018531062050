import { app } from "../../../../../app";
import { Entity } from "../../../../../core/ecs";
import {
    BattleAid,
    BattleEntityType,
    PveBuildingType,
    PveEventType,
} from "../../../../../def/auto/battle";
import { BattleEntityRow } from "../../../../../def/table";
import { res } from "../../../../../misc/res";
import { ElementCreator } from "../../../pve-server/PveDefs";
import { BulletType } from "../../../pve-server/btree/actions/CreateBullet";
import { HeadInfoStyle } from "../../../ui-runtime/pve/HeadInfoUI";
import { PveContext } from "../../PveContext";
import { PveAnimationComponent } from "../components/PveAnimationComponent";
import { PveBoardComponent } from "../components/PveBoardComponent";
import { PveBulletComponent } from "../components/PveBulletComponent";
import { PveElementComponent } from "../components/PveElementComponent";
import { PveHeadInfoComponent } from "../components/PveHeadInfoComponent";
import { PveNavinArrowComponent } from "../components/PveNavinArrowComponent";
import { PveShadowComponent } from "../components/PveShadowComponent";
import { PveSkillWarningComponent } from "../components/PveSkillWarningComponent";
import { PveSpoilsComponent } from "../components/PveSpoilsComponent";
import { PveTransformComponent } from "../components/PveTransformComponent";
import { PveTruckComponent } from "../components/PveTruckComponent";
import { PveUICommonInfoComponent } from "../components/PveUICommonInfoComponent";
import { PveUIProductionInfoComponent } from "../components/PveUIProductionInfoComponent";
import { PveUITruckHudComponent } from "../components/PveUITruckHudComponent";
import { PveVFXComponent } from "../components/PveVFXComponent";

export class PveElementUtils {
    static createElement(ctx: PveContext, data: ElementCreator) {
        const table = app.service.table;
        const entityRow = table.battleEntity[data.teid];
        const entity = ctx.ecs.createEntity(data.eid, data.etype);
        this._addElement(ctx, entity, data);
        this._addTransform(ctx, entity, data);

        this._tryAddHeadInfo(ctx, entity, data, entityRow);

        let resPath: string | undefined;
        const skin_id = entityRow.skin_id;
        if (skin_id) {
            const skinCfg = app.service.table.skin[skin_id];
            if (skinCfg) {
                resPath = skinCfg.prefeb_res || skinCfg.prefeb_baked_res;
            }
        }
        switch (data.etype) {
            case BattleEntityType.HERO:
                this._addShadow(ctx, entity, data);
                this._addNavArrow(ctx, entity, data);
                this._addAnimation(ctx, entity, data, resPath!);
                break;
            case BattleEntityType.MONSTER:
                this._addShadow(ctx, entity, data);
                this._addAnimation(ctx, entity, data, resPath!);
                break;
            case BattleEntityType.BOSS:
                this._addShadow(ctx, entity, data);
                this._addAnimation(ctx, entity, data, resPath!);
                break;
            case BattleEntityType.NPC:
                this._addShadow(ctx, entity, data);
                this._addAnimation(ctx, entity, data, resPath!);
                break;
            case BattleEntityType.SOLDIER:
                this._addShadow(ctx, entity, data);
                this._addAnimation(ctx, entity, data, resPath!);
                break;
            case BattleEntityType.TRUCK:
                this._onCreateTruckElement(ctx, entity, data, entityRow);
                break;
            case BattleEntityType.BULLET:
                this._onCreateBulletElement(ctx, entity, data, entityRow);
                break;
            case BattleEntityType.WOOD:
                this._addAnimation(ctx, entity, data, resPath!);
                break;
            case BattleEntityType.STONE:
                this._addAnimation(ctx, entity, data, resPath!);
                break;
            case BattleEntityType.FOOD:
                this._addAnimation(ctx, entity, data, resPath!);
                break;
            case BattleEntityType.BUILDING: {
                const be = table.battleBuilding[data.tid].battle_entity;
                const level = data.level;
                const buildingVo = app.service.pve.buildingVoMap.getVoWithBattleEntityIdAndLevel(
                    be,
                    level
                )!;
                const skinRes = buildingVo.skinRes;
                if (skinRes) {
                    resPath = skinRes;
                }
                if (resPath) {
                    this._addAnimation(ctx, entity, data, resPath);
                }
                // else {
                // if (buildingVo && buildingVo.textureKey) {
                //     this._addBoard(ctx, entity, data);
                // }
                // }
                this._checkAddProductionInfo(ctx, entity, data);

                const tileName = buildingVo.titleName;
                if (tileName) {
                    entity.addComponent(PveUICommonInfoComponent);
                }
                break;
            }
            case BattleEntityType.SPOILS:
                this._addSpoils(ctx, entity, data);
                break;
            case BattleEntityType.EVENT:
                this._onCreateEventElement(ctx, entity, data);
                break;
            case BattleEntityType.SKILL_WARNING:
                this._addSkillWarning(ctx, entity, data);
                break;
            case BattleEntityType.VFX:
                this._onCreateVFXElement(ctx, entity, data, entityRow);
                break;
            default:
                console.error(`PveElementUtils.createElement: unknown etype ${data.etype}`);
                break;
        }

        // check entity must have components
    }

    private static _addElement(ctx: PveContext, entity: Entity, data: ElementCreator) {
        const element = entity.addComponent(PveElementComponent);
        element.teid = data.teid;
        element.tid = data.tid;
        element.key = data.key;
        return element;
    }

    private static _addTransform(ctx: PveContext, entity: Entity, data: ElementCreator) {
        const transform = entity.addComponent(PveTransformComponent);
        transform.position.cloneFrom(data.position);
        transform.rotation = data.rotation;
        transform.flag |= PveTransformComponent.POSITION;
        transform.flag |= PveTransformComponent.ROTATION;
        return transform;
    }

    private static _addAnimation(
        ctx: PveContext,
        entity: Entity,
        data: ElementCreator,
        resStr: string
    ) {
        const animation = entity.addComponent(PveAnimationComponent);
        animation.res = resStr;
    }

    private static _tryAddHeadInfo(
        ctx: PveContext,
        entity: Entity,
        data: ElementCreator,
        entityRow: BattleEntityRow
    ) {
        if (data.hp !== undefined && data.maxHp !== undefined) {
            const info = entity.addComponent(PveHeadInfoComponent);
            info.data.hp = data.hp;
            info.data.maxHp = data.maxHp;
            info.data.skinId = entityRow.skin_id ?? 0;
            if (data.aid === BattleAid.ENEMY) {
                info.data.style = HeadInfoStyle.ENEMY;
            }
            if (entityRow.etype === BattleEntityType.HERO) {
                info.res = res.BATTLE_HEAD_INFO1;
            } else if (entityRow.etype === BattleEntityType.MONSTER) {
                info.res = res.BATTLE_HEAD_INFO3;
            } else if (entityRow.etype === BattleEntityType.BOSS) {
                info.res = res.BATTLE_HEAD_INFO3;
            } else if (entityRow.etype === BattleEntityType.SOLDIER) {
                info.res = res.BATTLE_HEAD_INFO3;
            }
        }
    }

    private static _addShadow(ctx: PveContext, entity: Entity, data: ElementCreator) {
        const shadow = entity.addComponent(PveShadowComponent);
        shadow.res = res.BATTLE_ROLE_SHADOW;
    }

    private static _addNavArrow(ctx: PveContext, entity: Entity, data: ElementCreator) {
        const navigationArrow = entity.addComponent(PveNavinArrowComponent);
        navigationArrow.res = res.BATTLE_ROLE_NAVIGATION_ARROW;
    }

    private static _addBoard(ctx: PveContext, entity: Entity, data: ElementCreator) {
        const boardComp = entity.addComponent(PveBoardComponent);
        boardComp.level = data.level;
    }

    /** 如果建筑正在产出资源中，则添加产出信息提示 */
    private static _checkAddProductionInfo(ctx: PveContext, entity: Entity, data: ElementCreator) {
        const buildingVo = app.service.pve.buildingVoMap.getVoWithBattleEntityIdAndLevel(
            data.teid,
            data.level
        )!;

        if (buildingVo.buildingType !== PveBuildingType.PRODUCTION_FACTORY) {
            return;
        }
        const bdData = app.service.pve.mlData.pveStateData.getBuildingByKey(data.key);
        if (!bdData) {
            return;
        }
        const level = bdData.level;
        if (level === 0) {
            // 未升级，不会产出资源
            return;
        }
        entity.addComponent(PveUIProductionInfoComponent);
    }

    private static _addSpoils(ctx: PveContext, entity: Entity, data: ElementCreator) {
        entity.addComponent(PveSpoilsComponent);
    }

    private static _addTruck(ctx: PveContext, entity: Entity, data: ElementCreator) {
        const truck = entity.addComponent(PveTruckComponent);
        truck.collectCnt = data.collectCnt ?? 0;
        truck.collectObjCnt = truck.collectCnt;
        truck.collectType = data.collectType ?? 0;
        truck.followEid = data.followEid ?? 0;
        return truck;
    }

    private static _addTruckHeadInfo(
        ctx: PveContext,
        entity: Entity,
        data: ElementCreator,
        resStr: string
    ) {
        // 头顶信息的显示
        const truckHeadInfoComp = entity.addComponent(PveUITruckHudComponent);
        truckHeadInfoComp.res = resStr;
    }

    private static _addSkillWarning(ctx: PveContext, entity: Entity, data: ElementCreator) {
        // 技能进度预警
        const skillWaring = entity.addComponent(PveSkillWarningComponent);
        skillWaring.skillSelectorArgs = data.skillSelectorArgs!;

        if (skillWaring.skillSelectorArgs.shape === "rect") {
            // 矩形
            skillWaring.res = res.BATTLE_SKILL_WARNING_RECT;
        } else if (skillWaring.skillSelectorArgs.shape === "fan") {
            // 扇形
            skillWaring.res = res.BATTLE_SKILL_WARNING_FAN;
        }
        skillWaring.startTime = ctx.time;
    }

    private static _onCreateEventElement(ctx: PveContext, entity: Entity, data: ElementCreator) {
        // 事件
        const table = app.service.table;
        const eventVo = app.service.pve.eventLoMap.get(data.tid)!;
        if (eventVo.eventType === PveEventType.GAIN_CHEST) {
            this._addBoard(ctx, entity, data);
        } else if (eventVo.eventType === PveEventType.UNLOCK_CLOUD) {
            const skinRes = eventVo.skinRes;
            if (skinRes) {
                this._addAnimation(ctx, entity, data, skinRes);
            } else {
                this._addBoard(ctx, entity, data);
            }
        } else if (eventVo.eventType === PveEventType.RESCUE_SOLDIER) {
            // 解救士兵，创建笼子
            const entityRow = table.battleEntity[data.teid];
            const skinCfg = table.skin[entityRow.skin_id!];
            const resPath = skinCfg.prefeb_res || skinCfg.prefeb_baked_res;
            this._addAnimation(ctx, entity, data, resPath!);
        }
    }

    private static _onCreateTruckElement(
        ctx: PveContext,
        entity: Entity,
        data: ElementCreator,
        entityRow: BattleEntityRow
    ) {
        this._addShadow(ctx, entity, data);

        const skinCfg = app.service.table.skin[entityRow.skin_id!];
        const resPath = skinCfg.prefeb_res || skinCfg.prefeb_baked_res;

        this._addAnimation(ctx, entity, data, resPath!);

        const truck = this._addTruck(ctx, entity, data);
        let truckHeadInfoCompRes = "";
        switch (truck.collectType) {
            case BattleEntityType.WOOD:
                truckHeadInfoCompRes = res.BATTLE_TRUCK_HEAD_INFO_WOOD;
                break;
            case BattleEntityType.FOOD:
                truckHeadInfoCompRes = res.BATTLE_TRUCK_HEAD_INFO_FOOD;
                break;
            case BattleEntityType.STONE:
                truckHeadInfoCompRes = res.BATTLE_TRUCK_HEAD_INFO_STONE;
                break;
        }

        truckHeadInfoCompRes && this._addTruckHeadInfo(ctx, entity, data, truckHeadInfoCompRes);
    }

    private static _onCreateVFXElement(
        ctx: PveContext,
        entity: Entity,
        data: ElementCreator,
        entityRow: BattleEntityRow
    ) {
        const skillComp = entity.addComponent(PveVFXComponent);
        const skinCfg = app.service.table.skin[entityRow.skin_id!];
        const resPath = skinCfg.prefeb_res || skinCfg.prefeb_baked_res;
        skillComp.res = resPath!;
    }

    private static _onCreateBulletElement(
        ctx: PveContext,
        entity: Entity,
        data: ElementCreator,
        entityRow: BattleEntityRow
    ) {
        const bulletComp = entity.addComponent(PveBulletComponent);
        bulletComp.h = data.bulletArgs?.h ?? 0;
        bulletComp.bulletType = data.bulletArgs?.type ?? BulletType.Static;

        const skinId = entityRow.skin_id;
        if (skinId) {
            const skinCfg = app.service.table.skin[skinId];
            const resPath = skinCfg.prefeb_res || skinCfg.prefeb_baked_res;
            bulletComp.res = resPath!;
        }
    }
}
