import { Pool } from "../../../../../core/pool";
import { AstarPathNode } from "./astar-path-node";

export type AStarAroundFunc = (p: Laya.Vector2) => void;

export class AStar {
    /** 方向临时点 */
    private tempDirPointX: number = 0;
    private tempDirPointY: number = 0;

    private yUpFun(p: Laya.Vector2): void {
        this.tempDirPointX = p.x;
        this.tempDirPointY = p.y + 1;
    }

    private xUpFun(p: Laya.Vector2): void {
        this.tempDirPointX = p.x + 1;
        this.tempDirPointY = p.y;
    }

    private yDownFun(p: Laya.Vector2): void {
        this.tempDirPointX = p.x;
        this.tempDirPointY = p.y - 1;
    }

    private xDownFun(p: Laya.Vector2): void {
        this.tempDirPointX = p.x - 1;
        this.tempDirPointY = p.y;
    }

    private aroundFuns: Array<AStarAroundFunc> = [
        this.yUpFun.bind(this),
        this.xUpFun.bind(this),
        this.yDownFun.bind(this),
        this.xDownFun.bind(this),
    ];

    private xyDownFun(p: Laya.Vector2): void {
        this.tempDirPointX = p.x - 1;
        this.tempDirPointY = p.y - 1;
    }

    private xyUpFun(p: Laya.Vector2): void {
        this.tempDirPointX = p.x + 1;
        this.tempDirPointY = p.y + 1;
    }

    private xUpYDownFun(p: Laya.Vector2): void {
        this.tempDirPointX = p.x + 1;
        this.tempDirPointY = p.y - 1;
    }

    private xDownYUpFun(p: Laya.Vector2): void {
        this.tempDirPointX = p.x - 1;
        this.tempDirPointY = p.y + 1;
    }

    private aroundFuns_2: Array<AStarAroundFunc> = [
        this.yUpFun.bind(this),
        this.xUpFun.bind(this),
        this.yDownFun.bind(this),
        this.xDownFun.bind(this),
        this.xyUpFun.bind(this),
        this.xyDownFun.bind(this),
        this.xUpYDownFun.bind(this),
        this.xDownYUpFun.bind(this),
    ];

    /** 网格数据 */
    private data: number[][] = [];

    /** 动态障碍数据(云) */
    public dynamic_block_data: number[][] = [];

    /** 网格宽 */
    private width: number = 0;

    /** 网格高 */
    private high: number = 0;

    /** 开启队列 */
    private openQueue: Array<AstarPathNode> = [];

    /** 首个节点 */
    private firstNode: AstarPathNode | null = null;

    private nodeMap: Map<number, AstarPathNode> | undefined;

    constructor(data: number[][]) {
        this.data = data;
        this.width = data.length;
        this.high = data[0].length;
    }

    private popQueueNode(): AstarPathNode | undefined {
        this.openQueue.sort((a: AstarPathNode, b: AstarPathNode): number => {
            return a.compareTo(b);
        });
        return this.openQueue.shift();
    }

    /**
     * 查找路径(不可斜走)
     * @param startX 开始X格子索引
     * @param startY 开始Y格子索引
     * @param endX 结束X格子索引
     * @param endY 结束Y格子索引
     * @param out 路径点输出的数组
     * @param supportHypotenuse 是否支持斜线走
     * @returns 寻路找到目标点的路线距离，-1表示目标点不可到达 0 表示同一个格子内 >0 表示寻路格子路点数量
     */
    findPath(
        startX: number,
        startY: number,
        endX: number,
        endY: number,
        out: Array<Laya.Vector2>,
        supportHypotenuse: boolean = false
    ): number {
        if (startX === endX && startY === endY) return 0;
        this.openQueue = [];
        this.nodeMap = new Map<number, AstarPathNode>();
        if (!this.canReach(startX, startY)) {
            return -1;
        }
        if (!this.canReach(endX, endY)) {
            return -1;
        }

        const node: AstarPathNode = Pool.obtain(AstarPathNode);
        node.init(startX, startY, null, 0, this.calcH(startX, startY, endX, endY), this.width);
        this.firstNode = node;
        this.openQueue.push(node);

        let aroundFuns: AStarAroundFunc[];
        if (supportHypotenuse) {
            aroundFuns = this.aroundFuns_2;
        } else {
            aroundFuns = this.aroundFuns;
        }
        let count: number = 0;
        const sourcePathPointsArray: Laya.Vector2[] = [];
        while (this.openQueue.length > 0) {
            const currentNode: AstarPathNode | undefined = this.popQueueNode();
            if (!currentNode) break;
            count++;
            if (count >= 1000) {
                break; // 优化寻路，如果计算了1000个格子仍然无法搜索到目的地，则直接不可到达
            }
            this.consumeAroundNodes(currentNode, endX, endY, aroundFuns);
            this.closeNode(currentNode);
            const endKey: number = this.getXYKey(endX, endY);
            const endNode: AstarPathNode | undefined = this.nodeMap.get(endKey);
            if (endNode !== undefined) {
                this.buildPath(endNode, sourcePathPointsArray);
                const removePoints = this.optimizationPathPoints(
                    sourcePathPointsArray,
                    out,
                    supportHypotenuse
                );
                if (removePoints) {
                    Pool.free(removePoints);
                }
                break;
            }
        }

        this.recoveryNodes();
        if (out.length < 1) {
            // 找不到
            return -1;
        }
        return out.length - 1;
    }

    /**
     * 优化A星寻路结果,去掉连续的点
     * @param inArr 需要被优化的点数组
     * @param outArr 优化后的点会输出到这里
     * @returns 返回被优化掉的中间点
     */
    optimizationPathPoints(
        inArr: Laya.Vector2[],
        outArr: Laya.Vector2[],
        supportHypotenuse: boolean = false
    ): Laya.Vector2[] | null {
        if (inArr.length < 2) return null; // 至少需要三个路点才可优化
        let pointA: Laya.Vector2 | null = null; // 上一个
        let pointB: Laya.Vector2 | null = null; // 当前比较
        let pointC: Laya.Vector2 | null = null; // 下一个
        outArr.push(inArr[0]); // 第一个肯定不可被优化
        let optimizationPoints: Laya.Vector2[] | null = null;
        for (let indexA = 0; indexA < inArr.length - 2; indexA++) {
            pointA = inArr[indexA];
            pointB = inArr[indexA + 1];
            pointC = inArr[indexA + 2];
            let isOptimization =
                (pointB.x === pointA.x && pointB.x === pointC.x) ||
                (pointB.y === pointA.y && pointB.y === pointC.y);

            if (supportHypotenuse && !isOptimization) {
                isOptimization =
                    pointB.x === (pointA.x + pointC.x) / 2 &&
                    pointB.y === (pointA.y + pointC.y) / 2;
            }

            if (!isOptimization) {
                outArr.push(pointB);
            } else {
                if (!optimizationPoints) {
                    optimizationPoints = [];
                }
                optimizationPoints.push(pointB);
            }
        }
        outArr.push(inArr[inArr.length - 1]); // 最后一个肯定不可被优化
        return optimizationPoints;
    }

    /** 获取Key */
    private getXYKey(px: number, py: number): number {
        return (px << 20) | (py << 0);
    }

    /** 回收节点 */
    private recoveryNodes(): void {
        if (this.firstNode) {
            Pool.free(this.firstNode);
            this.firstNode = null;
        }
        if (this.nodeMap) {
            this.nodeMap.forEach((value: AstarPathNode, key: number) => {
                Pool.free(value);
            });
            this.nodeMap.clear();
            this.nodeMap = undefined;
        }
        this.openQueue.length = 0;
    }

    private consumeAroundNodes(
        currentNode: AstarPathNode,
        endX: number,
        endY: number,
        aroundFuns: AStarAroundFunc[]
    ): void {
        if (!this.nodeMap) return;
        for (let i = 0; i < aroundFuns.length; i++) {
            const fun: AStarAroundFunc = aroundFuns[i];
            fun(currentNode.point);
            if (this.canReach(this.tempDirPointX, this.tempDirPointY)) {
                // 从对象池创建
                const tempKey: number = this.getXYKey(this.tempDirPointX, this.tempDirPointY);
                if (!this.nodeMap.get(tempKey)) {
                    const node: AstarPathNode = Pool.obtain(AstarPathNode);
                    const ng = currentNode.g + 1;
                    node.init(
                        this.tempDirPointX,
                        this.tempDirPointY,
                        currentNode,
                        ng,
                        this.calcH(this.tempDirPointX, this.tempDirPointY, endX, endY),
                        this.width
                    );
                    this.openNode(node);
                }
            }
        }
    }

    /** 关闭节点 */
    private closeNode(node: AstarPathNode) {
        node.open = false;
    }

    /** 打开节点 */
    private openNode(node: AstarPathNode): void {
        if (!this.nodeMap) return;
        const originalNode: AstarPathNode | undefined = this.nodeMap.get(node.pointKey);
        if (originalNode === undefined) {
            this.openQueue.push(node);
            this.nodeMap.set(node.pointKey, node);
        } else {
            if (!originalNode.open) {
                return;
            }
            if (originalNode.g > node.g) {
                const idx = this.openQueue.indexOf(originalNode);
                if (idx !== -1) {
                    this.openQueue.splice(idx, 1);
                }
                this.openQueue.push(node);
                this.nodeMap.set(node.pointKey, node);
            }
        }
    }

    /** 检查指定点是否可到达 */
    private canReach(px: number, py: number): boolean {
        if (this.dynamic_block_data[px] && this.dynamic_block_data[px][py] !== 0) {
            return false;
        }
        return this.checkPoint(px, py) && this.data[px][py] === 0;
    }

    /** 检查指定点是否在地图范围内 */
    private checkPoint(px: number, py: number): boolean {
        return px >= 0 && px < this.width && py >= 0 && py < this.high;
    }

    /**
     * 构建出路径
     * 注：构建出来的点使用完成需要回池
     */
    private buildPath(endNode: AstarPathNode, out: Array<Laya.Vector2>): void {
        let pathPoint = Pool.obtain(Laya.Vector2);
        pathPoint.x = endNode.point.x;
        pathPoint.y = endNode.point.y;
        out.push(pathPoint);
        let node: AstarPathNode = endNode;
        /*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
        while (true) {
            const parent: AstarPathNode | null = node.parent;
            if (parent === null) {
                return;
            }
            pathPoint = Pool.obtain(Laya.Vector2);
            pathPoint.x = parent.point.x;
            pathPoint.y = parent.point.y;
            out.unshift(pathPoint);
            node = parent;
        }
    }

    /**
     * 计算H的估值：“曼哈顿”法，坐标分别取差值相加
     */
    private calcH(endX: number, endY: number, pointX: number, pointY: number): number {
        return Math.abs(endX - pointX) + Math.abs(endY - pointY);
    }
}
