/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIImageBar } from "../../../core/ui/UIImageBar";

/**
 * resources/prefab/troop/TroopSoliderItem.lh
 */
export class TroopSoliderItemBase extends Laya.Box {
    public imgCicle1!: Laya.Image;
    public imgCicle2!: Laya.Image;
    public imgCicle3!: Laya.Image;
    public imgCicle4!: Laya.Image;
    public imghero1!: Laya.Image;
    public imghero2!: Laya.Image;
    public imghero3!: Laya.Image;
    public imghero4!: Laya.Image;
    public boxBar1!: Laya.Box;
    public imgbar1!: UIImageBar;
    public boxBar2!: Laya.Box;
    public imgbar2!: UIImageBar;
    public boxBar3!: Laya.Box;
    public imgbar3!: UIImageBar;
    public boxBar4!: Laya.Box;
    public imgbar4!: UIImageBar;
}
