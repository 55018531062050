import { app } from "../../../app";
import { MainlineConf } from "../../../def/auto/mainline";
import { task } from "../../../def/auto/proto";
import { TaskType } from "../../../def/auto/task";
import { Reward, TaskArgsMainlineEvent, TaskMainRow, TaskRow } from "../../../def/table";
import { Vo } from "../../../misc/vo/Vo";

export enum TaskState {
    NOT_RECEIVED = 1, // 未接取任务
    IN_PROGRESS = 2, // 任务进行中
    CAN_DRAW = 3, // 任务完成可领取奖励
    COMPLETED = 4, // 任务完结
}

export type TalkToNpcTaskParam = {
    npc_id: number; // 对应npc
    change_state_to: number; // 当正在这个任务时，切换npc到指定状态
    finish_state_to: number; // 当完成任务时，切换npc到指定状态
};

export abstract class TaskVoBase<D> extends Vo<TaskRow, D> {
    get name(): string {
        return this._config.desc;
    }

    get desc(): string {
        return this._config.desc || "";
    }

    get reward(): Reward[] {
        return this._config.reward;
    }

    /** 任务进度满后，是否自动领取奖励 */
    get isAutoDraw(): boolean {
        return this._config.receive_type === 1;
    }

    get tipsName(): string {
        if (this._config.type === TaskType.MAIN) {
            return "主线";
        } else {
            return "支线";
        }
    }
}

export class TaskVo extends TaskVoBase<never> {
    override get key(): number {
        return this._config.id;
    }

    /** 参考TaskConf.TASK_TYPE.XXX */
    public get taskType(): number {
        return this._config.type;
    }

    override clone(): TaskVo {
        return new TaskVo(this._config);
    }

    get data(): task.TaskInfo | undefined {
        return app.service.task.getTaskInfoWithTaskId(this.key);
    }

    /** 当前完成的进度 */
    get curProgressValue(): number {
        const d = this.data;
        if (d) {
            return d.num || 0;
        }
        return 0;
    }

    get maxProgressValue(): number {
        const d = this.data;
        if (d) {
            return d.max || 0;
        }
        return 0;
    }

    /** 是否是累计计数的任务 */
    get isCumulative(): boolean {
        return this._config.total ?? false;
    }

    /** 任务状态 */
    public get taskState(): TaskState {
        const d = this.data;
        if (!d) {
            // 如果是主线，则当前主线id超过当前id
            if (this.taskType === 1) {
                // 获取最高主线id
                const curMainTask = app.service.task.getCurMainTaskVo();
                if (!curMainTask) {
                    return TaskState.COMPLETED; // 当前没有进行中的主线任务，则完结
                } else {
                    if (curMainTask.key > this.key) {
                        // 当前的主线任务id大于该数据的id，则该数据为已完成
                        return TaskState.COMPLETED;
                    }
                }
            }
            // 没有数据即为未接取
            return TaskState.NOT_RECEIVED;
        }
        if (d.finish) {
            // 已完成
            return TaskState.CAN_DRAW;
        }
        if (this.curProgressValue >= this.maxProgressValue) {
            return TaskState.CAN_DRAW;
        }
        return TaskState.IN_PROGRESS;
    }

    /**
     * 主线事件类型
     * MainlineConf.TASK
     */
    public get mainLineEventType(): number {
        const c = this._config as TaskMainRow;
        if (c.condition === "mainline_event") {
            if (!c.args) {
                console.error("任务配置错误", c);
            }
            const et = (c.args as TaskArgsMainlineEvent).type;
            if (et) {
                return et;
            }
        }
        return 0;
    }

    private _talkToNpcTaskParam: TalkToNpcTaskParam | undefined;

    /** 与npc对话任务的参数 */
    public get talkToNpcTaskParam(): TalkToNpcTaskParam | undefined {
        if (this._talkToNpcTaskParam) {
            return this._talkToNpcTaskParam;
        }
        const et = this.mainLineEventType;
        if (et === MainlineConf.TASK.TALK_TO_NPC) {
            if (this._config.custom) {
                this._talkToNpcTaskParam = JSON.parse(this._config.custom);
                return this._talkToNpcTaskParam;
            }
        }
        return undefined;
    }

    public get npcId(): number | undefined {
        const param = this.talkToNpcTaskParam;
        if (param) {
            return param.npc_id;
        }
        return undefined;
    }
}
