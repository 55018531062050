/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/skill/SkillICon.lh
 */
export class SkillIconUIBase extends Laya.Box {
    public imgSkil!: Laya.Image;
    public imgQuality!: Laya.Image;
    public labName!: Laya.Label;
}

