import {
    Node,
    NodeDef,
    NodeVars as NodeVarsBase,
    Process,
    Status,
} from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { SkillOption } from "../BtreeDef";

interface NodeArgs {
    readonly cd: number;
    readonly times?: number;
}

interface NodeVars extends NodeVarsBase {
    lastCheck: number;
    count: number;
}

export class CheckInterval extends Process {
    override init(node: Node) {}

    override run(node: Node, env: AiTreeEnv): Status {
        const args = node.args as unknown as NodeArgs;
        const vars = node.vars as unknown as NodeVars;
        const curTime = env.context.time;
        const times = args.times ?? -1;
        const cd = args.cd;
        const count = vars.count ?? 0;
        if (
            vars.lastCheck === undefined ||
            (curTime > vars.lastCheck + cd && (times < 0 || count < times))
        ) {
            vars.lastCheck = curTime;
            vars.count = vars.count ?? 0;
            vars.count++;
            return "success";
        }

        return "failure";
    }

    override get descriptor(): NodeDef {
        return {
            name: "CheckInterval",
            type: "Condition",
            desc: "检查循环执行",
            args: [
                { name: "cd", type: "float", desc: "冷却时间", options: SkillOption },
                { name: "times", type: "int?", desc: "次数, 不填或负数表示无限次" },
            ],
            doc: `
                + 检查循环执行 CD
                + 如果时间到了返回 SUCCESS，否则返回 FAILURE
                `,
        };
    }
}
