/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIImageBar } from "../../../core/ui/UIImageBar";

/**
 * resources/prefab/soliderking/SoliderKingRewardItem.lh
 */
export class SoliderKingRewardItemBase extends Laya.Box {
    public boxBar!: Laya.Box;
    public imgBar!: UIImageBar;
    public imgSel!: Laya.Image;
    public boxIcon!: Laya.Box;
    public imgIcon!: Laya.Image;
    public boxLevel!: Laya.Box;
    public lblLevel!: Laya.Label;
    public boxExp!: Laya.Box;
    public lblExp!: Laya.Label;
    public imgBg!: Laya.Image;
    public imgRedpoint!: Laya.Image;
    public imgItem!: Laya.Image;
    public imgGet!: Laya.Image;
    public imgGou!: Laya.Image;
    public lblCount!: Laya.Label;
}
