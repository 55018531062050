const { regClass } = Laya;
import { AlertArgs } from "../ui";
import { AlertUIBase } from "./AlertUI.generated";

@regClass('_PJM_fwKSvWuVGXvJxEt-w')
export class AlertUI extends AlertUIBase {
    args!: AlertArgs;

    override open(closeOther?: boolean | undefined, param?: any): void {
        this.args = param;
        super.open(closeOther, param);
    }

    override close(type?: string | undefined): void {
        if (!type) {
            Laya.Dialog.manager.close(this);
        }
    }
}
