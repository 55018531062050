import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { PveSvrBuffComponent } from "../../ecs/components/PveSvrBuffComponent";
import { BuffTreeEnv } from "../../ecs/data/buff/BuffBase";

export class ClearSelfCreatedBuffData extends Process {
    override run(node: Node, env: BuffTreeEnv): Status {
        const buffComp = env.owner.getComponent(PveSvrBuffComponent)!;

        for (let attrId in buffComp.buffAttrDatas) {
            for (let buffId in buffComp.buffAttrDatas[attrId]) {
                const buffDataArr = buffComp.buffAttrDatas[attrId][buffId];
                for (let i = 0; i < buffDataArr.length; i++) {
                    const buffData = buffDataArr[i];
                    if (buffData.fromBuffTreeId === env.buffTree.id) {
                        // console.log("ClearSelfCreatedBuffData ", buffComp.eid, buffId, buffData);
                        buffDataArr.splice(i, 1);
                        i--;
                    }
                }
            }
        }

        for (let attrId in buffComp.buffAttrPercentDatas) {
            for (let buffId in buffComp.buffAttrPercentDatas[attrId]) {
                const buffDataArr = buffComp.buffAttrPercentDatas[attrId][buffId];
                for (let i = 0; i < buffDataArr.length; i++) {
                    const buffData = buffDataArr[i];
                    if (buffData.fromBuffTreeId === env.buffTree.id) {
                        // console.log("ClearSelfCreatedBuffData ", buffComp.eid, buffId, buffData);
                        buffDataArr.splice(i, 1);
                        i--;
                    }
                }
            }
        }

        buffComp.buffFunctionalDatas.forEach((buffDataMap, buffType) => {
            buffDataMap.forEach((buffDataArr, buffId) => {
                for (let i = buffDataArr.length - 1; i >= 0; i--) {
                    const buffData = buffDataArr[i];
                    if (buffData.fromBuffTreeId === env.buffTree.id) {
                        // console.log("ClearSelfCreatedBuffData ", buffComp.eid, buffId, buffData);
                        buffDataArr.splice(i, 1);
                    }
                }
            });
        });

        buffComp.buffSpecialDatas.forEach((buffDataMap, buffType) => {
            buffDataMap.forEach((buffDataArr, buffId) => {
                for (let i = buffDataArr.length - 1; i >= 0; i--) {
                    const buffData = buffDataArr[i];
                    if (buffData.fromBuffTreeId === env.buffTree.id) {
                        // console.log("ClearSelfCreatedBuffData buffSpecialDatas", buffComp.eid, buffId, buffData);
                        buffDataArr.splice(i, 1);
                    }
                }
            });
        });

        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "ClearSelfCreatedBuffData",
            type: "Action(Buff)" as "Action",
            desc: "删除本行为树添加的所有BuffDatas，只能在Buff行为树里使用",
            doc: `
                + 则默认为行为树当前归属的实体`,
        };
    }
}
