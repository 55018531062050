import { Mediator } from "../../core/ui-mediator";
import { UIAllianceContactLeader } from "./ui-runtime/UIAllianceContactLeader";

const { regClass, property } = Laya;

@regClass('x-PCnS-1T-6cz1Hw1SQNMg')
export class UIAllianceContactLeaderMediator extends Mediator {
    declare owner: UIAllianceContactLeader;

    override onAwake(): void {
        super.onAwake();
    }
}
