const { regClass } = Laya;
import { SkillVo } from "../vo/SkillVo";
import { SkillRemoveUIBase } from "./SkillRemoveUI.generated";

@regClass('4RX4VSW6RwacPVAFE1gtMA')
export class SkillRemoveUI extends SkillRemoveUIBase {
    public m_data: SkillVo | null = null;

    override open(closeOther?: boolean, param?: SkillVo): void {
        this.m_data = param!;
        super.open(closeOther, param);
    }
}
