/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/gm/UI_GMPurchase.lh
 */
export class UI_GM_PurchaseBase extends Laya.Dialog {
    public itemList!: Laya.List;
    public searchInput!: Laya.TextInput;
    public searchBtn!: Laya.Button;
}

