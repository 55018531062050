/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/home/HomeUserBox.lh
 */
export class HomeUserBoxUIBase extends Laya.Panel {
    public moneyList!: Laya.List;
    public btnAvatar!: Laya.Box;
    public imgIcon!: Laya.Image;
    public boxLevel!: Laya.Box;
    public labLevel!: Laya.Label;
    public labelPower!: Laya.Text;
}

