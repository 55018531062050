const { regClass } = Laya;
import { UICharacterSwitchTabBase } from "./CharacterSwitchTab.generated";

@regClass('5L4-XZQRSE2iITcXEEOlRA')
export class CharacterSwitchTab extends UICharacterSwitchTabBase {

    onSelect(bSelected: boolean, index: number) {
        this.lblList.color = bSelected ? "#DDE7EC" : "#3D4C60";
        this.lblList.stroke = bSelected ? 2 : 0;
    }
}