import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { BattleConf } from "../../../../../def/auto/battle";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrTroopComponent } from "../../ecs/components/PveSvrTroopComponent";

const stationOptions = [
    { name: "取输入值", value: -1 },
    { name: "首领", value: 0 },
    { name: "前排", value: 1 },
    { name: "中排", value: 2 },
    { name: "后排", value: 3 },
    { name: "三排", value: 4 },
    { name: "全部", value: 5 },
];

interface NodeArgs {
    readonly station: number;
}

type NodeInput = [number?, number?];

// old name: GetGroupSoldiers
export class GetMemberByStation extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const [inputEid, inputStation] = env.input as NodeInput;
        const troopEid = inputEid ?? env.owner.troopEid;
        const troopComp = env.context.ecs.getComponent(troopEid, PveSvrTroopComponent);
        if (!troopComp) {
            console.error(
                `GetMemberByStation: inputEid ${inputEid} or self ${troopEid} has no PveSvrTroopComponent`
            );
            return "failure";
        }
        const station = inputStation ?? (node.args as unknown as NodeArgs).station;
        const ret: number[] = [];

        if (station === 4) {
            ret.push(...Object.values(troopComp.members));
        } else if (station === 5) {
            ret.push(troopComp.leaderEid);
            ret.push(...Object.values(troopComp.members));
        } else {
            if ([0, 1, 2, 3].indexOf(station) < 0) {
                console.error(`GetMemberByStation: invalid station ${station}`);
                return "failure";
            }

            if (station === 0) {
                ret.push(troopComp.leaderEid);
                env.output.push(ret);
                return "success";
            }

            const idxStart = (station - 1) * BattleConf.PVE.SOLDIER_ONE_ROW_COUNT;
            for (const key in troopComp.members) {
                const idx = parseInt(key);
                if (idxStart <= idx && idx < idxStart + BattleConf.PVE.SOLDIER_ONE_ROW_COUNT) {
                    ret.push(troopComp.members[idx]);
                }
            }
        }

        if (ret.length === 0) {
            return "failure";
        }
        env.output.push(ret);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "GetMemberByStation",
            type: "Action",
            desc: "获取编队里某排单位",
            args: [{ name: "station", type: "enum", desc: "第几排", options: stationOptions }],
            input: ["目标编队?", "第几排?"],
            output: ["排所有士兵"],
            doc: `
                + 不输入目标编队，默认自己所在编队!
            `,
        };
    }
}
