/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIButton } from "../../../core/ui/UIButton";

/**
 * resources/prefab/alliance/UIAllianceIntro.lh
 */
export class UIAllianceIntroBase extends Laya.Dialog {
    public btnGo!: UIButton;
}

