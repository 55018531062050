/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/character/detail/char_btn_skill.lh
 */
export class UICharacterBtnSkillBase extends Laya.Button {
    public imgPlus!: Laya.Image;
    public imgLock!: Laya.Image;
    public imgSkill!: Laya.Image;
    public imgSkillLv!: Laya.Image;
    public lblSkillLv!: Laya.Label;
}

