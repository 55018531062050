import { AttrBaseBase } from "./AttrBase.generated";

const { regClass } = Laya;

@regClass('OGAWr6MeRXOAeDHOmU2OLQ')
export class AttrBase extends AttrBaseBase {
    onRender(attrData: { arrtName: string; AttrValue: number }, index: number) {
        this.labAtt.text = attrData.arrtName;
        this.labAttValue.text = `+${attrData.AttrValue}`;
        this.imgBg.visible = index % 2 === 0;
    }
}
