import { app } from "../../../../app";
import { Mediator } from "../../../../core/ui-mediator";
import { mainline } from "../../../../def/auto/proto";
import { opcode } from "../../../../def/auto/protocol";
import { AreaProgressListItemUI } from "../../ui-runtime/pve/map-transfer/AreaProgressListItemUI";
import { UIAreaProgress } from "../../ui-runtime/pve/map-transfer/UIAreaProgress";

const { regClass, property } = Laya;

@regClass('YaPSUkSKTzCGshb7MYNf6A')
export class UIAreaProgressMediator extends Mediator {
    declare owner: UIAreaProgress;

    override onAwake(): void {
        super.onAwake();
        this.$(app).on(opcode.mainline.s2c_area_progress_draw, this.onDrawProgressReward, this);
        this.owner.baseRoundWindowBg.setTitle("区域探索度");

        this.owner.list01.renderHandler = new Laya.Handler(this, this.onListItemRenderer);
    }

    /** 领取完成进度奖励的返回 */
    private onDrawProgressReward(
        s2c: mainline.s2c_area_progress_draw,
        c2s: mainline.c2s_area_progress_draw
    ): void {
        this.updateProgressBar();
    }

    private onListItemRenderer(item: AreaProgressListItemUI, index: number): void {
        item.updateShow(index);
    }

    override onEnable(): void {
        this.updateProgressBar();
    }

    private updateProgressBar(): void {
        const areaItem = this.owner.areaItem;
        if (!areaItem) return;

        const progressData = app.service.pve.mainLineProgressData;
        const datas = progressData.getDatasWithAreaId(areaItem.id);
        this.owner.list01.array = datas;
        this.owner.progress_bar.updateShow(this.owner.areaItem!, areaItem.name, datas);
    }
}
