/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/battle/pve-ui/build/bd_common_info.lh
 */
export class BDCommonInfoUIBase extends Laya.Box {
    public txt_name!: Laya.Text;
}

