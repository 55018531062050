/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { BaseRoundWindowBg } from "../../common/ui-runtime/dialog/BaseRoundWindowBg";
import { SkillIconUI } from "./SkillIconUI";
import { UIImageBar } from "../../../core/ui/UIImageBar";
import { IconUI } from "../../../misc/ui-runtime/IconUI";

/**
 * resources/prefab/skill/SkillActive.lh
 */
export class SkillActiveUIBase extends Laya.Dialog {
    public imgClose!: Laya.Image;
    public bgwin!: BaseRoundWindowBg;
    public skillIcon!: SkillIconUI;
    public ImageBar!: UIImageBar;
    public icon!: IconUI;
    public labBar!: Laya.Label;
    public btnActive!: Laya.Button;
}

