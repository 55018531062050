import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { SkillTreeEnv } from "../../ecs/components/PveSvrSkillLauncherComponent";
import { PveSvrSoldierGroupComponent } from "../../ecs/components/PveSvrSoldierGroupComponent";
import { PveSvrTroopComponent } from "../../ecs/components/PveSvrTroopComponent";

type NodeInput = [number];

const groupIndexOptions = [
    { name: "前排", value: 1 },
    { name: "中排", value: 2 },
    { name: "后排", value: 3 },
];

interface NodeArgs {
    readonly station: number;
}

export class GetSoldierGroup extends Process {
    override run(node: Node, env: SkillTreeEnv): Status {
        const [inputEid] = env.input as NodeInput;
        const station = (node.args as unknown as NodeArgs).station;

        const troopEid = inputEid ?? env.owner.troopEid;
        const troopComp = env.context.ecs.getComponent(troopEid, PveSvrTroopComponent);
        if (!troopComp) {
            console.error(
                `GetGroupSoldiers: target ${inputEid} or self ${troopEid} has no PveSvrTroopComponent `
            );
            return "failure";
        }

        const soldierGroups = env.context.ecs.getComponents(PveSvrSoldierGroupComponent);

        for (const [groupEid, groupComp] of soldierGroups) {
            if (groupComp.eleComp.troopEid === troopComp.eid && groupComp.station === station) {
                env.output.push(groupComp.eid);
                return "success";
            }
        }

        // console.error(
        //     `GetGroupSoldiers: target ${troopEid} has no soldier group in station ${station}`
        // );
        return "failure";
    }

    override get descriptor(): NodeDef {
        return {
            name: "GetSoldierGroup",
            type: "Action",
            desc: "获取目标所在编队的士兵组",
            args: [{ name: "station", type: "enum", desc: "编队索引", options: groupIndexOptions }],
            input: ["目标?"],
            output: ["士兵组"],
            doc: `
                + 不输入目标组，默认获取自己的编队，此时只能在技能行为树使用!
                + 无法找到目标编队 或者 目标编队没有参数需要的士兵组 则返回失败
            `,
        };
    }
}
