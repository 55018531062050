import { app } from "../../../../app";
import { Mediator } from "../../../../core/ui-mediator";
import { TimeFormatUtils } from "../../../../core/utils/time-format-util";
import { SystemEvent } from "../../../../misc/system-event";
import { CostMidItemIcon } from "../../../common/ui-runtime/item-icon/CostMidItemIcon";
import { InteractiveBubbleActionType, InteractiveBubbleViewType } from "../../pve-server/PveDefs";
import { UIBDCollectionFactory } from "../../ui-runtime/pve/building_func/UIBDCollectionFactory";
import { PveElementComponent } from "../ecs/components/PveElementComponent";

const { regClass, property } = Laya;

@regClass('7vma7bvtT3O7KimEDPRfNw')
export class UIBDCollectionFactoryMediator extends Mediator {
    declare owner: UIBDCollectionFactory;

    open(): void {
        this.$(app).on(
            SystemEvent.PVE.ON_BUILDING_START_UPGRADE,
            this.onBuildingStartUpgrade,
            this
        );
        this.$(app).on(SystemEvent.PVE.ON_BUILDING_UPGRADE_COMP, this.onBuildingUpgradeComp, this);
        this.$(app).on(SystemEvent.PVE.ON_BUILDING_HARVEST_COMP, this.onBuildingHarvestComp, this);
        this.owner.costList.renderHandler = new Laya.Handler(this, this.onListItemRenderer);
        this.updateShow();
        this.schedule(1, "onLoopFunc", this.onLoopFunc, this);
    }

    private onListItemRenderer(item: CostMidItemIcon, index: number): void {
        item.updateWithListReward();
        // console.log("index:" + index + "data:" + item.dataSource);
    }

    /** 建筑升级完成，刷新界面 */
    private onBuildingUpgradeComp(): void {
        this.updateShow();
    }

    /** 收获完成 */
    private onBuildingHarvestComp(): void {
        this.onLoopFunc();
    }

    /** 建筑开始升级，刷新界面 */
    private onBuildingStartUpgrade(): void {
        this.updateShow();
    }

    /** 点击升级按钮 */
    public onClickUpgradeBtn(): void {
        const op = this.owner.openParam;
        if (!op) return;
        const context = op.context;
        const eid = op.eid;
        context.sender.interactiveBubbleAction(
            eid,
            InteractiveBubbleViewType.FOUNDATION_BUILD_VIEW,
            InteractiveBubbleActionType.FOUNDATION_BUILD_ACTION_1,
            null
        );
    }

    /** 点击加速按钮 */
    public onClickAddSpeedBtn(): void {
        app.ui.toast("onClickAddSpeedBtn");
    }

    /** 每秒刷新*/
    private onLoopFunc(): void {
        const op = this.owner.openParam;
        if (!op) return;
        const context = op.context;
        const eid = op.eid;
        const eElement = context.ecs.getComponent(eid, PveElementComponent);
        if (!eElement) return;
        // 对应等级配置

        const stateData = app.service.pve.mlData.pveStateData;
        const building = stateData.getBuildingByKey(eElement.key);
        if (!building) return;

        const levelCfgLo = app.service.pve.buildingVoMap.getVoWithBattleEntityIdAndLevel(
            eElement.teid,
            building.level
        )!;

        const pveStateData = app.service.pve.mlData.pveStateData;
        // 上次收获的时间
        let harvestTime = 0;
        const bdData = pveStateData.getBuildingByKey(eElement.key);
        harvestTime = bdData?.harvestTime ?? 0;

        // 进度信息
        this.owner.progressBlock.updateShow(levelCfgLo, harvestTime);

        // 升级时间
        let upgradeTime = 0;
        upgradeTime = bdData?.upgradeTime ?? 0;

        if (upgradeTime) {
            // 升级中...
            this.owner.img_clock.visible = true;
            this.owner.lab_01.text = "升级中";
            // 显示加速按钮
            this.owner.btn_add_speed.visible = true;
            // 显示倒计时
            const remainTime = levelCfgLo.getUpgradeRemainTime(upgradeTime);
            const remainTimeStr = TimeFormatUtils.totalTimeFormat(remainTime, "hh:mm:ss");
            this.owner.lab_clock.text = remainTimeStr;
            this.owner.btn_upgrade.visible = false;
            this.owner.costList.visible = false;
        } else {
            this.owner.btn_add_speed.visible = false;
            // 判断是否满级
            if (levelCfgLo.isMaxLevel) {
                // 已满级
                this.owner.img_clock.visible = false;
                this.owner.lab_01.text = "已达最大等级";
                this.owner.btn_upgrade.visible = false;
                this.owner.costList.visible = false;
            } else {
                // 未满级,且未在升级
                this.owner.img_clock.visible = true;
                this.owner.lab_01.text = "";
                this.owner.btn_upgrade.visible = true;
                const needTime = levelCfgLo.upgradeNeedTime;
                const remainTimeStr = TimeFormatUtils.totalTimeFormat(needTime, "hh:mm:ss");
                this.owner.lab_clock.text = remainTimeStr;

                // 升级扣除
                const upgrade_cost = levelCfgLo.upgradeCost;
                if (upgrade_cost && upgrade_cost.length > 0) {
                    this.owner.costList.visible = true;
                    this.owner.costList.array = upgrade_cost;
                } else {
                    this.owner.costList.visible = false;
                }
            }
        }
    }

    public updateShow(): void {
        const op = this.owner.openParam;
        if (!op) return;
        const context = op.context;
        const eid = op.eid;
        const eElement = context.ecs.getComponent(eid, PveElementComponent)!;

        const stateData = app.service.pve.mlData.pveStateData;
        const building = stateData.getBuildingByKey(eElement.key);
        if (!building) return;

        const levelCfgLo = app.service.pve.buildingVoMap.getVoWithBattleEntityIdAndLevel(
            eElement.teid,
            building.level
        )!;

        // 设置显示标题
        this.owner.windowBG.title.text = levelCfgLo.titleName;
        // 判断是否满级
        const isMax = levelCfgLo.isMaxLevel;
        const curLv = levelCfgLo.level;

        // 当前等级
        this.owner.levelBlock.setLevel(curLv, isMax ? curLv : curLv + 1);

        // 生产速度信息
        this.owner.speedBlock.updateShow(levelCfgLo);

        this.onLoopFunc();
    }

    onClosed() {}
}
