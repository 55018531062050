import * as ecs from "../../../../../core/ecs";
import { BulletType } from "../../../pve-server/btree/actions/CreateBullet";

// 生命周期跟随子弹Entity
export class PveBulletComponent extends ecs.Component {
    readonly mounter: Laya.Sprite3D = new Laya.Sprite3D("PveBulletComp");
    view?: Laya.Sprite3D;
    res!: string;

    h: number = 0;
    bulletType: BulletType = BulletType.Static;
}
