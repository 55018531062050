import { app } from "../../app";
import { Callback } from "../../core/dispatcher";
import { ItemLo } from "../../system/bag/vo/ItemLo";

import { ui } from "../ui";
import { IconUI } from "../ui-runtime/IconUI";

const { regClass, property } = Laya;

@regClass('0CSBVEgqR0m5WjN3WYaPUQ')
export class IconNode extends Laya.Script {
    declare owner: IconUI;

    @property(Boolean)
    isNoShowTips!: boolean;
    private clickBack: Callback | null = null;

    //组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
    override onAwake(): void {
        super.onAwake();
        this.owner.on(Laya.Event.CLICK, (evn: Laya.Event) => {
            this.onIconClick(evn);

            Laya.SoundManager.playSound("");
        });
    }

    onIconClick(evn: Laya.Event) {
        if (this.isNoShowTips && this.clickBack) {
            this.clickBack(evn);
            return;
        }
        const itemData = this.owner.dataSource as ItemLo;
        if (!itemData) {
            return;
        }
        app.ui.show(ui.ITEM_TIPS, itemData);
    }

    setClickHandler(func: Callback | null) {
        this.clickBack = func;
    }
}
