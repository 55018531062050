import * as ecs from "../../../../../core/ecs";
import { BattleConf, BattleEntityType } from "../../../../../def/auto/battle";
import { PveDef } from "../../PveDefs";
import { ElementTag } from "./PveSvrElementComponent";

/** 资源车 */

export class PveSvrTruckComponent extends ecs.Component {
    collectType!: number;
    collectCnt: number = 0;
    lastTipsTime: number = 0;

    private _maxValue: number = 0;

    public get collectTypeTag(): number {
        if (this.collectType === BattleEntityType.WOOD) {
            return ElementTag.WOOD;
        } else if (this.collectType === BattleEntityType.FOOD) {
            return ElementTag.FOOD;
        }
        return ElementTag.STONE;
    }

    public get TRUCK_COLLECT_MAX_CNT(): number {
        if (!this._maxValue) {
            let cfgValue: number | undefined;
            if (this.collectType === BattleEntityType.WOOD) {
                cfgValue = BattleConf.PVE.TRUCK_COLLECT_MAX_CNT_WOOD;
            } else if (this.collectType === BattleEntityType.FOOD) {
                cfgValue = BattleConf.PVE.TRUCK_COLLECT_MAX_CNT_FOOD;
            } else if (this.collectType === BattleEntityType.STONE) {
                cfgValue = BattleConf.PVE.TRUCK_COLLECT_MAX_CNT_STONE;
            }
            if (cfgValue) {
                this._maxValue = cfgValue;
            }
        }
        return this._maxValue;
    }

    public get isFull(): boolean {
        if (this.collectCnt >= this.TRUCK_COLLECT_MAX_CNT) {
            return true;
        }
        return false;
    }

    /** 对应的资源道具id */
    public get collectItemId(): number {
        if (this.collectType === BattleEntityType.WOOD) {
            return PveDef.COLLECT_WOOD_ID;
        } else if (this.collectType === BattleEntityType.FOOD) {
            return PveDef.COLLECT_FOOD_ID;
        } else if (this.collectType === BattleEntityType.STONE) {
            return PveDef.COLLECT_STONE_ID;
        }
        return PveDef.COLLECT_WOOD_ID;
    }
}
