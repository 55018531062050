import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { StringUtil } from "../../core/utils/string-util";
import { Reward } from "../../def/table";
import { ItemLo } from "../bag/vo/ItemLo";
import { ShopBuyUI } from "./ui-runtime/ShopBuyUI";

const { regClass, property } = Laya;

@regClass('8SyZ_59LTgiWQOBZK0eUXQ')
export class ShopBuyMediator extends Mediator {
    declare owner: ShopBuyUI;

    private itemVo!: ItemLo; //购买道具
    private costVo!: ItemLo; //货币道具

    private count: number = 0;

    override onAwake(): void {
        super.onAwake();
        this.itemVo = app.service.bag.itemLoMap.get(
            this.owner.openData.shopItem.refData.items[0].id
        );

        this.initUIEvent();
        this.initInfo();
    }

    //初始化UI
    private initInfo() {
        if (this.itemVo) {
            this.count = this.owner.openData.shopItem.refData.items[0].num;
            this.owner.icon.updateGoods(this.itemVo);
            this.owner.labelName.text = this.itemVo.name;
            const amount = app.service.bag.itemVoMap.getItemAmount(this.itemVo.id);
            this.owner.labelItemCurNum.text = `当前拥有:${amount}`;
        }
        const cost = this.owner.openData.shopItem.refData.cost as Reward[];

        this.costVo = app.service.bag.itemLoMap.get(cost[0].id);
        const costBagNum = app.service.bag.itemVoMap.getItemAmount(cost[0].id);
        // this.owner.slider.max = app.service.shop.getShopItemBuyNum(
        //     this.owner.openData.shopItem
        // ).num;
        this.owner.labelNum.text = StringUtil.str2UBB(
            "{0} {1}/{2}",
            { image: this.costVo.iconUrl, width: 20, height: 20 },
            {
                text: `{voNum=${costBagNum}}`,
            },
            { text: `{seleNum=${cost[0].num}}` }
        );
    }

    //初始化UI事件监听
    initUIEvent() {
        this.owner.btnClose.on(Laya.Event.CLICK, this.owner, this.owner.close);
        // this.owner.slider.onSliderChange = () => {
        //     this.owner.icon.itemNumber = (
        //         this.owner.slider.value * this.owner.openData.shopItem.refData.items[0].num
        //     ).toString();
        //     if (this.owner.openData.shopItem.refData.cost) {
        //         this.owner.labelNum.setVar(
        //             "seleNum",
        //             this.owner.slider.value * this.owner.openData.shopItem.refData.cost[0].count
        //         );
        //     }
        // };
        this.owner.btnSynthesis.on(Laya.Event.CLICK, this, this.callBuy);
    }

    //请求购买
    async callBuy() {
        // app.service.shop
        //     .requestBuy({
        //         shopId: this.owner.openData.shopId,
        //         shopItemId: this.owner.openData.shopItem.refData.id,
        //         num: this.owner.slider.value,
        //     })
        //     .then(() => {
        //         if (this.owner.openData.buyBack) {
        //             this.owner.openData.buyBack.run();
        //         }
        //         this.owner.close();
        //     });
    }
}
