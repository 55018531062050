import { app } from "../../../app";
import { Mediator } from "../../../core/ui-mediator";
import { SystemEvent } from "../../../misc/system-event";
import { ui } from "../../../misc/ui";
import { PvpUI } from "../ui-runtime/PvpUI";

//专门处理UI
@Laya.regClass('nNWxWEPRQ5eJPxWEh9-lyQ')
export class PvpMediator extends Mediator {
    declare owner: PvpUI;

    private _camera!: Laya.Camera;

    get camera() {
        return (this._camera ||= this.owner.scene3D.getChildByName("Main Camera") as Laya.Camera);
    }

    // 确保执行在PvpContext的onAwake之后
    override onAwake() {
        super.onAwake();
        this._initMapClickArea();
        this._initBattleReport();
        this.initUIEvent();
    }

    private initUIEvent() {
        this.owner.btnEditTroops.on(Laya.Event.CLICK, this, () => {
            app.ui.show(ui.TROOP_TEAM);
        });
    }

    private _initMapClickArea() {
        const clickArea = this.owner.mapTouchArea.getChildAt(0);
        let clickEnabled = false;
        let isDown = false;

        const lastPos = new Laya.Vector2();
        const currPos = new Laya.Vector2();

        // 计算拖动距离
        const ray = new Laya.Ray(new Laya.Vector3(), new Laya.Vector3());
        const lastXZPos = new Laya.Vector3();
        const currXZPos = new Laya.Vector3();

        // const selectedTile = this.scene3D.getChildByPath("world-map/selected") as Laya.Sprite3D;
        // const debugFocus = this.scene3D.getChildByPath("world-map/debugFocus") as Laya.Sprite3D;
        // selectedTile.active = false;

        const viewportPointToXZ = (point: Laya.Vector2, out: Laya.Vector3) => {
            this.camera.viewportPointToRay(point, ray);
            const t = -ray.origin.y / ray.direction.y;
            ray.direction.scale(t, ray.direction);
            ray.origin.vadd(ray.direction, out);
        };

        clickArea.on(Laya.Event.MOUSE_DOWN, (e: Laya.Event) => {
            clickEnabled = true;
            isDown = true;

            const target = e.target as Laya.Sprite;
            lastPos.setValue(target.mouseX, target.mouseY);
            Laya.stage.addChild(clickArea);

            // 派发事件即可
            app.event(SystemEvent.PVP.TOUCH_AREA_MOUSE_DOWN);
        });

        clickArea.on(Laya.Event.MOUSE_MOVE, (e: Laya.Event) => {
            if (!isDown) {
                return;
            }
            const target = e.target as Laya.Sprite;
            currPos.setValue(target.mouseX, target.mouseY);
            if (!clickEnabled) {
                // means MOVING
                viewportPointToXZ(lastPos, lastXZPos);
                viewportPointToXZ(currPos, currXZPos);
                lastXZPos.vsub(currXZPos, lastXZPos);
                lastPos.cloneFrom(currPos);

                app.event(SystemEvent.PVP.TILEMAP_DRAG_MOVE, lastXZPos.clone()); //内部变量通过clone传递
            } else if (Laya.Vector2.distance(lastPos, currPos) > 5) {
                // means START_MOVE
                clickEnabled = false;
                // 派发事件即可
                app.event(SystemEvent.PVP.TILEMAP_DRAG_START);
            }
        });

        clickArea.on(Laya.Event.MOUSE_UP, (e: Laya.Event) => {
            isDown = false;
            this.owner.mapTouchArea.addChild(clickArea);
            if (clickEnabled) {
                const target = e.target as Laya.Sprite;
                currPos.setValue(target.mouseX, target.mouseY);
                viewportPointToXZ(currPos, currXZPos);
                currXZPos.x = Math.floor(currXZPos.x);
                currXZPos.z = Math.floor(currXZPos.z);

                // 派发事件即可
                app.event(SystemEvent.PVP.TILEMAP_CLICK, currXZPos.clone()); //内部变量通过clone传递
            } else {
                // 派发事件即可
                app.event(SystemEvent.PVP.TILEMAP_DRAG_END);
            }
        });

        clickArea.on(Laya.Event.MOUSE_OUT, (e: Laya.Event) => {
            isDown = false;
            // 派发事件即可
            app.event(SystemEvent.PVP.TOUCH_AREA_MOUSE_OUT);
        });
    }

    private _initBattleReport() {
        this.owner.btnReport.onClick(() => {
            app.ui.show(ui.PVP_BATTLE_REPORT);
        });
    }
}
