import * as ecs from "../../../../../core/ecs";
import { PveSvrOffsetComponent } from "./PveSvrOffsetComponent";

// 士兵组件，专用
export class PveSvrSoldierComponent extends ecs.Component {
    override get dependencies() {
        return [PveSvrOffsetComponent];
    }

    groupEid: number = 0;
}
