/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/alliance/AllianceAvatar.lh
 */
export class AllianceAvatarBase extends Laya.Box {
    public icon!: Laya.Image;
}

