const { regClass } = Laya;
import { app } from "../../../../app";
import { ui } from "../../../../misc/ui";
import { ChestBoxHeroBase } from "./ChestBoxHero.generated";

@regClass('rQX6X5yqR9-JwuN09k7Vow')
export class ChestBoxHero extends ChestBoxHeroBase {
    override onAwake(): void {
        super.onAwake();
        this.btn_01.onClick(this.onClickChangeBtn.bind(this));
    }

    public updateShow(): void {
        const lo = app.service.chest.curHeroLo;
        if (lo.iconFull) {
            this.hero_avatar.skin = lo.iconFull;
        } else {
            console.error("找不到英雄头像iconFull");
        }
    }

    private onClickChangeBtn(): void {
        app.ui.show(ui.CHEST_HERO);
    }
}
