const { regClass } = Laya;
import { NormalTalentItemUIBase } from "./NormalTalentItemUI.generated";
import { app } from "../../../app";
import { TalentTreeRow } from "../../../def/table";
import { SystemEvent } from "../../../misc/system-event";

@regClass('rd65b9vmRXeznjLNEV4ctA')
export class NormalTalentItemUI extends NormalTalentItemUIBase {
    private cfgData: TalentTreeRow | null = null;

    initPanel(tanlentData: TalentTreeRow) {
        this.cfgData = tanlentData;
        const actNormalId = app.service.trainCamp.getCurActNomalId();
        this.imgBar.value = actNormalId >= tanlentData.id ? 1 : 0;
        this.imgIcon.skin = app.service.trainCamp.getTalentIcon(tanlentData.effect_id);
        this.boxIcon.gray = actNormalId < tanlentData.id;
        this.imgUp.visible = app.service.trainCamp.checkIsCanActive(tanlentData);
        const isStartPos = app.service.trainCamp.getNormalIsStartPos(tanlentData);
        this.imgBar.visible = this.imgBarBg.visible = !isStartPos;
        this.boxIcon.on(Laya.Event.CLICK, this, this.onCickIcon);
    }

    private onCickIcon() {
        app.event(SystemEvent.TRAINCAMP.CLICK_ICON, this.cfgData);
    }
}
