/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { BaseRoundWindowBg } from "../../../../common/ui-runtime/dialog/BaseRoundWindowBg";
import { AreaProgressEnterBtnUI } from "./AreaProgressEnterBtnUI";

/**
 * resources/prefab/map-transfer/UIMapTransfer.lh
 */
export class UIMapTransferBase extends Laya.Dialog {
    public baseRoundWindowBg!: BaseRoundWindowBg;
    public contentBox!: Laya.Box;
    public btnList02!: Laya.List;
    public btnList01!: Laya.List;
    public areaProgressEnterBtn!: AreaProgressEnterBtnUI;
}

