import { Mediator } from "../../core/ui-mediator";
import { UIAllianceList } from "./ui-runtime/UIAllianceList";

const { regClass, property } = Laya;

@regClass('fW1Xjik3Q9u9b0soSUYiWA')
export class UIAllianceListMediator extends Mediator {
    declare owner: UIAllianceList;

    override onAwake(): void {
        super.onAwake();
    }
}
