/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { BaseMidItemIcon } from "../../system/common/ui-runtime/item-icon/BaseMidItemIcon";

/**
 * resources/prefab/icon/icon.lh
 */
export class IconUIBase extends Laya.Box {
    public itemIcon!: BaseMidItemIcon;
    public labelNumber!: Laya.Label;
}
