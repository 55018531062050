import proto from "../../../def/auto/proto";
import { HeroJobs, HeroVo, HeroVoBase } from "./HeroVo";

export class HeroHandbookVo extends HeroVoBase<proto.hero.HeroHandbookItem> {
    override clone(): HeroHandbookVo {
        return new HeroHandbookVo(this._config, this._data);
    }

    override get key(): number {
        return this._config.id;
    }

    get isUnlock(): boolean {
        return !!this._data;
    }

    get star() {
        return this._data?.star;
    }

    get awardedStar() {
        return this._data?.awardedStar;
    }

    get jobDesc(): string {
        return HeroJobs.find((v) => v.job === this.job)?.desc ?? "";
    }

    get quality(): number {
        return this._data ? HeroVo.GetQualityByStar(this.star!) : this.baseQuality;
    }
}
