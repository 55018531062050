/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { IconUI } from "../../../misc/ui-runtime/IconUI";
import { UIHSlider } from "../../common/ui-runtime/UIHSlider";

/**
 * resources/prefab/bag/UIItemCompose.lh
 */
export class UIItemComposeBase extends Laya.Dialog {
    public imgMask!: Laya.Image;
    public imgBg!: Laya.Image;
    public iconTop!: IconUI;
    public labOwn!: Laya.Label;
    public labCount!: Laya.Label;
    public labelName!: Laya.Label;
    public labelDesc!: Laya.TextArea;
    public btnUse!: Laya.Button;
    public hsliderCom!: UIHSlider;
}

