/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

/**
 * resources/prefab/chat/chat-cell.lh
 */
export class ChatCellUIBase extends Laya.Box {
    imgHead!: Laya.Image;
    labelName!: Laya.Label;
    labelMsg!: Laya.Label;
}
