import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { BattleConf, BattleEntityType } from "../../../../../def/auto/battle";
import { SystemEvent } from "../../../../../misc/system-event";
import { Animator } from "../../../base/Animator";
import { TMPropKey } from "../../../tilemap/tm-def";
import { CloudConfirmUI } from "../../../ui-runtime/pve/CloudConfirmUI";
import { HeadInfoUI } from "../../../ui-runtime/pve/HeadInfoUI";
import { RescueSoldierConfirmUI } from "../../../ui-runtime/pve/RescueSoldierConfirmUI";
import { TruckHeadInfo } from "../../../ui-runtime/pve/TruckHeadInfo";
import { BDCommonInfoUI } from "../../../ui-runtime/pve/build/BDCommonInfoUI";
import { BDProductionInfoUI } from "../../../ui-runtime/pve/build/BDProductionInfoUI";
import { BDUpgradeInfoUI } from "../../../ui-runtime/pve/build/BDUpgradeInfoUI";
import { BuildConfirmUI } from "../../../ui-runtime/pve/build/BuildConfirmUI";
import { BuildingConfirmUI } from "../../../ui-runtime/pve/build/BuildingConfirmUI";
import { NpcOperationConfirmUI } from "../../../ui-runtime/pve/npc/NpcOperationConfirmUI";
import { PveContext } from "../../PveContext";
import { PveAnimationComponent } from "../components/PveAnimationComponent";
import { PveBoardComponent } from "../components/PveBoardComponent";
import { PveBulletComponent } from "../components/PveBulletComponent";
import { PveElementComponent } from "../components/PveElementComponent";
import { PveHeadInfoComponent } from "../components/PveHeadInfoComponent";
import { PveMapComponent } from "../components/PveMapComponent";
import { PveNavinArrowComponent as PveNaviArrowComponent } from "../components/PveNavinArrowComponent";
import { PveShadowComponent } from "../components/PveShadowComponent";
import { PveSkillWarningComponent } from "../components/PveSkillWarningComponent";
import { PveSpoilsComponent } from "../components/PveSpoilsComponent";
import { PveTransformComponent } from "../components/PveTransformComponent";
import { PveUIBDUpGradeComponent } from "../components/PveUIBDUpGradeComponent";
import { PveUIBuildConfirmComponent } from "../components/PveUIBuildConfirmComponent";
import { PveUIBuildingConfirmComponent } from "../components/PveUIBuildingConfirmComponent";
import { PveUICloudComponent } from "../components/PveUICloudComponent";
import { PveUICommonInfoComponent } from "../components/PveUICommonInfoComponent";
import { PveUINpcOperationConfirmComponent } from "../components/PveUINpcOperationConfirmComponent";
import { PveUIProductionInfoComponent } from "../components/PveUIProductionInfoComponent";
import { PveUIRescueComponent } from "../components/PveUIRescueComponent";
import { PveUITruckHudComponent } from "../components/PveUITruckHudComponent";
import { PveVFXComponent } from "../components/PveVFXComponent";
import { PveCommandSystem } from "./PveCommandSystem";

export interface IViewComponent {
    readonly mounter: Laya.Node;
    view?: Laya.Node | null;
    res?: string;
}

const tmpInfoVector3 = new Laya.Vector3();
const tmpInfoVector4 = new Laya.Vector4();

export class PveRenderSystem extends ecs.System {
    declare context: PveContext;

    override onCreate(): void {
        this.registerHandler(
            PveAnimationComponent,
            this._onAddPveAnimationComponent,
            this._onDelPveAnimationComponent
        );
        this.registerHandler(
            PveHeadInfoComponent,
            this._onAddPveHeadInfoComponent,
            this._onDelPveHeadInfoComponent
        );
        this.registerHandler(
            PveUITruckHudComponent,
            this._onAddPveUITruckHudComponent,
            this._onDelPveUITruckHudComponent
        );
        this.registerHandler(
            PveShadowComponent,
            this._onAddPveShadowComponent,
            this._onDelPveShadowComponent
        );
        this.registerHandler(
            PveNaviArrowComponent,
            this._onAddPveNaviArrowComponent,
            this._onDelPveNaviArrowComponent
        );
        this.registerHandler(
            PveBoardComponent,
            this._onAddPveBoardComponent,
            this._onDelPveBoardComponent
        );
        this.registerHandler(
            PveSpoilsComponent,
            this._onAddPveSpoilsComponent,
            this._onDelPveSpoilsComponent
        );
        this.registerHandler(
            PveSkillWarningComponent,
            this._onAddPveSkillWarningComponent,
            this._onDelPveSkillWarningComponent
        );
        this.registerHandler(
            PveUIRescueComponent,
            this._onAddPveUIRescueComponent,
            this._onDelPveUIRescueComponent
        );
        this.registerHandler(
            PveUICloudComponent,
            this._onAddPveUICloudComponent,
            this._onDelPveUICloudComponent
        );

        this.registerHandler(
            PveUIBuildConfirmComponent,
            this._onAddPveUIBuildConfirmComponent,
            this._onDelPveUIBuildConfirmComponent
        );

        this.registerHandler(
            PveUIBDUpGradeComponent,
            this._onAddPveUIBDUpGradeComponent,
            this._onDelPveUIBDUpGradeComponent
        );

        this.registerHandler(
            PveUIProductionInfoComponent,
            this._onAddPveUIProductionInfoComponent,
            this._onDelPveUIProductionInfoComponent
        );

        this.registerHandler(
            PveUICommonInfoComponent,
            this._onAddPveUICommonInfoComponent,
            this._onDelPveUICommonInfoComponent
        );

        this.registerHandler(
            PveUIBuildingConfirmComponent,
            this._onAddPveUIBuildingConfirmComponent,
            this._onDelPveUIBuildingConfirmComponent
        );

        this.registerHandler(
            PveUINpcOperationConfirmComponent,
            this._onAddPveUINpcOperationConfirmComponent,
            this._onDelPveUINpcOperationConfirmComponent
        );

        this.registerHandler(
            PveBulletComponent,
            this._onAddPveBulletComponent,
            this._onDelPveBulletComponent
        );
        this.registerHandler(
            PveVFXComponent,
            this._onAddPveVFXComponent,
            this._onDelPveVFXComponent
        );
    }

    private _destroyViewComponent<T extends IViewComponent>(component: T) {
        component.view?.destroy();
        component.view = null;
        component.mounter.destroy();
    }

    override update(dt: number): void {
        this.ecs.getComponents(PveAnimationComponent).forEach((anim) => {
            this._updateTransform(anim, true);
        });
        this.ecs.getComponents(PveBulletComponent).forEach((comp) => {
            this._updateTransform(comp);
        });
        this.ecs.getComponents(PveVFXComponent).forEach((comp) => {
            this._updateTransform(comp);
        });
        this.ecs.getComponents(PveHeadInfoComponent).forEach((info) => {
            this._updateHeadInfoPos(info);
        });
        this.ecs.getComponents(PveUITruckHudComponent).forEach((info) => {
            this._updateTruckHeadInfoPos(info);
        });
    }

    private _updateTransform(
        comp: ecs.Component & { mounter: Laya.Sprite3D },
        updateShadow?: boolean
    ) {
        const transform = comp.getComponent(PveTransformComponent)!;
        const targetTransform = comp.mounter.transform;

        if (transform.flag & PveTransformComponent.POSITION) {
            if (updateShadow) {
                // 更新阴影位置
                const shadow = comp.getComponent(PveShadowComponent);
                if (shadow) {
                    const p = shadow.mounter.transform.position;
                    p.x = transform.position.x;
                    p.z = transform.position.z;
                    p.y = BattleConf.GROUND_OFFSET.SHADOW;
                    shadow.mounter.transform.position = p;
                }
            }

            targetTransform.position = transform.position;
            transform.flag &= ~PveTransformComponent.POSITION;
        }

        if (transform.flag & PveTransformComponent.ROTATION_QUAT) {
            targetTransform.rotation = transform.quaternion;
            transform.flag &= ~PveTransformComponent.ROTATION_QUAT;
        } else if (transform.flag & PveTransformComponent.ROTATION) {
            const roEuler = targetTransform.rotationEuler;
            roEuler.y = transform.rotation;
            targetTransform.rotationEuler = roEuler;
            transform.flag &= ~PveTransformComponent.ROTATION;
        }
    }

    private _updateHeadInfoPos(info: PveHeadInfoComponent) {
        const animation = info.getComponent(PveAnimationComponent)!;
        if (!animation || !info.view) {
            return;
        }

        const skinCfg = app.service.table.skin[info.data.skinId];
        let model_height = 0;
        if (skinCfg && skinCfg.model_height) {
            model_height = skinCfg.model_height;
        }
        const position = animation.mounter.transform.position;
        tmpInfoVector3.x = position.x;
        tmpInfoVector3.y = model_height;
        tmpInfoVector3.z = position.z;

        this.context.camera.worldToViewportPoint(tmpInfoVector3, tmpInfoVector4);
        info.mounter.pos(tmpInfoVector4.x - info.view.width / 2, tmpInfoVector4.y, true);
    }

    private _updateTruckHeadInfoPos(info: PveUITruckHudComponent) {
        const animation = info.getComponent(PveAnimationComponent)!;
        if (!animation.view || !info.view) {
            return;
        }

        this.context.camera.worldToViewportPoint(
            animation.mounter.transform.position,
            tmpInfoVector4
        );
        info.view.pos(tmpInfoVector4.x - info.view.width / 2, tmpInfoVector4.y - 0, true);
    }

    private async _onAddPveBulletComponent(comp: PveBulletComponent) {
        this.context.owner.roles.addChild(comp.mounter);
        this._updateTransform(comp);

        if (comp.res) {
            const checker = comp.checker;
            const prefab = await app.loader.loadPrefab(comp.res);
            if (!checker()) {
                return;
            }

            comp.view = prefab.create() as Laya.Sprite3D;
            comp.view.transform.position.y = comp.h;
            comp.mounter.addChild(comp.view);
        }
        // 发送子弹模型加载完成事件 ？
    }

    private _onDelPveBulletComponent(comp: PveBulletComponent) {
        this._destroyViewComponent(comp);
    }

    private async _onAddPveVFXComponent(comp: PveVFXComponent) {
        this.context.owner.roles.addChild(comp.mounter);
        this._updateTransform(comp);

        if (comp.res) {
            const checker = comp.checker;
            const prefab = await app.loader.loadPrefab(comp.res);
            if (!checker()) {
                return;
            }

            comp.view = prefab.create() as Laya.Sprite3D;
            comp.mounter.addChild(comp.view);
        }
    }

    private _onDelPveVFXComponent(comp: PveVFXComponent) {
        this._destroyViewComponent<PveVFXComponent>(comp);
    }

    private async _onAddPveHeadInfoComponent(info: PveHeadInfoComponent) {
        this.context.owner.troops.addChild(info.mounter);
        if (info.res) {
            const checker = info.checker;
            const prefab = await app.loader.loadPrefab(info.res);
            if (!checker()) {
                return;
            }

            info.view = prefab.create() as HeadInfoUI;
            info.view.update(info.data);
            info.mounter.addChild(info.view);
        }
    }

    private _onDelPveHeadInfoComponent(info: PveHeadInfoComponent) {
        this._destroyViewComponent(info);
    }

    private async _onAddPveUITruckHudComponent(info: PveUITruckHudComponent) {
        if (info.res) {
            const checker = info.checker;
            const prefab = await app.loader.loadPrefab(info.res);
            if (!checker()) {
                return;
            }

            info.view = prefab.create() as TruckHeadInfo;
            info.view.update(info);
            this.context.owner.troops.addChild(info.view);
        }
    }

    private _onDelPveUITruckHudComponent(info: PveUITruckHudComponent) {
        this._destroyViewComponent(info);
    }

    private async _onAddPveShadowComponent(shadow: PveShadowComponent) {
        this.context.owner.shadows.addChild(shadow.mounter);

        const checker = shadow.checker;
        const prefab = await app.loader.loadPrefab(shadow.res);
        if (!checker()) {
            return;
        }

        shadow.view = prefab.create() as Laya.Sprite3D;
        shadow.view.transform.localPositionY = BattleConf.GROUND_OFFSET.SHADOW;
        shadow.mounter.addChild(shadow.view);
    }

    private _onDelPveShadowComponent(shadow: PveShadowComponent) {
        this._destroyViewComponent(shadow);
    }

    private async _onAddPveNaviArrowComponent(navigationArrow: PveNaviArrowComponent) {
        this.context.owner.navigationArrows.addChild(navigationArrow.mounter);

        const checker = navigationArrow.checker;
        const prefab = await app.loader.loadPrefab(navigationArrow.res);
        if (!checker()) {
            return;
        }

        navigationArrow.view = prefab.create() as Laya.Sprite3D;
        navigationArrow.view.transform.localPositionY = BattleConf.GROUND_OFFSET.NAVI_ARROW;
        navigationArrow.mounter.addChild(navigationArrow.view);
    }

    private _onDelPveNaviArrowComponent(navigationArrow: PveNaviArrowComponent) {
        this._destroyViewComponent(navigationArrow);
    }

    private async _onAddPveUIRescueComponent(comp: PveUIRescueComponent) {
        this.context.owner.battleui.addChild(comp.mounter);

        // 加载预置
        const checker = comp.checker;
        const prefab = await app.loader.loadPrefab(comp.res);
        if (!checker()) {
            return;
        }

        // 创建
        const instance = prefab.create() as RescueSoldierConfirmUI;
        instance.name = "rescue_confirm_" + comp.eid;
        instance.context = this.context;
        instance.comp = comp;
        comp.view = instance;
        comp.mounter.addChild(comp.view);
        app.event(SystemEvent.PVE.ON_UI_RESCUE_LOADED, comp.eid);
    }

    private _onDelPveUIRescueComponent(comp: PveUIRescueComponent) {
        this._destroyViewComponent(comp);
    }

    private async _onAddPveUICloudComponent(comp: PveUICloudComponent) {
        this.context.owner.battleui.addChild(comp.mounter);

        // 加载预置
        const checker = comp.checker;
        const prefab = await app.loader.loadPrefab(comp.res);
        if (!checker()) {
            return;
        }

        // 创建
        const instance = prefab.create() as CloudConfirmUI;
        instance.name = "cloud_confirm_" + comp.eid;
        instance.context = this.context;
        instance.comp = comp;
        comp.view = instance;
        comp.mounter.addChild(comp.view);
        app.event(SystemEvent.PVE.ON_UI_CLOUD_LOADED, comp.eid);
    }

    private _onDelPveUICloudComponent(comp: PveUICloudComponent) {
        this._destroyViewComponent(comp);
    }

    private async _onAddPveUIBuildConfirmComponent(comp: PveUIBuildConfirmComponent) {
        this.context.owner.battleui.addChild(comp.mounter);
        // 加载预置
        const checker = comp.checker;
        const prefab = await app.loader.loadPrefab(comp.res);
        if (!checker()) {
            return;
        }

        // 创建
        const instance = prefab.create() as BuildConfirmUI;
        instance.name = "build_confirm_" + comp.eid;
        instance.context = this.context;
        instance.comp = comp;
        comp.view = instance;
        comp.mounter.addChild(comp.view);
        app.event(SystemEvent.PVE.ON_UI_BUILD_CONFIRM_LOADED, comp.eid);
    }

    private _onDelPveUIBuildConfirmComponent(comp: PveUIBuildConfirmComponent) {
        this._destroyViewComponent(comp);
    }

    private async _onAddPveUIBDUpGradeComponent(comp: PveUIBDUpGradeComponent) {
        this.context.owner.battleui.addChild(comp.mounter);
        // 加载预置
        const checker = comp.checker;
        const prefab = await app.loader.loadPrefab(comp.res);
        if (!checker()) {
            return;
        }

        // 创建
        const instance = prefab.create() as BDUpgradeInfoUI;
        instance.name = "build_upgrade_" + comp.eid;
        instance.context = this.context;
        instance.comp = comp;
        comp.view = instance;
        comp.mounter.addChild(comp.view);
        app.event(SystemEvent.PVE.ON_UI_DB_UPGRADE_LOADED, comp.eid);
    }

    private _onDelPveUIBDUpGradeComponent(comp: PveUIBDUpGradeComponent) {
        this._destroyViewComponent(comp);
    }

    private async _onAddPveUIProductionInfoComponent(comp: PveUIProductionInfoComponent) {
        this.context.owner.battleui.addChild(comp.mounter);
        // 加载预置
        const checker = comp.checker;
        const prefab = await app.loader.loadPrefab(comp.res);
        if (!checker()) {
            return;
        }

        // 创建
        const instance = prefab.create() as BDProductionInfoUI;
        instance.name = "production_info_" + comp.eid;
        instance.context = this.context;
        instance.comp = comp;
        comp.view = instance;
        comp.mounter.addChild(comp.view);
        app.event(SystemEvent.PVE.ON_UI_DB_PRODUCTIONINFO_LOADED, comp.eid);
    }

    private _onDelPveUIProductionInfoComponent(comp: PveUIProductionInfoComponent) {
        this._destroyViewComponent(comp);
    }

    private async _onAddPveUICommonInfoComponent(comp: PveUICommonInfoComponent) {
        this.context.owner.battleui.addChild(comp.mounter);
        // 加载预置
        const checker = comp.checker;
        const prefab = await app.loader.loadPrefab(comp.res);
        if (!checker()) {
            return;
        }

        // 创建
        const instance = prefab.create() as BDCommonInfoUI;
        instance.name = "bd_common_info_" + comp.eid;
        instance.context = this.context;
        instance.comp = comp;
        comp.view = instance;
        comp.mounter.addChild(comp.view);
        app.event(SystemEvent.PVE.ON_UI_DB_COMMONINFO_LOADED, comp.eid);
    }

    private _onDelPveUICommonInfoComponent(comp: PveUICommonInfoComponent) {
        this._destroyViewComponent(comp);
    }

    private async _onAddPveUIBuildingConfirmComponent(comp: PveUIBuildingConfirmComponent) {
        this.context.owner.battleui.addChild(comp.mounter);
        // 加载预置
        const checker = comp.checker;
        const prefab = await app.loader.loadPrefab(comp.res);
        if (!checker()) {
            return;
        }

        // 创建
        const instance = prefab.create() as BuildingConfirmUI;
        instance.name = "building_confirm_" + comp.eid;
        instance.context = this.context;
        instance.comp = comp;
        comp.view = instance;
        comp.mounter.addChild(comp.view);
        app.event(SystemEvent.PVE.ON_UI_BUILDING_CONFIRM_LOADED, comp.eid);
    }

    private _onDelPveUIBuildingConfirmComponent(comp: PveUIBuildingConfirmComponent) {
        this._destroyViewComponent(comp);
    }

    private async _onAddPveUINpcOperationConfirmComponent(comp: PveUINpcOperationConfirmComponent) {
        this.context.owner.battleui.addChild(comp.mounter);
        // 加载预置
        const checker = comp.checker;
        const prefab = await app.loader.loadPrefab(comp.res);
        if (!checker()) {
            return;
        }

        // 创建
        const instance = prefab.create() as NpcOperationConfirmUI;
        instance.name = "npc_confirm_" + comp.eid;
        instance.context = this.context;
        instance.comp = comp;
        comp.view = instance;
        comp.mounter.addChild(comp.view);
        app.event(SystemEvent.PVE.ON_UI_NPC_OP_CONFIRM_LOADED, comp.eid);
    }

    private _onDelPveUINpcOperationConfirmComponent(comp: PveUINpcOperationConfirmComponent) {
        this._destroyViewComponent(comp);
    }

    private async _onAddPveSkillWarningComponent(skillWarning: PveSkillWarningComponent) {
        this.context.owner.objects.addChild(skillWarning.mounter);
        // 加载预置
        const checker = skillWarning.checker;
        const prefab = await app.loader.loadPrefab(skillWarning.res);
        if (!checker()) {
            return;
        }

        skillWarning.view = prefab.create() as Laya.Sprite3D;
        skillWarning.view.name = "skill_warning_" + skillWarning.eid;
        skillWarning.mounter.addChild(skillWarning.view);

        app.event(SystemEvent.PVE.ON_SKILL_WARNING_LOADED, skillWarning.eid);
    }

    private _onDelPveSkillWarningComponent(skillWarning: PveSkillWarningComponent) {
        this._destroyViewComponent(skillWarning);
    }

    private async _onAddPveSpoilsComponent(spoils: PveSpoilsComponent) {
        // 加载图片资源
        const element = spoils.getComponent(PveElementComponent)!;
        const table = app.service.table;
        const spoilsRow = table.spoils[element.tid];
        const itemLo = app.service.bag.itemLoMap.get(spoilsRow.item_id);

        // const resName = spoilsRow.texture_key;
        const path = itemLo.modelPrefebSkinUrl;
        if (!path) {
            return;
        }
        const checker = spoils.checker;
        const prefab = await app.loader.loadPrefab(path);
        if (!checker()) {
            return;
        }

        // 创建广告板并设置坐标
        const board = prefab.create() as Laya.Sprite3D;
        board.name = "spolis_" + spoils.eid;
        spoils.view = board;

        const transform = spoils.getComponent(PveTransformComponent)!;

        // 添加到地图
        this.context.owner.objects.addChild(spoils.view);
        board.transform.position = transform.position;
    }

    private _onDelPveSpoilsComponent(spoils: PveSpoilsComponent) {
        this._destroyViewComponent(spoils);
    }

    private async _onAddPveBoardComponent(board: PveBoardComponent) {
        const transform = board.getComponent(PveTransformComponent)!;
        const element = board.getComponent(PveElementComponent)!;
        const headInfo = board.getComponent(PveHeadInfoComponent)!;

        const etype = element.etype;
        const table = app.service.table;

        const props = new Map<string, unknown>();
        if (etype === BattleEntityType.EVENT) {
            const eventVo = app.service.pve.eventLoMap.get(element.tid)!;
            props.set(TMPropKey.TextureKey, eventVo.textureKey);
        } else {
            // TODO：其他实体类型的属性待定义
            console.log("TODO: load borad ", etype);
        }
        if (props.size === 0) {
            return;
        }
        const gridX = Math.floor(transform.position.x);
        const gridY = Math.floor(transform.position.z);

        const map = this.ecs.getSingletonComponent(PveMapComponent);
        map.tilemap.addObjectElement(board.eid, gridX, gridY, props);
    }

    private _onDelPveBoardComponent(board: PveBoardComponent) {
        const map = this.ecs.getSingletonComponent(PveMapComponent);
        map.tilemap.delObjectElementByEid(board.eid);
    }

    //#region PveAnimationComponent
    private async _onAddPveAnimationComponent(anim: PveAnimationComponent) {
        this.context.owner.roles.addChild(anim.mounter);

        const checker = anim.checker;
        const prefab = await app.loader.loadPrefab(anim.res);
        if (!checker()) {
            return;
        }

        const transform = anim.getComponent(PveTransformComponent)!;
        transform.flag = PveTransformComponent.POSITION;
        this._updateTransform(anim);

        anim.view = prefab.create() as Laya.Sprite3D;
        anim.mounter.addChild(anim.view);
        anim.animator = anim.view.getComponent(Animator);
        if (anim.animator) {
            anim.animator.url = anim.res;
            anim.animator.play("idle");
        }

        // 发送动画加载完成事件
        app.event(SystemEvent.PVE.ON_ANIMATION_LOADED, anim.eid);

        const etype = anim.etype;
        if (
            etype === BattleEntityType.WOOD ||
            etype === BattleEntityType.FOOD ||
            etype === BattleEntityType.STONE
        ) {
            const element = anim.getComponent(PveElementComponent)!;
            const headInfo = anim.getComponent(PveHeadInfoComponent)!;
            const commandSystem = this.ecs.getSystem(PveCommandSystem);
            const curHp = headInfo.data.hp;
            commandSystem?.updateCollectionHp(element, curHp, headInfo.data.maxHp, false);
        }
    }

    private _onDelPveAnimationComponent(component: PveAnimationComponent) {
        component.animator = null;
        this._destroyViewComponent(component);
    }
    //#endregion
}
