const { regClass } = Laya;
import { Callback } from "../../../core/dispatcher";
import { UIInputBase } from "./UIInput.generated";

@regClass('b64DNDL3RwC_oMuRwUb-vw')
export class UIInput extends UIInputBase {
    private m_value: number = 0;
    private m_max: number = 0;
    private _onInputChange: Callback | undefined;

    override onAwake(): void {
        super.onAwake();
        super.onAwake();

        this.addBtn.on(Laya.Event.CLICK, this, this.onAddBtn);
        this.minBtn.on(Laya.Event.CLICK, this, this.onMinBtn);
        this.inputText.on(Laya.Event.INPUT, this, this.onInputText);
    }

    private onInputText() {
        const text = this.inputText.text;
        let value = text ? parseInt(text) : 0;
        if (value > this.m_max) {
            value = this.m_max;
        }
        if (value < 0) {
            value = 0;
        }
        this.m_value = value;
        this.updateBtnState();
        this.updateValue();
    }

    public setData(value: number, max: number) {
        this.m_value = value;
        this.m_max = max;
        this.updateValue();
        this.updateBtnState();
    }

    public set max(value: number) {
        this.m_max = value;
        this.updateBtnState();
    }

    private onAddBtn() {
        this.m_value++;
        this.updateValue();
        this.updateBtnState();
    }

    private onMinBtn() {
        this.m_value--;
        this.updateValue();
        this.updateBtnState();
    }

    private updateBtnState() {
        this.minBtn.disabled = this.m_value <= 0;
        this.addBtn.disabled = this.m_value === this.m_max;
    }

    private updateValue() {
        this.inputText.text = `${this.m_value}`;
        if (this._onInputChange) {
            this._onInputChange(this.m_value);
        }
    }

    set onInputChange(func: Callback) {
        this._onInputChange = func;
    }
}
