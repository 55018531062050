import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";

type Input = [unknown[]];

export class GetRandomIndex extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const [inputs] = env.input as Input;
        if (!(inputs instanceof Array) || inputs.length === 0) {
            return "failure";
        }

        const randomIndex = Math.floor(Math.random() * inputs.length);
        env.output.push(inputs[randomIndex]);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "GetRandomIndex",
            type: "Action",
            desc: "随机返回输入的其中一个!",
            input: ["输入目标"],
            output: ["随机目标"],
            doc: `
                + 此节点只能在技能行为树使用!
            `,
        };
    }
}
