import { HeroVoBase } from "./HeroVo";

export class HeroLo extends HeroVoBase<never> {
    override get key() {
        return this._config.id;
    }

    override clone(): HeroLo {
        return new HeroLo(this._config);
    }
}
