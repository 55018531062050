import * as ecs from "../../../../../core/ecs";

class Location extends Laya.Vector3 {
    cursor: number = 0;
}

export class PvpMovementComponent extends ecs.Component {
    static readonly UPDATE = 1 << 0;

    flag: number = 0;
    startTime: number = 0; // 开始时间(秒)
    speed: number = 0; // 每秒多少格
    ratio: number = 1;
    index: number = 0;
    paths: Laya.Vector3[] = [];
    target: Laya.Vector3 = new Laya.Vector3();
}
