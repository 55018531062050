import * as ecs from "../../../../../core/ecs";
import { SecondOrderDynamics } from "../../../base/SecondOrderDynamics";

/**
 * 箭头组件，通过 setAstarDestination 设置目的地，根据A星寻路指向路径
 * TODO：需要时创建
 */
export class PveNavinArrowComponent extends ecs.Component {
    readonly mounter: Laya.Sprite3D = new Laya.Sprite3D("PveNavinArrowComponent");
    view?: Laya.Sprite3D | null;
    res!: string;

    /** 开始寻路的点 */
    pointToPosO: Laya.Vector3 = new Laya.Vector3(0, 0, 0);
    /**
     * 需要指向的地图上坐标A,与B
     * 为了避免玩家已经超过A点，导致指引回头的问题，增加B点，指引时取近的点进行指引
     */
    pointToPosA: Laya.Vector3 = new Laya.Vector3(0, 0, 0);
    pointToPosB: Laya.Vector3 = new Laya.Vector3(0, 0, 0);

    /** 经过平滑的指向位置 */
    sodPointToPos: Laya.Vector3 = new Laya.Vector3(0, 0, 0);

    /** 是否存在需要指向的目标点 */
    hasTarget: boolean = false;

    sod: SecondOrderDynamics | undefined;
}
