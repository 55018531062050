import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { PveEventType } from "../../../../../def/auto/battle";
import { MainlineConf } from "../../../../../def/auto/mainline";
import { CollectionAmountData } from "../../PveDefs";
import { PveServer } from "../../PveServer";
import { PveSvrAStarComponent } from "../components/PveSvrAstarComponent";
import { PveSvrElementComponent } from "../components/PveSvrElementComponent";
import { PveSvrCollectSystem } from "./PveSvrCollectSystem";
import { PveSvrStateSystem } from "./PveSvrStateSystem";

export class PveSvrCloudSystem extends ecs.System {
    declare context: PveServer;

    /** 更新动态云障碍 */
    public static updateDynamicCloudBlocks(pveServer: PveServer): void {
        const pveStateData = app.service.pve.mlData.pveStateData;
        const events = pveStateData.events.filter((event) => !!event.eventVo);
        // 已经解开的迷雾数据map
        const openCloudMap: Map<string, boolean> = new Map<string, boolean>();
        for (const event of events) {
            const eventVo = event.eventVo!;
            if (eventVo.eventType === PveEventType.UNLOCK_CLOUD) {
                if (event.isDeath) {
                    openCloudMap.set(eventVo.cloudKey, true);
                }
            } else if (eventVo.eventType === PveEventType.TALK_TO_NPC) {
                //  需要判断npc解开迷雾
                if (event.isDeath) {
                    const cd = eventVo.customData!;
                    if (cd.finish_open_cloud) {
                        openCloudMap.set(cd.finish_open_cloud!, true);
                    }
                }
            }
        }

        const cloudData = app.service.pve.minimap.cloudData;
        const minimapData = app.service.pve.minimap.minimapData;
        const xNum = minimapData.blocks.width;
        const yNum = minimapData.blocks.height;
        const dynamicCloudBlocks: number[][] = [];
        for (let xIndex = 0; xIndex < xNum; xIndex++) {
            const col: number[] = [];
            for (let yIndex = 0; yIndex < yNum; yIndex++) {
                const key = (xIndex << 20) | (yIndex << 0);
                const cloudKey = cloudData.get(key);
                if (!cloudKey || openCloudMap.has(cloudKey)) {
                    col.push(0); // 没有迷雾或者迷雾已解开
                } else {
                    col.push(1);
                }
            }
            dynamicCloudBlocks.push(col);
        }

        const astarComp = pveServer.ecs.getSingletonComponent(PveSvrAStarComponent);
        astarComp.pveAStarData.astar.dynamic_block_data = dynamicCloudBlocks;
        astarComp.pveAStarData.dynamicCloudBlocks = dynamicCloudBlocks;
    }

    /** 把已经解开的云id通知到显示层 */
    public static setClearCloudsData(pveServer: PveServer, needClearCloudKey: string | undefined) {
        const pveStateData = app.service.pve.mlData.pveStateData;
        const events = pveStateData.events.filter((event) => !!event.eventVo);
        const clearCloudKeys: string[] = [];
        for (const event of events) {
            const eventVo = event.eventVo!;
            if (eventVo.eventType === PveEventType.UNLOCK_CLOUD) {
                if (event.isDeath) {
                    clearCloudKeys.push(eventVo.cloudKey);
                }
            } else if (eventVo.eventType === PveEventType.TALK_TO_NPC) {
                //  需要判断npc解开迷雾
                if (event.isDeath) {
                    const cd = eventVo.customData!;
                    if (cd.finish_open_cloud) {
                        clearCloudKeys.push(cd.finish_open_cloud!);
                    }
                }
            }
        }
        this.updateDynamicCloudBlocks(pveServer);
        pveServer.sender.setClearCloudKeys(clearCloudKeys, needClearCloudKey);
    }

    public static async clearCloudWithCost(
        pveServer: PveServer,
        eid: number,
        amountObj: CollectionAmountData,
        needAmount: number,
        costToPosition: Laya.Vector3,
        eventId: number
    ) {
        await PveSvrCollectSystem.costFormTruck(pveServer, costToPosition, amountObj, needAmount);
        this.clearCloud(pveServer, eid, true);
        await app.service.pve.requestEvent(eventId);
    }

    /**
     * 清除迷雾
     * 1. 设置事件触发时间
     * 2. 把已经解开的云id通知到显示层
     * 3. 移除事件Element
     * 4. 进行保存状态
     */
    public static clearCloud(pveServer: PveServer, eventEid: number, needSave: boolean) {
        const event = pveServer.ecs.getComponent(eventEid, PveSvrElementComponent);
        if (event) {
            const pveStateData = app.service.pve.mlData.pveStateData;
            // 设置事件的触发时间
            const nowTime = app.service.network.serverTime;
            pveStateData.setEventTriggerTime(event, nowTime << 0);

            let cloudKey = "";
            const eventVo = app.service.pve.eventLoMap.get(event.tid)!;
            if (eventVo.eventType === PveEventType.UNLOCK_CLOUD) {
                cloudKey = eventVo.cloudKey;
            } else if (eventVo.eventType === PveEventType.TALK_TO_NPC) {
                const cd = eventVo.customData!;
                if (cd.finish_open_cloud) {
                    cloudKey = cd.finish_open_cloud!;
                }
            }

            if (!cloudKey) {
                console.error("未配置解开迷雾的绑定标识 event_tid:" + eventVo.key);
            } else {
                // 做任务
                app.service.pve.requestDoTask(MainlineConf.TASK.UNLOCK_CLOUD, 1, eventVo.cloudID);
                app.service.pve.requestDoTask(MainlineConf.TASK.UNLOCK_ANY_CLOUD, 1);
                // 清除迷雾
                PveSvrCloudSystem.setClearCloudsData(pveServer, cloudKey);
            }
            pveServer.removeElement(event);

            if (needSave) {
                // 清除迷雾后马上进行保存状态
                PveSvrStateSystem.save(pveServer.ecs);
            }
        }
    }

    public static async waitTime(time: number) {
        await new Promise((resolve) => {
            Laya.timer.once(time, this, () => {
                resolve(1);
            });
        });
    }
}
