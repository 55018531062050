import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { ImageRef } from "../../misc/ImageRef";
import { SystemEvent } from "../../misc/system-event";
import { ui } from "../../misc/ui";
import { SkillDetailsUI } from "./ui-runtime/SkillDetailsUI";
import { SkillFastListUIArgs } from "./ui-runtime/SkillFastListUI";

const { regClass, property } = Laya;

@regClass('5bSX0NeHQKSd44dh8dccWQ') //技能详情界面
export class SkilllDetailsMediator extends Mediator {
    declare owner: SkillDetailsUI;

    override onAwake(): void {
        super.onAwake();
        this.initPanel();
        this.initUIEvent();
    }

    private initUIEvent() {
        this.owner.imgClose.on(Laya.Event.CLICK, this.owner, this.owner.destroy);
        this.owner.btnUplevel.on(Laya.Event.CLICK, this, this.onClickUp);
        this.owner.btnReplace.on(Laya.Event.CLICK, this, this.onClickReplace);
        this.$(app).on(SystemEvent.SkILL.SKILL_UP_GRADE, this.initPanel, this);
    }

    private onClickReplace() {
        const skillPos = this.owner.skillPos;
        app.ui.show(ui.SKILL_FAST_LIST, {
            uid: this.owner.heroUid,
            skillPos: skillPos,
            sysType: this.owner.sysType,
        } as SkillFastListUIArgs);
    }

    private onClickUp() {
        const data = this.owner.data;
        const config = app.service.skill.getSkillCfgById(data.id);
        if (!config) return;
        const rewardList = config.upgrade_cost[data.lv - 1];
        const reward = rewardList[0];
        const costCount = reward.num;
        const owerCount = app.service.user.getMoneyCount(reward.id);
        if (owerCount < costCount) {
            console.log(`${reward.id}道具不足`);
            return;
        }
        const skillPos = this.owner.skillPos;
        app.service.skill.requestUpGradeSkill(this.owner.heroUid, skillPos, this.owner.sysType);
    }

    private initPanel() {
        const data = this.owner.data;
        this.owner.bgwin.setTitle("技能详情");
        const config = app.service.skill.getSkillCfgById(data.id);
        if (!config) return;
        this.owner.skillicon.initIcon(config);
        this.owner.labLevel.text = `${data.lv}`;
        this.owner.labDesc.text = app.service.skill.getCurLevelSkillDesc(config, data.lv);
        const texturePaths = this.owner.imgType.getComponent(ImageRef)!.texturePaths;
        this.owner.imgType.skin = texturePaths[(config.type_icon || 1) - 1];
        this.owner.labType.text = app.service.skill.getSkillTypeText(config.type_icon || 1);
        this.owner.labType.color = app.service.skill.getTypeTextColor(config.type_icon || 1);
        const isMax = config.upgrade_cost.length === data.lv;
        this.owner.labMax.visible = isMax && this.owner.isActive;
        this.owner.btnUplevel.visible = !isMax && this.owner.isActive;
        this.owner.labCost.visible = this.owner.boxCost.visible = !isMax && this.owner.isActive;
        this.owner.labSpecial.text = config.spec_desc || "";

        if (!isMax) {
            const rewardList = config.upgrade_cost[data.lv - 1];
            const reward = rewardList[0];
            const costCount = reward.num;
            const itemLo = app.service.bag.itemLoMap.get(reward.id);
            this.owner.imgIcon.skin = itemLo.iconUrl;
            const owerCount = app.service.user.getMoneyCount(reward.id);
            this.owner.labCount.text = `${costCount}/${owerCount}`;
            this.owner.labCount.color = owerCount >= costCount ? "#02ac16" : "#d54e45";
            this.owner.btnUplevel.gray = owerCount < costCount;
        }
        this.owner.btnReplace.visible = this.owner.isReplace && this.owner.isActive;

        this.owner.btnReplace.x = this.owner.btnUplevel.visible ? 164 : 346;
        this.owner.btnUplevel.x = this.owner.btnReplace.visible ? 527 : 346;
        this.owner.labMax.x = this.owner.btnReplace.visible ? 457 : 272;
    }
}
