const { regClass } = Laya;
import { MapTransferAreaBtnUIBase } from "./MapTransferAreaBtnUI.generated";

@regClass('ZYo2ncNGQjm8ah6cwtSYBw')
export class MapTransferAreaBtnUI extends MapTransferAreaBtnUIBase {
    setButtonName(name: string): void {
        this.btn_text.text = name;
    }

    updateSelected(isSelected: boolean): void {
        this.btn.selected = isSelected;
        if(isSelected){
            this.btn_text.color = "#ffffff";
        }else{
            this.btn_text.color = "#3d4c60";
        }
    }
}
