import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { Pool } from "../../../../../core/pool";
import { BattleConf } from "../../../../../def/auto/battle";
import { calcVelocity } from "../../../base/Util";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrElementComponent } from "../../ecs/components/PveSvrElementComponent";
import {
    MovementState,
    PveSvrMovementComponent,
} from "../../ecs/components/PveSvrMovementComponent";
import { PveSvrOffsetComponent } from "../../ecs/components/PveSvrOffsetComponent";
import { PveSvrTroopComponent } from "../../ecs/components/PveSvrTroopComponent";
import { PveDef } from "../../PveDefs";
import { PveServer } from "../../PveServer";

const tmpT3d: Laya.Transform3D = new Laya.Transform3D();
const tmpV3: Laya.Vector3 = new Laya.Vector3();
/** 直角交点 */
const tmpRightAnglePos: Laya.Vector3 = new Laya.Vector3();

/** 期望的位置，但是阻碍格 */
const tmpExpectPos: Laya.Vector3 = new Laya.Vector3();
/** 实际应前往的位置 */
const tmpActualGoMapPos: Laya.Vector3 = new Laya.Vector3();
/** 速度 */
const tmpVelocity = new Laya.Vector3();

/** 当前位置 */
const tmpOriginPos: Laya.Vector3 = new Laya.Vector3();

/** 单位撞墙后，进行A星寻路到期望的位置 */
export class HitWallFindPath extends Process {
    override init(node: Node) {}

    override run(node: Node, env: AiTreeEnv): Status {
        if (!env.get(PveDef.HIT_WALL_FLAG)) {
            return "failure";
        }

        const findPathMoveing = env.get(PveDef.FIND_PATH_MOVEING);
        // 寻路中
        if (findPathMoveing) {
            // 当有目标位置的情况下，到达指定位置后，state会变成STOP
            const state = env.owner.getComponent(PveSvrMovementComponent)!.state;
            if (state === MovementState.STOP) {
                const movePathPoints = env.get(PveDef.MOVE_PATH_POINTS) as
                    | Laya.Vector2[]
                    | undefined;

                if (movePathPoints && movePathPoints.length > 0) {
                    const nextPoint = movePathPoints.shift()!;
                    tmpOriginPos.cloneFrom(env.owner.transform.position);
                    tmpActualGoMapPos.x = nextPoint.x;
                    tmpActualGoMapPos.z = nextPoint.y;
                    Pool.free(nextPoint);

                    // 找到了寻路的目标点
                    // TODO: 是否需要加速移动？

                    env.context.moveTo(env.owner, tmpActualGoMapPos, BattleConf.PVE.MOVE_SPEED);
                    return node.yield(env); // 继续挂起继续寻路
                } else {
                    env.set(PveDef.FIND_PATH_MOVEING, false);
                    env.set(PveDef.HIT_WALL_FLAG, false);
                    return "success"; // 到达目的位置
                }
            }
            return node.yield(env);
        }

        const posData = env.get(PveDef.POS_DATA) as ExpectGoToTargetData | undefined;
        if (!posData) {
            console.warn("no expected pos to go");
            env.set(PveDef.HIT_WALL_FLAG, false);
            return "failure";
        }

        tmpOriginPos.cloneFrom(env.owner.transform.position);

        let hasFindMoveTarget = false;
        // 进行撞墙寻路
        const isLocalToHero = posData.isLocalToHero;

        // 需要等待移动过去的格子点
        const waitMovePoints: Laya.Vector2[] = [];

        if (isLocalToHero) {
            const offsetComp = env.owner.getComponent(PveSvrOffsetComponent)!;
            const troopComp = env.context.ecs.getComponent(
                offsetComp.troopEid,
                PveSvrTroopComponent
            )!;
            const leader = env.context.ecs.getComponent(
                troopComp.leaderEid,
                PveSvrElementComponent
            )!;

            tmpT3d.localPosition = leader.transform.position;
            tmpT3d.localRotationEulerY = leader.transform.rotation;
            tmpV3.cloneFrom(offsetComp.offset);
            // 直角点位置
            tmpRightAnglePos.cloneFrom(offsetComp.offset);
            tmpRightAnglePos.x = 0;
            tmpT3d.localToGlobal(tmpRightAnglePos, tmpRightAnglePos);

            tmpT3d.localToGlobal(tmpV3, tmpV3);
            tmpExpectPos.x = tmpV3.x;
            tmpExpectPos.z = tmpV3.z;
            // 检查 tempPos 是否位于阻挡格子里
            const isBlock = env.context.isBlockAt(tmpExpectPos.x, tmpExpectPos.z, true);
            let hasFind = false;
            if (isBlock) {
                // 期望位置为阻碍格子，则根据直角靠近原则找一个合适的位置
                hasFind = env.context.findCloserMoveVaildPoint(
                    leader,
                    tmpExpectPos,
                    tmpRightAnglePos,
                    tmpActualGoMapPos
                );
            } else {
                // 期望位置不在阻碍格子里，进行直接寻路
                hasFind = true;
                tmpActualGoMapPos.cloneFrom(tmpExpectPos);
            }

            if (!hasFind) {
                // 没有找到就不移动
                tmpActualGoMapPos.cloneFrom(tmpOriginPos);
                hasFindMoveTarget = false;
            } else {
                hasFindMoveTarget = this.findPathWithAstar(
                    env.context,
                    tmpOriginPos,
                    tmpActualGoMapPos,
                    waitMovePoints
                );
            }
        } else {
            tmpExpectPos.x = posData.targetX;
            tmpExpectPos.z = posData.targetZ;
            if (tmpOriginPos.equal(tmpExpectPos)) {
                hasFindMoveTarget = false;
            } else {
                const isBlock = env.context.isBlockAt(tmpExpectPos.x, tmpExpectPos.z, true);
                if (isBlock) {
                    hasFindMoveTarget = false;
                } else {
                    hasFindMoveTarget = this.findPathWithAstar(
                        env.context,
                        tmpOriginPos,
                        tmpExpectPos,
                        waitMovePoints
                    );
                }
            }
        }

        if (hasFindMoveTarget) {
            // 回收之前的路点
            Pool.free(env.get(PveDef.MOVE_PATH_POINTS));
            if (waitMovePoints.length > 0) {
                env.set(PveDef.MOVE_PATH_POINTS, waitMovePoints);
            } else {
                env.set(PveDef.MOVE_PATH_POINTS, undefined);
            }
            // 找到了寻路的目标点
            env.set(PveDef.FIND_PATH_MOVEING, true);
            // TODO: 是否需要加速移动？
            calcVelocity(tmpOriginPos, tmpActualGoMapPos, BattleConf.PVE.MOVE_SPEED, tmpVelocity);
            env.context.moveTo(env.owner, tmpActualGoMapPos, BattleConf.PVE.MOVE_SPEED);
            return node.yield(env);
        } else {
            // 未找到寻路目标点
            env.set(PveDef.HIT_WALL_FLAG, false);
            env.set(PveDef.FIND_PATH_MOVEING, false);
            return "failure";
        }
    }

    /**
     * @param context
     * @param startPos 寻路开始点
     * @param endPos 寻路结束点
     * @param waitMovePoints 最多保留两个等待移动的额外寻路点
     * @returns true 寻路成功，false 目标不可到达或者开始点与结束点一致
     */
    private findPathWithAstar(
        context: PveServer,
        startPos: Laya.Vector3,
        endPos: Laya.Vector3,
        waitMovePoints: Laya.Vector2[]
    ): boolean {
        const astarResult: Laya.Vector2[] = [];
        // 找到了合适的点，进行A星寻路过去目标点
        const pathLength = context.astarFindpath(
            startPos.x,
            startPos.z,
            endPos.x,
            endPos.z,
            astarResult
        );

        // 为了以格子为中心，xy各自增加 0.5
        for (let i = 0; i < astarResult.length; i++) {
            const tempPoint: Laya.Vector2 = astarResult[i];
            tempPoint.x += 0.5;
            tempPoint.y += 0.5;
        }

        if (pathLength > 0) {
            Pool.free(astarResult.shift()); // 开始格子无用，删除开始格子
            if (pathLength > 1) {
                const nextPathPos = astarResult.shift()!;
                tmpActualGoMapPos.x = nextPathPos.x;
                tmpActualGoMapPos.z = nextPathPos.y;
                Pool.free(nextPathPos);
                // 需要连续移动多2个A星结果路点格
                if (astarResult.length > 1) waitMovePoints.push(astarResult.shift()!);
                if (astarResult.length > 1) waitMovePoints.push(astarResult.shift()!);
            } else {
                tmpActualGoMapPos.x = endPos.x;
                tmpActualGoMapPos.z = endPos.z;
            }
            Pool.free(astarResult);
            return true;
        } else {
            tmpActualGoMapPos.cloneFrom(startPos);
            return false;
        }
    }

    override get descriptor(): NodeDef {
        return {
            name: "HitWallFindPath",
            type: "Action (PVE)" as NodeDef["type"],
            desc: "撞墙寻路",
            doc: `撞墙之后，进行A星寻路的期望的目的地。`,
        };
    }
}

/**
 * 单位期望去的位置信息(撞墙后，A星寻路需要的位置信息)
 * 可能是固定世界位置
 * 也可能是相对主角的局部位置
 */
export class ExpectGoToTargetData {
    /** 是否相对于主角 */
    isLocalToHero: boolean = false;

    /** 地图上的X坐标 */
    targetX: number = 0;

    /** 地图上的Z坐标 */
    targetZ: number = 0;
}
