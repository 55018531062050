/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIList } from "../../../core/ui/UIList";

/**
 * resources/prefab/login/UILoginPvpSelector.lh
 */
export class UILoginPvpSelectorBase extends Laya.Dialog {
    public list!: UIList;
}

