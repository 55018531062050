import { IReusable, Pool } from "../../../../../../core/pool";
import { mainline } from "../../../../../../def/auto/proto";
import { PveSvrMonsterStateData } from "./PveSvrMonsterStateData";

export class PveSvrMonsterTroopStateData implements IReusable {
    __unuse(): void {
        Pool.free(this.monsters);
    }

    __reuse(...args: unknown[]): void {
        this.key = 0;
        this.tid = 0;
        this.monsters.length = 0;
        this.inView = false;

        this.deathTime = 0;
        this.deathCount = 0;
        this.reliveCD = -1;
    }

    deathTime: number = 0;
    private reliveCD: number = -1; //负数代表永不复活，0代表马上复活

    setReliveCD(cd: number): void {
        this.reliveCD = cd;
    }

    get isDeath(): boolean {
        if (this.deathTime > 0) {
            return true;
        }

        // todo: 兼容一下，下面这个逻辑触发为true是有问题的。 后续需要修复
        if (this.monsters.length === 0) {
            return true;
        }
        if (this.monsters.some((v) => v.hp > 0)) {
            return false;
        } else {
            return true;
        }
    }

    get isShouldRelive(): boolean {
        return (
            this.isDeath && this.reliveCD >= 0 && this.deathTime + this.reliveCD < Date.now() / 1000
        );
    }

    constructor() {
        this.__reuse();
    }

    key: number = 0;

    /** 对应表 monster.troop 表 */
    tid: number = 0;

    readonly monsters: PveSvrMonsterStateData[] = [];

    inView: boolean = false;

    /** 死亡次数累计 */
    deathCount: number = 0;

    setMonsterData(
        idx: number,
        hp: number,
        maxHp: number,
        x: number,
        z: number,
        rotation: number
    ): void {
        let monsterData = this.monsters.find((v) => v.idx === idx);
        if (!monsterData) {
            monsterData = Pool.obtain(PveSvrMonsterStateData);
            monsterData.idx = idx;
            this.monsters.push(monsterData);
        }

        monsterData.hp = hp;
        monsterData.maxHp = maxHp;
        monsterData.pos.x = x;
        monsterData.pos.y = z;
        monsterData.rotation = rotation;
    }

    delMonsterData(idx: number): void {
        const index = this.monsters.findIndex((v) => v.idx === idx);
        if (index >= 0) {
            const monster = this.monsters[index];
            this.monsters.splice(index, 1);
            Pool.free(monster);
        }
    }

    private decodeMonsterData(data: mainline.IMonster): PveSvrMonsterStateData {
        let monster = this.monsters.find((v) => {
            return v.idx === data.idx;
        });
        if (!monster) {
            monster = Pool.obtain(PveSvrMonsterStateData);
            this.monsters.push(monster);
        }
        monster.decode(data);
        return monster;
    }

    decode(data: mainline.MonsterTroop): PveSvrMonsterTroopStateData {
        this.key = data.key;
        this.tid = data.tid;
        this.deathCount = data.deathCount;
        this.deathTime = data.deathTime;
        data.monsters?.forEach((monsterData) => {
            this.decodeMonsterData(monsterData);
        });
        return this;
    }

    /** 获取服务端需要的数据 */ // 只保存死亡或者有怪物被改变的部队
    encode(): mainline.MonsterTroop | undefined {
        const monsters = this.monsters
            .map((m) => {
                return m.encode();
            })
            .filter((v) => v !== undefined);
        if (monsters.length > 0 || this.deathCount || this.deathTime) {
            const data = Pool.obtain(mainline.MonsterTroop);
            data.key = this.key;
            data.tid = this.tid;
            data.deathCount = this.deathCount;
            data.deathTime = this.deathTime;
            data.monsters = monsters as mainline.Monster[];
            return data;
        }
        return undefined;
    }
}
