/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/alliance/AllianceJoinItem.lh
 */
export class AllianceJoinItemBase extends Laya.Box {
    public btnJoin!: Laya.Button;
    public btnApply!: Laya.Button;
    public memberCount!: Laya.Label;
    public stateText!: Laya.Label;
}

