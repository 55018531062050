import { mainline } from "../../../../../../def/auto/proto";
import { Healthable, ReliveableRecordData } from "./PveSvrBaseStateData";

/** 树木，粮草，石头 */
export class PveSvrCollectionStateData extends ReliveableRecordData implements Healthable {
    key: number = 0;

    /** 对应表 battle/battle_building.xlsx */
    tid: number = 0;

    /** 所在地图上的x轴索引 */
    xIdx: number = 0;

    /** 所在地图上的y轴索引 */
    yIdx: number = 0;

    /** 当前血量 */
    hp: number = 0;

    /** 最大血量 */
    maxHp: number = 0;

    inView: boolean = false;

    decode(data: mainline.Collection) {
        this.key = data.key;
        this.deathTime = data.deathTime ?? 0;
        this.hp = data.health?.hp ?? 0;
        this.maxHp = data.health?.maxHp ?? 0;
    }

    encode(): mainline.Collection | undefined {
        if (this.hp === this.maxHp) {
            // 满血无需记录
            return undefined;
        }

        const data = mainline.Collection.create();
        data.key = this.key;
        data.deathTime = this.deathTime;

        data.health = mainline.Health.create();
        data.health.hp = this.hp;
        data.health.maxHp = this.maxHp;
        return data;
    }
}
