const { regClass, property } = Laya;

@regClass('VCMj3wz_Tkyjr6n1ezl39w')
export class ButtonScaleEffect extends Laya.Script {
    declare owner: Laya.Sprite;

    @property(Number)
    scaleX: number = 0.8;
    @property(Number)
    scaleY: number = 0.8;

    curScaleX!: number;
    curScaleY!: number;

    get targetScaleX(): number {
        return this.curScaleX * this.scaleX;
    }

    get targetScaleY(): number {
        return this.curScaleY * this.scaleY;
    }

    override onAwake(): void {
        super.onAwake();
        this.curScaleX = this.owner.scaleX;
        this.curScaleY = this.owner.scaleY;
        this.owner.on(Laya.Event.MOUSE_DOWN, () => {
            Laya.Tween.to(this.owner, { scaleX: this.targetScaleX, scaleY: this.targetScaleY }, 30);
            this.owner.once(Laya.Event.MOUSE_OUT, () => {
                Laya.Tween.to(this.owner, { scaleX: this.curScaleX, scaleY: this.curScaleY }, 30);
                this.owner.offAll(Laya.Event.MOUSE_UP);
            });
            this.owner.once(Laya.Event.MOUSE_UP, () => {
                this.owner.offAll(Laya.Event.MOUSE_OUT);
                Laya.Tween.to(this.owner, { scaleX: this.curScaleX, scaleY: this.curScaleY }, 30);
            });
        });
    }
}
