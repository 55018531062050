import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { StringUtil } from "../../core/utils/string-util";
import { PlotRow } from "../../def/table";
import { res } from "../../misc/res";
import { ui } from "../../misc/ui";
import { PlotDialogueWinUI } from "./ui-runtime/PlotDialogueWinUI";

const { regClass, property } = Laya;

@regClass('EZyAULjpS0mLXZzQHYfi_Q')
export class PlotDialogueWinMediator extends Mediator {
    declare owner: PlotDialogueWinUI;

    /** 当前显示对白索引 */
    private curShowDialogueIndex: number = 0;

    private plotCfg!: PlotRow;

    override onAwake(): void {
        super.onAwake();
        this.owner.alphaButton.onClick(this.onAlphaButtonClick.bind(this));
    }

    public onOpened(): void {
        // app.ui.toast("显示对白:" + this.owner.plotId);
        this.updateShow();
    }

    private showCloseAnimation(): void {
        const animator = this.owner.contentBox.getComponent(Laya.Animator2D)!;
        animator.play("plot_close");
        Laya.timer.once(180, this, this.onCloseAnimationComplete);
    }

    private onCloseAnimationComplete(): void {
        this.owner.close();
        const cb = this.owner.dialogueParam.callBack;
        if (cb) {
            cb.run();
        }
    }

    public updateShow(): void {
        const plotId = this.owner.dialogueParam.plotId;
        const cfg = app.service.table.plot[plotId];
        if (!cfg) {
            app.ui.toast("plot表找不到对白:" + plotId);
            return;
        }
        this.plotCfg = cfg;
        this.curShowDialogueIndex = 0;
        this.updateCurrentDialogue();
    }

    /** 刷新当前对白的显示 */
    private updateCurrentDialogue(): void {
        const cfg = this.plotCfg;
        const dialogue = cfg.dialogue[this.curShowDialogueIndex];
        if (!dialogue) {
            return;
        }
        const [tag, content] = dialogue;
        let showName = cfg.performer[tag];
        if (showName === "PLAYER") {
            showName = app.service.user.profileInfo.name;
        }

        const skinIdStr = cfg.skin_id[tag];
        let skinId = 0;
        if (skinIdStr === "PLAYER") {
            skinId = 220003; // TODO: 需要读取英雄主角的皮肤id
        } else {
            skinId = Number(skinIdStr);
        }
        const skinCfg = app.service.table.skin[skinId];

        const imagePath = StringUtil.format(res.HERO_ICON_FORMAT.FULL, skinCfg.code_name);

        this.owner.img_role.skin = imagePath;
        this.owner.plot_dialogue_msg.nameLabel.text = showName;
        this.owner.plot_dialogue_msg.contentLabel.text = content;
    }

    /** 显示下一句对白 */
    private showNextDialogue(): void {
        this.curShowDialogueIndex++;
        const cfg = this.plotCfg;
        const len = cfg.dialogue.length;
        if (this.curShowDialogueIndex >= len) {
            // 对白播放完毕
            this.showCloseAnimation();
            return;
        }
        this.updateCurrentDialogue();
    }

    private onAlphaButtonClick(): void {
        this.showNextDialogue();
    }

    public static showPlot(plotId: number, callBack?: Laya.Handler): void {
        app.ui.show(ui.PLOT, { plotId, callBack });
    }
}
