import { Mediator } from "../../../../core/ui-mediator";
import { ActionMenuUI } from "../../ui-runtime/pvp/ActionMenuUI";
import { PvpContext } from "../PvpContext";

@Laya.regClass('-tebU3kXRbeuQaz0PAOXnA')
export class PvpResourceInfoMediator extends Mediator {
    declare owner: ActionMenuUI;

    private targetPos?: Laya.Vector3 | null;

    updatePos(ctx: PvpContext, currXZPos?: Laya.Vector3) {
        const pos = currXZPos ?? this.targetPos;
        if (!pos) {
            return;
        }
        const out = new Laya.Vector4();
        ctx.camera.worldToViewportPoint(pos, out);
        this.owner.pos(out.x - this.owner.width - 50, out.y);
    }

    refresh(ctx: PvpContext, currXZPos: Laya.Vector3) {
        //  resource info 的刷新逻辑写在这里
        this.targetPos = currXZPos.clone();
        this.updatePos(ctx, currXZPos);
    }
}
