/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIButton } from "../../../core/ui/UIButton";

/**
 * resources/prefab/common/money/money-item.lh
 */
export class HomeMoneyItemBase extends Laya.Box {
    public imgIcon!: Laya.Image;
    public labelNum!: Laya.Label;
    public btnAdd!: UIButton;
}
