import { app } from "../../../app";
import { money } from "../../../def/auto/proto";
import { MoneyRow } from "../../../def/table";
import { Vo } from "../../../misc/vo/Vo";

// export class MoneyNewVo extends MoneyVoBase<MoneyRow, proto.money.IMoneyItem> implements IItemVo {
export class MoneyVo extends Vo<MoneyRow, money.MoneyItem> {
    override clone(): MoneyVo {
        return new MoneyVo(this._config, this._data);
    }

    override get key(): number {
        return this._config.id;
    }

    get id() {
        return this._config.id;
    }

    get name() {
        return this._config.name;
    }

    get iconUrl(): string {
        const itemLo = app.service.bag.itemLoMap.get(this._config.id);
        return itemLo.iconUrl;
    }

    get count() {
        return this._data?.num ?? 0;
    }

    get quality(): number {
        return 1;
    }

    get qualitySkin(): string {
        return "";
    }
}
