import { Node, NodeDef, Status, TargetType } from "../../../../../core/behavior3";
import { Listen as ListenBase } from "../../../../../core/behavior3/nodes/decorators/listen";
import { SystemEvent } from "../../../../../misc/system-event";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";

interface NodeArgs {
    event: string;
}

type NodeInput = [TargetType | TargetType[] | undefined];
type NodeOutput = [string?];

const _EventOptions: { name: string; value: string }[] = [];

function GetEventOptions() {
    if (_EventOptions.length > 0) {
        return _EventOptions;
    }

    for (const key in SystemEvent.BTREE) {
        _EventOptions.push({
            name: key,
            value: SystemEvent.BTREE[key as keyof typeof SystemEvent.BTREE],
        });
    }
    return _EventOptions;
}

export class Listen extends ListenBase {
    override run(node: Node, env: AiTreeEnv): Status {
        const [target] = env.input as NodeInput;

        if (target !== undefined) {
            if (target instanceof Array) {
                for (const v of target) {
                    if (typeof v !== "number") {
                        console.error("target is not number", v, target);
                        return "failure";
                    }
                }
            } else {
                if (typeof target !== "number") {
                    console.error("target is not number", target);
                    return "failure";
                }
            }
        }

        return super.run(node, env);
    }

    override get descriptor(): NodeDef {
        const descriptor = super.descriptor;
        descriptor.args![0] = {
            name: "event",
            type: "enum",
            desc: "事件",
            options: GetEventOptions(),
        };
        return descriptor;
    }
}
