/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { BaseRoundWindowBg } from "../../common/ui-runtime/dialog/BaseRoundWindowBg";

/**
 * resources/prefab/draw-card/UIDrawCardBuy.lh
 */
export class UIDrawCardBuyBase extends Laya.Dialog {
    public imgMask!: Laya.Image;
    public bgwin!: BaseRoundWindowBg;
    public boxCheck!: Laya.Box;
    public imgGou!: Laya.Image;
    public imgCost!: Laya.Image;
    public labDesc!: Laya.Label;
    public labCount!: Laya.Label;
    public btnBuy!: Laya.Button;
}

