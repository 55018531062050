/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIHBoxStar } from "../../hero/ui-runtime/UIHBoxStar";
import { DrawCardGainHeroSkill } from "./DrawCardGainHeroSkill";

/**
 * resources/prefab/draw-card/UIDrawCardGainHero.lh
 */
export class UIDrawCardGainHeroBase extends Laya.Dialog {
    public imgBg!: Laya.Image;
    public boxStar!: UIHBoxStar;
    public imgHero!: Laya.Image;
    public labName!: Laya.Label;
    public labType!: Laya.Label;
    public hboxSkill!: UIHBoxStar;
    public skillItem1!: DrawCardGainHeroSkill;
    public skillItem2!: DrawCardGainHeroSkill;
    public skillItem3!: DrawCardGainHeroSkill;
    public skillItem4!: DrawCardGainHeroSkill;
    public boxMask!: Laya.Box;
    public boxSkill!: Laya.Box;
    public labSkillName!: Laya.Label;
    public labSkillDesc!: Laya.Label;
}

