import { app } from "../../app";
import { Service } from "../../core/service";
import { StringUtil } from "../../core/utils/string-util";
import proto from "../../def/auto/proto";
import { errcode, opcode } from "../../def/auto/protocol";
import { SystemEvent } from "../../misc/system-event";
import { ui } from "../../misc/ui";
import { ItemLoMap } from "./vo/ItemLoMap";
import { ItemRourceLo } from "./vo/ItemRourceLo";
import { ItemRourceLoMap } from "./vo/ItemRourceLoMap";
import { ItemVo } from "./vo/ItemVo";
import { ItemVoMap } from "./vo/ItemVoMap";

export class BagService extends Service {
    /** 服务器创建的道具数据，以 uid 为索引 */
    readonly itemVoMap = new ItemVoMap();
    /** 本地道具配置数据，以 id 为索引 */
    readonly itemLoMap = new ItemLoMap();

    readonly itemRourceLoMap = new ItemRourceLoMap(); //跳转数据

    private _Loaded = false;

    get loaded() {
        return this._Loaded;
    }

    override onCreate() {
        this.handle(opcode.bag.s2c_load, this._onLoad);
        this.handle(opcode.bag.s2c_use_item, this._onUseItem);
        this.handle(opcode.bag.s2c_composite_item, this._onCompositeItem);
        this.handle(opcode.bag.s2c_discard_item, this._onDiscardItem);
        this.handle(opcode.bag.notify_items, this._noNotify);
        this.handle(opcode.bag.s2c_open_optional_chest, this._onOptionalChest);
        this.handle(opcode.bag.s2c_open_random_chest, this._onRandomChest);
    }

    override onStartInit(): void {
        this.itemLoMap.getAllItemLo();
        const itemRourceTabe = app.service.table.itemRource;
        for (const key in itemRourceTabe) {
            const config = itemRourceTabe[key];
            const itemRourceLo = new ItemRourceLo(config, undefined!);
            this.itemRourceLoMap.set(config.id, itemRourceLo);
        }
    }

    override onDestroy() {}

    private _onLoad(resp: proto.bag.s2c_load) {
        if (resp.err !== errcode.OK) {
            return;
        }
        this._Loaded = true;
        this._onUpdateItems(resp.items as proto.bag.Item[]);
    }

    private _onUseItem(data: proto.bag.s2c_use_item) {
        if (data.err === errcode.OK) {
            const itemReward = data.rewards;
            app.ui.show(ui.REWARD, itemReward);
        }
    }

    //合成奖励
    private _onCompositeItem(data: proto.bag.s2c_composite_item) {
        if (data.err === errcode.OK) {
            const itemReward = data.rewards;
            app.ui.show(ui.REWARD, itemReward);
        }
    }

    //自选奖励
    private _onOptionalChest(data: proto.bag.s2c_open_optional_chest) {
        if (data.err === errcode.OK) {
            const itemReward = data.rewards;
            app.ui.show(ui.REWARD, itemReward);
        }
    }

    //随机奖励
    private _onRandomChest(data: proto.bag.s2c_open_random_chest) {
        if (data.err === errcode.OK) {
            const itemReward = data.rewards;
            app.ui.show(ui.REWARD, itemReward);
        }
    }

    private _onDiscardItem(data: proto.bag.s2c_discard_item) {}

    // 通知物品变化, 如果uid是不存在表示这是个新物品, 如果num是0则表示删除这个物品
    private _noNotify(data: proto.bag.notify_items) {
        this._onUpdateItems(data.items as proto.bag.Item[]);
    }

    private _onUpdateItems(items: proto.bag.Item[]) {
        items.forEach((value) => {
            const item = app.service.table.item.item[value.id!];
            if (value.num === 0) {
                this.itemVoMap.delete(value.id);
            } else {
                let vo = this.itemVoMap.get(value.id);
                if (!vo) {
                    vo = new ItemVo(item, value);
                    this.itemVoMap.set(value.uid, vo);
                } else {
                    vo.__setData(value);
                }
            }
        });
        app.event(SystemEvent.UI.ITEM_SVR_UPDATE);
    }

    /** 根据配置id获取数量 */
    public static getItemAmountByItemCfgId(id: number): number {
        const lomap = app.service.bag.itemLoMap;
        const itemData = lomap.toArray().find((v) => v.id === id);
        if (itemData) {
            // if (itemData.itemType === ItemType.CHEST) {
            //     return app.service.chest.getChestAmountWithId(id);
            // } else
            if (itemData.isMoneyItem) {
                return app.service.user.getMoneyCount(itemData.id);
            } else {
                const itemVoData = app.service.bag.itemVoMap.toArray().find((v) => v.id === id);
                if (itemVoData) {
                    return itemVoData.count;
                }
            }
        }
        return 0;
    }

    // ------------------------------------------------------------------------
    // rpc call
    // ------------------------------------------------------------------------
    async load(data: proto.bag.Ic2s_load) {
        return await app.service.network.call(proto.bag.c2s_load.create(data), proto.bag.s2c_load);
    }

    /**
     *请求使用道具
     * @param data
     */
    async requestUseItem(data: proto.bag.c2s_use_item) {
        return await app.service.network.call(
            proto.bag.c2s_use_item.create(data),
            proto.bag.s2c_use_item
        );
    }

    /**
     *请求合成道具
     * @param data
     */
    async requestCompositeItem(data: proto.bag.c2s_composite_item) {
        return await app.service.network.call(
            proto.bag.c2s_composite_item.create(data),
            proto.bag.s2c_composite_item
        );
    }

    /**
     *请求自选宝箱
     * @param data
     */
    async requestOpenOptional(data: proto.bag.c2s_open_optional_chest) {
        return await app.service.network.call(
            proto.bag.c2s_open_optional_chest.create(data),
            proto.bag.s2c_open_optional_chest
        );
    }

    /**
     *请求随机宝箱
     * @param data
     */
    async requestOpenRandom(data: proto.bag.c2s_open_random_chest) {
        return await app.service.network.call(
            proto.bag.c2s_open_random_chest.create(data),
            proto.bag.s2c_open_random_chest
        );
    }

    /**
     *请求丢弃道具
     * @param data
     */
    async requestDiscradItem(data: proto.bag.c2s_discard_item) {
        return await app.service.network.call(
            proto.bag.c2s_discard_item.create(data),
            proto.bag.s2c_discard_item
        );
    }

    public static showNotEnoughCostTip(itemId: number, needAmount: number) {
        const itemLo = app.service.bag.itemLoMap.get(itemId);
        const costIcon = itemLo.smallIconUrl;
        const ubb = StringUtil.str2UBB(
            "消耗不足，需要{0}{1}",
            { image: costIcon, width: 30, height: 30 },
            needAmount
        );
        app.ui.toast(ubb);
    }
}
