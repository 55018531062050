import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import proto from "../../def/auto/proto";
import { ImageRef } from "../../misc/ImageRef";
import { SystemEvent } from "../../misc/system-event";
import { ui } from "../../misc/ui";
import { PvpTroopListHeroItemUI } from "./ui-runtime/PvpTroopListHeroItemUI";
import { PvpTroopListItemUI } from "./ui-runtime/PvpTroopListItemUI";
import { TroopHeroItem } from "./ui-runtime/TroopheroItem";
import { UIPvpTroopList } from "./ui-runtime/UIPvpTroopList";
import { TroopTeamVo } from "./vo/TroopTeamVo";

const { regClass, property } = Laya;

@regClass('IYRqk6cdQlW4fDUosd9wSA')
export class UIPvpTroopListMediator extends Mediator {
    //declare owner : Laya.Sprite3D;
    declare owner: UIPvpTroopList;
    private startPoint = Laya.Point.create().setTo(0, 0);
    private curClickItem?: TroopHeroItem;
    private listScrollerValue: number = 0;
    // @property(String)
    // public text: string = "";

    //组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
    override onAwake(): void {
        super.onAwake();
        this.initUIEvent();
        this.initServiceEvent(); //1234
        this.initInfo();
    }

    private initUIEvent() {
        this.owner.listteam.renderHandler = new Laya.Handler(this, this.onTeamListRender);
        this.owner.listteam.repeatY = 4;
        this.owner.listteam.elasticEnabled = true;

        this.owner.dragItem.on(Laya.Event.DRAG_START, this, this.onDragStart);
        this.owner.dragItem.on(Laya.Event.DRAG_MOVE, this, this.onDragMove);
        this.owner.dragItem.on(Laya.Event.DRAG_END, this, this.onDragEnd);

        this.owner.btn_close.on(Laya.Event.CLICK, this.owner, this.owner.destroy);
    }

    private initInfo() {
        this.owner.dragItem.visible = false;
        this.owner.win_bg.setTitle("部队列表");
        this.updateList();
    }

    private updateList() {
        const troopList = app.service.troop.getPvpTeamList();
        this.owner.listteam.array = troopList;
    }

    private initServiceEvent() {
        this.$(app).on(SystemEvent.UI.TROOP_EDIT, this.updateList, this);
        this.$(app).on(SystemEvent.UI.TROOP_EXCHANGE_HERO, this.updateList, this);
    }

    private onDragStart(e: Laya.Event) {}

    private onDragMove(e: Laya.Event) {
        if (!this.curClickItem) {
            return;
        }
    }

    private onDragEnd() {
        if (!this.curClickItem) {
            return;
        }
        console.log("拖拽后的位置：" + this.owner.dragItem.y + "--------" + this.owner.dragItem.y);
        const lenY = this.owner.dragItem.y - (this.startPoint.y + this.owner.dragItem.height / 2);
        const spaceY = Math.ceil(lenY / 300);
        const soliderData = this.curClickItem.dataSource as proto.troop.IHero;
        const listIdx = app.service.troop.getTeamArrIdx(soliderData.uid!);
        const starPoint = this.startPoint.x + this.owner.dragItem.width / 2;
        const endPoint = this.owner.dragItem.x;
        const dragStartIdx = this.getDragIdx(starPoint);
        const dragEndIdx = this.getDragIdx(endPoint);
        console.log(
            "起始的拖拽位置：----" + dragStartIdx + "------------结束后的位置：" + dragEndIdx
        );
        const dragTeamId = listIdx + spaceY;
        let isUnlcok = true;
        if (this.owner.listteam.array[dragTeamId]) {
            const teamVo = this.owner.listteam.array[dragTeamId] as TroopTeamVo;
            isUnlcok = teamVo.isUnlock;
        }
        const isSucess =
            dragEndIdx !== -1 &&
            ((spaceY !== 0 && Math.abs(spaceY) <= 1) || dragEndIdx !== dragStartIdx) &&
            isUnlcok;

        //判定当前区域未成功
        if (isSucess) {
            //移动交换数据
            console.log("拖拽成功");
            const secondIdx = (this.owner.listteam.array[dragTeamId] as TroopTeamVo).idx;
            const teamIdx = (this.owner.listteam.array[listIdx] as TroopTeamVo).idx;
            app.service.troop.requestExchangSolider({
                firstIdx: teamIdx,
                secondIdx: secondIdx,
                firstSoldierIdx: dragStartIdx,
                secondSoldierIdx: dragEndIdx,
            });
            this.curClickItem.visible = true;
            this.owner.dragItem.visible = false;
            this.owner.dragItem.stopDrag();
            //发协议交换上阵
        } else {
            this.owner.dragItem.stopDrag();
            this.curClickItem.visible = true;
            this.owner.dragItem.visible = false;
            console.log("拖拽失败");
        }
    }

    private getDragIdx(posX: number): number {
        const dragWith = this.owner.dragItem.width;
        let dragIdx = -1;
        const startPosx = 334;
        const space = 18;
        if (posX >= startPosx - dragWith / 2 && posX <= startPosx + dragWith / 2) {
            dragIdx = 1;
        } else if (
            posX >= startPosx + dragWith / 2 + space &&
            posX <= startPosx + (dragWith * 3) / 2 + space
        ) {
            dragIdx = 2;
        } else if (
            posX >= startPosx + (dragWith * 3) / 2 + space * 2 &&
            posX <= startPosx + (dragWith * 5) / 2 + space * 2
        ) {
            dragIdx = 3;
        }
        return dragIdx;
    }

    private onTeamListRender(item: PvpTroopListItemUI, index: number) {
        const data = item.dataSource as TroopTeamVo;
        const isUnlock = data.isUnlock;
        // item.img_bg.skin = data.bgIcon;
        item.boxOpen.visible = isUnlock;
        item.boxLock.visible = !isUnlock;
        if (isUnlock) {
            item.imghero.skin = data.heroIcon;
            item.lab_power.text = data.power.toString();
            item.lab_team.text = `${index + 1}`;
            item.boxPower.visible = data.power > 0;
            item.lab_cond.text = "";
            const heroUid = data.getSoliderDataByIdx(0).uid!;
            const heroData = app.service.troop.getHeroDataByuid(heroUid);
            const starNum = heroData.star;
            for (let i = 0; i < 5; i++) {
                const star = item.boxStar.getChildAt(i) as Laya.Image;
                star.visible = i < starNum;
            }
            item.boxStar.forceUpdate();
            const heroHp = data.getSoliderHpByIdx(0);
            item.boxDamage.visible = heroHp === 0;
            item.listhero.renderHandler = new Laya.Handler(this, this.onHeroRender);
            const soliderArr: proto.troop.IHero[] = [];
            for (let i = 0; i < 3; i++) {
                const soliderData = data.getSoliderDataByIdx(i + 1);
                soliderArr.push(soliderData);
            }
            item.listhero.array = soliderArr;
            item.on(Laya.Event.CLICK, this, this.onClickTeam, [data]);
        } else {
            item.lab_cond.text = data.unlockCond;
        }
    }

    private onClickTeam(troopData: TroopTeamVo) {
        app.ui.show(ui.TROOP_SELECT, troopData);
    }

    private onHeroRender(item: PvpTroopListHeroItemUI, index: number) {
        const data = item.dataSource as proto.troop.IHero;
        if (data?.uid) {
            item.boxItem.visible = true;
            item.imgAdd.visible = false;
            const heroData = app.service.troop.getHeroDataByuid(data.uid);
            const typeTextPaths = item.imgType.getComponent(ImageRef)!.texturePaths;
            item.imgType.skin = typeTextPaths[heroData.quality];
            item.labJob.text = heroData.jobDesc;

            for (let i = 0; i < 5; i++) {
                const star = item.boxStar.getChildAt(i) as Laya.Image;
                star.visible = i < heroData.star;
            }
            item.imgHero.skin = heroData.iconBig || "";
            item.boxStar.forceUpdate();
            const textPaths = item.imgBg.getComponent(ImageRef)!.texturePaths;
            item.imgBg.skin = textPaths[heroData.quality];
            item.labStar.text = `${heroData.star}`;
            item.labName.text = heroData.name;
            item.boxDamage.visible = heroData.hp === 0;
            item.on(Laya.Event.CLICK, this, this.onStarClick);
            item.on(Laya.Event.MOUSE_DOWN, this, this.onMousDown);
            item.on(Laya.Event.MOUSE_UP, this, this._onMouseUp);
        } else {
            item.boxItem.visible = false;
            item.imgAdd.visible = true;
            item.off(Laya.Event.CLICK, this, this.onStarClick);
            item.off(Laya.Event.MOUSE_DOWN, this, this.onMousDown);
            item.off(Laya.Event.MOUSE_UP, this, this._onMouseUp);
        }
    }

    private _onMouseUp() {
        if (!this.curClickItem) {
            return;
        }
        console.log("点击抬起");
        this.killDelay("dragTroopList");
        this.owner.dragItem.visible = false;
        this.curClickItem.visible = true;
    }

    private updateHeroData(item: PvpTroopListHeroItemUI, data: proto.troop.IHero) {
        if (data?.uid) {
            item.boxItem.visible = true;
            item.imgAdd.visible = false;
            const heroData = app.service.troop.getHeroDataByuid(data.uid);
            item.imgHero.skin = heroData.iconBig || "";

            const typeTextPaths = item.imgType.getComponent(ImageRef)!.texturePaths;
            item.imgType.skin = typeTextPaths[heroData.quality];
            item.labJob.text = heroData.jobDesc;

            for (let i = 0; i < 5; i++) {
                const star = item.boxStar.getChildAt(i) as Laya.Image;
                star.visible = i < heroData.star;
            }
            item.boxStar.forceUpdate();
            const textPaths = item.imgBg.getComponent(ImageRef)!.texturePaths;
            item.imgBg.skin = textPaths[heroData.quality];
            item.labStar.text = `${heroData.star}`;
            item.labName.text = heroData.name;
            item.boxDamage.visible = heroData.hp === 0;
        } else {
            item.boxItem.visible = false;
            item.imgAdd.visible = true;
        }
    }

    private onStarClick(e: Laya.Event) {
        if (!this.curClickItem) {
            return;
        }
        console.log("点击了");
        this.killDelay("dragTroopList");
        this.owner.dragItem.visible = false;
        this.curClickItem.visible = true;
    }

    private onMousDown(e: Laya.Event) {
        const taret = e.currentTarget as TroopHeroItem;
        this.curClickItem = taret;
        this.killDelay("dragTroopList");
        this.delay(0.5, "dragTroopList", () => this.onClickLong());
    }

    private onClickLong() {
        if (!this.curClickItem) {
            return;
        }
        const nowlistValue = this.owner.listteam.scrollBar?.value || 0;
        if (Math.abs(nowlistValue - this.listScrollerValue) > 10) {
            return;
        }
        this.owner.listteam.scrollBar?.stopScroll();
        this.startPoint = Laya.Point.create().setTo(0, 0);
        (<TroopHeroItem>this.curClickItem).localToGlobal(this.startPoint);
        this.owner.dragItem.pos(
            this.startPoint.x + this.owner.dragItem.width / 2,
            this.startPoint.y + this.owner.dragItem.height / 2
        );
        this.updateHeroData(this.owner.dragItem, this.curClickItem.dataSource);
        this.owner.dragItem.visible = true;
        Laya.Tween.to(
            this.owner.dragItem,
            { scaleX: 1.2, scaleY: 1.2 },
            300,
            Laya.Ease.cubicOut,
            Laya.Handler.create(this, () => {
                Laya.Tween.to(
                    this.owner.dragItem,
                    { scaleX: 1, scaleY: 1 },
                    150,
                    Laya.Ease.cubicOut,
                    Laya.Handler.create(this, () => {
                        Laya.Tween.clearAll(this.owner.dragItem);
                    })
                );
            })
        );
        this.curClickItem.visible = false;

        this.owner.dragItem.startDrag();
    }

    //组件被启用后执行，例如节点被添加到舞台后
    //onEnable(): void {}

    //组件被禁用时执行，例如从节点从舞台移除后
    //onDisable(): void {}

    //第一次执行update之前执行，只会执行一次
    //onStart(): void {}

    //手动调用节点销毁时执行
    override onDestroy(): void {
        Laya.Tween.clearAll(this.owner.dragItem);
    }

    //每帧更新时执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onUpdate(): void {}

    //每帧更新时执行，在update之后执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onLateUpdate(): void {}

    //鼠标点击后执行。与交互相关的还有onMouseDown等十多个函数，具体请参阅文档。
    //onMouseClick(): void {}
}
