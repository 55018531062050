/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIList } from "../../../../core/ui/UIList";
import { BaseRoundWindowBg } from "../../../common/ui-runtime/dialog/BaseRoundWindowBg";

/**
 * resources/prefab/character/rebuild/uicharacter_rebuild.lh
 */
export class UICharacterRebuildBase extends Laya.Dialog {
    public base_round_window_bg!: BaseRoundWindowBg;
    public listResource!: UIList;
    public btnRebuild!: Laya.Button;
}
