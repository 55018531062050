import { Node, NodeDef, Process, Status, TreeEnv } from "../../../../../core/behavior3";
import { BattleConf } from "../../../../../def/auto/battle";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrMovementComponent } from "../../ecs/components/PveSvrMovementComponent";
import { NodeStyle } from "../BtreeDef";

const tmpVelocity = new Laya.Vector3();
const tmpDir = new Laya.Vector3();

/** 调整位置可能在好几个节点执行，所以使用与节点ID无关的变量 */
export const LAST_ADJUST_DIR = TreeEnv.makePrivateVar("lastAdjustDir");

type AdjustStanceInput = [number];

// 可以通过预定位置的方式进行优化
export class AdjustStance extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const movement = env.owner.getComponent(PveSvrMovementComponent);
        if (!movement) {
            return "failure";
        }

        const [enemyEid] = env.input as AdjustStanceInput;
        const enemy = env.context.findElement(enemyEid);
        if (!enemy) {
            node.warn(`invalid enemyEid: ${enemyEid}`);
            return "failure";
        }

        const owner = env.owner;
        let p1 = node.resume(env) as Laya.Vector3 | undefined;
        let running = !!p1;
        const p0: Readonly<Laya.Vector3> = owner.transform.position;
        if (p1) {
            if (!env.context.isFreeStance(owner, p1)) {
                // 目标位置有人，重新计算
                p1 = undefined;
                running = false;
            }
        }
        if (!p1) {
            /**
             *  │  p1
             *  │
             *  │  p0(owner)  p2(emeny)
             *  │
             *  ┼─────────────────────>
             */
            // 二维空间中, 一个向量(x, y)的垂直向量是(-y, x)
            // TODO: 调换XZ？
            const p2 = enemy.transform.position;
            // 确定方向 p0->p1
            tmpDir.set(-(p2.z - p0.z), 0, p2.x - p0.x);
            tmpDir.normalize();

            // 与目标切线方向走0.5的距离
            let lastAdjustDir = env.get(LAST_ADJUST_DIR) as number | undefined;
            if (lastAdjustDir === undefined) {
                lastAdjustDir = Math.round(Math.random() % 10000) % 2 ? 1 : -1;
                env.set(LAST_ADJUST_DIR, lastAdjustDir);
            }
            p1 = owner.transform.position.clone();
            p1.x += tmpDir.x * 0.3 * lastAdjustDir;
            p1.z += tmpDir.z * 0.3 * lastAdjustDir;
        }

        // 设置期望位置
        env.context.setExpectGoToTargetData(owner, false, p1.x, p1.z);

        const distance = Laya.Vector3.distanceXZ(p1, p0);
        if (distance <= 0.01) {
            return "success";
        } else if (!running) {
            const speed = Math.min(
                BattleConf.PVE.MAX_ADJUST_SPEED,
                Math.max(BattleConf.PVE.MOVE_SPEED, movement.speed ?? 0)
            );
            env.context.moveTo(env.owner, p1, speed);
        }
        return node.yield(env, p1);
    }

    override get descriptor(): NodeDef {
        return {
            name: "AdjustStance",
            type: "Action (PVE)" as NodeDef["type"],
            desc: "根据目标调整攻击站位",
            icon: NodeStyle.runningAction.icon,
            color: NodeStyle.runningAction.color,
            input: ["目标"],
        };
    }
}
