/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { BaseRoundWindowBg } from "../../common/ui-runtime/dialog/BaseRoundWindowBg";
import { SkillCheckBoxUI } from "./SkillCheckBoxUI";

/**
 * resources/prefab/skill/SkillScreen.lh
 */
export class SkillScreenUIBase extends Laya.Dialog {
    public imgClose!: Laya.Image;
    public bgwin!: BaseRoundWindowBg;
    public checkQulity1!: SkillCheckBoxUI;
    public checkQulity2!: SkillCheckBoxUI;
    public checkQulity3!: SkillCheckBoxUI;
    public checkQulity4!: SkillCheckBoxUI;
    public checkType1!: SkillCheckBoxUI;
    public checkType2!: SkillCheckBoxUI;
    public checkType3!: SkillCheckBoxUI;
    public checkType4!: SkillCheckBoxUI;
    public checkType5!: SkillCheckBoxUI;
    public btnQulity!: Laya.Button;
    public btnType!: Laya.Button;
}
