import * as ecs from "../../../../../core/ecs";

export class PvpBoardComponent extends ecs.Component {
    readonly mounter: Laya.Sprite3D = new Laya.Sprite3D();

    res!: string;
    view?: Laya.Sprite3D | null;

    textureKey?: string;
    props: Map<string, unknown> = new Map();
}
