const { regClass } = Laya;
import {
    InteractiveBubbleActionType,
    InteractiveBubbleViewType,
} from "../../../pve-server/PveDefs";
import { PveContext } from "../../../pve/PveContext";
import { PveUIBuildingConfirmComponent } from "../../../pve/ecs/components/PveUIBuildingConfirmComponent";
import { IInteractiveBubbleView } from "../IInteractiveBubbleView";
import { BuildingConfirmUIBase } from "./BuildingConfirmUI.generated";

@regClass('0HVeH29LSn-RL5oqXmRXLQ')
export class BuildingConfirmUI extends BuildingConfirmUIBase implements IInteractiveBubbleView {
    get viewType(): InteractiveBubbleViewType {
        return InteractiveBubbleViewType.BUILDING_INTERACTIVE_VIEW;
    }

    get actionTypes(): InteractiveBubbleActionType[] {
        return [InteractiveBubbleActionType.BUILDING_INTERACTIVE_ACTION_1];
    }

    comp: PveUIBuildingConfirmComponent | undefined;

    context: PveContext | undefined;

    worldPosition: Laya.Vector3 = new Laya.Vector3();

    tid: number = 0;

    override onAwake(): void {
        super.onAwake();
        this.button1.on(Laya.Event.CLICK, this, this.onBtn1Click);
    }

    async onBtn1Click() {
        if (this.context && this.comp) {
            this.context.sender.interactiveBubbleAction(
                this.comp.eid,
                this.viewType,
                this.actionTypes[0],
                null
            );
            this.comp.removeComponent(PveUIBuildingConfirmComponent);
        }
    }
}
