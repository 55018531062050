import { app } from "../../../app";
import { VoMap } from "../../../misc/vo/VoMap";
import { ItemVo } from "./ItemVo";

export class ItemVoMap extends VoMap<number, ItemVo> {
    getItems(itemId: number) {
        return this.toArray().filter((vo) => vo.id === itemId);
    }

    /**
     * 获取道具总数量
     */
    getItemAmount(itemId: number) {
        if (app.service.user.moneyVoMap.has(itemId)) {
            return app.service.user.getMoneyCount(itemId);
        }
        return this.getItems(itemId).reduce((sum, vo) => sum + vo.count, 0);
    }
}
