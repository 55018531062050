import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";

export class GetExeclData extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "GetExeclData",
            type: "Action",
            desc: "获取表格数据",
            args: [
                { name: "sheet", type: "string", desc: "表格" },
                { name: "index", type: "code", desc: "索引" },
                { name: "field", type: "string", desc: "字段" },
            ],
            output: ["获取的数据"],
            doc: `
                + 从 execl 表格中获取数据
                `,
        };
    }
}
