/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIList } from "../../../core/ui/UIList";

/**
 * resources/prefab/alliance/AllianceAppointPost.lh
 */
export class AllianceAppointPostBase extends Laya.Box {
    public btnToggle!: Laya.Box;
    public title!: Laya.Label;
    public indicator!: Laya.Image;
    public list!: UIList;
    public selectedBox!: Laya.Image;
}

