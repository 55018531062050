import * as ecs from "../../../../../core/ecs";

// 移动目标的特效
export class PvpMoveTargetVFXComponent extends ecs.Component {
    readonly mounter: Laya.Sprite3D = new Laya.Sprite3D();
    view?: Laya.Sprite3D;
    res!: string;

    readonly pos: Laya.Vector3 = new Laya.Vector3();
}
