const { regClass } = Laya;

import { ItemLo } from "../../system/bag/vo/ItemLo";
import { IconUIBase } from "./IconUI.generated";

@regClass('CZLMSZpVSlG_GwovbYr3nw')
export class IconUI extends IconUIBase {
    override onAwake(): void {
        super.onAwake();
    }

    updateGoods(vo?: ItemLo) {
        if (vo) {
            this.itemIcon.setItemLo(vo);
        }
    }

    set itemNumber(num: string) {
        this.labelNumber.text = num;
    }

    set itemName(name: string) {
        this.labelNumber.text = name;
    }
}
