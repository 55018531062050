import { Mediator } from "../../core/ui-mediator";
import { UIAllianceInvite } from "./ui-runtime/UIAllianceInvite";

const { regClass, property } = Laya;

@regClass('uMpc1_rXTGKBRbtsCVM67Q')
export class UIAllianceInviteMediator extends Mediator {
    declare owner: UIAllianceInvite;

    override onAwake(): void {
        super.onAwake();
    }
}
