import * as ecs from "../../../../../core/ecs";
import { IVector3Like } from "../../../../../core/laya";
import { PveSvrElementComponent } from "./PveSvrElementComponent";
import { PveSvrTransformComponent } from "./PveSvrTransformComponent";

/** 跟随编队的单位都有这个组件 */
export class PveSvrOffsetComponent extends ecs.Component {
    override get dependencies() {
        return [PveSvrElementComponent, PveSvrTransformComponent];
    }

    // 跟随编队，然后跟随编队里的leader，如果编队里没有leader或者leader死了，就不用跟随了
    get troopEid(): number {
        return this.eleComp.troopEid;
    }

    get eleComp() {
        return this.getComponent(PveSvrElementComponent)!;
    }

    index: number = 0;
    offset!: IVector3Like;
}
