const { regClass, property } = Laya;

export type AnimClip =
    | "none"
    | "idle"
    | "attack1"
    | "attack2"
    | "move"
    | "run"
    | "die"
    | "chop"
    | "dig"
    | "mow"
    | "skill1_precast"
    | "skill1"
    | "vertigo"
    | "siege";

export const enum AnimName {
    NONE = "none",
    IDLE = "idle",
    ATTACK = "attack", // attack1 attack2
    MOVE = "move",
    RUN = "run",
    DIE = "die",
    CHOP = "chop",
    DIG = "dig",
    MOW = "mow",
    SKILL1_PRECAST = "skill1_precast",
    SKILL1 = "skill1",
    VERTIGO = "vertigo",
    SIEGE = "siege",
}

@regClass('_K0Kf7EARPCfCQC8AnkKJA')
export class Animator extends Laya.Script {
    private _weaponChop: Laya.Node | null = null;
    private _weaponDig: Laya.Node | null = null;
    private _weaponMow: Laya.Node | null = null;
    private _weaponAttack: Laya.Node | null = null;
    private _weaponTorch: Laya.Node | null = null;
    private _animator!: Laya.Animator;

    private _clips: Map<string, Laya.AnimatorState | undefined> = new Map();
    private _nextPlay: AnimClip | null = null;
    private _currPlay: string | null = null;
    private _crossFade: number = 0;

    @Laya.property({ type: Number })
    speed: number = 1;

    url: string = "";

    override onAwake() {
        this._animator = this.owner.getChildByName("anim").getComponent(Laya.Animator)!;
        this._animator.getControllerLayer().playOnWake = false;
        this._animator.speed = this.speed;

        this._weaponAttack = this.owner.getChildByPath("anim/weapon_attack");
        this._weaponDig = this.owner.getChildByPath("anim/weapon_dig");
        this._weaponMow = this.owner.getChildByPath("anim/weapon_mow");
        this._weaponChop = this.owner.getChildByPath("anim/weapon_chop");
        this._weaponTorch = this.owner.getChildByPath("anim/weapon_torch");

        this._setState(this._weaponAttack, true);
        this._setState(this._weaponDig, false);
        this._setState(this._weaponMow, false);
        this._setState(this._weaponChop, false);
        this._setState(this._weaponTorch, false);

        this._clips.set("idle", this._findClip("idle"));
        this._clips.set("attack1", this._findClip("attack1"));
        this._clips.set("attack2", this._findClip("attack2", "attack1"));
        this._clips.set("move", this._findClip("move"));
        this._clips.set("run", this._findClip("run", "move"));
        this._clips.set("die", this._findClip("die"));
        this._clips.set("dig", this._findClip("dig", "attack1"));
        this._clips.set("chop", this._findClip("chop", "attack1"));
        this._clips.set("mow", this._findClip("mow", "attack1"));
        this._clips.set("skill1_precast", this._findClip("skill1_precast", "idle"));
        this._clips.set("skill1", this._findClip("skill1", "attack1"));
        this._clips.set("vertigo", this._findClip("vertigo", "idle"));
        this._clips.set("siege", this._findClip("siege", "attack1"));

        const states: Set<Laya.AnimatorState> = new Set();
        this._clips.forEach((clip) => {
            if (clip) {
                states.add(clip);
            }
        });
        states.forEach((state) => {
            state.on(Laya.AnimatorState.EVENT_OnStateExit, () => {
                const playState = this._animator.getControllerLayer().getCurrentPlayState();
                if (
                    this._nextPlay &&
                    this._currPlay === playState.currentState?.name &&
                    playState.normalizedTime + this._crossFade >= 1
                ) {
                    const name = this._nextPlay;
                    this._nextPlay = null;
                    if (this._crossFade > 0) {
                        this.crossFade(name, false, this._crossFade);
                    } else {
                        this.play(name);
                    }
                }
            });
        });

        // this.play("idle");
    }

    private _setState(target: Laya.Node | null, active: boolean) {
        if (target && target.active !== active) {
            target.active = active;
        }
    }

    private _findClip(name: AnimClip, defaultName?: AnimClip): Laya.AnimatorState | undefined {
        const state = this._animator.getControllerLayer().getAnimatorState(name);
        if (state) {
            return state;
        } else if (defaultName) {
            return this._findClip(defaultName);
        }
    }

    private _setWeaponState(name: AnimClip) {
        if (
            name === "attack1" ||
            name === "attack2" ||
            name === "move" ||
            name === "run" ||
            name === "skill1_precast" ||
            name === "skill1"
        ) {
            this._setState(this._weaponAttack, true);
            this._setState(this._weaponChop, false);
            this._setState(this._weaponMow, false);
            this._setState(this._weaponDig, false);
        } else if (name === "dig") {
            this._setState(this._weaponDig, true);
            this._setState(this._weaponAttack, false);
            this._setState(this._weaponChop, false);
            this._setState(this._weaponMow, false);
        } else if (name === "chop") {
            this._setState(this._weaponChop, true);
            this._setState(this._weaponDig, false);
            this._setState(this._weaponAttack, false);
            this._setState(this._weaponMow, false);
        } else if (name === "mow") {
            this._setState(this._weaponMow, true);
            this._setState(this._weaponDig, false);
            this._setState(this._weaponAttack, false);
            this._setState(this._weaponChop, false);
        } else if (name === "siege") {
            this._setState(this._weaponTorch, true);
            this._setState(this._weaponDig, false);
            this._setState(this._weaponAttack, false);
            this._setState(this._weaponChop, false);
            this._setState(this._weaponMow, false);
        }
    }

    crossFade(name: AnimClip, bForce: boolean = false, transitionDuration: number = 0.15) {
        const clip = this._clips.get(name);
        this._setWeaponState(name);
        if (clip) {
            if (this._currPlay !== name || bForce) {
                if (clip.name === "move") {
                    this._animator.crossFade(
                        clip.name,
                        transitionDuration,
                        undefined,
                        Math.random() * 0.5
                    );
                } else {
                    this._animator.crossFade(clip.name, transitionDuration);
                }
                this._currPlay = name;
                this._nextPlay = null;
            }
            this._crossFade = Math.max(transitionDuration, 0);
        } else {
            console.error(`no animation state: ${name}`);
        }
    }

    play(name: AnimClip) {
        const clip = this._clips.get(name);
        this._setWeaponState(name);
        if (clip) {
            const playState = this._animator.getControllerLayer().getCurrentPlayState();
            if (playState.currentState?.name !== name) {
                this._animator.play(clip.name);
            }
            this._crossFade = 0;
            this._currPlay = clip.name;
            this._nextPlay = null;
        } else {
            console.error(`no animation state '${name}' in ${this.url}`);
        }
    }

    loopPlay(name: AnimClip) {
        const clip = this._clips.get(name);
        if (clip) {
            clip.clip!.islooping = true;
            this.play(name);
        } else {
            console.error(`no animation state: ${name}`);
        }
    }

    nextPlay(name: AnimClip | null) {
        this._nextPlay = name;
    }

    get currentPlayState() {
        return this._animator.getControllerLayer(0).getCurrentPlayState();
    }
}
