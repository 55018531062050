/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { PnvnCampInfoUI } from "./pnvn/PnvnCampInfoUI";

/**
 * scenes/pvp-nvn.ls
 */
export class PnvnUIBase extends Laya.Scene {
    public battle!: Laya.Box;
    public labels!: Laya.Box;
    public mapTouchArea!: Laya.Box;
    public myCampInfo!: PnvnCampInfoUI;
    public btnClose!: Laya.Button;
    public info!: Laya.Box;
    public enemy!: Laya.Box;
    public hp!: Laya.Image;
    public username!: Laya.Label;
    public hpTxt!: Laya.Label;
    public my!: Laya.Box;
}

