import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { SystemEvent } from "../../misc/system-event";
import { TaskState } from "../task/vo/TaskVo";
import { HomeTaskBox } from "./ui-runtime/HomeTaskBox";

const { regClass, property } = Laya;

@regClass('A3mnSlYGSsqVefwdSM6Uug')
export class HomeTaskMediator extends Mediator {
    //组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
    declare owner: HomeTaskBox;

    override onAwake(): void {
        super.onAwake();
        this.initEvent();
        this.owner.boxComplete.alpha = 0;
    }

    override onEnable(): void {
        this.updateInfo();
    }

    initEvent() {
        this.$(app).on(SystemEvent.TASK_MAIN_UPDATE, () => {
            if (this.enabled) {
                this.updateInfo();
            }
        });
    }

    updateInfo() {
        const taskVo = app.service.task.getCurMainTaskVo();
        if (!taskVo) {
            this.owner.visible = false;
            return;
        }
        this.owner.labelDesc.text = taskVo.desc || "";
        if (taskVo.maxProgressValue > 0) {
            this.owner.labelValue.text = `${taskVo.curProgressValue}/${taskVo.maxProgressValue}`;
        } else {
            this.owner.labelValue.text = `已完成`;
        }

        if (taskVo.taskState === TaskState.CAN_DRAW && taskVo.isAutoDraw) {
            //完成任务
            this.owner.mouseEnabled = false;
            this.owner.boxTaskInfo.visible = false;
            Laya.Tween.to(this.owner.boxComplete, { alpha: 1 }, 200);
            Laya.timer.once(1500, this, () => {
                this.owner.mouseEnabled = true;
                app.service.task.requestReceiveReward({ taskIds: [taskVo.key] });
            });
        } else {
            Laya.Tween.to(
                this.owner.boxComplete,
                { alpha: 0 },
                200,
                null,
                new Laya.Handler(this, () => {
                    this.owner.boxTaskInfo.visible = true;
                })
            );
        }
    }

    override onDisable() {}
}
