import { ItemRourceRow } from "../../../def/table";
import { Vo } from "../../../misc/vo/Vo";

export class ItemRourceLo extends Vo<ItemRourceRow, never> {
    override clone(): ItemRourceLo {
        return new ItemRourceLo(this._config, this._data);
    }

    override get key(): number {
        return this._config.id;
    }

    public get name(): string {
        return this._config.name;
    }

    public get panelStr(): string {
        return this._config.panelstr;
    }
}
