const { regClass } = Laya;
import { app } from "../../../../app";
import { TableUtil } from "../../../table/TableUtil";
import {
    CharacterUpStarPreviewItem,
    CharacterUpStarPreviewItemArgs,
} from "./CharacterUpStarPreviewItem";
import { UICharacterUpStarPreviewBase } from "./UICharacterUpStarPreview.generated";

export interface UICharacterUpStarPreviewArgs {
    id: number;
    star: number;
}

@regClass('RMAfhPApQaCc1tkmyxFSdA')
export class UICharacterUpStarPreview extends UICharacterUpStarPreviewBase {
    private _openArgs!: UICharacterUpStarPreviewArgs;

    override open(closeOther?: boolean | undefined, param?: any): void {
        this._openArgs = param!;
        super.open(closeOther, param);
    }

    override onAwake(): void {
        super.onAwake();

        const heroRow = TableUtil.getRow(app.service.table.hero.hero, { id: this._openArgs.id });
        if (!heroRow) {
            throw new Error(`heroRow not found: ${this._openArgs.id}`);
        }

        const starPrgmId = heroRow.star_programme;
        const starPrgmRows = app.service.table.hero.hero_star[starPrgmId];

        this.listSelect.array = [];
        const data = starPrgmRows.map((row): CharacterUpStarPreviewItemArgs => {
            return {
                config: row,
                bReached: row.star <= this._openArgs.star,
            };
        });
        this.listSelect.renderHandler = new Laya.Handler(this, this._onRenderListSelect);
        this.listSelect.array = data;
    }

    private _onRenderListSelect(cell: CharacterUpStarPreviewItem, index: number): void {
        const data = this.listSelect.array[index] as CharacterUpStarPreviewItemArgs;
        cell.onRender(data, index);
    }
}
