/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/shop/recharge-item.lh
 */
export class RechargeItemUIBase extends Laya.Box {
    public imgIcon!: Laya.Image;
    public labelReward!: Laya.Label;
    public boxExtra!: Laya.Box;
    public labelExtraDesc!: Laya.Label;
    public labelExtraReward!: Laya.Label;
    public labelPrice!: Laya.Label;
}

