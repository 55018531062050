/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/battle/pve-ui/cloud-confirm.lh
 */
export class CloudConfirmUIBase extends Laya.Box {
    public costIcon!: Laya.Image;
    public needCostLabel!: Laya.Label;
    public button1!: Laya.Button;
}

