const { regClass } = Laya;
import { app } from "../../../app";
import { UI_ChestBoxMediator } from "../UIChestBoxMediator";
import { UIChestBoxBase } from "./UIChestBox.generated";

@regClass('RahJ9w1NS7uW-qiPWQTZvQ')
export class UIChestBox extends UIChestBoxBase {
    public get mediator(): UI_ChestBoxMediator | null {
        return this.getComponent(UI_ChestBoxMediator);
    }

    override onAwake(): void {
        super.onAwake();
        (this.openBtn as Laya.Button).onClick(this.onClickOpenBtn.bind(this));
        app.service.chest.initChooseChestBox();
    }

    override onOpened(param: unknown): void {
        const med = this.mediator;
        if (med) {
            med.onOpened();
        }
    }

    private onClickOpenBtn(): void {
        const med = this.mediator;
        if (med) {
            med.onClickOpenBtn();
        }
    }
}
