import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { Pool } from "../../../../../core/pool";
import { TMWorldMinimap } from "../../PveDefs";
import { PveServer } from "../../PveServer";
import { AStar } from "../astar/astar";
import { PveAStarData, PveSvrAStarComponent } from "../components/PveSvrAstarComponent";
import { PveSvrNaviArrowComponent } from "../components/PveSvrNaviArrowComponent";
import { PveSvrTransformComponent } from "../components/PveSvrTransformComponent";
import { PveSvrNaviArrowSystem } from "./PveSvrNaviArrowSystem";

const next1PosTonext2Pos: Laya.Vector2 = new Laya.Vector2();
const astarTempResult: Laya.Vector2[] = [];

export class PveSvrAStarSystem extends ecs.System {
    declare context: PveServer;

    public override update(dt: number): void {
        this.ecs.getComponents(PveSvrNaviArrowComponent).forEach((navigationArrow) => {
            if (navigationArrow.hasTarget) {
                const pointToTargetArr = this.updateArrowComp(navigationArrow, dt);
                if (pointToTargetArr) {
                    PveSvrNaviArrowSystem.navigationArrowPointTo(
                        this.context,
                        navigationArrow.eid,
                        pointToTargetArr[0],
                        pointToTargetArr[1],
                        pointToTargetArr[2]
                    );
                } else {
                    if (!navigationArrow.hasTarget) {
                        PveSvrNaviArrowSystem.hideNavigationArrow(
                            this.context,
                            navigationArrow.eid
                        );
                    }
                }
            }
        });
    }

    /**
     * 每帧执行
     * @returns 找到指向点的时候，返回指向点，否则返回null
     */
    updateArrowComp(arrowComp: PveSvrNaviArrowComponent, dt: number): Laya.Vector3[] | null {
        if (!arrowComp.hasTarget) {
            return null;
        }
        arrowComp.overTime += dt;
        if (arrowComp.overTime > arrowComp.updateAstarDelayTime) {
            arrowComp.overTime = 0;
            return this.astarFindNextGridPoint(arrowComp);
        }
        return null;
    }

    /**
     * A 星寻路，找到下一个路点设置到 pointToPos
     * 若找不到，或者已到达则 设置 hasTarget 为false
     * @returns 找到指向点的时候，返回指向点，否则返回null
     */
    private astarFindNextGridPoint(arrowComp: PveSvrNaviArrowComponent): Laya.Vector3[] | null {
        const astarComponent = this.ecs.getSingletonComponent(PveSvrAStarComponent);
        const transform = arrowComp.getComponent(PveSvrTransformComponent)!;
        if (astarTempResult.length > 0) {
            Pool.free(astarTempResult);
        }

        arrowComp.pointToPosO.x = transform.position.x;
        arrowComp.pointToPosO.z = transform.position.z;
        // 寻路，支持斜线走
        const pathLen = astarComponent.pveAStarData.astar.findPath(
            transform.position.x << 0,
            transform.position.z << 0,
            arrowComp.tmpDestinationPoint.x << 0,
            arrowComp.tmpDestinationPoint.z << 0,
            astarTempResult,
            true
        );

        if (pathLen > 0) {
            if (pathLen <= 2) {
                // 相差两个格子以内时，直接指向终点即可
                arrowComp.pointToPosA.x = astarTempResult[astarTempResult.length - 1].x + 0.5;
                arrowComp.pointToPosA.z = astarTempResult[astarTempResult.length - 1].y + 0.5;
                arrowComp.pointToPosB.x = arrowComp.pointToPosA.x;
                arrowComp.pointToPosB.z = arrowComp.pointToPosA.z;
            } else {
                astarTempResult[0].x = transform.position.x;
                astarTempResult[0].y = transform.position.z;
                const curPos = astarTempResult[0];
                const next1Pos = astarTempResult[1];
                const next2Pos = astarTempResult[2];
                const next3Pos = astarTempResult[3];
                const next3Dis = Laya.Vector2.distance(next2Pos, next3Pos);
                if (next3Dis < 2) {
                    next2Pos.x = next3Pos.x;
                    next2Pos.y = next3Pos.y;
                }

                const next1Dis = Laya.Vector2.distance(curPos, next1Pos);
                if (next1Dis > 3) {
                    arrowComp.pointToPosA.x = next1Pos.x + 0.5;
                    arrowComp.pointToPosA.z = next1Pos.y + 0.5;
                    arrowComp.pointToPosB.x = next2Pos.x + 0.5;
                    arrowComp.pointToPosB.z = next2Pos.x + 0.5;
                } else {
                    // 差2个格子以上时，取下个格子指向下下个格子方向1单位处的位置
                    const offsetDistance = 1.0;
                    // next1Pos 指向 next2Pos 的向量
                    next1PosTonext2Pos.x = next2Pos.x - next1Pos.x;
                    next1PosTonext2Pos.y = next2Pos.y - next1Pos.y;
                    next1PosTonext2Pos.normalize();
                    arrowComp.pointToPosA.x =
                        next1Pos.x + next1PosTonext2Pos.x * offsetDistance + 0.5;
                    arrowComp.pointToPosA.z =
                        next1Pos.y + next1PosTonext2Pos.y * offsetDistance + 0.5;
                    arrowComp.pointToPosB.x = next2Pos.x + 0.5;
                    arrowComp.pointToPosB.z = next2Pos.y + 0.5;
                }
            }
            arrowComp.hasTarget = true;
            return arrowComp.pointABArr;
        } else {
            // 已到达，或者不可到达
            arrowComp.hasTarget = false;
            return null;
        }
    }

    override onCreate() {
        super.onCreate();
        // this.loadWorldBlocks();
        const worldMinimap = app.service.pve.minimap.minimapData;
        this.initAstar(worldMinimap);
    }

    /** 初始化大地图的A星 */
    private initAstar(minimap: TMWorldMinimap) {
        const astarComp = this.ecs.getSingletonComponent(PveSvrAStarComponent);
        astarComp.pveAStarData = new PveAStarData();
        const worldBlockDatas: number[][] = app.service.pve.minimap.staticWorldBlockDatas;
        const astar = new AStar(worldBlockDatas);
        astarComp.pveAStarData.astar = astar;
    }
}
