import * as ecs from "../../../../../core/ecs";

export class PnvnDebugComponent extends ecs.SingletonComponent {
    debugs: Laya.Sprite3D[] = [];
}

export class PnvnDebugBlockComponent extends ecs.Component {
    res!: string;
    view?: Laya.Sprite3D | null;
}
