import { app } from "../../../../../app";
import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { ILaunchBulletArgs } from "../../PveDefs";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrElementComponent } from "../../ecs/components/PveSvrElementComponent";
import { SkillTreeEnv } from "../../ecs/components/PveSvrSkillLauncherComponent";

interface NodeArgs {
    entity_id: number;
    speed?: number;
    h?: number;
    type: BulletType;
    follow?: boolean;
}

type NodeInput = [number[] | number];

export const enum BulletType {
    Fly = "Fly", //飞行物，比如箭矢
    Straight = "Straight", //直线飞行物，比如激光
    Static = "Static", //静止物，比如陷阱，Buff
}

const BulletTypeOptions = [
    { name: "飞行物", value: BulletType.Fly },
    { name: "直线飞行物", value: BulletType.Straight },
    { name: "静止物", value: BulletType.Static },
];

export class CreateBullet extends Process {
    override init(node: Node): void {
        const args = node.args as unknown as NodeArgs;
        if (!app.service.table.battleEntity[args.entity_id]) {
            throw new Error(`no bullet entity: ${args.entity_id}`);
        }
    }

    override run(node: Node, env: AiTreeEnv): Status {
        if (!this._checkArgs(node)) {
            return "failure";
        }
        if (!(env instanceof SkillTreeEnv)) {
            throw new Error(`env is not SkillTreeEnv: ${env}`);
        }
        const [targetEids] = env.input as NodeInput;
        const targetEid = targetEids instanceof Array ? targetEids[0] : targetEids;
        const enemy = env.context.ecs.getComponent(targetEid, PveSvrElementComponent);
        if (!enemy) {
            node.warn(`no target ${targetEid}`);
            return "failure";
        }
        const args = node.args as unknown as NodeArgs;
        const skill = env.skill;
        const bulletArgs: ILaunchBulletArgs = {
            h: args.h ?? 0,
            type: args.type,
            speed: args.speed ?? 0,
            follow: !!args.follow,
            followEid: enemy.eid,
        };
        // console.log(`CreateBullet: `, bulletArgs, node.tree.name, args);
        env.context.launchBullet(skill, args.entity_id, bulletArgs);
        return "success";
    }

    private _checkArgs(node: Node): boolean {
        const args = node.args as unknown as NodeArgs;
        const checkKeys: (keyof NodeArgs)[] = ["entity_id", "type"];
        for (const key of checkKeys) {
            if (args[key] === undefined) {
                node.error(`${key} is required`);
                return false;
            }
        }
        return true;
    }

    override get descriptor(): NodeDef {
        return {
            name: "CreateBullet",
            type: "Action",
            desc: "创建子弹",
            args: [
                { name: "entity_id", type: "int", desc: "子弹实体id" },
                { name: "h", type: "float?", desc: "子弹高度" },
                { name: "follow", type: "boolean?", desc: "是否跟随目标" },
                { name: "type", type: "enum", desc: "子弹类型", options: BulletTypeOptions },
                { name: "speed", type: "float?", desc: "飞行速度" },
                { name: "src_x", type: "float?", desc: "起点x坐标(相对释放者)" },
                { name: "src_y", type: "float?", desc: "起点y坐标(相对释放者)" },
                { name: "dst_x", type: "float?", desc: "终点x坐标(相对受击者)" },
                { name: "dst_y", type: "float?", desc: "终点y坐标(相对受击者)" },
            ],
            input: ["目标单位", "终点坐标?"],
            doc: `
            ## 终点的优先级
            + 跟随目标
            + 输入变量【终点坐标】
            + 参数【终点坐(相对释放者)】
            ![](https://c-ssl.dtstatic.com/uploads/item/201509/01/20150901143403_jdkS5.thumb.1000_0.gif)
            `,
        };
    }
}
