import { app } from "../../app";
import { VoMap } from "../../misc/vo/VoMap";
import { ChestScoreVo } from "./vo/ChestScoreVo";

export class ChestScoreVoMap extends VoMap<number, ChestScoreVo> {
    override get(id: number) {
        let lo = super.get(id);
        if (!lo) {
            lo = new ChestScoreVo(app.service.table.chest.score[id]);
            this.set(id, lo);
        }
        return lo;
    }

    override toArray(): ChestScoreVo[] {
        return super.toArray();
    }

    public initData(): void {
        for (const k in app.service.table.chest.score) {
            this.get(Number(k));
        }
    }
}
