import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { BuffTreeEnv } from "../../ecs/data/buff/BuffBase";

type NodeInput = [number?];

type NodeArgs = {
    readonly skill_id?: number;
};

export class GetBuffArgs extends Process {
    override run(node: Node, env: BuffTreeEnv): Status {
        const buffArgs = env.buffTree.buffArgs;
        env.output.push(buffArgs);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "GetBuffArgs",
            type: "Action",
            desc: "获取本Buff参数，只能在Buff行为树里使用",
            output: ["参数数组"],
            doc: `
                `,
        };
    }
}
