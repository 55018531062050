import { app } from "../../../app";
import { PveEventType } from "../../../def/auto/battle";
import { mainline } from "../../../def/auto/proto";
import { BattlePveMapAreaRow } from "../../../def/table";
import { AreaProgressItemData } from "../pve-server/PveDefs";
import { TMUtil } from "../tilemap/tm-util";
import { PveTMWorldMinimap } from "./PveTMWorldMinimap";

export const enum ProgressItemType {
    CHEST = 0,
    RESCUE_SOLDIER = 1,
    MAP_TRANSFER = 2,
    UNLOCK_CLOUD = 3,
    BOSS = 4,
    ENUM_MAX = 5,
}

export class MainLineProgressData {
    /** 已领取的最高奖励索引 */
    public areaProgressList: mainline.IAreaProgress[] = [];

    private _progressItemDatas!: AreaProgressItemData[];

    public progressItemNames: string[] = ["宝箱", "士兵", "传送点", "迷雾", "怪物首领"];

    public progressItemIcons: string[] = [
        "img_com_icon_baoxiang",
        "img_com_icon_shibing",
        "img_com_icon_chuansongdian",
        "img_com_icon_miwu",
        "img_com_icon_gwsl",
    ];

    constructor() {
        this.init();
    }

    private init(): void {
        this._progressItemDatas = [];
        const max = ProgressItemType.ENUM_MAX.valueOf();
        for (let i = 0; i < max; i++) {
            this._progressItemDatas.push({
                index: i,
                icon: this.progressItemIcons[i],
                name: this.progressItemNames[i],
                curVal: 0,
                maxVal: 1,
            });
        }
    }

    public getHasDrawIndex(areaItem: BattlePveMapAreaRow): number {
        const list = this.areaProgressList;
        for (let i = 0; i < list.length; i++) {
            const item = list[i];
            if (item.area === areaItem.id) {
                return item.awardedIndex ?? -1;
            }
        }
        return -1;
    }

    public getCanDrawIndex(areaItem: BattlePveMapAreaRow, curPersent: number): number {
        let canDrawIndex = -1;
        const persents = areaItem.progress_persent;
        for (let i = 0; i < persents.length; i++) {
            const invI = persents.length - 1 - i;
            const temp_persent = persents[invI];
            if (curPersent >= temp_persent) {
                canDrawIndex = invI;
            }
        }
        return canDrawIndex;
    }

    public getDatasWithAreaId(areaId: number): AreaProgressItemData[] {
        this.updateProgress(areaId);
        return this._progressItemDatas;
    }

    private updateProgress(areaId: number): void {
        const max = ProgressItemType.ENUM_MAX.valueOf();
        for (let i = 0; i < max; i++) {
            const data = this._progressItemDatas[i];
            this.updateDataProgress(data, areaId);
        }
    }

    public getTotalProgressPersent(totalProgress: {
        totalCount: number;
        finishCount: number;
    }): number {
        const bi = totalProgress.finishCount / totalProgress.totalCount;
        return Math.floor(bi * 100);
    }

    /** 获取总完成进度 */
    public getTotalProgress(areaId: number): { totalCount: number; finishCount: number } {
        this.updateProgress(areaId);
        return this.getTotalProgressWith(this._progressItemDatas);
    }

    public getTotalProgressWith(itemDatas: AreaProgressItemData[]): {
        totalCount: number;
        finishCount: number;
    } {
        let totalCount = 0;
        let finishCount = 0;
        const max = ProgressItemType.ENUM_MAX.valueOf();
        for (let i = 0; i < max; i++) {
            const data = itemDatas[i];
            finishCount += data.curVal;
            totalCount += data.maxVal;
        }
        if (totalCount < 1) {
            totalCount = 1;
        }
        return { totalCount, finishCount };
    }

    private updateDataProgress(data: AreaProgressItemData, areaId: number): void {
        data.curVal = 0;
        data.maxVal = 1;
        const minimap = app.service.pve.minimap;
        switch (data.index) {
            case ProgressItemType.CHEST:
                // 获取指定区域的总宝箱数量
                data.curVal = this.getFinishEventCountWithAreaId(areaId, PveEventType.GAIN_CHEST);
                data.maxVal = minimap.getAreaChestCount(areaId);
                break;
            case ProgressItemType.RESCUE_SOLDIER:
                data.curVal = this.getFinishEventCountWithAreaId(
                    areaId,
                    PveEventType.RESCUE_SOLDIER
                );
                data.maxVal = minimap.getAreaRecusSoilderCount(areaId);
                break;
            case ProgressItemType.MAP_TRANSFER:
                // 获取指定区域传送点的总数量
                {
                    const allTransferIds = minimap.getAreaTransferIdsWithAreaId(areaId);
                    let total = 0;
                    allTransferIds.forEach((transfer_id) => {
                        const isOpen = app.service.pve.getTransferIdIsOpen(transfer_id.id);
                        if (isOpen) {
                            total = total + 1;
                        }
                    });
                    data.curVal = total;
                    data.maxVal = allTransferIds.length;
                }
                break;
            case ProgressItemType.UNLOCK_CLOUD:
                data.curVal = this.getFinishUnLockCloudEventCountWithAreaId(areaId);
                data.maxVal = minimap.getAreaUnLockCloudCount(areaId);
                break;
            case ProgressItemType.BOSS:
                data.curVal = this.getFinishBossCountWithAreaId(areaId);
                data.maxVal = minimap.getAreaBossCount(areaId);
                break;
            default:
                break;
        }
    }

    /** 获取指定区域的boss完成个数 */
    public getFinishBossCountWithAreaId(areaId: number): number {
        const minimap: PveTMWorldMinimap = app.service.pve.minimap;

        const mainLineData = app.service.pve.mlData.mainLineData;
        if (!mainLineData) return 0;
        const monsterTroops = mainLineData.monsterTroops;
        if (!monsterTroops || monsterTroops.length < 1) return 0;
        let totalCount = 0;
        for (let i = 0; i < monsterTroops.length; i++) {
            const troop = monsterTroops[i];
            if (troop && troop.deathCount && troop.tid) {
                const { x, z } = TMUtil.decodeKey(troop.key!);
                const curAreaId = minimap.getAreaIDWithGridPos(x, z);
                if (curAreaId === areaId) {
                    const cfg = app.service.table.monster.troop[troop.tid];
                    if (cfg && cfg.isBoss) {
                        totalCount++;
                    }
                }
            }
        }
        return totalCount;
    }

    public getFinishUnLockCloudEventCountWithAreaId(areaId: number): number {
        const mainLineData = app.service.pve.mlData.mainLineData;
        if (!mainLineData) return 0;
        const events = mainLineData.events;
        if (!events || events.length < 1) return 0;
        const minimap: PveTMWorldMinimap = app.service.pve.minimap;
        let totalCount = 0;
        for (let i = 0; i < events.length; i++) {
            const event = events[i];
            if (event && event.tid && event.triggerTime) {
                const eventVo = app.service.pve.eventLoMap.get(event.tid)!;
                let isUnlockCloud = false;
                if (eventVo.eventType === PveEventType.UNLOCK_CLOUD) {
                    // 该事件已完成，判断该事件所在区域
                    isUnlockCloud = true;
                } else if (eventVo.eventType === PveEventType.TALK_TO_NPC) {
                    const cd = eventVo.customData!;
                    if (cd.finish_open_cloud) {
                        isUnlockCloud = true;
                    }
                }
                if (isUnlockCloud) {
                    const { x, z } = TMUtil.decodeKey(event.key!);
                    const curAreaId = minimap.getAreaIDWithGridPos(x, z);
                    if (curAreaId === areaId) {
                        totalCount++;
                    }
                }
            }
        }
        return totalCount;
    }

    public getFinishEventCountWithAreaId(areaId: number, eventType: PveEventType): number {
        const mainLineData = app.service.pve.mlData.mainLineData;
        if (!mainLineData) return 0;
        const events = mainLineData.events;
        if (!events || events.length < 1) return 0;
        const minimap: PveTMWorldMinimap = app.service.pve.minimap;
        let totalCount = 0;
        for (let i = 0; i < events.length; i++) {
            const event = events[i];
            if (event && event.tid && event.triggerTime) {
                const eventVo = app.service.pve.eventLoMap.get(event.tid)!;
                if (eventVo.eventType === eventType) {
                    // 该事件已完成，判断该事件所在区域
                    const { x, z } = TMUtil.decodeKey(event.key!);
                    const curAreaId = minimap.getAreaIDWithGridPos(x, z);
                    if (curAreaId === areaId) {
                        totalCount++;
                    }
                }
            }
        }
        return totalCount;
    }
}
