/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/misc/toast.lh
 */
export class ToastUIBase extends Laya.Dialog {
    public content!: Laya.Label;
}

