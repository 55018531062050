import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { ChatConf } from "../../def/auto/chat";
import { EmojiRow } from "../../def/table";
import { TableUtil } from "../table/TableUtil";
import { ChatService } from "./ChatService";
import { UIChat } from "./ui-runtime/UIChat";

const { regClass, property } = Laya;

@regClass('F48lbFE4TKer8slAzO48ew')
export class UIChatMediator extends Mediator {
    declare owner: UIChat;
    emojiData!: EmojiRow[];

    override onAwake(): void {
        super.onAwake();
        this.owner.boxEmoji.visible = false;
        this.emojiData = TableUtil.getRows(app.service.table.emoji, {});
        this.initUIEvent();
        this.initServiceEvent();
        this.initInfo();
    }

    override onStart(): void {
        this.updateList();
    }

    //初始化UI事件监听
    initUIEvent() {
        this.owner.on(Laya.Event.MOUSE_DOWN, this, (evn: Laya.Event) => {
            const touchPoint = this.owner.globalToLocal(evn.touchPos);
            if (!this.owner.boxEmoji.getBounds().contains(touchPoint.x, touchPoint.y)) {
                this.owner.boxEmoji.visible = false;
            }
        });
        this.owner.btnEmoji.on(Laya.Event.CLICK, this.owner, () => {
            this.owner.boxEmoji.visible = true;
        });
        this.owner.btnSend.on(Laya.Event.CLICK, this, () => {
            app.service.chat.requestSend({
                channel: ChatConf.CHAT_CHANNEL.WORLD,
                text: this.owner.inputMsg.text,
            });
            this.owner.inputMsg.text = "";
        });
    }

    //初始化Service事件监听
    initServiceEvent() {
        this.$(app).on(ChatService.CHAT_UPDATE, (oldIndex: number, nextIndex: number) => {
            // this.owner.listPanel.addData(data);
            // this.owner.listPanel.refreshScrollBar();
            for (let i = oldIndex; i < nextIndex; i++) {
                const data = app.service.chat.chatMsgVoMap.get(i);
                if (!data) {
                    console.warn("msg is null", i);
                    continue;
                }
                this.owner.virtuallyList.addData(data);
            }
        });
    }

    initInfo() {
        this.owner.listEmoji.mouseHandler = new Laya.Handler(
            this,
            (evn: Laya.Event, index: number) => {
                if (evn.type === Laya.Event.CLICK) {
                    this.owner.inputMsg.text += `{${this.emojiData[index].id}}`;
                }
            }
        );
        this.owner.listEmoji.renderHandler = new Laya.Handler(
            this,
            (cell: Laya.Sprite, index: number) => {
                const icon = cell.getChildByName("imgIcon") as Laya.Image;
                icon.skin = `resources/texture/emoji/emoji/${this.emojiData[index].icon}.png`;
            }
        );
        this.owner.listEmoji.array = this.emojiData;
    }

    //列表点击回调
    onListClick(evn: Laya.Event, index: number) {
        // if (evn.type === Laya.Event.CLICK) {
        // }
    }

    //标签点击回调
    onTabSelect(index: number) {
        // this.updateList();
    }

    //刷新列表
    updateList() {
        // this.owner.listPanel.setArrayData(
        //     Util.toArray(app.service.chat.chatMsgVoBag.getBag()).reverse()
        // );
        // this.owner.listPanel.refreshScrollBar();
        this.owner.virtuallyList.setArrayData(app.service.chat.chatMsgVoMap.toArray(), 1);
    }
}
