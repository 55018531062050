const { regClass } = Laya;
import { UILeaderSKinUpgradeBase } from "./UILeaderSKinUpgrade.generated";

@regClass('uwyn8EtvSaqlR5MGyXGqeg')
export class UILeaderSKinUpgrade extends UILeaderSKinUpgradeBase {
    public skinId: number = 0;

    override open(closeOther?: boolean, param?: any): void {
        this.skinId = param as number;
        super.open(closeOther, closeOther);
    }
}
