import * as ecs from "../../../../../core/ecs";
import { SecondOrderDynamics } from "../../../base/SecondOrderDynamics";
import { PveContext } from "../../PveContext";
import { PveNavinArrowComponent } from "../components/PveNavinArrowComponent";
import { PveTransformComponent } from "../components/PveTransformComponent";

const tmpPoint: Laya.Vector3 = new Laya.Vector3();

export class PveNavigationArrowSystem extends ecs.System {
    declare context: PveContext;

    override update(dt: number): void {
        this.ecs.getComponents(PveNavinArrowComponent).forEach((arrow) => {
            if (!arrow.view) return;
            if (!arrow.hasTarget) {
                if (arrow.view.active) {
                    arrow.view.active = false;
                }
                return;
            }
            const transform = arrow.getComponent(PveTransformComponent)!;
            const position = transform.position;
            const disO = Laya.Vector3.distanceSquared(arrow.pointToPosO, position);

            // 因为不是需要确切的距离，所以用distanceSquared少一个开方，性能更好。
            const disA = Laya.Vector3.distanceSquared(arrow.pointToPosA, arrow.pointToPosO);

            // 取近的进行引导
            let pointToPosX: number = 0;
            let pointToPosZ: number = 0;
            if (disO > disA) {
                pointToPosX = arrow.pointToPosB.x;
                pointToPosZ = arrow.pointToPosB.z;
            } else {
                pointToPosX = arrow.pointToPosA.x;
                pointToPosZ = arrow.pointToPosA.z;
            }

            if (!arrow.sod) {
                arrow.sod = new SecondOrderDynamics();
                arrow.sod.init(0.3, 0.8, 0.0, position.x, position.z);
                arrow.sodPointToPos.x = pointToPosX;
                arrow.sodPointToPos.z = pointToPosZ;
            } else {
                const sodV2 = arrow.sod.update(dt, pointToPosX, pointToPosZ);
                arrow.sodPointToPos.x = sodV2.x;
                arrow.sodPointToPos.z = sodV2.y;
            }

            if (!arrow.view.active) {
                arrow.view.active = true;
            }
            this.updatePositionAndRotation(arrow);
        });
    }

    /**
     * 更新位置和旋转
     * 箭头指向 pointToPos
     */
    updatePositionAndRotation(arrow: PveNavinArrowComponent): void {
        const transform = arrow.getComponent(PveTransformComponent)!;
        arrow.mounter.transform.position = transform.position;

        // 指向的目标方向
        Laya.Vector3.subtract(arrow.sodPointToPos, transform.position, tmpPoint);
        tmpPoint.normalize();
        const oldRot = arrow.mounter.transform.localRotationEulerY;
        const newRotation = this.calcRotation(oldRot, tmpPoint.x, tmpPoint.z);
        arrow.mounter.transform.localRotationEulerY = newRotation;
    }

    private calcRotation(oldRotation: number, x: number, z: number): number {
        const rad = Math.atan2(x, z); // 弧度
        const degress = (rad * 180) / Math.PI; // 角度
        let newRotation = degress - 90;
        let offset = newRotation - oldRotation;
        if (offset > 180) {
            offset -= 360;
        } else if (offset < -180) {
            offset += 360;
        }
        newRotation = oldRotation + offset;
        return newRotation;
    }
}
