/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/battle/pvp-ui/action-menu.lh
 */
export class ActionMenuUIBase extends Laya.Box {
    public btnMove!: Laya.Button;
    public btnAttack!: Laya.Button;
}

