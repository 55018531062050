import { HomeNaviMenuItemBase } from "./HomeNaviMenuItem.generated";

const { regClass } = Laya;

@regClass('PxaNic_JTYitTt-Nt4TSsw')
export class HomeNaviMenuItem extends HomeNaviMenuItemBase {
    private _selected: boolean = false;

    get selected() {
        return this._selected;
    }

    set selected(value: boolean) {
        this._selected = value;
        this.selectBox.visible = value;
        // if (value) {
        //     Laya.Tween.to(this.selectBox, { width: 320, alpha: 1 }, 150);
        // } else {
        //     Laya.Tween.to(this.selectBox, { width: 0, alpha: 0 }, 150);
        // }
    }
}
