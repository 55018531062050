import { Mediator } from "../../core/ui-mediator";
import { UIAllianceEditInfo } from "./ui-runtime/UIAllianceEditInfo";

const { regClass, property } = Laya;

@regClass('JfuiqCS2RBKGGU39Xq0pWA')
export class UIAllianceEditInfoMediator extends Mediator {
    declare owner: UIAllianceEditInfo;

    override onAwake(): void {
        super.onAwake();
    }
}
