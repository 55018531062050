/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIImageBar } from "../../../core/ui/UIImageBar";

/**
 * resources/prefab/common/slider/UISlider2.lh
 */
export class UIHSliderBase extends Laya.Box {
    public imgBg!: Laya.Image;
    public imgPro!: UIImageBar;
    public imgBar!: Laya.Image;
    public minBtn!: Laya.Button;
    public addBtn!: Laya.Button;
}
