const { regClass } = Laya;
import { app } from "../../../app";
import { res } from "../../../misc/res";
import { SkillVo } from "../vo/SkillVo";
import { SkillFastListItemUI } from "./SkillFastListItemUI";
import { SkillFastListUIBase } from "./SkillFastListUI.generated";

export interface SkillFastListUIArgs {
    uid: number; //英雄uid
    skillPos: number; //技能位置
    sysType: number; //系统类型
}
@regClass('wBeAEvmlS8ycZRyImScqPA')
export class SkillFastListUI extends SkillFastListUIBase {
    private skillItemList: { [key: number]: SkillFastListItemUI } = {}; //存放添加进入的

    private curClikItem: SkillFastListItemUI | null = null;

    private vboxContentHeight: number = 0;
    public heroUid: number = 0;
    public skillPos: number = 0;
    public sysType: number = 0;

    override open(closeOther?: boolean, param?: SkillFastListUIArgs): void {
        this.heroUid = param!.uid;
        this.skillPos = param!.skillPos;
        this.sysType = param!.sysType;
        super.open(closeOther, param);
    }

    async initList(skillDatas: SkillVo[]) {
        this.vboxContent.removeChildren();
        this.curClikItem = null;
        this.vboxContentHeight = 0;
        const checker = () => !this.vboxContent.destroyed;
        for (const skilData of skillDatas) {
            const prefab = await app.loader.loadPrefab(res.skill.SKILL_FAST_ITEM);
            if (!checker()) {
                return;
            }
            const itemBox = prefab.create() as SkillFastListItemUI;
            const skillId = skilData.skillId;
            itemBox.isShowDetails(false);
            this.vboxContent.addChild(itemBox);
            this.skillItemList[skillId] = itemBox;
            itemBox.dataSource = skilData;
            itemBox.setData(this.heroUid, this.skillPos, this.sysType);
            itemBox.on(Laya.Event.CLICK, this, this.onClickItem);
        }
        this.vboxContent.height = this.vboxContentHeight =
            152 * skillDatas.length + (skillDatas.length - 1) * this.vboxContent.space;
    }

    private onClickItem(e: Laya.Event) {
        const targe = e.currentTarget as SkillFastListItemUI;
        if (this.curClikItem === targe) {
            this.curClikItem.isShowDetails(false);
            this.vboxContent.height = this.vboxContentHeight - 103;
            this.curClikItem = null;
            return;
        }
        if (!this.curClikItem) {
            this.vboxContent.height = this.vboxContentHeight + 103;
        }
        if (this.curClikItem) {
            this.curClikItem.isShowDetails(false);
        }
        this.curClikItem = targe;
        this.curClikItem.isShowDetails(true);
    }
}
