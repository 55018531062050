const { regClass } = Laya;
import { app } from "../../../../app";
import { ChestBoxBtnItem } from "./ChestBoxBtnItem";
import { ChestBoxDownBlockBase } from "./ChestBoxDownBlock.generated";

@regClass('4DubZtgsSR238xM9VTQcjQ')
export class ChestBoxDownBlock extends ChestBoxDownBlockBase {
    override onAwake(): void {
        super.onAwake();
        this.itemList.renderHandler = new Laya.Handler(this, this.onListItemRenderer);
        // console.log("ChestBoxDownBlock::onAwake");
    }

    private onListItemRenderer(item: ChestBoxBtnItem, index: number): void {
        // console.log("index:" + index + "data:" + item.dataSource);
        item.updateShow();
    }

    public updateShow(): void {
        this.itemList.array = app.service.chest.chestBoxVoMap.toArray();
    }
}
