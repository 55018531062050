import { BuffBaseData } from "../BuffBase";

export enum BuffSpecialType {
    None = 0,
    LimitHurt = 1, //限制收到伤害的最大值--技能--圣衣护体
}

export class BuffSpecialData extends BuffBaseData {
    readonly fromBuffTreeId: number = 0;

    readonly type: BuffSpecialType = BuffSpecialType.None;

    readonly value: number = 0;

    constructor(
        fromBuffTreeId: number,
        type: BuffSpecialType,
        value: number,
        overdueTime: number = -1
    ) {
        super(overdueTime);
        this.fromBuffTreeId = fromBuffTreeId;
        this.type = type;
        this.value = value;
    }
}
