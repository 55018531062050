/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/character/character/character_switch_tab.lh
 */
export class UICharacterSwitchTabBase extends Laya.Box {
    public lblList!: Laya.Label;
}

