import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrCollectSystem } from "../../ecs/systems/PveSvrCollectSystem";

type CollectInput = [number];

export class Collect extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const [targetEid] = env.input as CollectInput;
        const target = env.context.findElement(targetEid);
        if (!target) {
            node.warn(`invalid targetEid: ${targetEid}`);
            return "failure";
        }

        PveSvrCollectSystem.collect(env.context, env.owner, target);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "Collect",
            type: "Action",
            desc: "采集",
            input: ["目标"],
        };
    }
}
