const TO_DEGREE = 180 / Math.PI;

declare global {
    interface Math {
        equal(n1: number, n2: number, precision: number): boolean;
        toDegree(rad: number): number;
        toRadian(degree: number): number;
        randomInt(min: number, max: number): number;
        lerp(a: number, b: number, t: number): number;
        inverseLerp(a: number, b: number, value: number): number;
        remap(iMin: number, iMax: number, oMin: number, oMax: number, value: number): number;
        clamp(value: number, min: number, max: number): number;
    }
}

Math.clamp = function (value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max);
};

Math.equal = function (n1: number, n2: number, precision: number): boolean {
    return !(n1 > precision || n1 < -precision);
};

Math.toDegree = function (rad: number): number {
    return rad * TO_DEGREE;
};

Math.toRadian = function (degree: number): number {
    return degree / TO_DEGREE;
};

Math.lerp = function (a: number, b: number, t: number): number {
    return (1 - t) * a + t * b;
};

Math.inverseLerp = function (a: number, b: number, value: number): number {
    return (value - a) / (b - a);
};

Math.remap = function (
    iMin: number,
    iMax: number,
    oMin: number,
    oMax: number,
    value: number
): number {
    return Math.lerp(oMin, oMax, Math.inverseLerp(iMin, iMax, value));
};

Math.randomInt = function (min: number, max: number): number {
    return min + Math.round(Math.random() * (max - min));
};
//换个名字，不要直接export default Math，否则会报错。 反正也不建议从这里导入，直接用Math就行
export const MathExt = Math;
