import { app } from "../../../../../app";
import { Callback } from "../../../../../core/dispatcher";
import * as ecs from "../../../../../core/ecs";
import { StyleConf } from "../../../../../def/style";
import { SystemEvent } from "../../../../../misc/system-event";
import { PveContext } from "../../PveContext";
import { PveElementComponent } from "../components/PveElementComponent";
import { PveUICloudComponent } from "../components/PveUICloudComponent";

const tempVector3 = new Laya.Vector3();
const tempVector4 = new Laya.Vector4();

/** 云雾系统 */
export class PveCloudSystem extends ecs.System {
    declare context: PveContext;

    override onCreate(): void {
        this._on(SystemEvent.PVE.ON_CLOUD_CONTROL_ENTER, this.onPveCloudControlEnter);
        this._on(SystemEvent.PVE.ON_CLOUD_CONTROL_LEAVE, this.onPveCloudControlLeave);
        this._on(SystemEvent.PVE.ON_UI_CLOUD_LOADED, this.onPveloadCloudConfirmComp);
        this._on(SystemEvent.USER_MONEY_UPDATE, this.onUserMoneyUpdate);
    }

    private _on(event: string, callback: Callback) {
        this.context.$(app).on(event, callback, this);
    }

    /** 用户货币更新,刷新显示提示UI */
    private onUserMoneyUpdate(): void {
        const comps = this.ecs.getComponents(PveUICloudComponent);
        comps.forEach((c) => {
            this.onPveloadCloudConfirmComp(c.eid);
        });
    }

    private onPveloadCloudConfirmComp(eid: number): void {
        // 初始化刷新显示UI
        const comp = this.ecs.getComponent(eid, PveUICloudComponent);
        if (!comp || !comp.view) return;
        const v = comp.view;
        const cost = comp.eventVo.cost;
        if (cost) {
            const rewardCode = cost[0].id;
            const itemLo = app.service.bag.itemLoMap.get(rewardCode);
            v.costIcon.skin = itemLo.iconUrl; // costIcon 是 Image
            const needAmount = cost[0].num;
            // 通过身上的资源数量 改变文本颜色的显示
            v.needCostLabel.text = needAmount + "";

            const amountObj = app.service.pve.mlData.getCurrentAssetAmount(rewardCode);
            const curHasAmount = amountObj.packAmount + amountObj.truckAmount;
            if (curHasAmount >= needAmount) {
                // 足够
                v.needCostLabel.color = StyleConf.COST_AMOUNT_COLOR.ENOUGH;
                v.button1.gray = false;
            } else {
                // 不够
                v.needCostLabel.color = StyleConf.COST_AMOUNT_COLOR.NOT_ENOUGH;
                v.button1.gray = true;
            }
        }
    }

    private onPveCloudControlEnter(eid: number): void {
        const element = this.ecs.getComponent(eid, PveElementComponent);
        if (element) {
            const eventVo = app.service.pve.eventLoMap.get(element.tid)!;
            const comp = element.addComponent(PveUICloudComponent);
            comp.eventVo = eventVo;
        }
    }

    private onPveCloudControlLeave(eid: number): void {
        const element = this.ecs.getComponent(eid, PveElementComponent);
        if (element) {
            element.removeComponent(PveUICloudComponent);
        }
    }

    override update(dt: number): void {
        this.ecs.getComponents(PveUICloudComponent).forEach((component) => {
            if (component.view) {
                const element = component.getComponent(PveElementComponent)!;
                const v = component.mounter;
                // 更新位置
                const pos = element.transform.position;
                tempVector3.cloneFrom(pos);
                tempVector3.y = 1.0;
                this.context.camera.worldToViewportPoint(tempVector3, tempVector4);
                v.pos(tempVector4.x, tempVector4.y);
            }
        });
    }
}
