/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIImageBar } from "../../../core/ui/UIImageBar";

/**
 * scenes/loading.ls
 */
export class LoadingUIBase extends Laya.Scene {
    public imgBg!: Laya.Image;
    public progress!: Laya.ProgressBar;
    public labelTips!: Laya.Label;
    public imgBar!: UIImageBar;
}

