import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrElementComponent } from "../../ecs/components/PveSvrElementComponent";
import { PveSvrTroopComponent } from "../../ecs/components/PveSvrTroopComponent";

export class GetLeader extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const troopEid = env.owner.troopEid;
        const troopComp = env.context.ecs.getComponent(troopEid, PveSvrTroopComponent);
        if (!troopComp) {
            console.error(`self has no PveSvrTroopComponent ${troopEid}`);
            return "failure";
        }
        if (!env.context.ecs.getComponent(troopComp.leaderEid, PveSvrElementComponent)) {
            console.error(`leader ${troopComp.leaderEid} has no PveSvrElementComponent`);
            return "failure";
        }
        env.output.push(troopComp.leaderEid);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "GetLeader",
            type: "Action",
            desc: "获取本编队的首领",
            output: ["首领"],
            doc: `
                `,
        };
    }
}
