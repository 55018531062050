import { Constructor } from "protobufjs";
import * as ecs from "../../../../../core/ecs";
import { IReusable, Pool } from "../../../../../core/pool";
import { PveSvrTransformComponent } from "./PveSvrTransformComponent";

export const enum MovementCtrlType {
    AI,
    WHEEL,
}

export enum MovementState {
    START,
    STOP,
}

export enum MovementUpdateType {
    NONE,
    TARGET_POSITION, //朝着target_position移动,速度为speed,target position不变
    VELOCITY, //按velocity移动朝着固定方向移动
    FOLLOW_TARGET, //固定时间内到达目标所在位置
}

export const enum TrackType {
    NONE = 0,
    LINE = 1,
    ORIENTATION = 2,
    CURVE = 3,
    DURATION = 4,
}

// 从PveMovementComponent.ts拷过来的，没找到用它的地方
export class TrackVector3 extends Laya.Vector3 {
    /** 与前一个坐标点的距离差 */
    offset: number = 0;

    constructor(x?: number, y?: number, z?: number, offset?: number) {
        super(x, y, z);
        this.offset = offset ?? 0;
    }
}

export type Track = {
    time: number;
    duration: number;
    p0: Laya.Vector3; // 起始点
    p1?: Laya.Vector3 | null; // 中间穿过点
    p2: Laya.Vector3; // 终点
    cp?: Laya.Vector3 | null; // 控制点
};

export class InterpolationRate {
    static readonly POSITION = 5;
    static readonly ROTATION = 7;
    static readonly SOLDIER_POSITION = 5;
    static readonly SOLDIER_ROTATION = 4;
}

export interface FollowTarget {
    targetEid: number;
    duration: number;
    ratio: number;
}

/**
 * 移动方式：
 * 1. 固定速度和方向移动，不需要target
 * 2. 朝着target_position移动，速度为speed
 * 3. 朝着target移动，速度为speed，方向随着target position
 */
@Pool.reusable
export class PveSvrMovementComponent extends ecs.Component implements IReusable {
    override get dependencies(): Constructor<ecs.Component>[] {
        return [PveSvrTransformComponent];
    }

    shouldCollision: boolean = true; // 是否碰撞，碰撞后需要改变移动方向。默认为true

    state: MovementState = MovementState.STOP;

    ctrlType: MovementCtrlType = MovementCtrlType.AI;

    readonly targetPosition: Laya.Vector3 = new Laya.Vector3();

    // MovementUpdateType.FOLLOW_TARGET 时才有意义
    readonly followTarget: FollowTarget = {
        targetEid: 0,
        duration: 0,
        ratio: 1,
    };

    updateType: MovementUpdateType = MovementUpdateType.NONE;
    // speed 和 velocity 完全独立，概念上完全不能混用
    speed: number = 0; // MovementUpdateType.TARGET_POS 时才有意义
    readonly velocity: Laya.Vector3 = new Laya.Vector3(); // MovementUpdateType.VOLECITY 时才有意义

    // 轨迹方式移动
    trackType: TrackType = TrackType.NONE;
    track?: Track;

    __unuse() {}

    __reuse() {
        this.shouldCollision = true;
        this.state = MovementState.STOP;
        this.trackType = TrackType.NONE;
        this.updateType = MovementUpdateType.NONE;
        this.speed = 0;
        this.targetPosition.setValue(0, 0, 0);

        this.followTarget.targetEid = 0;
        this.followTarget.duration = 0;
        this.followTarget.ratio = 1;
    }
}
