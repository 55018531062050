/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { BaseMidItemIcon } from "../../../common/ui-runtime/item-icon/BaseMidItemIcon";

/**
 * resources/prefab/chest-box/sub-choose-hero/ChestBoxChooseHeroItem.lh
 */
export class ChestBoxChooseHeroItemBase extends Laya.Box {
    public itemIcon!: BaseMidItemIcon;
    public lab_name!: Laya.Label;
    public img_mask!: Laya.Image;
}

