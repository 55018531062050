const { regClass } = Laya;
import { StringUtil } from "../../../../../core/utils/string-util";
import { AreaProgressItemData } from "../../../pve-server/PveDefs";
import { AreaProgressListItemUIBase } from "./AreaProgressListItemUI.generated";

@regClass('bxj2eZ2oR3-e5P0Hb24Cpw')
export class AreaProgressListItemUI extends AreaProgressListItemUIBase {
    public updateShow(index: number): void {
        const data: AreaProgressItemData = this.dataSource;
        if (!data) return;

        const iconUrl: string = "resources/atlas/map-transfer/{0}.png";
        this.img_icon.skin = StringUtil.format(iconUrl, data.icon);
        this.text_name.text = data.name;
        this.text_value.text = data.curVal + "/" + data.maxVal;
        this.img_01.visible = index % 2 !== 0;
    }
}
