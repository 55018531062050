import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import {
    MovementUpdateType,
    PveSvrMovementComponent,
} from "../../ecs/components/PveSvrMovementComponent";

interface NodeArgs {
    readonly track: string;
    readonly x?: number;
    readonly y?: number;
    readonly rate?: number;
}

type NodeInput = [Laya.Vector3 | undefined];

export class FlyToPos extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const cacheSpeed = node.resume(env) as Laya.Vector3 | undefined;
        const [target] = env.input as NodeInput;
        if (!target || !(target instanceof Laya.Vector3)) {
            node.error("no target position to move");
        }

        const args = node.args as unknown as NodeArgs;
        const speed = args.rate ?? 1;
        const p0 = env.owner.transform.position;
        const p1 = target!;
        const distance = Laya.Vector3.distanceXZ(p1, p0);

        if (distance <= 0.01) {
            return "success";
        }
        const movement = env.owner.getComponent(PveSvrMovementComponent);
        if (!movement) {
            return "failure";
        }
        if (
            !cacheSpeed ||
            movement.updateType !== MovementUpdateType.TARGET_POSITION ||
            movement.speed === 0
        ) {
            env.context.moveTo(env.owner, p1, speed);
        }

        return node.yield(env, speed);
    }

    override get descriptor(): NodeDef {
        return {
            name: "FlyToPos",
            type: "Action",
            desc: "飞到坐标点",
            args: [
                { name: "x", type: "int?", desc: "x" },
                { name: "y", type: "int?", desc: "y" },
                { name: "rate", type: "float?", desc: "速率" },
            ],
            input: ["坐标"],
            doc: `
                + 优先输入变量`,
        };
    }
}
