/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIImageBar } from "../../../core/ui/UIImageBar";

/**
 * resources/prefab/traincamp/PowerTalentItem.lh
 */
export class PowerTalentItemUIBase extends Laya.Box {
    public imgBarBg!: Laya.Image;
    public imgBar!: UIImageBar;
    public boxIcon!: Laya.Box;
    public imgBg!: Laya.Image;
    public imgTalent!: Laya.Image;
    public imgUp!: Laya.Image;
}
