import { Mediator } from "../../core/ui-mediator";
import { UIAllianceInvited } from "./ui-runtime/UIAllianceInvited";

const { regClass, property } = Laya;

@regClass('VxbqnfLiR8eXjnHRNesNlw')
export class UIAllianceInvitedMediator extends Mediator {
    declare owner: UIAllianceInvited;

    override onAwake(): void {
        super.onAwake();
    }
}
