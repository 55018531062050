/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/chest-box/sub/ChestBoxProbabilityTips.lh
 */
export class ChestBoxProbabilityTipsBase extends Laya.Box {
    public lab_tips!: Laya.Label;
}

