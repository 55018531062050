export class UIAdaptManager {
    public designWidth: number = 750;
    public designHeight: number = 1334;

    /** 当前渲染宽度 */
    public renderW: number = 750;
    /** 当前渲染高度 */
    public renderH: number = 1334;
    /** 原始宽度 */
    public rawWidth: number = 750;
    /** 原始高度 */
    public rawHeight: number = 1334;
    /** 当前适配缩放 */
    public adaptScale: number = 1;

    public init(): void {
        //浏览器或屏幕大小改变时
        // Laya.Browser.window.addEventListener("resize", this.adaptScreenOnPhone.bind(this));
        // this.adaptScreenOnPhone();
    }

    private adaptScreenOnPhone(): void {
        const width = Math.min(Laya.Browser.width, Laya.Browser.height);
        const height = Math.max(Laya.Browser.width, Laya.Browser.height);
        this.renderW = width;
        this.renderH = height;
        const scaleW = width / this.designWidth;
        const scaleH = height / this.designHeight;
        this.adaptScale = Math.min(scaleW, scaleH);

        this.rawWidth = width / this.adaptScale;
        this.rawHeight = height / this.adaptScale;
        Laya.stage.designWidth = this.rawWidth;
        Laya.stage.designHeight = this.rawHeight;

        // console.log("--------------------------------------------------------");
        // console.log("renderW:" + this.renderW);
        // console.log("renderH:" + this.renderH);
        // console.log("rawWidth:" + this.rawWidth);
        // console.log("rawHeight:" + this.rawHeight);
        // console.log("adaptScale:" + this.adaptScale);
    }
}
