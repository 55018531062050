import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { Reward } from "../../def/table";
import { ImageRef } from "../../misc/ImageRef";
import { RewardCostUtil } from "../../misc/reward-cost-util";
import { SystemEvent } from "../../misc/system-event";
import { AttrUpGrade } from "../common/ui-runtime/attribute/AttrUpGrade";
import { UILeaderSKinUpgrade } from "./ui-runtime/UILeaderSKinUpgrade";

const { regClass, property } = Laya;

@regClass('aFBHFag4RPWVjAFbs-R7Dg')
export class UILeaderSkinUpgradeMediator extends Mediator {
    //declare owner : Laya.Sprite3D;
    declare owner: UILeaderSKinUpgrade;

    @property(String)
    public text: string = "";

    //组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
    override onAwake(): void {
        super.onAwake();
        this.initUIEvent();
        this.initPanel();
    }

    private initUIEvent() {
        this.owner.bgwin.setTitle("幻化升级");
        this.owner.imgMask.on(Laya.Event.CLICK, this.owner, this.owner.destroy);
        this.owner.btnUpgrade.on(Laya.Event.CLICK, this, this.onClickUpgrade);
        this.owner.listAtt.renderHandler = new Laya.Handler(this, this.onAttrRender);

        this.$(app).on(SystemEvent.LEADERSKIN.LEADERSKINUPDATE, this.initPanel, this);
    }

    private onAttrRender(item: AttrUpGrade, index: number) {
        const attrData = item.dataSource;
        item.onRender(attrData, index);
    }

    private onClickUpgrade() {
        const skinId = this.owner.skinId;
        const loData = app.service.leaderSkin.leaderSkinLoMap.get(skinId);
        if (!loData) {
            return;
        }
        const skinData = app.service.leaderSkin.getSkinDataById(loData.id);
        const lv = skinData!.lv;
        const count = loData.getUpgradeCount(lv);
        const reward: Reward[] = [{ id: loData.skinCostItemId, num: count }];

        if (RewardCostUtil.checkEnoughCost(reward)) {
            app.service.leaderSkin.requestUpgradeLv(loData.id);
        } else {
            app.ui.toast("幻化升级材料不足");
        }
    }

    public initPanel() {
        const skinId = this.owner.skinId;
        const loData = app.service.leaderSkin.leaderSkinLoMap.get(skinId);
        if (!loData) {
            return;
        }
        if (loData.checkIsMax()) {
            this.owner.destroy();
            return;
        }
        const item = this.owner.leaderSkinItem;
        const lv = app.service.leaderSkin.getSkinLevelById(loData.id);
        item.lablevel.text = `${lv}级`;
        const skinData = app.service.leaderSkin.getSkinDataById(loData.id);
        item.imgSkin.visible = skinData !== undefined;
        const texturePaths = item.imgBg.getComponent(ImageRef)!.texturePaths;
        item.imgBg.skin = texturePaths[loData.quality];
        item.imgRedpoint.visible = false;
        const midRes = loData.getHeroRes().midRes;
        item.imgIcon.skin = midRes;

        const count = loData.getUpgradeCount(lv);
        const owerCount = app.service.bag.itemVoMap.getItemAmount(loData.skinCostItemId);
        item.imgBar.value = owerCount / count;
        item.labBar.text = `${owerCount} / ${count}`;
        item.boxTroop.visible = false;
        this.updateAttr();
    }

    public updateAttr() {
        const skinId = this.owner.skinId;
        const loData = app.service.leaderSkin.leaderSkinLoMap.get(skinId);
        if (!loData) {
            return;
        }
        const attrList = loData.getCurAndNextAttrList();
        this.owner.listAtt.array = attrList;
    }

    //组件被启用后执行，例如节点被添加到舞台后
    //onEnable(): void {}

    //组件被禁用时执行，例如从节点从舞台移除后
    //onDisable(): void {}

    //第一次执行update之前执行，只会执行一次
    //onStart(): void {}

    //手动调用节点销毁时执行
    //onDestroy(): void {}

    //每帧更新时执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onUpdate(): void {}

    //每帧更新时执行，在update之后执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onLateUpdate(): void {}

    //鼠标点击后执行。与交互相关的还有onMouseDown等十多个函数，具体请参阅文档。
    //onMouseClick(): void {}
}
