/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIImageBar } from "../../../../core/ui/UIImageBar";

/**
 * resources/prefab/battle/pvp-ui/troop-picker-item.lh
 */
export class TroopPickItemUIBase extends Laya.Box {
    public imgTroopState!: Laya.Image;
    public troopHp!: UIImageBar;
    public txtTroopName!: Laya.Text;
    public txtTroopHp!: Laya.Text;
}
