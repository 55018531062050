/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { PlotDialogueMsgUI } from "./PlotDialogueMsgUI";

/**
 * resources/prefab/plot/plot_dialogue_win.lh
 */
export class PlotDialogueWinUIBase extends Laya.Dialog {
    public contentBox!: Laya.Box;
    public img_role!: Laya.Image;
    public plot_dialogue_msg!: PlotDialogueMsgUI;
    public alphaButton!: Laya.Button;
}

