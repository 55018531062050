import { BattleSkillRow } from "../../../def/table";
import { SkillActiveUIBase } from "./SkillActiveUI.generated";

const { regClass } = Laya;

@regClass('bSa0SF1pSia9571Xv9NRAQ')
export class SkillActiveUI extends SkillActiveUIBase {
    public data!: BattleSkillRow;

    override open(closeOther?: boolean, param?: BattleSkillRow): void {
        this.data = param!;
        super.open(closeOther, param);
    }
}
