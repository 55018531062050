/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIList } from "../../../../core/ui/UIList";
import { BaseRoundWindowBg } from "../../../common/ui-runtime/dialog/BaseRoundWindowBg";

/**
 * resources/prefab/character/mat/UICharacterSelectMat.lh
 */
export class UICharacterSelectMatBase extends Laya.Dialog {
    public base_round_window_bg!: BaseRoundWindowBg;
    public listSelect!: UIList;
    public lblRequire!: Laya.Label;
    public btnConfirm!: Laya.Button;
}
