const { regClass } = Laya;
import { app } from "../../../app";
import proto from "../../../def/auto/proto";
import { IconUI } from "../../../misc/ui-runtime/IconUI";
import { UIRewardBase } from "./UIReward.generated";

@regClass('9JbgVeW-Qkm4KW1CnI_wSg')
export class UIReward extends UIRewardBase {
    openData: proto.bag.ItemReward[] = [];

    override open(closeOther?: boolean | undefined, param?: any): void {
        this.openData = param;

        super.open(closeOther, param);
    }

    override onAwake(): void {
        super.onAwake();
        this.initUIEvent();
    }

    private initUIEvent() {
        this.listItem.renderHandler = new Laya.Handler(this, this.onListRender);
        this.imgMask.on(Laya.Event.CLICK, this, this.close);
        this._updateList();
    }

    onListRender(cell: IconUI, index: number) {
        const itemReward = cell.dataSource as proto.bag.ItemReward;
        const itemLo = app.service.bag.itemLoMap.get(itemReward.id);
        cell.updateGoods(itemLo);
        cell.itemNumber = itemReward.num.toString();
    }

    private _updateList() {
        const arrayData: proto.bag.ItemReward[] = this.openData;
        this.listItem.array = arrayData;
    }
}
