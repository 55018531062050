import { app } from "../../../app";
import { ChestRow } from "../../../def/table";
import { Vo } from "../../../misc/vo/Vo";
import { BagService } from "../../bag/BagService";
import { ItemLo } from "../../bag/vo/ItemLo";

export abstract class ChestBoxVoBase<D> extends Vo<ChestRow, D> {}

export class ChestBoxVo extends ChestBoxVoBase<never> {
    override clone(): Vo<ChestRow, never> {
        return new ChestBoxVo(this._config);
    }

    override get key(): number {
        return this._config.id;
    }

    public get probabilityTips(): string {
        return this._config.desc;
    }

    public get itemLo(): ItemLo {
        return app.service.bag.itemLoMap.get(this.key);
    }

    /** 当前拥有的宝箱数量 */
    public get amount(): number {
        return BagService.getItemAmountByItemCfgId(this.key);
    }

    public get isSelected(): boolean {
        const currentChooseChestBox = app.service.chest.currentChooseChestBox;
        if (currentChooseChestBox && currentChooseChestBox === this) {
            return true;
        }
        return false;
    }
}
