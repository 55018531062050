import { app } from "../../app";
import { Service } from "../../core/service";
import proto from "../../def/auto/proto";
import { errcode, opcode } from "../../def/auto/protocol";

export class AllianceService extends Service {
    allianceInfo?: proto.alliance.AllianceInfo | null;
    commonInfo?: proto.alliance.CommonInfo | null;

    override onCreate(): void {
        this.handle(opcode.alliance.s2c_load, this._onLoad);
        this.handle(opcode.alliance.s2c_join_system_alliance, this._onJoinSystemAlliance);
    }

    override onStartInit(): void {}

    override onDestroy(): void {}

    private _onLoad(data: proto.alliance.s2c_load) {
        if (data.err === errcode.OK) {
            this.allianceInfo = data.info;
            this.commonInfo = data.commonInfo;
        }
    }

    private _onJoinSystemAlliance(data: proto.alliance.s2c_join_system_alliance) {
        if (data.err === errcode.OK) {
            this.allianceInfo = data.info;
        }
    }

    // --------------------------------------------------------------------------
    // RPC call
    // --------------------------------------------------------------------------
    async load() {
        return await app.service.network.call(
            proto.alliance.c2s_load.create(),
            proto.alliance.s2c_load
        );
    }

    /** 加入系统联盟 */
    async requestJoinSystemAlliance() {
        return await app.service.network.call(
            proto.alliance.c2s_join_system_alliance.create(),
            proto.alliance.s2c_join_system_alliance
        );
    }
}
