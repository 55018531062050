import { ItemVo } from "../vo/ItemVo";
import { UIItemComposeBase } from "./UIItemCompose.generated";

const { regClass } = Laya;

@regClass('vdYp4V7JSge0W3DyO1NL2g')
export class UIItemCompose extends UIItemComposeBase {
    public itemData!: ItemVo;

    override open(closeOther?: boolean | undefined, param?: ItemVo): void {
        this.itemData = param!;
        super.open(closeOther, param);
    }
}
