import { app } from "../../../../../app";
import { Callback } from "../../../../../core/dispatcher";
import * as ecs from "../../../../../core/ecs";
import { Pool } from "../../../../../core/pool";
import { SystemEvent } from "../../../../../misc/system-event";
import { SecondOrderDynamics } from "../../../base/SecondOrderDynamics";
import { PveContext } from "../../PveContext";
import { PveCameraComponent } from "../components/PveCameraComponent";
import { PveTransformComponent } from "../components/PveTransformComponent";

export class PveCameraSystem extends ecs.System {
    declare context: PveContext;

    private _ray: Laya.Ray = new Laya.Ray(new Laya.Vector3(), new Laya.Vector3());
    private _rayMat: Laya.Matrix4x4 = new Laya.Matrix4x4();

    /** 剧情需要自定义摄像机追踪的当前位置 */
    private _curCustomFocus: Laya.Vector2 | undefined = undefined;
    /** 剧情需要自定义摄像机追踪的目的地位置 */
    private _targetCustomPos: Laya.Vector2 | undefined = undefined;
    /** 程序化动画 */
    private _sod: SecondOrderDynamics | undefined;

    override onCreate(): void {
        this._on(SystemEvent.PVE.CAMERA_MOVE_TO, this.cameraMoveTo);
        this._on(SystemEvent.PVE.RESET_CAMERA_TO_HERO, this.resetCameraToHero);
    }

    private _on(event: string, callback: Callback) {
        this.context.$(app).on(event, callback, this);
    }

    override update(dt: number): void {
        const camera = this.ecs.getSingletonComponent(PveCameraComponent);

        if (this.context.ctrlHeroEid) {
            const transform = this.ecs.getComponent(
                this.context.ctrlHeroEid,
                PveTransformComponent
            );
            if (transform) {
                if (this._curCustomFocus) {
                    camera.focus.y = transform.position.y;
                } else {
                    camera.focus.cloneFrom(transform.position);
                }
            }
        }

        // let isArrive: boolean = false;
        if (this._sod && this._curCustomFocus && this._targetCustomPos) {
            const newPos = this._sod.update(dt, this._targetCustomPos.x, this._targetCustomPos.y);
            // const dis = Laya.Vector2.distance(newPos, this._curCustomFocus);
            // if (dis < 0.001) {
            //     isArrive = true;
            // }
            this._curCustomFocus.x = newPos.x;
            this._curCustomFocus.y = newPos.y;
        }

        if (this._curCustomFocus) {
            camera.focus.x = this._curCustomFocus.x;
            camera.focus.z = this._curCustomFocus.y;

            // 判断是否到达自定义目的地
            // if (isArrive) {
            //     Pool.free(this._sod);
            //     Pool.free(this._curCustomFocus);
            //     Pool.free(this._targetCustomPos);
            //     this._sod = undefined;
            //     this._curCustomFocus = undefined;
            //     this._targetCustomPos = undefined;
            // }
        }

        const cameraTransform = this.context.cameraNode.transform;
        const ray = this._ray;
        const rayMat = this._rayMat;
        cameraTransform.position.cloneTo(ray.origin);
        ray.direction.set(0, 0, -1);
        Laya.Matrix4x4.createRotationQuaternion(cameraTransform.rotation, rayMat);
        Laya.Vector3.transformCoordinate(ray.direction, rayMat, ray.direction);
        ray.direction.normalize();

        // 光从相机原点出发到地面所需时间
        const t = -ray.origin.y / ray.direction.y;
        ray.direction.scale(t, ray.direction);
        ray.origin.vadd(ray.direction, ray.origin);
        camera.focus.vsub(ray.origin, ray.origin);
        ray.origin.y = 0;
        ray.origin.vadd(cameraTransform.position, ray.origin);
        cameraTransform.position = ray.origin;
    }

    /** 摄像机移动到指定位置 */
    private cameraMoveTo(toX: number, toZ: number): void {
        if (toX === 0 && toZ === 0) {
            // ZY都是0的情况下是回到英雄位置
            if (!this.context.ctrlHeroEid) {
                return;
            }
            const transform = this.ecs.getComponent(
                this.context.ctrlHeroEid,
                PveTransformComponent
            );
            if (!transform) {
                return;
            }
            toX = transform.position.x;
            toZ = transform.position.z;
        }
        const camera = this.ecs.getSingletonComponent(PveCameraComponent);

        this._targetCustomPos = Pool.obtain(Laya.Vector2);
        this._targetCustomPos.x = toX;
        this._targetCustomPos.y = toZ;

        this._curCustomFocus = Pool.obtain(Laya.Vector2);
        this._curCustomFocus.x = camera.focus.x;
        this._curCustomFocus.y = camera.focus.z;

        if (!this._sod) {
            this._sod = Pool.obtain(SecondOrderDynamics);
        }
        this._sod.init(1, 1, 0, this._curCustomFocus.x, this._curCustomFocus.y);
    }

    private resetCameraToHero(): void {
        Pool.free(this._sod);
        Pool.free(this._curCustomFocus);
        Pool.free(this._targetCustomPos);
        this._sod = undefined;
        this._curCustomFocus = undefined;
        this._targetCustomPos = undefined;
    }
}
