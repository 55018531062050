import { IReusable, Pool } from "../../../../../../core/pool";
import { mainline } from "../../../../../../def/auto/proto";
import { Healthable } from "./PveSvrBaseStateData";

export class PveSvrMonsterStateData implements Healthable, IReusable {
    __unuse(): void {}

    __reuse(): void {
        this.idx = -1;
        this.hp = 0;
        this.maxHp = 0;
        this.pos.x = 0;
        this.pos.y = 0;
        this.rotation = 0;
    }

    idx: number = -1;

    /** 当前血量 */
    hp: number = 0;

    /** 最大血量 */
    maxHp: number = 0;

    /**
     * 位置
     */
    readonly pos: Laya.Vector2 = new Laya.Vector2();

    /** 朝向 */
    rotation: number = 0;

    decode(data: mainline.IMonster): void {
        data.idx !== undefined && (this.idx = data.idx!);
        this.maxHp = data.health?.maxHp ?? 0;
        this.hp = Math.min(this.maxHp, data.health?.hp ?? 0);
        this.pos.x = (data.pos?.x ?? 0) / 10;
        this.pos.y = (data.pos?.z ?? 0) / 10;
        this.rotation = (data.pos?.rotation ?? 0) / 10;
    }

    encode(): mainline.Monster | undefined {
        if (this.hp === this.maxHp) {
            return undefined;
        }

        const data = Pool.obtain(mainline.Monster);
        data.idx = this.idx;
        data.health = data.health ?? Pool.obtain(mainline.Health);
        data.health.hp = Math.clamp(this.hp, 0, this.maxHp) | 0;
        data.health.maxHp = this.maxHp | 0;
        data.pos = data.pos ?? Pool.obtain(mainline.Position);
        data.pos.x = (this.pos.x * 10) | 0;
        data.pos.z = (this.pos.y * 10) | 0;
        const r = ((this.rotation % 360) + 360) % 360; //0-360
        data.pos.rotation = (r * 10) | 0;
        return data;
    }
}
