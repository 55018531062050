/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/battle/pve-ui/building_func/bd_func_level_block.lh
 */
export class BDFuncLevelBlockUIBase extends Laya.Box {
    public img_jiantou!: Laya.Image;
    public leb_level!: Laya.Label;
    public leb_next_level!: Laya.Label;
}

