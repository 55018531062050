import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { PveSvrCreatureComponent } from "../../ecs/components/PveSvrCreatureComponent";
import { SkillTreeEnv } from "../../ecs/components/PveSvrSkillLauncherComponent";
import { AttackType } from "../../utils/skill/DamagePipeline";

interface NodeArgs {
    readonly atk_type: AttackType;
    readonly add?: number;
}

type NodeInput = [number, number?];

const AttackTypeOptions = [
    { name: "普攻", value: AttackType.Normal },
    { name: "技能", value: AttackType.Skill },
    { name: "纯伤", value: AttackType.Pure },
];

export class Hurt extends Process {
    override init(node: Node) {}

    override run(node: Node, env: SkillTreeEnv): Status {
        const [defenderEid] = env.input as NodeInput;
        const defender = env.context.ecs.getComponent(defenderEid!, PveSvrCreatureComponent);
        if (!defender) {
            node.warn(`invalid target eid: ${defenderEid}`);
            return "failure";
        }
        const args = node.args as unknown as NodeArgs;
        const ratio = (args.add ?? 0) / 100;
        const atkType = args.atk_type;
        let damage = 0;
        if (atkType === AttackType.Pure) {
            damage = (env.input as NodeInput)[1] ?? 0;
            if (damage === 0) {
                node.warn(`invalid damage: ${damage}`);
                return "failure";
            }
        }
        env.context.hurt(env.skill, defender, atkType, damage);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "Hurt",
            type: "Action",
            desc: "造成伤害",
            input: ["目标单位", "伤害数值?"],
            args: [
                {
                    name: "atk_type",
                    type: "enum",
                    desc: "伤害类型",
                    options: AttackTypeOptions,
                },
                { name: "add", type: "float?", desc: "伤害加成(百分比)" },
            ],
            doc: `
                + 如果输入目标非法，则返回失败 
                + 当伤害类型为纯伤害时，读取伤害数值，否则忽略。如果伤害数值为0，则返回失败
            `,
        };
    }
}
