const { regClass } = Laya;
import { app } from "../../../app";
import { ui } from "../../../misc/ui";
import { HomeRightMenuBase } from "./HomeRightMenu.generated";

@regClass('lpn_gRK0TVurEAgZMJpvXw')
export class HomeRightMenu extends HomeRightMenuBase {
    override onAwake(): void {
        super.onAwake();
        this._initUIEvent();
    }

    private _initUIEvent() {
        this.btnBag.on(Laya.Event.CLICK, () => {
            app.ui.show(ui.BAG);
            this.visible = false;
        });
        this.btnNotice.on(Laya.Event.CLICK, () => {
            app.ui.show(ui.MAIL);
            this.visible = false;
        });
        this.btnRank.on(Laya.Event.CLICK, () => {});
        this.btnShop.on(Laya.Event.CLICK, () => {
            app.service.shop.load({ shopId: 1 }).then(() => {
                app.ui.show(ui.SHOP);
                this.visible = false;
            });
        });
        this.imgClose.on(Laya.Event.CLICK, () => {
            this.visible = false;
        });
    }
}
