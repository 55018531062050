import { app } from "../../../app";
import { Constructor } from "../../../core/dispatcher";
import { mapInfo } from "../../../def/auto/tilemap_blockinfo";
import { BoardTextureRow } from "../../../def/table";
import { TMKey, TMLayerIndex, TMLayerName, TMLayerNames, TMMapArea } from "./tm-def";
import {
    TMBlockElement,
    TMBuildingElement,
    TMCloudElement,
    TMElement,
    TMEventElement,
    TMGroundElement,
    TMMonsterElement,
    TMNpcElement,
    TMObjectElement,
    TMRiverElement,
    TMRoadElement,
    TMStaticElement,
} from "./tm-element";

export class TMUtil {
    static DEBUG_MODE = false; // 调试模式

    static readonly VISION_WIDTH = 30; // 视野宽度（单位：米）
    static readonly VISION_HEIGHT = 30; // 视野高度（单位：米）
    static readonly VISION_RATIO = 0.65; // 视野半径中心比例位置

    static readonly TILE_WIDTH = 128; // 瓦片宽度（单位：像素）
    static readonly TILE_HEIGHT = 64; // 瓦片高度（单位：像素）

    static readonly inRect = (
        x: number,
        y: number,
        rectX: number,
        rectY: number,
        rectW: number,
        rectH: number
    ) => {
        return rectX <= x && x < rectX + rectW && rectY <= y && y < rectY + rectH;
    };

    static setStaticBoardScaleAndOffset(
        sprite: Laya.Sprite3D,
        texWidth: number,
        texHeight: number
    ) {
        const key: string = "static_" + texWidth + "x" + texHeight;
        this.setBoardScaleAndOffset(sprite, key, texWidth, texHeight);
    }

    static setBoardScaleAndOffset(
        sprite: Laya.Sprite3D,
        cfgKey: string,
        texWidth: number,
        texHeight: number
    ) {
        const offsetCfg: BoardTextureRow | undefined = app.service.table.boardTexture[cfgKey];

        let scale = 1;
        if (offsetCfg && offsetCfg.scale) {
            scale = offsetCfg.scale;
        }
        const imgW = texWidth * scale;
        const imgH = texHeight * scale;
        const scaleX = (imgW / 128) * Math.SQRT2;
        const bi = imgW / imgH;
        const scaleY = scaleX / bi;
        const h = scaleY / 2;
        const rad = Math.toRadian(45);
        const offsetY = Math.sin(rad) * h;

        sprite.transform.localRotationEulerX = -45;
        sprite.transform.localRotationEulerY = 45;
        sprite.transform.localScaleX = scaleX;
        sprite.transform.localScaleY = scaleY;
        sprite.transform.localPositionY = offsetY;

        if (!offsetCfg) {
            const ld_local_v3 = Laya.Vector3._tempVector3;
            ld_local_v3.x = -0.5;
            ld_local_v3.y = -0.5;
            ld_local_v3.z = 0;
            // 左下角的全局坐标
            sprite.transform.localToGlobal(ld_local_v3, ld_local_v3);
            ld_local_v3.x = -ld_local_v3.x - 0.05;
            ld_local_v3.y = 0;
            ld_local_v3.z = -ld_local_v3.z + 0.72;
            sprite.transform.translate(ld_local_v3, false);
            return;
        } else {
            // 设置位置
            const spritePos = sprite.transform.localPosition;
            spritePos.x = offsetCfg?.offset_x ?? 0;
            spritePos.y = offsetY;
            spritePos.z = offsetCfg?.offset_z ?? 0;
            sprite.transform.localPosition = spritePos;
        }
    }

    static layerToCls(layerName: string): Constructor<TMElement> | null {
        switch (layerName) {
            case TMLayerName.Ground:
                return TMGroundElement;
            case TMLayerName.Road:
                return TMRoadElement;
            case TMLayerName.River:
                return TMRiverElement;
            case TMLayerName.Static:
                return TMStaticElement;
            case TMLayerName.Object:
                return TMObjectElement;
            case TMLayerName.Block:
                return TMBlockElement;
            case TMLayerName.Building:
                return TMBuildingElement;
            case TMLayerName.Monster:
                return TMMonsterElement;
            case TMLayerName.Npc:
                return TMNpcElement;
            case TMLayerName.Event:
                return TMEventElement;
            case TMLayerName.Cloud:
                return TMCloudElement;
        }
        return null;
    }

    static encodeKey(key: TMKey): number;

    static encodeKey(layer: TMLayerName, x: number, z: number): number;

    static encodeKey(layer: TMLayerName | TMKey, x?: number, z?: number): number {
        if (typeof layer === "object") {
            x = layer.x;
            z = layer.z;
            layer = layer.layer;
        }
        return (TMLayerIndex[layer] << 24) | (x! << 12) | (z! << 0);
    }

    static decodeKey(key: number): TMKey {
        const index = (key >> 24) & 0xff;
        const x = (key >> 12) & 0xfff;
        const z = (key >> 0) & 0xfff;
        return {
            layer: TMLayerNames[index],
            x: x,
            z: z,
        };
    }

    static convertKey(key: number, layer: TMLayerName) {
        const x = (key >> 12) & 0xfff;
        const z = (key >> 0) & 0xfff;
        return TMUtil.encodeKey(layer, x, z);
    }

    static findAreaByKey(key: number): TMMapArea | undefined {
        const x = (key >> 12) & 0xfff;
        const z = (key >> 0) & 0xfff;
        return TMUtil.findArea(x, z);
    }

    static findArea(x: number, z: number): TMMapArea | undefined {
        const block = mapInfo.find((v) => {
            const [_, bx, by, bw, bh] = v;
            return TMUtil.inRect(x, z, bx, by, bw, bh);
        });
        if (block) {
            return {
                id: block[0],
                x: block[1],
                z: block[2],
                width: block[3],
                height: block[4],
            };
        }
    }
}
