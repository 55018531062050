import * as ecs from "../../../../../core/ecs";
import { TroopState } from "../../../../../def/auto/world";
import { PvpHeadInfoData, PvpHeadInfoUI } from "../../../ui-runtime/pvp/PvpHeadInfoUI";
import { PvpCampStyle } from "../../PVPUtils";

export class PvpHeadInfoComponent extends ecs.Component {
    static readonly DEFAUFT_OFFSET = 70;
    static readonly UPDATE = 1 << 0;

    readonly mounter: Laya.Sprite = new Laya.Sprite();

    res!: string;
    view?: PvpHeadInfoUI | null;

    flag: number = 0;
    data: PvpHeadInfoData = {
        name: "",
        hp: 0,
        maxHp: 1,
        state: TroopState.IDLE,
        style: PvpCampStyle.SELF,
        offset: PvpHeadInfoComponent.DEFAUFT_OFFSET,
    };
}
