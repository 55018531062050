import { IRectangle, MaxRectsBin, MaxRectsPacker, Rectangle } from "maxrects-packer";

export class DyncRT {
    private _rt: Laya.Texture2D;

    get rt(): Laya.Texture2D {
        return this._rt;
    }

    private _maxRect: MaxRectsPacker;

    readonly width: number = 0;
    readonly height: number = 0;

    readonly premultiplyAlpha = true;

    cachedBitmaps: Map<Laya.BaseTexture, any> = new Map();

    // 动态字体纹理，保存字体纹理在RT上的位置
    private dyncFontRects: { [fontKey: string]: Rectangle } = {};

    constructor(width: number = 2048, height: number = 2048) {
        this.width = width || 2048;
        this.height = height || 2048;
        this._rt = this.createRT(this.width, this.height);

        this._maxRect = new MaxRectsPacker(this.width, this.height, 3, {
            smart: false,
        });
    }

    private createRT(width: number, height: number) {
        const rt = new Laya.Texture2D(
            width,
            height,
            Laya.TextureFormat.R8G8B8A8,
            false,
            true,
            this.premultiplyAlpha
        );
        rt.setPixelsData(null!, this.premultiplyAlpha, false);
        return rt;
    }

    checkIfBitmapInCached(bitmap: Laya.BaseTexture) {
        return this.cachedBitmaps.has(bitmap);
    }

    assignBitmapToRT(texture: Laya.Texture): IRectangle | undefined {
        const bitmap = texture.bitmap;
        if (this.cachedBitmaps.has(bitmap)) {
            return this.cachedBitmaps.get(bitmap);
        }

        // 将texture data draw进RT
        let pixData: Uint8Array | null = null;
        try {
            pixData = <Uint8Array>(bitmap as Laya.Texture2D).getPixels();
        } catch (e) {
            // do nothing
        }
        if (!pixData) {
            // 直接读取getPixels，需要先以bitmap构建Texture，再让texture先渲染出来再读取pixelsData
            const nTex = new Laya.Texture(bitmap);
            // Note: 如果直接以texture去getTexturePixels，最多只能获取到texture引用的纹理区域的像素数据，而不是整个bitmap的像素数据
            pixData = nTex.getTexturePixels(0, 0, bitmap.width, bitmap.height);
        }
        if (!pixData) {
            console.warn("cannot get pixel data of texture");
            return undefined;
        }

        const preBinCount = this._maxRect.bins.length;
        const rect = this._maxRect.add(bitmap.width, bitmap.height, pixData);
        const curBinCount = this._maxRect.bins.length;
        if (preBinCount > 0 && curBinCount > preBinCount) {
            console.log("only support one bin");
            this._maxRect.bins.pop();
            return undefined;
        }

        if (!pixData) {
            console.warn("cannot get pixel data of texture");
            return undefined;
        }
        this._rt.setSubPixelsData(
            rect.x,
            rect.y,
            rect.width,
            rect.height,
            pixData,
            0,
            false, //todo: 读取源数据的设置
            this.premultiplyAlpha, //todo: 读取源数据的设置
            false //todo: 读取源数据的设置
        );

        this.cachedBitmaps.set(bitmap, rect);
        return rect;
    }

    /**
     * 清除动态字体纹理，只释放动态字体的配置即可，
     * 1. 清理一次字体动态纹理资源，方式是将所有缓存的字体动态纹理Rects在MaxRect中释放掉。
     * 2. 如果外部保证引用对应纹理的Texture会被全部释放掉，则可以触发重排[bRepack=true]，重置RT上的纹理，更好的利用RT空间。
     *      如果重排后，不擦除RT上的纹理数据，那么下次插入新的纹理时，会直接覆盖掉原有的纹理数据，但是会影响渲染，纹理的边缘会受残留的纹理数据影响。
     *      --note:可以参考Cocos的DynamicAtlas, 插入纹理的时候往外扩展一定的像素，避免边缘受影响。
     * 3. 如果外部无法保证，只需要释放掉MaxRect中的Rects即可，RT上的纹理不会改变。
     * @param bRepack 是否重排RT上的纹理
     * @param bEraseRT 是否擦除RT上的纹理数据, 仅在bRepack=true时有效
     */
    clearDyncFonts(bRepack: boolean = false, bEraseRT: boolean = true) {
        const bin = this._maxRect.bins[0];
        if (!bin) {
            return;
        }
        for (const key in this.dyncFontRects) {
            const rect = this.dyncFontRects[key];
            const idx = bin.rects.indexOf(rect);
            if (idx >= 0) {
                bin.rects.splice(idx, 1);
            } else {
                console.warn(`font ${key} not found in RT`, rect, bin.rects);
            }
        }
        this.dyncFontRects = {};
        if (bRepack) {
            // 重排
            const unpacked = bin.repack();
            if (unpacked) {
                console.error("repack failed"); //理论上是不会失败的
                return;
            }
            if (bEraseRT) {
                // this._rt = this.createRT(this.width, this.height); // 重新获取一张RT

                // 擦除原有RT上的纹理数据
                const emptyData = new Uint8Array(this.width * this.height * 4);
                this._rt.setPixelsData(emptyData, this.premultiplyAlpha, false);
            }

            for (const rect of bin.rects) {
                const pixData = rect.data;
                if (pixData) {
                    this._rt.setSubPixelsData(
                        rect.x,
                        rect.y,
                        rect.width,
                        rect.height,
                        pixData,
                        0,
                        false, //todo: 读取源数据的设置
                        this.premultiplyAlpha, //todo: 读取源数据的设置
                        false //todo: 读取源数据的设置
                    );
                }
            }
        } else {
            // 不重排，只重新计算freeRects即可，主要是为了合并小的freeRects，提高下次插入的效率
            (bin as MaxRectsBin).recalculateFreeRects();
        }
    }

    // 添加动态font字体纹理到RT, 返回纹理在RT中的位置。并在本地缓存纹理资源，方便后续清除
    assignFontImgDataToRT(imgData: ImageData, fontStr: string, charCode: number) {
        const key = `${fontStr}_${charCode}`;
        if (this.dyncFontRects[key]) {
            console.warn(`font ${key} already assigned to RT`);
            return this.dyncFontRects[key];
        }

        const preBinCount = this._maxRect.bins.length;
        const rect = this._maxRect.add(imgData.width, imgData.height, null);
        const curBinCount = this._maxRect.bins.length;
        if (preBinCount > 0 && curBinCount > preBinCount) {
            console.log("only support one bin");
            this._maxRect.bins.pop();
            return undefined;
        }

        const pixData = imgData.data;
        this._rt.setSubPixelsData(
            rect.x,
            rect.y,
            rect.width,
            rect.height,
            pixData,
            0,
            false, //todo: 读取源数据的设置
            this.premultiplyAlpha, //todo: 读取源数据的设置
            false //todo: 读取源数据的设置
        );
        this.dyncFontRects[key] = rect;
        return rect;
    }
}
