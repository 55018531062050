const { regClass } = Laya;
import { app } from "../../../app";
import { TalentTreeRow } from "../../../def/table";
import { SystemEvent } from "../../../misc/system-event";
import { PowerTalentItemUIBase } from "./PowerTalentItemUI.generated";

@regClass('An407GWgQM2i9K-L2Q0LUQ')
export class PowerTalentItemUI extends PowerTalentItemUIBase {
    private cfgData: TalentTreeRow | null = null;

    public async initPanel(tanlentData: TalentTreeRow) {
        this.cfgData = tanlentData;
        const actStrongId = app.service.trainCamp.getCurActStrongId();
        this.imgBar.value = actStrongId >= tanlentData.id ? 1 : 0;
        this.imgBg.gray = this.imgTalent.gray = actStrongId < tanlentData.id;
        this.imgTalent.skin = app.service.trainCamp.getTalentIcon(tanlentData.effect_id);
        this.imgUp.visible = app.service.trainCamp.checkIsCanActive(tanlentData);
        const isStartPos = app.service.trainCamp.getStrongIsStartPos(tanlentData);
        this.imgBar.visible = this.imgBarBg.visible = !isStartPos;
        this.boxIcon.on(Laya.Event.CLICK, this, this.onCickIcon);
    }

    public updataPanel() {
        const actStrongId = app.service.trainCamp.getCurActStrongId();
        this.imgBar.value = actStrongId >= this.cfgData!.id ? 1 : 0;
        this.imgBg.gray = this.imgTalent.gray = actStrongId < this.cfgData!.id;
        this.imgTalent.skin = app.service.trainCamp.getTalentIcon(this.cfgData!.effect_id);
        this.imgUp.visible = app.service.trainCamp.checkIsCanActive(this.cfgData!);
        const isStartPos = app.service.trainCamp.getStrongIsStartPos(this.cfgData!);
        this.imgBar.visible = this.imgBarBg.visible = !isStartPos;
    }

    private onCickIcon() {
        app.event(SystemEvent.TRAINCAMP.CLICK_ICON, this.cfgData);
    }
}
