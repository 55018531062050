import { app } from "../../../../../app";
import { TreeRunner } from "../../../../../core/behavior3";
import * as ecs from "../../../../../core/ecs";
import { btreeCode } from "../../../../../def/auto/btree-code";
import { PveServer } from "../../PveServer";
import { AddBuffAttribute } from "../../btree/actions/AddBuffAttribute";
import { AddBuffFunctional } from "../../btree/actions/AddBuffFunctional";
import { AddBuffSpecial } from "../../btree/actions/AddBuffSpecial";
import { AdjustStance } from "../../btree/actions/AdjustStance";
import { BackSpawnpoint } from "../../btree/actions/BackSpawnpoint";
import { BackTeam } from "../../btree/actions/BackTeam";
import { CacheVariant } from "../../btree/actions/CacheVariant";
import { CalcHeroDistance } from "../../btree/actions/CalcHeroDistance";
import { CalcSpawnDistance } from "../../btree/actions/CalcSpawnpointDistance";
import { ClearSelfCreatedBuffData } from "../../btree/actions/ClearSelfCreatedBuffData";
import { ClearStance } from "../../btree/actions/ClearStance";
import { Collect } from "../../btree/actions/Collect";
import { CreateBullet } from "../../btree/actions/CreateBullet";
import { CreateSkillWarning } from "../../btree/actions/CreateSkillWarning";
import { CreateVFX } from "../../btree/actions/CreateVFX";
import { FlyToPos } from "../../btree/actions/FlyToPos";
import { Follow } from "../../btree/actions/Follow";
import { FollowHero } from "../../btree/actions/FollowHero";
import { GetAtkTarget } from "../../btree/actions/GetAtkTarget";
import { GetBuffArgs } from "../../btree/actions/GetBuffArgs";
import { GetBuffCreator } from "../../btree/actions/GetBuffCreator";
import { GetExeclData } from "../../btree/actions/GetExeclData";
import { GetHp } from "../../btree/actions/GetHp";
import { GetInputIndex } from "../../btree/actions/GetInputIndex";
import { GetInputValueByKey } from "../../btree/actions/GetInputValueByKey";
import { GetLeader } from "../../btree/actions/GetLeader";
import { GetMemberByStation } from "../../btree/actions/GetMemberByStation";
import { GetOwner } from "../../btree/actions/GetOwner";
import { GetOwnerTroop } from "../../btree/actions/GetOwnerTroop";
import { GetPos } from "../../btree/actions/GetPos";
import { GetRandomIndex } from "../../btree/actions/GetRandomIndex";
import { GetRot } from "../../btree/actions/GetRot";
import { GetSkillArgs } from "../../btree/actions/GetSkillArgs";
import { GetSkillRadius } from "../../btree/actions/GetSkillRadius";
import { GetSkillTarget } from "../../btree/actions/GetSkillTarget";
import { GetSoldierGroup } from "../../btree/actions/GetSoldierGroup";
import { GetSoldierGroupStation } from "../../btree/actions/GetSoldierGroupStation";
import { Heal } from "../../btree/actions/Heal";
import { HitWallFindPath } from "../../btree/actions/HitWallFindPath";
import { Hurt } from "../../btree/actions/Hurt";
import { Listen } from "../../btree/actions/Listen";
import { MoveForward } from "../../btree/actions/MoveForward";
import { MoveStop } from "../../btree/actions/MoveStop";
import { MoveToAtkPos } from "../../btree/actions/MoveToAtkPos";
import { MoveToPos } from "../../btree/actions/MoveToPos";
import { NumberSign } from "../../btree/actions/NumberSign";
import { PlayAnim } from "../../btree/actions/PlayAnim";
import { RemoveSelfCreatedBuff } from "../../btree/actions/RemoveSelfCreatedBuff";
import { RemoveVFX } from "../../btree/actions/RemoveVFX";
import { SetAtkTarget } from "../../btree/actions/SetAtkTarget";
import { SetStance } from "../../btree/actions/SetStance";
import { TowardToTarget } from "../../btree/actions/TowardToTarget";
import { TryLaunchBuff } from "../../btree/actions/TryLaunchBuff";
import { TryLaunchSkill } from "../../btree/actions/TryLaunchSkill";
import { CheckDistance } from "../../btree/conditions/CheckDistance";
import { CheckInterval } from "../../btree/conditions/CheckInterval";
import { CheckProbability } from "../../btree/conditions/CheckProbability";
import { FilterTarget } from "../../btree/conditions/FilterTarget";
import { FindOneTarget } from "../../btree/conditions/FindOneTarget";
import { FindTargets } from "../../btree/conditions/FindTargets";
import { FindTargetsByAttr } from "../../btree/conditions/FindTargetsByAttr";
import { FindVaildableCollectionTarget } from "../../btree/conditions/FindVaildableCollectionTarget";
import { GetFightingEnemies } from "../../btree/conditions/GetFightingEnemies";
import { GetFightingEnemyTroops } from "../../btree/conditions/GetFightingEnemyTroops";
import { GetTargetsInShape } from "../../btree/conditions/GetTargetsInShape";
import { IsFreeStance } from "../../btree/conditions/IsFreeStance";
import { IsReachPos } from "../../btree/conditions/IsReachPos";
import { IsSameTroop } from "../../btree/conditions/IsSameTroop";
import { IsTargetDie } from "../../btree/conditions/IsTargetDie";
import { IsTargetFighting } from "../../btree/conditions/IsTargetFighting";
import { SkillSystemUtils } from "../../utils/skill/SkillSystemUtils";
import { AiTreeEnv, PveSvrAiComponent } from "../components/PveSvrAiComponent";
import { PveSvrElementComponent } from "../components/PveSvrElementComponent";
import { PveSvrSkillLauncherComponent } from "../components/PveSvrSkillLauncherComponent";

export class PveSvrAiSystem extends ecs.System {
    declare context: PveServer;

    static readonly TICK = 0.1;

    override onCreate() {
        this.context.registerProcess(AdjustStance);
        this.context.registerProcess(BackSpawnpoint);
        this.context.registerProcess(BackTeam);
        this.context.registerProcess(CalcHeroDistance);
        this.context.registerProcess(CalcSpawnDistance);
        this.context.registerProcess(ClearStance);
        this.context.registerProcess(Collect);
        this.context.registerProcess(CreateBullet);
        this.context.registerProcess(CreateSkillWarning);
        this.context.registerProcess(CreateVFX);
        this.context.registerProcess(FilterTarget);
        this.context.registerProcess(FindOneTarget);
        this.context.registerProcess(FindTargets);
        this.context.registerProcess(FindTargetsByAttr);
        this.context.registerProcess(FindVaildableCollectionTarget);
        this.context.registerProcess(FlyToPos);
        this.context.registerProcess(Follow);
        this.context.registerProcess(FollowHero);
        this.context.registerProcess(GetExeclData);
        this.context.registerProcess(GetOwner);
        this.context.registerProcess(GetOwnerTroop);
        this.context.registerProcess(GetLeader);
        this.context.registerProcess(GetPos);
        this.context.registerProcess(GetHp);
        this.context.registerProcess(GetRot);
        this.context.registerProcess(GetSkillTarget);
        this.context.registerProcess(GetTargetsInShape);
        this.context.registerProcess(GetFightingEnemyTroops);
        this.context.registerProcess(GetFightingEnemies);
        this.context.registerProcess(Heal);
        this.context.registerProcess(HitWallFindPath);
        this.context.registerProcess(Hurt);
        this.context.registerProcess(IsFreeStance);
        this.context.registerProcess(IsReachPos);
        this.context.registerProcess(IsTargetDie);
        this.context.registerProcess(IsTargetFighting);
        this.context.registerProcess(MoveForward);
        this.context.registerProcess(MoveStop);
        this.context.registerProcess(MoveToAtkPos);
        this.context.registerProcess(MoveToPos);
        this.context.registerProcess(PlayAnim);
        this.context.registerProcess(CheckProbability);
        this.context.registerProcess(NumberSign);
        this.context.registerProcess(CheckInterval);
        this.context.registerProcess(CheckDistance);
        this.context.registerProcess(RemoveVFX);
        this.context.registerProcess(GetAtkTarget);
        this.context.registerProcess(SetAtkTarget);
        this.context.registerProcess(SetStance);
        this.context.registerProcess(TowardToTarget);
        this.context.registerProcess(TryLaunchSkill);
        this.context.registerProcess(GetMemberByStation);
        this.context.registerProcess(GetRandomIndex);
        this.context.registerProcess(GetInputIndex);
        this.context.registerProcess(GetInputValueByKey);
        this.context.registerProcess(IsSameTroop);
        this.context.registerProcess(AddBuffAttribute);
        this.context.registerProcess(AddBuffFunctional);
        this.context.registerProcess(AddBuffSpecial);
        this.context.registerProcess(GetSkillArgs);
        this.context.registerProcess(GetSkillRadius);
        this.context.registerProcess(TryLaunchBuff);
        this.context.registerProcess(RemoveSelfCreatedBuff);
        this.context.registerProcess(ClearSelfCreatedBuffData);
        this.context.registerProcess(GetBuffCreator);
        this.context.registerProcess(GetSoldierGroup);
        this.context.registerProcess(GetSoldierGroupStation);
        this.context.registerProcess(Listen);
        this.context.registerProcess(GetBuffArgs);
        this.context.registerProcess(CacheVariant);

        btreeCode.forEach((value) => this.context.registerCode(value.code, value.evaluator));

        this.registerHandler(
            PveSvrAiComponent,
            this._onAddPveSvrAiComponent,
            this._onDelPveSvrAiComponent
        );
    }

    override update(dt: number): void {
        const time = this.context.time;
        this.ecs.getComponents(PveSvrAiComponent).forEach((ai) => {
            if (!ai.checker()) {
                return;
            }
            if (time - ai.lastUpdate > ai.tick) {
                const launcher = ai.getComponent(PveSvrSkillLauncherComponent);
                if (launcher) {
                    for (const idx in launcher.atkSkills) {
                        const v = launcher.atkSkills[idx];
                        if (v.running) {
                            ai.lastUpdate = time;
                            return;
                        }
                    }
                    for (const idx in launcher.skills) {
                        const v = launcher.skills[idx];
                        if (v.running) {
                            ai.lastUpdate = time;
                            return;
                        }
                    }
                }
                const connected = app.service.network.connected;
                const showPlotTheatreing = app.service.plotTheatre.showPlotTheatreing;
                const isImprisonment = SkillSystemUtils.isImprisonment(this.context, ai.eid);
                if (ai.active && ai.tree && !isImprisonment && connected && !showPlotTheatreing) {
                    ai.tree.run();
                    ai.lastUpdate = time;
                }
            }
        });
    }

    private async _onAddPveSvrAiComponent(ai: PveSvrAiComponent) {
        const checker = ai.checker;
        const tree = await this.context.loadAiTree(ai.btree);
        if (!checker()) {
            return;
        }
        ai.tree = new TreeRunner<AiTreeEnv>(
            new AiTreeEnv(this.context, ai.getComponent(PveSvrElementComponent)!),
            tree
        );

        ai.tick = PveSvrAiSystem.TICK;
    }

    private _onDelPveSvrAiComponent(component: PveSvrAiComponent): void {
        const ai = component;
        ai.tree?.clear();
        ai.tree = null;
    }
}
