import * as ecs from "../../../../../core/ecs";
import { Formation } from "../../../../../def/table";
import { PveSvrElementComponent } from "./PveSvrElementComponent";

// 作为独立的Entity，成为单独的部队概念，不再依附于主角
export class PveSvrTroopComponent extends ecs.Component {
    static getMonsterIndex(comp: PveSvrTroopComponent, eid: number): number | undefined {
        for (const key in comp.members) {
            if (comp.members[key] === eid) {
                return Number(key);
            }
        }
    }

    override get dependencies() {
        return [PveSvrElementComponent];
    }

    get eleComp() {
        return this.getComponent(PveSvrElementComponent)!;
    }

    isSelf: boolean = false; // 是否是自己的部队

    leaderEid: number = 0;

    formation!: Readonly<Formation>;

    readonly members: { [index: number]: number } = {}; //eids
    readonly truckEids: { [etype: number]: number } = {};
}
