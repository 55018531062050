const { regClass } = Laya;
import { ItemVo } from "../vo/ItemVo";
import { UIItemUseSelectBase } from "./UIItemUseSelect.generated";

@regClass('TKyZR0SeRLW_b1jC6BFNxQ')
export class UIItemUseSelect extends UIItemUseSelectBase {
    public itemData!: ItemVo;

    override open(closeOther?: boolean, param?: ItemVo): void {
        this.itemData = param!;
        super.open(closeOther, param);
    }
}
