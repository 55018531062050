/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/map-transfer/area-progress-enter-btn.lh
 */
export class AreaProgressEnterBtnUIBase extends Laya.Box {
    public mainBtn!: Laya.Button;
    public img_gift!: Laya.Image;
}

