import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { SystemEvent } from "../../../../../misc/system-event";
import { PveContext } from "../../PveContext";
export class PveTerritorySystem extends ecs.System {
    declare context: PveContext;

    static onEnterLeaveTerritory(context: PveContext, isEnter: boolean): void {
        app.event(SystemEvent.PVE.ON_ENTER_LEAVE_TERRITORY, isEnter);
    }
}
