import { app } from "../../app";
import { VoMap } from "../../misc/vo/VoMap";
import { PlotTheatreLo } from "./lo/PlotTheatreLo";

export class PlotTheatreLoMap extends VoMap<number, PlotTheatreLo> {
    private _allLo: PlotTheatreLo[] | undefined;

    public getAllLo(): PlotTheatreLo[] {
        if (this._allLo) {
            return this._allLo;
        }
        this._allLo = [];
        const datas = app.service.table.plotTheatre.plot_theatre;
        for (const key in datas) {
            const lo = new PlotTheatreLo(app.service.table.plotTheatre.plot_theatre[key]);
            this.set(lo.key, lo);
            this._allLo.push(lo);
        }
        return this._allLo;
    }
}
