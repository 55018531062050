/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/alliance/AllianceLogItem.lh
 */
export class AllianceLogItemBase extends Laya.Box {
    public bg!: Laya.Image;
    public icon!: Laya.Image;
    public content!: Laya.Label;
    public time!: Laya.Label;
}

