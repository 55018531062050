import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { BattleConf } from "../../../../../def/auto/battle";
import { TroopStation } from "../../../../../def/auto/troop";
import { PveServer } from "../../PveServer";
import { PveSvrUtils } from "../../PveSvrUtils";
import { PveSvrCreatureComponent } from "../components/PveSvrCreatureComponent";
import { PveSvrElementComponent } from "../components/PveSvrElementComponent";
import { PveSvrProbingComponent } from "../components/PveSvrProbingComponent";
import { PveSvrRevivalComponent } from "../components/PveSvrRevivalComponent";
import { PveSvrTransformComponent } from "../components/PveSvrTransformComponent";
import { PveSvrTroopComponent } from "../components/PveSvrTroopComponent";

/** 士兵救援/回血建筑(医疗所)系统 */
export class PveSvrRevivalSystem extends ecs.System {
    /** 士兵重生间隔 */
    private static readonly REBORN_DELAY = 1.0;

    /** 血量恢复间隔 */
    private static readonly RECOVER_HP_DELAY = 0.2;

    /** 每次恢复血量百分比 */
    private static readonly RECOVER_HP_PER = 0.04;

    declare context: PveServer;

    private _lastUpdateRebornTime: number = 0;
    private _lastRecoverHpTime: number = 0;

    /** 恢复血量 */
    private _updateRecoveryHp(focusRoleElement: PveSvrElementComponent): void {
        const time = this.context.time;
        if (this._lastRecoverHpTime + PveSvrRevivalSystem.RECOVER_HP_DELAY > time) {
            return;
        }
        this._lastRecoverHpTime = time;
        // 当前医疗室存在救助的英雄
        this._recoveryHp(focusRoleElement.eid);

        const soldiers = PveSvrRevivalSystem.getTroopSoldiders(this.context, focusRoleElement.eid);
        soldiers.forEach((eid) => {
            this._recoveryHp(eid);
        });
    }

    /** 持续回血 */
    private _recoveryHp(eid: number): void {
        const creatureComp = this.context.ecs.getComponent(eid, PveSvrCreatureComponent);
        if (!creatureComp) return;
        if (creatureComp.hp >= creatureComp.maxHp) return; // 满血
        const addHp = Math.floor(creatureComp.maxHp * PveSvrRevivalSystem.RECOVER_HP_PER);
        let newHp = creatureComp.hp + addHp;
        if (newHp > creatureComp.maxHp) {
            newHp = creatureComp.maxHp;
        }
        const deltaHp = Math.round(newHp - creatureComp.hp);
        creatureComp.hp = newHp;
        if (deltaHp > 0) {
            this.context.sender.recoverHp(eid, {
                hp: creatureComp.hp,
                maxHp: creatureComp.maxHp,
                addHp: deltaHp,
            });
        }
    }

    /** 每秒检查复活士兵 */
    private _updateReborn(
        focusRoleElement: PveSvrElementComponent,
        revivalComp: PveSvrRevivalComponent,
        dt: number
    ): void {
        const time = this.context.time;
        if (this._lastUpdateRebornTime + PveSvrRevivalSystem.REBORN_DELAY > time) {
            return;
        }
        this._lastUpdateRebornTime = time;

        const soldierArr = this.getNeedRebornSoldierArr();
        if (soldierArr.length > 0) {
            const soldierId = soldierArr[0].soldierId;
            const idx = soldierArr[0].idx;
            PveSvrRevivalSystem.rebornSoldier(
                this.context,
                focusRoleElement,
                revivalComp,
                soldierId,
                idx
            );
        }

        //#region
        // 当前医疗室存在救助的英雄
        // const curSoldiderMap = PveSvrRevivalSystem.getCurSoldiderMap(
        //     this.context,
        //     focusRoleElement.eid
        // );

        // 已救援的士兵id的map
        // const soldierArr = PveSvrRevivalSystem.getHasRescusSoldierArr();
        // if (soldierArr.length > 0) {
        //     // 存在已救援的士兵
        //     for (let i = 0; i < soldierArr.length; i++) {
        //         const soldierId = soldierArr[i];
        //         if (!curSoldiderMap.has(soldierId.soldier)) {
        //             // 需要复活这个soldier
        //             PveSvrRevivalSystem.rebornSoldier(
        //                 this.context,
        //                 focusRoleElement,
        //                 revivalComp,
        //                 soldierId.soldier,
        //                 soldierId.pos
        //             );
        //             return;
        //         }
        //     }
        // }
        //#endregion
    }

    /** 获取需要复活的士兵数组 */
    public getNeedRebornSoldierArr(): { soldierId: number; idx: number }[] {
        const stateData = app.service.pve.mlData.pveStateData;
        const ret: { soldierId: number; idx: number }[] = [];
        stateData.soldierGroups.forEach((group) => {
            group.soldiers.forEach((soldier) => {
                if (soldier.hp <= 0) {
                    ret.push({ soldierId: group.tid, idx: soldier.idx });
                }
            });
        });
        return ret;
    }

    // /** 获取当前已经营救的士兵数组 */
    // private static getHasRescusSoldierArr(): { soldier: number; pos: number }[] {
    //     const soldierArr: { soldier: number; pos: number }[] = [];
    //     const pveStateData = app.service.pve.mlData.pveStateData;
    //     for (let i = 0; i < pveStateData.events.length; i++) {
    //         const event = pveStateData.events[i];
    //         const vo = event.eventVo;
    //         if (vo.eventType === PveEventType.RESCUE_SOLDIER && event.isDeath) {
    //             // 已救援
    //             soldierArr.push({ soldier: vo.soldierHeroTid, pos: vo.soldierHeroPos });
    //         }
    //     }
    //     return soldierArr;
    // }

    private static getTroopSoldiders(pveServer: PveServer, heroEid: number): number[] {
        // 我的所有士兵
        const ret: number[] = [];
        const troopEid = PveSvrUtils.getTroopByLeaderEid(pveServer, heroEid)?.eid ?? 0;
        const troopComp = pveServer.ecs.getComponent(troopEid, PveSvrTroopComponent);
        if (!troopComp || !troopComp.isSelf) {
            return ret;
        }
        return Object.values(troopComp.members);
    }

    /** 重生士兵 */
    private static rebornSoldier(
        pveServer: PveServer,
        focusRoleElement: PveSvrElementComponent,
        revivalComp: PveSvrRevivalComponent,
        soldierId: number,
        posIndex: TroopStation
    ): void {
        const transformComp = revivalComp.getComponent(PveSvrTransformComponent)!;

        let px = 0;
        let pz = 0;
        const revivalEle = revivalComp.getComponent(PveSvrElementComponent)!;
        if (revivalEle.tid === PveSvrRevivalComponent.TERRITORY_REVIVAL_TID) {
            px = transformComp.position.x;
            pz = transformComp.position.z + 4;
        } else {
            const offsetCfg = BattleConf.PVE.REVIVAL_BORN_OFFSET;
            px = transformComp.position.x + offsetCfg.x;
            pz = transformComp.position.z + offsetCfg.y;
        }

        pveServer.addSoldierWith(focusRoleElement, posIndex, soldierId, px, pz, 1);
    }

    override update(dt: number): void {
        const heroEleComp = this.context.ecs.getComponent(
            this.context.ctrlHeroEid,
            PveSvrElementComponent
        );
        if (!heroEleComp) {
            return;
        }
        this.ecs.getComponents(PveSvrRevivalComponent).forEach((rescue) => {
            const probingComp = rescue.getComponent(PveSvrProbingComponent);
            if (!probingComp) {
                return;
            }
            if (probingComp.curInRange) {
                // 检查医疗所是否建造完成
                const rescueEle = rescue.getComponent(PveSvrElementComponent)!;
                const pveStateData = app.service.pve.mlData.pveStateData;
                const data = pveStateData.getBuildingByKey(rescueEle.key)!;
                const buildingVo = app.service.pve.buildingVoMap.getVoWithBattleEntityIdAndLevel(
                    rescueEle.data.id,
                    data.level
                )!;
                if (buildingVo.effectiveable) {
                    this._updateReborn(heroEleComp, rescue, dt);
                    this._updateRecoveryHp(heroEleComp);
                }
            }
        });
    }
}
