/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/chest-box/sub/ChestBoxHero.lh
 */
export class ChestBoxHeroBase extends Laya.Box {
    public hero_avatar!: Laya.Image;
    public btn_01!: Laya.Button;
}

