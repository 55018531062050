import { app } from "../../../app";
import { StringUtil } from "../../../core/utils/string-util";
import proto from "../../../def/auto/proto";
import { GeneratedHeroLvTableCol, GeneratedHeroStageRow } from "../../../def/auto/table.generated";
import { AttrRow, BattleSkinRow, HeroRow } from "../../../def/table";
import { res } from "../../../misc/res";
import { Vo } from "../../../misc/vo/Vo";
import { TableUtil } from "../../table/TableUtil";

export type IHeroJob = { job: number; desc: string };
export const HeroJobs = [
    { job: 1, desc: "骑" },
    { job: 2, desc: "步" },
    { job: 3, desc: "枪" },
    { job: 4, desc: "弓" },
    { job: 5, desc: "辅" },
];

export abstract class HeroVoBase<D> extends Vo<HeroRow, D> {
    // 等级先不生效
    private static calcuAttr(
        row: HeroRow,
        attr: "atk" | "hp" | "defence",
        lv: number = 1,
        stage: number = 0
    ): number {
        const attrRow = TableUtil.getRow(app.service.table.attr, { define: attr })!;
        const baseKey = `attr_base${attrRow.id}` as keyof HeroRow;
        const lvKey = `attr_lv${attrRow.id}` as keyof HeroRow;
        const stageKey = `attr_stage${attrRow.id}` as keyof HeroRow;

        const baseValue = row[baseKey] as number;
        const lvId = row[lvKey];
        const stageId = row[stageKey];

        const lvGrowKey = `attr_grow${lvId}` as keyof GeneratedHeroLvTableCol;
        const stageGrowKey = `attr_grow${stageId}` as keyof GeneratedHeroStageRow;

        const lvArr = app.service.table.hero.hero_lv[lvGrowKey];
        const stageArr = app.service.table.hero.hero_stage[stageGrowKey] as number[];

        const lvSum = lvArr.slice(0, lv).reduce((acc, cur) => acc + cur, 0);
        const stageSum = stageArr.slice(0, stage + 1).reduce((acc, cur) => acc + cur, 0);

        const ret = baseValue + lvSum + stageSum;
        return ret;
    }

    private cachedAttr: { [attr: string]: { [lv: number]: number } } = {};

    get job(): number {
        return this._config.job;
    }

    get id() {
        return this._config.id;
    }

    get baseStar() {
        return this._config.base_star;
    }

    get starProgramme() {
        return this._config.star_programme;
    }

    get name(): string {
        return this._config.name;
    }

    get baseQuality(): number {
        const quality = TableUtil.getRow(app.service.table.hero.star_2_quality, {
            id: this.baseStar,
        })!.quality;
        return quality;
    }

    get enable(): boolean {
        // 1代表不在任何地方启用
        return this._config.enable === 1;
    }

    get battleEntity() {
        return this._config.battle_entity;
    }

    get battleEntityRow() {
        const beCfg = app.service.table.battleEntity[this._config.battle_entity];
        return beCfg;
    }

    get battleSkinRow(): BattleSkinRow {
        const skinId = this.battleEntityRow.skin_id!;
        return app.service.table.skin[skinId];
    }

    get iconSmall(): string | undefined {
        const codeName = this.battleSkinRow.code_name;
        return StringUtil.format(res.HERO_ICON_FORMAT.SMALL, codeName);
    }

    get iconMiddle(): string | undefined {
        const codeName = this.battleSkinRow.code_name;
        return StringUtil.format(res.HERO_ICON_FORMAT.MIDDLE, codeName);
    }

    get iconBig(): string | undefined {
        const codeName = this.battleSkinRow.code_name;
        return StringUtil.format(res.HERO_ICON_FORMAT.BIG, codeName);
    }

    get iconFull(): string | undefined {
        const codeName = this.battleSkinRow.code_name;
        return StringUtil.format(res.HERO_ICON_FORMAT.FULL, codeName);
    }

    get atk1() {
        return this._config.atk1;
    }

    get atk2() {
        return this._config.atk2;
    }

    get skill1() {
        return this._config.skill1;
    }

    get skill2() {
        return this._config.skill2;
    }

    get skill3() {
        return this._config.skill3;
    }

    get skill4() {
        return this._config.skill4;
    }

    get maxHp(): number {
        return this._config.attr_base101 ?? 0;
    }

    getConfAtk(lv = 1, stage = 0) {
        return this._getAttr("atk", lv, stage);
    }

    getConfHp(lv = 1, stage = 0) {
        return this._getAttr("hp", lv, stage);
    }

    getConfDefence(lv = 1, stage = 0) {
        return this._getAttr("defence", lv, stage);
    }

    private _getAttr(attr: "atk" | "hp" | "defence", lv = 1, stage = 0) {
        if (this.cachedAttr[attr] && this.cachedAttr[attr][lv]) {
            return this.cachedAttr[attr][lv];
        }
        const ret = HeroVoBase.calcuAttr(this._config, attr, lv);
        if (!this.cachedAttr[attr]) {
            this.cachedAttr[attr] = {};
        }
        this.cachedAttr[attr][lv] = ret;
        return ret;
    }
}

export class HeroVo extends HeroVoBase<proto.hero.Hero> {
    static GetQualityByStar(star: number) {
        const quality = TableUtil.getRow(app.service.table.hero.star_2_quality, {
            id: star,
        })!.quality;
        return quality;
    }

    protected declare _data: Readonly<proto.hero.Hero>;

    override clone(): HeroVo {
        return new HeroVo(this._config, this._data);
    }

    override get key(): number {
        return this._data.uid;
    }

    get uid() {
        return this._data.uid;
    }

    get isLeader(): boolean {
        return !!this._config.leader;
    }

    override get name(): string {
        return this.isLeader ? app.service.user.profileInfo.name : this._config.name;
    }

    get lv(): number {
        return this._data.lv;
    }

    get jobDesc(): string {
        return HeroJobs.find((v) => v.job === this.job)?.desc ?? "";
    }

    get star(): number {
        return this._data.star;
    }

    get quality(): number {
        return HeroVo.GetQualityByStar(this.star);
    }

    get stage(): number {
        return this._data.stage;
    }

    get atk(): number {
        return this.getAttr("atk");
    }

    get defence(): number {
        return this.getAttr("defence");
    }

    get hp(): number {
        return this.getAttr("hp");
    }

    private getAttr(attrKey: string) {
        const attrRow = TableUtil.getRow(app.service.table.attr, <AttrRow>{
            define: attrKey,
        })!;

        return this._data.attrs[attrRow.id];
    }

    // 升阶消耗的角色材料
    get costedHeroMats(): Readonly<proto.hero.HeroMaterial[]> {
        return this._data.heroMaterials as proto.hero.HeroMaterial[];
    }

    // 角色战力
    get power(): number {
        return 0;
    }

    get skills() {
        return this._data.skills as { [key: string]: proto.hero.SkillInfo };
    }
}
