/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/character/mat/CharacterSelectMatItem.lh
 */
export class CharacterSelectMatItemBase extends Laya.Box {
    public imgQuality!: Laya.Image;
    public imgIcon!: Laya.Image;
    public imgMask!: Laya.Image;
    public lblLv!: Laya.Label;
    public imgStar0!: Laya.Image;
    public imgStar1!: Laya.Image;
    public imgStar2!: Laya.Image;
    public imgStar3!: Laya.Image;
    public imgStar4!: Laya.Image;
}

