import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { SystemEvent } from "../../misc/system-event";
import { ui } from "../../misc/ui";
import { UserUI } from "./ui-runtime/UserUI";

const { regClass, property } = Laya;
enum TabMenuColor {
    noSelect = "#fff99d",
    select = "#71b9a4",
}
@regClass('gYmfSZbKSNaCTaO7yyMfQw')
export class UserMediator extends Mediator {
    //组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
    declare owner: UserUI;

    override onAwake(): void {
        super.onAwake();
        this.initEvent();
        this.initServerEvent();
        this.updateInfo();
    }

    initEvent() {
        this.owner.btnCopy.on(Laya.Event.CLICK, this, () => {
            //复制ID
            // const a = new Clipboard();
            // a.readText().then((text: string) => {});
            // a.readText();
            // Laya.Browser.document.body.appendChild("11111");
            // window.document.createElement("textarea");
            navigator.clipboard.writeText(app.service.user.rid.toString() || "");
            // if (navigator.clipboard) {
            //     navigator.clipboard
            //         .read()
            //         .then((data) => {
            //             // 假设我们只关心剪切板中的第一个项目
            //             return data.items[0];
            //         })
            //         .then((item) => {
            //             // 将剪切板的内容转换为文本
            //             return item.getType("text/plain");
            //         })
            //         .then((content) => {
            //             // 打印剪切板的文本内容
            //             console.log(content);
            //         })
            //         .catch((err) => {
            //             // 错误处理
            //             console.error("读取剪切板权限被拒绝:", err);
            //         });
            // } else {
            //     console.log("Clipboard API不可用");
            // }
        });
        this.owner.btnRename.on(Laya.Event.CLICK, () => {
            //打开更名窗口
            app.ui.show(ui.RENAME);
        });
        this.owner.btnUp.on(Laya.Event.CLICK, () => {
            app.ui.show(ui.TASK, { openIdx: 0 });
            //提升
        });
        this.owner.btnSkin.on(Laya.Event.CLICK, () => {
            //装扮
        });
        this.owner.listTabMenu.renderHandler = new Laya.Handler(
            this,
            (node: Laya.Node, index: number) => {
                const label = node.getChildByName("labelName") as Laya.Label;
                label.text = (index + 1).toString();
                label.color =
                    index === this.owner.listTabMenu.selectedIndex
                        ? TabMenuColor.noSelect
                        : TabMenuColor.select;
            }
        );
        this.owner.listTabMenu.selectHandler = new Laya.Handler(this, () => {
            // this._updateTroop();
        });
        const listData = [];
        for (const troop of app.service.troop.troopTeamVoMap.toArray()) {
            if (troop && troop.eid) {
                listData.push(troop.eid);
            }
        }
        this.owner.listTabMenu.array = listData;
        // this._updateTroop();
    }

    private initServerEvent() {
        this.$(app).on(SystemEvent.USER_MONEY_UPDATE, () => {
            this.updateInfo();
        });
    }

    //渲染上阵士兵ListCell
    // private _updateTroopSoldierItem(item: TroopItemUI, data: TroopCellData) {
    //     item.updateInfo(data as TroopCellData);
    // }

    // private _updateTroop() {
    //     const curTroop = app.service.troopNew.troopTeamVoMap.get(
    //         this.owner.listTabMenu.selectedIndex + 1
    //     ) as TroopTeamVo;
    //     this._updateTroopSoldierItem(this.owner.troopItem1, {
    //         soldierId: curTroop.getSoldierIdByPos(TroopStation.FRONT),
    //         soldierPos: TroopStation.FRONT,
    //     });
    //     this._updateTroopSoldierItem(this.owner.troopItem2, {
    //         soldierId: curTroop.getSoldierIdByPos(TroopStation.MIDDLE),
    //         soldierPos: TroopStation.MIDDLE,
    //     });
    //     this._updateTroopSoldierItem(this.owner.troopItem3, {
    //         soldierId: curTroop.getSoldierIdByPos(TroopStation.BACK),
    //         soldierPos: TroopStation.BACK,
    //     });
    // }

    updateInfo() {
        const userInfo = app.service.user.profileInfo;
        this.owner.labelName.text = userInfo.name;
        this.owner.labelId.text = `玩家ID: ${app.service.user.rid}`;
        // this.owner.labelUnionName.text
        this.owner.labelLv.text = userInfo.lv.toString();
        const expRow = app.service.table.role.level[userInfo.lv].upgrade_exp;
        const expCmd = userInfo.exp || 0;
        this.owner.labelBarNum.text = `${expCmd}/${expRow}`;
        this.owner.imgBar.value = expRow / expCmd;
    }
}
