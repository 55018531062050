const { regClass } = Laya;
import { ImageRef } from "../../../../misc/ImageRef";
import { HeroVo } from "../../vo/HeroVo";
import { CharacterSelectMatItemBase } from "./CharacterSelectMatItem.generated";

export interface CharacterSelectMatItemData {
    heroVo: HeroVo;
    selcted: boolean;
}

@regClass('-zwohvcnSi6WQ5qOMmMEBQ')
export class CharacterSelectMatItem extends CharacterSelectMatItemBase {
    override get_dataSource(): CharacterSelectMatItemData {
        return super.get_dataSource();
    }

    onRender(data: CharacterSelectMatItemData, index: number) {
        this.lblLv.text = `LV:${data.heroVo.lv}`;
        this.imgMask.visible = data.selcted;

        const starts = [this.imgStar0, this.imgStar1, this.imgStar2, this.imgStar3, this.imgStar4];
        for (let i = 0; i < starts.length; i++) {
            const star = starts[i];
            star.visible = i < data.heroVo.star;
        }

        const texturepaths = this.imgQuality.getComponent(ImageRef)!.texturePaths;
        const qualitySkin = texturepaths[data.heroVo.quality];
        this.imgQuality.skin = qualitySkin;

        this.imgIcon.skin = data.heroVo.iconSmall ?? "";
    }
}
