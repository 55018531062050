import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { PveSvrUtils } from "../../PveSvrUtils";
import { SkillTreeEnv } from "../../ecs/components/PveSvrSkillLauncherComponent";

type NodeInput = [number[] | number];

interface NodeArgs {
    readonly buff: number;
}

export class RemoveSelfCreatedBuff extends Process {
    override run(node: Node, env: SkillTreeEnv): Status {
        const input = env.input as NodeInput;
        const buffId = (node.args as unknown as NodeArgs).buff;

        const targetEids = input[0];
        const eids = targetEids instanceof Array ? targetEids : [targetEids];
        if (eids.length === 0) {
            console.error("TryLaunchBuff: target is empty");
            return "failure";
        }

        eids.forEach((eid) => {
            PveSvrUtils.removeBuffTree(env.context, buffId, env.skill, eid);
        });
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "RemoveSelfCreatedBuff",
            type: "Action(Skill)" as "Action",
            desc: "删除目标身上生成自本身的buff tree",
            input: ["目标"],
            args: [{ name: "buff", type: "int", desc: "buff id" }],
            doc: `
                + 删除目标身上生成自本身的buff tree
                + 只能在Skill行为树中使用
                + 目标可以是单个或多个`,
        };
    }
}
