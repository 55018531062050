/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIButton } from "../../../core/ui/UIButton";

/**
 * resources/prefab/alliance/UIAllianceApplyList.lh
 */
export class UIAllianceEditInfoBase extends Laya.Box {
    public nameLimit!: Laya.Label;
    public nameInput!: Laya.TextInput;
    public nameError!: Laya.Label;
    public declLimit!: Laya.Label;
    public declInput!: Laya.TextInput;
    public btnEdit!: UIButton;
}

