import { app } from "../../../app";
import { VoMap } from "../../../misc/vo/VoMap";
import { ItemLo } from "./ItemLo";

export class ItemLoMap extends VoMap<number, ItemLo> {
    override get(itemId: number) {
        let lo = super.get(itemId);
        if (!lo) {
            lo = new ItemLo(app.service.table.item.item[itemId]);
            this.set(itemId, lo);
        }
        return lo;
    }

    private _allItemLo: ItemLo[] | undefined;

    public getAllItemLo(): ItemLo[] {
        if (this._allItemLo) {
            return this._allItemLo;
        }
        this._allItemLo = [];
        const items = app.service.table.item.item;
        for (const key in items) {
            const lo = this.get(Number(key));
            this._allItemLo.push(lo);
        }
        return this._allItemLo;
    }
}
