import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrCreatureComponent } from "../../ecs/components/PveSvrCreatureComponent";

// interface NodeArgs {}

// type NodeInput = [number];

export class GetAtkTarget extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const creatureComp = env.owner.getComponent(PveSvrCreatureComponent);
        if (!creatureComp) {
            node.error(`invalid target eid: ${env.owner.eid}, cannot find PveSvrCreatureComponent`);
            return "failure";
        }
        const atkTargetEid = creatureComp.atkTargetEid;
        const atkTargetCreature = env.context.ecs.getComponent(
            atkTargetEid,
            PveSvrCreatureComponent
        );
        if (!atkTargetCreature) {
            return "failure";
        }
        env.output.push(atkTargetEid);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "GetAtkTarget",
            type: "Action",
            desc: "获取攻击目标, 没有攻击目标则返回失败",
            output: ["攻击目标"],
        };
    }
}
