const { regClass } = Laya;

import { app } from "../../../../app";
import { ui } from "../../../../misc/ui";
import { IUpStageMatHeroes, IUpStageResult } from "../../HeroService";
import { UICharacterSelectMatArgs } from "../mat/UICharacterSelectMat";
import { UICharacterUpStageBase } from "./UICharacterUpStage.generated";

export type UICharacterUpStageArgs = {
    uid: number;
};

export interface IUpStageSelectData {
    uid: number;
    readonly selectedUids: number[];
}

@regClass('UuG2a4FnQVCVc3jMztrQVA')
export class UICharacterUpStage extends UICharacterUpStageBase {
    // 角色升阶选择素材的缓存数据，用于在选择素材界面选择素材后返回时，更新界面
    static readonly upStageSelectData: IUpStageSelectData = {
        uid: 0,
        selectedUids: [],
    };

    private _openArgs!: UICharacterUpStageArgs;

    private _upStageResult?: IUpStageResult;
    private _matHeroes!: IUpStageMatHeroes;

    override open(closeOther?: boolean | undefined, param?: any): void {
        this._openArgs = param;

        super.open(closeOther, param);
    }

    override onAwake(): void {
        super.onAwake();
        const heroVo = app.service.hero.heroVoMap.get(this._openArgs.uid);
        if (!heroVo) {
            throw new Error(`heroVo not found: ${this._openArgs.uid}`);
        }

        const ret = app.service.hero.calcuUpStageResult(heroVo);
        this._upStageResult = ret;
        this.lblPreValue0.text = `${heroVo.lv}`;
        this.lblPreValue1.text = `${heroVo.atk}`;
        this.lblPreValue2.text = `${heroVo.hp}`;
        this.lblPreValue3.text = `${heroVo.defence}`;

        this.lblNextValue0.text = `${ret.upLvLimit}`;
        this.lblNextValue1.text = `${heroVo.atk + ret.upAtk}`;
        this.lblNextValue2.text = `${heroVo.hp + ret.upHp}`;
        this.lblNextValue3.text = `${heroVo.defence + ret.upDefence}`;

        console.log("UICharacterUpStage.onAwake", heroVo, ret);

        if (UICharacterUpStage.upStageSelectData.uid !== this._openArgs.uid) {
            UICharacterUpStage.upStageSelectData.uid = this._openArgs.uid;
            UICharacterUpStage.upStageSelectData.selectedUids.length = 0; //重置
        }
        const mat = app.service.hero.getUpStageMatHeroes(this._openArgs.uid);
        this._matHeroes = mat;
        // const needHeroStar = mat.requreHero[0][0];
        const needHeroCount = mat.requireHeroConf[0][1];
        const matHeroes = mat.matHeroes;

        if (UICharacterUpStage.upStageSelectData.selectedUids.length === 0) {
            for (let i = 0; i < needHeroCount; i++) {
                if (i >= matHeroes.length) {
                    break;
                }
                UICharacterUpStage.upStageSelectData.selectedUids.push(matHeroes[i].uid);
            }
        }

        const dispHeroVo =
            UICharacterUpStage.upStageSelectData.selectedUids.length > 0
                ? app.service.hero.heroVoMap.get(
                      UICharacterUpStage.upStageSelectData.selectedUids[0]
                  )!
                : heroVo;
        this.boxSelect.onRender({
            type: "upstage",
            dispHeroVo: dispHeroVo,
            hasHeroCount: UICharacterUpStage.upStageSelectData.selectedUids.length,
            needHeroCount: needHeroCount,
        });
        this.boxSelect.on(Laya.Event.CLICK, this, this.onClickBoxMatItem);

        this.btnUpStage.clickHandler = new Laya.Handler(this, this.onClickBtnUpStage);
    }

    private onClickBoxMatItem() {
        console.log("UICharacterUpStage.onClickBoxMatItem");
        const availableUids = this._matHeroes.matHeroes.map((v) => v.uid);
        if (availableUids.length < this._matHeroes.requireHeroConf[0][1]) {
            app.ui.toast("无适合的角色");
            return;
        }

        const args: UICharacterSelectMatArgs = {
            type: "upstage",
            uid: this._openArgs.uid,
            needCount: this._matHeroes.requireHeroConf[0][1],
            availableUids,
            selectedUids: UICharacterUpStage.upStageSelectData.selectedUids,
        };
        app.ui.show(ui.CHARACTER_SELECT_MAT, args);
    }

    override onClosed(type?: string | undefined): void {
        UICharacterUpStage.upStageSelectData.uid = 0;
        UICharacterUpStage.upStageSelectData.selectedUids.length = 0; //重置
    }

    private onClickBtnUpStage() {
        console.log("UICharacterUpStage.onClickBtnUpStage");

        if (
            UICharacterUpStage.upStageSelectData.selectedUids.length <
            this._matHeroes.requireHeroConf[0][1]
        ) {
            app.ui.toast("升阶材料不足");
            return;
        } else {
            if (
                this._matHeroes.requireHeroConf[0][1] !==
                UICharacterUpStage.upStageSelectData.selectedUids.length
            ) {
                console.error(
                    "升阶材料数量不对",
                    this._matHeroes.requireHeroConf[0][1],
                    UICharacterUpStage.upStageSelectData.selectedUids.length
                );
            }

            app.service.hero.requestUpStage(
                UICharacterUpStage.upStageSelectData.uid,
                UICharacterUpStage.upStageSelectData.selectedUids
            );
            this.close();
        }
    }
}
