const { regClass } = Laya;
import { app } from "../../../../app";
import { SystemEvent } from "../../../../misc/system-event";
import { ChestBoxVo } from "../../vo/ChestBoxVo";
import { ChestBoxBtnItemBase } from "./ChestBoxBtnItem.generated";

@regClass('xit2WaIKRIS0QRA1JbqUBQ')
export class ChestBoxBtnItem extends ChestBoxBtnItemBase {
    private _glowFilter!: Laya.GlowFilter[];

    public get glowFilter(): Laya.GlowFilter[] {
        if (!this._glowFilter) {
            this._glowFilter = [new Laya.GlowFilter("#fff46c", 20, 0, 0)];
        }
        return this._glowFilter;
    }

    private _selected: boolean = false;

    public get selected(): boolean {
        return this._selected;
    }

    public set selected(value: boolean) {
        this._selected = value;
        if (this._selected) {
            this.img_amount_normal.visible = false;
            this.img_amount_sel.visible = true;
            this.img_icon.y = -24;
            this.img_icon.filters = this.glowFilter;
        } else {
            this.img_amount_normal.visible = true;
            this.img_amount_sel.visible = false;
            this.img_icon.y = 4;
            this.img_icon.filters = [];
        }
    }

    override onAwake(): void {
        super.onAwake();
        this.img_icon.onClick(this.onClickImgIcon.bind(this));
    }

    public get data(): ChestBoxVo {
        return this.dataSource as ChestBoxVo;
    }

    public updateShow(): void {
        const d = this.data;
        this.img_icon.skin = d.itemLo.bigIconUrl;
        this.lab_amount.text = `X ${d.amount}`;
        this.selected = d.isSelected;
    }

    private onClickImgIcon(): void {
        // console.log("点击");
        const vo = this.data;
        if (vo.amount > 0) {
            const currentChooseVo = app.service.chest.currentChooseChestBox;
            if (currentChooseVo !== vo) {
                app.service.chest.currentChooseChestBox = vo;
                app.event(SystemEvent.CHEST_BOX.ON_CHOOSE_CHEST_CHANGED, this.data);
            }
        } else {
            app.ui.toast("暂未获得该宝箱!");
        }
    }
}
