import * as ecs from "../../../../../core/ecs";
import { res } from "../../../../../misc/res";
import { BuildConfirmUI } from "../../../ui-runtime/pve/build/BuildConfirmUI";

/**
 * 建造确认UI组件
 */
export class PveUIBuildConfirmComponent extends ecs.Component {
    readonly mounter: Laya.Sprite = new Laya.Sprite();

    view?: BuildConfirmUI | null;

    /** 资源路径 */
    public get res(): string {
        return res.BATTLE_BUILD_CONFIRM;
    }
}
