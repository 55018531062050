/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/battle/pvp-ui/resource-info.lh
 */
export class ResourceInfoUIBase extends Laya.Box {
    public resName!: Laya.Label;
    public resPos!: Laya.Label;
}

