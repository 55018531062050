import * as ecs from "../../../../../core/ecs";
import { IReusable, Pool } from "../../../../../core/pool";
import { PveProbingType } from "../../../../../def/auto/battle";

/**
 * 需要探测玩家进入该实体指定范围内时，需要挂载该组件
 */
@Pool.reusable
export class PveSvrProbingComponent extends ecs.Component implements IReusable {
    curInRange: boolean = false;
    probingRange: number = 0;
    isInTerritory: boolean = false;

    /** 探测类型 */
    probingType: PveProbingType = PveProbingType.BUILDING;

    public set probing_range(val: string) {
        if (val === "TERRITORY") {
            this.probingRange = 0;
            this.isInTerritory = true;
        } else {
            this.probingRange = Number(val);
            this.isInTerritory = false;
        }
    }

    __unuse(): void {
        this.probingRange = 0;
        this.isInTerritory = false;
        this.curInRange = false;
        this.probingType = PveProbingType.BUILDING;
    }

    __reuse(...args: unknown[]): void {}
}
