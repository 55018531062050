/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/battle/pve-defeat/pve-defeat-window.lh
 */
export class PveDefeatWindowUIBase extends Laya.Dialog {
    public btn_reborn!: Laya.Button;
    public btn_giveup!: Laya.Button;
}

