import { app } from "../../app";
import { Callback } from "../../core/dispatcher";
import { Mediator } from "../../core/ui-mediator";
import { Debug } from "../../misc/debug";
import { SystemEvent } from "../../misc/system-event";
import { ui } from "../../misc/ui";
import { ExportNodes } from "../battle/pve-server/ExportNodeDef";
import { TMUtil } from "../battle/tilemap/tm-util";
import { UIGM } from "./ui-runtime/UIGM";

const { regClass, property } = Laya;
interface GmCmdData {
    name: string;
    instruct: string;
}
interface SwitchData {
    name: string;
    func: Callback;
}

const consoleFunc = {
    debug: console.debug,
    log: console.log,
    warn: console.warn,
};

@regClass('pAic0q01RISUE9HfkiUkmg')
export class UIGMMediator extends Mediator {
    declare owner: UIGM;
    listGmData!: GmCmdData[];
    listSwitchData!: SwitchData[];
    isShowStat: boolean = false;

    override onAwake(): void {
        super.onAwake();
        this.owner.bgWin.setTitle("GM面板");
        this._initEvent();
        this.updateList();
    }

    private _initEvent() {
        //-------------------------------------gm指令列表
        this.owner.listGm.mouseHandler = new Laya.Handler(
            this,
            (evn: Laya.Event, index: number) => {
                if (evn.type === Laya.Event.CLICK) {
                    const instruct = this.listGmData[index].instruct;
                    this.owner.textInput.text = instruct;
                    if (instruct.indexOf("add_item") !== -1) {
                        this.onPurchaseBtn();
                    }
                }
            }
        );
        this.owner.listGm.renderHandler = new Laya.Handler(
            this,
            (cell: Laya.Node, index: number) => {
                (cell.getChildByName("Label") as Laya.Label).text = this.listGmData[index].name;
            }
        );
        this.owner.listGm.selectHandler = new Laya.Handler(
            this,
            (evn: Laya.Event, index: number) => {
                if (evn.type === Laya.Event.CLICK) {
                    const instruct = this.listGmData[index].instruct;
                    this.owner.textInput.text = instruct;
                }
            }
        );
        //-------------------------------------gm指令列表

        //-------------------------------------功能开关列表
        this.owner.listSwitch.mouseHandler = new Laya.Handler(
            this,
            (evn: Laya.Event, index: number) => {
                if (evn.type === Laya.Event.CLICK) {
                    this.listSwitchData[index].func(index);
                }
            }
        );
        this.owner.listSwitch.renderHandler = new Laya.Handler(
            this,
            (cell: Laya.Node, index: number) => {
                (cell.getChildByName("Label") as Laya.Label).text = this.listSwitchData[index].name;
            }
        );
        //-------------------------------------功能开关列表

        this.owner.btnUse.on(Laya.Event.CLICK, () => {
            const text = this.owner.textInput.text;
            if (text.startsWith("GM_PVE")) {
                app.event(SystemEvent.PVE.GM_CMD, text);
            } else {
                app.service.gm.requestGM(this.owner.textInput.text);
            }
        });

        this.owner.btnReset.on(Laya.Event.CLICK, () => {
            app.service.gm.requestGM("reset");
        });

        this.owner.tab.selectHandler = new Laya.Handler(this, (index: number) => {
            this.owner.ViewStack.selectedIndex = index;
        });
        this.owner.ViewStack.selectedIndex = 1;

        this.owner.printNode.on(Laya.Event.CLICK, () => {
            const ctx = new ExportNodes();
            const nodeStr = ctx.stringify();
            if (!navigator.clipboard) {
                console.log(nodeStr);
            } else {
                navigator.clipboard.writeText(nodeStr);
                app.ui.toast("已复制到剪贴板");
            }
            ctx.destroy(false);
        });

        this.owner.soldierBtn.on(Laya.Event.CLICK, this, this.addSoldiers);

        this.owner.purchaseBtn.on(Laya.Event.CLICK, this, this.onPurchaseBtn);

        this.owner.btnSkill.on(Laya.Event.CLICK, this, () => {
            app.ui.show(ui.SKILL_MAIN);
        });

        this.owner.btnTrain.on(Laya.Event.CLICK, this, () => {
            app.ui.show(ui.TRAIN_CAMP_MAIN);
        });
    }

    /** 点击零元购 */
    private onPurchaseBtn(): void {
        app.ui.show(ui.GM_PURCHASE);
    }

    async addSoldiers() {
        app.event(SystemEvent.PVE.ON_ADD_DEBUG_SOLDIERS);
    }

    onTabSelect(index: number) {
        this.updateList();
    }

    updateList() {
        this.listGmData = [];

        this.listGmData.push({ name: "加经验", instruct: "add_exp 99" });
        this.listGmData.push({ name: "升级", instruct: "add_lv 99" });
        this.listGmData.push({ name: "增加铜钱", instruct: "add_coin 99" });
        this.listGmData.push({ name: "增加钻石", instruct: "add_diamond 99" });
        this.listGmData.push({ name: "增加体力", instruct: "add_energy 99" });
        this.listGmData.push({ name: "添加道具1:id,2:数量", instruct: "add_item 1 2" });
        this.listGmData.push({ name: "删除道具1:id,2:数量", instruct: "sub_item 1 2" });
        this.listGmData.push({ name: "发送系统邮件", instruct: "sys_mail 101" });
        this.listGmData.push({
            name: "发送自定义邮件",
            instruct: "gm_mail hello 'content xxx' {10101,11},{10102,22}}",
        });
        this.listGmData.push({ name: "修改主城位置1:X,2:Y", instruct: " change_castle_pos 1 2" });

        this.listGmData.push({ name: "PVE恢复满血", instruct: "GM_PVE_ADD_BLOOD" });
        this.listGmData.push({ name: "PVE增加攻击力", instruct: "GM_PVE_ADD_ATK" });
        // this.listGmData.push({ name: "PVE增加小兵", instruct: "GM_PVE_ADD_SOLDER" });

        this.owner.listGm.array = this.listGmData;

        this.listSwitchData = [];
        this.listSwitchData.push({
            name: "统计信息:" + (!this.isShowStat ? "关" : "开"),
            func: (index: number) => {
                this.isShowStat = !this.isShowStat;
                this.isShowStat ? Laya.Stat.show() : Laya.Stat.hide();
                this.listSwitchData[index].name = "统计信息:" + (!this.isShowStat ? "关" : "开");
                this.owner.listSwitch.refresh();
            },
        });
        this.listSwitchData.push({
            name: "地图调试:" + (!TMUtil.DEBUG_MODE ? "关" : "开"),
            func: (index: number) => {
                TMUtil.DEBUG_MODE = !TMUtil.DEBUG_MODE;
                app.event(SystemEvent.TILEMAP_DEBUG_MODE_UPDATE);

                this.listSwitchData[index].name = "地图调试:" + (!TMUtil.DEBUG_MODE ? "关" : "开");
                this.owner.listSwitch.refresh();
            },
        });
        this.listSwitchData.push({
            name: "PVE摇杆:" + (!Debug.PVE_JOYSTICK ? "关" : "开"),
            func: (index: number) => {
                Debug.PVE_JOYSTICK = !Debug.PVE_JOYSTICK;
                app.event(SystemEvent.PVE.JOYSTICK_UPDATE);

                this.listSwitchData[index].name = "PVE摇杆:" + (!Debug.PVE_JOYSTICK ? "关" : "开");
                this.owner.listSwitch.refresh();
            },
        });

        this.listSwitchData.push({
            name: "log打开:" + (!Debug.LOG ? "关" : "开"),
            func: (index: number) => {
                Debug.LOG = !Debug.LOG;
                if (Debug.LOG) {
                    console.log = consoleFunc.log;
                    console.debug = consoleFunc.debug;
                    console.warn = consoleFunc.warn;
                } else {
                    console.log = () => {};
                    console.debug = () => {};
                    console.warn = () => {};
                }

                this.listSwitchData[index].name = "log打开:" + (!Debug.LOG ? "关" : "开");
                this.owner.listSwitch.refresh();
            },
        });

        this.listSwitchData.push({
            name: "测试PVP 10 vs 10",
            func: (index: number) => {
                app.service.gm.requestGM("test_pvp 10 10");
            },
        });

        this.listSwitchData.push({
            name: "测试PVP 20 vs 20",
            func: (index: number) => {
                app.service.gm.requestGM("test_pvp 20 20");
            },
        });

        this.listSwitchData.push({
            name: "测试攻城10v6",
            func: (index: number) => {
                app.service.gm.requestGM("test_siege 10 6");
            },
        });

        this.listSwitchData.push({
            name: "测试攻城2v1",
            func: (index: number) => {
                app.service.gm.requestGM("test_siege 2 1");
            },
        });

        this.owner.listSwitch.array = this.listSwitchData;

        // this.owner.l
    }
}
