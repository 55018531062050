import { Node, NodeDef } from "../../../../../core/behavior3/node";
import { Process, Status } from "../../../../../core/behavior3/process";
import { TreeEnv } from "../../../../../core/behavior3/tree-env";

type NodeInput = [number?];

type NodeArgs = {
    probability: number;
};

export class CheckProbability extends Process {
    override run(node: Node, env: TreeEnv): Status {
        let probability = (env.input as NodeInput)[0] ?? (node.args as NodeArgs).probability ?? 0;
        probability = Math.min(1, Math.max(0, probability));
        return Math.random() < probability ? "success" : "failure";
    }

    override get descriptor(): NodeDef {
        return {
            name: "CheckProbability",
            type: "Condition",
            desc: "随机，按照概率返回成功或失败，参数为成功概率",
            input: ["成功概率 0-1?"],
            args: [{ name: "probability", type: "float?", desc: "成功概率 0-1" }],
            doc: `
                + 优先获取输入的成功概率，如果没有输入则使用节点参数的成功概率
            `,
        };
    }
}
