import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrTroopComponent } from "../../ecs/components/PveSvrTroopComponent";

export class GetOwnerTroop extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const troopEid = env.owner.troopEid;
        const troopComp = env.context.ecs.getComponent(troopEid, PveSvrTroopComponent);
        if (!troopComp) {
            console.error(`GetOwnerTroop: owner ${env.owner.eid} has no PveSvrTroopComponent`);
            return "failure";
        }
        env.output.push(troopEid);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "GetOwnerTroop",
            type: "Action",
            desc: "获取本AI树挂载的编队。",
            output: ["编队"],
            doc: `
            + 返回类型是 PveSvrElementComponent的TroopEid。
            + 如果没有编队则返回失败。
            `,
        };
    }
}
