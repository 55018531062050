import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { TaskType } from "../../def/auto/task";
import { RoleLevelRow } from "../../def/table";
import { ImageRef } from "../../misc/ImageRef";
import { SystemEvent } from "../../misc/system-event";
import { CharacterSwitchTab } from "../hero/ui-runtime/character/CharacterSwitchTab";
import { TaskState, TaskVo } from "../task/vo/TaskVo";
import {
    SoliderKingRewardItem,
    SoliderKingRewardItemUIArgs,
} from "./ui-runtime/SoliderKingRewardItem";
import { SoliderKingUpItem } from "./ui-runtime/SoliderKingUpItem";
import { UISoliderKing } from "./ui-runtime/UISoliderKing";

const { regClass, property } = Laya;

@regClass('enA9JXlNSxabZHkEE34EBw')
export class UISoliderkingMediator extends Mediator {
    declare owner: UISoliderKing;

    @property(String)
    public text: string = "";

    override onAwake(): void {
        super.onAwake();
        this.owner.boxMask.mouseEnabled = false;
        this.owner.boxTips.visible = false;
        this.initUIEvent();
        this.initServiceEvent();
    }

    override onStart(): void {
        this.updateRewardList();
        this.updateTaskList();
    }

    private initUIEvent() {
        this.owner.item0.visible = this.owner.listSwitch.selectedIndex === 0;
        this.owner.item1.visible = this.owner.listSwitch.selectedIndex === 1;

        this.owner.listSwitch.renderHandler = new Laya.Handler(this, this.onSwitchRender);
        this.owner.listSwitch.selectHandler = new Laya.Handler(this, this.onSwitchSelect);
        this.owner.listSwitch.array = ["奖励树", "提升指南"];

        this.owner.viewStack.selectedIndex = this.owner.listSwitch.selectedIndex;
        this.owner.list_task.renderHandler = new Laya.Handler(this, this.onTaskListRender);

        this.owner.listReward.renderHandler = new Laya.Handler(this, this.rewardItemRender);
        this.owner.listReward.repeatY = 4;
        this.owner.listReward.elasticEnabled = true;
        this.owner.listSwitch.selectedIndex = this.owner.openIdx;

        this.owner.btn_close.on(Laya.Event.CLICK, this.owner, this.owner.destroy);
        this.owner.boxMask.on(Laya.Event.CLICK, this, this.onClickMask);
        this.owner.win_bg.setTitle("兵王之路");
    }

    private onSwitchRender(item: CharacterSwitchTab, index: number) {
        const data = item.dataSource;
        item.lblList.text = data;
    }

    onSwitchSelect(index: number) {
        const childCount = this.owner.listSwitch.array.length;
        let bSelected = false;
        for (let i = 0; i < childCount; i++) {
            const cell = this.owner.listSwitch.getCell(i) as CharacterSwitchTab;
            bSelected = i === index;
            cell.lblList.color = bSelected ? "#DDE7EC" : "#3D4C60";
            cell.lblList.stroke = bSelected ? 2 : 0;
        }
        this.owner.viewStack.selectedIndex = index;
    }

    private initServiceEvent() {
        this.$(app).on(SystemEvent.TASK_UPDATE, () => {
            this.updateTaskList();
        });
        this.$(app).on(SystemEvent.USER_COMMONINFO_UPDATE, () => {
            this.updateRewardList();
        });
        this.$(app).on(SystemEvent.USER_PROFILE_UPDATE, () => {
            this.updateRewardList();
        });
    }

    private onTaskListRender(item: SoliderKingUpItem, index: number) {
        const data = item.dataSource as TaskVo;
        this.updateTaskData(item, data);
    }

    private updateTaskData(item: SoliderKingUpItem, data: TaskVo, isMainTask: boolean = false) {
        item.labelDesc.text = data.desc;
        if (data.taskState === TaskState.CAN_DRAW) {
            item.btn_go.visible = false;
            item.btn_finish.visible = true;
        } else {
            item.btn_go.visible = true;
            item.btn_finish.visible = false;
        }
        const reward = data.reward[0];
        const itemLo = app.service.bag.itemLoMap.get(reward.id);
        item.item_icon.updateGoods(itemLo);
        item.item_icon.itemNumber = `${reward.num}`;

        const [mainTaskBG, taskBG] = this.owner.getComponent(ImageRef)!.texturePaths;
        item.img_bg.skin = isMainTask ? mainTaskBG : taskBG;
        item.labelDesc.color = isMainTask ? "#7E5E4C " : "#233F53";

        if (data.maxProgressValue > 0) {
            item.lab_bar.text = `${data.curProgressValue}/${data.maxProgressValue}`;
            item.img_bar.width = 345 * (data.curProgressValue / data.maxProgressValue);
        } else {
            item.lab_bar.text = "已完成";
            item.img_bar.width = 345;
        }

        item.btn_finish.on(Laya.Event.CLICK, () => {
            if (data.taskState === TaskState.CAN_DRAW) {
                //领取
                app.service.task.requestReceiveReward({ taskIds: [data.key] });
            }
        });

        item.btn_go.on(Laya.Event.CLICK, () => {
            //前往
            app.event(SystemEvent.CLICK_GO_TASK_SHOW_WEAK_GUIDE, data);
            this.owner.destroy();
        });
    }

    private rewardItemRender(item: SoliderKingRewardItem, index: number) {
        const cfgData = item.dataSource as RoleLevelRow;
        item.updateInfo(
            <SoliderKingRewardItemUIArgs>{ type: "solder_king", data: cfgData },
            -index
        );
        item.imgIcon.on(Laya.Event.CLICK, this, this.onClickFuncIcon);
        item.offAll(Laya.Event.CLICK);
        item.on(Laya.Event.CLICK, this, () => {
            const receivedLv = app.service.user.commonInfo?.receivedLv || 0;
            if (
                cfgData.lv <= app.service.user.profileInfo.lv &&
                app.service.user.profileInfo.lv > receivedLv
            ) {
                app.service.user.requestReceiveReward();
            }
        });
    }

    private onClickFuncIcon(e: Laya.Event) {
        const boxItem = ((e.target as Laya.Image).parent as Laya.Box).parent
            .parent as SoliderKingRewardItem;
        const data = boxItem.dataSource as RoleLevelRow;

        const reward = data.reward[0];
        const itemLo = app.service.bag.itemLoMap.get(reward.id);
        this.owner.iconItem.updateGoods(itemLo);
        this.owner.boxTips.visible = true;
        this.owner.boxMask.mouseEnabled = true;
        this.owner.txtFuncName.text = data.func ?? "";
        this.owner.txtFuncDesc.text = data.scr ?? "";
    }

    private onClickMask() {
        this.owner.boxTips.visible = false;
        this.owner.boxMask.mouseEnabled = false;
    }

    private updateTaskList() {
        // 主线任务
        const mainTaskVo = app.service.task.getCurMainTaskVo();
        if (mainTaskVo) {
            this.owner.questItem.visible = true;
            this.updateTaskData(this.owner.questItem, mainTaskVo, true);
        } else {
            this.owner.questItem.visible = false;
        }

        const branchTasks = app.service.task.taskVoMap.getTasksWithType(TaskType.BRANCH);
        this.owner.boxBattle.visible = branchTasks.length > 0;
        this.owner.boxMain.visible = !!mainTaskVo;
        this.owner.list_task.array = branchTasks;
    }

    private updateRewardList() {
        this.owner.listReward.array = app.service.table.role.level.slice().reverse();
        const maxLevel = Number(app.service.table.role.conf["max_level"]);
        this.owner.listReward.scrollTo(maxLevel - app.service.user.profileInfo.lv);
        this.owner.listReward.selectedIndex = maxLevel - app.service.user.profileInfo.lv - 1;
        this.owner.labelExp.text = app.service.user.profileInfo.exp.toString();
    }
}
