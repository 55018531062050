import * as ecs from "../../../../../core/ecs";
import { PveServer } from "../../PveServer";
import { PveSvrSkillWarningComponent } from "../components/PveSvrSkillWarningComponent";
export class PveSvrSkillWarningSystem extends ecs.System {
    declare context: PveServer;

    override onCreate(): void {}

    override update(dt: number): void {
        this.ecs.getComponents(PveSvrSkillWarningComponent).forEach((swComp) => {
            const currentTime = this.context.time;
            let overTime = currentTime - swComp.startTime;
            const duration = swComp.skillSelectorArgs!.duration;
            if (overTime > duration) overTime = duration;
            // 进度 0 ~ 1
            const progress = overTime / duration;
            if (progress >= 1) {
                // 到达进度100%
                // 表现层和数据层均移除对应实体
                this.context.sender.removeElement(swComp.eid);
                this.ecs.removeEntity(swComp.eid);
                return;
            }
        });
    }
}
