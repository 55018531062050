/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIImageBar } from "../../../core/ui/UIImageBar";

/**
 * resources/prefab/leader-skin/LeaderSkinItem.lh
 */
export class LeaderSkinItemBase extends Laya.Box {
    public boxGray!: Laya.Box;
    public imgBg!: Laya.Image;
    public imgIcon!: Laya.Image;
    public boxTroop!: Laya.Box;
    public labTeam!: Laya.Label;
    public lablevel!: Laya.Label;
    public selectBox!: Laya.Image;
    public imgRedpoint!: Laya.Image;
    public imgSkin!: Laya.Image;
    public imgBar!: UIImageBar;
    public labBar!: Laya.Label;
}

