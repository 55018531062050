import {
    Node,
    NodeDef,
    NodeVars as NodeVarsBase,
    Process,
    Status,
} from "../../../../../core/behavior3";
import { BattleEntityType } from "../../../../../def/auto/battle";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { ElementTag } from "../../ecs/components/PveSvrElementComponent";
import { PveSvrOffsetComponent } from "../../ecs/components/PveSvrOffsetComponent";
import { PveSvrSoldierComponent } from "../../ecs/components/PveSvrSoldierComponent";
import { PveSvrSoldierGroupComponent } from "../../ecs/components/PveSvrSoldierGroupComponent";

interface NodeArgs {
    limit: number;

    hero?: boolean;
    soldier?: boolean;
    boss?: boolean;
    monster?: boolean;

    front?: boolean;
    mid?: boolean;
    back?: boolean;

    lancer?: boolean;
    saber?: boolean;
    knight?: boolean;
    archer?: boolean;
    assistant?: boolean;
}

interface NodeVars extends NodeVarsBase {
    tag: ElementTag;
}

export class FilterTarget extends Process {
    override init(node: Node) {
        const vars = node.vars as NodeVars;
        const args = node.args as unknown as NodeArgs;
        vars.tag = 0;
        if (args.hero) {
            vars.tag |= ElementTag.LEADER;
        }
        if (args.soldier) {
            vars.tag |= ElementTag.MEMBER;
        }
    }

    override run(node: Node, env: AiTreeEnv): Status {
        const args = node.args as unknown as NodeArgs;
        const vars = node.vars as NodeVars;

        const [inputEids] = env.input as [number[]];
        if (!inputEids || !inputEids.length) {
            env.output.push([]);
            return "failure";
        }

        const selectedIndexes: number[] = [];
        if (args.front) {
            selectedIndexes.push(1, 2, 3, 4, 5);
        }
        if (args.mid) {
            selectedIndexes.push(6, 7, 8, 9, 10);
        }
        if (args.back) {
            selectedIndexes.push(11, 12, 13, 14, 15);
        }

        const selectedJobs: number[] = [];
        if (args.knight) {
            selectedJobs.push(1);
        }
        if (args.saber) {
            selectedJobs.push(2);
        }
        if (args.lancer) {
            selectedJobs.push(3);
        }
        if (args.archer) {
            selectedJobs.push(4);
        }
        if (args.assistant) {
            selectedJobs.push(5);
        }

        const resultEids = inputEids
            .map((eid): number | undefined => {
                const ele = env.context.findElement(eid);
                if (!ele) {
                    return undefined;
                }
                // 不是战斗单位
                if (!(ele.tag & (ElementTag.LEADER | ElementTag.MEMBER))) {
                    return undefined;
                }

                // leader 不用判断其余的参数了
                if (ele.tag & ElementTag.LEADER) {
                    if (ele.etype === BattleEntityType.BOSS && args.boss) {
                        return eid;
                    } else if (ele.etype === BattleEntityType.HERO && args.hero) {
                        return eid;
                    } else {
                        return undefined;
                    }
                }
                // 怪物
                if (ele.etype === BattleEntityType.MONSTER && args.monster) {
                    if (selectedIndexes.length === 0) {
                        return eid;
                    }

                    // 怪物只需要判断前中后排就行了
                    const offsetComp = ele.getComponent(PveSvrOffsetComponent);
                    return selectedIndexes.indexOf(offsetComp?.index ?? 0) >= 0 ? eid : undefined;
                }

                // 士兵
                if (ele.etype === BattleEntityType.SOLDIER && args.soldier) {
                    const soldiderComp = ele.getComponent(PveSvrSoldierComponent)!;
                    const soldiderGroupComp = env.context.ecs.getComponent(
                        soldiderComp.groupEid,
                        PveSvrSoldierGroupComponent
                    )!;
                    const heroLo = soldiderGroupComp.data;

                    if (selectedIndexes.length === 0 && selectedJobs.length === 0) {
                        return eid;
                    } else if (selectedIndexes.length === 0 && selectedJobs.length > 0) {
                        const job = heroLo.job;
                        if (selectedJobs.indexOf(job) >= 0) {
                            return eid;
                        }
                    } else if (selectedIndexes.length > 0 && selectedJobs.length === 0) {
                        const offsetComp = ele.getComponent(PveSvrOffsetComponent);
                        if (selectedIndexes.indexOf(offsetComp?.index ?? 0) >= 0) {
                            return eid;
                        }
                    } else {
                        const offsetComp = ele.getComponent(PveSvrOffsetComponent);
                        const job = heroLo.job;
                        if (
                            selectedIndexes.indexOf(offsetComp?.index ?? 0) >= 0 &&
                            selectedJobs.indexOf(job) >= 0
                        ) {
                            return eid;
                        }
                    }

                    return undefined;
                }
            })
            .filter((v) => !!v);

        if (args.limit) {
            resultEids.length = Math.min(resultEids.length, args.limit);
        }
        if (resultEids.length === 0) {
            return "failure";
        }

        env.output.push(resultEids);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "FilterTarget",
            type: "Condition",
            desc: "根据输入变量筛选目标单位, 可以多选",
            args: [
                { name: "limit", type: "int", desc: "限制最多获取数量" },

                { name: "hero", type: "boolean?", desc: "筛选英雄" },
                { name: "soldier", type: "boolean?", desc: "筛选士兵" },
                { name: "boss", type: "boolean?", desc: "筛选boss" },
                { name: "monster", type: "boolean?", desc: "筛选monster" },

                { name: "front", type: "boolean?", desc: "筛选前排士兵" },
                { name: "mid", type: "boolean?", desc: "筛选中排士兵" },
                { name: "back", type: "boolean?", desc: "筛选后排士兵" },

                { name: "lancer", type: "boolean?", desc: "筛选枪兵职业" },
                { name: "saber", type: "boolean?", desc: "筛选步兵职业" },
                { name: "knight", type: "boolean?", desc: "筛选骑兵职业" },
                { name: "archer", type: "boolean?", desc: "筛选弓箭手职业" },
                { name: "assistant", type: "boolean?", desc: "筛选辅助职业" },
            ],
            input: ["输入单位"],
            output: ["目标单位"],
            doc: `
                + 没有输入或者没找到合适的单位，输入目标为空数组，返回失败
                `,
        };
    }
}
