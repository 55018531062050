import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { ClusterRow } from "../../def/table";
import { ImageRef } from "../../misc/ImageRef";
import { ui } from "../../misc/ui";
import { LoginUI } from "./ui-runtime/LoginUI";
import { UILoginServerListArgs } from "./ui-runtime/UILoginServerList";
import { IServerInfo, ServerInfoVo } from "./vo/ServerInfoVo";

const { regClass, property } = Laya;

type ServerList = {
    err: number;
    serverlist: IServerInfo[];
};

@regClass('uUKclwbVSpOFTLfNLs_7PA')
export class LoginMediator extends Mediator {
    declare owner: LoginUI;

    private _tlServerList!: ServerInfoVo[];
    serverData!: ServerInfoVo;

    override onAwake(): void {
        super.onAwake();
        this.owner.btnLogin.on(Laya.Event.CLICK, this, this.onBtnLogin);
        this.owner.btnSelectServer.on(Laya.Event.CLICK, this, this.onBtnServer);
        this.owner.boxCheck.on(Laya.Event.CLICK, this, this.onClickCheck);
        this.owner.labAgree.on(Laya.Event.CLICK, this, this.onClickAgree);
        this.owner.boxCluster.on(Laya.Event.CLICK, this, this.onClickCluster);

        this.owner.inputAccount.text = app.service.login.username;

        this.owner.listServer.renderHandler = new Laya.Handler(this, this.onServerRender);
        this.owner.listServer.selectHandler = new Laya.Handler(this, this.onChangeDomain);
        this.updateServerList();

        this.owner.inputAccount.on(Laya.Event.BLUR, this, this.onSubmit);
    }

    private onSubmit() {
        if (this.owner.inputAccount.text !== "") {
            this.requestServerList(app.service.login.domain, this.owner.inputAccount.text);
        }
    }

    private onClickCluster() {
        this.owner.listServer.visible = !this.owner.listServer.visible;
    }

    onChangeDomain(index: number) {
        const data = this.owner.listServer.array[index] as ClusterRow;

        app.service.login.domain = data.watchdog || "";
        this.owner.listServer.visible = false;

        this.requestServerList(app.service.login.domain, app.service.login.username);
    }

    private onServerRender(item: Laya.Box, index: number) {
        const data = item.dataSource as ClusterRow;
        const label = item.getChildByName("labelName") as Laya.Label;
        label.text = data.cluster;
    }

    private updateServerList() {
        const clusterCfgs = app.service.table.cluster;
        const serverList: ClusterRow[] = [];
        for (const key in clusterCfgs) {
            const config = clusterCfgs[key];
            if (config.node_type === "center") {
                serverList.push(config);
            }
        }
        this.owner.listServer.array = serverList;

        this.requestServerList(app.service.login.domain, app.service.login.username);
    }

    private requestServerList(damain: string, account: string) {
        this.owner.labelServerName.text = "";
        this.owner.btnSelectServer.mouseEnabled = false;
        if (app.service.login.domain) {
            if (account) {
                const Http = new Laya.HttpRequest();
                // TODO:错误处理
                Http.once(Laya.Event.COMPLETE, ({ serverlist, err }: ServerList) => {
                    app.service.login.username = account;
                    app.service.login.domain = damain;

                    this.owner.btnSelectServer.mouseEnabled = true;
                    this._tlServerList = serverlist.map((v) => new ServerInfoVo(v));
                    const last = this._tlServerList.find(
                        (v) => v.serverSn === app.service.login.serverSn
                    );
                    this.serverData = last || this._tlServerList[0];
                    this.updateInfo();
                });
                Http.send(`${damain}/gate/serverlist`, { account: account }, "post", "json");
                this.owner.listServer.visible = false;
            }
        } else {
            this.owner.listServer.selectedIndex = 0;
        }
    }

    private onClickCheck() {
        console.log("勾选用户协议");
        this.owner.img_gou.visible = !this.owner.img_gou.visible;
    }

    private onClickAgree() {
        console.log("点击了服务协议");
    }

    onBtnLogin() {
        console.log("点击了登录按钮");
        if (!this.serverData) {
            app.ui.toast("网络连接不上，请选择可以进去的域名");
            return;
        }
        if (this.owner.inputAccount.text !== "") {
            app.service.login.username = this.owner.inputAccount.text;
            app.service.login.url = this.serverData.url;
            app.service.login.serverSn = this.serverData.serverSn;
            app.service.login.connect(app.service.login.url);
        }
    }

    onBtnServer() {
        //打开服务器列表
        app.ui.show(ui.LOGIN_SERVER_LIST, {
            back: new Laya.Handler(this, this.onServerDialogClick),
            serverList: this._tlServerList,
        } as UILoginServerListArgs);
    }

    onServerDialogClick(data: ServerInfoVo) {
        this.serverData = data;
        app.service.login.serverSn = data.serverSn;
        this.updateInfo();
    }

    updateInfo() {
        this.owner.labelServerName.text = this.serverData.serverName;
        const [hong, lv] = this.owner.imgState.getComponent(ImageRef)!.texturePaths;
        this.owner.imgState.skin = this.serverData.state === 3 ? lv : hong;
    }
}
