import { Callback, Constructor, ContextChecker } from "./dispatcher";
import { IReusable, Pool } from "./pool";

type Timer = {
    interval: number;
    time: number;
    callback: Callback;
    thisArg: unknown;
};

interface HandlerCallback<T> {
    (comp: T): void;
}

class ComponentHandler<T> {
    private _onAdded: HandlerCallback<T>;
    private _onRemoved: HandlerCallback<T>;
    private _system: System;

    constructor(system: System, onAdded: HandlerCallback<T>, onRemoved: HandlerCallback<T>) {
        this._system = system;
        this._onAdded = onAdded;
        this._onRemoved = onRemoved;
    }

    onAdded(component: T) {
        this._onAdded.call(this._system, component);
    }

    onRemoved(component: T) {
        this._onRemoved.call(this._system, component);
    }
}

export class World {
    private _destroyed: boolean = false;
    private _systems: System[] = [];
    private _namedSystems: Map<Constructor<System>, System> = new Map();
    private _entities: Map<number, Entity> = new Map();

    private _components: Map<Constructor<Component>, Map<number, Component>> = new Map();
    private _singletons: Map<Constructor<SingletonComponent>, SingletonComponent> = new Map();
    private _creatingComponents: Map<Component, boolean> = new Map();
    private _deletingComponents: Component[] = [];
    private _handlers: Map<Constructor<Component>, ComponentHandler<Component>[]> = new Map();
    private _timers: Timer[] = [];
    private _delays: Map<string | number, Timer> = new Map();
    private _time: number = 0;
    private _context: unknown;

    constructor(context: unknown) {
        this._context = context;
    }

    get destroyed() {
        return this._destroyed;
    }

    get time() {
        return this._time;
    }

    __registerHandler<T extends Component>(cls: Constructor<T>, handler: ComponentHandler<T>) {
        if (!this._handlers.has(cls)) {
            this._handlers.set(cls, []);
        }
        this._handlers.get(cls)!.push(handler as ComponentHandler<unknown>);
    }

    destroy() {
        if (this._destroyed) {
            return;
        }

        for (const eid of Array.from(this._entities.keys())) {
            this.removeEntity(eid);
        }
        this._systems.forEach((sys) => sys.onDestroy());
        this._systems.length = 0;
        this._namedSystems.clear();
        this._entities.clear();
        this._components.clear();
        this._singletons.clear();
        this._handlers.clear();
        this._destroyed = true;
    }

    addSystem<T extends System>(cls: Constructor<T>) {
        if (!this._namedSystems.has(cls)) {
            const sys = new cls(this, this._context);
            if (sys.interval > 0) {
                sys.__lastTick = Math.random() * sys.interval;
            }
            this._namedSystems.set(cls, sys);
            this._systems.push(sys);
            sys.onCreate();
        } else {
            console.warn(`System ${cls.name} already exists`);
        }
    }

    getSystem<T extends System>(cls: Constructor<T>) {
        return this._namedSystems.get(cls) as T | undefined;
    }

    private _execCallback(callback: Callback, thisArg: unknown) {
        if (thisArg) {
            callback.apply(thisArg);
        } else {
            callback();
        }
    }

    update(dt: number) {
        this._time += dt;
        this._timers.forEach((timer) => {
            timer.time += dt;
            if (timer.time >= timer.interval) {
                timer.time -= timer.interval;
                this._execCallback(timer.callback, timer.thisArg);
            }
        });
        this._delays.forEach((timer, key) => {
            if (timer.time <= this._time) {
                this._delays.delete(key);
                this._execCallback(timer.callback, timer.thisArg);
            }
        });
        for (const sys of this._systems) {
            const curr = this._time - sys.__lastTick;
            if (curr >= sys.interval) {
                sys.update?.(curr);
                sys.__lastTick = this._time;
            }
        }
        if (this._creatingComponents.size) {
            const components = this._creatingComponents;
            this._creatingComponents = new Map();
            components.forEach((flag, comp) => {
                if (flag) {
                    for (const compcls of comp.dependencies) {
                        if (!comp.getComponent(compcls)) {
                            throw new Error(
                                `${comp.constructor.name}: dependency '${compcls.name}' not found`
                            );
                        }
                    }
                    this._handlers
                        .get(comp.constructor as Constructor<Component>)
                        ?.forEach((handler) => handler.onAdded(comp));
                }
            });
        }
        if (this._deletingComponents.length) {
            this._deletingComponents.forEach((comp) => {
                const cls = comp.constructor as Constructor<Component>;
                if (Pool.isReusable(cls)) {
                    Pool.free(comp);
                }
                comp.__reset();
            });
            this._deletingComponents.length = 0;
        }
    }

    schedule(interval: number, callback: Callback, thisArg?: unknown, delay?: number) {
        this._timers.push({
            interval: interval,
            time: -(delay ?? 0),
            callback: callback,
            thisArg: thisArg,
        });
    }

    delay(time: number, key: string | number, callback: Callback, thisArg?: unknown) {
        if (this._delays.has(key)) {
            console.warn(`ecs.delay: overwrite the delay callback with key ${key}`);
        }
        if (time < 0) {
            this._execCallback(callback, thisArg);
            return;
        }
        this._delays.set(key, {
            interval: 0,
            time: this._time + time,
            callback: callback,
            thisArg: thisArg,
        });
    }

    killDelay(key: string) {
        this._delays.delete(key);
    }

    createEntity(eid: number, etype: number) {
        let entity = this._entities.get(eid);
        if (!entity) {
            entity = Pool.obtain(Entity);
            entity.__init(this, eid, etype);
            this._entities.set(eid, entity);
        }
        return entity;
    }

    getEntity(eid: number) {
        return this._entities.get(eid);
    }

    removeEntity(eid: number) {
        const entity = this.getEntity(eid);
        if (entity) {
            for (const components of this._components.values()) {
                const component = components.get(entity.eid);
                if (component) {
                    this.removeComponent(eid, component.constructor as Constructor<Component>);
                }
            }
            this._entities.delete(eid);
            Pool.free(entity);
        }
    }

    addSingletonComponent<T extends SingletonComponent>(cls: Constructor<T>) {
        let component = this._singletons.get(cls);
        if (!component) {
            component = new cls();
            this._singletons.set(cls, component);
        }
        return component as T;
    }

    getSingletonComponent<T extends SingletonComponent>(cls: Constructor<T>) {
        const component = this._singletons.get(cls);
        if (!component) {
            throw new Error(`singleton component '${cls.name}' not found`);
        }
        return component as T;
    }

    getComponents<T extends Component>(cls: Constructor<T>) {
        let components = this._components.get(cls);
        if (!components) {
            components = new Map<number, T>();
            this._components.set(cls, components);
        }
        return components as Map<number, T>;
    }

    addComponent<T extends Component>(eid: number, cls: Constructor<T>) {
        const entity = this.getEntity(eid);
        if (entity) {
            let components = this._components.get(cls);
            if (!components) {
                components = new Map();
                this._components.set(cls, components);
            }
            let component = components.get(entity.eid);
            if (!component) {
                if (Pool.isReusable(cls)) {
                    component = Pool.obtain(cls);
                } else {
                    component = new cls();
                }
                component.__init(this, entity.eid, entity.etype);
                components.set(entity.eid, component);
                this._creatingComponents.set(component, true);
            }
            return component as T;
        }
    }

    getComponent<T extends Component>(eid: number, cls: Constructor<T>, includeDeleting = false) {
        const components = this._components.get(cls);
        let comp = components?.get(eid);
        if (!comp && includeDeleting) {
            comp = this._deletingComponents.find((v) => v.constructor === cls && v.eid === eid);
        }
        return comp as T | undefined;
    }

    removeComponent<T extends Component>(eid: number, cls: Constructor<T>) {
        const components = this._components.get(cls);
        const component = components?.get(eid);
        if (component) {
            this._handlers.get(cls)?.forEach((handler) => handler.onRemoved(component));
            if (this._creatingComponents.has(component)) {
                this._creatingComponents.set(component, false);
            }
            this._deletingComponents.push(component);
            components!.delete(eid);
        }
    }
}

export abstract class Component {
    private static _idCount: number = 1;

    private _ecs?: World;
    private _eid!: number;
    private _id!: number;
    private _etype!: number;

    __init(ecs: World, eid: number, etype: number) {
        this._ecs = ecs;
        this._eid = eid;
        this._etype = etype;
        this._id = ++Component._idCount;
    }

    __reset() {
        this._ecs = undefined;
        this._eid = 0;
        this._etype = 0;
        this._id = 0;
    }

    protected get time() {
        return this._ecs?.time ?? 0;
    }

    get dependencies(): Constructor<Component>[] {
        return [];
    }

    get alive() {
        return !!this._ecs?.getEntity(this.eid);
    }

    get eid() {
        return this._eid;
    }

    get etype() {
        return this._etype;
    }

    get checker(): ContextChecker {
        const id = this._id;
        return () => this._id === id && this.alive;
    }

    addComponent<T extends Component>(cls: Constructor<T>): T {
        return this._ecs!.addComponent(this.eid, cls)!;
    }

    removeComponent<T extends Component>(cls: Constructor<T>) {
        return this._ecs!.removeComponent(this.eid, cls);
    }

    getComponent<T extends Component>(cls: Constructor<T>, includeDeleting = false): T | undefined {
        return this._ecs!.getComponent(this.eid, cls, includeDeleting);
    }
}

export class SingletonComponent {
    // for type checking
    protected readonly __singleton: string = this.constructor.name;
}

@Pool.reusable
export class Entity implements IReusable {
    private _eid: number = 0;
    private _etype: number = 0;
    private _ecs?: World;

    __init(ecs: World, eid: number, etype: number) {
        this._ecs = ecs;
        this._eid = eid;
        this._etype = etype;
    }

    __unuse() {
        this._etype = 0;
        this._eid = 0;
        this._ecs = undefined;
    }

    __reuse() {}

    get eid() {
        return this._eid;
    }

    get etype() {
        return this._etype;
    }

    addComponent<T extends Component>(cls: Constructor<T>): T {
        return this._ecs!.addComponent(this.eid, cls)!;
    }

    removeComponent<T extends Component>(cls: Constructor<T>) {
        return this._ecs!.removeComponent(this._eid, cls);
    }

    getComponent<T extends Component>(cls: Constructor<T>): T | undefined {
        return this._ecs!.getComponent(this._eid, cls);
    }
}

export abstract class System {
    constructor(readonly ecs: World, readonly context: unknown) {}

    __lastTick: number = 0;

    registerHandler<T extends Component>(
        cls: Constructor<T>,
        onAdded: HandlerCallback<T>,
        onRemoved: HandlerCallback<T>
    ) {
        this.ecs.__registerHandler(cls, new ComponentHandler<T>(this, onAdded, onRemoved));
    }

    /** 自定义更新间隔 */
    get interval() {
        return 0;
    }

    onCreate() {}

    onDestroy() {}

    update?(dt: number): void;
}
