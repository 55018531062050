/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/common/dialog/BaseWindowBg.lh
 */
export class BaseBigWindowBgBase extends Laya.Box {
    public title!: Laya.Label;
}

