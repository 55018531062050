/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/chest-box/sub/ChestBoxBtnItem.lh
 */
export class ChestBoxBtnItemBase extends Laya.Box {
    public img_amount_normal!: Laya.Image;
    public img_amount_sel!: Laya.Image;
    public lab_amount!: Laya.Label;
    public img_icon!: Laya.Image;
}

