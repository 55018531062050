/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { BaseRoundWindowBg } from "../../common/ui-runtime/dialog/BaseRoundWindowBg";
import { UIList } from "../../../core/ui/UIList";

/**
 * resources/prefab/bag/UIBag.lh
 */
export class UIBagBase extends Laya.Dialog {
    public imgMask!: Laya.Image;
    public bgwin!: BaseRoundWindowBg;
    public listItem!: Laya.List;
    public boxSwitch!: Laya.Box;
    public listSwitch!: UIList;
}

