import { app } from "../../../app";
import { StringUtil } from "../../../core/utils/string-util";
import { TimeFormatUtils } from "../../../core/utils/time-format-util";
import { RecruitPoolBaseRow } from "../../../def/table";
import { res } from "../../../misc/res";
import { Vo } from "../../../misc/vo/Vo";

export class DrawCardLo extends Vo<RecruitPoolBaseRow, never> {
    override get key(): number {
        return this._config.id;
    }

    override clone(): DrawCardLo {
        return new DrawCardLo(this._config);
    }

    public get id() {
        return this._config.id;
    }

    public get time_args() {
        return this._config.time_args;
    }

    public get bgicon() {
        const bgIcon = StringUtil.format(res.DRAW_CARD_BIG_BG, this._config.bgicon);
        return bgIcon;
    }

    public get btnicon() {
        const btnIcon = StringUtil.format(res.DRAW_CARD_ICON, this._config.btnicon);
        return btnIcon;
    }

    public get freeTime() {
        return this._config.free_times_per_day;
    }

    public get maxTimes() {
        return this._config.max_times_per_day;
    }

    public get freecd() {
        return this._config.free_cd;
    }

    public get guaranteedtimes() {
        return this._config.public_guaranteed_times;
    }

    public get firstawardtimes() {
        return this._config.first_award_times;
    }

    public get reMainGuaranteedTimes() {
        const time = this.firstawardtimes;
        const voData = app.service.drawCard.poolVoMap.get(this.id);
        if (voData) {
            const guaranteedtimes = this.guaranteedtimes;
            if (voData.openGuaranteed) {
                return guaranteedtimes - voData.currentTime;
            } else {
                return time - voData.currentTime;
            }
        }
        return time;
    }

    public get reMainTime() {
        const totalTime = this.maxTimes;
        const voData = app.service.drawCard.poolVoMap.get(this.id);
        if (voData) {
            return totalTime - voData.times;
        }
        return totalTime;
    }

    public checkIsShowFree() {
        const freeTime = this.freeTime;
        const voData = app.service.drawCard.poolVoMap.get(this.id);
        if (voData) {
            const times = voData.useFreeTimes;
            const lastTime = voData.lastUseFreeTimes;
            if (times < freeTime && lastTime + this.freecd < app.service.network.serverTime) {
                return true;
            }
        } else {
            return freeTime > 0;
        }
        return false;
    }

    public get showFreeText(): string {
        const freeTime = this.freeTime;
        const isShowFree = this.checkIsShowFree();
        if (isShowFree) {
            return "免费";
        } else {
            const voData = app.service.drawCard.poolVoMap.get(this.id);
            if (voData) {
                const lastTime = voData.lastUseFreeTimes;
                const times = voData.useFreeTimes;
                const remainTime = lastTime + this.freecd - app.service.network.serverTime;
                if (times < freeTime && remainTime > 0) {
                    const remainTimeStr = TimeFormatUtils.timeFormatReMain(remainTime);
                    return remainTimeStr + "后免费";
                }
            }
            return "";
        }
    }

    //检测是否显示
    public checkisShow() {
        const args = this._config.time_args;
        const starDateArg1 = args[0];
        const starDateArg2 = args[1];
        const dateArr = StringUtil.formatDateByNumber(starDateArg1);
        const timeArr = StringUtil.formatDateByNumber(starDateArg2);

        const starTime = app.service.drawCard.getDateTime(dateArr, timeArr);

        const endDateArg1 = args[2];
        const endDateArg2 = args[3];
        const endDateArr = StringUtil.formatDateByNumber(endDateArg1);
        const endTimeArr = StringUtil.formatDateByNumber(endDateArg2);
        const endTime = app.service.drawCard.getDateTime(endDateArr, endTimeArr);

        const serverTime = app.service.network.serverTime;
        if (serverTime >= starTime && serverTime <= endTime) {
            return true;
        }
        return false;
    }

    public get poolDownTimeStr(): string {
        const args = this._config.time_args;
        const endDateArg1 = args[2];
        const endDateArg2 = args[3];
        const endDateArr = StringUtil.formatDateByNumber(endDateArg1);
        const endTimeArr = StringUtil.formatDateByNumber(endDateArg2);
        const endTime = app.service.drawCard.getDateTime(endDateArr, endTimeArr);
        const serverTime = app.service.network.serverTime;
        if (endTime > serverTime) {
            const remainTime = endTime - serverTime;
            const remainTimeStr = TimeFormatUtils.timeFormatReMain(remainTime);
            return remainTimeStr;
        } else {
            return "";
        }
    }
}
