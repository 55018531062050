/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal.js";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const alliance = $root.alliance = (() => {

    /**
     * Namespace alliance.
     * @exports alliance
     * @namespace
     */
    const alliance = {};

    alliance.AllianceProfile = (function() {

        /**
         * Properties of an AllianceProfile.
         * @memberof alliance
         * @interface IAllianceProfile
         * @property {string|null} [name] AllianceProfile name
         * @property {number|null} [emblem] AllianceProfile emblem
         * @property {string|null} [announcement] AllianceProfile announcement
         * @property {number|null} [color] AllianceProfile color
         * @property {number|null} [level] AllianceProfile level
         * @property {number|null} [joinType] AllianceProfile joinType
         * @property {number|null} [exp] AllianceProfile exp
         * @property {number|null} [requireLv] AllianceProfile requireLv
         * @property {number|null} [activeFlag] AllianceProfile activeFlag
         */

        /**
         * Constructs a new AllianceProfile.
         * @memberof alliance
         * @classdesc Represents an AllianceProfile.
         * @implements IAllianceProfile
         * @constructor
         * @param {alliance.IAllianceProfile=} [properties] Properties to set
         */
        function AllianceProfile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AllianceProfile name.
         * @member {string} name
         * @memberof alliance.AllianceProfile
         * @instance
         */
        AllianceProfile.prototype.name = "";

        /**
         * AllianceProfile emblem.
         * @member {number} emblem
         * @memberof alliance.AllianceProfile
         * @instance
         */
        AllianceProfile.prototype.emblem = 0;

        /**
         * AllianceProfile announcement.
         * @member {string} announcement
         * @memberof alliance.AllianceProfile
         * @instance
         */
        AllianceProfile.prototype.announcement = "";

        /**
         * AllianceProfile color.
         * @member {number} color
         * @memberof alliance.AllianceProfile
         * @instance
         */
        AllianceProfile.prototype.color = 0;

        /**
         * AllianceProfile level.
         * @member {number} level
         * @memberof alliance.AllianceProfile
         * @instance
         */
        AllianceProfile.prototype.level = 0;

        /**
         * AllianceProfile joinType.
         * @member {number} joinType
         * @memberof alliance.AllianceProfile
         * @instance
         */
        AllianceProfile.prototype.joinType = 0;

        /**
         * AllianceProfile exp.
         * @member {number} exp
         * @memberof alliance.AllianceProfile
         * @instance
         */
        AllianceProfile.prototype.exp = 0;

        /**
         * AllianceProfile requireLv.
         * @member {number} requireLv
         * @memberof alliance.AllianceProfile
         * @instance
         */
        AllianceProfile.prototype.requireLv = 0;

        /**
         * AllianceProfile activeFlag.
         * @member {number} activeFlag
         * @memberof alliance.AllianceProfile
         * @instance
         */
        AllianceProfile.prototype.activeFlag = 0;

        /**
         * Creates a new AllianceProfile instance using the specified properties.
         * @function create
         * @memberof alliance.AllianceProfile
         * @static
         * @param {alliance.IAllianceProfile=} [properties] Properties to set
         * @returns {alliance.AllianceProfile} AllianceProfile instance
         */
        AllianceProfile.create = function create(properties) {
            return new AllianceProfile(properties);
        };

        /**
         * Encodes the specified AllianceProfile message. Does not implicitly {@link alliance.AllianceProfile.verify|verify} messages.
         * @function encode
         * @memberof alliance.AllianceProfile
         * @static
         * @param {alliance.AllianceProfile} message AllianceProfile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AllianceProfile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.emblem != null && Object.hasOwnProperty.call(message, "emblem"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.emblem);
            if (message.announcement != null && Object.hasOwnProperty.call(message, "announcement"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.announcement);
            if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.color);
            if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.level);
            if (message.joinType != null && Object.hasOwnProperty.call(message, "joinType"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.joinType);
            if (message.exp != null && Object.hasOwnProperty.call(message, "exp"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.exp);
            if (message.requireLv != null && Object.hasOwnProperty.call(message, "requireLv"))
                writer.uint32(/* id 8, wireType 0 =*/64).uint32(message.requireLv);
            if (message.activeFlag != null && Object.hasOwnProperty.call(message, "activeFlag"))
                writer.uint32(/* id 9, wireType 0 =*/72).uint32(message.activeFlag);
            return writer;
        };

        /**
         * Decodes an AllianceProfile message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.AllianceProfile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.AllianceProfile} AllianceProfile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AllianceProfile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.AllianceProfile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.emblem = reader.uint32();
                        break;
                    }
                case 3: {
                        message.announcement = reader.string();
                        break;
                    }
                case 4: {
                        message.color = reader.uint32();
                        break;
                    }
                case 5: {
                        message.level = reader.uint32();
                        break;
                    }
                case 6: {
                        message.joinType = reader.uint32();
                        break;
                    }
                case 7: {
                        message.exp = reader.uint32();
                        break;
                    }
                case 8: {
                        message.requireLv = reader.uint32();
                        break;
                    }
                case 9: {
                        message.activeFlag = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for AllianceProfile
         * @function getTypeUrl
         * @memberof alliance.AllianceProfile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AllianceProfile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.AllianceProfile";
        };

        return AllianceProfile;
    })();

    alliance.AllianceInfo = (function() {

        /**
         * Properties of an AllianceInfo.
         * @memberof alliance
         * @interface IAllianceInfo
         * @property {number|null} [allianceId] AllianceInfo allianceId
         * @property {alliance.AllianceProfile|null} [profile] AllianceInfo profile
         * @property {number|null} [memberCnt] AllianceInfo memberCnt
         */

        /**
         * Constructs a new AllianceInfo.
         * @memberof alliance
         * @classdesc Represents an AllianceInfo.
         * @implements IAllianceInfo
         * @constructor
         * @param {alliance.IAllianceInfo=} [properties] Properties to set
         */
        function AllianceInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AllianceInfo allianceId.
         * @member {number} allianceId
         * @memberof alliance.AllianceInfo
         * @instance
         */
        AllianceInfo.prototype.allianceId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * AllianceInfo profile.
         * @member {alliance.AllianceProfile|null|undefined} profile
         * @memberof alliance.AllianceInfo
         * @instance
         */
        AllianceInfo.prototype.profile = null;

        /**
         * AllianceInfo memberCnt.
         * @member {number} memberCnt
         * @memberof alliance.AllianceInfo
         * @instance
         */
        AllianceInfo.prototype.memberCnt = 0;

        /**
         * Creates a new AllianceInfo instance using the specified properties.
         * @function create
         * @memberof alliance.AllianceInfo
         * @static
         * @param {alliance.IAllianceInfo=} [properties] Properties to set
         * @returns {alliance.AllianceInfo} AllianceInfo instance
         */
        AllianceInfo.create = function create(properties) {
            return new AllianceInfo(properties);
        };

        /**
         * Encodes the specified AllianceInfo message. Does not implicitly {@link alliance.AllianceInfo.verify|verify} messages.
         * @function encode
         * @memberof alliance.AllianceInfo
         * @static
         * @param {alliance.AllianceInfo} message AllianceInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AllianceInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.allianceId != null && Object.hasOwnProperty.call(message, "allianceId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.allianceId);
            if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
                $root.alliance.AllianceProfile.encode(message.profile, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.memberCnt != null && Object.hasOwnProperty.call(message, "memberCnt"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.memberCnt);
            return writer;
        };

        /**
         * Decodes an AllianceInfo message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.AllianceInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.AllianceInfo} AllianceInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AllianceInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.AllianceInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.allianceId = reader.int64();
                        break;
                    }
                case 2: {
                        message.profile = $root.alliance.AllianceProfile.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.memberCnt = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for AllianceInfo
         * @function getTypeUrl
         * @memberof alliance.AllianceInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AllianceInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.AllianceInfo";
        };

        return AllianceInfo;
    })();

    alliance.CommonInfo = (function() {

        /**
         * Properties of a CommonInfo.
         * @memberof alliance
         * @interface ICommonInfo
         * @property {number|null} [lastQuitTime] CommonInfo lastQuitTime
         */

        /**
         * Constructs a new CommonInfo.
         * @memberof alliance
         * @classdesc Represents a CommonInfo.
         * @implements ICommonInfo
         * @constructor
         * @param {alliance.ICommonInfo=} [properties] Properties to set
         */
        function CommonInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CommonInfo lastQuitTime.
         * @member {number} lastQuitTime
         * @memberof alliance.CommonInfo
         * @instance
         */
        CommonInfo.prototype.lastQuitTime = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new CommonInfo instance using the specified properties.
         * @function create
         * @memberof alliance.CommonInfo
         * @static
         * @param {alliance.ICommonInfo=} [properties] Properties to set
         * @returns {alliance.CommonInfo} CommonInfo instance
         */
        CommonInfo.create = function create(properties) {
            return new CommonInfo(properties);
        };

        /**
         * Encodes the specified CommonInfo message. Does not implicitly {@link alliance.CommonInfo.verify|verify} messages.
         * @function encode
         * @memberof alliance.CommonInfo
         * @static
         * @param {alliance.CommonInfo} message CommonInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommonInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.lastQuitTime != null && Object.hasOwnProperty.call(message, "lastQuitTime"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.lastQuitTime);
            return writer;
        };

        /**
         * Decodes a CommonInfo message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.CommonInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.CommonInfo} CommonInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommonInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.CommonInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.lastQuitTime = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for CommonInfo
         * @function getTypeUrl
         * @memberof alliance.CommonInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CommonInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.CommonInfo";
        };

        return CommonInfo;
    })();

    alliance.AllianceMyApplyItem = (function() {

        /**
         * Properties of an AllianceMyApplyItem.
         * @memberof alliance
         * @interface IAllianceMyApplyItem
         * @property {alliance.AllianceInfo|null} [info] AllianceMyApplyItem info
         * @property {number|null} [createTime] AllianceMyApplyItem createTime
         */

        /**
         * Constructs a new AllianceMyApplyItem.
         * @memberof alliance
         * @classdesc Represents an AllianceMyApplyItem.
         * @implements IAllianceMyApplyItem
         * @constructor
         * @param {alliance.IAllianceMyApplyItem=} [properties] Properties to set
         */
        function AllianceMyApplyItem(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AllianceMyApplyItem info.
         * @member {alliance.AllianceInfo|null|undefined} info
         * @memberof alliance.AllianceMyApplyItem
         * @instance
         */
        AllianceMyApplyItem.prototype.info = null;

        /**
         * AllianceMyApplyItem createTime.
         * @member {number} createTime
         * @memberof alliance.AllianceMyApplyItem
         * @instance
         */
        AllianceMyApplyItem.prototype.createTime = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new AllianceMyApplyItem instance using the specified properties.
         * @function create
         * @memberof alliance.AllianceMyApplyItem
         * @static
         * @param {alliance.IAllianceMyApplyItem=} [properties] Properties to set
         * @returns {alliance.AllianceMyApplyItem} AllianceMyApplyItem instance
         */
        AllianceMyApplyItem.create = function create(properties) {
            return new AllianceMyApplyItem(properties);
        };

        /**
         * Encodes the specified AllianceMyApplyItem message. Does not implicitly {@link alliance.AllianceMyApplyItem.verify|verify} messages.
         * @function encode
         * @memberof alliance.AllianceMyApplyItem
         * @static
         * @param {alliance.AllianceMyApplyItem} message AllianceMyApplyItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AllianceMyApplyItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.info != null && Object.hasOwnProperty.call(message, "info"))
                $root.alliance.AllianceInfo.encode(message.info, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.createTime != null && Object.hasOwnProperty.call(message, "createTime"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.createTime);
            return writer;
        };

        /**
         * Decodes an AllianceMyApplyItem message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.AllianceMyApplyItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.AllianceMyApplyItem} AllianceMyApplyItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AllianceMyApplyItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.AllianceMyApplyItem();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.info = $root.alliance.AllianceInfo.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.createTime = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for AllianceMyApplyItem
         * @function getTypeUrl
         * @memberof alliance.AllianceMyApplyItem
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AllianceMyApplyItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.AllianceMyApplyItem";
        };

        return AllianceMyApplyItem;
    })();

    alliance.AllianceMyInvitedItem = (function() {

        /**
         * Properties of an AllianceMyInvitedItem.
         * @memberof alliance
         * @interface IAllianceMyInvitedItem
         * @property {alliance.AllianceInfo|null} [info] AllianceMyInvitedItem info
         */

        /**
         * Constructs a new AllianceMyInvitedItem.
         * @memberof alliance
         * @classdesc Represents an AllianceMyInvitedItem.
         * @implements IAllianceMyInvitedItem
         * @constructor
         * @param {alliance.IAllianceMyInvitedItem=} [properties] Properties to set
         */
        function AllianceMyInvitedItem(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AllianceMyInvitedItem info.
         * @member {alliance.AllianceInfo|null|undefined} info
         * @memberof alliance.AllianceMyInvitedItem
         * @instance
         */
        AllianceMyInvitedItem.prototype.info = null;

        /**
         * Creates a new AllianceMyInvitedItem instance using the specified properties.
         * @function create
         * @memberof alliance.AllianceMyInvitedItem
         * @static
         * @param {alliance.IAllianceMyInvitedItem=} [properties] Properties to set
         * @returns {alliance.AllianceMyInvitedItem} AllianceMyInvitedItem instance
         */
        AllianceMyInvitedItem.create = function create(properties) {
            return new AllianceMyInvitedItem(properties);
        };

        /**
         * Encodes the specified AllianceMyInvitedItem message. Does not implicitly {@link alliance.AllianceMyInvitedItem.verify|verify} messages.
         * @function encode
         * @memberof alliance.AllianceMyInvitedItem
         * @static
         * @param {alliance.AllianceMyInvitedItem} message AllianceMyInvitedItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AllianceMyInvitedItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.info != null && Object.hasOwnProperty.call(message, "info"))
                $root.alliance.AllianceInfo.encode(message.info, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an AllianceMyInvitedItem message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.AllianceMyInvitedItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.AllianceMyInvitedItem} AllianceMyInvitedItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AllianceMyInvitedItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.AllianceMyInvitedItem();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.info = $root.alliance.AllianceInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for AllianceMyInvitedItem
         * @function getTypeUrl
         * @memberof alliance.AllianceMyInvitedItem
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AllianceMyInvitedItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.AllianceMyInvitedItem";
        };

        return AllianceMyInvitedItem;
    })();

    alliance.c2s_load = (function() {

        /**
         * Properties of a c2s_load.
         * @memberof alliance
         * @interface Ic2s_load
         */

        /**
         * Constructs a new c2s_load.
         * @memberof alliance
         * @classdesc Represents a c2s_load.
         * @implements Ic2s_load
         * @constructor
         * @param {alliance.Ic2s_load=} [properties] Properties to set
         */
        function c2s_load(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_load instance using the specified properties.
         * @function create
         * @memberof alliance.c2s_load
         * @static
         * @param {alliance.Ic2s_load=} [properties] Properties to set
         * @returns {alliance.c2s_load} c2s_load instance
         */
        c2s_load.create = function create(properties) {
            return new c2s_load(properties);
        };

        /**
         * Encodes the specified c2s_load message. Does not implicitly {@link alliance.c2s_load.verify|verify} messages.
         * @function encode
         * @memberof alliance.c2s_load
         * @static
         * @param {alliance.c2s_load} message c2s_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_load message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.c2s_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.c2s_load} c2s_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.c2s_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_load
         * @function getTypeUrl
         * @memberof alliance.c2s_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.c2s_load";
        };

        return c2s_load;
    })();

    alliance.s2c_load = (function() {

        /**
         * Properties of a s2c_load.
         * @memberof alliance
         * @interface Is2c_load
         * @property {number|null} [err] s2c_load err
         * @property {alliance.AllianceInfo|null} [info] s2c_load info
         * @property {alliance.CommonInfo|null} [commonInfo] s2c_load commonInfo
         */

        /**
         * Constructs a new s2c_load.
         * @memberof alliance
         * @classdesc Represents a s2c_load.
         * @implements Is2c_load
         * @constructor
         * @param {alliance.Is2c_load=} [properties] Properties to set
         */
        function s2c_load(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_load err.
         * @member {number} err
         * @memberof alliance.s2c_load
         * @instance
         */
        s2c_load.prototype.err = 0;

        /**
         * s2c_load info.
         * @member {alliance.AllianceInfo|null|undefined} info
         * @memberof alliance.s2c_load
         * @instance
         */
        s2c_load.prototype.info = null;

        /**
         * s2c_load commonInfo.
         * @member {alliance.CommonInfo|null|undefined} commonInfo
         * @memberof alliance.s2c_load
         * @instance
         */
        s2c_load.prototype.commonInfo = null;

        /**
         * Creates a new s2c_load instance using the specified properties.
         * @function create
         * @memberof alliance.s2c_load
         * @static
         * @param {alliance.Is2c_load=} [properties] Properties to set
         * @returns {alliance.s2c_load} s2c_load instance
         */
        s2c_load.create = function create(properties) {
            return new s2c_load(properties);
        };

        /**
         * Encodes the specified s2c_load message. Does not implicitly {@link alliance.s2c_load.verify|verify} messages.
         * @function encode
         * @memberof alliance.s2c_load
         * @static
         * @param {alliance.s2c_load} message s2c_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.info != null && Object.hasOwnProperty.call(message, "info"))
                $root.alliance.AllianceInfo.encode(message.info, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.commonInfo != null && Object.hasOwnProperty.call(message, "commonInfo"))
                $root.alliance.CommonInfo.encode(message.commonInfo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_load message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.s2c_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.s2c_load} s2c_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.s2c_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        message.info = $root.alliance.AllianceInfo.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.commonInfo = $root.alliance.CommonInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_load
         * @function getTypeUrl
         * @memberof alliance.s2c_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.s2c_load";
        };

        return s2c_load;
    })();

    alliance.c2s_join_system_alliance = (function() {

        /**
         * Properties of a c2s_join_system_alliance.
         * @memberof alliance
         * @interface Ic2s_join_system_alliance
         */

        /**
         * Constructs a new c2s_join_system_alliance.
         * @memberof alliance
         * @classdesc Represents a c2s_join_system_alliance.
         * @implements Ic2s_join_system_alliance
         * @constructor
         * @param {alliance.Ic2s_join_system_alliance=} [properties] Properties to set
         */
        function c2s_join_system_alliance(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_join_system_alliance instance using the specified properties.
         * @function create
         * @memberof alliance.c2s_join_system_alliance
         * @static
         * @param {alliance.Ic2s_join_system_alliance=} [properties] Properties to set
         * @returns {alliance.c2s_join_system_alliance} c2s_join_system_alliance instance
         */
        c2s_join_system_alliance.create = function create(properties) {
            return new c2s_join_system_alliance(properties);
        };

        /**
         * Encodes the specified c2s_join_system_alliance message. Does not implicitly {@link alliance.c2s_join_system_alliance.verify|verify} messages.
         * @function encode
         * @memberof alliance.c2s_join_system_alliance
         * @static
         * @param {alliance.c2s_join_system_alliance} message c2s_join_system_alliance message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_join_system_alliance.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_join_system_alliance message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.c2s_join_system_alliance
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.c2s_join_system_alliance} c2s_join_system_alliance
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_join_system_alliance.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.c2s_join_system_alliance();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_join_system_alliance
         * @function getTypeUrl
         * @memberof alliance.c2s_join_system_alliance
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_join_system_alliance.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.c2s_join_system_alliance";
        };

        return c2s_join_system_alliance;
    })();

    alliance.s2c_join_system_alliance = (function() {

        /**
         * Properties of a s2c_join_system_alliance.
         * @memberof alliance
         * @interface Is2c_join_system_alliance
         * @property {number|null} [err] s2c_join_system_alliance err
         * @property {alliance.AllianceInfo|null} [info] s2c_join_system_alliance info
         */

        /**
         * Constructs a new s2c_join_system_alliance.
         * @memberof alliance
         * @classdesc Represents a s2c_join_system_alliance.
         * @implements Is2c_join_system_alliance
         * @constructor
         * @param {alliance.Is2c_join_system_alliance=} [properties] Properties to set
         */
        function s2c_join_system_alliance(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_join_system_alliance err.
         * @member {number} err
         * @memberof alliance.s2c_join_system_alliance
         * @instance
         */
        s2c_join_system_alliance.prototype.err = 0;

        /**
         * s2c_join_system_alliance info.
         * @member {alliance.AllianceInfo|null|undefined} info
         * @memberof alliance.s2c_join_system_alliance
         * @instance
         */
        s2c_join_system_alliance.prototype.info = null;

        /**
         * Creates a new s2c_join_system_alliance instance using the specified properties.
         * @function create
         * @memberof alliance.s2c_join_system_alliance
         * @static
         * @param {alliance.Is2c_join_system_alliance=} [properties] Properties to set
         * @returns {alliance.s2c_join_system_alliance} s2c_join_system_alliance instance
         */
        s2c_join_system_alliance.create = function create(properties) {
            return new s2c_join_system_alliance(properties);
        };

        /**
         * Encodes the specified s2c_join_system_alliance message. Does not implicitly {@link alliance.s2c_join_system_alliance.verify|verify} messages.
         * @function encode
         * @memberof alliance.s2c_join_system_alliance
         * @static
         * @param {alliance.s2c_join_system_alliance} message s2c_join_system_alliance message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_join_system_alliance.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.info != null && Object.hasOwnProperty.call(message, "info"))
                $root.alliance.AllianceInfo.encode(message.info, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_join_system_alliance message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.s2c_join_system_alliance
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.s2c_join_system_alliance} s2c_join_system_alliance
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_join_system_alliance.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.s2c_join_system_alliance();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        message.info = $root.alliance.AllianceInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_join_system_alliance
         * @function getTypeUrl
         * @memberof alliance.s2c_join_system_alliance
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_join_system_alliance.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.s2c_join_system_alliance";
        };

        return s2c_join_system_alliance;
    })();

    alliance.c2s_create_alliance = (function() {

        /**
         * Properties of a c2s_create_alliance.
         * @memberof alliance
         * @interface Ic2s_create_alliance
         * @property {string|null} [name] c2s_create_alliance name
         * @property {number|null} [emblem] c2s_create_alliance emblem
         * @property {number|null} [color] c2s_create_alliance color
         * @property {string|null} [announcement] c2s_create_alliance announcement
         */

        /**
         * Constructs a new c2s_create_alliance.
         * @memberof alliance
         * @classdesc Represents a c2s_create_alliance.
         * @implements Ic2s_create_alliance
         * @constructor
         * @param {alliance.Ic2s_create_alliance=} [properties] Properties to set
         */
        function c2s_create_alliance(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_create_alliance name.
         * @member {string} name
         * @memberof alliance.c2s_create_alliance
         * @instance
         */
        c2s_create_alliance.prototype.name = "";

        /**
         * c2s_create_alliance emblem.
         * @member {number} emblem
         * @memberof alliance.c2s_create_alliance
         * @instance
         */
        c2s_create_alliance.prototype.emblem = 0;

        /**
         * c2s_create_alliance color.
         * @member {number} color
         * @memberof alliance.c2s_create_alliance
         * @instance
         */
        c2s_create_alliance.prototype.color = 0;

        /**
         * c2s_create_alliance announcement.
         * @member {string} announcement
         * @memberof alliance.c2s_create_alliance
         * @instance
         */
        c2s_create_alliance.prototype.announcement = "";

        /**
         * Creates a new c2s_create_alliance instance using the specified properties.
         * @function create
         * @memberof alliance.c2s_create_alliance
         * @static
         * @param {alliance.Ic2s_create_alliance=} [properties] Properties to set
         * @returns {alliance.c2s_create_alliance} c2s_create_alliance instance
         */
        c2s_create_alliance.create = function create(properties) {
            return new c2s_create_alliance(properties);
        };

        /**
         * Encodes the specified c2s_create_alliance message. Does not implicitly {@link alliance.c2s_create_alliance.verify|verify} messages.
         * @function encode
         * @memberof alliance.c2s_create_alliance
         * @static
         * @param {alliance.c2s_create_alliance} message c2s_create_alliance message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_create_alliance.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.emblem != null && Object.hasOwnProperty.call(message, "emblem"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.emblem);
            if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.color);
            if (message.announcement != null && Object.hasOwnProperty.call(message, "announcement"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.announcement);
            return writer;
        };

        /**
         * Decodes a c2s_create_alliance message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.c2s_create_alliance
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.c2s_create_alliance} c2s_create_alliance
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_create_alliance.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.c2s_create_alliance();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.emblem = reader.uint32();
                        break;
                    }
                case 3: {
                        message.color = reader.uint32();
                        break;
                    }
                case 4: {
                        message.announcement = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_create_alliance
         * @function getTypeUrl
         * @memberof alliance.c2s_create_alliance
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_create_alliance.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.c2s_create_alliance";
        };

        return c2s_create_alliance;
    })();

    alliance.s2c_create_alliance = (function() {

        /**
         * Properties of a s2c_create_alliance.
         * @memberof alliance
         * @interface Is2c_create_alliance
         * @property {number|null} [err] s2c_create_alliance err
         * @property {alliance.AllianceInfo|null} [info] s2c_create_alliance info
         */

        /**
         * Constructs a new s2c_create_alliance.
         * @memberof alliance
         * @classdesc Represents a s2c_create_alliance.
         * @implements Is2c_create_alliance
         * @constructor
         * @param {alliance.Is2c_create_alliance=} [properties] Properties to set
         */
        function s2c_create_alliance(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_create_alliance err.
         * @member {number} err
         * @memberof alliance.s2c_create_alliance
         * @instance
         */
        s2c_create_alliance.prototype.err = 0;

        /**
         * s2c_create_alliance info.
         * @member {alliance.AllianceInfo|null|undefined} info
         * @memberof alliance.s2c_create_alliance
         * @instance
         */
        s2c_create_alliance.prototype.info = null;

        /**
         * Creates a new s2c_create_alliance instance using the specified properties.
         * @function create
         * @memberof alliance.s2c_create_alliance
         * @static
         * @param {alliance.Is2c_create_alliance=} [properties] Properties to set
         * @returns {alliance.s2c_create_alliance} s2c_create_alliance instance
         */
        s2c_create_alliance.create = function create(properties) {
            return new s2c_create_alliance(properties);
        };

        /**
         * Encodes the specified s2c_create_alliance message. Does not implicitly {@link alliance.s2c_create_alliance.verify|verify} messages.
         * @function encode
         * @memberof alliance.s2c_create_alliance
         * @static
         * @param {alliance.s2c_create_alliance} message s2c_create_alliance message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_create_alliance.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.info != null && Object.hasOwnProperty.call(message, "info"))
                $root.alliance.AllianceInfo.encode(message.info, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_create_alliance message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.s2c_create_alliance
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.s2c_create_alliance} s2c_create_alliance
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_create_alliance.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.s2c_create_alliance();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        message.info = $root.alliance.AllianceInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_create_alliance
         * @function getTypeUrl
         * @memberof alliance.s2c_create_alliance
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_create_alliance.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.s2c_create_alliance";
        };

        return s2c_create_alliance;
    })();

    alliance.c2s_join_alliance = (function() {

        /**
         * Properties of a c2s_join_alliance.
         * @memberof alliance
         * @interface Ic2s_join_alliance
         * @property {number|null} [allianceId] c2s_join_alliance allianceId
         */

        /**
         * Constructs a new c2s_join_alliance.
         * @memberof alliance
         * @classdesc Represents a c2s_join_alliance.
         * @implements Ic2s_join_alliance
         * @constructor
         * @param {alliance.Ic2s_join_alliance=} [properties] Properties to set
         */
        function c2s_join_alliance(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_join_alliance allianceId.
         * @member {number} allianceId
         * @memberof alliance.c2s_join_alliance
         * @instance
         */
        c2s_join_alliance.prototype.allianceId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new c2s_join_alliance instance using the specified properties.
         * @function create
         * @memberof alliance.c2s_join_alliance
         * @static
         * @param {alliance.Ic2s_join_alliance=} [properties] Properties to set
         * @returns {alliance.c2s_join_alliance} c2s_join_alliance instance
         */
        c2s_join_alliance.create = function create(properties) {
            return new c2s_join_alliance(properties);
        };

        /**
         * Encodes the specified c2s_join_alliance message. Does not implicitly {@link alliance.c2s_join_alliance.verify|verify} messages.
         * @function encode
         * @memberof alliance.c2s_join_alliance
         * @static
         * @param {alliance.c2s_join_alliance} message c2s_join_alliance message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_join_alliance.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.allianceId != null && Object.hasOwnProperty.call(message, "allianceId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.allianceId);
            return writer;
        };

        /**
         * Decodes a c2s_join_alliance message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.c2s_join_alliance
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.c2s_join_alliance} c2s_join_alliance
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_join_alliance.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.c2s_join_alliance();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.allianceId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_join_alliance
         * @function getTypeUrl
         * @memberof alliance.c2s_join_alliance
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_join_alliance.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.c2s_join_alliance";
        };

        return c2s_join_alliance;
    })();

    alliance.s2c_join_alliance = (function() {

        /**
         * Properties of a s2c_join_alliance.
         * @memberof alliance
         * @interface Is2c_join_alliance
         * @property {number|null} [err] s2c_join_alliance err
         * @property {alliance.AllianceInfo|null} [info] s2c_join_alliance info
         */

        /**
         * Constructs a new s2c_join_alliance.
         * @memberof alliance
         * @classdesc Represents a s2c_join_alliance.
         * @implements Is2c_join_alliance
         * @constructor
         * @param {alliance.Is2c_join_alliance=} [properties] Properties to set
         */
        function s2c_join_alliance(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_join_alliance err.
         * @member {number} err
         * @memberof alliance.s2c_join_alliance
         * @instance
         */
        s2c_join_alliance.prototype.err = 0;

        /**
         * s2c_join_alliance info.
         * @member {alliance.AllianceInfo|null|undefined} info
         * @memberof alliance.s2c_join_alliance
         * @instance
         */
        s2c_join_alliance.prototype.info = null;

        /**
         * Creates a new s2c_join_alliance instance using the specified properties.
         * @function create
         * @memberof alliance.s2c_join_alliance
         * @static
         * @param {alliance.Is2c_join_alliance=} [properties] Properties to set
         * @returns {alliance.s2c_join_alliance} s2c_join_alliance instance
         */
        s2c_join_alliance.create = function create(properties) {
            return new s2c_join_alliance(properties);
        };

        /**
         * Encodes the specified s2c_join_alliance message. Does not implicitly {@link alliance.s2c_join_alliance.verify|verify} messages.
         * @function encode
         * @memberof alliance.s2c_join_alliance
         * @static
         * @param {alliance.s2c_join_alliance} message s2c_join_alliance message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_join_alliance.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.info != null && Object.hasOwnProperty.call(message, "info"))
                $root.alliance.AllianceInfo.encode(message.info, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_join_alliance message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.s2c_join_alliance
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.s2c_join_alliance} s2c_join_alliance
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_join_alliance.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.s2c_join_alliance();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        message.info = $root.alliance.AllianceInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_join_alliance
         * @function getTypeUrl
         * @memberof alliance.s2c_join_alliance
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_join_alliance.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.s2c_join_alliance";
        };

        return s2c_join_alliance;
    })();

    alliance.c2s_apply_join_alliance = (function() {

        /**
         * Properties of a c2s_apply_join_alliance.
         * @memberof alliance
         * @interface Ic2s_apply_join_alliance
         * @property {number|null} [allianceId] c2s_apply_join_alliance allianceId
         */

        /**
         * Constructs a new c2s_apply_join_alliance.
         * @memberof alliance
         * @classdesc Represents a c2s_apply_join_alliance.
         * @implements Ic2s_apply_join_alliance
         * @constructor
         * @param {alliance.Ic2s_apply_join_alliance=} [properties] Properties to set
         */
        function c2s_apply_join_alliance(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_apply_join_alliance allianceId.
         * @member {number} allianceId
         * @memberof alliance.c2s_apply_join_alliance
         * @instance
         */
        c2s_apply_join_alliance.prototype.allianceId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new c2s_apply_join_alliance instance using the specified properties.
         * @function create
         * @memberof alliance.c2s_apply_join_alliance
         * @static
         * @param {alliance.Ic2s_apply_join_alliance=} [properties] Properties to set
         * @returns {alliance.c2s_apply_join_alliance} c2s_apply_join_alliance instance
         */
        c2s_apply_join_alliance.create = function create(properties) {
            return new c2s_apply_join_alliance(properties);
        };

        /**
         * Encodes the specified c2s_apply_join_alliance message. Does not implicitly {@link alliance.c2s_apply_join_alliance.verify|verify} messages.
         * @function encode
         * @memberof alliance.c2s_apply_join_alliance
         * @static
         * @param {alliance.c2s_apply_join_alliance} message c2s_apply_join_alliance message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_apply_join_alliance.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.allianceId != null && Object.hasOwnProperty.call(message, "allianceId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.allianceId);
            return writer;
        };

        /**
         * Decodes a c2s_apply_join_alliance message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.c2s_apply_join_alliance
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.c2s_apply_join_alliance} c2s_apply_join_alliance
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_apply_join_alliance.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.c2s_apply_join_alliance();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.allianceId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_apply_join_alliance
         * @function getTypeUrl
         * @memberof alliance.c2s_apply_join_alliance
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_apply_join_alliance.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.c2s_apply_join_alliance";
        };

        return c2s_apply_join_alliance;
    })();

    alliance.s2c_apply_join_alliance = (function() {

        /**
         * Properties of a s2c_apply_join_alliance.
         * @memberof alliance
         * @interface Is2c_apply_join_alliance
         * @property {number|null} [err] s2c_apply_join_alliance err
         */

        /**
         * Constructs a new s2c_apply_join_alliance.
         * @memberof alliance
         * @classdesc Represents a s2c_apply_join_alliance.
         * @implements Is2c_apply_join_alliance
         * @constructor
         * @param {alliance.Is2c_apply_join_alliance=} [properties] Properties to set
         */
        function s2c_apply_join_alliance(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_apply_join_alliance err.
         * @member {number} err
         * @memberof alliance.s2c_apply_join_alliance
         * @instance
         */
        s2c_apply_join_alliance.prototype.err = 0;

        /**
         * Creates a new s2c_apply_join_alliance instance using the specified properties.
         * @function create
         * @memberof alliance.s2c_apply_join_alliance
         * @static
         * @param {alliance.Is2c_apply_join_alliance=} [properties] Properties to set
         * @returns {alliance.s2c_apply_join_alliance} s2c_apply_join_alliance instance
         */
        s2c_apply_join_alliance.create = function create(properties) {
            return new s2c_apply_join_alliance(properties);
        };

        /**
         * Encodes the specified s2c_apply_join_alliance message. Does not implicitly {@link alliance.s2c_apply_join_alliance.verify|verify} messages.
         * @function encode
         * @memberof alliance.s2c_apply_join_alliance
         * @static
         * @param {alliance.s2c_apply_join_alliance} message s2c_apply_join_alliance message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_apply_join_alliance.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_apply_join_alliance message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.s2c_apply_join_alliance
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.s2c_apply_join_alliance} s2c_apply_join_alliance
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_apply_join_alliance.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.s2c_apply_join_alliance();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_apply_join_alliance
         * @function getTypeUrl
         * @memberof alliance.s2c_apply_join_alliance
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_apply_join_alliance.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.s2c_apply_join_alliance";
        };

        return s2c_apply_join_alliance;
    })();

    alliance.c2s_agree_apply = (function() {

        /**
         * Properties of a c2s_agree_apply.
         * @memberof alliance
         * @interface Ic2s_agree_apply
         * @property {number|null} [applyRid] c2s_agree_apply applyRid
         */

        /**
         * Constructs a new c2s_agree_apply.
         * @memberof alliance
         * @classdesc Represents a c2s_agree_apply.
         * @implements Ic2s_agree_apply
         * @constructor
         * @param {alliance.Ic2s_agree_apply=} [properties] Properties to set
         */
        function c2s_agree_apply(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_agree_apply applyRid.
         * @member {number} applyRid
         * @memberof alliance.c2s_agree_apply
         * @instance
         */
        c2s_agree_apply.prototype.applyRid = 0;

        /**
         * Creates a new c2s_agree_apply instance using the specified properties.
         * @function create
         * @memberof alliance.c2s_agree_apply
         * @static
         * @param {alliance.Ic2s_agree_apply=} [properties] Properties to set
         * @returns {alliance.c2s_agree_apply} c2s_agree_apply instance
         */
        c2s_agree_apply.create = function create(properties) {
            return new c2s_agree_apply(properties);
        };

        /**
         * Encodes the specified c2s_agree_apply message. Does not implicitly {@link alliance.c2s_agree_apply.verify|verify} messages.
         * @function encode
         * @memberof alliance.c2s_agree_apply
         * @static
         * @param {alliance.c2s_agree_apply} message c2s_agree_apply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_agree_apply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.applyRid != null && Object.hasOwnProperty.call(message, "applyRid"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.applyRid);
            return writer;
        };

        /**
         * Decodes a c2s_agree_apply message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.c2s_agree_apply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.c2s_agree_apply} c2s_agree_apply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_agree_apply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.c2s_agree_apply();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.applyRid = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_agree_apply
         * @function getTypeUrl
         * @memberof alliance.c2s_agree_apply
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_agree_apply.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.c2s_agree_apply";
        };

        return c2s_agree_apply;
    })();

    alliance.s2c_agree_apply = (function() {

        /**
         * Properties of a s2c_agree_apply.
         * @memberof alliance
         * @interface Is2c_agree_apply
         * @property {number|null} [err] s2c_agree_apply err
         */

        /**
         * Constructs a new s2c_agree_apply.
         * @memberof alliance
         * @classdesc Represents a s2c_agree_apply.
         * @implements Is2c_agree_apply
         * @constructor
         * @param {alliance.Is2c_agree_apply=} [properties] Properties to set
         */
        function s2c_agree_apply(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_agree_apply err.
         * @member {number} err
         * @memberof alliance.s2c_agree_apply
         * @instance
         */
        s2c_agree_apply.prototype.err = 0;

        /**
         * Creates a new s2c_agree_apply instance using the specified properties.
         * @function create
         * @memberof alliance.s2c_agree_apply
         * @static
         * @param {alliance.Is2c_agree_apply=} [properties] Properties to set
         * @returns {alliance.s2c_agree_apply} s2c_agree_apply instance
         */
        s2c_agree_apply.create = function create(properties) {
            return new s2c_agree_apply(properties);
        };

        /**
         * Encodes the specified s2c_agree_apply message. Does not implicitly {@link alliance.s2c_agree_apply.verify|verify} messages.
         * @function encode
         * @memberof alliance.s2c_agree_apply
         * @static
         * @param {alliance.s2c_agree_apply} message s2c_agree_apply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_agree_apply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_agree_apply message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.s2c_agree_apply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.s2c_agree_apply} s2c_agree_apply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_agree_apply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.s2c_agree_apply();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_agree_apply
         * @function getTypeUrl
         * @memberof alliance.s2c_agree_apply
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_agree_apply.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.s2c_agree_apply";
        };

        return s2c_agree_apply;
    })();

    alliance.c2s_agree_all_apply = (function() {

        /**
         * Properties of a c2s_agree_all_apply.
         * @memberof alliance
         * @interface Ic2s_agree_all_apply
         */

        /**
         * Constructs a new c2s_agree_all_apply.
         * @memberof alliance
         * @classdesc Represents a c2s_agree_all_apply.
         * @implements Ic2s_agree_all_apply
         * @constructor
         * @param {alliance.Ic2s_agree_all_apply=} [properties] Properties to set
         */
        function c2s_agree_all_apply(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_agree_all_apply instance using the specified properties.
         * @function create
         * @memberof alliance.c2s_agree_all_apply
         * @static
         * @param {alliance.Ic2s_agree_all_apply=} [properties] Properties to set
         * @returns {alliance.c2s_agree_all_apply} c2s_agree_all_apply instance
         */
        c2s_agree_all_apply.create = function create(properties) {
            return new c2s_agree_all_apply(properties);
        };

        /**
         * Encodes the specified c2s_agree_all_apply message. Does not implicitly {@link alliance.c2s_agree_all_apply.verify|verify} messages.
         * @function encode
         * @memberof alliance.c2s_agree_all_apply
         * @static
         * @param {alliance.c2s_agree_all_apply} message c2s_agree_all_apply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_agree_all_apply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_agree_all_apply message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.c2s_agree_all_apply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.c2s_agree_all_apply} c2s_agree_all_apply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_agree_all_apply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.c2s_agree_all_apply();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_agree_all_apply
         * @function getTypeUrl
         * @memberof alliance.c2s_agree_all_apply
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_agree_all_apply.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.c2s_agree_all_apply";
        };

        return c2s_agree_all_apply;
    })();

    alliance.s2c_agree_all_apply = (function() {

        /**
         * Properties of a s2c_agree_all_apply.
         * @memberof alliance
         * @interface Is2c_agree_all_apply
         * @property {number|null} [err] s2c_agree_all_apply err
         */

        /**
         * Constructs a new s2c_agree_all_apply.
         * @memberof alliance
         * @classdesc Represents a s2c_agree_all_apply.
         * @implements Is2c_agree_all_apply
         * @constructor
         * @param {alliance.Is2c_agree_all_apply=} [properties] Properties to set
         */
        function s2c_agree_all_apply(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_agree_all_apply err.
         * @member {number} err
         * @memberof alliance.s2c_agree_all_apply
         * @instance
         */
        s2c_agree_all_apply.prototype.err = 0;

        /**
         * Creates a new s2c_agree_all_apply instance using the specified properties.
         * @function create
         * @memberof alliance.s2c_agree_all_apply
         * @static
         * @param {alliance.Is2c_agree_all_apply=} [properties] Properties to set
         * @returns {alliance.s2c_agree_all_apply} s2c_agree_all_apply instance
         */
        s2c_agree_all_apply.create = function create(properties) {
            return new s2c_agree_all_apply(properties);
        };

        /**
         * Encodes the specified s2c_agree_all_apply message. Does not implicitly {@link alliance.s2c_agree_all_apply.verify|verify} messages.
         * @function encode
         * @memberof alliance.s2c_agree_all_apply
         * @static
         * @param {alliance.s2c_agree_all_apply} message s2c_agree_all_apply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_agree_all_apply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_agree_all_apply message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.s2c_agree_all_apply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.s2c_agree_all_apply} s2c_agree_all_apply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_agree_all_apply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.s2c_agree_all_apply();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_agree_all_apply
         * @function getTypeUrl
         * @memberof alliance.s2c_agree_all_apply
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_agree_all_apply.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.s2c_agree_all_apply";
        };

        return s2c_agree_all_apply;
    })();

    alliance.c2s_reject_apply = (function() {

        /**
         * Properties of a c2s_reject_apply.
         * @memberof alliance
         * @interface Ic2s_reject_apply
         * @property {number|null} [applyRid] c2s_reject_apply applyRid
         */

        /**
         * Constructs a new c2s_reject_apply.
         * @memberof alliance
         * @classdesc Represents a c2s_reject_apply.
         * @implements Ic2s_reject_apply
         * @constructor
         * @param {alliance.Ic2s_reject_apply=} [properties] Properties to set
         */
        function c2s_reject_apply(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_reject_apply applyRid.
         * @member {number} applyRid
         * @memberof alliance.c2s_reject_apply
         * @instance
         */
        c2s_reject_apply.prototype.applyRid = 0;

        /**
         * Creates a new c2s_reject_apply instance using the specified properties.
         * @function create
         * @memberof alliance.c2s_reject_apply
         * @static
         * @param {alliance.Ic2s_reject_apply=} [properties] Properties to set
         * @returns {alliance.c2s_reject_apply} c2s_reject_apply instance
         */
        c2s_reject_apply.create = function create(properties) {
            return new c2s_reject_apply(properties);
        };

        /**
         * Encodes the specified c2s_reject_apply message. Does not implicitly {@link alliance.c2s_reject_apply.verify|verify} messages.
         * @function encode
         * @memberof alliance.c2s_reject_apply
         * @static
         * @param {alliance.c2s_reject_apply} message c2s_reject_apply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_reject_apply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.applyRid != null && Object.hasOwnProperty.call(message, "applyRid"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.applyRid);
            return writer;
        };

        /**
         * Decodes a c2s_reject_apply message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.c2s_reject_apply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.c2s_reject_apply} c2s_reject_apply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_reject_apply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.c2s_reject_apply();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.applyRid = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_reject_apply
         * @function getTypeUrl
         * @memberof alliance.c2s_reject_apply
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_reject_apply.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.c2s_reject_apply";
        };

        return c2s_reject_apply;
    })();

    alliance.s2c_reject_apply = (function() {

        /**
         * Properties of a s2c_reject_apply.
         * @memberof alliance
         * @interface Is2c_reject_apply
         * @property {number|null} [err] s2c_reject_apply err
         */

        /**
         * Constructs a new s2c_reject_apply.
         * @memberof alliance
         * @classdesc Represents a s2c_reject_apply.
         * @implements Is2c_reject_apply
         * @constructor
         * @param {alliance.Is2c_reject_apply=} [properties] Properties to set
         */
        function s2c_reject_apply(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_reject_apply err.
         * @member {number} err
         * @memberof alliance.s2c_reject_apply
         * @instance
         */
        s2c_reject_apply.prototype.err = 0;

        /**
         * Creates a new s2c_reject_apply instance using the specified properties.
         * @function create
         * @memberof alliance.s2c_reject_apply
         * @static
         * @param {alliance.Is2c_reject_apply=} [properties] Properties to set
         * @returns {alliance.s2c_reject_apply} s2c_reject_apply instance
         */
        s2c_reject_apply.create = function create(properties) {
            return new s2c_reject_apply(properties);
        };

        /**
         * Encodes the specified s2c_reject_apply message. Does not implicitly {@link alliance.s2c_reject_apply.verify|verify} messages.
         * @function encode
         * @memberof alliance.s2c_reject_apply
         * @static
         * @param {alliance.s2c_reject_apply} message s2c_reject_apply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_reject_apply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_reject_apply message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.s2c_reject_apply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.s2c_reject_apply} s2c_reject_apply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_reject_apply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.s2c_reject_apply();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_reject_apply
         * @function getTypeUrl
         * @memberof alliance.s2c_reject_apply
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_reject_apply.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.s2c_reject_apply";
        };

        return s2c_reject_apply;
    })();

    alliance.c2s_reject_all_apply = (function() {

        /**
         * Properties of a c2s_reject_all_apply.
         * @memberof alliance
         * @interface Ic2s_reject_all_apply
         */

        /**
         * Constructs a new c2s_reject_all_apply.
         * @memberof alliance
         * @classdesc Represents a c2s_reject_all_apply.
         * @implements Ic2s_reject_all_apply
         * @constructor
         * @param {alliance.Ic2s_reject_all_apply=} [properties] Properties to set
         */
        function c2s_reject_all_apply(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_reject_all_apply instance using the specified properties.
         * @function create
         * @memberof alliance.c2s_reject_all_apply
         * @static
         * @param {alliance.Ic2s_reject_all_apply=} [properties] Properties to set
         * @returns {alliance.c2s_reject_all_apply} c2s_reject_all_apply instance
         */
        c2s_reject_all_apply.create = function create(properties) {
            return new c2s_reject_all_apply(properties);
        };

        /**
         * Encodes the specified c2s_reject_all_apply message. Does not implicitly {@link alliance.c2s_reject_all_apply.verify|verify} messages.
         * @function encode
         * @memberof alliance.c2s_reject_all_apply
         * @static
         * @param {alliance.c2s_reject_all_apply} message c2s_reject_all_apply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_reject_all_apply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_reject_all_apply message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.c2s_reject_all_apply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.c2s_reject_all_apply} c2s_reject_all_apply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_reject_all_apply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.c2s_reject_all_apply();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_reject_all_apply
         * @function getTypeUrl
         * @memberof alliance.c2s_reject_all_apply
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_reject_all_apply.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.c2s_reject_all_apply";
        };

        return c2s_reject_all_apply;
    })();

    alliance.s2c_reject_all_apply = (function() {

        /**
         * Properties of a s2c_reject_all_apply.
         * @memberof alliance
         * @interface Is2c_reject_all_apply
         * @property {number|null} [err] s2c_reject_all_apply err
         */

        /**
         * Constructs a new s2c_reject_all_apply.
         * @memberof alliance
         * @classdesc Represents a s2c_reject_all_apply.
         * @implements Is2c_reject_all_apply
         * @constructor
         * @param {alliance.Is2c_reject_all_apply=} [properties] Properties to set
         */
        function s2c_reject_all_apply(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_reject_all_apply err.
         * @member {number} err
         * @memberof alliance.s2c_reject_all_apply
         * @instance
         */
        s2c_reject_all_apply.prototype.err = 0;

        /**
         * Creates a new s2c_reject_all_apply instance using the specified properties.
         * @function create
         * @memberof alliance.s2c_reject_all_apply
         * @static
         * @param {alliance.Is2c_reject_all_apply=} [properties] Properties to set
         * @returns {alliance.s2c_reject_all_apply} s2c_reject_all_apply instance
         */
        s2c_reject_all_apply.create = function create(properties) {
            return new s2c_reject_all_apply(properties);
        };

        /**
         * Encodes the specified s2c_reject_all_apply message. Does not implicitly {@link alliance.s2c_reject_all_apply.verify|verify} messages.
         * @function encode
         * @memberof alliance.s2c_reject_all_apply
         * @static
         * @param {alliance.s2c_reject_all_apply} message s2c_reject_all_apply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_reject_all_apply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_reject_all_apply message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.s2c_reject_all_apply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.s2c_reject_all_apply} s2c_reject_all_apply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_reject_all_apply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.s2c_reject_all_apply();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_reject_all_apply
         * @function getTypeUrl
         * @memberof alliance.s2c_reject_all_apply
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_reject_all_apply.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.s2c_reject_all_apply";
        };

        return s2c_reject_all_apply;
    })();

    alliance.c2s_process_apply = (function() {

        /**
         * Properties of a c2s_process_apply.
         * @memberof alliance
         * @interface Ic2s_process_apply
         * @property {number|null} [op] c2s_process_apply op
         * @property {number|null} [rid] c2s_process_apply rid
         */

        /**
         * Constructs a new c2s_process_apply.
         * @memberof alliance
         * @classdesc Represents a c2s_process_apply.
         * @implements Ic2s_process_apply
         * @constructor
         * @param {alliance.Ic2s_process_apply=} [properties] Properties to set
         */
        function c2s_process_apply(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_process_apply op.
         * @member {number} op
         * @memberof alliance.c2s_process_apply
         * @instance
         */
        c2s_process_apply.prototype.op = 0;

        /**
         * c2s_process_apply rid.
         * @member {number} rid
         * @memberof alliance.c2s_process_apply
         * @instance
         */
        c2s_process_apply.prototype.rid = 0;

        /**
         * Creates a new c2s_process_apply instance using the specified properties.
         * @function create
         * @memberof alliance.c2s_process_apply
         * @static
         * @param {alliance.Ic2s_process_apply=} [properties] Properties to set
         * @returns {alliance.c2s_process_apply} c2s_process_apply instance
         */
        c2s_process_apply.create = function create(properties) {
            return new c2s_process_apply(properties);
        };

        /**
         * Encodes the specified c2s_process_apply message. Does not implicitly {@link alliance.c2s_process_apply.verify|verify} messages.
         * @function encode
         * @memberof alliance.c2s_process_apply
         * @static
         * @param {alliance.c2s_process_apply} message c2s_process_apply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_process_apply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.op != null && Object.hasOwnProperty.call(message, "op"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.op);
            if (message.rid != null && Object.hasOwnProperty.call(message, "rid"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.rid);
            return writer;
        };

        /**
         * Decodes a c2s_process_apply message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.c2s_process_apply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.c2s_process_apply} c2s_process_apply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_process_apply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.c2s_process_apply();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.op = reader.uint32();
                        break;
                    }
                case 2: {
                        message.rid = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_process_apply
         * @function getTypeUrl
         * @memberof alliance.c2s_process_apply
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_process_apply.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.c2s_process_apply";
        };

        return c2s_process_apply;
    })();

    alliance.s2c_process_apply = (function() {

        /**
         * Properties of a s2c_process_apply.
         * @memberof alliance
         * @interface Is2c_process_apply
         * @property {number|null} [err] s2c_process_apply err
         */

        /**
         * Constructs a new s2c_process_apply.
         * @memberof alliance
         * @classdesc Represents a s2c_process_apply.
         * @implements Is2c_process_apply
         * @constructor
         * @param {alliance.Is2c_process_apply=} [properties] Properties to set
         */
        function s2c_process_apply(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_process_apply err.
         * @member {number} err
         * @memberof alliance.s2c_process_apply
         * @instance
         */
        s2c_process_apply.prototype.err = 0;

        /**
         * Creates a new s2c_process_apply instance using the specified properties.
         * @function create
         * @memberof alliance.s2c_process_apply
         * @static
         * @param {alliance.Is2c_process_apply=} [properties] Properties to set
         * @returns {alliance.s2c_process_apply} s2c_process_apply instance
         */
        s2c_process_apply.create = function create(properties) {
            return new s2c_process_apply(properties);
        };

        /**
         * Encodes the specified s2c_process_apply message. Does not implicitly {@link alliance.s2c_process_apply.verify|verify} messages.
         * @function encode
         * @memberof alliance.s2c_process_apply
         * @static
         * @param {alliance.s2c_process_apply} message s2c_process_apply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_process_apply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_process_apply message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.s2c_process_apply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.s2c_process_apply} s2c_process_apply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_process_apply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.s2c_process_apply();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_process_apply
         * @function getTypeUrl
         * @memberof alliance.s2c_process_apply
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_process_apply.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.s2c_process_apply";
        };

        return s2c_process_apply;
    })();

    alliance.AllianceApplyRoleItem = (function() {

        /**
         * Properties of an AllianceApplyRoleItem.
         * @memberof alliance
         * @interface IAllianceApplyRoleItem
         * @property {number|null} [rid] AllianceApplyRoleItem rid
         * @property {number|null} [pic] AllianceApplyRoleItem pic
         * @property {string|null} [name] AllianceApplyRoleItem name
         * @property {number|null} [createTime] AllianceApplyRoleItem createTime
         */

        /**
         * Constructs a new AllianceApplyRoleItem.
         * @memberof alliance
         * @classdesc Represents an AllianceApplyRoleItem.
         * @implements IAllianceApplyRoleItem
         * @constructor
         * @param {alliance.IAllianceApplyRoleItem=} [properties] Properties to set
         */
        function AllianceApplyRoleItem(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AllianceApplyRoleItem rid.
         * @member {number} rid
         * @memberof alliance.AllianceApplyRoleItem
         * @instance
         */
        AllianceApplyRoleItem.prototype.rid = 0;

        /**
         * AllianceApplyRoleItem pic.
         * @member {number} pic
         * @memberof alliance.AllianceApplyRoleItem
         * @instance
         */
        AllianceApplyRoleItem.prototype.pic = 0;

        /**
         * AllianceApplyRoleItem name.
         * @member {string} name
         * @memberof alliance.AllianceApplyRoleItem
         * @instance
         */
        AllianceApplyRoleItem.prototype.name = "";

        /**
         * AllianceApplyRoleItem createTime.
         * @member {number} createTime
         * @memberof alliance.AllianceApplyRoleItem
         * @instance
         */
        AllianceApplyRoleItem.prototype.createTime = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new AllianceApplyRoleItem instance using the specified properties.
         * @function create
         * @memberof alliance.AllianceApplyRoleItem
         * @static
         * @param {alliance.IAllianceApplyRoleItem=} [properties] Properties to set
         * @returns {alliance.AllianceApplyRoleItem} AllianceApplyRoleItem instance
         */
        AllianceApplyRoleItem.create = function create(properties) {
            return new AllianceApplyRoleItem(properties);
        };

        /**
         * Encodes the specified AllianceApplyRoleItem message. Does not implicitly {@link alliance.AllianceApplyRoleItem.verify|verify} messages.
         * @function encode
         * @memberof alliance.AllianceApplyRoleItem
         * @static
         * @param {alliance.AllianceApplyRoleItem} message AllianceApplyRoleItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AllianceApplyRoleItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rid != null && Object.hasOwnProperty.call(message, "rid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.rid);
            if (message.pic != null && Object.hasOwnProperty.call(message, "pic"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.pic);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.createTime != null && Object.hasOwnProperty.call(message, "createTime"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.createTime);
            return writer;
        };

        /**
         * Decodes an AllianceApplyRoleItem message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.AllianceApplyRoleItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.AllianceApplyRoleItem} AllianceApplyRoleItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AllianceApplyRoleItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.AllianceApplyRoleItem();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.pic = reader.uint32();
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                case 4: {
                        message.createTime = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for AllianceApplyRoleItem
         * @function getTypeUrl
         * @memberof alliance.AllianceApplyRoleItem
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AllianceApplyRoleItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.AllianceApplyRoleItem";
        };

        return AllianceApplyRoleItem;
    })();

    alliance.c2s_get_apply_list = (function() {

        /**
         * Properties of a c2s_get_apply_list.
         * @memberof alliance
         * @interface Ic2s_get_apply_list
         */

        /**
         * Constructs a new c2s_get_apply_list.
         * @memberof alliance
         * @classdesc Represents a c2s_get_apply_list.
         * @implements Ic2s_get_apply_list
         * @constructor
         * @param {alliance.Ic2s_get_apply_list=} [properties] Properties to set
         */
        function c2s_get_apply_list(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_get_apply_list instance using the specified properties.
         * @function create
         * @memberof alliance.c2s_get_apply_list
         * @static
         * @param {alliance.Ic2s_get_apply_list=} [properties] Properties to set
         * @returns {alliance.c2s_get_apply_list} c2s_get_apply_list instance
         */
        c2s_get_apply_list.create = function create(properties) {
            return new c2s_get_apply_list(properties);
        };

        /**
         * Encodes the specified c2s_get_apply_list message. Does not implicitly {@link alliance.c2s_get_apply_list.verify|verify} messages.
         * @function encode
         * @memberof alliance.c2s_get_apply_list
         * @static
         * @param {alliance.c2s_get_apply_list} message c2s_get_apply_list message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_get_apply_list.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_get_apply_list message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.c2s_get_apply_list
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.c2s_get_apply_list} c2s_get_apply_list
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_get_apply_list.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.c2s_get_apply_list();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_get_apply_list
         * @function getTypeUrl
         * @memberof alliance.c2s_get_apply_list
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_get_apply_list.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.c2s_get_apply_list";
        };

        return c2s_get_apply_list;
    })();

    alliance.s2c_get_apply_list = (function() {

        /**
         * Properties of a s2c_get_apply_list.
         * @memberof alliance
         * @interface Is2c_get_apply_list
         * @property {number|null} [err] s2c_get_apply_list err
         * @property {Array.<alliance.AllianceApplyRoleItem>|null} [list] s2c_get_apply_list list
         */

        /**
         * Constructs a new s2c_get_apply_list.
         * @memberof alliance
         * @classdesc Represents a s2c_get_apply_list.
         * @implements Is2c_get_apply_list
         * @constructor
         * @param {alliance.Is2c_get_apply_list=} [properties] Properties to set
         */
        function s2c_get_apply_list(properties) {
            this.list = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_get_apply_list err.
         * @member {number} err
         * @memberof alliance.s2c_get_apply_list
         * @instance
         */
        s2c_get_apply_list.prototype.err = 0;

        /**
         * s2c_get_apply_list list.
         * @member {Array.<alliance.AllianceApplyRoleItem>} list
         * @memberof alliance.s2c_get_apply_list
         * @instance
         */
        s2c_get_apply_list.prototype.list = $util.emptyArray;

        /**
         * Creates a new s2c_get_apply_list instance using the specified properties.
         * @function create
         * @memberof alliance.s2c_get_apply_list
         * @static
         * @param {alliance.Is2c_get_apply_list=} [properties] Properties to set
         * @returns {alliance.s2c_get_apply_list} s2c_get_apply_list instance
         */
        s2c_get_apply_list.create = function create(properties) {
            return new s2c_get_apply_list(properties);
        };

        /**
         * Encodes the specified s2c_get_apply_list message. Does not implicitly {@link alliance.s2c_get_apply_list.verify|verify} messages.
         * @function encode
         * @memberof alliance.s2c_get_apply_list
         * @static
         * @param {alliance.s2c_get_apply_list} message s2c_get_apply_list message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_get_apply_list.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.list != null && message.list.length)
                for (let i = 0; i < message.list.length; ++i)
                    $root.alliance.AllianceApplyRoleItem.encode(message.list[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_get_apply_list message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.s2c_get_apply_list
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.s2c_get_apply_list} s2c_get_apply_list
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_get_apply_list.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.s2c_get_apply_list();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.list && message.list.length))
                            message.list = [];
                        message.list.push($root.alliance.AllianceApplyRoleItem.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_get_apply_list
         * @function getTypeUrl
         * @memberof alliance.s2c_get_apply_list
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_get_apply_list.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.s2c_get_apply_list";
        };

        return s2c_get_apply_list;
    })();

    alliance.c2s_cancel_my_apply = (function() {

        /**
         * Properties of a c2s_cancel_my_apply.
         * @memberof alliance
         * @interface Ic2s_cancel_my_apply
         * @property {number|null} [allianceId] c2s_cancel_my_apply allianceId
         */

        /**
         * Constructs a new c2s_cancel_my_apply.
         * @memberof alliance
         * @classdesc Represents a c2s_cancel_my_apply.
         * @implements Ic2s_cancel_my_apply
         * @constructor
         * @param {alliance.Ic2s_cancel_my_apply=} [properties] Properties to set
         */
        function c2s_cancel_my_apply(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_cancel_my_apply allianceId.
         * @member {number} allianceId
         * @memberof alliance.c2s_cancel_my_apply
         * @instance
         */
        c2s_cancel_my_apply.prototype.allianceId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new c2s_cancel_my_apply instance using the specified properties.
         * @function create
         * @memberof alliance.c2s_cancel_my_apply
         * @static
         * @param {alliance.Ic2s_cancel_my_apply=} [properties] Properties to set
         * @returns {alliance.c2s_cancel_my_apply} c2s_cancel_my_apply instance
         */
        c2s_cancel_my_apply.create = function create(properties) {
            return new c2s_cancel_my_apply(properties);
        };

        /**
         * Encodes the specified c2s_cancel_my_apply message. Does not implicitly {@link alliance.c2s_cancel_my_apply.verify|verify} messages.
         * @function encode
         * @memberof alliance.c2s_cancel_my_apply
         * @static
         * @param {alliance.c2s_cancel_my_apply} message c2s_cancel_my_apply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_cancel_my_apply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.allianceId != null && Object.hasOwnProperty.call(message, "allianceId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.allianceId);
            return writer;
        };

        /**
         * Decodes a c2s_cancel_my_apply message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.c2s_cancel_my_apply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.c2s_cancel_my_apply} c2s_cancel_my_apply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_cancel_my_apply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.c2s_cancel_my_apply();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.allianceId = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_cancel_my_apply
         * @function getTypeUrl
         * @memberof alliance.c2s_cancel_my_apply
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_cancel_my_apply.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.c2s_cancel_my_apply";
        };

        return c2s_cancel_my_apply;
    })();

    alliance.s2c_cancel_my_apply = (function() {

        /**
         * Properties of a s2c_cancel_my_apply.
         * @memberof alliance
         * @interface Is2c_cancel_my_apply
         * @property {number|null} [err] s2c_cancel_my_apply err
         */

        /**
         * Constructs a new s2c_cancel_my_apply.
         * @memberof alliance
         * @classdesc Represents a s2c_cancel_my_apply.
         * @implements Is2c_cancel_my_apply
         * @constructor
         * @param {alliance.Is2c_cancel_my_apply=} [properties] Properties to set
         */
        function s2c_cancel_my_apply(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_cancel_my_apply err.
         * @member {number} err
         * @memberof alliance.s2c_cancel_my_apply
         * @instance
         */
        s2c_cancel_my_apply.prototype.err = 0;

        /**
         * Creates a new s2c_cancel_my_apply instance using the specified properties.
         * @function create
         * @memberof alliance.s2c_cancel_my_apply
         * @static
         * @param {alliance.Is2c_cancel_my_apply=} [properties] Properties to set
         * @returns {alliance.s2c_cancel_my_apply} s2c_cancel_my_apply instance
         */
        s2c_cancel_my_apply.create = function create(properties) {
            return new s2c_cancel_my_apply(properties);
        };

        /**
         * Encodes the specified s2c_cancel_my_apply message. Does not implicitly {@link alliance.s2c_cancel_my_apply.verify|verify} messages.
         * @function encode
         * @memberof alliance.s2c_cancel_my_apply
         * @static
         * @param {alliance.s2c_cancel_my_apply} message s2c_cancel_my_apply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_cancel_my_apply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_cancel_my_apply message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.s2c_cancel_my_apply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.s2c_cancel_my_apply} s2c_cancel_my_apply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_cancel_my_apply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.s2c_cancel_my_apply();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_cancel_my_apply
         * @function getTypeUrl
         * @memberof alliance.s2c_cancel_my_apply
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_cancel_my_apply.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.s2c_cancel_my_apply";
        };

        return s2c_cancel_my_apply;
    })();

    alliance.c2s_quit_alliance = (function() {

        /**
         * Properties of a c2s_quit_alliance.
         * @memberof alliance
         * @interface Ic2s_quit_alliance
         */

        /**
         * Constructs a new c2s_quit_alliance.
         * @memberof alliance
         * @classdesc Represents a c2s_quit_alliance.
         * @implements Ic2s_quit_alliance
         * @constructor
         * @param {alliance.Ic2s_quit_alliance=} [properties] Properties to set
         */
        function c2s_quit_alliance(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_quit_alliance instance using the specified properties.
         * @function create
         * @memberof alliance.c2s_quit_alliance
         * @static
         * @param {alliance.Ic2s_quit_alliance=} [properties] Properties to set
         * @returns {alliance.c2s_quit_alliance} c2s_quit_alliance instance
         */
        c2s_quit_alliance.create = function create(properties) {
            return new c2s_quit_alliance(properties);
        };

        /**
         * Encodes the specified c2s_quit_alliance message. Does not implicitly {@link alliance.c2s_quit_alliance.verify|verify} messages.
         * @function encode
         * @memberof alliance.c2s_quit_alliance
         * @static
         * @param {alliance.c2s_quit_alliance} message c2s_quit_alliance message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_quit_alliance.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_quit_alliance message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.c2s_quit_alliance
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.c2s_quit_alliance} c2s_quit_alliance
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_quit_alliance.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.c2s_quit_alliance();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_quit_alliance
         * @function getTypeUrl
         * @memberof alliance.c2s_quit_alliance
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_quit_alliance.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.c2s_quit_alliance";
        };

        return c2s_quit_alliance;
    })();

    alliance.s2c_quit_alliance = (function() {

        /**
         * Properties of a s2c_quit_alliance.
         * @memberof alliance
         * @interface Is2c_quit_alliance
         * @property {number|null} [err] s2c_quit_alliance err
         */

        /**
         * Constructs a new s2c_quit_alliance.
         * @memberof alliance
         * @classdesc Represents a s2c_quit_alliance.
         * @implements Is2c_quit_alliance
         * @constructor
         * @param {alliance.Is2c_quit_alliance=} [properties] Properties to set
         */
        function s2c_quit_alliance(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_quit_alliance err.
         * @member {number} err
         * @memberof alliance.s2c_quit_alliance
         * @instance
         */
        s2c_quit_alliance.prototype.err = 0;

        /**
         * Creates a new s2c_quit_alliance instance using the specified properties.
         * @function create
         * @memberof alliance.s2c_quit_alliance
         * @static
         * @param {alliance.Is2c_quit_alliance=} [properties] Properties to set
         * @returns {alliance.s2c_quit_alliance} s2c_quit_alliance instance
         */
        s2c_quit_alliance.create = function create(properties) {
            return new s2c_quit_alliance(properties);
        };

        /**
         * Encodes the specified s2c_quit_alliance message. Does not implicitly {@link alliance.s2c_quit_alliance.verify|verify} messages.
         * @function encode
         * @memberof alliance.s2c_quit_alliance
         * @static
         * @param {alliance.s2c_quit_alliance} message s2c_quit_alliance message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_quit_alliance.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_quit_alliance message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.s2c_quit_alliance
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.s2c_quit_alliance} s2c_quit_alliance
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_quit_alliance.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.s2c_quit_alliance();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_quit_alliance
         * @function getTypeUrl
         * @memberof alliance.s2c_quit_alliance
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_quit_alliance.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.s2c_quit_alliance";
        };

        return s2c_quit_alliance;
    })();

    alliance.c2s_dismiss_alliance = (function() {

        /**
         * Properties of a c2s_dismiss_alliance.
         * @memberof alliance
         * @interface Ic2s_dismiss_alliance
         */

        /**
         * Constructs a new c2s_dismiss_alliance.
         * @memberof alliance
         * @classdesc Represents a c2s_dismiss_alliance.
         * @implements Ic2s_dismiss_alliance
         * @constructor
         * @param {alliance.Ic2s_dismiss_alliance=} [properties] Properties to set
         */
        function c2s_dismiss_alliance(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_dismiss_alliance instance using the specified properties.
         * @function create
         * @memberof alliance.c2s_dismiss_alliance
         * @static
         * @param {alliance.Ic2s_dismiss_alliance=} [properties] Properties to set
         * @returns {alliance.c2s_dismiss_alliance} c2s_dismiss_alliance instance
         */
        c2s_dismiss_alliance.create = function create(properties) {
            return new c2s_dismiss_alliance(properties);
        };

        /**
         * Encodes the specified c2s_dismiss_alliance message. Does not implicitly {@link alliance.c2s_dismiss_alliance.verify|verify} messages.
         * @function encode
         * @memberof alliance.c2s_dismiss_alliance
         * @static
         * @param {alliance.c2s_dismiss_alliance} message c2s_dismiss_alliance message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_dismiss_alliance.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_dismiss_alliance message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.c2s_dismiss_alliance
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.c2s_dismiss_alliance} c2s_dismiss_alliance
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_dismiss_alliance.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.c2s_dismiss_alliance();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_dismiss_alliance
         * @function getTypeUrl
         * @memberof alliance.c2s_dismiss_alliance
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_dismiss_alliance.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.c2s_dismiss_alliance";
        };

        return c2s_dismiss_alliance;
    })();

    alliance.s2c_dismiss_alliance = (function() {

        /**
         * Properties of a s2c_dismiss_alliance.
         * @memberof alliance
         * @interface Is2c_dismiss_alliance
         * @property {number|null} [err] s2c_dismiss_alliance err
         */

        /**
         * Constructs a new s2c_dismiss_alliance.
         * @memberof alliance
         * @classdesc Represents a s2c_dismiss_alliance.
         * @implements Is2c_dismiss_alliance
         * @constructor
         * @param {alliance.Is2c_dismiss_alliance=} [properties] Properties to set
         */
        function s2c_dismiss_alliance(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_dismiss_alliance err.
         * @member {number} err
         * @memberof alliance.s2c_dismiss_alliance
         * @instance
         */
        s2c_dismiss_alliance.prototype.err = 0;

        /**
         * Creates a new s2c_dismiss_alliance instance using the specified properties.
         * @function create
         * @memberof alliance.s2c_dismiss_alliance
         * @static
         * @param {alliance.Is2c_dismiss_alliance=} [properties] Properties to set
         * @returns {alliance.s2c_dismiss_alliance} s2c_dismiss_alliance instance
         */
        s2c_dismiss_alliance.create = function create(properties) {
            return new s2c_dismiss_alliance(properties);
        };

        /**
         * Encodes the specified s2c_dismiss_alliance message. Does not implicitly {@link alliance.s2c_dismiss_alliance.verify|verify} messages.
         * @function encode
         * @memberof alliance.s2c_dismiss_alliance
         * @static
         * @param {alliance.s2c_dismiss_alliance} message s2c_dismiss_alliance message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_dismiss_alliance.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_dismiss_alliance message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.s2c_dismiss_alliance
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.s2c_dismiss_alliance} s2c_dismiss_alliance
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_dismiss_alliance.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.s2c_dismiss_alliance();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_dismiss_alliance
         * @function getTypeUrl
         * @memberof alliance.s2c_dismiss_alliance
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_dismiss_alliance.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.s2c_dismiss_alliance";
        };

        return s2c_dismiss_alliance;
    })();

    alliance.AllianceListItem = (function() {

        /**
         * Properties of an AllianceListItem.
         * @memberof alliance
         * @interface IAllianceListItem
         * @property {number|null} [allianceId] AllianceListItem allianceId
         * @property {alliance.AllianceProfile|null} [profile] AllianceListItem profile
         * @property {number|null} [memberCnt] AllianceListItem memberCnt
         */

        /**
         * Constructs a new AllianceListItem.
         * @memberof alliance
         * @classdesc Represents an AllianceListItem.
         * @implements IAllianceListItem
         * @constructor
         * @param {alliance.IAllianceListItem=} [properties] Properties to set
         */
        function AllianceListItem(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AllianceListItem allianceId.
         * @member {number} allianceId
         * @memberof alliance.AllianceListItem
         * @instance
         */
        AllianceListItem.prototype.allianceId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * AllianceListItem profile.
         * @member {alliance.AllianceProfile|null|undefined} profile
         * @memberof alliance.AllianceListItem
         * @instance
         */
        AllianceListItem.prototype.profile = null;

        /**
         * AllianceListItem memberCnt.
         * @member {number} memberCnt
         * @memberof alliance.AllianceListItem
         * @instance
         */
        AllianceListItem.prototype.memberCnt = 0;

        /**
         * Creates a new AllianceListItem instance using the specified properties.
         * @function create
         * @memberof alliance.AllianceListItem
         * @static
         * @param {alliance.IAllianceListItem=} [properties] Properties to set
         * @returns {alliance.AllianceListItem} AllianceListItem instance
         */
        AllianceListItem.create = function create(properties) {
            return new AllianceListItem(properties);
        };

        /**
         * Encodes the specified AllianceListItem message. Does not implicitly {@link alliance.AllianceListItem.verify|verify} messages.
         * @function encode
         * @memberof alliance.AllianceListItem
         * @static
         * @param {alliance.AllianceListItem} message AllianceListItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AllianceListItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.allianceId != null && Object.hasOwnProperty.call(message, "allianceId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.allianceId);
            if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
                $root.alliance.AllianceProfile.encode(message.profile, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.memberCnt != null && Object.hasOwnProperty.call(message, "memberCnt"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.memberCnt);
            return writer;
        };

        /**
         * Decodes an AllianceListItem message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.AllianceListItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.AllianceListItem} AllianceListItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AllianceListItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.AllianceListItem();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.allianceId = reader.int64();
                        break;
                    }
                case 2: {
                        message.profile = $root.alliance.AllianceProfile.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.memberCnt = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for AllianceListItem
         * @function getTypeUrl
         * @memberof alliance.AllianceListItem
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AllianceListItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.AllianceListItem";
        };

        return AllianceListItem;
    })();

    alliance.c2s_get_alliance_list = (function() {

        /**
         * Properties of a c2s_get_alliance_list.
         * @memberof alliance
         * @interface Ic2s_get_alliance_list
         */

        /**
         * Constructs a new c2s_get_alliance_list.
         * @memberof alliance
         * @classdesc Represents a c2s_get_alliance_list.
         * @implements Ic2s_get_alliance_list
         * @constructor
         * @param {alliance.Ic2s_get_alliance_list=} [properties] Properties to set
         */
        function c2s_get_alliance_list(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_get_alliance_list instance using the specified properties.
         * @function create
         * @memberof alliance.c2s_get_alliance_list
         * @static
         * @param {alliance.Ic2s_get_alliance_list=} [properties] Properties to set
         * @returns {alliance.c2s_get_alliance_list} c2s_get_alliance_list instance
         */
        c2s_get_alliance_list.create = function create(properties) {
            return new c2s_get_alliance_list(properties);
        };

        /**
         * Encodes the specified c2s_get_alliance_list message. Does not implicitly {@link alliance.c2s_get_alliance_list.verify|verify} messages.
         * @function encode
         * @memberof alliance.c2s_get_alliance_list
         * @static
         * @param {alliance.c2s_get_alliance_list} message c2s_get_alliance_list message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_get_alliance_list.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_get_alliance_list message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.c2s_get_alliance_list
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.c2s_get_alliance_list} c2s_get_alliance_list
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_get_alliance_list.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.c2s_get_alliance_list();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_get_alliance_list
         * @function getTypeUrl
         * @memberof alliance.c2s_get_alliance_list
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_get_alliance_list.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.c2s_get_alliance_list";
        };

        return c2s_get_alliance_list;
    })();

    alliance.s2c_get_alliance_list = (function() {

        /**
         * Properties of a s2c_get_alliance_list.
         * @memberof alliance
         * @interface Is2c_get_alliance_list
         * @property {number|null} [err] s2c_get_alliance_list err
         * @property {Array.<alliance.AllianceListItem>|null} [list] s2c_get_alliance_list list
         * @property {number|null} [joinCdExpireTime] s2c_get_alliance_list joinCdExpireTime
         */

        /**
         * Constructs a new s2c_get_alliance_list.
         * @memberof alliance
         * @classdesc Represents a s2c_get_alliance_list.
         * @implements Is2c_get_alliance_list
         * @constructor
         * @param {alliance.Is2c_get_alliance_list=} [properties] Properties to set
         */
        function s2c_get_alliance_list(properties) {
            this.list = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_get_alliance_list err.
         * @member {number} err
         * @memberof alliance.s2c_get_alliance_list
         * @instance
         */
        s2c_get_alliance_list.prototype.err = 0;

        /**
         * s2c_get_alliance_list list.
         * @member {Array.<alliance.AllianceListItem>} list
         * @memberof alliance.s2c_get_alliance_list
         * @instance
         */
        s2c_get_alliance_list.prototype.list = $util.emptyArray;

        /**
         * s2c_get_alliance_list joinCdExpireTime.
         * @member {number} joinCdExpireTime
         * @memberof alliance.s2c_get_alliance_list
         * @instance
         */
        s2c_get_alliance_list.prototype.joinCdExpireTime = 0;

        /**
         * Creates a new s2c_get_alliance_list instance using the specified properties.
         * @function create
         * @memberof alliance.s2c_get_alliance_list
         * @static
         * @param {alliance.Is2c_get_alliance_list=} [properties] Properties to set
         * @returns {alliance.s2c_get_alliance_list} s2c_get_alliance_list instance
         */
        s2c_get_alliance_list.create = function create(properties) {
            return new s2c_get_alliance_list(properties);
        };

        /**
         * Encodes the specified s2c_get_alliance_list message. Does not implicitly {@link alliance.s2c_get_alliance_list.verify|verify} messages.
         * @function encode
         * @memberof alliance.s2c_get_alliance_list
         * @static
         * @param {alliance.s2c_get_alliance_list} message s2c_get_alliance_list message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_get_alliance_list.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.list != null && message.list.length)
                for (let i = 0; i < message.list.length; ++i)
                    $root.alliance.AllianceListItem.encode(message.list[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.joinCdExpireTime != null && Object.hasOwnProperty.call(message, "joinCdExpireTime"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.joinCdExpireTime);
            return writer;
        };

        /**
         * Decodes a s2c_get_alliance_list message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.s2c_get_alliance_list
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.s2c_get_alliance_list} s2c_get_alliance_list
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_get_alliance_list.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.s2c_get_alliance_list();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.list && message.list.length))
                            message.list = [];
                        message.list.push($root.alliance.AllianceListItem.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.joinCdExpireTime = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_get_alliance_list
         * @function getTypeUrl
         * @memberof alliance.s2c_get_alliance_list
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_get_alliance_list.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.s2c_get_alliance_list";
        };

        return s2c_get_alliance_list;
    })();

    alliance.c2s_search_alliance_by_name = (function() {

        /**
         * Properties of a c2s_search_alliance_by_name.
         * @memberof alliance
         * @interface Ic2s_search_alliance_by_name
         * @property {string|null} [name] c2s_search_alliance_by_name name
         */

        /**
         * Constructs a new c2s_search_alliance_by_name.
         * @memberof alliance
         * @classdesc Represents a c2s_search_alliance_by_name.
         * @implements Ic2s_search_alliance_by_name
         * @constructor
         * @param {alliance.Ic2s_search_alliance_by_name=} [properties] Properties to set
         */
        function c2s_search_alliance_by_name(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_search_alliance_by_name name.
         * @member {string} name
         * @memberof alliance.c2s_search_alliance_by_name
         * @instance
         */
        c2s_search_alliance_by_name.prototype.name = "";

        /**
         * Creates a new c2s_search_alliance_by_name instance using the specified properties.
         * @function create
         * @memberof alliance.c2s_search_alliance_by_name
         * @static
         * @param {alliance.Ic2s_search_alliance_by_name=} [properties] Properties to set
         * @returns {alliance.c2s_search_alliance_by_name} c2s_search_alliance_by_name instance
         */
        c2s_search_alliance_by_name.create = function create(properties) {
            return new c2s_search_alliance_by_name(properties);
        };

        /**
         * Encodes the specified c2s_search_alliance_by_name message. Does not implicitly {@link alliance.c2s_search_alliance_by_name.verify|verify} messages.
         * @function encode
         * @memberof alliance.c2s_search_alliance_by_name
         * @static
         * @param {alliance.c2s_search_alliance_by_name} message c2s_search_alliance_by_name message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_search_alliance_by_name.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            return writer;
        };

        /**
         * Decodes a c2s_search_alliance_by_name message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.c2s_search_alliance_by_name
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.c2s_search_alliance_by_name} c2s_search_alliance_by_name
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_search_alliance_by_name.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.c2s_search_alliance_by_name();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_search_alliance_by_name
         * @function getTypeUrl
         * @memberof alliance.c2s_search_alliance_by_name
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_search_alliance_by_name.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.c2s_search_alliance_by_name";
        };

        return c2s_search_alliance_by_name;
    })();

    alliance.s2c_search_alliance_by_name = (function() {

        /**
         * Properties of a s2c_search_alliance_by_name.
         * @memberof alliance
         * @interface Is2c_search_alliance_by_name
         * @property {number|null} [err] s2c_search_alliance_by_name err
         * @property {Array.<alliance.AllianceListItem>|null} [list] s2c_search_alliance_by_name list
         */

        /**
         * Constructs a new s2c_search_alliance_by_name.
         * @memberof alliance
         * @classdesc Represents a s2c_search_alliance_by_name.
         * @implements Is2c_search_alliance_by_name
         * @constructor
         * @param {alliance.Is2c_search_alliance_by_name=} [properties] Properties to set
         */
        function s2c_search_alliance_by_name(properties) {
            this.list = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_search_alliance_by_name err.
         * @member {number} err
         * @memberof alliance.s2c_search_alliance_by_name
         * @instance
         */
        s2c_search_alliance_by_name.prototype.err = 0;

        /**
         * s2c_search_alliance_by_name list.
         * @member {Array.<alliance.AllianceListItem>} list
         * @memberof alliance.s2c_search_alliance_by_name
         * @instance
         */
        s2c_search_alliance_by_name.prototype.list = $util.emptyArray;

        /**
         * Creates a new s2c_search_alliance_by_name instance using the specified properties.
         * @function create
         * @memberof alliance.s2c_search_alliance_by_name
         * @static
         * @param {alliance.Is2c_search_alliance_by_name=} [properties] Properties to set
         * @returns {alliance.s2c_search_alliance_by_name} s2c_search_alliance_by_name instance
         */
        s2c_search_alliance_by_name.create = function create(properties) {
            return new s2c_search_alliance_by_name(properties);
        };

        /**
         * Encodes the specified s2c_search_alliance_by_name message. Does not implicitly {@link alliance.s2c_search_alliance_by_name.verify|verify} messages.
         * @function encode
         * @memberof alliance.s2c_search_alliance_by_name
         * @static
         * @param {alliance.s2c_search_alliance_by_name} message s2c_search_alliance_by_name message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_search_alliance_by_name.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.list != null && message.list.length)
                for (let i = 0; i < message.list.length; ++i)
                    $root.alliance.AllianceListItem.encode(message.list[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_search_alliance_by_name message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.s2c_search_alliance_by_name
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.s2c_search_alliance_by_name} s2c_search_alliance_by_name
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_search_alliance_by_name.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.s2c_search_alliance_by_name();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.list && message.list.length))
                            message.list = [];
                        message.list.push($root.alliance.AllianceListItem.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_search_alliance_by_name
         * @function getTypeUrl
         * @memberof alliance.s2c_search_alliance_by_name
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_search_alliance_by_name.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.s2c_search_alliance_by_name";
        };

        return s2c_search_alliance_by_name;
    })();

    alliance.c2s_update_alliance_info = (function() {

        /**
         * Properties of a c2s_update_alliance_info.
         * @memberof alliance
         * @interface Ic2s_update_alliance_info
         * @property {string|null} [name] c2s_update_alliance_info name
         * @property {number|null} [emblem] c2s_update_alliance_info emblem
         * @property {number|null} [color] c2s_update_alliance_info color
         * @property {string|null} [announcement] c2s_update_alliance_info announcement
         * @property {number|null} [joinType] c2s_update_alliance_info joinType
         * @property {number|null} [requireLv] c2s_update_alliance_info requireLv
         */

        /**
         * Constructs a new c2s_update_alliance_info.
         * @memberof alliance
         * @classdesc Represents a c2s_update_alliance_info.
         * @implements Ic2s_update_alliance_info
         * @constructor
         * @param {alliance.Ic2s_update_alliance_info=} [properties] Properties to set
         */
        function c2s_update_alliance_info(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_update_alliance_info name.
         * @member {string} name
         * @memberof alliance.c2s_update_alliance_info
         * @instance
         */
        c2s_update_alliance_info.prototype.name = "";

        /**
         * c2s_update_alliance_info emblem.
         * @member {number} emblem
         * @memberof alliance.c2s_update_alliance_info
         * @instance
         */
        c2s_update_alliance_info.prototype.emblem = 0;

        /**
         * c2s_update_alliance_info color.
         * @member {number} color
         * @memberof alliance.c2s_update_alliance_info
         * @instance
         */
        c2s_update_alliance_info.prototype.color = 0;

        /**
         * c2s_update_alliance_info announcement.
         * @member {string} announcement
         * @memberof alliance.c2s_update_alliance_info
         * @instance
         */
        c2s_update_alliance_info.prototype.announcement = "";

        /**
         * c2s_update_alliance_info joinType.
         * @member {number} joinType
         * @memberof alliance.c2s_update_alliance_info
         * @instance
         */
        c2s_update_alliance_info.prototype.joinType = 0;

        /**
         * c2s_update_alliance_info requireLv.
         * @member {number} requireLv
         * @memberof alliance.c2s_update_alliance_info
         * @instance
         */
        c2s_update_alliance_info.prototype.requireLv = 0;

        /**
         * Creates a new c2s_update_alliance_info instance using the specified properties.
         * @function create
         * @memberof alliance.c2s_update_alliance_info
         * @static
         * @param {alliance.Ic2s_update_alliance_info=} [properties] Properties to set
         * @returns {alliance.c2s_update_alliance_info} c2s_update_alliance_info instance
         */
        c2s_update_alliance_info.create = function create(properties) {
            return new c2s_update_alliance_info(properties);
        };

        /**
         * Encodes the specified c2s_update_alliance_info message. Does not implicitly {@link alliance.c2s_update_alliance_info.verify|verify} messages.
         * @function encode
         * @memberof alliance.c2s_update_alliance_info
         * @static
         * @param {alliance.c2s_update_alliance_info} message c2s_update_alliance_info message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_update_alliance_info.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.emblem != null && Object.hasOwnProperty.call(message, "emblem"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.emblem);
            if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.color);
            if (message.announcement != null && Object.hasOwnProperty.call(message, "announcement"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.announcement);
            if (message.joinType != null && Object.hasOwnProperty.call(message, "joinType"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.joinType);
            if (message.requireLv != null && Object.hasOwnProperty.call(message, "requireLv"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.requireLv);
            return writer;
        };

        /**
         * Decodes a c2s_update_alliance_info message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.c2s_update_alliance_info
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.c2s_update_alliance_info} c2s_update_alliance_info
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_update_alliance_info.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.c2s_update_alliance_info();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.emblem = reader.uint32();
                        break;
                    }
                case 3: {
                        message.color = reader.uint32();
                        break;
                    }
                case 4: {
                        message.announcement = reader.string();
                        break;
                    }
                case 5: {
                        message.joinType = reader.uint32();
                        break;
                    }
                case 6: {
                        message.requireLv = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_update_alliance_info
         * @function getTypeUrl
         * @memberof alliance.c2s_update_alliance_info
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_update_alliance_info.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.c2s_update_alliance_info";
        };

        return c2s_update_alliance_info;
    })();

    alliance.s2c_update_alliance_info = (function() {

        /**
         * Properties of a s2c_update_alliance_info.
         * @memberof alliance
         * @interface Is2c_update_alliance_info
         * @property {number|null} [err] s2c_update_alliance_info err
         */

        /**
         * Constructs a new s2c_update_alliance_info.
         * @memberof alliance
         * @classdesc Represents a s2c_update_alliance_info.
         * @implements Is2c_update_alliance_info
         * @constructor
         * @param {alliance.Is2c_update_alliance_info=} [properties] Properties to set
         */
        function s2c_update_alliance_info(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_update_alliance_info err.
         * @member {number} err
         * @memberof alliance.s2c_update_alliance_info
         * @instance
         */
        s2c_update_alliance_info.prototype.err = 0;

        /**
         * Creates a new s2c_update_alliance_info instance using the specified properties.
         * @function create
         * @memberof alliance.s2c_update_alliance_info
         * @static
         * @param {alliance.Is2c_update_alliance_info=} [properties] Properties to set
         * @returns {alliance.s2c_update_alliance_info} s2c_update_alliance_info instance
         */
        s2c_update_alliance_info.create = function create(properties) {
            return new s2c_update_alliance_info(properties);
        };

        /**
         * Encodes the specified s2c_update_alliance_info message. Does not implicitly {@link alliance.s2c_update_alliance_info.verify|verify} messages.
         * @function encode
         * @memberof alliance.s2c_update_alliance_info
         * @static
         * @param {alliance.s2c_update_alliance_info} message s2c_update_alliance_info message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_update_alliance_info.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_update_alliance_info message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.s2c_update_alliance_info
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.s2c_update_alliance_info} s2c_update_alliance_info
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_update_alliance_info.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.s2c_update_alliance_info();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_update_alliance_info
         * @function getTypeUrl
         * @memberof alliance.s2c_update_alliance_info
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_update_alliance_info.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.s2c_update_alliance_info";
        };

        return s2c_update_alliance_info;
    })();

    alliance.MemberInfo = (function() {

        /**
         * Properties of a MemberInfo.
         * @memberof alliance
         * @interface IMemberInfo
         * @property {number|null} [rid] MemberInfo rid
         * @property {number|null} [pic] MemberInfo pic
         * @property {string|null} [name] MemberInfo name
         * @property {boolean|null} [isOnline] MemberInfo isOnline
         * @property {number|null} [officerId] MemberInfo officerId
         * @property {number|null} [offlineTime] MemberInfo offlineTime
         * @property {number|null} [impeachTimes] MemberInfo impeachTimes
         * @property {number|null} [power] MemberInfo power
         * @property {number|null} [activeFlag] MemberInfo activeFlag
         */

        /**
         * Constructs a new MemberInfo.
         * @memberof alliance
         * @classdesc Represents a MemberInfo.
         * @implements IMemberInfo
         * @constructor
         * @param {alliance.IMemberInfo=} [properties] Properties to set
         */
        function MemberInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MemberInfo rid.
         * @member {number} rid
         * @memberof alliance.MemberInfo
         * @instance
         */
        MemberInfo.prototype.rid = 0;

        /**
         * MemberInfo pic.
         * @member {number} pic
         * @memberof alliance.MemberInfo
         * @instance
         */
        MemberInfo.prototype.pic = 0;

        /**
         * MemberInfo name.
         * @member {string} name
         * @memberof alliance.MemberInfo
         * @instance
         */
        MemberInfo.prototype.name = "";

        /**
         * MemberInfo isOnline.
         * @member {boolean} isOnline
         * @memberof alliance.MemberInfo
         * @instance
         */
        MemberInfo.prototype.isOnline = false;

        /**
         * MemberInfo officerId.
         * @member {number} officerId
         * @memberof alliance.MemberInfo
         * @instance
         */
        MemberInfo.prototype.officerId = 0;

        /**
         * MemberInfo offlineTime.
         * @member {number} offlineTime
         * @memberof alliance.MemberInfo
         * @instance
         */
        MemberInfo.prototype.offlineTime = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * MemberInfo impeachTimes.
         * @member {number} impeachTimes
         * @memberof alliance.MemberInfo
         * @instance
         */
        MemberInfo.prototype.impeachTimes = 0;

        /**
         * MemberInfo power.
         * @member {number} power
         * @memberof alliance.MemberInfo
         * @instance
         */
        MemberInfo.prototype.power = 0;

        /**
         * MemberInfo activeFlag.
         * @member {number} activeFlag
         * @memberof alliance.MemberInfo
         * @instance
         */
        MemberInfo.prototype.activeFlag = 0;

        /**
         * Creates a new MemberInfo instance using the specified properties.
         * @function create
         * @memberof alliance.MemberInfo
         * @static
         * @param {alliance.IMemberInfo=} [properties] Properties to set
         * @returns {alliance.MemberInfo} MemberInfo instance
         */
        MemberInfo.create = function create(properties) {
            return new MemberInfo(properties);
        };

        /**
         * Encodes the specified MemberInfo message. Does not implicitly {@link alliance.MemberInfo.verify|verify} messages.
         * @function encode
         * @memberof alliance.MemberInfo
         * @static
         * @param {alliance.MemberInfo} message MemberInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rid != null && Object.hasOwnProperty.call(message, "rid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.rid);
            if (message.pic != null && Object.hasOwnProperty.call(message, "pic"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.pic);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.isOnline != null && Object.hasOwnProperty.call(message, "isOnline"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isOnline);
            if (message.officerId != null && Object.hasOwnProperty.call(message, "officerId"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.officerId);
            if (message.offlineTime != null && Object.hasOwnProperty.call(message, "offlineTime"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.offlineTime);
            if (message.impeachTimes != null && Object.hasOwnProperty.call(message, "impeachTimes"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.impeachTimes);
            if (message.power != null && Object.hasOwnProperty.call(message, "power"))
                writer.uint32(/* id 8, wireType 0 =*/64).uint32(message.power);
            if (message.activeFlag != null && Object.hasOwnProperty.call(message, "activeFlag"))
                writer.uint32(/* id 9, wireType 0 =*/72).uint32(message.activeFlag);
            return writer;
        };

        /**
         * Decodes a MemberInfo message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.MemberInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.MemberInfo} MemberInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.MemberInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.pic = reader.uint32();
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                case 4: {
                        message.isOnline = reader.bool();
                        break;
                    }
                case 5: {
                        message.officerId = reader.uint32();
                        break;
                    }
                case 6: {
                        message.offlineTime = reader.uint64();
                        break;
                    }
                case 7: {
                        message.impeachTimes = reader.uint32();
                        break;
                    }
                case 8: {
                        message.power = reader.uint32();
                        break;
                    }
                case 9: {
                        message.activeFlag = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for MemberInfo
         * @function getTypeUrl
         * @memberof alliance.MemberInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MemberInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.MemberInfo";
        };

        return MemberInfo;
    })();

    alliance.c2s_get_member_list = (function() {

        /**
         * Properties of a c2s_get_member_list.
         * @memberof alliance
         * @interface Ic2s_get_member_list
         */

        /**
         * Constructs a new c2s_get_member_list.
         * @memberof alliance
         * @classdesc Represents a c2s_get_member_list.
         * @implements Ic2s_get_member_list
         * @constructor
         * @param {alliance.Ic2s_get_member_list=} [properties] Properties to set
         */
        function c2s_get_member_list(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_get_member_list instance using the specified properties.
         * @function create
         * @memberof alliance.c2s_get_member_list
         * @static
         * @param {alliance.Ic2s_get_member_list=} [properties] Properties to set
         * @returns {alliance.c2s_get_member_list} c2s_get_member_list instance
         */
        c2s_get_member_list.create = function create(properties) {
            return new c2s_get_member_list(properties);
        };

        /**
         * Encodes the specified c2s_get_member_list message. Does not implicitly {@link alliance.c2s_get_member_list.verify|verify} messages.
         * @function encode
         * @memberof alliance.c2s_get_member_list
         * @static
         * @param {alliance.c2s_get_member_list} message c2s_get_member_list message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_get_member_list.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_get_member_list message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.c2s_get_member_list
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.c2s_get_member_list} c2s_get_member_list
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_get_member_list.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.c2s_get_member_list();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_get_member_list
         * @function getTypeUrl
         * @memberof alliance.c2s_get_member_list
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_get_member_list.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.c2s_get_member_list";
        };

        return c2s_get_member_list;
    })();

    alliance.s2c_get_member_list = (function() {

        /**
         * Properties of a s2c_get_member_list.
         * @memberof alliance
         * @interface Is2c_get_member_list
         * @property {number|null} [err] s2c_get_member_list err
         * @property {Array.<alliance.MemberInfo>|null} [list] s2c_get_member_list list
         */

        /**
         * Constructs a new s2c_get_member_list.
         * @memberof alliance
         * @classdesc Represents a s2c_get_member_list.
         * @implements Is2c_get_member_list
         * @constructor
         * @param {alliance.Is2c_get_member_list=} [properties] Properties to set
         */
        function s2c_get_member_list(properties) {
            this.list = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_get_member_list err.
         * @member {number} err
         * @memberof alliance.s2c_get_member_list
         * @instance
         */
        s2c_get_member_list.prototype.err = 0;

        /**
         * s2c_get_member_list list.
         * @member {Array.<alliance.MemberInfo>} list
         * @memberof alliance.s2c_get_member_list
         * @instance
         */
        s2c_get_member_list.prototype.list = $util.emptyArray;

        /**
         * Creates a new s2c_get_member_list instance using the specified properties.
         * @function create
         * @memberof alliance.s2c_get_member_list
         * @static
         * @param {alliance.Is2c_get_member_list=} [properties] Properties to set
         * @returns {alliance.s2c_get_member_list} s2c_get_member_list instance
         */
        s2c_get_member_list.create = function create(properties) {
            return new s2c_get_member_list(properties);
        };

        /**
         * Encodes the specified s2c_get_member_list message. Does not implicitly {@link alliance.s2c_get_member_list.verify|verify} messages.
         * @function encode
         * @memberof alliance.s2c_get_member_list
         * @static
         * @param {alliance.s2c_get_member_list} message s2c_get_member_list message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_get_member_list.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.list != null && message.list.length)
                for (let i = 0; i < message.list.length; ++i)
                    $root.alliance.MemberInfo.encode(message.list[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_get_member_list message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.s2c_get_member_list
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.s2c_get_member_list} s2c_get_member_list
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_get_member_list.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.s2c_get_member_list();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.list && message.list.length))
                            message.list = [];
                        message.list.push($root.alliance.MemberInfo.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_get_member_list
         * @function getTypeUrl
         * @memberof alliance.s2c_get_member_list
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_get_member_list.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.s2c_get_member_list";
        };

        return s2c_get_member_list;
    })();

    alliance.c2s_get_my_apply_list = (function() {

        /**
         * Properties of a c2s_get_my_apply_list.
         * @memberof alliance
         * @interface Ic2s_get_my_apply_list
         */

        /**
         * Constructs a new c2s_get_my_apply_list.
         * @memberof alliance
         * @classdesc Represents a c2s_get_my_apply_list.
         * @implements Ic2s_get_my_apply_list
         * @constructor
         * @param {alliance.Ic2s_get_my_apply_list=} [properties] Properties to set
         */
        function c2s_get_my_apply_list(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_get_my_apply_list instance using the specified properties.
         * @function create
         * @memberof alliance.c2s_get_my_apply_list
         * @static
         * @param {alliance.Ic2s_get_my_apply_list=} [properties] Properties to set
         * @returns {alliance.c2s_get_my_apply_list} c2s_get_my_apply_list instance
         */
        c2s_get_my_apply_list.create = function create(properties) {
            return new c2s_get_my_apply_list(properties);
        };

        /**
         * Encodes the specified c2s_get_my_apply_list message. Does not implicitly {@link alliance.c2s_get_my_apply_list.verify|verify} messages.
         * @function encode
         * @memberof alliance.c2s_get_my_apply_list
         * @static
         * @param {alliance.c2s_get_my_apply_list} message c2s_get_my_apply_list message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_get_my_apply_list.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_get_my_apply_list message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.c2s_get_my_apply_list
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.c2s_get_my_apply_list} c2s_get_my_apply_list
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_get_my_apply_list.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.c2s_get_my_apply_list();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_get_my_apply_list
         * @function getTypeUrl
         * @memberof alliance.c2s_get_my_apply_list
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_get_my_apply_list.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.c2s_get_my_apply_list";
        };

        return c2s_get_my_apply_list;
    })();

    alliance.s2c_get_my_apply_list = (function() {

        /**
         * Properties of a s2c_get_my_apply_list.
         * @memberof alliance
         * @interface Is2c_get_my_apply_list
         * @property {number|null} [err] s2c_get_my_apply_list err
         * @property {Array.<alliance.AllianceMyApplyItem>|null} [list] s2c_get_my_apply_list list
         */

        /**
         * Constructs a new s2c_get_my_apply_list.
         * @memberof alliance
         * @classdesc Represents a s2c_get_my_apply_list.
         * @implements Is2c_get_my_apply_list
         * @constructor
         * @param {alliance.Is2c_get_my_apply_list=} [properties] Properties to set
         */
        function s2c_get_my_apply_list(properties) {
            this.list = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_get_my_apply_list err.
         * @member {number} err
         * @memberof alliance.s2c_get_my_apply_list
         * @instance
         */
        s2c_get_my_apply_list.prototype.err = 0;

        /**
         * s2c_get_my_apply_list list.
         * @member {Array.<alliance.AllianceMyApplyItem>} list
         * @memberof alliance.s2c_get_my_apply_list
         * @instance
         */
        s2c_get_my_apply_list.prototype.list = $util.emptyArray;

        /**
         * Creates a new s2c_get_my_apply_list instance using the specified properties.
         * @function create
         * @memberof alliance.s2c_get_my_apply_list
         * @static
         * @param {alliance.Is2c_get_my_apply_list=} [properties] Properties to set
         * @returns {alliance.s2c_get_my_apply_list} s2c_get_my_apply_list instance
         */
        s2c_get_my_apply_list.create = function create(properties) {
            return new s2c_get_my_apply_list(properties);
        };

        /**
         * Encodes the specified s2c_get_my_apply_list message. Does not implicitly {@link alliance.s2c_get_my_apply_list.verify|verify} messages.
         * @function encode
         * @memberof alliance.s2c_get_my_apply_list
         * @static
         * @param {alliance.s2c_get_my_apply_list} message s2c_get_my_apply_list message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_get_my_apply_list.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.list != null && message.list.length)
                for (let i = 0; i < message.list.length; ++i)
                    $root.alliance.AllianceMyApplyItem.encode(message.list[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_get_my_apply_list message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.s2c_get_my_apply_list
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.s2c_get_my_apply_list} s2c_get_my_apply_list
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_get_my_apply_list.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.s2c_get_my_apply_list();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.list && message.list.length))
                            message.list = [];
                        message.list.push($root.alliance.AllianceMyApplyItem.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_get_my_apply_list
         * @function getTypeUrl
         * @memberof alliance.s2c_get_my_apply_list
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_get_my_apply_list.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.s2c_get_my_apply_list";
        };

        return s2c_get_my_apply_list;
    })();

    alliance.c2s_get_my_invited_list = (function() {

        /**
         * Properties of a c2s_get_my_invited_list.
         * @memberof alliance
         * @interface Ic2s_get_my_invited_list
         */

        /**
         * Constructs a new c2s_get_my_invited_list.
         * @memberof alliance
         * @classdesc Represents a c2s_get_my_invited_list.
         * @implements Ic2s_get_my_invited_list
         * @constructor
         * @param {alliance.Ic2s_get_my_invited_list=} [properties] Properties to set
         */
        function c2s_get_my_invited_list(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_get_my_invited_list instance using the specified properties.
         * @function create
         * @memberof alliance.c2s_get_my_invited_list
         * @static
         * @param {alliance.Ic2s_get_my_invited_list=} [properties] Properties to set
         * @returns {alliance.c2s_get_my_invited_list} c2s_get_my_invited_list instance
         */
        c2s_get_my_invited_list.create = function create(properties) {
            return new c2s_get_my_invited_list(properties);
        };

        /**
         * Encodes the specified c2s_get_my_invited_list message. Does not implicitly {@link alliance.c2s_get_my_invited_list.verify|verify} messages.
         * @function encode
         * @memberof alliance.c2s_get_my_invited_list
         * @static
         * @param {alliance.c2s_get_my_invited_list} message c2s_get_my_invited_list message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_get_my_invited_list.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_get_my_invited_list message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.c2s_get_my_invited_list
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.c2s_get_my_invited_list} c2s_get_my_invited_list
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_get_my_invited_list.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.c2s_get_my_invited_list();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_get_my_invited_list
         * @function getTypeUrl
         * @memberof alliance.c2s_get_my_invited_list
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_get_my_invited_list.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.c2s_get_my_invited_list";
        };

        return c2s_get_my_invited_list;
    })();

    alliance.s2c_get_my_invited_list = (function() {

        /**
         * Properties of a s2c_get_my_invited_list.
         * @memberof alliance
         * @interface Is2c_get_my_invited_list
         * @property {number|null} [err] s2c_get_my_invited_list err
         * @property {Array.<alliance.AllianceMyInvitedItem>|null} [list] s2c_get_my_invited_list list
         */

        /**
         * Constructs a new s2c_get_my_invited_list.
         * @memberof alliance
         * @classdesc Represents a s2c_get_my_invited_list.
         * @implements Is2c_get_my_invited_list
         * @constructor
         * @param {alliance.Is2c_get_my_invited_list=} [properties] Properties to set
         */
        function s2c_get_my_invited_list(properties) {
            this.list = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_get_my_invited_list err.
         * @member {number} err
         * @memberof alliance.s2c_get_my_invited_list
         * @instance
         */
        s2c_get_my_invited_list.prototype.err = 0;

        /**
         * s2c_get_my_invited_list list.
         * @member {Array.<alliance.AllianceMyInvitedItem>} list
         * @memberof alliance.s2c_get_my_invited_list
         * @instance
         */
        s2c_get_my_invited_list.prototype.list = $util.emptyArray;

        /**
         * Creates a new s2c_get_my_invited_list instance using the specified properties.
         * @function create
         * @memberof alliance.s2c_get_my_invited_list
         * @static
         * @param {alliance.Is2c_get_my_invited_list=} [properties] Properties to set
         * @returns {alliance.s2c_get_my_invited_list} s2c_get_my_invited_list instance
         */
        s2c_get_my_invited_list.create = function create(properties) {
            return new s2c_get_my_invited_list(properties);
        };

        /**
         * Encodes the specified s2c_get_my_invited_list message. Does not implicitly {@link alliance.s2c_get_my_invited_list.verify|verify} messages.
         * @function encode
         * @memberof alliance.s2c_get_my_invited_list
         * @static
         * @param {alliance.s2c_get_my_invited_list} message s2c_get_my_invited_list message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_get_my_invited_list.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.list != null && message.list.length)
                for (let i = 0; i < message.list.length; ++i)
                    $root.alliance.AllianceMyInvitedItem.encode(message.list[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_get_my_invited_list message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.s2c_get_my_invited_list
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.s2c_get_my_invited_list} s2c_get_my_invited_list
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_get_my_invited_list.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.s2c_get_my_invited_list();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.list && message.list.length))
                            message.list = [];
                        message.list.push($root.alliance.AllianceMyInvitedItem.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_get_my_invited_list
         * @function getTypeUrl
         * @memberof alliance.s2c_get_my_invited_list
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_get_my_invited_list.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.s2c_get_my_invited_list";
        };

        return s2c_get_my_invited_list;
    })();

    alliance.c2s_invite = (function() {

        /**
         * Properties of a c2s_invite.
         * @memberof alliance
         * @interface Ic2s_invite
         * @property {number|null} [rid] c2s_invite rid
         */

        /**
         * Constructs a new c2s_invite.
         * @memberof alliance
         * @classdesc Represents a c2s_invite.
         * @implements Ic2s_invite
         * @constructor
         * @param {alliance.Ic2s_invite=} [properties] Properties to set
         */
        function c2s_invite(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_invite rid.
         * @member {number} rid
         * @memberof alliance.c2s_invite
         * @instance
         */
        c2s_invite.prototype.rid = 0;

        /**
         * Creates a new c2s_invite instance using the specified properties.
         * @function create
         * @memberof alliance.c2s_invite
         * @static
         * @param {alliance.Ic2s_invite=} [properties] Properties to set
         * @returns {alliance.c2s_invite} c2s_invite instance
         */
        c2s_invite.create = function create(properties) {
            return new c2s_invite(properties);
        };

        /**
         * Encodes the specified c2s_invite message. Does not implicitly {@link alliance.c2s_invite.verify|verify} messages.
         * @function encode
         * @memberof alliance.c2s_invite
         * @static
         * @param {alliance.c2s_invite} message c2s_invite message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_invite.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rid != null && Object.hasOwnProperty.call(message, "rid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.rid);
            return writer;
        };

        /**
         * Decodes a c2s_invite message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.c2s_invite
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.c2s_invite} c2s_invite
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_invite.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.c2s_invite();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rid = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_invite
         * @function getTypeUrl
         * @memberof alliance.c2s_invite
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_invite.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.c2s_invite";
        };

        return c2s_invite;
    })();

    alliance.s2c_invite = (function() {

        /**
         * Properties of a s2c_invite.
         * @memberof alliance
         * @interface Is2c_invite
         * @property {number|null} [err] s2c_invite err
         */

        /**
         * Constructs a new s2c_invite.
         * @memberof alliance
         * @classdesc Represents a s2c_invite.
         * @implements Is2c_invite
         * @constructor
         * @param {alliance.Is2c_invite=} [properties] Properties to set
         */
        function s2c_invite(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_invite err.
         * @member {number} err
         * @memberof alliance.s2c_invite
         * @instance
         */
        s2c_invite.prototype.err = 0;

        /**
         * Creates a new s2c_invite instance using the specified properties.
         * @function create
         * @memberof alliance.s2c_invite
         * @static
         * @param {alliance.Is2c_invite=} [properties] Properties to set
         * @returns {alliance.s2c_invite} s2c_invite instance
         */
        s2c_invite.create = function create(properties) {
            return new s2c_invite(properties);
        };

        /**
         * Encodes the specified s2c_invite message. Does not implicitly {@link alliance.s2c_invite.verify|verify} messages.
         * @function encode
         * @memberof alliance.s2c_invite
         * @static
         * @param {alliance.s2c_invite} message s2c_invite message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_invite.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_invite message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.s2c_invite
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.s2c_invite} s2c_invite
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_invite.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.s2c_invite();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_invite
         * @function getTypeUrl
         * @memberof alliance.s2c_invite
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_invite.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.s2c_invite";
        };

        return s2c_invite;
    })();

    alliance.c2s_process_invited = (function() {

        /**
         * Properties of a c2s_process_invited.
         * @memberof alliance
         * @interface Ic2s_process_invited
         * @property {number|null} [op] c2s_process_invited op
         * @property {number|null} [allianceId] c2s_process_invited allianceId
         */

        /**
         * Constructs a new c2s_process_invited.
         * @memberof alliance
         * @classdesc Represents a c2s_process_invited.
         * @implements Ic2s_process_invited
         * @constructor
         * @param {alliance.Ic2s_process_invited=} [properties] Properties to set
         */
        function c2s_process_invited(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_process_invited op.
         * @member {number} op
         * @memberof alliance.c2s_process_invited
         * @instance
         */
        c2s_process_invited.prototype.op = 0;

        /**
         * c2s_process_invited allianceId.
         * @member {number} allianceId
         * @memberof alliance.c2s_process_invited
         * @instance
         */
        c2s_process_invited.prototype.allianceId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new c2s_process_invited instance using the specified properties.
         * @function create
         * @memberof alliance.c2s_process_invited
         * @static
         * @param {alliance.Ic2s_process_invited=} [properties] Properties to set
         * @returns {alliance.c2s_process_invited} c2s_process_invited instance
         */
        c2s_process_invited.create = function create(properties) {
            return new c2s_process_invited(properties);
        };

        /**
         * Encodes the specified c2s_process_invited message. Does not implicitly {@link alliance.c2s_process_invited.verify|verify} messages.
         * @function encode
         * @memberof alliance.c2s_process_invited
         * @static
         * @param {alliance.c2s_process_invited} message c2s_process_invited message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_process_invited.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.op != null && Object.hasOwnProperty.call(message, "op"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.op);
            if (message.allianceId != null && Object.hasOwnProperty.call(message, "allianceId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.allianceId);
            return writer;
        };

        /**
         * Decodes a c2s_process_invited message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.c2s_process_invited
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.c2s_process_invited} c2s_process_invited
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_process_invited.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.c2s_process_invited();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.op = reader.uint32();
                        break;
                    }
                case 2: {
                        message.allianceId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_process_invited
         * @function getTypeUrl
         * @memberof alliance.c2s_process_invited
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_process_invited.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.c2s_process_invited";
        };

        return c2s_process_invited;
    })();

    alliance.s2c_process_invited = (function() {

        /**
         * Properties of a s2c_process_invited.
         * @memberof alliance
         * @interface Is2c_process_invited
         * @property {number|null} [err] s2c_process_invited err
         * @property {alliance.AllianceInfo|null} [info] s2c_process_invited info
         */

        /**
         * Constructs a new s2c_process_invited.
         * @memberof alliance
         * @classdesc Represents a s2c_process_invited.
         * @implements Is2c_process_invited
         * @constructor
         * @param {alliance.Is2c_process_invited=} [properties] Properties to set
         */
        function s2c_process_invited(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_process_invited err.
         * @member {number} err
         * @memberof alliance.s2c_process_invited
         * @instance
         */
        s2c_process_invited.prototype.err = 0;

        /**
         * s2c_process_invited info.
         * @member {alliance.AllianceInfo|null|undefined} info
         * @memberof alliance.s2c_process_invited
         * @instance
         */
        s2c_process_invited.prototype.info = null;

        /**
         * Creates a new s2c_process_invited instance using the specified properties.
         * @function create
         * @memberof alliance.s2c_process_invited
         * @static
         * @param {alliance.Is2c_process_invited=} [properties] Properties to set
         * @returns {alliance.s2c_process_invited} s2c_process_invited instance
         */
        s2c_process_invited.create = function create(properties) {
            return new s2c_process_invited(properties);
        };

        /**
         * Encodes the specified s2c_process_invited message. Does not implicitly {@link alliance.s2c_process_invited.verify|verify} messages.
         * @function encode
         * @memberof alliance.s2c_process_invited
         * @static
         * @param {alliance.s2c_process_invited} message s2c_process_invited message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_process_invited.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.info != null && Object.hasOwnProperty.call(message, "info"))
                $root.alliance.AllianceInfo.encode(message.info, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_process_invited message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.s2c_process_invited
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.s2c_process_invited} s2c_process_invited
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_process_invited.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.s2c_process_invited();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        message.info = $root.alliance.AllianceInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_process_invited
         * @function getTypeUrl
         * @memberof alliance.s2c_process_invited
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_process_invited.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.s2c_process_invited";
        };

        return s2c_process_invited;
    })();

    alliance.c2s_kick_member = (function() {

        /**
         * Properties of a c2s_kick_member.
         * @memberof alliance
         * @interface Ic2s_kick_member
         * @property {number|null} [rid] c2s_kick_member rid
         */

        /**
         * Constructs a new c2s_kick_member.
         * @memberof alliance
         * @classdesc Represents a c2s_kick_member.
         * @implements Ic2s_kick_member
         * @constructor
         * @param {alliance.Ic2s_kick_member=} [properties] Properties to set
         */
        function c2s_kick_member(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_kick_member rid.
         * @member {number} rid
         * @memberof alliance.c2s_kick_member
         * @instance
         */
        c2s_kick_member.prototype.rid = 0;

        /**
         * Creates a new c2s_kick_member instance using the specified properties.
         * @function create
         * @memberof alliance.c2s_kick_member
         * @static
         * @param {alliance.Ic2s_kick_member=} [properties] Properties to set
         * @returns {alliance.c2s_kick_member} c2s_kick_member instance
         */
        c2s_kick_member.create = function create(properties) {
            return new c2s_kick_member(properties);
        };

        /**
         * Encodes the specified c2s_kick_member message. Does not implicitly {@link alliance.c2s_kick_member.verify|verify} messages.
         * @function encode
         * @memberof alliance.c2s_kick_member
         * @static
         * @param {alliance.c2s_kick_member} message c2s_kick_member message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_kick_member.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rid != null && Object.hasOwnProperty.call(message, "rid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.rid);
            return writer;
        };

        /**
         * Decodes a c2s_kick_member message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.c2s_kick_member
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.c2s_kick_member} c2s_kick_member
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_kick_member.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.c2s_kick_member();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rid = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_kick_member
         * @function getTypeUrl
         * @memberof alliance.c2s_kick_member
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_kick_member.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.c2s_kick_member";
        };

        return c2s_kick_member;
    })();

    alliance.s2c_kick_member = (function() {

        /**
         * Properties of a s2c_kick_member.
         * @memberof alliance
         * @interface Is2c_kick_member
         * @property {number|null} [err] s2c_kick_member err
         */

        /**
         * Constructs a new s2c_kick_member.
         * @memberof alliance
         * @classdesc Represents a s2c_kick_member.
         * @implements Is2c_kick_member
         * @constructor
         * @param {alliance.Is2c_kick_member=} [properties] Properties to set
         */
        function s2c_kick_member(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_kick_member err.
         * @member {number} err
         * @memberof alliance.s2c_kick_member
         * @instance
         */
        s2c_kick_member.prototype.err = 0;

        /**
         * Creates a new s2c_kick_member instance using the specified properties.
         * @function create
         * @memberof alliance.s2c_kick_member
         * @static
         * @param {alliance.Is2c_kick_member=} [properties] Properties to set
         * @returns {alliance.s2c_kick_member} s2c_kick_member instance
         */
        s2c_kick_member.create = function create(properties) {
            return new s2c_kick_member(properties);
        };

        /**
         * Encodes the specified s2c_kick_member message. Does not implicitly {@link alliance.s2c_kick_member.verify|verify} messages.
         * @function encode
         * @memberof alliance.s2c_kick_member
         * @static
         * @param {alliance.s2c_kick_member} message s2c_kick_member message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_kick_member.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_kick_member message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.s2c_kick_member
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.s2c_kick_member} s2c_kick_member
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_kick_member.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.s2c_kick_member();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_kick_member
         * @function getTypeUrl
         * @memberof alliance.s2c_kick_member
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_kick_member.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.s2c_kick_member";
        };

        return s2c_kick_member;
    })();

    alliance.notify_alliance_dismissed = (function() {

        /**
         * Properties of a notify_alliance_dismissed.
         * @memberof alliance
         * @interface Inotify_alliance_dismissed
         */

        /**
         * Constructs a new notify_alliance_dismissed.
         * @memberof alliance
         * @classdesc Represents a notify_alliance_dismissed.
         * @implements Inotify_alliance_dismissed
         * @constructor
         * @param {alliance.Inotify_alliance_dismissed=} [properties] Properties to set
         */
        function notify_alliance_dismissed(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new notify_alliance_dismissed instance using the specified properties.
         * @function create
         * @memberof alliance.notify_alliance_dismissed
         * @static
         * @param {alliance.Inotify_alliance_dismissed=} [properties] Properties to set
         * @returns {alliance.notify_alliance_dismissed} notify_alliance_dismissed instance
         */
        notify_alliance_dismissed.create = function create(properties) {
            return new notify_alliance_dismissed(properties);
        };

        /**
         * Encodes the specified notify_alliance_dismissed message. Does not implicitly {@link alliance.notify_alliance_dismissed.verify|verify} messages.
         * @function encode
         * @memberof alliance.notify_alliance_dismissed
         * @static
         * @param {alliance.notify_alliance_dismissed} message notify_alliance_dismissed message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_alliance_dismissed.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a notify_alliance_dismissed message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.notify_alliance_dismissed
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.notify_alliance_dismissed} notify_alliance_dismissed
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_alliance_dismissed.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.notify_alliance_dismissed();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_alliance_dismissed
         * @function getTypeUrl
         * @memberof alliance.notify_alliance_dismissed
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_alliance_dismissed.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.notify_alliance_dismissed";
        };

        return notify_alliance_dismissed;
    })();

    alliance.notify_kicked = (function() {

        /**
         * Properties of a notify_kicked.
         * @memberof alliance
         * @interface Inotify_kicked
         */

        /**
         * Constructs a new notify_kicked.
         * @memberof alliance
         * @classdesc Represents a notify_kicked.
         * @implements Inotify_kicked
         * @constructor
         * @param {alliance.Inotify_kicked=} [properties] Properties to set
         */
        function notify_kicked(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new notify_kicked instance using the specified properties.
         * @function create
         * @memberof alliance.notify_kicked
         * @static
         * @param {alliance.Inotify_kicked=} [properties] Properties to set
         * @returns {alliance.notify_kicked} notify_kicked instance
         */
        notify_kicked.create = function create(properties) {
            return new notify_kicked(properties);
        };

        /**
         * Encodes the specified notify_kicked message. Does not implicitly {@link alliance.notify_kicked.verify|verify} messages.
         * @function encode
         * @memberof alliance.notify_kicked
         * @static
         * @param {alliance.notify_kicked} message notify_kicked message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_kicked.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a notify_kicked message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.notify_kicked
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.notify_kicked} notify_kicked
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_kicked.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.notify_kicked();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_kicked
         * @function getTypeUrl
         * @memberof alliance.notify_kicked
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_kicked.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.notify_kicked";
        };

        return notify_kicked;
    })();

    alliance.notify_profile_update = (function() {

        /**
         * Properties of a notify_profile_update.
         * @memberof alliance
         * @interface Inotify_profile_update
         * @property {alliance.AllianceProfile|null} [profile] notify_profile_update profile
         */

        /**
         * Constructs a new notify_profile_update.
         * @memberof alliance
         * @classdesc Represents a notify_profile_update.
         * @implements Inotify_profile_update
         * @constructor
         * @param {alliance.Inotify_profile_update=} [properties] Properties to set
         */
        function notify_profile_update(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_profile_update profile.
         * @member {alliance.AllianceProfile|null|undefined} profile
         * @memberof alliance.notify_profile_update
         * @instance
         */
        notify_profile_update.prototype.profile = null;

        /**
         * Creates a new notify_profile_update instance using the specified properties.
         * @function create
         * @memberof alliance.notify_profile_update
         * @static
         * @param {alliance.Inotify_profile_update=} [properties] Properties to set
         * @returns {alliance.notify_profile_update} notify_profile_update instance
         */
        notify_profile_update.create = function create(properties) {
            return new notify_profile_update(properties);
        };

        /**
         * Encodes the specified notify_profile_update message. Does not implicitly {@link alliance.notify_profile_update.verify|verify} messages.
         * @function encode
         * @memberof alliance.notify_profile_update
         * @static
         * @param {alliance.notify_profile_update} message notify_profile_update message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_profile_update.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
                $root.alliance.AllianceProfile.encode(message.profile, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a notify_profile_update message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.notify_profile_update
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.notify_profile_update} notify_profile_update
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_profile_update.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.notify_profile_update();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.profile = $root.alliance.AllianceProfile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_profile_update
         * @function getTypeUrl
         * @memberof alliance.notify_profile_update
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_profile_update.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.notify_profile_update";
        };

        return notify_profile_update;
    })();

    alliance.notify_join = (function() {

        /**
         * Properties of a notify_join.
         * @memberof alliance
         * @interface Inotify_join
         * @property {alliance.AllianceInfo|null} [info] notify_join info
         */

        /**
         * Constructs a new notify_join.
         * @memberof alliance
         * @classdesc Represents a notify_join.
         * @implements Inotify_join
         * @constructor
         * @param {alliance.Inotify_join=} [properties] Properties to set
         */
        function notify_join(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_join info.
         * @member {alliance.AllianceInfo|null|undefined} info
         * @memberof alliance.notify_join
         * @instance
         */
        notify_join.prototype.info = null;

        /**
         * Creates a new notify_join instance using the specified properties.
         * @function create
         * @memberof alliance.notify_join
         * @static
         * @param {alliance.Inotify_join=} [properties] Properties to set
         * @returns {alliance.notify_join} notify_join instance
         */
        notify_join.create = function create(properties) {
            return new notify_join(properties);
        };

        /**
         * Encodes the specified notify_join message. Does not implicitly {@link alliance.notify_join.verify|verify} messages.
         * @function encode
         * @memberof alliance.notify_join
         * @static
         * @param {alliance.notify_join} message notify_join message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_join.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.info != null && Object.hasOwnProperty.call(message, "info"))
                $root.alliance.AllianceInfo.encode(message.info, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a notify_join message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.notify_join
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.notify_join} notify_join
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_join.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.notify_join();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.info = $root.alliance.AllianceInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_join
         * @function getTypeUrl
         * @memberof alliance.notify_join
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_join.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.notify_join";
        };

        return notify_join;
    })();

    alliance.notify_common_info = (function() {

        /**
         * Properties of a notify_common_info.
         * @memberof alliance
         * @interface Inotify_common_info
         * @property {alliance.CommonInfo|null} [commonInfo] notify_common_info commonInfo
         */

        /**
         * Constructs a new notify_common_info.
         * @memberof alliance
         * @classdesc Represents a notify_common_info.
         * @implements Inotify_common_info
         * @constructor
         * @param {alliance.Inotify_common_info=} [properties] Properties to set
         */
        function notify_common_info(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_common_info commonInfo.
         * @member {alliance.CommonInfo|null|undefined} commonInfo
         * @memberof alliance.notify_common_info
         * @instance
         */
        notify_common_info.prototype.commonInfo = null;

        /**
         * Creates a new notify_common_info instance using the specified properties.
         * @function create
         * @memberof alliance.notify_common_info
         * @static
         * @param {alliance.Inotify_common_info=} [properties] Properties to set
         * @returns {alliance.notify_common_info} notify_common_info instance
         */
        notify_common_info.create = function create(properties) {
            return new notify_common_info(properties);
        };

        /**
         * Encodes the specified notify_common_info message. Does not implicitly {@link alliance.notify_common_info.verify|verify} messages.
         * @function encode
         * @memberof alliance.notify_common_info
         * @static
         * @param {alliance.notify_common_info} message notify_common_info message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_common_info.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonInfo != null && Object.hasOwnProperty.call(message, "commonInfo"))
                $root.alliance.CommonInfo.encode(message.commonInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a notify_common_info message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.notify_common_info
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.notify_common_info} notify_common_info
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_common_info.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.notify_common_info();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.commonInfo = $root.alliance.CommonInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_common_info
         * @function getTypeUrl
         * @memberof alliance.notify_common_info
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_common_info.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.notify_common_info";
        };

        return notify_common_info;
    })();

    alliance.notify_apply_processed = (function() {

        /**
         * Properties of a notify_apply_processed.
         * @memberof alliance
         * @interface Inotify_apply_processed
         * @property {number|null} [op] notify_apply_processed op
         * @property {alliance.AllianceInfo|null} [info] notify_apply_processed info
         */

        /**
         * Constructs a new notify_apply_processed.
         * @memberof alliance
         * @classdesc Represents a notify_apply_processed.
         * @implements Inotify_apply_processed
         * @constructor
         * @param {alliance.Inotify_apply_processed=} [properties] Properties to set
         */
        function notify_apply_processed(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_apply_processed op.
         * @member {number} op
         * @memberof alliance.notify_apply_processed
         * @instance
         */
        notify_apply_processed.prototype.op = 0;

        /**
         * notify_apply_processed info.
         * @member {alliance.AllianceInfo|null|undefined} info
         * @memberof alliance.notify_apply_processed
         * @instance
         */
        notify_apply_processed.prototype.info = null;

        /**
         * Creates a new notify_apply_processed instance using the specified properties.
         * @function create
         * @memberof alliance.notify_apply_processed
         * @static
         * @param {alliance.Inotify_apply_processed=} [properties] Properties to set
         * @returns {alliance.notify_apply_processed} notify_apply_processed instance
         */
        notify_apply_processed.create = function create(properties) {
            return new notify_apply_processed(properties);
        };

        /**
         * Encodes the specified notify_apply_processed message. Does not implicitly {@link alliance.notify_apply_processed.verify|verify} messages.
         * @function encode
         * @memberof alliance.notify_apply_processed
         * @static
         * @param {alliance.notify_apply_processed} message notify_apply_processed message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_apply_processed.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.op != null && Object.hasOwnProperty.call(message, "op"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.op);
            if (message.info != null && Object.hasOwnProperty.call(message, "info"))
                $root.alliance.AllianceInfo.encode(message.info, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a notify_apply_processed message from the specified reader or buffer.
         * @function decode
         * @memberof alliance.notify_apply_processed
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {alliance.notify_apply_processed} notify_apply_processed
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_apply_processed.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.alliance.notify_apply_processed();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.op = reader.uint32();
                        break;
                    }
                case 2: {
                        message.info = $root.alliance.AllianceInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_apply_processed
         * @function getTypeUrl
         * @memberof alliance.notify_apply_processed
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_apply_processed.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/alliance.notify_apply_processed";
        };

        return notify_apply_processed;
    })();

    return alliance;
})();

export const bag = $root.bag = (() => {

    /**
     * Namespace bag.
     * @exports bag
     * @namespace
     */
    const bag = {};

    bag.Item = (function() {

        /**
         * Properties of an Item.
         * @memberof bag
         * @interface IItem
         * @property {number|null} [id] Item id
         * @property {number|null} [uid] Item uid
         * @property {number|null} [num] Item num
         * @property {number|null} [expireTs] Item expireTs
         */

        /**
         * Constructs a new Item.
         * @memberof bag
         * @classdesc Represents an Item.
         * @implements IItem
         * @constructor
         * @param {bag.IItem=} [properties] Properties to set
         */
        function Item(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Item id.
         * @member {number} id
         * @memberof bag.Item
         * @instance
         */
        Item.prototype.id = 0;

        /**
         * Item uid.
         * @member {number} uid
         * @memberof bag.Item
         * @instance
         */
        Item.prototype.uid = 0;

        /**
         * Item num.
         * @member {number} num
         * @memberof bag.Item
         * @instance
         */
        Item.prototype.num = 0;

        /**
         * Item expireTs.
         * @member {number} expireTs
         * @memberof bag.Item
         * @instance
         */
        Item.prototype.expireTs = 0;

        /**
         * Creates a new Item instance using the specified properties.
         * @function create
         * @memberof bag.Item
         * @static
         * @param {bag.IItem=} [properties] Properties to set
         * @returns {bag.Item} Item instance
         */
        Item.create = function create(properties) {
            return new Item(properties);
        };

        /**
         * Encodes the specified Item message. Does not implicitly {@link bag.Item.verify|verify} messages.
         * @function encode
         * @memberof bag.Item
         * @static
         * @param {bag.Item} message Item message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Item.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.uid);
            if (message.num != null && Object.hasOwnProperty.call(message, "num"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.num);
            if (message.expireTs != null && Object.hasOwnProperty.call(message, "expireTs"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.expireTs);
            return writer;
        };

        /**
         * Decodes an Item message from the specified reader or buffer.
         * @function decode
         * @memberof bag.Item
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {bag.Item} Item
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Item.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.bag.Item();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 2: {
                        message.uid = reader.uint32();
                        break;
                    }
                case 3: {
                        message.num = reader.uint32();
                        break;
                    }
                case 4: {
                        message.expireTs = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for Item
         * @function getTypeUrl
         * @memberof bag.Item
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Item.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/bag.Item";
        };

        return Item;
    })();

    bag.ItemReward = (function() {

        /**
         * Properties of an ItemReward.
         * @memberof bag
         * @interface IItemReward
         * @property {number|null} [id] ItemReward id
         * @property {number|null} [num] ItemReward num
         */

        /**
         * Constructs a new ItemReward.
         * @memberof bag
         * @classdesc Represents an ItemReward.
         * @implements IItemReward
         * @constructor
         * @param {bag.IItemReward=} [properties] Properties to set
         */
        function ItemReward(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ItemReward id.
         * @member {number} id
         * @memberof bag.ItemReward
         * @instance
         */
        ItemReward.prototype.id = 0;

        /**
         * ItemReward num.
         * @member {number} num
         * @memberof bag.ItemReward
         * @instance
         */
        ItemReward.prototype.num = 0;

        /**
         * Creates a new ItemReward instance using the specified properties.
         * @function create
         * @memberof bag.ItemReward
         * @static
         * @param {bag.IItemReward=} [properties] Properties to set
         * @returns {bag.ItemReward} ItemReward instance
         */
        ItemReward.create = function create(properties) {
            return new ItemReward(properties);
        };

        /**
         * Encodes the specified ItemReward message. Does not implicitly {@link bag.ItemReward.verify|verify} messages.
         * @function encode
         * @memberof bag.ItemReward
         * @static
         * @param {bag.ItemReward} message ItemReward message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ItemReward.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            if (message.num != null && Object.hasOwnProperty.call(message, "num"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.num);
            return writer;
        };

        /**
         * Decodes an ItemReward message from the specified reader or buffer.
         * @function decode
         * @memberof bag.ItemReward
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {bag.ItemReward} ItemReward
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ItemReward.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.bag.ItemReward();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 2: {
                        message.num = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for ItemReward
         * @function getTypeUrl
         * @memberof bag.ItemReward
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ItemReward.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/bag.ItemReward";
        };

        return ItemReward;
    })();

    bag.c2s_load = (function() {

        /**
         * Properties of a c2s_load.
         * @memberof bag
         * @interface Ic2s_load
         * @property {number|null} [bagId] c2s_load bagId
         */

        /**
         * Constructs a new c2s_load.
         * @memberof bag
         * @classdesc Represents a c2s_load.
         * @implements Ic2s_load
         * @constructor
         * @param {bag.Ic2s_load=} [properties] Properties to set
         */
        function c2s_load(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_load bagId.
         * @member {number} bagId
         * @memberof bag.c2s_load
         * @instance
         */
        c2s_load.prototype.bagId = 0;

        /**
         * Creates a new c2s_load instance using the specified properties.
         * @function create
         * @memberof bag.c2s_load
         * @static
         * @param {bag.Ic2s_load=} [properties] Properties to set
         * @returns {bag.c2s_load} c2s_load instance
         */
        c2s_load.create = function create(properties) {
            return new c2s_load(properties);
        };

        /**
         * Encodes the specified c2s_load message. Does not implicitly {@link bag.c2s_load.verify|verify} messages.
         * @function encode
         * @memberof bag.c2s_load
         * @static
         * @param {bag.c2s_load} message c2s_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.bagId != null && Object.hasOwnProperty.call(message, "bagId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.bagId);
            return writer;
        };

        /**
         * Decodes a c2s_load message from the specified reader or buffer.
         * @function decode
         * @memberof bag.c2s_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {bag.c2s_load} c2s_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.bag.c2s_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.bagId = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_load
         * @function getTypeUrl
         * @memberof bag.c2s_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/bag.c2s_load";
        };

        return c2s_load;
    })();

    bag.s2c_load = (function() {

        /**
         * Properties of a s2c_load.
         * @memberof bag
         * @interface Is2c_load
         * @property {number|null} [err] s2c_load err
         * @property {Array.<bag.Item>|null} [items] s2c_load items
         */

        /**
         * Constructs a new s2c_load.
         * @memberof bag
         * @classdesc Represents a s2c_load.
         * @implements Is2c_load
         * @constructor
         * @param {bag.Is2c_load=} [properties] Properties to set
         */
        function s2c_load(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_load err.
         * @member {number} err
         * @memberof bag.s2c_load
         * @instance
         */
        s2c_load.prototype.err = 0;

        /**
         * s2c_load items.
         * @member {Array.<bag.Item>} items
         * @memberof bag.s2c_load
         * @instance
         */
        s2c_load.prototype.items = $util.emptyArray;

        /**
         * Creates a new s2c_load instance using the specified properties.
         * @function create
         * @memberof bag.s2c_load
         * @static
         * @param {bag.Is2c_load=} [properties] Properties to set
         * @returns {bag.s2c_load} s2c_load instance
         */
        s2c_load.create = function create(properties) {
            return new s2c_load(properties);
        };

        /**
         * Encodes the specified s2c_load message. Does not implicitly {@link bag.s2c_load.verify|verify} messages.
         * @function encode
         * @memberof bag.s2c_load
         * @static
         * @param {bag.s2c_load} message s2c_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.bag.Item.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_load message from the specified reader or buffer.
         * @function decode
         * @memberof bag.s2c_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {bag.s2c_load} s2c_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.bag.s2c_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.bag.Item.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_load
         * @function getTypeUrl
         * @memberof bag.s2c_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/bag.s2c_load";
        };

        return s2c_load;
    })();

    bag.c2s_use_item = (function() {

        /**
         * Properties of a c2s_use_item.
         * @memberof bag
         * @interface Ic2s_use_item
         * @property {number|null} [itemId] c2s_use_item itemId
         * @property {number|null} [num] c2s_use_item num
         */

        /**
         * Constructs a new c2s_use_item.
         * @memberof bag
         * @classdesc Represents a c2s_use_item.
         * @implements Ic2s_use_item
         * @constructor
         * @param {bag.Ic2s_use_item=} [properties] Properties to set
         */
        function c2s_use_item(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_use_item itemId.
         * @member {number} itemId
         * @memberof bag.c2s_use_item
         * @instance
         */
        c2s_use_item.prototype.itemId = 0;

        /**
         * c2s_use_item num.
         * @member {number} num
         * @memberof bag.c2s_use_item
         * @instance
         */
        c2s_use_item.prototype.num = 0;

        /**
         * Creates a new c2s_use_item instance using the specified properties.
         * @function create
         * @memberof bag.c2s_use_item
         * @static
         * @param {bag.Ic2s_use_item=} [properties] Properties to set
         * @returns {bag.c2s_use_item} c2s_use_item instance
         */
        c2s_use_item.create = function create(properties) {
            return new c2s_use_item(properties);
        };

        /**
         * Encodes the specified c2s_use_item message. Does not implicitly {@link bag.c2s_use_item.verify|verify} messages.
         * @function encode
         * @memberof bag.c2s_use_item
         * @static
         * @param {bag.c2s_use_item} message c2s_use_item message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_use_item.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.itemId != null && Object.hasOwnProperty.call(message, "itemId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.itemId);
            if (message.num != null && Object.hasOwnProperty.call(message, "num"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.num);
            return writer;
        };

        /**
         * Decodes a c2s_use_item message from the specified reader or buffer.
         * @function decode
         * @memberof bag.c2s_use_item
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {bag.c2s_use_item} c2s_use_item
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_use_item.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.bag.c2s_use_item();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.itemId = reader.uint32();
                        break;
                    }
                case 2: {
                        message.num = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_use_item
         * @function getTypeUrl
         * @memberof bag.c2s_use_item
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_use_item.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/bag.c2s_use_item";
        };

        return c2s_use_item;
    })();

    bag.s2c_use_item = (function() {

        /**
         * Properties of a s2c_use_item.
         * @memberof bag
         * @interface Is2c_use_item
         * @property {number|null} [err] s2c_use_item err
         * @property {Array.<bag.ItemReward>|null} [rewards] s2c_use_item rewards
         */

        /**
         * Constructs a new s2c_use_item.
         * @memberof bag
         * @classdesc Represents a s2c_use_item.
         * @implements Is2c_use_item
         * @constructor
         * @param {bag.Is2c_use_item=} [properties] Properties to set
         */
        function s2c_use_item(properties) {
            this.rewards = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_use_item err.
         * @member {number} err
         * @memberof bag.s2c_use_item
         * @instance
         */
        s2c_use_item.prototype.err = 0;

        /**
         * s2c_use_item rewards.
         * @member {Array.<bag.ItemReward>} rewards
         * @memberof bag.s2c_use_item
         * @instance
         */
        s2c_use_item.prototype.rewards = $util.emptyArray;

        /**
         * Creates a new s2c_use_item instance using the specified properties.
         * @function create
         * @memberof bag.s2c_use_item
         * @static
         * @param {bag.Is2c_use_item=} [properties] Properties to set
         * @returns {bag.s2c_use_item} s2c_use_item instance
         */
        s2c_use_item.create = function create(properties) {
            return new s2c_use_item(properties);
        };

        /**
         * Encodes the specified s2c_use_item message. Does not implicitly {@link bag.s2c_use_item.verify|verify} messages.
         * @function encode
         * @memberof bag.s2c_use_item
         * @static
         * @param {bag.s2c_use_item} message s2c_use_item message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_use_item.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.rewards != null && message.rewards.length)
                for (let i = 0; i < message.rewards.length; ++i)
                    $root.bag.ItemReward.encode(message.rewards[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_use_item message from the specified reader or buffer.
         * @function decode
         * @memberof bag.s2c_use_item
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {bag.s2c_use_item} s2c_use_item
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_use_item.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.bag.s2c_use_item();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.rewards && message.rewards.length))
                            message.rewards = [];
                        message.rewards.push($root.bag.ItemReward.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_use_item
         * @function getTypeUrl
         * @memberof bag.s2c_use_item
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_use_item.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/bag.s2c_use_item";
        };

        return s2c_use_item;
    })();

    bag.c2s_composite_item = (function() {

        /**
         * Properties of a c2s_composite_item.
         * @memberof bag
         * @interface Ic2s_composite_item
         * @property {number|null} [itemId] c2s_composite_item itemId
         * @property {number|null} [times] c2s_composite_item times
         */

        /**
         * Constructs a new c2s_composite_item.
         * @memberof bag
         * @classdesc Represents a c2s_composite_item.
         * @implements Ic2s_composite_item
         * @constructor
         * @param {bag.Ic2s_composite_item=} [properties] Properties to set
         */
        function c2s_composite_item(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_composite_item itemId.
         * @member {number} itemId
         * @memberof bag.c2s_composite_item
         * @instance
         */
        c2s_composite_item.prototype.itemId = 0;

        /**
         * c2s_composite_item times.
         * @member {number} times
         * @memberof bag.c2s_composite_item
         * @instance
         */
        c2s_composite_item.prototype.times = 0;

        /**
         * Creates a new c2s_composite_item instance using the specified properties.
         * @function create
         * @memberof bag.c2s_composite_item
         * @static
         * @param {bag.Ic2s_composite_item=} [properties] Properties to set
         * @returns {bag.c2s_composite_item} c2s_composite_item instance
         */
        c2s_composite_item.create = function create(properties) {
            return new c2s_composite_item(properties);
        };

        /**
         * Encodes the specified c2s_composite_item message. Does not implicitly {@link bag.c2s_composite_item.verify|verify} messages.
         * @function encode
         * @memberof bag.c2s_composite_item
         * @static
         * @param {bag.c2s_composite_item} message c2s_composite_item message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_composite_item.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.itemId != null && Object.hasOwnProperty.call(message, "itemId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.itemId);
            if (message.times != null && Object.hasOwnProperty.call(message, "times"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.times);
            return writer;
        };

        /**
         * Decodes a c2s_composite_item message from the specified reader or buffer.
         * @function decode
         * @memberof bag.c2s_composite_item
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {bag.c2s_composite_item} c2s_composite_item
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_composite_item.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.bag.c2s_composite_item();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.itemId = reader.uint32();
                        break;
                    }
                case 2: {
                        message.times = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_composite_item
         * @function getTypeUrl
         * @memberof bag.c2s_composite_item
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_composite_item.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/bag.c2s_composite_item";
        };

        return c2s_composite_item;
    })();

    bag.s2c_composite_item = (function() {

        /**
         * Properties of a s2c_composite_item.
         * @memberof bag
         * @interface Is2c_composite_item
         * @property {number|null} [err] s2c_composite_item err
         * @property {Array.<bag.ItemReward>|null} [rewards] s2c_composite_item rewards
         */

        /**
         * Constructs a new s2c_composite_item.
         * @memberof bag
         * @classdesc Represents a s2c_composite_item.
         * @implements Is2c_composite_item
         * @constructor
         * @param {bag.Is2c_composite_item=} [properties] Properties to set
         */
        function s2c_composite_item(properties) {
            this.rewards = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_composite_item err.
         * @member {number} err
         * @memberof bag.s2c_composite_item
         * @instance
         */
        s2c_composite_item.prototype.err = 0;

        /**
         * s2c_composite_item rewards.
         * @member {Array.<bag.ItemReward>} rewards
         * @memberof bag.s2c_composite_item
         * @instance
         */
        s2c_composite_item.prototype.rewards = $util.emptyArray;

        /**
         * Creates a new s2c_composite_item instance using the specified properties.
         * @function create
         * @memberof bag.s2c_composite_item
         * @static
         * @param {bag.Is2c_composite_item=} [properties] Properties to set
         * @returns {bag.s2c_composite_item} s2c_composite_item instance
         */
        s2c_composite_item.create = function create(properties) {
            return new s2c_composite_item(properties);
        };

        /**
         * Encodes the specified s2c_composite_item message. Does not implicitly {@link bag.s2c_composite_item.verify|verify} messages.
         * @function encode
         * @memberof bag.s2c_composite_item
         * @static
         * @param {bag.s2c_composite_item} message s2c_composite_item message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_composite_item.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.rewards != null && message.rewards.length)
                for (let i = 0; i < message.rewards.length; ++i)
                    $root.bag.ItemReward.encode(message.rewards[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_composite_item message from the specified reader or buffer.
         * @function decode
         * @memberof bag.s2c_composite_item
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {bag.s2c_composite_item} s2c_composite_item
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_composite_item.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.bag.s2c_composite_item();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.rewards && message.rewards.length))
                            message.rewards = [];
                        message.rewards.push($root.bag.ItemReward.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_composite_item
         * @function getTypeUrl
         * @memberof bag.s2c_composite_item
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_composite_item.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/bag.s2c_composite_item";
        };

        return s2c_composite_item;
    })();

    bag.OptionalChestIdx = (function() {

        /**
         * Properties of an OptionalChestIdx.
         * @memberof bag
         * @interface IOptionalChestIdx
         * @property {number|null} [slotIdx] OptionalChestIdx slotIdx
         * @property {number|null} [num] OptionalChestIdx num
         */

        /**
         * Constructs a new OptionalChestIdx.
         * @memberof bag
         * @classdesc Represents an OptionalChestIdx.
         * @implements IOptionalChestIdx
         * @constructor
         * @param {bag.IOptionalChestIdx=} [properties] Properties to set
         */
        function OptionalChestIdx(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OptionalChestIdx slotIdx.
         * @member {number} slotIdx
         * @memberof bag.OptionalChestIdx
         * @instance
         */
        OptionalChestIdx.prototype.slotIdx = 0;

        /**
         * OptionalChestIdx num.
         * @member {number} num
         * @memberof bag.OptionalChestIdx
         * @instance
         */
        OptionalChestIdx.prototype.num = 0;

        /**
         * Creates a new OptionalChestIdx instance using the specified properties.
         * @function create
         * @memberof bag.OptionalChestIdx
         * @static
         * @param {bag.IOptionalChestIdx=} [properties] Properties to set
         * @returns {bag.OptionalChestIdx} OptionalChestIdx instance
         */
        OptionalChestIdx.create = function create(properties) {
            return new OptionalChestIdx(properties);
        };

        /**
         * Encodes the specified OptionalChestIdx message. Does not implicitly {@link bag.OptionalChestIdx.verify|verify} messages.
         * @function encode
         * @memberof bag.OptionalChestIdx
         * @static
         * @param {bag.OptionalChestIdx} message OptionalChestIdx message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OptionalChestIdx.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.slotIdx != null && Object.hasOwnProperty.call(message, "slotIdx"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.slotIdx);
            if (message.num != null && Object.hasOwnProperty.call(message, "num"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.num);
            return writer;
        };

        /**
         * Decodes an OptionalChestIdx message from the specified reader or buffer.
         * @function decode
         * @memberof bag.OptionalChestIdx
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {bag.OptionalChestIdx} OptionalChestIdx
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OptionalChestIdx.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.bag.OptionalChestIdx();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.slotIdx = reader.uint32();
                        break;
                    }
                case 2: {
                        message.num = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for OptionalChestIdx
         * @function getTypeUrl
         * @memberof bag.OptionalChestIdx
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        OptionalChestIdx.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/bag.OptionalChestIdx";
        };

        return OptionalChestIdx;
    })();

    bag.c2s_open_optional_chest = (function() {

        /**
         * Properties of a c2s_open_optional_chest.
         * @memberof bag
         * @interface Ic2s_open_optional_chest
         * @property {number|null} [itemId] c2s_open_optional_chest itemId
         * @property {Array.<bag.OptionalChestIdx>|null} [idxList] c2s_open_optional_chest idxList
         */

        /**
         * Constructs a new c2s_open_optional_chest.
         * @memberof bag
         * @classdesc Represents a c2s_open_optional_chest.
         * @implements Ic2s_open_optional_chest
         * @constructor
         * @param {bag.Ic2s_open_optional_chest=} [properties] Properties to set
         */
        function c2s_open_optional_chest(properties) {
            this.idxList = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_open_optional_chest itemId.
         * @member {number} itemId
         * @memberof bag.c2s_open_optional_chest
         * @instance
         */
        c2s_open_optional_chest.prototype.itemId = 0;

        /**
         * c2s_open_optional_chest idxList.
         * @member {Array.<bag.OptionalChestIdx>} idxList
         * @memberof bag.c2s_open_optional_chest
         * @instance
         */
        c2s_open_optional_chest.prototype.idxList = $util.emptyArray;

        /**
         * Creates a new c2s_open_optional_chest instance using the specified properties.
         * @function create
         * @memberof bag.c2s_open_optional_chest
         * @static
         * @param {bag.Ic2s_open_optional_chest=} [properties] Properties to set
         * @returns {bag.c2s_open_optional_chest} c2s_open_optional_chest instance
         */
        c2s_open_optional_chest.create = function create(properties) {
            return new c2s_open_optional_chest(properties);
        };

        /**
         * Encodes the specified c2s_open_optional_chest message. Does not implicitly {@link bag.c2s_open_optional_chest.verify|verify} messages.
         * @function encode
         * @memberof bag.c2s_open_optional_chest
         * @static
         * @param {bag.c2s_open_optional_chest} message c2s_open_optional_chest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_open_optional_chest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.itemId != null && Object.hasOwnProperty.call(message, "itemId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.itemId);
            if (message.idxList != null && message.idxList.length)
                for (let i = 0; i < message.idxList.length; ++i)
                    $root.bag.OptionalChestIdx.encode(message.idxList[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a c2s_open_optional_chest message from the specified reader or buffer.
         * @function decode
         * @memberof bag.c2s_open_optional_chest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {bag.c2s_open_optional_chest} c2s_open_optional_chest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_open_optional_chest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.bag.c2s_open_optional_chest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.itemId = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.idxList && message.idxList.length))
                            message.idxList = [];
                        message.idxList.push($root.bag.OptionalChestIdx.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_open_optional_chest
         * @function getTypeUrl
         * @memberof bag.c2s_open_optional_chest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_open_optional_chest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/bag.c2s_open_optional_chest";
        };

        return c2s_open_optional_chest;
    })();

    bag.s2c_open_optional_chest = (function() {

        /**
         * Properties of a s2c_open_optional_chest.
         * @memberof bag
         * @interface Is2c_open_optional_chest
         * @property {number|null} [err] s2c_open_optional_chest err
         * @property {Array.<bag.ItemReward>|null} [rewards] s2c_open_optional_chest rewards
         */

        /**
         * Constructs a new s2c_open_optional_chest.
         * @memberof bag
         * @classdesc Represents a s2c_open_optional_chest.
         * @implements Is2c_open_optional_chest
         * @constructor
         * @param {bag.Is2c_open_optional_chest=} [properties] Properties to set
         */
        function s2c_open_optional_chest(properties) {
            this.rewards = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_open_optional_chest err.
         * @member {number} err
         * @memberof bag.s2c_open_optional_chest
         * @instance
         */
        s2c_open_optional_chest.prototype.err = 0;

        /**
         * s2c_open_optional_chest rewards.
         * @member {Array.<bag.ItemReward>} rewards
         * @memberof bag.s2c_open_optional_chest
         * @instance
         */
        s2c_open_optional_chest.prototype.rewards = $util.emptyArray;

        /**
         * Creates a new s2c_open_optional_chest instance using the specified properties.
         * @function create
         * @memberof bag.s2c_open_optional_chest
         * @static
         * @param {bag.Is2c_open_optional_chest=} [properties] Properties to set
         * @returns {bag.s2c_open_optional_chest} s2c_open_optional_chest instance
         */
        s2c_open_optional_chest.create = function create(properties) {
            return new s2c_open_optional_chest(properties);
        };

        /**
         * Encodes the specified s2c_open_optional_chest message. Does not implicitly {@link bag.s2c_open_optional_chest.verify|verify} messages.
         * @function encode
         * @memberof bag.s2c_open_optional_chest
         * @static
         * @param {bag.s2c_open_optional_chest} message s2c_open_optional_chest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_open_optional_chest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.rewards != null && message.rewards.length)
                for (let i = 0; i < message.rewards.length; ++i)
                    $root.bag.ItemReward.encode(message.rewards[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_open_optional_chest message from the specified reader or buffer.
         * @function decode
         * @memberof bag.s2c_open_optional_chest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {bag.s2c_open_optional_chest} s2c_open_optional_chest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_open_optional_chest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.bag.s2c_open_optional_chest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.rewards && message.rewards.length))
                            message.rewards = [];
                        message.rewards.push($root.bag.ItemReward.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_open_optional_chest
         * @function getTypeUrl
         * @memberof bag.s2c_open_optional_chest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_open_optional_chest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/bag.s2c_open_optional_chest";
        };

        return s2c_open_optional_chest;
    })();

    bag.c2s_open_random_chest = (function() {

        /**
         * Properties of a c2s_open_random_chest.
         * @memberof bag
         * @interface Ic2s_open_random_chest
         * @property {number|null} [itemId] c2s_open_random_chest itemId
         * @property {number|null} [num] c2s_open_random_chest num
         */

        /**
         * Constructs a new c2s_open_random_chest.
         * @memberof bag
         * @classdesc Represents a c2s_open_random_chest.
         * @implements Ic2s_open_random_chest
         * @constructor
         * @param {bag.Ic2s_open_random_chest=} [properties] Properties to set
         */
        function c2s_open_random_chest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_open_random_chest itemId.
         * @member {number} itemId
         * @memberof bag.c2s_open_random_chest
         * @instance
         */
        c2s_open_random_chest.prototype.itemId = 0;

        /**
         * c2s_open_random_chest num.
         * @member {number} num
         * @memberof bag.c2s_open_random_chest
         * @instance
         */
        c2s_open_random_chest.prototype.num = 0;

        /**
         * Creates a new c2s_open_random_chest instance using the specified properties.
         * @function create
         * @memberof bag.c2s_open_random_chest
         * @static
         * @param {bag.Ic2s_open_random_chest=} [properties] Properties to set
         * @returns {bag.c2s_open_random_chest} c2s_open_random_chest instance
         */
        c2s_open_random_chest.create = function create(properties) {
            return new c2s_open_random_chest(properties);
        };

        /**
         * Encodes the specified c2s_open_random_chest message. Does not implicitly {@link bag.c2s_open_random_chest.verify|verify} messages.
         * @function encode
         * @memberof bag.c2s_open_random_chest
         * @static
         * @param {bag.c2s_open_random_chest} message c2s_open_random_chest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_open_random_chest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.itemId != null && Object.hasOwnProperty.call(message, "itemId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.itemId);
            if (message.num != null && Object.hasOwnProperty.call(message, "num"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.num);
            return writer;
        };

        /**
         * Decodes a c2s_open_random_chest message from the specified reader or buffer.
         * @function decode
         * @memberof bag.c2s_open_random_chest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {bag.c2s_open_random_chest} c2s_open_random_chest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_open_random_chest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.bag.c2s_open_random_chest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.itemId = reader.uint32();
                        break;
                    }
                case 2: {
                        message.num = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_open_random_chest
         * @function getTypeUrl
         * @memberof bag.c2s_open_random_chest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_open_random_chest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/bag.c2s_open_random_chest";
        };

        return c2s_open_random_chest;
    })();

    bag.s2c_open_random_chest = (function() {

        /**
         * Properties of a s2c_open_random_chest.
         * @memberof bag
         * @interface Is2c_open_random_chest
         * @property {number|null} [err] s2c_open_random_chest err
         * @property {Array.<bag.ItemReward>|null} [rewards] s2c_open_random_chest rewards
         */

        /**
         * Constructs a new s2c_open_random_chest.
         * @memberof bag
         * @classdesc Represents a s2c_open_random_chest.
         * @implements Is2c_open_random_chest
         * @constructor
         * @param {bag.Is2c_open_random_chest=} [properties] Properties to set
         */
        function s2c_open_random_chest(properties) {
            this.rewards = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_open_random_chest err.
         * @member {number} err
         * @memberof bag.s2c_open_random_chest
         * @instance
         */
        s2c_open_random_chest.prototype.err = 0;

        /**
         * s2c_open_random_chest rewards.
         * @member {Array.<bag.ItemReward>} rewards
         * @memberof bag.s2c_open_random_chest
         * @instance
         */
        s2c_open_random_chest.prototype.rewards = $util.emptyArray;

        /**
         * Creates a new s2c_open_random_chest instance using the specified properties.
         * @function create
         * @memberof bag.s2c_open_random_chest
         * @static
         * @param {bag.Is2c_open_random_chest=} [properties] Properties to set
         * @returns {bag.s2c_open_random_chest} s2c_open_random_chest instance
         */
        s2c_open_random_chest.create = function create(properties) {
            return new s2c_open_random_chest(properties);
        };

        /**
         * Encodes the specified s2c_open_random_chest message. Does not implicitly {@link bag.s2c_open_random_chest.verify|verify} messages.
         * @function encode
         * @memberof bag.s2c_open_random_chest
         * @static
         * @param {bag.s2c_open_random_chest} message s2c_open_random_chest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_open_random_chest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.rewards != null && message.rewards.length)
                for (let i = 0; i < message.rewards.length; ++i)
                    $root.bag.ItemReward.encode(message.rewards[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_open_random_chest message from the specified reader or buffer.
         * @function decode
         * @memberof bag.s2c_open_random_chest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {bag.s2c_open_random_chest} s2c_open_random_chest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_open_random_chest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.bag.s2c_open_random_chest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.rewards && message.rewards.length))
                            message.rewards = [];
                        message.rewards.push($root.bag.ItemReward.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_open_random_chest
         * @function getTypeUrl
         * @memberof bag.s2c_open_random_chest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_open_random_chest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/bag.s2c_open_random_chest";
        };

        return s2c_open_random_chest;
    })();

    bag.c2s_discard_item = (function() {

        /**
         * Properties of a c2s_discard_item.
         * @memberof bag
         * @interface Ic2s_discard_item
         * @property {number|null} [itemId] c2s_discard_item itemId
         * @property {number|null} [itemUid] c2s_discard_item itemUid
         */

        /**
         * Constructs a new c2s_discard_item.
         * @memberof bag
         * @classdesc Represents a c2s_discard_item.
         * @implements Ic2s_discard_item
         * @constructor
         * @param {bag.Ic2s_discard_item=} [properties] Properties to set
         */
        function c2s_discard_item(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_discard_item itemId.
         * @member {number} itemId
         * @memberof bag.c2s_discard_item
         * @instance
         */
        c2s_discard_item.prototype.itemId = 0;

        /**
         * c2s_discard_item itemUid.
         * @member {number} itemUid
         * @memberof bag.c2s_discard_item
         * @instance
         */
        c2s_discard_item.prototype.itemUid = 0;

        /**
         * Creates a new c2s_discard_item instance using the specified properties.
         * @function create
         * @memberof bag.c2s_discard_item
         * @static
         * @param {bag.Ic2s_discard_item=} [properties] Properties to set
         * @returns {bag.c2s_discard_item} c2s_discard_item instance
         */
        c2s_discard_item.create = function create(properties) {
            return new c2s_discard_item(properties);
        };

        /**
         * Encodes the specified c2s_discard_item message. Does not implicitly {@link bag.c2s_discard_item.verify|verify} messages.
         * @function encode
         * @memberof bag.c2s_discard_item
         * @static
         * @param {bag.c2s_discard_item} message c2s_discard_item message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_discard_item.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.itemId != null && Object.hasOwnProperty.call(message, "itemId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.itemId);
            if (message.itemUid != null && Object.hasOwnProperty.call(message, "itemUid"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.itemUid);
            return writer;
        };

        /**
         * Decodes a c2s_discard_item message from the specified reader or buffer.
         * @function decode
         * @memberof bag.c2s_discard_item
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {bag.c2s_discard_item} c2s_discard_item
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_discard_item.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.bag.c2s_discard_item();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.itemId = reader.uint32();
                        break;
                    }
                case 2: {
                        message.itemUid = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_discard_item
         * @function getTypeUrl
         * @memberof bag.c2s_discard_item
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_discard_item.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/bag.c2s_discard_item";
        };

        return c2s_discard_item;
    })();

    bag.s2c_discard_item = (function() {

        /**
         * Properties of a s2c_discard_item.
         * @memberof bag
         * @interface Is2c_discard_item
         * @property {number|null} [err] s2c_discard_item err
         */

        /**
         * Constructs a new s2c_discard_item.
         * @memberof bag
         * @classdesc Represents a s2c_discard_item.
         * @implements Is2c_discard_item
         * @constructor
         * @param {bag.Is2c_discard_item=} [properties] Properties to set
         */
        function s2c_discard_item(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_discard_item err.
         * @member {number} err
         * @memberof bag.s2c_discard_item
         * @instance
         */
        s2c_discard_item.prototype.err = 0;

        /**
         * Creates a new s2c_discard_item instance using the specified properties.
         * @function create
         * @memberof bag.s2c_discard_item
         * @static
         * @param {bag.Is2c_discard_item=} [properties] Properties to set
         * @returns {bag.s2c_discard_item} s2c_discard_item instance
         */
        s2c_discard_item.create = function create(properties) {
            return new s2c_discard_item(properties);
        };

        /**
         * Encodes the specified s2c_discard_item message. Does not implicitly {@link bag.s2c_discard_item.verify|verify} messages.
         * @function encode
         * @memberof bag.s2c_discard_item
         * @static
         * @param {bag.s2c_discard_item} message s2c_discard_item message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_discard_item.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_discard_item message from the specified reader or buffer.
         * @function decode
         * @memberof bag.s2c_discard_item
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {bag.s2c_discard_item} s2c_discard_item
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_discard_item.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.bag.s2c_discard_item();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_discard_item
         * @function getTypeUrl
         * @memberof bag.s2c_discard_item
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_discard_item.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/bag.s2c_discard_item";
        };

        return s2c_discard_item;
    })();

    bag.notify_items = (function() {

        /**
         * Properties of a notify_items.
         * @memberof bag
         * @interface Inotify_items
         * @property {Array.<bag.Item>|null} [items] notify_items items
         */

        /**
         * Constructs a new notify_items.
         * @memberof bag
         * @classdesc Represents a notify_items.
         * @implements Inotify_items
         * @constructor
         * @param {bag.Inotify_items=} [properties] Properties to set
         */
        function notify_items(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_items items.
         * @member {Array.<bag.Item>} items
         * @memberof bag.notify_items
         * @instance
         */
        notify_items.prototype.items = $util.emptyArray;

        /**
         * Creates a new notify_items instance using the specified properties.
         * @function create
         * @memberof bag.notify_items
         * @static
         * @param {bag.Inotify_items=} [properties] Properties to set
         * @returns {bag.notify_items} notify_items instance
         */
        notify_items.create = function create(properties) {
            return new notify_items(properties);
        };

        /**
         * Encodes the specified notify_items message. Does not implicitly {@link bag.notify_items.verify|verify} messages.
         * @function encode
         * @memberof bag.notify_items
         * @static
         * @param {bag.notify_items} message notify_items message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_items.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.bag.Item.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a notify_items message from the specified reader or buffer.
         * @function decode
         * @memberof bag.notify_items
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {bag.notify_items} notify_items
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_items.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.bag.notify_items();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.bag.Item.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_items
         * @function getTypeUrl
         * @memberof bag.notify_items
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_items.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/bag.notify_items";
        };

        return notify_items;
    })();

    bag.notify_reward = (function() {

        /**
         * Properties of a notify_reward.
         * @memberof bag
         * @interface Inotify_reward
         * @property {Array.<bag.Item>|null} [items] notify_reward items
         */

        /**
         * Constructs a new notify_reward.
         * @memberof bag
         * @classdesc Represents a notify_reward.
         * @implements Inotify_reward
         * @constructor
         * @param {bag.Inotify_reward=} [properties] Properties to set
         */
        function notify_reward(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_reward items.
         * @member {Array.<bag.Item>} items
         * @memberof bag.notify_reward
         * @instance
         */
        notify_reward.prototype.items = $util.emptyArray;

        /**
         * Creates a new notify_reward instance using the specified properties.
         * @function create
         * @memberof bag.notify_reward
         * @static
         * @param {bag.Inotify_reward=} [properties] Properties to set
         * @returns {bag.notify_reward} notify_reward instance
         */
        notify_reward.create = function create(properties) {
            return new notify_reward(properties);
        };

        /**
         * Encodes the specified notify_reward message. Does not implicitly {@link bag.notify_reward.verify|verify} messages.
         * @function encode
         * @memberof bag.notify_reward
         * @static
         * @param {bag.notify_reward} message notify_reward message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_reward.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.bag.Item.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a notify_reward message from the specified reader or buffer.
         * @function decode
         * @memberof bag.notify_reward
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {bag.notify_reward} notify_reward
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_reward.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.bag.notify_reward();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.bag.Item.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_reward
         * @function getTypeUrl
         * @memberof bag.notify_reward
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_reward.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/bag.notify_reward";
        };

        return notify_reward;
    })();

    return bag;
})();

export const battle = $root.battle = (() => {

    /**
     * Namespace battle.
     * @exports battle
     * @namespace
     */
    const battle = {};

    battle.BattleHero = (function() {

        /**
         * Properties of a BattleHero.
         * @memberof battle
         * @interface IBattleHero
         * @property {number|null} [heroId] BattleHero heroId
         * @property {number|null} [eid] BattleHero eid
         */

        /**
         * Constructs a new BattleHero.
         * @memberof battle
         * @classdesc Represents a BattleHero.
         * @implements IBattleHero
         * @constructor
         * @param {battle.IBattleHero=} [properties] Properties to set
         */
        function BattleHero(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BattleHero heroId.
         * @member {number} heroId
         * @memberof battle.BattleHero
         * @instance
         */
        BattleHero.prototype.heroId = 0;

        /**
         * BattleHero eid.
         * @member {number} eid
         * @memberof battle.BattleHero
         * @instance
         */
        BattleHero.prototype.eid = 0;

        /**
         * Creates a new BattleHero instance using the specified properties.
         * @function create
         * @memberof battle.BattleHero
         * @static
         * @param {battle.IBattleHero=} [properties] Properties to set
         * @returns {battle.BattleHero} BattleHero instance
         */
        BattleHero.create = function create(properties) {
            return new BattleHero(properties);
        };

        /**
         * Encodes the specified BattleHero message. Does not implicitly {@link battle.BattleHero.verify|verify} messages.
         * @function encode
         * @memberof battle.BattleHero
         * @static
         * @param {battle.BattleHero} message BattleHero message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BattleHero.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.heroId != null && Object.hasOwnProperty.call(message, "heroId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.heroId);
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.eid);
            return writer;
        };

        /**
         * Decodes a BattleHero message from the specified reader or buffer.
         * @function decode
         * @memberof battle.BattleHero
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.BattleHero} BattleHero
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BattleHero.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.BattleHero();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.heroId = reader.uint32();
                        break;
                    }
                case 2: {
                        message.eid = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for BattleHero
         * @function getTypeUrl
         * @memberof battle.BattleHero
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BattleHero.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.BattleHero";
        };

        return BattleHero;
    })();

    battle.BattleRole = (function() {

        /**
         * Properties of a BattleRole.
         * @memberof battle
         * @interface IBattleRole
         * @property {number|null} [rid] BattleRole rid
         * @property {number|null} [aid] BattleRole aid
         * @property {string|null} [name] BattleRole name
         * @property {Array.<battle.BattleHero>|null} [heros] BattleRole heros
         */

        /**
         * Constructs a new BattleRole.
         * @memberof battle
         * @classdesc Represents a BattleRole.
         * @implements IBattleRole
         * @constructor
         * @param {battle.IBattleRole=} [properties] Properties to set
         */
        function BattleRole(properties) {
            this.heros = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BattleRole rid.
         * @member {number} rid
         * @memberof battle.BattleRole
         * @instance
         */
        BattleRole.prototype.rid = 0;

        /**
         * BattleRole aid.
         * @member {number} aid
         * @memberof battle.BattleRole
         * @instance
         */
        BattleRole.prototype.aid = 0;

        /**
         * BattleRole name.
         * @member {string} name
         * @memberof battle.BattleRole
         * @instance
         */
        BattleRole.prototype.name = "";

        /**
         * BattleRole heros.
         * @member {Array.<battle.BattleHero>} heros
         * @memberof battle.BattleRole
         * @instance
         */
        BattleRole.prototype.heros = $util.emptyArray;

        /**
         * Creates a new BattleRole instance using the specified properties.
         * @function create
         * @memberof battle.BattleRole
         * @static
         * @param {battle.IBattleRole=} [properties] Properties to set
         * @returns {battle.BattleRole} BattleRole instance
         */
        BattleRole.create = function create(properties) {
            return new BattleRole(properties);
        };

        /**
         * Encodes the specified BattleRole message. Does not implicitly {@link battle.BattleRole.verify|verify} messages.
         * @function encode
         * @memberof battle.BattleRole
         * @static
         * @param {battle.BattleRole} message BattleRole message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BattleRole.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rid != null && Object.hasOwnProperty.call(message, "rid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.rid);
            if (message.aid != null && Object.hasOwnProperty.call(message, "aid"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.aid);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.heros != null && message.heros.length)
                for (let i = 0; i < message.heros.length; ++i)
                    $root.battle.BattleHero.encode(message.heros[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a BattleRole message from the specified reader or buffer.
         * @function decode
         * @memberof battle.BattleRole
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.BattleRole} BattleRole
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BattleRole.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.BattleRole();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.aid = reader.uint32();
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                case 4: {
                        if (!(message.heros && message.heros.length))
                            message.heros = [];
                        message.heros.push($root.battle.BattleHero.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for BattleRole
         * @function getTypeUrl
         * @memberof battle.BattleRole
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BattleRole.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.BattleRole";
        };

        return BattleRole;
    })();

    battle.MoveInfo = (function() {

        /**
         * Properties of a MoveInfo.
         * @memberof battle
         * @interface IMoveInfo
         * @property {number|null} [speed] MoveInfo speed
         * @property {number|null} [targetX] MoveInfo targetX
         * @property {number|null} [targetY] MoveInfo targetY
         * @property {boolean|null} [force] MoveInfo force
         */

        /**
         * Constructs a new MoveInfo.
         * @memberof battle
         * @classdesc Represents a MoveInfo.
         * @implements IMoveInfo
         * @constructor
         * @param {battle.IMoveInfo=} [properties] Properties to set
         */
        function MoveInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MoveInfo speed.
         * @member {number} speed
         * @memberof battle.MoveInfo
         * @instance
         */
        MoveInfo.prototype.speed = 0;

        /**
         * MoveInfo targetX.
         * @member {number} targetX
         * @memberof battle.MoveInfo
         * @instance
         */
        MoveInfo.prototype.targetX = 0;

        /**
         * MoveInfo targetY.
         * @member {number} targetY
         * @memberof battle.MoveInfo
         * @instance
         */
        MoveInfo.prototype.targetY = 0;

        /**
         * MoveInfo force.
         * @member {boolean} force
         * @memberof battle.MoveInfo
         * @instance
         */
        MoveInfo.prototype.force = false;

        /**
         * Creates a new MoveInfo instance using the specified properties.
         * @function create
         * @memberof battle.MoveInfo
         * @static
         * @param {battle.IMoveInfo=} [properties] Properties to set
         * @returns {battle.MoveInfo} MoveInfo instance
         */
        MoveInfo.create = function create(properties) {
            return new MoveInfo(properties);
        };

        /**
         * Encodes the specified MoveInfo message. Does not implicitly {@link battle.MoveInfo.verify|verify} messages.
         * @function encode
         * @memberof battle.MoveInfo
         * @static
         * @param {battle.MoveInfo} message MoveInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MoveInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.speed != null && Object.hasOwnProperty.call(message, "speed"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message.speed);
            if (message.targetX != null && Object.hasOwnProperty.call(message, "targetX"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.targetX);
            if (message.targetY != null && Object.hasOwnProperty.call(message, "targetY"))
                writer.uint32(/* id 3, wireType 5 =*/29).float(message.targetY);
            if (message.force != null && Object.hasOwnProperty.call(message, "force"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.force);
            return writer;
        };

        /**
         * Decodes a MoveInfo message from the specified reader or buffer.
         * @function decode
         * @memberof battle.MoveInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.MoveInfo} MoveInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MoveInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.MoveInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.speed = reader.float();
                        break;
                    }
                case 2: {
                        message.targetX = reader.float();
                        break;
                    }
                case 3: {
                        message.targetY = reader.float();
                        break;
                    }
                case 4: {
                        message.force = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for MoveInfo
         * @function getTypeUrl
         * @memberof battle.MoveInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MoveInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.MoveInfo";
        };

        return MoveInfo;
    })();

    battle.BattleEntity = (function() {

        /**
         * Properties of a BattleEntity.
         * @memberof battle
         * @interface IBattleEntity
         * @property {number|null} [eid] BattleEntity eid
         * @property {number|null} [rid] BattleEntity rid
         * @property {number|null} [worldEid] BattleEntity worldEid
         * @property {number|null} [entityId] BattleEntity entityId
         * @property {number|null} [maxHp] BattleEntity maxHp
         * @property {number|null} [hp] BattleEntity hp
         * @property {number|null} [x] BattleEntity x
         * @property {number|null} [y] BattleEntity y
         * @property {number|null} [rotation] BattleEntity rotation
         * @property {number|null} [maxMp] BattleEntity maxMp
         * @property {number|null} [mp] BattleEntity mp
         * @property {battle.MoveInfo|null} [move] BattleEntity move
         */

        /**
         * Constructs a new BattleEntity.
         * @memberof battle
         * @classdesc Represents a BattleEntity.
         * @implements IBattleEntity
         * @constructor
         * @param {battle.IBattleEntity=} [properties] Properties to set
         */
        function BattleEntity(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BattleEntity eid.
         * @member {number} eid
         * @memberof battle.BattleEntity
         * @instance
         */
        BattleEntity.prototype.eid = 0;

        /**
         * BattleEntity rid.
         * @member {number} rid
         * @memberof battle.BattleEntity
         * @instance
         */
        BattleEntity.prototype.rid = 0;

        /**
         * BattleEntity worldEid.
         * @member {number} worldEid
         * @memberof battle.BattleEntity
         * @instance
         */
        BattleEntity.prototype.worldEid = 0;

        /**
         * BattleEntity entityId.
         * @member {number} entityId
         * @memberof battle.BattleEntity
         * @instance
         */
        BattleEntity.prototype.entityId = 0;

        /**
         * BattleEntity maxHp.
         * @member {number} maxHp
         * @memberof battle.BattleEntity
         * @instance
         */
        BattleEntity.prototype.maxHp = 0;

        /**
         * BattleEntity hp.
         * @member {number} hp
         * @memberof battle.BattleEntity
         * @instance
         */
        BattleEntity.prototype.hp = 0;

        /**
         * BattleEntity x.
         * @member {number} x
         * @memberof battle.BattleEntity
         * @instance
         */
        BattleEntity.prototype.x = 0;

        /**
         * BattleEntity y.
         * @member {number} y
         * @memberof battle.BattleEntity
         * @instance
         */
        BattleEntity.prototype.y = 0;

        /**
         * BattleEntity rotation.
         * @member {number} rotation
         * @memberof battle.BattleEntity
         * @instance
         */
        BattleEntity.prototype.rotation = 0;

        /**
         * BattleEntity maxMp.
         * @member {number} maxMp
         * @memberof battle.BattleEntity
         * @instance
         */
        BattleEntity.prototype.maxMp = 0;

        /**
         * BattleEntity mp.
         * @member {number} mp
         * @memberof battle.BattleEntity
         * @instance
         */
        BattleEntity.prototype.mp = 0;

        /**
         * BattleEntity move.
         * @member {battle.MoveInfo|null|undefined} move
         * @memberof battle.BattleEntity
         * @instance
         */
        BattleEntity.prototype.move = null;

        /**
         * Creates a new BattleEntity instance using the specified properties.
         * @function create
         * @memberof battle.BattleEntity
         * @static
         * @param {battle.IBattleEntity=} [properties] Properties to set
         * @returns {battle.BattleEntity} BattleEntity instance
         */
        BattleEntity.create = function create(properties) {
            return new BattleEntity(properties);
        };

        /**
         * Encodes the specified BattleEntity message. Does not implicitly {@link battle.BattleEntity.verify|verify} messages.
         * @function encode
         * @memberof battle.BattleEntity
         * @static
         * @param {battle.BattleEntity} message BattleEntity message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BattleEntity.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.eid);
            if (message.rid != null && Object.hasOwnProperty.call(message, "rid"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.rid);
            if (message.worldEid != null && Object.hasOwnProperty.call(message, "worldEid"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.worldEid);
            if (message.entityId != null && Object.hasOwnProperty.call(message, "entityId"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.entityId);
            if (message.maxHp != null && Object.hasOwnProperty.call(message, "maxHp"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.maxHp);
            if (message.hp != null && Object.hasOwnProperty.call(message, "hp"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.hp);
            if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                writer.uint32(/* id 10, wireType 5 =*/85).float(message.x);
            if (message.y != null && Object.hasOwnProperty.call(message, "y"))
                writer.uint32(/* id 11, wireType 5 =*/93).float(message.y);
            if (message.rotation != null && Object.hasOwnProperty.call(message, "rotation"))
                writer.uint32(/* id 12, wireType 0 =*/96).int32(message.rotation);
            if (message.maxMp != null && Object.hasOwnProperty.call(message, "maxMp"))
                writer.uint32(/* id 13, wireType 0 =*/104).uint32(message.maxMp);
            if (message.mp != null && Object.hasOwnProperty.call(message, "mp"))
                writer.uint32(/* id 14, wireType 0 =*/112).uint32(message.mp);
            if (message.move != null && Object.hasOwnProperty.call(message, "move"))
                $root.battle.MoveInfo.encode(message.move, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a BattleEntity message from the specified reader or buffer.
         * @function decode
         * @memberof battle.BattleEntity
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.BattleEntity} BattleEntity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BattleEntity.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.BattleEntity();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.eid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.rid = reader.uint32();
                        break;
                    }
                case 3: {
                        message.worldEid = reader.uint32();
                        break;
                    }
                case 4: {
                        message.entityId = reader.uint32();
                        break;
                    }
                case 5: {
                        message.maxHp = reader.uint32();
                        break;
                    }
                case 6: {
                        message.hp = reader.uint32();
                        break;
                    }
                case 10: {
                        message.x = reader.float();
                        break;
                    }
                case 11: {
                        message.y = reader.float();
                        break;
                    }
                case 12: {
                        message.rotation = reader.int32();
                        break;
                    }
                case 13: {
                        message.maxMp = reader.uint32();
                        break;
                    }
                case 14: {
                        message.mp = reader.uint32();
                        break;
                    }
                case 15: {
                        message.move = $root.battle.MoveInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for BattleEntity
         * @function getTypeUrl
         * @memberof battle.BattleEntity
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BattleEntity.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.BattleEntity";
        };

        return BattleEntity;
    })();

    battle.DebugInfo = (function() {

        /**
         * Properties of a DebugInfo.
         * @memberof battle
         * @interface IDebugInfo
         * @property {number|null} [x] DebugInfo x
         * @property {number|null} [y] DebugInfo y
         * @property {number|null} [color] DebugInfo color
         * @property {number|null} [size] DebugInfo size
         */

        /**
         * Constructs a new DebugInfo.
         * @memberof battle
         * @classdesc Represents a DebugInfo.
         * @implements IDebugInfo
         * @constructor
         * @param {battle.IDebugInfo=} [properties] Properties to set
         */
        function DebugInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DebugInfo x.
         * @member {number} x
         * @memberof battle.DebugInfo
         * @instance
         */
        DebugInfo.prototype.x = 0;

        /**
         * DebugInfo y.
         * @member {number} y
         * @memberof battle.DebugInfo
         * @instance
         */
        DebugInfo.prototype.y = 0;

        /**
         * DebugInfo color.
         * @member {number} color
         * @memberof battle.DebugInfo
         * @instance
         */
        DebugInfo.prototype.color = 0;

        /**
         * DebugInfo size.
         * @member {number} size
         * @memberof battle.DebugInfo
         * @instance
         */
        DebugInfo.prototype.size = 0;

        /**
         * Creates a new DebugInfo instance using the specified properties.
         * @function create
         * @memberof battle.DebugInfo
         * @static
         * @param {battle.IDebugInfo=} [properties] Properties to set
         * @returns {battle.DebugInfo} DebugInfo instance
         */
        DebugInfo.create = function create(properties) {
            return new DebugInfo(properties);
        };

        /**
         * Encodes the specified DebugInfo message. Does not implicitly {@link battle.DebugInfo.verify|verify} messages.
         * @function encode
         * @memberof battle.DebugInfo
         * @static
         * @param {battle.DebugInfo} message DebugInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DebugInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message.x);
            if (message.y != null && Object.hasOwnProperty.call(message, "y"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.y);
            if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.color);
            if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.size);
            return writer;
        };

        /**
         * Decodes a DebugInfo message from the specified reader or buffer.
         * @function decode
         * @memberof battle.DebugInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.DebugInfo} DebugInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DebugInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.DebugInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.x = reader.float();
                        break;
                    }
                case 2: {
                        message.y = reader.float();
                        break;
                    }
                case 4: {
                        message.color = reader.uint32();
                        break;
                    }
                case 5: {
                        message.size = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for DebugInfo
         * @function getTypeUrl
         * @memberof battle.DebugInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DebugInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.DebugInfo";
        };

        return DebugInfo;
    })();

    battle.cmd_add_entity = (function() {

        /**
         * Properties of a cmd_add_entity.
         * @memberof battle
         * @interface Icmd_add_entity
         * @property {battle.BattleEntity|null} [entity] cmd_add_entity entity
         */

        /**
         * Constructs a new cmd_add_entity.
         * @memberof battle
         * @classdesc Represents a cmd_add_entity.
         * @implements Icmd_add_entity
         * @constructor
         * @param {battle.Icmd_add_entity=} [properties] Properties to set
         */
        function cmd_add_entity(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * cmd_add_entity entity.
         * @member {battle.BattleEntity|null|undefined} entity
         * @memberof battle.cmd_add_entity
         * @instance
         */
        cmd_add_entity.prototype.entity = null;

        /**
         * Creates a new cmd_add_entity instance using the specified properties.
         * @function create
         * @memberof battle.cmd_add_entity
         * @static
         * @param {battle.Icmd_add_entity=} [properties] Properties to set
         * @returns {battle.cmd_add_entity} cmd_add_entity instance
         */
        cmd_add_entity.create = function create(properties) {
            return new cmd_add_entity(properties);
        };

        /**
         * Encodes the specified cmd_add_entity message. Does not implicitly {@link battle.cmd_add_entity.verify|verify} messages.
         * @function encode
         * @memberof battle.cmd_add_entity
         * @static
         * @param {battle.cmd_add_entity} message cmd_add_entity message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        cmd_add_entity.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.entity != null && Object.hasOwnProperty.call(message, "entity"))
                $root.battle.BattleEntity.encode(message.entity, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a cmd_add_entity message from the specified reader or buffer.
         * @function decode
         * @memberof battle.cmd_add_entity
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.cmd_add_entity} cmd_add_entity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        cmd_add_entity.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.cmd_add_entity();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.entity = $root.battle.BattleEntity.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for cmd_add_entity
         * @function getTypeUrl
         * @memberof battle.cmd_add_entity
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        cmd_add_entity.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.cmd_add_entity";
        };

        return cmd_add_entity;
    })();

    battle.cmd_update_entity = (function() {

        /**
         * Properties of a cmd_update_entity.
         * @memberof battle
         * @interface Icmd_update_entity
         * @property {number|null} [eid] cmd_update_entity eid
         * @property {number|null} [maxHp] cmd_update_entity maxHp
         * @property {number|null} [hp] cmd_update_entity hp
         * @property {number|null} [x] cmd_update_entity x
         * @property {number|null} [y] cmd_update_entity y
         */

        /**
         * Constructs a new cmd_update_entity.
         * @memberof battle
         * @classdesc Represents a cmd_update_entity.
         * @implements Icmd_update_entity
         * @constructor
         * @param {battle.Icmd_update_entity=} [properties] Properties to set
         */
        function cmd_update_entity(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * cmd_update_entity eid.
         * @member {number} eid
         * @memberof battle.cmd_update_entity
         * @instance
         */
        cmd_update_entity.prototype.eid = 0;

        /**
         * cmd_update_entity maxHp.
         * @member {number} maxHp
         * @memberof battle.cmd_update_entity
         * @instance
         */
        cmd_update_entity.prototype.maxHp = 0;

        /**
         * cmd_update_entity hp.
         * @member {number} hp
         * @memberof battle.cmd_update_entity
         * @instance
         */
        cmd_update_entity.prototype.hp = 0;

        /**
         * cmd_update_entity x.
         * @member {number} x
         * @memberof battle.cmd_update_entity
         * @instance
         */
        cmd_update_entity.prototype.x = 0;

        /**
         * cmd_update_entity y.
         * @member {number} y
         * @memberof battle.cmd_update_entity
         * @instance
         */
        cmd_update_entity.prototype.y = 0;

        /**
         * Creates a new cmd_update_entity instance using the specified properties.
         * @function create
         * @memberof battle.cmd_update_entity
         * @static
         * @param {battle.Icmd_update_entity=} [properties] Properties to set
         * @returns {battle.cmd_update_entity} cmd_update_entity instance
         */
        cmd_update_entity.create = function create(properties) {
            return new cmd_update_entity(properties);
        };

        /**
         * Encodes the specified cmd_update_entity message. Does not implicitly {@link battle.cmd_update_entity.verify|verify} messages.
         * @function encode
         * @memberof battle.cmd_update_entity
         * @static
         * @param {battle.cmd_update_entity} message cmd_update_entity message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        cmd_update_entity.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.eid);
            if (message.maxHp != null && Object.hasOwnProperty.call(message, "maxHp"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.maxHp);
            if (message.hp != null && Object.hasOwnProperty.call(message, "hp"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.hp);
            if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                writer.uint32(/* id 5, wireType 5 =*/45).float(message.x);
            if (message.y != null && Object.hasOwnProperty.call(message, "y"))
                writer.uint32(/* id 6, wireType 5 =*/53).float(message.y);
            return writer;
        };

        /**
         * Decodes a cmd_update_entity message from the specified reader or buffer.
         * @function decode
         * @memberof battle.cmd_update_entity
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.cmd_update_entity} cmd_update_entity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        cmd_update_entity.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.cmd_update_entity();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.eid = reader.uint32();
                        break;
                    }
                case 3: {
                        message.maxHp = reader.uint32();
                        break;
                    }
                case 4: {
                        message.hp = reader.uint32();
                        break;
                    }
                case 5: {
                        message.x = reader.float();
                        break;
                    }
                case 6: {
                        message.y = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for cmd_update_entity
         * @function getTypeUrl
         * @memberof battle.cmd_update_entity
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        cmd_update_entity.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.cmd_update_entity";
        };

        return cmd_update_entity;
    })();

    battle.cmd_del_entity = (function() {

        /**
         * Properties of a cmd_del_entity.
         * @memberof battle
         * @interface Icmd_del_entity
         * @property {number|null} [eid] cmd_del_entity eid
         */

        /**
         * Constructs a new cmd_del_entity.
         * @memberof battle
         * @classdesc Represents a cmd_del_entity.
         * @implements Icmd_del_entity
         * @constructor
         * @param {battle.Icmd_del_entity=} [properties] Properties to set
         */
        function cmd_del_entity(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * cmd_del_entity eid.
         * @member {number} eid
         * @memberof battle.cmd_del_entity
         * @instance
         */
        cmd_del_entity.prototype.eid = 0;

        /**
         * Creates a new cmd_del_entity instance using the specified properties.
         * @function create
         * @memberof battle.cmd_del_entity
         * @static
         * @param {battle.Icmd_del_entity=} [properties] Properties to set
         * @returns {battle.cmd_del_entity} cmd_del_entity instance
         */
        cmd_del_entity.create = function create(properties) {
            return new cmd_del_entity(properties);
        };

        /**
         * Encodes the specified cmd_del_entity message. Does not implicitly {@link battle.cmd_del_entity.verify|verify} messages.
         * @function encode
         * @memberof battle.cmd_del_entity
         * @static
         * @param {battle.cmd_del_entity} message cmd_del_entity message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        cmd_del_entity.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.eid);
            return writer;
        };

        /**
         * Decodes a cmd_del_entity message from the specified reader or buffer.
         * @function decode
         * @memberof battle.cmd_del_entity
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.cmd_del_entity} cmd_del_entity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        cmd_del_entity.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.cmd_del_entity();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.eid = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for cmd_del_entity
         * @function getTypeUrl
         * @memberof battle.cmd_del_entity
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        cmd_del_entity.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.cmd_del_entity";
        };

        return cmd_del_entity;
    })();

    battle.cmd_move_to = (function() {

        /**
         * Properties of a cmd_move_to.
         * @memberof battle
         * @interface Icmd_move_to
         * @property {number|null} [eid] cmd_move_to eid
         * @property {number|null} [speed] cmd_move_to speed
         * @property {number|null} [targetX] cmd_move_to targetX
         * @property {number|null} [targetY] cmd_move_to targetY
         */

        /**
         * Constructs a new cmd_move_to.
         * @memberof battle
         * @classdesc Represents a cmd_move_to.
         * @implements Icmd_move_to
         * @constructor
         * @param {battle.Icmd_move_to=} [properties] Properties to set
         */
        function cmd_move_to(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * cmd_move_to eid.
         * @member {number} eid
         * @memberof battle.cmd_move_to
         * @instance
         */
        cmd_move_to.prototype.eid = 0;

        /**
         * cmd_move_to speed.
         * @member {number} speed
         * @memberof battle.cmd_move_to
         * @instance
         */
        cmd_move_to.prototype.speed = 0;

        /**
         * cmd_move_to targetX.
         * @member {number} targetX
         * @memberof battle.cmd_move_to
         * @instance
         */
        cmd_move_to.prototype.targetX = 0;

        /**
         * cmd_move_to targetY.
         * @member {number} targetY
         * @memberof battle.cmd_move_to
         * @instance
         */
        cmd_move_to.prototype.targetY = 0;

        /**
         * Creates a new cmd_move_to instance using the specified properties.
         * @function create
         * @memberof battle.cmd_move_to
         * @static
         * @param {battle.Icmd_move_to=} [properties] Properties to set
         * @returns {battle.cmd_move_to} cmd_move_to instance
         */
        cmd_move_to.create = function create(properties) {
            return new cmd_move_to(properties);
        };

        /**
         * Encodes the specified cmd_move_to message. Does not implicitly {@link battle.cmd_move_to.verify|verify} messages.
         * @function encode
         * @memberof battle.cmd_move_to
         * @static
         * @param {battle.cmd_move_to} message cmd_move_to message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        cmd_move_to.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.eid);
            if (message.speed != null && Object.hasOwnProperty.call(message, "speed"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.speed);
            if (message.targetX != null && Object.hasOwnProperty.call(message, "targetX"))
                writer.uint32(/* id 10, wireType 5 =*/85).float(message.targetX);
            if (message.targetY != null && Object.hasOwnProperty.call(message, "targetY"))
                writer.uint32(/* id 11, wireType 5 =*/93).float(message.targetY);
            return writer;
        };

        /**
         * Decodes a cmd_move_to message from the specified reader or buffer.
         * @function decode
         * @memberof battle.cmd_move_to
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.cmd_move_to} cmd_move_to
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        cmd_move_to.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.cmd_move_to();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.eid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.speed = reader.float();
                        break;
                    }
                case 10: {
                        message.targetX = reader.float();
                        break;
                    }
                case 11: {
                        message.targetY = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for cmd_move_to
         * @function getTypeUrl
         * @memberof battle.cmd_move_to
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        cmd_move_to.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.cmd_move_to";
        };

        return cmd_move_to;
    })();

    battle.cmd_force_to = (function() {

        /**
         * Properties of a cmd_force_to.
         * @memberof battle
         * @interface Icmd_force_to
         * @property {number|null} [eid] cmd_force_to eid
         * @property {number|null} [speed] cmd_force_to speed
         * @property {number|null} [targetX] cmd_force_to targetX
         * @property {number|null} [targetY] cmd_force_to targetY
         */

        /**
         * Constructs a new cmd_force_to.
         * @memberof battle
         * @classdesc Represents a cmd_force_to.
         * @implements Icmd_force_to
         * @constructor
         * @param {battle.Icmd_force_to=} [properties] Properties to set
         */
        function cmd_force_to(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * cmd_force_to eid.
         * @member {number} eid
         * @memberof battle.cmd_force_to
         * @instance
         */
        cmd_force_to.prototype.eid = 0;

        /**
         * cmd_force_to speed.
         * @member {number} speed
         * @memberof battle.cmd_force_to
         * @instance
         */
        cmd_force_to.prototype.speed = 0;

        /**
         * cmd_force_to targetX.
         * @member {number} targetX
         * @memberof battle.cmd_force_to
         * @instance
         */
        cmd_force_to.prototype.targetX = 0;

        /**
         * cmd_force_to targetY.
         * @member {number} targetY
         * @memberof battle.cmd_force_to
         * @instance
         */
        cmd_force_to.prototype.targetY = 0;

        /**
         * Creates a new cmd_force_to instance using the specified properties.
         * @function create
         * @memberof battle.cmd_force_to
         * @static
         * @param {battle.Icmd_force_to=} [properties] Properties to set
         * @returns {battle.cmd_force_to} cmd_force_to instance
         */
        cmd_force_to.create = function create(properties) {
            return new cmd_force_to(properties);
        };

        /**
         * Encodes the specified cmd_force_to message. Does not implicitly {@link battle.cmd_force_to.verify|verify} messages.
         * @function encode
         * @memberof battle.cmd_force_to
         * @static
         * @param {battle.cmd_force_to} message cmd_force_to message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        cmd_force_to.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.eid);
            if (message.speed != null && Object.hasOwnProperty.call(message, "speed"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.speed);
            if (message.targetX != null && Object.hasOwnProperty.call(message, "targetX"))
                writer.uint32(/* id 10, wireType 5 =*/85).float(message.targetX);
            if (message.targetY != null && Object.hasOwnProperty.call(message, "targetY"))
                writer.uint32(/* id 11, wireType 5 =*/93).float(message.targetY);
            return writer;
        };

        /**
         * Decodes a cmd_force_to message from the specified reader or buffer.
         * @function decode
         * @memberof battle.cmd_force_to
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.cmd_force_to} cmd_force_to
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        cmd_force_to.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.cmd_force_to();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.eid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.speed = reader.float();
                        break;
                    }
                case 10: {
                        message.targetX = reader.float();
                        break;
                    }
                case 11: {
                        message.targetY = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for cmd_force_to
         * @function getTypeUrl
         * @memberof battle.cmd_force_to
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        cmd_force_to.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.cmd_force_to";
        };

        return cmd_force_to;
    })();

    battle.cmd_move_stop = (function() {

        /**
         * Properties of a cmd_move_stop.
         * @memberof battle
         * @interface Icmd_move_stop
         * @property {number|null} [eid] cmd_move_stop eid
         * @property {number|null} [x] cmd_move_stop x
         * @property {number|null} [y] cmd_move_stop y
         */

        /**
         * Constructs a new cmd_move_stop.
         * @memberof battle
         * @classdesc Represents a cmd_move_stop.
         * @implements Icmd_move_stop
         * @constructor
         * @param {battle.Icmd_move_stop=} [properties] Properties to set
         */
        function cmd_move_stop(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * cmd_move_stop eid.
         * @member {number} eid
         * @memberof battle.cmd_move_stop
         * @instance
         */
        cmd_move_stop.prototype.eid = 0;

        /**
         * cmd_move_stop x.
         * @member {number} x
         * @memberof battle.cmd_move_stop
         * @instance
         */
        cmd_move_stop.prototype.x = 0;

        /**
         * cmd_move_stop y.
         * @member {number} y
         * @memberof battle.cmd_move_stop
         * @instance
         */
        cmd_move_stop.prototype.y = 0;

        /**
         * Creates a new cmd_move_stop instance using the specified properties.
         * @function create
         * @memberof battle.cmd_move_stop
         * @static
         * @param {battle.Icmd_move_stop=} [properties] Properties to set
         * @returns {battle.cmd_move_stop} cmd_move_stop instance
         */
        cmd_move_stop.create = function create(properties) {
            return new cmd_move_stop(properties);
        };

        /**
         * Encodes the specified cmd_move_stop message. Does not implicitly {@link battle.cmd_move_stop.verify|verify} messages.
         * @function encode
         * @memberof battle.cmd_move_stop
         * @static
         * @param {battle.cmd_move_stop} message cmd_move_stop message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        cmd_move_stop.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.eid);
            if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.x);
            if (message.y != null && Object.hasOwnProperty.call(message, "y"))
                writer.uint32(/* id 3, wireType 5 =*/29).float(message.y);
            return writer;
        };

        /**
         * Decodes a cmd_move_stop message from the specified reader or buffer.
         * @function decode
         * @memberof battle.cmd_move_stop
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.cmd_move_stop} cmd_move_stop
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        cmd_move_stop.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.cmd_move_stop();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.eid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.x = reader.float();
                        break;
                    }
                case 3: {
                        message.y = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for cmd_move_stop
         * @function getTypeUrl
         * @memberof battle.cmd_move_stop
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        cmd_move_stop.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.cmd_move_stop";
        };

        return cmd_move_stop;
    })();

    battle.cmd_add_buff = (function() {

        /**
         * Properties of a cmd_add_buff.
         * @memberof battle
         * @interface Icmd_add_buff
         * @property {number|null} [eid] cmd_add_buff eid
         * @property {number|null} [id] cmd_add_buff id
         * @property {number|null} [stack] cmd_add_buff stack
         * @property {number|null} [expired] cmd_add_buff expired
         */

        /**
         * Constructs a new cmd_add_buff.
         * @memberof battle
         * @classdesc Represents a cmd_add_buff.
         * @implements Icmd_add_buff
         * @constructor
         * @param {battle.Icmd_add_buff=} [properties] Properties to set
         */
        function cmd_add_buff(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * cmd_add_buff eid.
         * @member {number} eid
         * @memberof battle.cmd_add_buff
         * @instance
         */
        cmd_add_buff.prototype.eid = 0;

        /**
         * cmd_add_buff id.
         * @member {number} id
         * @memberof battle.cmd_add_buff
         * @instance
         */
        cmd_add_buff.prototype.id = 0;

        /**
         * cmd_add_buff stack.
         * @member {number} stack
         * @memberof battle.cmd_add_buff
         * @instance
         */
        cmd_add_buff.prototype.stack = 0;

        /**
         * cmd_add_buff expired.
         * @member {number} expired
         * @memberof battle.cmd_add_buff
         * @instance
         */
        cmd_add_buff.prototype.expired = 0;

        /**
         * Creates a new cmd_add_buff instance using the specified properties.
         * @function create
         * @memberof battle.cmd_add_buff
         * @static
         * @param {battle.Icmd_add_buff=} [properties] Properties to set
         * @returns {battle.cmd_add_buff} cmd_add_buff instance
         */
        cmd_add_buff.create = function create(properties) {
            return new cmd_add_buff(properties);
        };

        /**
         * Encodes the specified cmd_add_buff message. Does not implicitly {@link battle.cmd_add_buff.verify|verify} messages.
         * @function encode
         * @memberof battle.cmd_add_buff
         * @static
         * @param {battle.cmd_add_buff} message cmd_add_buff message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        cmd_add_buff.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.eid);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.id);
            if (message.stack != null && Object.hasOwnProperty.call(message, "stack"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.stack);
            if (message.expired != null && Object.hasOwnProperty.call(message, "expired"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.expired);
            return writer;
        };

        /**
         * Decodes a cmd_add_buff message from the specified reader or buffer.
         * @function decode
         * @memberof battle.cmd_add_buff
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.cmd_add_buff} cmd_add_buff
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        cmd_add_buff.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.cmd_add_buff();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.eid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.id = reader.uint32();
                        break;
                    }
                case 3: {
                        message.stack = reader.uint32();
                        break;
                    }
                case 4: {
                        message.expired = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for cmd_add_buff
         * @function getTypeUrl
         * @memberof battle.cmd_add_buff
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        cmd_add_buff.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.cmd_add_buff";
        };

        return cmd_add_buff;
    })();

    battle.cmd_del_buff = (function() {

        /**
         * Properties of a cmd_del_buff.
         * @memberof battle
         * @interface Icmd_del_buff
         * @property {number|null} [eid] cmd_del_buff eid
         * @property {number|null} [id] cmd_del_buff id
         */

        /**
         * Constructs a new cmd_del_buff.
         * @memberof battle
         * @classdesc Represents a cmd_del_buff.
         * @implements Icmd_del_buff
         * @constructor
         * @param {battle.Icmd_del_buff=} [properties] Properties to set
         */
        function cmd_del_buff(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * cmd_del_buff eid.
         * @member {number} eid
         * @memberof battle.cmd_del_buff
         * @instance
         */
        cmd_del_buff.prototype.eid = 0;

        /**
         * cmd_del_buff id.
         * @member {number} id
         * @memberof battle.cmd_del_buff
         * @instance
         */
        cmd_del_buff.prototype.id = 0;

        /**
         * Creates a new cmd_del_buff instance using the specified properties.
         * @function create
         * @memberof battle.cmd_del_buff
         * @static
         * @param {battle.Icmd_del_buff=} [properties] Properties to set
         * @returns {battle.cmd_del_buff} cmd_del_buff instance
         */
        cmd_del_buff.create = function create(properties) {
            return new cmd_del_buff(properties);
        };

        /**
         * Encodes the specified cmd_del_buff message. Does not implicitly {@link battle.cmd_del_buff.verify|verify} messages.
         * @function encode
         * @memberof battle.cmd_del_buff
         * @static
         * @param {battle.cmd_del_buff} message cmd_del_buff message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        cmd_del_buff.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.eid);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.id);
            return writer;
        };

        /**
         * Decodes a cmd_del_buff message from the specified reader or buffer.
         * @function decode
         * @memberof battle.cmd_del_buff
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.cmd_del_buff} cmd_del_buff
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        cmd_del_buff.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.cmd_del_buff();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.eid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.id = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for cmd_del_buff
         * @function getTypeUrl
         * @memberof battle.cmd_del_buff
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        cmd_del_buff.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.cmd_del_buff";
        };

        return cmd_del_buff;
    })();

    battle.cmd_attack = (function() {

        /**
         * Properties of a cmd_attack.
         * @memberof battle
         * @interface Icmd_attack
         * @property {number|null} [eid] cmd_attack eid
         * @property {number|null} [skillId] cmd_attack skillId
         * @property {number|null} [curMp] cmd_attack curMp
         * @property {number|null} [targetEid] cmd_attack targetEid
         */

        /**
         * Constructs a new cmd_attack.
         * @memberof battle
         * @classdesc Represents a cmd_attack.
         * @implements Icmd_attack
         * @constructor
         * @param {battle.Icmd_attack=} [properties] Properties to set
         */
        function cmd_attack(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * cmd_attack eid.
         * @member {number} eid
         * @memberof battle.cmd_attack
         * @instance
         */
        cmd_attack.prototype.eid = 0;

        /**
         * cmd_attack skillId.
         * @member {number} skillId
         * @memberof battle.cmd_attack
         * @instance
         */
        cmd_attack.prototype.skillId = 0;

        /**
         * cmd_attack curMp.
         * @member {number} curMp
         * @memberof battle.cmd_attack
         * @instance
         */
        cmd_attack.prototype.curMp = 0;

        /**
         * cmd_attack targetEid.
         * @member {number} targetEid
         * @memberof battle.cmd_attack
         * @instance
         */
        cmd_attack.prototype.targetEid = 0;

        /**
         * Creates a new cmd_attack instance using the specified properties.
         * @function create
         * @memberof battle.cmd_attack
         * @static
         * @param {battle.Icmd_attack=} [properties] Properties to set
         * @returns {battle.cmd_attack} cmd_attack instance
         */
        cmd_attack.create = function create(properties) {
            return new cmd_attack(properties);
        };

        /**
         * Encodes the specified cmd_attack message. Does not implicitly {@link battle.cmd_attack.verify|verify} messages.
         * @function encode
         * @memberof battle.cmd_attack
         * @static
         * @param {battle.cmd_attack} message cmd_attack message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        cmd_attack.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.eid);
            if (message.skillId != null && Object.hasOwnProperty.call(message, "skillId"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.skillId);
            if (message.curMp != null && Object.hasOwnProperty.call(message, "curMp"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.curMp);
            if (message.targetEid != null && Object.hasOwnProperty.call(message, "targetEid"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.targetEid);
            return writer;
        };

        /**
         * Decodes a cmd_attack message from the specified reader or buffer.
         * @function decode
         * @memberof battle.cmd_attack
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.cmd_attack} cmd_attack
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        cmd_attack.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.cmd_attack();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.eid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.skillId = reader.uint32();
                        break;
                    }
                case 3: {
                        message.curMp = reader.uint32();
                        break;
                    }
                case 4: {
                        message.targetEid = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for cmd_attack
         * @function getTypeUrl
         * @memberof battle.cmd_attack
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        cmd_attack.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.cmd_attack";
        };

        return cmd_attack;
    })();

    battle.cmd_under_atk = (function() {

        /**
         * Properties of a cmd_under_atk.
         * @memberof battle
         * @interface Icmd_under_atk
         * @property {number|null} [eid] cmd_under_atk eid
         * @property {number|null} [skillId] cmd_under_atk skillId
         * @property {number|null} [subHp] cmd_under_atk subHp
         * @property {number|null} [curMp] cmd_under_atk curMp
         * @property {boolean|null} [critical] cmd_under_atk critical
         * @property {boolean|null} [dodge] cmd_under_atk dodge
         * @property {boolean|null} [sputtering] cmd_under_atk sputtering
         * @property {boolean|null} [pursuit] cmd_under_atk pursuit
         * @property {boolean|null} [penetrate] cmd_under_atk penetrate
         */

        /**
         * Constructs a new cmd_under_atk.
         * @memberof battle
         * @classdesc Represents a cmd_under_atk.
         * @implements Icmd_under_atk
         * @constructor
         * @param {battle.Icmd_under_atk=} [properties] Properties to set
         */
        function cmd_under_atk(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * cmd_under_atk eid.
         * @member {number} eid
         * @memberof battle.cmd_under_atk
         * @instance
         */
        cmd_under_atk.prototype.eid = 0;

        /**
         * cmd_under_atk skillId.
         * @member {number} skillId
         * @memberof battle.cmd_under_atk
         * @instance
         */
        cmd_under_atk.prototype.skillId = 0;

        /**
         * cmd_under_atk subHp.
         * @member {number} subHp
         * @memberof battle.cmd_under_atk
         * @instance
         */
        cmd_under_atk.prototype.subHp = 0;

        /**
         * cmd_under_atk curMp.
         * @member {number} curMp
         * @memberof battle.cmd_under_atk
         * @instance
         */
        cmd_under_atk.prototype.curMp = 0;

        /**
         * cmd_under_atk critical.
         * @member {boolean} critical
         * @memberof battle.cmd_under_atk
         * @instance
         */
        cmd_under_atk.prototype.critical = false;

        /**
         * cmd_under_atk dodge.
         * @member {boolean} dodge
         * @memberof battle.cmd_under_atk
         * @instance
         */
        cmd_under_atk.prototype.dodge = false;

        /**
         * cmd_under_atk sputtering.
         * @member {boolean} sputtering
         * @memberof battle.cmd_under_atk
         * @instance
         */
        cmd_under_atk.prototype.sputtering = false;

        /**
         * cmd_under_atk pursuit.
         * @member {boolean} pursuit
         * @memberof battle.cmd_under_atk
         * @instance
         */
        cmd_under_atk.prototype.pursuit = false;

        /**
         * cmd_under_atk penetrate.
         * @member {boolean} penetrate
         * @memberof battle.cmd_under_atk
         * @instance
         */
        cmd_under_atk.prototype.penetrate = false;

        /**
         * Creates a new cmd_under_atk instance using the specified properties.
         * @function create
         * @memberof battle.cmd_under_atk
         * @static
         * @param {battle.Icmd_under_atk=} [properties] Properties to set
         * @returns {battle.cmd_under_atk} cmd_under_atk instance
         */
        cmd_under_atk.create = function create(properties) {
            return new cmd_under_atk(properties);
        };

        /**
         * Encodes the specified cmd_under_atk message. Does not implicitly {@link battle.cmd_under_atk.verify|verify} messages.
         * @function encode
         * @memberof battle.cmd_under_atk
         * @static
         * @param {battle.cmd_under_atk} message cmd_under_atk message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        cmd_under_atk.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.eid);
            if (message.skillId != null && Object.hasOwnProperty.call(message, "skillId"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.skillId);
            if (message.subHp != null && Object.hasOwnProperty.call(message, "subHp"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.subHp);
            if (message.curMp != null && Object.hasOwnProperty.call(message, "curMp"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.curMp);
            if (message.critical != null && Object.hasOwnProperty.call(message, "critical"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.critical);
            if (message.dodge != null && Object.hasOwnProperty.call(message, "dodge"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.dodge);
            if (message.sputtering != null && Object.hasOwnProperty.call(message, "sputtering"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.sputtering);
            if (message.pursuit != null && Object.hasOwnProperty.call(message, "pursuit"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.pursuit);
            if (message.penetrate != null && Object.hasOwnProperty.call(message, "penetrate"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.penetrate);
            return writer;
        };

        /**
         * Decodes a cmd_under_atk message from the specified reader or buffer.
         * @function decode
         * @memberof battle.cmd_under_atk
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.cmd_under_atk} cmd_under_atk
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        cmd_under_atk.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.cmd_under_atk();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.eid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.skillId = reader.uint32();
                        break;
                    }
                case 3: {
                        message.subHp = reader.uint32();
                        break;
                    }
                case 4: {
                        message.curMp = reader.uint32();
                        break;
                    }
                case 5: {
                        message.critical = reader.bool();
                        break;
                    }
                case 6: {
                        message.dodge = reader.bool();
                        break;
                    }
                case 7: {
                        message.sputtering = reader.bool();
                        break;
                    }
                case 8: {
                        message.pursuit = reader.bool();
                        break;
                    }
                case 9: {
                        message.penetrate = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for cmd_under_atk
         * @function getTypeUrl
         * @memberof battle.cmd_under_atk
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        cmd_under_atk.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.cmd_under_atk";
        };

        return cmd_under_atk;
    })();

    battle.cmd_recover_hp = (function() {

        /**
         * Properties of a cmd_recover_hp.
         * @memberof battle
         * @interface Icmd_recover_hp
         * @property {number|null} [eid] cmd_recover_hp eid
         * @property {number|null} [addHp] cmd_recover_hp addHp
         */

        /**
         * Constructs a new cmd_recover_hp.
         * @memberof battle
         * @classdesc Represents a cmd_recover_hp.
         * @implements Icmd_recover_hp
         * @constructor
         * @param {battle.Icmd_recover_hp=} [properties] Properties to set
         */
        function cmd_recover_hp(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * cmd_recover_hp eid.
         * @member {number} eid
         * @memberof battle.cmd_recover_hp
         * @instance
         */
        cmd_recover_hp.prototype.eid = 0;

        /**
         * cmd_recover_hp addHp.
         * @member {number} addHp
         * @memberof battle.cmd_recover_hp
         * @instance
         */
        cmd_recover_hp.prototype.addHp = 0;

        /**
         * Creates a new cmd_recover_hp instance using the specified properties.
         * @function create
         * @memberof battle.cmd_recover_hp
         * @static
         * @param {battle.Icmd_recover_hp=} [properties] Properties to set
         * @returns {battle.cmd_recover_hp} cmd_recover_hp instance
         */
        cmd_recover_hp.create = function create(properties) {
            return new cmd_recover_hp(properties);
        };

        /**
         * Encodes the specified cmd_recover_hp message. Does not implicitly {@link battle.cmd_recover_hp.verify|verify} messages.
         * @function encode
         * @memberof battle.cmd_recover_hp
         * @static
         * @param {battle.cmd_recover_hp} message cmd_recover_hp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        cmd_recover_hp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.eid);
            if (message.addHp != null && Object.hasOwnProperty.call(message, "addHp"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.addHp);
            return writer;
        };

        /**
         * Decodes a cmd_recover_hp message from the specified reader or buffer.
         * @function decode
         * @memberof battle.cmd_recover_hp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.cmd_recover_hp} cmd_recover_hp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        cmd_recover_hp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.cmd_recover_hp();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.eid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.addHp = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for cmd_recover_hp
         * @function getTypeUrl
         * @memberof battle.cmd_recover_hp
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        cmd_recover_hp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.cmd_recover_hp";
        };

        return cmd_recover_hp;
    })();

    battle.cmd_create_vfx = (function() {

        /**
         * Properties of a cmd_create_vfx.
         * @memberof battle
         * @interface Icmd_create_vfx
         * @property {number|null} [eid] cmd_create_vfx eid
         * @property {number|null} [entityId] cmd_create_vfx entityId
         * @property {number|null} [delayDelete] cmd_create_vfx delayDelete
         * @property {boolean|null} [attchTarget] cmd_create_vfx attchTarget
         * @property {number|null} [srcX] cmd_create_vfx srcX
         * @property {number|null} [srcY] cmd_create_vfx srcY
         * @property {number|null} [srcZ] cmd_create_vfx srcZ
         */

        /**
         * Constructs a new cmd_create_vfx.
         * @memberof battle
         * @classdesc Represents a cmd_create_vfx.
         * @implements Icmd_create_vfx
         * @constructor
         * @param {battle.Icmd_create_vfx=} [properties] Properties to set
         */
        function cmd_create_vfx(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * cmd_create_vfx eid.
         * @member {number} eid
         * @memberof battle.cmd_create_vfx
         * @instance
         */
        cmd_create_vfx.prototype.eid = 0;

        /**
         * cmd_create_vfx entityId.
         * @member {number} entityId
         * @memberof battle.cmd_create_vfx
         * @instance
         */
        cmd_create_vfx.prototype.entityId = 0;

        /**
         * cmd_create_vfx delayDelete.
         * @member {number} delayDelete
         * @memberof battle.cmd_create_vfx
         * @instance
         */
        cmd_create_vfx.prototype.delayDelete = 0;

        /**
         * cmd_create_vfx attchTarget.
         * @member {boolean} attchTarget
         * @memberof battle.cmd_create_vfx
         * @instance
         */
        cmd_create_vfx.prototype.attchTarget = false;

        /**
         * cmd_create_vfx srcX.
         * @member {number} srcX
         * @memberof battle.cmd_create_vfx
         * @instance
         */
        cmd_create_vfx.prototype.srcX = 0;

        /**
         * cmd_create_vfx srcY.
         * @member {number} srcY
         * @memberof battle.cmd_create_vfx
         * @instance
         */
        cmd_create_vfx.prototype.srcY = 0;

        /**
         * cmd_create_vfx srcZ.
         * @member {number} srcZ
         * @memberof battle.cmd_create_vfx
         * @instance
         */
        cmd_create_vfx.prototype.srcZ = 0;

        /**
         * Creates a new cmd_create_vfx instance using the specified properties.
         * @function create
         * @memberof battle.cmd_create_vfx
         * @static
         * @param {battle.Icmd_create_vfx=} [properties] Properties to set
         * @returns {battle.cmd_create_vfx} cmd_create_vfx instance
         */
        cmd_create_vfx.create = function create(properties) {
            return new cmd_create_vfx(properties);
        };

        /**
         * Encodes the specified cmd_create_vfx message. Does not implicitly {@link battle.cmd_create_vfx.verify|verify} messages.
         * @function encode
         * @memberof battle.cmd_create_vfx
         * @static
         * @param {battle.cmd_create_vfx} message cmd_create_vfx message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        cmd_create_vfx.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.eid);
            if (message.entityId != null && Object.hasOwnProperty.call(message, "entityId"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.entityId);
            if (message.delayDelete != null && Object.hasOwnProperty.call(message, "delayDelete"))
                writer.uint32(/* id 3, wireType 5 =*/29).float(message.delayDelete);
            if (message.attchTarget != null && Object.hasOwnProperty.call(message, "attchTarget"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.attchTarget);
            if (message.srcX != null && Object.hasOwnProperty.call(message, "srcX"))
                writer.uint32(/* id 5, wireType 5 =*/45).float(message.srcX);
            if (message.srcY != null && Object.hasOwnProperty.call(message, "srcY"))
                writer.uint32(/* id 6, wireType 5 =*/53).float(message.srcY);
            if (message.srcZ != null && Object.hasOwnProperty.call(message, "srcZ"))
                writer.uint32(/* id 7, wireType 5 =*/61).float(message.srcZ);
            return writer;
        };

        /**
         * Decodes a cmd_create_vfx message from the specified reader or buffer.
         * @function decode
         * @memberof battle.cmd_create_vfx
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.cmd_create_vfx} cmd_create_vfx
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        cmd_create_vfx.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.cmd_create_vfx();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.eid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.entityId = reader.uint32();
                        break;
                    }
                case 3: {
                        message.delayDelete = reader.float();
                        break;
                    }
                case 4: {
                        message.attchTarget = reader.bool();
                        break;
                    }
                case 5: {
                        message.srcX = reader.float();
                        break;
                    }
                case 6: {
                        message.srcY = reader.float();
                        break;
                    }
                case 7: {
                        message.srcZ = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for cmd_create_vfx
         * @function getTypeUrl
         * @memberof battle.cmd_create_vfx
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        cmd_create_vfx.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.cmd_create_vfx";
        };

        return cmd_create_vfx;
    })();

    battle.cmd_remove_vfx = (function() {

        /**
         * Properties of a cmd_remove_vfx.
         * @memberof battle
         * @interface Icmd_remove_vfx
         * @property {number|null} [eid] cmd_remove_vfx eid
         * @property {number|null} [entityId] cmd_remove_vfx entityId
         */

        /**
         * Constructs a new cmd_remove_vfx.
         * @memberof battle
         * @classdesc Represents a cmd_remove_vfx.
         * @implements Icmd_remove_vfx
         * @constructor
         * @param {battle.Icmd_remove_vfx=} [properties] Properties to set
         */
        function cmd_remove_vfx(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * cmd_remove_vfx eid.
         * @member {number} eid
         * @memberof battle.cmd_remove_vfx
         * @instance
         */
        cmd_remove_vfx.prototype.eid = 0;

        /**
         * cmd_remove_vfx entityId.
         * @member {number} entityId
         * @memberof battle.cmd_remove_vfx
         * @instance
         */
        cmd_remove_vfx.prototype.entityId = 0;

        /**
         * Creates a new cmd_remove_vfx instance using the specified properties.
         * @function create
         * @memberof battle.cmd_remove_vfx
         * @static
         * @param {battle.Icmd_remove_vfx=} [properties] Properties to set
         * @returns {battle.cmd_remove_vfx} cmd_remove_vfx instance
         */
        cmd_remove_vfx.create = function create(properties) {
            return new cmd_remove_vfx(properties);
        };

        /**
         * Encodes the specified cmd_remove_vfx message. Does not implicitly {@link battle.cmd_remove_vfx.verify|verify} messages.
         * @function encode
         * @memberof battle.cmd_remove_vfx
         * @static
         * @param {battle.cmd_remove_vfx} message cmd_remove_vfx message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        cmd_remove_vfx.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.eid);
            if (message.entityId != null && Object.hasOwnProperty.call(message, "entityId"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.entityId);
            return writer;
        };

        /**
         * Decodes a cmd_remove_vfx message from the specified reader or buffer.
         * @function decode
         * @memberof battle.cmd_remove_vfx
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.cmd_remove_vfx} cmd_remove_vfx
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        cmd_remove_vfx.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.cmd_remove_vfx();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.eid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.entityId = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for cmd_remove_vfx
         * @function getTypeUrl
         * @memberof battle.cmd_remove_vfx
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        cmd_remove_vfx.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.cmd_remove_vfx";
        };

        return cmd_remove_vfx;
    })();

    battle.cmd_play_anim = (function() {

        /**
         * Properties of a cmd_play_anim.
         * @memberof battle
         * @interface Icmd_play_anim
         * @property {number|null} [eid] cmd_play_anim eid
         * @property {string|null} [name] cmd_play_anim name
         */

        /**
         * Constructs a new cmd_play_anim.
         * @memberof battle
         * @classdesc Represents a cmd_play_anim.
         * @implements Icmd_play_anim
         * @constructor
         * @param {battle.Icmd_play_anim=} [properties] Properties to set
         */
        function cmd_play_anim(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * cmd_play_anim eid.
         * @member {number} eid
         * @memberof battle.cmd_play_anim
         * @instance
         */
        cmd_play_anim.prototype.eid = 0;

        /**
         * cmd_play_anim name.
         * @member {string} name
         * @memberof battle.cmd_play_anim
         * @instance
         */
        cmd_play_anim.prototype.name = "";

        /**
         * Creates a new cmd_play_anim instance using the specified properties.
         * @function create
         * @memberof battle.cmd_play_anim
         * @static
         * @param {battle.Icmd_play_anim=} [properties] Properties to set
         * @returns {battle.cmd_play_anim} cmd_play_anim instance
         */
        cmd_play_anim.create = function create(properties) {
            return new cmd_play_anim(properties);
        };

        /**
         * Encodes the specified cmd_play_anim message. Does not implicitly {@link battle.cmd_play_anim.verify|verify} messages.
         * @function encode
         * @memberof battle.cmd_play_anim
         * @static
         * @param {battle.cmd_play_anim} message cmd_play_anim message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        cmd_play_anim.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.eid);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Decodes a cmd_play_anim message from the specified reader or buffer.
         * @function decode
         * @memberof battle.cmd_play_anim
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.cmd_play_anim} cmd_play_anim
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        cmd_play_anim.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.cmd_play_anim();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.eid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for cmd_play_anim
         * @function getTypeUrl
         * @memberof battle.cmd_play_anim
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        cmd_play_anim.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.cmd_play_anim";
        };

        return cmd_play_anim;
    })();

    battle.BattleCmd = (function() {

        /**
         * Properties of a BattleCmd.
         * @memberof battle
         * @interface IBattleCmd
         * @property {number|null} [cmdType] BattleCmd cmdType
         * @property {battle.cmd_add_entity|null} [addEntity] BattleCmd addEntity
         * @property {battle.cmd_update_entity|null} [updateEntity] BattleCmd updateEntity
         * @property {battle.cmd_del_entity|null} [delEntity] BattleCmd delEntity
         * @property {battle.cmd_add_buff|null} [addBuff] BattleCmd addBuff
         * @property {battle.cmd_del_buff|null} [delBuff] BattleCmd delBuff
         * @property {battle.cmd_attack|null} [attack] BattleCmd attack
         * @property {battle.cmd_under_atk|null} [underAtk] BattleCmd underAtk
         * @property {battle.cmd_force_to|null} [forceTo] BattleCmd forceTo
         * @property {battle.cmd_move_to|null} [moveTo] BattleCmd moveTo
         * @property {battle.cmd_move_stop|null} [moveStop] BattleCmd moveStop
         * @property {battle.cmd_recover_hp|null} [recoverHp] BattleCmd recoverHp
         * @property {battle.cmd_create_vfx|null} [createVfx] BattleCmd createVfx
         * @property {battle.cmd_remove_vfx|null} [removeVfx] BattleCmd removeVfx
         * @property {battle.cmd_play_anim|null} [playAnim] BattleCmd playAnim
         */

        /**
         * Constructs a new BattleCmd.
         * @memberof battle
         * @classdesc Represents a BattleCmd.
         * @implements IBattleCmd
         * @constructor
         * @param {battle.IBattleCmd=} [properties] Properties to set
         */
        function BattleCmd(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BattleCmd cmdType.
         * @member {number} cmdType
         * @memberof battle.BattleCmd
         * @instance
         */
        BattleCmd.prototype.cmdType = 0;

        /**
         * BattleCmd addEntity.
         * @member {battle.cmd_add_entity|null|undefined} addEntity
         * @memberof battle.BattleCmd
         * @instance
         */
        BattleCmd.prototype.addEntity = null;

        /**
         * BattleCmd updateEntity.
         * @member {battle.cmd_update_entity|null|undefined} updateEntity
         * @memberof battle.BattleCmd
         * @instance
         */
        BattleCmd.prototype.updateEntity = null;

        /**
         * BattleCmd delEntity.
         * @member {battle.cmd_del_entity|null|undefined} delEntity
         * @memberof battle.BattleCmd
         * @instance
         */
        BattleCmd.prototype.delEntity = null;

        /**
         * BattleCmd addBuff.
         * @member {battle.cmd_add_buff|null|undefined} addBuff
         * @memberof battle.BattleCmd
         * @instance
         */
        BattleCmd.prototype.addBuff = null;

        /**
         * BattleCmd delBuff.
         * @member {battle.cmd_del_buff|null|undefined} delBuff
         * @memberof battle.BattleCmd
         * @instance
         */
        BattleCmd.prototype.delBuff = null;

        /**
         * BattleCmd attack.
         * @member {battle.cmd_attack|null|undefined} attack
         * @memberof battle.BattleCmd
         * @instance
         */
        BattleCmd.prototype.attack = null;

        /**
         * BattleCmd underAtk.
         * @member {battle.cmd_under_atk|null|undefined} underAtk
         * @memberof battle.BattleCmd
         * @instance
         */
        BattleCmd.prototype.underAtk = null;

        /**
         * BattleCmd forceTo.
         * @member {battle.cmd_force_to|null|undefined} forceTo
         * @memberof battle.BattleCmd
         * @instance
         */
        BattleCmd.prototype.forceTo = null;

        /**
         * BattleCmd moveTo.
         * @member {battle.cmd_move_to|null|undefined} moveTo
         * @memberof battle.BattleCmd
         * @instance
         */
        BattleCmd.prototype.moveTo = null;

        /**
         * BattleCmd moveStop.
         * @member {battle.cmd_move_stop|null|undefined} moveStop
         * @memberof battle.BattleCmd
         * @instance
         */
        BattleCmd.prototype.moveStop = null;

        /**
         * BattleCmd recoverHp.
         * @member {battle.cmd_recover_hp|null|undefined} recoverHp
         * @memberof battle.BattleCmd
         * @instance
         */
        BattleCmd.prototype.recoverHp = null;

        /**
         * BattleCmd createVfx.
         * @member {battle.cmd_create_vfx|null|undefined} createVfx
         * @memberof battle.BattleCmd
         * @instance
         */
        BattleCmd.prototype.createVfx = null;

        /**
         * BattleCmd removeVfx.
         * @member {battle.cmd_remove_vfx|null|undefined} removeVfx
         * @memberof battle.BattleCmd
         * @instance
         */
        BattleCmd.prototype.removeVfx = null;

        /**
         * BattleCmd playAnim.
         * @member {battle.cmd_play_anim|null|undefined} playAnim
         * @memberof battle.BattleCmd
         * @instance
         */
        BattleCmd.prototype.playAnim = null;

        /**
         * Creates a new BattleCmd instance using the specified properties.
         * @function create
         * @memberof battle.BattleCmd
         * @static
         * @param {battle.IBattleCmd=} [properties] Properties to set
         * @returns {battle.BattleCmd} BattleCmd instance
         */
        BattleCmd.create = function create(properties) {
            return new BattleCmd(properties);
        };

        /**
         * Encodes the specified BattleCmd message. Does not implicitly {@link battle.BattleCmd.verify|verify} messages.
         * @function encode
         * @memberof battle.BattleCmd
         * @static
         * @param {battle.BattleCmd} message BattleCmd message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BattleCmd.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cmdType != null && Object.hasOwnProperty.call(message, "cmdType"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.cmdType);
            if (message.addEntity != null && Object.hasOwnProperty.call(message, "addEntity"))
                $root.battle.cmd_add_entity.encode(message.addEntity, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.updateEntity != null && Object.hasOwnProperty.call(message, "updateEntity"))
                $root.battle.cmd_update_entity.encode(message.updateEntity, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.delEntity != null && Object.hasOwnProperty.call(message, "delEntity"))
                $root.battle.cmd_del_entity.encode(message.delEntity, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.addBuff != null && Object.hasOwnProperty.call(message, "addBuff"))
                $root.battle.cmd_add_buff.encode(message.addBuff, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.delBuff != null && Object.hasOwnProperty.call(message, "delBuff"))
                $root.battle.cmd_del_buff.encode(message.delBuff, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.attack != null && Object.hasOwnProperty.call(message, "attack"))
                $root.battle.cmd_attack.encode(message.attack, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.underAtk != null && Object.hasOwnProperty.call(message, "underAtk"))
                $root.battle.cmd_under_atk.encode(message.underAtk, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.forceTo != null && Object.hasOwnProperty.call(message, "forceTo"))
                $root.battle.cmd_force_to.encode(message.forceTo, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.moveTo != null && Object.hasOwnProperty.call(message, "moveTo"))
                $root.battle.cmd_move_to.encode(message.moveTo, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.moveStop != null && Object.hasOwnProperty.call(message, "moveStop"))
                $root.battle.cmd_move_stop.encode(message.moveStop, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.recoverHp != null && Object.hasOwnProperty.call(message, "recoverHp"))
                $root.battle.cmd_recover_hp.encode(message.recoverHp, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.createVfx != null && Object.hasOwnProperty.call(message, "createVfx"))
                $root.battle.cmd_create_vfx.encode(message.createVfx, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.removeVfx != null && Object.hasOwnProperty.call(message, "removeVfx"))
                $root.battle.cmd_remove_vfx.encode(message.removeVfx, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.playAnim != null && Object.hasOwnProperty.call(message, "playAnim"))
                $root.battle.cmd_play_anim.encode(message.playAnim, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a BattleCmd message from the specified reader or buffer.
         * @function decode
         * @memberof battle.BattleCmd
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.BattleCmd} BattleCmd
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BattleCmd.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.BattleCmd();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.cmdType = reader.uint32();
                        break;
                    }
                case 2: {
                        message.addEntity = $root.battle.cmd_add_entity.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.updateEntity = $root.battle.cmd_update_entity.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.delEntity = $root.battle.cmd_del_entity.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.addBuff = $root.battle.cmd_add_buff.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.delBuff = $root.battle.cmd_del_buff.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.attack = $root.battle.cmd_attack.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        message.underAtk = $root.battle.cmd_under_atk.decode(reader, reader.uint32());
                        break;
                    }
                case 13: {
                        message.forceTo = $root.battle.cmd_force_to.decode(reader, reader.uint32());
                        break;
                    }
                case 14: {
                        message.moveTo = $root.battle.cmd_move_to.decode(reader, reader.uint32());
                        break;
                    }
                case 15: {
                        message.moveStop = $root.battle.cmd_move_stop.decode(reader, reader.uint32());
                        break;
                    }
                case 17: {
                        message.recoverHp = $root.battle.cmd_recover_hp.decode(reader, reader.uint32());
                        break;
                    }
                case 18: {
                        message.createVfx = $root.battle.cmd_create_vfx.decode(reader, reader.uint32());
                        break;
                    }
                case 19: {
                        message.removeVfx = $root.battle.cmd_remove_vfx.decode(reader, reader.uint32());
                        break;
                    }
                case 20: {
                        message.playAnim = $root.battle.cmd_play_anim.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for BattleCmd
         * @function getTypeUrl
         * @memberof battle.BattleCmd
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BattleCmd.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.BattleCmd";
        };

        return BattleCmd;
    })();

    battle.TroopFullInfo = (function() {

        /**
         * Properties of a TroopFullInfo.
         * @memberof battle
         * @interface ITroopFullInfo
         * @property {number|null} [worldEid] TroopFullInfo worldEid
         * @property {number|null} [rid] TroopFullInfo rid
         * @property {number|null} [aid] TroopFullInfo aid
         * @property {string|null} [name] TroopFullInfo name
         * @property {number|null} [side] TroopFullInfo side
         * @property {number|null} [army] TroopFullInfo army
         * @property {Object.<string,number>|null} [eids] TroopFullInfo eids
         * @property {Object.<string,number>|null} [entityIds] TroopFullInfo entityIds
         * @property {number|null} [curGrid] TroopFullInfo curGrid
         * @property {number|null} [dstGrid] TroopFullInfo dstGrid
         * @property {number|null} [state] TroopFullInfo state
         * @property {number|null} [stateMs] TroopFullInfo stateMs
         * @property {number|null} [battleUid] TroopFullInfo battleUid
         */

        /**
         * Constructs a new TroopFullInfo.
         * @memberof battle
         * @classdesc Represents a TroopFullInfo.
         * @implements ITroopFullInfo
         * @constructor
         * @param {battle.ITroopFullInfo=} [properties] Properties to set
         */
        function TroopFullInfo(properties) {
            this.eids = {};
            this.entityIds = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TroopFullInfo worldEid.
         * @member {number} worldEid
         * @memberof battle.TroopFullInfo
         * @instance
         */
        TroopFullInfo.prototype.worldEid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * TroopFullInfo rid.
         * @member {number} rid
         * @memberof battle.TroopFullInfo
         * @instance
         */
        TroopFullInfo.prototype.rid = 0;

        /**
         * TroopFullInfo aid.
         * @member {number} aid
         * @memberof battle.TroopFullInfo
         * @instance
         */
        TroopFullInfo.prototype.aid = 0;

        /**
         * TroopFullInfo name.
         * @member {string} name
         * @memberof battle.TroopFullInfo
         * @instance
         */
        TroopFullInfo.prototype.name = "";

        /**
         * TroopFullInfo side.
         * @member {number} side
         * @memberof battle.TroopFullInfo
         * @instance
         */
        TroopFullInfo.prototype.side = 0;

        /**
         * TroopFullInfo army.
         * @member {number} army
         * @memberof battle.TroopFullInfo
         * @instance
         */
        TroopFullInfo.prototype.army = 0;

        /**
         * TroopFullInfo eids.
         * @member {Object.<string,number>} eids
         * @memberof battle.TroopFullInfo
         * @instance
         */
        TroopFullInfo.prototype.eids = $util.emptyObject;

        /**
         * TroopFullInfo entityIds.
         * @member {Object.<string,number>} entityIds
         * @memberof battle.TroopFullInfo
         * @instance
         */
        TroopFullInfo.prototype.entityIds = $util.emptyObject;

        /**
         * TroopFullInfo curGrid.
         * @member {number} curGrid
         * @memberof battle.TroopFullInfo
         * @instance
         */
        TroopFullInfo.prototype.curGrid = 0;

        /**
         * TroopFullInfo dstGrid.
         * @member {number} dstGrid
         * @memberof battle.TroopFullInfo
         * @instance
         */
        TroopFullInfo.prototype.dstGrid = 0;

        /**
         * TroopFullInfo state.
         * @member {number} state
         * @memberof battle.TroopFullInfo
         * @instance
         */
        TroopFullInfo.prototype.state = 0;

        /**
         * TroopFullInfo stateMs.
         * @member {number} stateMs
         * @memberof battle.TroopFullInfo
         * @instance
         */
        TroopFullInfo.prototype.stateMs = 0;

        /**
         * TroopFullInfo battleUid.
         * @member {number} battleUid
         * @memberof battle.TroopFullInfo
         * @instance
         */
        TroopFullInfo.prototype.battleUid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new TroopFullInfo instance using the specified properties.
         * @function create
         * @memberof battle.TroopFullInfo
         * @static
         * @param {battle.ITroopFullInfo=} [properties] Properties to set
         * @returns {battle.TroopFullInfo} TroopFullInfo instance
         */
        TroopFullInfo.create = function create(properties) {
            return new TroopFullInfo(properties);
        };

        /**
         * Encodes the specified TroopFullInfo message. Does not implicitly {@link battle.TroopFullInfo.verify|verify} messages.
         * @function encode
         * @memberof battle.TroopFullInfo
         * @static
         * @param {battle.TroopFullInfo} message TroopFullInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TroopFullInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.worldEid != null && Object.hasOwnProperty.call(message, "worldEid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.worldEid);
            if (message.rid != null && Object.hasOwnProperty.call(message, "rid"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.rid);
            if (message.aid != null && Object.hasOwnProperty.call(message, "aid"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.aid);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
            if (message.side != null && Object.hasOwnProperty.call(message, "side"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.side);
            if (message.army != null && Object.hasOwnProperty.call(message, "army"))
                writer.uint32(/* id 10, wireType 0 =*/80).uint32(message.army);
            if (message.eids != null && Object.hasOwnProperty.call(message, "eids"))
                for (let keys = Object.keys(message.eids), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 11, wireType 2 =*/90).fork().uint32(/* id 1, wireType 0 =*/8).uint32(keys[i]).uint32(/* id 2, wireType 0 =*/16).uint32(message.eids[keys[i]]).ldelim();
            if (message.entityIds != null && Object.hasOwnProperty.call(message, "entityIds"))
                for (let keys = Object.keys(message.entityIds), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 12, wireType 2 =*/98).fork().uint32(/* id 1, wireType 0 =*/8).uint32(keys[i]).uint32(/* id 2, wireType 0 =*/16).uint32(message.entityIds[keys[i]]).ldelim();
            if (message.curGrid != null && Object.hasOwnProperty.call(message, "curGrid"))
                writer.uint32(/* id 13, wireType 0 =*/104).uint32(message.curGrid);
            if (message.dstGrid != null && Object.hasOwnProperty.call(message, "dstGrid"))
                writer.uint32(/* id 14, wireType 0 =*/112).uint32(message.dstGrid);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 15, wireType 0 =*/120).uint32(message.state);
            if (message.stateMs != null && Object.hasOwnProperty.call(message, "stateMs"))
                writer.uint32(/* id 16, wireType 0 =*/128).uint32(message.stateMs);
            if (message.battleUid != null && Object.hasOwnProperty.call(message, "battleUid"))
                writer.uint32(/* id 17, wireType 0 =*/136).uint64(message.battleUid);
            return writer;
        };

        /**
         * Decodes a TroopFullInfo message from the specified reader or buffer.
         * @function decode
         * @memberof battle.TroopFullInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.TroopFullInfo} TroopFullInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TroopFullInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.TroopFullInfo(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.worldEid = reader.uint64();
                        break;
                    }
                case 2: {
                        message.rid = reader.uint32();
                        break;
                    }
                case 3: {
                        message.aid = reader.uint32();
                        break;
                    }
                case 4: {
                        message.name = reader.string();
                        break;
                    }
                case 5: {
                        message.side = reader.int32();
                        break;
                    }
                case 10: {
                        message.army = reader.uint32();
                        break;
                    }
                case 11: {
                        if (message.eids === $util.emptyObject)
                            message.eids = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = 0;
                        value = 0;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.uint32();
                                break;
                            case 2:
                                value = reader.uint32();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.eids[key] = value;
                        break;
                    }
                case 12: {
                        if (message.entityIds === $util.emptyObject)
                            message.entityIds = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = 0;
                        value = 0;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.uint32();
                                break;
                            case 2:
                                value = reader.uint32();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.entityIds[key] = value;
                        break;
                    }
                case 13: {
                        message.curGrid = reader.uint32();
                        break;
                    }
                case 14: {
                        message.dstGrid = reader.uint32();
                        break;
                    }
                case 15: {
                        message.state = reader.uint32();
                        break;
                    }
                case 16: {
                        message.stateMs = reader.uint32();
                        break;
                    }
                case 17: {
                        message.battleUid = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for TroopFullInfo
         * @function getTypeUrl
         * @memberof battle.TroopFullInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TroopFullInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.TroopFullInfo";
        };

        return TroopFullInfo;
    })();

    battle.TroopNotifyInfo = (function() {

        /**
         * Properties of a TroopNotifyInfo.
         * @memberof battle
         * @interface ITroopNotifyInfo
         * @property {number|null} [worldEid] TroopNotifyInfo worldEid
         * @property {number|null} [army] TroopNotifyInfo army
         * @property {number|null} [curGrid] TroopNotifyInfo curGrid
         * @property {number|null} [dstGrid] TroopNotifyInfo dstGrid
         * @property {number|null} [state] TroopNotifyInfo state
         * @property {number|null} [stateMs] TroopNotifyInfo stateMs
         * @property {number|null} [battleUid] TroopNotifyInfo battleUid
         */

        /**
         * Constructs a new TroopNotifyInfo.
         * @memberof battle
         * @classdesc Represents a TroopNotifyInfo.
         * @implements ITroopNotifyInfo
         * @constructor
         * @param {battle.ITroopNotifyInfo=} [properties] Properties to set
         */
        function TroopNotifyInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TroopNotifyInfo worldEid.
         * @member {number} worldEid
         * @memberof battle.TroopNotifyInfo
         * @instance
         */
        TroopNotifyInfo.prototype.worldEid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * TroopNotifyInfo army.
         * @member {number} army
         * @memberof battle.TroopNotifyInfo
         * @instance
         */
        TroopNotifyInfo.prototype.army = 0;

        /**
         * TroopNotifyInfo curGrid.
         * @member {number} curGrid
         * @memberof battle.TroopNotifyInfo
         * @instance
         */
        TroopNotifyInfo.prototype.curGrid = 0;

        /**
         * TroopNotifyInfo dstGrid.
         * @member {number} dstGrid
         * @memberof battle.TroopNotifyInfo
         * @instance
         */
        TroopNotifyInfo.prototype.dstGrid = 0;

        /**
         * TroopNotifyInfo state.
         * @member {number} state
         * @memberof battle.TroopNotifyInfo
         * @instance
         */
        TroopNotifyInfo.prototype.state = 0;

        /**
         * TroopNotifyInfo stateMs.
         * @member {number} stateMs
         * @memberof battle.TroopNotifyInfo
         * @instance
         */
        TroopNotifyInfo.prototype.stateMs = 0;

        /**
         * TroopNotifyInfo battleUid.
         * @member {number} battleUid
         * @memberof battle.TroopNotifyInfo
         * @instance
         */
        TroopNotifyInfo.prototype.battleUid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new TroopNotifyInfo instance using the specified properties.
         * @function create
         * @memberof battle.TroopNotifyInfo
         * @static
         * @param {battle.ITroopNotifyInfo=} [properties] Properties to set
         * @returns {battle.TroopNotifyInfo} TroopNotifyInfo instance
         */
        TroopNotifyInfo.create = function create(properties) {
            return new TroopNotifyInfo(properties);
        };

        /**
         * Encodes the specified TroopNotifyInfo message. Does not implicitly {@link battle.TroopNotifyInfo.verify|verify} messages.
         * @function encode
         * @memberof battle.TroopNotifyInfo
         * @static
         * @param {battle.TroopNotifyInfo} message TroopNotifyInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TroopNotifyInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.worldEid != null && Object.hasOwnProperty.call(message, "worldEid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.worldEid);
            if (message.army != null && Object.hasOwnProperty.call(message, "army"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.army);
            if (message.curGrid != null && Object.hasOwnProperty.call(message, "curGrid"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.curGrid);
            if (message.dstGrid != null && Object.hasOwnProperty.call(message, "dstGrid"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.dstGrid);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.state);
            if (message.stateMs != null && Object.hasOwnProperty.call(message, "stateMs"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.stateMs);
            if (message.battleUid != null && Object.hasOwnProperty.call(message, "battleUid"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.battleUid);
            return writer;
        };

        /**
         * Decodes a TroopNotifyInfo message from the specified reader or buffer.
         * @function decode
         * @memberof battle.TroopNotifyInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.TroopNotifyInfo} TroopNotifyInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TroopNotifyInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.TroopNotifyInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.worldEid = reader.uint64();
                        break;
                    }
                case 2: {
                        message.army = reader.uint32();
                        break;
                    }
                case 3: {
                        message.curGrid = reader.uint32();
                        break;
                    }
                case 4: {
                        message.dstGrid = reader.uint32();
                        break;
                    }
                case 5: {
                        message.state = reader.uint32();
                        break;
                    }
                case 6: {
                        message.stateMs = reader.uint32();
                        break;
                    }
                case 7: {
                        message.battleUid = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for TroopNotifyInfo
         * @function getTypeUrl
         * @memberof battle.TroopNotifyInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TroopNotifyInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.TroopNotifyInfo";
        };

        return TroopNotifyInfo;
    })();

    battle.WallInfo = (function() {

        /**
         * Properties of a WallInfo.
         * @memberof battle
         * @interface IWallInfo
         * @property {number|null} [maxHp] WallInfo maxHp
         * @property {number|null} [curHp] WallInfo curHp
         * @property {number|null} [rid] WallInfo rid
         * @property {number|null} [aid] WallInfo aid
         */

        /**
         * Constructs a new WallInfo.
         * @memberof battle
         * @classdesc Represents a WallInfo.
         * @implements IWallInfo
         * @constructor
         * @param {battle.IWallInfo=} [properties] Properties to set
         */
        function WallInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WallInfo maxHp.
         * @member {number} maxHp
         * @memberof battle.WallInfo
         * @instance
         */
        WallInfo.prototype.maxHp = 0;

        /**
         * WallInfo curHp.
         * @member {number} curHp
         * @memberof battle.WallInfo
         * @instance
         */
        WallInfo.prototype.curHp = 0;

        /**
         * WallInfo rid.
         * @member {number} rid
         * @memberof battle.WallInfo
         * @instance
         */
        WallInfo.prototype.rid = 0;

        /**
         * WallInfo aid.
         * @member {number} aid
         * @memberof battle.WallInfo
         * @instance
         */
        WallInfo.prototype.aid = 0;

        /**
         * Creates a new WallInfo instance using the specified properties.
         * @function create
         * @memberof battle.WallInfo
         * @static
         * @param {battle.IWallInfo=} [properties] Properties to set
         * @returns {battle.WallInfo} WallInfo instance
         */
        WallInfo.create = function create(properties) {
            return new WallInfo(properties);
        };

        /**
         * Encodes the specified WallInfo message. Does not implicitly {@link battle.WallInfo.verify|verify} messages.
         * @function encode
         * @memberof battle.WallInfo
         * @static
         * @param {battle.WallInfo} message WallInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WallInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.maxHp != null && Object.hasOwnProperty.call(message, "maxHp"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.maxHp);
            if (message.curHp != null && Object.hasOwnProperty.call(message, "curHp"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.curHp);
            if (message.rid != null && Object.hasOwnProperty.call(message, "rid"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.rid);
            if (message.aid != null && Object.hasOwnProperty.call(message, "aid"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.aid);
            return writer;
        };

        /**
         * Decodes a WallInfo message from the specified reader or buffer.
         * @function decode
         * @memberof battle.WallInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.WallInfo} WallInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WallInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.WallInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.maxHp = reader.uint32();
                        break;
                    }
                case 2: {
                        message.curHp = reader.uint32();
                        break;
                    }
                case 3: {
                        message.rid = reader.uint32();
                        break;
                    }
                case 4: {
                        message.aid = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for WallInfo
         * @function getTypeUrl
         * @memberof battle.WallInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WallInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.WallInfo";
        };

        return WallInfo;
    })();

    battle.BattleScene = (function() {

        /**
         * Properties of a BattleScene.
         * @memberof battle
         * @interface IBattleScene
         * @property {number|null} [sceneUid] BattleScene sceneUid
         * @property {Array.<battle.TroopFullInfo>|null} [troops] BattleScene troops
         * @property {battle.WallInfo|null} [wall] BattleScene wall
         */

        /**
         * Constructs a new BattleScene.
         * @memberof battle
         * @classdesc Represents a BattleScene.
         * @implements IBattleScene
         * @constructor
         * @param {battle.IBattleScene=} [properties] Properties to set
         */
        function BattleScene(properties) {
            this.troops = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BattleScene sceneUid.
         * @member {number} sceneUid
         * @memberof battle.BattleScene
         * @instance
         */
        BattleScene.prototype.sceneUid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * BattleScene troops.
         * @member {Array.<battle.TroopFullInfo>} troops
         * @memberof battle.BattleScene
         * @instance
         */
        BattleScene.prototype.troops = $util.emptyArray;

        /**
         * BattleScene wall.
         * @member {battle.WallInfo|null|undefined} wall
         * @memberof battle.BattleScene
         * @instance
         */
        BattleScene.prototype.wall = null;

        /**
         * Creates a new BattleScene instance using the specified properties.
         * @function create
         * @memberof battle.BattleScene
         * @static
         * @param {battle.IBattleScene=} [properties] Properties to set
         * @returns {battle.BattleScene} BattleScene instance
         */
        BattleScene.create = function create(properties) {
            return new BattleScene(properties);
        };

        /**
         * Encodes the specified BattleScene message. Does not implicitly {@link battle.BattleScene.verify|verify} messages.
         * @function encode
         * @memberof battle.BattleScene
         * @static
         * @param {battle.BattleScene} message BattleScene message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BattleScene.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sceneUid != null && Object.hasOwnProperty.call(message, "sceneUid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.sceneUid);
            if (message.troops != null && message.troops.length)
                for (let i = 0; i < message.troops.length; ++i)
                    $root.battle.TroopFullInfo.encode(message.troops[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.wall != null && Object.hasOwnProperty.call(message, "wall"))
                $root.battle.WallInfo.encode(message.wall, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a BattleScene message from the specified reader or buffer.
         * @function decode
         * @memberof battle.BattleScene
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.BattleScene} BattleScene
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BattleScene.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.BattleScene();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sceneUid = reader.uint64();
                        break;
                    }
                case 2: {
                        if (!(message.troops && message.troops.length))
                            message.troops = [];
                        message.troops.push($root.battle.TroopFullInfo.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.wall = $root.battle.WallInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for BattleScene
         * @function getTypeUrl
         * @memberof battle.BattleScene
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BattleScene.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.BattleScene";
        };

        return BattleScene;
    })();

    battle.c2s_enter_scene = (function() {

        /**
         * Properties of a c2s_enter_scene.
         * @memberof battle
         * @interface Ic2s_enter_scene
         * @property {number|null} [sceneUid] c2s_enter_scene sceneUid
         */

        /**
         * Constructs a new c2s_enter_scene.
         * @memberof battle
         * @classdesc Represents a c2s_enter_scene.
         * @implements Ic2s_enter_scene
         * @constructor
         * @param {battle.Ic2s_enter_scene=} [properties] Properties to set
         */
        function c2s_enter_scene(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_enter_scene sceneUid.
         * @member {number} sceneUid
         * @memberof battle.c2s_enter_scene
         * @instance
         */
        c2s_enter_scene.prototype.sceneUid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new c2s_enter_scene instance using the specified properties.
         * @function create
         * @memberof battle.c2s_enter_scene
         * @static
         * @param {battle.Ic2s_enter_scene=} [properties] Properties to set
         * @returns {battle.c2s_enter_scene} c2s_enter_scene instance
         */
        c2s_enter_scene.create = function create(properties) {
            return new c2s_enter_scene(properties);
        };

        /**
         * Encodes the specified c2s_enter_scene message. Does not implicitly {@link battle.c2s_enter_scene.verify|verify} messages.
         * @function encode
         * @memberof battle.c2s_enter_scene
         * @static
         * @param {battle.c2s_enter_scene} message c2s_enter_scene message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_enter_scene.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sceneUid != null && Object.hasOwnProperty.call(message, "sceneUid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.sceneUid);
            return writer;
        };

        /**
         * Decodes a c2s_enter_scene message from the specified reader or buffer.
         * @function decode
         * @memberof battle.c2s_enter_scene
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.c2s_enter_scene} c2s_enter_scene
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_enter_scene.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.c2s_enter_scene();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sceneUid = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_enter_scene
         * @function getTypeUrl
         * @memberof battle.c2s_enter_scene
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_enter_scene.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.c2s_enter_scene";
        };

        return c2s_enter_scene;
    })();

    battle.s2c_enter_scene = (function() {

        /**
         * Properties of a s2c_enter_scene.
         * @memberof battle
         * @interface Is2c_enter_scene
         * @property {number|null} [err] s2c_enter_scene err
         * @property {Array.<battle.Battle>|null} [battles] s2c_enter_scene battles
         * @property {battle.BattleScene|null} [scene] s2c_enter_scene scene
         */

        /**
         * Constructs a new s2c_enter_scene.
         * @memberof battle
         * @classdesc Represents a s2c_enter_scene.
         * @implements Is2c_enter_scene
         * @constructor
         * @param {battle.Is2c_enter_scene=} [properties] Properties to set
         */
        function s2c_enter_scene(properties) {
            this.battles = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_enter_scene err.
         * @member {number} err
         * @memberof battle.s2c_enter_scene
         * @instance
         */
        s2c_enter_scene.prototype.err = 0;

        /**
         * s2c_enter_scene battles.
         * @member {Array.<battle.Battle>} battles
         * @memberof battle.s2c_enter_scene
         * @instance
         */
        s2c_enter_scene.prototype.battles = $util.emptyArray;

        /**
         * s2c_enter_scene scene.
         * @member {battle.BattleScene|null|undefined} scene
         * @memberof battle.s2c_enter_scene
         * @instance
         */
        s2c_enter_scene.prototype.scene = null;

        /**
         * Creates a new s2c_enter_scene instance using the specified properties.
         * @function create
         * @memberof battle.s2c_enter_scene
         * @static
         * @param {battle.Is2c_enter_scene=} [properties] Properties to set
         * @returns {battle.s2c_enter_scene} s2c_enter_scene instance
         */
        s2c_enter_scene.create = function create(properties) {
            return new s2c_enter_scene(properties);
        };

        /**
         * Encodes the specified s2c_enter_scene message. Does not implicitly {@link battle.s2c_enter_scene.verify|verify} messages.
         * @function encode
         * @memberof battle.s2c_enter_scene
         * @static
         * @param {battle.s2c_enter_scene} message s2c_enter_scene message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_enter_scene.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.battles != null && message.battles.length)
                for (let i = 0; i < message.battles.length; ++i)
                    $root.battle.Battle.encode(message.battles[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.scene != null && Object.hasOwnProperty.call(message, "scene"))
                $root.battle.BattleScene.encode(message.scene, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_enter_scene message from the specified reader or buffer.
         * @function decode
         * @memberof battle.s2c_enter_scene
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.s2c_enter_scene} s2c_enter_scene
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_enter_scene.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.s2c_enter_scene();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.battles && message.battles.length))
                            message.battles = [];
                        message.battles.push($root.battle.Battle.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.scene = $root.battle.BattleScene.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_enter_scene
         * @function getTypeUrl
         * @memberof battle.s2c_enter_scene
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_enter_scene.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.s2c_enter_scene";
        };

        return s2c_enter_scene;
    })();

    battle.Battle = (function() {

        /**
         * Properties of a Battle.
         * @memberof battle
         * @interface IBattle
         * @property {number|null} [battleUid] Battle battleUid
         * @property {number|null} [slotIdx] Battle slotIdx
         * @property {number|null} [battleId] Battle battleId
         * @property {Array.<battle.BattleRole>|null} [roles] Battle roles
         * @property {Array.<battle.BattleEntity>|null} [entities] Battle entities
         */

        /**
         * Constructs a new Battle.
         * @memberof battle
         * @classdesc Represents a Battle.
         * @implements IBattle
         * @constructor
         * @param {battle.IBattle=} [properties] Properties to set
         */
        function Battle(properties) {
            this.roles = [];
            this.entities = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Battle battleUid.
         * @member {number} battleUid
         * @memberof battle.Battle
         * @instance
         */
        Battle.prototype.battleUid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Battle slotIdx.
         * @member {number} slotIdx
         * @memberof battle.Battle
         * @instance
         */
        Battle.prototype.slotIdx = 0;

        /**
         * Battle battleId.
         * @member {number} battleId
         * @memberof battle.Battle
         * @instance
         */
        Battle.prototype.battleId = 0;

        /**
         * Battle roles.
         * @member {Array.<battle.BattleRole>} roles
         * @memberof battle.Battle
         * @instance
         */
        Battle.prototype.roles = $util.emptyArray;

        /**
         * Battle entities.
         * @member {Array.<battle.BattleEntity>} entities
         * @memberof battle.Battle
         * @instance
         */
        Battle.prototype.entities = $util.emptyArray;

        /**
         * Creates a new Battle instance using the specified properties.
         * @function create
         * @memberof battle.Battle
         * @static
         * @param {battle.IBattle=} [properties] Properties to set
         * @returns {battle.Battle} Battle instance
         */
        Battle.create = function create(properties) {
            return new Battle(properties);
        };

        /**
         * Encodes the specified Battle message. Does not implicitly {@link battle.Battle.verify|verify} messages.
         * @function encode
         * @memberof battle.Battle
         * @static
         * @param {battle.Battle} message Battle message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Battle.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.battleUid != null && Object.hasOwnProperty.call(message, "battleUid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.battleUid);
            if (message.slotIdx != null && Object.hasOwnProperty.call(message, "slotIdx"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.slotIdx);
            if (message.battleId != null && Object.hasOwnProperty.call(message, "battleId"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.battleId);
            if (message.roles != null && message.roles.length)
                for (let i = 0; i < message.roles.length; ++i)
                    $root.battle.BattleRole.encode(message.roles[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.entities != null && message.entities.length)
                for (let i = 0; i < message.entities.length; ++i)
                    $root.battle.BattleEntity.encode(message.entities[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Battle message from the specified reader or buffer.
         * @function decode
         * @memberof battle.Battle
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.Battle} Battle
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Battle.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.Battle();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.battleUid = reader.uint64();
                        break;
                    }
                case 2: {
                        message.slotIdx = reader.uint32();
                        break;
                    }
                case 3: {
                        message.battleId = reader.uint32();
                        break;
                    }
                case 4: {
                        if (!(message.roles && message.roles.length))
                            message.roles = [];
                        message.roles.push($root.battle.BattleRole.decode(reader, reader.uint32()));
                        break;
                    }
                case 5: {
                        if (!(message.entities && message.entities.length))
                            message.entities = [];
                        message.entities.push($root.battle.BattleEntity.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for Battle
         * @function getTypeUrl
         * @memberof battle.Battle
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Battle.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.Battle";
        };

        return Battle;
    })();

    battle.c2s_enter_slot = (function() {

        /**
         * Properties of a c2s_enter_slot.
         * @memberof battle
         * @interface Ic2s_enter_slot
         * @property {number|null} [sceneUid] c2s_enter_slot sceneUid
         * @property {number|null} [slotIdx] c2s_enter_slot slotIdx
         */

        /**
         * Constructs a new c2s_enter_slot.
         * @memberof battle
         * @classdesc Represents a c2s_enter_slot.
         * @implements Ic2s_enter_slot
         * @constructor
         * @param {battle.Ic2s_enter_slot=} [properties] Properties to set
         */
        function c2s_enter_slot(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_enter_slot sceneUid.
         * @member {number} sceneUid
         * @memberof battle.c2s_enter_slot
         * @instance
         */
        c2s_enter_slot.prototype.sceneUid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * c2s_enter_slot slotIdx.
         * @member {number} slotIdx
         * @memberof battle.c2s_enter_slot
         * @instance
         */
        c2s_enter_slot.prototype.slotIdx = 0;

        /**
         * Creates a new c2s_enter_slot instance using the specified properties.
         * @function create
         * @memberof battle.c2s_enter_slot
         * @static
         * @param {battle.Ic2s_enter_slot=} [properties] Properties to set
         * @returns {battle.c2s_enter_slot} c2s_enter_slot instance
         */
        c2s_enter_slot.create = function create(properties) {
            return new c2s_enter_slot(properties);
        };

        /**
         * Encodes the specified c2s_enter_slot message. Does not implicitly {@link battle.c2s_enter_slot.verify|verify} messages.
         * @function encode
         * @memberof battle.c2s_enter_slot
         * @static
         * @param {battle.c2s_enter_slot} message c2s_enter_slot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_enter_slot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sceneUid != null && Object.hasOwnProperty.call(message, "sceneUid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.sceneUid);
            if (message.slotIdx != null && Object.hasOwnProperty.call(message, "slotIdx"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.slotIdx);
            return writer;
        };

        /**
         * Decodes a c2s_enter_slot message from the specified reader or buffer.
         * @function decode
         * @memberof battle.c2s_enter_slot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.c2s_enter_slot} c2s_enter_slot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_enter_slot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.c2s_enter_slot();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sceneUid = reader.uint64();
                        break;
                    }
                case 2: {
                        message.slotIdx = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_enter_slot
         * @function getTypeUrl
         * @memberof battle.c2s_enter_slot
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_enter_slot.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.c2s_enter_slot";
        };

        return c2s_enter_slot;
    })();

    battle.s2c_enter_slot = (function() {

        /**
         * Properties of a s2c_enter_slot.
         * @memberof battle
         * @interface Is2c_enter_slot
         * @property {number|null} [err] s2c_enter_slot err
         * @property {battle.Battle|null} [battle] s2c_enter_slot battle
         */

        /**
         * Constructs a new s2c_enter_slot.
         * @memberof battle
         * @classdesc Represents a s2c_enter_slot.
         * @implements Is2c_enter_slot
         * @constructor
         * @param {battle.Is2c_enter_slot=} [properties] Properties to set
         */
        function s2c_enter_slot(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_enter_slot err.
         * @member {number} err
         * @memberof battle.s2c_enter_slot
         * @instance
         */
        s2c_enter_slot.prototype.err = 0;

        /**
         * s2c_enter_slot battle.
         * @member {battle.Battle|null|undefined} battle
         * @memberof battle.s2c_enter_slot
         * @instance
         */
        s2c_enter_slot.prototype.battle = null;

        /**
         * Creates a new s2c_enter_slot instance using the specified properties.
         * @function create
         * @memberof battle.s2c_enter_slot
         * @static
         * @param {battle.Is2c_enter_slot=} [properties] Properties to set
         * @returns {battle.s2c_enter_slot} s2c_enter_slot instance
         */
        s2c_enter_slot.create = function create(properties) {
            return new s2c_enter_slot(properties);
        };

        /**
         * Encodes the specified s2c_enter_slot message. Does not implicitly {@link battle.s2c_enter_slot.verify|verify} messages.
         * @function encode
         * @memberof battle.s2c_enter_slot
         * @static
         * @param {battle.s2c_enter_slot} message s2c_enter_slot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_enter_slot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.battle != null && Object.hasOwnProperty.call(message, "battle"))
                $root.battle.Battle.encode(message.battle, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_enter_slot message from the specified reader or buffer.
         * @function decode
         * @memberof battle.s2c_enter_slot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.s2c_enter_slot} s2c_enter_slot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_enter_slot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.s2c_enter_slot();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        message.battle = $root.battle.Battle.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_enter_slot
         * @function getTypeUrl
         * @memberof battle.s2c_enter_slot
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_enter_slot.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.s2c_enter_slot";
        };

        return s2c_enter_slot;
    })();

    battle.c2s_exit = (function() {

        /**
         * Properties of a c2s_exit.
         * @memberof battle
         * @interface Ic2s_exit
         * @property {number|null} [sceneUid] c2s_exit sceneUid
         */

        /**
         * Constructs a new c2s_exit.
         * @memberof battle
         * @classdesc Represents a c2s_exit.
         * @implements Ic2s_exit
         * @constructor
         * @param {battle.Ic2s_exit=} [properties] Properties to set
         */
        function c2s_exit(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_exit sceneUid.
         * @member {number} sceneUid
         * @memberof battle.c2s_exit
         * @instance
         */
        c2s_exit.prototype.sceneUid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new c2s_exit instance using the specified properties.
         * @function create
         * @memberof battle.c2s_exit
         * @static
         * @param {battle.Ic2s_exit=} [properties] Properties to set
         * @returns {battle.c2s_exit} c2s_exit instance
         */
        c2s_exit.create = function create(properties) {
            return new c2s_exit(properties);
        };

        /**
         * Encodes the specified c2s_exit message. Does not implicitly {@link battle.c2s_exit.verify|verify} messages.
         * @function encode
         * @memberof battle.c2s_exit
         * @static
         * @param {battle.c2s_exit} message c2s_exit message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_exit.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sceneUid != null && Object.hasOwnProperty.call(message, "sceneUid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.sceneUid);
            return writer;
        };

        /**
         * Decodes a c2s_exit message from the specified reader or buffer.
         * @function decode
         * @memberof battle.c2s_exit
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.c2s_exit} c2s_exit
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_exit.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.c2s_exit();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sceneUid = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_exit
         * @function getTypeUrl
         * @memberof battle.c2s_exit
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_exit.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.c2s_exit";
        };

        return c2s_exit;
    })();

    battle.s2c_exit = (function() {

        /**
         * Properties of a s2c_exit.
         * @memberof battle
         * @interface Is2c_exit
         * @property {number|null} [err] s2c_exit err
         */

        /**
         * Constructs a new s2c_exit.
         * @memberof battle
         * @classdesc Represents a s2c_exit.
         * @implements Is2c_exit
         * @constructor
         * @param {battle.Is2c_exit=} [properties] Properties to set
         */
        function s2c_exit(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_exit err.
         * @member {number} err
         * @memberof battle.s2c_exit
         * @instance
         */
        s2c_exit.prototype.err = 0;

        /**
         * Creates a new s2c_exit instance using the specified properties.
         * @function create
         * @memberof battle.s2c_exit
         * @static
         * @param {battle.Is2c_exit=} [properties] Properties to set
         * @returns {battle.s2c_exit} s2c_exit instance
         */
        s2c_exit.create = function create(properties) {
            return new s2c_exit(properties);
        };

        /**
         * Encodes the specified s2c_exit message. Does not implicitly {@link battle.s2c_exit.verify|verify} messages.
         * @function encode
         * @memberof battle.s2c_exit
         * @static
         * @param {battle.s2c_exit} message s2c_exit message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_exit.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_exit message from the specified reader or buffer.
         * @function decode
         * @memberof battle.s2c_exit
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.s2c_exit} s2c_exit
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_exit.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.s2c_exit();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_exit
         * @function getTypeUrl
         * @memberof battle.s2c_exit
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_exit.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.s2c_exit";
        };

        return s2c_exit;
    })();

    battle.BattleFrame = (function() {

        /**
         * Properties of a BattleFrame.
         * @memberof battle
         * @interface IBattleFrame
         * @property {number|null} [frame] BattleFrame frame
         * @property {number|null} [battleUid] BattleFrame battleUid
         * @property {Array.<battle.BattleCmd>|null} [cmds] BattleFrame cmds
         * @property {Array.<battle.DebugInfo>|null} [debugPoints] BattleFrame debugPoints
         */

        /**
         * Constructs a new BattleFrame.
         * @memberof battle
         * @classdesc Represents a BattleFrame.
         * @implements IBattleFrame
         * @constructor
         * @param {battle.IBattleFrame=} [properties] Properties to set
         */
        function BattleFrame(properties) {
            this.cmds = [];
            this.debugPoints = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BattleFrame frame.
         * @member {number} frame
         * @memberof battle.BattleFrame
         * @instance
         */
        BattleFrame.prototype.frame = 0;

        /**
         * BattleFrame battleUid.
         * @member {number} battleUid
         * @memberof battle.BattleFrame
         * @instance
         */
        BattleFrame.prototype.battleUid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * BattleFrame cmds.
         * @member {Array.<battle.BattleCmd>} cmds
         * @memberof battle.BattleFrame
         * @instance
         */
        BattleFrame.prototype.cmds = $util.emptyArray;

        /**
         * BattleFrame debugPoints.
         * @member {Array.<battle.DebugInfo>} debugPoints
         * @memberof battle.BattleFrame
         * @instance
         */
        BattleFrame.prototype.debugPoints = $util.emptyArray;

        /**
         * Creates a new BattleFrame instance using the specified properties.
         * @function create
         * @memberof battle.BattleFrame
         * @static
         * @param {battle.IBattleFrame=} [properties] Properties to set
         * @returns {battle.BattleFrame} BattleFrame instance
         */
        BattleFrame.create = function create(properties) {
            return new BattleFrame(properties);
        };

        /**
         * Encodes the specified BattleFrame message. Does not implicitly {@link battle.BattleFrame.verify|verify} messages.
         * @function encode
         * @memberof battle.BattleFrame
         * @static
         * @param {battle.BattleFrame} message BattleFrame message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BattleFrame.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.frame != null && Object.hasOwnProperty.call(message, "frame"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.frame);
            if (message.battleUid != null && Object.hasOwnProperty.call(message, "battleUid"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.battleUid);
            if (message.cmds != null && message.cmds.length)
                for (let i = 0; i < message.cmds.length; ++i)
                    $root.battle.BattleCmd.encode(message.cmds[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.debugPoints != null && message.debugPoints.length)
                for (let i = 0; i < message.debugPoints.length; ++i)
                    $root.battle.DebugInfo.encode(message.debugPoints[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a BattleFrame message from the specified reader or buffer.
         * @function decode
         * @memberof battle.BattleFrame
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.BattleFrame} BattleFrame
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BattleFrame.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.BattleFrame();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.frame = reader.uint32();
                        break;
                    }
                case 2: {
                        message.battleUid = reader.uint64();
                        break;
                    }
                case 3: {
                        if (!(message.cmds && message.cmds.length))
                            message.cmds = [];
                        message.cmds.push($root.battle.BattleCmd.decode(reader, reader.uint32()));
                        break;
                    }
                case 4: {
                        if (!(message.debugPoints && message.debugPoints.length))
                            message.debugPoints = [];
                        message.debugPoints.push($root.battle.DebugInfo.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for BattleFrame
         * @function getTypeUrl
         * @memberof battle.BattleFrame
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BattleFrame.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.BattleFrame";
        };

        return BattleFrame;
    })();

    battle.BattleReport = (function() {

        /**
         * Properties of a BattleReport.
         * @memberof battle
         * @interface IBattleReport
         * @property {number|null} [battleUid] BattleReport battleUid
         * @property {Array.<battle.BattleFrame>|null} [frames] BattleReport frames
         */

        /**
         * Constructs a new BattleReport.
         * @memberof battle
         * @classdesc Represents a BattleReport.
         * @implements IBattleReport
         * @constructor
         * @param {battle.IBattleReport=} [properties] Properties to set
         */
        function BattleReport(properties) {
            this.frames = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BattleReport battleUid.
         * @member {number} battleUid
         * @memberof battle.BattleReport
         * @instance
         */
        BattleReport.prototype.battleUid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * BattleReport frames.
         * @member {Array.<battle.BattleFrame>} frames
         * @memberof battle.BattleReport
         * @instance
         */
        BattleReport.prototype.frames = $util.emptyArray;

        /**
         * Creates a new BattleReport instance using the specified properties.
         * @function create
         * @memberof battle.BattleReport
         * @static
         * @param {battle.IBattleReport=} [properties] Properties to set
         * @returns {battle.BattleReport} BattleReport instance
         */
        BattleReport.create = function create(properties) {
            return new BattleReport(properties);
        };

        /**
         * Encodes the specified BattleReport message. Does not implicitly {@link battle.BattleReport.verify|verify} messages.
         * @function encode
         * @memberof battle.BattleReport
         * @static
         * @param {battle.BattleReport} message BattleReport message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BattleReport.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.battleUid != null && Object.hasOwnProperty.call(message, "battleUid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.battleUid);
            if (message.frames != null && message.frames.length)
                for (let i = 0; i < message.frames.length; ++i)
                    $root.battle.BattleFrame.encode(message.frames[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a BattleReport message from the specified reader or buffer.
         * @function decode
         * @memberof battle.BattleReport
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.BattleReport} BattleReport
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BattleReport.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.BattleReport();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.battleUid = reader.uint64();
                        break;
                    }
                case 2: {
                        if (!(message.frames && message.frames.length))
                            message.frames = [];
                        message.frames.push($root.battle.BattleFrame.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for BattleReport
         * @function getTypeUrl
         * @memberof battle.BattleReport
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BattleReport.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.BattleReport";
        };

        return BattleReport;
    })();

    battle.c2s_load_report = (function() {

        /**
         * Properties of a c2s_load_report.
         * @memberof battle
         * @interface Ic2s_load_report
         * @property {number|null} [battleUid] c2s_load_report battleUid
         */

        /**
         * Constructs a new c2s_load_report.
         * @memberof battle
         * @classdesc Represents a c2s_load_report.
         * @implements Ic2s_load_report
         * @constructor
         * @param {battle.Ic2s_load_report=} [properties] Properties to set
         */
        function c2s_load_report(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_load_report battleUid.
         * @member {number} battleUid
         * @memberof battle.c2s_load_report
         * @instance
         */
        c2s_load_report.prototype.battleUid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new c2s_load_report instance using the specified properties.
         * @function create
         * @memberof battle.c2s_load_report
         * @static
         * @param {battle.Ic2s_load_report=} [properties] Properties to set
         * @returns {battle.c2s_load_report} c2s_load_report instance
         */
        c2s_load_report.create = function create(properties) {
            return new c2s_load_report(properties);
        };

        /**
         * Encodes the specified c2s_load_report message. Does not implicitly {@link battle.c2s_load_report.verify|verify} messages.
         * @function encode
         * @memberof battle.c2s_load_report
         * @static
         * @param {battle.c2s_load_report} message c2s_load_report message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_load_report.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.battleUid != null && Object.hasOwnProperty.call(message, "battleUid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.battleUid);
            return writer;
        };

        /**
         * Decodes a c2s_load_report message from the specified reader or buffer.
         * @function decode
         * @memberof battle.c2s_load_report
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.c2s_load_report} c2s_load_report
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_load_report.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.c2s_load_report();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.battleUid = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_load_report
         * @function getTypeUrl
         * @memberof battle.c2s_load_report
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_load_report.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.c2s_load_report";
        };

        return c2s_load_report;
    })();

    battle.s2c_load_report = (function() {

        /**
         * Properties of a s2c_load_report.
         * @memberof battle
         * @interface Is2c_load_report
         * @property {number|null} [err] s2c_load_report err
         * @property {string|null} [url] s2c_load_report url
         */

        /**
         * Constructs a new s2c_load_report.
         * @memberof battle
         * @classdesc Represents a s2c_load_report.
         * @implements Is2c_load_report
         * @constructor
         * @param {battle.Is2c_load_report=} [properties] Properties to set
         */
        function s2c_load_report(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_load_report err.
         * @member {number} err
         * @memberof battle.s2c_load_report
         * @instance
         */
        s2c_load_report.prototype.err = 0;

        /**
         * s2c_load_report url.
         * @member {string} url
         * @memberof battle.s2c_load_report
         * @instance
         */
        s2c_load_report.prototype.url = "";

        /**
         * Creates a new s2c_load_report instance using the specified properties.
         * @function create
         * @memberof battle.s2c_load_report
         * @static
         * @param {battle.Is2c_load_report=} [properties] Properties to set
         * @returns {battle.s2c_load_report} s2c_load_report instance
         */
        s2c_load_report.create = function create(properties) {
            return new s2c_load_report(properties);
        };

        /**
         * Encodes the specified s2c_load_report message. Does not implicitly {@link battle.s2c_load_report.verify|verify} messages.
         * @function encode
         * @memberof battle.s2c_load_report
         * @static
         * @param {battle.s2c_load_report} message s2c_load_report message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_load_report.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
            return writer;
        };

        /**
         * Decodes a s2c_load_report message from the specified reader or buffer.
         * @function decode
         * @memberof battle.s2c_load_report
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.s2c_load_report} s2c_load_report
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_load_report.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.s2c_load_report();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        message.url = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_load_report
         * @function getTypeUrl
         * @memberof battle.s2c_load_report
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_load_report.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.s2c_load_report";
        };

        return s2c_load_report;
    })();

    battle.c2s_load_report_list = (function() {

        /**
         * Properties of a c2s_load_report_list.
         * @memberof battle
         * @interface Ic2s_load_report_list
         */

        /**
         * Constructs a new c2s_load_report_list.
         * @memberof battle
         * @classdesc Represents a c2s_load_report_list.
         * @implements Ic2s_load_report_list
         * @constructor
         * @param {battle.Ic2s_load_report_list=} [properties] Properties to set
         */
        function c2s_load_report_list(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_load_report_list instance using the specified properties.
         * @function create
         * @memberof battle.c2s_load_report_list
         * @static
         * @param {battle.Ic2s_load_report_list=} [properties] Properties to set
         * @returns {battle.c2s_load_report_list} c2s_load_report_list instance
         */
        c2s_load_report_list.create = function create(properties) {
            return new c2s_load_report_list(properties);
        };

        /**
         * Encodes the specified c2s_load_report_list message. Does not implicitly {@link battle.c2s_load_report_list.verify|verify} messages.
         * @function encode
         * @memberof battle.c2s_load_report_list
         * @static
         * @param {battle.c2s_load_report_list} message c2s_load_report_list message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_load_report_list.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_load_report_list message from the specified reader or buffer.
         * @function decode
         * @memberof battle.c2s_load_report_list
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.c2s_load_report_list} c2s_load_report_list
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_load_report_list.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.c2s_load_report_list();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_load_report_list
         * @function getTypeUrl
         * @memberof battle.c2s_load_report_list
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_load_report_list.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.c2s_load_report_list";
        };

        return c2s_load_report_list;
    })();

    battle.s2c_load_report_list = (function() {

        /**
         * Properties of a s2c_load_report_list.
         * @memberof battle
         * @interface Is2c_load_report_list
         * @property {number|null} [err] s2c_load_report_list err
         * @property {Array.<string>|null} [urlList] s2c_load_report_list urlList
         */

        /**
         * Constructs a new s2c_load_report_list.
         * @memberof battle
         * @classdesc Represents a s2c_load_report_list.
         * @implements Is2c_load_report_list
         * @constructor
         * @param {battle.Is2c_load_report_list=} [properties] Properties to set
         */
        function s2c_load_report_list(properties) {
            this.urlList = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_load_report_list err.
         * @member {number} err
         * @memberof battle.s2c_load_report_list
         * @instance
         */
        s2c_load_report_list.prototype.err = 0;

        /**
         * s2c_load_report_list urlList.
         * @member {Array.<string>} urlList
         * @memberof battle.s2c_load_report_list
         * @instance
         */
        s2c_load_report_list.prototype.urlList = $util.emptyArray;

        /**
         * Creates a new s2c_load_report_list instance using the specified properties.
         * @function create
         * @memberof battle.s2c_load_report_list
         * @static
         * @param {battle.Is2c_load_report_list=} [properties] Properties to set
         * @returns {battle.s2c_load_report_list} s2c_load_report_list instance
         */
        s2c_load_report_list.create = function create(properties) {
            return new s2c_load_report_list(properties);
        };

        /**
         * Encodes the specified s2c_load_report_list message. Does not implicitly {@link battle.s2c_load_report_list.verify|verify} messages.
         * @function encode
         * @memberof battle.s2c_load_report_list
         * @static
         * @param {battle.s2c_load_report_list} message s2c_load_report_list message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_load_report_list.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.urlList != null && message.urlList.length)
                for (let i = 0; i < message.urlList.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.urlList[i]);
            return writer;
        };

        /**
         * Decodes a s2c_load_report_list message from the specified reader or buffer.
         * @function decode
         * @memberof battle.s2c_load_report_list
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.s2c_load_report_list} s2c_load_report_list
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_load_report_list.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.s2c_load_report_list();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.urlList && message.urlList.length))
                            message.urlList = [];
                        message.urlList.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_load_report_list
         * @function getTypeUrl
         * @memberof battle.s2c_load_report_list
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_load_report_list.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.s2c_load_report_list";
        };

        return s2c_load_report_list;
    })();

    battle.notify_cmd = (function() {

        /**
         * Properties of a notify_cmd.
         * @memberof battle
         * @interface Inotify_cmd
         * @property {battle.BattleFrame|null} [frame] notify_cmd frame
         */

        /**
         * Constructs a new notify_cmd.
         * @memberof battle
         * @classdesc Represents a notify_cmd.
         * @implements Inotify_cmd
         * @constructor
         * @param {battle.Inotify_cmd=} [properties] Properties to set
         */
        function notify_cmd(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_cmd frame.
         * @member {battle.BattleFrame|null|undefined} frame
         * @memberof battle.notify_cmd
         * @instance
         */
        notify_cmd.prototype.frame = null;

        /**
         * Creates a new notify_cmd instance using the specified properties.
         * @function create
         * @memberof battle.notify_cmd
         * @static
         * @param {battle.Inotify_cmd=} [properties] Properties to set
         * @returns {battle.notify_cmd} notify_cmd instance
         */
        notify_cmd.create = function create(properties) {
            return new notify_cmd(properties);
        };

        /**
         * Encodes the specified notify_cmd message. Does not implicitly {@link battle.notify_cmd.verify|verify} messages.
         * @function encode
         * @memberof battle.notify_cmd
         * @static
         * @param {battle.notify_cmd} message notify_cmd message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_cmd.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.frame != null && Object.hasOwnProperty.call(message, "frame"))
                $root.battle.BattleFrame.encode(message.frame, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a notify_cmd message from the specified reader or buffer.
         * @function decode
         * @memberof battle.notify_cmd
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.notify_cmd} notify_cmd
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_cmd.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.notify_cmd();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.frame = $root.battle.BattleFrame.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_cmd
         * @function getTypeUrl
         * @memberof battle.notify_cmd
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_cmd.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.notify_cmd";
        };

        return notify_cmd;
    })();

    battle.notify_scene = (function() {

        /**
         * Properties of a notify_scene.
         * @memberof battle
         * @interface Inotify_scene
         * @property {Array.<battle.TroopNotifyInfo>|null} [updateTroops] notify_scene updateTroops
         * @property {Array.<battle.TroopFullInfo>|null} [newTroops] notify_scene newTroops
         * @property {Array.<number>|null} [delTroops] notify_scene delTroops
         */

        /**
         * Constructs a new notify_scene.
         * @memberof battle
         * @classdesc Represents a notify_scene.
         * @implements Inotify_scene
         * @constructor
         * @param {battle.Inotify_scene=} [properties] Properties to set
         */
        function notify_scene(properties) {
            this.updateTroops = [];
            this.newTroops = [];
            this.delTroops = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_scene updateTroops.
         * @member {Array.<battle.TroopNotifyInfo>} updateTroops
         * @memberof battle.notify_scene
         * @instance
         */
        notify_scene.prototype.updateTroops = $util.emptyArray;

        /**
         * notify_scene newTroops.
         * @member {Array.<battle.TroopFullInfo>} newTroops
         * @memberof battle.notify_scene
         * @instance
         */
        notify_scene.prototype.newTroops = $util.emptyArray;

        /**
         * notify_scene delTroops.
         * @member {Array.<number>} delTroops
         * @memberof battle.notify_scene
         * @instance
         */
        notify_scene.prototype.delTroops = $util.emptyArray;

        /**
         * Creates a new notify_scene instance using the specified properties.
         * @function create
         * @memberof battle.notify_scene
         * @static
         * @param {battle.Inotify_scene=} [properties] Properties to set
         * @returns {battle.notify_scene} notify_scene instance
         */
        notify_scene.create = function create(properties) {
            return new notify_scene(properties);
        };

        /**
         * Encodes the specified notify_scene message. Does not implicitly {@link battle.notify_scene.verify|verify} messages.
         * @function encode
         * @memberof battle.notify_scene
         * @static
         * @param {battle.notify_scene} message notify_scene message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_scene.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.updateTroops != null && message.updateTroops.length)
                for (let i = 0; i < message.updateTroops.length; ++i)
                    $root.battle.TroopNotifyInfo.encode(message.updateTroops[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.newTroops != null && message.newTroops.length)
                for (let i = 0; i < message.newTroops.length; ++i)
                    $root.battle.TroopFullInfo.encode(message.newTroops[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.delTroops != null && message.delTroops.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (let i = 0; i < message.delTroops.length; ++i)
                    writer.uint32(message.delTroops[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a notify_scene message from the specified reader or buffer.
         * @function decode
         * @memberof battle.notify_scene
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.notify_scene} notify_scene
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_scene.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.notify_scene();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.updateTroops && message.updateTroops.length))
                            message.updateTroops = [];
                        message.updateTroops.push($root.battle.TroopNotifyInfo.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        if (!(message.newTroops && message.newTroops.length))
                            message.newTroops = [];
                        message.newTroops.push($root.battle.TroopFullInfo.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        if (!(message.delTroops && message.delTroops.length))
                            message.delTroops = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.delTroops.push(reader.uint32());
                        } else
                            message.delTroops.push(reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_scene
         * @function getTypeUrl
         * @memberof battle.notify_scene
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_scene.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.notify_scene";
        };

        return notify_scene;
    })();

    battle.notify_debug = (function() {

        /**
         * Properties of a notify_debug.
         * @memberof battle
         * @interface Inotify_debug
         * @property {Array.<battle.DebugInfo>|null} [infos] notify_debug infos
         * @property {Array.<string>|null} [logs] notify_debug logs
         */

        /**
         * Constructs a new notify_debug.
         * @memberof battle
         * @classdesc Represents a notify_debug.
         * @implements Inotify_debug
         * @constructor
         * @param {battle.Inotify_debug=} [properties] Properties to set
         */
        function notify_debug(properties) {
            this.infos = [];
            this.logs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_debug infos.
         * @member {Array.<battle.DebugInfo>} infos
         * @memberof battle.notify_debug
         * @instance
         */
        notify_debug.prototype.infos = $util.emptyArray;

        /**
         * notify_debug logs.
         * @member {Array.<string>} logs
         * @memberof battle.notify_debug
         * @instance
         */
        notify_debug.prototype.logs = $util.emptyArray;

        /**
         * Creates a new notify_debug instance using the specified properties.
         * @function create
         * @memberof battle.notify_debug
         * @static
         * @param {battle.Inotify_debug=} [properties] Properties to set
         * @returns {battle.notify_debug} notify_debug instance
         */
        notify_debug.create = function create(properties) {
            return new notify_debug(properties);
        };

        /**
         * Encodes the specified notify_debug message. Does not implicitly {@link battle.notify_debug.verify|verify} messages.
         * @function encode
         * @memberof battle.notify_debug
         * @static
         * @param {battle.notify_debug} message notify_debug message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_debug.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.infos != null && message.infos.length)
                for (let i = 0; i < message.infos.length; ++i)
                    $root.battle.DebugInfo.encode(message.infos[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.logs != null && message.logs.length)
                for (let i = 0; i < message.logs.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.logs[i]);
            return writer;
        };

        /**
         * Decodes a notify_debug message from the specified reader or buffer.
         * @function decode
         * @memberof battle.notify_debug
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {battle.notify_debug} notify_debug
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_debug.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.battle.notify_debug();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.infos && message.infos.length))
                            message.infos = [];
                        message.infos.push($root.battle.DebugInfo.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        if (!(message.logs && message.logs.length))
                            message.logs = [];
                        message.logs.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_debug
         * @function getTypeUrl
         * @memberof battle.notify_debug
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_debug.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/battle.notify_debug";
        };

        return notify_debug;
    })();

    return battle;
})();

export const chat = $root.chat = (() => {

    /**
     * Namespace chat.
     * @exports chat
     * @namespace
     */
    const chat = {};

    chat.ChatRole = (function() {

        /**
         * Properties of a ChatRole.
         * @memberof chat
         * @interface IChatRole
         * @property {number|null} [rid] ChatRole rid
         * @property {number|null} [avatarId] ChatRole avatarId
         * @property {string|null} [avatarUrl] ChatRole avatarUrl
         * @property {string|null} [name] ChatRole name
         */

        /**
         * Constructs a new ChatRole.
         * @memberof chat
         * @classdesc Represents a ChatRole.
         * @implements IChatRole
         * @constructor
         * @param {chat.IChatRole=} [properties] Properties to set
         */
        function ChatRole(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChatRole rid.
         * @member {number} rid
         * @memberof chat.ChatRole
         * @instance
         */
        ChatRole.prototype.rid = 0;

        /**
         * ChatRole avatarId.
         * @member {number} avatarId
         * @memberof chat.ChatRole
         * @instance
         */
        ChatRole.prototype.avatarId = 0;

        /**
         * ChatRole avatarUrl.
         * @member {string} avatarUrl
         * @memberof chat.ChatRole
         * @instance
         */
        ChatRole.prototype.avatarUrl = "";

        /**
         * ChatRole name.
         * @member {string} name
         * @memberof chat.ChatRole
         * @instance
         */
        ChatRole.prototype.name = "";

        /**
         * Creates a new ChatRole instance using the specified properties.
         * @function create
         * @memberof chat.ChatRole
         * @static
         * @param {chat.IChatRole=} [properties] Properties to set
         * @returns {chat.ChatRole} ChatRole instance
         */
        ChatRole.create = function create(properties) {
            return new ChatRole(properties);
        };

        /**
         * Encodes the specified ChatRole message. Does not implicitly {@link chat.ChatRole.verify|verify} messages.
         * @function encode
         * @memberof chat.ChatRole
         * @static
         * @param {chat.ChatRole} message ChatRole message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChatRole.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rid != null && Object.hasOwnProperty.call(message, "rid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.rid);
            if (message.avatarId != null && Object.hasOwnProperty.call(message, "avatarId"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.avatarId);
            if (message.avatarUrl != null && Object.hasOwnProperty.call(message, "avatarUrl"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.avatarUrl);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
            return writer;
        };

        /**
         * Decodes a ChatRole message from the specified reader or buffer.
         * @function decode
         * @memberof chat.ChatRole
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chat.ChatRole} ChatRole
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChatRole.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chat.ChatRole();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.avatarId = reader.uint32();
                        break;
                    }
                case 3: {
                        message.avatarUrl = reader.string();
                        break;
                    }
                case 4: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for ChatRole
         * @function getTypeUrl
         * @memberof chat.ChatRole
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChatRole.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/chat.ChatRole";
        };

        return ChatRole;
    })();

    chat.ChatMessage = (function() {

        /**
         * Properties of a ChatMessage.
         * @memberof chat
         * @interface IChatMessage
         * @property {number|null} [rid] ChatMessage rid
         * @property {string|null} [text] ChatMessage text
         * @property {number|null} [time] ChatMessage time
         */

        /**
         * Constructs a new ChatMessage.
         * @memberof chat
         * @classdesc Represents a ChatMessage.
         * @implements IChatMessage
         * @constructor
         * @param {chat.IChatMessage=} [properties] Properties to set
         */
        function ChatMessage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChatMessage rid.
         * @member {number} rid
         * @memberof chat.ChatMessage
         * @instance
         */
        ChatMessage.prototype.rid = 0;

        /**
         * ChatMessage text.
         * @member {string} text
         * @memberof chat.ChatMessage
         * @instance
         */
        ChatMessage.prototype.text = "";

        /**
         * ChatMessage time.
         * @member {number} time
         * @memberof chat.ChatMessage
         * @instance
         */
        ChatMessage.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new ChatMessage instance using the specified properties.
         * @function create
         * @memberof chat.ChatMessage
         * @static
         * @param {chat.IChatMessage=} [properties] Properties to set
         * @returns {chat.ChatMessage} ChatMessage instance
         */
        ChatMessage.create = function create(properties) {
            return new ChatMessage(properties);
        };

        /**
         * Encodes the specified ChatMessage message. Does not implicitly {@link chat.ChatMessage.verify|verify} messages.
         * @function encode
         * @memberof chat.ChatMessage
         * @static
         * @param {chat.ChatMessage} message ChatMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChatMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rid != null && Object.hasOwnProperty.call(message, "rid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.rid);
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.text);
            if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.time);
            return writer;
        };

        /**
         * Decodes a ChatMessage message from the specified reader or buffer.
         * @function decode
         * @memberof chat.ChatMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chat.ChatMessage} ChatMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChatMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chat.ChatMessage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.text = reader.string();
                        break;
                    }
                case 4: {
                        message.time = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for ChatMessage
         * @function getTypeUrl
         * @memberof chat.ChatMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChatMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/chat.ChatMessage";
        };

        return ChatMessage;
    })();

    chat.c2s_load = (function() {

        /**
         * Properties of a c2s_load.
         * @memberof chat
         * @interface Ic2s_load
         * @property {number|null} [channel] c2s_load channel
         */

        /**
         * Constructs a new c2s_load.
         * @memberof chat
         * @classdesc Represents a c2s_load.
         * @implements Ic2s_load
         * @constructor
         * @param {chat.Ic2s_load=} [properties] Properties to set
         */
        function c2s_load(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_load channel.
         * @member {number} channel
         * @memberof chat.c2s_load
         * @instance
         */
        c2s_load.prototype.channel = 0;

        /**
         * Creates a new c2s_load instance using the specified properties.
         * @function create
         * @memberof chat.c2s_load
         * @static
         * @param {chat.Ic2s_load=} [properties] Properties to set
         * @returns {chat.c2s_load} c2s_load instance
         */
        c2s_load.create = function create(properties) {
            return new c2s_load(properties);
        };

        /**
         * Encodes the specified c2s_load message. Does not implicitly {@link chat.c2s_load.verify|verify} messages.
         * @function encode
         * @memberof chat.c2s_load
         * @static
         * @param {chat.c2s_load} message c2s_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.channel);
            return writer;
        };

        /**
         * Decodes a c2s_load message from the specified reader or buffer.
         * @function decode
         * @memberof chat.c2s_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chat.c2s_load} c2s_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chat.c2s_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.channel = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_load
         * @function getTypeUrl
         * @memberof chat.c2s_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/chat.c2s_load";
        };

        return c2s_load;
    })();

    chat.s2c_load = (function() {

        /**
         * Properties of a s2c_load.
         * @memberof chat
         * @interface Is2c_load
         * @property {number|null} [err] s2c_load err
         * @property {number|null} [channel] s2c_load channel
         * @property {Array.<chat.ChatMessage>|null} [messages] s2c_load messages
         * @property {Array.<chat.ChatRole>|null} [chatRoles] s2c_load chatRoles
         */

        /**
         * Constructs a new s2c_load.
         * @memberof chat
         * @classdesc Represents a s2c_load.
         * @implements Is2c_load
         * @constructor
         * @param {chat.Is2c_load=} [properties] Properties to set
         */
        function s2c_load(properties) {
            this.messages = [];
            this.chatRoles = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_load err.
         * @member {number} err
         * @memberof chat.s2c_load
         * @instance
         */
        s2c_load.prototype.err = 0;

        /**
         * s2c_load channel.
         * @member {number} channel
         * @memberof chat.s2c_load
         * @instance
         */
        s2c_load.prototype.channel = 0;

        /**
         * s2c_load messages.
         * @member {Array.<chat.ChatMessage>} messages
         * @memberof chat.s2c_load
         * @instance
         */
        s2c_load.prototype.messages = $util.emptyArray;

        /**
         * s2c_load chatRoles.
         * @member {Array.<chat.ChatRole>} chatRoles
         * @memberof chat.s2c_load
         * @instance
         */
        s2c_load.prototype.chatRoles = $util.emptyArray;

        /**
         * Creates a new s2c_load instance using the specified properties.
         * @function create
         * @memberof chat.s2c_load
         * @static
         * @param {chat.Is2c_load=} [properties] Properties to set
         * @returns {chat.s2c_load} s2c_load instance
         */
        s2c_load.create = function create(properties) {
            return new s2c_load(properties);
        };

        /**
         * Encodes the specified s2c_load message. Does not implicitly {@link chat.s2c_load.verify|verify} messages.
         * @function encode
         * @memberof chat.s2c_load
         * @static
         * @param {chat.s2c_load} message s2c_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.channel);
            if (message.messages != null && message.messages.length)
                for (let i = 0; i < message.messages.length; ++i)
                    $root.chat.ChatMessage.encode(message.messages[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.chatRoles != null && message.chatRoles.length)
                for (let i = 0; i < message.chatRoles.length; ++i)
                    $root.chat.ChatRole.encode(message.chatRoles[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_load message from the specified reader or buffer.
         * @function decode
         * @memberof chat.s2c_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chat.s2c_load} s2c_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chat.s2c_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        message.channel = reader.uint32();
                        break;
                    }
                case 3: {
                        if (!(message.messages && message.messages.length))
                            message.messages = [];
                        message.messages.push($root.chat.ChatMessage.decode(reader, reader.uint32()));
                        break;
                    }
                case 4: {
                        if (!(message.chatRoles && message.chatRoles.length))
                            message.chatRoles = [];
                        message.chatRoles.push($root.chat.ChatRole.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_load
         * @function getTypeUrl
         * @memberof chat.s2c_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/chat.s2c_load";
        };

        return s2c_load;
    })();

    chat.c2s_send = (function() {

        /**
         * Properties of a c2s_send.
         * @memberof chat
         * @interface Ic2s_send
         * @property {number|null} [channel] c2s_send channel
         * @property {string|null} [text] c2s_send text
         */

        /**
         * Constructs a new c2s_send.
         * @memberof chat
         * @classdesc Represents a c2s_send.
         * @implements Ic2s_send
         * @constructor
         * @param {chat.Ic2s_send=} [properties] Properties to set
         */
        function c2s_send(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_send channel.
         * @member {number} channel
         * @memberof chat.c2s_send
         * @instance
         */
        c2s_send.prototype.channel = 0;

        /**
         * c2s_send text.
         * @member {string} text
         * @memberof chat.c2s_send
         * @instance
         */
        c2s_send.prototype.text = "";

        /**
         * Creates a new c2s_send instance using the specified properties.
         * @function create
         * @memberof chat.c2s_send
         * @static
         * @param {chat.Ic2s_send=} [properties] Properties to set
         * @returns {chat.c2s_send} c2s_send instance
         */
        c2s_send.create = function create(properties) {
            return new c2s_send(properties);
        };

        /**
         * Encodes the specified c2s_send message. Does not implicitly {@link chat.c2s_send.verify|verify} messages.
         * @function encode
         * @memberof chat.c2s_send
         * @static
         * @param {chat.c2s_send} message c2s_send message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_send.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.channel);
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.text);
            return writer;
        };

        /**
         * Decodes a c2s_send message from the specified reader or buffer.
         * @function decode
         * @memberof chat.c2s_send
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chat.c2s_send} c2s_send
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_send.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chat.c2s_send();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.channel = reader.uint32();
                        break;
                    }
                case 2: {
                        message.text = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_send
         * @function getTypeUrl
         * @memberof chat.c2s_send
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_send.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/chat.c2s_send";
        };

        return c2s_send;
    })();

    chat.s2c_send = (function() {

        /**
         * Properties of a s2c_send.
         * @memberof chat
         * @interface Is2c_send
         * @property {number|null} [err] s2c_send err
         */

        /**
         * Constructs a new s2c_send.
         * @memberof chat
         * @classdesc Represents a s2c_send.
         * @implements Is2c_send
         * @constructor
         * @param {chat.Is2c_send=} [properties] Properties to set
         */
        function s2c_send(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_send err.
         * @member {number} err
         * @memberof chat.s2c_send
         * @instance
         */
        s2c_send.prototype.err = 0;

        /**
         * Creates a new s2c_send instance using the specified properties.
         * @function create
         * @memberof chat.s2c_send
         * @static
         * @param {chat.Is2c_send=} [properties] Properties to set
         * @returns {chat.s2c_send} s2c_send instance
         */
        s2c_send.create = function create(properties) {
            return new s2c_send(properties);
        };

        /**
         * Encodes the specified s2c_send message. Does not implicitly {@link chat.s2c_send.verify|verify} messages.
         * @function encode
         * @memberof chat.s2c_send
         * @static
         * @param {chat.s2c_send} message s2c_send message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_send.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_send message from the specified reader or buffer.
         * @function decode
         * @memberof chat.s2c_send
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chat.s2c_send} s2c_send
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_send.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chat.s2c_send();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_send
         * @function getTypeUrl
         * @memberof chat.s2c_send
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_send.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/chat.s2c_send";
        };

        return s2c_send;
    })();

    chat.notify = (function() {

        /**
         * Properties of a notify.
         * @memberof chat
         * @interface Inotify
         * @property {number|null} [channel] notify channel
         * @property {Array.<chat.ChatMessage>|null} [messages] notify messages
         * @property {Array.<chat.ChatRole>|null} [chatRoles] notify chatRoles
         */

        /**
         * Constructs a new notify.
         * @memberof chat
         * @classdesc Represents a notify.
         * @implements Inotify
         * @constructor
         * @param {chat.Inotify=} [properties] Properties to set
         */
        function notify(properties) {
            this.messages = [];
            this.chatRoles = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify channel.
         * @member {number} channel
         * @memberof chat.notify
         * @instance
         */
        notify.prototype.channel = 0;

        /**
         * notify messages.
         * @member {Array.<chat.ChatMessage>} messages
         * @memberof chat.notify
         * @instance
         */
        notify.prototype.messages = $util.emptyArray;

        /**
         * notify chatRoles.
         * @member {Array.<chat.ChatRole>} chatRoles
         * @memberof chat.notify
         * @instance
         */
        notify.prototype.chatRoles = $util.emptyArray;

        /**
         * Creates a new notify instance using the specified properties.
         * @function create
         * @memberof chat.notify
         * @static
         * @param {chat.Inotify=} [properties] Properties to set
         * @returns {chat.notify} notify instance
         */
        notify.create = function create(properties) {
            return new notify(properties);
        };

        /**
         * Encodes the specified notify message. Does not implicitly {@link chat.notify.verify|verify} messages.
         * @function encode
         * @memberof chat.notify
         * @static
         * @param {chat.notify} message notify message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.channel);
            if (message.messages != null && message.messages.length)
                for (let i = 0; i < message.messages.length; ++i)
                    $root.chat.ChatMessage.encode(message.messages[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.chatRoles != null && message.chatRoles.length)
                for (let i = 0; i < message.chatRoles.length; ++i)
                    $root.chat.ChatRole.encode(message.chatRoles[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a notify message from the specified reader or buffer.
         * @function decode
         * @memberof chat.notify
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chat.notify} notify
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chat.notify();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.channel = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.messages && message.messages.length))
                            message.messages = [];
                        message.messages.push($root.chat.ChatMessage.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        if (!(message.chatRoles && message.chatRoles.length))
                            message.chatRoles = [];
                        message.chatRoles.push($root.chat.ChatRole.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify
         * @function getTypeUrl
         * @memberof chat.notify
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/chat.notify";
        };

        return notify;
    })();

    return chat;
})();

export const chest = $root.chest = (() => {

    /**
     * Namespace chest.
     * @exports chest
     * @namespace
     */
    const chest = {};

    chest.ScoreInfo = (function() {

        /**
         * Properties of a ScoreInfo.
         * @memberof chest
         * @interface IScoreInfo
         * @property {number|null} [score] ScoreInfo score
         * @property {number|null} [scoreId] ScoreInfo scoreId
         */

        /**
         * Constructs a new ScoreInfo.
         * @memberof chest
         * @classdesc Represents a ScoreInfo.
         * @implements IScoreInfo
         * @constructor
         * @param {chest.IScoreInfo=} [properties] Properties to set
         */
        function ScoreInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ScoreInfo score.
         * @member {number} score
         * @memberof chest.ScoreInfo
         * @instance
         */
        ScoreInfo.prototype.score = 0;

        /**
         * ScoreInfo scoreId.
         * @member {number} scoreId
         * @memberof chest.ScoreInfo
         * @instance
         */
        ScoreInfo.prototype.scoreId = 0;

        /**
         * Creates a new ScoreInfo instance using the specified properties.
         * @function create
         * @memberof chest.ScoreInfo
         * @static
         * @param {chest.IScoreInfo=} [properties] Properties to set
         * @returns {chest.ScoreInfo} ScoreInfo instance
         */
        ScoreInfo.create = function create(properties) {
            return new ScoreInfo(properties);
        };

        /**
         * Encodes the specified ScoreInfo message. Does not implicitly {@link chest.ScoreInfo.verify|verify} messages.
         * @function encode
         * @memberof chest.ScoreInfo
         * @static
         * @param {chest.ScoreInfo} message ScoreInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ScoreInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.score != null && Object.hasOwnProperty.call(message, "score"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.score);
            if (message.scoreId != null && Object.hasOwnProperty.call(message, "scoreId"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.scoreId);
            return writer;
        };

        /**
         * Decodes a ScoreInfo message from the specified reader or buffer.
         * @function decode
         * @memberof chest.ScoreInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chest.ScoreInfo} ScoreInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ScoreInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chest.ScoreInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4: {
                        message.score = reader.uint32();
                        break;
                    }
                case 5: {
                        message.scoreId = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for ScoreInfo
         * @function getTypeUrl
         * @memberof chest.ScoreInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ScoreInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/chest.ScoreInfo";
        };

        return ScoreInfo;
    })();

    chest.c2s_load = (function() {

        /**
         * Properties of a c2s_load.
         * @memberof chest
         * @interface Ic2s_load
         */

        /**
         * Constructs a new c2s_load.
         * @memberof chest
         * @classdesc Represents a c2s_load.
         * @implements Ic2s_load
         * @constructor
         * @param {chest.Ic2s_load=} [properties] Properties to set
         */
        function c2s_load(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_load instance using the specified properties.
         * @function create
         * @memberof chest.c2s_load
         * @static
         * @param {chest.Ic2s_load=} [properties] Properties to set
         * @returns {chest.c2s_load} c2s_load instance
         */
        c2s_load.create = function create(properties) {
            return new c2s_load(properties);
        };

        /**
         * Encodes the specified c2s_load message. Does not implicitly {@link chest.c2s_load.verify|verify} messages.
         * @function encode
         * @memberof chest.c2s_load
         * @static
         * @param {chest.c2s_load} message c2s_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_load message from the specified reader or buffer.
         * @function decode
         * @memberof chest.c2s_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chest.c2s_load} c2s_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chest.c2s_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_load
         * @function getTypeUrl
         * @memberof chest.c2s_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/chest.c2s_load";
        };

        return c2s_load;
    })();

    chest.s2c_load = (function() {

        /**
         * Properties of a s2c_load.
         * @memberof chest
         * @interface Is2c_load
         * @property {number|null} [err] s2c_load err
         * @property {number|null} [heroId] s2c_load heroId
         * @property {chest.ScoreInfo|null} [score] s2c_load score
         * @property {Array.<number>|null} [heroIds] s2c_load heroIds
         */

        /**
         * Constructs a new s2c_load.
         * @memberof chest
         * @classdesc Represents a s2c_load.
         * @implements Is2c_load
         * @constructor
         * @param {chest.Is2c_load=} [properties] Properties to set
         */
        function s2c_load(properties) {
            this.heroIds = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_load err.
         * @member {number} err
         * @memberof chest.s2c_load
         * @instance
         */
        s2c_load.prototype.err = 0;

        /**
         * s2c_load heroId.
         * @member {number} heroId
         * @memberof chest.s2c_load
         * @instance
         */
        s2c_load.prototype.heroId = 0;

        /**
         * s2c_load score.
         * @member {chest.ScoreInfo|null|undefined} score
         * @memberof chest.s2c_load
         * @instance
         */
        s2c_load.prototype.score = null;

        /**
         * s2c_load heroIds.
         * @member {Array.<number>} heroIds
         * @memberof chest.s2c_load
         * @instance
         */
        s2c_load.prototype.heroIds = $util.emptyArray;

        /**
         * Creates a new s2c_load instance using the specified properties.
         * @function create
         * @memberof chest.s2c_load
         * @static
         * @param {chest.Is2c_load=} [properties] Properties to set
         * @returns {chest.s2c_load} s2c_load instance
         */
        s2c_load.create = function create(properties) {
            return new s2c_load(properties);
        };

        /**
         * Encodes the specified s2c_load message. Does not implicitly {@link chest.s2c_load.verify|verify} messages.
         * @function encode
         * @memberof chest.s2c_load
         * @static
         * @param {chest.s2c_load} message s2c_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.heroId != null && Object.hasOwnProperty.call(message, "heroId"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.heroId);
            if (message.score != null && Object.hasOwnProperty.call(message, "score"))
                $root.chest.ScoreInfo.encode(message.score, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.heroIds != null && message.heroIds.length) {
                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                for (let i = 0; i < message.heroIds.length; ++i)
                    writer.uint32(message.heroIds[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a s2c_load message from the specified reader or buffer.
         * @function decode
         * @memberof chest.s2c_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chest.s2c_load} s2c_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chest.s2c_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        message.heroId = reader.uint32();
                        break;
                    }
                case 3: {
                        message.score = $root.chest.ScoreInfo.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        if (!(message.heroIds && message.heroIds.length))
                            message.heroIds = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.heroIds.push(reader.uint32());
                        } else
                            message.heroIds.push(reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_load
         * @function getTypeUrl
         * @memberof chest.s2c_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/chest.s2c_load";
        };

        return s2c_load;
    })();

    chest.c2s_open_chest = (function() {

        /**
         * Properties of a c2s_open_chest.
         * @memberof chest
         * @interface Ic2s_open_chest
         * @property {number|null} [id] c2s_open_chest id
         */

        /**
         * Constructs a new c2s_open_chest.
         * @memberof chest
         * @classdesc Represents a c2s_open_chest.
         * @implements Ic2s_open_chest
         * @constructor
         * @param {chest.Ic2s_open_chest=} [properties] Properties to set
         */
        function c2s_open_chest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_open_chest id.
         * @member {number} id
         * @memberof chest.c2s_open_chest
         * @instance
         */
        c2s_open_chest.prototype.id = 0;

        /**
         * Creates a new c2s_open_chest instance using the specified properties.
         * @function create
         * @memberof chest.c2s_open_chest
         * @static
         * @param {chest.Ic2s_open_chest=} [properties] Properties to set
         * @returns {chest.c2s_open_chest} c2s_open_chest instance
         */
        c2s_open_chest.create = function create(properties) {
            return new c2s_open_chest(properties);
        };

        /**
         * Encodes the specified c2s_open_chest message. Does not implicitly {@link chest.c2s_open_chest.verify|verify} messages.
         * @function encode
         * @memberof chest.c2s_open_chest
         * @static
         * @param {chest.c2s_open_chest} message c2s_open_chest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_open_chest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            return writer;
        };

        /**
         * Decodes a c2s_open_chest message from the specified reader or buffer.
         * @function decode
         * @memberof chest.c2s_open_chest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chest.c2s_open_chest} c2s_open_chest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_open_chest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chest.c2s_open_chest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_open_chest
         * @function getTypeUrl
         * @memberof chest.c2s_open_chest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_open_chest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/chest.c2s_open_chest";
        };

        return c2s_open_chest;
    })();

    chest.s2c_open_chest = (function() {

        /**
         * Properties of a s2c_open_chest.
         * @memberof chest
         * @interface Is2c_open_chest
         * @property {number|null} [err] s2c_open_chest err
         * @property {chest.ScoreInfo|null} [score] s2c_open_chest score
         * @property {Array.<bag.ItemReward>|null} [rewards] s2c_open_chest rewards
         */

        /**
         * Constructs a new s2c_open_chest.
         * @memberof chest
         * @classdesc Represents a s2c_open_chest.
         * @implements Is2c_open_chest
         * @constructor
         * @param {chest.Is2c_open_chest=} [properties] Properties to set
         */
        function s2c_open_chest(properties) {
            this.rewards = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_open_chest err.
         * @member {number} err
         * @memberof chest.s2c_open_chest
         * @instance
         */
        s2c_open_chest.prototype.err = 0;

        /**
         * s2c_open_chest score.
         * @member {chest.ScoreInfo|null|undefined} score
         * @memberof chest.s2c_open_chest
         * @instance
         */
        s2c_open_chest.prototype.score = null;

        /**
         * s2c_open_chest rewards.
         * @member {Array.<bag.ItemReward>} rewards
         * @memberof chest.s2c_open_chest
         * @instance
         */
        s2c_open_chest.prototype.rewards = $util.emptyArray;

        /**
         * Creates a new s2c_open_chest instance using the specified properties.
         * @function create
         * @memberof chest.s2c_open_chest
         * @static
         * @param {chest.Is2c_open_chest=} [properties] Properties to set
         * @returns {chest.s2c_open_chest} s2c_open_chest instance
         */
        s2c_open_chest.create = function create(properties) {
            return new s2c_open_chest(properties);
        };

        /**
         * Encodes the specified s2c_open_chest message. Does not implicitly {@link chest.s2c_open_chest.verify|verify} messages.
         * @function encode
         * @memberof chest.s2c_open_chest
         * @static
         * @param {chest.s2c_open_chest} message s2c_open_chest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_open_chest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.score != null && Object.hasOwnProperty.call(message, "score"))
                $root.chest.ScoreInfo.encode(message.score, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.rewards != null && message.rewards.length)
                for (let i = 0; i < message.rewards.length; ++i)
                    $root.bag.ItemReward.encode(message.rewards[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_open_chest message from the specified reader or buffer.
         * @function decode
         * @memberof chest.s2c_open_chest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chest.s2c_open_chest} s2c_open_chest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_open_chest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chest.s2c_open_chest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 3: {
                        message.score = $root.chest.ScoreInfo.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        if (!(message.rewards && message.rewards.length))
                            message.rewards = [];
                        message.rewards.push($root.bag.ItemReward.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_open_chest
         * @function getTypeUrl
         * @memberof chest.s2c_open_chest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_open_chest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/chest.s2c_open_chest";
        };

        return s2c_open_chest;
    })();

    chest.c2s_score_receive = (function() {

        /**
         * Properties of a c2s_score_receive.
         * @memberof chest
         * @interface Ic2s_score_receive
         */

        /**
         * Constructs a new c2s_score_receive.
         * @memberof chest
         * @classdesc Represents a c2s_score_receive.
         * @implements Ic2s_score_receive
         * @constructor
         * @param {chest.Ic2s_score_receive=} [properties] Properties to set
         */
        function c2s_score_receive(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_score_receive instance using the specified properties.
         * @function create
         * @memberof chest.c2s_score_receive
         * @static
         * @param {chest.Ic2s_score_receive=} [properties] Properties to set
         * @returns {chest.c2s_score_receive} c2s_score_receive instance
         */
        c2s_score_receive.create = function create(properties) {
            return new c2s_score_receive(properties);
        };

        /**
         * Encodes the specified c2s_score_receive message. Does not implicitly {@link chest.c2s_score_receive.verify|verify} messages.
         * @function encode
         * @memberof chest.c2s_score_receive
         * @static
         * @param {chest.c2s_score_receive} message c2s_score_receive message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_score_receive.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_score_receive message from the specified reader or buffer.
         * @function decode
         * @memberof chest.c2s_score_receive
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chest.c2s_score_receive} c2s_score_receive
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_score_receive.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chest.c2s_score_receive();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_score_receive
         * @function getTypeUrl
         * @memberof chest.c2s_score_receive
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_score_receive.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/chest.c2s_score_receive";
        };

        return c2s_score_receive;
    })();

    chest.s2c_score_receive = (function() {

        /**
         * Properties of a s2c_score_receive.
         * @memberof chest
         * @interface Is2c_score_receive
         * @property {number|null} [err] s2c_score_receive err
         * @property {chest.ScoreInfo|null} [score] s2c_score_receive score
         */

        /**
         * Constructs a new s2c_score_receive.
         * @memberof chest
         * @classdesc Represents a s2c_score_receive.
         * @implements Is2c_score_receive
         * @constructor
         * @param {chest.Is2c_score_receive=} [properties] Properties to set
         */
        function s2c_score_receive(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_score_receive err.
         * @member {number} err
         * @memberof chest.s2c_score_receive
         * @instance
         */
        s2c_score_receive.prototype.err = 0;

        /**
         * s2c_score_receive score.
         * @member {chest.ScoreInfo|null|undefined} score
         * @memberof chest.s2c_score_receive
         * @instance
         */
        s2c_score_receive.prototype.score = null;

        /**
         * Creates a new s2c_score_receive instance using the specified properties.
         * @function create
         * @memberof chest.s2c_score_receive
         * @static
         * @param {chest.Is2c_score_receive=} [properties] Properties to set
         * @returns {chest.s2c_score_receive} s2c_score_receive instance
         */
        s2c_score_receive.create = function create(properties) {
            return new s2c_score_receive(properties);
        };

        /**
         * Encodes the specified s2c_score_receive message. Does not implicitly {@link chest.s2c_score_receive.verify|verify} messages.
         * @function encode
         * @memberof chest.s2c_score_receive
         * @static
         * @param {chest.s2c_score_receive} message s2c_score_receive message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_score_receive.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.score != null && Object.hasOwnProperty.call(message, "score"))
                $root.chest.ScoreInfo.encode(message.score, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_score_receive message from the specified reader or buffer.
         * @function decode
         * @memberof chest.s2c_score_receive
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chest.s2c_score_receive} s2c_score_receive
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_score_receive.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chest.s2c_score_receive();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        message.score = $root.chest.ScoreInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_score_receive
         * @function getTypeUrl
         * @memberof chest.s2c_score_receive
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_score_receive.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/chest.s2c_score_receive";
        };

        return s2c_score_receive;
    })();

    chest.c2s_switch_hero = (function() {

        /**
         * Properties of a c2s_switch_hero.
         * @memberof chest
         * @interface Ic2s_switch_hero
         * @property {number|null} [heroId] c2s_switch_hero heroId
         */

        /**
         * Constructs a new c2s_switch_hero.
         * @memberof chest
         * @classdesc Represents a c2s_switch_hero.
         * @implements Ic2s_switch_hero
         * @constructor
         * @param {chest.Ic2s_switch_hero=} [properties] Properties to set
         */
        function c2s_switch_hero(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_switch_hero heroId.
         * @member {number} heroId
         * @memberof chest.c2s_switch_hero
         * @instance
         */
        c2s_switch_hero.prototype.heroId = 0;

        /**
         * Creates a new c2s_switch_hero instance using the specified properties.
         * @function create
         * @memberof chest.c2s_switch_hero
         * @static
         * @param {chest.Ic2s_switch_hero=} [properties] Properties to set
         * @returns {chest.c2s_switch_hero} c2s_switch_hero instance
         */
        c2s_switch_hero.create = function create(properties) {
            return new c2s_switch_hero(properties);
        };

        /**
         * Encodes the specified c2s_switch_hero message. Does not implicitly {@link chest.c2s_switch_hero.verify|verify} messages.
         * @function encode
         * @memberof chest.c2s_switch_hero
         * @static
         * @param {chest.c2s_switch_hero} message c2s_switch_hero message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_switch_hero.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.heroId != null && Object.hasOwnProperty.call(message, "heroId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.heroId);
            return writer;
        };

        /**
         * Decodes a c2s_switch_hero message from the specified reader or buffer.
         * @function decode
         * @memberof chest.c2s_switch_hero
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chest.c2s_switch_hero} c2s_switch_hero
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_switch_hero.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chest.c2s_switch_hero();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.heroId = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_switch_hero
         * @function getTypeUrl
         * @memberof chest.c2s_switch_hero
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_switch_hero.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/chest.c2s_switch_hero";
        };

        return c2s_switch_hero;
    })();

    chest.s2c_switch_hero = (function() {

        /**
         * Properties of a s2c_switch_hero.
         * @memberof chest
         * @interface Is2c_switch_hero
         * @property {number|null} [err] s2c_switch_hero err
         */

        /**
         * Constructs a new s2c_switch_hero.
         * @memberof chest
         * @classdesc Represents a s2c_switch_hero.
         * @implements Is2c_switch_hero
         * @constructor
         * @param {chest.Is2c_switch_hero=} [properties] Properties to set
         */
        function s2c_switch_hero(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_switch_hero err.
         * @member {number} err
         * @memberof chest.s2c_switch_hero
         * @instance
         */
        s2c_switch_hero.prototype.err = 0;

        /**
         * Creates a new s2c_switch_hero instance using the specified properties.
         * @function create
         * @memberof chest.s2c_switch_hero
         * @static
         * @param {chest.Is2c_switch_hero=} [properties] Properties to set
         * @returns {chest.s2c_switch_hero} s2c_switch_hero instance
         */
        s2c_switch_hero.create = function create(properties) {
            return new s2c_switch_hero(properties);
        };

        /**
         * Encodes the specified s2c_switch_hero message. Does not implicitly {@link chest.s2c_switch_hero.verify|verify} messages.
         * @function encode
         * @memberof chest.s2c_switch_hero
         * @static
         * @param {chest.s2c_switch_hero} message s2c_switch_hero message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_switch_hero.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_switch_hero message from the specified reader or buffer.
         * @function decode
         * @memberof chest.s2c_switch_hero
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chest.s2c_switch_hero} s2c_switch_hero
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_switch_hero.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chest.s2c_switch_hero();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_switch_hero
         * @function getTypeUrl
         * @memberof chest.s2c_switch_hero
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_switch_hero.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/chest.s2c_switch_hero";
        };

        return s2c_switch_hero;
    })();

    chest.notify_chest = (function() {

        /**
         * Properties of a notify_chest.
         * @memberof chest
         * @interface Inotify_chest
         * @property {chest.ScoreInfo|null} [score] notify_chest score
         */

        /**
         * Constructs a new notify_chest.
         * @memberof chest
         * @classdesc Represents a notify_chest.
         * @implements Inotify_chest
         * @constructor
         * @param {chest.Inotify_chest=} [properties] Properties to set
         */
        function notify_chest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_chest score.
         * @member {chest.ScoreInfo|null|undefined} score
         * @memberof chest.notify_chest
         * @instance
         */
        notify_chest.prototype.score = null;

        /**
         * Creates a new notify_chest instance using the specified properties.
         * @function create
         * @memberof chest.notify_chest
         * @static
         * @param {chest.Inotify_chest=} [properties] Properties to set
         * @returns {chest.notify_chest} notify_chest instance
         */
        notify_chest.create = function create(properties) {
            return new notify_chest(properties);
        };

        /**
         * Encodes the specified notify_chest message. Does not implicitly {@link chest.notify_chest.verify|verify} messages.
         * @function encode
         * @memberof chest.notify_chest
         * @static
         * @param {chest.notify_chest} message notify_chest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_chest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.score != null && Object.hasOwnProperty.call(message, "score"))
                $root.chest.ScoreInfo.encode(message.score, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a notify_chest message from the specified reader or buffer.
         * @function decode
         * @memberof chest.notify_chest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chest.notify_chest} notify_chest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_chest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chest.notify_chest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.score = $root.chest.ScoreInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_chest
         * @function getTypeUrl
         * @memberof chest.notify_chest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_chest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/chest.notify_chest";
        };

        return notify_chest;
    })();

    chest.notify_chest_hero = (function() {

        /**
         * Properties of a notify_chest_hero.
         * @memberof chest
         * @interface Inotify_chest_hero
         * @property {Array.<number>|null} [heroIds] notify_chest_hero heroIds
         */

        /**
         * Constructs a new notify_chest_hero.
         * @memberof chest
         * @classdesc Represents a notify_chest_hero.
         * @implements Inotify_chest_hero
         * @constructor
         * @param {chest.Inotify_chest_hero=} [properties] Properties to set
         */
        function notify_chest_hero(properties) {
            this.heroIds = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_chest_hero heroIds.
         * @member {Array.<number>} heroIds
         * @memberof chest.notify_chest_hero
         * @instance
         */
        notify_chest_hero.prototype.heroIds = $util.emptyArray;

        /**
         * Creates a new notify_chest_hero instance using the specified properties.
         * @function create
         * @memberof chest.notify_chest_hero
         * @static
         * @param {chest.Inotify_chest_hero=} [properties] Properties to set
         * @returns {chest.notify_chest_hero} notify_chest_hero instance
         */
        notify_chest_hero.create = function create(properties) {
            return new notify_chest_hero(properties);
        };

        /**
         * Encodes the specified notify_chest_hero message. Does not implicitly {@link chest.notify_chest_hero.verify|verify} messages.
         * @function encode
         * @memberof chest.notify_chest_hero
         * @static
         * @param {chest.notify_chest_hero} message notify_chest_hero message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_chest_hero.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.heroIds != null && message.heroIds.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (let i = 0; i < message.heroIds.length; ++i)
                    writer.uint32(message.heroIds[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a notify_chest_hero message from the specified reader or buffer.
         * @function decode
         * @memberof chest.notify_chest_hero
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chest.notify_chest_hero} notify_chest_hero
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_chest_hero.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chest.notify_chest_hero();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.heroIds && message.heroIds.length))
                            message.heroIds = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.heroIds.push(reader.uint32());
                        } else
                            message.heroIds.push(reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_chest_hero
         * @function getTypeUrl
         * @memberof chest.notify_chest_hero
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_chest_hero.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/chest.notify_chest_hero";
        };

        return notify_chest_hero;
    })();

    return chest;
})();

export const hero = $root.hero = (() => {

    /**
     * Namespace hero.
     * @exports hero
     * @namespace
     */
    const hero = {};

    hero.SkillInfo = (function() {

        /**
         * Properties of a SkillInfo.
         * @memberof hero
         * @interface ISkillInfo
         * @property {number|null} [id] SkillInfo id
         * @property {number|null} [lv] SkillInfo lv
         */

        /**
         * Constructs a new SkillInfo.
         * @memberof hero
         * @classdesc Represents a SkillInfo.
         * @implements ISkillInfo
         * @constructor
         * @param {hero.ISkillInfo=} [properties] Properties to set
         */
        function SkillInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SkillInfo id.
         * @member {number} id
         * @memberof hero.SkillInfo
         * @instance
         */
        SkillInfo.prototype.id = 0;

        /**
         * SkillInfo lv.
         * @member {number} lv
         * @memberof hero.SkillInfo
         * @instance
         */
        SkillInfo.prototype.lv = 0;

        /**
         * Creates a new SkillInfo instance using the specified properties.
         * @function create
         * @memberof hero.SkillInfo
         * @static
         * @param {hero.ISkillInfo=} [properties] Properties to set
         * @returns {hero.SkillInfo} SkillInfo instance
         */
        SkillInfo.create = function create(properties) {
            return new SkillInfo(properties);
        };

        /**
         * Encodes the specified SkillInfo message. Does not implicitly {@link hero.SkillInfo.verify|verify} messages.
         * @function encode
         * @memberof hero.SkillInfo
         * @static
         * @param {hero.SkillInfo} message SkillInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkillInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            if (message.lv != null && Object.hasOwnProperty.call(message, "lv"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.lv);
            return writer;
        };

        /**
         * Decodes a SkillInfo message from the specified reader or buffer.
         * @function decode
         * @memberof hero.SkillInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.SkillInfo} SkillInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkillInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.SkillInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 2: {
                        message.lv = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for SkillInfo
         * @function getTypeUrl
         * @memberof hero.SkillInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SkillInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.SkillInfo";
        };

        return SkillInfo;
    })();

    hero.HeroMaterial = (function() {

        /**
         * Properties of a HeroMaterial.
         * @memberof hero
         * @interface IHeroMaterial
         * @property {number|null} [id] HeroMaterial id
         * @property {number|null} [star] HeroMaterial star
         * @property {number|null} [cnt] HeroMaterial cnt
         */

        /**
         * Constructs a new HeroMaterial.
         * @memberof hero
         * @classdesc Represents a HeroMaterial.
         * @implements IHeroMaterial
         * @constructor
         * @param {hero.IHeroMaterial=} [properties] Properties to set
         */
        function HeroMaterial(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HeroMaterial id.
         * @member {number} id
         * @memberof hero.HeroMaterial
         * @instance
         */
        HeroMaterial.prototype.id = 0;

        /**
         * HeroMaterial star.
         * @member {number} star
         * @memberof hero.HeroMaterial
         * @instance
         */
        HeroMaterial.prototype.star = 0;

        /**
         * HeroMaterial cnt.
         * @member {number} cnt
         * @memberof hero.HeroMaterial
         * @instance
         */
        HeroMaterial.prototype.cnt = 0;

        /**
         * Creates a new HeroMaterial instance using the specified properties.
         * @function create
         * @memberof hero.HeroMaterial
         * @static
         * @param {hero.IHeroMaterial=} [properties] Properties to set
         * @returns {hero.HeroMaterial} HeroMaterial instance
         */
        HeroMaterial.create = function create(properties) {
            return new HeroMaterial(properties);
        };

        /**
         * Encodes the specified HeroMaterial message. Does not implicitly {@link hero.HeroMaterial.verify|verify} messages.
         * @function encode
         * @memberof hero.HeroMaterial
         * @static
         * @param {hero.HeroMaterial} message HeroMaterial message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HeroMaterial.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            if (message.star != null && Object.hasOwnProperty.call(message, "star"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.star);
            if (message.cnt != null && Object.hasOwnProperty.call(message, "cnt"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.cnt);
            return writer;
        };

        /**
         * Decodes a HeroMaterial message from the specified reader or buffer.
         * @function decode
         * @memberof hero.HeroMaterial
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.HeroMaterial} HeroMaterial
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HeroMaterial.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.HeroMaterial();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 2: {
                        message.star = reader.uint32();
                        break;
                    }
                case 3: {
                        message.cnt = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for HeroMaterial
         * @function getTypeUrl
         * @memberof hero.HeroMaterial
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        HeroMaterial.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.HeroMaterial";
        };

        return HeroMaterial;
    })();

    hero.Hero = (function() {

        /**
         * Properties of a Hero.
         * @memberof hero
         * @interface IHero
         * @property {number|null} [uid] Hero uid
         * @property {number|null} [id] Hero id
         * @property {number|null} [star] Hero star
         * @property {number|null} [lv] Hero lv
         * @property {boolean|null} [isLocked] Hero isLocked
         * @property {Object.<string,number>|null} [attrs] Hero attrs
         * @property {number|null} [stage] Hero stage
         * @property {Array.<hero.HeroMaterial>|null} [heroMaterials] Hero heroMaterials
         * @property {Object.<string,hero.SkillInfo>|null} [skills] Hero skills
         */

        /**
         * Constructs a new Hero.
         * @memberof hero
         * @classdesc Represents a Hero.
         * @implements IHero
         * @constructor
         * @param {hero.IHero=} [properties] Properties to set
         */
        function Hero(properties) {
            this.attrs = {};
            this.heroMaterials = [];
            this.skills = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Hero uid.
         * @member {number} uid
         * @memberof hero.Hero
         * @instance
         */
        Hero.prototype.uid = 0;

        /**
         * Hero id.
         * @member {number} id
         * @memberof hero.Hero
         * @instance
         */
        Hero.prototype.id = 0;

        /**
         * Hero star.
         * @member {number} star
         * @memberof hero.Hero
         * @instance
         */
        Hero.prototype.star = 0;

        /**
         * Hero lv.
         * @member {number} lv
         * @memberof hero.Hero
         * @instance
         */
        Hero.prototype.lv = 0;

        /**
         * Hero isLocked.
         * @member {boolean} isLocked
         * @memberof hero.Hero
         * @instance
         */
        Hero.prototype.isLocked = false;

        /**
         * Hero attrs.
         * @member {Object.<string,number>} attrs
         * @memberof hero.Hero
         * @instance
         */
        Hero.prototype.attrs = $util.emptyObject;

        /**
         * Hero stage.
         * @member {number} stage
         * @memberof hero.Hero
         * @instance
         */
        Hero.prototype.stage = 0;

        /**
         * Hero heroMaterials.
         * @member {Array.<hero.HeroMaterial>} heroMaterials
         * @memberof hero.Hero
         * @instance
         */
        Hero.prototype.heroMaterials = $util.emptyArray;

        /**
         * Hero skills.
         * @member {Object.<string,hero.SkillInfo>} skills
         * @memberof hero.Hero
         * @instance
         */
        Hero.prototype.skills = $util.emptyObject;

        /**
         * Creates a new Hero instance using the specified properties.
         * @function create
         * @memberof hero.Hero
         * @static
         * @param {hero.IHero=} [properties] Properties to set
         * @returns {hero.Hero} Hero instance
         */
        Hero.create = function create(properties) {
            return new Hero(properties);
        };

        /**
         * Encodes the specified Hero message. Does not implicitly {@link hero.Hero.verify|verify} messages.
         * @function encode
         * @memberof hero.Hero
         * @static
         * @param {hero.Hero} message Hero message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Hero.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.uid);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.id);
            if (message.star != null && Object.hasOwnProperty.call(message, "star"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.star);
            if (message.lv != null && Object.hasOwnProperty.call(message, "lv"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.lv);
            if (message.isLocked != null && Object.hasOwnProperty.call(message, "isLocked"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isLocked);
            if (message.attrs != null && Object.hasOwnProperty.call(message, "attrs"))
                for (let keys = Object.keys(message.attrs), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 6, wireType 2 =*/50).fork().uint32(/* id 1, wireType 0 =*/8).uint32(keys[i]).uint32(/* id 2, wireType 0 =*/16).uint32(message.attrs[keys[i]]).ldelim();
            if (message.stage != null && Object.hasOwnProperty.call(message, "stage"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.stage);
            if (message.heroMaterials != null && message.heroMaterials.length)
                for (let i = 0; i < message.heroMaterials.length; ++i)
                    $root.hero.HeroMaterial.encode(message.heroMaterials[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.skills != null && Object.hasOwnProperty.call(message, "skills"))
                for (let keys = Object.keys(message.skills), i = 0; i < keys.length; ++i) {
                    writer.uint32(/* id 9, wireType 2 =*/74).fork().uint32(/* id 1, wireType 0 =*/8).uint32(keys[i]);
                    $root.hero.SkillInfo.encode(message.skills[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                }
            return writer;
        };

        /**
         * Decodes a Hero message from the specified reader or buffer.
         * @function decode
         * @memberof hero.Hero
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.Hero} Hero
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Hero.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.Hero(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.uid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.id = reader.uint32();
                        break;
                    }
                case 3: {
                        message.star = reader.uint32();
                        break;
                    }
                case 4: {
                        message.lv = reader.uint32();
                        break;
                    }
                case 5: {
                        message.isLocked = reader.bool();
                        break;
                    }
                case 6: {
                        if (message.attrs === $util.emptyObject)
                            message.attrs = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = 0;
                        value = 0;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.uint32();
                                break;
                            case 2:
                                value = reader.uint32();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.attrs[key] = value;
                        break;
                    }
                case 7: {
                        message.stage = reader.uint32();
                        break;
                    }
                case 8: {
                        if (!(message.heroMaterials && message.heroMaterials.length))
                            message.heroMaterials = [];
                        message.heroMaterials.push($root.hero.HeroMaterial.decode(reader, reader.uint32()));
                        break;
                    }
                case 9: {
                        if (message.skills === $util.emptyObject)
                            message.skills = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = 0;
                        value = null;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.uint32();
                                break;
                            case 2:
                                value = $root.hero.SkillInfo.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.skills[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for Hero
         * @function getTypeUrl
         * @memberof hero.Hero
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Hero.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.Hero";
        };

        return Hero;
    })();

    hero.HeroHandbookItem = (function() {

        /**
         * Properties of a HeroHandbookItem.
         * @memberof hero
         * @interface IHeroHandbookItem
         * @property {number|null} [id] HeroHandbookItem id
         * @property {number|null} [star] HeroHandbookItem star
         * @property {number|null} [awardedStar] HeroHandbookItem awardedStar
         */

        /**
         * Constructs a new HeroHandbookItem.
         * @memberof hero
         * @classdesc Represents a HeroHandbookItem.
         * @implements IHeroHandbookItem
         * @constructor
         * @param {hero.IHeroHandbookItem=} [properties] Properties to set
         */
        function HeroHandbookItem(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HeroHandbookItem id.
         * @member {number} id
         * @memberof hero.HeroHandbookItem
         * @instance
         */
        HeroHandbookItem.prototype.id = 0;

        /**
         * HeroHandbookItem star.
         * @member {number} star
         * @memberof hero.HeroHandbookItem
         * @instance
         */
        HeroHandbookItem.prototype.star = 0;

        /**
         * HeroHandbookItem awardedStar.
         * @member {number} awardedStar
         * @memberof hero.HeroHandbookItem
         * @instance
         */
        HeroHandbookItem.prototype.awardedStar = 0;

        /**
         * Creates a new HeroHandbookItem instance using the specified properties.
         * @function create
         * @memberof hero.HeroHandbookItem
         * @static
         * @param {hero.IHeroHandbookItem=} [properties] Properties to set
         * @returns {hero.HeroHandbookItem} HeroHandbookItem instance
         */
        HeroHandbookItem.create = function create(properties) {
            return new HeroHandbookItem(properties);
        };

        /**
         * Encodes the specified HeroHandbookItem message. Does not implicitly {@link hero.HeroHandbookItem.verify|verify} messages.
         * @function encode
         * @memberof hero.HeroHandbookItem
         * @static
         * @param {hero.HeroHandbookItem} message HeroHandbookItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HeroHandbookItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            if (message.star != null && Object.hasOwnProperty.call(message, "star"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.star);
            if (message.awardedStar != null && Object.hasOwnProperty.call(message, "awardedStar"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.awardedStar);
            return writer;
        };

        /**
         * Decodes a HeroHandbookItem message from the specified reader or buffer.
         * @function decode
         * @memberof hero.HeroHandbookItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.HeroHandbookItem} HeroHandbookItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HeroHandbookItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.HeroHandbookItem();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 2: {
                        message.star = reader.uint32();
                        break;
                    }
                case 3: {
                        message.awardedStar = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for HeroHandbookItem
         * @function getTypeUrl
         * @memberof hero.HeroHandbookItem
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        HeroHandbookItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.HeroHandbookItem";
        };

        return HeroHandbookItem;
    })();

    hero.CommonInfo = (function() {

        /**
         * Properties of a CommonInfo.
         * @memberof hero
         * @interface ICommonInfo
         * @property {number|null} [fame] CommonInfo fame
         * @property {number|null} [receivedFameStage] CommonInfo receivedFameStage
         */

        /**
         * Constructs a new CommonInfo.
         * @memberof hero
         * @classdesc Represents a CommonInfo.
         * @implements ICommonInfo
         * @constructor
         * @param {hero.ICommonInfo=} [properties] Properties to set
         */
        function CommonInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CommonInfo fame.
         * @member {number} fame
         * @memberof hero.CommonInfo
         * @instance
         */
        CommonInfo.prototype.fame = 0;

        /**
         * CommonInfo receivedFameStage.
         * @member {number} receivedFameStage
         * @memberof hero.CommonInfo
         * @instance
         */
        CommonInfo.prototype.receivedFameStage = 0;

        /**
         * Creates a new CommonInfo instance using the specified properties.
         * @function create
         * @memberof hero.CommonInfo
         * @static
         * @param {hero.ICommonInfo=} [properties] Properties to set
         * @returns {hero.CommonInfo} CommonInfo instance
         */
        CommonInfo.create = function create(properties) {
            return new CommonInfo(properties);
        };

        /**
         * Encodes the specified CommonInfo message. Does not implicitly {@link hero.CommonInfo.verify|verify} messages.
         * @function encode
         * @memberof hero.CommonInfo
         * @static
         * @param {hero.CommonInfo} message CommonInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommonInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fame != null && Object.hasOwnProperty.call(message, "fame"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.fame);
            if (message.receivedFameStage != null && Object.hasOwnProperty.call(message, "receivedFameStage"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.receivedFameStage);
            return writer;
        };

        /**
         * Decodes a CommonInfo message from the specified reader or buffer.
         * @function decode
         * @memberof hero.CommonInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.CommonInfo} CommonInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommonInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.CommonInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fame = reader.uint32();
                        break;
                    }
                case 2: {
                        message.receivedFameStage = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for CommonInfo
         * @function getTypeUrl
         * @memberof hero.CommonInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CommonInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.CommonInfo";
        };

        return CommonInfo;
    })();

    hero.c2s_load = (function() {

        /**
         * Properties of a c2s_load.
         * @memberof hero
         * @interface Ic2s_load
         */

        /**
         * Constructs a new c2s_load.
         * @memberof hero
         * @classdesc Represents a c2s_load.
         * @implements Ic2s_load
         * @constructor
         * @param {hero.Ic2s_load=} [properties] Properties to set
         */
        function c2s_load(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_load instance using the specified properties.
         * @function create
         * @memberof hero.c2s_load
         * @static
         * @param {hero.Ic2s_load=} [properties] Properties to set
         * @returns {hero.c2s_load} c2s_load instance
         */
        c2s_load.create = function create(properties) {
            return new c2s_load(properties);
        };

        /**
         * Encodes the specified c2s_load message. Does not implicitly {@link hero.c2s_load.verify|verify} messages.
         * @function encode
         * @memberof hero.c2s_load
         * @static
         * @param {hero.c2s_load} message c2s_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_load message from the specified reader or buffer.
         * @function decode
         * @memberof hero.c2s_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.c2s_load} c2s_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.c2s_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_load
         * @function getTypeUrl
         * @memberof hero.c2s_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.c2s_load";
        };

        return c2s_load;
    })();

    hero.s2c_load = (function() {

        /**
         * Properties of a s2c_load.
         * @memberof hero
         * @interface Is2c_load
         * @property {number|null} [err] s2c_load err
         * @property {Array.<hero.Hero>|null} [heroList] s2c_load heroList
         * @property {Array.<hero.HeroHandbookItem>|null} [heroHandbook] s2c_load heroHandbook
         * @property {hero.CommonInfo|null} [commonInfo] s2c_load commonInfo
         */

        /**
         * Constructs a new s2c_load.
         * @memberof hero
         * @classdesc Represents a s2c_load.
         * @implements Is2c_load
         * @constructor
         * @param {hero.Is2c_load=} [properties] Properties to set
         */
        function s2c_load(properties) {
            this.heroList = [];
            this.heroHandbook = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_load err.
         * @member {number} err
         * @memberof hero.s2c_load
         * @instance
         */
        s2c_load.prototype.err = 0;

        /**
         * s2c_load heroList.
         * @member {Array.<hero.Hero>} heroList
         * @memberof hero.s2c_load
         * @instance
         */
        s2c_load.prototype.heroList = $util.emptyArray;

        /**
         * s2c_load heroHandbook.
         * @member {Array.<hero.HeroHandbookItem>} heroHandbook
         * @memberof hero.s2c_load
         * @instance
         */
        s2c_load.prototype.heroHandbook = $util.emptyArray;

        /**
         * s2c_load commonInfo.
         * @member {hero.CommonInfo|null|undefined} commonInfo
         * @memberof hero.s2c_load
         * @instance
         */
        s2c_load.prototype.commonInfo = null;

        /**
         * Creates a new s2c_load instance using the specified properties.
         * @function create
         * @memberof hero.s2c_load
         * @static
         * @param {hero.Is2c_load=} [properties] Properties to set
         * @returns {hero.s2c_load} s2c_load instance
         */
        s2c_load.create = function create(properties) {
            return new s2c_load(properties);
        };

        /**
         * Encodes the specified s2c_load message. Does not implicitly {@link hero.s2c_load.verify|verify} messages.
         * @function encode
         * @memberof hero.s2c_load
         * @static
         * @param {hero.s2c_load} message s2c_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.heroList != null && message.heroList.length)
                for (let i = 0; i < message.heroList.length; ++i)
                    $root.hero.Hero.encode(message.heroList[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.heroHandbook != null && message.heroHandbook.length)
                for (let i = 0; i < message.heroHandbook.length; ++i)
                    $root.hero.HeroHandbookItem.encode(message.heroHandbook[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.commonInfo != null && Object.hasOwnProperty.call(message, "commonInfo"))
                $root.hero.CommonInfo.encode(message.commonInfo, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_load message from the specified reader or buffer.
         * @function decode
         * @memberof hero.s2c_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.s2c_load} s2c_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.s2c_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.heroList && message.heroList.length))
                            message.heroList = [];
                        message.heroList.push($root.hero.Hero.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        if (!(message.heroHandbook && message.heroHandbook.length))
                            message.heroHandbook = [];
                        message.heroHandbook.push($root.hero.HeroHandbookItem.decode(reader, reader.uint32()));
                        break;
                    }
                case 4: {
                        message.commonInfo = $root.hero.CommonInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_load
         * @function getTypeUrl
         * @memberof hero.s2c_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.s2c_load";
        };

        return s2c_load;
    })();

    hero.HeroItem = (function() {

        /**
         * Properties of a HeroItem.
         * @memberof hero
         * @interface IHeroItem
         * @property {number|null} [id] HeroItem id
         * @property {number|null} [num] HeroItem num
         */

        /**
         * Constructs a new HeroItem.
         * @memberof hero
         * @classdesc Represents a HeroItem.
         * @implements IHeroItem
         * @constructor
         * @param {hero.IHeroItem=} [properties] Properties to set
         */
        function HeroItem(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HeroItem id.
         * @member {number} id
         * @memberof hero.HeroItem
         * @instance
         */
        HeroItem.prototype.id = 0;

        /**
         * HeroItem num.
         * @member {number} num
         * @memberof hero.HeroItem
         * @instance
         */
        HeroItem.prototype.num = 0;

        /**
         * Creates a new HeroItem instance using the specified properties.
         * @function create
         * @memberof hero.HeroItem
         * @static
         * @param {hero.IHeroItem=} [properties] Properties to set
         * @returns {hero.HeroItem} HeroItem instance
         */
        HeroItem.create = function create(properties) {
            return new HeroItem(properties);
        };

        /**
         * Encodes the specified HeroItem message. Does not implicitly {@link hero.HeroItem.verify|verify} messages.
         * @function encode
         * @memberof hero.HeroItem
         * @static
         * @param {hero.HeroItem} message HeroItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HeroItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            if (message.num != null && Object.hasOwnProperty.call(message, "num"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.num);
            return writer;
        };

        /**
         * Decodes a HeroItem message from the specified reader or buffer.
         * @function decode
         * @memberof hero.HeroItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.HeroItem} HeroItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HeroItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.HeroItem();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 2: {
                        message.num = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for HeroItem
         * @function getTypeUrl
         * @memberof hero.HeroItem
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        HeroItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.HeroItem";
        };

        return HeroItem;
    })();

    hero.c2s_upgrade_star = (function() {

        /**
         * Properties of a c2s_upgrade_star.
         * @memberof hero
         * @interface Ic2s_upgrade_star
         * @property {number|null} [uid] c2s_upgrade_star uid
         * @property {boolean|null} [useStone] c2s_upgrade_star useStone
         * @property {Array.<hero.HeroItem>|null} [otherHeroItems] c2s_upgrade_star otherHeroItems
         * @property {Array.<number>|null} [materialHeroUids] c2s_upgrade_star materialHeroUids
         */

        /**
         * Constructs a new c2s_upgrade_star.
         * @memberof hero
         * @classdesc Represents a c2s_upgrade_star.
         * @implements Ic2s_upgrade_star
         * @constructor
         * @param {hero.Ic2s_upgrade_star=} [properties] Properties to set
         */
        function c2s_upgrade_star(properties) {
            this.otherHeroItems = [];
            this.materialHeroUids = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_upgrade_star uid.
         * @member {number} uid
         * @memberof hero.c2s_upgrade_star
         * @instance
         */
        c2s_upgrade_star.prototype.uid = 0;

        /**
         * c2s_upgrade_star useStone.
         * @member {boolean} useStone
         * @memberof hero.c2s_upgrade_star
         * @instance
         */
        c2s_upgrade_star.prototype.useStone = false;

        /**
         * c2s_upgrade_star otherHeroItems.
         * @member {Array.<hero.HeroItem>} otherHeroItems
         * @memberof hero.c2s_upgrade_star
         * @instance
         */
        c2s_upgrade_star.prototype.otherHeroItems = $util.emptyArray;

        /**
         * c2s_upgrade_star materialHeroUids.
         * @member {Array.<number>} materialHeroUids
         * @memberof hero.c2s_upgrade_star
         * @instance
         */
        c2s_upgrade_star.prototype.materialHeroUids = $util.emptyArray;

        /**
         * Creates a new c2s_upgrade_star instance using the specified properties.
         * @function create
         * @memberof hero.c2s_upgrade_star
         * @static
         * @param {hero.Ic2s_upgrade_star=} [properties] Properties to set
         * @returns {hero.c2s_upgrade_star} c2s_upgrade_star instance
         */
        c2s_upgrade_star.create = function create(properties) {
            return new c2s_upgrade_star(properties);
        };

        /**
         * Encodes the specified c2s_upgrade_star message. Does not implicitly {@link hero.c2s_upgrade_star.verify|verify} messages.
         * @function encode
         * @memberof hero.c2s_upgrade_star
         * @static
         * @param {hero.c2s_upgrade_star} message c2s_upgrade_star message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_upgrade_star.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.uid);
            if (message.useStone != null && Object.hasOwnProperty.call(message, "useStone"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.useStone);
            if (message.otherHeroItems != null && message.otherHeroItems.length)
                for (let i = 0; i < message.otherHeroItems.length; ++i)
                    $root.hero.HeroItem.encode(message.otherHeroItems[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.materialHeroUids != null && message.materialHeroUids.length) {
                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                for (let i = 0; i < message.materialHeroUids.length; ++i)
                    writer.uint32(message.materialHeroUids[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a c2s_upgrade_star message from the specified reader or buffer.
         * @function decode
         * @memberof hero.c2s_upgrade_star
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.c2s_upgrade_star} c2s_upgrade_star
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_upgrade_star.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.c2s_upgrade_star();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.uid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.useStone = reader.bool();
                        break;
                    }
                case 3: {
                        if (!(message.otherHeroItems && message.otherHeroItems.length))
                            message.otherHeroItems = [];
                        message.otherHeroItems.push($root.hero.HeroItem.decode(reader, reader.uint32()));
                        break;
                    }
                case 4: {
                        if (!(message.materialHeroUids && message.materialHeroUids.length))
                            message.materialHeroUids = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.materialHeroUids.push(reader.uint32());
                        } else
                            message.materialHeroUids.push(reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_upgrade_star
         * @function getTypeUrl
         * @memberof hero.c2s_upgrade_star
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_upgrade_star.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.c2s_upgrade_star";
        };

        return c2s_upgrade_star;
    })();

    hero.s2c_upgrade_star = (function() {

        /**
         * Properties of a s2c_upgrade_star.
         * @memberof hero
         * @interface Is2c_upgrade_star
         * @property {number|null} [err] s2c_upgrade_star err
         * @property {Array.<bag.ItemReward>|null} [rewards] s2c_upgrade_star rewards
         */

        /**
         * Constructs a new s2c_upgrade_star.
         * @memberof hero
         * @classdesc Represents a s2c_upgrade_star.
         * @implements Is2c_upgrade_star
         * @constructor
         * @param {hero.Is2c_upgrade_star=} [properties] Properties to set
         */
        function s2c_upgrade_star(properties) {
            this.rewards = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_upgrade_star err.
         * @member {number} err
         * @memberof hero.s2c_upgrade_star
         * @instance
         */
        s2c_upgrade_star.prototype.err = 0;

        /**
         * s2c_upgrade_star rewards.
         * @member {Array.<bag.ItemReward>} rewards
         * @memberof hero.s2c_upgrade_star
         * @instance
         */
        s2c_upgrade_star.prototype.rewards = $util.emptyArray;

        /**
         * Creates a new s2c_upgrade_star instance using the specified properties.
         * @function create
         * @memberof hero.s2c_upgrade_star
         * @static
         * @param {hero.Is2c_upgrade_star=} [properties] Properties to set
         * @returns {hero.s2c_upgrade_star} s2c_upgrade_star instance
         */
        s2c_upgrade_star.create = function create(properties) {
            return new s2c_upgrade_star(properties);
        };

        /**
         * Encodes the specified s2c_upgrade_star message. Does not implicitly {@link hero.s2c_upgrade_star.verify|verify} messages.
         * @function encode
         * @memberof hero.s2c_upgrade_star
         * @static
         * @param {hero.s2c_upgrade_star} message s2c_upgrade_star message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_upgrade_star.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.rewards != null && message.rewards.length)
                for (let i = 0; i < message.rewards.length; ++i)
                    $root.bag.ItemReward.encode(message.rewards[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_upgrade_star message from the specified reader or buffer.
         * @function decode
         * @memberof hero.s2c_upgrade_star
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.s2c_upgrade_star} s2c_upgrade_star
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_upgrade_star.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.s2c_upgrade_star();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.rewards && message.rewards.length))
                            message.rewards = [];
                        message.rewards.push($root.bag.ItemReward.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_upgrade_star
         * @function getTypeUrl
         * @memberof hero.s2c_upgrade_star
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_upgrade_star.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.s2c_upgrade_star";
        };

        return s2c_upgrade_star;
    })();

    hero.c2s_upgrade_level = (function() {

        /**
         * Properties of a c2s_upgrade_level.
         * @memberof hero
         * @interface Ic2s_upgrade_level
         * @property {number|null} [uid] c2s_upgrade_level uid
         * @property {number|null} [destLv] c2s_upgrade_level destLv
         */

        /**
         * Constructs a new c2s_upgrade_level.
         * @memberof hero
         * @classdesc Represents a c2s_upgrade_level.
         * @implements Ic2s_upgrade_level
         * @constructor
         * @param {hero.Ic2s_upgrade_level=} [properties] Properties to set
         */
        function c2s_upgrade_level(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_upgrade_level uid.
         * @member {number} uid
         * @memberof hero.c2s_upgrade_level
         * @instance
         */
        c2s_upgrade_level.prototype.uid = 0;

        /**
         * c2s_upgrade_level destLv.
         * @member {number} destLv
         * @memberof hero.c2s_upgrade_level
         * @instance
         */
        c2s_upgrade_level.prototype.destLv = 0;

        /**
         * Creates a new c2s_upgrade_level instance using the specified properties.
         * @function create
         * @memberof hero.c2s_upgrade_level
         * @static
         * @param {hero.Ic2s_upgrade_level=} [properties] Properties to set
         * @returns {hero.c2s_upgrade_level} c2s_upgrade_level instance
         */
        c2s_upgrade_level.create = function create(properties) {
            return new c2s_upgrade_level(properties);
        };

        /**
         * Encodes the specified c2s_upgrade_level message. Does not implicitly {@link hero.c2s_upgrade_level.verify|verify} messages.
         * @function encode
         * @memberof hero.c2s_upgrade_level
         * @static
         * @param {hero.c2s_upgrade_level} message c2s_upgrade_level message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_upgrade_level.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.uid);
            if (message.destLv != null && Object.hasOwnProperty.call(message, "destLv"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.destLv);
            return writer;
        };

        /**
         * Decodes a c2s_upgrade_level message from the specified reader or buffer.
         * @function decode
         * @memberof hero.c2s_upgrade_level
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.c2s_upgrade_level} c2s_upgrade_level
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_upgrade_level.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.c2s_upgrade_level();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.uid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.destLv = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_upgrade_level
         * @function getTypeUrl
         * @memberof hero.c2s_upgrade_level
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_upgrade_level.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.c2s_upgrade_level";
        };

        return c2s_upgrade_level;
    })();

    hero.s2c_upgrade_level = (function() {

        /**
         * Properties of a s2c_upgrade_level.
         * @memberof hero
         * @interface Is2c_upgrade_level
         * @property {number|null} [err] s2c_upgrade_level err
         */

        /**
         * Constructs a new s2c_upgrade_level.
         * @memberof hero
         * @classdesc Represents a s2c_upgrade_level.
         * @implements Is2c_upgrade_level
         * @constructor
         * @param {hero.Is2c_upgrade_level=} [properties] Properties to set
         */
        function s2c_upgrade_level(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_upgrade_level err.
         * @member {number} err
         * @memberof hero.s2c_upgrade_level
         * @instance
         */
        s2c_upgrade_level.prototype.err = 0;

        /**
         * Creates a new s2c_upgrade_level instance using the specified properties.
         * @function create
         * @memberof hero.s2c_upgrade_level
         * @static
         * @param {hero.Is2c_upgrade_level=} [properties] Properties to set
         * @returns {hero.s2c_upgrade_level} s2c_upgrade_level instance
         */
        s2c_upgrade_level.create = function create(properties) {
            return new s2c_upgrade_level(properties);
        };

        /**
         * Encodes the specified s2c_upgrade_level message. Does not implicitly {@link hero.s2c_upgrade_level.verify|verify} messages.
         * @function encode
         * @memberof hero.s2c_upgrade_level
         * @static
         * @param {hero.s2c_upgrade_level} message s2c_upgrade_level message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_upgrade_level.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_upgrade_level message from the specified reader or buffer.
         * @function decode
         * @memberof hero.s2c_upgrade_level
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.s2c_upgrade_level} s2c_upgrade_level
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_upgrade_level.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.s2c_upgrade_level();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_upgrade_level
         * @function getTypeUrl
         * @memberof hero.s2c_upgrade_level
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_upgrade_level.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.s2c_upgrade_level";
        };

        return s2c_upgrade_level;
    })();

    hero.c2s_switch_lock_hero = (function() {

        /**
         * Properties of a c2s_switch_lock_hero.
         * @memberof hero
         * @interface Ic2s_switch_lock_hero
         * @property {number|null} [uid] c2s_switch_lock_hero uid
         */

        /**
         * Constructs a new c2s_switch_lock_hero.
         * @memberof hero
         * @classdesc Represents a c2s_switch_lock_hero.
         * @implements Ic2s_switch_lock_hero
         * @constructor
         * @param {hero.Ic2s_switch_lock_hero=} [properties] Properties to set
         */
        function c2s_switch_lock_hero(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_switch_lock_hero uid.
         * @member {number} uid
         * @memberof hero.c2s_switch_lock_hero
         * @instance
         */
        c2s_switch_lock_hero.prototype.uid = 0;

        /**
         * Creates a new c2s_switch_lock_hero instance using the specified properties.
         * @function create
         * @memberof hero.c2s_switch_lock_hero
         * @static
         * @param {hero.Ic2s_switch_lock_hero=} [properties] Properties to set
         * @returns {hero.c2s_switch_lock_hero} c2s_switch_lock_hero instance
         */
        c2s_switch_lock_hero.create = function create(properties) {
            return new c2s_switch_lock_hero(properties);
        };

        /**
         * Encodes the specified c2s_switch_lock_hero message. Does not implicitly {@link hero.c2s_switch_lock_hero.verify|verify} messages.
         * @function encode
         * @memberof hero.c2s_switch_lock_hero
         * @static
         * @param {hero.c2s_switch_lock_hero} message c2s_switch_lock_hero message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_switch_lock_hero.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.uid);
            return writer;
        };

        /**
         * Decodes a c2s_switch_lock_hero message from the specified reader or buffer.
         * @function decode
         * @memberof hero.c2s_switch_lock_hero
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.c2s_switch_lock_hero} c2s_switch_lock_hero
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_switch_lock_hero.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.c2s_switch_lock_hero();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.uid = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_switch_lock_hero
         * @function getTypeUrl
         * @memberof hero.c2s_switch_lock_hero
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_switch_lock_hero.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.c2s_switch_lock_hero";
        };

        return c2s_switch_lock_hero;
    })();

    hero.s2c_switch_lock_hero = (function() {

        /**
         * Properties of a s2c_switch_lock_hero.
         * @memberof hero
         * @interface Is2c_switch_lock_hero
         * @property {number|null} [err] s2c_switch_lock_hero err
         */

        /**
         * Constructs a new s2c_switch_lock_hero.
         * @memberof hero
         * @classdesc Represents a s2c_switch_lock_hero.
         * @implements Is2c_switch_lock_hero
         * @constructor
         * @param {hero.Is2c_switch_lock_hero=} [properties] Properties to set
         */
        function s2c_switch_lock_hero(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_switch_lock_hero err.
         * @member {number} err
         * @memberof hero.s2c_switch_lock_hero
         * @instance
         */
        s2c_switch_lock_hero.prototype.err = 0;

        /**
         * Creates a new s2c_switch_lock_hero instance using the specified properties.
         * @function create
         * @memberof hero.s2c_switch_lock_hero
         * @static
         * @param {hero.Is2c_switch_lock_hero=} [properties] Properties to set
         * @returns {hero.s2c_switch_lock_hero} s2c_switch_lock_hero instance
         */
        s2c_switch_lock_hero.create = function create(properties) {
            return new s2c_switch_lock_hero(properties);
        };

        /**
         * Encodes the specified s2c_switch_lock_hero message. Does not implicitly {@link hero.s2c_switch_lock_hero.verify|verify} messages.
         * @function encode
         * @memberof hero.s2c_switch_lock_hero
         * @static
         * @param {hero.s2c_switch_lock_hero} message s2c_switch_lock_hero message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_switch_lock_hero.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_switch_lock_hero message from the specified reader or buffer.
         * @function decode
         * @memberof hero.s2c_switch_lock_hero
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.s2c_switch_lock_hero} s2c_switch_lock_hero
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_switch_lock_hero.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.s2c_switch_lock_hero();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_switch_lock_hero
         * @function getTypeUrl
         * @memberof hero.s2c_switch_lock_hero
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_switch_lock_hero.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.s2c_switch_lock_hero";
        };

        return s2c_switch_lock_hero;
    })();

    hero.c2s_reset_hero = (function() {

        /**
         * Properties of a c2s_reset_hero.
         * @memberof hero
         * @interface Ic2s_reset_hero
         * @property {number|null} [uid] c2s_reset_hero uid
         */

        /**
         * Constructs a new c2s_reset_hero.
         * @memberof hero
         * @classdesc Represents a c2s_reset_hero.
         * @implements Ic2s_reset_hero
         * @constructor
         * @param {hero.Ic2s_reset_hero=} [properties] Properties to set
         */
        function c2s_reset_hero(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_reset_hero uid.
         * @member {number} uid
         * @memberof hero.c2s_reset_hero
         * @instance
         */
        c2s_reset_hero.prototype.uid = 0;

        /**
         * Creates a new c2s_reset_hero instance using the specified properties.
         * @function create
         * @memberof hero.c2s_reset_hero
         * @static
         * @param {hero.Ic2s_reset_hero=} [properties] Properties to set
         * @returns {hero.c2s_reset_hero} c2s_reset_hero instance
         */
        c2s_reset_hero.create = function create(properties) {
            return new c2s_reset_hero(properties);
        };

        /**
         * Encodes the specified c2s_reset_hero message. Does not implicitly {@link hero.c2s_reset_hero.verify|verify} messages.
         * @function encode
         * @memberof hero.c2s_reset_hero
         * @static
         * @param {hero.c2s_reset_hero} message c2s_reset_hero message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_reset_hero.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.uid);
            return writer;
        };

        /**
         * Decodes a c2s_reset_hero message from the specified reader or buffer.
         * @function decode
         * @memberof hero.c2s_reset_hero
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.c2s_reset_hero} c2s_reset_hero
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_reset_hero.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.c2s_reset_hero();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.uid = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_reset_hero
         * @function getTypeUrl
         * @memberof hero.c2s_reset_hero
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_reset_hero.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.c2s_reset_hero";
        };

        return c2s_reset_hero;
    })();

    hero.s2c_reset_hero = (function() {

        /**
         * Properties of a s2c_reset_hero.
         * @memberof hero
         * @interface Is2c_reset_hero
         * @property {number|null} [err] s2c_reset_hero err
         * @property {Array.<bag.ItemReward>|null} [rewards] s2c_reset_hero rewards
         */

        /**
         * Constructs a new s2c_reset_hero.
         * @memberof hero
         * @classdesc Represents a s2c_reset_hero.
         * @implements Is2c_reset_hero
         * @constructor
         * @param {hero.Is2c_reset_hero=} [properties] Properties to set
         */
        function s2c_reset_hero(properties) {
            this.rewards = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_reset_hero err.
         * @member {number} err
         * @memberof hero.s2c_reset_hero
         * @instance
         */
        s2c_reset_hero.prototype.err = 0;

        /**
         * s2c_reset_hero rewards.
         * @member {Array.<bag.ItemReward>} rewards
         * @memberof hero.s2c_reset_hero
         * @instance
         */
        s2c_reset_hero.prototype.rewards = $util.emptyArray;

        /**
         * Creates a new s2c_reset_hero instance using the specified properties.
         * @function create
         * @memberof hero.s2c_reset_hero
         * @static
         * @param {hero.Is2c_reset_hero=} [properties] Properties to set
         * @returns {hero.s2c_reset_hero} s2c_reset_hero instance
         */
        s2c_reset_hero.create = function create(properties) {
            return new s2c_reset_hero(properties);
        };

        /**
         * Encodes the specified s2c_reset_hero message. Does not implicitly {@link hero.s2c_reset_hero.verify|verify} messages.
         * @function encode
         * @memberof hero.s2c_reset_hero
         * @static
         * @param {hero.s2c_reset_hero} message s2c_reset_hero message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_reset_hero.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.rewards != null && message.rewards.length)
                for (let i = 0; i < message.rewards.length; ++i)
                    $root.bag.ItemReward.encode(message.rewards[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_reset_hero message from the specified reader or buffer.
         * @function decode
         * @memberof hero.s2c_reset_hero
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.s2c_reset_hero} s2c_reset_hero
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_reset_hero.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.s2c_reset_hero();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.rewards && message.rewards.length))
                            message.rewards = [];
                        message.rewards.push($root.bag.ItemReward.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_reset_hero
         * @function getTypeUrl
         * @memberof hero.s2c_reset_hero
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_reset_hero.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.s2c_reset_hero";
        };

        return s2c_reset_hero;
    })();

    hero.c2s_upgrade_stage = (function() {

        /**
         * Properties of a c2s_upgrade_stage.
         * @memberof hero
         * @interface Ic2s_upgrade_stage
         * @property {number|null} [uid] c2s_upgrade_stage uid
         * @property {Array.<number>|null} [materialHeroUids] c2s_upgrade_stage materialHeroUids
         */

        /**
         * Constructs a new c2s_upgrade_stage.
         * @memberof hero
         * @classdesc Represents a c2s_upgrade_stage.
         * @implements Ic2s_upgrade_stage
         * @constructor
         * @param {hero.Ic2s_upgrade_stage=} [properties] Properties to set
         */
        function c2s_upgrade_stage(properties) {
            this.materialHeroUids = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_upgrade_stage uid.
         * @member {number} uid
         * @memberof hero.c2s_upgrade_stage
         * @instance
         */
        c2s_upgrade_stage.prototype.uid = 0;

        /**
         * c2s_upgrade_stage materialHeroUids.
         * @member {Array.<number>} materialHeroUids
         * @memberof hero.c2s_upgrade_stage
         * @instance
         */
        c2s_upgrade_stage.prototype.materialHeroUids = $util.emptyArray;

        /**
         * Creates a new c2s_upgrade_stage instance using the specified properties.
         * @function create
         * @memberof hero.c2s_upgrade_stage
         * @static
         * @param {hero.Ic2s_upgrade_stage=} [properties] Properties to set
         * @returns {hero.c2s_upgrade_stage} c2s_upgrade_stage instance
         */
        c2s_upgrade_stage.create = function create(properties) {
            return new c2s_upgrade_stage(properties);
        };

        /**
         * Encodes the specified c2s_upgrade_stage message. Does not implicitly {@link hero.c2s_upgrade_stage.verify|verify} messages.
         * @function encode
         * @memberof hero.c2s_upgrade_stage
         * @static
         * @param {hero.c2s_upgrade_stage} message c2s_upgrade_stage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_upgrade_stage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.uid);
            if (message.materialHeroUids != null && message.materialHeroUids.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (let i = 0; i < message.materialHeroUids.length; ++i)
                    writer.uint32(message.materialHeroUids[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a c2s_upgrade_stage message from the specified reader or buffer.
         * @function decode
         * @memberof hero.c2s_upgrade_stage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.c2s_upgrade_stage} c2s_upgrade_stage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_upgrade_stage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.c2s_upgrade_stage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.uid = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.materialHeroUids && message.materialHeroUids.length))
                            message.materialHeroUids = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.materialHeroUids.push(reader.uint32());
                        } else
                            message.materialHeroUids.push(reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_upgrade_stage
         * @function getTypeUrl
         * @memberof hero.c2s_upgrade_stage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_upgrade_stage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.c2s_upgrade_stage";
        };

        return c2s_upgrade_stage;
    })();

    hero.s2c_upgrade_stage = (function() {

        /**
         * Properties of a s2c_upgrade_stage.
         * @memberof hero
         * @interface Is2c_upgrade_stage
         * @property {number|null} [err] s2c_upgrade_stage err
         * @property {Array.<bag.ItemReward>|null} [rewards] s2c_upgrade_stage rewards
         */

        /**
         * Constructs a new s2c_upgrade_stage.
         * @memberof hero
         * @classdesc Represents a s2c_upgrade_stage.
         * @implements Is2c_upgrade_stage
         * @constructor
         * @param {hero.Is2c_upgrade_stage=} [properties] Properties to set
         */
        function s2c_upgrade_stage(properties) {
            this.rewards = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_upgrade_stage err.
         * @member {number} err
         * @memberof hero.s2c_upgrade_stage
         * @instance
         */
        s2c_upgrade_stage.prototype.err = 0;

        /**
         * s2c_upgrade_stage rewards.
         * @member {Array.<bag.ItemReward>} rewards
         * @memberof hero.s2c_upgrade_stage
         * @instance
         */
        s2c_upgrade_stage.prototype.rewards = $util.emptyArray;

        /**
         * Creates a new s2c_upgrade_stage instance using the specified properties.
         * @function create
         * @memberof hero.s2c_upgrade_stage
         * @static
         * @param {hero.Is2c_upgrade_stage=} [properties] Properties to set
         * @returns {hero.s2c_upgrade_stage} s2c_upgrade_stage instance
         */
        s2c_upgrade_stage.create = function create(properties) {
            return new s2c_upgrade_stage(properties);
        };

        /**
         * Encodes the specified s2c_upgrade_stage message. Does not implicitly {@link hero.s2c_upgrade_stage.verify|verify} messages.
         * @function encode
         * @memberof hero.s2c_upgrade_stage
         * @static
         * @param {hero.s2c_upgrade_stage} message s2c_upgrade_stage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_upgrade_stage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.rewards != null && message.rewards.length)
                for (let i = 0; i < message.rewards.length; ++i)
                    $root.bag.ItemReward.encode(message.rewards[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_upgrade_stage message from the specified reader or buffer.
         * @function decode
         * @memberof hero.s2c_upgrade_stage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.s2c_upgrade_stage} s2c_upgrade_stage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_upgrade_stage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.s2c_upgrade_stage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.rewards && message.rewards.length))
                            message.rewards = [];
                        message.rewards.push($root.bag.ItemReward.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_upgrade_stage
         * @function getTypeUrl
         * @memberof hero.s2c_upgrade_stage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_upgrade_stage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.s2c_upgrade_stage";
        };

        return s2c_upgrade_stage;
    })();

    hero.c2s_receive_fame_reward = (function() {

        /**
         * Properties of a c2s_receive_fame_reward.
         * @memberof hero
         * @interface Ic2s_receive_fame_reward
         */

        /**
         * Constructs a new c2s_receive_fame_reward.
         * @memberof hero
         * @classdesc Represents a c2s_receive_fame_reward.
         * @implements Ic2s_receive_fame_reward
         * @constructor
         * @param {hero.Ic2s_receive_fame_reward=} [properties] Properties to set
         */
        function c2s_receive_fame_reward(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_receive_fame_reward instance using the specified properties.
         * @function create
         * @memberof hero.c2s_receive_fame_reward
         * @static
         * @param {hero.Ic2s_receive_fame_reward=} [properties] Properties to set
         * @returns {hero.c2s_receive_fame_reward} c2s_receive_fame_reward instance
         */
        c2s_receive_fame_reward.create = function create(properties) {
            return new c2s_receive_fame_reward(properties);
        };

        /**
         * Encodes the specified c2s_receive_fame_reward message. Does not implicitly {@link hero.c2s_receive_fame_reward.verify|verify} messages.
         * @function encode
         * @memberof hero.c2s_receive_fame_reward
         * @static
         * @param {hero.c2s_receive_fame_reward} message c2s_receive_fame_reward message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_receive_fame_reward.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_receive_fame_reward message from the specified reader or buffer.
         * @function decode
         * @memberof hero.c2s_receive_fame_reward
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.c2s_receive_fame_reward} c2s_receive_fame_reward
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_receive_fame_reward.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.c2s_receive_fame_reward();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_receive_fame_reward
         * @function getTypeUrl
         * @memberof hero.c2s_receive_fame_reward
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_receive_fame_reward.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.c2s_receive_fame_reward";
        };

        return c2s_receive_fame_reward;
    })();

    hero.s2c_receive_fame_reward = (function() {

        /**
         * Properties of a s2c_receive_fame_reward.
         * @memberof hero
         * @interface Is2c_receive_fame_reward
         * @property {number|null} [err] s2c_receive_fame_reward err
         * @property {Array.<bag.ItemReward>|null} [rewards] s2c_receive_fame_reward rewards
         */

        /**
         * Constructs a new s2c_receive_fame_reward.
         * @memberof hero
         * @classdesc Represents a s2c_receive_fame_reward.
         * @implements Is2c_receive_fame_reward
         * @constructor
         * @param {hero.Is2c_receive_fame_reward=} [properties] Properties to set
         */
        function s2c_receive_fame_reward(properties) {
            this.rewards = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_receive_fame_reward err.
         * @member {number} err
         * @memberof hero.s2c_receive_fame_reward
         * @instance
         */
        s2c_receive_fame_reward.prototype.err = 0;

        /**
         * s2c_receive_fame_reward rewards.
         * @member {Array.<bag.ItemReward>} rewards
         * @memberof hero.s2c_receive_fame_reward
         * @instance
         */
        s2c_receive_fame_reward.prototype.rewards = $util.emptyArray;

        /**
         * Creates a new s2c_receive_fame_reward instance using the specified properties.
         * @function create
         * @memberof hero.s2c_receive_fame_reward
         * @static
         * @param {hero.Is2c_receive_fame_reward=} [properties] Properties to set
         * @returns {hero.s2c_receive_fame_reward} s2c_receive_fame_reward instance
         */
        s2c_receive_fame_reward.create = function create(properties) {
            return new s2c_receive_fame_reward(properties);
        };

        /**
         * Encodes the specified s2c_receive_fame_reward message. Does not implicitly {@link hero.s2c_receive_fame_reward.verify|verify} messages.
         * @function encode
         * @memberof hero.s2c_receive_fame_reward
         * @static
         * @param {hero.s2c_receive_fame_reward} message s2c_receive_fame_reward message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_receive_fame_reward.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.rewards != null && message.rewards.length)
                for (let i = 0; i < message.rewards.length; ++i)
                    $root.bag.ItemReward.encode(message.rewards[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_receive_fame_reward message from the specified reader or buffer.
         * @function decode
         * @memberof hero.s2c_receive_fame_reward
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.s2c_receive_fame_reward} s2c_receive_fame_reward
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_receive_fame_reward.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.s2c_receive_fame_reward();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.rewards && message.rewards.length))
                            message.rewards = [];
                        message.rewards.push($root.bag.ItemReward.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_receive_fame_reward
         * @function getTypeUrl
         * @memberof hero.s2c_receive_fame_reward
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_receive_fame_reward.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.s2c_receive_fame_reward";
        };

        return s2c_receive_fame_reward;
    })();

    hero.c2s_receive_handbook_reward = (function() {

        /**
         * Properties of a c2s_receive_handbook_reward.
         * @memberof hero
         * @interface Ic2s_receive_handbook_reward
         * @property {Array.<number>|null} [idList] c2s_receive_handbook_reward idList
         */

        /**
         * Constructs a new c2s_receive_handbook_reward.
         * @memberof hero
         * @classdesc Represents a c2s_receive_handbook_reward.
         * @implements Ic2s_receive_handbook_reward
         * @constructor
         * @param {hero.Ic2s_receive_handbook_reward=} [properties] Properties to set
         */
        function c2s_receive_handbook_reward(properties) {
            this.idList = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_receive_handbook_reward idList.
         * @member {Array.<number>} idList
         * @memberof hero.c2s_receive_handbook_reward
         * @instance
         */
        c2s_receive_handbook_reward.prototype.idList = $util.emptyArray;

        /**
         * Creates a new c2s_receive_handbook_reward instance using the specified properties.
         * @function create
         * @memberof hero.c2s_receive_handbook_reward
         * @static
         * @param {hero.Ic2s_receive_handbook_reward=} [properties] Properties to set
         * @returns {hero.c2s_receive_handbook_reward} c2s_receive_handbook_reward instance
         */
        c2s_receive_handbook_reward.create = function create(properties) {
            return new c2s_receive_handbook_reward(properties);
        };

        /**
         * Encodes the specified c2s_receive_handbook_reward message. Does not implicitly {@link hero.c2s_receive_handbook_reward.verify|verify} messages.
         * @function encode
         * @memberof hero.c2s_receive_handbook_reward
         * @static
         * @param {hero.c2s_receive_handbook_reward} message c2s_receive_handbook_reward message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_receive_handbook_reward.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.idList != null && message.idList.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (let i = 0; i < message.idList.length; ++i)
                    writer.uint32(message.idList[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a c2s_receive_handbook_reward message from the specified reader or buffer.
         * @function decode
         * @memberof hero.c2s_receive_handbook_reward
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.c2s_receive_handbook_reward} c2s_receive_handbook_reward
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_receive_handbook_reward.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.c2s_receive_handbook_reward();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.idList && message.idList.length))
                            message.idList = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.idList.push(reader.uint32());
                        } else
                            message.idList.push(reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_receive_handbook_reward
         * @function getTypeUrl
         * @memberof hero.c2s_receive_handbook_reward
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_receive_handbook_reward.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.c2s_receive_handbook_reward";
        };

        return c2s_receive_handbook_reward;
    })();

    hero.s2c_receive_handbook_reward = (function() {

        /**
         * Properties of a s2c_receive_handbook_reward.
         * @memberof hero
         * @interface Is2c_receive_handbook_reward
         * @property {number|null} [err] s2c_receive_handbook_reward err
         * @property {Array.<bag.ItemReward>|null} [rewards] s2c_receive_handbook_reward rewards
         */

        /**
         * Constructs a new s2c_receive_handbook_reward.
         * @memberof hero
         * @classdesc Represents a s2c_receive_handbook_reward.
         * @implements Is2c_receive_handbook_reward
         * @constructor
         * @param {hero.Is2c_receive_handbook_reward=} [properties] Properties to set
         */
        function s2c_receive_handbook_reward(properties) {
            this.rewards = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_receive_handbook_reward err.
         * @member {number} err
         * @memberof hero.s2c_receive_handbook_reward
         * @instance
         */
        s2c_receive_handbook_reward.prototype.err = 0;

        /**
         * s2c_receive_handbook_reward rewards.
         * @member {Array.<bag.ItemReward>} rewards
         * @memberof hero.s2c_receive_handbook_reward
         * @instance
         */
        s2c_receive_handbook_reward.prototype.rewards = $util.emptyArray;

        /**
         * Creates a new s2c_receive_handbook_reward instance using the specified properties.
         * @function create
         * @memberof hero.s2c_receive_handbook_reward
         * @static
         * @param {hero.Is2c_receive_handbook_reward=} [properties] Properties to set
         * @returns {hero.s2c_receive_handbook_reward} s2c_receive_handbook_reward instance
         */
        s2c_receive_handbook_reward.create = function create(properties) {
            return new s2c_receive_handbook_reward(properties);
        };

        /**
         * Encodes the specified s2c_receive_handbook_reward message. Does not implicitly {@link hero.s2c_receive_handbook_reward.verify|verify} messages.
         * @function encode
         * @memberof hero.s2c_receive_handbook_reward
         * @static
         * @param {hero.s2c_receive_handbook_reward} message s2c_receive_handbook_reward message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_receive_handbook_reward.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.rewards != null && message.rewards.length)
                for (let i = 0; i < message.rewards.length; ++i)
                    $root.bag.ItemReward.encode(message.rewards[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_receive_handbook_reward message from the specified reader or buffer.
         * @function decode
         * @memberof hero.s2c_receive_handbook_reward
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.s2c_receive_handbook_reward} s2c_receive_handbook_reward
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_receive_handbook_reward.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.s2c_receive_handbook_reward();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.rewards && message.rewards.length))
                            message.rewards = [];
                        message.rewards.push($root.bag.ItemReward.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_receive_handbook_reward
         * @function getTypeUrl
         * @memberof hero.s2c_receive_handbook_reward
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_receive_handbook_reward.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.s2c_receive_handbook_reward";
        };

        return s2c_receive_handbook_reward;
    })();

    hero.c2s_load_skills = (function() {

        /**
         * Properties of a c2s_load_skills.
         * @memberof hero
         * @interface Ic2s_load_skills
         */

        /**
         * Constructs a new c2s_load_skills.
         * @memberof hero
         * @classdesc Represents a c2s_load_skills.
         * @implements Ic2s_load_skills
         * @constructor
         * @param {hero.Ic2s_load_skills=} [properties] Properties to set
         */
        function c2s_load_skills(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_load_skills instance using the specified properties.
         * @function create
         * @memberof hero.c2s_load_skills
         * @static
         * @param {hero.Ic2s_load_skills=} [properties] Properties to set
         * @returns {hero.c2s_load_skills} c2s_load_skills instance
         */
        c2s_load_skills.create = function create(properties) {
            return new c2s_load_skills(properties);
        };

        /**
         * Encodes the specified c2s_load_skills message. Does not implicitly {@link hero.c2s_load_skills.verify|verify} messages.
         * @function encode
         * @memberof hero.c2s_load_skills
         * @static
         * @param {hero.c2s_load_skills} message c2s_load_skills message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_load_skills.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_load_skills message from the specified reader or buffer.
         * @function decode
         * @memberof hero.c2s_load_skills
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.c2s_load_skills} c2s_load_skills
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_load_skills.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.c2s_load_skills();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_load_skills
         * @function getTypeUrl
         * @memberof hero.c2s_load_skills
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_load_skills.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.c2s_load_skills";
        };

        return c2s_load_skills;
    })();

    hero.s2c_load_skills = (function() {

        /**
         * Properties of a s2c_load_skills.
         * @memberof hero
         * @interface Is2c_load_skills
         * @property {number|null} [err] s2c_load_skills err
         * @property {Array.<number>|null} [skills] s2c_load_skills skills
         */

        /**
         * Constructs a new s2c_load_skills.
         * @memberof hero
         * @classdesc Represents a s2c_load_skills.
         * @implements Is2c_load_skills
         * @constructor
         * @param {hero.Is2c_load_skills=} [properties] Properties to set
         */
        function s2c_load_skills(properties) {
            this.skills = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_load_skills err.
         * @member {number} err
         * @memberof hero.s2c_load_skills
         * @instance
         */
        s2c_load_skills.prototype.err = 0;

        /**
         * s2c_load_skills skills.
         * @member {Array.<number>} skills
         * @memberof hero.s2c_load_skills
         * @instance
         */
        s2c_load_skills.prototype.skills = $util.emptyArray;

        /**
         * Creates a new s2c_load_skills instance using the specified properties.
         * @function create
         * @memberof hero.s2c_load_skills
         * @static
         * @param {hero.Is2c_load_skills=} [properties] Properties to set
         * @returns {hero.s2c_load_skills} s2c_load_skills instance
         */
        s2c_load_skills.create = function create(properties) {
            return new s2c_load_skills(properties);
        };

        /**
         * Encodes the specified s2c_load_skills message. Does not implicitly {@link hero.s2c_load_skills.verify|verify} messages.
         * @function encode
         * @memberof hero.s2c_load_skills
         * @static
         * @param {hero.s2c_load_skills} message s2c_load_skills message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_load_skills.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.skills != null && message.skills.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (let i = 0; i < message.skills.length; ++i)
                    writer.uint32(message.skills[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a s2c_load_skills message from the specified reader or buffer.
         * @function decode
         * @memberof hero.s2c_load_skills
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.s2c_load_skills} s2c_load_skills
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_load_skills.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.s2c_load_skills();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.skills && message.skills.length))
                            message.skills = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.skills.push(reader.uint32());
                        } else
                            message.skills.push(reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_load_skills
         * @function getTypeUrl
         * @memberof hero.s2c_load_skills
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_load_skills.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.s2c_load_skills";
        };

        return s2c_load_skills;
    })();

    hero.c2s_active_skill = (function() {

        /**
         * Properties of a c2s_active_skill.
         * @memberof hero
         * @interface Ic2s_active_skill
         * @property {number|null} [skillId] c2s_active_skill skillId
         */

        /**
         * Constructs a new c2s_active_skill.
         * @memberof hero
         * @classdesc Represents a c2s_active_skill.
         * @implements Ic2s_active_skill
         * @constructor
         * @param {hero.Ic2s_active_skill=} [properties] Properties to set
         */
        function c2s_active_skill(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_active_skill skillId.
         * @member {number} skillId
         * @memberof hero.c2s_active_skill
         * @instance
         */
        c2s_active_skill.prototype.skillId = 0;

        /**
         * Creates a new c2s_active_skill instance using the specified properties.
         * @function create
         * @memberof hero.c2s_active_skill
         * @static
         * @param {hero.Ic2s_active_skill=} [properties] Properties to set
         * @returns {hero.c2s_active_skill} c2s_active_skill instance
         */
        c2s_active_skill.create = function create(properties) {
            return new c2s_active_skill(properties);
        };

        /**
         * Encodes the specified c2s_active_skill message. Does not implicitly {@link hero.c2s_active_skill.verify|verify} messages.
         * @function encode
         * @memberof hero.c2s_active_skill
         * @static
         * @param {hero.c2s_active_skill} message c2s_active_skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_active_skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.skillId != null && Object.hasOwnProperty.call(message, "skillId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.skillId);
            return writer;
        };

        /**
         * Decodes a c2s_active_skill message from the specified reader or buffer.
         * @function decode
         * @memberof hero.c2s_active_skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.c2s_active_skill} c2s_active_skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_active_skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.c2s_active_skill();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.skillId = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_active_skill
         * @function getTypeUrl
         * @memberof hero.c2s_active_skill
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_active_skill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.c2s_active_skill";
        };

        return c2s_active_skill;
    })();

    hero.s2c_active_skill = (function() {

        /**
         * Properties of a s2c_active_skill.
         * @memberof hero
         * @interface Is2c_active_skill
         * @property {number|null} [err] s2c_active_skill err
         */

        /**
         * Constructs a new s2c_active_skill.
         * @memberof hero
         * @classdesc Represents a s2c_active_skill.
         * @implements Is2c_active_skill
         * @constructor
         * @param {hero.Is2c_active_skill=} [properties] Properties to set
         */
        function s2c_active_skill(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_active_skill err.
         * @member {number} err
         * @memberof hero.s2c_active_skill
         * @instance
         */
        s2c_active_skill.prototype.err = 0;

        /**
         * Creates a new s2c_active_skill instance using the specified properties.
         * @function create
         * @memberof hero.s2c_active_skill
         * @static
         * @param {hero.Is2c_active_skill=} [properties] Properties to set
         * @returns {hero.s2c_active_skill} s2c_active_skill instance
         */
        s2c_active_skill.create = function create(properties) {
            return new s2c_active_skill(properties);
        };

        /**
         * Encodes the specified s2c_active_skill message. Does not implicitly {@link hero.s2c_active_skill.verify|verify} messages.
         * @function encode
         * @memberof hero.s2c_active_skill
         * @static
         * @param {hero.s2c_active_skill} message s2c_active_skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_active_skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_active_skill message from the specified reader or buffer.
         * @function decode
         * @memberof hero.s2c_active_skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.s2c_active_skill} s2c_active_skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_active_skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.s2c_active_skill();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_active_skill
         * @function getTypeUrl
         * @memberof hero.s2c_active_skill
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_active_skill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.s2c_active_skill";
        };

        return s2c_active_skill;
    })();

    hero.c2s_upgrade_skill = (function() {

        /**
         * Properties of a c2s_upgrade_skill.
         * @memberof hero
         * @interface Ic2s_upgrade_skill
         * @property {number|null} [heroUid] c2s_upgrade_skill heroUid
         * @property {number|null} [slotIdx] c2s_upgrade_skill slotIdx
         */

        /**
         * Constructs a new c2s_upgrade_skill.
         * @memberof hero
         * @classdesc Represents a c2s_upgrade_skill.
         * @implements Ic2s_upgrade_skill
         * @constructor
         * @param {hero.Ic2s_upgrade_skill=} [properties] Properties to set
         */
        function c2s_upgrade_skill(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_upgrade_skill heroUid.
         * @member {number} heroUid
         * @memberof hero.c2s_upgrade_skill
         * @instance
         */
        c2s_upgrade_skill.prototype.heroUid = 0;

        /**
         * c2s_upgrade_skill slotIdx.
         * @member {number} slotIdx
         * @memberof hero.c2s_upgrade_skill
         * @instance
         */
        c2s_upgrade_skill.prototype.slotIdx = 0;

        /**
         * Creates a new c2s_upgrade_skill instance using the specified properties.
         * @function create
         * @memberof hero.c2s_upgrade_skill
         * @static
         * @param {hero.Ic2s_upgrade_skill=} [properties] Properties to set
         * @returns {hero.c2s_upgrade_skill} c2s_upgrade_skill instance
         */
        c2s_upgrade_skill.create = function create(properties) {
            return new c2s_upgrade_skill(properties);
        };

        /**
         * Encodes the specified c2s_upgrade_skill message. Does not implicitly {@link hero.c2s_upgrade_skill.verify|verify} messages.
         * @function encode
         * @memberof hero.c2s_upgrade_skill
         * @static
         * @param {hero.c2s_upgrade_skill} message c2s_upgrade_skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_upgrade_skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.heroUid != null && Object.hasOwnProperty.call(message, "heroUid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.heroUid);
            if (message.slotIdx != null && Object.hasOwnProperty.call(message, "slotIdx"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.slotIdx);
            return writer;
        };

        /**
         * Decodes a c2s_upgrade_skill message from the specified reader or buffer.
         * @function decode
         * @memberof hero.c2s_upgrade_skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.c2s_upgrade_skill} c2s_upgrade_skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_upgrade_skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.c2s_upgrade_skill();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.heroUid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.slotIdx = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_upgrade_skill
         * @function getTypeUrl
         * @memberof hero.c2s_upgrade_skill
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_upgrade_skill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.c2s_upgrade_skill";
        };

        return c2s_upgrade_skill;
    })();

    hero.s2c_upgrade_skill = (function() {

        /**
         * Properties of a s2c_upgrade_skill.
         * @memberof hero
         * @interface Is2c_upgrade_skill
         * @property {number|null} [err] s2c_upgrade_skill err
         */

        /**
         * Constructs a new s2c_upgrade_skill.
         * @memberof hero
         * @classdesc Represents a s2c_upgrade_skill.
         * @implements Is2c_upgrade_skill
         * @constructor
         * @param {hero.Is2c_upgrade_skill=} [properties] Properties to set
         */
        function s2c_upgrade_skill(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_upgrade_skill err.
         * @member {number} err
         * @memberof hero.s2c_upgrade_skill
         * @instance
         */
        s2c_upgrade_skill.prototype.err = 0;

        /**
         * Creates a new s2c_upgrade_skill instance using the specified properties.
         * @function create
         * @memberof hero.s2c_upgrade_skill
         * @static
         * @param {hero.Is2c_upgrade_skill=} [properties] Properties to set
         * @returns {hero.s2c_upgrade_skill} s2c_upgrade_skill instance
         */
        s2c_upgrade_skill.create = function create(properties) {
            return new s2c_upgrade_skill(properties);
        };

        /**
         * Encodes the specified s2c_upgrade_skill message. Does not implicitly {@link hero.s2c_upgrade_skill.verify|verify} messages.
         * @function encode
         * @memberof hero.s2c_upgrade_skill
         * @static
         * @param {hero.s2c_upgrade_skill} message s2c_upgrade_skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_upgrade_skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_upgrade_skill message from the specified reader or buffer.
         * @function decode
         * @memberof hero.s2c_upgrade_skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.s2c_upgrade_skill} s2c_upgrade_skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_upgrade_skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.s2c_upgrade_skill();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_upgrade_skill
         * @function getTypeUrl
         * @memberof hero.s2c_upgrade_skill
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_upgrade_skill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.s2c_upgrade_skill";
        };

        return s2c_upgrade_skill;
    })();

    hero.c2s_mount_skill = (function() {

        /**
         * Properties of a c2s_mount_skill.
         * @memberof hero
         * @interface Ic2s_mount_skill
         * @property {number|null} [heroUid] c2s_mount_skill heroUid
         * @property {number|null} [slotIdx] c2s_mount_skill slotIdx
         * @property {number|null} [skillId] c2s_mount_skill skillId
         */

        /**
         * Constructs a new c2s_mount_skill.
         * @memberof hero
         * @classdesc Represents a c2s_mount_skill.
         * @implements Ic2s_mount_skill
         * @constructor
         * @param {hero.Ic2s_mount_skill=} [properties] Properties to set
         */
        function c2s_mount_skill(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_mount_skill heroUid.
         * @member {number} heroUid
         * @memberof hero.c2s_mount_skill
         * @instance
         */
        c2s_mount_skill.prototype.heroUid = 0;

        /**
         * c2s_mount_skill slotIdx.
         * @member {number} slotIdx
         * @memberof hero.c2s_mount_skill
         * @instance
         */
        c2s_mount_skill.prototype.slotIdx = 0;

        /**
         * c2s_mount_skill skillId.
         * @member {number} skillId
         * @memberof hero.c2s_mount_skill
         * @instance
         */
        c2s_mount_skill.prototype.skillId = 0;

        /**
         * Creates a new c2s_mount_skill instance using the specified properties.
         * @function create
         * @memberof hero.c2s_mount_skill
         * @static
         * @param {hero.Ic2s_mount_skill=} [properties] Properties to set
         * @returns {hero.c2s_mount_skill} c2s_mount_skill instance
         */
        c2s_mount_skill.create = function create(properties) {
            return new c2s_mount_skill(properties);
        };

        /**
         * Encodes the specified c2s_mount_skill message. Does not implicitly {@link hero.c2s_mount_skill.verify|verify} messages.
         * @function encode
         * @memberof hero.c2s_mount_skill
         * @static
         * @param {hero.c2s_mount_skill} message c2s_mount_skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_mount_skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.heroUid != null && Object.hasOwnProperty.call(message, "heroUid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.heroUid);
            if (message.slotIdx != null && Object.hasOwnProperty.call(message, "slotIdx"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.slotIdx);
            if (message.skillId != null && Object.hasOwnProperty.call(message, "skillId"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.skillId);
            return writer;
        };

        /**
         * Decodes a c2s_mount_skill message from the specified reader or buffer.
         * @function decode
         * @memberof hero.c2s_mount_skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.c2s_mount_skill} c2s_mount_skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_mount_skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.c2s_mount_skill();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.heroUid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.slotIdx = reader.uint32();
                        break;
                    }
                case 3: {
                        message.skillId = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_mount_skill
         * @function getTypeUrl
         * @memberof hero.c2s_mount_skill
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_mount_skill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.c2s_mount_skill";
        };

        return c2s_mount_skill;
    })();

    hero.s2c_mount_skill = (function() {

        /**
         * Properties of a s2c_mount_skill.
         * @memberof hero
         * @interface Is2c_mount_skill
         * @property {number|null} [err] s2c_mount_skill err
         */

        /**
         * Constructs a new s2c_mount_skill.
         * @memberof hero
         * @classdesc Represents a s2c_mount_skill.
         * @implements Is2c_mount_skill
         * @constructor
         * @param {hero.Is2c_mount_skill=} [properties] Properties to set
         */
        function s2c_mount_skill(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_mount_skill err.
         * @member {number} err
         * @memberof hero.s2c_mount_skill
         * @instance
         */
        s2c_mount_skill.prototype.err = 0;

        /**
         * Creates a new s2c_mount_skill instance using the specified properties.
         * @function create
         * @memberof hero.s2c_mount_skill
         * @static
         * @param {hero.Is2c_mount_skill=} [properties] Properties to set
         * @returns {hero.s2c_mount_skill} s2c_mount_skill instance
         */
        s2c_mount_skill.create = function create(properties) {
            return new s2c_mount_skill(properties);
        };

        /**
         * Encodes the specified s2c_mount_skill message. Does not implicitly {@link hero.s2c_mount_skill.verify|verify} messages.
         * @function encode
         * @memberof hero.s2c_mount_skill
         * @static
         * @param {hero.s2c_mount_skill} message s2c_mount_skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_mount_skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_mount_skill message from the specified reader or buffer.
         * @function decode
         * @memberof hero.s2c_mount_skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.s2c_mount_skill} s2c_mount_skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_mount_skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.s2c_mount_skill();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_mount_skill
         * @function getTypeUrl
         * @memberof hero.s2c_mount_skill
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_mount_skill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.s2c_mount_skill";
        };

        return s2c_mount_skill;
    })();

    hero.c2s_unmount_skill = (function() {

        /**
         * Properties of a c2s_unmount_skill.
         * @memberof hero
         * @interface Ic2s_unmount_skill
         * @property {number|null} [heroUid] c2s_unmount_skill heroUid
         * @property {number|null} [slotIdx] c2s_unmount_skill slotIdx
         */

        /**
         * Constructs a new c2s_unmount_skill.
         * @memberof hero
         * @classdesc Represents a c2s_unmount_skill.
         * @implements Ic2s_unmount_skill
         * @constructor
         * @param {hero.Ic2s_unmount_skill=} [properties] Properties to set
         */
        function c2s_unmount_skill(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_unmount_skill heroUid.
         * @member {number} heroUid
         * @memberof hero.c2s_unmount_skill
         * @instance
         */
        c2s_unmount_skill.prototype.heroUid = 0;

        /**
         * c2s_unmount_skill slotIdx.
         * @member {number} slotIdx
         * @memberof hero.c2s_unmount_skill
         * @instance
         */
        c2s_unmount_skill.prototype.slotIdx = 0;

        /**
         * Creates a new c2s_unmount_skill instance using the specified properties.
         * @function create
         * @memberof hero.c2s_unmount_skill
         * @static
         * @param {hero.Ic2s_unmount_skill=} [properties] Properties to set
         * @returns {hero.c2s_unmount_skill} c2s_unmount_skill instance
         */
        c2s_unmount_skill.create = function create(properties) {
            return new c2s_unmount_skill(properties);
        };

        /**
         * Encodes the specified c2s_unmount_skill message. Does not implicitly {@link hero.c2s_unmount_skill.verify|verify} messages.
         * @function encode
         * @memberof hero.c2s_unmount_skill
         * @static
         * @param {hero.c2s_unmount_skill} message c2s_unmount_skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_unmount_skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.heroUid != null && Object.hasOwnProperty.call(message, "heroUid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.heroUid);
            if (message.slotIdx != null && Object.hasOwnProperty.call(message, "slotIdx"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.slotIdx);
            return writer;
        };

        /**
         * Decodes a c2s_unmount_skill message from the specified reader or buffer.
         * @function decode
         * @memberof hero.c2s_unmount_skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.c2s_unmount_skill} c2s_unmount_skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_unmount_skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.c2s_unmount_skill();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.heroUid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.slotIdx = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_unmount_skill
         * @function getTypeUrl
         * @memberof hero.c2s_unmount_skill
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_unmount_skill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.c2s_unmount_skill";
        };

        return c2s_unmount_skill;
    })();

    hero.s2c_unmount_skill = (function() {

        /**
         * Properties of a s2c_unmount_skill.
         * @memberof hero
         * @interface Is2c_unmount_skill
         * @property {number|null} [err] s2c_unmount_skill err
         */

        /**
         * Constructs a new s2c_unmount_skill.
         * @memberof hero
         * @classdesc Represents a s2c_unmount_skill.
         * @implements Is2c_unmount_skill
         * @constructor
         * @param {hero.Is2c_unmount_skill=} [properties] Properties to set
         */
        function s2c_unmount_skill(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_unmount_skill err.
         * @member {number} err
         * @memberof hero.s2c_unmount_skill
         * @instance
         */
        s2c_unmount_skill.prototype.err = 0;

        /**
         * Creates a new s2c_unmount_skill instance using the specified properties.
         * @function create
         * @memberof hero.s2c_unmount_skill
         * @static
         * @param {hero.Is2c_unmount_skill=} [properties] Properties to set
         * @returns {hero.s2c_unmount_skill} s2c_unmount_skill instance
         */
        s2c_unmount_skill.create = function create(properties) {
            return new s2c_unmount_skill(properties);
        };

        /**
         * Encodes the specified s2c_unmount_skill message. Does not implicitly {@link hero.s2c_unmount_skill.verify|verify} messages.
         * @function encode
         * @memberof hero.s2c_unmount_skill
         * @static
         * @param {hero.s2c_unmount_skill} message s2c_unmount_skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_unmount_skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_unmount_skill message from the specified reader or buffer.
         * @function decode
         * @memberof hero.s2c_unmount_skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.s2c_unmount_skill} s2c_unmount_skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_unmount_skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.s2c_unmount_skill();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_unmount_skill
         * @function getTypeUrl
         * @memberof hero.s2c_unmount_skill
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_unmount_skill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.s2c_unmount_skill";
        };

        return s2c_unmount_skill;
    })();

    hero.notify_hero_update = (function() {

        /**
         * Properties of a notify_hero_update.
         * @memberof hero
         * @interface Inotify_hero_update
         * @property {Array.<hero.Hero>|null} [heroList] notify_hero_update heroList
         * @property {Array.<number>|null} [delHeroUidList] notify_hero_update delHeroUidList
         */

        /**
         * Constructs a new notify_hero_update.
         * @memberof hero
         * @classdesc Represents a notify_hero_update.
         * @implements Inotify_hero_update
         * @constructor
         * @param {hero.Inotify_hero_update=} [properties] Properties to set
         */
        function notify_hero_update(properties) {
            this.heroList = [];
            this.delHeroUidList = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_hero_update heroList.
         * @member {Array.<hero.Hero>} heroList
         * @memberof hero.notify_hero_update
         * @instance
         */
        notify_hero_update.prototype.heroList = $util.emptyArray;

        /**
         * notify_hero_update delHeroUidList.
         * @member {Array.<number>} delHeroUidList
         * @memberof hero.notify_hero_update
         * @instance
         */
        notify_hero_update.prototype.delHeroUidList = $util.emptyArray;

        /**
         * Creates a new notify_hero_update instance using the specified properties.
         * @function create
         * @memberof hero.notify_hero_update
         * @static
         * @param {hero.Inotify_hero_update=} [properties] Properties to set
         * @returns {hero.notify_hero_update} notify_hero_update instance
         */
        notify_hero_update.create = function create(properties) {
            return new notify_hero_update(properties);
        };

        /**
         * Encodes the specified notify_hero_update message. Does not implicitly {@link hero.notify_hero_update.verify|verify} messages.
         * @function encode
         * @memberof hero.notify_hero_update
         * @static
         * @param {hero.notify_hero_update} message notify_hero_update message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_hero_update.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.heroList != null && message.heroList.length)
                for (let i = 0; i < message.heroList.length; ++i)
                    $root.hero.Hero.encode(message.heroList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.delHeroUidList != null && message.delHeroUidList.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (let i = 0; i < message.delHeroUidList.length; ++i)
                    writer.uint32(message.delHeroUidList[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a notify_hero_update message from the specified reader or buffer.
         * @function decode
         * @memberof hero.notify_hero_update
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.notify_hero_update} notify_hero_update
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_hero_update.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.notify_hero_update();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.heroList && message.heroList.length))
                            message.heroList = [];
                        message.heroList.push($root.hero.Hero.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        if (!(message.delHeroUidList && message.delHeroUidList.length))
                            message.delHeroUidList = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.delHeroUidList.push(reader.uint32());
                        } else
                            message.delHeroUidList.push(reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_hero_update
         * @function getTypeUrl
         * @memberof hero.notify_hero_update
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_hero_update.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.notify_hero_update";
        };

        return notify_hero_update;
    })();

    hero.notify_hero_handbook_update = (function() {

        /**
         * Properties of a notify_hero_handbook_update.
         * @memberof hero
         * @interface Inotify_hero_handbook_update
         * @property {Array.<hero.HeroHandbookItem>|null} [heroHandbook] notify_hero_handbook_update heroHandbook
         */

        /**
         * Constructs a new notify_hero_handbook_update.
         * @memberof hero
         * @classdesc Represents a notify_hero_handbook_update.
         * @implements Inotify_hero_handbook_update
         * @constructor
         * @param {hero.Inotify_hero_handbook_update=} [properties] Properties to set
         */
        function notify_hero_handbook_update(properties) {
            this.heroHandbook = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_hero_handbook_update heroHandbook.
         * @member {Array.<hero.HeroHandbookItem>} heroHandbook
         * @memberof hero.notify_hero_handbook_update
         * @instance
         */
        notify_hero_handbook_update.prototype.heroHandbook = $util.emptyArray;

        /**
         * Creates a new notify_hero_handbook_update instance using the specified properties.
         * @function create
         * @memberof hero.notify_hero_handbook_update
         * @static
         * @param {hero.Inotify_hero_handbook_update=} [properties] Properties to set
         * @returns {hero.notify_hero_handbook_update} notify_hero_handbook_update instance
         */
        notify_hero_handbook_update.create = function create(properties) {
            return new notify_hero_handbook_update(properties);
        };

        /**
         * Encodes the specified notify_hero_handbook_update message. Does not implicitly {@link hero.notify_hero_handbook_update.verify|verify} messages.
         * @function encode
         * @memberof hero.notify_hero_handbook_update
         * @static
         * @param {hero.notify_hero_handbook_update} message notify_hero_handbook_update message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_hero_handbook_update.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.heroHandbook != null && message.heroHandbook.length)
                for (let i = 0; i < message.heroHandbook.length; ++i)
                    $root.hero.HeroHandbookItem.encode(message.heroHandbook[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a notify_hero_handbook_update message from the specified reader or buffer.
         * @function decode
         * @memberof hero.notify_hero_handbook_update
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.notify_hero_handbook_update} notify_hero_handbook_update
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_hero_handbook_update.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.notify_hero_handbook_update();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.heroHandbook && message.heroHandbook.length))
                            message.heroHandbook = [];
                        message.heroHandbook.push($root.hero.HeroHandbookItem.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_hero_handbook_update
         * @function getTypeUrl
         * @memberof hero.notify_hero_handbook_update
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_hero_handbook_update.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.notify_hero_handbook_update";
        };

        return notify_hero_handbook_update;
    })();

    hero.notify_common_info = (function() {

        /**
         * Properties of a notify_common_info.
         * @memberof hero
         * @interface Inotify_common_info
         * @property {hero.CommonInfo|null} [commonInfo] notify_common_info commonInfo
         */

        /**
         * Constructs a new notify_common_info.
         * @memberof hero
         * @classdesc Represents a notify_common_info.
         * @implements Inotify_common_info
         * @constructor
         * @param {hero.Inotify_common_info=} [properties] Properties to set
         */
        function notify_common_info(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_common_info commonInfo.
         * @member {hero.CommonInfo|null|undefined} commonInfo
         * @memberof hero.notify_common_info
         * @instance
         */
        notify_common_info.prototype.commonInfo = null;

        /**
         * Creates a new notify_common_info instance using the specified properties.
         * @function create
         * @memberof hero.notify_common_info
         * @static
         * @param {hero.Inotify_common_info=} [properties] Properties to set
         * @returns {hero.notify_common_info} notify_common_info instance
         */
        notify_common_info.create = function create(properties) {
            return new notify_common_info(properties);
        };

        /**
         * Encodes the specified notify_common_info message. Does not implicitly {@link hero.notify_common_info.verify|verify} messages.
         * @function encode
         * @memberof hero.notify_common_info
         * @static
         * @param {hero.notify_common_info} message notify_common_info message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_common_info.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonInfo != null && Object.hasOwnProperty.call(message, "commonInfo"))
                $root.hero.CommonInfo.encode(message.commonInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a notify_common_info message from the specified reader or buffer.
         * @function decode
         * @memberof hero.notify_common_info
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hero.notify_common_info} notify_common_info
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_common_info.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hero.notify_common_info();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.commonInfo = $root.hero.CommonInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_common_info
         * @function getTypeUrl
         * @memberof hero.notify_common_info
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_common_info.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/hero.notify_common_info";
        };

        return notify_common_info;
    })();

    return hero;
})();

export const leader_skin = $root.leader_skin = (() => {

    /**
     * Namespace leader_skin.
     * @exports leader_skin
     * @namespace
     */
    const leader_skin = {};

    leader_skin.SkillInfo = (function() {

        /**
         * Properties of a SkillInfo.
         * @memberof leader_skin
         * @interface ISkillInfo
         * @property {number|null} [id] SkillInfo id
         * @property {number|null} [lv] SkillInfo lv
         */

        /**
         * Constructs a new SkillInfo.
         * @memberof leader_skin
         * @classdesc Represents a SkillInfo.
         * @implements ISkillInfo
         * @constructor
         * @param {leader_skin.ISkillInfo=} [properties] Properties to set
         */
        function SkillInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SkillInfo id.
         * @member {number} id
         * @memberof leader_skin.SkillInfo
         * @instance
         */
        SkillInfo.prototype.id = 0;

        /**
         * SkillInfo lv.
         * @member {number} lv
         * @memberof leader_skin.SkillInfo
         * @instance
         */
        SkillInfo.prototype.lv = 0;

        /**
         * Creates a new SkillInfo instance using the specified properties.
         * @function create
         * @memberof leader_skin.SkillInfo
         * @static
         * @param {leader_skin.ISkillInfo=} [properties] Properties to set
         * @returns {leader_skin.SkillInfo} SkillInfo instance
         */
        SkillInfo.create = function create(properties) {
            return new SkillInfo(properties);
        };

        /**
         * Encodes the specified SkillInfo message. Does not implicitly {@link leader_skin.SkillInfo.verify|verify} messages.
         * @function encode
         * @memberof leader_skin.SkillInfo
         * @static
         * @param {leader_skin.SkillInfo} message SkillInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkillInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            if (message.lv != null && Object.hasOwnProperty.call(message, "lv"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.lv);
            return writer;
        };

        /**
         * Decodes a SkillInfo message from the specified reader or buffer.
         * @function decode
         * @memberof leader_skin.SkillInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {leader_skin.SkillInfo} SkillInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkillInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.leader_skin.SkillInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 2: {
                        message.lv = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for SkillInfo
         * @function getTypeUrl
         * @memberof leader_skin.SkillInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SkillInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/leader_skin.SkillInfo";
        };

        return SkillInfo;
    })();

    leader_skin.LeaderSkin = (function() {

        /**
         * Properties of a LeaderSkin.
         * @memberof leader_skin
         * @interface ILeaderSkin
         * @property {number|null} [id] LeaderSkin id
         * @property {number|null} [lv] LeaderSkin lv
         * @property {number|null} [hp] LeaderSkin hp
         * @property {Object.<string,leader_skin.SkillInfo>|null} [skills] LeaderSkin skills
         */

        /**
         * Constructs a new LeaderSkin.
         * @memberof leader_skin
         * @classdesc Represents a LeaderSkin.
         * @implements ILeaderSkin
         * @constructor
         * @param {leader_skin.ILeaderSkin=} [properties] Properties to set
         */
        function LeaderSkin(properties) {
            this.skills = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LeaderSkin id.
         * @member {number} id
         * @memberof leader_skin.LeaderSkin
         * @instance
         */
        LeaderSkin.prototype.id = 0;

        /**
         * LeaderSkin lv.
         * @member {number} lv
         * @memberof leader_skin.LeaderSkin
         * @instance
         */
        LeaderSkin.prototype.lv = 0;

        /**
         * LeaderSkin hp.
         * @member {number} hp
         * @memberof leader_skin.LeaderSkin
         * @instance
         */
        LeaderSkin.prototype.hp = 0;

        /**
         * LeaderSkin skills.
         * @member {Object.<string,leader_skin.SkillInfo>} skills
         * @memberof leader_skin.LeaderSkin
         * @instance
         */
        LeaderSkin.prototype.skills = $util.emptyObject;

        /**
         * Creates a new LeaderSkin instance using the specified properties.
         * @function create
         * @memberof leader_skin.LeaderSkin
         * @static
         * @param {leader_skin.ILeaderSkin=} [properties] Properties to set
         * @returns {leader_skin.LeaderSkin} LeaderSkin instance
         */
        LeaderSkin.create = function create(properties) {
            return new LeaderSkin(properties);
        };

        /**
         * Encodes the specified LeaderSkin message. Does not implicitly {@link leader_skin.LeaderSkin.verify|verify} messages.
         * @function encode
         * @memberof leader_skin.LeaderSkin
         * @static
         * @param {leader_skin.LeaderSkin} message LeaderSkin message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LeaderSkin.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            if (message.lv != null && Object.hasOwnProperty.call(message, "lv"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.lv);
            if (message.hp != null && Object.hasOwnProperty.call(message, "hp"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.hp);
            if (message.skills != null && Object.hasOwnProperty.call(message, "skills"))
                for (let keys = Object.keys(message.skills), i = 0; i < keys.length; ++i) {
                    writer.uint32(/* id 4, wireType 2 =*/34).fork().uint32(/* id 1, wireType 0 =*/8).uint32(keys[i]);
                    $root.leader_skin.SkillInfo.encode(message.skills[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                }
            return writer;
        };

        /**
         * Decodes a LeaderSkin message from the specified reader or buffer.
         * @function decode
         * @memberof leader_skin.LeaderSkin
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {leader_skin.LeaderSkin} LeaderSkin
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LeaderSkin.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.leader_skin.LeaderSkin(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 2: {
                        message.lv = reader.uint32();
                        break;
                    }
                case 3: {
                        message.hp = reader.uint32();
                        break;
                    }
                case 4: {
                        if (message.skills === $util.emptyObject)
                            message.skills = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = 0;
                        value = null;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.uint32();
                                break;
                            case 2:
                                value = $root.leader_skin.SkillInfo.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.skills[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for LeaderSkin
         * @function getTypeUrl
         * @memberof leader_skin.LeaderSkin
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LeaderSkin.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/leader_skin.LeaderSkin";
        };

        return LeaderSkin;
    })();

    leader_skin.c2s_load = (function() {

        /**
         * Properties of a c2s_load.
         * @memberof leader_skin
         * @interface Ic2s_load
         */

        /**
         * Constructs a new c2s_load.
         * @memberof leader_skin
         * @classdesc Represents a c2s_load.
         * @implements Ic2s_load
         * @constructor
         * @param {leader_skin.Ic2s_load=} [properties] Properties to set
         */
        function c2s_load(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_load instance using the specified properties.
         * @function create
         * @memberof leader_skin.c2s_load
         * @static
         * @param {leader_skin.Ic2s_load=} [properties] Properties to set
         * @returns {leader_skin.c2s_load} c2s_load instance
         */
        c2s_load.create = function create(properties) {
            return new c2s_load(properties);
        };

        /**
         * Encodes the specified c2s_load message. Does not implicitly {@link leader_skin.c2s_load.verify|verify} messages.
         * @function encode
         * @memberof leader_skin.c2s_load
         * @static
         * @param {leader_skin.c2s_load} message c2s_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_load message from the specified reader or buffer.
         * @function decode
         * @memberof leader_skin.c2s_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {leader_skin.c2s_load} c2s_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.leader_skin.c2s_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_load
         * @function getTypeUrl
         * @memberof leader_skin.c2s_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/leader_skin.c2s_load";
        };

        return c2s_load;
    })();

    leader_skin.s2c_load = (function() {

        /**
         * Properties of a s2c_load.
         * @memberof leader_skin
         * @interface Is2c_load
         * @property {number|null} [err] s2c_load err
         * @property {Array.<leader_skin.LeaderSkin>|null} [leaderSkinList] s2c_load leaderSkinList
         */

        /**
         * Constructs a new s2c_load.
         * @memberof leader_skin
         * @classdesc Represents a s2c_load.
         * @implements Is2c_load
         * @constructor
         * @param {leader_skin.Is2c_load=} [properties] Properties to set
         */
        function s2c_load(properties) {
            this.leaderSkinList = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_load err.
         * @member {number} err
         * @memberof leader_skin.s2c_load
         * @instance
         */
        s2c_load.prototype.err = 0;

        /**
         * s2c_load leaderSkinList.
         * @member {Array.<leader_skin.LeaderSkin>} leaderSkinList
         * @memberof leader_skin.s2c_load
         * @instance
         */
        s2c_load.prototype.leaderSkinList = $util.emptyArray;

        /**
         * Creates a new s2c_load instance using the specified properties.
         * @function create
         * @memberof leader_skin.s2c_load
         * @static
         * @param {leader_skin.Is2c_load=} [properties] Properties to set
         * @returns {leader_skin.s2c_load} s2c_load instance
         */
        s2c_load.create = function create(properties) {
            return new s2c_load(properties);
        };

        /**
         * Encodes the specified s2c_load message. Does not implicitly {@link leader_skin.s2c_load.verify|verify} messages.
         * @function encode
         * @memberof leader_skin.s2c_load
         * @static
         * @param {leader_skin.s2c_load} message s2c_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.leaderSkinList != null && message.leaderSkinList.length)
                for (let i = 0; i < message.leaderSkinList.length; ++i)
                    $root.leader_skin.LeaderSkin.encode(message.leaderSkinList[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_load message from the specified reader or buffer.
         * @function decode
         * @memberof leader_skin.s2c_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {leader_skin.s2c_load} s2c_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.leader_skin.s2c_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.leaderSkinList && message.leaderSkinList.length))
                            message.leaderSkinList = [];
                        message.leaderSkinList.push($root.leader_skin.LeaderSkin.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_load
         * @function getTypeUrl
         * @memberof leader_skin.s2c_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/leader_skin.s2c_load";
        };

        return s2c_load;
    })();

    leader_skin.c2s_upgrade_lv = (function() {

        /**
         * Properties of a c2s_upgrade_lv.
         * @memberof leader_skin
         * @interface Ic2s_upgrade_lv
         * @property {number|null} [id] c2s_upgrade_lv id
         */

        /**
         * Constructs a new c2s_upgrade_lv.
         * @memberof leader_skin
         * @classdesc Represents a c2s_upgrade_lv.
         * @implements Ic2s_upgrade_lv
         * @constructor
         * @param {leader_skin.Ic2s_upgrade_lv=} [properties] Properties to set
         */
        function c2s_upgrade_lv(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_upgrade_lv id.
         * @member {number} id
         * @memberof leader_skin.c2s_upgrade_lv
         * @instance
         */
        c2s_upgrade_lv.prototype.id = 0;

        /**
         * Creates a new c2s_upgrade_lv instance using the specified properties.
         * @function create
         * @memberof leader_skin.c2s_upgrade_lv
         * @static
         * @param {leader_skin.Ic2s_upgrade_lv=} [properties] Properties to set
         * @returns {leader_skin.c2s_upgrade_lv} c2s_upgrade_lv instance
         */
        c2s_upgrade_lv.create = function create(properties) {
            return new c2s_upgrade_lv(properties);
        };

        /**
         * Encodes the specified c2s_upgrade_lv message. Does not implicitly {@link leader_skin.c2s_upgrade_lv.verify|verify} messages.
         * @function encode
         * @memberof leader_skin.c2s_upgrade_lv
         * @static
         * @param {leader_skin.c2s_upgrade_lv} message c2s_upgrade_lv message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_upgrade_lv.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            return writer;
        };

        /**
         * Decodes a c2s_upgrade_lv message from the specified reader or buffer.
         * @function decode
         * @memberof leader_skin.c2s_upgrade_lv
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {leader_skin.c2s_upgrade_lv} c2s_upgrade_lv
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_upgrade_lv.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.leader_skin.c2s_upgrade_lv();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_upgrade_lv
         * @function getTypeUrl
         * @memberof leader_skin.c2s_upgrade_lv
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_upgrade_lv.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/leader_skin.c2s_upgrade_lv";
        };

        return c2s_upgrade_lv;
    })();

    leader_skin.s2c_upgrade_lv = (function() {

        /**
         * Properties of a s2c_upgrade_lv.
         * @memberof leader_skin
         * @interface Is2c_upgrade_lv
         * @property {number|null} [err] s2c_upgrade_lv err
         */

        /**
         * Constructs a new s2c_upgrade_lv.
         * @memberof leader_skin
         * @classdesc Represents a s2c_upgrade_lv.
         * @implements Is2c_upgrade_lv
         * @constructor
         * @param {leader_skin.Is2c_upgrade_lv=} [properties] Properties to set
         */
        function s2c_upgrade_lv(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_upgrade_lv err.
         * @member {number} err
         * @memberof leader_skin.s2c_upgrade_lv
         * @instance
         */
        s2c_upgrade_lv.prototype.err = 0;

        /**
         * Creates a new s2c_upgrade_lv instance using the specified properties.
         * @function create
         * @memberof leader_skin.s2c_upgrade_lv
         * @static
         * @param {leader_skin.Is2c_upgrade_lv=} [properties] Properties to set
         * @returns {leader_skin.s2c_upgrade_lv} s2c_upgrade_lv instance
         */
        s2c_upgrade_lv.create = function create(properties) {
            return new s2c_upgrade_lv(properties);
        };

        /**
         * Encodes the specified s2c_upgrade_lv message. Does not implicitly {@link leader_skin.s2c_upgrade_lv.verify|verify} messages.
         * @function encode
         * @memberof leader_skin.s2c_upgrade_lv
         * @static
         * @param {leader_skin.s2c_upgrade_lv} message s2c_upgrade_lv message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_upgrade_lv.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_upgrade_lv message from the specified reader or buffer.
         * @function decode
         * @memberof leader_skin.s2c_upgrade_lv
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {leader_skin.s2c_upgrade_lv} s2c_upgrade_lv
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_upgrade_lv.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.leader_skin.s2c_upgrade_lv();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_upgrade_lv
         * @function getTypeUrl
         * @memberof leader_skin.s2c_upgrade_lv
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_upgrade_lv.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/leader_skin.s2c_upgrade_lv";
        };

        return s2c_upgrade_lv;
    })();

    leader_skin.c2s_upgrade_skill = (function() {

        /**
         * Properties of a c2s_upgrade_skill.
         * @memberof leader_skin
         * @interface Ic2s_upgrade_skill
         * @property {number|null} [id] c2s_upgrade_skill id
         * @property {number|null} [slotIdx] c2s_upgrade_skill slotIdx
         */

        /**
         * Constructs a new c2s_upgrade_skill.
         * @memberof leader_skin
         * @classdesc Represents a c2s_upgrade_skill.
         * @implements Ic2s_upgrade_skill
         * @constructor
         * @param {leader_skin.Ic2s_upgrade_skill=} [properties] Properties to set
         */
        function c2s_upgrade_skill(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_upgrade_skill id.
         * @member {number} id
         * @memberof leader_skin.c2s_upgrade_skill
         * @instance
         */
        c2s_upgrade_skill.prototype.id = 0;

        /**
         * c2s_upgrade_skill slotIdx.
         * @member {number} slotIdx
         * @memberof leader_skin.c2s_upgrade_skill
         * @instance
         */
        c2s_upgrade_skill.prototype.slotIdx = 0;

        /**
         * Creates a new c2s_upgrade_skill instance using the specified properties.
         * @function create
         * @memberof leader_skin.c2s_upgrade_skill
         * @static
         * @param {leader_skin.Ic2s_upgrade_skill=} [properties] Properties to set
         * @returns {leader_skin.c2s_upgrade_skill} c2s_upgrade_skill instance
         */
        c2s_upgrade_skill.create = function create(properties) {
            return new c2s_upgrade_skill(properties);
        };

        /**
         * Encodes the specified c2s_upgrade_skill message. Does not implicitly {@link leader_skin.c2s_upgrade_skill.verify|verify} messages.
         * @function encode
         * @memberof leader_skin.c2s_upgrade_skill
         * @static
         * @param {leader_skin.c2s_upgrade_skill} message c2s_upgrade_skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_upgrade_skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            if (message.slotIdx != null && Object.hasOwnProperty.call(message, "slotIdx"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.slotIdx);
            return writer;
        };

        /**
         * Decodes a c2s_upgrade_skill message from the specified reader or buffer.
         * @function decode
         * @memberof leader_skin.c2s_upgrade_skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {leader_skin.c2s_upgrade_skill} c2s_upgrade_skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_upgrade_skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.leader_skin.c2s_upgrade_skill();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 2: {
                        message.slotIdx = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_upgrade_skill
         * @function getTypeUrl
         * @memberof leader_skin.c2s_upgrade_skill
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_upgrade_skill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/leader_skin.c2s_upgrade_skill";
        };

        return c2s_upgrade_skill;
    })();

    leader_skin.s2c_upgrade_skill = (function() {

        /**
         * Properties of a s2c_upgrade_skill.
         * @memberof leader_skin
         * @interface Is2c_upgrade_skill
         * @property {number|null} [err] s2c_upgrade_skill err
         */

        /**
         * Constructs a new s2c_upgrade_skill.
         * @memberof leader_skin
         * @classdesc Represents a s2c_upgrade_skill.
         * @implements Is2c_upgrade_skill
         * @constructor
         * @param {leader_skin.Is2c_upgrade_skill=} [properties] Properties to set
         */
        function s2c_upgrade_skill(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_upgrade_skill err.
         * @member {number} err
         * @memberof leader_skin.s2c_upgrade_skill
         * @instance
         */
        s2c_upgrade_skill.prototype.err = 0;

        /**
         * Creates a new s2c_upgrade_skill instance using the specified properties.
         * @function create
         * @memberof leader_skin.s2c_upgrade_skill
         * @static
         * @param {leader_skin.Is2c_upgrade_skill=} [properties] Properties to set
         * @returns {leader_skin.s2c_upgrade_skill} s2c_upgrade_skill instance
         */
        s2c_upgrade_skill.create = function create(properties) {
            return new s2c_upgrade_skill(properties);
        };

        /**
         * Encodes the specified s2c_upgrade_skill message. Does not implicitly {@link leader_skin.s2c_upgrade_skill.verify|verify} messages.
         * @function encode
         * @memberof leader_skin.s2c_upgrade_skill
         * @static
         * @param {leader_skin.s2c_upgrade_skill} message s2c_upgrade_skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_upgrade_skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_upgrade_skill message from the specified reader or buffer.
         * @function decode
         * @memberof leader_skin.s2c_upgrade_skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {leader_skin.s2c_upgrade_skill} s2c_upgrade_skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_upgrade_skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.leader_skin.s2c_upgrade_skill();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_upgrade_skill
         * @function getTypeUrl
         * @memberof leader_skin.s2c_upgrade_skill
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_upgrade_skill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/leader_skin.s2c_upgrade_skill";
        };

        return s2c_upgrade_skill;
    })();

    leader_skin.c2s_mount_skill = (function() {

        /**
         * Properties of a c2s_mount_skill.
         * @memberof leader_skin
         * @interface Ic2s_mount_skill
         * @property {number|null} [id] c2s_mount_skill id
         * @property {number|null} [slotIdx] c2s_mount_skill slotIdx
         * @property {number|null} [skillId] c2s_mount_skill skillId
         */

        /**
         * Constructs a new c2s_mount_skill.
         * @memberof leader_skin
         * @classdesc Represents a c2s_mount_skill.
         * @implements Ic2s_mount_skill
         * @constructor
         * @param {leader_skin.Ic2s_mount_skill=} [properties] Properties to set
         */
        function c2s_mount_skill(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_mount_skill id.
         * @member {number} id
         * @memberof leader_skin.c2s_mount_skill
         * @instance
         */
        c2s_mount_skill.prototype.id = 0;

        /**
         * c2s_mount_skill slotIdx.
         * @member {number} slotIdx
         * @memberof leader_skin.c2s_mount_skill
         * @instance
         */
        c2s_mount_skill.prototype.slotIdx = 0;

        /**
         * c2s_mount_skill skillId.
         * @member {number} skillId
         * @memberof leader_skin.c2s_mount_skill
         * @instance
         */
        c2s_mount_skill.prototype.skillId = 0;

        /**
         * Creates a new c2s_mount_skill instance using the specified properties.
         * @function create
         * @memberof leader_skin.c2s_mount_skill
         * @static
         * @param {leader_skin.Ic2s_mount_skill=} [properties] Properties to set
         * @returns {leader_skin.c2s_mount_skill} c2s_mount_skill instance
         */
        c2s_mount_skill.create = function create(properties) {
            return new c2s_mount_skill(properties);
        };

        /**
         * Encodes the specified c2s_mount_skill message. Does not implicitly {@link leader_skin.c2s_mount_skill.verify|verify} messages.
         * @function encode
         * @memberof leader_skin.c2s_mount_skill
         * @static
         * @param {leader_skin.c2s_mount_skill} message c2s_mount_skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_mount_skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            if (message.slotIdx != null && Object.hasOwnProperty.call(message, "slotIdx"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.slotIdx);
            if (message.skillId != null && Object.hasOwnProperty.call(message, "skillId"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.skillId);
            return writer;
        };

        /**
         * Decodes a c2s_mount_skill message from the specified reader or buffer.
         * @function decode
         * @memberof leader_skin.c2s_mount_skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {leader_skin.c2s_mount_skill} c2s_mount_skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_mount_skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.leader_skin.c2s_mount_skill();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 2: {
                        message.slotIdx = reader.uint32();
                        break;
                    }
                case 3: {
                        message.skillId = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_mount_skill
         * @function getTypeUrl
         * @memberof leader_skin.c2s_mount_skill
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_mount_skill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/leader_skin.c2s_mount_skill";
        };

        return c2s_mount_skill;
    })();

    leader_skin.s2c_mount_skill = (function() {

        /**
         * Properties of a s2c_mount_skill.
         * @memberof leader_skin
         * @interface Is2c_mount_skill
         * @property {number|null} [err] s2c_mount_skill err
         */

        /**
         * Constructs a new s2c_mount_skill.
         * @memberof leader_skin
         * @classdesc Represents a s2c_mount_skill.
         * @implements Is2c_mount_skill
         * @constructor
         * @param {leader_skin.Is2c_mount_skill=} [properties] Properties to set
         */
        function s2c_mount_skill(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_mount_skill err.
         * @member {number} err
         * @memberof leader_skin.s2c_mount_skill
         * @instance
         */
        s2c_mount_skill.prototype.err = 0;

        /**
         * Creates a new s2c_mount_skill instance using the specified properties.
         * @function create
         * @memberof leader_skin.s2c_mount_skill
         * @static
         * @param {leader_skin.Is2c_mount_skill=} [properties] Properties to set
         * @returns {leader_skin.s2c_mount_skill} s2c_mount_skill instance
         */
        s2c_mount_skill.create = function create(properties) {
            return new s2c_mount_skill(properties);
        };

        /**
         * Encodes the specified s2c_mount_skill message. Does not implicitly {@link leader_skin.s2c_mount_skill.verify|verify} messages.
         * @function encode
         * @memberof leader_skin.s2c_mount_skill
         * @static
         * @param {leader_skin.s2c_mount_skill} message s2c_mount_skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_mount_skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_mount_skill message from the specified reader or buffer.
         * @function decode
         * @memberof leader_skin.s2c_mount_skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {leader_skin.s2c_mount_skill} s2c_mount_skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_mount_skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.leader_skin.s2c_mount_skill();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_mount_skill
         * @function getTypeUrl
         * @memberof leader_skin.s2c_mount_skill
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_mount_skill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/leader_skin.s2c_mount_skill";
        };

        return s2c_mount_skill;
    })();

    leader_skin.c2s_unmount_skill = (function() {

        /**
         * Properties of a c2s_unmount_skill.
         * @memberof leader_skin
         * @interface Ic2s_unmount_skill
         * @property {number|null} [id] c2s_unmount_skill id
         * @property {number|null} [slotIdx] c2s_unmount_skill slotIdx
         */

        /**
         * Constructs a new c2s_unmount_skill.
         * @memberof leader_skin
         * @classdesc Represents a c2s_unmount_skill.
         * @implements Ic2s_unmount_skill
         * @constructor
         * @param {leader_skin.Ic2s_unmount_skill=} [properties] Properties to set
         */
        function c2s_unmount_skill(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_unmount_skill id.
         * @member {number} id
         * @memberof leader_skin.c2s_unmount_skill
         * @instance
         */
        c2s_unmount_skill.prototype.id = 0;

        /**
         * c2s_unmount_skill slotIdx.
         * @member {number} slotIdx
         * @memberof leader_skin.c2s_unmount_skill
         * @instance
         */
        c2s_unmount_skill.prototype.slotIdx = 0;

        /**
         * Creates a new c2s_unmount_skill instance using the specified properties.
         * @function create
         * @memberof leader_skin.c2s_unmount_skill
         * @static
         * @param {leader_skin.Ic2s_unmount_skill=} [properties] Properties to set
         * @returns {leader_skin.c2s_unmount_skill} c2s_unmount_skill instance
         */
        c2s_unmount_skill.create = function create(properties) {
            return new c2s_unmount_skill(properties);
        };

        /**
         * Encodes the specified c2s_unmount_skill message. Does not implicitly {@link leader_skin.c2s_unmount_skill.verify|verify} messages.
         * @function encode
         * @memberof leader_skin.c2s_unmount_skill
         * @static
         * @param {leader_skin.c2s_unmount_skill} message c2s_unmount_skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_unmount_skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            if (message.slotIdx != null && Object.hasOwnProperty.call(message, "slotIdx"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.slotIdx);
            return writer;
        };

        /**
         * Decodes a c2s_unmount_skill message from the specified reader or buffer.
         * @function decode
         * @memberof leader_skin.c2s_unmount_skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {leader_skin.c2s_unmount_skill} c2s_unmount_skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_unmount_skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.leader_skin.c2s_unmount_skill();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 2: {
                        message.slotIdx = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_unmount_skill
         * @function getTypeUrl
         * @memberof leader_skin.c2s_unmount_skill
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_unmount_skill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/leader_skin.c2s_unmount_skill";
        };

        return c2s_unmount_skill;
    })();

    leader_skin.s2c_unmount_skill = (function() {

        /**
         * Properties of a s2c_unmount_skill.
         * @memberof leader_skin
         * @interface Is2c_unmount_skill
         * @property {number|null} [err] s2c_unmount_skill err
         */

        /**
         * Constructs a new s2c_unmount_skill.
         * @memberof leader_skin
         * @classdesc Represents a s2c_unmount_skill.
         * @implements Is2c_unmount_skill
         * @constructor
         * @param {leader_skin.Is2c_unmount_skill=} [properties] Properties to set
         */
        function s2c_unmount_skill(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_unmount_skill err.
         * @member {number} err
         * @memberof leader_skin.s2c_unmount_skill
         * @instance
         */
        s2c_unmount_skill.prototype.err = 0;

        /**
         * Creates a new s2c_unmount_skill instance using the specified properties.
         * @function create
         * @memberof leader_skin.s2c_unmount_skill
         * @static
         * @param {leader_skin.Is2c_unmount_skill=} [properties] Properties to set
         * @returns {leader_skin.s2c_unmount_skill} s2c_unmount_skill instance
         */
        s2c_unmount_skill.create = function create(properties) {
            return new s2c_unmount_skill(properties);
        };

        /**
         * Encodes the specified s2c_unmount_skill message. Does not implicitly {@link leader_skin.s2c_unmount_skill.verify|verify} messages.
         * @function encode
         * @memberof leader_skin.s2c_unmount_skill
         * @static
         * @param {leader_skin.s2c_unmount_skill} message s2c_unmount_skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_unmount_skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_unmount_skill message from the specified reader or buffer.
         * @function decode
         * @memberof leader_skin.s2c_unmount_skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {leader_skin.s2c_unmount_skill} s2c_unmount_skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_unmount_skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.leader_skin.s2c_unmount_skill();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_unmount_skill
         * @function getTypeUrl
         * @memberof leader_skin.s2c_unmount_skill
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_unmount_skill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/leader_skin.s2c_unmount_skill";
        };

        return s2c_unmount_skill;
    })();

    leader_skin.notify_leader_skin_update = (function() {

        /**
         * Properties of a notify_leader_skin_update.
         * @memberof leader_skin
         * @interface Inotify_leader_skin_update
         * @property {Array.<leader_skin.LeaderSkin>|null} [leaderSkinList] notify_leader_skin_update leaderSkinList
         */

        /**
         * Constructs a new notify_leader_skin_update.
         * @memberof leader_skin
         * @classdesc Represents a notify_leader_skin_update.
         * @implements Inotify_leader_skin_update
         * @constructor
         * @param {leader_skin.Inotify_leader_skin_update=} [properties] Properties to set
         */
        function notify_leader_skin_update(properties) {
            this.leaderSkinList = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_leader_skin_update leaderSkinList.
         * @member {Array.<leader_skin.LeaderSkin>} leaderSkinList
         * @memberof leader_skin.notify_leader_skin_update
         * @instance
         */
        notify_leader_skin_update.prototype.leaderSkinList = $util.emptyArray;

        /**
         * Creates a new notify_leader_skin_update instance using the specified properties.
         * @function create
         * @memberof leader_skin.notify_leader_skin_update
         * @static
         * @param {leader_skin.Inotify_leader_skin_update=} [properties] Properties to set
         * @returns {leader_skin.notify_leader_skin_update} notify_leader_skin_update instance
         */
        notify_leader_skin_update.create = function create(properties) {
            return new notify_leader_skin_update(properties);
        };

        /**
         * Encodes the specified notify_leader_skin_update message. Does not implicitly {@link leader_skin.notify_leader_skin_update.verify|verify} messages.
         * @function encode
         * @memberof leader_skin.notify_leader_skin_update
         * @static
         * @param {leader_skin.notify_leader_skin_update} message notify_leader_skin_update message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_leader_skin_update.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.leaderSkinList != null && message.leaderSkinList.length)
                for (let i = 0; i < message.leaderSkinList.length; ++i)
                    $root.leader_skin.LeaderSkin.encode(message.leaderSkinList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a notify_leader_skin_update message from the specified reader or buffer.
         * @function decode
         * @memberof leader_skin.notify_leader_skin_update
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {leader_skin.notify_leader_skin_update} notify_leader_skin_update
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_leader_skin_update.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.leader_skin.notify_leader_skin_update();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.leaderSkinList && message.leaderSkinList.length))
                            message.leaderSkinList = [];
                        message.leaderSkinList.push($root.leader_skin.LeaderSkin.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_leader_skin_update
         * @function getTypeUrl
         * @memberof leader_skin.notify_leader_skin_update
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_leader_skin_update.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/leader_skin.notify_leader_skin_update";
        };

        return notify_leader_skin_update;
    })();

    return leader_skin;
})();

export const mail = $root.mail = (() => {

    /**
     * Namespace mail.
     * @exports mail
     * @namespace
     */
    const mail = {};

    mail.MailInfo = (function() {

        /**
         * Properties of a MailInfo.
         * @memberof mail
         * @interface IMailInfo
         * @property {number|null} [uid] MailInfo uid
         * @property {number|null} [id] MailInfo id
         * @property {number|null} [time] MailInfo time
         * @property {number|null} [state] MailInfo state
         * @property {string|null} [title] MailInfo title
         * @property {string|null} [content] MailInfo content
         * @property {Array.<bag.ItemReward>|null} [reward] MailInfo reward
         * @property {number|null} [expiredSec] MailInfo expiredSec
         */

        /**
         * Constructs a new MailInfo.
         * @memberof mail
         * @classdesc Represents a MailInfo.
         * @implements IMailInfo
         * @constructor
         * @param {mail.IMailInfo=} [properties] Properties to set
         */
        function MailInfo(properties) {
            this.reward = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MailInfo uid.
         * @member {number} uid
         * @memberof mail.MailInfo
         * @instance
         */
        MailInfo.prototype.uid = 0;

        /**
         * MailInfo id.
         * @member {number} id
         * @memberof mail.MailInfo
         * @instance
         */
        MailInfo.prototype.id = 0;

        /**
         * MailInfo time.
         * @member {number} time
         * @memberof mail.MailInfo
         * @instance
         */
        MailInfo.prototype.time = 0;

        /**
         * MailInfo state.
         * @member {number} state
         * @memberof mail.MailInfo
         * @instance
         */
        MailInfo.prototype.state = 0;

        /**
         * MailInfo title.
         * @member {string} title
         * @memberof mail.MailInfo
         * @instance
         */
        MailInfo.prototype.title = "";

        /**
         * MailInfo content.
         * @member {string} content
         * @memberof mail.MailInfo
         * @instance
         */
        MailInfo.prototype.content = "";

        /**
         * MailInfo reward.
         * @member {Array.<bag.ItemReward>} reward
         * @memberof mail.MailInfo
         * @instance
         */
        MailInfo.prototype.reward = $util.emptyArray;

        /**
         * MailInfo expiredSec.
         * @member {number} expiredSec
         * @memberof mail.MailInfo
         * @instance
         */
        MailInfo.prototype.expiredSec = 0;

        /**
         * Creates a new MailInfo instance using the specified properties.
         * @function create
         * @memberof mail.MailInfo
         * @static
         * @param {mail.IMailInfo=} [properties] Properties to set
         * @returns {mail.MailInfo} MailInfo instance
         */
        MailInfo.create = function create(properties) {
            return new MailInfo(properties);
        };

        /**
         * Encodes the specified MailInfo message. Does not implicitly {@link mail.MailInfo.verify|verify} messages.
         * @function encode
         * @memberof mail.MailInfo
         * @static
         * @param {mail.MailInfo} message MailInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MailInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.uid);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.id);
            if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.time);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.state);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.title);
            if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.content);
            if (message.reward != null && message.reward.length)
                for (let i = 0; i < message.reward.length; ++i)
                    $root.bag.ItemReward.encode(message.reward[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.expiredSec != null && Object.hasOwnProperty.call(message, "expiredSec"))
                writer.uint32(/* id 8, wireType 0 =*/64).uint32(message.expiredSec);
            return writer;
        };

        /**
         * Decodes a MailInfo message from the specified reader or buffer.
         * @function decode
         * @memberof mail.MailInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mail.MailInfo} MailInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MailInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mail.MailInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.uid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.id = reader.uint32();
                        break;
                    }
                case 3: {
                        message.time = reader.uint32();
                        break;
                    }
                case 4: {
                        message.state = reader.uint32();
                        break;
                    }
                case 5: {
                        message.title = reader.string();
                        break;
                    }
                case 6: {
                        message.content = reader.string();
                        break;
                    }
                case 7: {
                        if (!(message.reward && message.reward.length))
                            message.reward = [];
                        message.reward.push($root.bag.ItemReward.decode(reader, reader.uint32()));
                        break;
                    }
                case 8: {
                        message.expiredSec = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for MailInfo
         * @function getTypeUrl
         * @memberof mail.MailInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MailInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mail.MailInfo";
        };

        return MailInfo;
    })();

    mail.c2s_load = (function() {

        /**
         * Properties of a c2s_load.
         * @memberof mail
         * @interface Ic2s_load
         */

        /**
         * Constructs a new c2s_load.
         * @memberof mail
         * @classdesc Represents a c2s_load.
         * @implements Ic2s_load
         * @constructor
         * @param {mail.Ic2s_load=} [properties] Properties to set
         */
        function c2s_load(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_load instance using the specified properties.
         * @function create
         * @memberof mail.c2s_load
         * @static
         * @param {mail.Ic2s_load=} [properties] Properties to set
         * @returns {mail.c2s_load} c2s_load instance
         */
        c2s_load.create = function create(properties) {
            return new c2s_load(properties);
        };

        /**
         * Encodes the specified c2s_load message. Does not implicitly {@link mail.c2s_load.verify|verify} messages.
         * @function encode
         * @memberof mail.c2s_load
         * @static
         * @param {mail.c2s_load} message c2s_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_load message from the specified reader or buffer.
         * @function decode
         * @memberof mail.c2s_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mail.c2s_load} c2s_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mail.c2s_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_load
         * @function getTypeUrl
         * @memberof mail.c2s_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mail.c2s_load";
        };

        return c2s_load;
    })();

    mail.s2c_load = (function() {

        /**
         * Properties of a s2c_load.
         * @memberof mail
         * @interface Is2c_load
         * @property {number|null} [err] s2c_load err
         * @property {Array.<mail.MailInfo>|null} [mails] s2c_load mails
         */

        /**
         * Constructs a new s2c_load.
         * @memberof mail
         * @classdesc Represents a s2c_load.
         * @implements Is2c_load
         * @constructor
         * @param {mail.Is2c_load=} [properties] Properties to set
         */
        function s2c_load(properties) {
            this.mails = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_load err.
         * @member {number} err
         * @memberof mail.s2c_load
         * @instance
         */
        s2c_load.prototype.err = 0;

        /**
         * s2c_load mails.
         * @member {Array.<mail.MailInfo>} mails
         * @memberof mail.s2c_load
         * @instance
         */
        s2c_load.prototype.mails = $util.emptyArray;

        /**
         * Creates a new s2c_load instance using the specified properties.
         * @function create
         * @memberof mail.s2c_load
         * @static
         * @param {mail.Is2c_load=} [properties] Properties to set
         * @returns {mail.s2c_load} s2c_load instance
         */
        s2c_load.create = function create(properties) {
            return new s2c_load(properties);
        };

        /**
         * Encodes the specified s2c_load message. Does not implicitly {@link mail.s2c_load.verify|verify} messages.
         * @function encode
         * @memberof mail.s2c_load
         * @static
         * @param {mail.s2c_load} message s2c_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.mails != null && message.mails.length)
                for (let i = 0; i < message.mails.length; ++i)
                    $root.mail.MailInfo.encode(message.mails[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_load message from the specified reader or buffer.
         * @function decode
         * @memberof mail.s2c_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mail.s2c_load} s2c_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mail.s2c_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.mails && message.mails.length))
                            message.mails = [];
                        message.mails.push($root.mail.MailInfo.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_load
         * @function getTypeUrl
         * @memberof mail.s2c_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mail.s2c_load";
        };

        return s2c_load;
    })();

    mail.c2s_read = (function() {

        /**
         * Properties of a c2s_read.
         * @memberof mail
         * @interface Ic2s_read
         * @property {Array.<number>|null} [mailUids] c2s_read mailUids
         */

        /**
         * Constructs a new c2s_read.
         * @memberof mail
         * @classdesc Represents a c2s_read.
         * @implements Ic2s_read
         * @constructor
         * @param {mail.Ic2s_read=} [properties] Properties to set
         */
        function c2s_read(properties) {
            this.mailUids = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_read mailUids.
         * @member {Array.<number>} mailUids
         * @memberof mail.c2s_read
         * @instance
         */
        c2s_read.prototype.mailUids = $util.emptyArray;

        /**
         * Creates a new c2s_read instance using the specified properties.
         * @function create
         * @memberof mail.c2s_read
         * @static
         * @param {mail.Ic2s_read=} [properties] Properties to set
         * @returns {mail.c2s_read} c2s_read instance
         */
        c2s_read.create = function create(properties) {
            return new c2s_read(properties);
        };

        /**
         * Encodes the specified c2s_read message. Does not implicitly {@link mail.c2s_read.verify|verify} messages.
         * @function encode
         * @memberof mail.c2s_read
         * @static
         * @param {mail.c2s_read} message c2s_read message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_read.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mailUids != null && message.mailUids.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (let i = 0; i < message.mailUids.length; ++i)
                    writer.uint32(message.mailUids[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a c2s_read message from the specified reader or buffer.
         * @function decode
         * @memberof mail.c2s_read
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mail.c2s_read} c2s_read
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_read.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mail.c2s_read();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.mailUids && message.mailUids.length))
                            message.mailUids = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.mailUids.push(reader.uint32());
                        } else
                            message.mailUids.push(reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_read
         * @function getTypeUrl
         * @memberof mail.c2s_read
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_read.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mail.c2s_read";
        };

        return c2s_read;
    })();

    mail.s2c_read = (function() {

        /**
         * Properties of a s2c_read.
         * @memberof mail
         * @interface Is2c_read
         * @property {number|null} [err] s2c_read err
         */

        /**
         * Constructs a new s2c_read.
         * @memberof mail
         * @classdesc Represents a s2c_read.
         * @implements Is2c_read
         * @constructor
         * @param {mail.Is2c_read=} [properties] Properties to set
         */
        function s2c_read(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_read err.
         * @member {number} err
         * @memberof mail.s2c_read
         * @instance
         */
        s2c_read.prototype.err = 0;

        /**
         * Creates a new s2c_read instance using the specified properties.
         * @function create
         * @memberof mail.s2c_read
         * @static
         * @param {mail.Is2c_read=} [properties] Properties to set
         * @returns {mail.s2c_read} s2c_read instance
         */
        s2c_read.create = function create(properties) {
            return new s2c_read(properties);
        };

        /**
         * Encodes the specified s2c_read message. Does not implicitly {@link mail.s2c_read.verify|verify} messages.
         * @function encode
         * @memberof mail.s2c_read
         * @static
         * @param {mail.s2c_read} message s2c_read message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_read.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_read message from the specified reader or buffer.
         * @function decode
         * @memberof mail.s2c_read
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mail.s2c_read} s2c_read
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_read.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mail.s2c_read();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_read
         * @function getTypeUrl
         * @memberof mail.s2c_read
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_read.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mail.s2c_read";
        };

        return s2c_read;
    })();

    mail.c2s_receive_reward = (function() {

        /**
         * Properties of a c2s_receive_reward.
         * @memberof mail
         * @interface Ic2s_receive_reward
         * @property {Array.<number>|null} [mailUids] c2s_receive_reward mailUids
         */

        /**
         * Constructs a new c2s_receive_reward.
         * @memberof mail
         * @classdesc Represents a c2s_receive_reward.
         * @implements Ic2s_receive_reward
         * @constructor
         * @param {mail.Ic2s_receive_reward=} [properties] Properties to set
         */
        function c2s_receive_reward(properties) {
            this.mailUids = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_receive_reward mailUids.
         * @member {Array.<number>} mailUids
         * @memberof mail.c2s_receive_reward
         * @instance
         */
        c2s_receive_reward.prototype.mailUids = $util.emptyArray;

        /**
         * Creates a new c2s_receive_reward instance using the specified properties.
         * @function create
         * @memberof mail.c2s_receive_reward
         * @static
         * @param {mail.Ic2s_receive_reward=} [properties] Properties to set
         * @returns {mail.c2s_receive_reward} c2s_receive_reward instance
         */
        c2s_receive_reward.create = function create(properties) {
            return new c2s_receive_reward(properties);
        };

        /**
         * Encodes the specified c2s_receive_reward message. Does not implicitly {@link mail.c2s_receive_reward.verify|verify} messages.
         * @function encode
         * @memberof mail.c2s_receive_reward
         * @static
         * @param {mail.c2s_receive_reward} message c2s_receive_reward message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_receive_reward.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mailUids != null && message.mailUids.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (let i = 0; i < message.mailUids.length; ++i)
                    writer.uint32(message.mailUids[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a c2s_receive_reward message from the specified reader or buffer.
         * @function decode
         * @memberof mail.c2s_receive_reward
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mail.c2s_receive_reward} c2s_receive_reward
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_receive_reward.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mail.c2s_receive_reward();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.mailUids && message.mailUids.length))
                            message.mailUids = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.mailUids.push(reader.uint32());
                        } else
                            message.mailUids.push(reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_receive_reward
         * @function getTypeUrl
         * @memberof mail.c2s_receive_reward
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_receive_reward.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mail.c2s_receive_reward";
        };

        return c2s_receive_reward;
    })();

    mail.s2c_receive_reward = (function() {

        /**
         * Properties of a s2c_receive_reward.
         * @memberof mail
         * @interface Is2c_receive_reward
         * @property {number|null} [err] s2c_receive_reward err
         * @property {Array.<bag.ItemReward>|null} [rewards] s2c_receive_reward rewards
         */

        /**
         * Constructs a new s2c_receive_reward.
         * @memberof mail
         * @classdesc Represents a s2c_receive_reward.
         * @implements Is2c_receive_reward
         * @constructor
         * @param {mail.Is2c_receive_reward=} [properties] Properties to set
         */
        function s2c_receive_reward(properties) {
            this.rewards = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_receive_reward err.
         * @member {number} err
         * @memberof mail.s2c_receive_reward
         * @instance
         */
        s2c_receive_reward.prototype.err = 0;

        /**
         * s2c_receive_reward rewards.
         * @member {Array.<bag.ItemReward>} rewards
         * @memberof mail.s2c_receive_reward
         * @instance
         */
        s2c_receive_reward.prototype.rewards = $util.emptyArray;

        /**
         * Creates a new s2c_receive_reward instance using the specified properties.
         * @function create
         * @memberof mail.s2c_receive_reward
         * @static
         * @param {mail.Is2c_receive_reward=} [properties] Properties to set
         * @returns {mail.s2c_receive_reward} s2c_receive_reward instance
         */
        s2c_receive_reward.create = function create(properties) {
            return new s2c_receive_reward(properties);
        };

        /**
         * Encodes the specified s2c_receive_reward message. Does not implicitly {@link mail.s2c_receive_reward.verify|verify} messages.
         * @function encode
         * @memberof mail.s2c_receive_reward
         * @static
         * @param {mail.s2c_receive_reward} message s2c_receive_reward message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_receive_reward.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.rewards != null && message.rewards.length)
                for (let i = 0; i < message.rewards.length; ++i)
                    $root.bag.ItemReward.encode(message.rewards[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_receive_reward message from the specified reader or buffer.
         * @function decode
         * @memberof mail.s2c_receive_reward
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mail.s2c_receive_reward} s2c_receive_reward
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_receive_reward.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mail.s2c_receive_reward();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.rewards && message.rewards.length))
                            message.rewards = [];
                        message.rewards.push($root.bag.ItemReward.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_receive_reward
         * @function getTypeUrl
         * @memberof mail.s2c_receive_reward
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_receive_reward.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mail.s2c_receive_reward";
        };

        return s2c_receive_reward;
    })();

    mail.c2s_delete_mails = (function() {

        /**
         * Properties of a c2s_delete_mails.
         * @memberof mail
         * @interface Ic2s_delete_mails
         * @property {Array.<number>|null} [mailUids] c2s_delete_mails mailUids
         */

        /**
         * Constructs a new c2s_delete_mails.
         * @memberof mail
         * @classdesc Represents a c2s_delete_mails.
         * @implements Ic2s_delete_mails
         * @constructor
         * @param {mail.Ic2s_delete_mails=} [properties] Properties to set
         */
        function c2s_delete_mails(properties) {
            this.mailUids = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_delete_mails mailUids.
         * @member {Array.<number>} mailUids
         * @memberof mail.c2s_delete_mails
         * @instance
         */
        c2s_delete_mails.prototype.mailUids = $util.emptyArray;

        /**
         * Creates a new c2s_delete_mails instance using the specified properties.
         * @function create
         * @memberof mail.c2s_delete_mails
         * @static
         * @param {mail.Ic2s_delete_mails=} [properties] Properties to set
         * @returns {mail.c2s_delete_mails} c2s_delete_mails instance
         */
        c2s_delete_mails.create = function create(properties) {
            return new c2s_delete_mails(properties);
        };

        /**
         * Encodes the specified c2s_delete_mails message. Does not implicitly {@link mail.c2s_delete_mails.verify|verify} messages.
         * @function encode
         * @memberof mail.c2s_delete_mails
         * @static
         * @param {mail.c2s_delete_mails} message c2s_delete_mails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_delete_mails.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mailUids != null && message.mailUids.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (let i = 0; i < message.mailUids.length; ++i)
                    writer.uint32(message.mailUids[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a c2s_delete_mails message from the specified reader or buffer.
         * @function decode
         * @memberof mail.c2s_delete_mails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mail.c2s_delete_mails} c2s_delete_mails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_delete_mails.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mail.c2s_delete_mails();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.mailUids && message.mailUids.length))
                            message.mailUids = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.mailUids.push(reader.uint32());
                        } else
                            message.mailUids.push(reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_delete_mails
         * @function getTypeUrl
         * @memberof mail.c2s_delete_mails
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_delete_mails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mail.c2s_delete_mails";
        };

        return c2s_delete_mails;
    })();

    mail.s2c_delete_mails = (function() {

        /**
         * Properties of a s2c_delete_mails.
         * @memberof mail
         * @interface Is2c_delete_mails
         * @property {number|null} [err] s2c_delete_mails err
         */

        /**
         * Constructs a new s2c_delete_mails.
         * @memberof mail
         * @classdesc Represents a s2c_delete_mails.
         * @implements Is2c_delete_mails
         * @constructor
         * @param {mail.Is2c_delete_mails=} [properties] Properties to set
         */
        function s2c_delete_mails(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_delete_mails err.
         * @member {number} err
         * @memberof mail.s2c_delete_mails
         * @instance
         */
        s2c_delete_mails.prototype.err = 0;

        /**
         * Creates a new s2c_delete_mails instance using the specified properties.
         * @function create
         * @memberof mail.s2c_delete_mails
         * @static
         * @param {mail.Is2c_delete_mails=} [properties] Properties to set
         * @returns {mail.s2c_delete_mails} s2c_delete_mails instance
         */
        s2c_delete_mails.create = function create(properties) {
            return new s2c_delete_mails(properties);
        };

        /**
         * Encodes the specified s2c_delete_mails message. Does not implicitly {@link mail.s2c_delete_mails.verify|verify} messages.
         * @function encode
         * @memberof mail.s2c_delete_mails
         * @static
         * @param {mail.s2c_delete_mails} message s2c_delete_mails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_delete_mails.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_delete_mails message from the specified reader or buffer.
         * @function decode
         * @memberof mail.s2c_delete_mails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mail.s2c_delete_mails} s2c_delete_mails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_delete_mails.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mail.s2c_delete_mails();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_delete_mails
         * @function getTypeUrl
         * @memberof mail.s2c_delete_mails
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_delete_mails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mail.s2c_delete_mails";
        };

        return s2c_delete_mails;
    })();

    mail.c2s_receive_all = (function() {

        /**
         * Properties of a c2s_receive_all.
         * @memberof mail
         * @interface Ic2s_receive_all
         */

        /**
         * Constructs a new c2s_receive_all.
         * @memberof mail
         * @classdesc Represents a c2s_receive_all.
         * @implements Ic2s_receive_all
         * @constructor
         * @param {mail.Ic2s_receive_all=} [properties] Properties to set
         */
        function c2s_receive_all(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_receive_all instance using the specified properties.
         * @function create
         * @memberof mail.c2s_receive_all
         * @static
         * @param {mail.Ic2s_receive_all=} [properties] Properties to set
         * @returns {mail.c2s_receive_all} c2s_receive_all instance
         */
        c2s_receive_all.create = function create(properties) {
            return new c2s_receive_all(properties);
        };

        /**
         * Encodes the specified c2s_receive_all message. Does not implicitly {@link mail.c2s_receive_all.verify|verify} messages.
         * @function encode
         * @memberof mail.c2s_receive_all
         * @static
         * @param {mail.c2s_receive_all} message c2s_receive_all message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_receive_all.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_receive_all message from the specified reader or buffer.
         * @function decode
         * @memberof mail.c2s_receive_all
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mail.c2s_receive_all} c2s_receive_all
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_receive_all.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mail.c2s_receive_all();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_receive_all
         * @function getTypeUrl
         * @memberof mail.c2s_receive_all
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_receive_all.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mail.c2s_receive_all";
        };

        return c2s_receive_all;
    })();

    mail.s2c_receive_all = (function() {

        /**
         * Properties of a s2c_receive_all.
         * @memberof mail
         * @interface Is2c_receive_all
         * @property {number|null} [err] s2c_receive_all err
         * @property {Array.<bag.ItemReward>|null} [rewards] s2c_receive_all rewards
         */

        /**
         * Constructs a new s2c_receive_all.
         * @memberof mail
         * @classdesc Represents a s2c_receive_all.
         * @implements Is2c_receive_all
         * @constructor
         * @param {mail.Is2c_receive_all=} [properties] Properties to set
         */
        function s2c_receive_all(properties) {
            this.rewards = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_receive_all err.
         * @member {number} err
         * @memberof mail.s2c_receive_all
         * @instance
         */
        s2c_receive_all.prototype.err = 0;

        /**
         * s2c_receive_all rewards.
         * @member {Array.<bag.ItemReward>} rewards
         * @memberof mail.s2c_receive_all
         * @instance
         */
        s2c_receive_all.prototype.rewards = $util.emptyArray;

        /**
         * Creates a new s2c_receive_all instance using the specified properties.
         * @function create
         * @memberof mail.s2c_receive_all
         * @static
         * @param {mail.Is2c_receive_all=} [properties] Properties to set
         * @returns {mail.s2c_receive_all} s2c_receive_all instance
         */
        s2c_receive_all.create = function create(properties) {
            return new s2c_receive_all(properties);
        };

        /**
         * Encodes the specified s2c_receive_all message. Does not implicitly {@link mail.s2c_receive_all.verify|verify} messages.
         * @function encode
         * @memberof mail.s2c_receive_all
         * @static
         * @param {mail.s2c_receive_all} message s2c_receive_all message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_receive_all.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.rewards != null && message.rewards.length)
                for (let i = 0; i < message.rewards.length; ++i)
                    $root.bag.ItemReward.encode(message.rewards[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_receive_all message from the specified reader or buffer.
         * @function decode
         * @memberof mail.s2c_receive_all
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mail.s2c_receive_all} s2c_receive_all
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_receive_all.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mail.s2c_receive_all();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.rewards && message.rewards.length))
                            message.rewards = [];
                        message.rewards.push($root.bag.ItemReward.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_receive_all
         * @function getTypeUrl
         * @memberof mail.s2c_receive_all
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_receive_all.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mail.s2c_receive_all";
        };

        return s2c_receive_all;
    })();

    mail.notify_new_mails = (function() {

        /**
         * Properties of a notify_new_mails.
         * @memberof mail
         * @interface Inotify_new_mails
         * @property {Array.<mail.MailInfo>|null} [mails] notify_new_mails mails
         */

        /**
         * Constructs a new notify_new_mails.
         * @memberof mail
         * @classdesc Represents a notify_new_mails.
         * @implements Inotify_new_mails
         * @constructor
         * @param {mail.Inotify_new_mails=} [properties] Properties to set
         */
        function notify_new_mails(properties) {
            this.mails = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_new_mails mails.
         * @member {Array.<mail.MailInfo>} mails
         * @memberof mail.notify_new_mails
         * @instance
         */
        notify_new_mails.prototype.mails = $util.emptyArray;

        /**
         * Creates a new notify_new_mails instance using the specified properties.
         * @function create
         * @memberof mail.notify_new_mails
         * @static
         * @param {mail.Inotify_new_mails=} [properties] Properties to set
         * @returns {mail.notify_new_mails} notify_new_mails instance
         */
        notify_new_mails.create = function create(properties) {
            return new notify_new_mails(properties);
        };

        /**
         * Encodes the specified notify_new_mails message. Does not implicitly {@link mail.notify_new_mails.verify|verify} messages.
         * @function encode
         * @memberof mail.notify_new_mails
         * @static
         * @param {mail.notify_new_mails} message notify_new_mails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_new_mails.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mails != null && message.mails.length)
                for (let i = 0; i < message.mails.length; ++i)
                    $root.mail.MailInfo.encode(message.mails[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a notify_new_mails message from the specified reader or buffer.
         * @function decode
         * @memberof mail.notify_new_mails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mail.notify_new_mails} notify_new_mails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_new_mails.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mail.notify_new_mails();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.mails && message.mails.length))
                            message.mails = [];
                        message.mails.push($root.mail.MailInfo.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_new_mails
         * @function getTypeUrl
         * @memberof mail.notify_new_mails
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_new_mails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mail.notify_new_mails";
        };

        return notify_new_mails;
    })();

    return mail;
})();

export const mainline = $root.mainline = (() => {

    /**
     * Namespace mainline.
     * @exports mainline
     * @namespace
     */
    const mainline = {};

    mainline.Position = (function() {

        /**
         * Properties of a Position.
         * @memberof mainline
         * @interface IPosition
         * @property {number|null} [x] Position x
         * @property {number|null} [z] Position z
         * @property {number|null} [rotation] Position rotation
         */

        /**
         * Constructs a new Position.
         * @memberof mainline
         * @classdesc Represents a Position.
         * @implements IPosition
         * @constructor
         * @param {mainline.IPosition=} [properties] Properties to set
         */
        function Position(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Position x.
         * @member {number} x
         * @memberof mainline.Position
         * @instance
         */
        Position.prototype.x = 0;

        /**
         * Position z.
         * @member {number} z
         * @memberof mainline.Position
         * @instance
         */
        Position.prototype.z = 0;

        /**
         * Position rotation.
         * @member {number} rotation
         * @memberof mainline.Position
         * @instance
         */
        Position.prototype.rotation = 0;

        /**
         * Creates a new Position instance using the specified properties.
         * @function create
         * @memberof mainline.Position
         * @static
         * @param {mainline.IPosition=} [properties] Properties to set
         * @returns {mainline.Position} Position instance
         */
        Position.create = function create(properties) {
            return new Position(properties);
        };

        /**
         * Encodes the specified Position message. Does not implicitly {@link mainline.Position.verify|verify} messages.
         * @function encode
         * @memberof mainline.Position
         * @static
         * @param {mainline.Position} message Position message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Position.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.x);
            if (message.z != null && Object.hasOwnProperty.call(message, "z"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.z);
            if (message.rotation != null && Object.hasOwnProperty.call(message, "rotation"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.rotation);
            return writer;
        };

        /**
         * Decodes a Position message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.Position
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.Position} Position
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Position.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.Position();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.x = reader.uint32();
                        break;
                    }
                case 2: {
                        message.z = reader.uint32();
                        break;
                    }
                case 3: {
                        message.rotation = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for Position
         * @function getTypeUrl
         * @memberof mainline.Position
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Position.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.Position";
        };

        return Position;
    })();

    mainline.Health = (function() {

        /**
         * Properties of a Health.
         * @memberof mainline
         * @interface IHealth
         * @property {number|null} [hp] Health hp
         * @property {number|null} [maxHp] Health maxHp
         */

        /**
         * Constructs a new Health.
         * @memberof mainline
         * @classdesc Represents a Health.
         * @implements IHealth
         * @constructor
         * @param {mainline.IHealth=} [properties] Properties to set
         */
        function Health(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Health hp.
         * @member {number} hp
         * @memberof mainline.Health
         * @instance
         */
        Health.prototype.hp = 0;

        /**
         * Health maxHp.
         * @member {number} maxHp
         * @memberof mainline.Health
         * @instance
         */
        Health.prototype.maxHp = 0;

        /**
         * Creates a new Health instance using the specified properties.
         * @function create
         * @memberof mainline.Health
         * @static
         * @param {mainline.IHealth=} [properties] Properties to set
         * @returns {mainline.Health} Health instance
         */
        Health.create = function create(properties) {
            return new Health(properties);
        };

        /**
         * Encodes the specified Health message. Does not implicitly {@link mainline.Health.verify|verify} messages.
         * @function encode
         * @memberof mainline.Health
         * @static
         * @param {mainline.Health} message Health message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Health.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hp != null && Object.hasOwnProperty.call(message, "hp"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.hp);
            if (message.maxHp != null && Object.hasOwnProperty.call(message, "maxHp"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.maxHp);
            return writer;
        };

        /**
         * Decodes a Health message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.Health
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.Health} Health
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Health.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.Health();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.hp = reader.uint32();
                        break;
                    }
                case 2: {
                        message.maxHp = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for Health
         * @function getTypeUrl
         * @memberof mainline.Health
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Health.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.Health";
        };

        return Health;
    })();

    mainline.Charactor = (function() {

        /**
         * Properties of a Charactor.
         * @memberof mainline
         * @interface ICharactor
         * @property {number|null} [tid] Charactor tid
         * @property {mainline.Position|null} [pos] Charactor pos
         * @property {mainline.Health|null} [health] Charactor health
         * @property {number|null} [unlockSoldiers] Charactor unlockSoldiers
         * @property {number|null} [level] Charactor level
         * @property {number|null} [stage] Charactor stage
         */

        /**
         * Constructs a new Charactor.
         * @memberof mainline
         * @classdesc Represents a Charactor.
         * @implements ICharactor
         * @constructor
         * @param {mainline.ICharactor=} [properties] Properties to set
         */
        function Charactor(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Charactor tid.
         * @member {number} tid
         * @memberof mainline.Charactor
         * @instance
         */
        Charactor.prototype.tid = 0;

        /**
         * Charactor pos.
         * @member {mainline.Position|null|undefined} pos
         * @memberof mainline.Charactor
         * @instance
         */
        Charactor.prototype.pos = null;

        /**
         * Charactor health.
         * @member {mainline.Health|null|undefined} health
         * @memberof mainline.Charactor
         * @instance
         */
        Charactor.prototype.health = null;

        /**
         * Charactor unlockSoldiers.
         * @member {number} unlockSoldiers
         * @memberof mainline.Charactor
         * @instance
         */
        Charactor.prototype.unlockSoldiers = 0;

        /**
         * Charactor level.
         * @member {number} level
         * @memberof mainline.Charactor
         * @instance
         */
        Charactor.prototype.level = 0;

        /**
         * Charactor stage.
         * @member {number} stage
         * @memberof mainline.Charactor
         * @instance
         */
        Charactor.prototype.stage = 0;

        /**
         * Creates a new Charactor instance using the specified properties.
         * @function create
         * @memberof mainline.Charactor
         * @static
         * @param {mainline.ICharactor=} [properties] Properties to set
         * @returns {mainline.Charactor} Charactor instance
         */
        Charactor.create = function create(properties) {
            return new Charactor(properties);
        };

        /**
         * Encodes the specified Charactor message. Does not implicitly {@link mainline.Charactor.verify|verify} messages.
         * @function encode
         * @memberof mainline.Charactor
         * @static
         * @param {mainline.Charactor} message Charactor message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Charactor.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tid != null && Object.hasOwnProperty.call(message, "tid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.tid);
            if (message.pos != null && Object.hasOwnProperty.call(message, "pos"))
                $root.mainline.Position.encode(message.pos, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.health != null && Object.hasOwnProperty.call(message, "health"))
                $root.mainline.Health.encode(message.health, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.unlockSoldiers != null && Object.hasOwnProperty.call(message, "unlockSoldiers"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.unlockSoldiers);
            if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.level);
            if (message.stage != null && Object.hasOwnProperty.call(message, "stage"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.stage);
            return writer;
        };

        /**
         * Decodes a Charactor message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.Charactor
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.Charactor} Charactor
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Charactor.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.Charactor();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.tid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.pos = $root.mainline.Position.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.health = $root.mainline.Health.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.unlockSoldiers = reader.uint32();
                        break;
                    }
                case 5: {
                        message.level = reader.uint32();
                        break;
                    }
                case 6: {
                        message.stage = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for Charactor
         * @function getTypeUrl
         * @memberof mainline.Charactor
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Charactor.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.Charactor";
        };

        return Charactor;
    })();

    mainline.SoldierGroup = (function() {

        /**
         * Properties of a SoldierGroup.
         * @memberof mainline
         * @interface ISoldierGroup
         * @property {number|null} [idx] SoldierGroup idx
         * @property {number|null} [tid] SoldierGroup tid
         * @property {Array.<mainline.Soldier>|null} [soldiers] SoldierGroup soldiers
         * @property {number|null} [level] SoldierGroup level
         * @property {number|null} [stage] SoldierGroup stage
         */

        /**
         * Constructs a new SoldierGroup.
         * @memberof mainline
         * @classdesc Represents a SoldierGroup.
         * @implements ISoldierGroup
         * @constructor
         * @param {mainline.ISoldierGroup=} [properties] Properties to set
         */
        function SoldierGroup(properties) {
            this.soldiers = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SoldierGroup idx.
         * @member {number} idx
         * @memberof mainline.SoldierGroup
         * @instance
         */
        SoldierGroup.prototype.idx = 0;

        /**
         * SoldierGroup tid.
         * @member {number} tid
         * @memberof mainline.SoldierGroup
         * @instance
         */
        SoldierGroup.prototype.tid = 0;

        /**
         * SoldierGroup soldiers.
         * @member {Array.<mainline.Soldier>} soldiers
         * @memberof mainline.SoldierGroup
         * @instance
         */
        SoldierGroup.prototype.soldiers = $util.emptyArray;

        /**
         * SoldierGroup level.
         * @member {number} level
         * @memberof mainline.SoldierGroup
         * @instance
         */
        SoldierGroup.prototype.level = 0;

        /**
         * SoldierGroup stage.
         * @member {number} stage
         * @memberof mainline.SoldierGroup
         * @instance
         */
        SoldierGroup.prototype.stage = 0;

        /**
         * Creates a new SoldierGroup instance using the specified properties.
         * @function create
         * @memberof mainline.SoldierGroup
         * @static
         * @param {mainline.ISoldierGroup=} [properties] Properties to set
         * @returns {mainline.SoldierGroup} SoldierGroup instance
         */
        SoldierGroup.create = function create(properties) {
            return new SoldierGroup(properties);
        };

        /**
         * Encodes the specified SoldierGroup message. Does not implicitly {@link mainline.SoldierGroup.verify|verify} messages.
         * @function encode
         * @memberof mainline.SoldierGroup
         * @static
         * @param {mainline.SoldierGroup} message SoldierGroup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SoldierGroup.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.idx != null && Object.hasOwnProperty.call(message, "idx"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.idx);
            if (message.tid != null && Object.hasOwnProperty.call(message, "tid"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.tid);
            if (message.soldiers != null && message.soldiers.length)
                for (let i = 0; i < message.soldiers.length; ++i)
                    $root.mainline.Soldier.encode(message.soldiers[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.level);
            if (message.stage != null && Object.hasOwnProperty.call(message, "stage"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.stage);
            return writer;
        };

        /**
         * Decodes a SoldierGroup message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.SoldierGroup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.SoldierGroup} SoldierGroup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SoldierGroup.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.SoldierGroup();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.idx = reader.uint32();
                        break;
                    }
                case 2: {
                        message.tid = reader.uint32();
                        break;
                    }
                case 3: {
                        if (!(message.soldiers && message.soldiers.length))
                            message.soldiers = [];
                        message.soldiers.push($root.mainline.Soldier.decode(reader, reader.uint32()));
                        break;
                    }
                case 4: {
                        message.level = reader.uint32();
                        break;
                    }
                case 5: {
                        message.stage = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for SoldierGroup
         * @function getTypeUrl
         * @memberof mainline.SoldierGroup
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SoldierGroup.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.SoldierGroup";
        };

        return SoldierGroup;
    })();

    mainline.Soldier = (function() {

        /**
         * Properties of a Soldier.
         * @memberof mainline
         * @interface ISoldier
         * @property {number|null} [idx] Soldier idx
         * @property {mainline.Position|null} [pos] Soldier pos
         * @property {mainline.Health|null} [health] Soldier health
         */

        /**
         * Constructs a new Soldier.
         * @memberof mainline
         * @classdesc Represents a Soldier.
         * @implements ISoldier
         * @constructor
         * @param {mainline.ISoldier=} [properties] Properties to set
         */
        function Soldier(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Soldier idx.
         * @member {number} idx
         * @memberof mainline.Soldier
         * @instance
         */
        Soldier.prototype.idx = 0;

        /**
         * Soldier pos.
         * @member {mainline.Position|null|undefined} pos
         * @memberof mainline.Soldier
         * @instance
         */
        Soldier.prototype.pos = null;

        /**
         * Soldier health.
         * @member {mainline.Health|null|undefined} health
         * @memberof mainline.Soldier
         * @instance
         */
        Soldier.prototype.health = null;

        /**
         * Creates a new Soldier instance using the specified properties.
         * @function create
         * @memberof mainline.Soldier
         * @static
         * @param {mainline.ISoldier=} [properties] Properties to set
         * @returns {mainline.Soldier} Soldier instance
         */
        Soldier.create = function create(properties) {
            return new Soldier(properties);
        };

        /**
         * Encodes the specified Soldier message. Does not implicitly {@link mainline.Soldier.verify|verify} messages.
         * @function encode
         * @memberof mainline.Soldier
         * @static
         * @param {mainline.Soldier} message Soldier message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Soldier.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.idx != null && Object.hasOwnProperty.call(message, "idx"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.idx);
            if (message.pos != null && Object.hasOwnProperty.call(message, "pos"))
                $root.mainline.Position.encode(message.pos, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.health != null && Object.hasOwnProperty.call(message, "health"))
                $root.mainline.Health.encode(message.health, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Soldier message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.Soldier
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.Soldier} Soldier
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Soldier.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.Soldier();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.idx = reader.uint32();
                        break;
                    }
                case 2: {
                        message.pos = $root.mainline.Position.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.health = $root.mainline.Health.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for Soldier
         * @function getTypeUrl
         * @memberof mainline.Soldier
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Soldier.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.Soldier";
        };

        return Soldier;
    })();

    mainline.MonsterTroop = (function() {

        /**
         * Properties of a MonsterTroop.
         * @memberof mainline
         * @interface IMonsterTroop
         * @property {number|null} [key] MonsterTroop key
         * @property {number|null} [tid] MonsterTroop tid
         * @property {number|null} [deathTime] MonsterTroop deathTime
         * @property {number|null} [deathCount] MonsterTroop deathCount
         * @property {Array.<mainline.Monster>|null} [monsters] MonsterTroop monsters
         */

        /**
         * Constructs a new MonsterTroop.
         * @memberof mainline
         * @classdesc Represents a MonsterTroop.
         * @implements IMonsterTroop
         * @constructor
         * @param {mainline.IMonsterTroop=} [properties] Properties to set
         */
        function MonsterTroop(properties) {
            this.monsters = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MonsterTroop key.
         * @member {number} key
         * @memberof mainline.MonsterTroop
         * @instance
         */
        MonsterTroop.prototype.key = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * MonsterTroop tid.
         * @member {number} tid
         * @memberof mainline.MonsterTroop
         * @instance
         */
        MonsterTroop.prototype.tid = 0;

        /**
         * MonsterTroop deathTime.
         * @member {number} deathTime
         * @memberof mainline.MonsterTroop
         * @instance
         */
        MonsterTroop.prototype.deathTime = 0;

        /**
         * MonsterTroop deathCount.
         * @member {number} deathCount
         * @memberof mainline.MonsterTroop
         * @instance
         */
        MonsterTroop.prototype.deathCount = 0;

        /**
         * MonsterTroop monsters.
         * @member {Array.<mainline.Monster>} monsters
         * @memberof mainline.MonsterTroop
         * @instance
         */
        MonsterTroop.prototype.monsters = $util.emptyArray;

        /**
         * Creates a new MonsterTroop instance using the specified properties.
         * @function create
         * @memberof mainline.MonsterTroop
         * @static
         * @param {mainline.IMonsterTroop=} [properties] Properties to set
         * @returns {mainline.MonsterTroop} MonsterTroop instance
         */
        MonsterTroop.create = function create(properties) {
            return new MonsterTroop(properties);
        };

        /**
         * Encodes the specified MonsterTroop message. Does not implicitly {@link mainline.MonsterTroop.verify|verify} messages.
         * @function encode
         * @memberof mainline.MonsterTroop
         * @static
         * @param {mainline.MonsterTroop} message MonsterTroop message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonsterTroop.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.key);
            if (message.tid != null && Object.hasOwnProperty.call(message, "tid"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.tid);
            if (message.deathTime != null && Object.hasOwnProperty.call(message, "deathTime"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.deathTime);
            if (message.deathCount != null && Object.hasOwnProperty.call(message, "deathCount"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.deathCount);
            if (message.monsters != null && message.monsters.length)
                for (let i = 0; i < message.monsters.length; ++i)
                    $root.mainline.Monster.encode(message.monsters[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MonsterTroop message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.MonsterTroop
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.MonsterTroop} MonsterTroop
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonsterTroop.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.MonsterTroop();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.uint64();
                        break;
                    }
                case 2: {
                        message.tid = reader.uint32();
                        break;
                    }
                case 3: {
                        message.deathTime = reader.uint32();
                        break;
                    }
                case 4: {
                        message.deathCount = reader.uint32();
                        break;
                    }
                case 5: {
                        if (!(message.monsters && message.monsters.length))
                            message.monsters = [];
                        message.monsters.push($root.mainline.Monster.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for MonsterTroop
         * @function getTypeUrl
         * @memberof mainline.MonsterTroop
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MonsterTroop.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.MonsterTroop";
        };

        return MonsterTroop;
    })();

    mainline.Monster = (function() {

        /**
         * Properties of a Monster.
         * @memberof mainline
         * @interface IMonster
         * @property {number|null} [idx] Monster idx
         * @property {mainline.Position|null} [pos] Monster pos
         * @property {mainline.Health|null} [health] Monster health
         */

        /**
         * Constructs a new Monster.
         * @memberof mainline
         * @classdesc Represents a Monster.
         * @implements IMonster
         * @constructor
         * @param {mainline.IMonster=} [properties] Properties to set
         */
        function Monster(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Monster idx.
         * @member {number} idx
         * @memberof mainline.Monster
         * @instance
         */
        Monster.prototype.idx = 0;

        /**
         * Monster pos.
         * @member {mainline.Position|null|undefined} pos
         * @memberof mainline.Monster
         * @instance
         */
        Monster.prototype.pos = null;

        /**
         * Monster health.
         * @member {mainline.Health|null|undefined} health
         * @memberof mainline.Monster
         * @instance
         */
        Monster.prototype.health = null;

        /**
         * Creates a new Monster instance using the specified properties.
         * @function create
         * @memberof mainline.Monster
         * @static
         * @param {mainline.IMonster=} [properties] Properties to set
         * @returns {mainline.Monster} Monster instance
         */
        Monster.create = function create(properties) {
            return new Monster(properties);
        };

        /**
         * Encodes the specified Monster message. Does not implicitly {@link mainline.Monster.verify|verify} messages.
         * @function encode
         * @memberof mainline.Monster
         * @static
         * @param {mainline.Monster} message Monster message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Monster.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.idx != null && Object.hasOwnProperty.call(message, "idx"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.idx);
            if (message.pos != null && Object.hasOwnProperty.call(message, "pos"))
                $root.mainline.Position.encode(message.pos, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.health != null && Object.hasOwnProperty.call(message, "health"))
                $root.mainline.Health.encode(message.health, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Monster message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.Monster
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.Monster} Monster
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Monster.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.Monster();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.idx = reader.uint32();
                        break;
                    }
                case 2: {
                        message.pos = $root.mainline.Position.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.health = $root.mainline.Health.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for Monster
         * @function getTypeUrl
         * @memberof mainline.Monster
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Monster.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.Monster";
        };

        return Monster;
    })();

    mainline.Truck = (function() {

        /**
         * Properties of a Truck.
         * @memberof mainline
         * @interface ITruck
         * @property {number|null} [collectType] Truck collectType
         * @property {number|null} [amount] Truck amount
         * @property {mainline.Position|null} [pos] Truck pos
         */

        /**
         * Constructs a new Truck.
         * @memberof mainline
         * @classdesc Represents a Truck.
         * @implements ITruck
         * @constructor
         * @param {mainline.ITruck=} [properties] Properties to set
         */
        function Truck(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Truck collectType.
         * @member {number} collectType
         * @memberof mainline.Truck
         * @instance
         */
        Truck.prototype.collectType = 0;

        /**
         * Truck amount.
         * @member {number} amount
         * @memberof mainline.Truck
         * @instance
         */
        Truck.prototype.amount = 0;

        /**
         * Truck pos.
         * @member {mainline.Position|null|undefined} pos
         * @memberof mainline.Truck
         * @instance
         */
        Truck.prototype.pos = null;

        /**
         * Creates a new Truck instance using the specified properties.
         * @function create
         * @memberof mainline.Truck
         * @static
         * @param {mainline.ITruck=} [properties] Properties to set
         * @returns {mainline.Truck} Truck instance
         */
        Truck.create = function create(properties) {
            return new Truck(properties);
        };

        /**
         * Encodes the specified Truck message. Does not implicitly {@link mainline.Truck.verify|verify} messages.
         * @function encode
         * @memberof mainline.Truck
         * @static
         * @param {mainline.Truck} message Truck message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Truck.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.collectType != null && Object.hasOwnProperty.call(message, "collectType"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.collectType);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.amount);
            if (message.pos != null && Object.hasOwnProperty.call(message, "pos"))
                $root.mainline.Position.encode(message.pos, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Truck message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.Truck
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.Truck} Truck
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Truck.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.Truck();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.collectType = reader.uint32();
                        break;
                    }
                case 2: {
                        message.amount = reader.uint32();
                        break;
                    }
                case 3: {
                        message.pos = $root.mainline.Position.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for Truck
         * @function getTypeUrl
         * @memberof mainline.Truck
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Truck.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.Truck";
        };

        return Truck;
    })();

    mainline.Event = (function() {

        /**
         * Properties of an Event.
         * @memberof mainline
         * @interface IEvent
         * @property {number|null} [key] Event key
         * @property {number|null} [tid] Event tid
         * @property {number|null} [triggerTime] Event triggerTime
         */

        /**
         * Constructs a new Event.
         * @memberof mainline
         * @classdesc Represents an Event.
         * @implements IEvent
         * @constructor
         * @param {mainline.IEvent=} [properties] Properties to set
         */
        function Event(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Event key.
         * @member {number} key
         * @memberof mainline.Event
         * @instance
         */
        Event.prototype.key = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Event tid.
         * @member {number} tid
         * @memberof mainline.Event
         * @instance
         */
        Event.prototype.tid = 0;

        /**
         * Event triggerTime.
         * @member {number} triggerTime
         * @memberof mainline.Event
         * @instance
         */
        Event.prototype.triggerTime = 0;

        /**
         * Creates a new Event instance using the specified properties.
         * @function create
         * @memberof mainline.Event
         * @static
         * @param {mainline.IEvent=} [properties] Properties to set
         * @returns {mainline.Event} Event instance
         */
        Event.create = function create(properties) {
            return new Event(properties);
        };

        /**
         * Encodes the specified Event message. Does not implicitly {@link mainline.Event.verify|verify} messages.
         * @function encode
         * @memberof mainline.Event
         * @static
         * @param {mainline.Event} message Event message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Event.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.key);
            if (message.tid != null && Object.hasOwnProperty.call(message, "tid"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.tid);
            if (message.triggerTime != null && Object.hasOwnProperty.call(message, "triggerTime"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.triggerTime);
            return writer;
        };

        /**
         * Decodes an Event message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.Event
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.Event} Event
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Event.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.Event();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.uint64();
                        break;
                    }
                case 2: {
                        message.tid = reader.uint32();
                        break;
                    }
                case 3: {
                        message.triggerTime = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for Event
         * @function getTypeUrl
         * @memberof mainline.Event
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Event.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.Event";
        };

        return Event;
    })();

    mainline.Building = (function() {

        /**
         * Properties of a Building.
         * @memberof mainline
         * @interface IBuilding
         * @property {number|null} [key] Building key
         * @property {number|null} [tid] Building tid
         * @property {number|null} [level] Building level
         * @property {number|null} [upgradeTime] Building upgradeTime
         * @property {number|null} [harvestTime] Building harvestTime
         */

        /**
         * Constructs a new Building.
         * @memberof mainline
         * @classdesc Represents a Building.
         * @implements IBuilding
         * @constructor
         * @param {mainline.IBuilding=} [properties] Properties to set
         */
        function Building(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Building key.
         * @member {number} key
         * @memberof mainline.Building
         * @instance
         */
        Building.prototype.key = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Building tid.
         * @member {number} tid
         * @memberof mainline.Building
         * @instance
         */
        Building.prototype.tid = 0;

        /**
         * Building level.
         * @member {number} level
         * @memberof mainline.Building
         * @instance
         */
        Building.prototype.level = 0;

        /**
         * Building upgradeTime.
         * @member {number} upgradeTime
         * @memberof mainline.Building
         * @instance
         */
        Building.prototype.upgradeTime = 0;

        /**
         * Building harvestTime.
         * @member {number} harvestTime
         * @memberof mainline.Building
         * @instance
         */
        Building.prototype.harvestTime = 0;

        /**
         * Creates a new Building instance using the specified properties.
         * @function create
         * @memberof mainline.Building
         * @static
         * @param {mainline.IBuilding=} [properties] Properties to set
         * @returns {mainline.Building} Building instance
         */
        Building.create = function create(properties) {
            return new Building(properties);
        };

        /**
         * Encodes the specified Building message. Does not implicitly {@link mainline.Building.verify|verify} messages.
         * @function encode
         * @memberof mainline.Building
         * @static
         * @param {mainline.Building} message Building message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Building.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.key);
            if (message.tid != null && Object.hasOwnProperty.call(message, "tid"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.tid);
            if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.level);
            if (message.upgradeTime != null && Object.hasOwnProperty.call(message, "upgradeTime"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.upgradeTime);
            if (message.harvestTime != null && Object.hasOwnProperty.call(message, "harvestTime"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.harvestTime);
            return writer;
        };

        /**
         * Decodes a Building message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.Building
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.Building} Building
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Building.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.Building();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.uint64();
                        break;
                    }
                case 2: {
                        message.tid = reader.uint32();
                        break;
                    }
                case 3: {
                        message.level = reader.uint32();
                        break;
                    }
                case 4: {
                        message.upgradeTime = reader.uint32();
                        break;
                    }
                case 5: {
                        message.harvestTime = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for Building
         * @function getTypeUrl
         * @memberof mainline.Building
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Building.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.Building";
        };

        return Building;
    })();

    mainline.Collection = (function() {

        /**
         * Properties of a Collection.
         * @memberof mainline
         * @interface ICollection
         * @property {number|null} [key] Collection key
         * @property {mainline.Health|null} [health] Collection health
         * @property {number|null} [deathTime] Collection deathTime
         */

        /**
         * Constructs a new Collection.
         * @memberof mainline
         * @classdesc Represents a Collection.
         * @implements ICollection
         * @constructor
         * @param {mainline.ICollection=} [properties] Properties to set
         */
        function Collection(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Collection key.
         * @member {number} key
         * @memberof mainline.Collection
         * @instance
         */
        Collection.prototype.key = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Collection health.
         * @member {mainline.Health|null|undefined} health
         * @memberof mainline.Collection
         * @instance
         */
        Collection.prototype.health = null;

        /**
         * Collection deathTime.
         * @member {number} deathTime
         * @memberof mainline.Collection
         * @instance
         */
        Collection.prototype.deathTime = 0;

        /**
         * Creates a new Collection instance using the specified properties.
         * @function create
         * @memberof mainline.Collection
         * @static
         * @param {mainline.ICollection=} [properties] Properties to set
         * @returns {mainline.Collection} Collection instance
         */
        Collection.create = function create(properties) {
            return new Collection(properties);
        };

        /**
         * Encodes the specified Collection message. Does not implicitly {@link mainline.Collection.verify|verify} messages.
         * @function encode
         * @memberof mainline.Collection
         * @static
         * @param {mainline.Collection} message Collection message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Collection.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.key);
            if (message.health != null && Object.hasOwnProperty.call(message, "health"))
                $root.mainline.Health.encode(message.health, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.deathTime != null && Object.hasOwnProperty.call(message, "deathTime"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.deathTime);
            return writer;
        };

        /**
         * Decodes a Collection message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.Collection
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.Collection} Collection
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Collection.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.Collection();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.uint64();
                        break;
                    }
                case 2: {
                        message.health = $root.mainline.Health.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.deathTime = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for Collection
         * @function getTypeUrl
         * @memberof mainline.Collection
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Collection.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.Collection";
        };

        return Collection;
    })();

    mainline.Spoils = (function() {

        /**
         * Properties of a Spoils.
         * @memberof mainline
         * @interface ISpoils
         * @property {number|null} [tid] Spoils tid
         * @property {mainline.Position|null} [pos] Spoils pos
         * @property {number|null} [dropTime] Spoils dropTime
         */

        /**
         * Constructs a new Spoils.
         * @memberof mainline
         * @classdesc Represents a Spoils.
         * @implements ISpoils
         * @constructor
         * @param {mainline.ISpoils=} [properties] Properties to set
         */
        function Spoils(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Spoils tid.
         * @member {number} tid
         * @memberof mainline.Spoils
         * @instance
         */
        Spoils.prototype.tid = 0;

        /**
         * Spoils pos.
         * @member {mainline.Position|null|undefined} pos
         * @memberof mainline.Spoils
         * @instance
         */
        Spoils.prototype.pos = null;

        /**
         * Spoils dropTime.
         * @member {number} dropTime
         * @memberof mainline.Spoils
         * @instance
         */
        Spoils.prototype.dropTime = 0;

        /**
         * Creates a new Spoils instance using the specified properties.
         * @function create
         * @memberof mainline.Spoils
         * @static
         * @param {mainline.ISpoils=} [properties] Properties to set
         * @returns {mainline.Spoils} Spoils instance
         */
        Spoils.create = function create(properties) {
            return new Spoils(properties);
        };

        /**
         * Encodes the specified Spoils message. Does not implicitly {@link mainline.Spoils.verify|verify} messages.
         * @function encode
         * @memberof mainline.Spoils
         * @static
         * @param {mainline.Spoils} message Spoils message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Spoils.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tid != null && Object.hasOwnProperty.call(message, "tid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.tid);
            if (message.pos != null && Object.hasOwnProperty.call(message, "pos"))
                $root.mainline.Position.encode(message.pos, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.dropTime != null && Object.hasOwnProperty.call(message, "dropTime"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.dropTime);
            return writer;
        };

        /**
         * Decodes a Spoils message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.Spoils
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.Spoils} Spoils
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Spoils.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.Spoils();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.tid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.pos = $root.mainline.Position.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.dropTime = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for Spoils
         * @function getTypeUrl
         * @memberof mainline.Spoils
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Spoils.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.Spoils";
        };

        return Spoils;
    })();

    mainline.Npc = (function() {

        /**
         * Properties of a Npc.
         * @memberof mainline
         * @interface INpc
         * @property {number|null} [key] Npc key
         * @property {number|null} [state] Npc state
         */

        /**
         * Constructs a new Npc.
         * @memberof mainline
         * @classdesc Represents a Npc.
         * @implements INpc
         * @constructor
         * @param {mainline.INpc=} [properties] Properties to set
         */
        function Npc(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Npc key.
         * @member {number} key
         * @memberof mainline.Npc
         * @instance
         */
        Npc.prototype.key = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Npc state.
         * @member {number} state
         * @memberof mainline.Npc
         * @instance
         */
        Npc.prototype.state = 0;

        /**
         * Creates a new Npc instance using the specified properties.
         * @function create
         * @memberof mainline.Npc
         * @static
         * @param {mainline.INpc=} [properties] Properties to set
         * @returns {mainline.Npc} Npc instance
         */
        Npc.create = function create(properties) {
            return new Npc(properties);
        };

        /**
         * Encodes the specified Npc message. Does not implicitly {@link mainline.Npc.verify|verify} messages.
         * @function encode
         * @memberof mainline.Npc
         * @static
         * @param {mainline.Npc} message Npc message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Npc.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.key);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.state);
            return writer;
        };

        /**
         * Decodes a Npc message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.Npc
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.Npc} Npc
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Npc.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.Npc();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.uint64();
                        break;
                    }
                case 2: {
                        message.state = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for Npc
         * @function getTypeUrl
         * @memberof mainline.Npc
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Npc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.Npc";
        };

        return Npc;
    })();

    mainline.MainLineData = (function() {

        /**
         * Properties of a MainLineData.
         * @memberof mainline
         * @interface IMainLineData
         * @property {mainline.Charactor|null} [hero] MainLineData hero
         * @property {Array.<mainline.SoldierGroup>|null} [soldierGroups] MainLineData soldierGroups
         * @property {Array.<mainline.MonsterTroop>|null} [monsterTroops] MainLineData monsterTroops
         * @property {Array.<mainline.Truck>|null} [trucks] MainLineData trucks
         * @property {Array.<mainline.Event>|null} [events] MainLineData events
         * @property {Array.<mainline.Building>|null} [buildings] MainLineData buildings
         * @property {Array.<mainline.Collection>|null} [collections] MainLineData collections
         * @property {Array.<mainline.Spoils>|null} [spoils] MainLineData spoils
         * @property {Array.<mainline.Npc>|null} [npcs] MainLineData npcs
         */

        /**
         * Constructs a new MainLineData.
         * @memberof mainline
         * @classdesc Represents a MainLineData.
         * @implements IMainLineData
         * @constructor
         * @param {mainline.IMainLineData=} [properties] Properties to set
         */
        function MainLineData(properties) {
            this.soldierGroups = [];
            this.monsterTroops = [];
            this.trucks = [];
            this.events = [];
            this.buildings = [];
            this.collections = [];
            this.spoils = [];
            this.npcs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MainLineData hero.
         * @member {mainline.Charactor|null|undefined} hero
         * @memberof mainline.MainLineData
         * @instance
         */
        MainLineData.prototype.hero = null;

        /**
         * MainLineData soldierGroups.
         * @member {Array.<mainline.SoldierGroup>} soldierGroups
         * @memberof mainline.MainLineData
         * @instance
         */
        MainLineData.prototype.soldierGroups = $util.emptyArray;

        /**
         * MainLineData monsterTroops.
         * @member {Array.<mainline.MonsterTroop>} monsterTroops
         * @memberof mainline.MainLineData
         * @instance
         */
        MainLineData.prototype.monsterTroops = $util.emptyArray;

        /**
         * MainLineData trucks.
         * @member {Array.<mainline.Truck>} trucks
         * @memberof mainline.MainLineData
         * @instance
         */
        MainLineData.prototype.trucks = $util.emptyArray;

        /**
         * MainLineData events.
         * @member {Array.<mainline.Event>} events
         * @memberof mainline.MainLineData
         * @instance
         */
        MainLineData.prototype.events = $util.emptyArray;

        /**
         * MainLineData buildings.
         * @member {Array.<mainline.Building>} buildings
         * @memberof mainline.MainLineData
         * @instance
         */
        MainLineData.prototype.buildings = $util.emptyArray;

        /**
         * MainLineData collections.
         * @member {Array.<mainline.Collection>} collections
         * @memberof mainline.MainLineData
         * @instance
         */
        MainLineData.prototype.collections = $util.emptyArray;

        /**
         * MainLineData spoils.
         * @member {Array.<mainline.Spoils>} spoils
         * @memberof mainline.MainLineData
         * @instance
         */
        MainLineData.prototype.spoils = $util.emptyArray;

        /**
         * MainLineData npcs.
         * @member {Array.<mainline.Npc>} npcs
         * @memberof mainline.MainLineData
         * @instance
         */
        MainLineData.prototype.npcs = $util.emptyArray;

        /**
         * Creates a new MainLineData instance using the specified properties.
         * @function create
         * @memberof mainline.MainLineData
         * @static
         * @param {mainline.IMainLineData=} [properties] Properties to set
         * @returns {mainline.MainLineData} MainLineData instance
         */
        MainLineData.create = function create(properties) {
            return new MainLineData(properties);
        };

        /**
         * Encodes the specified MainLineData message. Does not implicitly {@link mainline.MainLineData.verify|verify} messages.
         * @function encode
         * @memberof mainline.MainLineData
         * @static
         * @param {mainline.MainLineData} message MainLineData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MainLineData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hero != null && Object.hasOwnProperty.call(message, "hero"))
                $root.mainline.Charactor.encode(message.hero, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.soldierGroups != null && message.soldierGroups.length)
                for (let i = 0; i < message.soldierGroups.length; ++i)
                    $root.mainline.SoldierGroup.encode(message.soldierGroups[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.monsterTroops != null && message.monsterTroops.length)
                for (let i = 0; i < message.monsterTroops.length; ++i)
                    $root.mainline.MonsterTroop.encode(message.monsterTroops[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.trucks != null && message.trucks.length)
                for (let i = 0; i < message.trucks.length; ++i)
                    $root.mainline.Truck.encode(message.trucks[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.events != null && message.events.length)
                for (let i = 0; i < message.events.length; ++i)
                    $root.mainline.Event.encode(message.events[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.buildings != null && message.buildings.length)
                for (let i = 0; i < message.buildings.length; ++i)
                    $root.mainline.Building.encode(message.buildings[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.collections != null && message.collections.length)
                for (let i = 0; i < message.collections.length; ++i)
                    $root.mainline.Collection.encode(message.collections[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.spoils != null && message.spoils.length)
                for (let i = 0; i < message.spoils.length; ++i)
                    $root.mainline.Spoils.encode(message.spoils[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.npcs != null && message.npcs.length)
                for (let i = 0; i < message.npcs.length; ++i)
                    $root.mainline.Npc.encode(message.npcs[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MainLineData message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.MainLineData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.MainLineData} MainLineData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MainLineData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.MainLineData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.hero = $root.mainline.Charactor.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        if (!(message.soldierGroups && message.soldierGroups.length))
                            message.soldierGroups = [];
                        message.soldierGroups.push($root.mainline.SoldierGroup.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        if (!(message.monsterTroops && message.monsterTroops.length))
                            message.monsterTroops = [];
                        message.monsterTroops.push($root.mainline.MonsterTroop.decode(reader, reader.uint32()));
                        break;
                    }
                case 4: {
                        if (!(message.trucks && message.trucks.length))
                            message.trucks = [];
                        message.trucks.push($root.mainline.Truck.decode(reader, reader.uint32()));
                        break;
                    }
                case 5: {
                        if (!(message.events && message.events.length))
                            message.events = [];
                        message.events.push($root.mainline.Event.decode(reader, reader.uint32()));
                        break;
                    }
                case 6: {
                        if (!(message.buildings && message.buildings.length))
                            message.buildings = [];
                        message.buildings.push($root.mainline.Building.decode(reader, reader.uint32()));
                        break;
                    }
                case 7: {
                        if (!(message.collections && message.collections.length))
                            message.collections = [];
                        message.collections.push($root.mainline.Collection.decode(reader, reader.uint32()));
                        break;
                    }
                case 8: {
                        if (!(message.spoils && message.spoils.length))
                            message.spoils = [];
                        message.spoils.push($root.mainline.Spoils.decode(reader, reader.uint32()));
                        break;
                    }
                case 9: {
                        if (!(message.npcs && message.npcs.length))
                            message.npcs = [];
                        message.npcs.push($root.mainline.Npc.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for MainLineData
         * @function getTypeUrl
         * @memberof mainline.MainLineData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MainLineData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.MainLineData";
        };

        return MainLineData;
    })();

    mainline.c2s_load = (function() {

        /**
         * Properties of a c2s_load.
         * @memberof mainline
         * @interface Ic2s_load
         */

        /**
         * Constructs a new c2s_load.
         * @memberof mainline
         * @classdesc Represents a c2s_load.
         * @implements Ic2s_load
         * @constructor
         * @param {mainline.Ic2s_load=} [properties] Properties to set
         */
        function c2s_load(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_load instance using the specified properties.
         * @function create
         * @memberof mainline.c2s_load
         * @static
         * @param {mainline.Ic2s_load=} [properties] Properties to set
         * @returns {mainline.c2s_load} c2s_load instance
         */
        c2s_load.create = function create(properties) {
            return new c2s_load(properties);
        };

        /**
         * Encodes the specified c2s_load message. Does not implicitly {@link mainline.c2s_load.verify|verify} messages.
         * @function encode
         * @memberof mainline.c2s_load
         * @static
         * @param {mainline.c2s_load} message c2s_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_load message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.c2s_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.c2s_load} c2s_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.c2s_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_load
         * @function getTypeUrl
         * @memberof mainline.c2s_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.c2s_load";
        };

        return c2s_load;
    })();

    mainline.s2c_load = (function() {

        /**
         * Properties of a s2c_load.
         * @memberof mainline
         * @interface Is2c_load
         * @property {number|null} [err] s2c_load err
         * @property {mainline.MainLineData|null} [data] s2c_load data
         */

        /**
         * Constructs a new s2c_load.
         * @memberof mainline
         * @classdesc Represents a s2c_load.
         * @implements Is2c_load
         * @constructor
         * @param {mainline.Is2c_load=} [properties] Properties to set
         */
        function s2c_load(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_load err.
         * @member {number} err
         * @memberof mainline.s2c_load
         * @instance
         */
        s2c_load.prototype.err = 0;

        /**
         * s2c_load data.
         * @member {mainline.MainLineData|null|undefined} data
         * @memberof mainline.s2c_load
         * @instance
         */
        s2c_load.prototype.data = null;

        /**
         * Creates a new s2c_load instance using the specified properties.
         * @function create
         * @memberof mainline.s2c_load
         * @static
         * @param {mainline.Is2c_load=} [properties] Properties to set
         * @returns {mainline.s2c_load} s2c_load instance
         */
        s2c_load.create = function create(properties) {
            return new s2c_load(properties);
        };

        /**
         * Encodes the specified s2c_load message. Does not implicitly {@link mainline.s2c_load.verify|verify} messages.
         * @function encode
         * @memberof mainline.s2c_load
         * @static
         * @param {mainline.s2c_load} message s2c_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.mainline.MainLineData.encode(message.data, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_load message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.s2c_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.s2c_load} s2c_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.s2c_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        message.data = $root.mainline.MainLineData.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_load
         * @function getTypeUrl
         * @memberof mainline.s2c_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.s2c_load";
        };

        return s2c_load;
    })();

    mainline.c2s_save = (function() {

        /**
         * Properties of a c2s_save.
         * @memberof mainline
         * @interface Ic2s_save
         * @property {mainline.MainLineData|null} [data] c2s_save data
         */

        /**
         * Constructs a new c2s_save.
         * @memberof mainline
         * @classdesc Represents a c2s_save.
         * @implements Ic2s_save
         * @constructor
         * @param {mainline.Ic2s_save=} [properties] Properties to set
         */
        function c2s_save(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_save data.
         * @member {mainline.MainLineData|null|undefined} data
         * @memberof mainline.c2s_save
         * @instance
         */
        c2s_save.prototype.data = null;

        /**
         * Creates a new c2s_save instance using the specified properties.
         * @function create
         * @memberof mainline.c2s_save
         * @static
         * @param {mainline.Ic2s_save=} [properties] Properties to set
         * @returns {mainline.c2s_save} c2s_save instance
         */
        c2s_save.create = function create(properties) {
            return new c2s_save(properties);
        };

        /**
         * Encodes the specified c2s_save message. Does not implicitly {@link mainline.c2s_save.verify|verify} messages.
         * @function encode
         * @memberof mainline.c2s_save
         * @static
         * @param {mainline.c2s_save} message c2s_save message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_save.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.mainline.MainLineData.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a c2s_save message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.c2s_save
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.c2s_save} c2s_save
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_save.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.c2s_save();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.data = $root.mainline.MainLineData.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_save
         * @function getTypeUrl
         * @memberof mainline.c2s_save
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_save.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.c2s_save";
        };

        return c2s_save;
    })();

    mainline.s2c_save = (function() {

        /**
         * Properties of a s2c_save.
         * @memberof mainline
         * @interface Is2c_save
         * @property {number|null} [err] s2c_save err
         */

        /**
         * Constructs a new s2c_save.
         * @memberof mainline
         * @classdesc Represents a s2c_save.
         * @implements Is2c_save
         * @constructor
         * @param {mainline.Is2c_save=} [properties] Properties to set
         */
        function s2c_save(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_save err.
         * @member {number} err
         * @memberof mainline.s2c_save
         * @instance
         */
        s2c_save.prototype.err = 0;

        /**
         * Creates a new s2c_save instance using the specified properties.
         * @function create
         * @memberof mainline.s2c_save
         * @static
         * @param {mainline.Is2c_save=} [properties] Properties to set
         * @returns {mainline.s2c_save} s2c_save instance
         */
        s2c_save.create = function create(properties) {
            return new s2c_save(properties);
        };

        /**
         * Encodes the specified s2c_save message. Does not implicitly {@link mainline.s2c_save.verify|verify} messages.
         * @function encode
         * @memberof mainline.s2c_save
         * @static
         * @param {mainline.s2c_save} message s2c_save message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_save.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_save message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.s2c_save
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.s2c_save} s2c_save
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_save.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.s2c_save();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_save
         * @function getTypeUrl
         * @memberof mainline.s2c_save
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_save.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.s2c_save";
        };

        return s2c_save;
    })();

    mainline.c2s_event = (function() {

        /**
         * Properties of a c2s_event.
         * @memberof mainline
         * @interface Ic2s_event
         * @property {number|null} [eventId] c2s_event eventId
         * @property {number|null} [amount] c2s_event amount
         */

        /**
         * Constructs a new c2s_event.
         * @memberof mainline
         * @classdesc Represents a c2s_event.
         * @implements Ic2s_event
         * @constructor
         * @param {mainline.Ic2s_event=} [properties] Properties to set
         */
        function c2s_event(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_event eventId.
         * @member {number} eventId
         * @memberof mainline.c2s_event
         * @instance
         */
        c2s_event.prototype.eventId = 0;

        /**
         * c2s_event amount.
         * @member {number} amount
         * @memberof mainline.c2s_event
         * @instance
         */
        c2s_event.prototype.amount = 0;

        /**
         * Creates a new c2s_event instance using the specified properties.
         * @function create
         * @memberof mainline.c2s_event
         * @static
         * @param {mainline.Ic2s_event=} [properties] Properties to set
         * @returns {mainline.c2s_event} c2s_event instance
         */
        c2s_event.create = function create(properties) {
            return new c2s_event(properties);
        };

        /**
         * Encodes the specified c2s_event message. Does not implicitly {@link mainline.c2s_event.verify|verify} messages.
         * @function encode
         * @memberof mainline.c2s_event
         * @static
         * @param {mainline.c2s_event} message c2s_event message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_event.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eventId != null && Object.hasOwnProperty.call(message, "eventId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.eventId);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.amount);
            return writer;
        };

        /**
         * Decodes a c2s_event message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.c2s_event
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.c2s_event} c2s_event
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_event.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.c2s_event();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.eventId = reader.uint32();
                        break;
                    }
                case 2: {
                        message.amount = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_event
         * @function getTypeUrl
         * @memberof mainline.c2s_event
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_event.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.c2s_event";
        };

        return c2s_event;
    })();

    mainline.s2c_event = (function() {

        /**
         * Properties of a s2c_event.
         * @memberof mainline
         * @interface Is2c_event
         * @property {number|null} [err] s2c_event err
         */

        /**
         * Constructs a new s2c_event.
         * @memberof mainline
         * @classdesc Represents a s2c_event.
         * @implements Is2c_event
         * @constructor
         * @param {mainline.Is2c_event=} [properties] Properties to set
         */
        function s2c_event(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_event err.
         * @member {number} err
         * @memberof mainline.s2c_event
         * @instance
         */
        s2c_event.prototype.err = 0;

        /**
         * Creates a new s2c_event instance using the specified properties.
         * @function create
         * @memberof mainline.s2c_event
         * @static
         * @param {mainline.Is2c_event=} [properties] Properties to set
         * @returns {mainline.s2c_event} s2c_event instance
         */
        s2c_event.create = function create(properties) {
            return new s2c_event(properties);
        };

        /**
         * Encodes the specified s2c_event message. Does not implicitly {@link mainline.s2c_event.verify|verify} messages.
         * @function encode
         * @memberof mainline.s2c_event
         * @static
         * @param {mainline.s2c_event} message s2c_event message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_event.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_event message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.s2c_event
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.s2c_event} s2c_event
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_event.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.s2c_event();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_event
         * @function getTypeUrl
         * @memberof mainline.s2c_event
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_event.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.s2c_event";
        };

        return s2c_event;
    })();

    mainline.c2s_reborn = (function() {

        /**
         * Properties of a c2s_reborn.
         * @memberof mainline
         * @interface Ic2s_reborn
         * @property {number|null} [type] c2s_reborn type
         */

        /**
         * Constructs a new c2s_reborn.
         * @memberof mainline
         * @classdesc Represents a c2s_reborn.
         * @implements Ic2s_reborn
         * @constructor
         * @param {mainline.Ic2s_reborn=} [properties] Properties to set
         */
        function c2s_reborn(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_reborn type.
         * @member {number} type
         * @memberof mainline.c2s_reborn
         * @instance
         */
        c2s_reborn.prototype.type = 0;

        /**
         * Creates a new c2s_reborn instance using the specified properties.
         * @function create
         * @memberof mainline.c2s_reborn
         * @static
         * @param {mainline.Ic2s_reborn=} [properties] Properties to set
         * @returns {mainline.c2s_reborn} c2s_reborn instance
         */
        c2s_reborn.create = function create(properties) {
            return new c2s_reborn(properties);
        };

        /**
         * Encodes the specified c2s_reborn message. Does not implicitly {@link mainline.c2s_reborn.verify|verify} messages.
         * @function encode
         * @memberof mainline.c2s_reborn
         * @static
         * @param {mainline.c2s_reborn} message c2s_reborn message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_reborn.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.type);
            return writer;
        };

        /**
         * Decodes a c2s_reborn message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.c2s_reborn
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.c2s_reborn} c2s_reborn
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_reborn.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.c2s_reborn();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_reborn
         * @function getTypeUrl
         * @memberof mainline.c2s_reborn
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_reborn.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.c2s_reborn";
        };

        return c2s_reborn;
    })();

    mainline.s2c_reborn = (function() {

        /**
         * Properties of a s2c_reborn.
         * @memberof mainline
         * @interface Is2c_reborn
         * @property {number|null} [err] s2c_reborn err
         */

        /**
         * Constructs a new s2c_reborn.
         * @memberof mainline
         * @classdesc Represents a s2c_reborn.
         * @implements Is2c_reborn
         * @constructor
         * @param {mainline.Is2c_reborn=} [properties] Properties to set
         */
        function s2c_reborn(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_reborn err.
         * @member {number} err
         * @memberof mainline.s2c_reborn
         * @instance
         */
        s2c_reborn.prototype.err = 0;

        /**
         * Creates a new s2c_reborn instance using the specified properties.
         * @function create
         * @memberof mainline.s2c_reborn
         * @static
         * @param {mainline.Is2c_reborn=} [properties] Properties to set
         * @returns {mainline.s2c_reborn} s2c_reborn instance
         */
        s2c_reborn.create = function create(properties) {
            return new s2c_reborn(properties);
        };

        /**
         * Encodes the specified s2c_reborn message. Does not implicitly {@link mainline.s2c_reborn.verify|verify} messages.
         * @function encode
         * @memberof mainline.s2c_reborn
         * @static
         * @param {mainline.s2c_reborn} message s2c_reborn message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_reborn.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_reborn message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.s2c_reborn
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.s2c_reborn} s2c_reborn
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_reborn.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.s2c_reborn();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_reborn
         * @function getTypeUrl
         * @memberof mainline.s2c_reborn
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_reborn.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.s2c_reborn";
        };

        return s2c_reborn;
    })();

    mainline.c2s_do_task = (function() {

        /**
         * Properties of a c2s_do_task.
         * @memberof mainline
         * @interface Ic2s_do_task
         * @property {number|null} [type] c2s_do_task type
         * @property {number|null} [amount] c2s_do_task amount
         * @property {number|null} [argId] c2s_do_task argId
         */

        /**
         * Constructs a new c2s_do_task.
         * @memberof mainline
         * @classdesc Represents a c2s_do_task.
         * @implements Ic2s_do_task
         * @constructor
         * @param {mainline.Ic2s_do_task=} [properties] Properties to set
         */
        function c2s_do_task(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_do_task type.
         * @member {number} type
         * @memberof mainline.c2s_do_task
         * @instance
         */
        c2s_do_task.prototype.type = 0;

        /**
         * c2s_do_task amount.
         * @member {number} amount
         * @memberof mainline.c2s_do_task
         * @instance
         */
        c2s_do_task.prototype.amount = 0;

        /**
         * c2s_do_task argId.
         * @member {number} argId
         * @memberof mainline.c2s_do_task
         * @instance
         */
        c2s_do_task.prototype.argId = 0;

        /**
         * Creates a new c2s_do_task instance using the specified properties.
         * @function create
         * @memberof mainline.c2s_do_task
         * @static
         * @param {mainline.Ic2s_do_task=} [properties] Properties to set
         * @returns {mainline.c2s_do_task} c2s_do_task instance
         */
        c2s_do_task.create = function create(properties) {
            return new c2s_do_task(properties);
        };

        /**
         * Encodes the specified c2s_do_task message. Does not implicitly {@link mainline.c2s_do_task.verify|verify} messages.
         * @function encode
         * @memberof mainline.c2s_do_task
         * @static
         * @param {mainline.c2s_do_task} message c2s_do_task message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_do_task.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.type);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.amount);
            if (message.argId != null && Object.hasOwnProperty.call(message, "argId"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.argId);
            return writer;
        };

        /**
         * Decodes a c2s_do_task message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.c2s_do_task
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.c2s_do_task} c2s_do_task
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_do_task.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.c2s_do_task();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.uint32();
                        break;
                    }
                case 2: {
                        message.amount = reader.uint32();
                        break;
                    }
                case 3: {
                        message.argId = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_do_task
         * @function getTypeUrl
         * @memberof mainline.c2s_do_task
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_do_task.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.c2s_do_task";
        };

        return c2s_do_task;
    })();

    mainline.s2c_do_task = (function() {

        /**
         * Properties of a s2c_do_task.
         * @memberof mainline
         * @interface Is2c_do_task
         * @property {number|null} [err] s2c_do_task err
         */

        /**
         * Constructs a new s2c_do_task.
         * @memberof mainline
         * @classdesc Represents a s2c_do_task.
         * @implements Is2c_do_task
         * @constructor
         * @param {mainline.Is2c_do_task=} [properties] Properties to set
         */
        function s2c_do_task(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_do_task err.
         * @member {number} err
         * @memberof mainline.s2c_do_task
         * @instance
         */
        s2c_do_task.prototype.err = 0;

        /**
         * Creates a new s2c_do_task instance using the specified properties.
         * @function create
         * @memberof mainline.s2c_do_task
         * @static
         * @param {mainline.Is2c_do_task=} [properties] Properties to set
         * @returns {mainline.s2c_do_task} s2c_do_task instance
         */
        s2c_do_task.create = function create(properties) {
            return new s2c_do_task(properties);
        };

        /**
         * Encodes the specified s2c_do_task message. Does not implicitly {@link mainline.s2c_do_task.verify|verify} messages.
         * @function encode
         * @memberof mainline.s2c_do_task
         * @static
         * @param {mainline.s2c_do_task} message s2c_do_task message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_do_task.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_do_task message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.s2c_do_task
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.s2c_do_task} s2c_do_task
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_do_task.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.s2c_do_task();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_do_task
         * @function getTypeUrl
         * @memberof mainline.s2c_do_task
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_do_task.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.s2c_do_task";
        };

        return s2c_do_task;
    })();

    mainline.c2s_area_progress_draw = (function() {

        /**
         * Properties of a c2s_area_progress_draw.
         * @memberof mainline
         * @interface Ic2s_area_progress_draw
         * @property {number|null} [index] c2s_area_progress_draw index
         * @property {number|null} [area] c2s_area_progress_draw area
         */

        /**
         * Constructs a new c2s_area_progress_draw.
         * @memberof mainline
         * @classdesc Represents a c2s_area_progress_draw.
         * @implements Ic2s_area_progress_draw
         * @constructor
         * @param {mainline.Ic2s_area_progress_draw=} [properties] Properties to set
         */
        function c2s_area_progress_draw(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_area_progress_draw index.
         * @member {number} index
         * @memberof mainline.c2s_area_progress_draw
         * @instance
         */
        c2s_area_progress_draw.prototype.index = 0;

        /**
         * c2s_area_progress_draw area.
         * @member {number} area
         * @memberof mainline.c2s_area_progress_draw
         * @instance
         */
        c2s_area_progress_draw.prototype.area = 0;

        /**
         * Creates a new c2s_area_progress_draw instance using the specified properties.
         * @function create
         * @memberof mainline.c2s_area_progress_draw
         * @static
         * @param {mainline.Ic2s_area_progress_draw=} [properties] Properties to set
         * @returns {mainline.c2s_area_progress_draw} c2s_area_progress_draw instance
         */
        c2s_area_progress_draw.create = function create(properties) {
            return new c2s_area_progress_draw(properties);
        };

        /**
         * Encodes the specified c2s_area_progress_draw message. Does not implicitly {@link mainline.c2s_area_progress_draw.verify|verify} messages.
         * @function encode
         * @memberof mainline.c2s_area_progress_draw
         * @static
         * @param {mainline.c2s_area_progress_draw} message c2s_area_progress_draw message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_area_progress_draw.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.index);
            if (message.area != null && Object.hasOwnProperty.call(message, "area"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.area);
            return writer;
        };

        /**
         * Decodes a c2s_area_progress_draw message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.c2s_area_progress_draw
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.c2s_area_progress_draw} c2s_area_progress_draw
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_area_progress_draw.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.c2s_area_progress_draw();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.index = reader.uint32();
                        break;
                    }
                case 2: {
                        message.area = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_area_progress_draw
         * @function getTypeUrl
         * @memberof mainline.c2s_area_progress_draw
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_area_progress_draw.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.c2s_area_progress_draw";
        };

        return c2s_area_progress_draw;
    })();

    mainline.s2c_area_progress_draw = (function() {

        /**
         * Properties of a s2c_area_progress_draw.
         * @memberof mainline
         * @interface Is2c_area_progress_draw
         * @property {number|null} [err] s2c_area_progress_draw err
         */

        /**
         * Constructs a new s2c_area_progress_draw.
         * @memberof mainline
         * @classdesc Represents a s2c_area_progress_draw.
         * @implements Is2c_area_progress_draw
         * @constructor
         * @param {mainline.Is2c_area_progress_draw=} [properties] Properties to set
         */
        function s2c_area_progress_draw(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_area_progress_draw err.
         * @member {number} err
         * @memberof mainline.s2c_area_progress_draw
         * @instance
         */
        s2c_area_progress_draw.prototype.err = 0;

        /**
         * Creates a new s2c_area_progress_draw instance using the specified properties.
         * @function create
         * @memberof mainline.s2c_area_progress_draw
         * @static
         * @param {mainline.Is2c_area_progress_draw=} [properties] Properties to set
         * @returns {mainline.s2c_area_progress_draw} s2c_area_progress_draw instance
         */
        s2c_area_progress_draw.create = function create(properties) {
            return new s2c_area_progress_draw(properties);
        };

        /**
         * Encodes the specified s2c_area_progress_draw message. Does not implicitly {@link mainline.s2c_area_progress_draw.verify|verify} messages.
         * @function encode
         * @memberof mainline.s2c_area_progress_draw
         * @static
         * @param {mainline.s2c_area_progress_draw} message s2c_area_progress_draw message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_area_progress_draw.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_area_progress_draw message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.s2c_area_progress_draw
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.s2c_area_progress_draw} s2c_area_progress_draw
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_area_progress_draw.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.s2c_area_progress_draw();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_area_progress_draw
         * @function getTypeUrl
         * @memberof mainline.s2c_area_progress_draw
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_area_progress_draw.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.s2c_area_progress_draw";
        };

        return s2c_area_progress_draw;
    })();

    mainline.AreaProgress = (function() {

        /**
         * Properties of an AreaProgress.
         * @memberof mainline
         * @interface IAreaProgress
         * @property {number|null} [area] AreaProgress area
         * @property {number|null} [awardedIndex] AreaProgress awardedIndex
         */

        /**
         * Constructs a new AreaProgress.
         * @memberof mainline
         * @classdesc Represents an AreaProgress.
         * @implements IAreaProgress
         * @constructor
         * @param {mainline.IAreaProgress=} [properties] Properties to set
         */
        function AreaProgress(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AreaProgress area.
         * @member {number} area
         * @memberof mainline.AreaProgress
         * @instance
         */
        AreaProgress.prototype.area = 0;

        /**
         * AreaProgress awardedIndex.
         * @member {number} awardedIndex
         * @memberof mainline.AreaProgress
         * @instance
         */
        AreaProgress.prototype.awardedIndex = 0;

        /**
         * Creates a new AreaProgress instance using the specified properties.
         * @function create
         * @memberof mainline.AreaProgress
         * @static
         * @param {mainline.IAreaProgress=} [properties] Properties to set
         * @returns {mainline.AreaProgress} AreaProgress instance
         */
        AreaProgress.create = function create(properties) {
            return new AreaProgress(properties);
        };

        /**
         * Encodes the specified AreaProgress message. Does not implicitly {@link mainline.AreaProgress.verify|verify} messages.
         * @function encode
         * @memberof mainline.AreaProgress
         * @static
         * @param {mainline.AreaProgress} message AreaProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AreaProgress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.area != null && Object.hasOwnProperty.call(message, "area"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.area);
            if (message.awardedIndex != null && Object.hasOwnProperty.call(message, "awardedIndex"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.awardedIndex);
            return writer;
        };

        /**
         * Decodes an AreaProgress message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.AreaProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.AreaProgress} AreaProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AreaProgress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.AreaProgress();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.area = reader.uint32();
                        break;
                    }
                case 2: {
                        message.awardedIndex = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for AreaProgress
         * @function getTypeUrl
         * @memberof mainline.AreaProgress
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AreaProgress.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.AreaProgress";
        };

        return AreaProgress;
    })();

    mainline.c2s_area_progress_reward = (function() {

        /**
         * Properties of a c2s_area_progress_reward.
         * @memberof mainline
         * @interface Ic2s_area_progress_reward
         */

        /**
         * Constructs a new c2s_area_progress_reward.
         * @memberof mainline
         * @classdesc Represents a c2s_area_progress_reward.
         * @implements Ic2s_area_progress_reward
         * @constructor
         * @param {mainline.Ic2s_area_progress_reward=} [properties] Properties to set
         */
        function c2s_area_progress_reward(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_area_progress_reward instance using the specified properties.
         * @function create
         * @memberof mainline.c2s_area_progress_reward
         * @static
         * @param {mainline.Ic2s_area_progress_reward=} [properties] Properties to set
         * @returns {mainline.c2s_area_progress_reward} c2s_area_progress_reward instance
         */
        c2s_area_progress_reward.create = function create(properties) {
            return new c2s_area_progress_reward(properties);
        };

        /**
         * Encodes the specified c2s_area_progress_reward message. Does not implicitly {@link mainline.c2s_area_progress_reward.verify|verify} messages.
         * @function encode
         * @memberof mainline.c2s_area_progress_reward
         * @static
         * @param {mainline.c2s_area_progress_reward} message c2s_area_progress_reward message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_area_progress_reward.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_area_progress_reward message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.c2s_area_progress_reward
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.c2s_area_progress_reward} c2s_area_progress_reward
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_area_progress_reward.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.c2s_area_progress_reward();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_area_progress_reward
         * @function getTypeUrl
         * @memberof mainline.c2s_area_progress_reward
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_area_progress_reward.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.c2s_area_progress_reward";
        };

        return c2s_area_progress_reward;
    })();

    mainline.s2c_area_progress_reward = (function() {

        /**
         * Properties of a s2c_area_progress_reward.
         * @memberof mainline
         * @interface Is2c_area_progress_reward
         * @property {number|null} [err] s2c_area_progress_reward err
         * @property {Array.<mainline.AreaProgress>|null} [areaProgressList] s2c_area_progress_reward areaProgressList
         */

        /**
         * Constructs a new s2c_area_progress_reward.
         * @memberof mainline
         * @classdesc Represents a s2c_area_progress_reward.
         * @implements Is2c_area_progress_reward
         * @constructor
         * @param {mainline.Is2c_area_progress_reward=} [properties] Properties to set
         */
        function s2c_area_progress_reward(properties) {
            this.areaProgressList = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_area_progress_reward err.
         * @member {number} err
         * @memberof mainline.s2c_area_progress_reward
         * @instance
         */
        s2c_area_progress_reward.prototype.err = 0;

        /**
         * s2c_area_progress_reward areaProgressList.
         * @member {Array.<mainline.AreaProgress>} areaProgressList
         * @memberof mainline.s2c_area_progress_reward
         * @instance
         */
        s2c_area_progress_reward.prototype.areaProgressList = $util.emptyArray;

        /**
         * Creates a new s2c_area_progress_reward instance using the specified properties.
         * @function create
         * @memberof mainline.s2c_area_progress_reward
         * @static
         * @param {mainline.Is2c_area_progress_reward=} [properties] Properties to set
         * @returns {mainline.s2c_area_progress_reward} s2c_area_progress_reward instance
         */
        s2c_area_progress_reward.create = function create(properties) {
            return new s2c_area_progress_reward(properties);
        };

        /**
         * Encodes the specified s2c_area_progress_reward message. Does not implicitly {@link mainline.s2c_area_progress_reward.verify|verify} messages.
         * @function encode
         * @memberof mainline.s2c_area_progress_reward
         * @static
         * @param {mainline.s2c_area_progress_reward} message s2c_area_progress_reward message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_area_progress_reward.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.areaProgressList != null && message.areaProgressList.length)
                for (let i = 0; i < message.areaProgressList.length; ++i)
                    $root.mainline.AreaProgress.encode(message.areaProgressList[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_area_progress_reward message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.s2c_area_progress_reward
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.s2c_area_progress_reward} s2c_area_progress_reward
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_area_progress_reward.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.s2c_area_progress_reward();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.areaProgressList && message.areaProgressList.length))
                            message.areaProgressList = [];
                        message.areaProgressList.push($root.mainline.AreaProgress.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_area_progress_reward
         * @function getTypeUrl
         * @memberof mainline.s2c_area_progress_reward
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_area_progress_reward.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.s2c_area_progress_reward";
        };

        return s2c_area_progress_reward;
    })();

    mainline.c2s_upgrade_building = (function() {

        /**
         * Properties of a c2s_upgrade_building.
         * @memberof mainline
         * @interface Ic2s_upgrade_building
         * @property {number|null} [buildingId] c2s_upgrade_building buildingId
         */

        /**
         * Constructs a new c2s_upgrade_building.
         * @memberof mainline
         * @classdesc Represents a c2s_upgrade_building.
         * @implements Ic2s_upgrade_building
         * @constructor
         * @param {mainline.Ic2s_upgrade_building=} [properties] Properties to set
         */
        function c2s_upgrade_building(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_upgrade_building buildingId.
         * @member {number} buildingId
         * @memberof mainline.c2s_upgrade_building
         * @instance
         */
        c2s_upgrade_building.prototype.buildingId = 0;

        /**
         * Creates a new c2s_upgrade_building instance using the specified properties.
         * @function create
         * @memberof mainline.c2s_upgrade_building
         * @static
         * @param {mainline.Ic2s_upgrade_building=} [properties] Properties to set
         * @returns {mainline.c2s_upgrade_building} c2s_upgrade_building instance
         */
        c2s_upgrade_building.create = function create(properties) {
            return new c2s_upgrade_building(properties);
        };

        /**
         * Encodes the specified c2s_upgrade_building message. Does not implicitly {@link mainline.c2s_upgrade_building.verify|verify} messages.
         * @function encode
         * @memberof mainline.c2s_upgrade_building
         * @static
         * @param {mainline.c2s_upgrade_building} message c2s_upgrade_building message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_upgrade_building.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.buildingId != null && Object.hasOwnProperty.call(message, "buildingId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.buildingId);
            return writer;
        };

        /**
         * Decodes a c2s_upgrade_building message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.c2s_upgrade_building
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.c2s_upgrade_building} c2s_upgrade_building
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_upgrade_building.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.c2s_upgrade_building();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.buildingId = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_upgrade_building
         * @function getTypeUrl
         * @memberof mainline.c2s_upgrade_building
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_upgrade_building.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.c2s_upgrade_building";
        };

        return c2s_upgrade_building;
    })();

    mainline.s2c_upgrade_building = (function() {

        /**
         * Properties of a s2c_upgrade_building.
         * @memberof mainline
         * @interface Is2c_upgrade_building
         * @property {number|null} [err] s2c_upgrade_building err
         */

        /**
         * Constructs a new s2c_upgrade_building.
         * @memberof mainline
         * @classdesc Represents a s2c_upgrade_building.
         * @implements Is2c_upgrade_building
         * @constructor
         * @param {mainline.Is2c_upgrade_building=} [properties] Properties to set
         */
        function s2c_upgrade_building(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_upgrade_building err.
         * @member {number} err
         * @memberof mainline.s2c_upgrade_building
         * @instance
         */
        s2c_upgrade_building.prototype.err = 0;

        /**
         * Creates a new s2c_upgrade_building instance using the specified properties.
         * @function create
         * @memberof mainline.s2c_upgrade_building
         * @static
         * @param {mainline.Is2c_upgrade_building=} [properties] Properties to set
         * @returns {mainline.s2c_upgrade_building} s2c_upgrade_building instance
         */
        s2c_upgrade_building.create = function create(properties) {
            return new s2c_upgrade_building(properties);
        };

        /**
         * Encodes the specified s2c_upgrade_building message. Does not implicitly {@link mainline.s2c_upgrade_building.verify|verify} messages.
         * @function encode
         * @memberof mainline.s2c_upgrade_building
         * @static
         * @param {mainline.s2c_upgrade_building} message s2c_upgrade_building message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_upgrade_building.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_upgrade_building message from the specified reader or buffer.
         * @function decode
         * @memberof mainline.s2c_upgrade_building
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mainline.s2c_upgrade_building} s2c_upgrade_building
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_upgrade_building.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mainline.s2c_upgrade_building();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_upgrade_building
         * @function getTypeUrl
         * @memberof mainline.s2c_upgrade_building
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_upgrade_building.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mainline.s2c_upgrade_building";
        };

        return s2c_upgrade_building;
    })();

    return mainline;
})();

export const money = $root.money = (() => {

    /**
     * Namespace money.
     * @exports money
     * @namespace
     */
    const money = {};

    money.MoneyItem = (function() {

        /**
         * Properties of a MoneyItem.
         * @memberof money
         * @interface IMoneyItem
         * @property {number|null} [id] MoneyItem id
         * @property {number|null} [num] MoneyItem num
         * @property {number|null} [recoverTs] MoneyItem recoverTs
         */

        /**
         * Constructs a new MoneyItem.
         * @memberof money
         * @classdesc Represents a MoneyItem.
         * @implements IMoneyItem
         * @constructor
         * @param {money.IMoneyItem=} [properties] Properties to set
         */
        function MoneyItem(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MoneyItem id.
         * @member {number} id
         * @memberof money.MoneyItem
         * @instance
         */
        MoneyItem.prototype.id = 0;

        /**
         * MoneyItem num.
         * @member {number} num
         * @memberof money.MoneyItem
         * @instance
         */
        MoneyItem.prototype.num = 0;

        /**
         * MoneyItem recoverTs.
         * @member {number} recoverTs
         * @memberof money.MoneyItem
         * @instance
         */
        MoneyItem.prototype.recoverTs = 0;

        /**
         * Creates a new MoneyItem instance using the specified properties.
         * @function create
         * @memberof money.MoneyItem
         * @static
         * @param {money.IMoneyItem=} [properties] Properties to set
         * @returns {money.MoneyItem} MoneyItem instance
         */
        MoneyItem.create = function create(properties) {
            return new MoneyItem(properties);
        };

        /**
         * Encodes the specified MoneyItem message. Does not implicitly {@link money.MoneyItem.verify|verify} messages.
         * @function encode
         * @memberof money.MoneyItem
         * @static
         * @param {money.MoneyItem} message MoneyItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MoneyItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            if (message.num != null && Object.hasOwnProperty.call(message, "num"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.num);
            if (message.recoverTs != null && Object.hasOwnProperty.call(message, "recoverTs"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.recoverTs);
            return writer;
        };

        /**
         * Decodes a MoneyItem message from the specified reader or buffer.
         * @function decode
         * @memberof money.MoneyItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {money.MoneyItem} MoneyItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MoneyItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.money.MoneyItem();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 2: {
                        message.num = reader.uint32();
                        break;
                    }
                case 3: {
                        message.recoverTs = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for MoneyItem
         * @function getTypeUrl
         * @memberof money.MoneyItem
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MoneyItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/money.MoneyItem";
        };

        return MoneyItem;
    })();

    money.c2s_load = (function() {

        /**
         * Properties of a c2s_load.
         * @memberof money
         * @interface Ic2s_load
         */

        /**
         * Constructs a new c2s_load.
         * @memberof money
         * @classdesc Represents a c2s_load.
         * @implements Ic2s_load
         * @constructor
         * @param {money.Ic2s_load=} [properties] Properties to set
         */
        function c2s_load(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_load instance using the specified properties.
         * @function create
         * @memberof money.c2s_load
         * @static
         * @param {money.Ic2s_load=} [properties] Properties to set
         * @returns {money.c2s_load} c2s_load instance
         */
        c2s_load.create = function create(properties) {
            return new c2s_load(properties);
        };

        /**
         * Encodes the specified c2s_load message. Does not implicitly {@link money.c2s_load.verify|verify} messages.
         * @function encode
         * @memberof money.c2s_load
         * @static
         * @param {money.c2s_load} message c2s_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_load message from the specified reader or buffer.
         * @function decode
         * @memberof money.c2s_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {money.c2s_load} c2s_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.money.c2s_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_load
         * @function getTypeUrl
         * @memberof money.c2s_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/money.c2s_load";
        };

        return c2s_load;
    })();

    money.s2c_load = (function() {

        /**
         * Properties of a s2c_load.
         * @memberof money
         * @interface Is2c_load
         * @property {number|null} [err] s2c_load err
         * @property {Array.<money.MoneyItem>|null} [items] s2c_load items
         */

        /**
         * Constructs a new s2c_load.
         * @memberof money
         * @classdesc Represents a s2c_load.
         * @implements Is2c_load
         * @constructor
         * @param {money.Is2c_load=} [properties] Properties to set
         */
        function s2c_load(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_load err.
         * @member {number} err
         * @memberof money.s2c_load
         * @instance
         */
        s2c_load.prototype.err = 0;

        /**
         * s2c_load items.
         * @member {Array.<money.MoneyItem>} items
         * @memberof money.s2c_load
         * @instance
         */
        s2c_load.prototype.items = $util.emptyArray;

        /**
         * Creates a new s2c_load instance using the specified properties.
         * @function create
         * @memberof money.s2c_load
         * @static
         * @param {money.Is2c_load=} [properties] Properties to set
         * @returns {money.s2c_load} s2c_load instance
         */
        s2c_load.create = function create(properties) {
            return new s2c_load(properties);
        };

        /**
         * Encodes the specified s2c_load message. Does not implicitly {@link money.s2c_load.verify|verify} messages.
         * @function encode
         * @memberof money.s2c_load
         * @static
         * @param {money.s2c_load} message s2c_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.money.MoneyItem.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_load message from the specified reader or buffer.
         * @function decode
         * @memberof money.s2c_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {money.s2c_load} s2c_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.money.s2c_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.money.MoneyItem.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_load
         * @function getTypeUrl
         * @memberof money.s2c_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/money.s2c_load";
        };

        return s2c_load;
    })();

    money.notify_items = (function() {

        /**
         * Properties of a notify_items.
         * @memberof money
         * @interface Inotify_items
         * @property {Array.<money.MoneyItem>|null} [items] notify_items items
         */

        /**
         * Constructs a new notify_items.
         * @memberof money
         * @classdesc Represents a notify_items.
         * @implements Inotify_items
         * @constructor
         * @param {money.Inotify_items=} [properties] Properties to set
         */
        function notify_items(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_items items.
         * @member {Array.<money.MoneyItem>} items
         * @memberof money.notify_items
         * @instance
         */
        notify_items.prototype.items = $util.emptyArray;

        /**
         * Creates a new notify_items instance using the specified properties.
         * @function create
         * @memberof money.notify_items
         * @static
         * @param {money.Inotify_items=} [properties] Properties to set
         * @returns {money.notify_items} notify_items instance
         */
        notify_items.create = function create(properties) {
            return new notify_items(properties);
        };

        /**
         * Encodes the specified notify_items message. Does not implicitly {@link money.notify_items.verify|verify} messages.
         * @function encode
         * @memberof money.notify_items
         * @static
         * @param {money.notify_items} message notify_items message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_items.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.money.MoneyItem.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a notify_items message from the specified reader or buffer.
         * @function decode
         * @memberof money.notify_items
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {money.notify_items} notify_items
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_items.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.money.notify_items();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.money.MoneyItem.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_items
         * @function getTypeUrl
         * @memberof money.notify_items
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_items.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/money.notify_items";
        };

        return notify_items;
    })();

    return money;
})();

export const profile = $root.profile = (() => {

    /**
     * Namespace profile.
     * @exports profile
     * @namespace
     */
    const profile = {};

    profile.ProfileInfo = (function() {

        /**
         * Properties of a ProfileInfo.
         * @memberof profile
         * @interface IProfileInfo
         * @property {string|null} [name] ProfileInfo name
         * @property {number|null} [gender] ProfileInfo gender
         * @property {number|null} [exp] ProfileInfo exp
         * @property {number|null} [lv] ProfileInfo lv
         * @property {number|null} [headImgId] ProfileInfo headImgId
         * @property {number|null} [job] ProfileInfo job
         */

        /**
         * Constructs a new ProfileInfo.
         * @memberof profile
         * @classdesc Represents a ProfileInfo.
         * @implements IProfileInfo
         * @constructor
         * @param {profile.IProfileInfo=} [properties] Properties to set
         */
        function ProfileInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProfileInfo name.
         * @member {string} name
         * @memberof profile.ProfileInfo
         * @instance
         */
        ProfileInfo.prototype.name = "";

        /**
         * ProfileInfo gender.
         * @member {number} gender
         * @memberof profile.ProfileInfo
         * @instance
         */
        ProfileInfo.prototype.gender = 0;

        /**
         * ProfileInfo exp.
         * @member {number} exp
         * @memberof profile.ProfileInfo
         * @instance
         */
        ProfileInfo.prototype.exp = 0;

        /**
         * ProfileInfo lv.
         * @member {number} lv
         * @memberof profile.ProfileInfo
         * @instance
         */
        ProfileInfo.prototype.lv = 0;

        /**
         * ProfileInfo headImgId.
         * @member {number} headImgId
         * @memberof profile.ProfileInfo
         * @instance
         */
        ProfileInfo.prototype.headImgId = 0;

        /**
         * ProfileInfo job.
         * @member {number} job
         * @memberof profile.ProfileInfo
         * @instance
         */
        ProfileInfo.prototype.job = 0;

        /**
         * Creates a new ProfileInfo instance using the specified properties.
         * @function create
         * @memberof profile.ProfileInfo
         * @static
         * @param {profile.IProfileInfo=} [properties] Properties to set
         * @returns {profile.ProfileInfo} ProfileInfo instance
         */
        ProfileInfo.create = function create(properties) {
            return new ProfileInfo(properties);
        };

        /**
         * Encodes the specified ProfileInfo message. Does not implicitly {@link profile.ProfileInfo.verify|verify} messages.
         * @function encode
         * @memberof profile.ProfileInfo
         * @static
         * @param {profile.ProfileInfo} message ProfileInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfileInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.gender);
            if (message.exp != null && Object.hasOwnProperty.call(message, "exp"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.exp);
            if (message.lv != null && Object.hasOwnProperty.call(message, "lv"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.lv);
            if (message.headImgId != null && Object.hasOwnProperty.call(message, "headImgId"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.headImgId);
            if (message.job != null && Object.hasOwnProperty.call(message, "job"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.job);
            return writer;
        };

        /**
         * Decodes a ProfileInfo message from the specified reader or buffer.
         * @function decode
         * @memberof profile.ProfileInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {profile.ProfileInfo} ProfileInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfileInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.profile.ProfileInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.gender = reader.uint32();
                        break;
                    }
                case 3: {
                        message.exp = reader.uint32();
                        break;
                    }
                case 4: {
                        message.lv = reader.uint32();
                        break;
                    }
                case 5: {
                        message.headImgId = reader.uint32();
                        break;
                    }
                case 6: {
                        message.job = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for ProfileInfo
         * @function getTypeUrl
         * @memberof profile.ProfileInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProfileInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/profile.ProfileInfo";
        };

        return ProfileInfo;
    })();

    profile.CommonInfo = (function() {

        /**
         * Properties of a CommonInfo.
         * @memberof profile
         * @interface ICommonInfo
         * @property {number|null} [receivedLv] CommonInfo receivedLv
         */

        /**
         * Constructs a new CommonInfo.
         * @memberof profile
         * @classdesc Represents a CommonInfo.
         * @implements ICommonInfo
         * @constructor
         * @param {profile.ICommonInfo=} [properties] Properties to set
         */
        function CommonInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CommonInfo receivedLv.
         * @member {number} receivedLv
         * @memberof profile.CommonInfo
         * @instance
         */
        CommonInfo.prototype.receivedLv = 0;

        /**
         * Creates a new CommonInfo instance using the specified properties.
         * @function create
         * @memberof profile.CommonInfo
         * @static
         * @param {profile.ICommonInfo=} [properties] Properties to set
         * @returns {profile.CommonInfo} CommonInfo instance
         */
        CommonInfo.create = function create(properties) {
            return new CommonInfo(properties);
        };

        /**
         * Encodes the specified CommonInfo message. Does not implicitly {@link profile.CommonInfo.verify|verify} messages.
         * @function encode
         * @memberof profile.CommonInfo
         * @static
         * @param {profile.CommonInfo} message CommonInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommonInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.receivedLv != null && Object.hasOwnProperty.call(message, "receivedLv"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.receivedLv);
            return writer;
        };

        /**
         * Decodes a CommonInfo message from the specified reader or buffer.
         * @function decode
         * @memberof profile.CommonInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {profile.CommonInfo} CommonInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommonInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.profile.CommonInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.receivedLv = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for CommonInfo
         * @function getTypeUrl
         * @memberof profile.CommonInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CommonInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/profile.CommonInfo";
        };

        return CommonInfo;
    })();

    profile.c2s_load = (function() {

        /**
         * Properties of a c2s_load.
         * @memberof profile
         * @interface Ic2s_load
         */

        /**
         * Constructs a new c2s_load.
         * @memberof profile
         * @classdesc Represents a c2s_load.
         * @implements Ic2s_load
         * @constructor
         * @param {profile.Ic2s_load=} [properties] Properties to set
         */
        function c2s_load(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_load instance using the specified properties.
         * @function create
         * @memberof profile.c2s_load
         * @static
         * @param {profile.Ic2s_load=} [properties] Properties to set
         * @returns {profile.c2s_load} c2s_load instance
         */
        c2s_load.create = function create(properties) {
            return new c2s_load(properties);
        };

        /**
         * Encodes the specified c2s_load message. Does not implicitly {@link profile.c2s_load.verify|verify} messages.
         * @function encode
         * @memberof profile.c2s_load
         * @static
         * @param {profile.c2s_load} message c2s_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_load message from the specified reader or buffer.
         * @function decode
         * @memberof profile.c2s_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {profile.c2s_load} c2s_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.profile.c2s_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_load
         * @function getTypeUrl
         * @memberof profile.c2s_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/profile.c2s_load";
        };

        return c2s_load;
    })();

    profile.s2c_load = (function() {

        /**
         * Properties of a s2c_load.
         * @memberof profile
         * @interface Is2c_load
         * @property {number|null} [err] s2c_load err
         * @property {profile.ProfileInfo|null} [profile] s2c_load profile
         * @property {boolean|null} ["new"] s2c_load new
         * @property {profile.CommonInfo|null} [commonInfo] s2c_load commonInfo
         */

        /**
         * Constructs a new s2c_load.
         * @memberof profile
         * @classdesc Represents a s2c_load.
         * @implements Is2c_load
         * @constructor
         * @param {profile.Is2c_load=} [properties] Properties to set
         */
        function s2c_load(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_load err.
         * @member {number} err
         * @memberof profile.s2c_load
         * @instance
         */
        s2c_load.prototype.err = 0;

        /**
         * s2c_load profile.
         * @member {profile.ProfileInfo|null|undefined} profile
         * @memberof profile.s2c_load
         * @instance
         */
        s2c_load.prototype.profile = null;

        /**
         * s2c_load new.
         * @member {boolean} new
         * @memberof profile.s2c_load
         * @instance
         */
        s2c_load.prototype["new"] = false;

        /**
         * s2c_load commonInfo.
         * @member {profile.CommonInfo|null|undefined} commonInfo
         * @memberof profile.s2c_load
         * @instance
         */
        s2c_load.prototype.commonInfo = null;

        /**
         * Creates a new s2c_load instance using the specified properties.
         * @function create
         * @memberof profile.s2c_load
         * @static
         * @param {profile.Is2c_load=} [properties] Properties to set
         * @returns {profile.s2c_load} s2c_load instance
         */
        s2c_load.create = function create(properties) {
            return new s2c_load(properties);
        };

        /**
         * Encodes the specified s2c_load message. Does not implicitly {@link profile.s2c_load.verify|verify} messages.
         * @function encode
         * @memberof profile.s2c_load
         * @static
         * @param {profile.s2c_load} message s2c_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.err);
            if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
                $root.profile.ProfileInfo.encode(message.profile, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message["new"] != null && Object.hasOwnProperty.call(message, "new"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message["new"]);
            if (message.commonInfo != null && Object.hasOwnProperty.call(message, "commonInfo"))
                $root.profile.CommonInfo.encode(message.commonInfo, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_load message from the specified reader or buffer.
         * @function decode
         * @memberof profile.s2c_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {profile.s2c_load} s2c_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.profile.s2c_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.int32();
                        break;
                    }
                case 2: {
                        message.profile = $root.profile.ProfileInfo.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message["new"] = reader.bool();
                        break;
                    }
                case 4: {
                        message.commonInfo = $root.profile.CommonInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_load
         * @function getTypeUrl
         * @memberof profile.s2c_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/profile.s2c_load";
        };

        return s2c_load;
    })();

    profile.c2s_receive_reward = (function() {

        /**
         * Properties of a c2s_receive_reward.
         * @memberof profile
         * @interface Ic2s_receive_reward
         */

        /**
         * Constructs a new c2s_receive_reward.
         * @memberof profile
         * @classdesc Represents a c2s_receive_reward.
         * @implements Ic2s_receive_reward
         * @constructor
         * @param {profile.Ic2s_receive_reward=} [properties] Properties to set
         */
        function c2s_receive_reward(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_receive_reward instance using the specified properties.
         * @function create
         * @memberof profile.c2s_receive_reward
         * @static
         * @param {profile.Ic2s_receive_reward=} [properties] Properties to set
         * @returns {profile.c2s_receive_reward} c2s_receive_reward instance
         */
        c2s_receive_reward.create = function create(properties) {
            return new c2s_receive_reward(properties);
        };

        /**
         * Encodes the specified c2s_receive_reward message. Does not implicitly {@link profile.c2s_receive_reward.verify|verify} messages.
         * @function encode
         * @memberof profile.c2s_receive_reward
         * @static
         * @param {profile.c2s_receive_reward} message c2s_receive_reward message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_receive_reward.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_receive_reward message from the specified reader or buffer.
         * @function decode
         * @memberof profile.c2s_receive_reward
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {profile.c2s_receive_reward} c2s_receive_reward
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_receive_reward.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.profile.c2s_receive_reward();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_receive_reward
         * @function getTypeUrl
         * @memberof profile.c2s_receive_reward
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_receive_reward.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/profile.c2s_receive_reward";
        };

        return c2s_receive_reward;
    })();

    profile.s2c_receive_reward = (function() {

        /**
         * Properties of a s2c_receive_reward.
         * @memberof profile
         * @interface Is2c_receive_reward
         * @property {number|null} [err] s2c_receive_reward err
         * @property {Array.<bag.ItemReward>|null} [rewards] s2c_receive_reward rewards
         */

        /**
         * Constructs a new s2c_receive_reward.
         * @memberof profile
         * @classdesc Represents a s2c_receive_reward.
         * @implements Is2c_receive_reward
         * @constructor
         * @param {profile.Is2c_receive_reward=} [properties] Properties to set
         */
        function s2c_receive_reward(properties) {
            this.rewards = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_receive_reward err.
         * @member {number} err
         * @memberof profile.s2c_receive_reward
         * @instance
         */
        s2c_receive_reward.prototype.err = 0;

        /**
         * s2c_receive_reward rewards.
         * @member {Array.<bag.ItemReward>} rewards
         * @memberof profile.s2c_receive_reward
         * @instance
         */
        s2c_receive_reward.prototype.rewards = $util.emptyArray;

        /**
         * Creates a new s2c_receive_reward instance using the specified properties.
         * @function create
         * @memberof profile.s2c_receive_reward
         * @static
         * @param {profile.Is2c_receive_reward=} [properties] Properties to set
         * @returns {profile.s2c_receive_reward} s2c_receive_reward instance
         */
        s2c_receive_reward.create = function create(properties) {
            return new s2c_receive_reward(properties);
        };

        /**
         * Encodes the specified s2c_receive_reward message. Does not implicitly {@link profile.s2c_receive_reward.verify|verify} messages.
         * @function encode
         * @memberof profile.s2c_receive_reward
         * @static
         * @param {profile.s2c_receive_reward} message s2c_receive_reward message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_receive_reward.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.err);
            if (message.rewards != null && message.rewards.length)
                for (let i = 0; i < message.rewards.length; ++i)
                    $root.bag.ItemReward.encode(message.rewards[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_receive_reward message from the specified reader or buffer.
         * @function decode
         * @memberof profile.s2c_receive_reward
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {profile.s2c_receive_reward} s2c_receive_reward
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_receive_reward.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.profile.s2c_receive_reward();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.int32();
                        break;
                    }
                case 2: {
                        if (!(message.rewards && message.rewards.length))
                            message.rewards = [];
                        message.rewards.push($root.bag.ItemReward.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_receive_reward
         * @function getTypeUrl
         * @memberof profile.s2c_receive_reward
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_receive_reward.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/profile.s2c_receive_reward";
        };

        return s2c_receive_reward;
    })();

    profile.notify_profile = (function() {

        /**
         * Properties of a notify_profile.
         * @memberof profile
         * @interface Inotify_profile
         * @property {profile.ProfileInfo|null} [profile] notify_profile profile
         * @property {profile.CommonInfo|null} [commonInfo] notify_profile commonInfo
         */

        /**
         * Constructs a new notify_profile.
         * @memberof profile
         * @classdesc Represents a notify_profile.
         * @implements Inotify_profile
         * @constructor
         * @param {profile.Inotify_profile=} [properties] Properties to set
         */
        function notify_profile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_profile profile.
         * @member {profile.ProfileInfo|null|undefined} profile
         * @memberof profile.notify_profile
         * @instance
         */
        notify_profile.prototype.profile = null;

        /**
         * notify_profile commonInfo.
         * @member {profile.CommonInfo|null|undefined} commonInfo
         * @memberof profile.notify_profile
         * @instance
         */
        notify_profile.prototype.commonInfo = null;

        /**
         * Creates a new notify_profile instance using the specified properties.
         * @function create
         * @memberof profile.notify_profile
         * @static
         * @param {profile.Inotify_profile=} [properties] Properties to set
         * @returns {profile.notify_profile} notify_profile instance
         */
        notify_profile.create = function create(properties) {
            return new notify_profile(properties);
        };

        /**
         * Encodes the specified notify_profile message. Does not implicitly {@link profile.notify_profile.verify|verify} messages.
         * @function encode
         * @memberof profile.notify_profile
         * @static
         * @param {profile.notify_profile} message notify_profile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_profile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
                $root.profile.ProfileInfo.encode(message.profile, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.commonInfo != null && Object.hasOwnProperty.call(message, "commonInfo"))
                $root.profile.CommonInfo.encode(message.commonInfo, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a notify_profile message from the specified reader or buffer.
         * @function decode
         * @memberof profile.notify_profile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {profile.notify_profile} notify_profile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_profile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.profile.notify_profile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.profile = $root.profile.ProfileInfo.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.commonInfo = $root.profile.CommonInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_profile
         * @function getTypeUrl
         * @memberof profile.notify_profile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_profile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/profile.notify_profile";
        };

        return notify_profile;
    })();

    return profile;
})();

export const recharge = $root.recharge = (() => {

    /**
     * Namespace recharge.
     * @exports recharge
     * @namespace
     */
    const recharge = {};

    recharge.RechargeInfo = (function() {

        /**
         * Properties of a RechargeInfo.
         * @memberof recharge
         * @interface IRechargeInfo
         * @property {number|null} [id] RechargeInfo id
         * @property {Array.<bag.ItemReward>|null} [items] RechargeInfo items
         * @property {number|null} [buyNum] RechargeInfo buyNum
         * @property {number|null} [price] RechargeInfo price
         * @property {number|null} [discount] RechargeInfo discount
         * @property {Array.<bag.ItemReward>|null} [extraItems] RechargeInfo extraItems
         * @property {Array.<bag.ItemReward>|null} [firstItems] RechargeInfo firstItems
         */

        /**
         * Constructs a new RechargeInfo.
         * @memberof recharge
         * @classdesc Represents a RechargeInfo.
         * @implements IRechargeInfo
         * @constructor
         * @param {recharge.IRechargeInfo=} [properties] Properties to set
         */
        function RechargeInfo(properties) {
            this.items = [];
            this.extraItems = [];
            this.firstItems = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RechargeInfo id.
         * @member {number} id
         * @memberof recharge.RechargeInfo
         * @instance
         */
        RechargeInfo.prototype.id = 0;

        /**
         * RechargeInfo items.
         * @member {Array.<bag.ItemReward>} items
         * @memberof recharge.RechargeInfo
         * @instance
         */
        RechargeInfo.prototype.items = $util.emptyArray;

        /**
         * RechargeInfo buyNum.
         * @member {number} buyNum
         * @memberof recharge.RechargeInfo
         * @instance
         */
        RechargeInfo.prototype.buyNum = 0;

        /**
         * RechargeInfo price.
         * @member {number} price
         * @memberof recharge.RechargeInfo
         * @instance
         */
        RechargeInfo.prototype.price = 0;

        /**
         * RechargeInfo discount.
         * @member {number} discount
         * @memberof recharge.RechargeInfo
         * @instance
         */
        RechargeInfo.prototype.discount = 0;

        /**
         * RechargeInfo extraItems.
         * @member {Array.<bag.ItemReward>} extraItems
         * @memberof recharge.RechargeInfo
         * @instance
         */
        RechargeInfo.prototype.extraItems = $util.emptyArray;

        /**
         * RechargeInfo firstItems.
         * @member {Array.<bag.ItemReward>} firstItems
         * @memberof recharge.RechargeInfo
         * @instance
         */
        RechargeInfo.prototype.firstItems = $util.emptyArray;

        /**
         * Creates a new RechargeInfo instance using the specified properties.
         * @function create
         * @memberof recharge.RechargeInfo
         * @static
         * @param {recharge.IRechargeInfo=} [properties] Properties to set
         * @returns {recharge.RechargeInfo} RechargeInfo instance
         */
        RechargeInfo.create = function create(properties) {
            return new RechargeInfo(properties);
        };

        /**
         * Encodes the specified RechargeInfo message. Does not implicitly {@link recharge.RechargeInfo.verify|verify} messages.
         * @function encode
         * @memberof recharge.RechargeInfo
         * @static
         * @param {recharge.RechargeInfo} message RechargeInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RechargeInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.bag.ItemReward.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.buyNum != null && Object.hasOwnProperty.call(message, "buyNum"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.buyNum);
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.price);
            if (message.discount != null && Object.hasOwnProperty.call(message, "discount"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.discount);
            if (message.extraItems != null && message.extraItems.length)
                for (let i = 0; i < message.extraItems.length; ++i)
                    $root.bag.ItemReward.encode(message.extraItems[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.firstItems != null && message.firstItems.length)
                for (let i = 0; i < message.firstItems.length; ++i)
                    $root.bag.ItemReward.encode(message.firstItems[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a RechargeInfo message from the specified reader or buffer.
         * @function decode
         * @memberof recharge.RechargeInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recharge.RechargeInfo} RechargeInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RechargeInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recharge.RechargeInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.bag.ItemReward.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.buyNum = reader.uint32();
                        break;
                    }
                case 4: {
                        message.price = reader.uint32();
                        break;
                    }
                case 5: {
                        message.discount = reader.uint32();
                        break;
                    }
                case 6: {
                        if (!(message.extraItems && message.extraItems.length))
                            message.extraItems = [];
                        message.extraItems.push($root.bag.ItemReward.decode(reader, reader.uint32()));
                        break;
                    }
                case 7: {
                        if (!(message.firstItems && message.firstItems.length))
                            message.firstItems = [];
                        message.firstItems.push($root.bag.ItemReward.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for RechargeInfo
         * @function getTypeUrl
         * @memberof recharge.RechargeInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RechargeInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recharge.RechargeInfo";
        };

        return RechargeInfo;
    })();

    recharge.c2s_load_recharge = (function() {

        /**
         * Properties of a c2s_load_recharge.
         * @memberof recharge
         * @interface Ic2s_load_recharge
         * @property {number|null} [type] c2s_load_recharge type
         */

        /**
         * Constructs a new c2s_load_recharge.
         * @memberof recharge
         * @classdesc Represents a c2s_load_recharge.
         * @implements Ic2s_load_recharge
         * @constructor
         * @param {recharge.Ic2s_load_recharge=} [properties] Properties to set
         */
        function c2s_load_recharge(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_load_recharge type.
         * @member {number} type
         * @memberof recharge.c2s_load_recharge
         * @instance
         */
        c2s_load_recharge.prototype.type = 0;

        /**
         * Creates a new c2s_load_recharge instance using the specified properties.
         * @function create
         * @memberof recharge.c2s_load_recharge
         * @static
         * @param {recharge.Ic2s_load_recharge=} [properties] Properties to set
         * @returns {recharge.c2s_load_recharge} c2s_load_recharge instance
         */
        c2s_load_recharge.create = function create(properties) {
            return new c2s_load_recharge(properties);
        };

        /**
         * Encodes the specified c2s_load_recharge message. Does not implicitly {@link recharge.c2s_load_recharge.verify|verify} messages.
         * @function encode
         * @memberof recharge.c2s_load_recharge
         * @static
         * @param {recharge.c2s_load_recharge} message c2s_load_recharge message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_load_recharge.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.type);
            return writer;
        };

        /**
         * Decodes a c2s_load_recharge message from the specified reader or buffer.
         * @function decode
         * @memberof recharge.c2s_load_recharge
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recharge.c2s_load_recharge} c2s_load_recharge
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_load_recharge.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recharge.c2s_load_recharge();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_load_recharge
         * @function getTypeUrl
         * @memberof recharge.c2s_load_recharge
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_load_recharge.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recharge.c2s_load_recharge";
        };

        return c2s_load_recharge;
    })();

    recharge.s2c_load_recharge = (function() {

        /**
         * Properties of a s2c_load_recharge.
         * @memberof recharge
         * @interface Is2c_load_recharge
         * @property {number|null} [err] s2c_load_recharge err
         * @property {Array.<recharge.RechargeInfo>|null} [recharges] s2c_load_recharge recharges
         */

        /**
         * Constructs a new s2c_load_recharge.
         * @memberof recharge
         * @classdesc Represents a s2c_load_recharge.
         * @implements Is2c_load_recharge
         * @constructor
         * @param {recharge.Is2c_load_recharge=} [properties] Properties to set
         */
        function s2c_load_recharge(properties) {
            this.recharges = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_load_recharge err.
         * @member {number} err
         * @memberof recharge.s2c_load_recharge
         * @instance
         */
        s2c_load_recharge.prototype.err = 0;

        /**
         * s2c_load_recharge recharges.
         * @member {Array.<recharge.RechargeInfo>} recharges
         * @memberof recharge.s2c_load_recharge
         * @instance
         */
        s2c_load_recharge.prototype.recharges = $util.emptyArray;

        /**
         * Creates a new s2c_load_recharge instance using the specified properties.
         * @function create
         * @memberof recharge.s2c_load_recharge
         * @static
         * @param {recharge.Is2c_load_recharge=} [properties] Properties to set
         * @returns {recharge.s2c_load_recharge} s2c_load_recharge instance
         */
        s2c_load_recharge.create = function create(properties) {
            return new s2c_load_recharge(properties);
        };

        /**
         * Encodes the specified s2c_load_recharge message. Does not implicitly {@link recharge.s2c_load_recharge.verify|verify} messages.
         * @function encode
         * @memberof recharge.s2c_load_recharge
         * @static
         * @param {recharge.s2c_load_recharge} message s2c_load_recharge message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_load_recharge.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.recharges != null && message.recharges.length)
                for (let i = 0; i < message.recharges.length; ++i)
                    $root.recharge.RechargeInfo.encode(message.recharges[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_load_recharge message from the specified reader or buffer.
         * @function decode
         * @memberof recharge.s2c_load_recharge
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recharge.s2c_load_recharge} s2c_load_recharge
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_load_recharge.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recharge.s2c_load_recharge();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.recharges && message.recharges.length))
                            message.recharges = [];
                        message.recharges.push($root.recharge.RechargeInfo.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_load_recharge
         * @function getTypeUrl
         * @memberof recharge.s2c_load_recharge
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_load_recharge.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recharge.s2c_load_recharge";
        };

        return s2c_load_recharge;
    })();

    return recharge;
})();

export const recruit = $root.recruit = (() => {

    /**
     * Namespace recruit.
     * @exports recruit
     * @namespace
     */
    const recruit = {};

    recruit.PoolInfo = (function() {

        /**
         * Properties of a PoolInfo.
         * @memberof recruit
         * @interface IPoolInfo
         * @property {number|null} [poolId] PoolInfo poolId
         * @property {number|null} [useFreeTimes] PoolInfo useFreeTimes
         * @property {number|null} [lastUseFreeTime] PoolInfo lastUseFreeTime
         * @property {number|null} [currentTimes] PoolInfo currentTimes
         * @property {number|null} [times] PoolInfo times
         * @property {number|null} [lastRecruitTime] PoolInfo lastRecruitTime
         * @property {boolean|null} [openGuaranteed] PoolInfo openGuaranteed
         */

        /**
         * Constructs a new PoolInfo.
         * @memberof recruit
         * @classdesc Represents a PoolInfo.
         * @implements IPoolInfo
         * @constructor
         * @param {recruit.IPoolInfo=} [properties] Properties to set
         */
        function PoolInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PoolInfo poolId.
         * @member {number} poolId
         * @memberof recruit.PoolInfo
         * @instance
         */
        PoolInfo.prototype.poolId = 0;

        /**
         * PoolInfo useFreeTimes.
         * @member {number} useFreeTimes
         * @memberof recruit.PoolInfo
         * @instance
         */
        PoolInfo.prototype.useFreeTimes = 0;

        /**
         * PoolInfo lastUseFreeTime.
         * @member {number} lastUseFreeTime
         * @memberof recruit.PoolInfo
         * @instance
         */
        PoolInfo.prototype.lastUseFreeTime = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * PoolInfo currentTimes.
         * @member {number} currentTimes
         * @memberof recruit.PoolInfo
         * @instance
         */
        PoolInfo.prototype.currentTimes = 0;

        /**
         * PoolInfo times.
         * @member {number} times
         * @memberof recruit.PoolInfo
         * @instance
         */
        PoolInfo.prototype.times = 0;

        /**
         * PoolInfo lastRecruitTime.
         * @member {number} lastRecruitTime
         * @memberof recruit.PoolInfo
         * @instance
         */
        PoolInfo.prototype.lastRecruitTime = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * PoolInfo openGuaranteed.
         * @member {boolean} openGuaranteed
         * @memberof recruit.PoolInfo
         * @instance
         */
        PoolInfo.prototype.openGuaranteed = false;

        /**
         * Creates a new PoolInfo instance using the specified properties.
         * @function create
         * @memberof recruit.PoolInfo
         * @static
         * @param {recruit.IPoolInfo=} [properties] Properties to set
         * @returns {recruit.PoolInfo} PoolInfo instance
         */
        PoolInfo.create = function create(properties) {
            return new PoolInfo(properties);
        };

        /**
         * Encodes the specified PoolInfo message. Does not implicitly {@link recruit.PoolInfo.verify|verify} messages.
         * @function encode
         * @memberof recruit.PoolInfo
         * @static
         * @param {recruit.PoolInfo} message PoolInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PoolInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.poolId != null && Object.hasOwnProperty.call(message, "poolId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.poolId);
            if (message.useFreeTimes != null && Object.hasOwnProperty.call(message, "useFreeTimes"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.useFreeTimes);
            if (message.lastUseFreeTime != null && Object.hasOwnProperty.call(message, "lastUseFreeTime"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.lastUseFreeTime);
            if (message.currentTimes != null && Object.hasOwnProperty.call(message, "currentTimes"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.currentTimes);
            if (message.times != null && Object.hasOwnProperty.call(message, "times"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.times);
            if (message.lastRecruitTime != null && Object.hasOwnProperty.call(message, "lastRecruitTime"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.lastRecruitTime);
            if (message.openGuaranteed != null && Object.hasOwnProperty.call(message, "openGuaranteed"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.openGuaranteed);
            return writer;
        };

        /**
         * Decodes a PoolInfo message from the specified reader or buffer.
         * @function decode
         * @memberof recruit.PoolInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recruit.PoolInfo} PoolInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PoolInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recruit.PoolInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.poolId = reader.uint32();
                        break;
                    }
                case 2: {
                        message.useFreeTimes = reader.uint32();
                        break;
                    }
                case 3: {
                        message.lastUseFreeTime = reader.uint64();
                        break;
                    }
                case 4: {
                        message.currentTimes = reader.uint32();
                        break;
                    }
                case 5: {
                        message.times = reader.uint32();
                        break;
                    }
                case 6: {
                        message.lastRecruitTime = reader.uint64();
                        break;
                    }
                case 7: {
                        message.openGuaranteed = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for PoolInfo
         * @function getTypeUrl
         * @memberof recruit.PoolInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PoolInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recruit.PoolInfo";
        };

        return PoolInfo;
    })();

    recruit.CommonInfo = (function() {

        /**
         * Properties of a CommonInfo.
         * @memberof recruit
         * @interface ICommonInfo
         * @property {number|null} [credit] CommonInfo credit
         * @property {number|null} [closeBuyPopupTime] CommonInfo closeBuyPopupTime
         */

        /**
         * Constructs a new CommonInfo.
         * @memberof recruit
         * @classdesc Represents a CommonInfo.
         * @implements ICommonInfo
         * @constructor
         * @param {recruit.ICommonInfo=} [properties] Properties to set
         */
        function CommonInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CommonInfo credit.
         * @member {number} credit
         * @memberof recruit.CommonInfo
         * @instance
         */
        CommonInfo.prototype.credit = 0;

        /**
         * CommonInfo closeBuyPopupTime.
         * @member {number} closeBuyPopupTime
         * @memberof recruit.CommonInfo
         * @instance
         */
        CommonInfo.prototype.closeBuyPopupTime = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new CommonInfo instance using the specified properties.
         * @function create
         * @memberof recruit.CommonInfo
         * @static
         * @param {recruit.ICommonInfo=} [properties] Properties to set
         * @returns {recruit.CommonInfo} CommonInfo instance
         */
        CommonInfo.create = function create(properties) {
            return new CommonInfo(properties);
        };

        /**
         * Encodes the specified CommonInfo message. Does not implicitly {@link recruit.CommonInfo.verify|verify} messages.
         * @function encode
         * @memberof recruit.CommonInfo
         * @static
         * @param {recruit.CommonInfo} message CommonInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommonInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.credit != null && Object.hasOwnProperty.call(message, "credit"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.credit);
            if (message.closeBuyPopupTime != null && Object.hasOwnProperty.call(message, "closeBuyPopupTime"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.closeBuyPopupTime);
            return writer;
        };

        /**
         * Decodes a CommonInfo message from the specified reader or buffer.
         * @function decode
         * @memberof recruit.CommonInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recruit.CommonInfo} CommonInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommonInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recruit.CommonInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.credit = reader.uint32();
                        break;
                    }
                case 2: {
                        message.closeBuyPopupTime = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for CommonInfo
         * @function getTypeUrl
         * @memberof recruit.CommonInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CommonInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recruit.CommonInfo";
        };

        return CommonInfo;
    })();

    recruit.c2s_load = (function() {

        /**
         * Properties of a c2s_load.
         * @memberof recruit
         * @interface Ic2s_load
         */

        /**
         * Constructs a new c2s_load.
         * @memberof recruit
         * @classdesc Represents a c2s_load.
         * @implements Ic2s_load
         * @constructor
         * @param {recruit.Ic2s_load=} [properties] Properties to set
         */
        function c2s_load(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_load instance using the specified properties.
         * @function create
         * @memberof recruit.c2s_load
         * @static
         * @param {recruit.Ic2s_load=} [properties] Properties to set
         * @returns {recruit.c2s_load} c2s_load instance
         */
        c2s_load.create = function create(properties) {
            return new c2s_load(properties);
        };

        /**
         * Encodes the specified c2s_load message. Does not implicitly {@link recruit.c2s_load.verify|verify} messages.
         * @function encode
         * @memberof recruit.c2s_load
         * @static
         * @param {recruit.c2s_load} message c2s_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_load message from the specified reader or buffer.
         * @function decode
         * @memberof recruit.c2s_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recruit.c2s_load} c2s_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recruit.c2s_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_load
         * @function getTypeUrl
         * @memberof recruit.c2s_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recruit.c2s_load";
        };

        return c2s_load;
    })();

    recruit.s2c_load = (function() {

        /**
         * Properties of a s2c_load.
         * @memberof recruit
         * @interface Is2c_load
         * @property {number|null} [err] s2c_load err
         * @property {Array.<recruit.PoolInfo>|null} [poolInfoList] s2c_load poolInfoList
         * @property {recruit.CommonInfo|null} [commonInfo] s2c_load commonInfo
         */

        /**
         * Constructs a new s2c_load.
         * @memberof recruit
         * @classdesc Represents a s2c_load.
         * @implements Is2c_load
         * @constructor
         * @param {recruit.Is2c_load=} [properties] Properties to set
         */
        function s2c_load(properties) {
            this.poolInfoList = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_load err.
         * @member {number} err
         * @memberof recruit.s2c_load
         * @instance
         */
        s2c_load.prototype.err = 0;

        /**
         * s2c_load poolInfoList.
         * @member {Array.<recruit.PoolInfo>} poolInfoList
         * @memberof recruit.s2c_load
         * @instance
         */
        s2c_load.prototype.poolInfoList = $util.emptyArray;

        /**
         * s2c_load commonInfo.
         * @member {recruit.CommonInfo|null|undefined} commonInfo
         * @memberof recruit.s2c_load
         * @instance
         */
        s2c_load.prototype.commonInfo = null;

        /**
         * Creates a new s2c_load instance using the specified properties.
         * @function create
         * @memberof recruit.s2c_load
         * @static
         * @param {recruit.Is2c_load=} [properties] Properties to set
         * @returns {recruit.s2c_load} s2c_load instance
         */
        s2c_load.create = function create(properties) {
            return new s2c_load(properties);
        };

        /**
         * Encodes the specified s2c_load message. Does not implicitly {@link recruit.s2c_load.verify|verify} messages.
         * @function encode
         * @memberof recruit.s2c_load
         * @static
         * @param {recruit.s2c_load} message s2c_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.poolInfoList != null && message.poolInfoList.length)
                for (let i = 0; i < message.poolInfoList.length; ++i)
                    $root.recruit.PoolInfo.encode(message.poolInfoList[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.commonInfo != null && Object.hasOwnProperty.call(message, "commonInfo"))
                $root.recruit.CommonInfo.encode(message.commonInfo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_load message from the specified reader or buffer.
         * @function decode
         * @memberof recruit.s2c_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recruit.s2c_load} s2c_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recruit.s2c_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.poolInfoList && message.poolInfoList.length))
                            message.poolInfoList = [];
                        message.poolInfoList.push($root.recruit.PoolInfo.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.commonInfo = $root.recruit.CommonInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_load
         * @function getTypeUrl
         * @memberof recruit.s2c_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recruit.s2c_load";
        };

        return s2c_load;
    })();

    recruit.c2s_recruit = (function() {

        /**
         * Properties of a c2s_recruit.
         * @memberof recruit
         * @interface Ic2s_recruit
         * @property {number|null} [poolId] c2s_recruit poolId
         * @property {number|null} [recruitType] c2s_recruit recruitType
         */

        /**
         * Constructs a new c2s_recruit.
         * @memberof recruit
         * @classdesc Represents a c2s_recruit.
         * @implements Ic2s_recruit
         * @constructor
         * @param {recruit.Ic2s_recruit=} [properties] Properties to set
         */
        function c2s_recruit(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_recruit poolId.
         * @member {number} poolId
         * @memberof recruit.c2s_recruit
         * @instance
         */
        c2s_recruit.prototype.poolId = 0;

        /**
         * c2s_recruit recruitType.
         * @member {number} recruitType
         * @memberof recruit.c2s_recruit
         * @instance
         */
        c2s_recruit.prototype.recruitType = 0;

        /**
         * Creates a new c2s_recruit instance using the specified properties.
         * @function create
         * @memberof recruit.c2s_recruit
         * @static
         * @param {recruit.Ic2s_recruit=} [properties] Properties to set
         * @returns {recruit.c2s_recruit} c2s_recruit instance
         */
        c2s_recruit.create = function create(properties) {
            return new c2s_recruit(properties);
        };

        /**
         * Encodes the specified c2s_recruit message. Does not implicitly {@link recruit.c2s_recruit.verify|verify} messages.
         * @function encode
         * @memberof recruit.c2s_recruit
         * @static
         * @param {recruit.c2s_recruit} message c2s_recruit message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_recruit.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.poolId != null && Object.hasOwnProperty.call(message, "poolId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.poolId);
            if (message.recruitType != null && Object.hasOwnProperty.call(message, "recruitType"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.recruitType);
            return writer;
        };

        /**
         * Decodes a c2s_recruit message from the specified reader or buffer.
         * @function decode
         * @memberof recruit.c2s_recruit
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recruit.c2s_recruit} c2s_recruit
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_recruit.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recruit.c2s_recruit();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.poolId = reader.uint32();
                        break;
                    }
                case 2: {
                        message.recruitType = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_recruit
         * @function getTypeUrl
         * @memberof recruit.c2s_recruit
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_recruit.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recruit.c2s_recruit";
        };

        return c2s_recruit;
    })();

    recruit.s2c_recruit = (function() {

        /**
         * Properties of a s2c_recruit.
         * @memberof recruit
         * @interface Is2c_recruit
         * @property {number|null} [err] s2c_recruit err
         * @property {Array.<bag.ItemReward>|null} [rewards] s2c_recruit rewards
         */

        /**
         * Constructs a new s2c_recruit.
         * @memberof recruit
         * @classdesc Represents a s2c_recruit.
         * @implements Is2c_recruit
         * @constructor
         * @param {recruit.Is2c_recruit=} [properties] Properties to set
         */
        function s2c_recruit(properties) {
            this.rewards = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_recruit err.
         * @member {number} err
         * @memberof recruit.s2c_recruit
         * @instance
         */
        s2c_recruit.prototype.err = 0;

        /**
         * s2c_recruit rewards.
         * @member {Array.<bag.ItemReward>} rewards
         * @memberof recruit.s2c_recruit
         * @instance
         */
        s2c_recruit.prototype.rewards = $util.emptyArray;

        /**
         * Creates a new s2c_recruit instance using the specified properties.
         * @function create
         * @memberof recruit.s2c_recruit
         * @static
         * @param {recruit.Is2c_recruit=} [properties] Properties to set
         * @returns {recruit.s2c_recruit} s2c_recruit instance
         */
        s2c_recruit.create = function create(properties) {
            return new s2c_recruit(properties);
        };

        /**
         * Encodes the specified s2c_recruit message. Does not implicitly {@link recruit.s2c_recruit.verify|verify} messages.
         * @function encode
         * @memberof recruit.s2c_recruit
         * @static
         * @param {recruit.s2c_recruit} message s2c_recruit message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_recruit.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.rewards != null && message.rewards.length)
                for (let i = 0; i < message.rewards.length; ++i)
                    $root.bag.ItemReward.encode(message.rewards[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_recruit message from the specified reader or buffer.
         * @function decode
         * @memberof recruit.s2c_recruit
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recruit.s2c_recruit} s2c_recruit
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_recruit.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recruit.s2c_recruit();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.rewards && message.rewards.length))
                            message.rewards = [];
                        message.rewards.push($root.bag.ItemReward.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_recruit
         * @function getTypeUrl
         * @memberof recruit.s2c_recruit
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_recruit.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recruit.s2c_recruit";
        };

        return s2c_recruit;
    })();

    recruit.c2s_receive_credit_award = (function() {

        /**
         * Properties of a c2s_receive_credit_award.
         * @memberof recruit
         * @interface Ic2s_receive_credit_award
         */

        /**
         * Constructs a new c2s_receive_credit_award.
         * @memberof recruit
         * @classdesc Represents a c2s_receive_credit_award.
         * @implements Ic2s_receive_credit_award
         * @constructor
         * @param {recruit.Ic2s_receive_credit_award=} [properties] Properties to set
         */
        function c2s_receive_credit_award(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_receive_credit_award instance using the specified properties.
         * @function create
         * @memberof recruit.c2s_receive_credit_award
         * @static
         * @param {recruit.Ic2s_receive_credit_award=} [properties] Properties to set
         * @returns {recruit.c2s_receive_credit_award} c2s_receive_credit_award instance
         */
        c2s_receive_credit_award.create = function create(properties) {
            return new c2s_receive_credit_award(properties);
        };

        /**
         * Encodes the specified c2s_receive_credit_award message. Does not implicitly {@link recruit.c2s_receive_credit_award.verify|verify} messages.
         * @function encode
         * @memberof recruit.c2s_receive_credit_award
         * @static
         * @param {recruit.c2s_receive_credit_award} message c2s_receive_credit_award message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_receive_credit_award.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_receive_credit_award message from the specified reader or buffer.
         * @function decode
         * @memberof recruit.c2s_receive_credit_award
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recruit.c2s_receive_credit_award} c2s_receive_credit_award
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_receive_credit_award.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recruit.c2s_receive_credit_award();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_receive_credit_award
         * @function getTypeUrl
         * @memberof recruit.c2s_receive_credit_award
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_receive_credit_award.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recruit.c2s_receive_credit_award";
        };

        return c2s_receive_credit_award;
    })();

    recruit.s2c_receive_credit_award = (function() {

        /**
         * Properties of a s2c_receive_credit_award.
         * @memberof recruit
         * @interface Is2c_receive_credit_award
         * @property {number|null} [err] s2c_receive_credit_award err
         * @property {Array.<bag.ItemReward>|null} [rewards] s2c_receive_credit_award rewards
         */

        /**
         * Constructs a new s2c_receive_credit_award.
         * @memberof recruit
         * @classdesc Represents a s2c_receive_credit_award.
         * @implements Is2c_receive_credit_award
         * @constructor
         * @param {recruit.Is2c_receive_credit_award=} [properties] Properties to set
         */
        function s2c_receive_credit_award(properties) {
            this.rewards = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_receive_credit_award err.
         * @member {number} err
         * @memberof recruit.s2c_receive_credit_award
         * @instance
         */
        s2c_receive_credit_award.prototype.err = 0;

        /**
         * s2c_receive_credit_award rewards.
         * @member {Array.<bag.ItemReward>} rewards
         * @memberof recruit.s2c_receive_credit_award
         * @instance
         */
        s2c_receive_credit_award.prototype.rewards = $util.emptyArray;

        /**
         * Creates a new s2c_receive_credit_award instance using the specified properties.
         * @function create
         * @memberof recruit.s2c_receive_credit_award
         * @static
         * @param {recruit.Is2c_receive_credit_award=} [properties] Properties to set
         * @returns {recruit.s2c_receive_credit_award} s2c_receive_credit_award instance
         */
        s2c_receive_credit_award.create = function create(properties) {
            return new s2c_receive_credit_award(properties);
        };

        /**
         * Encodes the specified s2c_receive_credit_award message. Does not implicitly {@link recruit.s2c_receive_credit_award.verify|verify} messages.
         * @function encode
         * @memberof recruit.s2c_receive_credit_award
         * @static
         * @param {recruit.s2c_receive_credit_award} message s2c_receive_credit_award message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_receive_credit_award.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.rewards != null && message.rewards.length)
                for (let i = 0; i < message.rewards.length; ++i)
                    $root.bag.ItemReward.encode(message.rewards[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_receive_credit_award message from the specified reader or buffer.
         * @function decode
         * @memberof recruit.s2c_receive_credit_award
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recruit.s2c_receive_credit_award} s2c_receive_credit_award
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_receive_credit_award.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recruit.s2c_receive_credit_award();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.rewards && message.rewards.length))
                            message.rewards = [];
                        message.rewards.push($root.bag.ItemReward.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_receive_credit_award
         * @function getTypeUrl
         * @memberof recruit.s2c_receive_credit_award
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_receive_credit_award.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recruit.s2c_receive_credit_award";
        };

        return s2c_receive_credit_award;
    })();

    recruit.c2s_close_buy_popup = (function() {

        /**
         * Properties of a c2s_close_buy_popup.
         * @memberof recruit
         * @interface Ic2s_close_buy_popup
         */

        /**
         * Constructs a new c2s_close_buy_popup.
         * @memberof recruit
         * @classdesc Represents a c2s_close_buy_popup.
         * @implements Ic2s_close_buy_popup
         * @constructor
         * @param {recruit.Ic2s_close_buy_popup=} [properties] Properties to set
         */
        function c2s_close_buy_popup(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_close_buy_popup instance using the specified properties.
         * @function create
         * @memberof recruit.c2s_close_buy_popup
         * @static
         * @param {recruit.Ic2s_close_buy_popup=} [properties] Properties to set
         * @returns {recruit.c2s_close_buy_popup} c2s_close_buy_popup instance
         */
        c2s_close_buy_popup.create = function create(properties) {
            return new c2s_close_buy_popup(properties);
        };

        /**
         * Encodes the specified c2s_close_buy_popup message. Does not implicitly {@link recruit.c2s_close_buy_popup.verify|verify} messages.
         * @function encode
         * @memberof recruit.c2s_close_buy_popup
         * @static
         * @param {recruit.c2s_close_buy_popup} message c2s_close_buy_popup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_close_buy_popup.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_close_buy_popup message from the specified reader or buffer.
         * @function decode
         * @memberof recruit.c2s_close_buy_popup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recruit.c2s_close_buy_popup} c2s_close_buy_popup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_close_buy_popup.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recruit.c2s_close_buy_popup();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_close_buy_popup
         * @function getTypeUrl
         * @memberof recruit.c2s_close_buy_popup
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_close_buy_popup.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recruit.c2s_close_buy_popup";
        };

        return c2s_close_buy_popup;
    })();

    recruit.s2c_close_buy_popup = (function() {

        /**
         * Properties of a s2c_close_buy_popup.
         * @memberof recruit
         * @interface Is2c_close_buy_popup
         * @property {number|null} [err] s2c_close_buy_popup err
         */

        /**
         * Constructs a new s2c_close_buy_popup.
         * @memberof recruit
         * @classdesc Represents a s2c_close_buy_popup.
         * @implements Is2c_close_buy_popup
         * @constructor
         * @param {recruit.Is2c_close_buy_popup=} [properties] Properties to set
         */
        function s2c_close_buy_popup(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_close_buy_popup err.
         * @member {number} err
         * @memberof recruit.s2c_close_buy_popup
         * @instance
         */
        s2c_close_buy_popup.prototype.err = 0;

        /**
         * Creates a new s2c_close_buy_popup instance using the specified properties.
         * @function create
         * @memberof recruit.s2c_close_buy_popup
         * @static
         * @param {recruit.Is2c_close_buy_popup=} [properties] Properties to set
         * @returns {recruit.s2c_close_buy_popup} s2c_close_buy_popup instance
         */
        s2c_close_buy_popup.create = function create(properties) {
            return new s2c_close_buy_popup(properties);
        };

        /**
         * Encodes the specified s2c_close_buy_popup message. Does not implicitly {@link recruit.s2c_close_buy_popup.verify|verify} messages.
         * @function encode
         * @memberof recruit.s2c_close_buy_popup
         * @static
         * @param {recruit.s2c_close_buy_popup} message s2c_close_buy_popup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_close_buy_popup.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_close_buy_popup message from the specified reader or buffer.
         * @function decode
         * @memberof recruit.s2c_close_buy_popup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recruit.s2c_close_buy_popup} s2c_close_buy_popup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_close_buy_popup.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recruit.s2c_close_buy_popup();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_close_buy_popup
         * @function getTypeUrl
         * @memberof recruit.s2c_close_buy_popup
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_close_buy_popup.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recruit.s2c_close_buy_popup";
        };

        return s2c_close_buy_popup;
    })();

    recruit.c2s_buy_ticket = (function() {

        /**
         * Properties of a c2s_buy_ticket.
         * @memberof recruit
         * @interface Ic2s_buy_ticket
         * @property {number|null} [num] c2s_buy_ticket num
         */

        /**
         * Constructs a new c2s_buy_ticket.
         * @memberof recruit
         * @classdesc Represents a c2s_buy_ticket.
         * @implements Ic2s_buy_ticket
         * @constructor
         * @param {recruit.Ic2s_buy_ticket=} [properties] Properties to set
         */
        function c2s_buy_ticket(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_buy_ticket num.
         * @member {number} num
         * @memberof recruit.c2s_buy_ticket
         * @instance
         */
        c2s_buy_ticket.prototype.num = 0;

        /**
         * Creates a new c2s_buy_ticket instance using the specified properties.
         * @function create
         * @memberof recruit.c2s_buy_ticket
         * @static
         * @param {recruit.Ic2s_buy_ticket=} [properties] Properties to set
         * @returns {recruit.c2s_buy_ticket} c2s_buy_ticket instance
         */
        c2s_buy_ticket.create = function create(properties) {
            return new c2s_buy_ticket(properties);
        };

        /**
         * Encodes the specified c2s_buy_ticket message. Does not implicitly {@link recruit.c2s_buy_ticket.verify|verify} messages.
         * @function encode
         * @memberof recruit.c2s_buy_ticket
         * @static
         * @param {recruit.c2s_buy_ticket} message c2s_buy_ticket message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_buy_ticket.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.num != null && Object.hasOwnProperty.call(message, "num"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.num);
            return writer;
        };

        /**
         * Decodes a c2s_buy_ticket message from the specified reader or buffer.
         * @function decode
         * @memberof recruit.c2s_buy_ticket
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recruit.c2s_buy_ticket} c2s_buy_ticket
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_buy_ticket.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recruit.c2s_buy_ticket();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.num = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_buy_ticket
         * @function getTypeUrl
         * @memberof recruit.c2s_buy_ticket
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_buy_ticket.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recruit.c2s_buy_ticket";
        };

        return c2s_buy_ticket;
    })();

    recruit.s2c_buy_ticket = (function() {

        /**
         * Properties of a s2c_buy_ticket.
         * @memberof recruit
         * @interface Is2c_buy_ticket
         * @property {number|null} [err] s2c_buy_ticket err
         */

        /**
         * Constructs a new s2c_buy_ticket.
         * @memberof recruit
         * @classdesc Represents a s2c_buy_ticket.
         * @implements Is2c_buy_ticket
         * @constructor
         * @param {recruit.Is2c_buy_ticket=} [properties] Properties to set
         */
        function s2c_buy_ticket(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_buy_ticket err.
         * @member {number} err
         * @memberof recruit.s2c_buy_ticket
         * @instance
         */
        s2c_buy_ticket.prototype.err = 0;

        /**
         * Creates a new s2c_buy_ticket instance using the specified properties.
         * @function create
         * @memberof recruit.s2c_buy_ticket
         * @static
         * @param {recruit.Is2c_buy_ticket=} [properties] Properties to set
         * @returns {recruit.s2c_buy_ticket} s2c_buy_ticket instance
         */
        s2c_buy_ticket.create = function create(properties) {
            return new s2c_buy_ticket(properties);
        };

        /**
         * Encodes the specified s2c_buy_ticket message. Does not implicitly {@link recruit.s2c_buy_ticket.verify|verify} messages.
         * @function encode
         * @memberof recruit.s2c_buy_ticket
         * @static
         * @param {recruit.s2c_buy_ticket} message s2c_buy_ticket message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_buy_ticket.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_buy_ticket message from the specified reader or buffer.
         * @function decode
         * @memberof recruit.s2c_buy_ticket
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recruit.s2c_buy_ticket} s2c_buy_ticket
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_buy_ticket.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recruit.s2c_buy_ticket();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_buy_ticket
         * @function getTypeUrl
         * @memberof recruit.s2c_buy_ticket
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_buy_ticket.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recruit.s2c_buy_ticket";
        };

        return s2c_buy_ticket;
    })();

    recruit.notify_pool_info_update = (function() {

        /**
         * Properties of a notify_pool_info_update.
         * @memberof recruit
         * @interface Inotify_pool_info_update
         * @property {Array.<recruit.PoolInfo>|null} [poolInfoList] notify_pool_info_update poolInfoList
         */

        /**
         * Constructs a new notify_pool_info_update.
         * @memberof recruit
         * @classdesc Represents a notify_pool_info_update.
         * @implements Inotify_pool_info_update
         * @constructor
         * @param {recruit.Inotify_pool_info_update=} [properties] Properties to set
         */
        function notify_pool_info_update(properties) {
            this.poolInfoList = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_pool_info_update poolInfoList.
         * @member {Array.<recruit.PoolInfo>} poolInfoList
         * @memberof recruit.notify_pool_info_update
         * @instance
         */
        notify_pool_info_update.prototype.poolInfoList = $util.emptyArray;

        /**
         * Creates a new notify_pool_info_update instance using the specified properties.
         * @function create
         * @memberof recruit.notify_pool_info_update
         * @static
         * @param {recruit.Inotify_pool_info_update=} [properties] Properties to set
         * @returns {recruit.notify_pool_info_update} notify_pool_info_update instance
         */
        notify_pool_info_update.create = function create(properties) {
            return new notify_pool_info_update(properties);
        };

        /**
         * Encodes the specified notify_pool_info_update message. Does not implicitly {@link recruit.notify_pool_info_update.verify|verify} messages.
         * @function encode
         * @memberof recruit.notify_pool_info_update
         * @static
         * @param {recruit.notify_pool_info_update} message notify_pool_info_update message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_pool_info_update.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.poolInfoList != null && message.poolInfoList.length)
                for (let i = 0; i < message.poolInfoList.length; ++i)
                    $root.recruit.PoolInfo.encode(message.poolInfoList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a notify_pool_info_update message from the specified reader or buffer.
         * @function decode
         * @memberof recruit.notify_pool_info_update
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recruit.notify_pool_info_update} notify_pool_info_update
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_pool_info_update.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recruit.notify_pool_info_update();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.poolInfoList && message.poolInfoList.length))
                            message.poolInfoList = [];
                        message.poolInfoList.push($root.recruit.PoolInfo.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_pool_info_update
         * @function getTypeUrl
         * @memberof recruit.notify_pool_info_update
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_pool_info_update.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recruit.notify_pool_info_update";
        };

        return notify_pool_info_update;
    })();

    recruit.notify_common_info_update = (function() {

        /**
         * Properties of a notify_common_info_update.
         * @memberof recruit
         * @interface Inotify_common_info_update
         * @property {recruit.CommonInfo|null} [commonInfo] notify_common_info_update commonInfo
         */

        /**
         * Constructs a new notify_common_info_update.
         * @memberof recruit
         * @classdesc Represents a notify_common_info_update.
         * @implements Inotify_common_info_update
         * @constructor
         * @param {recruit.Inotify_common_info_update=} [properties] Properties to set
         */
        function notify_common_info_update(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_common_info_update commonInfo.
         * @member {recruit.CommonInfo|null|undefined} commonInfo
         * @memberof recruit.notify_common_info_update
         * @instance
         */
        notify_common_info_update.prototype.commonInfo = null;

        /**
         * Creates a new notify_common_info_update instance using the specified properties.
         * @function create
         * @memberof recruit.notify_common_info_update
         * @static
         * @param {recruit.Inotify_common_info_update=} [properties] Properties to set
         * @returns {recruit.notify_common_info_update} notify_common_info_update instance
         */
        notify_common_info_update.create = function create(properties) {
            return new notify_common_info_update(properties);
        };

        /**
         * Encodes the specified notify_common_info_update message. Does not implicitly {@link recruit.notify_common_info_update.verify|verify} messages.
         * @function encode
         * @memberof recruit.notify_common_info_update
         * @static
         * @param {recruit.notify_common_info_update} message notify_common_info_update message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_common_info_update.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonInfo != null && Object.hasOwnProperty.call(message, "commonInfo"))
                $root.recruit.CommonInfo.encode(message.commonInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a notify_common_info_update message from the specified reader or buffer.
         * @function decode
         * @memberof recruit.notify_common_info_update
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recruit.notify_common_info_update} notify_common_info_update
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_common_info_update.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recruit.notify_common_info_update();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.commonInfo = $root.recruit.CommonInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_common_info_update
         * @function getTypeUrl
         * @memberof recruit.notify_common_info_update
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_common_info_update.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recruit.notify_common_info_update";
        };

        return notify_common_info_update;
    })();

    return recruit;
})();

export const shop = $root.shop = (() => {

    /**
     * Namespace shop.
     * @exports shop
     * @namespace
     */
    const shop = {};

    shop.ItemInfo = (function() {

        /**
         * Properties of an ItemInfo.
         * @memberof shop
         * @interface IItemInfo
         * @property {number|null} [id] ItemInfo id
         * @property {number|null} [buyNum] ItemInfo buyNum
         */

        /**
         * Constructs a new ItemInfo.
         * @memberof shop
         * @classdesc Represents an ItemInfo.
         * @implements IItemInfo
         * @constructor
         * @param {shop.IItemInfo=} [properties] Properties to set
         */
        function ItemInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ItemInfo id.
         * @member {number} id
         * @memberof shop.ItemInfo
         * @instance
         */
        ItemInfo.prototype.id = 0;

        /**
         * ItemInfo buyNum.
         * @member {number} buyNum
         * @memberof shop.ItemInfo
         * @instance
         */
        ItemInfo.prototype.buyNum = 0;

        /**
         * Creates a new ItemInfo instance using the specified properties.
         * @function create
         * @memberof shop.ItemInfo
         * @static
         * @param {shop.IItemInfo=} [properties] Properties to set
         * @returns {shop.ItemInfo} ItemInfo instance
         */
        ItemInfo.create = function create(properties) {
            return new ItemInfo(properties);
        };

        /**
         * Encodes the specified ItemInfo message. Does not implicitly {@link shop.ItemInfo.verify|verify} messages.
         * @function encode
         * @memberof shop.ItemInfo
         * @static
         * @param {shop.ItemInfo} message ItemInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ItemInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            if (message.buyNum != null && Object.hasOwnProperty.call(message, "buyNum"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.buyNum);
            return writer;
        };

        /**
         * Decodes an ItemInfo message from the specified reader or buffer.
         * @function decode
         * @memberof shop.ItemInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {shop.ItemInfo} ItemInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ItemInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.shop.ItemInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 2: {
                        message.buyNum = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for ItemInfo
         * @function getTypeUrl
         * @memberof shop.ItemInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ItemInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/shop.ItemInfo";
        };

        return ItemInfo;
    })();

    shop.c2s_load = (function() {

        /**
         * Properties of a c2s_load.
         * @memberof shop
         * @interface Ic2s_load
         * @property {number|null} [shopId] c2s_load shopId
         */

        /**
         * Constructs a new c2s_load.
         * @memberof shop
         * @classdesc Represents a c2s_load.
         * @implements Ic2s_load
         * @constructor
         * @param {shop.Ic2s_load=} [properties] Properties to set
         */
        function c2s_load(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_load shopId.
         * @member {number} shopId
         * @memberof shop.c2s_load
         * @instance
         */
        c2s_load.prototype.shopId = 0;

        /**
         * Creates a new c2s_load instance using the specified properties.
         * @function create
         * @memberof shop.c2s_load
         * @static
         * @param {shop.Ic2s_load=} [properties] Properties to set
         * @returns {shop.c2s_load} c2s_load instance
         */
        c2s_load.create = function create(properties) {
            return new c2s_load(properties);
        };

        /**
         * Encodes the specified c2s_load message. Does not implicitly {@link shop.c2s_load.verify|verify} messages.
         * @function encode
         * @memberof shop.c2s_load
         * @static
         * @param {shop.c2s_load} message c2s_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.shopId != null && Object.hasOwnProperty.call(message, "shopId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.shopId);
            return writer;
        };

        /**
         * Decodes a c2s_load message from the specified reader or buffer.
         * @function decode
         * @memberof shop.c2s_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {shop.c2s_load} c2s_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.shop.c2s_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.shopId = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_load
         * @function getTypeUrl
         * @memberof shop.c2s_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/shop.c2s_load";
        };

        return c2s_load;
    })();

    shop.s2c_load = (function() {

        /**
         * Properties of a s2c_load.
         * @memberof shop
         * @interface Is2c_load
         * @property {number|null} [err] s2c_load err
         * @property {Array.<shop.ItemInfo>|null} [items] s2c_load items
         * @property {number|null} [time] s2c_load time
         */

        /**
         * Constructs a new s2c_load.
         * @memberof shop
         * @classdesc Represents a s2c_load.
         * @implements Is2c_load
         * @constructor
         * @param {shop.Is2c_load=} [properties] Properties to set
         */
        function s2c_load(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_load err.
         * @member {number} err
         * @memberof shop.s2c_load
         * @instance
         */
        s2c_load.prototype.err = 0;

        /**
         * s2c_load items.
         * @member {Array.<shop.ItemInfo>} items
         * @memberof shop.s2c_load
         * @instance
         */
        s2c_load.prototype.items = $util.emptyArray;

        /**
         * s2c_load time.
         * @member {number} time
         * @memberof shop.s2c_load
         * @instance
         */
        s2c_load.prototype.time = 0;

        /**
         * Creates a new s2c_load instance using the specified properties.
         * @function create
         * @memberof shop.s2c_load
         * @static
         * @param {shop.Is2c_load=} [properties] Properties to set
         * @returns {shop.s2c_load} s2c_load instance
         */
        s2c_load.create = function create(properties) {
            return new s2c_load(properties);
        };

        /**
         * Encodes the specified s2c_load message. Does not implicitly {@link shop.s2c_load.verify|verify} messages.
         * @function encode
         * @memberof shop.s2c_load
         * @static
         * @param {shop.s2c_load} message s2c_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.shop.ItemInfo.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.time);
            return writer;
        };

        /**
         * Decodes a s2c_load message from the specified reader or buffer.
         * @function decode
         * @memberof shop.s2c_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {shop.s2c_load} s2c_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.shop.s2c_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.shop.ItemInfo.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.time = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_load
         * @function getTypeUrl
         * @memberof shop.s2c_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/shop.s2c_load";
        };

        return s2c_load;
    })();

    shop.c2s_buy = (function() {

        /**
         * Properties of a c2s_buy.
         * @memberof shop
         * @interface Ic2s_buy
         * @property {number|null} [shopId] c2s_buy shopId
         * @property {number|null} [shopItemId] c2s_buy shopItemId
         * @property {number|null} [num] c2s_buy num
         */

        /**
         * Constructs a new c2s_buy.
         * @memberof shop
         * @classdesc Represents a c2s_buy.
         * @implements Ic2s_buy
         * @constructor
         * @param {shop.Ic2s_buy=} [properties] Properties to set
         */
        function c2s_buy(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_buy shopId.
         * @member {number} shopId
         * @memberof shop.c2s_buy
         * @instance
         */
        c2s_buy.prototype.shopId = 0;

        /**
         * c2s_buy shopItemId.
         * @member {number} shopItemId
         * @memberof shop.c2s_buy
         * @instance
         */
        c2s_buy.prototype.shopItemId = 0;

        /**
         * c2s_buy num.
         * @member {number} num
         * @memberof shop.c2s_buy
         * @instance
         */
        c2s_buy.prototype.num = 0;

        /**
         * Creates a new c2s_buy instance using the specified properties.
         * @function create
         * @memberof shop.c2s_buy
         * @static
         * @param {shop.Ic2s_buy=} [properties] Properties to set
         * @returns {shop.c2s_buy} c2s_buy instance
         */
        c2s_buy.create = function create(properties) {
            return new c2s_buy(properties);
        };

        /**
         * Encodes the specified c2s_buy message. Does not implicitly {@link shop.c2s_buy.verify|verify} messages.
         * @function encode
         * @memberof shop.c2s_buy
         * @static
         * @param {shop.c2s_buy} message c2s_buy message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_buy.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.shopId != null && Object.hasOwnProperty.call(message, "shopId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.shopId);
            if (message.shopItemId != null && Object.hasOwnProperty.call(message, "shopItemId"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.shopItemId);
            if (message.num != null && Object.hasOwnProperty.call(message, "num"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.num);
            return writer;
        };

        /**
         * Decodes a c2s_buy message from the specified reader or buffer.
         * @function decode
         * @memberof shop.c2s_buy
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {shop.c2s_buy} c2s_buy
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_buy.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.shop.c2s_buy();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.shopId = reader.uint32();
                        break;
                    }
                case 2: {
                        message.shopItemId = reader.uint32();
                        break;
                    }
                case 3: {
                        message.num = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_buy
         * @function getTypeUrl
         * @memberof shop.c2s_buy
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_buy.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/shop.c2s_buy";
        };

        return c2s_buy;
    })();

    shop.s2c_buy = (function() {

        /**
         * Properties of a s2c_buy.
         * @memberof shop
         * @interface Is2c_buy
         * @property {number|null} [err] s2c_buy err
         */

        /**
         * Constructs a new s2c_buy.
         * @memberof shop
         * @classdesc Represents a s2c_buy.
         * @implements Is2c_buy
         * @constructor
         * @param {shop.Is2c_buy=} [properties] Properties to set
         */
        function s2c_buy(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_buy err.
         * @member {number} err
         * @memberof shop.s2c_buy
         * @instance
         */
        s2c_buy.prototype.err = 0;

        /**
         * Creates a new s2c_buy instance using the specified properties.
         * @function create
         * @memberof shop.s2c_buy
         * @static
         * @param {shop.Is2c_buy=} [properties] Properties to set
         * @returns {shop.s2c_buy} s2c_buy instance
         */
        s2c_buy.create = function create(properties) {
            return new s2c_buy(properties);
        };

        /**
         * Encodes the specified s2c_buy message. Does not implicitly {@link shop.s2c_buy.verify|verify} messages.
         * @function encode
         * @memberof shop.s2c_buy
         * @static
         * @param {shop.s2c_buy} message s2c_buy message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_buy.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_buy message from the specified reader or buffer.
         * @function decode
         * @memberof shop.s2c_buy
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {shop.s2c_buy} s2c_buy
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_buy.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.shop.s2c_buy();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_buy
         * @function getTypeUrl
         * @memberof shop.s2c_buy
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_buy.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/shop.s2c_buy";
        };

        return s2c_buy;
    })();

    return shop;
})();

export const skill = $root.skill = (() => {

    /**
     * Namespace skill.
     * @exports skill
     * @namespace
     */
    const skill = {};

    skill.c2s_load_skills = (function() {

        /**
         * Properties of a c2s_load_skills.
         * @memberof skill
         * @interface Ic2s_load_skills
         */

        /**
         * Constructs a new c2s_load_skills.
         * @memberof skill
         * @classdesc Represents a c2s_load_skills.
         * @implements Ic2s_load_skills
         * @constructor
         * @param {skill.Ic2s_load_skills=} [properties] Properties to set
         */
        function c2s_load_skills(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_load_skills instance using the specified properties.
         * @function create
         * @memberof skill.c2s_load_skills
         * @static
         * @param {skill.Ic2s_load_skills=} [properties] Properties to set
         * @returns {skill.c2s_load_skills} c2s_load_skills instance
         */
        c2s_load_skills.create = function create(properties) {
            return new c2s_load_skills(properties);
        };

        /**
         * Encodes the specified c2s_load_skills message. Does not implicitly {@link skill.c2s_load_skills.verify|verify} messages.
         * @function encode
         * @memberof skill.c2s_load_skills
         * @static
         * @param {skill.c2s_load_skills} message c2s_load_skills message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_load_skills.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_load_skills message from the specified reader or buffer.
         * @function decode
         * @memberof skill.c2s_load_skills
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {skill.c2s_load_skills} c2s_load_skills
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_load_skills.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.skill.c2s_load_skills();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_load_skills
         * @function getTypeUrl
         * @memberof skill.c2s_load_skills
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_load_skills.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/skill.c2s_load_skills";
        };

        return c2s_load_skills;
    })();

    skill.s2c_load_skills = (function() {

        /**
         * Properties of a s2c_load_skills.
         * @memberof skill
         * @interface Is2c_load_skills
         * @property {number|null} [err] s2c_load_skills err
         * @property {Array.<number>|null} [skills] s2c_load_skills skills
         */

        /**
         * Constructs a new s2c_load_skills.
         * @memberof skill
         * @classdesc Represents a s2c_load_skills.
         * @implements Is2c_load_skills
         * @constructor
         * @param {skill.Is2c_load_skills=} [properties] Properties to set
         */
        function s2c_load_skills(properties) {
            this.skills = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_load_skills err.
         * @member {number} err
         * @memberof skill.s2c_load_skills
         * @instance
         */
        s2c_load_skills.prototype.err = 0;

        /**
         * s2c_load_skills skills.
         * @member {Array.<number>} skills
         * @memberof skill.s2c_load_skills
         * @instance
         */
        s2c_load_skills.prototype.skills = $util.emptyArray;

        /**
         * Creates a new s2c_load_skills instance using the specified properties.
         * @function create
         * @memberof skill.s2c_load_skills
         * @static
         * @param {skill.Is2c_load_skills=} [properties] Properties to set
         * @returns {skill.s2c_load_skills} s2c_load_skills instance
         */
        s2c_load_skills.create = function create(properties) {
            return new s2c_load_skills(properties);
        };

        /**
         * Encodes the specified s2c_load_skills message. Does not implicitly {@link skill.s2c_load_skills.verify|verify} messages.
         * @function encode
         * @memberof skill.s2c_load_skills
         * @static
         * @param {skill.s2c_load_skills} message s2c_load_skills message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_load_skills.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.skills != null && message.skills.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (let i = 0; i < message.skills.length; ++i)
                    writer.uint32(message.skills[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a s2c_load_skills message from the specified reader or buffer.
         * @function decode
         * @memberof skill.s2c_load_skills
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {skill.s2c_load_skills} s2c_load_skills
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_load_skills.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.skill.s2c_load_skills();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.skills && message.skills.length))
                            message.skills = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.skills.push(reader.uint32());
                        } else
                            message.skills.push(reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_load_skills
         * @function getTypeUrl
         * @memberof skill.s2c_load_skills
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_load_skills.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/skill.s2c_load_skills";
        };

        return s2c_load_skills;
    })();

    skill.c2s_active_skill = (function() {

        /**
         * Properties of a c2s_active_skill.
         * @memberof skill
         * @interface Ic2s_active_skill
         * @property {number|null} [skillId] c2s_active_skill skillId
         */

        /**
         * Constructs a new c2s_active_skill.
         * @memberof skill
         * @classdesc Represents a c2s_active_skill.
         * @implements Ic2s_active_skill
         * @constructor
         * @param {skill.Ic2s_active_skill=} [properties] Properties to set
         */
        function c2s_active_skill(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_active_skill skillId.
         * @member {number} skillId
         * @memberof skill.c2s_active_skill
         * @instance
         */
        c2s_active_skill.prototype.skillId = 0;

        /**
         * Creates a new c2s_active_skill instance using the specified properties.
         * @function create
         * @memberof skill.c2s_active_skill
         * @static
         * @param {skill.Ic2s_active_skill=} [properties] Properties to set
         * @returns {skill.c2s_active_skill} c2s_active_skill instance
         */
        c2s_active_skill.create = function create(properties) {
            return new c2s_active_skill(properties);
        };

        /**
         * Encodes the specified c2s_active_skill message. Does not implicitly {@link skill.c2s_active_skill.verify|verify} messages.
         * @function encode
         * @memberof skill.c2s_active_skill
         * @static
         * @param {skill.c2s_active_skill} message c2s_active_skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_active_skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.skillId != null && Object.hasOwnProperty.call(message, "skillId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.skillId);
            return writer;
        };

        /**
         * Decodes a c2s_active_skill message from the specified reader or buffer.
         * @function decode
         * @memberof skill.c2s_active_skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {skill.c2s_active_skill} c2s_active_skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_active_skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.skill.c2s_active_skill();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.skillId = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_active_skill
         * @function getTypeUrl
         * @memberof skill.c2s_active_skill
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_active_skill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/skill.c2s_active_skill";
        };

        return c2s_active_skill;
    })();

    skill.s2c_active_skill = (function() {

        /**
         * Properties of a s2c_active_skill.
         * @memberof skill
         * @interface Is2c_active_skill
         * @property {number|null} [err] s2c_active_skill err
         */

        /**
         * Constructs a new s2c_active_skill.
         * @memberof skill
         * @classdesc Represents a s2c_active_skill.
         * @implements Is2c_active_skill
         * @constructor
         * @param {skill.Is2c_active_skill=} [properties] Properties to set
         */
        function s2c_active_skill(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_active_skill err.
         * @member {number} err
         * @memberof skill.s2c_active_skill
         * @instance
         */
        s2c_active_skill.prototype.err = 0;

        /**
         * Creates a new s2c_active_skill instance using the specified properties.
         * @function create
         * @memberof skill.s2c_active_skill
         * @static
         * @param {skill.Is2c_active_skill=} [properties] Properties to set
         * @returns {skill.s2c_active_skill} s2c_active_skill instance
         */
        s2c_active_skill.create = function create(properties) {
            return new s2c_active_skill(properties);
        };

        /**
         * Encodes the specified s2c_active_skill message. Does not implicitly {@link skill.s2c_active_skill.verify|verify} messages.
         * @function encode
         * @memberof skill.s2c_active_skill
         * @static
         * @param {skill.s2c_active_skill} message s2c_active_skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_active_skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_active_skill message from the specified reader or buffer.
         * @function decode
         * @memberof skill.s2c_active_skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {skill.s2c_active_skill} s2c_active_skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_active_skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.skill.s2c_active_skill();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_active_skill
         * @function getTypeUrl
         * @memberof skill.s2c_active_skill
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_active_skill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/skill.s2c_active_skill";
        };

        return s2c_active_skill;
    })();

    skill.c2s_upgrade_skill = (function() {

        /**
         * Properties of a c2s_upgrade_skill.
         * @memberof skill
         * @interface Ic2s_upgrade_skill
         * @property {number|null} [sysType] c2s_upgrade_skill sysType
         * @property {number|null} [uid] c2s_upgrade_skill uid
         * @property {number|null} [slotIdx] c2s_upgrade_skill slotIdx
         */

        /**
         * Constructs a new c2s_upgrade_skill.
         * @memberof skill
         * @classdesc Represents a c2s_upgrade_skill.
         * @implements Ic2s_upgrade_skill
         * @constructor
         * @param {skill.Ic2s_upgrade_skill=} [properties] Properties to set
         */
        function c2s_upgrade_skill(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_upgrade_skill sysType.
         * @member {number} sysType
         * @memberof skill.c2s_upgrade_skill
         * @instance
         */
        c2s_upgrade_skill.prototype.sysType = 0;

        /**
         * c2s_upgrade_skill uid.
         * @member {number} uid
         * @memberof skill.c2s_upgrade_skill
         * @instance
         */
        c2s_upgrade_skill.prototype.uid = 0;

        /**
         * c2s_upgrade_skill slotIdx.
         * @member {number} slotIdx
         * @memberof skill.c2s_upgrade_skill
         * @instance
         */
        c2s_upgrade_skill.prototype.slotIdx = 0;

        /**
         * Creates a new c2s_upgrade_skill instance using the specified properties.
         * @function create
         * @memberof skill.c2s_upgrade_skill
         * @static
         * @param {skill.Ic2s_upgrade_skill=} [properties] Properties to set
         * @returns {skill.c2s_upgrade_skill} c2s_upgrade_skill instance
         */
        c2s_upgrade_skill.create = function create(properties) {
            return new c2s_upgrade_skill(properties);
        };

        /**
         * Encodes the specified c2s_upgrade_skill message. Does not implicitly {@link skill.c2s_upgrade_skill.verify|verify} messages.
         * @function encode
         * @memberof skill.c2s_upgrade_skill
         * @static
         * @param {skill.c2s_upgrade_skill} message c2s_upgrade_skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_upgrade_skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sysType != null && Object.hasOwnProperty.call(message, "sysType"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.sysType);
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.uid);
            if (message.slotIdx != null && Object.hasOwnProperty.call(message, "slotIdx"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.slotIdx);
            return writer;
        };

        /**
         * Decodes a c2s_upgrade_skill message from the specified reader or buffer.
         * @function decode
         * @memberof skill.c2s_upgrade_skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {skill.c2s_upgrade_skill} c2s_upgrade_skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_upgrade_skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.skill.c2s_upgrade_skill();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sysType = reader.uint32();
                        break;
                    }
                case 2: {
                        message.uid = reader.uint32();
                        break;
                    }
                case 3: {
                        message.slotIdx = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_upgrade_skill
         * @function getTypeUrl
         * @memberof skill.c2s_upgrade_skill
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_upgrade_skill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/skill.c2s_upgrade_skill";
        };

        return c2s_upgrade_skill;
    })();

    skill.s2c_upgrade_skill = (function() {

        /**
         * Properties of a s2c_upgrade_skill.
         * @memberof skill
         * @interface Is2c_upgrade_skill
         * @property {number|null} [err] s2c_upgrade_skill err
         */

        /**
         * Constructs a new s2c_upgrade_skill.
         * @memberof skill
         * @classdesc Represents a s2c_upgrade_skill.
         * @implements Is2c_upgrade_skill
         * @constructor
         * @param {skill.Is2c_upgrade_skill=} [properties] Properties to set
         */
        function s2c_upgrade_skill(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_upgrade_skill err.
         * @member {number} err
         * @memberof skill.s2c_upgrade_skill
         * @instance
         */
        s2c_upgrade_skill.prototype.err = 0;

        /**
         * Creates a new s2c_upgrade_skill instance using the specified properties.
         * @function create
         * @memberof skill.s2c_upgrade_skill
         * @static
         * @param {skill.Is2c_upgrade_skill=} [properties] Properties to set
         * @returns {skill.s2c_upgrade_skill} s2c_upgrade_skill instance
         */
        s2c_upgrade_skill.create = function create(properties) {
            return new s2c_upgrade_skill(properties);
        };

        /**
         * Encodes the specified s2c_upgrade_skill message. Does not implicitly {@link skill.s2c_upgrade_skill.verify|verify} messages.
         * @function encode
         * @memberof skill.s2c_upgrade_skill
         * @static
         * @param {skill.s2c_upgrade_skill} message s2c_upgrade_skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_upgrade_skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_upgrade_skill message from the specified reader or buffer.
         * @function decode
         * @memberof skill.s2c_upgrade_skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {skill.s2c_upgrade_skill} s2c_upgrade_skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_upgrade_skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.skill.s2c_upgrade_skill();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_upgrade_skill
         * @function getTypeUrl
         * @memberof skill.s2c_upgrade_skill
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_upgrade_skill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/skill.s2c_upgrade_skill";
        };

        return s2c_upgrade_skill;
    })();

    skill.c2s_mount_skill = (function() {

        /**
         * Properties of a c2s_mount_skill.
         * @memberof skill
         * @interface Ic2s_mount_skill
         * @property {number|null} [sysType] c2s_mount_skill sysType
         * @property {number|null} [uid] c2s_mount_skill uid
         * @property {number|null} [slotIdx] c2s_mount_skill slotIdx
         * @property {number|null} [skillId] c2s_mount_skill skillId
         */

        /**
         * Constructs a new c2s_mount_skill.
         * @memberof skill
         * @classdesc Represents a c2s_mount_skill.
         * @implements Ic2s_mount_skill
         * @constructor
         * @param {skill.Ic2s_mount_skill=} [properties] Properties to set
         */
        function c2s_mount_skill(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_mount_skill sysType.
         * @member {number} sysType
         * @memberof skill.c2s_mount_skill
         * @instance
         */
        c2s_mount_skill.prototype.sysType = 0;

        /**
         * c2s_mount_skill uid.
         * @member {number} uid
         * @memberof skill.c2s_mount_skill
         * @instance
         */
        c2s_mount_skill.prototype.uid = 0;

        /**
         * c2s_mount_skill slotIdx.
         * @member {number} slotIdx
         * @memberof skill.c2s_mount_skill
         * @instance
         */
        c2s_mount_skill.prototype.slotIdx = 0;

        /**
         * c2s_mount_skill skillId.
         * @member {number} skillId
         * @memberof skill.c2s_mount_skill
         * @instance
         */
        c2s_mount_skill.prototype.skillId = 0;

        /**
         * Creates a new c2s_mount_skill instance using the specified properties.
         * @function create
         * @memberof skill.c2s_mount_skill
         * @static
         * @param {skill.Ic2s_mount_skill=} [properties] Properties to set
         * @returns {skill.c2s_mount_skill} c2s_mount_skill instance
         */
        c2s_mount_skill.create = function create(properties) {
            return new c2s_mount_skill(properties);
        };

        /**
         * Encodes the specified c2s_mount_skill message. Does not implicitly {@link skill.c2s_mount_skill.verify|verify} messages.
         * @function encode
         * @memberof skill.c2s_mount_skill
         * @static
         * @param {skill.c2s_mount_skill} message c2s_mount_skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_mount_skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sysType != null && Object.hasOwnProperty.call(message, "sysType"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.sysType);
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.uid);
            if (message.slotIdx != null && Object.hasOwnProperty.call(message, "slotIdx"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.slotIdx);
            if (message.skillId != null && Object.hasOwnProperty.call(message, "skillId"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.skillId);
            return writer;
        };

        /**
         * Decodes a c2s_mount_skill message from the specified reader or buffer.
         * @function decode
         * @memberof skill.c2s_mount_skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {skill.c2s_mount_skill} c2s_mount_skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_mount_skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.skill.c2s_mount_skill();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sysType = reader.uint32();
                        break;
                    }
                case 2: {
                        message.uid = reader.uint32();
                        break;
                    }
                case 3: {
                        message.slotIdx = reader.uint32();
                        break;
                    }
                case 4: {
                        message.skillId = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_mount_skill
         * @function getTypeUrl
         * @memberof skill.c2s_mount_skill
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_mount_skill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/skill.c2s_mount_skill";
        };

        return c2s_mount_skill;
    })();

    skill.s2c_mount_skill = (function() {

        /**
         * Properties of a s2c_mount_skill.
         * @memberof skill
         * @interface Is2c_mount_skill
         * @property {number|null} [err] s2c_mount_skill err
         */

        /**
         * Constructs a new s2c_mount_skill.
         * @memberof skill
         * @classdesc Represents a s2c_mount_skill.
         * @implements Is2c_mount_skill
         * @constructor
         * @param {skill.Is2c_mount_skill=} [properties] Properties to set
         */
        function s2c_mount_skill(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_mount_skill err.
         * @member {number} err
         * @memberof skill.s2c_mount_skill
         * @instance
         */
        s2c_mount_skill.prototype.err = 0;

        /**
         * Creates a new s2c_mount_skill instance using the specified properties.
         * @function create
         * @memberof skill.s2c_mount_skill
         * @static
         * @param {skill.Is2c_mount_skill=} [properties] Properties to set
         * @returns {skill.s2c_mount_skill} s2c_mount_skill instance
         */
        s2c_mount_skill.create = function create(properties) {
            return new s2c_mount_skill(properties);
        };

        /**
         * Encodes the specified s2c_mount_skill message. Does not implicitly {@link skill.s2c_mount_skill.verify|verify} messages.
         * @function encode
         * @memberof skill.s2c_mount_skill
         * @static
         * @param {skill.s2c_mount_skill} message s2c_mount_skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_mount_skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_mount_skill message from the specified reader or buffer.
         * @function decode
         * @memberof skill.s2c_mount_skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {skill.s2c_mount_skill} s2c_mount_skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_mount_skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.skill.s2c_mount_skill();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_mount_skill
         * @function getTypeUrl
         * @memberof skill.s2c_mount_skill
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_mount_skill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/skill.s2c_mount_skill";
        };

        return s2c_mount_skill;
    })();

    skill.c2s_unmount_skill = (function() {

        /**
         * Properties of a c2s_unmount_skill.
         * @memberof skill
         * @interface Ic2s_unmount_skill
         * @property {number|null} [sysType] c2s_unmount_skill sysType
         * @property {number|null} [uid] c2s_unmount_skill uid
         * @property {number|null} [slotIdx] c2s_unmount_skill slotIdx
         */

        /**
         * Constructs a new c2s_unmount_skill.
         * @memberof skill
         * @classdesc Represents a c2s_unmount_skill.
         * @implements Ic2s_unmount_skill
         * @constructor
         * @param {skill.Ic2s_unmount_skill=} [properties] Properties to set
         */
        function c2s_unmount_skill(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_unmount_skill sysType.
         * @member {number} sysType
         * @memberof skill.c2s_unmount_skill
         * @instance
         */
        c2s_unmount_skill.prototype.sysType = 0;

        /**
         * c2s_unmount_skill uid.
         * @member {number} uid
         * @memberof skill.c2s_unmount_skill
         * @instance
         */
        c2s_unmount_skill.prototype.uid = 0;

        /**
         * c2s_unmount_skill slotIdx.
         * @member {number} slotIdx
         * @memberof skill.c2s_unmount_skill
         * @instance
         */
        c2s_unmount_skill.prototype.slotIdx = 0;

        /**
         * Creates a new c2s_unmount_skill instance using the specified properties.
         * @function create
         * @memberof skill.c2s_unmount_skill
         * @static
         * @param {skill.Ic2s_unmount_skill=} [properties] Properties to set
         * @returns {skill.c2s_unmount_skill} c2s_unmount_skill instance
         */
        c2s_unmount_skill.create = function create(properties) {
            return new c2s_unmount_skill(properties);
        };

        /**
         * Encodes the specified c2s_unmount_skill message. Does not implicitly {@link skill.c2s_unmount_skill.verify|verify} messages.
         * @function encode
         * @memberof skill.c2s_unmount_skill
         * @static
         * @param {skill.c2s_unmount_skill} message c2s_unmount_skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_unmount_skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sysType != null && Object.hasOwnProperty.call(message, "sysType"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.sysType);
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.uid);
            if (message.slotIdx != null && Object.hasOwnProperty.call(message, "slotIdx"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.slotIdx);
            return writer;
        };

        /**
         * Decodes a c2s_unmount_skill message from the specified reader or buffer.
         * @function decode
         * @memberof skill.c2s_unmount_skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {skill.c2s_unmount_skill} c2s_unmount_skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_unmount_skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.skill.c2s_unmount_skill();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sysType = reader.uint32();
                        break;
                    }
                case 2: {
                        message.uid = reader.uint32();
                        break;
                    }
                case 3: {
                        message.slotIdx = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_unmount_skill
         * @function getTypeUrl
         * @memberof skill.c2s_unmount_skill
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_unmount_skill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/skill.c2s_unmount_skill";
        };

        return c2s_unmount_skill;
    })();

    skill.s2c_unmount_skill = (function() {

        /**
         * Properties of a s2c_unmount_skill.
         * @memberof skill
         * @interface Is2c_unmount_skill
         * @property {number|null} [err] s2c_unmount_skill err
         */

        /**
         * Constructs a new s2c_unmount_skill.
         * @memberof skill
         * @classdesc Represents a s2c_unmount_skill.
         * @implements Is2c_unmount_skill
         * @constructor
         * @param {skill.Is2c_unmount_skill=} [properties] Properties to set
         */
        function s2c_unmount_skill(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_unmount_skill err.
         * @member {number} err
         * @memberof skill.s2c_unmount_skill
         * @instance
         */
        s2c_unmount_skill.prototype.err = 0;

        /**
         * Creates a new s2c_unmount_skill instance using the specified properties.
         * @function create
         * @memberof skill.s2c_unmount_skill
         * @static
         * @param {skill.Is2c_unmount_skill=} [properties] Properties to set
         * @returns {skill.s2c_unmount_skill} s2c_unmount_skill instance
         */
        s2c_unmount_skill.create = function create(properties) {
            return new s2c_unmount_skill(properties);
        };

        /**
         * Encodes the specified s2c_unmount_skill message. Does not implicitly {@link skill.s2c_unmount_skill.verify|verify} messages.
         * @function encode
         * @memberof skill.s2c_unmount_skill
         * @static
         * @param {skill.s2c_unmount_skill} message s2c_unmount_skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_unmount_skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_unmount_skill message from the specified reader or buffer.
         * @function decode
         * @memberof skill.s2c_unmount_skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {skill.s2c_unmount_skill} s2c_unmount_skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_unmount_skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.skill.s2c_unmount_skill();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_unmount_skill
         * @function getTypeUrl
         * @memberof skill.s2c_unmount_skill
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_unmount_skill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/skill.s2c_unmount_skill";
        };

        return s2c_unmount_skill;
    })();

    skill.notify_new_skill = (function() {

        /**
         * Properties of a notify_new_skill.
         * @memberof skill
         * @interface Inotify_new_skill
         * @property {Array.<number>|null} [skillIds] notify_new_skill skillIds
         */

        /**
         * Constructs a new notify_new_skill.
         * @memberof skill
         * @classdesc Represents a notify_new_skill.
         * @implements Inotify_new_skill
         * @constructor
         * @param {skill.Inotify_new_skill=} [properties] Properties to set
         */
        function notify_new_skill(properties) {
            this.skillIds = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_new_skill skillIds.
         * @member {Array.<number>} skillIds
         * @memberof skill.notify_new_skill
         * @instance
         */
        notify_new_skill.prototype.skillIds = $util.emptyArray;

        /**
         * Creates a new notify_new_skill instance using the specified properties.
         * @function create
         * @memberof skill.notify_new_skill
         * @static
         * @param {skill.Inotify_new_skill=} [properties] Properties to set
         * @returns {skill.notify_new_skill} notify_new_skill instance
         */
        notify_new_skill.create = function create(properties) {
            return new notify_new_skill(properties);
        };

        /**
         * Encodes the specified notify_new_skill message. Does not implicitly {@link skill.notify_new_skill.verify|verify} messages.
         * @function encode
         * @memberof skill.notify_new_skill
         * @static
         * @param {skill.notify_new_skill} message notify_new_skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_new_skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.skillIds != null && message.skillIds.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (let i = 0; i < message.skillIds.length; ++i)
                    writer.uint32(message.skillIds[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a notify_new_skill message from the specified reader or buffer.
         * @function decode
         * @memberof skill.notify_new_skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {skill.notify_new_skill} notify_new_skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_new_skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.skill.notify_new_skill();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.skillIds && message.skillIds.length))
                            message.skillIds = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.skillIds.push(reader.uint32());
                        } else
                            message.skillIds.push(reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_new_skill
         * @function getTypeUrl
         * @memberof skill.notify_new_skill
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_new_skill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/skill.notify_new_skill";
        };

        return notify_new_skill;
    })();

    return skill;
})();

export const talent = $root.talent = (() => {

    /**
     * Namespace talent.
     * @exports talent
     * @namespace
     */
    const talent = {};

    talent.c2s_load = (function() {

        /**
         * Properties of a c2s_load.
         * @memberof talent
         * @interface Ic2s_load
         */

        /**
         * Constructs a new c2s_load.
         * @memberof talent
         * @classdesc Represents a c2s_load.
         * @implements Ic2s_load
         * @constructor
         * @param {talent.Ic2s_load=} [properties] Properties to set
         */
        function c2s_load(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_load instance using the specified properties.
         * @function create
         * @memberof talent.c2s_load
         * @static
         * @param {talent.Ic2s_load=} [properties] Properties to set
         * @returns {talent.c2s_load} c2s_load instance
         */
        c2s_load.create = function create(properties) {
            return new c2s_load(properties);
        };

        /**
         * Encodes the specified c2s_load message. Does not implicitly {@link talent.c2s_load.verify|verify} messages.
         * @function encode
         * @memberof talent.c2s_load
         * @static
         * @param {talent.c2s_load} message c2s_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_load message from the specified reader or buffer.
         * @function decode
         * @memberof talent.c2s_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {talent.c2s_load} c2s_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.talent.c2s_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_load
         * @function getTypeUrl
         * @memberof talent.c2s_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/talent.c2s_load";
        };

        return c2s_load;
    })();

    talent.s2c_load = (function() {

        /**
         * Properties of a s2c_load.
         * @memberof talent
         * @interface Is2c_load
         * @property {number|null} [err] s2c_load err
         * @property {number|null} [normalIdx] s2c_load normalIdx
         * @property {number|null} [strongIdx] s2c_load strongIdx
         */

        /**
         * Constructs a new s2c_load.
         * @memberof talent
         * @classdesc Represents a s2c_load.
         * @implements Is2c_load
         * @constructor
         * @param {talent.Is2c_load=} [properties] Properties to set
         */
        function s2c_load(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_load err.
         * @member {number} err
         * @memberof talent.s2c_load
         * @instance
         */
        s2c_load.prototype.err = 0;

        /**
         * s2c_load normalIdx.
         * @member {number} normalIdx
         * @memberof talent.s2c_load
         * @instance
         */
        s2c_load.prototype.normalIdx = 0;

        /**
         * s2c_load strongIdx.
         * @member {number} strongIdx
         * @memberof talent.s2c_load
         * @instance
         */
        s2c_load.prototype.strongIdx = 0;

        /**
         * Creates a new s2c_load instance using the specified properties.
         * @function create
         * @memberof talent.s2c_load
         * @static
         * @param {talent.Is2c_load=} [properties] Properties to set
         * @returns {talent.s2c_load} s2c_load instance
         */
        s2c_load.create = function create(properties) {
            return new s2c_load(properties);
        };

        /**
         * Encodes the specified s2c_load message. Does not implicitly {@link talent.s2c_load.verify|verify} messages.
         * @function encode
         * @memberof talent.s2c_load
         * @static
         * @param {talent.s2c_load} message s2c_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.normalIdx != null && Object.hasOwnProperty.call(message, "normalIdx"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.normalIdx);
            if (message.strongIdx != null && Object.hasOwnProperty.call(message, "strongIdx"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.strongIdx);
            return writer;
        };

        /**
         * Decodes a s2c_load message from the specified reader or buffer.
         * @function decode
         * @memberof talent.s2c_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {talent.s2c_load} s2c_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.talent.s2c_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        message.normalIdx = reader.uint32();
                        break;
                    }
                case 3: {
                        message.strongIdx = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_load
         * @function getTypeUrl
         * @memberof talent.s2c_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/talent.s2c_load";
        };

        return s2c_load;
    })();

    talent.c2s_active_normal = (function() {

        /**
         * Properties of a c2s_active_normal.
         * @memberof talent
         * @interface Ic2s_active_normal
         */

        /**
         * Constructs a new c2s_active_normal.
         * @memberof talent
         * @classdesc Represents a c2s_active_normal.
         * @implements Ic2s_active_normal
         * @constructor
         * @param {talent.Ic2s_active_normal=} [properties] Properties to set
         */
        function c2s_active_normal(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_active_normal instance using the specified properties.
         * @function create
         * @memberof talent.c2s_active_normal
         * @static
         * @param {talent.Ic2s_active_normal=} [properties] Properties to set
         * @returns {talent.c2s_active_normal} c2s_active_normal instance
         */
        c2s_active_normal.create = function create(properties) {
            return new c2s_active_normal(properties);
        };

        /**
         * Encodes the specified c2s_active_normal message. Does not implicitly {@link talent.c2s_active_normal.verify|verify} messages.
         * @function encode
         * @memberof talent.c2s_active_normal
         * @static
         * @param {talent.c2s_active_normal} message c2s_active_normal message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_active_normal.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_active_normal message from the specified reader or buffer.
         * @function decode
         * @memberof talent.c2s_active_normal
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {talent.c2s_active_normal} c2s_active_normal
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_active_normal.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.talent.c2s_active_normal();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_active_normal
         * @function getTypeUrl
         * @memberof talent.c2s_active_normal
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_active_normal.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/talent.c2s_active_normal";
        };

        return c2s_active_normal;
    })();

    talent.s2c_active_normal = (function() {

        /**
         * Properties of a s2c_active_normal.
         * @memberof talent
         * @interface Is2c_active_normal
         * @property {number|null} [err] s2c_active_normal err
         */

        /**
         * Constructs a new s2c_active_normal.
         * @memberof talent
         * @classdesc Represents a s2c_active_normal.
         * @implements Is2c_active_normal
         * @constructor
         * @param {talent.Is2c_active_normal=} [properties] Properties to set
         */
        function s2c_active_normal(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_active_normal err.
         * @member {number} err
         * @memberof talent.s2c_active_normal
         * @instance
         */
        s2c_active_normal.prototype.err = 0;

        /**
         * Creates a new s2c_active_normal instance using the specified properties.
         * @function create
         * @memberof talent.s2c_active_normal
         * @static
         * @param {talent.Is2c_active_normal=} [properties] Properties to set
         * @returns {talent.s2c_active_normal} s2c_active_normal instance
         */
        s2c_active_normal.create = function create(properties) {
            return new s2c_active_normal(properties);
        };

        /**
         * Encodes the specified s2c_active_normal message. Does not implicitly {@link talent.s2c_active_normal.verify|verify} messages.
         * @function encode
         * @memberof talent.s2c_active_normal
         * @static
         * @param {talent.s2c_active_normal} message s2c_active_normal message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_active_normal.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_active_normal message from the specified reader or buffer.
         * @function decode
         * @memberof talent.s2c_active_normal
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {talent.s2c_active_normal} s2c_active_normal
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_active_normal.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.talent.s2c_active_normal();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_active_normal
         * @function getTypeUrl
         * @memberof talent.s2c_active_normal
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_active_normal.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/talent.s2c_active_normal";
        };

        return s2c_active_normal;
    })();

    talent.c2s_active_strong = (function() {

        /**
         * Properties of a c2s_active_strong.
         * @memberof talent
         * @interface Ic2s_active_strong
         */

        /**
         * Constructs a new c2s_active_strong.
         * @memberof talent
         * @classdesc Represents a c2s_active_strong.
         * @implements Ic2s_active_strong
         * @constructor
         * @param {talent.Ic2s_active_strong=} [properties] Properties to set
         */
        function c2s_active_strong(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_active_strong instance using the specified properties.
         * @function create
         * @memberof talent.c2s_active_strong
         * @static
         * @param {talent.Ic2s_active_strong=} [properties] Properties to set
         * @returns {talent.c2s_active_strong} c2s_active_strong instance
         */
        c2s_active_strong.create = function create(properties) {
            return new c2s_active_strong(properties);
        };

        /**
         * Encodes the specified c2s_active_strong message. Does not implicitly {@link talent.c2s_active_strong.verify|verify} messages.
         * @function encode
         * @memberof talent.c2s_active_strong
         * @static
         * @param {talent.c2s_active_strong} message c2s_active_strong message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_active_strong.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_active_strong message from the specified reader or buffer.
         * @function decode
         * @memberof talent.c2s_active_strong
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {talent.c2s_active_strong} c2s_active_strong
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_active_strong.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.talent.c2s_active_strong();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_active_strong
         * @function getTypeUrl
         * @memberof talent.c2s_active_strong
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_active_strong.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/talent.c2s_active_strong";
        };

        return c2s_active_strong;
    })();

    talent.s2c_active_strong = (function() {

        /**
         * Properties of a s2c_active_strong.
         * @memberof talent
         * @interface Is2c_active_strong
         * @property {number|null} [err] s2c_active_strong err
         */

        /**
         * Constructs a new s2c_active_strong.
         * @memberof talent
         * @classdesc Represents a s2c_active_strong.
         * @implements Is2c_active_strong
         * @constructor
         * @param {talent.Is2c_active_strong=} [properties] Properties to set
         */
        function s2c_active_strong(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_active_strong err.
         * @member {number} err
         * @memberof talent.s2c_active_strong
         * @instance
         */
        s2c_active_strong.prototype.err = 0;

        /**
         * Creates a new s2c_active_strong instance using the specified properties.
         * @function create
         * @memberof talent.s2c_active_strong
         * @static
         * @param {talent.Is2c_active_strong=} [properties] Properties to set
         * @returns {talent.s2c_active_strong} s2c_active_strong instance
         */
        s2c_active_strong.create = function create(properties) {
            return new s2c_active_strong(properties);
        };

        /**
         * Encodes the specified s2c_active_strong message. Does not implicitly {@link talent.s2c_active_strong.verify|verify} messages.
         * @function encode
         * @memberof talent.s2c_active_strong
         * @static
         * @param {talent.s2c_active_strong} message s2c_active_strong message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_active_strong.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_active_strong message from the specified reader or buffer.
         * @function decode
         * @memberof talent.s2c_active_strong
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {talent.s2c_active_strong} s2c_active_strong
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_active_strong.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.talent.s2c_active_strong();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_active_strong
         * @function getTypeUrl
         * @memberof talent.s2c_active_strong
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_active_strong.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/talent.s2c_active_strong";
        };

        return s2c_active_strong;
    })();

    return talent;
})();

export const task = $root.task = (() => {

    /**
     * Namespace task.
     * @exports task
     * @namespace
     */
    const task = {};

    task.TaskInfo = (function() {

        /**
         * Properties of a TaskInfo.
         * @memberof task
         * @interface ITaskInfo
         * @property {number|null} [id] TaskInfo id
         * @property {number|null} [num] TaskInfo num
         * @property {number|null} [max] TaskInfo max
         * @property {boolean|null} [finish] TaskInfo finish
         */

        /**
         * Constructs a new TaskInfo.
         * @memberof task
         * @classdesc Represents a TaskInfo.
         * @implements ITaskInfo
         * @constructor
         * @param {task.ITaskInfo=} [properties] Properties to set
         */
        function TaskInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TaskInfo id.
         * @member {number} id
         * @memberof task.TaskInfo
         * @instance
         */
        TaskInfo.prototype.id = 0;

        /**
         * TaskInfo num.
         * @member {number} num
         * @memberof task.TaskInfo
         * @instance
         */
        TaskInfo.prototype.num = 0;

        /**
         * TaskInfo max.
         * @member {number} max
         * @memberof task.TaskInfo
         * @instance
         */
        TaskInfo.prototype.max = 0;

        /**
         * TaskInfo finish.
         * @member {boolean} finish
         * @memberof task.TaskInfo
         * @instance
         */
        TaskInfo.prototype.finish = false;

        /**
         * Creates a new TaskInfo instance using the specified properties.
         * @function create
         * @memberof task.TaskInfo
         * @static
         * @param {task.ITaskInfo=} [properties] Properties to set
         * @returns {task.TaskInfo} TaskInfo instance
         */
        TaskInfo.create = function create(properties) {
            return new TaskInfo(properties);
        };

        /**
         * Encodes the specified TaskInfo message. Does not implicitly {@link task.TaskInfo.verify|verify} messages.
         * @function encode
         * @memberof task.TaskInfo
         * @static
         * @param {task.TaskInfo} message TaskInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TaskInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            if (message.num != null && Object.hasOwnProperty.call(message, "num"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.num);
            if (message.max != null && Object.hasOwnProperty.call(message, "max"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.max);
            if (message.finish != null && Object.hasOwnProperty.call(message, "finish"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.finish);
            return writer;
        };

        /**
         * Decodes a TaskInfo message from the specified reader or buffer.
         * @function decode
         * @memberof task.TaskInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {task.TaskInfo} TaskInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TaskInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.task.TaskInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 2: {
                        message.num = reader.uint32();
                        break;
                    }
                case 3: {
                        message.max = reader.uint32();
                        break;
                    }
                case 4: {
                        message.finish = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for TaskInfo
         * @function getTypeUrl
         * @memberof task.TaskInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TaskInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/task.TaskInfo";
        };

        return TaskInfo;
    })();

    task.c2s_load = (function() {

        /**
         * Properties of a c2s_load.
         * @memberof task
         * @interface Ic2s_load
         * @property {number|null} [taskType] c2s_load taskType
         */

        /**
         * Constructs a new c2s_load.
         * @memberof task
         * @classdesc Represents a c2s_load.
         * @implements Ic2s_load
         * @constructor
         * @param {task.Ic2s_load=} [properties] Properties to set
         */
        function c2s_load(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_load taskType.
         * @member {number} taskType
         * @memberof task.c2s_load
         * @instance
         */
        c2s_load.prototype.taskType = 0;

        /**
         * Creates a new c2s_load instance using the specified properties.
         * @function create
         * @memberof task.c2s_load
         * @static
         * @param {task.Ic2s_load=} [properties] Properties to set
         * @returns {task.c2s_load} c2s_load instance
         */
        c2s_load.create = function create(properties) {
            return new c2s_load(properties);
        };

        /**
         * Encodes the specified c2s_load message. Does not implicitly {@link task.c2s_load.verify|verify} messages.
         * @function encode
         * @memberof task.c2s_load
         * @static
         * @param {task.c2s_load} message c2s_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.taskType);
            return writer;
        };

        /**
         * Decodes a c2s_load message from the specified reader or buffer.
         * @function decode
         * @memberof task.c2s_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {task.c2s_load} c2s_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.task.c2s_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.taskType = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_load
         * @function getTypeUrl
         * @memberof task.c2s_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/task.c2s_load";
        };

        return c2s_load;
    })();

    task.s2c_load = (function() {

        /**
         * Properties of a s2c_load.
         * @memberof task
         * @interface Is2c_load
         * @property {number|null} [err] s2c_load err
         * @property {Array.<task.TaskInfo>|null} [tasks] s2c_load tasks
         */

        /**
         * Constructs a new s2c_load.
         * @memberof task
         * @classdesc Represents a s2c_load.
         * @implements Is2c_load
         * @constructor
         * @param {task.Is2c_load=} [properties] Properties to set
         */
        function s2c_load(properties) {
            this.tasks = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_load err.
         * @member {number} err
         * @memberof task.s2c_load
         * @instance
         */
        s2c_load.prototype.err = 0;

        /**
         * s2c_load tasks.
         * @member {Array.<task.TaskInfo>} tasks
         * @memberof task.s2c_load
         * @instance
         */
        s2c_load.prototype.tasks = $util.emptyArray;

        /**
         * Creates a new s2c_load instance using the specified properties.
         * @function create
         * @memberof task.s2c_load
         * @static
         * @param {task.Is2c_load=} [properties] Properties to set
         * @returns {task.s2c_load} s2c_load instance
         */
        s2c_load.create = function create(properties) {
            return new s2c_load(properties);
        };

        /**
         * Encodes the specified s2c_load message. Does not implicitly {@link task.s2c_load.verify|verify} messages.
         * @function encode
         * @memberof task.s2c_load
         * @static
         * @param {task.s2c_load} message s2c_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.tasks != null && message.tasks.length)
                for (let i = 0; i < message.tasks.length; ++i)
                    $root.task.TaskInfo.encode(message.tasks[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_load message from the specified reader or buffer.
         * @function decode
         * @memberof task.s2c_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {task.s2c_load} s2c_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.task.s2c_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.tasks && message.tasks.length))
                            message.tasks = [];
                        message.tasks.push($root.task.TaskInfo.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_load
         * @function getTypeUrl
         * @memberof task.s2c_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/task.s2c_load";
        };

        return s2c_load;
    })();

    task.c2s_receive_reward = (function() {

        /**
         * Properties of a c2s_receive_reward.
         * @memberof task
         * @interface Ic2s_receive_reward
         * @property {Array.<number>|null} [taskIds] c2s_receive_reward taskIds
         */

        /**
         * Constructs a new c2s_receive_reward.
         * @memberof task
         * @classdesc Represents a c2s_receive_reward.
         * @implements Ic2s_receive_reward
         * @constructor
         * @param {task.Ic2s_receive_reward=} [properties] Properties to set
         */
        function c2s_receive_reward(properties) {
            this.taskIds = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_receive_reward taskIds.
         * @member {Array.<number>} taskIds
         * @memberof task.c2s_receive_reward
         * @instance
         */
        c2s_receive_reward.prototype.taskIds = $util.emptyArray;

        /**
         * Creates a new c2s_receive_reward instance using the specified properties.
         * @function create
         * @memberof task.c2s_receive_reward
         * @static
         * @param {task.Ic2s_receive_reward=} [properties] Properties to set
         * @returns {task.c2s_receive_reward} c2s_receive_reward instance
         */
        c2s_receive_reward.create = function create(properties) {
            return new c2s_receive_reward(properties);
        };

        /**
         * Encodes the specified c2s_receive_reward message. Does not implicitly {@link task.c2s_receive_reward.verify|verify} messages.
         * @function encode
         * @memberof task.c2s_receive_reward
         * @static
         * @param {task.c2s_receive_reward} message c2s_receive_reward message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_receive_reward.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.taskIds != null && message.taskIds.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (let i = 0; i < message.taskIds.length; ++i)
                    writer.uint32(message.taskIds[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a c2s_receive_reward message from the specified reader or buffer.
         * @function decode
         * @memberof task.c2s_receive_reward
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {task.c2s_receive_reward} c2s_receive_reward
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_receive_reward.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.task.c2s_receive_reward();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.taskIds && message.taskIds.length))
                            message.taskIds = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.taskIds.push(reader.uint32());
                        } else
                            message.taskIds.push(reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_receive_reward
         * @function getTypeUrl
         * @memberof task.c2s_receive_reward
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_receive_reward.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/task.c2s_receive_reward";
        };

        return c2s_receive_reward;
    })();

    task.s2c_receive_reward = (function() {

        /**
         * Properties of a s2c_receive_reward.
         * @memberof task
         * @interface Is2c_receive_reward
         * @property {number|null} [err] s2c_receive_reward err
         * @property {Array.<bag.ItemReward>|null} [rewards] s2c_receive_reward rewards
         */

        /**
         * Constructs a new s2c_receive_reward.
         * @memberof task
         * @classdesc Represents a s2c_receive_reward.
         * @implements Is2c_receive_reward
         * @constructor
         * @param {task.Is2c_receive_reward=} [properties] Properties to set
         */
        function s2c_receive_reward(properties) {
            this.rewards = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_receive_reward err.
         * @member {number} err
         * @memberof task.s2c_receive_reward
         * @instance
         */
        s2c_receive_reward.prototype.err = 0;

        /**
         * s2c_receive_reward rewards.
         * @member {Array.<bag.ItemReward>} rewards
         * @memberof task.s2c_receive_reward
         * @instance
         */
        s2c_receive_reward.prototype.rewards = $util.emptyArray;

        /**
         * Creates a new s2c_receive_reward instance using the specified properties.
         * @function create
         * @memberof task.s2c_receive_reward
         * @static
         * @param {task.Is2c_receive_reward=} [properties] Properties to set
         * @returns {task.s2c_receive_reward} s2c_receive_reward instance
         */
        s2c_receive_reward.create = function create(properties) {
            return new s2c_receive_reward(properties);
        };

        /**
         * Encodes the specified s2c_receive_reward message. Does not implicitly {@link task.s2c_receive_reward.verify|verify} messages.
         * @function encode
         * @memberof task.s2c_receive_reward
         * @static
         * @param {task.s2c_receive_reward} message s2c_receive_reward message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_receive_reward.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.rewards != null && message.rewards.length)
                for (let i = 0; i < message.rewards.length; ++i)
                    $root.bag.ItemReward.encode(message.rewards[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_receive_reward message from the specified reader or buffer.
         * @function decode
         * @memberof task.s2c_receive_reward
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {task.s2c_receive_reward} s2c_receive_reward
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_receive_reward.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.task.s2c_receive_reward();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.rewards && message.rewards.length))
                            message.rewards = [];
                        message.rewards.push($root.bag.ItemReward.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_receive_reward
         * @function getTypeUrl
         * @memberof task.s2c_receive_reward
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_receive_reward.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/task.s2c_receive_reward";
        };

        return s2c_receive_reward;
    })();

    task.notify_tasks = (function() {

        /**
         * Properties of a notify_tasks.
         * @memberof task
         * @interface Inotify_tasks
         * @property {Array.<task.TaskInfo>|null} [tasks] notify_tasks tasks
         */

        /**
         * Constructs a new notify_tasks.
         * @memberof task
         * @classdesc Represents a notify_tasks.
         * @implements Inotify_tasks
         * @constructor
         * @param {task.Inotify_tasks=} [properties] Properties to set
         */
        function notify_tasks(properties) {
            this.tasks = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_tasks tasks.
         * @member {Array.<task.TaskInfo>} tasks
         * @memberof task.notify_tasks
         * @instance
         */
        notify_tasks.prototype.tasks = $util.emptyArray;

        /**
         * Creates a new notify_tasks instance using the specified properties.
         * @function create
         * @memberof task.notify_tasks
         * @static
         * @param {task.Inotify_tasks=} [properties] Properties to set
         * @returns {task.notify_tasks} notify_tasks instance
         */
        notify_tasks.create = function create(properties) {
            return new notify_tasks(properties);
        };

        /**
         * Encodes the specified notify_tasks message. Does not implicitly {@link task.notify_tasks.verify|verify} messages.
         * @function encode
         * @memberof task.notify_tasks
         * @static
         * @param {task.notify_tasks} message notify_tasks message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_tasks.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tasks != null && message.tasks.length)
                for (let i = 0; i < message.tasks.length; ++i)
                    $root.task.TaskInfo.encode(message.tasks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a notify_tasks message from the specified reader or buffer.
         * @function decode
         * @memberof task.notify_tasks
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {task.notify_tasks} notify_tasks
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_tasks.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.task.notify_tasks();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.tasks && message.tasks.length))
                            message.tasks = [];
                        message.tasks.push($root.task.TaskInfo.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_tasks
         * @function getTypeUrl
         * @memberof task.notify_tasks
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_tasks.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/task.notify_tasks";
        };

        return notify_tasks;
    })();

    task.notify_remove_tasks = (function() {

        /**
         * Properties of a notify_remove_tasks.
         * @memberof task
         * @interface Inotify_remove_tasks
         * @property {Array.<number>|null} [taskIds] notify_remove_tasks taskIds
         */

        /**
         * Constructs a new notify_remove_tasks.
         * @memberof task
         * @classdesc Represents a notify_remove_tasks.
         * @implements Inotify_remove_tasks
         * @constructor
         * @param {task.Inotify_remove_tasks=} [properties] Properties to set
         */
        function notify_remove_tasks(properties) {
            this.taskIds = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_remove_tasks taskIds.
         * @member {Array.<number>} taskIds
         * @memberof task.notify_remove_tasks
         * @instance
         */
        notify_remove_tasks.prototype.taskIds = $util.emptyArray;

        /**
         * Creates a new notify_remove_tasks instance using the specified properties.
         * @function create
         * @memberof task.notify_remove_tasks
         * @static
         * @param {task.Inotify_remove_tasks=} [properties] Properties to set
         * @returns {task.notify_remove_tasks} notify_remove_tasks instance
         */
        notify_remove_tasks.create = function create(properties) {
            return new notify_remove_tasks(properties);
        };

        /**
         * Encodes the specified notify_remove_tasks message. Does not implicitly {@link task.notify_remove_tasks.verify|verify} messages.
         * @function encode
         * @memberof task.notify_remove_tasks
         * @static
         * @param {task.notify_remove_tasks} message notify_remove_tasks message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_remove_tasks.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.taskIds != null && message.taskIds.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (let i = 0; i < message.taskIds.length; ++i)
                    writer.int32(message.taskIds[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a notify_remove_tasks message from the specified reader or buffer.
         * @function decode
         * @memberof task.notify_remove_tasks
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {task.notify_remove_tasks} notify_remove_tasks
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_remove_tasks.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.task.notify_remove_tasks();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.taskIds && message.taskIds.length))
                            message.taskIds = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.taskIds.push(reader.int32());
                        } else
                            message.taskIds.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_remove_tasks
         * @function getTypeUrl
         * @memberof task.notify_remove_tasks
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_remove_tasks.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/task.notify_remove_tasks";
        };

        return notify_remove_tasks;
    })();

    return task;
})();

export const troop = $root.troop = (() => {

    /**
     * Namespace troop.
     * @exports troop
     * @namespace
     */
    const troop = {};

    troop.Hero = (function() {

        /**
         * Properties of a Hero.
         * @memberof troop
         * @interface IHero
         * @property {number|null} [uid] Hero uid
         * @property {number|null} [maxHp] Hero maxHp
         */

        /**
         * Constructs a new Hero.
         * @memberof troop
         * @classdesc Represents a Hero.
         * @implements IHero
         * @constructor
         * @param {troop.IHero=} [properties] Properties to set
         */
        function Hero(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Hero uid.
         * @member {number} uid
         * @memberof troop.Hero
         * @instance
         */
        Hero.prototype.uid = 0;

        /**
         * Hero maxHp.
         * @member {number} maxHp
         * @memberof troop.Hero
         * @instance
         */
        Hero.prototype.maxHp = 0;

        /**
         * Creates a new Hero instance using the specified properties.
         * @function create
         * @memberof troop.Hero
         * @static
         * @param {troop.IHero=} [properties] Properties to set
         * @returns {troop.Hero} Hero instance
         */
        Hero.create = function create(properties) {
            return new Hero(properties);
        };

        /**
         * Encodes the specified Hero message. Does not implicitly {@link troop.Hero.verify|verify} messages.
         * @function encode
         * @memberof troop.Hero
         * @static
         * @param {troop.Hero} message Hero message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Hero.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.uid);
            if (message.maxHp != null && Object.hasOwnProperty.call(message, "maxHp"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.maxHp);
            return writer;
        };

        /**
         * Decodes a Hero message from the specified reader or buffer.
         * @function decode
         * @memberof troop.Hero
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {troop.Hero} Hero
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Hero.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.troop.Hero();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.uid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.maxHp = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for Hero
         * @function getTypeUrl
         * @memberof troop.Hero
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Hero.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/troop.Hero";
        };

        return Hero;
    })();

    troop.Troop = (function() {

        /**
         * Properties of a Troop.
         * @memberof troop
         * @interface ITroop
         * @property {number|null} [idx] Troop idx
         * @property {number|null} [eid] Troop eid
         * @property {Array.<troop.Hero>|null} [heros] Troop heros
         * @property {Array.<number>|null} [hps] Troop hps
         * @property {number|null} [leaderSkinId] Troop leaderSkinId
         */

        /**
         * Constructs a new Troop.
         * @memberof troop
         * @classdesc Represents a Troop.
         * @implements ITroop
         * @constructor
         * @param {troop.ITroop=} [properties] Properties to set
         */
        function Troop(properties) {
            this.heros = [];
            this.hps = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Troop idx.
         * @member {number} idx
         * @memberof troop.Troop
         * @instance
         */
        Troop.prototype.idx = 0;

        /**
         * Troop eid.
         * @member {number} eid
         * @memberof troop.Troop
         * @instance
         */
        Troop.prototype.eid = 0;

        /**
         * Troop heros.
         * @member {Array.<troop.Hero>} heros
         * @memberof troop.Troop
         * @instance
         */
        Troop.prototype.heros = $util.emptyArray;

        /**
         * Troop hps.
         * @member {Array.<number>} hps
         * @memberof troop.Troop
         * @instance
         */
        Troop.prototype.hps = $util.emptyArray;

        /**
         * Troop leaderSkinId.
         * @member {number} leaderSkinId
         * @memberof troop.Troop
         * @instance
         */
        Troop.prototype.leaderSkinId = 0;

        /**
         * Creates a new Troop instance using the specified properties.
         * @function create
         * @memberof troop.Troop
         * @static
         * @param {troop.ITroop=} [properties] Properties to set
         * @returns {troop.Troop} Troop instance
         */
        Troop.create = function create(properties) {
            return new Troop(properties);
        };

        /**
         * Encodes the specified Troop message. Does not implicitly {@link troop.Troop.verify|verify} messages.
         * @function encode
         * @memberof troop.Troop
         * @static
         * @param {troop.Troop} message Troop message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Troop.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.idx != null && Object.hasOwnProperty.call(message, "idx"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.idx);
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.eid);
            if (message.heros != null && message.heros.length)
                for (let i = 0; i < message.heros.length; ++i)
                    $root.troop.Hero.encode(message.heros[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.hps != null && message.hps.length) {
                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                for (let i = 0; i < message.hps.length; ++i)
                    writer.uint32(message.hps[i]);
                writer.ldelim();
            }
            if (message.leaderSkinId != null && Object.hasOwnProperty.call(message, "leaderSkinId"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.leaderSkinId);
            return writer;
        };

        /**
         * Decodes a Troop message from the specified reader or buffer.
         * @function decode
         * @memberof troop.Troop
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {troop.Troop} Troop
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Troop.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.troop.Troop();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.idx = reader.uint32();
                        break;
                    }
                case 2: {
                        message.eid = reader.uint32();
                        break;
                    }
                case 3: {
                        if (!(message.heros && message.heros.length))
                            message.heros = [];
                        message.heros.push($root.troop.Hero.decode(reader, reader.uint32()));
                        break;
                    }
                case 4: {
                        if (!(message.hps && message.hps.length))
                            message.hps = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.hps.push(reader.uint32());
                        } else
                            message.hps.push(reader.uint32());
                        break;
                    }
                case 5: {
                        message.leaderSkinId = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for Troop
         * @function getTypeUrl
         * @memberof troop.Troop
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Troop.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/troop.Troop";
        };

        return Troop;
    })();

    troop.c2s_load = (function() {

        /**
         * Properties of a c2s_load.
         * @memberof troop
         * @interface Ic2s_load
         */

        /**
         * Constructs a new c2s_load.
         * @memberof troop
         * @classdesc Represents a c2s_load.
         * @implements Ic2s_load
         * @constructor
         * @param {troop.Ic2s_load=} [properties] Properties to set
         */
        function c2s_load(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_load instance using the specified properties.
         * @function create
         * @memberof troop.c2s_load
         * @static
         * @param {troop.Ic2s_load=} [properties] Properties to set
         * @returns {troop.c2s_load} c2s_load instance
         */
        c2s_load.create = function create(properties) {
            return new c2s_load(properties);
        };

        /**
         * Encodes the specified c2s_load message. Does not implicitly {@link troop.c2s_load.verify|verify} messages.
         * @function encode
         * @memberof troop.c2s_load
         * @static
         * @param {troop.c2s_load} message c2s_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_load message from the specified reader or buffer.
         * @function decode
         * @memberof troop.c2s_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {troop.c2s_load} c2s_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.troop.c2s_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_load
         * @function getTypeUrl
         * @memberof troop.c2s_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/troop.c2s_load";
        };

        return c2s_load;
    })();

    troop.s2c_load = (function() {

        /**
         * Properties of a s2c_load.
         * @memberof troop
         * @interface Is2c_load
         * @property {number|null} [err] s2c_load err
         * @property {Array.<troop.Troop>|null} [troopList] s2c_load troopList
         * @property {number|null} [mainTroopIdx] s2c_load mainTroopIdx
         */

        /**
         * Constructs a new s2c_load.
         * @memberof troop
         * @classdesc Represents a s2c_load.
         * @implements Is2c_load
         * @constructor
         * @param {troop.Is2c_load=} [properties] Properties to set
         */
        function s2c_load(properties) {
            this.troopList = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_load err.
         * @member {number} err
         * @memberof troop.s2c_load
         * @instance
         */
        s2c_load.prototype.err = 0;

        /**
         * s2c_load troopList.
         * @member {Array.<troop.Troop>} troopList
         * @memberof troop.s2c_load
         * @instance
         */
        s2c_load.prototype.troopList = $util.emptyArray;

        /**
         * s2c_load mainTroopIdx.
         * @member {number} mainTroopIdx
         * @memberof troop.s2c_load
         * @instance
         */
        s2c_load.prototype.mainTroopIdx = 0;

        /**
         * Creates a new s2c_load instance using the specified properties.
         * @function create
         * @memberof troop.s2c_load
         * @static
         * @param {troop.Is2c_load=} [properties] Properties to set
         * @returns {troop.s2c_load} s2c_load instance
         */
        s2c_load.create = function create(properties) {
            return new s2c_load(properties);
        };

        /**
         * Encodes the specified s2c_load message. Does not implicitly {@link troop.s2c_load.verify|verify} messages.
         * @function encode
         * @memberof troop.s2c_load
         * @static
         * @param {troop.s2c_load} message s2c_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.troopList != null && message.troopList.length)
                for (let i = 0; i < message.troopList.length; ++i)
                    $root.troop.Troop.encode(message.troopList[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.mainTroopIdx != null && Object.hasOwnProperty.call(message, "mainTroopIdx"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.mainTroopIdx);
            return writer;
        };

        /**
         * Decodes a s2c_load message from the specified reader or buffer.
         * @function decode
         * @memberof troop.s2c_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {troop.s2c_load} s2c_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.troop.s2c_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.troopList && message.troopList.length))
                            message.troopList = [];
                        message.troopList.push($root.troop.Troop.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.mainTroopIdx = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_load
         * @function getTypeUrl
         * @memberof troop.s2c_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/troop.s2c_load";
        };

        return s2c_load;
    })();

    troop.c2s_edit_troop = (function() {

        /**
         * Properties of a c2s_edit_troop.
         * @memberof troop
         * @interface Ic2s_edit_troop
         * @property {troop.Troop|null} [troop] c2s_edit_troop troop
         */

        /**
         * Constructs a new c2s_edit_troop.
         * @memberof troop
         * @classdesc Represents a c2s_edit_troop.
         * @implements Ic2s_edit_troop
         * @constructor
         * @param {troop.Ic2s_edit_troop=} [properties] Properties to set
         */
        function c2s_edit_troop(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_edit_troop troop.
         * @member {troop.Troop|null|undefined} troop
         * @memberof troop.c2s_edit_troop
         * @instance
         */
        c2s_edit_troop.prototype.troop = null;

        /**
         * Creates a new c2s_edit_troop instance using the specified properties.
         * @function create
         * @memberof troop.c2s_edit_troop
         * @static
         * @param {troop.Ic2s_edit_troop=} [properties] Properties to set
         * @returns {troop.c2s_edit_troop} c2s_edit_troop instance
         */
        c2s_edit_troop.create = function create(properties) {
            return new c2s_edit_troop(properties);
        };

        /**
         * Encodes the specified c2s_edit_troop message. Does not implicitly {@link troop.c2s_edit_troop.verify|verify} messages.
         * @function encode
         * @memberof troop.c2s_edit_troop
         * @static
         * @param {troop.c2s_edit_troop} message c2s_edit_troop message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_edit_troop.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.troop != null && Object.hasOwnProperty.call(message, "troop"))
                $root.troop.Troop.encode(message.troop, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a c2s_edit_troop message from the specified reader or buffer.
         * @function decode
         * @memberof troop.c2s_edit_troop
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {troop.c2s_edit_troop} c2s_edit_troop
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_edit_troop.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.troop.c2s_edit_troop();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.troop = $root.troop.Troop.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_edit_troop
         * @function getTypeUrl
         * @memberof troop.c2s_edit_troop
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_edit_troop.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/troop.c2s_edit_troop";
        };

        return c2s_edit_troop;
    })();

    troop.s2c_edit_troop = (function() {

        /**
         * Properties of a s2c_edit_troop.
         * @memberof troop
         * @interface Is2c_edit_troop
         * @property {number|null} [err] s2c_edit_troop err
         * @property {Array.<troop.Troop>|null} [troopList] s2c_edit_troop troopList
         */

        /**
         * Constructs a new s2c_edit_troop.
         * @memberof troop
         * @classdesc Represents a s2c_edit_troop.
         * @implements Is2c_edit_troop
         * @constructor
         * @param {troop.Is2c_edit_troop=} [properties] Properties to set
         */
        function s2c_edit_troop(properties) {
            this.troopList = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_edit_troop err.
         * @member {number} err
         * @memberof troop.s2c_edit_troop
         * @instance
         */
        s2c_edit_troop.prototype.err = 0;

        /**
         * s2c_edit_troop troopList.
         * @member {Array.<troop.Troop>} troopList
         * @memberof troop.s2c_edit_troop
         * @instance
         */
        s2c_edit_troop.prototype.troopList = $util.emptyArray;

        /**
         * Creates a new s2c_edit_troop instance using the specified properties.
         * @function create
         * @memberof troop.s2c_edit_troop
         * @static
         * @param {troop.Is2c_edit_troop=} [properties] Properties to set
         * @returns {troop.s2c_edit_troop} s2c_edit_troop instance
         */
        s2c_edit_troop.create = function create(properties) {
            return new s2c_edit_troop(properties);
        };

        /**
         * Encodes the specified s2c_edit_troop message. Does not implicitly {@link troop.s2c_edit_troop.verify|verify} messages.
         * @function encode
         * @memberof troop.s2c_edit_troop
         * @static
         * @param {troop.s2c_edit_troop} message s2c_edit_troop message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_edit_troop.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.troopList != null && message.troopList.length)
                for (let i = 0; i < message.troopList.length; ++i)
                    $root.troop.Troop.encode(message.troopList[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_edit_troop message from the specified reader or buffer.
         * @function decode
         * @memberof troop.s2c_edit_troop
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {troop.s2c_edit_troop} s2c_edit_troop
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_edit_troop.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.troop.s2c_edit_troop();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.troopList && message.troopList.length))
                            message.troopList = [];
                        message.troopList.push($root.troop.Troop.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_edit_troop
         * @function getTypeUrl
         * @memberof troop.s2c_edit_troop
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_edit_troop.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/troop.s2c_edit_troop";
        };

        return s2c_edit_troop;
    })();

    troop.c2s_set_main_troop_idx = (function() {

        /**
         * Properties of a c2s_set_main_troop_idx.
         * @memberof troop
         * @interface Ic2s_set_main_troop_idx
         * @property {number|null} [idx] c2s_set_main_troop_idx idx
         */

        /**
         * Constructs a new c2s_set_main_troop_idx.
         * @memberof troop
         * @classdesc Represents a c2s_set_main_troop_idx.
         * @implements Ic2s_set_main_troop_idx
         * @constructor
         * @param {troop.Ic2s_set_main_troop_idx=} [properties] Properties to set
         */
        function c2s_set_main_troop_idx(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_set_main_troop_idx idx.
         * @member {number} idx
         * @memberof troop.c2s_set_main_troop_idx
         * @instance
         */
        c2s_set_main_troop_idx.prototype.idx = 0;

        /**
         * Creates a new c2s_set_main_troop_idx instance using the specified properties.
         * @function create
         * @memberof troop.c2s_set_main_troop_idx
         * @static
         * @param {troop.Ic2s_set_main_troop_idx=} [properties] Properties to set
         * @returns {troop.c2s_set_main_troop_idx} c2s_set_main_troop_idx instance
         */
        c2s_set_main_troop_idx.create = function create(properties) {
            return new c2s_set_main_troop_idx(properties);
        };

        /**
         * Encodes the specified c2s_set_main_troop_idx message. Does not implicitly {@link troop.c2s_set_main_troop_idx.verify|verify} messages.
         * @function encode
         * @memberof troop.c2s_set_main_troop_idx
         * @static
         * @param {troop.c2s_set_main_troop_idx} message c2s_set_main_troop_idx message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_set_main_troop_idx.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.idx != null && Object.hasOwnProperty.call(message, "idx"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.idx);
            return writer;
        };

        /**
         * Decodes a c2s_set_main_troop_idx message from the specified reader or buffer.
         * @function decode
         * @memberof troop.c2s_set_main_troop_idx
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {troop.c2s_set_main_troop_idx} c2s_set_main_troop_idx
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_set_main_troop_idx.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.troop.c2s_set_main_troop_idx();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.idx = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_set_main_troop_idx
         * @function getTypeUrl
         * @memberof troop.c2s_set_main_troop_idx
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_set_main_troop_idx.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/troop.c2s_set_main_troop_idx";
        };

        return c2s_set_main_troop_idx;
    })();

    troop.s2c_set_main_troop_idx = (function() {

        /**
         * Properties of a s2c_set_main_troop_idx.
         * @memberof troop
         * @interface Is2c_set_main_troop_idx
         * @property {number|null} [err] s2c_set_main_troop_idx err
         */

        /**
         * Constructs a new s2c_set_main_troop_idx.
         * @memberof troop
         * @classdesc Represents a s2c_set_main_troop_idx.
         * @implements Is2c_set_main_troop_idx
         * @constructor
         * @param {troop.Is2c_set_main_troop_idx=} [properties] Properties to set
         */
        function s2c_set_main_troop_idx(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_set_main_troop_idx err.
         * @member {number} err
         * @memberof troop.s2c_set_main_troop_idx
         * @instance
         */
        s2c_set_main_troop_idx.prototype.err = 0;

        /**
         * Creates a new s2c_set_main_troop_idx instance using the specified properties.
         * @function create
         * @memberof troop.s2c_set_main_troop_idx
         * @static
         * @param {troop.Is2c_set_main_troop_idx=} [properties] Properties to set
         * @returns {troop.s2c_set_main_troop_idx} s2c_set_main_troop_idx instance
         */
        s2c_set_main_troop_idx.create = function create(properties) {
            return new s2c_set_main_troop_idx(properties);
        };

        /**
         * Encodes the specified s2c_set_main_troop_idx message. Does not implicitly {@link troop.s2c_set_main_troop_idx.verify|verify} messages.
         * @function encode
         * @memberof troop.s2c_set_main_troop_idx
         * @static
         * @param {troop.s2c_set_main_troop_idx} message s2c_set_main_troop_idx message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_set_main_troop_idx.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_set_main_troop_idx message from the specified reader or buffer.
         * @function decode
         * @memberof troop.s2c_set_main_troop_idx
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {troop.s2c_set_main_troop_idx} s2c_set_main_troop_idx
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_set_main_troop_idx.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.troop.s2c_set_main_troop_idx();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_set_main_troop_idx
         * @function getTypeUrl
         * @memberof troop.s2c_set_main_troop_idx
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_set_main_troop_idx.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/troop.s2c_set_main_troop_idx";
        };

        return s2c_set_main_troop_idx;
    })();

    troop.c2s_exchange_soldier = (function() {

        /**
         * Properties of a c2s_exchange_soldier.
         * @memberof troop
         * @interface Ic2s_exchange_soldier
         * @property {number|null} [firstIdx] c2s_exchange_soldier firstIdx
         * @property {number|null} [firstSoldierIdx] c2s_exchange_soldier firstSoldierIdx
         * @property {number|null} [secondIdx] c2s_exchange_soldier secondIdx
         * @property {number|null} [secondSoldierIdx] c2s_exchange_soldier secondSoldierIdx
         */

        /**
         * Constructs a new c2s_exchange_soldier.
         * @memberof troop
         * @classdesc Represents a c2s_exchange_soldier.
         * @implements Ic2s_exchange_soldier
         * @constructor
         * @param {troop.Ic2s_exchange_soldier=} [properties] Properties to set
         */
        function c2s_exchange_soldier(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_exchange_soldier firstIdx.
         * @member {number} firstIdx
         * @memberof troop.c2s_exchange_soldier
         * @instance
         */
        c2s_exchange_soldier.prototype.firstIdx = 0;

        /**
         * c2s_exchange_soldier firstSoldierIdx.
         * @member {number} firstSoldierIdx
         * @memberof troop.c2s_exchange_soldier
         * @instance
         */
        c2s_exchange_soldier.prototype.firstSoldierIdx = 0;

        /**
         * c2s_exchange_soldier secondIdx.
         * @member {number} secondIdx
         * @memberof troop.c2s_exchange_soldier
         * @instance
         */
        c2s_exchange_soldier.prototype.secondIdx = 0;

        /**
         * c2s_exchange_soldier secondSoldierIdx.
         * @member {number} secondSoldierIdx
         * @memberof troop.c2s_exchange_soldier
         * @instance
         */
        c2s_exchange_soldier.prototype.secondSoldierIdx = 0;

        /**
         * Creates a new c2s_exchange_soldier instance using the specified properties.
         * @function create
         * @memberof troop.c2s_exchange_soldier
         * @static
         * @param {troop.Ic2s_exchange_soldier=} [properties] Properties to set
         * @returns {troop.c2s_exchange_soldier} c2s_exchange_soldier instance
         */
        c2s_exchange_soldier.create = function create(properties) {
            return new c2s_exchange_soldier(properties);
        };

        /**
         * Encodes the specified c2s_exchange_soldier message. Does not implicitly {@link troop.c2s_exchange_soldier.verify|verify} messages.
         * @function encode
         * @memberof troop.c2s_exchange_soldier
         * @static
         * @param {troop.c2s_exchange_soldier} message c2s_exchange_soldier message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_exchange_soldier.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.firstIdx != null && Object.hasOwnProperty.call(message, "firstIdx"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.firstIdx);
            if (message.firstSoldierIdx != null && Object.hasOwnProperty.call(message, "firstSoldierIdx"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.firstSoldierIdx);
            if (message.secondIdx != null && Object.hasOwnProperty.call(message, "secondIdx"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.secondIdx);
            if (message.secondSoldierIdx != null && Object.hasOwnProperty.call(message, "secondSoldierIdx"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.secondSoldierIdx);
            return writer;
        };

        /**
         * Decodes a c2s_exchange_soldier message from the specified reader or buffer.
         * @function decode
         * @memberof troop.c2s_exchange_soldier
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {troop.c2s_exchange_soldier} c2s_exchange_soldier
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_exchange_soldier.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.troop.c2s_exchange_soldier();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.firstIdx = reader.uint32();
                        break;
                    }
                case 2: {
                        message.firstSoldierIdx = reader.uint32();
                        break;
                    }
                case 3: {
                        message.secondIdx = reader.uint32();
                        break;
                    }
                case 4: {
                        message.secondSoldierIdx = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_exchange_soldier
         * @function getTypeUrl
         * @memberof troop.c2s_exchange_soldier
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_exchange_soldier.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/troop.c2s_exchange_soldier";
        };

        return c2s_exchange_soldier;
    })();

    troop.s2c_exchange_soldier = (function() {

        /**
         * Properties of a s2c_exchange_soldier.
         * @memberof troop
         * @interface Is2c_exchange_soldier
         * @property {number|null} [err] s2c_exchange_soldier err
         * @property {Array.<troop.Troop>|null} [troopList] s2c_exchange_soldier troopList
         */

        /**
         * Constructs a new s2c_exchange_soldier.
         * @memberof troop
         * @classdesc Represents a s2c_exchange_soldier.
         * @implements Is2c_exchange_soldier
         * @constructor
         * @param {troop.Is2c_exchange_soldier=} [properties] Properties to set
         */
        function s2c_exchange_soldier(properties) {
            this.troopList = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_exchange_soldier err.
         * @member {number} err
         * @memberof troop.s2c_exchange_soldier
         * @instance
         */
        s2c_exchange_soldier.prototype.err = 0;

        /**
         * s2c_exchange_soldier troopList.
         * @member {Array.<troop.Troop>} troopList
         * @memberof troop.s2c_exchange_soldier
         * @instance
         */
        s2c_exchange_soldier.prototype.troopList = $util.emptyArray;

        /**
         * Creates a new s2c_exchange_soldier instance using the specified properties.
         * @function create
         * @memberof troop.s2c_exchange_soldier
         * @static
         * @param {troop.Is2c_exchange_soldier=} [properties] Properties to set
         * @returns {troop.s2c_exchange_soldier} s2c_exchange_soldier instance
         */
        s2c_exchange_soldier.create = function create(properties) {
            return new s2c_exchange_soldier(properties);
        };

        /**
         * Encodes the specified s2c_exchange_soldier message. Does not implicitly {@link troop.s2c_exchange_soldier.verify|verify} messages.
         * @function encode
         * @memberof troop.s2c_exchange_soldier
         * @static
         * @param {troop.s2c_exchange_soldier} message s2c_exchange_soldier message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_exchange_soldier.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.troopList != null && message.troopList.length)
                for (let i = 0; i < message.troopList.length; ++i)
                    $root.troop.Troop.encode(message.troopList[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_exchange_soldier message from the specified reader or buffer.
         * @function decode
         * @memberof troop.s2c_exchange_soldier
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {troop.s2c_exchange_soldier} s2c_exchange_soldier
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_exchange_soldier.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.troop.s2c_exchange_soldier();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.troopList && message.troopList.length))
                            message.troopList = [];
                        message.troopList.push($root.troop.Troop.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_exchange_soldier
         * @function getTypeUrl
         * @memberof troop.s2c_exchange_soldier
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_exchange_soldier.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/troop.s2c_exchange_soldier";
        };

        return s2c_exchange_soldier;
    })();

    troop.c2s_install_leader_skin = (function() {

        /**
         * Properties of a c2s_install_leader_skin.
         * @memberof troop
         * @interface Ic2s_install_leader_skin
         * @property {number|null} [idx] c2s_install_leader_skin idx
         * @property {number|null} [leaderSkinId] c2s_install_leader_skin leaderSkinId
         */

        /**
         * Constructs a new c2s_install_leader_skin.
         * @memberof troop
         * @classdesc Represents a c2s_install_leader_skin.
         * @implements Ic2s_install_leader_skin
         * @constructor
         * @param {troop.Ic2s_install_leader_skin=} [properties] Properties to set
         */
        function c2s_install_leader_skin(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_install_leader_skin idx.
         * @member {number} idx
         * @memberof troop.c2s_install_leader_skin
         * @instance
         */
        c2s_install_leader_skin.prototype.idx = 0;

        /**
         * c2s_install_leader_skin leaderSkinId.
         * @member {number} leaderSkinId
         * @memberof troop.c2s_install_leader_skin
         * @instance
         */
        c2s_install_leader_skin.prototype.leaderSkinId = 0;

        /**
         * Creates a new c2s_install_leader_skin instance using the specified properties.
         * @function create
         * @memberof troop.c2s_install_leader_skin
         * @static
         * @param {troop.Ic2s_install_leader_skin=} [properties] Properties to set
         * @returns {troop.c2s_install_leader_skin} c2s_install_leader_skin instance
         */
        c2s_install_leader_skin.create = function create(properties) {
            return new c2s_install_leader_skin(properties);
        };

        /**
         * Encodes the specified c2s_install_leader_skin message. Does not implicitly {@link troop.c2s_install_leader_skin.verify|verify} messages.
         * @function encode
         * @memberof troop.c2s_install_leader_skin
         * @static
         * @param {troop.c2s_install_leader_skin} message c2s_install_leader_skin message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_install_leader_skin.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.idx != null && Object.hasOwnProperty.call(message, "idx"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.idx);
            if (message.leaderSkinId != null && Object.hasOwnProperty.call(message, "leaderSkinId"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.leaderSkinId);
            return writer;
        };

        /**
         * Decodes a c2s_install_leader_skin message from the specified reader or buffer.
         * @function decode
         * @memberof troop.c2s_install_leader_skin
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {troop.c2s_install_leader_skin} c2s_install_leader_skin
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_install_leader_skin.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.troop.c2s_install_leader_skin();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.idx = reader.uint32();
                        break;
                    }
                case 2: {
                        message.leaderSkinId = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_install_leader_skin
         * @function getTypeUrl
         * @memberof troop.c2s_install_leader_skin
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_install_leader_skin.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/troop.c2s_install_leader_skin";
        };

        return c2s_install_leader_skin;
    })();

    troop.s2c_install_leader_skin = (function() {

        /**
         * Properties of a s2c_install_leader_skin.
         * @memberof troop
         * @interface Is2c_install_leader_skin
         * @property {number|null} [err] s2c_install_leader_skin err
         */

        /**
         * Constructs a new s2c_install_leader_skin.
         * @memberof troop
         * @classdesc Represents a s2c_install_leader_skin.
         * @implements Is2c_install_leader_skin
         * @constructor
         * @param {troop.Is2c_install_leader_skin=} [properties] Properties to set
         */
        function s2c_install_leader_skin(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_install_leader_skin err.
         * @member {number} err
         * @memberof troop.s2c_install_leader_skin
         * @instance
         */
        s2c_install_leader_skin.prototype.err = 0;

        /**
         * Creates a new s2c_install_leader_skin instance using the specified properties.
         * @function create
         * @memberof troop.s2c_install_leader_skin
         * @static
         * @param {troop.Is2c_install_leader_skin=} [properties] Properties to set
         * @returns {troop.s2c_install_leader_skin} s2c_install_leader_skin instance
         */
        s2c_install_leader_skin.create = function create(properties) {
            return new s2c_install_leader_skin(properties);
        };

        /**
         * Encodes the specified s2c_install_leader_skin message. Does not implicitly {@link troop.s2c_install_leader_skin.verify|verify} messages.
         * @function encode
         * @memberof troop.s2c_install_leader_skin
         * @static
         * @param {troop.s2c_install_leader_skin} message s2c_install_leader_skin message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_install_leader_skin.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_install_leader_skin message from the specified reader or buffer.
         * @function decode
         * @memberof troop.s2c_install_leader_skin
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {troop.s2c_install_leader_skin} s2c_install_leader_skin
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_install_leader_skin.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.troop.s2c_install_leader_skin();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_install_leader_skin
         * @function getTypeUrl
         * @memberof troop.s2c_install_leader_skin
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_install_leader_skin.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/troop.s2c_install_leader_skin";
        };

        return s2c_install_leader_skin;
    })();

    troop.notify_troop_update = (function() {

        /**
         * Properties of a notify_troop_update.
         * @memberof troop
         * @interface Inotify_troop_update
         * @property {Array.<troop.Troop>|null} [troopList] notify_troop_update troopList
         */

        /**
         * Constructs a new notify_troop_update.
         * @memberof troop
         * @classdesc Represents a notify_troop_update.
         * @implements Inotify_troop_update
         * @constructor
         * @param {troop.Inotify_troop_update=} [properties] Properties to set
         */
        function notify_troop_update(properties) {
            this.troopList = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_troop_update troopList.
         * @member {Array.<troop.Troop>} troopList
         * @memberof troop.notify_troop_update
         * @instance
         */
        notify_troop_update.prototype.troopList = $util.emptyArray;

        /**
         * Creates a new notify_troop_update instance using the specified properties.
         * @function create
         * @memberof troop.notify_troop_update
         * @static
         * @param {troop.Inotify_troop_update=} [properties] Properties to set
         * @returns {troop.notify_troop_update} notify_troop_update instance
         */
        notify_troop_update.create = function create(properties) {
            return new notify_troop_update(properties);
        };

        /**
         * Encodes the specified notify_troop_update message. Does not implicitly {@link troop.notify_troop_update.verify|verify} messages.
         * @function encode
         * @memberof troop.notify_troop_update
         * @static
         * @param {troop.notify_troop_update} message notify_troop_update message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_troop_update.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.troopList != null && message.troopList.length)
                for (let i = 0; i < message.troopList.length; ++i)
                    $root.troop.Troop.encode(message.troopList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a notify_troop_update message from the specified reader or buffer.
         * @function decode
         * @memberof troop.notify_troop_update
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {troop.notify_troop_update} notify_troop_update
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_troop_update.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.troop.notify_troop_update();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.troopList && message.troopList.length))
                            message.troopList = [];
                        message.troopList.push($root.troop.Troop.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_troop_update
         * @function getTypeUrl
         * @memberof troop.notify_troop_update
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_troop_update.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/troop.notify_troop_update";
        };

        return notify_troop_update;
    })();

    return troop;
})();

export const user = $root.user = (() => {

    /**
     * Namespace user.
     * @exports user
     * @namespace
     */
    const user = {};

    user.UserInfo = (function() {

        /**
         * Properties of a UserInfo.
         * @memberof user
         * @interface IUserInfo
         * @property {string|null} [account] UserInfo account
         */

        /**
         * Constructs a new UserInfo.
         * @memberof user
         * @classdesc Represents a UserInfo.
         * @implements IUserInfo
         * @constructor
         * @param {user.IUserInfo=} [properties] Properties to set
         */
        function UserInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserInfo account.
         * @member {string} account
         * @memberof user.UserInfo
         * @instance
         */
        UserInfo.prototype.account = "";

        /**
         * Creates a new UserInfo instance using the specified properties.
         * @function create
         * @memberof user.UserInfo
         * @static
         * @param {user.IUserInfo=} [properties] Properties to set
         * @returns {user.UserInfo} UserInfo instance
         */
        UserInfo.create = function create(properties) {
            return new UserInfo(properties);
        };

        /**
         * Encodes the specified UserInfo message. Does not implicitly {@link user.UserInfo.verify|verify} messages.
         * @function encode
         * @memberof user.UserInfo
         * @static
         * @param {user.UserInfo} message UserInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.account);
            return writer;
        };

        /**
         * Decodes a UserInfo message from the specified reader or buffer.
         * @function decode
         * @memberof user.UserInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.UserInfo} UserInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.UserInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.account = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for UserInfo
         * @function getTypeUrl
         * @memberof user.UserInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.UserInfo";
        };

        return UserInfo;
    })();

    user.RoleInfo = (function() {

        /**
         * Properties of a RoleInfo.
         * @memberof user
         * @interface IRoleInfo
         * @property {number|null} [rid] RoleInfo rid
         * @property {number|null} [serverId] RoleInfo serverId
         * @property {string|null} [serverName] RoleInfo serverName
         * @property {number|null} [serverType] RoleInfo serverType
         */

        /**
         * Constructs a new RoleInfo.
         * @memberof user
         * @classdesc Represents a RoleInfo.
         * @implements IRoleInfo
         * @constructor
         * @param {user.IRoleInfo=} [properties] Properties to set
         */
        function RoleInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RoleInfo rid.
         * @member {number} rid
         * @memberof user.RoleInfo
         * @instance
         */
        RoleInfo.prototype.rid = 0;

        /**
         * RoleInfo serverId.
         * @member {number} serverId
         * @memberof user.RoleInfo
         * @instance
         */
        RoleInfo.prototype.serverId = 0;

        /**
         * RoleInfo serverName.
         * @member {string} serverName
         * @memberof user.RoleInfo
         * @instance
         */
        RoleInfo.prototype.serverName = "";

        /**
         * RoleInfo serverType.
         * @member {number} serverType
         * @memberof user.RoleInfo
         * @instance
         */
        RoleInfo.prototype.serverType = 0;

        /**
         * Creates a new RoleInfo instance using the specified properties.
         * @function create
         * @memberof user.RoleInfo
         * @static
         * @param {user.IRoleInfo=} [properties] Properties to set
         * @returns {user.RoleInfo} RoleInfo instance
         */
        RoleInfo.create = function create(properties) {
            return new RoleInfo(properties);
        };

        /**
         * Encodes the specified RoleInfo message. Does not implicitly {@link user.RoleInfo.verify|verify} messages.
         * @function encode
         * @memberof user.RoleInfo
         * @static
         * @param {user.RoleInfo} message RoleInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RoleInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rid != null && Object.hasOwnProperty.call(message, "rid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.rid);
            if (message.serverId != null && Object.hasOwnProperty.call(message, "serverId"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.serverId);
            if (message.serverName != null && Object.hasOwnProperty.call(message, "serverName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.serverName);
            if (message.serverType != null && Object.hasOwnProperty.call(message, "serverType"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.serverType);
            return writer;
        };

        /**
         * Decodes a RoleInfo message from the specified reader or buffer.
         * @function decode
         * @memberof user.RoleInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.RoleInfo} RoleInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RoleInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.RoleInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.serverId = reader.uint32();
                        break;
                    }
                case 3: {
                        message.serverName = reader.string();
                        break;
                    }
                case 4: {
                        message.serverType = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for RoleInfo
         * @function getTypeUrl
         * @memberof user.RoleInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RoleInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.RoleInfo";
        };

        return RoleInfo;
    })();

    user.c2s_login = (function() {

        /**
         * Properties of a c2s_login.
         * @memberof user
         * @interface Ic2s_login
         * @property {string|null} [account] c2s_login account
         */

        /**
         * Constructs a new c2s_login.
         * @memberof user
         * @classdesc Represents a c2s_login.
         * @implements Ic2s_login
         * @constructor
         * @param {user.Ic2s_login=} [properties] Properties to set
         */
        function c2s_login(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_login account.
         * @member {string} account
         * @memberof user.c2s_login
         * @instance
         */
        c2s_login.prototype.account = "";

        /**
         * Creates a new c2s_login instance using the specified properties.
         * @function create
         * @memberof user.c2s_login
         * @static
         * @param {user.Ic2s_login=} [properties] Properties to set
         * @returns {user.c2s_login} c2s_login instance
         */
        c2s_login.create = function create(properties) {
            return new c2s_login(properties);
        };

        /**
         * Encodes the specified c2s_login message. Does not implicitly {@link user.c2s_login.verify|verify} messages.
         * @function encode
         * @memberof user.c2s_login
         * @static
         * @param {user.c2s_login} message c2s_login message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_login.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.account);
            return writer;
        };

        /**
         * Decodes a c2s_login message from the specified reader or buffer.
         * @function decode
         * @memberof user.c2s_login
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.c2s_login} c2s_login
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_login.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.c2s_login();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.account = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_login
         * @function getTypeUrl
         * @memberof user.c2s_login
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_login.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.c2s_login";
        };

        return c2s_login;
    })();

    user.s2c_login = (function() {

        /**
         * Properties of a s2c_login.
         * @memberof user
         * @interface Is2c_login
         * @property {number|null} [err] s2c_login err
         * @property {user.UserInfo|null} [info] s2c_login info
         * @property {user.RoleInfo|null} [role] s2c_login role
         */

        /**
         * Constructs a new s2c_login.
         * @memberof user
         * @classdesc Represents a s2c_login.
         * @implements Is2c_login
         * @constructor
         * @param {user.Is2c_login=} [properties] Properties to set
         */
        function s2c_login(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_login err.
         * @member {number} err
         * @memberof user.s2c_login
         * @instance
         */
        s2c_login.prototype.err = 0;

        /**
         * s2c_login info.
         * @member {user.UserInfo|null|undefined} info
         * @memberof user.s2c_login
         * @instance
         */
        s2c_login.prototype.info = null;

        /**
         * s2c_login role.
         * @member {user.RoleInfo|null|undefined} role
         * @memberof user.s2c_login
         * @instance
         */
        s2c_login.prototype.role = null;

        /**
         * Creates a new s2c_login instance using the specified properties.
         * @function create
         * @memberof user.s2c_login
         * @static
         * @param {user.Is2c_login=} [properties] Properties to set
         * @returns {user.s2c_login} s2c_login instance
         */
        s2c_login.create = function create(properties) {
            return new s2c_login(properties);
        };

        /**
         * Encodes the specified s2c_login message. Does not implicitly {@link user.s2c_login.verify|verify} messages.
         * @function encode
         * @memberof user.s2c_login
         * @static
         * @param {user.s2c_login} message s2c_login message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_login.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.info != null && Object.hasOwnProperty.call(message, "info"))
                $root.user.UserInfo.encode(message.info, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                $root.user.RoleInfo.encode(message.role, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_login message from the specified reader or buffer.
         * @function decode
         * @memberof user.s2c_login
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.s2c_login} s2c_login
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_login.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.s2c_login();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        message.info = $root.user.UserInfo.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.role = $root.user.RoleInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_login
         * @function getTypeUrl
         * @memberof user.s2c_login
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_login.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.s2c_login";
        };

        return s2c_login;
    })();

    user.c2s_create_role = (function() {

        /**
         * Properties of a c2s_create_role.
         * @memberof user
         * @interface Ic2s_create_role
         * @property {string|null} [account] c2s_create_role account
         * @property {string|null} [name] c2s_create_role name
         * @property {number|null} [gender] c2s_create_role gender
         * @property {number|null} [jobId] c2s_create_role jobId
         */

        /**
         * Constructs a new c2s_create_role.
         * @memberof user
         * @classdesc Represents a c2s_create_role.
         * @implements Ic2s_create_role
         * @constructor
         * @param {user.Ic2s_create_role=} [properties] Properties to set
         */
        function c2s_create_role(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_create_role account.
         * @member {string} account
         * @memberof user.c2s_create_role
         * @instance
         */
        c2s_create_role.prototype.account = "";

        /**
         * c2s_create_role name.
         * @member {string} name
         * @memberof user.c2s_create_role
         * @instance
         */
        c2s_create_role.prototype.name = "";

        /**
         * c2s_create_role gender.
         * @member {number} gender
         * @memberof user.c2s_create_role
         * @instance
         */
        c2s_create_role.prototype.gender = 0;

        /**
         * c2s_create_role jobId.
         * @member {number} jobId
         * @memberof user.c2s_create_role
         * @instance
         */
        c2s_create_role.prototype.jobId = 0;

        /**
         * Creates a new c2s_create_role instance using the specified properties.
         * @function create
         * @memberof user.c2s_create_role
         * @static
         * @param {user.Ic2s_create_role=} [properties] Properties to set
         * @returns {user.c2s_create_role} c2s_create_role instance
         */
        c2s_create_role.create = function create(properties) {
            return new c2s_create_role(properties);
        };

        /**
         * Encodes the specified c2s_create_role message. Does not implicitly {@link user.c2s_create_role.verify|verify} messages.
         * @function encode
         * @memberof user.c2s_create_role
         * @static
         * @param {user.c2s_create_role} message c2s_create_role message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_create_role.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.account);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.gender);
            if (message.jobId != null && Object.hasOwnProperty.call(message, "jobId"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.jobId);
            return writer;
        };

        /**
         * Decodes a c2s_create_role message from the specified reader or buffer.
         * @function decode
         * @memberof user.c2s_create_role
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.c2s_create_role} c2s_create_role
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_create_role.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.c2s_create_role();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.account = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.gender = reader.uint32();
                        break;
                    }
                case 4: {
                        message.jobId = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_create_role
         * @function getTypeUrl
         * @memberof user.c2s_create_role
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_create_role.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.c2s_create_role";
        };

        return c2s_create_role;
    })();

    user.s2c_create_role = (function() {

        /**
         * Properties of a s2c_create_role.
         * @memberof user
         * @interface Is2c_create_role
         * @property {number|null} [err] s2c_create_role err
         * @property {user.UserInfo|null} [info] s2c_create_role info
         * @property {user.RoleInfo|null} [role] s2c_create_role role
         */

        /**
         * Constructs a new s2c_create_role.
         * @memberof user
         * @classdesc Represents a s2c_create_role.
         * @implements Is2c_create_role
         * @constructor
         * @param {user.Is2c_create_role=} [properties] Properties to set
         */
        function s2c_create_role(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_create_role err.
         * @member {number} err
         * @memberof user.s2c_create_role
         * @instance
         */
        s2c_create_role.prototype.err = 0;

        /**
         * s2c_create_role info.
         * @member {user.UserInfo|null|undefined} info
         * @memberof user.s2c_create_role
         * @instance
         */
        s2c_create_role.prototype.info = null;

        /**
         * s2c_create_role role.
         * @member {user.RoleInfo|null|undefined} role
         * @memberof user.s2c_create_role
         * @instance
         */
        s2c_create_role.prototype.role = null;

        /**
         * Creates a new s2c_create_role instance using the specified properties.
         * @function create
         * @memberof user.s2c_create_role
         * @static
         * @param {user.Is2c_create_role=} [properties] Properties to set
         * @returns {user.s2c_create_role} s2c_create_role instance
         */
        s2c_create_role.create = function create(properties) {
            return new s2c_create_role(properties);
        };

        /**
         * Encodes the specified s2c_create_role message. Does not implicitly {@link user.s2c_create_role.verify|verify} messages.
         * @function encode
         * @memberof user.s2c_create_role
         * @static
         * @param {user.s2c_create_role} message s2c_create_role message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_create_role.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.info != null && Object.hasOwnProperty.call(message, "info"))
                $root.user.UserInfo.encode(message.info, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                $root.user.RoleInfo.encode(message.role, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_create_role message from the specified reader or buffer.
         * @function decode
         * @memberof user.s2c_create_role
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.s2c_create_role} s2c_create_role
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_create_role.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.s2c_create_role();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        message.info = $root.user.UserInfo.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.role = $root.user.RoleInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_create_role
         * @function getTypeUrl
         * @memberof user.s2c_create_role
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_create_role.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.s2c_create_role";
        };

        return s2c_create_role;
    })();

    user.c2s_random_name = (function() {

        /**
         * Properties of a c2s_random_name.
         * @memberof user
         * @interface Ic2s_random_name
         * @property {number|null} [gender] c2s_random_name gender
         */

        /**
         * Constructs a new c2s_random_name.
         * @memberof user
         * @classdesc Represents a c2s_random_name.
         * @implements Ic2s_random_name
         * @constructor
         * @param {user.Ic2s_random_name=} [properties] Properties to set
         */
        function c2s_random_name(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_random_name gender.
         * @member {number} gender
         * @memberof user.c2s_random_name
         * @instance
         */
        c2s_random_name.prototype.gender = 0;

        /**
         * Creates a new c2s_random_name instance using the specified properties.
         * @function create
         * @memberof user.c2s_random_name
         * @static
         * @param {user.Ic2s_random_name=} [properties] Properties to set
         * @returns {user.c2s_random_name} c2s_random_name instance
         */
        c2s_random_name.create = function create(properties) {
            return new c2s_random_name(properties);
        };

        /**
         * Encodes the specified c2s_random_name message. Does not implicitly {@link user.c2s_random_name.verify|verify} messages.
         * @function encode
         * @memberof user.c2s_random_name
         * @static
         * @param {user.c2s_random_name} message c2s_random_name message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_random_name.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.gender);
            return writer;
        };

        /**
         * Decodes a c2s_random_name message from the specified reader or buffer.
         * @function decode
         * @memberof user.c2s_random_name
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.c2s_random_name} c2s_random_name
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_random_name.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.c2s_random_name();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.gender = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_random_name
         * @function getTypeUrl
         * @memberof user.c2s_random_name
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_random_name.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.c2s_random_name";
        };

        return c2s_random_name;
    })();

    user.s2c_random_name = (function() {

        /**
         * Properties of a s2c_random_name.
         * @memberof user
         * @interface Is2c_random_name
         * @property {number|null} [err] s2c_random_name err
         * @property {string|null} [name] s2c_random_name name
         */

        /**
         * Constructs a new s2c_random_name.
         * @memberof user
         * @classdesc Represents a s2c_random_name.
         * @implements Is2c_random_name
         * @constructor
         * @param {user.Is2c_random_name=} [properties] Properties to set
         */
        function s2c_random_name(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_random_name err.
         * @member {number} err
         * @memberof user.s2c_random_name
         * @instance
         */
        s2c_random_name.prototype.err = 0;

        /**
         * s2c_random_name name.
         * @member {string} name
         * @memberof user.s2c_random_name
         * @instance
         */
        s2c_random_name.prototype.name = "";

        /**
         * Creates a new s2c_random_name instance using the specified properties.
         * @function create
         * @memberof user.s2c_random_name
         * @static
         * @param {user.Is2c_random_name=} [properties] Properties to set
         * @returns {user.s2c_random_name} s2c_random_name instance
         */
        s2c_random_name.create = function create(properties) {
            return new s2c_random_name(properties);
        };

        /**
         * Encodes the specified s2c_random_name message. Does not implicitly {@link user.s2c_random_name.verify|verify} messages.
         * @function encode
         * @memberof user.s2c_random_name
         * @static
         * @param {user.s2c_random_name} message s2c_random_name message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_random_name.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Decodes a s2c_random_name message from the specified reader or buffer.
         * @function decode
         * @memberof user.s2c_random_name
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.s2c_random_name} s2c_random_name
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_random_name.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.s2c_random_name();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_random_name
         * @function getTypeUrl
         * @memberof user.s2c_random_name
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_random_name.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.s2c_random_name";
        };

        return s2c_random_name;
    })();

    user.c2s_ping = (function() {

        /**
         * Properties of a c2s_ping.
         * @memberof user
         * @interface Ic2s_ping
         */

        /**
         * Constructs a new c2s_ping.
         * @memberof user
         * @classdesc Represents a c2s_ping.
         * @implements Ic2s_ping
         * @constructor
         * @param {user.Ic2s_ping=} [properties] Properties to set
         */
        function c2s_ping(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_ping instance using the specified properties.
         * @function create
         * @memberof user.c2s_ping
         * @static
         * @param {user.Ic2s_ping=} [properties] Properties to set
         * @returns {user.c2s_ping} c2s_ping instance
         */
        c2s_ping.create = function create(properties) {
            return new c2s_ping(properties);
        };

        /**
         * Encodes the specified c2s_ping message. Does not implicitly {@link user.c2s_ping.verify|verify} messages.
         * @function encode
         * @memberof user.c2s_ping
         * @static
         * @param {user.c2s_ping} message c2s_ping message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_ping.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_ping message from the specified reader or buffer.
         * @function decode
         * @memberof user.c2s_ping
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.c2s_ping} c2s_ping
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_ping.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.c2s_ping();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_ping
         * @function getTypeUrl
         * @memberof user.c2s_ping
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_ping.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.c2s_ping";
        };

        return c2s_ping;
    })();

    user.s2c_ping = (function() {

        /**
         * Properties of a s2c_ping.
         * @memberof user
         * @interface Is2c_ping
         * @property {number|null} [err] s2c_ping err
         * @property {number|null} [serverMs] s2c_ping serverMs
         */

        /**
         * Constructs a new s2c_ping.
         * @memberof user
         * @classdesc Represents a s2c_ping.
         * @implements Is2c_ping
         * @constructor
         * @param {user.Is2c_ping=} [properties] Properties to set
         */
        function s2c_ping(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_ping err.
         * @member {number} err
         * @memberof user.s2c_ping
         * @instance
         */
        s2c_ping.prototype.err = 0;

        /**
         * s2c_ping serverMs.
         * @member {number} serverMs
         * @memberof user.s2c_ping
         * @instance
         */
        s2c_ping.prototype.serverMs = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new s2c_ping instance using the specified properties.
         * @function create
         * @memberof user.s2c_ping
         * @static
         * @param {user.Is2c_ping=} [properties] Properties to set
         * @returns {user.s2c_ping} s2c_ping instance
         */
        s2c_ping.create = function create(properties) {
            return new s2c_ping(properties);
        };

        /**
         * Encodes the specified s2c_ping message. Does not implicitly {@link user.s2c_ping.verify|verify} messages.
         * @function encode
         * @memberof user.s2c_ping
         * @static
         * @param {user.s2c_ping} message s2c_ping message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_ping.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.serverMs != null && Object.hasOwnProperty.call(message, "serverMs"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.serverMs);
            return writer;
        };

        /**
         * Decodes a s2c_ping message from the specified reader or buffer.
         * @function decode
         * @memberof user.s2c_ping
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.s2c_ping} s2c_ping
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_ping.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.s2c_ping();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        message.serverMs = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_ping
         * @function getTypeUrl
         * @memberof user.s2c_ping
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_ping.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.s2c_ping";
        };

        return s2c_ping;
    })();

    user.c2s_gm = (function() {

        /**
         * Properties of a c2s_gm.
         * @memberof user
         * @interface Ic2s_gm
         * @property {string|null} [cmd] c2s_gm cmd
         */

        /**
         * Constructs a new c2s_gm.
         * @memberof user
         * @classdesc Represents a c2s_gm.
         * @implements Ic2s_gm
         * @constructor
         * @param {user.Ic2s_gm=} [properties] Properties to set
         */
        function c2s_gm(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_gm cmd.
         * @member {string} cmd
         * @memberof user.c2s_gm
         * @instance
         */
        c2s_gm.prototype.cmd = "";

        /**
         * Creates a new c2s_gm instance using the specified properties.
         * @function create
         * @memberof user.c2s_gm
         * @static
         * @param {user.Ic2s_gm=} [properties] Properties to set
         * @returns {user.c2s_gm} c2s_gm instance
         */
        c2s_gm.create = function create(properties) {
            return new c2s_gm(properties);
        };

        /**
         * Encodes the specified c2s_gm message. Does not implicitly {@link user.c2s_gm.verify|verify} messages.
         * @function encode
         * @memberof user.c2s_gm
         * @static
         * @param {user.c2s_gm} message c2s_gm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_gm.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cmd != null && Object.hasOwnProperty.call(message, "cmd"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.cmd);
            return writer;
        };

        /**
         * Decodes a c2s_gm message from the specified reader or buffer.
         * @function decode
         * @memberof user.c2s_gm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.c2s_gm} c2s_gm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_gm.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.c2s_gm();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.cmd = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_gm
         * @function getTypeUrl
         * @memberof user.c2s_gm
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_gm.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.c2s_gm";
        };

        return c2s_gm;
    })();

    user.s2c_gm = (function() {

        /**
         * Properties of a s2c_gm.
         * @memberof user
         * @interface Is2c_gm
         * @property {number|null} [err] s2c_gm err
         * @property {string|null} [msg] s2c_gm msg
         */

        /**
         * Constructs a new s2c_gm.
         * @memberof user
         * @classdesc Represents a s2c_gm.
         * @implements Is2c_gm
         * @constructor
         * @param {user.Is2c_gm=} [properties] Properties to set
         */
        function s2c_gm(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_gm err.
         * @member {number} err
         * @memberof user.s2c_gm
         * @instance
         */
        s2c_gm.prototype.err = 0;

        /**
         * s2c_gm msg.
         * @member {string} msg
         * @memberof user.s2c_gm
         * @instance
         */
        s2c_gm.prototype.msg = "";

        /**
         * Creates a new s2c_gm instance using the specified properties.
         * @function create
         * @memberof user.s2c_gm
         * @static
         * @param {user.Is2c_gm=} [properties] Properties to set
         * @returns {user.s2c_gm} s2c_gm instance
         */
        s2c_gm.create = function create(properties) {
            return new s2c_gm(properties);
        };

        /**
         * Encodes the specified s2c_gm message. Does not implicitly {@link user.s2c_gm.verify|verify} messages.
         * @function encode
         * @memberof user.s2c_gm
         * @static
         * @param {user.s2c_gm} message s2c_gm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_gm.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.msg != null && Object.hasOwnProperty.call(message, "msg"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.msg);
            return writer;
        };

        /**
         * Decodes a s2c_gm message from the specified reader or buffer.
         * @function decode
         * @memberof user.s2c_gm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.s2c_gm} s2c_gm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_gm.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.s2c_gm();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        message.msg = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_gm
         * @function getTypeUrl
         * @memberof user.s2c_gm
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_gm.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.s2c_gm";
        };

        return s2c_gm;
    })();

    user.c2s_rename = (function() {

        /**
         * Properties of a c2s_rename.
         * @memberof user
         * @interface Ic2s_rename
         * @property {string|null} [name] c2s_rename name
         */

        /**
         * Constructs a new c2s_rename.
         * @memberof user
         * @classdesc Represents a c2s_rename.
         * @implements Ic2s_rename
         * @constructor
         * @param {user.Ic2s_rename=} [properties] Properties to set
         */
        function c2s_rename(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_rename name.
         * @member {string} name
         * @memberof user.c2s_rename
         * @instance
         */
        c2s_rename.prototype.name = "";

        /**
         * Creates a new c2s_rename instance using the specified properties.
         * @function create
         * @memberof user.c2s_rename
         * @static
         * @param {user.Ic2s_rename=} [properties] Properties to set
         * @returns {user.c2s_rename} c2s_rename instance
         */
        c2s_rename.create = function create(properties) {
            return new c2s_rename(properties);
        };

        /**
         * Encodes the specified c2s_rename message. Does not implicitly {@link user.c2s_rename.verify|verify} messages.
         * @function encode
         * @memberof user.c2s_rename
         * @static
         * @param {user.c2s_rename} message c2s_rename message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_rename.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            return writer;
        };

        /**
         * Decodes a c2s_rename message from the specified reader or buffer.
         * @function decode
         * @memberof user.c2s_rename
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.c2s_rename} c2s_rename
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_rename.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.c2s_rename();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_rename
         * @function getTypeUrl
         * @memberof user.c2s_rename
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_rename.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.c2s_rename";
        };

        return c2s_rename;
    })();

    user.s2c_rename = (function() {

        /**
         * Properties of a s2c_rename.
         * @memberof user
         * @interface Is2c_rename
         * @property {number|null} [err] s2c_rename err
         */

        /**
         * Constructs a new s2c_rename.
         * @memberof user
         * @classdesc Represents a s2c_rename.
         * @implements Is2c_rename
         * @constructor
         * @param {user.Is2c_rename=} [properties] Properties to set
         */
        function s2c_rename(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_rename err.
         * @member {number} err
         * @memberof user.s2c_rename
         * @instance
         */
        s2c_rename.prototype.err = 0;

        /**
         * Creates a new s2c_rename instance using the specified properties.
         * @function create
         * @memberof user.s2c_rename
         * @static
         * @param {user.Is2c_rename=} [properties] Properties to set
         * @returns {user.s2c_rename} s2c_rename instance
         */
        s2c_rename.create = function create(properties) {
            return new s2c_rename(properties);
        };

        /**
         * Encodes the specified s2c_rename message. Does not implicitly {@link user.s2c_rename.verify|verify} messages.
         * @function encode
         * @memberof user.s2c_rename
         * @static
         * @param {user.s2c_rename} message s2c_rename message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_rename.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_rename message from the specified reader or buffer.
         * @function decode
         * @memberof user.s2c_rename
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.s2c_rename} s2c_rename
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_rename.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.s2c_rename();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_rename
         * @function getTypeUrl
         * @memberof user.s2c_rename
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_rename.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.s2c_rename";
        };

        return s2c_rename;
    })();

    user.PvpServer = (function() {

        /**
         * Properties of a PvpServer.
         * @memberof user
         * @interface IPvpServer
         * @property {string|null} [name] PvpServer name
         * @property {string|null} [desc] PvpServer desc
         */

        /**
         * Constructs a new PvpServer.
         * @memberof user
         * @classdesc Represents a PvpServer.
         * @implements IPvpServer
         * @constructor
         * @param {user.IPvpServer=} [properties] Properties to set
         */
        function PvpServer(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PvpServer name.
         * @member {string} name
         * @memberof user.PvpServer
         * @instance
         */
        PvpServer.prototype.name = "";

        /**
         * PvpServer desc.
         * @member {string} desc
         * @memberof user.PvpServer
         * @instance
         */
        PvpServer.prototype.desc = "";

        /**
         * Creates a new PvpServer instance using the specified properties.
         * @function create
         * @memberof user.PvpServer
         * @static
         * @param {user.IPvpServer=} [properties] Properties to set
         * @returns {user.PvpServer} PvpServer instance
         */
        PvpServer.create = function create(properties) {
            return new PvpServer(properties);
        };

        /**
         * Encodes the specified PvpServer message. Does not implicitly {@link user.PvpServer.verify|verify} messages.
         * @function encode
         * @memberof user.PvpServer
         * @static
         * @param {user.PvpServer} message PvpServer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PvpServer.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.desc != null && Object.hasOwnProperty.call(message, "desc"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.desc);
            return writer;
        };

        /**
         * Decodes a PvpServer message from the specified reader or buffer.
         * @function decode
         * @memberof user.PvpServer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.PvpServer} PvpServer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PvpServer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.PvpServer();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.desc = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for PvpServer
         * @function getTypeUrl
         * @memberof user.PvpServer
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PvpServer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.PvpServer";
        };

        return PvpServer;
    })();

    user.c2s_load_pvp_servers = (function() {

        /**
         * Properties of a c2s_load_pvp_servers.
         * @memberof user
         * @interface Ic2s_load_pvp_servers
         */

        /**
         * Constructs a new c2s_load_pvp_servers.
         * @memberof user
         * @classdesc Represents a c2s_load_pvp_servers.
         * @implements Ic2s_load_pvp_servers
         * @constructor
         * @param {user.Ic2s_load_pvp_servers=} [properties] Properties to set
         */
        function c2s_load_pvp_servers(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_load_pvp_servers instance using the specified properties.
         * @function create
         * @memberof user.c2s_load_pvp_servers
         * @static
         * @param {user.Ic2s_load_pvp_servers=} [properties] Properties to set
         * @returns {user.c2s_load_pvp_servers} c2s_load_pvp_servers instance
         */
        c2s_load_pvp_servers.create = function create(properties) {
            return new c2s_load_pvp_servers(properties);
        };

        /**
         * Encodes the specified c2s_load_pvp_servers message. Does not implicitly {@link user.c2s_load_pvp_servers.verify|verify} messages.
         * @function encode
         * @memberof user.c2s_load_pvp_servers
         * @static
         * @param {user.c2s_load_pvp_servers} message c2s_load_pvp_servers message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_load_pvp_servers.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_load_pvp_servers message from the specified reader or buffer.
         * @function decode
         * @memberof user.c2s_load_pvp_servers
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.c2s_load_pvp_servers} c2s_load_pvp_servers
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_load_pvp_servers.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.c2s_load_pvp_servers();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_load_pvp_servers
         * @function getTypeUrl
         * @memberof user.c2s_load_pvp_servers
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_load_pvp_servers.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.c2s_load_pvp_servers";
        };

        return c2s_load_pvp_servers;
    })();

    user.s2c_load_pvp_servers = (function() {

        /**
         * Properties of a s2c_load_pvp_servers.
         * @memberof user
         * @interface Is2c_load_pvp_servers
         * @property {number|null} [err] s2c_load_pvp_servers err
         * @property {Array.<user.PvpServer>|null} [servers] s2c_load_pvp_servers servers
         */

        /**
         * Constructs a new s2c_load_pvp_servers.
         * @memberof user
         * @classdesc Represents a s2c_load_pvp_servers.
         * @implements Is2c_load_pvp_servers
         * @constructor
         * @param {user.Is2c_load_pvp_servers=} [properties] Properties to set
         */
        function s2c_load_pvp_servers(properties) {
            this.servers = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_load_pvp_servers err.
         * @member {number} err
         * @memberof user.s2c_load_pvp_servers
         * @instance
         */
        s2c_load_pvp_servers.prototype.err = 0;

        /**
         * s2c_load_pvp_servers servers.
         * @member {Array.<user.PvpServer>} servers
         * @memberof user.s2c_load_pvp_servers
         * @instance
         */
        s2c_load_pvp_servers.prototype.servers = $util.emptyArray;

        /**
         * Creates a new s2c_load_pvp_servers instance using the specified properties.
         * @function create
         * @memberof user.s2c_load_pvp_servers
         * @static
         * @param {user.Is2c_load_pvp_servers=} [properties] Properties to set
         * @returns {user.s2c_load_pvp_servers} s2c_load_pvp_servers instance
         */
        s2c_load_pvp_servers.create = function create(properties) {
            return new s2c_load_pvp_servers(properties);
        };

        /**
         * Encodes the specified s2c_load_pvp_servers message. Does not implicitly {@link user.s2c_load_pvp_servers.verify|verify} messages.
         * @function encode
         * @memberof user.s2c_load_pvp_servers
         * @static
         * @param {user.s2c_load_pvp_servers} message s2c_load_pvp_servers message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_load_pvp_servers.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.servers != null && message.servers.length)
                for (let i = 0; i < message.servers.length; ++i)
                    $root.user.PvpServer.encode(message.servers[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_load_pvp_servers message from the specified reader or buffer.
         * @function decode
         * @memberof user.s2c_load_pvp_servers
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.s2c_load_pvp_servers} s2c_load_pvp_servers
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_load_pvp_servers.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.s2c_load_pvp_servers();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.servers && message.servers.length))
                            message.servers = [];
                        message.servers.push($root.user.PvpServer.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_load_pvp_servers
         * @function getTypeUrl
         * @memberof user.s2c_load_pvp_servers
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_load_pvp_servers.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.s2c_load_pvp_servers";
        };

        return s2c_load_pvp_servers;
    })();

    user.c2s_join_pvp_server = (function() {

        /**
         * Properties of a c2s_join_pvp_server.
         * @memberof user
         * @interface Ic2s_join_pvp_server
         * @property {string|null} [name] c2s_join_pvp_server name
         */

        /**
         * Constructs a new c2s_join_pvp_server.
         * @memberof user
         * @classdesc Represents a c2s_join_pvp_server.
         * @implements Ic2s_join_pvp_server
         * @constructor
         * @param {user.Ic2s_join_pvp_server=} [properties] Properties to set
         */
        function c2s_join_pvp_server(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_join_pvp_server name.
         * @member {string} name
         * @memberof user.c2s_join_pvp_server
         * @instance
         */
        c2s_join_pvp_server.prototype.name = "";

        /**
         * Creates a new c2s_join_pvp_server instance using the specified properties.
         * @function create
         * @memberof user.c2s_join_pvp_server
         * @static
         * @param {user.Ic2s_join_pvp_server=} [properties] Properties to set
         * @returns {user.c2s_join_pvp_server} c2s_join_pvp_server instance
         */
        c2s_join_pvp_server.create = function create(properties) {
            return new c2s_join_pvp_server(properties);
        };

        /**
         * Encodes the specified c2s_join_pvp_server message. Does not implicitly {@link user.c2s_join_pvp_server.verify|verify} messages.
         * @function encode
         * @memberof user.c2s_join_pvp_server
         * @static
         * @param {user.c2s_join_pvp_server} message c2s_join_pvp_server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_join_pvp_server.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            return writer;
        };

        /**
         * Decodes a c2s_join_pvp_server message from the specified reader or buffer.
         * @function decode
         * @memberof user.c2s_join_pvp_server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.c2s_join_pvp_server} c2s_join_pvp_server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_join_pvp_server.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.c2s_join_pvp_server();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_join_pvp_server
         * @function getTypeUrl
         * @memberof user.c2s_join_pvp_server
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_join_pvp_server.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.c2s_join_pvp_server";
        };

        return c2s_join_pvp_server;
    })();

    user.s2c_join_pvp_server = (function() {

        /**
         * Properties of a s2c_join_pvp_server.
         * @memberof user
         * @interface Is2c_join_pvp_server
         * @property {number|null} [err] s2c_join_pvp_server err
         * @property {string|null} [url] s2c_join_pvp_server url
         */

        /**
         * Constructs a new s2c_join_pvp_server.
         * @memberof user
         * @classdesc Represents a s2c_join_pvp_server.
         * @implements Is2c_join_pvp_server
         * @constructor
         * @param {user.Is2c_join_pvp_server=} [properties] Properties to set
         */
        function s2c_join_pvp_server(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_join_pvp_server err.
         * @member {number} err
         * @memberof user.s2c_join_pvp_server
         * @instance
         */
        s2c_join_pvp_server.prototype.err = 0;

        /**
         * s2c_join_pvp_server url.
         * @member {string} url
         * @memberof user.s2c_join_pvp_server
         * @instance
         */
        s2c_join_pvp_server.prototype.url = "";

        /**
         * Creates a new s2c_join_pvp_server instance using the specified properties.
         * @function create
         * @memberof user.s2c_join_pvp_server
         * @static
         * @param {user.Is2c_join_pvp_server=} [properties] Properties to set
         * @returns {user.s2c_join_pvp_server} s2c_join_pvp_server instance
         */
        s2c_join_pvp_server.create = function create(properties) {
            return new s2c_join_pvp_server(properties);
        };

        /**
         * Encodes the specified s2c_join_pvp_server message. Does not implicitly {@link user.s2c_join_pvp_server.verify|verify} messages.
         * @function encode
         * @memberof user.s2c_join_pvp_server
         * @static
         * @param {user.s2c_join_pvp_server} message s2c_join_pvp_server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_join_pvp_server.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
            return writer;
        };

        /**
         * Decodes a s2c_join_pvp_server message from the specified reader or buffer.
         * @function decode
         * @memberof user.s2c_join_pvp_server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.s2c_join_pvp_server} s2c_join_pvp_server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_join_pvp_server.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.s2c_join_pvp_server();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        message.url = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_join_pvp_server
         * @function getTypeUrl
         * @memberof user.s2c_join_pvp_server
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_join_pvp_server.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.s2c_join_pvp_server";
        };

        return s2c_join_pvp_server;
    })();

    return user;
})();

export const world = $root.world = (() => {

    /**
     * Namespace world.
     * @exports world
     * @namespace
     */
    const world = {};

    world.Role = (function() {

        /**
         * Properties of a Role.
         * @memberof world
         * @interface IRole
         * @property {number|null} [rid] Role rid
         * @property {string|null} [name] Role name
         * @property {number|null} [aid] Role aid
         */

        /**
         * Constructs a new Role.
         * @memberof world
         * @classdesc Represents a Role.
         * @implements IRole
         * @constructor
         * @param {world.IRole=} [properties] Properties to set
         */
        function Role(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Role rid.
         * @member {number} rid
         * @memberof world.Role
         * @instance
         */
        Role.prototype.rid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Role name.
         * @member {string} name
         * @memberof world.Role
         * @instance
         */
        Role.prototype.name = "";

        /**
         * Role aid.
         * @member {number} aid
         * @memberof world.Role
         * @instance
         */
        Role.prototype.aid = 0;

        /**
         * Creates a new Role instance using the specified properties.
         * @function create
         * @memberof world.Role
         * @static
         * @param {world.IRole=} [properties] Properties to set
         * @returns {world.Role} Role instance
         */
        Role.create = function create(properties) {
            return new Role(properties);
        };

        /**
         * Encodes the specified Role message. Does not implicitly {@link world.Role.verify|verify} messages.
         * @function encode
         * @memberof world.Role
         * @static
         * @param {world.Role} message Role message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Role.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rid != null && Object.hasOwnProperty.call(message, "rid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.rid);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.aid != null && Object.hasOwnProperty.call(message, "aid"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.aid);
            return writer;
        };

        /**
         * Decodes a Role message from the specified reader or buffer.
         * @function decode
         * @memberof world.Role
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.Role} Role
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Role.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.Role();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rid = reader.uint64();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.aid = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for Role
         * @function getTypeUrl
         * @memberof world.Role
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Role.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.Role";
        };

        return Role;
    })();

    world.Position = (function() {

        /**
         * Properties of a Position.
         * @memberof world
         * @interface IPosition
         * @property {number|null} [x] Position x
         * @property {number|null} [y] Position y
         */

        /**
         * Constructs a new Position.
         * @memberof world
         * @classdesc Represents a Position.
         * @implements IPosition
         * @constructor
         * @param {world.IPosition=} [properties] Properties to set
         */
        function Position(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Position x.
         * @member {number} x
         * @memberof world.Position
         * @instance
         */
        Position.prototype.x = 0;

        /**
         * Position y.
         * @member {number} y
         * @memberof world.Position
         * @instance
         */
        Position.prototype.y = 0;

        /**
         * Creates a new Position instance using the specified properties.
         * @function create
         * @memberof world.Position
         * @static
         * @param {world.IPosition=} [properties] Properties to set
         * @returns {world.Position} Position instance
         */
        Position.create = function create(properties) {
            return new Position(properties);
        };

        /**
         * Encodes the specified Position message. Does not implicitly {@link world.Position.verify|verify} messages.
         * @function encode
         * @memberof world.Position
         * @static
         * @param {world.Position} message Position message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Position.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message.x);
            if (message.y != null && Object.hasOwnProperty.call(message, "y"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.y);
            return writer;
        };

        /**
         * Decodes a Position message from the specified reader or buffer.
         * @function decode
         * @memberof world.Position
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.Position} Position
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Position.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.Position();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.x = reader.float();
                        break;
                    }
                case 2: {
                        message.y = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for Position
         * @function getTypeUrl
         * @memberof world.Position
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Position.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.Position";
        };

        return Position;
    })();

    world.Alliance = (function() {

        /**
         * Properties of an Alliance.
         * @memberof world
         * @interface IAlliance
         * @property {number|null} [aid] Alliance aid
         * @property {string|null} [name] Alliance name
         */

        /**
         * Constructs a new Alliance.
         * @memberof world
         * @classdesc Represents an Alliance.
         * @implements IAlliance
         * @constructor
         * @param {world.IAlliance=} [properties] Properties to set
         */
        function Alliance(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Alliance aid.
         * @member {number} aid
         * @memberof world.Alliance
         * @instance
         */
        Alliance.prototype.aid = 0;

        /**
         * Alliance name.
         * @member {string} name
         * @memberof world.Alliance
         * @instance
         */
        Alliance.prototype.name = "";

        /**
         * Creates a new Alliance instance using the specified properties.
         * @function create
         * @memberof world.Alliance
         * @static
         * @param {world.IAlliance=} [properties] Properties to set
         * @returns {world.Alliance} Alliance instance
         */
        Alliance.create = function create(properties) {
            return new Alliance(properties);
        };

        /**
         * Encodes the specified Alliance message. Does not implicitly {@link world.Alliance.verify|verify} messages.
         * @function encode
         * @memberof world.Alliance
         * @static
         * @param {world.Alliance} message Alliance message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Alliance.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.aid != null && Object.hasOwnProperty.call(message, "aid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.aid);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            return writer;
        };

        /**
         * Decodes an Alliance message from the specified reader or buffer.
         * @function decode
         * @memberof world.Alliance
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.Alliance} Alliance
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Alliance.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.Alliance();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.aid = reader.uint32();
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for Alliance
         * @function getTypeUrl
         * @memberof world.Alliance
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Alliance.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.Alliance";
        };

        return Alliance;
    })();

    world.TroopComponent = (function() {

        /**
         * Properties of a TroopComponent.
         * @memberof world
         * @interface ITroopComponent
         * @property {number|null} [heroId] TroopComponent heroId
         * @property {Array.<number>|null} [soldierIds] TroopComponent soldierIds
         * @property {number|null} [battleEid] TroopComponent battleEid
         * @property {number|null} [maxHp] TroopComponent maxHp
         * @property {number|null} [hp] TroopComponent hp
         */

        /**
         * Constructs a new TroopComponent.
         * @memberof world
         * @classdesc Represents a TroopComponent.
         * @implements ITroopComponent
         * @constructor
         * @param {world.ITroopComponent=} [properties] Properties to set
         */
        function TroopComponent(properties) {
            this.soldierIds = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TroopComponent heroId.
         * @member {number} heroId
         * @memberof world.TroopComponent
         * @instance
         */
        TroopComponent.prototype.heroId = 0;

        /**
         * TroopComponent soldierIds.
         * @member {Array.<number>} soldierIds
         * @memberof world.TroopComponent
         * @instance
         */
        TroopComponent.prototype.soldierIds = $util.emptyArray;

        /**
         * TroopComponent battleEid.
         * @member {number} battleEid
         * @memberof world.TroopComponent
         * @instance
         */
        TroopComponent.prototype.battleEid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * TroopComponent maxHp.
         * @member {number} maxHp
         * @memberof world.TroopComponent
         * @instance
         */
        TroopComponent.prototype.maxHp = 0;

        /**
         * TroopComponent hp.
         * @member {number} hp
         * @memberof world.TroopComponent
         * @instance
         */
        TroopComponent.prototype.hp = 0;

        /**
         * Creates a new TroopComponent instance using the specified properties.
         * @function create
         * @memberof world.TroopComponent
         * @static
         * @param {world.ITroopComponent=} [properties] Properties to set
         * @returns {world.TroopComponent} TroopComponent instance
         */
        TroopComponent.create = function create(properties) {
            return new TroopComponent(properties);
        };

        /**
         * Encodes the specified TroopComponent message. Does not implicitly {@link world.TroopComponent.verify|verify} messages.
         * @function encode
         * @memberof world.TroopComponent
         * @static
         * @param {world.TroopComponent} message TroopComponent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TroopComponent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.heroId != null && Object.hasOwnProperty.call(message, "heroId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.heroId);
            if (message.soldierIds != null && message.soldierIds.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (let i = 0; i < message.soldierIds.length; ++i)
                    writer.uint32(message.soldierIds[i]);
                writer.ldelim();
            }
            if (message.battleEid != null && Object.hasOwnProperty.call(message, "battleEid"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.battleEid);
            if (message.maxHp != null && Object.hasOwnProperty.call(message, "maxHp"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.maxHp);
            if (message.hp != null && Object.hasOwnProperty.call(message, "hp"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.hp);
            return writer;
        };

        /**
         * Decodes a TroopComponent message from the specified reader or buffer.
         * @function decode
         * @memberof world.TroopComponent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.TroopComponent} TroopComponent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TroopComponent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.TroopComponent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.heroId = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.soldierIds && message.soldierIds.length))
                            message.soldierIds = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.soldierIds.push(reader.uint32());
                        } else
                            message.soldierIds.push(reader.uint32());
                        break;
                    }
                case 3: {
                        message.battleEid = reader.uint64();
                        break;
                    }
                case 4: {
                        message.maxHp = reader.uint32();
                        break;
                    }
                case 5: {
                        message.hp = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for TroopComponent
         * @function getTypeUrl
         * @memberof world.TroopComponent
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TroopComponent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.TroopComponent";
        };

        return TroopComponent;
    })();

    world.OwnerComponent = (function() {

        /**
         * Properties of an OwnerComponent.
         * @memberof world
         * @interface IOwnerComponent
         * @property {number|null} [rid] OwnerComponent rid
         * @property {number|null} [aid] OwnerComponent aid
         * @property {number|null} [eid] OwnerComponent eid
         * @property {boolean|null} [isRobot] OwnerComponent isRobot
         * @property {string|null} [roleName] OwnerComponent roleName
         * @property {string|null} [allianceName] OwnerComponent allianceName
         * @property {number|null} [homeEid] OwnerComponent homeEid
         * @property {number|null} [insideEid] OwnerComponent insideEid
         */

        /**
         * Constructs a new OwnerComponent.
         * @memberof world
         * @classdesc Represents an OwnerComponent.
         * @implements IOwnerComponent
         * @constructor
         * @param {world.IOwnerComponent=} [properties] Properties to set
         */
        function OwnerComponent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OwnerComponent rid.
         * @member {number} rid
         * @memberof world.OwnerComponent
         * @instance
         */
        OwnerComponent.prototype.rid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * OwnerComponent aid.
         * @member {number} aid
         * @memberof world.OwnerComponent
         * @instance
         */
        OwnerComponent.prototype.aid = 0;

        /**
         * OwnerComponent eid.
         * @member {number} eid
         * @memberof world.OwnerComponent
         * @instance
         */
        OwnerComponent.prototype.eid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * OwnerComponent isRobot.
         * @member {boolean} isRobot
         * @memberof world.OwnerComponent
         * @instance
         */
        OwnerComponent.prototype.isRobot = false;

        /**
         * OwnerComponent roleName.
         * @member {string} roleName
         * @memberof world.OwnerComponent
         * @instance
         */
        OwnerComponent.prototype.roleName = "";

        /**
         * OwnerComponent allianceName.
         * @member {string} allianceName
         * @memberof world.OwnerComponent
         * @instance
         */
        OwnerComponent.prototype.allianceName = "";

        /**
         * OwnerComponent homeEid.
         * @member {number} homeEid
         * @memberof world.OwnerComponent
         * @instance
         */
        OwnerComponent.prototype.homeEid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * OwnerComponent insideEid.
         * @member {number} insideEid
         * @memberof world.OwnerComponent
         * @instance
         */
        OwnerComponent.prototype.insideEid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new OwnerComponent instance using the specified properties.
         * @function create
         * @memberof world.OwnerComponent
         * @static
         * @param {world.IOwnerComponent=} [properties] Properties to set
         * @returns {world.OwnerComponent} OwnerComponent instance
         */
        OwnerComponent.create = function create(properties) {
            return new OwnerComponent(properties);
        };

        /**
         * Encodes the specified OwnerComponent message. Does not implicitly {@link world.OwnerComponent.verify|verify} messages.
         * @function encode
         * @memberof world.OwnerComponent
         * @static
         * @param {world.OwnerComponent} message OwnerComponent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OwnerComponent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rid != null && Object.hasOwnProperty.call(message, "rid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.rid);
            if (message.aid != null && Object.hasOwnProperty.call(message, "aid"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.aid);
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.eid);
            if (message.isRobot != null && Object.hasOwnProperty.call(message, "isRobot"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isRobot);
            if (message.roleName != null && Object.hasOwnProperty.call(message, "roleName"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.roleName);
            if (message.allianceName != null && Object.hasOwnProperty.call(message, "allianceName"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.allianceName);
            if (message.homeEid != null && Object.hasOwnProperty.call(message, "homeEid"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.homeEid);
            if (message.insideEid != null && Object.hasOwnProperty.call(message, "insideEid"))
                writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.insideEid);
            return writer;
        };

        /**
         * Decodes an OwnerComponent message from the specified reader or buffer.
         * @function decode
         * @memberof world.OwnerComponent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.OwnerComponent} OwnerComponent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OwnerComponent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.OwnerComponent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rid = reader.uint64();
                        break;
                    }
                case 2: {
                        message.aid = reader.uint32();
                        break;
                    }
                case 3: {
                        message.eid = reader.uint64();
                        break;
                    }
                case 4: {
                        message.isRobot = reader.bool();
                        break;
                    }
                case 5: {
                        message.roleName = reader.string();
                        break;
                    }
                case 6: {
                        message.allianceName = reader.string();
                        break;
                    }
                case 7: {
                        message.homeEid = reader.uint64();
                        break;
                    }
                case 8: {
                        message.insideEid = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for OwnerComponent
         * @function getTypeUrl
         * @memberof world.OwnerComponent
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        OwnerComponent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.OwnerComponent";
        };

        return OwnerComponent;
    })();

    world.StateComponent = (function() {

        /**
         * Properties of a StateComponent.
         * @memberof world
         * @interface IStateComponent
         * @property {number|null} [state] StateComponent state
         * @property {number|null} [cmd] StateComponent cmd
         */

        /**
         * Constructs a new StateComponent.
         * @memberof world
         * @classdesc Represents a StateComponent.
         * @implements IStateComponent
         * @constructor
         * @param {world.IStateComponent=} [properties] Properties to set
         */
        function StateComponent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StateComponent state.
         * @member {number} state
         * @memberof world.StateComponent
         * @instance
         */
        StateComponent.prototype.state = 0;

        /**
         * StateComponent cmd.
         * @member {number} cmd
         * @memberof world.StateComponent
         * @instance
         */
        StateComponent.prototype.cmd = 0;

        /**
         * Creates a new StateComponent instance using the specified properties.
         * @function create
         * @memberof world.StateComponent
         * @static
         * @param {world.IStateComponent=} [properties] Properties to set
         * @returns {world.StateComponent} StateComponent instance
         */
        StateComponent.create = function create(properties) {
            return new StateComponent(properties);
        };

        /**
         * Encodes the specified StateComponent message. Does not implicitly {@link world.StateComponent.verify|verify} messages.
         * @function encode
         * @memberof world.StateComponent
         * @static
         * @param {world.StateComponent} message StateComponent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StateComponent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.state);
            if (message.cmd != null && Object.hasOwnProperty.call(message, "cmd"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.cmd);
            return writer;
        };

        /**
         * Decodes a StateComponent message from the specified reader or buffer.
         * @function decode
         * @memberof world.StateComponent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.StateComponent} StateComponent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StateComponent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.StateComponent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.state = reader.uint32();
                        break;
                    }
                case 2: {
                        message.cmd = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for StateComponent
         * @function getTypeUrl
         * @memberof world.StateComponent
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StateComponent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.StateComponent";
        };

        return StateComponent;
    })();

    world.Timer = (function() {

        /**
         * Properties of a Timer.
         * @memberof world
         * @interface ITimer
         * @property {number|null} [type] Timer type
         * @property {number|null} [startMs] Timer startMs
         * @property {number|null} [expireMs] Timer expireMs
         */

        /**
         * Constructs a new Timer.
         * @memberof world
         * @classdesc Represents a Timer.
         * @implements ITimer
         * @constructor
         * @param {world.ITimer=} [properties] Properties to set
         */
        function Timer(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Timer type.
         * @member {number} type
         * @memberof world.Timer
         * @instance
         */
        Timer.prototype.type = 0;

        /**
         * Timer startMs.
         * @member {number} startMs
         * @memberof world.Timer
         * @instance
         */
        Timer.prototype.startMs = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Timer expireMs.
         * @member {number} expireMs
         * @memberof world.Timer
         * @instance
         */
        Timer.prototype.expireMs = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new Timer instance using the specified properties.
         * @function create
         * @memberof world.Timer
         * @static
         * @param {world.ITimer=} [properties] Properties to set
         * @returns {world.Timer} Timer instance
         */
        Timer.create = function create(properties) {
            return new Timer(properties);
        };

        /**
         * Encodes the specified Timer message. Does not implicitly {@link world.Timer.verify|verify} messages.
         * @function encode
         * @memberof world.Timer
         * @static
         * @param {world.Timer} message Timer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Timer.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.type);
            if (message.startMs != null && Object.hasOwnProperty.call(message, "startMs"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.startMs);
            if (message.expireMs != null && Object.hasOwnProperty.call(message, "expireMs"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.expireMs);
            return writer;
        };

        /**
         * Decodes a Timer message from the specified reader or buffer.
         * @function decode
         * @memberof world.Timer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.Timer} Timer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Timer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.Timer();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.uint32();
                        break;
                    }
                case 2: {
                        message.startMs = reader.uint64();
                        break;
                    }
                case 3: {
                        message.expireMs = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for Timer
         * @function getTypeUrl
         * @memberof world.Timer
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Timer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.Timer";
        };

        return Timer;
    })();

    world.TimerComponent = (function() {

        /**
         * Properties of a TimerComponent.
         * @memberof world
         * @interface ITimerComponent
         * @property {Object.<string,world.Timer>|null} [timers] TimerComponent timers
         */

        /**
         * Constructs a new TimerComponent.
         * @memberof world
         * @classdesc Represents a TimerComponent.
         * @implements ITimerComponent
         * @constructor
         * @param {world.ITimerComponent=} [properties] Properties to set
         */
        function TimerComponent(properties) {
            this.timers = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TimerComponent timers.
         * @member {Object.<string,world.Timer>} timers
         * @memberof world.TimerComponent
         * @instance
         */
        TimerComponent.prototype.timers = $util.emptyObject;

        /**
         * Creates a new TimerComponent instance using the specified properties.
         * @function create
         * @memberof world.TimerComponent
         * @static
         * @param {world.ITimerComponent=} [properties] Properties to set
         * @returns {world.TimerComponent} TimerComponent instance
         */
        TimerComponent.create = function create(properties) {
            return new TimerComponent(properties);
        };

        /**
         * Encodes the specified TimerComponent message. Does not implicitly {@link world.TimerComponent.verify|verify} messages.
         * @function encode
         * @memberof world.TimerComponent
         * @static
         * @param {world.TimerComponent} message TimerComponent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimerComponent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.timers != null && Object.hasOwnProperty.call(message, "timers"))
                for (let keys = Object.keys(message.timers), i = 0; i < keys.length; ++i) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 0 =*/8).uint32(keys[i]);
                    $root.world.Timer.encode(message.timers[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                }
            return writer;
        };

        /**
         * Decodes a TimerComponent message from the specified reader or buffer.
         * @function decode
         * @memberof world.TimerComponent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.TimerComponent} TimerComponent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimerComponent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.TimerComponent(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (message.timers === $util.emptyObject)
                            message.timers = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = 0;
                        value = null;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.uint32();
                                break;
                            case 2:
                                value = $root.world.Timer.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.timers[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for TimerComponent
         * @function getTypeUrl
         * @memberof world.TimerComponent
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TimerComponent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.TimerComponent";
        };

        return TimerComponent;
    })();

    world.MoveComponent = (function() {

        /**
         * Properties of a MoveComponent.
         * @memberof world
         * @interface IMoveComponent
         * @property {number|null} [startMs] MoveComponent startMs
         * @property {Array.<world.Position>|null} [path] MoveComponent path
         * @property {number|null} [speed] MoveComponent speed
         */

        /**
         * Constructs a new MoveComponent.
         * @memberof world
         * @classdesc Represents a MoveComponent.
         * @implements IMoveComponent
         * @constructor
         * @param {world.IMoveComponent=} [properties] Properties to set
         */
        function MoveComponent(properties) {
            this.path = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MoveComponent startMs.
         * @member {number} startMs
         * @memberof world.MoveComponent
         * @instance
         */
        MoveComponent.prototype.startMs = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * MoveComponent path.
         * @member {Array.<world.Position>} path
         * @memberof world.MoveComponent
         * @instance
         */
        MoveComponent.prototype.path = $util.emptyArray;

        /**
         * MoveComponent speed.
         * @member {number} speed
         * @memberof world.MoveComponent
         * @instance
         */
        MoveComponent.prototype.speed = 0;

        /**
         * Creates a new MoveComponent instance using the specified properties.
         * @function create
         * @memberof world.MoveComponent
         * @static
         * @param {world.IMoveComponent=} [properties] Properties to set
         * @returns {world.MoveComponent} MoveComponent instance
         */
        MoveComponent.create = function create(properties) {
            return new MoveComponent(properties);
        };

        /**
         * Encodes the specified MoveComponent message. Does not implicitly {@link world.MoveComponent.verify|verify} messages.
         * @function encode
         * @memberof world.MoveComponent
         * @static
         * @param {world.MoveComponent} message MoveComponent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MoveComponent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.startMs != null && Object.hasOwnProperty.call(message, "startMs"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.startMs);
            if (message.path != null && message.path.length)
                for (let i = 0; i < message.path.length; ++i)
                    $root.world.Position.encode(message.path[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.speed != null && Object.hasOwnProperty.call(message, "speed"))
                writer.uint32(/* id 3, wireType 5 =*/29).float(message.speed);
            return writer;
        };

        /**
         * Decodes a MoveComponent message from the specified reader or buffer.
         * @function decode
         * @memberof world.MoveComponent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.MoveComponent} MoveComponent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MoveComponent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.MoveComponent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.startMs = reader.uint64();
                        break;
                    }
                case 2: {
                        if (!(message.path && message.path.length))
                            message.path = [];
                        message.path.push($root.world.Position.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.speed = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for MoveComponent
         * @function getTypeUrl
         * @memberof world.MoveComponent
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MoveComponent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.MoveComponent";
        };

        return MoveComponent;
    })();

    world.BuildingComponent = (function() {

        /**
         * Properties of a BuildingComponent.
         * @memberof world
         * @interface IBuildingComponent
         * @property {number|null} [id] BuildingComponent id
         * @property {number|null} [curHp] BuildingComponent curHp
         * @property {number|null} [maxHp] BuildingComponent maxHp
         * @property {number|null} [autoHpMs] BuildingComponent autoHpMs
         * @property {number|null} [autoHp] BuildingComponent autoHp
         * @property {number|null} [autoHpInr] BuildingComponent autoHpInr
         * @property {string|null} [name] BuildingComponent name
         */

        /**
         * Constructs a new BuildingComponent.
         * @memberof world
         * @classdesc Represents a BuildingComponent.
         * @implements IBuildingComponent
         * @constructor
         * @param {world.IBuildingComponent=} [properties] Properties to set
         */
        function BuildingComponent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BuildingComponent id.
         * @member {number} id
         * @memberof world.BuildingComponent
         * @instance
         */
        BuildingComponent.prototype.id = 0;

        /**
         * BuildingComponent curHp.
         * @member {number} curHp
         * @memberof world.BuildingComponent
         * @instance
         */
        BuildingComponent.prototype.curHp = 0;

        /**
         * BuildingComponent maxHp.
         * @member {number} maxHp
         * @memberof world.BuildingComponent
         * @instance
         */
        BuildingComponent.prototype.maxHp = 0;

        /**
         * BuildingComponent autoHpMs.
         * @member {number} autoHpMs
         * @memberof world.BuildingComponent
         * @instance
         */
        BuildingComponent.prototype.autoHpMs = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * BuildingComponent autoHp.
         * @member {number} autoHp
         * @memberof world.BuildingComponent
         * @instance
         */
        BuildingComponent.prototype.autoHp = 0;

        /**
         * BuildingComponent autoHpInr.
         * @member {number} autoHpInr
         * @memberof world.BuildingComponent
         * @instance
         */
        BuildingComponent.prototype.autoHpInr = 0;

        /**
         * BuildingComponent name.
         * @member {string} name
         * @memberof world.BuildingComponent
         * @instance
         */
        BuildingComponent.prototype.name = "";

        /**
         * Creates a new BuildingComponent instance using the specified properties.
         * @function create
         * @memberof world.BuildingComponent
         * @static
         * @param {world.IBuildingComponent=} [properties] Properties to set
         * @returns {world.BuildingComponent} BuildingComponent instance
         */
        BuildingComponent.create = function create(properties) {
            return new BuildingComponent(properties);
        };

        /**
         * Encodes the specified BuildingComponent message. Does not implicitly {@link world.BuildingComponent.verify|verify} messages.
         * @function encode
         * @memberof world.BuildingComponent
         * @static
         * @param {world.BuildingComponent} message BuildingComponent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BuildingComponent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            if (message.curHp != null && Object.hasOwnProperty.call(message, "curHp"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.curHp);
            if (message.maxHp != null && Object.hasOwnProperty.call(message, "maxHp"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.maxHp);
            if (message.autoHpMs != null && Object.hasOwnProperty.call(message, "autoHpMs"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.autoHpMs);
            if (message.autoHp != null && Object.hasOwnProperty.call(message, "autoHp"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.autoHp);
            if (message.autoHpInr != null && Object.hasOwnProperty.call(message, "autoHpInr"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.autoHpInr);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.name);
            return writer;
        };

        /**
         * Decodes a BuildingComponent message from the specified reader or buffer.
         * @function decode
         * @memberof world.BuildingComponent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.BuildingComponent} BuildingComponent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BuildingComponent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.BuildingComponent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 2: {
                        message.curHp = reader.uint32();
                        break;
                    }
                case 3: {
                        message.maxHp = reader.uint32();
                        break;
                    }
                case 4: {
                        message.autoHpMs = reader.uint64();
                        break;
                    }
                case 5: {
                        message.autoHp = reader.int32();
                        break;
                    }
                case 6: {
                        message.autoHpInr = reader.uint32();
                        break;
                    }
                case 9: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for BuildingComponent
         * @function getTypeUrl
         * @memberof world.BuildingComponent
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BuildingComponent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.BuildingComponent";
        };

        return BuildingComponent;
    })();

    world.BattleComponent = (function() {

        /**
         * Properties of a BattleComponent.
         * @memberof world
         * @interface IBattleComponent
         * @property {number|null} [sceneUid] BattleComponent sceneUid
         * @property {number|null} [startMs] BattleComponent startMs
         * @property {number|null} [fighterEid1] BattleComponent fighterEid1
         * @property {number|null} [fighterEid2] BattleComponent fighterEid2
         */

        /**
         * Constructs a new BattleComponent.
         * @memberof world
         * @classdesc Represents a BattleComponent.
         * @implements IBattleComponent
         * @constructor
         * @param {world.IBattleComponent=} [properties] Properties to set
         */
        function BattleComponent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BattleComponent sceneUid.
         * @member {number} sceneUid
         * @memberof world.BattleComponent
         * @instance
         */
        BattleComponent.prototype.sceneUid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * BattleComponent startMs.
         * @member {number} startMs
         * @memberof world.BattleComponent
         * @instance
         */
        BattleComponent.prototype.startMs = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * BattleComponent fighterEid1.
         * @member {number} fighterEid1
         * @memberof world.BattleComponent
         * @instance
         */
        BattleComponent.prototype.fighterEid1 = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * BattleComponent fighterEid2.
         * @member {number} fighterEid2
         * @memberof world.BattleComponent
         * @instance
         */
        BattleComponent.prototype.fighterEid2 = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new BattleComponent instance using the specified properties.
         * @function create
         * @memberof world.BattleComponent
         * @static
         * @param {world.IBattleComponent=} [properties] Properties to set
         * @returns {world.BattleComponent} BattleComponent instance
         */
        BattleComponent.create = function create(properties) {
            return new BattleComponent(properties);
        };

        /**
         * Encodes the specified BattleComponent message. Does not implicitly {@link world.BattleComponent.verify|verify} messages.
         * @function encode
         * @memberof world.BattleComponent
         * @static
         * @param {world.BattleComponent} message BattleComponent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BattleComponent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sceneUid != null && Object.hasOwnProperty.call(message, "sceneUid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.sceneUid);
            if (message.startMs != null && Object.hasOwnProperty.call(message, "startMs"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.startMs);
            if (message.fighterEid1 != null && Object.hasOwnProperty.call(message, "fighterEid1"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.fighterEid1);
            if (message.fighterEid2 != null && Object.hasOwnProperty.call(message, "fighterEid2"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.fighterEid2);
            return writer;
        };

        /**
         * Decodes a BattleComponent message from the specified reader or buffer.
         * @function decode
         * @memberof world.BattleComponent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.BattleComponent} BattleComponent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BattleComponent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.BattleComponent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sceneUid = reader.uint64();
                        break;
                    }
                case 2: {
                        message.startMs = reader.uint64();
                        break;
                    }
                case 3: {
                        message.fighterEid1 = reader.uint64();
                        break;
                    }
                case 4: {
                        message.fighterEid2 = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for BattleComponent
         * @function getTypeUrl
         * @memberof world.BattleComponent
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BattleComponent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.BattleComponent";
        };

        return BattleComponent;
    })();

    world.BagItem = (function() {

        /**
         * Properties of a BagItem.
         * @memberof world
         * @interface IBagItem
         * @property {number|null} [id] BagItem id
         * @property {number|null} [num] BagItem num
         */

        /**
         * Constructs a new BagItem.
         * @memberof world
         * @classdesc Represents a BagItem.
         * @implements IBagItem
         * @constructor
         * @param {world.IBagItem=} [properties] Properties to set
         */
        function BagItem(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BagItem id.
         * @member {number} id
         * @memberof world.BagItem
         * @instance
         */
        BagItem.prototype.id = 0;

        /**
         * BagItem num.
         * @member {number} num
         * @memberof world.BagItem
         * @instance
         */
        BagItem.prototype.num = 0;

        /**
         * Creates a new BagItem instance using the specified properties.
         * @function create
         * @memberof world.BagItem
         * @static
         * @param {world.IBagItem=} [properties] Properties to set
         * @returns {world.BagItem} BagItem instance
         */
        BagItem.create = function create(properties) {
            return new BagItem(properties);
        };

        /**
         * Encodes the specified BagItem message. Does not implicitly {@link world.BagItem.verify|verify} messages.
         * @function encode
         * @memberof world.BagItem
         * @static
         * @param {world.BagItem} message BagItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BagItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            if (message.num != null && Object.hasOwnProperty.call(message, "num"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.num);
            return writer;
        };

        /**
         * Decodes a BagItem message from the specified reader or buffer.
         * @function decode
         * @memberof world.BagItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.BagItem} BagItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BagItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.BagItem();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 2: {
                        message.num = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for BagItem
         * @function getTypeUrl
         * @memberof world.BagItem
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BagItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.BagItem";
        };

        return BagItem;
    })();

    world.ItemComponent = (function() {

        /**
         * Properties of an ItemComponent.
         * @memberof world
         * @interface IItemComponent
         * @property {number|null} [id] ItemComponent id
         * @property {number|null} [num] ItemComponent num
         */

        /**
         * Constructs a new ItemComponent.
         * @memberof world
         * @classdesc Represents an ItemComponent.
         * @implements IItemComponent
         * @constructor
         * @param {world.IItemComponent=} [properties] Properties to set
         */
        function ItemComponent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ItemComponent id.
         * @member {number} id
         * @memberof world.ItemComponent
         * @instance
         */
        ItemComponent.prototype.id = 0;

        /**
         * ItemComponent num.
         * @member {number} num
         * @memberof world.ItemComponent
         * @instance
         */
        ItemComponent.prototype.num = 0;

        /**
         * Creates a new ItemComponent instance using the specified properties.
         * @function create
         * @memberof world.ItemComponent
         * @static
         * @param {world.IItemComponent=} [properties] Properties to set
         * @returns {world.ItemComponent} ItemComponent instance
         */
        ItemComponent.create = function create(properties) {
            return new ItemComponent(properties);
        };

        /**
         * Encodes the specified ItemComponent message. Does not implicitly {@link world.ItemComponent.verify|verify} messages.
         * @function encode
         * @memberof world.ItemComponent
         * @static
         * @param {world.ItemComponent} message ItemComponent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ItemComponent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            if (message.num != null && Object.hasOwnProperty.call(message, "num"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.num);
            return writer;
        };

        /**
         * Decodes an ItemComponent message from the specified reader or buffer.
         * @function decode
         * @memberof world.ItemComponent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.ItemComponent} ItemComponent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ItemComponent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.ItemComponent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 2: {
                        message.num = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for ItemComponent
         * @function getTypeUrl
         * @memberof world.ItemComponent
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ItemComponent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.ItemComponent";
        };

        return ItemComponent;
    })();

    world.GarrisonComponent = (function() {

        /**
         * Properties of a GarrisonComponent.
         * @memberof world
         * @interface IGarrisonComponent
         * @property {number|null} [cdStartMs] GarrisonComponent cdStartMs
         * @property {Array.<number>|null} [eids] GarrisonComponent eids
         */

        /**
         * Constructs a new GarrisonComponent.
         * @memberof world
         * @classdesc Represents a GarrisonComponent.
         * @implements IGarrisonComponent
         * @constructor
         * @param {world.IGarrisonComponent=} [properties] Properties to set
         */
        function GarrisonComponent(properties) {
            this.eids = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GarrisonComponent cdStartMs.
         * @member {number} cdStartMs
         * @memberof world.GarrisonComponent
         * @instance
         */
        GarrisonComponent.prototype.cdStartMs = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * GarrisonComponent eids.
         * @member {Array.<number>} eids
         * @memberof world.GarrisonComponent
         * @instance
         */
        GarrisonComponent.prototype.eids = $util.emptyArray;

        /**
         * Creates a new GarrisonComponent instance using the specified properties.
         * @function create
         * @memberof world.GarrisonComponent
         * @static
         * @param {world.IGarrisonComponent=} [properties] Properties to set
         * @returns {world.GarrisonComponent} GarrisonComponent instance
         */
        GarrisonComponent.create = function create(properties) {
            return new GarrisonComponent(properties);
        };

        /**
         * Encodes the specified GarrisonComponent message. Does not implicitly {@link world.GarrisonComponent.verify|verify} messages.
         * @function encode
         * @memberof world.GarrisonComponent
         * @static
         * @param {world.GarrisonComponent} message GarrisonComponent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GarrisonComponent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cdStartMs != null && Object.hasOwnProperty.call(message, "cdStartMs"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.cdStartMs);
            if (message.eids != null && message.eids.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (let i = 0; i < message.eids.length; ++i)
                    writer.uint64(message.eids[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a GarrisonComponent message from the specified reader or buffer.
         * @function decode
         * @memberof world.GarrisonComponent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.GarrisonComponent} GarrisonComponent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GarrisonComponent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.GarrisonComponent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.cdStartMs = reader.uint64();
                        break;
                    }
                case 2: {
                        if (!(message.eids && message.eids.length))
                            message.eids = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.eids.push(reader.uint64());
                        } else
                            message.eids.push(reader.uint64());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for GarrisonComponent
         * @function getTypeUrl
         * @memberof world.GarrisonComponent
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GarrisonComponent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.GarrisonComponent";
        };

        return GarrisonComponent;
    })();

    world.WagonComponent = (function() {

        /**
         * Properties of a WagonComponent.
         * @memberof world
         * @interface IWagonComponent
         * @property {number|null} [id] WagonComponent id
         */

        /**
         * Constructs a new WagonComponent.
         * @memberof world
         * @classdesc Represents a WagonComponent.
         * @implements IWagonComponent
         * @constructor
         * @param {world.IWagonComponent=} [properties] Properties to set
         */
        function WagonComponent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WagonComponent id.
         * @member {number} id
         * @memberof world.WagonComponent
         * @instance
         */
        WagonComponent.prototype.id = 0;

        /**
         * Creates a new WagonComponent instance using the specified properties.
         * @function create
         * @memberof world.WagonComponent
         * @static
         * @param {world.IWagonComponent=} [properties] Properties to set
         * @returns {world.WagonComponent} WagonComponent instance
         */
        WagonComponent.create = function create(properties) {
            return new WagonComponent(properties);
        };

        /**
         * Encodes the specified WagonComponent message. Does not implicitly {@link world.WagonComponent.verify|verify} messages.
         * @function encode
         * @memberof world.WagonComponent
         * @static
         * @param {world.WagonComponent} message WagonComponent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WagonComponent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            return writer;
        };

        /**
         * Decodes a WagonComponent message from the specified reader or buffer.
         * @function decode
         * @memberof world.WagonComponent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.WagonComponent} WagonComponent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WagonComponent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.WagonComponent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for WagonComponent
         * @function getTypeUrl
         * @memberof world.WagonComponent
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WagonComponent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.WagonComponent";
        };

        return WagonComponent;
    })();

    world.Entity = (function() {

        /**
         * Properties of an Entity.
         * @memberof world
         * @interface IEntity
         * @property {number|null} [eid] Entity eid
         * @property {number|null} [etype] Entity etype
         * @property {world.Position|null} [pos] Entity pos
         * @property {world.TimerComponent|null} [timer] Entity timer
         * @property {world.MoveComponent|null} [move] Entity move
         * @property {world.BuildingComponent|null} [building] Entity building
         * @property {world.OwnerComponent|null} [owner] Entity owner
         * @property {world.TroopComponent|null} [troop] Entity troop
         * @property {world.BattleComponent|null} [battle] Entity battle
         * @property {world.ItemComponent|null} [item] Entity item
         * @property {world.GarrisonComponent|null} [garrison] Entity garrison
         * @property {world.WagonComponent|null} [wagon] Entity wagon
         * @property {world.StateComponent|null} [state] Entity state
         */

        /**
         * Constructs a new Entity.
         * @memberof world
         * @classdesc Represents an Entity.
         * @implements IEntity
         * @constructor
         * @param {world.IEntity=} [properties] Properties to set
         */
        function Entity(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Entity eid.
         * @member {number} eid
         * @memberof world.Entity
         * @instance
         */
        Entity.prototype.eid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Entity etype.
         * @member {number} etype
         * @memberof world.Entity
         * @instance
         */
        Entity.prototype.etype = 0;

        /**
         * Entity pos.
         * @member {world.Position|null|undefined} pos
         * @memberof world.Entity
         * @instance
         */
        Entity.prototype.pos = null;

        /**
         * Entity timer.
         * @member {world.TimerComponent|null|undefined} timer
         * @memberof world.Entity
         * @instance
         */
        Entity.prototype.timer = null;

        /**
         * Entity move.
         * @member {world.MoveComponent|null|undefined} move
         * @memberof world.Entity
         * @instance
         */
        Entity.prototype.move = null;

        /**
         * Entity building.
         * @member {world.BuildingComponent|null|undefined} building
         * @memberof world.Entity
         * @instance
         */
        Entity.prototype.building = null;

        /**
         * Entity owner.
         * @member {world.OwnerComponent|null|undefined} owner
         * @memberof world.Entity
         * @instance
         */
        Entity.prototype.owner = null;

        /**
         * Entity troop.
         * @member {world.TroopComponent|null|undefined} troop
         * @memberof world.Entity
         * @instance
         */
        Entity.prototype.troop = null;

        /**
         * Entity battle.
         * @member {world.BattleComponent|null|undefined} battle
         * @memberof world.Entity
         * @instance
         */
        Entity.prototype.battle = null;

        /**
         * Entity item.
         * @member {world.ItemComponent|null|undefined} item
         * @memberof world.Entity
         * @instance
         */
        Entity.prototype.item = null;

        /**
         * Entity garrison.
         * @member {world.GarrisonComponent|null|undefined} garrison
         * @memberof world.Entity
         * @instance
         */
        Entity.prototype.garrison = null;

        /**
         * Entity wagon.
         * @member {world.WagonComponent|null|undefined} wagon
         * @memberof world.Entity
         * @instance
         */
        Entity.prototype.wagon = null;

        /**
         * Entity state.
         * @member {world.StateComponent|null|undefined} state
         * @memberof world.Entity
         * @instance
         */
        Entity.prototype.state = null;

        /**
         * Creates a new Entity instance using the specified properties.
         * @function create
         * @memberof world.Entity
         * @static
         * @param {world.IEntity=} [properties] Properties to set
         * @returns {world.Entity} Entity instance
         */
        Entity.create = function create(properties) {
            return new Entity(properties);
        };

        /**
         * Encodes the specified Entity message. Does not implicitly {@link world.Entity.verify|verify} messages.
         * @function encode
         * @memberof world.Entity
         * @static
         * @param {world.Entity} message Entity message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Entity.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.eid);
            if (message.etype != null && Object.hasOwnProperty.call(message, "etype"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.etype);
            if (message.pos != null && Object.hasOwnProperty.call(message, "pos"))
                $root.world.Position.encode(message.pos, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.timer != null && Object.hasOwnProperty.call(message, "timer"))
                $root.world.TimerComponent.encode(message.timer, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.move != null && Object.hasOwnProperty.call(message, "move"))
                $root.world.MoveComponent.encode(message.move, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.building != null && Object.hasOwnProperty.call(message, "building"))
                $root.world.BuildingComponent.encode(message.building, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.owner != null && Object.hasOwnProperty.call(message, "owner"))
                $root.world.OwnerComponent.encode(message.owner, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.troop != null && Object.hasOwnProperty.call(message, "troop"))
                $root.world.TroopComponent.encode(message.troop, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.battle != null && Object.hasOwnProperty.call(message, "battle"))
                $root.world.BattleComponent.encode(message.battle, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.item != null && Object.hasOwnProperty.call(message, "item"))
                $root.world.ItemComponent.encode(message.item, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.garrison != null && Object.hasOwnProperty.call(message, "garrison"))
                $root.world.GarrisonComponent.encode(message.garrison, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.wagon != null && Object.hasOwnProperty.call(message, "wagon"))
                $root.world.WagonComponent.encode(message.wagon, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                $root.world.StateComponent.encode(message.state, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an Entity message from the specified reader or buffer.
         * @function decode
         * @memberof world.Entity
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.Entity} Entity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Entity.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.Entity();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.eid = reader.uint64();
                        break;
                    }
                case 2: {
                        message.etype = reader.uint32();
                        break;
                    }
                case 3: {
                        message.pos = $root.world.Position.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.timer = $root.world.TimerComponent.decode(reader, reader.uint32());
                        break;
                    }
                case 13: {
                        message.move = $root.world.MoveComponent.decode(reader, reader.uint32());
                        break;
                    }
                case 14: {
                        message.building = $root.world.BuildingComponent.decode(reader, reader.uint32());
                        break;
                    }
                case 15: {
                        message.owner = $root.world.OwnerComponent.decode(reader, reader.uint32());
                        break;
                    }
                case 16: {
                        message.troop = $root.world.TroopComponent.decode(reader, reader.uint32());
                        break;
                    }
                case 17: {
                        message.battle = $root.world.BattleComponent.decode(reader, reader.uint32());
                        break;
                    }
                case 20: {
                        message.item = $root.world.ItemComponent.decode(reader, reader.uint32());
                        break;
                    }
                case 21: {
                        message.garrison = $root.world.GarrisonComponent.decode(reader, reader.uint32());
                        break;
                    }
                case 22: {
                        message.wagon = $root.world.WagonComponent.decode(reader, reader.uint32());
                        break;
                    }
                case 23: {
                        message.state = $root.world.StateComponent.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for Entity
         * @function getTypeUrl
         * @memberof world.Entity
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Entity.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.Entity";
        };

        return Entity;
    })();

    world.c2s_load = (function() {

        /**
         * Properties of a c2s_load.
         * @memberof world
         * @interface Ic2s_load
         * @property {number|null} [worldUid] c2s_load worldUid
         */

        /**
         * Constructs a new c2s_load.
         * @memberof world
         * @classdesc Represents a c2s_load.
         * @implements Ic2s_load
         * @constructor
         * @param {world.Ic2s_load=} [properties] Properties to set
         */
        function c2s_load(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_load worldUid.
         * @member {number} worldUid
         * @memberof world.c2s_load
         * @instance
         */
        c2s_load.prototype.worldUid = 0;

        /**
         * Creates a new c2s_load instance using the specified properties.
         * @function create
         * @memberof world.c2s_load
         * @static
         * @param {world.Ic2s_load=} [properties] Properties to set
         * @returns {world.c2s_load} c2s_load instance
         */
        c2s_load.create = function create(properties) {
            return new c2s_load(properties);
        };

        /**
         * Encodes the specified c2s_load message. Does not implicitly {@link world.c2s_load.verify|verify} messages.
         * @function encode
         * @memberof world.c2s_load
         * @static
         * @param {world.c2s_load} message c2s_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.worldUid != null && Object.hasOwnProperty.call(message, "worldUid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.worldUid);
            return writer;
        };

        /**
         * Decodes a c2s_load message from the specified reader or buffer.
         * @function decode
         * @memberof world.c2s_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.c2s_load} c2s_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.c2s_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.worldUid = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_load
         * @function getTypeUrl
         * @memberof world.c2s_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.c2s_load";
        };

        return c2s_load;
    })();

    world.s2c_load = (function() {

        /**
         * Properties of a s2c_load.
         * @memberof world
         * @interface Is2c_load
         * @property {number|null} [err] s2c_load err
         * @property {number|null} [mapId] s2c_load mapId
         * @property {number|null} [homeEid] s2c_load homeEid
         * @property {world.Position|null} [homePos] s2c_load homePos
         */

        /**
         * Constructs a new s2c_load.
         * @memberof world
         * @classdesc Represents a s2c_load.
         * @implements Is2c_load
         * @constructor
         * @param {world.Is2c_load=} [properties] Properties to set
         */
        function s2c_load(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_load err.
         * @member {number} err
         * @memberof world.s2c_load
         * @instance
         */
        s2c_load.prototype.err = 0;

        /**
         * s2c_load mapId.
         * @member {number} mapId
         * @memberof world.s2c_load
         * @instance
         */
        s2c_load.prototype.mapId = 0;

        /**
         * s2c_load homeEid.
         * @member {number} homeEid
         * @memberof world.s2c_load
         * @instance
         */
        s2c_load.prototype.homeEid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * s2c_load homePos.
         * @member {world.Position|null|undefined} homePos
         * @memberof world.s2c_load
         * @instance
         */
        s2c_load.prototype.homePos = null;

        /**
         * Creates a new s2c_load instance using the specified properties.
         * @function create
         * @memberof world.s2c_load
         * @static
         * @param {world.Is2c_load=} [properties] Properties to set
         * @returns {world.s2c_load} s2c_load instance
         */
        s2c_load.create = function create(properties) {
            return new s2c_load(properties);
        };

        /**
         * Encodes the specified s2c_load message. Does not implicitly {@link world.s2c_load.verify|verify} messages.
         * @function encode
         * @memberof world.s2c_load
         * @static
         * @param {world.s2c_load} message s2c_load message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_load.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.mapId != null && Object.hasOwnProperty.call(message, "mapId"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.mapId);
            if (message.homeEid != null && Object.hasOwnProperty.call(message, "homeEid"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.homeEid);
            if (message.homePos != null && Object.hasOwnProperty.call(message, "homePos"))
                $root.world.Position.encode(message.homePos, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a s2c_load message from the specified reader or buffer.
         * @function decode
         * @memberof world.s2c_load
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.s2c_load} s2c_load
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_load.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.s2c_load();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        message.mapId = reader.uint32();
                        break;
                    }
                case 3: {
                        message.homeEid = reader.uint64();
                        break;
                    }
                case 4: {
                        message.homePos = $root.world.Position.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_load
         * @function getTypeUrl
         * @memberof world.s2c_load
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_load.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.s2c_load";
        };

        return s2c_load;
    })();

    world.c2s_change_viewport = (function() {

        /**
         * Properties of a c2s_change_viewport.
         * @memberof world
         * @interface Ic2s_change_viewport
         * @property {number|null} [worldUid] c2s_change_viewport worldUid
         * @property {world.Position|null} [pos] c2s_change_viewport pos
         */

        /**
         * Constructs a new c2s_change_viewport.
         * @memberof world
         * @classdesc Represents a c2s_change_viewport.
         * @implements Ic2s_change_viewport
         * @constructor
         * @param {world.Ic2s_change_viewport=} [properties] Properties to set
         */
        function c2s_change_viewport(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_change_viewport worldUid.
         * @member {number} worldUid
         * @memberof world.c2s_change_viewport
         * @instance
         */
        c2s_change_viewport.prototype.worldUid = 0;

        /**
         * c2s_change_viewport pos.
         * @member {world.Position|null|undefined} pos
         * @memberof world.c2s_change_viewport
         * @instance
         */
        c2s_change_viewport.prototype.pos = null;

        /**
         * Creates a new c2s_change_viewport instance using the specified properties.
         * @function create
         * @memberof world.c2s_change_viewport
         * @static
         * @param {world.Ic2s_change_viewport=} [properties] Properties to set
         * @returns {world.c2s_change_viewport} c2s_change_viewport instance
         */
        c2s_change_viewport.create = function create(properties) {
            return new c2s_change_viewport(properties);
        };

        /**
         * Encodes the specified c2s_change_viewport message. Does not implicitly {@link world.c2s_change_viewport.verify|verify} messages.
         * @function encode
         * @memberof world.c2s_change_viewport
         * @static
         * @param {world.c2s_change_viewport} message c2s_change_viewport message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_change_viewport.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.worldUid != null && Object.hasOwnProperty.call(message, "worldUid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.worldUid);
            if (message.pos != null && Object.hasOwnProperty.call(message, "pos"))
                $root.world.Position.encode(message.pos, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a c2s_change_viewport message from the specified reader or buffer.
         * @function decode
         * @memberof world.c2s_change_viewport
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.c2s_change_viewport} c2s_change_viewport
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_change_viewport.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.c2s_change_viewport();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.worldUid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.pos = $root.world.Position.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_change_viewport
         * @function getTypeUrl
         * @memberof world.c2s_change_viewport
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_change_viewport.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.c2s_change_viewport";
        };

        return c2s_change_viewport;
    })();

    world.s2c_change_viewport = (function() {

        /**
         * Properties of a s2c_change_viewport.
         * @memberof world
         * @interface Is2c_change_viewport
         * @property {number|null} [err] s2c_change_viewport err
         */

        /**
         * Constructs a new s2c_change_viewport.
         * @memberof world
         * @classdesc Represents a s2c_change_viewport.
         * @implements Is2c_change_viewport
         * @constructor
         * @param {world.Is2c_change_viewport=} [properties] Properties to set
         */
        function s2c_change_viewport(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_change_viewport err.
         * @member {number} err
         * @memberof world.s2c_change_viewport
         * @instance
         */
        s2c_change_viewport.prototype.err = 0;

        /**
         * Creates a new s2c_change_viewport instance using the specified properties.
         * @function create
         * @memberof world.s2c_change_viewport
         * @static
         * @param {world.Is2c_change_viewport=} [properties] Properties to set
         * @returns {world.s2c_change_viewport} s2c_change_viewport instance
         */
        s2c_change_viewport.create = function create(properties) {
            return new s2c_change_viewport(properties);
        };

        /**
         * Encodes the specified s2c_change_viewport message. Does not implicitly {@link world.s2c_change_viewport.verify|verify} messages.
         * @function encode
         * @memberof world.s2c_change_viewport
         * @static
         * @param {world.s2c_change_viewport} message s2c_change_viewport message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_change_viewport.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_change_viewport message from the specified reader or buffer.
         * @function decode
         * @memberof world.s2c_change_viewport
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.s2c_change_viewport} s2c_change_viewport
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_change_viewport.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.s2c_change_viewport();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_change_viewport
         * @function getTypeUrl
         * @memberof world.s2c_change_viewport
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_change_viewport.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.s2c_change_viewport";
        };

        return s2c_change_viewport;
    })();

    world.c2s_close_viewport = (function() {

        /**
         * Properties of a c2s_close_viewport.
         * @memberof world
         * @interface Ic2s_close_viewport
         */

        /**
         * Constructs a new c2s_close_viewport.
         * @memberof world
         * @classdesc Represents a c2s_close_viewport.
         * @implements Ic2s_close_viewport
         * @constructor
         * @param {world.Ic2s_close_viewport=} [properties] Properties to set
         */
        function c2s_close_viewport(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new c2s_close_viewport instance using the specified properties.
         * @function create
         * @memberof world.c2s_close_viewport
         * @static
         * @param {world.Ic2s_close_viewport=} [properties] Properties to set
         * @returns {world.c2s_close_viewport} c2s_close_viewport instance
         */
        c2s_close_viewport.create = function create(properties) {
            return new c2s_close_viewport(properties);
        };

        /**
         * Encodes the specified c2s_close_viewport message. Does not implicitly {@link world.c2s_close_viewport.verify|verify} messages.
         * @function encode
         * @memberof world.c2s_close_viewport
         * @static
         * @param {world.c2s_close_viewport} message c2s_close_viewport message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_close_viewport.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a c2s_close_viewport message from the specified reader or buffer.
         * @function decode
         * @memberof world.c2s_close_viewport
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.c2s_close_viewport} c2s_close_viewport
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_close_viewport.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.c2s_close_viewport();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_close_viewport
         * @function getTypeUrl
         * @memberof world.c2s_close_viewport
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_close_viewport.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.c2s_close_viewport";
        };

        return c2s_close_viewport;
    })();

    world.s2c_close_viewport = (function() {

        /**
         * Properties of a s2c_close_viewport.
         * @memberof world
         * @interface Is2c_close_viewport
         * @property {number|null} [err] s2c_close_viewport err
         */

        /**
         * Constructs a new s2c_close_viewport.
         * @memberof world
         * @classdesc Represents a s2c_close_viewport.
         * @implements Is2c_close_viewport
         * @constructor
         * @param {world.Is2c_close_viewport=} [properties] Properties to set
         */
        function s2c_close_viewport(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_close_viewport err.
         * @member {number} err
         * @memberof world.s2c_close_viewport
         * @instance
         */
        s2c_close_viewport.prototype.err = 0;

        /**
         * Creates a new s2c_close_viewport instance using the specified properties.
         * @function create
         * @memberof world.s2c_close_viewport
         * @static
         * @param {world.Is2c_close_viewport=} [properties] Properties to set
         * @returns {world.s2c_close_viewport} s2c_close_viewport instance
         */
        s2c_close_viewport.create = function create(properties) {
            return new s2c_close_viewport(properties);
        };

        /**
         * Encodes the specified s2c_close_viewport message. Does not implicitly {@link world.s2c_close_viewport.verify|verify} messages.
         * @function encode
         * @memberof world.s2c_close_viewport
         * @static
         * @param {world.s2c_close_viewport} message s2c_close_viewport message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_close_viewport.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_close_viewport message from the specified reader or buffer.
         * @function decode
         * @memberof world.s2c_close_viewport
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.s2c_close_viewport} s2c_close_viewport
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_close_viewport.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.s2c_close_viewport();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_close_viewport
         * @function getTypeUrl
         * @memberof world.s2c_close_viewport
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_close_viewport.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.s2c_close_viewport";
        };

        return s2c_close_viewport;
    })();

    world.c2s_giveup = (function() {

        /**
         * Properties of a c2s_giveup.
         * @memberof world
         * @interface Ic2s_giveup
         * @property {number|null} [eid] c2s_giveup eid
         */

        /**
         * Constructs a new c2s_giveup.
         * @memberof world
         * @classdesc Represents a c2s_giveup.
         * @implements Ic2s_giveup
         * @constructor
         * @param {world.Ic2s_giveup=} [properties] Properties to set
         */
        function c2s_giveup(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_giveup eid.
         * @member {number} eid
         * @memberof world.c2s_giveup
         * @instance
         */
        c2s_giveup.prototype.eid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new c2s_giveup instance using the specified properties.
         * @function create
         * @memberof world.c2s_giveup
         * @static
         * @param {world.Ic2s_giveup=} [properties] Properties to set
         * @returns {world.c2s_giveup} c2s_giveup instance
         */
        c2s_giveup.create = function create(properties) {
            return new c2s_giveup(properties);
        };

        /**
         * Encodes the specified c2s_giveup message. Does not implicitly {@link world.c2s_giveup.verify|verify} messages.
         * @function encode
         * @memberof world.c2s_giveup
         * @static
         * @param {world.c2s_giveup} message c2s_giveup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_giveup.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.eid);
            return writer;
        };

        /**
         * Decodes a c2s_giveup message from the specified reader or buffer.
         * @function decode
         * @memberof world.c2s_giveup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.c2s_giveup} c2s_giveup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_giveup.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.c2s_giveup();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.eid = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_giveup
         * @function getTypeUrl
         * @memberof world.c2s_giveup
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_giveup.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.c2s_giveup";
        };

        return c2s_giveup;
    })();

    world.s2c_giveup = (function() {

        /**
         * Properties of a s2c_giveup.
         * @memberof world
         * @interface Is2c_giveup
         * @property {number|null} [err] s2c_giveup err
         */

        /**
         * Constructs a new s2c_giveup.
         * @memberof world
         * @classdesc Represents a s2c_giveup.
         * @implements Is2c_giveup
         * @constructor
         * @param {world.Is2c_giveup=} [properties] Properties to set
         */
        function s2c_giveup(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_giveup err.
         * @member {number} err
         * @memberof world.s2c_giveup
         * @instance
         */
        s2c_giveup.prototype.err = 0;

        /**
         * Creates a new s2c_giveup instance using the specified properties.
         * @function create
         * @memberof world.s2c_giveup
         * @static
         * @param {world.Is2c_giveup=} [properties] Properties to set
         * @returns {world.s2c_giveup} s2c_giveup instance
         */
        s2c_giveup.create = function create(properties) {
            return new s2c_giveup(properties);
        };

        /**
         * Encodes the specified s2c_giveup message. Does not implicitly {@link world.s2c_giveup.verify|verify} messages.
         * @function encode
         * @memberof world.s2c_giveup
         * @static
         * @param {world.s2c_giveup} message s2c_giveup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_giveup.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_giveup message from the specified reader or buffer.
         * @function decode
         * @memberof world.s2c_giveup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.s2c_giveup} s2c_giveup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_giveup.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.s2c_giveup();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_giveup
         * @function getTypeUrl
         * @memberof world.s2c_giveup
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_giveup.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.s2c_giveup";
        };

        return s2c_giveup;
    })();

    world.c2s_cancel_giveup = (function() {

        /**
         * Properties of a c2s_cancel_giveup.
         * @memberof world
         * @interface Ic2s_cancel_giveup
         * @property {number|null} [eid] c2s_cancel_giveup eid
         */

        /**
         * Constructs a new c2s_cancel_giveup.
         * @memberof world
         * @classdesc Represents a c2s_cancel_giveup.
         * @implements Ic2s_cancel_giveup
         * @constructor
         * @param {world.Ic2s_cancel_giveup=} [properties] Properties to set
         */
        function c2s_cancel_giveup(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_cancel_giveup eid.
         * @member {number} eid
         * @memberof world.c2s_cancel_giveup
         * @instance
         */
        c2s_cancel_giveup.prototype.eid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new c2s_cancel_giveup instance using the specified properties.
         * @function create
         * @memberof world.c2s_cancel_giveup
         * @static
         * @param {world.Ic2s_cancel_giveup=} [properties] Properties to set
         * @returns {world.c2s_cancel_giveup} c2s_cancel_giveup instance
         */
        c2s_cancel_giveup.create = function create(properties) {
            return new c2s_cancel_giveup(properties);
        };

        /**
         * Encodes the specified c2s_cancel_giveup message. Does not implicitly {@link world.c2s_cancel_giveup.verify|verify} messages.
         * @function encode
         * @memberof world.c2s_cancel_giveup
         * @static
         * @param {world.c2s_cancel_giveup} message c2s_cancel_giveup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_cancel_giveup.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.eid);
            return writer;
        };

        /**
         * Decodes a c2s_cancel_giveup message from the specified reader or buffer.
         * @function decode
         * @memberof world.c2s_cancel_giveup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.c2s_cancel_giveup} c2s_cancel_giveup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_cancel_giveup.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.c2s_cancel_giveup();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        message.eid = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_cancel_giveup
         * @function getTypeUrl
         * @memberof world.c2s_cancel_giveup
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_cancel_giveup.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.c2s_cancel_giveup";
        };

        return c2s_cancel_giveup;
    })();

    world.s2c_cancel_giveup = (function() {

        /**
         * Properties of a s2c_cancel_giveup.
         * @memberof world
         * @interface Is2c_cancel_giveup
         * @property {number|null} [err] s2c_cancel_giveup err
         */

        /**
         * Constructs a new s2c_cancel_giveup.
         * @memberof world
         * @classdesc Represents a s2c_cancel_giveup.
         * @implements Is2c_cancel_giveup
         * @constructor
         * @param {world.Is2c_cancel_giveup=} [properties] Properties to set
         */
        function s2c_cancel_giveup(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_cancel_giveup err.
         * @member {number} err
         * @memberof world.s2c_cancel_giveup
         * @instance
         */
        s2c_cancel_giveup.prototype.err = 0;

        /**
         * Creates a new s2c_cancel_giveup instance using the specified properties.
         * @function create
         * @memberof world.s2c_cancel_giveup
         * @static
         * @param {world.Is2c_cancel_giveup=} [properties] Properties to set
         * @returns {world.s2c_cancel_giveup} s2c_cancel_giveup instance
         */
        s2c_cancel_giveup.create = function create(properties) {
            return new s2c_cancel_giveup(properties);
        };

        /**
         * Encodes the specified s2c_cancel_giveup message. Does not implicitly {@link world.s2c_cancel_giveup.verify|verify} messages.
         * @function encode
         * @memberof world.s2c_cancel_giveup
         * @static
         * @param {world.s2c_cancel_giveup} message s2c_cancel_giveup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_cancel_giveup.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_cancel_giveup message from the specified reader or buffer.
         * @function decode
         * @memberof world.s2c_cancel_giveup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.s2c_cancel_giveup} s2c_cancel_giveup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_cancel_giveup.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.s2c_cancel_giveup();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_cancel_giveup
         * @function getTypeUrl
         * @memberof world.s2c_cancel_giveup
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_cancel_giveup.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.s2c_cancel_giveup";
        };

        return s2c_cancel_giveup;
    })();

    world.c2s_load_detect_info = (function() {

        /**
         * Properties of a c2s_load_detect_info.
         * @memberof world
         * @interface Ic2s_load_detect_info
         * @property {world.Position|null} [pos] c2s_load_detect_info pos
         */

        /**
         * Constructs a new c2s_load_detect_info.
         * @memberof world
         * @classdesc Represents a c2s_load_detect_info.
         * @implements Ic2s_load_detect_info
         * @constructor
         * @param {world.Ic2s_load_detect_info=} [properties] Properties to set
         */
        function c2s_load_detect_info(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_load_detect_info pos.
         * @member {world.Position|null|undefined} pos
         * @memberof world.c2s_load_detect_info
         * @instance
         */
        c2s_load_detect_info.prototype.pos = null;

        /**
         * Creates a new c2s_load_detect_info instance using the specified properties.
         * @function create
         * @memberof world.c2s_load_detect_info
         * @static
         * @param {world.Ic2s_load_detect_info=} [properties] Properties to set
         * @returns {world.c2s_load_detect_info} c2s_load_detect_info instance
         */
        c2s_load_detect_info.create = function create(properties) {
            return new c2s_load_detect_info(properties);
        };

        /**
         * Encodes the specified c2s_load_detect_info message. Does not implicitly {@link world.c2s_load_detect_info.verify|verify} messages.
         * @function encode
         * @memberof world.c2s_load_detect_info
         * @static
         * @param {world.c2s_load_detect_info} message c2s_load_detect_info message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_load_detect_info.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pos != null && Object.hasOwnProperty.call(message, "pos"))
                $root.world.Position.encode(message.pos, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a c2s_load_detect_info message from the specified reader or buffer.
         * @function decode
         * @memberof world.c2s_load_detect_info
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.c2s_load_detect_info} c2s_load_detect_info
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_load_detect_info.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.c2s_load_detect_info();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.pos = $root.world.Position.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_load_detect_info
         * @function getTypeUrl
         * @memberof world.c2s_load_detect_info
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_load_detect_info.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.c2s_load_detect_info";
        };

        return c2s_load_detect_info;
    })();

    world.s2c_load_detect_info = (function() {

        /**
         * Properties of a s2c_load_detect_info.
         * @memberof world
         * @interface Is2c_load_detect_info
         * @property {number|null} [err] s2c_load_detect_info err
         * @property {number|null} [dungeonId] s2c_load_detect_info dungeonId
         * @property {number|null} [aliveCount] s2c_load_detect_info aliveCount
         */

        /**
         * Constructs a new s2c_load_detect_info.
         * @memberof world
         * @classdesc Represents a s2c_load_detect_info.
         * @implements Is2c_load_detect_info
         * @constructor
         * @param {world.Is2c_load_detect_info=} [properties] Properties to set
         */
        function s2c_load_detect_info(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_load_detect_info err.
         * @member {number} err
         * @memberof world.s2c_load_detect_info
         * @instance
         */
        s2c_load_detect_info.prototype.err = 0;

        /**
         * s2c_load_detect_info dungeonId.
         * @member {number} dungeonId
         * @memberof world.s2c_load_detect_info
         * @instance
         */
        s2c_load_detect_info.prototype.dungeonId = 0;

        /**
         * s2c_load_detect_info aliveCount.
         * @member {number} aliveCount
         * @memberof world.s2c_load_detect_info
         * @instance
         */
        s2c_load_detect_info.prototype.aliveCount = 0;

        /**
         * Creates a new s2c_load_detect_info instance using the specified properties.
         * @function create
         * @memberof world.s2c_load_detect_info
         * @static
         * @param {world.Is2c_load_detect_info=} [properties] Properties to set
         * @returns {world.s2c_load_detect_info} s2c_load_detect_info instance
         */
        s2c_load_detect_info.create = function create(properties) {
            return new s2c_load_detect_info(properties);
        };

        /**
         * Encodes the specified s2c_load_detect_info message. Does not implicitly {@link world.s2c_load_detect_info.verify|verify} messages.
         * @function encode
         * @memberof world.s2c_load_detect_info
         * @static
         * @param {world.s2c_load_detect_info} message s2c_load_detect_info message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_load_detect_info.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.dungeonId != null && Object.hasOwnProperty.call(message, "dungeonId"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.dungeonId);
            if (message.aliveCount != null && Object.hasOwnProperty.call(message, "aliveCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.aliveCount);
            return writer;
        };

        /**
         * Decodes a s2c_load_detect_info message from the specified reader or buffer.
         * @function decode
         * @memberof world.s2c_load_detect_info
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.s2c_load_detect_info} s2c_load_detect_info
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_load_detect_info.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.s2c_load_detect_info();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        message.dungeonId = reader.uint32();
                        break;
                    }
                case 3: {
                        message.aliveCount = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_load_detect_info
         * @function getTypeUrl
         * @memberof world.s2c_load_detect_info
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_load_detect_info.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.s2c_load_detect_info";
        };

        return s2c_load_detect_info;
    })();

    world.c2s_troop_move_to = (function() {

        /**
         * Properties of a c2s_troop_move_to.
         * @memberof world
         * @interface Ic2s_troop_move_to
         * @property {number|null} [troopEid] c2s_troop_move_to troopEid
         * @property {world.Position|null} [dstPos] c2s_troop_move_to dstPos
         * @property {boolean|null} [autoRetreat] c2s_troop_move_to autoRetreat
         */

        /**
         * Constructs a new c2s_troop_move_to.
         * @memberof world
         * @classdesc Represents a c2s_troop_move_to.
         * @implements Ic2s_troop_move_to
         * @constructor
         * @param {world.Ic2s_troop_move_to=} [properties] Properties to set
         */
        function c2s_troop_move_to(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_troop_move_to troopEid.
         * @member {number} troopEid
         * @memberof world.c2s_troop_move_to
         * @instance
         */
        c2s_troop_move_to.prototype.troopEid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * c2s_troop_move_to dstPos.
         * @member {world.Position|null|undefined} dstPos
         * @memberof world.c2s_troop_move_to
         * @instance
         */
        c2s_troop_move_to.prototype.dstPos = null;

        /**
         * c2s_troop_move_to autoRetreat.
         * @member {boolean} autoRetreat
         * @memberof world.c2s_troop_move_to
         * @instance
         */
        c2s_troop_move_to.prototype.autoRetreat = false;

        /**
         * Creates a new c2s_troop_move_to instance using the specified properties.
         * @function create
         * @memberof world.c2s_troop_move_to
         * @static
         * @param {world.Ic2s_troop_move_to=} [properties] Properties to set
         * @returns {world.c2s_troop_move_to} c2s_troop_move_to instance
         */
        c2s_troop_move_to.create = function create(properties) {
            return new c2s_troop_move_to(properties);
        };

        /**
         * Encodes the specified c2s_troop_move_to message. Does not implicitly {@link world.c2s_troop_move_to.verify|verify} messages.
         * @function encode
         * @memberof world.c2s_troop_move_to
         * @static
         * @param {world.c2s_troop_move_to} message c2s_troop_move_to message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_troop_move_to.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.troopEid != null && Object.hasOwnProperty.call(message, "troopEid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.troopEid);
            if (message.dstPos != null && Object.hasOwnProperty.call(message, "dstPos"))
                $root.world.Position.encode(message.dstPos, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.autoRetreat != null && Object.hasOwnProperty.call(message, "autoRetreat"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.autoRetreat);
            return writer;
        };

        /**
         * Decodes a c2s_troop_move_to message from the specified reader or buffer.
         * @function decode
         * @memberof world.c2s_troop_move_to
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.c2s_troop_move_to} c2s_troop_move_to
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_troop_move_to.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.c2s_troop_move_to();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.troopEid = reader.uint64();
                        break;
                    }
                case 2: {
                        message.dstPos = $root.world.Position.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.autoRetreat = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_troop_move_to
         * @function getTypeUrl
         * @memberof world.c2s_troop_move_to
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_troop_move_to.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.c2s_troop_move_to";
        };

        return c2s_troop_move_to;
    })();

    world.s2c_troop_move_to = (function() {

        /**
         * Properties of a s2c_troop_move_to.
         * @memberof world
         * @interface Is2c_troop_move_to
         * @property {number|null} [err] s2c_troop_move_to err
         */

        /**
         * Constructs a new s2c_troop_move_to.
         * @memberof world
         * @classdesc Represents a s2c_troop_move_to.
         * @implements Is2c_troop_move_to
         * @constructor
         * @param {world.Is2c_troop_move_to=} [properties] Properties to set
         */
        function s2c_troop_move_to(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_troop_move_to err.
         * @member {number} err
         * @memberof world.s2c_troop_move_to
         * @instance
         */
        s2c_troop_move_to.prototype.err = 0;

        /**
         * Creates a new s2c_troop_move_to instance using the specified properties.
         * @function create
         * @memberof world.s2c_troop_move_to
         * @static
         * @param {world.Is2c_troop_move_to=} [properties] Properties to set
         * @returns {world.s2c_troop_move_to} s2c_troop_move_to instance
         */
        s2c_troop_move_to.create = function create(properties) {
            return new s2c_troop_move_to(properties);
        };

        /**
         * Encodes the specified s2c_troop_move_to message. Does not implicitly {@link world.s2c_troop_move_to.verify|verify} messages.
         * @function encode
         * @memberof world.s2c_troop_move_to
         * @static
         * @param {world.s2c_troop_move_to} message s2c_troop_move_to message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_troop_move_to.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_troop_move_to message from the specified reader or buffer.
         * @function decode
         * @memberof world.s2c_troop_move_to
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.s2c_troop_move_to} s2c_troop_move_to
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_troop_move_to.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.s2c_troop_move_to();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_troop_move_to
         * @function getTypeUrl
         * @memberof world.s2c_troop_move_to
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_troop_move_to.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.s2c_troop_move_to";
        };

        return s2c_troop_move_to;
    })();

    world.c2s_troop_move_stop = (function() {

        /**
         * Properties of a c2s_troop_move_stop.
         * @memberof world
         * @interface Ic2s_troop_move_stop
         * @property {number|null} [troopEid] c2s_troop_move_stop troopEid
         */

        /**
         * Constructs a new c2s_troop_move_stop.
         * @memberof world
         * @classdesc Represents a c2s_troop_move_stop.
         * @implements Ic2s_troop_move_stop
         * @constructor
         * @param {world.Ic2s_troop_move_stop=} [properties] Properties to set
         */
        function c2s_troop_move_stop(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_troop_move_stop troopEid.
         * @member {number} troopEid
         * @memberof world.c2s_troop_move_stop
         * @instance
         */
        c2s_troop_move_stop.prototype.troopEid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new c2s_troop_move_stop instance using the specified properties.
         * @function create
         * @memberof world.c2s_troop_move_stop
         * @static
         * @param {world.Ic2s_troop_move_stop=} [properties] Properties to set
         * @returns {world.c2s_troop_move_stop} c2s_troop_move_stop instance
         */
        c2s_troop_move_stop.create = function create(properties) {
            return new c2s_troop_move_stop(properties);
        };

        /**
         * Encodes the specified c2s_troop_move_stop message. Does not implicitly {@link world.c2s_troop_move_stop.verify|verify} messages.
         * @function encode
         * @memberof world.c2s_troop_move_stop
         * @static
         * @param {world.c2s_troop_move_stop} message c2s_troop_move_stop message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_troop_move_stop.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.troopEid != null && Object.hasOwnProperty.call(message, "troopEid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.troopEid);
            return writer;
        };

        /**
         * Decodes a c2s_troop_move_stop message from the specified reader or buffer.
         * @function decode
         * @memberof world.c2s_troop_move_stop
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.c2s_troop_move_stop} c2s_troop_move_stop
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_troop_move_stop.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.c2s_troop_move_stop();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.troopEid = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_troop_move_stop
         * @function getTypeUrl
         * @memberof world.c2s_troop_move_stop
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_troop_move_stop.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.c2s_troop_move_stop";
        };

        return c2s_troop_move_stop;
    })();

    world.s2c_troop_move_stop = (function() {

        /**
         * Properties of a s2c_troop_move_stop.
         * @memberof world
         * @interface Is2c_troop_move_stop
         * @property {number|null} [err] s2c_troop_move_stop err
         */

        /**
         * Constructs a new s2c_troop_move_stop.
         * @memberof world
         * @classdesc Represents a s2c_troop_move_stop.
         * @implements Is2c_troop_move_stop
         * @constructor
         * @param {world.Is2c_troop_move_stop=} [properties] Properties to set
         */
        function s2c_troop_move_stop(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_troop_move_stop err.
         * @member {number} err
         * @memberof world.s2c_troop_move_stop
         * @instance
         */
        s2c_troop_move_stop.prototype.err = 0;

        /**
         * Creates a new s2c_troop_move_stop instance using the specified properties.
         * @function create
         * @memberof world.s2c_troop_move_stop
         * @static
         * @param {world.Is2c_troop_move_stop=} [properties] Properties to set
         * @returns {world.s2c_troop_move_stop} s2c_troop_move_stop instance
         */
        s2c_troop_move_stop.create = function create(properties) {
            return new s2c_troop_move_stop(properties);
        };

        /**
         * Encodes the specified s2c_troop_move_stop message. Does not implicitly {@link world.s2c_troop_move_stop.verify|verify} messages.
         * @function encode
         * @memberof world.s2c_troop_move_stop
         * @static
         * @param {world.s2c_troop_move_stop} message s2c_troop_move_stop message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_troop_move_stop.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_troop_move_stop message from the specified reader or buffer.
         * @function decode
         * @memberof world.s2c_troop_move_stop
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.s2c_troop_move_stop} s2c_troop_move_stop
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_troop_move_stop.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.s2c_troop_move_stop();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_troop_move_stop
         * @function getTypeUrl
         * @memberof world.s2c_troop_move_stop
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_troop_move_stop.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.s2c_troop_move_stop";
        };

        return s2c_troop_move_stop;
    })();

    world.c2s_troop_retreat = (function() {

        /**
         * Properties of a c2s_troop_retreat.
         * @memberof world
         * @interface Ic2s_troop_retreat
         * @property {number|null} [troopEid] c2s_troop_retreat troopEid
         */

        /**
         * Constructs a new c2s_troop_retreat.
         * @memberof world
         * @classdesc Represents a c2s_troop_retreat.
         * @implements Ic2s_troop_retreat
         * @constructor
         * @param {world.Ic2s_troop_retreat=} [properties] Properties to set
         */
        function c2s_troop_retreat(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_troop_retreat troopEid.
         * @member {number} troopEid
         * @memberof world.c2s_troop_retreat
         * @instance
         */
        c2s_troop_retreat.prototype.troopEid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new c2s_troop_retreat instance using the specified properties.
         * @function create
         * @memberof world.c2s_troop_retreat
         * @static
         * @param {world.Ic2s_troop_retreat=} [properties] Properties to set
         * @returns {world.c2s_troop_retreat} c2s_troop_retreat instance
         */
        c2s_troop_retreat.create = function create(properties) {
            return new c2s_troop_retreat(properties);
        };

        /**
         * Encodes the specified c2s_troop_retreat message. Does not implicitly {@link world.c2s_troop_retreat.verify|verify} messages.
         * @function encode
         * @memberof world.c2s_troop_retreat
         * @static
         * @param {world.c2s_troop_retreat} message c2s_troop_retreat message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_troop_retreat.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.troopEid != null && Object.hasOwnProperty.call(message, "troopEid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.troopEid);
            return writer;
        };

        /**
         * Decodes a c2s_troop_retreat message from the specified reader or buffer.
         * @function decode
         * @memberof world.c2s_troop_retreat
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.c2s_troop_retreat} c2s_troop_retreat
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_troop_retreat.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.c2s_troop_retreat();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.troopEid = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_troop_retreat
         * @function getTypeUrl
         * @memberof world.c2s_troop_retreat
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_troop_retreat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.c2s_troop_retreat";
        };

        return c2s_troop_retreat;
    })();

    world.s2c_troop_retreat = (function() {

        /**
         * Properties of a s2c_troop_retreat.
         * @memberof world
         * @interface Is2c_troop_retreat
         * @property {number|null} [err] s2c_troop_retreat err
         */

        /**
         * Constructs a new s2c_troop_retreat.
         * @memberof world
         * @classdesc Represents a s2c_troop_retreat.
         * @implements Is2c_troop_retreat
         * @constructor
         * @param {world.Is2c_troop_retreat=} [properties] Properties to set
         */
        function s2c_troop_retreat(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_troop_retreat err.
         * @member {number} err
         * @memberof world.s2c_troop_retreat
         * @instance
         */
        s2c_troop_retreat.prototype.err = 0;

        /**
         * Creates a new s2c_troop_retreat instance using the specified properties.
         * @function create
         * @memberof world.s2c_troop_retreat
         * @static
         * @param {world.Is2c_troop_retreat=} [properties] Properties to set
         * @returns {world.s2c_troop_retreat} s2c_troop_retreat instance
         */
        s2c_troop_retreat.create = function create(properties) {
            return new s2c_troop_retreat(properties);
        };

        /**
         * Encodes the specified s2c_troop_retreat message. Does not implicitly {@link world.s2c_troop_retreat.verify|verify} messages.
         * @function encode
         * @memberof world.s2c_troop_retreat
         * @static
         * @param {world.s2c_troop_retreat} message s2c_troop_retreat message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_troop_retreat.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_troop_retreat message from the specified reader or buffer.
         * @function decode
         * @memberof world.s2c_troop_retreat
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.s2c_troop_retreat} s2c_troop_retreat
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_troop_retreat.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.s2c_troop_retreat();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_troop_retreat
         * @function getTypeUrl
         * @memberof world.s2c_troop_retreat
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_troop_retreat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.s2c_troop_retreat";
        };

        return s2c_troop_retreat;
    })();

    world.c2s_calc_troop_move_to = (function() {

        /**
         * Properties of a c2s_calc_troop_move_to.
         * @memberof world
         * @interface Ic2s_calc_troop_move_to
         * @property {number|null} [troopEid] c2s_calc_troop_move_to troopEid
         * @property {world.Position|null} [dstPos] c2s_calc_troop_move_to dstPos
         */

        /**
         * Constructs a new c2s_calc_troop_move_to.
         * @memberof world
         * @classdesc Represents a c2s_calc_troop_move_to.
         * @implements Ic2s_calc_troop_move_to
         * @constructor
         * @param {world.Ic2s_calc_troop_move_to=} [properties] Properties to set
         */
        function c2s_calc_troop_move_to(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_calc_troop_move_to troopEid.
         * @member {number} troopEid
         * @memberof world.c2s_calc_troop_move_to
         * @instance
         */
        c2s_calc_troop_move_to.prototype.troopEid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * c2s_calc_troop_move_to dstPos.
         * @member {world.Position|null|undefined} dstPos
         * @memberof world.c2s_calc_troop_move_to
         * @instance
         */
        c2s_calc_troop_move_to.prototype.dstPos = null;

        /**
         * Creates a new c2s_calc_troop_move_to instance using the specified properties.
         * @function create
         * @memberof world.c2s_calc_troop_move_to
         * @static
         * @param {world.Ic2s_calc_troop_move_to=} [properties] Properties to set
         * @returns {world.c2s_calc_troop_move_to} c2s_calc_troop_move_to instance
         */
        c2s_calc_troop_move_to.create = function create(properties) {
            return new c2s_calc_troop_move_to(properties);
        };

        /**
         * Encodes the specified c2s_calc_troop_move_to message. Does not implicitly {@link world.c2s_calc_troop_move_to.verify|verify} messages.
         * @function encode
         * @memberof world.c2s_calc_troop_move_to
         * @static
         * @param {world.c2s_calc_troop_move_to} message c2s_calc_troop_move_to message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_calc_troop_move_to.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.troopEid != null && Object.hasOwnProperty.call(message, "troopEid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.troopEid);
            if (message.dstPos != null && Object.hasOwnProperty.call(message, "dstPos"))
                $root.world.Position.encode(message.dstPos, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a c2s_calc_troop_move_to message from the specified reader or buffer.
         * @function decode
         * @memberof world.c2s_calc_troop_move_to
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.c2s_calc_troop_move_to} c2s_calc_troop_move_to
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_calc_troop_move_to.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.c2s_calc_troop_move_to();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.troopEid = reader.uint64();
                        break;
                    }
                case 2: {
                        message.dstPos = $root.world.Position.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_calc_troop_move_to
         * @function getTypeUrl
         * @memberof world.c2s_calc_troop_move_to
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_calc_troop_move_to.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.c2s_calc_troop_move_to";
        };

        return c2s_calc_troop_move_to;
    })();

    world.s2c_calc_troop_move_to = (function() {

        /**
         * Properties of a s2c_calc_troop_move_to.
         * @memberof world
         * @interface Is2c_calc_troop_move_to
         * @property {number|null} [err] s2c_calc_troop_move_to err
         * @property {Array.<world.Position>|null} [path] s2c_calc_troop_move_to path
         * @property {number|null} [costMs] s2c_calc_troop_move_to costMs
         */

        /**
         * Constructs a new s2c_calc_troop_move_to.
         * @memberof world
         * @classdesc Represents a s2c_calc_troop_move_to.
         * @implements Is2c_calc_troop_move_to
         * @constructor
         * @param {world.Is2c_calc_troop_move_to=} [properties] Properties to set
         */
        function s2c_calc_troop_move_to(properties) {
            this.path = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_calc_troop_move_to err.
         * @member {number} err
         * @memberof world.s2c_calc_troop_move_to
         * @instance
         */
        s2c_calc_troop_move_to.prototype.err = 0;

        /**
         * s2c_calc_troop_move_to path.
         * @member {Array.<world.Position>} path
         * @memberof world.s2c_calc_troop_move_to
         * @instance
         */
        s2c_calc_troop_move_to.prototype.path = $util.emptyArray;

        /**
         * s2c_calc_troop_move_to costMs.
         * @member {number} costMs
         * @memberof world.s2c_calc_troop_move_to
         * @instance
         */
        s2c_calc_troop_move_to.prototype.costMs = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new s2c_calc_troop_move_to instance using the specified properties.
         * @function create
         * @memberof world.s2c_calc_troop_move_to
         * @static
         * @param {world.Is2c_calc_troop_move_to=} [properties] Properties to set
         * @returns {world.s2c_calc_troop_move_to} s2c_calc_troop_move_to instance
         */
        s2c_calc_troop_move_to.create = function create(properties) {
            return new s2c_calc_troop_move_to(properties);
        };

        /**
         * Encodes the specified s2c_calc_troop_move_to message. Does not implicitly {@link world.s2c_calc_troop_move_to.verify|verify} messages.
         * @function encode
         * @memberof world.s2c_calc_troop_move_to
         * @static
         * @param {world.s2c_calc_troop_move_to} message s2c_calc_troop_move_to message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_calc_troop_move_to.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.path != null && message.path.length)
                for (let i = 0; i < message.path.length; ++i)
                    $root.world.Position.encode(message.path[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.costMs != null && Object.hasOwnProperty.call(message, "costMs"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.costMs);
            return writer;
        };

        /**
         * Decodes a s2c_calc_troop_move_to message from the specified reader or buffer.
         * @function decode
         * @memberof world.s2c_calc_troop_move_to
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.s2c_calc_troop_move_to} s2c_calc_troop_move_to
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_calc_troop_move_to.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.s2c_calc_troop_move_to();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        if (!(message.path && message.path.length))
                            message.path = [];
                        message.path.push($root.world.Position.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.costMs = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_calc_troop_move_to
         * @function getTypeUrl
         * @memberof world.s2c_calc_troop_move_to
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_calc_troop_move_to.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.s2c_calc_troop_move_to";
        };

        return s2c_calc_troop_move_to;
    })();

    world.c2s_declare = (function() {

        /**
         * Properties of a c2s_declare.
         * @memberof world
         * @interface Ic2s_declare
         * @property {number|null} [cityEid] c2s_declare cityEid
         */

        /**
         * Constructs a new c2s_declare.
         * @memberof world
         * @classdesc Represents a c2s_declare.
         * @implements Ic2s_declare
         * @constructor
         * @param {world.Ic2s_declare=} [properties] Properties to set
         */
        function c2s_declare(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_declare cityEid.
         * @member {number} cityEid
         * @memberof world.c2s_declare
         * @instance
         */
        c2s_declare.prototype.cityEid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new c2s_declare instance using the specified properties.
         * @function create
         * @memberof world.c2s_declare
         * @static
         * @param {world.Ic2s_declare=} [properties] Properties to set
         * @returns {world.c2s_declare} c2s_declare instance
         */
        c2s_declare.create = function create(properties) {
            return new c2s_declare(properties);
        };

        /**
         * Encodes the specified c2s_declare message. Does not implicitly {@link world.c2s_declare.verify|verify} messages.
         * @function encode
         * @memberof world.c2s_declare
         * @static
         * @param {world.c2s_declare} message c2s_declare message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_declare.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cityEid != null && Object.hasOwnProperty.call(message, "cityEid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.cityEid);
            return writer;
        };

        /**
         * Decodes a c2s_declare message from the specified reader or buffer.
         * @function decode
         * @memberof world.c2s_declare
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.c2s_declare} c2s_declare
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_declare.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.c2s_declare();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.cityEid = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_declare
         * @function getTypeUrl
         * @memberof world.c2s_declare
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_declare.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.c2s_declare";
        };

        return c2s_declare;
    })();

    world.s2c_declare = (function() {

        /**
         * Properties of a s2c_declare.
         * @memberof world
         * @interface Is2c_declare
         * @property {number|null} [err] s2c_declare err
         * @property {number|null} [seEid] s2c_declare seEid
         */

        /**
         * Constructs a new s2c_declare.
         * @memberof world
         * @classdesc Represents a s2c_declare.
         * @implements Is2c_declare
         * @constructor
         * @param {world.Is2c_declare=} [properties] Properties to set
         */
        function s2c_declare(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_declare err.
         * @member {number} err
         * @memberof world.s2c_declare
         * @instance
         */
        s2c_declare.prototype.err = 0;

        /**
         * s2c_declare seEid.
         * @member {number} seEid
         * @memberof world.s2c_declare
         * @instance
         */
        s2c_declare.prototype.seEid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new s2c_declare instance using the specified properties.
         * @function create
         * @memberof world.s2c_declare
         * @static
         * @param {world.Is2c_declare=} [properties] Properties to set
         * @returns {world.s2c_declare} s2c_declare instance
         */
        s2c_declare.create = function create(properties) {
            return new s2c_declare(properties);
        };

        /**
         * Encodes the specified s2c_declare message. Does not implicitly {@link world.s2c_declare.verify|verify} messages.
         * @function encode
         * @memberof world.s2c_declare
         * @static
         * @param {world.s2c_declare} message s2c_declare message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_declare.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            if (message.seEid != null && Object.hasOwnProperty.call(message, "seEid"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.seEid);
            return writer;
        };

        /**
         * Decodes a s2c_declare message from the specified reader or buffer.
         * @function decode
         * @memberof world.s2c_declare
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.s2c_declare} s2c_declare
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_declare.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.s2c_declare();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                case 2: {
                        message.seEid = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_declare
         * @function getTypeUrl
         * @memberof world.s2c_declare
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_declare.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.s2c_declare";
        };

        return s2c_declare;
    })();

    world.c2s_join_siege_engine = (function() {

        /**
         * Properties of a c2s_join_siege_engine.
         * @memberof world
         * @interface Ic2s_join_siege_engine
         * @property {Array.<number>|null} [troopEids] c2s_join_siege_engine troopEids
         * @property {number|null} [seEid] c2s_join_siege_engine seEid
         */

        /**
         * Constructs a new c2s_join_siege_engine.
         * @memberof world
         * @classdesc Represents a c2s_join_siege_engine.
         * @implements Ic2s_join_siege_engine
         * @constructor
         * @param {world.Ic2s_join_siege_engine=} [properties] Properties to set
         */
        function c2s_join_siege_engine(properties) {
            this.troopEids = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * c2s_join_siege_engine troopEids.
         * @member {Array.<number>} troopEids
         * @memberof world.c2s_join_siege_engine
         * @instance
         */
        c2s_join_siege_engine.prototype.troopEids = $util.emptyArray;

        /**
         * c2s_join_siege_engine seEid.
         * @member {number} seEid
         * @memberof world.c2s_join_siege_engine
         * @instance
         */
        c2s_join_siege_engine.prototype.seEid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new c2s_join_siege_engine instance using the specified properties.
         * @function create
         * @memberof world.c2s_join_siege_engine
         * @static
         * @param {world.Ic2s_join_siege_engine=} [properties] Properties to set
         * @returns {world.c2s_join_siege_engine} c2s_join_siege_engine instance
         */
        c2s_join_siege_engine.create = function create(properties) {
            return new c2s_join_siege_engine(properties);
        };

        /**
         * Encodes the specified c2s_join_siege_engine message. Does not implicitly {@link world.c2s_join_siege_engine.verify|verify} messages.
         * @function encode
         * @memberof world.c2s_join_siege_engine
         * @static
         * @param {world.c2s_join_siege_engine} message c2s_join_siege_engine message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        c2s_join_siege_engine.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.troopEids != null && message.troopEids.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (let i = 0; i < message.troopEids.length; ++i)
                    writer.uint64(message.troopEids[i]);
                writer.ldelim();
            }
            if (message.seEid != null && Object.hasOwnProperty.call(message, "seEid"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.seEid);
            return writer;
        };

        /**
         * Decodes a c2s_join_siege_engine message from the specified reader or buffer.
         * @function decode
         * @memberof world.c2s_join_siege_engine
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.c2s_join_siege_engine} c2s_join_siege_engine
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        c2s_join_siege_engine.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.c2s_join_siege_engine();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.troopEids && message.troopEids.length))
                            message.troopEids = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.troopEids.push(reader.uint64());
                        } else
                            message.troopEids.push(reader.uint64());
                        break;
                    }
                case 2: {
                        message.seEid = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for c2s_join_siege_engine
         * @function getTypeUrl
         * @memberof world.c2s_join_siege_engine
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        c2s_join_siege_engine.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.c2s_join_siege_engine";
        };

        return c2s_join_siege_engine;
    })();

    world.s2c_join_siege_engine = (function() {

        /**
         * Properties of a s2c_join_siege_engine.
         * @memberof world
         * @interface Is2c_join_siege_engine
         * @property {number|null} [err] s2c_join_siege_engine err
         */

        /**
         * Constructs a new s2c_join_siege_engine.
         * @memberof world
         * @classdesc Represents a s2c_join_siege_engine.
         * @implements Is2c_join_siege_engine
         * @constructor
         * @param {world.Is2c_join_siege_engine=} [properties] Properties to set
         */
        function s2c_join_siege_engine(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * s2c_join_siege_engine err.
         * @member {number} err
         * @memberof world.s2c_join_siege_engine
         * @instance
         */
        s2c_join_siege_engine.prototype.err = 0;

        /**
         * Creates a new s2c_join_siege_engine instance using the specified properties.
         * @function create
         * @memberof world.s2c_join_siege_engine
         * @static
         * @param {world.Is2c_join_siege_engine=} [properties] Properties to set
         * @returns {world.s2c_join_siege_engine} s2c_join_siege_engine instance
         */
        s2c_join_siege_engine.create = function create(properties) {
            return new s2c_join_siege_engine(properties);
        };

        /**
         * Encodes the specified s2c_join_siege_engine message. Does not implicitly {@link world.s2c_join_siege_engine.verify|verify} messages.
         * @function encode
         * @memberof world.s2c_join_siege_engine
         * @static
         * @param {world.s2c_join_siege_engine} message s2c_join_siege_engine message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        s2c_join_siege_engine.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.err);
            return writer;
        };

        /**
         * Decodes a s2c_join_siege_engine message from the specified reader or buffer.
         * @function decode
         * @memberof world.s2c_join_siege_engine
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.s2c_join_siege_engine} s2c_join_siege_engine
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        s2c_join_siege_engine.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.s2c_join_siege_engine();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.err = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for s2c_join_siege_engine
         * @function getTypeUrl
         * @memberof world.s2c_join_siege_engine
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        s2c_join_siege_engine.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.s2c_join_siege_engine";
        };

        return s2c_join_siege_engine;
    })();

    world.notify_alliances = (function() {

        /**
         * Properties of a notify_alliances.
         * @memberof world
         * @interface Inotify_alliances
         * @property {Array.<world.Alliance>|null} [alliances] notify_alliances alliances
         */

        /**
         * Constructs a new notify_alliances.
         * @memberof world
         * @classdesc Represents a notify_alliances.
         * @implements Inotify_alliances
         * @constructor
         * @param {world.Inotify_alliances=} [properties] Properties to set
         */
        function notify_alliances(properties) {
            this.alliances = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_alliances alliances.
         * @member {Array.<world.Alliance>} alliances
         * @memberof world.notify_alliances
         * @instance
         */
        notify_alliances.prototype.alliances = $util.emptyArray;

        /**
         * Creates a new notify_alliances instance using the specified properties.
         * @function create
         * @memberof world.notify_alliances
         * @static
         * @param {world.Inotify_alliances=} [properties] Properties to set
         * @returns {world.notify_alliances} notify_alliances instance
         */
        notify_alliances.create = function create(properties) {
            return new notify_alliances(properties);
        };

        /**
         * Encodes the specified notify_alliances message. Does not implicitly {@link world.notify_alliances.verify|verify} messages.
         * @function encode
         * @memberof world.notify_alliances
         * @static
         * @param {world.notify_alliances} message notify_alliances message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_alliances.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.alliances != null && message.alliances.length)
                for (let i = 0; i < message.alliances.length; ++i)
                    $root.world.Alliance.encode(message.alliances[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a notify_alliances message from the specified reader or buffer.
         * @function decode
         * @memberof world.notify_alliances
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.notify_alliances} notify_alliances
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_alliances.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.notify_alliances();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.alliances && message.alliances.length))
                            message.alliances = [];
                        message.alliances.push($root.world.Alliance.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_alliances
         * @function getTypeUrl
         * @memberof world.notify_alliances
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_alliances.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.notify_alliances";
        };

        return notify_alliances;
    })();

    world.notify_roles = (function() {

        /**
         * Properties of a notify_roles.
         * @memberof world
         * @interface Inotify_roles
         * @property {Array.<world.Role>|null} [roles] notify_roles roles
         */

        /**
         * Constructs a new notify_roles.
         * @memberof world
         * @classdesc Represents a notify_roles.
         * @implements Inotify_roles
         * @constructor
         * @param {world.Inotify_roles=} [properties] Properties to set
         */
        function notify_roles(properties) {
            this.roles = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_roles roles.
         * @member {Array.<world.Role>} roles
         * @memberof world.notify_roles
         * @instance
         */
        notify_roles.prototype.roles = $util.emptyArray;

        /**
         * Creates a new notify_roles instance using the specified properties.
         * @function create
         * @memberof world.notify_roles
         * @static
         * @param {world.Inotify_roles=} [properties] Properties to set
         * @returns {world.notify_roles} notify_roles instance
         */
        notify_roles.create = function create(properties) {
            return new notify_roles(properties);
        };

        /**
         * Encodes the specified notify_roles message. Does not implicitly {@link world.notify_roles.verify|verify} messages.
         * @function encode
         * @memberof world.notify_roles
         * @static
         * @param {world.notify_roles} message notify_roles message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_roles.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.roles != null && message.roles.length)
                for (let i = 0; i < message.roles.length; ++i)
                    $root.world.Role.encode(message.roles[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a notify_roles message from the specified reader or buffer.
         * @function decode
         * @memberof world.notify_roles
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.notify_roles} notify_roles
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_roles.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.notify_roles();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.roles && message.roles.length))
                            message.roles = [];
                        message.roles.push($root.world.Role.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_roles
         * @function getTypeUrl
         * @memberof world.notify_roles
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_roles.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.notify_roles";
        };

        return notify_roles;
    })();

    world.AddEntityAction = (function() {

        /**
         * Properties of an AddEntityAction.
         * @memberof world
         * @interface IAddEntityAction
         * @property {world.Entity|null} [entity] AddEntityAction entity
         */

        /**
         * Constructs a new AddEntityAction.
         * @memberof world
         * @classdesc Represents an AddEntityAction.
         * @implements IAddEntityAction
         * @constructor
         * @param {world.IAddEntityAction=} [properties] Properties to set
         */
        function AddEntityAction(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddEntityAction entity.
         * @member {world.Entity|null|undefined} entity
         * @memberof world.AddEntityAction
         * @instance
         */
        AddEntityAction.prototype.entity = null;

        /**
         * Creates a new AddEntityAction instance using the specified properties.
         * @function create
         * @memberof world.AddEntityAction
         * @static
         * @param {world.IAddEntityAction=} [properties] Properties to set
         * @returns {world.AddEntityAction} AddEntityAction instance
         */
        AddEntityAction.create = function create(properties) {
            return new AddEntityAction(properties);
        };

        /**
         * Encodes the specified AddEntityAction message. Does not implicitly {@link world.AddEntityAction.verify|verify} messages.
         * @function encode
         * @memberof world.AddEntityAction
         * @static
         * @param {world.AddEntityAction} message AddEntityAction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddEntityAction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.entity != null && Object.hasOwnProperty.call(message, "entity"))
                $root.world.Entity.encode(message.entity, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an AddEntityAction message from the specified reader or buffer.
         * @function decode
         * @memberof world.AddEntityAction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.AddEntityAction} AddEntityAction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddEntityAction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.AddEntityAction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.entity = $root.world.Entity.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for AddEntityAction
         * @function getTypeUrl
         * @memberof world.AddEntityAction
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AddEntityAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.AddEntityAction";
        };

        return AddEntityAction;
    })();

    world.DelEntityAction = (function() {

        /**
         * Properties of a DelEntityAction.
         * @memberof world
         * @interface IDelEntityAction
         * @property {number|null} [eid] DelEntityAction eid
         */

        /**
         * Constructs a new DelEntityAction.
         * @memberof world
         * @classdesc Represents a DelEntityAction.
         * @implements IDelEntityAction
         * @constructor
         * @param {world.IDelEntityAction=} [properties] Properties to set
         */
        function DelEntityAction(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DelEntityAction eid.
         * @member {number} eid
         * @memberof world.DelEntityAction
         * @instance
         */
        DelEntityAction.prototype.eid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new DelEntityAction instance using the specified properties.
         * @function create
         * @memberof world.DelEntityAction
         * @static
         * @param {world.IDelEntityAction=} [properties] Properties to set
         * @returns {world.DelEntityAction} DelEntityAction instance
         */
        DelEntityAction.create = function create(properties) {
            return new DelEntityAction(properties);
        };

        /**
         * Encodes the specified DelEntityAction message. Does not implicitly {@link world.DelEntityAction.verify|verify} messages.
         * @function encode
         * @memberof world.DelEntityAction
         * @static
         * @param {world.DelEntityAction} message DelEntityAction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DelEntityAction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.eid);
            return writer;
        };

        /**
         * Decodes a DelEntityAction message from the specified reader or buffer.
         * @function decode
         * @memberof world.DelEntityAction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.DelEntityAction} DelEntityAction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DelEntityAction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.DelEntityAction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.eid = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for DelEntityAction
         * @function getTypeUrl
         * @memberof world.DelEntityAction
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DelEntityAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.DelEntityAction";
        };

        return DelEntityAction;
    })();

    world.MoveAction = (function() {

        /**
         * Properties of a MoveAction.
         * @memberof world
         * @interface IMoveAction
         * @property {number|null} [eid] MoveAction eid
         * @property {Array.<world.Position>|null} [path] MoveAction path
         * @property {number|null} [speed] MoveAction speed
         * @property {number|null} [startMs] MoveAction startMs
         * @property {world.Position|null} [curPos] MoveAction curPos
         */

        /**
         * Constructs a new MoveAction.
         * @memberof world
         * @classdesc Represents a MoveAction.
         * @implements IMoveAction
         * @constructor
         * @param {world.IMoveAction=} [properties] Properties to set
         */
        function MoveAction(properties) {
            this.path = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MoveAction eid.
         * @member {number} eid
         * @memberof world.MoveAction
         * @instance
         */
        MoveAction.prototype.eid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * MoveAction path.
         * @member {Array.<world.Position>} path
         * @memberof world.MoveAction
         * @instance
         */
        MoveAction.prototype.path = $util.emptyArray;

        /**
         * MoveAction speed.
         * @member {number} speed
         * @memberof world.MoveAction
         * @instance
         */
        MoveAction.prototype.speed = 0;

        /**
         * MoveAction startMs.
         * @member {number} startMs
         * @memberof world.MoveAction
         * @instance
         */
        MoveAction.prototype.startMs = 0;

        /**
         * MoveAction curPos.
         * @member {world.Position|null|undefined} curPos
         * @memberof world.MoveAction
         * @instance
         */
        MoveAction.prototype.curPos = null;

        /**
         * Creates a new MoveAction instance using the specified properties.
         * @function create
         * @memberof world.MoveAction
         * @static
         * @param {world.IMoveAction=} [properties] Properties to set
         * @returns {world.MoveAction} MoveAction instance
         */
        MoveAction.create = function create(properties) {
            return new MoveAction(properties);
        };

        /**
         * Encodes the specified MoveAction message. Does not implicitly {@link world.MoveAction.verify|verify} messages.
         * @function encode
         * @memberof world.MoveAction
         * @static
         * @param {world.MoveAction} message MoveAction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MoveAction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.eid);
            if (message.path != null && message.path.length)
                for (let i = 0; i < message.path.length; ++i)
                    $root.world.Position.encode(message.path[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.speed != null && Object.hasOwnProperty.call(message, "speed"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.speed);
            if (message.startMs != null && Object.hasOwnProperty.call(message, "startMs"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.startMs);
            if (message.curPos != null && Object.hasOwnProperty.call(message, "curPos"))
                $root.world.Position.encode(message.curPos, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MoveAction message from the specified reader or buffer.
         * @function decode
         * @memberof world.MoveAction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.MoveAction} MoveAction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MoveAction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.MoveAction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.eid = reader.uint64();
                        break;
                    }
                case 2: {
                        if (!(message.path && message.path.length))
                            message.path = [];
                        message.path.push($root.world.Position.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.speed = reader.uint32();
                        break;
                    }
                case 4: {
                        message.startMs = reader.uint32();
                        break;
                    }
                case 5: {
                        message.curPos = $root.world.Position.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for MoveAction
         * @function getTypeUrl
         * @memberof world.MoveAction
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MoveAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.MoveAction";
        };

        return MoveAction;
    })();

    world.ChangeOwnerAction = (function() {

        /**
         * Properties of a ChangeOwnerAction.
         * @memberof world
         * @interface IChangeOwnerAction
         * @property {number|null} [eid] ChangeOwnerAction eid
         * @property {world.OwnerComponent|null} [owner] ChangeOwnerAction owner
         */

        /**
         * Constructs a new ChangeOwnerAction.
         * @memberof world
         * @classdesc Represents a ChangeOwnerAction.
         * @implements IChangeOwnerAction
         * @constructor
         * @param {world.IChangeOwnerAction=} [properties] Properties to set
         */
        function ChangeOwnerAction(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChangeOwnerAction eid.
         * @member {number} eid
         * @memberof world.ChangeOwnerAction
         * @instance
         */
        ChangeOwnerAction.prototype.eid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ChangeOwnerAction owner.
         * @member {world.OwnerComponent|null|undefined} owner
         * @memberof world.ChangeOwnerAction
         * @instance
         */
        ChangeOwnerAction.prototype.owner = null;

        /**
         * Creates a new ChangeOwnerAction instance using the specified properties.
         * @function create
         * @memberof world.ChangeOwnerAction
         * @static
         * @param {world.IChangeOwnerAction=} [properties] Properties to set
         * @returns {world.ChangeOwnerAction} ChangeOwnerAction instance
         */
        ChangeOwnerAction.create = function create(properties) {
            return new ChangeOwnerAction(properties);
        };

        /**
         * Encodes the specified ChangeOwnerAction message. Does not implicitly {@link world.ChangeOwnerAction.verify|verify} messages.
         * @function encode
         * @memberof world.ChangeOwnerAction
         * @static
         * @param {world.ChangeOwnerAction} message ChangeOwnerAction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangeOwnerAction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.eid);
            if (message.owner != null && Object.hasOwnProperty.call(message, "owner"))
                $root.world.OwnerComponent.encode(message.owner, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ChangeOwnerAction message from the specified reader or buffer.
         * @function decode
         * @memberof world.ChangeOwnerAction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.ChangeOwnerAction} ChangeOwnerAction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangeOwnerAction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.ChangeOwnerAction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.eid = reader.uint64();
                        break;
                    }
                case 2: {
                        message.owner = $root.world.OwnerComponent.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for ChangeOwnerAction
         * @function getTypeUrl
         * @memberof world.ChangeOwnerAction
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChangeOwnerAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.ChangeOwnerAction";
        };

        return ChangeOwnerAction;
    })();

    world.UpdateHpAction = (function() {

        /**
         * Properties of an UpdateHpAction.
         * @memberof world
         * @interface IUpdateHpAction
         * @property {number|null} [eid] UpdateHpAction eid
         * @property {number|null} [hp] UpdateHpAction hp
         * @property {number|null} [maxHp] UpdateHpAction maxHp
         */

        /**
         * Constructs a new UpdateHpAction.
         * @memberof world
         * @classdesc Represents an UpdateHpAction.
         * @implements IUpdateHpAction
         * @constructor
         * @param {world.IUpdateHpAction=} [properties] Properties to set
         */
        function UpdateHpAction(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateHpAction eid.
         * @member {number} eid
         * @memberof world.UpdateHpAction
         * @instance
         */
        UpdateHpAction.prototype.eid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * UpdateHpAction hp.
         * @member {number} hp
         * @memberof world.UpdateHpAction
         * @instance
         */
        UpdateHpAction.prototype.hp = 0;

        /**
         * UpdateHpAction maxHp.
         * @member {number} maxHp
         * @memberof world.UpdateHpAction
         * @instance
         */
        UpdateHpAction.prototype.maxHp = 0;

        /**
         * Creates a new UpdateHpAction instance using the specified properties.
         * @function create
         * @memberof world.UpdateHpAction
         * @static
         * @param {world.IUpdateHpAction=} [properties] Properties to set
         * @returns {world.UpdateHpAction} UpdateHpAction instance
         */
        UpdateHpAction.create = function create(properties) {
            return new UpdateHpAction(properties);
        };

        /**
         * Encodes the specified UpdateHpAction message. Does not implicitly {@link world.UpdateHpAction.verify|verify} messages.
         * @function encode
         * @memberof world.UpdateHpAction
         * @static
         * @param {world.UpdateHpAction} message UpdateHpAction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateHpAction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.eid);
            if (message.hp != null && Object.hasOwnProperty.call(message, "hp"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.hp);
            if (message.maxHp != null && Object.hasOwnProperty.call(message, "maxHp"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.maxHp);
            return writer;
        };

        /**
         * Decodes an UpdateHpAction message from the specified reader or buffer.
         * @function decode
         * @memberof world.UpdateHpAction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.UpdateHpAction} UpdateHpAction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateHpAction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.UpdateHpAction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.eid = reader.uint64();
                        break;
                    }
                case 2: {
                        message.hp = reader.uint32();
                        break;
                    }
                case 3: {
                        message.maxHp = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for UpdateHpAction
         * @function getTypeUrl
         * @memberof world.UpdateHpAction
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateHpAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.UpdateHpAction";
        };

        return UpdateHpAction;
    })();

    world.UpdateEntityAction = (function() {

        /**
         * Properties of an UpdateEntityAction.
         * @memberof world
         * @interface IUpdateEntityAction
         * @property {world.Entity|null} [entity] UpdateEntityAction entity
         */

        /**
         * Constructs a new UpdateEntityAction.
         * @memberof world
         * @classdesc Represents an UpdateEntityAction.
         * @implements IUpdateEntityAction
         * @constructor
         * @param {world.IUpdateEntityAction=} [properties] Properties to set
         */
        function UpdateEntityAction(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateEntityAction entity.
         * @member {world.Entity|null|undefined} entity
         * @memberof world.UpdateEntityAction
         * @instance
         */
        UpdateEntityAction.prototype.entity = null;

        /**
         * Creates a new UpdateEntityAction instance using the specified properties.
         * @function create
         * @memberof world.UpdateEntityAction
         * @static
         * @param {world.IUpdateEntityAction=} [properties] Properties to set
         * @returns {world.UpdateEntityAction} UpdateEntityAction instance
         */
        UpdateEntityAction.create = function create(properties) {
            return new UpdateEntityAction(properties);
        };

        /**
         * Encodes the specified UpdateEntityAction message. Does not implicitly {@link world.UpdateEntityAction.verify|verify} messages.
         * @function encode
         * @memberof world.UpdateEntityAction
         * @static
         * @param {world.UpdateEntityAction} message UpdateEntityAction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateEntityAction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.entity != null && Object.hasOwnProperty.call(message, "entity"))
                $root.world.Entity.encode(message.entity, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an UpdateEntityAction message from the specified reader or buffer.
         * @function decode
         * @memberof world.UpdateEntityAction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.UpdateEntityAction} UpdateEntityAction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateEntityAction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.UpdateEntityAction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.entity = $root.world.Entity.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for UpdateEntityAction
         * @function getTypeUrl
         * @memberof world.UpdateEntityAction
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateEntityAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.UpdateEntityAction";
        };

        return UpdateEntityAction;
    })();

    world.StartBattleAction = (function() {

        /**
         * Properties of a StartBattleAction.
         * @memberof world
         * @interface IStartBattleAction
         * @property {number|null} [battleEid] StartBattleAction battleEid
         * @property {Array.<number>|null} [troopEids] StartBattleAction troopEids
         */

        /**
         * Constructs a new StartBattleAction.
         * @memberof world
         * @classdesc Represents a StartBattleAction.
         * @implements IStartBattleAction
         * @constructor
         * @param {world.IStartBattleAction=} [properties] Properties to set
         */
        function StartBattleAction(properties) {
            this.troopEids = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StartBattleAction battleEid.
         * @member {number} battleEid
         * @memberof world.StartBattleAction
         * @instance
         */
        StartBattleAction.prototype.battleEid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * StartBattleAction troopEids.
         * @member {Array.<number>} troopEids
         * @memberof world.StartBattleAction
         * @instance
         */
        StartBattleAction.prototype.troopEids = $util.emptyArray;

        /**
         * Creates a new StartBattleAction instance using the specified properties.
         * @function create
         * @memberof world.StartBattleAction
         * @static
         * @param {world.IStartBattleAction=} [properties] Properties to set
         * @returns {world.StartBattleAction} StartBattleAction instance
         */
        StartBattleAction.create = function create(properties) {
            return new StartBattleAction(properties);
        };

        /**
         * Encodes the specified StartBattleAction message. Does not implicitly {@link world.StartBattleAction.verify|verify} messages.
         * @function encode
         * @memberof world.StartBattleAction
         * @static
         * @param {world.StartBattleAction} message StartBattleAction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StartBattleAction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.battleEid != null && Object.hasOwnProperty.call(message, "battleEid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.battleEid);
            if (message.troopEids != null && message.troopEids.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (let i = 0; i < message.troopEids.length; ++i)
                    writer.uint64(message.troopEids[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a StartBattleAction message from the specified reader or buffer.
         * @function decode
         * @memberof world.StartBattleAction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.StartBattleAction} StartBattleAction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StartBattleAction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.StartBattleAction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.battleEid = reader.uint64();
                        break;
                    }
                case 2: {
                        if (!(message.troopEids && message.troopEids.length))
                            message.troopEids = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.troopEids.push(reader.uint64());
                        } else
                            message.troopEids.push(reader.uint64());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for StartBattleAction
         * @function getTypeUrl
         * @memberof world.StartBattleAction
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StartBattleAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.StartBattleAction";
        };

        return StartBattleAction;
    })();

    world.LeaveBattleAction = (function() {

        /**
         * Properties of a LeaveBattleAction.
         * @memberof world
         * @interface ILeaveBattleAction
         * @property {number|null} [battleEid] LeaveBattleAction battleEid
         * @property {Array.<number>|null} [troopEids] LeaveBattleAction troopEids
         */

        /**
         * Constructs a new LeaveBattleAction.
         * @memberof world
         * @classdesc Represents a LeaveBattleAction.
         * @implements ILeaveBattleAction
         * @constructor
         * @param {world.ILeaveBattleAction=} [properties] Properties to set
         */
        function LeaveBattleAction(properties) {
            this.troopEids = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LeaveBattleAction battleEid.
         * @member {number} battleEid
         * @memberof world.LeaveBattleAction
         * @instance
         */
        LeaveBattleAction.prototype.battleEid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * LeaveBattleAction troopEids.
         * @member {Array.<number>} troopEids
         * @memberof world.LeaveBattleAction
         * @instance
         */
        LeaveBattleAction.prototype.troopEids = $util.emptyArray;

        /**
         * Creates a new LeaveBattleAction instance using the specified properties.
         * @function create
         * @memberof world.LeaveBattleAction
         * @static
         * @param {world.ILeaveBattleAction=} [properties] Properties to set
         * @returns {world.LeaveBattleAction} LeaveBattleAction instance
         */
        LeaveBattleAction.create = function create(properties) {
            return new LeaveBattleAction(properties);
        };

        /**
         * Encodes the specified LeaveBattleAction message. Does not implicitly {@link world.LeaveBattleAction.verify|verify} messages.
         * @function encode
         * @memberof world.LeaveBattleAction
         * @static
         * @param {world.LeaveBattleAction} message LeaveBattleAction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LeaveBattleAction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.battleEid != null && Object.hasOwnProperty.call(message, "battleEid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.battleEid);
            if (message.troopEids != null && message.troopEids.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (let i = 0; i < message.troopEids.length; ++i)
                    writer.uint64(message.troopEids[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a LeaveBattleAction message from the specified reader or buffer.
         * @function decode
         * @memberof world.LeaveBattleAction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.LeaveBattleAction} LeaveBattleAction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LeaveBattleAction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.LeaveBattleAction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.battleEid = reader.uint64();
                        break;
                    }
                case 2: {
                        if (!(message.troopEids && message.troopEids.length))
                            message.troopEids = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.troopEids.push(reader.uint64());
                        } else
                            message.troopEids.push(reader.uint64());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for LeaveBattleAction
         * @function getTypeUrl
         * @memberof world.LeaveBattleAction
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LeaveBattleAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.LeaveBattleAction";
        };

        return LeaveBattleAction;
    })();

    world.StopBattleAction = (function() {

        /**
         * Properties of a StopBattleAction.
         * @memberof world
         * @interface IStopBattleAction
         * @property {number|null} [battleEid] StopBattleAction battleEid
         * @property {Array.<number>|null} [troopEids] StopBattleAction troopEids
         */

        /**
         * Constructs a new StopBattleAction.
         * @memberof world
         * @classdesc Represents a StopBattleAction.
         * @implements IStopBattleAction
         * @constructor
         * @param {world.IStopBattleAction=} [properties] Properties to set
         */
        function StopBattleAction(properties) {
            this.troopEids = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StopBattleAction battleEid.
         * @member {number} battleEid
         * @memberof world.StopBattleAction
         * @instance
         */
        StopBattleAction.prototype.battleEid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * StopBattleAction troopEids.
         * @member {Array.<number>} troopEids
         * @memberof world.StopBattleAction
         * @instance
         */
        StopBattleAction.prototype.troopEids = $util.emptyArray;

        /**
         * Creates a new StopBattleAction instance using the specified properties.
         * @function create
         * @memberof world.StopBattleAction
         * @static
         * @param {world.IStopBattleAction=} [properties] Properties to set
         * @returns {world.StopBattleAction} StopBattleAction instance
         */
        StopBattleAction.create = function create(properties) {
            return new StopBattleAction(properties);
        };

        /**
         * Encodes the specified StopBattleAction message. Does not implicitly {@link world.StopBattleAction.verify|verify} messages.
         * @function encode
         * @memberof world.StopBattleAction
         * @static
         * @param {world.StopBattleAction} message StopBattleAction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StopBattleAction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.battleEid != null && Object.hasOwnProperty.call(message, "battleEid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.battleEid);
            if (message.troopEids != null && message.troopEids.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (let i = 0; i < message.troopEids.length; ++i)
                    writer.uint64(message.troopEids[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a StopBattleAction message from the specified reader or buffer.
         * @function decode
         * @memberof world.StopBattleAction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.StopBattleAction} StopBattleAction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StopBattleAction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.StopBattleAction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.battleEid = reader.uint64();
                        break;
                    }
                case 2: {
                        if (!(message.troopEids && message.troopEids.length))
                            message.troopEids = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.troopEids.push(reader.uint64());
                        } else
                            message.troopEids.push(reader.uint64());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for StopBattleAction
         * @function getTypeUrl
         * @memberof world.StopBattleAction
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StopBattleAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.StopBattleAction";
        };

        return StopBattleAction;
    })();

    world.EntityAction = (function() {

        /**
         * Properties of an EntityAction.
         * @memberof world
         * @interface IEntityAction
         * @property {number|null} [action] EntityAction action
         * @property {world.AddEntityAction|null} [addEntity] EntityAction addEntity
         * @property {world.DelEntityAction|null} [delEntity] EntityAction delEntity
         * @property {world.MoveAction|null} [move] EntityAction move
         * @property {world.ChangeOwnerAction|null} [changeOwner] EntityAction changeOwner
         * @property {world.UpdateHpAction|null} [updateHp] EntityAction updateHp
         * @property {world.UpdateEntityAction|null} [updateEntity] EntityAction updateEntity
         * @property {world.StartBattleAction|null} [startBattle] EntityAction startBattle
         * @property {world.LeaveBattleAction|null} [leaveBattle] EntityAction leaveBattle
         * @property {world.StopBattleAction|null} [stopBattle] EntityAction stopBattle
         */

        /**
         * Constructs a new EntityAction.
         * @memberof world
         * @classdesc Represents an EntityAction.
         * @implements IEntityAction
         * @constructor
         * @param {world.IEntityAction=} [properties] Properties to set
         */
        function EntityAction(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EntityAction action.
         * @member {number} action
         * @memberof world.EntityAction
         * @instance
         */
        EntityAction.prototype.action = 0;

        /**
         * EntityAction addEntity.
         * @member {world.AddEntityAction|null|undefined} addEntity
         * @memberof world.EntityAction
         * @instance
         */
        EntityAction.prototype.addEntity = null;

        /**
         * EntityAction delEntity.
         * @member {world.DelEntityAction|null|undefined} delEntity
         * @memberof world.EntityAction
         * @instance
         */
        EntityAction.prototype.delEntity = null;

        /**
         * EntityAction move.
         * @member {world.MoveAction|null|undefined} move
         * @memberof world.EntityAction
         * @instance
         */
        EntityAction.prototype.move = null;

        /**
         * EntityAction changeOwner.
         * @member {world.ChangeOwnerAction|null|undefined} changeOwner
         * @memberof world.EntityAction
         * @instance
         */
        EntityAction.prototype.changeOwner = null;

        /**
         * EntityAction updateHp.
         * @member {world.UpdateHpAction|null|undefined} updateHp
         * @memberof world.EntityAction
         * @instance
         */
        EntityAction.prototype.updateHp = null;

        /**
         * EntityAction updateEntity.
         * @member {world.UpdateEntityAction|null|undefined} updateEntity
         * @memberof world.EntityAction
         * @instance
         */
        EntityAction.prototype.updateEntity = null;

        /**
         * EntityAction startBattle.
         * @member {world.StartBattleAction|null|undefined} startBattle
         * @memberof world.EntityAction
         * @instance
         */
        EntityAction.prototype.startBattle = null;

        /**
         * EntityAction leaveBattle.
         * @member {world.LeaveBattleAction|null|undefined} leaveBattle
         * @memberof world.EntityAction
         * @instance
         */
        EntityAction.prototype.leaveBattle = null;

        /**
         * EntityAction stopBattle.
         * @member {world.StopBattleAction|null|undefined} stopBattle
         * @memberof world.EntityAction
         * @instance
         */
        EntityAction.prototype.stopBattle = null;

        /**
         * Creates a new EntityAction instance using the specified properties.
         * @function create
         * @memberof world.EntityAction
         * @static
         * @param {world.IEntityAction=} [properties] Properties to set
         * @returns {world.EntityAction} EntityAction instance
         */
        EntityAction.create = function create(properties) {
            return new EntityAction(properties);
        };

        /**
         * Encodes the specified EntityAction message. Does not implicitly {@link world.EntityAction.verify|verify} messages.
         * @function encode
         * @memberof world.EntityAction
         * @static
         * @param {world.EntityAction} message EntityAction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EntityAction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.action);
            if (message.addEntity != null && Object.hasOwnProperty.call(message, "addEntity"))
                $root.world.AddEntityAction.encode(message.addEntity, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.delEntity != null && Object.hasOwnProperty.call(message, "delEntity"))
                $root.world.DelEntityAction.encode(message.delEntity, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.move != null && Object.hasOwnProperty.call(message, "move"))
                $root.world.MoveAction.encode(message.move, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.changeOwner != null && Object.hasOwnProperty.call(message, "changeOwner"))
                $root.world.ChangeOwnerAction.encode(message.changeOwner, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.updateHp != null && Object.hasOwnProperty.call(message, "updateHp"))
                $root.world.UpdateHpAction.encode(message.updateHp, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.updateEntity != null && Object.hasOwnProperty.call(message, "updateEntity"))
                $root.world.UpdateEntityAction.encode(message.updateEntity, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.startBattle != null && Object.hasOwnProperty.call(message, "startBattle"))
                $root.world.StartBattleAction.encode(message.startBattle, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.leaveBattle != null && Object.hasOwnProperty.call(message, "leaveBattle"))
                $root.world.LeaveBattleAction.encode(message.leaveBattle, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.stopBattle != null && Object.hasOwnProperty.call(message, "stopBattle"))
                $root.world.StopBattleAction.encode(message.stopBattle, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an EntityAction message from the specified reader or buffer.
         * @function decode
         * @memberof world.EntityAction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.EntityAction} EntityAction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EntityAction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.EntityAction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.action = reader.uint32();
                        break;
                    }
                case 10: {
                        message.addEntity = $root.world.AddEntityAction.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.delEntity = $root.world.DelEntityAction.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.move = $root.world.MoveAction.decode(reader, reader.uint32());
                        break;
                    }
                case 13: {
                        message.changeOwner = $root.world.ChangeOwnerAction.decode(reader, reader.uint32());
                        break;
                    }
                case 14: {
                        message.updateHp = $root.world.UpdateHpAction.decode(reader, reader.uint32());
                        break;
                    }
                case 15: {
                        message.updateEntity = $root.world.UpdateEntityAction.decode(reader, reader.uint32());
                        break;
                    }
                case 21: {
                        message.startBattle = $root.world.StartBattleAction.decode(reader, reader.uint32());
                        break;
                    }
                case 22: {
                        message.leaveBattle = $root.world.LeaveBattleAction.decode(reader, reader.uint32());
                        break;
                    }
                case 23: {
                        message.stopBattle = $root.world.StopBattleAction.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for EntityAction
         * @function getTypeUrl
         * @memberof world.EntityAction
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EntityAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.EntityAction";
        };

        return EntityAction;
    })();

    world.notify_actions = (function() {

        /**
         * Properties of a notify_actions.
         * @memberof world
         * @interface Inotify_actions
         * @property {Array.<world.EntityAction>|null} [actions] notify_actions actions
         */

        /**
         * Constructs a new notify_actions.
         * @memberof world
         * @classdesc Represents a notify_actions.
         * @implements Inotify_actions
         * @constructor
         * @param {world.Inotify_actions=} [properties] Properties to set
         */
        function notify_actions(properties) {
            this.actions = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_actions actions.
         * @member {Array.<world.EntityAction>} actions
         * @memberof world.notify_actions
         * @instance
         */
        notify_actions.prototype.actions = $util.emptyArray;

        /**
         * Creates a new notify_actions instance using the specified properties.
         * @function create
         * @memberof world.notify_actions
         * @static
         * @param {world.Inotify_actions=} [properties] Properties to set
         * @returns {world.notify_actions} notify_actions instance
         */
        notify_actions.create = function create(properties) {
            return new notify_actions(properties);
        };

        /**
         * Encodes the specified notify_actions message. Does not implicitly {@link world.notify_actions.verify|verify} messages.
         * @function encode
         * @memberof world.notify_actions
         * @static
         * @param {world.notify_actions} message notify_actions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_actions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.actions != null && message.actions.length)
                for (let i = 0; i < message.actions.length; ++i)
                    $root.world.EntityAction.encode(message.actions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a notify_actions message from the specified reader or buffer.
         * @function decode
         * @memberof world.notify_actions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.notify_actions} notify_actions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_actions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.notify_actions();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.actions && message.actions.length))
                            message.actions = [];
                        message.actions.push($root.world.EntityAction.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_actions
         * @function getTypeUrl
         * @memberof world.notify_actions
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_actions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.notify_actions";
        };

        return notify_actions;
    })();

    world.notify_battle_result = (function() {

        /**
         * Properties of a notify_battle_result.
         * @memberof world
         * @interface Inotify_battle_result
         * @property {number|null} [srcEid] notify_battle_result srcEid
         * @property {number|null} [dstEid] notify_battle_result dstEid
         * @property {number|null} [result] notify_battle_result result
         */

        /**
         * Constructs a new notify_battle_result.
         * @memberof world
         * @classdesc Represents a notify_battle_result.
         * @implements Inotify_battle_result
         * @constructor
         * @param {world.Inotify_battle_result=} [properties] Properties to set
         */
        function notify_battle_result(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_battle_result srcEid.
         * @member {number} srcEid
         * @memberof world.notify_battle_result
         * @instance
         */
        notify_battle_result.prototype.srcEid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * notify_battle_result dstEid.
         * @member {number} dstEid
         * @memberof world.notify_battle_result
         * @instance
         */
        notify_battle_result.prototype.dstEid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * notify_battle_result result.
         * @member {number} result
         * @memberof world.notify_battle_result
         * @instance
         */
        notify_battle_result.prototype.result = 0;

        /**
         * Creates a new notify_battle_result instance using the specified properties.
         * @function create
         * @memberof world.notify_battle_result
         * @static
         * @param {world.Inotify_battle_result=} [properties] Properties to set
         * @returns {world.notify_battle_result} notify_battle_result instance
         */
        notify_battle_result.create = function create(properties) {
            return new notify_battle_result(properties);
        };

        /**
         * Encodes the specified notify_battle_result message. Does not implicitly {@link world.notify_battle_result.verify|verify} messages.
         * @function encode
         * @memberof world.notify_battle_result
         * @static
         * @param {world.notify_battle_result} message notify_battle_result message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_battle_result.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.srcEid != null && Object.hasOwnProperty.call(message, "srcEid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.srcEid);
            if (message.dstEid != null && Object.hasOwnProperty.call(message, "dstEid"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.dstEid);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.result);
            return writer;
        };

        /**
         * Decodes a notify_battle_result message from the specified reader or buffer.
         * @function decode
         * @memberof world.notify_battle_result
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.notify_battle_result} notify_battle_result
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_battle_result.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.notify_battle_result();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.srcEid = reader.uint64();
                        break;
                    }
                case 2: {
                        message.dstEid = reader.uint64();
                        break;
                    }
                case 3: {
                        message.result = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_battle_result
         * @function getTypeUrl
         * @memberof world.notify_battle_result
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_battle_result.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.notify_battle_result";
        };

        return notify_battle_result;
    })();

    world.notify_occupy_result = (function() {

        /**
         * Properties of a notify_occupy_result.
         * @memberof world
         * @interface Inotify_occupy_result
         * @property {number|null} [type] notify_occupy_result type
         * @property {number|null} [aid] notify_occupy_result aid
         * @property {number|null} [bid] notify_occupy_result bid
         * @property {number|null} [eid] notify_occupy_result eid
         */

        /**
         * Constructs a new notify_occupy_result.
         * @memberof world
         * @classdesc Represents a notify_occupy_result.
         * @implements Inotify_occupy_result
         * @constructor
         * @param {world.Inotify_occupy_result=} [properties] Properties to set
         */
        function notify_occupy_result(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * notify_occupy_result type.
         * @member {number} type
         * @memberof world.notify_occupy_result
         * @instance
         */
        notify_occupy_result.prototype.type = 0;

        /**
         * notify_occupy_result aid.
         * @member {number} aid
         * @memberof world.notify_occupy_result
         * @instance
         */
        notify_occupy_result.prototype.aid = 0;

        /**
         * notify_occupy_result bid.
         * @member {number} bid
         * @memberof world.notify_occupy_result
         * @instance
         */
        notify_occupy_result.prototype.bid = 0;

        /**
         * notify_occupy_result eid.
         * @member {number} eid
         * @memberof world.notify_occupy_result
         * @instance
         */
        notify_occupy_result.prototype.eid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new notify_occupy_result instance using the specified properties.
         * @function create
         * @memberof world.notify_occupy_result
         * @static
         * @param {world.Inotify_occupy_result=} [properties] Properties to set
         * @returns {world.notify_occupy_result} notify_occupy_result instance
         */
        notify_occupy_result.create = function create(properties) {
            return new notify_occupy_result(properties);
        };

        /**
         * Encodes the specified notify_occupy_result message. Does not implicitly {@link world.notify_occupy_result.verify|verify} messages.
         * @function encode
         * @memberof world.notify_occupy_result
         * @static
         * @param {world.notify_occupy_result} message notify_occupy_result message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        notify_occupy_result.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.type);
            if (message.aid != null && Object.hasOwnProperty.call(message, "aid"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.aid);
            if (message.bid != null && Object.hasOwnProperty.call(message, "bid"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.bid);
            if (message.eid != null && Object.hasOwnProperty.call(message, "eid"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.eid);
            return writer;
        };

        /**
         * Decodes a notify_occupy_result message from the specified reader or buffer.
         * @function decode
         * @memberof world.notify_occupy_result
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {world.notify_occupy_result} notify_occupy_result
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        notify_occupy_result.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.world.notify_occupy_result();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.uint32();
                        break;
                    }
                case 2: {
                        message.aid = reader.uint32();
                        break;
                    }
                case 3: {
                        message.bid = reader.uint32();
                        break;
                    }
                case 4: {
                        message.eid = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Gets the default type url for notify_occupy_result
         * @function getTypeUrl
         * @memberof world.notify_occupy_result
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        notify_occupy_result.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/world.notify_occupy_result";
        };

        return notify_occupy_result;
    })();

    return world;
})();

export { $root as default };
