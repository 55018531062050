import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";

type GetPosInput = [number?];

export class GetPos extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const [targetEid] = env.input as GetPosInput;
        const target = env.context.findElement(targetEid!);
        if (!target) {
            node.warn(`invalid targetEid: ${targetEid}`);
            return "failure";
        }

        const pos = new Laya.Vector3();
        pos.cloneFrom(target.transform.position);
        env.output.push(pos);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "GetPos",
            type: "Action",
            desc: "获取单位坐标",
            args: [],
            input: ["目标?"],
            output: ["坐标"],
            doc: `
                + 如果输入变量为空，则默认为行为树当前归属的实体`,
        };
    }
}
