import { TreeRunner } from "../../../../../core/behavior3";
import * as ecs from "../../../../../core/ecs";
import { Pool } from "../../../../../core/pool";
import { PveDef } from "../../PveDefs";
import { PveServer } from "../../PveServer";
import { BulletType } from "../../btree/actions/CreateBullet";
import { BulletTreeEnv, PveSvrBulletComponent } from "../components/PveSvrBulletCompoment";
import { PveSvrElementComponent } from "../components/PveSvrElementComponent";

export class PveSvrBulletSystem extends ecs.System {
    declare context: PveServer;

    static readonly TICK = 0.1;

    override onCreate(): void {
        this.registerHandler(
            PveSvrBulletComponent,
            this._onAddPveSvrBulletComponent,
            this._onDelPveSvrBulletComponent
        );
    }

    override update(dt: number) {
        const time = this.context.time;
        this.ecs.getComponents(PveSvrBulletComponent).forEach((bullet) => {
            const ownerEid = bullet.skill.owner.eid;
            if (
                !this.ecs.getComponent(ownerEid, PveSvrElementComponent) &&
                bullet.bulletType === BulletType.Static
            ) {
                this.context.removeElement(bullet.getComponent(PveSvrElementComponent)!);
                return;
            }

            if (time - bullet.lastUpdate > PveSvrBulletSystem.TICK && bullet.tree) {
                const status = bullet.tree.run();
                bullet.lastUpdate = time;
                if (status !== "running") {
                    this.context.removeElement(bullet.getComponent(PveSvrElementComponent)!);
                    return;
                }
            }

            if (bullet.bulletType === BulletType.Static && bullet.follow && bullet.followEid) {
                const follow = this.ecs.getComponent(bullet.followEid, PveSvrElementComponent);
                if (follow) {
                    this.context.setPosition(
                        bullet.getComponent(PveSvrElementComponent)!,
                        follow.transform.position
                    );
                }
            }
        });
    }

    private async _onAddPveSvrBulletComponent(bullet: PveSvrBulletComponent) {
        if (bullet.btree) {
            const checker = bullet.checker;
            const tree = await this.context.loadAiTree(bullet.btree);
            if (!checker()) {
                return;
            }
            if (bullet.skill.tree) {
                const owner = bullet.getComponent(PveSvrElementComponent)!;
                const env = Pool.obtain(BulletTreeEnv, this.context, owner, bullet.skill);
                bullet.tree = new TreeRunner(env, tree);
                if (bullet.followEid) {
                    bullet.tree.env.set(PveDef.SKILL_TARGET_EIDS, [bullet.followEid]);
                } else {
                    // 普攻
                    bullet.tree.env.set(
                        PveDef.SKILL_TARGET_EIDS,
                        bullet.skill.tree.env.get(PveDef.SKILL_TARGET_EIDS)
                    );
                }
            }
        }
    }

    private _onDelPveSvrBulletComponent(bullet: PveSvrBulletComponent) {
        bullet.tree?.clear();
        Pool.free(bullet.tree?.env);
        bullet.tree = null;
    }
}
