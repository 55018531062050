/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { AllianceManageItem } from "./AllianceManageItem";
import { UIButton } from "../../../core/ui/UIButton";

/**
 * resources/prefab/alliance/UIAllianceInfo.lh
 */
export class UIAllianceInfoBase extends Laya.Box {
    public icon!: Laya.Image;
    public btnMember!: AllianceManageItem;
    public btnContact!: AllianceManageItem;
    public btnApply!: UIButton;
    public leaderName!: Laya.Label;
    public memberCount!: Laya.Label;
    public allianceDecl!: Laya.Label;
    public allianceName!: Laya.Label;
    public error!: Laya.Label;
}

