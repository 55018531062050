import { app } from "../../../app";
import { Pool } from "../../../core/pool";
import { Mesh, MeshFilter, Quad } from "../../../core/render/mesh-render";
import {
    ITMContext,
    TMLayer,
    TMLayerName,
    TMMapInfo,
    TMPropKey,
    TMTileSet,
    TMWorld,
    TMWorldMap,
} from "./tm-def";
import { TMCloudElement, TMElement, TMObjectElement, TMStaticElement } from "./tm-element";
import { TMUtil } from "./tm-util";

export type LayerRender = {
    layer: Laya.Sprite3D;
    mesh: Mesh;
    count: number;
};

const tmpFocusPosition = new Laya.Vector3();

const toIndex = (x: number, z: number) => {
    return (x << 12) | (z << 0);
};

export class Tilemap {
    /**
     * 获取地图根节点
     * @returns 根节点
     */
    getRoot(): Laya.Sprite3D {
        return this._root;
    }

    /**
     *  获取元素映射总表
     */
    getAllMap() {
        return this._allMap;
    }

    /**
     * 添加对象元素
     * @param eid 实体ID
     * @param gridX X坐标
     * @param gridY Y坐标
     * @returns 对象元素的唯一ID
     */
    addObjectElement(
        eid: number,
        gridX: number,
        gridY: number,
        props: Map<string, unknown>
    ): number | undefined {
        return this._tryAdd(gridX, gridY, props, TMLayerName.Object, eid);
    }

    delObjectElementByKey(key: number): void {
        const element = this._allMap.get(key);
        if (element) {
            this._tryDel(element.gridX, element.gridY, (layerName: string) => {
                return layerName === TMLayerName.Object;
            });
        }
    }

    delObjectElementByEid(eid: number): void {
        for (const element of this._allMap.values()) {
            if (element.eid === eid && element.layerName === TMLayerName.Object) {
                this._tryDel(element.gridX, element.gridY, (layerName: string) => {
                    return layerName === TMLayerName.Object;
                });
                return;
            }
        }
    }

    /**
     * 根据唯一ID获取对象元素
     * @param uid 唯一ID
     * @returns 对象元素
     */
    getObjectElementByKey(key: number): TMObjectElement | undefined {
        const element = this._allMap.get(key);
        if (element?.layerName === TMLayerName.Object) {
            return element as TMObjectElement;
        }
        return undefined;
    }

    /**
     * 根据实体ID获取对象元素
     * @param eid 实体ID
     * @returns 对象元素
     */
    getObjectElementByEid(eid: number): TMObjectElement | undefined {
        for (const element of this._allMap.values()) {
            if (element.eid === eid && element.layerName === TMLayerName.Object) {
                return element as TMObjectElement;
            }
        }
        return undefined;
    }

    getElementByKey(key: number): TMElement | undefined {
        return this._allMap.get(key);
    }

    /**
     * 根据坐标获取元素
     * @param gridX X坐标
     * @param gridY Y坐标
     * @param layerName 层级名称
     * @returns 元素对象
     */
    getElementByPos(gridX: number, gridY: number, layerName: TMLayerName): TMElement | undefined {
        const uidMap = this._posMap.get(layerName);
        if (uidMap) {
            if (layerName === TMLayerName.Static || layerName === TMLayerName.Object) {
                for (const uid of uidMap.values()) {
                    const element = this._allMap.get(uid) as TMStaticElement | TMObjectElement;
                    if (
                        element &&
                        TMUtil.inRect(
                            gridX,
                            gridY,
                            element.startX,
                            element.startY,
                            element.width,
                            element.height
                        )
                    ) {
                        return element;
                    }
                }
            } else {
                const key = uidMap.get(toIndex(gridX, gridY));
                if (key) {
                    return this._allMap.get(key);
                }
            }
        }
        return undefined;
    }

    /**
     * 根据矩形范围获取元素列表
     * @param gridX X坐标
     * @param gridY Y坐标
     * @param width 矩形宽度
     * @param height 矩形高度
     * @param layerName 层级名称
     * @returns 元素对象列表
     */
    getElementsByRect(
        gridX: number,
        gridY: number,
        width: number,
        height: number,
        layerName: TMLayerName
    ): TMElement[] {
        const elements: TMElement[] = [];
        for (let $x = gridX; $x < gridX + width; $x++) {
            for (let $y = gridY; $y < gridY + height; $y++) {
                const element = this.getElementByPos($x, $y, layerName);
                if (element) {
                    elements.push(element);
                }
            }
        }
        return elements;
    }

    /**
     * 根据层级名称获取元素列表
     * @param layerName 层级名称
     * @returns 元素对象列表
     */
    getElementsByLayer(layerName: TMLayerName): TMElement[] {
        const elements: TMElement[] = [];
        const uidMap = this._posMap.get(layerName);
        if (uidMap) {
            for (const uid of uidMap.values()) {
                const element = this._allMap.get(uid);
                if (element) {
                    elements.push(element);
                }
            }
        }
        return elements;
    }

    /**
     * 指定位置是否阻挡块
     * @param gridX X坐标
     * @param gridY Y坐标
     */
    isBlock(gridX: number, gridY: number): boolean {
        const uidMap = this._posMap.get(TMLayerName.Block);
        const uid = uidMap?.get(toIndex(gridX, gridY));
        return Boolean(uid);
    }

    /**
     * 根据GID获取图集序列帧的序号
     * @param atlasName 图集名称
     * @param gid 资源ID
     * @returns
     */
    getAtlasFrameIdx(gid: number) {
        const tileset = this._textureMap.get(gid);
        if (tileset) {
            return {
                image: tileset.path,
                index: gid - tileset.firstgid,
            };
        }
        return null;
    }

    /**
     * 根据GID获取纹理资源名称
     * TODO: 优化查询
     * @param textureName 纹理名称
     * @param gid 资源ID
     * @returns
     */
    getTextureResName(gid: number): string {
        const titleset = this._textureMap.get(gid);
        if (titleset) {
            return titleset.path.split("/").at(-1)?.split(".").at(0) ?? "";
        }
        return "";
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    public cloudClearKeys: string[] | undefined;

    private _root!: Laya.Sprite3D;
    private _world: TMWorld | undefined;

    private _textureMap: Map<number, TMTileSet> = new Map();

    private _lastRect: Laya.Rectangle = Laya.Rectangle.create();
    private _curRect: Laya.Rectangle = Laya.Rectangle.create();

    private _allMap: Map<number, TMElement> = new Map();
    private _posMap: Map<TMLayerName, Map<number, number>> = new Map();

    private _layerRenderMap: Map<string, LayerRender> = new Map();

    private _tilesetRef!: TMWorldMap;

    constructor(readonly context: ITMContext) {}

    get isReady() {
        return !!this._root && !!this._world;
    }

    get visionRect(): Readonly<Laya.Rectangle> {
        return this._lastRect;
    }

    async addQuad(
        layerName: TMLayerName,
        quad: Quad,
        renderMode: Laya.MaterialRenderMode,
        materials?: Laya.Material
    ) {
        if (!quad.texture) {
            console.error(`no texture for quad: layer=${layerName}`);
            return;
        }
        const url = quad.texture.bitmap.url;
        let render = this._layerRenderMap.get(url);
        if (!render) {
            const mapLayer = this._root.getChildByName(layerName) as Laya.Sprite3D;
            render = {} as LayerRender;
            render.layer = new Laya.Sprite3D();
            render.layer.layer = mapLayer.layer;
            render.layer.name = quad.texture.bitmap.url;
            const meshFilter = render.layer.addComponent(MeshFilter);
            render.mesh = new Mesh();
            render.mesh.name = quad.texture.bitmap.url;
            render.count = 0;
            meshFilter.sharedMesh = render.mesh;
            const meshRenderer = render.layer.addComponent(Laya.MeshRenderer);
            if (materials) {
                meshRenderer.material = materials;
            } else {
                const mat = new Laya.BlinnPhongMaterial();
                mat.albedoTexture = quad.texture.bitmap;
                mat.renderMode = renderMode;
                meshRenderer.material = mat;
            }

            mapLayer.addChild(render.layer);
            this._layerRenderMap.set(url, render);
        }
        render.mesh.addQuad(quad);
        render.count++;
    }

    removeQuad(layerName: TMLayerName, quad: Quad) {
        if (!quad.texture) {
            return;
        }
        const render = this._layerRenderMap.get(quad.texture.bitmap.url);
        if (render) {
            render.mesh.removeQuad(quad);
            render.count--;
        }
    }

    async onCreate() {
        this._root = this.context.scene3D.getChildByName("world-map") as Laya.Sprite3D;

        const checker = () => this._root && !this._root.destroyed;

        this._world = await app.loader.loadJson(`${this.context.mapDir}/world.json`);
        this._tilesetRef = await app.loader.loadJson<TMWorldMap>(
            `${this.context.mapDir}/world-tileset-ref.json`
        );

        if (!checker()) {
            return;
        }

        this._tilesetRef.tilesets.forEach((tileset) => {
            for (let i = 0; i < tileset.count; i++) {
                this._textureMap.set(tileset.firstgid + i, tileset);
            }
        });
    }

    destroy() {
        this._allMap.forEach((element) => {
            element.recover();
        });
        this._layerRenderMap.forEach((layer) => layer.mesh.destroy());
        this._layerRenderMap.clear();
        this._world = undefined;
        this._textureMap.clear();
        this._lastRect.reset();
        this._curRect.reset();
        this._allMap.clear();
        this._posMap.clear();
    }

    /**
     * @param needClearCloudKey 云标识 01 ~ 20
     *
     */
    clearCloud(needClearCloudKey: string): void {
        const uidMap = this._posMap.get(TMLayerName.Cloud);
        if (!uidMap) return;
        uidMap.forEach((uid) => {
            const element = this._allMap.get(uid) as TMCloudElement;
            if (element) {
                if (element.resIndexKey === needClearCloudKey) {
                    this._tryDel(element.gridX, element.gridY, (layerName: string) => {
                        return layerName === TMLayerName.Cloud;
                    });
                }
            }
        });
    }

    update(position: Laya.Vector3): void {
        if (!this._root || !this._world) {
            return;
        }

        const curX = Math.floor(position.x - TMUtil.VISION_WIDTH * TMUtil.VISION_RATIO);
        const curY = Math.floor(position.z - TMUtil.VISION_HEIGHT * TMUtil.VISION_RATIO);
        this._curRect.setTo(curX, curY, TMUtil.VISION_WIDTH, TMUtil.VISION_HEIGHT);

        tmpFocusPosition.x = curX - this._lastRect.x;
        tmpFocusPosition.z = curY - this._lastRect.y;

        if (tmpFocusPosition.length() < 0.5) {
            return;
        }

        const addArr = [];
        const delArr = [];

        if (!this._lastRect.isEmpty()) {
            for (let x = curX; x < curX + this._curRect.width; x++) {
                for (let y = curY; y < curY + this._curRect.height; y++) {
                    if (!this._lastRect.contains(x, y)) {
                        addArr.push([x, y]);
                    }
                }
            }
            const lastX = this._lastRect.x;
            const lastY = this._lastRect.y;
            for (let x = lastX; x < lastX + this._lastRect.width; x++) {
                for (let y = lastY; y < lastY + this._lastRect.height; y++) {
                    if (!this._curRect.contains(x, y)) {
                        delArr.push([x, y]);
                    }
                }
            }
        } else {
            for (let x = curX; x < curX + this._curRect.width; x++) {
                for (let y = curY; y < curY + this._curRect.height; y++) {
                    addArr.push([x, y]);
                }
            }
        }
        this._lastRect.copyFrom(this._curRect);

        for (let i = 0; i < delArr.length; i++) {
            const [x, y] = delArr[i];
            this._tryDel(x, y, (layerName) => {
                return layerName !== TMLayerName.Object && layerName !== TMLayerName.Marker;
            });
        }

        for (let i = 0; i < addArr.length; i++) {
            const [x, y] = addArr[i];
            this._searchInMap(x, y, (layerName) => {
                return layerName !== TMLayerName.Object && layerName !== TMLayerName.Marker;
            });
        }
    }

    private async _searchInMap(
        gridX: number,
        gridY: number,
        filterFunc?: (layerName: string) => boolean
    ) {
        for (let i = 0; i < this._world!.maps.length; i++) {
            const info = this._world!.maps[i];

            const inRect = TMUtil.inRect(gridX, gridY, info.x, info.y, info.width, info.height);
            if (!inRect) {
                continue;
            }

            if (!info.worldMap) {
                const checker = () => this._root && !this._root.destroyed;
                info.worldMap = (await app.loader.loadJson(
                    `${this.context.mapDir}/` + info.fileName
                )) as TMWorldMap;

                if (!checker()) {
                    return;
                }
            }

            for (let j = 0; j < info.worldMap.layers.length; j++) {
                const layer = info.worldMap.layers[j];
                const layerName = layer.name as TMLayerName;
                if (filterFunc && !filterFunc(layerName)) {
                    continue;
                }
                const props = this._getProps(info, layer, gridX, gridY);
                if (!props) {
                    continue;
                }
                this._tryAdd(gridX, gridY, props, layerName);
            }
        }
    }

    private _tryAdd(
        gridX: number,
        gridY: number,
        props: Map<string, unknown>,
        layerName: TMLayerName,
        eid?: number
    ): number | undefined {
        // 如果是迷雾，则判断是否需要显示
        if (
            layerName === TMLayerName.Cloud &&
            this.cloudClearKeys &&
            this.cloudClearKeys.length > 0
        ) {
            const gid = props.get(TMPropKey.Gid) as number;
            const editorResName = this.getTextureResName(gid);
            const resNameArr = editorResName.split("_");
            const cloudKey = resNameArr[resNameArr.length - 1];
            // console.log("判断迷雾是否被清除,被清除则需要return出去");
            if (this.cloudClearKeys.indexOf(cloudKey) !== -1) {
                return;
            }
        }

        const cls = TMUtil.layerToCls(layerName);
        if (!cls) {
            return undefined;
        }
        let uidMap = this._posMap.get(layerName);
        if (!uidMap) {
            uidMap = new Map();
            this._posMap.set(layerName, uidMap);
        }
        const idx = toIndex(gridX, gridY);
        const key = uidMap.get(idx) ?? 0;

        let element = this._allMap.get(key);
        if (!element) {
            element = Pool.obtain(cls);
            element.init(this, gridX, gridY, props, layerName, eid);
            uidMap.set(idx, element.key);
            this._allMap.set(element.key, element);

            element.draw();

            this.context.onAddElement(element);
        }
    }

    private _tryDel(gridX: number, gridY: number, filterFunc?: (layerName: string) => boolean) {
        this._posMap.forEach((uidMap, layerName) => {
            if (filterFunc && !filterFunc(layerName)) {
                return;
            }
            const idx = toIndex(gridX, gridY);
            const key = uidMap.get(idx) ?? 0;
            const element = this._allMap.get(key);

            if (element) {
                this.context.onDelElement(element);
                element.recover();
            }
            uidMap.delete(idx);
            this._allMap.delete(key);
        });
    }

    private _getProps(
        info: TMMapInfo,
        layer: TMLayer,
        gridX: number,
        gridY: number
    ): Map<string, unknown> | undefined {
        if (layer.data) {
            const idx = (gridY - info.y) * info.width + (gridX - info.x);
            const gid = layer.data[idx];
            if (!gid || gid === 0) {
                return undefined;
            }
            const props = new Map<string, unknown>();
            props.set(TMPropKey.Gid, gid);
            return props;
        } else if (layer.objects) {
            const $gridX = gridX - info.x;
            const $gridY = gridY - info.y;
            const $key = `${$gridX}_${$gridY}`;
            const $targetObj = layer.objects[$key];
            if (!$targetObj) {
                return undefined;
            }
            const props = new Map<string, unknown>();
            const properties = $targetObj.properties;
            if (properties) {
                for (const k in properties) {
                    props.set(k, properties[k]);
                }
            }
            return props;
        } else {
            console.warn("该层没有数据", layer.name);
            return undefined;
        }
    }
}
