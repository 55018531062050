const { regClass } = Laya;
import proto from "../../../def/auto/proto";
import { DrawCardLo } from "../vo/DrawCardLo";
import { UIDrawCardGainRewardBase } from "./UIDrawCardGainReward.generated";

export interface UIDrawCardGainHeroArgs {
    loData: DrawCardLo;
    rewards: proto.bag.ItemReward[];
}
@regClass('-C2MCUEFTe2efnAIv2OwpA')
export class UIDrawCardGainReward extends UIDrawCardGainRewardBase {
    public rewards: proto.bag.ItemReward[] = [];
    public loData!: DrawCardLo;

    override open(closeOther?: boolean, param?: UIDrawCardGainHeroArgs): void {
        this.loData = param!.loData;
        this.rewards = param!.rewards;
        super.open(closeOther, param);
    }
}
