import { PvpCampStyle } from "../PVPUtils";

const { regClass, classInfo } = Laya;

@regClass('DoDFBURGTliffq-iq-be6A')
@classInfo({
    menu: "Battle",
})
export class VFXTroopBattleComp extends Laya.Component {
    // 用来设置VFX的颜色
    static readonly VFX_SELF_COLOR: [number, number, number] = [39 / 255, 89 / 255, 255 / 255]; //"#2759ff";//blue
    static readonly VFX_SELF_COLOR2: [number, number, number] = [0 / 255, 55 / 255, 255 / 255]; //"#0037ff";//blue2

    static readonly VFX_ENEMY_COLOR: [number, number, number] = [255 / 255, 59 / 255, 33 / 255]; //"#ff2c2c";//red
    static readonly VFX_ENEMY_COLOR2: [number, number, number] = [255 / 255, 0 / 255, 0 / 255]; //"#ff0000";//red2

    static readonly VFX_FRIEND_COLOR: [number, number, number] = [255 / 255, 255 / 255, 255 / 255]; //"#ffffff";//white

    setupColor(style: PvpCampStyle) {
        const color1 =
            style === PvpCampStyle.SELF
                ? VFXTroopBattleComp.VFX_SELF_COLOR
                : VFXTroopBattleComp.VFX_ENEMY_COLOR;
        const color2 =
            style === PvpCampStyle.SELF
                ? VFXTroopBattleComp.VFX_SELF_COLOR2
                : VFXTroopBattleComp.VFX_ENEMY_COLOR2;

        if (!this.owner) {
            return;
        }
        const chongjibo = this.owner.getChildByName("chongjibo") as Laya.Sprite3D;
        if (chongjibo) {
            const ring001Particle = chongjibo.getComponent(Laya.ShurikenParticleRenderer);
            if (ring001Particle?.particleSystem?.startColorConstant) {
                ring001Particle.particleSystem.startColorConstant.setValue(
                    ...color1,
                    ring001Particle.particleSystem.startColorConstant.w
                );
                const maxParticles = ring001Particle.particleSystem.maxParticles;
                // 重新设置maxParticles，触发粒子重置
                ring001Particle.particleSystem.maxParticles = maxParticles;
            }
        }

        const chongjibo_B = chongjibo.getChildByName("chongjibo_B") as Laya.Sprite3D;
        if (chongjibo_B) {
            const glowParticle = chongjibo_B.getComponent(Laya.ShurikenParticleRenderer);
            if (glowParticle?.particleSystem?.startColorConstant) {
                glowParticle.particleSystem.startColorConstant.setValue(
                    ...color2,
                    glowParticle.particleSystem.startColorConstant.w
                );
                const maxParticles = glowParticle.particleSystem.maxParticles;
                // 重新设置maxParticles，触发粒子重置
                glowParticle.particleSystem.maxParticles = maxParticles;
            }
        }
    }
}
