import { Mediator } from "../../core/ui-mediator";
import { UIAllianceApplyList } from "./ui-runtime/UIAllianceApplyList";

const { regClass, property } = Laya;

@regClass('21crBerkSgy7XkoDhQAvZQ')
export class UIAllianceApplyListMediator extends Mediator {
    declare owner: UIAllianceApplyList;

    override onAwake(): void {
        super.onAwake();
    }
}
