import { app } from "../../../app";
import { IVector2Like, IVector3Like } from "../../../core/laya";
import { Service } from "../../../core/service";
import proto from "../../../def/auto/proto";
import { BattleSceneConfigVoMap } from "../vo/BattleSceneConfigVoMap";

export class PvpService extends Service {
    readonly sceneConfigVoMap = new BattleSceneConfigVoMap();

    override onCreate() {}

    override onStartInit() {}

    override onDestroy() {}

    // ------------------------------------------------------------------------
    // rpc call
    // ------------------------------------------------------------------------
    async requestLoad() {
        return await app.service.network.call(proto.world.c2s_load.create(), proto.world.s2c_load);
    }

    async requestChangeViewport(viewport: IVector3Like) {
        return await app.service.network.call(
            proto.world.c2s_change_viewport.create({
                pos: { x: viewport.x, y: viewport.z },
            }),
            proto.world.s2c_change_viewport
        );
    }

    async requestCloseViewport() {
        return await app.service.network.call(
            proto.world.c2s_close_viewport.create(),
            proto.world.s2c_close_viewport
        );
    }

    async requestTroopMoveTo(troopEid: number, dest: IVector2Like, bAutoBackHome: boolean) {
        return await app.service.network.call(
            proto.world.c2s_troop_move_to.create({
                troopEid: troopEid,
                dstPos: dest,
                autoRetreat: !!bAutoBackHome,
            }),
            proto.world.s2c_troop_move_to
        );
    }

    async requestEnterScene(sceneUid: number) {
        return await app.service.network.call(
            proto.battle.c2s_enter_scene.create({
                sceneUid: sceneUid,
            }),
            proto.battle.s2c_enter_scene
        );
    }

    async requestEnterSlot(sceneUid: number, slotIdx: number) {
        return await app.service.network.call(
            proto.battle.c2s_enter_slot.create({
                sceneUid: sceneUid,
                slotIdx: slotIdx,
            }),
            proto.battle.s2c_enter_slot
        );
    }

    async requestExit(sceneUid: number) {
        return await app.service.network.call(
            proto.battle.c2s_exit.create({ sceneUid: sceneUid }),
            proto.battle.s2c_exit
        );
    }

    async requestLoadReport(battleUid: number) {
        return await app.service.network.call(
            proto.battle.c2s_load_report.create({
                battleUid: battleUid,
            }),
            proto.battle.s2c_load_report
        );
    }

    async requestLoadReportList() {
        return await app.service.network.call(
            proto.battle.c2s_load_report_list.create(),
            proto.battle.s2c_load_report_list
        );
    }
}
