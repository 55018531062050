const { regClass } = Laya;
import { app } from "../../../app";
import { Util } from "../../../core/utils/util";
import proto from "../../../def/auto/proto";
import { GeneratedMailRow } from "../../../def/auto/table.generated";
import { TableUtil } from "../../table/TableUtil";
import { MailInfoUIBase } from "./MailInfoUI.generated";

@regClass('v0c82FniR9WEk3yWuTPOCw')
export class MailInfoUI extends MailInfoUIBase {
    oepnData!: proto.mail.MailInfo; //界面打开数据
    refData!: GeneratedMailRow; //配置表数据
    rewards!: proto.bag.IItem[]; //奖励数据

    override open(closeOther?: boolean | undefined, param?: any): void {
        this.oepnData = param;
        if (this.oepnData.id) {
            this.refData = TableUtil.getRow(app.service.table.mail, {
                id: this.oepnData.id,
            }) as GeneratedMailRow;
            if (this.refData.reward) {
                this.rewards = Util.toBagItemArray(this.refData.reward as proto.bag.Item[]);
            }
        } else {
            if (this.oepnData.reward && this.oepnData.reward?.length > 0) {
                this.rewards = this.oepnData.reward;
            }
        }
        super.open(closeOther, param);
    }
}
