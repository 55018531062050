import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { UIItemUse } from "./ui-runtime/UIItemUse";

const { regClass, property } = Laya;
@regClass('ui7ApwCnRiOCZv3T86LXnQ') //简单的使用框
export class UIItemUseMediator extends Mediator {
    declare owner: UIItemUse;

    //组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
    override onAwake(): void {
        super.onAwake();
        this.owner.imgMask.on(Laya.Event.CLICK, this.owner, this.owner.destroy);
        this.owner.btnUse.on(Laya.Event.CLICK, this, this.onClickUse);
        this.initPanel();
    }

    private initPanel() {
        const itemData = this.owner.itemData;
        this.owner.labelName.text = itemData.name;
        this.owner.labDesc.text = itemData.desc;
        this.owner.btnUse.visible = itemData.use === undefined;
        this.owner.labCount.text = `${itemData.count}`;
        const itemLo = app.service.bag.itemLoMap.get(itemData.id);
        this.owner.iconItem.updateGoods(itemLo);
    }

    private onClickUse() {
        const itemData = this.owner.itemData;
        app.service.bag.requestUseItem({ itemId: itemData.id, num: itemData.count });
    }
}
