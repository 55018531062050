import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { IconUI } from "../../misc/ui-runtime/IconUI";
import { UIItemUseRandom } from "./ui-runtime/UIItemUseRandom";
import { ItemLo } from "./vo/ItemLo";

const { regClass, property } = Laya;

@regClass('5TTzvcQyRkmQNT9_Ys413w') //随机获得弹窗
export class UIItemUseRandomMediator extends Mediator {
    declare owner: UIItemUseRandom;

    //组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
    override onAwake(): void {
        super.onAwake();
        this.initPanel();
        this.initEvent();
    }

    //初始化界面信息
    initPanel() {
        const itemData = this.owner.itemData;
        const max = app.service.bag.itemVoMap.getItemAmount(itemData.id);
        this.owner.sliderCom.setSlider(0, max, 1);
        this.owner.sliderCom.onSliderChange = () => {
            const value = this.owner.sliderCom.value;
            this.owner.labUseCount.text = `数量:${value}`;
        };
        const tlData: ItemLo[] = [];
        const args = itemData.args as number[][];

        for (const reward of args) {
            const itemLo = app.service.bag.itemLoMap.get(reward[0]);
            tlData.push(itemLo);
        }
        this.owner.listItem.array = tlData;

        this.owner.labCount.text = `${itemData.count}`;
        const itemLo = app.service.bag.itemLoMap.get(itemData.id);
        this.owner.labUseCount.text = `${this.owner.sliderCom.value}/${max}`;
        this.owner.iconItem.updateGoods(itemLo);
        this.owner.labelName.text = itemData.name;
    }

    //初始化
    initEvent() {
        this.owner.imgMask.on(Laya.Event.CLICK, this.owner, this.owner.destroy);
        this.owner.btnUse.on(Laya.Event.CLICK, this, this.onClickUse);
        this.owner.listItem.renderHandler = new Laya.Handler(this, this.updateItem);
    }

    updateItem(cell: IconUI, index: number) {
        const itemData = cell.dataSource as ItemLo;
        cell.updateGoods(itemData);
    }

    onClickUse() {
        const value = this.owner.sliderCom.value;
        if (value) {
            app.service.bag.requestOpenRandom({
                itemId: this.owner.itemData.id,
                num: this.owner.sliderCom.value,
            });
            this.owner.destroy();
        } else {
            app.ui.toast("请选择数量");
        }
    }
}
