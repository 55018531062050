const { regClass } = Laya;
import { BDFuncLevelBlockUIBase } from "./BDFuncLevelBlockUI.generated";

@regClass('RbhVkX48QOevFeEQcdjuZw')
export class BDFuncLevelBlockUI extends BDFuncLevelBlockUIBase {
    public setLevel(curLevel: number, nextLevel: number): void {
        const isMax = curLevel === nextLevel;
        this.leb_level.text = `等级：${curLevel}`;
        if (!isMax) {
            // 等级未满
            this.leb_level.x = 216;
            this.leb_next_level.text = `${nextLevel}`;
            this.img_jiantou.visible = true;
        } else {
            // 等级已满
            this.leb_level.x = 300;
            this.leb_next_level.text = "";
            this.img_jiantou.visible = false;
        }
    }
}
