import { app } from "../../../../app";
import { Mediator } from "../../../../core/ui-mediator";
import { StringUtil } from "../../../../core/utils/string-util";
import { BattlePveMapAreaRow } from "../../../../def/table";
import { ui } from "../../../../misc/ui";
import { AreaProgressEnterBtnUI } from "../../ui-runtime/pve/map-transfer/AreaProgressEnterBtnUI";
import { PveContext } from "../PveContext";

const { regClass, property } = Laya;

@regClass('NbAEGcZxQZ6_6EDGKG0sag')
export class AreaProgressEnterBtnMediator extends Mediator {
    declare owner: AreaProgressEnterBtnUI;

    public areaItem?: BattlePveMapAreaRow;

    override onAwake(): void {
        super.onAwake();
        this.owner.mainBtn.onClick(this.onClickMainBtn.bind(this));
    }

    onClickMainBtn(): void {
        app.ui.show(ui.PVE_AREA_PROGRESS_WIN, this.areaItem);
    }

    public updateShow(areaItem: BattlePveMapAreaRow, context?: PveContext): void {
        this.areaItem = areaItem;
        let curRersent = 0;
        const progressData = app.service.pve.mainLineProgressData;
        const totalProgress = progressData.getTotalProgress(areaItem.id);
        curRersent = progressData.getTotalProgressPersent(totalProgress);
        const progressStr = StringUtil.format("探索度：{0}%", curRersent);
        this.owner.mainBtn.label = progressStr;

        const canDrawIndex = progressData.getCanDrawIndex(areaItem, curRersent);
        let showGift = false;
        if (canDrawIndex >= 0) {
            const hasDrawIndex = progressData.getHasDrawIndex(areaItem);
            if (canDrawIndex > hasDrawIndex) {
                showGift = true;
            }
        }
        this.owner.img_gift.visible = showGift;
    }
}
