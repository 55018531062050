const { regClass } = Laya;

import { ServerInfoVo } from "../vo/ServerInfoVo";
import { UILoginServerListBase } from "./UILoginServerList.generated";

export interface UILoginServerListArgs {
    back: Laya.Handler;
    serverList: ServerInfoVo[];
}
@regClass('zGc2lGz3TA6z-oUPYAjxXw')
export class UILoginServerList extends UILoginServerListBase {
    backFunc!: Laya.Handler;
    tlServerList!: ServerInfoVo[];

    override open(closeOther?: boolean | undefined, param?: UILoginServerListArgs): void {
        this.backFunc = param!.back;
        this.tlServerList = param!.serverList;
        super.open(closeOther, param);
    }
}
