import { mainline } from "../../../../../../def/auto/proto";

/** 资源车记录的数据 */
export class PveSvrTruckStateData {
    /** 资源类型 */
    collectType: number = 0;

    /**
     * 位置
     */
    pos: Laya.Vector2 = new Laya.Vector2();

    /** 朝向 */
    rotation: number = 0;

    /** 当前装载的资源数量 */
    amount: number = 0;

    inited: boolean = false;

    decode(data: mainline.Truck) {
        this.collectType = data.collectType;
        this.amount = data.amount;
        this.pos.x = (data.pos?.x ?? 0) / 10;
        this.pos.y = (data.pos?.z ?? 0) / 10;
        this.rotation = data.pos?.rotation ?? 0;
        this.inited = true;
        return this;
    }

    /** 获取服务端需要的数据 */
    encode(): mainline.Truck {
        const truck = mainline.Truck.create();
        truck.collectType = this.collectType;
        truck.amount = this.amount;
        truck.pos = mainline.Position.create();
        truck.pos.x = (this.pos.x * 10) | 0;
        truck.pos.z = (this.pos.y * 10) | 0;
        truck.pos.rotation = ((this.rotation % 360) + 360) % 360 | 0;
        return truck;
    }
}
