const { regClass } = Laya;
import { app } from "../../../../app";
import { ChestBoxLeftUpBtnBase } from "./ChestBoxLeftUpBtn.generated";

@regClass('9HgSmIPnSmenhceSei8rlw')
export class ChestBoxLeftUpBtn extends ChestBoxLeftUpBtnBase {
    override onAwake(): void {
        super.onAwake();
        this.img_icon.onClick(this.onClickIcon.bind(this));
    }

    public updateShow(): void {
        const vo = app.service.chest.curChestScoreVo;
        this.lab_amount.text = `${vo.currentScore}/${vo.needScore}`;
        this.img_icon.skin = vo.rewardVo.itemLo.bigIconUrl;
    }

    async onClickIcon() {
        // app.ui.toast("TODO");
        const svr = app.service.chest;
        const vo = svr.curChestScoreVo;
        if (!vo.isEnough) {
            app.ui.toast("积分不足！");
            return;
        }
        await svr.requestScoreReceive();
    }
}
