/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/plot/plot_dialogue_msg.lh
 */
export class PlotDialogueMsgUIBase extends Laya.Box {
    public nameLabel!: Laya.Label;
    public contentLabel!: Laya.TextArea;
}

