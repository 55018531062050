const { regClass } = Laya;
import { ChestBoxVo } from "../../vo/ChestBoxVo";
import { ChestBoxStackBase } from "./ChestBoxStack.generated";

@regClass('df0zF_RMQVCLK5eHHsx6Dg')
export class ChestBoxStack extends ChestBoxStackBase {
    private boxs!: Laya.Image[];

    override onAwake(): void {
        super.onAwake();
        this.boxs = [
            this.box_10,
            this.box_09,
            this.box_08,
            this.box_07,
            this.box_06,
            this.box_05,
            this.box_04,
            this.box_03,
            this.box_02,
            this.box_01,
        ];
    }

    public updateShow(vo: ChestBoxVo | undefined): void {
        let showAmount = 0;
        if (vo) {
            showAmount = vo.amount;
        }
        for (let i = 0; i < this.boxs.length; i++) {
            const box = this.boxs[i];
            if (i < showAmount) {
                box.visible = true;
                box.skin = vo!.itemLo.bigIconUrl;
            } else {
                box.visible = false;
            }
        }
    }
}
