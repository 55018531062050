import ByteBuffer from "bytebuffer";
import { IRectangle, MaxRectsBin, Rectangle } from "maxrects-packer";

ByteBuffer.prototype.writeBytes = ByteBuffer.prototype.append;

declare module "maxrects-packer" {
    interface MaxRectsBin {
        recalculateFreeRects(): void;
    }
}

interface MaxRectsBinPrivate {
    border: number;
    splitNode(freeRect: IRectangle, usedNode: IRectangle): boolean;
    pruneFreeList(): void;
}

MaxRectsBin.prototype.recalculateFreeRects = function () {
    const self = this as unknown as MaxRectsBinPrivate;
    this.freeRects = [
        new Rectangle(
            this.maxWidth + this.padding - self.border * 2,
            this.maxHeight + this.padding - self.border * 2,
            self.border,
            self.border
        ),
    ];
    for (const rect of this.rects) {
        let numRectToProcess = this.freeRects.length;
        let i: number = 0;
        while (i < numRectToProcess) {
            if (self.splitNode(this.freeRects[i], rect)) {
                this.freeRects.splice(i, 1);
                numRectToProcess--;
                i--;
            }
            i++;
        }
        self.pruneFreeList();
    }
};
