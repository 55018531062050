import { Mediator } from "../../core/ui-mediator";
import { UIAllianceMember } from "./ui-runtime/UIAllianceMember";

const { regClass, property } = Laya;

@regClass('nNYK0O8cQCC8uXkfgPFh6w')
export class UIAllianceMemberMediator extends Mediator {
    declare owner: UIAllianceMember;

    override onAwake(): void {
        super.onAwake();
    }
}
