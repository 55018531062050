/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/alliance/AllianceInviteSetting.lh
 */
export class AllianceInviteSettingBase extends Laya.Box {
    public content!: Laya.Box;
    public btnPrev!: Laya.Box;
    public joinSetting!: Laya.Label;
    public btnNext!: Laya.Box;
    public joinLevel!: Laya.TextInput;
    public btnToggle!: Laya.Box;
    public title!: Laya.Label;
    public indicator!: Laya.Image;
}

