/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/login/LoginPvpSelectorItem.lh
 */
export class LoginPvpSelectorItemBase extends Laya.Box {
    public status!: Laya.Image;
    public title!: Laya.Label;
}

