import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { SystemEvent } from "../../misc/system-event";
import { SkillQuality, USkillType } from "./SkillService";
import { SkillScreenUI } from "./ui-runtime/SkillScreenUI";

const { regClass, property } = Laya;

@regClass('XC2mT3NOR5m50nsgpOh6XA') //技能筛选弹窗
export class SkillScreenMediator extends Mediator {
    declare owner: SkillScreenUI;

    override onAwake(): void {
        super.onAwake();
        this.initPanel();
        this.initUIEvent();
    }

    private initPanel() {
        this.owner.bgwin.setTitle("技能筛选");
        this.owner.checkQulity1.setText("S");
        this.owner.checkQulity2.setText("A");
        this.owner.checkQulity3.setText("B");
        this.owner.checkQulity4.setText("C");
        this.owner.checkType1.setText("主动");
        this.owner.checkType2.setText("内政");
        this.owner.checkType3.setText("突击");
        this.owner.checkType4.setText("被动");
        this.owner.checkType5.setText("指挥");
        this.owner.checkQulity1.checkBoxSel.visible = app.service.skill.getQuqlitySel(
            SkillQuality.Red
        );
        this.owner.checkQulity2.checkBoxSel.visible = app.service.skill.getQuqlitySel(
            SkillQuality.Orange
        );
        this.owner.checkQulity3.checkBoxSel.visible = app.service.skill.getQuqlitySel(
            SkillQuality.Purple
        );
        this.owner.checkQulity4.checkBoxSel.visible = app.service.skill.getQuqlitySel(
            SkillQuality.Blue
        );

        this.owner.checkType1.checkBoxSel.visible = app.service.skill.getTypeSel(
            USkillType.Ability
        );
        this.owner.checkType2.checkBoxSel.visible = app.service.skill.getTypeSel(
            USkillType.Passive
        );
        this.owner.checkType3.checkBoxSel.visible = app.service.skill.getTypeSel(
            USkillType.AssAult
        );
        this.owner.checkType4.checkBoxSel.visible = app.service.skill.getTypeSel(
            USkillType.Cmmmand
        );
        this.owner.checkType5.checkBoxSel.visible = app.service.skill.getTypeSel(
            USkillType.Internal_Affairs
        );
    }

    private initUIEvent() {
        this.owner.checkQulity1.boxClick.on(Laya.Event.CLICK, this, this.onClickQuality);
        this.owner.checkQulity2.boxClick.on(Laya.Event.CLICK, this, this.onClickQuality);
        this.owner.checkQulity3.boxClick.on(Laya.Event.CLICK, this, this.onClickQuality);
        this.owner.checkQulity4.boxClick.on(Laya.Event.CLICK, this, this.onClickQuality);

        this.owner.btnQulity.on(Laya.Event.CLICK, this, this.onClickBtnQuality);

        this.owner.checkType1.boxClick.on(Laya.Event.CLICK, this, this.onClickType);
        this.owner.checkType2.boxClick.on(Laya.Event.CLICK, this, this.onClickType);
        this.owner.checkType3.boxClick.on(Laya.Event.CLICK, this, this.onClickType);
        this.owner.checkType4.boxClick.on(Laya.Event.CLICK, this, this.onClickType);
        this.owner.checkType5.boxClick.on(Laya.Event.CLICK, this, this.onClickType);

        this.owner.btnType.on(Laya.Event.CLICK, this, this.onClickBtnType);

        this.owner.imgClose.on(Laya.Event.CLICK, this.owner, this.owner.destroy);
    }

    private onClickBtnQuality() {
        let isSel = false;
        isSel = this.owner.checkQulity1.checkBoxSel.visible =
            !this.owner.checkQulity1.checkBoxSel.visible;
        app.service.skill.updateQualitySel(SkillQuality.Red, isSel);

        isSel = this.owner.checkQulity2.checkBoxSel.visible =
            !this.owner.checkQulity2.checkBoxSel.visible;
        app.service.skill.updateQualitySel(SkillQuality.Orange, isSel);

        isSel = this.owner.checkQulity3.checkBoxSel.visible =
            !this.owner.checkQulity3.checkBoxSel.visible;
        app.service.skill.updateQualitySel(SkillQuality.Purple, isSel);

        isSel = this.owner.checkQulity4.checkBoxSel.visible =
            !this.owner.checkQulity4.checkBoxSel.visible;
        app.service.skill.updateQualitySel(SkillQuality.Blue, isSel);
        app.event(SystemEvent.SkILL.SKILL_SCREEN);
    }

    private onClickBtnType() {
        let isSel = false;
        isSel = this.owner.checkType1.checkBoxSel.visible =
            !this.owner.checkType1.checkBoxSel.visible;
        app.service.skill.updateTypeSel(USkillType.Ability, isSel);

        isSel = this.owner.checkType2.checkBoxSel.visible =
            !this.owner.checkType2.checkBoxSel.visible;
        app.service.skill.updateTypeSel(USkillType.Passive, isSel);

        isSel = this.owner.checkType3.checkBoxSel.visible =
            !this.owner.checkType3.checkBoxSel.visible;
        app.service.skill.updateTypeSel(USkillType.AssAult, isSel);

        isSel = this.owner.checkType4.checkBoxSel.visible =
            !this.owner.checkType4.checkBoxSel.visible;
        app.service.skill.updateTypeSel(USkillType.Cmmmand, isSel);

        isSel = this.owner.checkType5.checkBoxSel.visible =
            !this.owner.checkType5.checkBoxSel.visible;
        app.service.skill.updateTypeSel(USkillType.Internal_Affairs, isSel);
        app.event(SystemEvent.SkILL.SKILL_SCREEN);
    }

    private onClickQuality(e: Laya.Event) {
        const target = e.currentTarget;
        let isSel = false;
        let qulityKey = 0;
        switch (target) {
            case this.owner.checkQulity1.boxClick:
                isSel = !this.owner.checkQulity1.checkBoxSel.visible;
                this.owner.checkQulity1.checkBoxSel.visible = isSel;
                qulityKey = SkillQuality.Red;
                break;
            case this.owner.checkQulity2.boxClick:
                isSel = !this.owner.checkQulity2.checkBoxSel.visible;
                this.owner.checkQulity2.checkBoxSel.visible = isSel;
                qulityKey = SkillQuality.Orange;
                break;
            case this.owner.checkQulity3.boxClick:
                isSel = !this.owner.checkQulity3.checkBoxSel.visible;
                this.owner.checkQulity3.checkBoxSel.visible = isSel;
                qulityKey = SkillQuality.Purple;
                break;
            case this.owner.checkQulity4.boxClick:
                isSel = !this.owner.checkQulity4.checkBoxSel.visible;
                this.owner.checkQulity4.checkBoxSel.visible = isSel;
                qulityKey = SkillQuality.Blue;
                break;
            default:
                isSel = true;
                break;
        }
        app.service.skill.updateQualitySel(qulityKey, isSel);
        app.event(SystemEvent.SkILL.SKILL_SCREEN);
    }

    private onClickType(e: Laya.Event) {
        const target = e.currentTarget;
        let isSel = false;
        let typeKey = 0;
        switch (target) {
            case this.owner.checkType1.boxClick:
                isSel = !this.owner.checkType1.checkBoxSel.visible;
                this.owner.checkType1.checkBoxSel.visible = isSel;
                typeKey = USkillType.Ability;
                break;
            case this.owner.checkType2.boxClick:
                isSel = !this.owner.checkType2.checkBoxSel.visible;
                this.owner.checkType2.checkBoxSel.visible = isSel;
                typeKey = USkillType.Passive;
                break;
            case this.owner.checkType3.boxClick:
                isSel = !this.owner.checkType3.checkBoxSel.visible;
                this.owner.checkType3.checkBoxSel.visible = isSel;
                typeKey = USkillType.AssAult;
                break;
            case this.owner.checkType4.boxClick:
                isSel = !this.owner.checkType4.checkBoxSel.visible;
                this.owner.checkType4.checkBoxSel.visible = isSel;
                typeKey = USkillType.Cmmmand;
                break;
            case this.owner.checkType5.boxClick:
                isSel = !this.owner.checkType5.checkBoxSel.visible;
                this.owner.checkType5.checkBoxSel.visible = isSel;
                typeKey = USkillType.Internal_Affairs;
                break;
            default:
                isSel = true;
                break;
        }
        app.service.skill.updateTypeSel(typeKey, isSel);

        app.event(SystemEvent.SkILL.SKILL_SCREEN);
    }
}
