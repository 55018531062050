import { app } from "../../../../app";
import { Entity } from "../../../../core/ecs";
import { PveEventType } from "../../../../def/auto/battle";
import { BattleEntityRow, BattleEventRow, EventCustomData, Reward } from "../../../../def/table";
import { Vo } from "../../../../misc/vo/Vo";
import { PveSvrProbingComponent } from "../../pve-server/ecs/components/PveSvrProbingComponent";

export class BattleEventLo extends Vo<BattleEventRow, never> {
    override get key(): number {
        return this._config.id;
    }

    override clone(): Vo<BattleEventRow, never> {
        return new BattleEventLo(this._config);
    }

    public get eventType(): PveEventType {
        return this._config.type;
    }

    public get entityRow(): BattleEntityRow {
        return app.service.table.battleEntity[this._config.battle_entity];
    }

    public get textureKey(): string {
        return this._config.texture_key;
    }

    public addProbingComponent(entity: Entity): void {
        if (
            this._config.probing_type &&
            this._config.probing_range &&
            this._config.probing_range !== "0"
        ) {
            const probingComp = entity.addComponent(PveSvrProbingComponent);
            probingComp.probing_range = this._config.probing_range;
            probingComp.probingType = this._config.probing_type;
        }
    }

    public get cloudKey(): string {
        return this.customData!.finish_open_cloud!;
    }

    private _cloudID: number = 0;

    public get cloudID(): number {
        if (this._cloudID) {
            return this._cloudID;
        }
        const finish_open_cloud = this.customData?.finish_open_cloud;
        this._cloudID = Number(finish_open_cloud!);
        return this._cloudID;
    }

    private _soldierDatas: { soldier: number; pos: number; count: number }[] | undefined;

    get soldierDatas(): { soldier: number; pos: number; count: number }[] | null {
        if (this._soldierDatas) {
            return this._soldierDatas;
        }

        if (this.eventType !== PveEventType.RESCUE_SOLDIER) {
            return null;
        }
        try {
            const data = JSON.parse(this._config.custom_data!);
            this._soldierDatas = data;
            return data;
        } catch (e) {
            console.error("soldierDatas parse error", e);
            return null;
        }
    }

    private _customData: EventCustomData | undefined;

    public get customData(): EventCustomData | undefined {
        if (this._customData) {
            return this._customData;
        }
        this._customData = JSON.parse(this._config.custom_data!);
        return this._customData;
    }

    public get cost(): Reward[] | undefined {
        return this._config.cost;
    }

    public get reward(): Reward[] | undefined {
        return this._config.reward;
    }

    /** 建筑对应的皮肤id */
    public get skinId(): number | undefined {
        return this.entityRow.skin_id;
    }

    public get skinRes(): string {
        const skinId = this.skinId;
        if (skinId) {
            const prefeb_baked_res = app.service.table.skin[skinId].prefeb_baked_res;
            if (prefeb_baked_res) {
                return prefeb_baked_res;
            }
            const prefeb_res = app.service.table.skin[skinId].prefeb_res;
            if (prefeb_res) {
                return prefeb_res;
            }
        }
        return "";
    }

    public get plotTheatreId(): number {
        return this.customData?.plot_theatre_id ?? 0;
    }
}
