const { regClass } = Laya;
import { app } from "../../../../app";
import proto from "../../../../def/auto/proto";
import { TroopState } from "../../../../def/auto/world";
import { SystemEvent } from "../../../../misc/system-event";
import { PvpContext } from "../../pvp/PvpContext";
import { PvpStateComponent } from "../../pvp/ecs/components/PvpStateComponent";
import { PvpTroopComponent } from "../../pvp/ecs/components/PvpTroopComponent";
import { TroopStateUIBase } from "./TroopStateUI.generated";

interface StateRes {
    normal: string;
    focus: string;
}

export const StateResDir = "resources/atlas/battle-pvp-ui";
export const StateResMap = new Map<number, StateRes>([
    [TroopState.MOVE, { normal: "ui_shapan_009.png", focus: "ui_shapan_001.png" }],
    [TroopState.IDLE, { normal: "ui_shapan_010.png", focus: "ui_shapan_002.png" }],
    [TroopState.SIEGE, { normal: "ui_shapan_011.png", focus: "ui_shapan_003.png" }],
    [TroopState.FIGHTING, { normal: "ui_shapan_012.png", focus: "ui_shapan_008.png" }],
    [TroopState.RETREAT, { normal: "ui_shapan_014.png", focus: "ui_shapan_004.png" }],
    [TroopState.WAITLIST, { normal: "ui_shapan_015.png", focus: "ui_shapan_007.png" }],
    [TroopState.INSIDE, { normal: "ui_shapan_016.png", focus: "ui_shapan_006.png" }],
]);
const ResBtnTroopEdit = "btn_shapan_002.png";
const ResBtnTroopRetread = "btn_shapan_003.png"; //Note: 撤退，但没资源，随便拿个图标来显示一下

@regClass('eFvCnoubRBWuQga5ST97zw')
export class TroopStateUI extends TroopStateUIBase {
    private _eid: number = 0;
    private index: number = 0;
    private _data?: PvpTroopComponent;
    private _selected: boolean = false;

    get eid(): number {
        return this._eid;
    }

    get selected(): boolean {
        return this._selected;
    }

    set selected(value: boolean) {
        if (this._selected === value) {
            return;
        }

        this._selected = value;
        if (this._data) {
            this._doRedresh(this._data);
        }
    }

    initWith(index: number, eid: number, clickCB: (eid: number) => void) {
        this.index = index;
        this._eid = eid;
        if (eid) {
            this.name = `troop_${eid}`;
        } else {
            this.name = "";
            this.focusState.visible = false;
            this.normalState.visible = false;
            this.noneState.visible = true;
        }

        this.offAll(Laya.Event.CLICK);
        this.on(Laya.Event.CLICK, () => {
            clickCB(this.eid);
        });

        this.focusState.btnTroopEdit.offAll(Laya.Event.CLICK);
        this.focusState.btnTroopEdit.on(Laya.Event.CLICK, () => {
            if (this._data) {
                const state = this._data.getComponent(PvpStateComponent);
                if (state?.state === TroopState.FIGHTING) {
                    app.service.network.call(
                        proto.world.c2s_troop_retreat.create({
                            troopEid: this._eid,
                        }),
                        proto.world.s2c_troop_retreat
                    );
                    return;
                }
            }

            app.event(SystemEvent.UI.TROOP_OPEN, { tabIndex: this.index });
        });

        this.noneState.btnEditTroop.offAll(Laya.Event.CLICK);
        this.noneState.btnEditTroop.on(Laya.Event.CLICK, () => {
            app.event(SystemEvent.UI.TROOP_OPEN, { tabIndex: this.index });
        });
    }

    update(ctx: PvpContext) {
        // console.warn("troopStateUI update", this._eid);
        const data = ctx.ecs.getComponent(this._eid, PvpTroopComponent);
        if (!data) {
            return;
        }
        this._doRedresh(data);
    }

    private _doRedresh(data: PvpTroopComponent) {
        this._data = data;
        this.focusState.visible = false;
        this.normalState.visible = false;
        this.noneState.visible = false;

        const state = data?.getComponent(PvpStateComponent);
        const stateRes = StateResMap.get(state?.state ?? 0);
        const dir = StateResDir;

        const heroRow = app.service.table.hero.hero[data.heroId];

        if (!this._data) {
            this.noneState.visible = true;
            this.width = this.noneState.width;
            this.height = this.noneState.height;
        } else if (this._selected) {
            this.focusState.visible = true;
            this.width = this.focusState.width;
            this.height = this.focusState.height;
            this.focusState.troopState.skin = `${dir}/${stateRes?.focus}`;
            this.focusState.troopName.text = `${heroRow?.name ?? "1"}队`;

            const btnRes =
                state?.state === TroopState.FIGHTING ? ResBtnTroopRetread : ResBtnTroopEdit;
            this.focusState.btnTroopEdit.skin = `${dir}/${btnRes}`;
        } else {
            this.normalState.visible = true;
            this.width = this.normalState.width;
            this.height = this.normalState.height;
            this.normalState.troopState.skin = `${dir}/${stateRes?.normal}`;
            this.normalState.troopName.text = `${heroRow?.name ?? "1"}队`;
        }
    }
}
