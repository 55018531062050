type ExecutorResolve = (value: unknown) => void;

interface PromiseDescriptor {
    resolve: ExecutorResolve;
}

export class Loader {
    private _loadings: Map<string, PromiseDescriptor[]> = new Map();

    async loadJson<T>(url: string): Promise<T> {
        const res = await this.load<Laya.TextResource>(url, Laya.Loader.JSON);
        return res.data as T;
    }

    async loadBuffer(url: string): Promise<ArrayBuffer> {
        const res = await this.load<Laya.TextResource>(url, Laya.Loader.BUFFER);
        return res.data as ArrayBuffer;
    }

    async loadAtlas(url: string) {
        return this.load<Laya.AtlasResource>(url, Laya.Loader.ATLAS);
    }

    async loadTexture2D(url: string) {
        return this.load<Laya.Texture2D>(url, Laya.Loader.TEXTURE2D);
    }

    async loadTexture(url: string) {
        return this.load<Laya.Texture>(url, Laya.Loader.IMAGE);
    }

    /**
     * checker 判断应该放在 loadPrefab 之后
     * TODO：修改if判断
     */
    async loadPrefab(url: string) {
        return this.load<Laya.Prefab>(url, Laya.Loader.HIERARCHY);
    }

    async loadMaterial(url: string) {
        return this.load<Laya.Material>(url, Laya.Loader.MATERIAL);
    }

    /**
     * 加载资源，并且有返回一定成功。
     * @param url 加载的资源。
     * @param checker 在加载成功的情况下并且通过检查才会继续执行。
     * @returns
     */
    load<T = unknown>(url: string): Promise<T>;

    load<T = unknown>(url: string, type: string): Promise<T>;

    async load<T = unknown>(url: string, type?: string): Promise<T> {
        if (!url) {
            console.warn("no url");
        }

        const res = Laya.loader.getRes(url, type) as T;
        if (res) {
            return res;
        }
        return new Promise<T>((resolve) => {
            let arr = this._loadings.get(url);
            if (!arr) {
                arr = [];
                this._loadings.set(url, arr);
            }
            arr.push({ resolve: resolve as ExecutorResolve });
            if (arr.length === 1) {
                Laya.loader.load(url, type as string).then((value) => {
                    if (!value) {
                        // TODO: 显示弹窗提醒用户，并且进行3次尝试
                        console.error(`load error: ${url}`);
                    } else {
                        for (const descriptor of arr) {
                            descriptor.resolve(value);
                        }
                        this._loadings.delete(url);
                    }
                });
            }
        });
    }
}
