/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/battle/pvp-ui/troop-picker.lh
 */
export class TroopPickerUIBase extends Laya.Dialog {
    public btnGo!: Laya.Image;
    public btnBack!: Laya.Image;
    public btnAutoBackHome!: Laya.Sprite;
    public imgSelected!: Laya.Image;
    public troopList!: Laya.List;
    public txtTroopCost!: Laya.Text;
}

