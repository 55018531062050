import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { Pool } from "../../../../../core/pool";
import { BattleEntityType } from "../../../../../def/auto/battle";
import { TMLayerName } from "../../../tilemap/tm-def";
import { TMElementDescriptor } from "../../../tilemap/tm-element";
import {
    BattlePveMapTransferItem,
    InteractiveBubbleActionType,
    InteractiveBubbleViewType,
} from "../../PveDefs";
import { PveServer } from "../../PveServer";
import { IPveSvrReceiver } from "../../PveSvrReceiver";
import { PveSvrCommandComponent } from "../components/PveSvrCommandComponent";
import { PveSvrCreatureComponent } from "../components/PveSvrCreatureComponent";

const tmpVec3 = new Laya.Vector3();

export class PveSvrCommandSystem extends ecs.System implements IPveSvrReceiver {
    declare context: PveServer;

    override update(dt: number): void {
        const cmdComp = this.ecs.getSingletonComponent(PveSvrCommandComponent);
        if (cmdComp.commands.length > 0) {
            let length = cmdComp.commands.length;
            cmdComp.commands.forEach((cmd) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (this[cmd.name] as any)(...cmd.args);
                if (cmdComp.commands.length !== length) {
                    for (let i = length; i < cmdComp.commands.length; i++) {
                        const dropCmd = cmdComp.commands[i];
                        const err = new Error(
                            `PveSvrCommandSystem drop command: ${dropCmd.name}, caused by ${cmd.name}(${cmd.args})`
                        );
                        console.error(err.stack);
                    }
                    length = cmdComp.commands.length;
                }
                cmd.args.forEach((arg) => {
                    if (typeof arg === "object") {
                        Pool.free(arg);
                    }
                });
            });
            cmdComp.commands.length = 0;
        }
    }

    //-------------------------------------------------------------------------
    // 接收前端指令
    //-------------------------------------------------------------------------
    joystickStart(eid: number, dir: Laya.Vector3) {
        this.context.joystickStart(eid, dir);
    }

    click(x: number, z: number) {
        this.context.click(x, z);
    }

    joystickStop(eid: number, activeAI: boolean) {
        this.context.joystickStop(eid, activeAI);
    }

    addElement(descriptor: TMElementDescriptor) {
        // 不管怎么样，创建对应的RecordData
        const pveStateData = app.service.pve.mlData.pveStateData;
        switch (descriptor.layer) {
            case TMLayerName.Monster: {
                const data = pveStateData.getOrCreateMonsterTroopByKey(
                    descriptor.key,
                    descriptor.tid
                );
                data.inView = true;
                break;
            }

            case TMLayerName.Npc: {
                const data = pveStateData.getOrCreateNpcByKey(
                    descriptor.key,
                    descriptor.x,
                    descriptor.z,
                    descriptor.tid,
                    descriptor.rotation
                );
                data.inView = true;
                break;
            }

            case TMLayerName.Event: {
                const data = pveStateData.getOrCreateEventByKey(descriptor.key);
                data.tid = descriptor.tid;
                data.inView = true;
                break;
            }
            case TMLayerName.Building: {
                const table = app.service.table;
                const buildingRow = table.battleBuilding[descriptor.tid];
                if (buildingRow === undefined) {
                    console.error(`not found buildingRow: ${descriptor.tid}`);
                    return;
                }
                const entityRow = table.battleEntity[buildingRow.battle_entity];
                switch (entityRow.etype) {
                    case BattleEntityType.BUILDING: {
                        const data = pveStateData.getOrCreateBuildingByKey(descriptor.key);
                        data.tid = descriptor.tid;
                        data.inView = true;
                        break;
                    }
                    case BattleEntityType.WOOD:
                    case BattleEntityType.FOOD:
                    case BattleEntityType.STONE: {
                        const data = pveStateData.getOrCreateCollectionByKey(descriptor.key);
                        data.inView = true;
                        break;
                    }
                }
                break;
            }
        }

        // 实际逻辑
        if (descriptor.layer === TMLayerName.Monster) {
            tmpVec3.set(descriptor.x, 0, descriptor.z);
            this.context.addMonsterTroop(descriptor.key, descriptor.tid, tmpVec3);
        } else if (descriptor.layer === TMLayerName.Npc) {
            this.context.addNpc(
                descriptor.key,
                descriptor.tid,
                descriptor.x,
                descriptor.z,
                descriptor.rotation
            );
        } else if (descriptor.layer === TMLayerName.Event) {
            tmpVec3.set(descriptor.x, 0, descriptor.z);
            this.context.addEvent(descriptor.key, descriptor.tid, tmpVec3);
        } else if (descriptor.layer === TMLayerName.Building) {
            tmpVec3.set(descriptor.x, 0, descriptor.z);
            const table = app.service.table;
            const buildingRow = table.battleBuilding[descriptor.tid];
            const entityRow = table.battleEntity[buildingRow.battle_entity];
            switch (entityRow.etype) {
                case BattleEntityType.BUILDING:
                    this.context.addBuilding(descriptor.key, descriptor.tid, tmpVec3);
                    break;
                case BattleEntityType.WOOD:
                case BattleEntityType.FOOD:
                case BattleEntityType.STONE:
                    this.context.addCollection(descriptor.key, descriptor.tid, tmpVec3);
                    break;
            }
        }
    }

    removeElement(descriptor: TMElementDescriptor) {
        // 不管怎么样，设置对应的RecordData
        const pveStateData = app.service.pve.mlData.pveStateData;
        switch (descriptor.layer) {
            case TMLayerName.Monster: {
                const data = pveStateData.getMonsterTroopByKey(descriptor.key);
                data && (data.inView = false);
                break;
            }

            case TMLayerName.Npc: {
                const data = pveStateData.getNpcByKey(descriptor.key);
                data && (data.inView = false);
                break;
            }
            case TMLayerName.Event: {
                const data = pveStateData.getEventByKey(descriptor.key);
                data && (data.inView = false);
                break;
            }
            case TMLayerName.Building: {
                const table = app.service.table;
                const buildingRow = table.battleBuilding[descriptor.tid];
                if (buildingRow === undefined) {
                    console.error(`not found buildingRow: ${descriptor.tid}`);
                    return;
                }
                const entityRow = table.battleEntity[buildingRow.battle_entity];
                switch (entityRow.etype) {
                    case BattleEntityType.BUILDING: {
                        const data = pveStateData.getBuildingByKey(descriptor.key);
                        data && (data.inView = false);
                        break;
                    }
                    case BattleEntityType.WOOD:
                    case BattleEntityType.FOOD:
                    case BattleEntityType.STONE: {
                        const data = pveStateData.getCollectionByKey(descriptor.key);
                        data && (data.inView = false);
                        break;
                    }
                }
                break;
            }
        }

        // 实际逻辑
        const element = this.context.elements.get(descriptor.key);
        element && this.context.removeElement(element);
    }

    removeSpoils(tid: number, position: Laya.Vector3) {
        this.context.removeSpoils(tid, position);
    }

    grubItem(eid: number) {
        this.context.grubItem(eid);
    }

    grubSpoils(tid: number, position: Laya.Vector3) {
        this.context.grubSpoils(tid, position);
    }

    removePlotTheatreEvent(eid: number) {
        this.context.removePlotTheatreEvent(eid);
    }

    navigationArrowPointTo(
        eid: number,
        posO: Laya.Vector3,
        targetPosA: Laya.Vector3,
        targetPosB: Laya.Vector3
    ): void {
        this.context.navigationArrowPointTo(eid, posO, targetPosA, targetPosB);
    }

    hideNavigationArrow(eid: number): void {
        this.context.hideNavigationArrow(eid);
    }

    heroReborn(resetPos: boolean) {
        this.context.heroReborn(resetPos);
    }

    mapTransfer(data: BattlePveMapTransferItem): void {
        this.context.mapTransfer(data);
    }

    gmCmd(cmd: string): void {
        switch (cmd) {
            case "GM_PVE_ADD_BLOOD":
                {
                    const creatureComp = this.context.ecs.getComponent(
                        this.context.ctrlHeroEid,
                        PveSvrCreatureComponent
                    )!;
                    if (!creatureComp.isDie) {
                        creatureComp.hp = creatureComp.maxHp;
                        this.context.sender.updateHp(creatureComp.eid, {
                            hp: creatureComp.hp,
                            maxHp: creatureComp.maxHp,
                        });
                        console.log("GM_PVE_ADD_BLOOD", creatureComp.maxHp, creatureComp.hp);
                    }
                }
                break;
            case "GM_PVE_ADD_ATK":
                {
                    const creatureComp = this.context.ecs.getComponent(
                        this.context.ctrlHeroEid,
                        PveSvrCreatureComponent
                    )!;
                    creatureComp.atk += 10;
                    console.log("GM_PVE_ADD_ATK", creatureComp.atk);
                }
                break;
            case "GM_PVE_ADD_SOLDER":
                // {
                //     const heroElement = this.context.ecs.getComponent(
                //         this.context.focusRole,
                //         PveSvrElementComponent
                //     )!;
                //     const posIndex = this.context.getNewSoldierPosIndex(40702, heroElement.eid);
                //     if (posIndex < 0) {
                //         console.error("GM_PVE_ADD_SOLDER posIndex < 0", posIndex);
                //         return;
                //     }
                //     const px = heroElement.transform.position.x;
                //     const pz = heroElement.transform.position.z;
                //     this.context.addSoliderWith(heroElement, posIndex, 40702, 200, 200, px, pz, 0);
                //     console.log("GM_PVE_ADD_SOLDER", posIndex);
                // }

                break;
        }
    }

    onClickBackToTerritory(): void {
        this.context.onClickBackToTerritory();
    }

    onStartPlotTheatre(): void {
        this.context.onStartPlotTheatre();
    }

    harvestCollection(eid: number): void {
        this.context.harvestCollection(eid);
    }

    interactiveBubbleAction(
        eid: number,
        bubbleViewType: InteractiveBubbleViewType,
        bubbleActionType: InteractiveBubbleActionType,
        param: unknown
    ): void {
        this.context.interactiveBubbleAction(eid, bubbleViewType, bubbleActionType, param);
    }
}
