import * as ecs from "../../../../../core/ecs";
import { Animator } from "../../../base/Animator";

export class PvpAnimationComponent extends ecs.Component {
    readonly mounter: Laya.Sprite3D = new Laya.Sprite3D();

    res!: string;
    view?: Laya.Sprite3D | null;

    // 循环播放
    loop: boolean = true;
    // 冲锋状态
    rushing: boolean = false;

    animator?: Animator | null;
}
