import { app } from "../../app";
import { Service } from "../../core/service";
import proto from "../../def/auto/proto";
import { opcode } from "../../def/auto/protocol";

export class GmService extends Service {
    override onCreate() {
        this.handle(opcode.user.s2c_gm, this._onGm);
    }

    override onStartInit(): void {}

    override onDestroy() {}

    private _onGm(data: proto.user.Is2c_gm) {
        if (data.err) {
            console.log(data.err);
            return;
        }
        console.log(data.msg);
    }

    //-------------------------------------------------------------------------
    // RPC
    //-------------------------------------------------------------------------
    async requestGM(cmd: string) {
        return await app.service.network.call(
            proto.user.c2s_gm.create({ cmd: cmd }),
            proto.user.s2c_gm
        );
    }
}
