import { Mediator } from "../../core/ui-mediator";
import { UIEffectForWake } from "./ui-runtime/UIEffectForWake";

const { regClass, property } = Laya;

@regClass('Y0W-rn52QFaoReCRiRZxZw')
export class UIEffectForWakeMediator extends Mediator {
    declare owner: UIEffectForWake;

    override onAwake(): void {
        super.onAwake();
        this.delay(2.5, this.onDelayComp.bind(this));
    }

    private onDelayComp(): void {
        this.owner.close();
    }
}
