import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { Pool } from "../../../../../core/pool";
import { StringUtil } from "../../../../../core/utils/string-util";
import { BattleEntityType } from "../../../../../def/auto/battle";
import { MainlineConf } from "../../../../../def/auto/mainline";
import { AnimName } from "../../../base/Animator";
import { CollectionAmountData, PveDef } from "../../PveDefs";
import { PveServer } from "../../PveServer";
import { PveSvrUtils } from "../../PveSvrUtils";
import { PveSvrCollectionComponent } from "../components/PveSvrCollectionComponent";
import { PveSvrElementComponent } from "../components/PveSvrElementComponent";
import { PveSvrProbingComponent } from "../components/PveSvrProbingComponent";
import { PveSvrRecycleComponent } from "../components/PveSvrRecycleComponent";
import { PveSvrTransformComponent } from "../components/PveSvrTransformComponent";
import { PveSvrTroopComponent } from "../components/PveSvrTroopComponent";
import { PveSvrTruckComponent } from "../components/PveSvrTruckComponent";
import { PveSvrSpoilsSystem } from "./PveSvrSpoilsSystem";

// const tempVector3A = new Laya.Vector3();
// const tempVector3B = new Laya.Vector3();

export class PveSvrCollectSystem extends ecs.System {
    /** 回收资源间隔 */
    private static readonly RECYCLE_DELAY = 0.5;

    /** 每次回收资源数量 */
    private static readonly RECYCLE_COUNT = 5;

    private _lastTick_RECYCLE_DELAY: number = 0;

    declare context: PveServer;

    override update(dt: number): void {
        const heroEleComp = this.context.ecs.getComponent(
            this.context.ctrlHeroEid,
            PveSvrElementComponent
        );
        if (!heroEleComp) {
            return;
        }

        this.ecs.getComponents(PveSvrRecycleComponent).forEach((recycle) => {
            const probingComp = recycle.getComponent(PveSvrProbingComponent);
            if (!probingComp) {
                return;
            }
            if (probingComp.curInRange) {
                // 检查是否建造完成
                const recycleEle = recycle.getComponent(PveSvrElementComponent)!;
                const pveStateData = app.service.pve.mlData.pveStateData;
                const data = pveStateData.getBuildingByKey(recycleEle.key)!;
                const buildingVo = app.service.pve.buildingVoMap.getVoWithBattleEntityIdAndLevel(
                    recycleEle.data.id,
                    data.level
                )!;
                if (buildingVo.effectiveable) {
                    this._updateRecycle(heroEleComp, recycle, dt);
                }
            }
        });
    }

    private _updateRecycle(
        focusRoleElement: PveSvrElementComponent,
        recycle: PveSvrRecycleComponent,
        dt: number
    ): void {
        const time = this.context.time;
        if (time - this._lastTick_RECYCLE_DELAY < PveSvrCollectSystem.RECYCLE_DELAY) {
            return;
        }
        this._lastTick_RECYCLE_DELAY = time;

        const heroTrucks = this.context.getHeroTrucks(focusRoleElement.eid);
        // 找出有资源的车子
        const hasCollectCntTrucks: PveSvrTruckComponent[] = [];
        if (heroTrucks && heroTrucks.length > 0) {
            for (let i = 0; i < heroTrucks.length; i++) {
                if (heroTrucks[i].collectCnt > 0) {
                    hasCollectCntTrucks.push(heroTrucks[i]);
                }
            }
        }
        if (hasCollectCntTrucks.length > 0) {
            const recycleTransformComp = recycle.getComponent(PveSvrTransformComponent)!;
            this.recycleOnceFrom(recycleTransformComp, hasCollectCntTrucks);
        }
    }

    /** 单次回收资源 */
    private recycleOnceFrom(
        recycleTransformComp: PveSvrTransformComponent,
        trucks: PveSvrTruckComponent[]
    ): void {
        const randIndex = Math.floor(Math.random() * trucks.length);
        const truck = trucks[randIndex];
        const RECYCLE_COUNT = PveSvrCollectSystem.RECYCLE_COUNT;
        // 回收后的数量
        const newCollectCnt = Math.max(0, truck.collectCnt - RECYCLE_COUNT);
        // 回收的实际数量
        const recycleCnt = truck.collectCnt - newCollectCnt;

        PveSvrCollectSystem.truckCollectionPutToPack(
            this.context,
            this.context.ctrlHeroEid,
            truck.collectItemId,
            recycleCnt,
            recycleTransformComp.position
        );
    }

    /** 资源车上的资源进行入包 */
    public static async truckCollectionPutToPack(
        pveServer: PveServer,
        leaderEid: number,
        itemId: number,
        costAmount: number,
        soldierPosition?: Laya.Vector3
    ) {
        const realCostAmount = this.costAssetsFromTrucks(
            pveServer,
            leaderEid,
            itemId,
            costAmount,
            soldierPosition
        );
        if (realCostAmount > 0) {
            // 实际扣除这部分资源需要入包
            const cmd = StringUtil.format("add_item {0} {1}", itemId, realCostAmount);
            await app.service.gm.requestGM(cmd);
        }
    }

    public static getTruckCompWithLeaderEidAndItemId(
        pveServer: PveServer,
        leaderEid: number,
        itemId: number
    ): PveSvrTruckComponent | undefined {
        const troopComp = PveSvrUtils.getTroopByLeaderEid(pveServer, leaderEid);
        if (!troopComp) {
            return undefined;
        }
        for (const etype in troopComp.truckEids) {
            const truckEid = troopComp.truckEids[etype];
            const truckComp = pveServer.ecs.getComponent(truckEid, PveSvrTruckComponent);
            if (truckComp && truckComp.collectItemId === itemId) {
                return truckComp;
            }
        }
    }

    /**
     * 扣除车上的资源
     * @returns 实际扣除的数量
     */
    public static costAssetsFromTrucks(
        pveServer: PveServer,
        leaderEid: number,
        itemId: number,
        costAmount: number,
        soldierPosition?: Laya.Vector3
    ): number {
        const truckComp = this.getTruckCompWithLeaderEidAndItemId(pveServer, leaderEid, itemId);
        if (!truckComp) {
            return 0;
        }

        const curHasCnt = truckComp.collectCnt;
        // 实际扣除的数量
        let realCostAmount: number = 0;
        if (curHasCnt > 0) {
            if (curHasCnt < costAmount) {
                // 不够消耗
                realCostAmount = curHasCnt;
            } else {
                realCostAmount = costAmount;
            }
        }
        if (realCostAmount > 0) {
            truckComp.collectCnt = curHasCnt - realCostAmount;
            pveServer.sender.updateTruck(truckComp.eid, {
                collection: 0,
                collectCnt: truckComp.collectCnt,
                costToPosition: soldierPosition,
            });
            pveServer.sender.updateTruckHeadView(truckComp.eid);
        }
        return realCostAmount;
    }

    public static collect(
        pveServer: PveServer,
        element: PveSvrElementComponent,
        target: PveSvrElementComponent
    ) {
        if (target.etype === BattleEntityType.WOOD) {
            pveServer.sender.playAnim(element.eid, AnimName.CHOP, true);
        } else if (target.etype === BattleEntityType.STONE) {
            pveServer.sender.playAnim(element.eid, AnimName.DIG, true);
        } else if (target.etype === BattleEntityType.FOOD) {
            pveServer.sender.playAnim(element.eid, AnimName.MOW, true);
        } else {
            console.error("collect error etype", target.etype);
        }

        const collectionComp = target.getComponent(PveSvrCollectionComponent)!;
        const collectAmount = collectionComp.amount;
        const max = collectionComp.max;
        const remain = collectionComp.remain;
        if (remain < 1) {
            return;
        }

        const tfComp = target.getComponent(PveSvrTransformComponent)!;
        const collectPosX = tfComp.position.x;
        const collectPosZ = tfComp.position.z;

        const oldProgress = (max - remain) / max;
        const oldAmount = Math.floor(oldProgress * collectAmount);
        collectionComp.remain = collectionComp.remain - 1;
        const curProgress = (max - collectionComp.remain) / max;
        const newAmount = Math.floor(curProgress * collectAmount);
        // 采集掉落的资源数量
        const dropAmount = newAmount - oldAmount;
        // if (dropAmount > 0) {
        //     app.ui.toast("dropAmount:" + dropAmount);
        // }

        const isEmpty = collectionComp.remain < 1;
        const delayKey: string = "delay_update_collect_up" + element.eid;
        pveServer.ecs.killDelay(delayKey);
        pveServer.ecs.delay(
            0.1,
            delayKey,
            () => {
                pveServer.sender.updateHp(target.eid, {
                    hp: collectionComp.remain,
                    maxHp: collectionComp.max,
                    subHp: 1,
                });
            },
            this
        );

        let doKillTaskType = -1;
        let doCollectOneTaskType = -1;
        const target_etype = target.data.etype;
        let rewardItemId = 0;
        switch (target_etype) {
            case BattleEntityType.WOOD:
                rewardItemId = PveDef.COLLECT_WOOD_ID;
                doKillTaskType = MainlineConf.TASK.KILL_WOOD;
                doCollectOneTaskType = MainlineConf.TASK.COLLECT_ONE_WOOD;
                break;
            case BattleEntityType.FOOD:
                rewardItemId = PveDef.COLLECT_FOOD_ID;
                doKillTaskType = MainlineConf.TASK.KILL_FOOD;
                doCollectOneTaskType = MainlineConf.TASK.COLLECT_ONE_FOOD;
                break;
            case BattleEntityType.STONE:
                rewardItemId = PveDef.COLLECT_STONE_ID;
                doKillTaskType = MainlineConf.TASK.KILL_STONE;
                doCollectOneTaskType = MainlineConf.TASK.COLLECT_ONE_STONE;
                break;
        }

        // 临时测试修改
        // const rand = Math.random();
        // if (rand < 0.5) {
        //     target_etype = BattleEntityType.FOOD;
        // }
        const troop = pveServer.ecs.getComponent(pveServer.troopEid, PveSvrTroopComponent);
        const truck = Object.values(troop?.truckEids ?? [])
            .map((eid) => pveServer.ecs.getComponent(eid, PveSvrTruckComponent))
            .find((t) => t?.collectType === target_etype);
        if (truck) {
            if (truck.isFull) {
                const lastTime = truck.lastTipsTime;
                if (!lastTime || pveServer.time - lastTime > 10) {
                    switch (truck.collectType) {
                        case BattleEntityType.WOOD:
                            app.ui.toast("木头资源车已满，无法继续获得木头");
                            break;
                        case BattleEntityType.FOOD:
                            app.ui.toast("粮食资源车已满，无法继续获得粮食");
                            break;
                        case BattleEntityType.STONE:
                            app.ui.toast("石材资源车已满，无法继续获得石材");
                            break;
                    }
                    truck.lastTipsTime = pveServer.time;
                }
            } else {
                if (dropAmount > 0) {
                    app.service.pve.requestDoTask(MainlineConf.TASK.COLLECT_ONE_ANY, 1);
                    if (doCollectOneTaskType !== -1) {
                        app.service.pve.requestDoTask(doCollectOneTaskType, 1);
                    }
                    truck.collectCnt += dropAmount;
                    pveServer.sender.updateTruck(truck.eid, {
                        collection: target.eid,
                        collectCnt: truck.collectCnt,
                    });
                }
            }
        } else {
            // 没有对应的车，直接以战利品的方式掉落地上
            if (dropAmount > 0) {
                PveSvrSpoilsSystem.handleCollectDropSpoils(
                    pveServer,
                    collectPosX,
                    collectPosZ,
                    dropAmount,
                    rewardItemId
                );
                // if (doCollectOneTaskType === MainlineConf.TASK.COLLECT_ONE_STONE) {
                //     app.service.pve.requestDoTask(doCollectOneTaskType, dropAmount);
                // }
            }
            // if (dropAmount > 0) {
            //     app.service.gm.requestGM(`add_item ${rewardItemId} ${dropAmount}`);
            // }
        }

        const pveStateData = app.service.pve.mlData.pveStateData;
        if (isEmpty) {
            const deathTime = app.service.network.serverTime << 0;
            const gridPos = target.gridPos;
            pveStateData.setCollectionData(
                gridPos.xIndex,
                gridPos.yIndex,
                target.tid,
                0,
                collectionComp.max,
                deathTime
            );

            // 请求做任务
            if (doKillTaskType !== -1) {
                app.service.pve.requestDoTask(doKillTaskType, 1);
            }
        } else {
            const gridPos = target.gridPos;
            pveStateData.setCollectionData(
                gridPos.xIndex,
                gridPos.yIndex,
                target.tid,
                collectionComp.remain,
                collectionComp.max
            );
        }
    }

    public static async costFormTruck(
        pveServer: PveServer,
        costToPosition: Laya.Vector3,
        amountObj: CollectionAmountData,
        needAmount: number
    ) {
        let useTruckAssetCnt = 0;
        if (amountObj.truckAmount >= needAmount) {
            // 车上的资源足够
            useTruckAssetCnt = needAmount;
        } else {
            // 车上的资源不够，需要背包里的资源做补充
            useTruckAssetCnt = amountObj.truckAmount;
        }

        let goTargetPosition: Laya.Vector3 | undefined;
        if (costToPosition) {
            goTargetPosition = Pool.obtain(Laya.Vector3);
            goTargetPosition.cloneFrom(costToPosition);
            goTargetPosition.y = 1;
        }

        if (useTruckAssetCnt > 0) {
            // 把这些车上的资源进行扣除
            await PveSvrCollectSystem.truckCollectionPutToPack(
                pveServer,
                pveServer.ctrlHeroEid,
                amountObj.itemId,
                useTruckAssetCnt,
                goTargetPosition
            );
            // 给1秒的表现扣除资源时间
            await this.waitTime(1500);
            return true;
        }
        return false;
    }

    public static async waitTime(time: number) {
        await new Promise((resolve) => {
            Laya.timer.once(time, this, () => {
                resolve(1);
            });
        });
    }
}
