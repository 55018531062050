// 加上 as const 可以确保TS将变量识别为只读的常量，而不是可变的变量
export class SystemEvent {
    /** 重连成功 */
    static readonly RECONNECT_LOGIN_SUCCESS = "RECONNECT_LOGIN_SUCCESS";
    /** 重连失败 */
    static readonly RECONNECT_FAIL = "RECONNECT_FAIL";
    static readonly DISCONNECTED = "DISCONNECTED";
    /** 连接PVP成功 */
    static readonly SWITCH_PVP_SUCCESS = "CONNECT_PVP_SUCCESS";

    /** 点击任务"前往",显示弱指引到任务目标 */
    static readonly CLICK_GO_TASK_SHOW_WEAK_GUIDE = "CLICK_GO_TASK_SHOW_WEAK_GUIDE";
    static readonly TILEMAP_DEBUG_MODE_UPDATE = "TILEMAP_DEBUG_MODE_UPDATE";

    static readonly USER_PROFILE_UPDATE = "USER_PROFILE_UPDATE";
    static readonly USER_MONEY_UPDATE = "USER_MONEY_UPDATE";
    static readonly USER_COMMONINFO_UPDATE = "USER_MONEY_CHANGE";

    static readonly TASK_UPDATE = "TASK_UPDATE";
    static readonly TASK_NEW_NPC_TASK = "TASK_NEW_NPC_TASK";
    static readonly TASK_MAIN_UPDATE = "TASK_MAIN_UPDATE";
    static readonly TASK_REMOVE = "TASK_REMOVE";

    static readonly ON_DESTROY_PVP_SCENE = "ON_DESTROY_PVP_SCENE";

    static readonly ON_START_PLOT_THEATRE = "ON_START_PLOT_THEATRE";
    static readonly ON_END_PLOT_THEATRE = "ON_END_PLOT_THEATRE";

    static readonly CHARACTER = {
        SVR_LOAD: "CHARACTER.SVR_LOAD" as const,
        SVR_HERO_UPDATE: "CHARACTER.SVR_HERO_UPDATE" as const,
        SVR_HANDBOOK_UPDATE: "CHARACTER.SVR_HANDBOOK_UPDATE" as const,
        SVR_FAME_UPDATE: "CHARACTER.SVR_FAME_UPDATE" as const,
    } as const;

    static readonly UI = {
        ON_CLICK_TROOP_STATE_UI: "UI.ON_CLICK_TROOP_STATE_UI",
        TROOP_OPEN: "UI.TROOP_OPEN",

        ITEM_SVR_UPDATE: "UI.ITEM_SVR_UPDATE",

        TROOP_INIT: "UI.TOOP_INIT",
        TROOP_EDIT: "UI.TROOP_EDIT",
        TROOP_EXCHANGE_HERO: "UI.TROOP_EXCHANGE_HERO",
        TROOP_INSTALL_SKIN: "UI.TROOP_INSTALL_SKIN",
    } as const;

    static readonly ALLIANCE = {
        OPEN_ALLIANCE: "openAlliance",
    } as const;

    static readonly TRAINCAMP = {
        CLICK_ICON: "TRAINCAMP.CLICK_ICON",
        TAINCAMP_INIT: "TRAINCAMP.TAINCAMP_INIT",
        TAINCAMP_ACTIVE_NOMAL: "TRAINCAMP.TAINCAMP_ACTIVE_NOMAL",
        TAINCAMP_ACTIVE_STRONG: "TRAINCAMP.TAINCAMP_ACTIVE_STRONG",
    };

    static readonly SkILL = {
        SKILL_SCREEN: "SkILL.SKILL_SCREEN",
        SKILL_MAP_SCREEN: "SKILL.SKILL_MAP_SCREEN",
        SKILL_ACTIVE: "SKILL.SKILL_ACTIVE",
        SKILL_UP_GRADE: "SKILL.SKILL_UP_GRADE",
        SKILL_MOUNT: "SKILL.SKILL_MOUNT",
        SKILL_UNMOUNT: "SKILL.SKILL_UNMOUNT",
    };

    static readonly CHEST_BOX = {
        ON_CHOOSE_CHEST_CHANGED: "CHEST_BOX.ON_CHOOSE_CHEST_CHANGED",
        UNLOCK_HERO_CHANGED: "CHEST_BOX.UNLOCK_HERO_CHANGED",
    };

    static readonly LEADERSKIN = {
        LEADERSKINUPDATE: "LEADERSKIN.LEADERSKINUPDATE",
        LEADERSKINUPGRADE: "LEADERSKIN.LEADERSKINUPGRADE",
    };

    static readonly DRAWCARD = {
        UPDATEPOOL: "DRAWCARD.UPDATEPOOL",
        GAINREWARD: "DRAWCARD.GAINREWARD",
        DrawCard: "DRAWCARD.DrawCard",
        BUYITEM: "DRAWCARD.BUYITEM",
        UPDATECOMMONINFO: "DRAWCARD.UPDATECOMMONINFO",
    };

    static readonly PVE = {
        // ON_SVR_PROBING_ENTER_LEAVE_WOOD_FACTORY: "onSvrProbingEnterLeaveWoodFactory",

        ON_ANIMATION_LOADED: "onPveAnimationLoaded",
        ON_ADD_DEBUG_SOLDIERS: "onAddDebugSoldsers",
        ON_SKILL_WARNING_LOADED: "onPveSkillWarningLoaded",
        ON_UI_MAP_TRANSFER_LOADED: "onPveMapTransferLoaded",
        ON_ENTER_LEAVE_TERRITORY: "onEnterLeaveTerritory",

        JOYSTICK_UPDATE: "pveJoystickUpdate",

        ON_RESCUE_ENTER: "onPveRescueEnter",
        ON_RESCUE_LEAVE: "onPveRescueLeave",
        ON_UI_RESCUE_LOADED: "onPveUIRescueLoaded",

        ON_CLOUD_CONTROL_ENTER: "onPveCloudControlEnter",
        ON_CLOUD_CONTROL_LEAVE: "onPveCloudControlLeave",
        ON_UI_CLOUD_LOADED: "onPveUICloudLoaded",
        ON_UI_BUILD_CONFIRM_LOADED: "onUIBuildConfirmLoaded",
        ON_UI_DB_UPGRADE_LOADED: "onUIDBUpgradeLoaded",
        ON_UI_DB_PRODUCTIONINFO_LOADED: "onUIDBProductionInfoLoaded",
        ON_UI_DB_COMMONINFO_LOADED: "onUIDBCommonInfoLoaded",
        ON_UI_BUILDING_CONFIRM_LOADED: "onUIBuildingConfirmLoaded",
        ON_UI_NPC_OP_CONFIRM_LOADED: "onUINpcOpConfirmLoaded",

        ON_BUILDING_ENTER: "onBuildingEnter",
        ON_BUILDING_LEAVE: "onBuildingLeave",

        ON_BUILDING_START_UPGRADE: "onBuildingStartUpgrade",
        ON_BUILDING_UPGRADE_COMP: "onBuildingUpgradeComp",
        ON_BUILDING_HARVEST_COMP: "onBuildingHarvestComp",

        ON_OUT_OF_FIGHT: "PVE.ON_OUT_OF_FIGHT",

        GM_CMD: "PVE.GM_CMD",

        CAMERA_MOVE_TO: "PVE.CAMERA_MOVE_TO",
        RESET_CAMERA_TO_HERO: "PVE.RESET_CAMERA_TO_HERO",
    } as const;

    static readonly PVE_SVR = {
        ON_ATK_TARGET_CHANGE: "PVE_SVR.ON_ATK_TARGET_CHANGE",
    } as const;

    static readonly PVP = {
        TOUCH_AREA_MOUSE_DOWN: "PVP.TOUCH_AREA_MOUSE_DOWN",
        TILEMAP_DRAG_START: "PVP.TILEMAP_DRAG_START", // 第一个移动事件
        TILEMAP_DRAG_MOVE: "PVP.TILEMAP_DRAG_MOVE", // 后续移动事件
        TILEMAP_DRAG_END: "PVP.TILEMAP_DRAG_END",
        TILEMAP_CLICK: "PVP.TILEMAP_CLICK",
        TOUCH_AREA_MOUSE_OUT: "PVP.TOUCH_AREA_MOUSE_OUT",

        S2C_TROOP_LOAD: "PVP.S2C_TROOP_LOAD",

        SELECT_TROOP: "PVP.SELECT_TROOP",

        OPEN_ACTION_MENU: "PVP.OPEN_ACTION_MENU",
        OPEN_RESOURCE_INFO: "PVP.OPEN_RESOURCE_INFO",

        UPDATE_TROOP_ENTITY: "PVP.UPDATE_TROOP_ENTITY", // 更新部队状态
        UPDATE_TROOP_ANIM: "PVP.UPDATE_TROOP_ANIM", // 更新部队动画

        CAMERA_UPDATE_POS: "PVP.CAMERA_UPDATE_POS", //更新相机位置

        /** 加载沙盘 PVP 多对多场景 */
        ON_LOAD_PVP_NVN: "loadPvpNvN",
        /** 释放沙盘 PVP 多对多场景 */
        ON_UNLOAD_PVP_NVN: "unloadPvpNvN",
    } as const;

    static readonly PNVN = {
        TOUCH_AREA_MOUSE_DOWN: "onPnvnTouchAreaMouseDown",
        TOUCH_AREA_MOUSE_OUT: "onPnvnTouchAreaMouseOut",
        TILEMAP_DRAG_START: "onPnvnTilemapDragStart", // 第一个移动事件
        TILEMAP_DRAG_MOVE: "onPnvnTilemapDragMove", // 后续移动事件
        TILEMAP_DRAG_END: "onPnvnTilemapDragEnd",
        TILEMAP_CLICK: "onPnvnTilemapClick",
    } as const;

    /**
     * 给战斗系统用的事件，需要传到进去行为树的，需要抹除server和client的差异
     */
    static readonly BTREE = {
        ON_CREATURE_DIE: "BTREE.ON_CREATURE_DIE", //生物死亡
        ON_OUT_OF_FIGHT: "BTREE.ON_OUT_OF_FIGHT",
        ON_BE_ATKED: "BTREE.ON_BE_ATKED", //被攻击 --不一定会造成伤害，因为可能miss，也可能被免疫
        ON_ATK_MISS: "BTREE.ON_ATK_MISS", //攻击miss
        ON_DEfENCE_MISS: "BTREE.ON_DEfENCE_MISS", //防御miss
        ON_COUSE_HURT: "BTREE.ON_COUSE_HURT", //造成伤害
        ON_GET_HURT: "BTREE.ON_GET_HURT", //受到伤害
        ON_HP_DECREASE: "BTREE.ON_HP_DECREASE", //血量减少
    };
}
