import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrElementComponent } from "../../ecs/components/PveSvrElementComponent";
import { PveSvrOffsetComponent } from "../../ecs/components/PveSvrOffsetComponent";
import { PveSvrTroopComponent } from "../../ecs/components/PveSvrTroopComponent";

export class CalcHeroDistance extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const offsetComp = env.owner.getComponent(PveSvrOffsetComponent);
        if (!offsetComp) {
            return "failure";
        }
        const troopComp = env.context.ecs.getComponent(offsetComp.troopEid, PveSvrTroopComponent);
        if (!troopComp) {
            return "failure";
        }

        const hero = env.context.ecs.getComponent(troopComp.leaderEid, PveSvrElementComponent);

        if (!hero) {
            return "failure";
        }
        env.output.push(
            Laya.Vector3.distanceXZ(hero.transform.position, env.owner.transform.position)
        );
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "CalcHeroDistance",
            type: "Action",
            desc: "计算与主将之间的距离",
            output: ["距离"],
        };
    }
}
