/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { AreaProgressBarRewardItemUI } from "./AreaProgressBarRewardItemUI";

/**
 * resources/prefab/map-transfer/area-progress-bar.lh
 */
export class AreaProgressBarUIBase extends Laya.Box {
    public img_bar_bg!: Laya.Image;
    public img_bar!: Laya.Image;
    public item_1!: AreaProgressBarRewardItemUI;
    public item_2!: AreaProgressBarRewardItemUI;
    public item_3!: AreaProgressBarRewardItemUI;
    public item_4!: AreaProgressBarRewardItemUI;
    public text_tsd!: Laya.Label;
}

