import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { IVector3Like } from "../../../../../core/laya";
import { TroopState } from "../../../../../def/auto/world";
import { res } from "../../../../../misc/res";
import { SystemEvent } from "../../../../../misc/system-event";
import { AnimName } from "../../../base/Animator";
import { PVPUtils } from "../../PVPUtils";
import { PvpContext } from "../../PvpContext";
import { PvpCameraComponent } from "../components/PvpCameraComponent";
import { PvpHeadInfoComponent } from "../components/PvpHeadInfoComponent";
import { PvpMovementComponent } from "../components/PvpMovementComponent";
import { PvpOwnerComponent } from "../components/PvpOwnerComponent";
import { PvpStateComponent } from "../components/PvpStateComponent";
import { PvpTroopBattleVFXComponent } from "../components/PvpTroopBattleVFXComponent";
import { PvpTroopComponent } from "../components/PvpTroopComponent";
import { PvpTroopSelectVFXComponent } from "../components/PvpTroopSelectVFXComponent";

export class PvpTroopSystem extends ecs.System {
    declare context: PvpContext;

    override onCreate() {
        this.context.$(app).on(SystemEvent.PVP.UPDATE_TROOP_ENTITY, this._updateTroopState, this);
        this.context.$(app).on(SystemEvent.PVP.SELECT_TROOP, this._onSelectTroop, this);
        this.context.$(app).on(SystemEvent.PVP.UPDATE_TROOP_ANIM, this._onTroopAnim, this);
    }

    private _updateTroopState(eid: number) {
        const troop = this.ecs.getComponent(eid, PvpTroopComponent);
        if (!troop) {
            return;
        }

        const state = troop.getComponent(PvpStateComponent)!;
        const owner = troop.getComponent(PvpOwnerComponent)!;

        if (state.state === TroopState.INSIDE) {
            troop.removeComponent(PvpTroopSelectVFXComponent);
            troop.removeComponent(PvpMovementComponent);
            troop.removeComponent(PvpHeadInfoComponent);
        } else {
            // 同时添加headInfoComponent
            const infoComp = troop.addComponent(PvpHeadInfoComponent);
            infoComp.res = res.BATTLE_PVP_HEAD_INFO1;
            if (owner.insideEid) {
                console.error(
                    `troop state error: eid=${eid} insideEid=${owner.insideEid} state=${state.state}`
                );
            }
        }

        if (troop.view) {
            troop.view.active = state.state !== TroopState.INSIDE;
        }

        this._updateTroopAnimation(troop);
    }

    // 部队战斗表现相关
    private _updateTroopAnimation(troop: PvpTroopComponent) {
        const state = troop.getComponent(PvpStateComponent)!;
        const setPosition = (target: Laya.Sprite3D, p: IVector3Like) => {
            const position = target.transform.localPosition;
            position.cloneFrom(p);
            target.transform.localPosition = position;
        };
        if (state.state === TroopState.FIGHTING) {
            const tbVFXComp = troop.addComponent(PvpTroopBattleVFXComponent);
            const ownerComp = troop.getComponent(PvpOwnerComponent);
            const pStyle = PVPUtils.CheckCampStyle(this.context, ownerComp!);
            tbVFXComp.pStyle = pStyle;
            tbVFXComp.res = res.VFX_PVP_TROOP_BATTLE;

            const formation = troop.formation.fight;
            troop.animators.forEach((animator, idx) => {
                if (idx === 0) {
                    setPosition(animator.owner as Laya.Sprite3D, formation.hero);
                } else {
                    setPosition(animator.owner as Laya.Sprite3D, formation.soldiers[idx - 1]);
                }
            });
            this._onTroopAnim(state.eid, AnimName.ATTACK);
        } else {
            troop.removeComponent(PvpTroopBattleVFXComponent);
            const formation = troop.formation.move;
            troop.animators.forEach((animator, idx) => {
                if (idx === 0) {
                    setPosition(animator.owner as Laya.Sprite3D, formation.hero);
                } else {
                    setPosition(animator.owner as Laya.Sprite3D, formation.soldiers[idx - 1]);
                }
            });
            if (state.state === TroopState.MOVE) {
                this._onTroopAnim(state.eid, AnimName.MOVE);
            } else if (state.state === TroopState.IDLE) {
                this._onTroopAnim(state.eid, AnimName.IDLE);
            }
        }
    }

    private _onSelectTroop(eid: number, from: string) {
        const clearAll = () => {
            // 如果没有选中部队，清除所有选中特效
            this.ecs.getComponents(PvpTroopSelectVFXComponent).forEach((comp) => {
                comp.removeComponent(PvpTroopSelectVFXComponent);
            });
        };

        const troopComp = this.ecs.getComponent(eid, PvpTroopComponent);
        if (!troopComp) {
            clearAll();
            return;
        }
        if (from === "UI") {
            const moveComp = this.ecs.getComponent(eid, PvpMovementComponent);
            if (!moveComp || moveComp.speed === 0) {
                clearAll();
                return;
            }
        }

        const ownerComp = this.ecs.getComponent(eid, PvpOwnerComponent);
        const vfxComp = this.ecs.addComponent(eid, PvpTroopSelectVFXComponent);
        if (!ownerComp || !vfxComp) {
            return;
        }

        vfxComp.pStyle = PVPUtils.CheckCampStyle(this.context, ownerComp);
        vfxComp.flag |= PvpTroopSelectVFXComponent.UPDATE;

        const cameraComp = this.ecs.getSingletonComponent(PvpCameraComponent);
        cameraComp.focusEid = vfxComp.eid;
    }

    private _onTroopAnim(eid: number, name: AnimName) {
        const troop = this.ecs.getComponent(eid, PvpTroopComponent);
        if (troop) {
            if (troop.animators.length === 0) {
                // console.warn(`not found animator: ${eid}`);
                return;
            }
            for (const animator of troop.animators) {
                switch (name) {
                    case AnimName.ATTACK: {
                        animator.loopPlay("attack1");
                        break;
                    }
                    case AnimName.IDLE:
                        animator.loopPlay("idle");
                        break;
                    case AnimName.MOVE: {
                        animator.loopPlay("move");
                        break;
                    }
                    default:
                        console.warn(`unhandle AnimName: ${name}`);
                        break;
                }
            }
        } else {
            console.warn(`not found troop component: ${eid}`);
        }
    }
}
