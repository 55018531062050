/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { AllianceMemberLeader } from "./AllianceMemberLeader";
import { UIButton } from "../../../core/ui/UIButton";

/**
 * resources/prefab/alliance/UIAllianceAppoint.lh
 */
export class UIAllianceMemberBase extends Laya.Dialog {
    public leader!: AllianceMemberLeader;
    public btnClose!: UIButton;
}

