import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { SystemEvent } from "../../../../../misc/system-event";
import { PveContext } from "../../PveContext";
import { PveSkillWarningComponent } from "../components/PveSkillWarningComponent";
import { PveTransformComponent } from "../components/PveTransformComponent";

const tempPos: Laya.Vector3 = new Laya.Vector3();

export class PveSkillWarningSystem extends ecs.System {
    declare context: PveContext;

    override onCreate() {
        super.onCreate();
        this.context
            .$(app)
            .on(SystemEvent.PVE.ON_SKILL_WARNING_LOADED, this.onPveLoadSkillWarningComp, this);
    }

    override update(dt: number): void {
        this.ecs.getComponents(PveSkillWarningComponent).forEach((swComp) => {
            this.updatePosAndRot(swComp);
            this.updateProgress(swComp);
        });
    }

    private onPveLoadSkillWarningComp(eid: number): void {
        const swComp = this.ecs.getComponent(eid, PveSkillWarningComponent);
        this.onCreateView(swComp!);
    }

    private updatePosAndRot(swComp: PveSkillWarningComponent): void {
        const ownerEntityId = swComp.skillSelectorArgs.ownerEntityId;
        const ownerTransform = this.ecs.getComponent(ownerEntityId, PveTransformComponent);
        if (!ownerTransform) {
            return;
        }
        const targetTransform = swComp.mounter.transform;
        tempPos.cloneFrom(ownerTransform.position);
        tempPos.y = 0.05;
        targetTransform.position = tempPos;
        const roEuler = targetTransform.rotationEuler;
        roEuler.y = ownerTransform.rotation;
        targetTransform.rotationEuler = roEuler;
    }

    private updateProgress(swComp: PveSkillWarningComponent): void {
        if (swComp.view) {
            // 更新进度
            const currentTime = this.context.time;
            let overTime = currentTime - swComp.startTime;
            const duration = swComp.skillSelectorArgs.duration;
            if (overTime > duration) {
                overTime = duration;
            }
            // 进度 0 ~ 1
            const progress = overTime / duration;

            const plane = swComp.view!.getChildAt(0);
            const renderer = plane.getComponent(Laya.MeshRenderer)!;
            const material = renderer.material;
            material.setFloat("u_Progress", progress);
        }
    }

    private onCreateView(swComp: PveSkillWarningComponent): void {
        this.initShowView(swComp);
        this.updatePosAndRot(swComp);
        this.updateProgress(swComp);
    }

    private initShowView(swComp: PveSkillWarningComponent): void {
        const distance = swComp.skillSelectorArgs.distance;
        const width = swComp.skillSelectorArgs.width;
        if (swComp.skillSelectorArgs.shape === "rect") {
            // 矩形
            // 设置长度和宽度
            swComp.view!.transform.localScale = new Laya.Vector3(width, 1.0, distance);
        } else if (swComp.skillSelectorArgs.shape === "fan") {
            // 扇形
            // 设置长度
            swComp.view!.transform.localScale = new Laya.Vector3(distance, 1.0, distance);
            // 设置角度
            const plane = swComp.view!.getChildAt(0);
            const renderer = plane.getComponent(Laya.MeshRenderer)!;
            const material = renderer.material;
            material.setFloat("u_Angle", width);
        }
    }
}
