import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { IVector3Like } from "../../../../../core/laya";
import { BattleConf, BattleEntityType, PveEventType } from "../../../../../def/auto/battle";
import { SystemEvent } from "../../../../../misc/system-event";
import { PveServer } from "../../PveServer";
import { PveSvrCreatureComponent } from "../components/PveSvrCreatureComponent";
import { PveSvrElementComponent } from "../components/PveSvrElementComponent";
import { PveSvrOffsetComponent } from "../components/PveSvrOffsetComponent";
import { PveSvrTransformComponent } from "../components/PveSvrTransformComponent";
import { PveSvrTroopComponent } from "../components/PveSvrTroopComponent";
import { PveSvrTruckComponent } from "../components/PveSvrTruckComponent";
import { PveSvrCollectionStateData } from "../data/state-data/PveSvrCollectionStateData";
import { PveSvrMonsterTroopStateData } from "../data/state-data/PveSvrMonsterTroopStateData";
import { PveSvrSpoilsSystem } from "./PveSvrSpoilsSystem";

export type SeparateFrameCheckFunc = () => void;

/** 记录地图场景当前状态的系统
 * TODO: 1.把一次性的和需要复活的分离
 * TODO: 2.分离方法和数据平坦分帧执行
 * TODO: 3.是否只轮询视野范围内的
 */
export class PveSvrStateSystem extends ecs.System {
    /** 每10秒进行储存一次状态 */
    private static readonly RECORD_STATE_DELAY = 10;

    private static readonly TICK = 0.5;

    declare context: PveServer;

    private _lastTick: number = 0;

    private currentCheckIndex: number = 0;

    private needCheckTick: boolean = true;

    private _recordStateTime: number = 0;

    /** 分帧执行的方法数组 */
    private checkFuns: Array<SeparateFrameCheckFunc> = [
        this.checkReliveCollection.bind(this),
        this.checkReliveMonsterTroop.bind(this),
        this.checkRemoveOverdueSpoils.bind(this),
    ];

    override onCreate(): void {
        app.on(SystemEvent.PVE.ON_ADD_DEBUG_SOLDIERS, this, this.onAddDebugSoldiers);
    }

    private onAddDebugSoldiers(): void {
        PveSvrStateSystem.addDebugSoldiers(this.context);
        app.ui.toast("已添加测试士兵");
    }

    override onDestroy(): void {
        app.off(SystemEvent.PVE.ON_ADD_DEBUG_SOLDIERS, this, this.onAddDebugSoldiers);
    }

    override update(dt: number): void {
        this.checkRecordState(dt);

        if (this.needCheckTick) {
            const time = this.context.time;
            if (time - this._lastTick < PveSvrStateSystem.TICK) {
                return;
            }
            this._lastTick = time;
            this.needCheckTick = false;
        }
        // 每帧执行一个check方法
        this.checkFuns[this.currentCheckIndex]();
        this.currentCheckIndex++;
        if (this.currentCheckIndex >= this.checkFuns.length) {
            // 已经全部分帧处理完成骚等一会儿再轮询
            this.needCheckTick = true;
            this.currentCheckIndex = 0;
        }
    }

    /** 检测是否进行保存pve地图状态 */
    private checkRecordState(dt: number): void {
        this._recordStateTime += dt;
        if (this._recordStateTime >= PveSvrStateSystem.RECORD_STATE_DELAY) {
            this._recordStateTime = 0;
            PveSvrStateSystem.save(this.ecs);
        }
    }

    private checkRemoveOverdueSpoils(): void {
        const pveStateData = app.service.pve.mlData.pveStateData;
        const overdueSpoilsDatas = pveStateData.clearAllOverdueSpoilsData();
        if (overdueSpoilsDatas.length > 0) {
            // 需要移除场景上的战利品
            PveSvrSpoilsSystem.removeOverdueSpoils(this.context, overdueSpoilsDatas);
        }
    }

    private checkReliveMonsterTroop(): void {
        const pveStateData = app.service.pve.mlData.pveStateData;
        const monsterTroops: PveSvrMonsterTroopStateData[] = pveStateData.monsterTroops;
        for (let i = 0; i < monsterTroops.length; i++) {
            const monsterTroop = monsterTroops[i];
            if (monsterTroop.inView && monsterTroop.isShouldRelive) {
                this.context.reliveMonsterTroop(monsterTroop);
            }
        }
    }

    private checkReliveCollection(): void {
        const pveStateData = app.service.pve.mlData.pveStateData;
        const collections: PveSvrCollectionStateData[] = pveStateData.collections;
        for (let i = 0; i < collections.length; i++) {
            const collection = collections[i];
            if (collection.inView && !collection.isDeath) {
                if (collection.deathTime > 0) {
                    collection.deathTime = 0; // 复活重置
                }
                if (collection.hp <= 0) {
                    collection.hp = collection.maxHp;
                    this.context.reliveCollection(collection);
                }
            }
        }
    }

    /** 保存当前场景的状态 */
    public static save(wecs: ecs.World): void {
        // app.ui.toast("记录当前Pve状态");
        const pveStateData = app.service.pve.mlData.pveStateData;
        pveStateData.trucks.length = 0;
        // 需要注意的是，这里获取的都是未死亡的。
        const truckComps = wecs.getComponents(PveSvrTruckComponent);

        // 更新资源车状态数据
        truckComps.forEach((truckComp, key) => {
            const element = truckComp.getComponent(PveSvrElementComponent);
            const transform = truckComp.getComponent(PveSvrTransformComponent);
            if (element && transform) {
                const collectType = truckComp.collectType;
                const amount = truckComp.collectCnt;
                pveStateData.addTruck(
                    collectType,
                    amount,
                    transform.position.x,
                    transform.position.z,
                    transform.rotation
                );
            }
        });

        const creatures = wecs.getComponents(PveSvrCreatureComponent);
        creatures.forEach((creatureComp, key) => {
            const transform = creatureComp.transformComp;
            const hp = creatureComp.hp;
            const maxHp = creatureComp.maxHp;

            switch (creatureComp.etype) {
                case BattleEntityType.HERO:
                    // 主角
                    // 位置
                    pveStateData.hero.pos.set(transform.position.x, transform.position.z);
                    pveStateData.hero.rotation = transform.rotation;
                    // 血量
                    pveStateData.hero.hp = hp;
                    pveStateData.hero.maxHp = maxHp;
                    break;
                case BattleEntityType.SOLDIER: {
                    // 士兵
                    const offsetComp = creatureComp.getComponent(PveSvrOffsetComponent);
                    if (offsetComp) {
                        const idx = offsetComp.index;

                        const soldiderData = pveStateData.getSoldier(idx);
                        if (soldiderData) {
                            soldiderData.hp = hp;
                            soldiderData.maxHp = maxHp;
                            soldiderData.pos.set(transform.position.x, transform.position.z);
                            soldiderData.rotation = transform.rotation;
                        } else {
                            // 可能不存在offsetComp
                            const tid = creatureComp.eleComp.tid;
                            pveStateData.addSoldier(
                                idx,
                                tid,
                                hp,
                                maxHp,
                                transform.position.x,
                                transform.position.z,
                                transform.rotation
                            );
                        }
                    }
                    break;
                }
            }
        });

        // 需要注意的是，这里获取的都是未死亡的。
        const troopComps = wecs.getComponents(PveSvrTroopComponent);
        troopComps.forEach((troopComp, key) => {
            if (troopComp.isSelf) {
                return;
            }
            const troopEle = troopComp.eleComp;

            const getMonsterUpdateData = (eid: number, idx: number) => {
                const creatureComp = wecs.getComponent(eid, PveSvrCreatureComponent);
                const transform = wecs.getComponent(eid, PveSvrTransformComponent);
                if (!creatureComp || !transform) {
                    return undefined;
                }
                const monsterData = {
                    idx,
                    hp: creatureComp.hp,
                    maxHp: creatureComp.maxHp,
                    x: transform.position.x,
                    z: transform.position.z,
                    rotation: transform.rotation,
                };
                return monsterData;
            };

            const monsterDatas = [];
            const leader = getMonsterUpdateData(troopComp.leaderEid, 0);
            leader && monsterDatas.push(leader);
            for (const idx in troopComp.members) {
                const eid = troopComp.members[idx];
                const monsterData = getMonsterUpdateData(eid, Number(idx));
                monsterData && monsterDatas.push(monsterData);
            }

            const monsterTroopData = pveStateData.getOrCreateMonsterTroopByKey(
                troopEle.key,
                troopEle.tid
            );
            monsterDatas.forEach((v) => {
                monsterTroopData.setMonsterData(v.idx, v.hp, v.maxHp, v.x, v.z, v.rotation);
            });
        });
        app.service.pve.requestSave(pveStateData.encode());
    }

    public static addDebugSoldiers(pveSvr: PveServer) {
        const pveStateData = app.service.pve.mlData.pveStateData;
        const heroData = pveStateData.hero;
        if (heroData.isUnlockTroop) {
            console.error("heroData.isUnlockTroop, cannot addDebugSoldiers");
            return;
        }

        heroData.unlockSodiderIndexes.length = 0;
        const totalSoldierCount = BattleConf.PVE.SOLDIER_ONE_ROW_COUNT * 3;
        for (let i = 0; i < totalSoldierCount; i++) {
            heroData.unlockSodiderIndexes.push(i);
        }

        const heroEid = pveSvr.ctrlHeroEid;
        const heroEle = pveSvr.ecs.getComponent(heroEid, PveSvrElementComponent)!;
        const heroTrans = heroEle.getComponent(PveSvrTransformComponent)!;

        const eventVos = app.service.pve.eventLoMap.getEventLoArrWithEventType(
            PveEventType.RESCUE_SOLDIER
        );

        const soldierLv = 1; //默认测试等级
        for (let i = 0; i < eventVos.length; i++) {
            const solierDatas = eventVos[i].soldierDatas;
            solierDatas?.forEach((data) => {
                const tid = data.soldier;
                const station = data.pos;
                for (let i = 0; i < data.count; i++) {
                    const posIndex = pveSvr.getEmptySoldierPosByStation(station);
                    if (posIndex < 0) {
                        console.warn("addDebugSoldiers posIndex < 0", tid);
                        continue;
                    }
                    const soldierLo = app.service.hero.heroLoMap.get(tid);
                    pveStateData.addSoldier(
                        posIndex,
                        tid,
                        soldierLo.getConfHp(soldierLv),
                        soldierLo.getConfHp(soldierLv),
                        heroData.pos.x,
                        heroData.pos.y,
                        heroData.rotation
                    );

                    const offsetCfg = BattleConf.PVE.REVIVAL_BORN_OFFSET;
                    const px = heroTrans.position.x + offsetCfg.x;
                    const pz = heroTrans.position.z + offsetCfg.y;
                    pveSvr.addSoldierWith(heroEle, posIndex, tid, px, pz);
                }
            });
        }
    }

    /** 从服务端加载场景的状态 */
    public static async load(wecs: ecs.World) {
        await app.service.pve.requestLoad();
        const pveStateData = app.service.pve.mlData.pveStateData;
        const mainLineData = app.service.pve.mlData.mainLineData;
        if (mainLineData) {
            pveStateData.decode(mainLineData);
        }
    }

    /** 使用记录的数据进行设置英雄的位置和旋转 */
    public static setHeroPositionAndRotation(
        context: PveServer,
        transform: PveSvrTransformComponent
    ): void {
        const pveStateData = app.service.pve.mlData.pveStateData;
        const hero = pveStateData.hero;
        if (hero.inited) {
            transform.position.x = hero.pos.x;
            transform.position.z = hero.pos.y;
            transform.rotation = hero.rotation;
        } else {
            // 没有服务端数据时，使用配置表的
            this.setHeroDefaultPositionAndRotation(context, transform);
        }
    }

    public static flashHeroToPositionAndRotation(
        pveServer: PveServer,
        px: number,
        pz: number,
        rot: number
    ): void {
        const pveStateData = app.service.pve.mlData.pveStateData;
        const hero = pveStateData.hero;
        hero.pos.x = px;
        hero.pos.y = pz;
        hero.rotation = rot;
    }

    public static setHeroDefaultPositionAndRotation(
        context: PveServer,
        transform: PveSvrTransformComponent
    ): void {
        // 如果解锁2号迷雾（事件id：12002）后，则获取 map_transfer_101 作为复活点
        const pveStateData = app.service.pve.mlData.pveStateData;
        const events = pveStateData.events.filter((v) => !!v.eventVo);
        let birthPointX: number = 0;
        let birthPointZ: number = 0;
        let birthPointRot: number = 0;
        let isPass12002: boolean = false;
        for (const event of events) {
            const eventVo = event.eventVo!;
            if (
                eventVo.eventType === PveEventType.UNLOCK_CLOUD ||
                eventVo.eventType === PveEventType.TALK_TO_NPC
            ) {
                if (event.isDeath && eventVo.key === BattleConf.PVE.UNLOCK_TERRITORY_CLOUD_ID) {
                    const transferPos = app.service.pve.minimap.getTransferPos(
                        BattleConf.PVE.TERRITORY_TRANSFER_ID
                    );
                    if (transferPos) {
                        birthPointX = transferPos.x;
                        birthPointZ = transferPos.y;
                        birthPointRot = transferPos.rot;
                        isPass12002 = true;
                    }
                    break;
                }
            }
        }

        if (!isPass12002) {
            // 否则以配置的出生点进行出生
            const birthPointData = app.service.pve.minimap.getBirthPoint(1001);
            if (birthPointData) {
                birthPointX = birthPointData.x;
                birthPointZ = birthPointData.z;
                birthPointRot = birthPointData.rot;
            }
        }

        transform.position.x = birthPointX;
        transform.position.z = birthPointZ;
        transform.rotation = birthPointRot;
    }

    /** 设置资源车的位置和资源数量 */
    public static setTruckPosAndAmount(
        pveServer: PveServer,
        collectType: number,
        offset: IVector3Like,
        truckTransform: PveSvrTransformComponent,
        heroTransform: PveSvrTransformComponent,
        truckComp: PveSvrTruckComponent
    ): void {
        let position_x: number = 0;
        let position_z: number = 0;
        let rotation: number = 0;
        let amount: number = 0;

        const pveStateData = app.service.pve.mlData.pveStateData;
        const truckData = pveStateData.getTruckByCollectType(collectType);
        if (truckData) {
            position_x = truckData.pos.x;
            position_z = truckData.pos.y;
            rotation = truckData.rotation;
            amount = truckData.amount;
        } else {
            const truckPosition: Laya.Vector3 = new Laya.Vector3();
            pveServer.calcFollowerPosition(heroTransform, offset, truckPosition);
            position_x = truckPosition.x;
            position_z = truckPosition.z;
        }

        truckTransform.position.x = position_x;
        truckTransform.position.z = position_z;
        truckTransform.rotation = rotation;
        truckComp.collectCnt = amount;
    }
}
