const { regClass } = Laya;
import { app } from "../../../app";
import { BattleSkillRow } from "../../../def/table";
import { res } from "../../../misc/res";
import { SkillMapItemUI } from "./SkillMapItemUI";
import { SkillMapUIBase } from "./SkillMapUI.generated";

@regClass('B0N_OwJaQN-73Rl0qR3xdQ')
export class SkillMapUI extends SkillMapUIBase {
    private skillItemList: { [key: number]: SkillMapItemUI } = {}; //存放添加进入的

    private curClikItem: SkillMapItemUI | null = null;

    private vboxContentHeight: number = 0;

    constructor() {
        super();
    }

    async initList(skillDatas: BattleSkillRow[]) {
        this.vboxContent.removeChildren();
        this.curClikItem = null;
        this.vboxContentHeight = 0;
        const checker = (): boolean => !this.vboxContent.destroyed;
        for (const skillCfg of skillDatas) {
            const prefab = await app.loader.loadPrefab(res.skill.Skill_MAP_ITEM);
            if (!checker()) {
                return;
            }

            const itemBox = prefab.create() as SkillMapItemUI;
            this.vboxContent.addChild(itemBox);
            this.skillItemList[skillCfg.id] = itemBox;
            itemBox.dataSource = skillCfg;
            itemBox.on(Laya.Event.CLICK, this, this.onClickItem);
        }
        this.vboxContent.height = this.vboxContentHeight =
            skillDatas.length * 149 + (skillDatas.length - 1) * this.vboxContent.space;
    }

    private onClickItem(e: Laya.Event) {
        const targe = e.currentTarget as SkillMapItemUI;
        if (this.curClikItem === targe) {
            this.curClikItem.isShowDetails(false);
            this.vboxContent.height = this.vboxContent.height - 111;
            this.curClikItem = null;
            return;
        }
        if (!this.curClikItem) {
            this.vboxContent.height = this.vboxContent.height + 111;
        }
        if (this.curClikItem) {
            this.curClikItem.isShowDetails(false);
        }
        this.curClikItem = targe;
        this.curClikItem.isShowDetails(true);
    }
}
