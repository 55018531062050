import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { TroopState } from "../../../../../def/auto/world";
import { res } from "../../../../../misc/res";
import { SystemEvent } from "../../../../../misc/system-event";
import { ActionMenuUI } from "../../../ui-runtime/pvp/ActionMenuUI";
import { PvpHeadInfoData, PvpHeadInfoUI } from "../../../ui-runtime/pvp/PvpHeadInfoUI";
import { ResourceInfoUI } from "../../../ui-runtime/pvp/ResourceInfoUI";
import { PVPUtils } from "../../PVPUtils";
import { PvpContext } from "../../PvpContext";
import { PvpActionMenuMediator } from "../../ui/PvpActionMenuMediator";
import { PvpResourceInfoMediator } from "../../ui/PvpResourceInfoMediator";
import { PvpHeadInfoComponent } from "../components/PvpHeadInfoComponent";
import { PvpMovementComponent } from "../components/PvpMovementComponent";
import { PvpOwnerComponent } from "../components/PvpOwnerComponent";
import { PvpStateComponent } from "../components/PvpStateComponent";
import { PvpTransformComponent } from "../components/PvpTransformComponent";
import { PvpTroopComponent } from "../components/PvpTroopComponent";
import { PvpUIComponent } from "../components/PvpUIComponent";

const tmpInfoVector4 = new Laya.Vector4();
const tempVector3 = new Laya.Vector3();

/**
 * 与 PvpRenderSystem 的区别是：
 * PvpRenderSystem 专门负责Scene3D下的组件渲染
 * PvpUISystem 专门负责Scene2D UI的渲染
 */
export class PvpUISystem extends ecs.System {
    declare context: PvpContext;

    override onCreate() {
        this.context.$(app).on(SystemEvent.PVP.UPDATE_TROOP_ENTITY, this._onUpdateTroop, this);

        this.context.$(app).on(SystemEvent.PVP.OPEN_ACTION_MENU, this._openActionMenu, this);
        this.context.$(app).on(SystemEvent.PVP.OPEN_RESOURCE_INFO, this._openResouceInfo, this);
        this.context.$(app).on(SystemEvent.PVP.CAMERA_UPDATE_POS, this._onCameraUpdatePos, this);

        this.registerHandler(
            PvpHeadInfoComponent,
            this._onAddPvpHeadInfoComponent,
            this._onDelPvpHeadInfoComponent
        );
    }

    override update(dt: number): void {
        this.ecs.getComponents(PvpHeadInfoComponent).forEach((info) => {
            this._updateHeadInfoComponent(info);
        });
    }

    override onDestroy(): void {
        const uiComp = this.ecs.getSingletonComponent(PvpUIComponent);
        if (uiComp) {
            uiComp.actionMenu?.destroy();
            uiComp.resourceInfo?.destroy();
        }
    }

    //#region HeadInfoComponent
    private async _onAddPvpHeadInfoComponent(info: PvpHeadInfoComponent) {
        const checker = info.checker;
        const prefab = await app.loader.loadPrefab(info.res);
        if (!checker()) {
            return;
        }

        info.view = prefab.create() as PvpHeadInfoUI;
        this.context.owner.troops.addChild(info.view);

        this._onUpdateTroop(info.eid);
    }

    private _onDelPvpHeadInfoComponent(comp: PvpHeadInfoComponent) {
        comp.view?.destroy();
        comp.view = null;
        comp.mounter.destroy();
    }

    private _updateHeadInfoComponent(info: PvpHeadInfoComponent) {
        if (!info.view) {
            return;
        }

        const troopComp = info.getComponent(PvpTroopComponent)!;
        if (!troopComp) {
            return;
        }
        this.context.camera.worldToViewportPoint(
            troopComp.mounter.transform.position,
            tmpInfoVector4
        );
        const x = tmpInfoVector4.x;
        const y = tmpInfoVector4.y;
        tempVector3.cloneFrom(troopComp.mounter.transform.position);
        tempVector3.z -= 0.5;
        tempVector3.x -= 0.5;
        this.context.camera.worldToViewportPoint(tempVector3, tmpInfoVector4);
        const offset = y - tmpInfoVector4.y;

        info.view.pos(x - info.view.width / 2, y - offset - info.data.offset, true);

        if (info.flag & PvpHeadInfoComponent.UPDATE) {
            info.flag &= ~PvpHeadInfoComponent.UPDATE;
            info.view.refresh(info.data);
        } else if (
            info.data?.state === TroopState.MOVE &&
            info.view &&
            info.view.lastRefreshTime + 1000 < Date.now()
        ) {
            const costTime = this._culcTroopMoveCost(info);
            if (costTime >= 0) {
                info.data.costTime = costTime;
                info.flag |= PvpHeadInfoComponent.UPDATE;
            }
        }
    }

    //#endregion HeadInfoComponent

    //#region event handlers
    private async _openActionMenu(currXZPos: Readonly<Laya.Vector3>) {
        const uiComp = this.ecs.getSingletonComponent(PvpUIComponent);
        if (!uiComp.actionMenu) {
            const checker = () => !this.ecs.destroyed;
            const prefab = await app.loader.loadPrefab(res.battle.PVP_ACTION_MENU);
            if (!checker()) {
                return;
            }
            uiComp.actionMenu = prefab.create() as ActionMenuUI;
        }

        const troopEids = PVPUtils.GetSelfActionableTroopEids(this.context);
        if (!troopEids?.length) {
            return; // 没有可操作的部队, 不显示操作菜单
        }

        if (uiComp.actionMenu.parent !== this.context.owner) {
            this.context.owner.addChild(uiComp.actionMenu);
        }
        uiComp.actionMenu.getComponent(PvpActionMenuMediator)?.refresh(this.context, currXZPos);
    }

    private async _openResouceInfo(currXZPos: Laya.Vector3) {
        const uiComp = this.ecs.getSingletonComponent(PvpUIComponent);
        if (!uiComp.resourceInfo) {
            const checker = () => !this.context.owner.destroyed;
            const prefab = await app.loader.loadPrefab(res.battle.PVP_RESOUCE_INFO);
            if (!checker()) {
                return;
            }

            uiComp.resourceInfo = prefab.create() as ResourceInfoUI;
        }

        if (uiComp.resourceInfo.parent !== this.context.owner) {
            this.context.owner.addChild(uiComp.resourceInfo!);
        }
        uiComp.resourceInfo.getComponent(PvpResourceInfoMediator)?.refresh(this.context, currXZPos);
    }

    private _onUpdateTroop(eid: number) {
        const info = this.ecs.getComponent(eid, PvpHeadInfoComponent);
        if (info && info.view) {
            const troopComp = this.ecs.getComponent(info.eid, PvpTroopComponent);
            const ownerComp = this.ecs.getComponent(info.eid, PvpOwnerComponent);
            const stateComp = this.ecs.getComponent(info.eid, PvpStateComponent);
            if (!troopComp || !ownerComp || !stateComp) {
                console.warn("troopComp or ownerComp or stateComp is null, _onUpdateTroop failed!");
                return;
            }

            const heroRow = app.service.table.hero.hero[troopComp.heroId];
            const pStyle = PVPUtils.CheckCampStyle(this.context, ownerComp!);

            const data: PvpHeadInfoData = {
                name: `${heroRow?.name ?? "1"}队`,
                state: stateComp.state,
                hp: troopComp.hp,
                maxHp: troopComp.maxHp,
                style: pStyle,
                offset: PvpHeadInfoComponent.DEFAUFT_OFFSET,
            };
            if (stateComp.state === TroopState.MOVE) {
                const costTime = this._culcTroopMoveCost(info);
                data.costTime = costTime === -1 ? 0 : costTime;
            }

            info.flag |= PvpHeadInfoComponent.UPDATE;
            info.data = data;
        }
    }

    private _culcTroopMoveCost(headInfoComp: PvpHeadInfoComponent) {
        const moveComp = this.ecs.getComponent(headInfoComp.eid, PvpMovementComponent);
        const transformComp = this.ecs.getComponent(headInfoComp.eid, PvpTransformComponent);

        if (moveComp && transformComp) {
            if (moveComp.speed === 0) {
                return -1;
            }
            const curPos = transformComp.position.clone();
            let totalDis = 0;
            for (let i = moveComp.index + 1; i < moveComp.paths.length; i++) {
                totalDis += Laya.Vector3.distance(curPos, moveComp.paths[i]);
                curPos.cloneFrom(moveComp.paths[i]);
            }
            const costTime = Math.round(totalDis / moveComp.speed);
            return costTime;
        } else {
            return -1;
        }
    }

    private _onCameraUpdatePos() {
        const uiComp = this.ecs.getSingletonComponent(PvpUIComponent);
        uiComp.actionMenu?.getComponent(PvpActionMenuMediator)?.updatePos(this.context);
        uiComp.resourceInfo?.getComponent(PvpResourceInfoMediator)?.updatePos(this.context);
    }
    //#endregion event handlers
}
