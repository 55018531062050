/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { AllianceAvatar } from "./AllianceAvatar";
import { UIButton } from "../../../core/ui/UIButton";

/**
 * resources/prefab/alliance/AllianceApplyListItem.lh
 */
export class AllianceApplyListItemBase extends Laya.Box {
    public avatar!: AllianceAvatar;
    public btnAccept!: UIButton;
    public btnReject!: UIButton;
    public username!: Laya.Label;
    public powerText!: Laya.Label;
}

