/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { BaseRoundWindowBg } from "../../../common/ui-runtime/dialog/BaseRoundWindowBg";
import { CharacterMatDispBox } from "../detail/CharacterMatDispBox";

/**
 * resources/prefab/character/uicharacter-upstage.lh
 */
export class UICharacterUpStageBase extends Laya.Dialog {
    public base_round_window_bg!: BaseRoundWindowBg;
    public lblPreValue0!: Laya.Label;
    public lblNextValue0!: Laya.Label;
    public lblPreValue1!: Laya.Label;
    public lblNextValue1!: Laya.Label;
    public lblPreValue2!: Laya.Label;
    public lblNextValue2!: Laya.Label;
    public lblPreValue3!: Laya.Label;
    public lblNextValue3!: Laya.Label;
    public lblNextStage!: Laya.Label;
    public boxSelect!: CharacterMatDispBox;
    public btnUpStage!: Laya.Button;
}
