/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { AllianceAvatar } from "./AllianceAvatar";
import { UIButton } from "../../../core/ui/UIButton";

/**
 * resources/prefab/alliance/UIAllianceMemberAction.lh
 */
export class UIAllianceMemberActionBase extends Laya.Box {
    public avatar!: AllianceAvatar;
    public leaderName!: Laya.Label;
    public officer!: Laya.Label;
    public stateText!: Laya.Label;
    public onlineText!: Laya.Label;
    public powerText!: Laya.Label;
    public btnAppoint!: UIButton;
    public btnKickout!: UIButton;
}

