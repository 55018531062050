const { regClass, property } = Laya;
import { ImageRef } from "../../../../misc/ImageRef";
import { ItemLo } from "../../../bag/vo/ItemLo";
import { ItemVo } from "../../../bag/vo/ItemVo";
import { HeroLo } from "../../../hero/vo/HeroLo";

import { BaseMidItemIconBase } from "./BaseMidItemIcon.generated";

@regClass('wkTvnKKzR-S1G7gfpOz5GQ')
export class BaseMidItemIcon extends BaseMidItemIconBase {
    public setItemLo(itemLo: ItemLo): void {
        const qArr = this.img_bg.getComponent(ImageRef)!.texturePaths;
        this.img_bg.skin = qArr[itemLo.quality];
        this.img_icon.skin = itemLo.iconUrl;
    }

    public setHeroLo(heroLo: HeroLo): void {
        const qArr = this.img_bg.getComponent(ImageRef)!.texturePaths;
        this.img_bg.skin = qArr[heroLo.baseQuality];
        this.img_icon.skin = heroLo.iconMiddle!;
    }

    public setItemVo(itemVo: ItemVo): void {
        const qArr = this.img_bg.getComponent(ImageRef)!.texturePaths;
        this.img_bg.skin = qArr[itemVo.quality];
        this.img_icon.skin = itemVo.iconUrl!;
    }
}
