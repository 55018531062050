import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrTroopComponent } from "../../ecs/components/PveSvrTroopComponent";

interface NodeArgs {
    readonly limit?: number;
}

export class GetFightingEnemyTroops extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const enemyTroops = env.context.getAllFightingEnemyTroops();
        if (enemyTroops.length === 0) {
            return "failure";
        }
        const cb = (() => {
            const cache = new Map<number, number>(); //闭包缓存距离数据
            return (a: PveSvrTroopComponent, b: PveSvrTroopComponent) => {
                let aDis: number = 0;
                if (cache.has(a.eid)) {
                    aDis = cache.get(a.eid)!;
                } else {
                    aDis = Laya.Vector3.distanceXZ(
                        a.eleComp.transform.position,
                        env.owner.transform.position
                    );
                    cache.set(a.eid, aDis);
                }

                let bDis: number = 0;
                if (cache.has(b.eid)) {
                    bDis = cache.get(b.eid)!;
                } else {
                    bDis = Laya.Vector3.distanceXZ(
                        b.eleComp.transform.position,
                        env.owner.transform.position
                    );
                    cache.set(b.eid, bDis);
                }

                return aDis - bDis;
            };
        })();
        enemyTroops.sort(cb);

        const limit = (node.args as unknown as NodeArgs).limit;
        if (typeof limit === "number" && limit > 0) {
            enemyTroops.length = Math.min(limit, enemyTroops.length);
        }
        const eids = enemyTroops.map((troop) => troop.eid);
        if (eids.length === 0) {
            return "failure";
        }
        env.output.push(eids);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "GetFightingEnemyTroops",
            type: "Condition",
            desc: "获取战斗中所有敌人的编队",
            args: [
                { name: "limit", type: "int?", desc: "限制获取个数" }, //队伍里的leader，怪物只有一个时，就是队伍里的leader
            ],
            output: ["敌对编队"],
            doc: `
            获取战斗中所有敌人的编队
            + 获取全部敌对单位的编队，以编队首领距离排序，从近到远。
            + 如果只想获取最近的敌对编队，可以设置limit为1
            + 如果没有交战中的敌对编队，返回失败
            `,
        };
    }
}
