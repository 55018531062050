/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { BDFuncProductionSpeedItemUI } from "./BDFuncProductionSpeedItemUI";

/**
 * resources/prefab/battle/pve-ui/building_func/bd_func_production_speed_block.lh
 */
export class BDFuncProductionSpeedBlockUIBase extends Laya.Box {
    public speedItem1!: BDFuncProductionSpeedItemUI;
    public speedItem2!: BDFuncProductionSpeedItemUI;
    public img_bd!: Laya.Image;
}

