import * as ecs from "../../../../../core/ecs";
import { PveContext } from "../../PveContext";
import { PveElementComponent } from "../components/PveElementComponent";
import { PveUINpcOperationConfirmComponent } from "../components/PveUINpcOperationConfirmComponent";

const tempVector3 = new Laya.Vector3();
const tempVector4 = new Laya.Vector4();

export class PveNpcSystem extends ecs.System {
    declare context: PveContext;

    override onCreate(): void {}

    // private _on(event: string, callback: Callback) {
    //     this.context.$(app).on(event, callback, this);
    // }

    override update(dt: number): void {
        this.ecs.getComponents(PveUINpcOperationConfirmComponent).forEach((component) => {
            if (component.view) {
                const element = component.getComponent(PveElementComponent)!;
                const v = component.mounter;
                // 更新位置
                const pos = element.transform.position;
                const tid = element.tid;
                tempVector3.cloneFrom(pos);
                tempVector3.y = 1.0;
                // component.view.worldPosition.cloneFrom(pos);
                // component.view.tid = tid;
                this.context.camera.worldToViewportPoint(tempVector3, tempVector4);
                v.pos(tempVector4.x, tempVector4.y);
            }
        });
    }
}
