import { app } from "../app";
import { UIAlertArgs, UIToastArgs } from "../core/ui-manager";

export interface AlertArgs extends UIAlertArgs {}
export interface ToastArgs extends UIToastArgs {}

export enum ui {
    TOAST = -2,
    ALERT = -1,

    APP,
    LOADING,
    LOGIN_SCENE,
    LOADING_SCENE,
    HOME_SCENE,
    CREATOR_ROLE_SCENE,

    ALLIANCE_APPLY_LIST,
    ALLIANCE_APPOINT,
    ALLIANCE_CONTACT_LEADER,
    ALLIANCE_EDIT_INFO,
    ALLIANCE_HOME,
    ALLIANCE_INFO,
    ALLIANCE_INTRO,
    ALLIANCE_INVITE,
    ALLIANCE_INVITED,
    ALLIANCE_JOIN,
    ALLIANCE_LIST,
    ALLIANCE_LOG,
    ALLIANCE_MANAGER,
    ALLIANCE_MEMBER_ACTION,
    ALLIANCE_MEMBER,

    LOGIN_RECONNECT,
    LOGIN_PVP_SELECTOR,

    PVE,
    PVE_GAME_DEFEAT,
    PVE_REBORN_WIN,
    PVE_COLLECTION_FACTORY_WIN,
    PVP,
    PVP_1v1,
    PVP_NVN,
    PVP_TROOP_PICKER,
    PVP_BATTLE_REPORT,
    PVE_MAP_TRANSFER_WIN,
    PVE_AREA_PROGRESS_WIN,

    TEST1,
    TEST2,

    BAG,
    BAG_ITEM_USE,
    BAG_ITEM_USE_SELECT,
    BAG_ITEM_USE_RANDOM,
    BAG_ITEM_COMPOSE,

    ITEM_TIPS,

    LOGIN_SERVER_LIST,

    TASK,

    MAIL,
    MAIL_INFO,

    SHOP,
    SHOP_BUY,

    ALLIANCE,

    CHAT,

    CHEST,
    CHEST_HERO,

    USER,
    RENAME,

    CHARACTER,
    CHARACTER_DETAIL,
    CHARACTER_REBUILD,
    CHARACTER_UP_STAGE,
    CHARACTER_SELECT_MAT,
    CHARACTER_UP_STAR_PREVIEW,
    CHARACTER_FAME_REWARD,

    REWARD,

    RECHARGE,

    TROOP_TEAM,
    TROOP_SELECT,
    SOLIDER_KING,
    SKILL_MAIN,
    SKILL_REMOVE,
    SKILL_ACTIVE,
    SKILL_DETAILS,
    SKILL_SCREEN,
    SKILL_MAP_SCREEN,
    SKILL_FAST_LIST,
    TRAIN_CAMP_MAIN,
    LEADER_SKIN,
    LEADER_SKIN_TEAM_SELECT,
    LEADER_SKIN_UPGRADE,
    DRAW_CARD,
    DRAW_CARD_GAIN_HERO,
    DRAW_CARD_GAIN_REWARD,
    DRAW_CARD_PREVIEW,
    DRAW_CARD_BUY,
    GM,
    GM_PURCHASE,

    PLOT,
    UI_EFFECT_OPEN_EYE,
    UI_PLOT_THEATRE_ALPHA,
}

export type UI_KEY = Exclude<keyof typeof ui, "register">;

export module ui {
    export const register = () => {
        app.ui.register({
            id: ui.APP,
            url: "scenes/app.ls",
        });
        app.ui.register({
            id: ui.ALERT,
            url: "resources/prefab/misc/alert.lh",
        });
        app.ui.register({
            id: ui.TOAST,
            url: "resources/prefab/misc/toast.lh",
        });
        app.ui.register({
            id: ui.LOGIN_SCENE,
            url: "scenes/login.ls",
        });
        app.ui.register({
            id: ui.CREATOR_ROLE_SCENE,
            url: "scenes/creator-role.ls",
        });
        app.ui.register({
            id: ui.LOADING_SCENE,
            url: "scenes/loading.ls",
        });
        app.ui.register({
            id: ui.BAG,
            url: "resources/prefab/bag/UIBag.lh",
            blockInput: true,
        });
        app.ui.register({
            id: ui.ITEM_TIPS,
            url: "resources/prefab/bag/UIItemTips.lh",
            blockInput: false,
        });
        app.ui.register({
            id: ui.PVE,
            url: "scenes/pve.ls",
        });

        app.ui.register({
            id: ui.PVE_GAME_DEFEAT,
            url: "resources/prefab/battle/pve-defeat/pve-defeat-window.lh",
        });

        app.ui.register({
            id: ui.PVE_REBORN_WIN,
            url: "resources/prefab/battle/pve-defeat/pve-reborn-window.lh",
        });

        app.ui.register({
            id: ui.PVE_MAP_TRANSFER_WIN,
            url: "resources/prefab/map-transfer/UIMapTransfer.lh",
        });

        app.ui.register({
            id: ui.PVE_AREA_PROGRESS_WIN,
            url: "resources/prefab/map-transfer/UIAreaProgress.lh",
        });

        app.ui.register({
            id: ui.PVE_COLLECTION_FACTORY_WIN,
            url: "resources/prefab/battle/pve-ui/building_func/ui_bd_collection_factory.lh",
        });

        //----------------------------------------------------------------
        // pvp
        //----------------------------------------------------------------
        app.ui.register({
            id: ui.PVP,
            url: "scenes/pvp.ls",
        });

        app.ui.register({
            id: ui.PVP_1v1,
            url: "scenes/pvp-1v1.ls",
        });

        app.ui.register({
            id: ui.PVP_NVN,
            url: "scenes/pvp-nvn.ls",
        });

        app.ui.register({
            id: ui.PVP_BATTLE_REPORT,
            url: "resources/prefab/battle/pvp-ui/battle-report.lh",
        });

        app.ui.register({
            id: ui.PVP_TROOP_PICKER,
            url: "resources/prefab/battle/pvp-ui/troop-picker.lh",
        });

        // --------------------------------------------------------------------
        // 联盟
        //---------------------------------------------------------------------
        app.ui.register({
            id: ui.ALLIANCE_APPLY_LIST,
            url: "resources/prefab/alliance/UIAllianceApplyList.lh",
        });
        app.ui.register({
            id: ui.ALLIANCE_APPOINT,
            url: "resources/prefab/alliance/UIAllianceAppoint.lh",
        });
        app.ui.register({
            id: ui.ALLIANCE_CONTACT_LEADER,
            url: "resources/prefab/alliance/UIAllianceContactLeader.lh",
        });
        app.ui.register({
            id: ui.ALLIANCE_EDIT_INFO,
            url: "resources/prefab/alliance/UIAllianceEditInfo.lh",
        });
        app.ui.register({
            id: ui.ALLIANCE_HOME,
            url: "resources/prefab/alliance/UIAllianceHome.lh",
        });
        app.ui.register({
            id: ui.ALLIANCE_INFO,
            url: "resources/prefab/alliance/UIAllianceInfo.lh",
        });
        app.ui.register({
            id: ui.ALLIANCE_INTRO,
            url: "resources/prefab/alliance/UIAllianceIntro.lh",
        });
        app.ui.register({
            id: ui.ALLIANCE_INVITE,
            url: "resources/prefab/alliance/UIAllianceInvite.lh",
        });
        app.ui.register({
            id: ui.ALLIANCE_INVITED,
            url: "resources/prefab/alliance/UIAllianceInvited.lh",
        });
        app.ui.register({
            id: ui.ALLIANCE_JOIN,
            url: "resources/prefab/alliance/UIAllianceJoin.lh",
        });
        app.ui.register({
            id: ui.ALLIANCE_LIST,
            url: "resources/prefab/alliance/UIAllianceList.lh",
        });
        app.ui.register({
            id: ui.ALLIANCE_LOG,
            url: "resources/prefab/alliance/UIAllianceLog.lh",
        });
        app.ui.register({
            id: ui.ALLIANCE_MEMBER,
            url: "resources/prefab/alliance/UIAllianceMember.lh",
        });
        app.ui.register({
            id: ui.ALLIANCE_MEMBER_ACTION,
            url: "resources/prefab/alliance/UIAllianceMemberAction.lh",
        });

        app.ui.register({
            id: ui.LOGIN_SERVER_LIST,
            url: "resources/prefab/login/UILoginServerList.lh",
            blockInput: true,
        });
        app.ui.register({
            id: ui.HOME_SCENE,
            url: "scenes/home.ls",
            blockInput: true,
        });
        app.ui.register({
            id: ui.BAG_ITEM_USE,
            url: "resources/prefab/bag/UIItemUse.lh",
            blockInput: true,
        });
        app.ui.register({
            id: ui.BAG_ITEM_USE_RANDOM,
            url: "resources/prefab/bag/UIItemUseRandom.lh",
            blockInput: true,
        });
        app.ui.register({
            id: ui.BAG_ITEM_USE_SELECT,
            url: "resources/prefab/bag/UIItemUseSelect.lh",
            blockInput: true,
        });
        app.ui.register({
            id: ui.BAG_ITEM_COMPOSE,
            url: "resources/prefab/bag/UIItemCompose.lh",
            blockInput: true,
        });
        app.ui.register({
            id: ui.TASK,

            url: "resources/prefab/task/task.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.MAIL,

            url: "resources/prefab/mail/mail.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.MAIL_INFO,

            url: "resources/prefab/mail/mail-info.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.SHOP,

            url: "resources/prefab/shop/shop.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.SHOP_BUY,

            url: "resources/prefab/shop/shop-buy.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.ALLIANCE,

            url: "resources/prefab/alliance/alliance.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.CHAT,

            url: "resources/prefab/chat/UIChat.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.CHEST,

            url: "resources/prefab/chest-box/UIChestBox.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.CHEST_HERO,
            url: "resources/prefab/chest-box/UIChestBoxChooseHero.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.REWARD,
            url: "resources/prefab/common/dialog/UIReard.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.USER,
            url: "resources/prefab/user/user.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.RENAME,
            url: "resources/prefab/user/rename.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.CHARACTER,
            url: "resources/prefab/character/character/UICharacter.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.CHARACTER_DETAIL,
            url: "resources/prefab/character/detail/UICharacterDetail.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.CHARACTER_REBUILD,
            url: "resources/prefab/character/rebuild/UICharacterRebuild.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.CHARACTER_UP_STAGE,
            url: "resources/prefab/character/upstage/UICharacterUpstage.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.CHARACTER_SELECT_MAT,
            url: "resources/prefab/character/mat/UICharacterSelectMat.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.CHARACTER_UP_STAR_PREVIEW,
            url: "resources/prefab/character/upstar-preview/UICharacterUpstarPreview.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.CHARACTER_FAME_REWARD,
            url: "resources/prefab/character/fame-reward/UICharacterFameReward.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.RECHARGE,
            url: "resources/prefab/shop/recharge.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.LOGIN_RECONNECT,
            url: "resources/prefab/login/UILoginReconnect.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.LOGIN_PVP_SELECTOR,
            url: "resources/prefab/login/UILoginPvpSelector.lh",
        });

        app.ui.register({
            id: ui.TROOP_TEAM,

            url: "resources/prefab/troop/UIPvpTroopList.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.TROOP_SELECT,
            url: "resources/prefab/troop/UITroopSelect.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.SOLIDER_KING,
            url: "resources/prefab/soliderking/UISoliderKing.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.GM,
            url: "resources/prefab/gm/UIGM.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.GM_PURCHASE,
            url: "resources/prefab/gm/UI_GMPurchase.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.SKILL_MAIN,
            url: "resources/prefab/skill/UISkillMain.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.SKILL_ACTIVE,
            url: "resources/prefab/skill/SkillActive.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.SKILL_REMOVE,
            url: "resources/prefab/skill/SkillRemove.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.SKILL_DETAILS,
            url: "resources/prefab/skill/SkillDetails.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.SKILL_MAP_SCREEN,
            url: "resources/prefab/skill/SkillMapScreen.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.SKILL_SCREEN,
            url: "resources/prefab/skill/SkillScreen.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.SKILL_FAST_LIST,
            url: "resources/prefab/skill/SkillFastList.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.TRAIN_CAMP_MAIN,
            url: "resources/prefab/traincamp/UITrainCamp.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.PLOT,
            url: "resources/prefab/plot/plot_dialogue_win.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.LEADER_SKIN,
            url: "resources/prefab/leader-skin/UILeaderSkin.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.LEADER_SKIN_TEAM_SELECT,
            url: "resources/prefab/leader-skin/UILeaderSkinTeam.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.LEADER_SKIN_UPGRADE,
            url: "resources/prefab/leader-skin/UILeaderSkinUpgrade.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.DRAW_CARD,
            url: "resources/prefab/draw-card/UIDrawCard.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.DRAW_CARD_GAIN_HERO,
            url: "resources/prefab/draw-card/UIDrawCardGainHero.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.DRAW_CARD_GAIN_REWARD,
            url: "resources/prefab/draw-card/UIDrawCardGainReward.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.DRAW_CARD_PREVIEW,
            url: "resources/prefab/draw-card/UIDrawCardPreView.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.DRAW_CARD_BUY,
            url: "resources/prefab/draw-card/UIDrawCardBuy.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.UI_EFFECT_OPEN_EYE,
            url: "resources/prefab/plot-theatre/UIEffectForWake.lh",
            blockInput: true,
        });

        app.ui.register({
            id: ui.UI_PLOT_THEATRE_ALPHA,
            url: "resources/prefab/plot-theatre/UIPlotTheatreAlpha.lh",
            blockInput: true,
        });
    };
}
