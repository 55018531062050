import * as ecs from "../../../../../core/ecs";
import { res } from "../../../../../misc/res";
import { BDCommonInfoUI } from "../../../ui-runtime/pve/build/BDCommonInfoUI";

/**
 * 建筑通用UI显示
 */
export class PveUICommonInfoComponent extends ecs.Component {
    readonly mounter: Laya.Sprite = new Laya.Sprite();

    view?: BDCommonInfoUI | null;

    buildingTid: number = 0;

    /** 资源路径 */
    public get res(): string {
        return res.BATTLE_BD_COMMON_INFO;
    }
}
