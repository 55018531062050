const { regClass } = Laya;
import { AttrUpGradeBase } from "./AttrUpGrade.generated";

@regClass('Pc93MDVfTHCirMfkizcHmQ')
export class AttrUpGrade extends AttrUpGradeBase {
    onRender(attrData: { arrtName: string; curVal: number; nextValue: number }, index: number) {
        this.lblDesc0.text = attrData.arrtName;
        this.lblPreValue0.text = attrData.curVal.toString();
        this.lblNextValue0.text = attrData.nextValue.toString();
        this.imgBg.visible = index % 2 === 0;
    }
}
