/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIList } from "../../../core/ui/UIList";

/**
 * resources/prefab/alliance/AllianceMemberList.lh
 */
export class AllianceMemberListBase extends Laya.Box {
    public btnToggle!: Laya.Box;
    public level!: Laya.Label;
    public title!: Laya.Label;
    public onlineCount!: Laya.Label;
    public indicator!: Laya.Image;
    public list!: UIList;
    public location!: Laya.Image;
}

