const { regClass } = Laya;
import { app } from "../../../../../app";
import { TimeFormatUtils } from "../../../../../core/utils/time-format-util";
import { BattleBuildingVo } from "../../../pve/vo/BattleBuildingVo";
import { BDFuncProductionProgressBlockUIBase } from "./BDFuncProductionProgressBlockUI.generated";

@regClass('kPHc_6QlQommEVfzHGzHAA')
export class BDFuncProductionProgressBlockUI extends BDFuncProductionProgressBlockUIBase {
    /**
     * harvestTime 上次收获的时间点(单位:秒)
     */
    public updateShow(levelCfg: BattleBuildingVo, harvestTime: number): void {
        this.img_icon.skin = levelCfg.iconUrl;

        let curAmount = 0;
        let progress = 0;
        let productionTime: number = 0;
        // 剩余可满的时间
        let remainTime: number = 0;
        if (harvestTime < 1) {
            this.progressBar.value = 0;
            curAmount = 0;
            remainTime = levelCfg.totalNeedTime;
        } else {
            const curTime = app.service.network.serverTime;
            productionTime = curTime - harvestTime;
            progress = levelCfg.getProgressWithProductionTime(productionTime);
            curAmount = levelCfg.getCurrentAmountWithProductionTime(productionTime);
            remainTime = Math.floor(levelCfg.totalNeedTime - productionTime);
        }

        if (progress >= 1) {
            this.lab_right.text = "已达上限";
        } else {
            const remainTimeStr = TimeFormatUtils.totalTimeFormat(remainTime, "hh:mm:ss");
            this.lab_right.text = `所需时间：${remainTimeStr}`;
        }

        this.progressBar.value = progress;
        this.lab_left.text = `${curAmount}/${levelCfg.storageLimit}`;
    }
}
