import * as ecs from "../../../../../core/ecs";
import { res } from "../../../../../misc/res";
import { CloudConfirmUI } from "../../../ui-runtime/pve/CloudConfirmUI";
import { BattleEventLo } from "../../vo/BattleEventLo";

/**
 * 解除迷雾确认UI组件
 */
export class PveUICloudComponent extends ecs.Component {
    readonly mounter: Laya.Sprite = new Laya.Sprite();

    view?: CloudConfirmUI | null;

    eventVo!: BattleEventLo;

    /** 资源路径 */
    public get res(): string {
        return res.BATTLE_CLOUD_CONFIRM;
    }
}
