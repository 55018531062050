import { app } from "../../../../../app";
import { SystemEvent } from "../../../../../misc/system-event";
import { PvpHeadInfoUI } from "../PvpHeadInfoUI";
import { DyncRT } from "./DyncRT";

interface DyncRTNode {
    __dync_rt__?: boolean; //给Texture资源打上标签，表示该资源引用的bitmap已经处理过了。
    __dync_bm_font__?: boolean; //如果是非使用BMFont的Text，会创建对应的BMFont去渲染，所以需要这个标志位表示Text原始是不使用BMFont的
    __dync_pre_text__?: string; //上一次渲染的text文本，如果和Laya.Text当前的不一样，则表示应该重新渲染了。格式为：`${fontStr}|||${text.text}`
}

// 开启测试模式，会在UI上显示RT的内容，用于调试。在提交代码的时候需要关闭。
const bDebug: boolean = false;

@Laya.regClass('fLmYjiqdStiuAHX1lrfBbw')
export class DyncRTComp extends Laya.Component {
    declare owner: PvpHeadInfoUI;

    private static _inDyncRT: DyncRT;
    private static _bmFonts: { [fontStr: string]: Laya.BitmapFont } = {}; // 不同样式的text对应不同的bmfont
    static bListenedPvpSceneDestroy: boolean = false;
    private static onPvpSceneDestroy = () => {
        DyncRTComp._inDyncRT?.clearDyncFonts(false);
        DyncRTComp._bmFonts = {};
    };

    override onAwake(): void {
        super.onAwake();
        if (!DyncRTComp.bListenedPvpSceneDestroy) {
            app.on(SystemEvent.ON_DESTROY_PVP_SCENE, DyncRTComp, DyncRTComp.onPvpSceneDestroy);
            DyncRTComp.bListenedPvpSceneDestroy = true;
        }
    }

    override onPreRender() {
        if (!DyncRTComp._inDyncRT) {
            DyncRTComp._inDyncRT = new DyncRT(2048, 2048);
        }
        // 遍历所有子节点，检查使用到的texture，检查bitmap是否在动态图集里，如果不在则将其贴进去，然后更换texture的source和uv
        // 最后将其设置repaint，强制重绘UI。
        const numChildren = this.owner.numChildren;

        let isRepaint: boolean = false;
        // todo: 当前只遍历一层子节点，如果需要嵌套，则在需要嵌套的节点上添加本Component。 是否需要递归遍历子节点？
        for (let i = 0; i < numChildren; i++) {
            const child = this.owner.getChildAt(i);
            if (!(child instanceof Laya.Sprite)) {
                continue;
            }
            if (!child.visible) {
                continue;
            }

            let texture: Laya.Texture | null = null;
            let isText = false;
            let bitmapFont: Laya.BitmapFont | null = null;
            if (child instanceof Laya.Image) {
                texture = child.source;
            } else if (child.constructor === Laya.Sprite) {
                texture = child.texture; //TODO:有bug，如果是sprite，会渲染不出来，但是用 Laya.Image是可以渲染的
            } else if (child instanceof Laya.Label) {
                const tf: Laya.Text = child.textField;
                if (!tf) {
                    continue;
                }

                bitmapFont = (tf as unknown as Laya.UText)._bitmapFont;
                if (!bitmapFont || (tf as DyncRTNode).__dync_bm_font__) {
                    // NOTE: 如果是bitmapFont，对Laya.Text.font进行赋值的时候可能需要异步加载bitmapFont文件
                    // NOTE: 这时候_bitmapFont还没有赋值，所以需要等待bitmapFont赋值后再处理
                    const _realFont = (tf as unknown as Laya.UText)._realFont;
                    if (_realFont && _realFont.startsWith("res://")) {
                        // means 正在异步加载bitmapFont中，直接continue
                        continue;
                    } else {
                        this.dealWithNormalText(tf as unknown as Laya.UText);
                    }
                    continue;
                }
                isText = true;
                texture = bitmapFont.texture;
            } else if (child instanceof Laya.Text) {
                bitmapFont = (child as unknown as Laya.UText)._bitmapFont;
                if (!bitmapFont || (child as DyncRTNode).__dync_bm_font__) {
                    this.dealWithNormalText(child as unknown as Laya.UText);
                    continue;
                }
                isText = true;
                texture = bitmapFont.texture;
            }

            if (!texture || !texture.bitmap) {
                if (bitmapFont) {
                    console.warn("some thing wrong!!");
                }
                continue;
            }
            if ((texture as DyncRTNode).__dync_rt__) {
                continue;
            }

            // texture用到的时候再检查source是否在RT里，如果在的话，检查是否已经重置过uv，再进行uv的重置
            const rect = DyncRTComp._inDyncRT.assignBitmapToRT(texture);

            if (!rect) {
                console.warn("assign texture fail", Laya.LayaEnv.isPreview ? texture : undefined);
                continue;
            }

            // 为了避免污染使用同一个texture的其他Sprite，重置采用clone新的texture进行修改source和uv。
            const oriX = texture.uv[0] * texture.bitmap.width;
            const oriY = texture.uv[1] * texture.bitmap.height;
            // clone
            const newTex = Laya.Texture.create(
                DyncRTComp._inDyncRT.rt,
                rect.x + oriX,
                rect.y + oriY,
                texture.width,
                texture.height,
                texture.offsetX,
                texture.offsetY,
                DyncRTComp._inDyncRT.rt.width,
                DyncRTComp._inDyncRT.rt.height
            );
            (newTex as DyncRTNode).__dync_rt__ = true;

            // 需要重置BMFont的配置
            if (bitmapFont) {
                const newBMFont = new Laya.BitmapFont();
                newBMFont.texture = newTex;
                newBMFont.padding = bitmapFont.padding.slice();
                newBMFont.fontSize = bitmapFont.fontSize;
                newBMFont.autoScaleSize = bitmapFont.autoScaleSize;
                newBMFont.tint = bitmapFont.tint;
                newBMFont.maxWidth = bitmapFont.maxWidth;
                newBMFont.lineHeight = bitmapFont.lineHeight;
                newBMFont.letterSpacing = bitmapFont.letterSpacing;

                const newDict: Record<string, Laya.BMGlyph> = {};
                for (const key in bitmapFont.dict) {
                    const d: Laya.BMGlyph = bitmapFont.dict[key];
                    let dTex: Laya.Texture | undefined = undefined;
                    if (d.texture) {
                        const dx = d.texture.uvrect[0] * d.texture.bitmap.width;
                        const dy = d.texture.uvrect[1] * d.texture.bitmap.height;
                        dTex = Laya.Texture.create(
                            newTex,
                            dx,
                            dy,
                            d.width!,
                            d.height!,
                            d.texture!.offsetX,
                            d.texture!.offsetY
                        );
                    }

                    const newD = {
                        x: d.x,
                        y: d.y,
                        width: d.width,
                        height: d.height,
                        advance: d.advance,
                        texture: dTex,
                    };
                    newDict[key] = newD;
                }
                newBMFont.dict = newDict;

                if (child instanceof Laya.Label) {
                    const tf: Laya.UText = child.textField as unknown as Laya.UText;
                    if (tf) {
                        tf._bitmapFont = newBMFont;
                        if (tf.text) {
                            tf.markChanged();
                        }
                    }
                } else if (child instanceof Laya.Text) {
                    const uChild = child as unknown as Laya.UText;
                    uChild._bitmapFont = newBMFont;
                    if (child.text) {
                        uChild.markChanged();
                    }
                }
            } else {
                if (child instanceof Laya.Image) {
                    child.useSourceSize = true; //
                    child.source = newTex;
                } else if (child.constructor === Laya.Sprite) {
                    child.texture = newTex;
                    // 重绘
                    // child.repaint(Laya.SpriteConst.REPAINT_ALL);
                }
            }
            isRepaint = true;
        }

        // preview 里显示测试
        if (isRepaint && bDebug) {
            const rt = this.owner.getChildByName("rt") as Laya.Sprite;
            if (rt) {
                rt.texture = Laya.Texture.create(
                    DyncRTComp._inDyncRT.rt,
                    0,
                    0,
                    DyncRTComp._inDyncRT.rt.width,
                    DyncRTComp._inDyncRT.rt.height
                );
                (rt.texture as DyncRTNode).__dync_rt__ = true;
            }
        }
    }

    private dealWithNormalText(text: Laya.UText) {
        const lineWidth = text.stroke ?? 0;
        const fontStr = `${text.italic ? "italic " : ""}${text.bold ? "bold " : ""}${
            text.fontSize
        }px ${text._realFont}`;

        const fontKeyStr = lineWidth > 0 ? `${fontStr}_${text.strokeColor}${lineWidth}` : fontStr;

        // 已经处理过一次，而且text没有变化
        if (
            (text as DyncRTNode).__dync_bm_font__ &&
            (text as DyncRTNode).__dync_pre_text__ === `${fontKeyStr}|||${text.text}`
        ) {
            return;
        }
        /**
         * 1. 先全部按BmFont来处理，提取出每一个字素进行渲染，渲染后将ImageData贴到RT上。
         * 2. 然后构建对应的BMFont，将每一个字素设置到对应的dict上
         * 3. 最后将BMFont设置给Text
         */

        const tempCri = new Laya.CharRenderInfo();

        const originFontSize = text.fontSize;
        const FONT_ADJUST_SCALE = 1.3;
        const renderFontSize = Math.ceil(originFontSize * FONT_ADJUST_SCALE);
        tempCri.height = text.fontSize;

        let bitmapFont = DyncRTComp._bmFonts[fontKeyStr];
        if (!bitmapFont) {
            bitmapFont = new Laya.BitmapFont();
            bitmapFont.fontSize = renderFontSize;
            bitmapFont.texture = Laya.Texture.create(
                DyncRTComp._inDyncRT.rt,
                0,
                0,
                DyncRTComp._inDyncRT.rt.width,
                DyncRTComp._inDyncRT.rt.height
            );
            bitmapFont.lineHeight = renderFontSize;
            bitmapFont.padding = [0, 0, 0, 0];
            bitmapFont.maxWidth = 0;
            bitmapFont.autoScaleSize = true;
            bitmapFont.dict = {};
            // add space
            bitmapFont.dict[32] = {
                x: 0,
                y: 0,
                advance: Math.floor(bitmapFont.fontSize * 0.5) + bitmapFont.letterSpacing,
            };
            DyncRTComp._bmFonts[fontKeyStr] = bitmapFont;
        }
        bDebug && console.log("bmfont fontSize ", bitmapFont.fontSize, bitmapFont.lineHeight);
        const needRenderStr = text.text; // 需要渲染的字符串
        let needReRender: boolean = false;
        for (let i = 0; i < needRenderStr.length; i++) {
            const charCode = needRenderStr.charCodeAt(i);
            if (!bitmapFont.dict[charCode]) {
                // 避免重复构建
                needReRender = true;
                break;
            }
        }

        if (needReRender) {
            const textRender = (Laya.Context as any)._textRender as Laya.TextRender;
            const charRender = (textRender as any).charRender as Laya.ICharRender;
            // const fontInfo = Laya.FontInfo.parse(fontStr);
            charRender.fontsz = text.fontSize;
            charRender.scale(FONT_ADJUST_SCALE, FONT_ADJUST_SCALE); // 设置文本缩放，否则会出现字体模糊
            const canvasWidth = charRender.canvasWidth >= 2048 ? 2047 : charRender.canvasWidth + 1;
            charRender.canvasWidth = canvasWidth; //强制清理缩放

            const marginH = Math.max(0, lineWidth / 5);
            const marginV = lineWidth;

            // 单独渲染每一个字符，并获取其ImageData，将其合到RT里，并创建对应的BMFont配置
            for (let i = 0; i < needRenderStr.length; i++) {
                const charCode = needRenderStr.charCodeAt(i);
                if (bitmapFont.dict[charCode]) {
                    // 避免重复构建
                    continue;
                }
                const char = needRenderStr.charAt(i);
                bDebug && console.log("char is ", char, charRender.fontsz);
                const cWidth = Math.ceil(charRender.getWidth(fontStr, char) + lineWidth * 2);
                if (cWidth > charRender.canvasWidth) {
                    charRender.canvasWidth = Math.min(2048, cWidth);
                }
                const imgdt = charRender.getCharBmp(
                    char,
                    fontStr,
                    lineWidth,
                    "#FFFFFF", //颜色必须是白色, 如果是其他颜色[Laya.Text.color]，会与Laya.Text.color双重颜色叠加。
                    text.strokeColor,
                    tempCri,
                    marginH,
                    marginV,
                    marginH,
                    marginV,
                    null
                );

                if (!imgdt) {
                    continue;
                }

                const rect = DyncRTComp._inDyncRT.assignFontImgDataToRT(
                    imgdt,
                    fontKeyStr,
                    charCode
                );
                if (!rect) {
                    console.warn("char insert fail: ", char);
                    continue;
                }
                const xOffset = 0;
                const yOffset = 0;
                const x = rect.x;
                const y = rect.y;
                const width = rect.width;
                const height = rect.height;
                const advance = width;
                bDebug &&
                    console.log(
                        "char is ",
                        char,
                        " check rect:",
                        rect.x,
                        rect.y,
                        rect.width,
                        rect.height
                    );

                const tex = Laya.Texture.create(
                    bitmapFont.texture,
                    x,
                    y,
                    width,
                    height,
                    xOffset,
                    yOffset
                );
                bitmapFont.dict[charCode] = {
                    x: 0,
                    y: 0,
                    width,
                    height,
                    advance,
                    texture: tex,
                };
                bitmapFont.maxWidth = Math.max(bitmapFont.maxWidth, advance);
            }
        }

        text._bitmapFont = bitmapFont;
        if (text.text) {
            text.markChanged();
        }

        (text as DyncRTNode).__dync_bm_font__ = true;
        (text as DyncRTNode).__dync_pre_text__ = `${fontKeyStr}|||${text.text}`;
    }
}
