/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { BaseRoundWindowBg } from "../../common/ui-runtime/dialog/BaseRoundWindowBg";

/**
 * resources/prefab/skill/SkillRemove.lh
 */
export class SkillRemoveUIBase extends Laya.Dialog {
    public imgClose!: Laya.Image;
    public bgwin!: BaseRoundWindowBg;
    public listTeam!: Laya.List;
}
