/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/common/attribute/AttrUpGrade.lh
 */
export class AttrUpGradeBase extends Laya.Box {
    public imgBg!: Laya.Image;
    public imgAtt!: Laya.Image;
    public lblDesc0!: Laya.Label;
    public lblPreValue0!: Laya.Label;
    public lblNextValue0!: Laya.Label;
}

