import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { MoneyConf } from "../../def/auto/money";
import { SystemEvent } from "../../misc/system-event";
import { ui } from "../../misc/ui";
import { MoneyVo } from "../user/vo/MoneyVo";
import { HomeMoneyItem } from "./ui-runtime/HomeMoneyItem";
import { HomeUserBoxUI } from "./ui-runtime/HomeUserBoxUI";

const { regClass, property } = Laya;

@regClass('JK2uqm9_TXmulSgwGnCxow')
export class HomeUserMediator extends Mediator {
    //组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
    declare owner: HomeUserBoxUI;

    private _monyeInfo: MoneyVo[] = [];

    override onAwake(): void {
        super.onAwake();
        this.initEvent();
        this.initServerEvent();
        this._updateInfo();
        this._updateMoney();
    }

    initEvent() {
        // this.owner.moneyDiamond.btnAdd.on(Laya.Event.CLICK, () => {
        //     app.ui.show(ui.RECHARGE);
        // });
        this.owner.btnAvatar.on(Laya.Event.CLICK, () => {
            //打开人物信息面板
            app.ui.show(ui.USER);
        });

        this.owner.boxLevel.on(Laya.Event.CLICK, async () => {
            app.ui.show(ui.SOLIDER_KING);
        });

        this.$(app).on(SystemEvent.USER_MONEY_UPDATE, () => {
            this._updateMoney();
        });

        this.owner.moneyList.renderHandler = new Laya.Handler(this, this._updateMoneyItem);
    }

    private initServerEvent() {
        this.$(app).on(SystemEvent.USER_PROFILE_UPDATE, () => {
            this._updateInfo();
        });
    }

    private _updateInfo() {
        const leader = app.service.hero.getLeaderHero();
        const icon = leader.iconSmall;
        this.owner.labLevel.text = `${app.service.user.profileInfo.lv}`;
        this.owner.imgIcon.skin = icon || "";
        // this.owner.labelName.text = app.service.user.profileInfo.name;
        // this.owner.labelPower.text = app.service.user.profileInfo.power;
        // this.owner.labelLv.text = app.service.user.profileInfo.lv.toString();
        // this.owner.labelExp.text = (exp / lvRow!.upgrade_exp) * 100 + "%";
        // this.owner.progressBarExp.value = exp / lvRow!.upgrade_exp;
        const diamondNum = app.service.user.moneyVoMap.get(MoneyConf.MONEY_ID.DIAMOND)?.count;
        // this.owner.moneyDiamond.labelNum.text = `${diamondNum ?? 0}`;
        const coinNum = app.service.user.moneyVoMap.get(MoneyConf.MONEY_ID.COIN)?.count;
        // this.owner.moneyCoin.labelNum.text = `${coinNum ?? 0}`;
    }

    private _updateMoney() {
        this._monyeInfo = [];
        [30601, 30701, 30801, 30301, 30401].forEach((id) => {
            this._monyeInfo.push(app.service.user.moneyVoMap.get(id)!);
        });
        this.owner.moneyList.array = this._monyeInfo;
    }

    private _updateMoneyItem(cell: HomeMoneyItem, index: number) {
        cell.labelNum.text = this._monyeInfo[index].count.toString() || "0";
        cell.imgIcon.skin = this._monyeInfo[index].iconUrl;
        cell.btnAdd.visible = this._monyeInfo[index].id === 30401;
        // cell.btnAdd.on(Laya.Event.CLICK, this, this.onClickAdd);
    }
}
