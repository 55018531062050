/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIImageBar } from "../../../../core/ui/UIImageBar";

/**
 * resources/prefab/battle/pvp-ui/troop-state-focus.lh
 */
export class TroopStateFocusUIBase extends Laya.Sprite {
    troopAvatar!: Laya.Image;
    troopState!: Laya.Image;
    troopHp!: UIImageBar;
    btnTroopEdit!: Laya.Image;
    troopName!: Laya.Text;
    troopHpTxt!: Laya.Text;
}
