/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIList } from "../../../core/ui/UIList";

/**
 * resources/prefab/draw-card/UIDrawCard.lh
 */
export class UIDrawCardBase extends Laya.Dialog {
    public imgBg!: Laya.Image;
    public imgTips!: Laya.Image;
    public btnClose!: Laya.Image;
    public imgItemIcon!: Laya.Image;
    public imgCostIcon2!: Laya.Image;
    public boxCheck!: Laya.Box;
    public imgGou!: Laya.Image;
    public boxPreview!: Laya.Box;
    public boxReward!: Laya.Box;
    public imgPro!: Laya.Image;
    public imgMask!: Laya.Sprite;
    public boxOne!: Laya.Box;
    public imgCostIcon!: Laya.Image;
    public labCostone!: Laya.Label;
    public listPool!: UIList;
    public btnOne!: Laya.Button;
    public btnTen!: Laya.Button;
    public boxMask!: Laya.Box;
    public BoxRewardDescribe!: Laya.Box;
    public labRewardPro!: Laya.Label;
    public labRewardDesc!: Laya.Label;
    public labscoreDesc!: Laya.Label;
    public boxDescribe!: Laya.Box;
    public labdecribe!: Laya.Label;
    public labtimes!: Laya.Label;
    public labCanget!: Laya.Label;
    public labdrawCount!: Laya.Label;
    public labCount!: Laya.Label;
    public labSkip!: Laya.Text;
    public labFreeTime!: Laya.Label;
    public labCostten!: Laya.Label;
}

