import { app } from "../../../../../app";
import { Callback } from "../../../../../core/dispatcher";
import * as ecs from "../../../../../core/ecs";
import { PveBuildingType, PveEventType } from "../../../../../def/auto/battle";
import { StyleConf } from "../../../../../def/style";
import { SystemEvent } from "../../../../../misc/system-event";
import { ui } from "../../../../../misc/ui";
import { TMLayerName } from "../../../tilemap/tm-def";
import { TMUtil } from "../../../tilemap/tm-util";
import { PveContext } from "../../PveContext";
import { BattleBuildingVo } from "../../vo/BattleBuildingVo";
import { PveAnimationComponent } from "../components/PveAnimationComponent";
import { PveElementComponent } from "../components/PveElementComponent";
import { PveUIBDUpGradeComponent } from "../components/PveUIBDUpGradeComponent";
import { PveUIBuildConfirmComponent } from "../components/PveUIBuildConfirmComponent";
import { PveUIBuildingConfirmComponent } from "../components/PveUIBuildingConfirmComponent";
import { PveUICloudComponent } from "../components/PveUICloudComponent";
import { PveUICommonInfoComponent } from "../components/PveUICommonInfoComponent";
import { PveUIProductionInfoComponent } from "../components/PveUIProductionInfoComponent";

const tempVector3 = new Laya.Vector3();
const tempVector4 = new Laya.Vector4();

export class PveBuildingSystem extends ecs.System {
    declare context: PveContext;

    override onCreate(): void {
        this._on(SystemEvent.PVE.ON_BUILDING_ENTER, this.onBuildingEnter);
        this._on(SystemEvent.PVE.ON_BUILDING_LEAVE, this.onBuildingLeave);
        this._on(SystemEvent.PVE.ON_UI_BUILD_CONFIRM_LOADED, this.onUIBuildConfirmLoaded);

        this._on(SystemEvent.PVE.ON_UI_DB_COMMONINFO_LOADED, this.onUIBuildingCommonInfoLoaded);
        this._on(SystemEvent.USER_MONEY_UPDATE, this.onUserMoneyUpdate);
    }

    private _on(event: string, callback: Callback) {
        this.context.$(app).on(event, callback, this);
    }

    /** 用户货币更新,刷新显示建造的提示UI */
    private onUserMoneyUpdate(): void {
        const comps = this.ecs.getComponents(PveUIBuildConfirmComponent);
        comps.forEach((c) => {
            this.onUIBuildConfirmLoaded(c.eid);
        });
    }

    private onUIBuildConfirmLoaded(eid: number): void {
        // 建造的提示UI显示
        const comp = this.ecs.getComponent(eid, PveUIBuildConfirmComponent);
        if (!comp || !comp.view) {
            return;
        }

        const building = this.ecs.getComponent(eid, PveElementComponent);
        if (!building) {
            return;
        }

        // 建筑配置
        const buildingVo: BattleBuildingVo = app.service.pve.buildingVoMap.get(building.tid)!;
        const cost = buildingVo.upgradeCost;
        const v = comp.view;

        const btnStr = `建造${buildingVo.titleName}`;
        v.buildButton.label = btnStr;

        if (cost && cost.length > 0) {
            v.costIcon.visible = true;
            v.needCostLabel.visible = true;
            const rewardCode = cost[0].id;
            const itemLo = app.service.bag.itemLoMap.get(rewardCode);
            v.costIcon.skin = itemLo.iconUrl; // costIcon 是 Image
            const needAmount = cost[0].num;
            // 通过身上的资源数量 改变文本颜色的显示
            v.needCostLabel.text = needAmount + "";

            const amountObj = app.service.pve.mlData.getCurrentAssetAmount(rewardCode);
            v.amountObj = amountObj;
            v.needAmount = needAmount;
            const curHasAmount = amountObj.packAmount + amountObj.truckAmount;
            if (curHasAmount >= needAmount) {
                // 足够
                v.needCostLabel.color = StyleConf.COST_AMOUNT_COLOR.ENOUGH;
                v.buildButton.gray = false;
                v.notEnoughCost = false;
            } else {
                // 不够
                v.needCostLabel.color = StyleConf.COST_AMOUNT_COLOR.NOT_ENOUGH;
                v.buildButton.gray = true;
                v.notEnoughCost = true;
            }
        } else {
            // 免费建造
            v.notEnoughCost = false;
            v.buildButton.gray = false;
            v.costIcon.visible = false;
            v.needCostLabel.visible = false;
        }
    }

    private onUIBuildingCommonInfoLoaded(eid: number): void {
        const comp = this.ecs.getComponent(eid, PveUICommonInfoComponent);
        if (!comp || !comp.view) {
            return;
        }

        const building = this.ecs.getComponent(eid, PveElementComponent);
        if (!building) {
            return;
        }

        // 建筑配置
        const buildingVo: BattleBuildingVo = app.service.pve.buildingVoMap.get(building.tid)!;
        const v = comp.view;
        const tileName = buildingVo.titleName;
        v.txt_name.text = tileName;
    }

    private onBuildingEnter(battleEntity: number, buildingType: number, eid: number): void {
        const building = this.ecs.getComponent(eid, PveElementComponent)!;
        const mld = app.service.pve.mlData;
        const buildingData = mld.pveStateData.getBuildingByKey(building.key)!;
        let buildingLevel = 0;
        let upgradeTime: number = 0;
        upgradeTime = buildingData.upgradeTime ?? 0;
        buildingLevel = buildingData.level ?? 0;

        const curVo = app.service.pve.buildingVoMap.getVoWithBattleEntityIdAndLevel(
            building.teid,
            buildingLevel
        )!;

        if (curVo.isFoundation && upgradeTime < 1) {
            // 地基,且未升级中
            // 未建造,显示建造按钮
            building.addComponent(PveUIBuildConfirmComponent);
            return;
        }

        if (buildingType === PveBuildingType.REVIVAL_UPGRADE) {
            // 靠近医疗所
        } else if (buildingType === PveBuildingType.MAP_TRANSFER) {
            // 靠近传送门
            if (buildingLevel > 0) {
                // 已建造,显示查看按钮
                building.addComponent(PveUIBuildingConfirmComponent);
            }
        } else if (buildingType === PveBuildingType.PRODUCTION_FACTORY) {
            // 靠近 伐木场/矿场/麦田   (可建造和升级的资源工厂)
            if (buildingLevel > 0) {
                // 已建造,显示查看按钮
                building.addComponent(PveUIBuildingConfirmComponent);
            }
            // 判断如果可以收获，则马上进行收获
            const isCanHarvest = curVo.getIsCanHarvestWithtHarvestTime(buildingData.harvestTime);
            if (isCanHarvest) {
                // 可以收获
                this.context.sender.harvestCollection(eid);
            }
        } else if (buildingType === PveBuildingType.OPEN_FUNCTION) {
            if (buildingLevel > 0) {
                // 已建造,显示查看按钮
                building.addComponent(PveUIBuildingConfirmComponent);
            }
        } else if (buildingType === PveBuildingType.CLEAR_CLOUD) {
            if (buildingLevel > 0) {
                const eventKey = TMUtil.convertKey(building.key, TMLayerName.Event);
                const eventElement = this.context.findStaticElementByKey(eventKey);
                if (eventElement) {
                    const pveStateData = app.service.pve.mlData.pveStateData;
                    const eventRecordData = pveStateData.getEventByKey(eventKey);
                    let isUnlock = false;
                    if (eventRecordData) {
                        const eventVo = eventRecordData.eventVo!;
                        if (eventVo.eventType === PveEventType.UNLOCK_CLOUD) {
                            // 解除迷雾事件
                            if (eventRecordData.isDeath) {
                                isUnlock = true;
                            }
                        }
                        if (!isUnlock) {
                            // 已建造,显示解除迷雾提示
                            const comp = eventElement.addComponent(PveUICloudComponent);
                            comp.eventVo = eventVo;
                        }
                    }
                }
            }
        }
    }

    private onBuildingLeave(battleEntity: number, buildingType: number, eid: number): void {
        const building = this.ecs.getComponent(eid, PveElementComponent);
        if (building) {
            let level = 0;
            const stateData = app.service.pve.mlData.pveStateData;
            const data = stateData.getBuildingByKey(building.key);
            if (data && data.level) {
                level = data.level;
            }
            const curVo = app.service.pve.buildingVoMap.getVoWithBattleEntityIdAndLevel(
                building.teid,
                level
            )!;
            if (curVo.hasFoundation) {
                building.removeComponent(PveUIBuildConfirmComponent);
                building.removeComponent(PveUIBuildingConfirmComponent);
                building.removeComponent(PveUICloudComponent);
            }
        }
    }

    override update(dt: number): void {
        // 建筑资源产出进度
        this.ecs.getComponents(PveUIProductionInfoComponent).forEach((component) => {
            if (component.view) {
                const element = component.getComponent(PveElementComponent)!;
                const v = component.mounter;
                // 更新位置
                tempVector3.cloneFrom(element.transform.position);
                tempVector3.y = 1.0;
                this.context.camera.worldToViewportPoint(tempVector3, tempVector4);
                v.pos(tempVector4.x, tempVector4.y);
                if (component.view) {
                    component.view.update();
                }
            }
        });

        // 建筑升级进度
        this.ecs.getComponents(PveUIBDUpGradeComponent).forEach((component) => {
            if (component.view) {
                const element = component.getComponent(PveElementComponent)!;
                const v = component.mounter;
                // 更新位置
                tempVector3.cloneFrom(element.transform.position);
                tempVector3.y = 1.0;
                this.context.camera.worldToViewportPoint(tempVector3, tempVector4);
                v.pos(tempVector4.x, tempVector4.y);
                if (component.view) {
                    component.view.updateShow();
                }
            }
        });

        // 建造提示
        this.ecs.getComponents(PveUIBuildConfirmComponent).forEach((component) => {
            if (component.view) {
                const element = component.getComponent(PveElementComponent)!;
                const v = component.mounter;
                // 更新位置
                const pos = element.transform.position;
                const tid = element.tid;
                tempVector3.cloneFrom(pos);
                tempVector3.y = 1.0;
                component.view.worldPosition.cloneFrom(pos);
                component.view.tid = tid;
                this.context.camera.worldToViewportPoint(tempVector3, tempVector4);
                v.pos(tempVector4.x, tempVector4.y);
            }
        });

        // 查看建筑提示
        this.ecs.getComponents(PveUIBuildingConfirmComponent).forEach((component) => {
            if (component.view) {
                const element = component.getComponent(PveElementComponent)!;
                const v = component.mounter;
                // 更新位置
                const pos = element.transform.position;
                const tid = element.tid;
                tempVector3.cloneFrom(pos);
                tempVector3.y = 1.0;
                component.view.worldPosition.cloneFrom(pos);
                component.view.tid = tid;
                this.context.camera.worldToViewportPoint(tempVector3, tempVector4);
                v.pos(tempVector4.x, tempVector4.y);
            }
        });

        this.ecs.getComponents(PveUICommonInfoComponent).forEach((component) => {
            if (component.view) {
                const element = component.getComponent(PveElementComponent)!;
                const v = component.mounter;
                // 更新位置
                const pos = element.transform.position;
                tempVector3.cloneFrom(pos);
                tempVector3.y = 0.0;
                this.context.camera.worldToViewportPoint(tempVector3, tempVector4);
                v.pos(tempVector4.x, tempVector4.y);
            }
        });
    }

    /** 开始升级建筑 */
    public static startUpgradeBuilding(
        context: PveContext,
        buildingEid: number,
        upgradeTime: number
    ): void {
        const building = context.ecs.getComponent(buildingEid, PveElementComponent);
        if (!building) {
            return;
        }
        building.removeComponent(PveUIBuildConfirmComponent);
        building.removeComponent(PveUIBuildingConfirmComponent);
        const upGradeComp = building.addComponent(PveUIBDUpGradeComponent);
        upGradeComp.buildingTid = building.tid;
        upGradeComp.upgradeStartTime = upgradeTime;
        const baseCfg = app.service.table.battleBuilding[building.tid];
        const bdData = app.service.pve.mlData.pveStateData.getBuildingByKey(building.key)!;
        const curLo = app.service.pve.buildingVoMap.getVoWithBattleEntityIdAndLevel(
            baseCfg.battle_entity,
            bdData.level
        )!;
        const needTime = curLo.upgradeNeedTime ?? 0;
        const upgradeEndTime = upgradeTime + needTime;
        upGradeComp.upgradeEndTime = upgradeEndTime;
        app.event(SystemEvent.PVE.ON_BUILDING_START_UPGRADE);
    }

    /** 升级建筑完成 */
    public static upgradeBuildingComplete(
        context: PveContext,
        newLevel: number,
        eid: number
    ): void {
        // 升级建造完成
        const building = context.ecs.getComponent(eid, PveElementComponent);
        if (!building) {
            return;
        }
        building.removeComponent(PveUIBDUpGradeComponent);
        // 移除旧的展示形象
        building.removeComponent(PveAnimationComponent);
        const buildingVo = app.service.pve.buildingVoMap.getVoWithBattleEntityIdAndLevel(
            building.teid,
            newLevel
        )!;
        context.delay(0.1, () => {
            // 添加新的展示形象
            const aniComp = building.addComponent(PveAnimationComponent);
            aniComp.res = buildingVo.skinRes;
        });

        if (buildingVo.buildingType === PveBuildingType.PRODUCTION_FACTORY) {
            building.addComponent(PveUIProductionInfoComponent);
        }
        app.event(SystemEvent.PVE.ON_BUILDING_UPGRADE_COMP);
    }

    public static openCollectionFactoryWin(context: PveContext, eid: number): void {
        app.ui.show(ui.PVE_COLLECTION_FACTORY_WIN, { context, eid });
    }

    public static openMapTransferWin(context: PveContext): void {
        app.ui.show(ui.PVE_MAP_TRANSFER_WIN, context);
    }

    public static onHarvestCollectionSuccess(context: PveContext, eid: number): void {
        app.event(SystemEvent.PVE.ON_BUILDING_HARVEST_COMP);
        app.ui.toast("完成收获资源！");
    }
}
