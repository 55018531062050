import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { SystemEvent } from "../../misc/system-event";
import { UILoginReconnect } from "./ui-runtime/UILoginReconnect";

const { regClass, property } = Laya;

@regClass('6bKVdpNpTeCgfdX3LvFWbQ')
export class UILoginReconnectMediator extends Mediator {
    declare owner: UILoginReconnect;

    private overtime: number = 0;

    override onAwake(): void {
        super.onAwake();
        this.overtime = 0;
        this.$(app).on(SystemEvent.RECONNECT_LOGIN_SUCCESS, this.onReconnectLoginSuccess, this);
    }

    protected override superUpdate(delta: number): void {
        this.overtime += delta;
        super.superUpdate(delta);
        this.owner.clock.material.setFloat("u_TimeValue", this.overtime);
    }

    /** 重连成功 */
    private onReconnectLoginSuccess(): void {
        this.owner.close();
    }
}
