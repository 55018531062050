const { regClass } = Laya;
import { app } from "../../../app";
import { ItemLo } from "../../bag/vo/ItemLo";
import { GM_PurchaseItemBase } from "./GM_PurchaseItem.generated";

@regClass('TUIzYFoDQ42tQiJFJKGUPg')
export class GM_PurchaseItem extends GM_PurchaseItemBase {
    override onAwake(): void {
        super.onAwake();
        this.buyBtn.onClick(this.onClickBuyBtn.bind(this));
    }

    /** 点击购买按钮 */
    private onClickBuyBtn(): void {
        const lo = this.dataSource as ItemLo;
        const amount = parseInt(this.input_amount.text);
        if (isNaN(amount) || amount <= 0) {
            return;
        }

        const code = lo.getGMCode(amount);
        app.ui.toast(`使用GM指令:${code}`);
        app.service.gm.requestGM(code);
    }

    public updateShow(): void {
        const lo = this.dataSource as ItemLo;
        this.itemName.text = lo.name;
        this.itemName.color = lo.qualityTextColor;
        this.itemIcon.setItemLo(lo);
    }
}
