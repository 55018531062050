/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/draw-card/DrawCardBtnItem.lh
 */
export class DrawCardBtnItemBase extends Laya.Box {
    public imgBg!: Laya.Image;
    public selectBox!: Laya.Image;
    public boxTime!: Laya.Box;
    public labTime!: Laya.Label;
    public labName!: Laya.Label;
}

