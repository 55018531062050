import { mainline } from "../../../../../../def/auto/proto";
import { PveSvrSoldierGroupStateData } from "./PveSvrSoldierGroupStateData";

/** 士兵记录的数据 */
export class PveSvrSoldierStateData {
    /** 所在阵位索引 0-14 */
    idx: number = 0;

    /** 配置表id */
    get tid(): number {
        return this._group.tid;
    }

    get level(): number {
        return this._group.level;
    }

    /** 当前血量 */
    hp: number = 0;

    /** 最大血量 */
    maxHp: number = 0;

    /**
     * 位置
     */
    pos: Laya.Vector2 = new Laya.Vector2();

    /** 朝向 */
    rotation: number = 0;

    inited: boolean = false;

    private _group: PveSvrSoldierGroupStateData = null!;

    setSoldierGroup(group: PveSvrSoldierGroupStateData) {
        this._group = group;
    }

    decode(data: mainline.Soldier) {
        this.idx = data.idx;
        this.maxHp = data.health?.maxHp ?? 0;
        this.hp = Math.min(this.maxHp, data.health?.hp ?? 0);
        this.pos.x = (data.pos?.x ?? 0) / 10;
        this.pos.y = (data.pos?.z ?? 0) / 10;
        this.rotation = data.pos?.rotation ?? 0;

        this.inited = true;
    }

    /** 获取服务端需要的数据 */
    encode(): mainline.Soldier {
        const soldier = mainline.Soldier.create();
        soldier.idx = this.idx;

        soldier.health = mainline.Health.create();
        soldier.health.hp = Math.clamp(this.hp, 0, this.maxHp) | 0;
        soldier.health.maxHp = this.maxHp | 0;

        soldier.pos = mainline.Position.create();
        soldier.pos.x = (this.pos.x * 10) | 0;
        soldier.pos.z = (this.pos.y * 10) | 0;
        const r = ((this.rotation % 360) + 360) % 360 | 0;
        soldier.pos.rotation = r;
        return soldier;
    }
}
