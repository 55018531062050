import { Mediator } from "../../core/ui-mediator";
import { UIAllianceManage } from "./ui-runtime/UIAllianceManage";

const { regClass, property } = Laya;

@regClass('ldVKxwALRyawDIBXGAj_jw')
export class UIAllianceManageMediator extends Mediator {
    declare owner: UIAllianceManage;

    override onAwake(): void {
        super.onAwake();
    }
}
