const { regClass } = Laya;
import { app } from "../../../../app";
import { CharacterSelectMatItem, CharacterSelectMatItemData } from "./CharacterSelectMatItem";
import { UICharacterSelectMatBase } from "./UICharacterSelectMat.generated";

export interface UICharacterSelectMatArgs {
    type: "upstage" | "upstar_self" | "upstar_other";
    uid: number; //强化目标
    needCount: number;
    availableUids: number[];
    selectedUids: number[];
}

@regClass('NRjpkrBWRW2dwCONq8QHXg')
export class UICharacterSelectMat extends UICharacterSelectMatBase {
    private _openArgs!: UICharacterSelectMatArgs;

    override open(closeOther?: boolean | undefined, param?: UICharacterSelectMatArgs): void {
        this._openArgs = param!;
        super.open(closeOther, param);
    }

    override onAwake(): void {
        super.onAwake();
        console.log("UICharacterSelectMat.onAwake", this._openArgs);

        this.listSelect.array = [];
        this.listSelect.selectCheck = (index: number) => {
            return this.onSelectCheck(index);
        };
        this.listSelect.renderHandler = new Laya.Handler(this, this.onRenderListSelect);
        this.btnConfirm.clickHandler = new Laya.Handler(this, this.onClickBtnConfirm);

        this.refreshPage();
    }

    private refreshPage() {
        console.log("UICharacterSelectMat.refreshPage", this._openArgs);

        const matHeroes = this._openArgs.availableUids.map((uid) => {
            const heroVo = app.service.hero.heroVoMap.get(uid);
            if (!heroVo) {
                throw new Error(`heroVo not found: ${uid}`);
            }
            return heroVo;
        });
        const dataSrc: CharacterSelectMatItemData[] = matHeroes.map((heroVo) => {
            const selcted = this._openArgs.selectedUids.includes(heroVo.uid);
            return { heroVo, selcted };
        });

        this.listSelect.array = dataSrc;

        this.updateLblRequire();
    }

    onRenderListSelect(cell: CharacterSelectMatItem, index: number): void {
        const data = cell.get_dataSource();
        cell.onRender(data, index);
    }

    private onSelectCheck(index: number): boolean {
        const data = this.listSelect.array[index] as CharacterSelectMatItemData;
        const cell = this.listSelect.getCell(index) as CharacterSelectMatItem;
        const uid = data.heroVo.uid;

        data.selcted = !data.selcted;
        cell.onRender(data, index); //重新渲染

        const selectedUids = this._openArgs.selectedUids;
        const idx = selectedUids.indexOf(uid);
        if (data.selcted) {
            if (idx >= 0) {
                throw new Error(`uid already selected: ${uid} ${JSON.stringify(selectedUids)}`);
            }
            if (selectedUids.length >= this._openArgs.needCount) {
                // 取消一个
                const cancelItem: CharacterSelectMatItemData = this.listSelect.array.find(
                    (v: CharacterSelectMatItemData) => {
                        return v.selcted && v.heroVo.uid !== uid;
                    }
                );
                cancelItem.selcted = false;

                const selectedUidIdx = selectedUids.indexOf(cancelItem.heroVo.uid);
                if (selectedUidIdx < 0) {
                    throw new Error(
                        `uid not found: ${cancelItem.heroVo.uid} ${JSON.stringify(selectedUids)}`
                    );
                }
                selectedUids.splice(selectedUidIdx, 1);

                const cells = this.listSelect.cells as CharacterSelectMatItem[];
                const cancelCell = cells.find((v) => {
                    if (!v.get_dataSource()) {
                        return false;
                    }
                    return v.get_dataSource().heroVo.uid === cancelItem.heroVo.uid;
                });
                //如果找到的cell是在可视状态，需要重新渲染
                if (cancelCell) {
                    cancelCell.onRender(cancelItem, this.listSelect.array.indexOf(cancelItem));
                }
            }

            selectedUids.push(uid);
        } else {
            if (idx < 0) {
                throw new Error(`uid not found: ${uid} ${JSON.stringify(selectedUids)}`);
            }
            selectedUids.splice(idx, 1);
        }
        this.updateLblRequire();

        return false;
    }

    private updateLblRequire() {
        this.lblRequire.text = `需要数量： ${this._openArgs.selectedUids.length}/${this._openArgs.needCount}`;
    }

    private onClickBtnConfirm() {
        this.close();
    }
}
