import { app } from "../../../app";
import { BattleEntityType } from "../../../def/auto/battle";
import { mainline } from "../../../def/auto/proto";
import { CollectionAmountData, PveDef } from "../pve-server/PveDefs";
import { PveSvrStateData } from "../pve-server/ecs/data/state-data/PveSvrStateData";
import { PveSvrTruckStateData } from "../pve-server/ecs/data/state-data/PveSvrTruckStateData";

/** pve 数据封装
 */
export class PveMainLineData {
    readonly pveStateData: PveSvrStateData = new PveSvrStateData();

    /** 主线pve的储存数据 */
    mainLineData: mainline.IMainLineData | null = null;

    /** 获取当前身上和车上的资源数量 */
    public getCurrentAssetAmount(itemId: number): CollectionAmountData {
        let stateData: PveSvrTruckStateData | undefined;
        if (itemId === PveDef.COLLECT_WOOD_ID) {
            stateData = this.pveStateData.getTruckByCollectType(BattleEntityType.WOOD);
        } else if (itemId === PveDef.COLLECT_FOOD_ID) {
            stateData = this.pveStateData.getTruckByCollectType(BattleEntityType.FOOD);
        } else if (itemId === PveDef.COLLECT_STONE_ID) {
            stateData = this.pveStateData.getTruckByCollectType(BattleEntityType.STONE);
        }
        let truckAmount: number = 0; // 车上包含的数量
        if (stateData) {
            truckAmount = stateData.amount;
        }

        let packAmount: number = 0; // 身上包含的数量
        const moneyVo = app.service.user.moneyVoMap.get(itemId);
        if (moneyVo) {
            packAmount = moneyVo.count;
        }
        return { itemId: itemId, truckAmount: truckAmount, packAmount: packAmount };
    }
}
