import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrElementComponent } from "../../ecs/components/PveSvrElementComponent";

interface NodeArgs {
    readonly min_distance?: number;
    readonly max_distance?: number;
}

type NodeInput = [number, number?, number?, number?];

export class CheckDistance extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const input = env.input as NodeInput;
        const target = env.context.ecs.getComponent(input[0], PveSvrElementComponent);
        const self =
            input[1] !== undefined
                ? env.context.ecs.getComponent(input[1], PveSvrElementComponent)
                : env.owner;
        if (!target || !self) {
            console.error(`target or self not found`, env.input);
            return "failure";
        }
        const minDistance = input[2] ?? (node.args as NodeArgs).min_distance ?? 0;
        const maxDistance =
            input[3] ?? (node.args as NodeArgs).max_distance ?? Number.MAX_SAFE_INTEGER;
        const distance = Laya.Vector3.distanceXZ(
            target.transform.position,
            self.transform.position
        );
        if (distance >= minDistance && distance <= maxDistance) {
            return "success";
        } else {
            return "failure";
        }
    }

    override get descriptor(): NodeDef {
        return {
            name: "CheckDistance",
            type: "Condition",
            desc: "检查两个实体之间的距离是否在指定范围内",
            input: ["实体1", "实体2?", "最小距离?", "最大距离?"],
            args: [
                { name: "min_distance", type: "float?", desc: "最小距离" },
                { name: "max_distance", type: "float?", desc: "最大距离" },
            ],
            doc: `
                + 检查两个实体之间的距离是否在指定范围内
                + 输入的实体1为目标实体，实体2为参照实体，如果没有实体2则默认为自身
                + 距离值优先从输入的参数中获取，如果没有则从配置中获取
                + 如果没有最小距离，则默认为0
                + 如果没有最大距离，则默认为无穷大
                `,
        };
    }
}
