@Laya.regClass('TSx9_FQxQr-SwRjN6FP50Q')
export class LabelStyle {
    @Laya.property({ type: String })
    color: string = "#FFFFFF";

    @Laya.property({ type: Number })
    size: number = 20;

    @Laya.property({ type: Number })
    stroke: number = 0;

    @Laya.property({ type: String })
    strokeColor: string = "#000000";

    @Laya.property({ type: String })
    padding: string = "0,0,0,0";

    @Laya.property({ type: String })
    skin: string = "";
}
