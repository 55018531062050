import { PveNpcStateType } from "../../../../../../def/auto/battle";
import { mainline } from "../../../../../../def/auto/proto";

export class PveSvrNpcStateData {
    key: number = 0;

    /** 对应表 battle_npc 表 */
    tid: number = 0;

    /** NPC状态 */
    state: PveNpcStateType = PveNpcStateType.NPC_STATE_0;

    inView: boolean = false;

    x: number = 0;

    z: number = 0;

    rot: number = 0;

    decode(data: mainline.Npc) {
        this.key = data.key;
        this.state = data.state;
    }

    encode(): mainline.Npc {
        const data = mainline.Npc.create();
        data.key = this.key;
        data.state = this.state;
        return data;
    }
}
