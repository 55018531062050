import { TreeRunner } from "../../../../../core/behavior3";
import * as ecs from "../../../../../core/ecs";
import { IReusable, Pool } from "../../../../../core/pool";
import { PveServer } from "../../PveServer";
import { BulletType } from "../../btree/actions/CreateBullet";
import { AiTreeEnv } from "./PveSvrAiComponent";
import { PveSvrElementComponent } from "./PveSvrElementComponent";
import { Skill } from "./PveSvrSkillLauncherComponent";

@Pool.reusable
export class BulletTreeEnv extends AiTreeEnv implements IReusable {
    skill!: Skill;

    constructor(context: PveServer, owner: PveSvrElementComponent, skill: Skill) {
        super(context, owner);
        this.__reuse(context, owner, skill);
    }

    __reuse(context: PveServer, owner: PveSvrElementComponent, skill: Skill) {
        this.owner = owner;
        this.skill = skill;
        this._context = context;
    }

    __unuse() {
        this.clear();
        this.owner = null!;
        this.skill = null!;
        this._context = null!;
    }
}

export class PveSvrBulletComponent extends ecs.Component {
    skill!: Skill;
    lastUpdate: number = 0;
    btree!: string;
    tree: TreeRunner<BulletTreeEnv> | null = null;

    bulletType: BulletType = BulletType.Fly;
    follow: boolean = false;
    followEid: number = 0;
    speed: number = 0;
    h: number = 0;
}
