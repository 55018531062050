import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";

type GetRotInput = [number | undefined];

export class GetRot extends Process {
    override init(node: Node): void {}

    override run(node: Node, env: AiTreeEnv): Status {
        const [targetEid] = env.input as GetRotInput;
        const target = env.context.findElement(targetEid!);
        if (!target) {
            node.warn(`invalid targetEid: ${targetEid}`);
            return "failure";
        }

        env.output.push(target.transform.rotation);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "GetRot",
            type: "Action",
            desc: "获取指定单位的Y轴旋转角度。",
            args: [],
            input: ["目标?"],
            output: ["旋转角度"],
            doc: `
            + 如果输入变量为空，则默认为行为树当前归属的实体
            `,
        };
    }
}
