const { regClass } = Laya;
import { PvpServerVo } from "../vo/PvpServerVo";
import { LoginPvpSelectorItemBase } from "./LoginPvpSelectorItem.generated";

@regClass('3irnIL1xSvOWiSdjSst0xg')
export class LoginPvpSelectorItem extends LoginPvpSelectorItemBase {
    override set dataSource(value: PvpServerVo) {
        super.dataSource = value;
        if (value) {
            this.title.text = value.desc;
        }
    }
}
