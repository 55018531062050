/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIList } from "../../../core/ui/UIList";
import { IconUI } from "../../../misc/ui-runtime/IconUI";
import { BaseBigWindowBg } from "../../common/ui-runtime/dialog/BaseBigWindowBg";
import { SoliderKingRewardItem } from "./SoliderKingRewardItem";
import { SoliderKingUpItem } from "./SoliderKingUpItem";

/**
 * resources/prefab/soliderking/UISoliderKing.lh
 */
export class UISoliderKingBase extends Laya.Dialog {
    public win_bg!: BaseBigWindowBg;
    public btn_close!: Laya.Button;
    public viewStack!: Laya.ViewStack;
    public item0!: Laya.Box;
    public listReward!: Laya.List;
    public boxReward!: SoliderKingRewardItem;
    public boxMask!: Laya.Box;
    public boxTips!: Laya.Box;
    public txtFuncName!: Laya.Text;
    public txtFuncDesc!: Laya.Text;
    public iconItem!: IconUI;
    public labelExp!: Laya.Label;
    public item1!: Laya.Box;
    public boxMain!: Laya.Box;
    public boxBattle!: Laya.Box;
    public questItem!: SoliderKingUpItem;
    public list_task!: UIList;
    public boxSwitch!: Laya.Box;
    public listSwitch!: UIList;
}
