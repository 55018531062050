import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { TalentTreeRow } from "../../def/table";
import { res } from "../../misc/res";
import { SystemEvent } from "../../misc/system-event";
import { PowerTalentItemUI } from "./ui-runtime/PowerTalentItemUI";
import { TalentListItemUI } from "./ui-runtime/TalentListItemUI";
import { UITrainCamp } from "./ui-runtime/UITrainCamp";

const { regClass, property } = Laya;

@regClass('64jV-K9WQOejaKmtsvakew')
export class UITrainCampMediator extends Mediator {
    //declare owner : Laya.Sprite3D;
    declare owner: UITrainCamp;
    private talenItemList: { [key: number]: TalentListItemUI } = {};
    private strongItemList: { [key: number]: PowerTalentItemUI } = {};
    @property(String)
    public text: string = "";
    private cfgData: TalentTreeRow | null = null;

    //组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
    override onAwake(): void {
        super.onAwake();
        this.initPanel();
        this.initIcon();
        this.initUIEvent();
        this.initServiceEvent();
    }

    private initIcon() {
        const normalCostId = app.service.trainCamp.getNormalCostId();
        const itemLo = app.service.bag.itemLoMap.get(normalCostId);
        this.owner.icon1.skin = itemLo.iconUrl;

        const strongCostId = app.service.trainCamp.getStrongCostId();
        const itemLo2 = app.service.bag.itemLoMap.get(strongCostId);
        this.owner.icon2.skin = itemLo2.iconUrl;

        this.owner.labIcon1.text = `${app.service.user.getMoneyCount(normalCostId)}`;

        this.owner.labIcon2.text = `${app.service.user.getMoneyCount(strongCostId)}`;
    }

    private initUIEvent() {
        this.owner.btnclose.on(Laya.Event.CLICK, this.owner, this.owner.destroy);
        this.owner.boxMask.on(Laya.Event.CLICK, this, this.onClickMask);
        this.owner.btnAct.on(Laya.Event.CLICK, this, this.onClickAct);
    }

    private initServiceEvent() {
        this.$(app).on(SystemEvent.TRAINCAMP.CLICK_ICON, this.onClickIcon, this);
        this.$(app).on(SystemEvent.TRAINCAMP.TAINCAMP_INIT, this.updateData, this);
        this.$(app).on(SystemEvent.TRAINCAMP.TAINCAMP_ACTIVE_NOMAL, this.updateData, this);
        this.$(app).on(SystemEvent.TRAINCAMP.TAINCAMP_ACTIVE_STRONG, this.updateData, this);
        this.$(app).on(SystemEvent.USER_MONEY_UPDATE, this.initIcon, this);
    }

    private onClickAct() {
        if (this.cfgData) {
            if (this.cfgData.strong === 1) {
                app.service.trainCamp.requestActStrong();
            } else {
                app.service.trainCamp.requestActNormal();
            }
            this.owner.boxTips.visible = false;
            this.owner.boxMask.mouseEnabled = false;
        }
    }

    private onClickIcon(cfgData: TalentTreeRow) {
        this.cfgData = cfgData;
        this.owner.boxTips.visible = true;
        this.owner.boxMask.mouseEnabled = true;
        const reward = cfgData.cost[0];
        const itemLo = app.service.bag.itemLoMap.get(reward.id);
        this.owner.imgIcon.skin = itemLo.iconUrl;
        this.owner.labCount.text = `${reward.num}`;
        const effectCfg = app.service.trainCamp.getEffectCfg(cfgData);
        if (effectCfg) {
            this.owner.txtFuncName.text = effectCfg.name;
            this.owner.txtFuncDesc.text = effectCfg.desc;
        }
        const isActed = app.service.trainCamp.checkIsActived(cfgData);
        if (isActed) {
            this.owner.boxIcon.visible = false;
            this.owner.btnAct.visible = false;
            this.owner.labActed.visible = true;
            this.owner.labCond.visible = false;
        } else {
            this.owner.btnAct.visible = true;
            this.owner.labActed.visible = false;
            const actState = app.service.trainCamp.getUnlockCond(cfgData);
            if (actState === "") {
                this.owner.boxIcon.visible = true;
                this.owner.btnAct.disabled = false;
                this.owner.labCond.visible = false;
            } else {
                this.owner.boxIcon.visible = false;
                this.owner.btnAct.disabled = true;
                this.owner.labCond.visible = true;
                this.owner.labCond.text = actState;
            }
        }
        this.owner.labCount.color =
            app.service.user.getMoneyCount(reward.id) >= reward.num ? "#02ac16" : "#e53030";
    }

    private onClickMask() {
        this.owner.boxTips.visible = false;
        this.owner.boxMask.mouseEnabled = false;
    }

    private async initPanel() {
        this.owner.bgwin.setTitle("训练营");
        this.owner.boxMask.mouseEnabled = false;
        this.owner.boxTips.visible = false;
        this.initNormalItem();
        this.initStrongItem();
    }

    private async initStrongItem() {
        this.owner.boxStrong.removeChildren();
        const normalItemHeight = 180;
        const strongCfgDatas = app.service.trainCamp.getStrongCfgDatas();
        let strongTotalHeight = 0;
        const checker = () => !this.owner.boxStrong.destroyed;
        for (let i = 0; i < strongCfgDatas.length; i++) {
            const strongDatas = strongCfgDatas[i];
            const prefab = await app.loader.loadPrefab(res.trainCamp.TRAINCAMP_POWER_ITEM);
            if (!checker()) {
                return;
            }
            const itemBox = prefab.create() as PowerTalentItemUI;
            const interVal = app.service.trainCamp.getStrongInterVal(strongDatas);
            itemBox.initPanel(strongDatas);
            itemBox.y = strongTotalHeight;
            this.strongItemList[strongDatas.id] = itemBox;
            itemBox.height = interVal * normalItemHeight;
            strongTotalHeight = strongTotalHeight + itemBox.height;
            this.owner.boxStrong.addChild(itemBox);
        }
        this.owner.boxStrong.height = strongTotalHeight;
    }

    private async initNormalItem() {
        this.owner.boxNormal.removeChildren();
        const roleLvList = app.service.trainCamp.getTalentLvDatas();
        let totalNormalHeigt = 0;
        const scrollLevel = app.service.trainCamp.getScroolLevel();
        let scrollPosY = 0;
        const normalItemHeight = 180;

        const roleLV = app.service.user.profileInfo.lv;
        let actImgHeigt = 0;
        const checker = () => !this.owner.boxNormal.destroyed;
        for (let i = 0; i < roleLvList.length; i++) {
            const lv = roleLvList[i];
            const normalDatas = app.service.trainCamp.getNormalListByLv(lv);
            const prefab = await app.loader.loadPrefab(res.trainCamp.TRAINCAMP_TALENT_ITEM);
            if (!checker()) {
                return;
            }
            const itemBox = prefab.create() as TalentListItemUI;
            itemBox.initPanel(normalDatas);
            let itemBoxHeight = normalItemHeight * normalDatas.length;
            if (i === roleLvList.length - 1) {
                itemBoxHeight = itemBoxHeight - 110;
            }
            itemBox.y = totalNormalHeigt;
            totalNormalHeigt = totalNormalHeigt + itemBoxHeight;
            this.owner.boxNormal.addChild(itemBox);
            if (lv <= scrollLevel) {
                scrollPosY = totalNormalHeigt + itemBoxHeight;
            }
            if (roleLV >= lv) {
                actImgHeigt = actImgHeigt + itemBoxHeight;
            }
            this.talenItemList[lv] = itemBox;
        }
        this.owner.boxNormal.height = totalNormalHeigt + 100;
        this.owner.boxImgGroup.height = totalNormalHeigt + 100;
        this.owner.imgAct.height = actImgHeigt + 30 + 200;
        this.delay(0.3, () => {
            this.owner.scrollPanel.vScrollBar.value = scrollPosY;
        });
    }

    private updateData() {
        for (const key in this.talenItemList) {
            const item = this.talenItemList[key];
            if (item) {
                item.updataPanel();
            }
        }
        for (const key in this.strongItemList) {
            const item = this.strongItemList[key];
            if (item) {
                item.updataPanel();
            }
        }
    }

    //组件被启用后执行，例如节点被添加到舞台后
    //onEnable(): void {}

    //组件被禁用时执行，例如从节点从舞台移除后
    //onDisable(): void {}

    //第一次执行update之前执行，只会执行一次
    //onStart(): void {}

    //手动调用节点销毁时执行
    //onDestroy(): void {}

    //每帧更新时执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onUpdate(): void {}

    //每帧更新时执行，在update之后执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onLateUpdate(): void {}

    //鼠标点击后执行。与交互相关的还有onMouseDown等十多个函数，具体请参阅文档。
    //onMouseClick(): void {}
}
