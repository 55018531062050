/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIList } from "../../../core/ui/UIList";

/**
 * resources/prefab/alliance/UIAllianceLog.lh
 */
export class UIAllianceLogBase extends Laya.Box {
    public list!: UIList;
}

