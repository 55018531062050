import * as ecs from "../../../../../core/ecs";

// EntityType 为 VFX 的专属component
export class PveSvrVFXComponent extends ecs.Component {
    ownerEid: number = 0;
    battleEntityId: number = 0;

    isAttach: boolean = false;
    attachEid: number = 0;

    readonly offset: Laya.Vector3 = new Laya.Vector3();
}
