import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { StringUtil } from "../../core/utils/string-util";
import proto from "../../def/auto/proto";
import { RechargeConf } from "../../def/auto/recharge";
import { SystemEvent } from "../../misc/system-event";
import { ItemLo } from "../bag/vo/ItemLo";
import { RechargeItemUI } from "../shop/ui-runtime/RechargeItemUI";
import { RechargeUI } from "../shop/ui-runtime/RechargeUI";

const { regClass, property } = Laya;
@regClass('ltYcHtDXS8umsYR5pd_gXw')
export class RechargeMediator extends Mediator {
    declare owner: RechargeUI;
    shopInfoData!: proto.recharge.s2c_load_recharge; //商城信息
    itemListData!: proto.recharge.RechargeInfo[]; //商城道具列表

    override onAwake(): void {
        super.onAwake();
        this.initUIEvent();
        this.initServerEvent();
        this.callShopLoad();
    }

    //初始化UI事件监听
    private initUIEvent() {
        this.owner.listItem.renderHandler = new Laya.Handler(this, this.updateItem);
        this.owner.listItem.mouseHandler = new Laya.Handler(this, this.onListClick);
    }

    private initServerEvent() {
        this.owner.moneyItem.labelNum.text = `${
            app.service.user.moneyVoMap.get(30401)?.count ?? 0
        }`;
        this.$(app).on(SystemEvent.USER_MONEY_UPDATE, () => {
            this.owner.moneyItem.labelNum.text = `${
                app.service.user.moneyVoMap.get(30401)?.count ?? 0
            }`;
        });
    }

    //请求商店数据
    private async callShopLoad() {
        app.service.recharge
            .loadRechargeShop({ type: RechargeConf.RECHARGE_TYPE.RECHARGE })
            .then((data: proto.recharge.s2c_load_recharge) => {
                this.shopInfoData = data;
                this.updateList();
            });
    }

    //list点击监听
    private onListClick(evn: Laya.Event, index: number) {
        if (evn.type === Laya.Event.CLICK) {
            // const buyData = app.service.shop.getShopItemBuyNum(this.itemListData[index]);
            // if (this.itemListData[index].refData.cost) {
            //     if (buyData.num <= 0) {
            //         app.ui.toast(buyData.tips);
            //     } else {
            //         app.ui.show(ui.SHOP_BUY, {
            //             shopId: 1,
            //             shopItem: this.itemListData[index],
            //             buyBack: new Laya.Handler(this, this.buyBack),
            //         });
            //     }
            // } else {
            //     if (buyData.num) {
            //         app.service.shop.requestBuy({
            //             shopId: ShopConf.SHOP_TYPE.REGULAR,
            //             shopItemId: this.itemListData[index].refData.id,
            //             num: 1, //免费购买1次
            //         });
            //     } else {
            //         app.ui.toast(buyData.tips);
            //     }
            // }
            app.service.gm.requestGM("recharge " + this.itemListData[index].id).then(() => {
                app.ui.toast("充值成功");
                this.callShopLoad();
            });
        }
    }

    //购买回调
    private buyBack() {
        this.callShopLoad();
    }

    //listItem刷新
    private updateItem(cell: RechargeItemUI, index: number) {
        const cellData = this.itemListData[index];
        const rechargeRow = app.service.table.recharge[Number(cellData.id)];
        cell.labelPrice.text = cellData.price ? cellData.price.toString() + "元" : "免费";
        const itemLo = app.service.bag.itemLoMap.get(cellData.items![0].id!);
        cell.labelReward.text = StringUtil.str2UBB(
            "{0} {1}",
            {
                text: `{voNum=${cellData.items![0].num}}`,
            },
            { image: itemLo.iconUrl, width: 30, height: 30 }
        );
        let shopItemLo!: ItemLo;
        let extraDesc = "";
        let count = 0;
        if (!cellData.buyNum && rechargeRow.first_items && rechargeRow.first_items.length > 0) {
            shopItemLo = app.service.bag.itemLoMap.get(Number(rechargeRow.first_items[0].id));
            count = rechargeRow.first_items[0].num as number;
            extraDesc = "首冲双倍";
        } else if (cellData.extraItems && cellData.extraItems.length > 0) {
            shopItemLo = app.service.bag.itemLoMap.get(Number(cellData.extraItems[0].id));
            count = cellData.extraItems[0].num as number;
            extraDesc = "赠送";
        }
        if (shopItemLo) {
            cell.boxExtra.visible = true;
            cell.labelExtraReward.text = StringUtil.str2UBB(
                "{0} {1}",
                {
                    text: `+{voNum=${count}}`,
                },
                { image: shopItemLo.iconUrl, width: 25, height: 25 }
            );
            cell.labelExtraDesc.text = extraDesc;
        } else {
            cell.boxExtra.visible = false;
        }
        cell.imgIcon.skin = `resources/atlas/recharge/${rechargeRow.icon}.png`;
    }

    //刷新list数据
    private updateList() {
        this.itemListData = [];
        const shopItemList = this.shopInfoData.recharges;
        if (shopItemList) {
            for (const item of shopItemList) {
                this.itemListData.push(item as proto.recharge.RechargeInfo);
            }
        }
        this.owner.listItem.array = this.itemListData;
    }
}
