import { app } from "../../../../../../app";
import { BattleEntityType } from "../../../../../../def/auto/battle";
import { PveSvrCreatureComponent } from "../../components/PveSvrCreatureComponent";
import { PveSvrOffsetComponent } from "../../components/PveSvrOffsetComponent";

export class PveSvrStateDataUtils {
    static updateHp(creature: PveSvrCreatureComponent, hp: number) {
        const pveStateData = app.service.pve.mlData.pveStateData;

        switch (creature.etype) {
            case BattleEntityType.HERO: {
                pveStateData.hero.hp = hp;
                break;
            }
            case BattleEntityType.SOLDIER: {
                const offsetComp = creature.getComponent(PveSvrOffsetComponent);
                if (offsetComp) {
                    const idx = offsetComp.index;
                    const soldiderData = pveStateData.getSoldier(idx);
                    if (soldiderData) {
                        soldiderData.hp = hp;
                    }
                }
                break;
            }
            case BattleEntityType.MONSTER:
            case BattleEntityType.BOSS: {
                const offsetComp = creature.getComponent(PveSvrOffsetComponent);
                const monsterTroopData = pveStateData.getMonsterTroopByKey(creature.eleComp.key);
                if (monsterTroopData && offsetComp) {
                    const monsterData = monsterTroopData.monsters.find(
                        (v) => v.idx === offsetComp.index
                    );
                    if (monsterData) {
                        monsterData.hp = hp;
                    }
                }
                break;
            }
            default:
                console.error("updateHp: unknown creature type", creature.etype);
                break;
        }
    }
}
