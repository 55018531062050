const { regClass } = Laya;
import { BattleBuildingVo } from "../../../pve/vo/BattleBuildingVo";
import { BDFuncProductionSpeedBlockUIBase } from "./BDFuncProductionSpeedBlockUI.generated";

@regClass('Z_lAs2OkR4eUsjmXZli7TQ')
export class BDFuncProductionSpeedBlockUI extends BDFuncProductionSpeedBlockUIBase {
    public updateShow(levelCfg: BattleBuildingVo): void {
        const avatar = levelCfg.uiAvatarRes;
        if (avatar) {
            this.img_bd.skin = avatar;
        }
        const hoursProductionAmount = levelCfg.hoursProductionAmount;
        const hoursProductionStr = `${hoursProductionAmount}/小时`;
        const storageLimit = levelCfg.storageLimit;
        const storageLimitStr = `${storageLimit}`;
        if (levelCfg.isMaxLevel) {
            // 满级
            this.speedItem1.updateShow(hoursProductionStr, "");
            this.speedItem2.updateShow(storageLimitStr, "");
        } else {
            // 未满级
            const nextLevel = levelCfg.nextLevel!;
            const nextHoursProductionAmount = nextLevel.hoursProductionAmount;
            const nextHoursProductionStr = `${nextHoursProductionAmount}/小时`;
            const nextStorageLimit = nextLevel.storageLimit;
            const nextStorageLimitStr = `${nextStorageLimit}`;
            this.speedItem1.updateShow(hoursProductionStr, nextHoursProductionStr);
            this.speedItem2.updateShow(storageLimitStr, nextStorageLimitStr);
        }
    }
}
