import { Mediator } from "../../core/ui-mediator";
import { UILoginPvpSelector } from "./ui-runtime/UILoginPvpSelector";

const { regClass, property } = Laya;

@regClass('wE71EoETQ0uQF3e0hDeYlg')
export class UILoginPvpSelectorMediator extends Mediator {
    declare owner: UILoginPvpSelector;

    override onAwake(): void {
        super.onAwake();

        this.owner.list.array = this.owner.args.list;
        this.owner.list.selectHandler = new Laya.Handler(this, this._onSelect);
    }

    private _onSelect(index: number) {
        const data = this.owner.list.array[index];
        this.owner.args.callback(data);
        this.owner.destroy();
    }
}
