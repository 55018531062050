import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { TimeFormatUtils } from "../../core/utils/time-format-util";
import proto from "../../def/auto/proto";
import { RecruitConf } from "../../def/auto/recruit";
import { ItemRow } from "../../def/table";
import { ui } from "../../misc/ui";
import { IconUI } from "../../misc/ui-runtime/IconUI";
import { TableUtil } from "../table/TableUtil";
import { UIDrawCardBuyArgs } from "./ui-runtime/UIDrawCardBuy";
import { UIDrawCardGainReward } from "./ui-runtime/UIDrawCardGainReward";

const { regClass, property } = Laya;

@regClass('6xW0CzmhTjy2jeD0cSeFgA')
export class UIDrawCardRewardMediator extends Mediator {
    //declare owner : Laya.Sprite3D;
    declare owner: UIDrawCardGainReward;

    @property(String)
    public text: string = "";

    //组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
    override onAwake(): void {
        super.onAwake();
        this.initUIEvent();
        this.initPanel();
    }

    private initUIEvent() {
        this.owner.listItem.renderHandler = new Laya.Handler(this, this.onListRender);
        this.owner.imgMask.on(Laya.Event.CLICK, this.owner, this.owner.destroy);
        this.owner.btnDraw.on(Laya.Event.CLICK, this, this.onClickDraw);
        this.initPanel();
    }

    private onClickDraw() {
        const loData = this.owner.loData;
        const times = loData.reMainTime;
        if (times <= 0) {
            app.ui.toast("今日剩余招募次数已用完");
            return;
        }
        const type = RecruitConf.RECRUIT_TYPE.TEN;
        const ticket = app.service.table.recruit.conf.RECRUIT_TICKET;
        const ownCount = app.service.bag.itemVoMap.getItemAmount(ticket);
        if (ownCount >= 10) {
            app.service.drawCard.requestRecruit(loData.id, type);
        } else {
            const dayisNoTipsKey = "DrayCardBuy";
            const serverTime = app.service.network.serverTime;
            const storageTime = parseInt(Laya.LocalStorage.getItem(dayisNoTipsKey));
            if (
                !storageTime ||
                TimeFormatUtils.timeFormatYYMMDD(serverTime) !==
                    TimeFormatUtils.timeFormatYYMMDD(storageTime)
            ) {
                const buyCount = 10 - ownCount;
                const costItemList = app.service.table.recruit.conf.TICKET_PRICE[0];
                const costId = costItemList.id;
                const costCount = costItemList.num;

                const buyConfig = TableUtil.getRow<ItemRow>(app.service.table.item.item, {
                    id: ticket,
                })!;

                const costConfig = TableUtil.getRow<ItemRow>(app.service.table.item.item, {
                    id: costId,
                })!;
                app.ui.show(ui.DRAW_CARD_BUY, {
                    costId: costId,
                    contentVars: {
                        count: buyCount,
                        buyName: buyConfig.name,
                        cost: costCount * buyCount,
                        costName: costConfig.name,
                    },
                    contentStr:
                        "你缺少[color=#F2211C]{count=5}[/color]个{buyName=招募券}！是否要花费{cost=999999}{costName=招募券}购买并使用？",
                    dayisNoTipsKey: dayisNoTipsKey,
                    buyCallBack: this.buyCallBack,
                } as UIDrawCardBuyArgs);
            } else {
                this.buyCallBack();
            }
            app.service.drawCard.clickDrawCardType = RecruitConf.RECRUIT_TYPE.TEN;
        }
    }

    private buyCallBack(): void {
        const costItemList = app.service.table.recruit.conf.TICKET_PRICE[0];
        const costId = costItemList.id;
        const costCount = costItemList.num;
        const buyItemId = app.service.table.recruit.conf.RECRUIT_TICKET;
        const ownCount = app.service.bag.itemVoMap.getItemAmount(buyItemId);
        const buyCount = 10 - ownCount;
        const needCostCount = costCount * buyCount;
        const ownCostCount = app.service.bag.itemVoMap.getItemAmount(costId);
        if (ownCostCount >= needCostCount) {
            app.service.drawCard.requestBuyTicket(buyCount);
        } else {
            app.ui.toast("道具不足");
        }
    }

    onListRender(cell: IconUI, index: number) {
        const itemReward = cell.dataSource as proto.bag.ItemReward;
        const itemLo = app.service.bag.itemLoMap.get(itemReward.id);
        cell.updateGoods(itemLo);
        cell.itemNumber = itemReward.num.toString();
    }

    private initPanel() {
        const arrayData: proto.bag.ItemReward[] = this.owner.rewards;
        this.owner.listItem.array = arrayData;

        const loData = this.owner.loData;
        const reMainTime = loData.reMainTime;
        this.owner.labCount.text = `今日剩余招募次数：${reMainTime}`;

        const costId = app.service.table.recruit.conf.RECRUIT_TICKET;
        const itemLo = app.service.bag.itemLoMap.get(costId);
        this.owner.imgCost.skin = itemLo.iconUrl;
    }

    //组件被启用后执行，例如节点被添加到舞台后
    //onEnable(): void {}

    //组件被禁用时执行，例如从节点从舞台移除后
    //onDisable(): void {}

    //第一次执行update之前执行，只会执行一次
    //onStart(): void {}

    //手动调用节点销毁时执行
    //onDestroy(): void {}

    //每帧更新时执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onUpdate(): void {}

    //每帧更新时执行，在update之后执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onLateUpdate(): void {}

    //鼠标点击后执行。与交互相关的还有onMouseDown等十多个函数，具体请参阅文档。
    //onMouseClick(): void {}
}
