const { regClass } = Laya;
import { app } from "../../../app";
import { CommonConf } from "../../../def/auto/common";
import proto from "../../../def/auto/proto";
import { errcode } from "../../../def/auto/protocol";
import { JobRow, RoleTable } from "../../../def/table";
import { res } from "../../../misc/res";
import { ui } from "../../../misc/ui";
import { TableUtil } from "../../table/TableUtil";
import { CreatorRoleUIBase } from "./CreatorRoleUI.generated";

@regClass('7flj7hgaSkOw9lopUAlK3Q')
export class CreatorRoleUI extends CreatorRoleUIBase {
    private _curName: string = "";
    private isThisName: boolean = false;
    private _isMan: boolean = true;
    private _selectIndex: number = 0;
    private roleTable!: RoleTable;

    override onAwake(): void {
        super.onAwake();
        this.initInfo();
    }

    private async initInfo() {
        const checker = () => !this.destroyed;
        this.roleTable = await app.loader.loadJson(res.TABLE_ROLE_TABLE);
        if (!checker()) {
            return;
        }
        this._initUIEvent();
        this.reqRandomName();
        this.listJob.array = TableUtil.getRows(this.roleTable.job, {});

        const data = this.listJob.selectedItem as JobRow;
        this.labelJobName.text = data.name;
        this.labelJobDesc.text = data.desc;
    }

    private _initUIEvent() {
        this.btnCreatorRole.on(Laya.Event.CLICK, () => {
            if (this.textInputName.text.length === 0) {
                app.ui.toast("名字不能为空");
                return;
            }

            const jobRow = this.listJob.selectedItem as JobRow;
            //请求创角
            app.service.user
                .requestCreateRole({
                    account: app.service.login.username,
                    gender: this._isMan ? CommonConf.GENDER.MALE : CommonConf.GENDER.FEMALE,
                    name: this.textInputName.text,
                    jobId: jobRow.id,
                })
                .then((data: proto.user.Is2c_create_role) => {
                    if (data.err === errcode.OK) {
                        app.ui.replace(ui.LOADING_SCENE);
                    }
                });
        });
        this.btnRandomName.on(Laya.Event.CLICK, () => {
            //随机名字
            this.reqRandomName();
        });
        this.textInputName.on(Laya.Event.BLUR, () => {
            if (!this.isThisName && this._curName !== this.textInputName.text) {
                this.isThisName = true;
            }
        });
        this.btnMan.on(Laya.Event.CLICK, () => {
            if (!this.imgSelectedMan.visible) {
                this.imgSelectedMan.visible = true;
                this.imgSelectedWoman.visible = false;
                this._isMan = true;
                this.reqRandomName();

                this._updateHeroSkin();
            }
        });
        this.btnWoman.on(Laya.Event.CLICK, () => {
            if (!this.imgSelectedWoman.visible) {
                this.imgSelectedWoman.visible = true;
                this.imgSelectedMan.visible = false;
                this._isMan = false;
                this.reqRandomName();

                this._updateHeroSkin();
            }
        });
        this.listJob.renderHandler = new Laya.Handler(this, this._onListRender);
        this.listJob.on(Laya.Event.CHANGE, () => {
            this._updateHeroSkin();
            const data = this.listJob.selectedItem as JobRow;
            this.labelJobName.text = data.name;
            this.labelJobDesc.text = data.desc;
        });
    }

    private _onListRender(cell: Laya.Node, index: number): void {
        const data = this.listJob.array[index] as JobRow;
        const imgIcon = cell.getChildByName("imgJobIcon") as Laya.Image;
        const labelName = cell.getChildByName("labelName") as Laya.Label;
        labelName.text = data.btn_name;
        imgIcon.skin = `resources/atlas/create-role/img_job_${data.icon}.png`;
    }

    private _updateHeroSkin() {
        const data = this.listJob.selectedItem as JobRow;
        this.imgHero.skin = `resources/atlas/cards/hero/img_hero_job_${data.icon}_${
            this._isMan ? CommonConf.GENDER.MALE : CommonConf.GENDER.FEMALE
        }.png`;
    }

    private reqRandomName() {
        app.service.user
            .requestRandomName({
                gender: this._isMan ? CommonConf.GENDER.MALE : CommonConf.GENDER.FEMALE,
            })
            .then((val: proto.user.s2c_random_name) => {
                this.textInputName.text = val.name;
                this.isThisName = false;
            });
    }
}
