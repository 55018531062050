import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { Pool } from "../../../../../core/pool";
import { SecondOrderDynamicsV3 } from "../../../base/SecondOrderDynamics";
import { GameObjectFlyData } from "../../../pve-server/PveDefs";
import { PveContext } from "../../PveContext";

/** 游戏对象飞行表现系统 */
export class PveGameObjectFlySystem extends ecs.System {
    declare context: PveContext;

    override update(dt: number): void {
        if (this.flyDataMap.size > 0) {
            const nowTime = app.service.network.serverTime;
            this.flyDataMap.forEach((flyData, sp3D) => {
                const delay = flyData.delay;
                const overTime = nowTime - flyData.startTime - delay;
                if (overTime > 0) {
                    const flyTime = flyData.flyTime;
                    if (overTime >= flyTime) {
                        // 到达目的地
                        Pool.free(flyData.startPoint);
                        Pool.free(flyData.endPoint);
                        Pool.free(flyData.topPoint);
                        Pool.free(flyData.start2TopVec);
                        Pool.free(flyData.top2EndVec);
                        Pool.free(flyData.resultPoint);
                        Pool.free(flyData.sod);
                        flyData.startPoint = undefined;
                        flyData.endPoint = undefined;
                        flyData.topPoint = undefined;
                        flyData.start2TopVec = undefined;
                        flyData.top2EndVec = undefined;
                        flyData.resultPoint = undefined;
                        flyData.sod = undefined;
                        this.flyDataMap.delete(sp3D);
                        flyData.endCallBack.run();
                    } else {
                        // 飞行进行中
                        const percent = overTime / flyTime;
                        const curVal = flyData.totalLen * percent;
                        const subTObj = this.getSubsectionT(
                            [flyData.start2TopVecLen, flyData.top2EndVecLen],
                            curVal
                        );
                        if (subTObj.index === 0) {
                            Laya.Vector3.lerp(
                                flyData.startPoint!,
                                flyData.topPoint!,
                                subTObj.subT,
                                flyData.resultPoint!
                            );
                        } else if (subTObj.index === 1) {
                            Laya.Vector3.lerp(
                                flyData.topPoint!,
                                flyData.endPoint!,
                                subTObj.subT,
                                flyData.resultPoint!
                            );
                        }
                        const newPos = flyData.sod!.update(
                            dt,
                            flyData.resultPoint!.x,
                            flyData.resultPoint!.y,
                            flyData.resultPoint!.z
                        );
                        sp3D.transform.position = newPos!;
                    }
                }
            });
        }
    }

    /**
     * 获取子分段里的0~1之间的t值
     * @param subsections 段长度的数组
     * @param val 0~subsections的总长度
     * @returns index: 所在段的索引 subT：所在段内的t值
     */
    public getSubsectionT(subsections: number[], val: number): { index: number; subT: number } {
        let index = -1;
        let subT = 0;
        let tempStart: number = 0;
        for (let i = 0; i < subsections.length; i++) {
            const subsection = subsections[i];
            const tempEnd = tempStart + subsection;
            subT = Math.inverseLerp(tempStart, tempEnd, val);
            if (subT >= 0 && subT <= 1) {
                index = i;
                break;
            }
            tempStart = tempEnd;
        }
        return { index: index, subT: subT };
    }

    private _tempS2EPoint: Laya.Vector3 = new Laya.Vector3();
    private _tempZPoint: Laya.Vector3 = new Laya.Vector3(0, 0, -1);
    private _tempVerticalPoint: Laya.Vector3 = new Laya.Vector3();

    public flyDataMap: Map<Laya.Sprite3D, GameObjectFlyData> = new Map<
        Laya.Sprite3D,
        GameObjectFlyData
    >();

    /**
     * @param gameObject 飞行的对象
     * @param startPoint 开始点
     * @param endPoint 结束点
     * @param speed 飞行速度
     * @param endCallBack 飞行到达目的地回调
     */
    public createGameObjectFly(
        gameObject: Laya.Sprite3D,
        startPoint: Laya.Vector3,
        endPoint: Laya.Vector3,
        speed: number,
        delay: number,
        endCallBack: Laya.Handler
    ): void {
        const distance = Laya.Vector3.distance(startPoint, endPoint);
        Laya.Vector3.subtract(endPoint, startPoint, this._tempS2EPoint);
        this._tempS2EPoint.normalize();
        Laya.Vector3.scale(this._tempS2EPoint, distance / 4, this._tempS2EPoint);
        Laya.Vector3.cross(this._tempZPoint, this._tempS2EPoint, this._tempVerticalPoint);
        this._tempVerticalPoint.y = Math.abs(this._tempVerticalPoint.y);
        this._tempVerticalPoint.normalize();
        const verticalHeight = distance / 4;
        Laya.Vector3.scale(this._tempVerticalPoint, verticalHeight, this._tempVerticalPoint);

        const topRandOffset = 0.1;
        const topRandOffsetX = Math.random() * topRandOffset - 0.5 * topRandOffset;
        const topRandOffsetY = Math.random() * topRandOffset;
        const topRandOffsetZ = Math.random() * topRandOffset - 0.5 * topRandOffset;
        // 最高点
        const topPoint = Pool.obtain(Laya.Vector3);
        topPoint.x =
            startPoint.x + this._tempS2EPoint.x + this._tempVerticalPoint.x + topRandOffsetX;
        topPoint.y =
            startPoint.y + this._tempS2EPoint.y + this._tempVerticalPoint.y + topRandOffsetY;
        topPoint.z =
            startPoint.z + this._tempS2EPoint.z + this._tempVerticalPoint.z + topRandOffsetZ;

        const start2TopPoint = Pool.obtain(Laya.Vector3);
        Laya.Vector3.subtract(topPoint, startPoint, start2TopPoint);
        const top2EndPoint = Pool.obtain(Laya.Vector3);
        Laya.Vector3.subtract(endPoint, topPoint, top2EndPoint);
        const start2TopPointLen = start2TopPoint.length();
        const top2EndPointLen = top2EndPoint.length();
        const totalLen = start2TopPointLen + top2EndPointLen;

        const sod = Pool.obtain(SecondOrderDynamicsV3);
        sod.init(2, 0.5, 2, startPoint.x, startPoint.y, startPoint.z);

        this.flyDataMap.set(gameObject, {
            startPoint: startPoint,
            endPoint: endPoint,
            topPoint: topPoint,
            start2TopVec: start2TopPoint,
            top2EndVec: top2EndPoint,
            start2TopVecLen: start2TopPointLen,
            top2EndVecLen: top2EndPointLen,
            totalLen: totalLen,
            startTime: app.service.network.serverTime,
            flyTime: totalLen / speed,
            endCallBack: endCallBack,
            resultPoint: Pool.obtain(Laya.Vector3),
            sod: sod,
            delay,
        });
    }
}
