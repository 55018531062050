/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { BaseMidItemIcon } from "./BaseMidItemIcon";

/**
 * resources/prefab/common/item-icon/cost_mid_item_icon.lh
 */
export class CostMidItemIconBase extends Laya.Box {
    public itemIcon!: BaseMidItemIcon;
    public progressBar!: Laya.ProgressBar;
    public lab_01!: Laya.Label;
}

