import { BattleEntityType } from "../../../../../def/auto/battle";
import { SystemEvent } from "../../../../../misc/system-event";
import { AnimName } from "../../../base/Animator";
import { PveServer } from "../../PveServer";
import { PveSvrUtils } from "../../PveSvrUtils";
import { PveSvrAiComponent } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrCreatureComponent } from "../../ecs/components/PveSvrCreatureComponent";
import { ElementTag, PveSvrElementComponent } from "../../ecs/components/PveSvrElementComponent";
import { PveSvrSkillLauncherComponent } from "../../ecs/components/PveSvrSkillLauncherComponent";
import { PveSvrSoldierComponent } from "../../ecs/components/PveSvrSoldierComponent";
import { PveSvrSoldierGroupComponent } from "../../ecs/components/PveSvrSoldierGroupComponent";
import { BuffFunctionalType } from "../../ecs/data/buff/functional/BuffFunctionalData";
import { BuffSpecialType } from "../../ecs/data/buff/special/BuffSpecialData";
import { PveSvrGameDefeatSystem } from "../../ecs/systems/PveSvrGameDefeatSystem";
import { SkillSystemUtils } from "./SkillSystemUtils";

export class DamagePipeline {
    constructor(private pveServer: PveServer) {}

    //PVE·普攻伤害=【基础伤害值】*【最终普攻伤害率】*【最终暴击伤害率】*【PVE最终减伤】*【兵种克制系数】*【随机数】
    normalAtk(attackerEid: number, defenderEid: number) {
        const attackerData = this.getAttackerData(attackerEid);
        const defenderData = this.getDefenderData(defenderEid);
        if (!attackerData || !defenderData) {
            console.warn(
                "normalAtk attacker or defender is null",
                attackerData,
                defenderData,
                attackerEid,
                defenderEid
            );
            return;
        }

        const damageData = new InteralDamageData();
        damageData.attacker = attackerData;
        damageData.defender = defenderData;
        damageData.curDamage = 0;
        damageData.attackerType = AttackType.Normal;
        damageData.damageType = DamageType.Normal;

        this.piplelineDamage(damageData);
    }

    //PVE·技能伤害=【基础伤害值】*【技能伤害率+技能伤害率修正】*【最终暴击伤害率】*【PVE最终减伤】*【兵种克制系数】*【随机数】
    skillAtk(attackerEid: number, defenderEid: number, skillRatio: number) {
        const attackerData = this.getAttackerData(attackerEid);
        const defenderData = this.getDefenderData(defenderEid);
        if (!attackerData || !defenderData) {
            console.warn(
                "skillAtk attacker or defender is null",
                attackerData,
                defenderData,
                attackerEid,
                defenderEid
            );
            return;
        }

        const damageData = new InteralDamageData();
        damageData.attacker = attackerData;
        damageData.defender = defenderData;
        damageData.curDamage = 0;
        damageData.attackerType = AttackType.Skill;
        damageData.damageType = DamageType.Normal;
        damageData.skillRatio = skillRatio;

        this.piplelineDamage(damageData);
    }

    pureAtk(attackerEid: number, defenderEid: number, damage: number) {
        const attackerData = new DamageAttacker();
        attackerData.eid = attackerEid;

        const defenderData = new DamageDefender();
        defenderData.eid = defenderEid;

        const damageData = new InteralDamageData();
        damageData.attacker = attackerData;
        damageData.defender = defenderData;
        damageData.curDamage = damage;
        damageData.attackerType = AttackType.Pure;
        damageData.damageType = DamageType.Normal;
        this.piplelineDamage(damageData);
    }

    private piplelineDamage(damageData: InteralDamageData) {
        this.pveServer.dispatchAll(
            damageData.defender.eid,
            SystemEvent.BTREE.ON_BE_ATKED,
            damageData.toData()
        );

        const defender = this.pveServer.ecs.getComponent(
            damageData.defender.eid,
            PveSvrCreatureComponent
        );
        if (!defender || defender.hp <= 0) {
            // 受击者已经死亡，不需要计算伤害
            return;
        }

        const isImmuneNegative = SkillSystemUtils.isImmuneNegative(
            this.pveServer,
            damageData.defender.eid
        );
        if (isImmuneNegative) {
            // 免疫一切伤害
            return;
        }

        if (
            damageData.attackerType === AttackType.Normal &&
            damageData.damageType === DamageType.Normal
        ) {
            const isMiss = this.calcuIsMiss(damageData);
            if (isMiss) {
                const data = damageData.toData();
                this.pveServer.dispatchAll(data.attacker, SystemEvent.BTREE.ON_ATK_MISS, data);
                return damageData;
            }
        }
        this.calcuDamage(damageData);
        this.defenderGetHurt(defender, damageData);

        if (
            damageData.attackerType === AttackType.Normal &&
            damageData.damageType === DamageType.Normal
        ) {
            // 普通攻击才有可能触发连击、溅射、反击
        }
        return damageData;
    }

    private defenderGetHurt(defender: PveSvrCreatureComponent, damageData: InteralDamageData) {
        // 受击者收到伤害，需要计算伤害分担
        const buffComp = defender.buffComp;
        const damage = damageData.curDamage;
        this.pveServer.dispatchAll(damageData.defender.eid, SystemEvent.BTREE.ON_GET_HURT, damage);
        this.pveServer.dispatchAll(
            damageData.attacker.eid,
            SystemEvent.BTREE.ON_COUSE_HURT,
            damage
        );

        const map = buffComp.buffFunctionalDatas.get(BuffFunctionalType.DecreaseFinalHurt);
        let decreaseFinalHurtRate = 0;
        map?.forEach((buffDatas, buffId) =>
            buffDatas.forEach((buffData) => {
                decreaseFinalHurtRate += buffData.value;
            })
        );
        decreaseFinalHurtRate = decreaseFinalHurtRate / 100;

        decreaseFinalHurtRate = Math.min(0.9, decreaseFinalHurtRate); //测试，最大减伤90%

        const finalDamage = damageData.curDamage * (1 - decreaseFinalHurtRate);
        damageData.curDamage = finalDamage;

        this.defenderDecreaseHp(defender, damageData);
    }

    // 到达扣血阶段
    private defenderDecreaseHp(defender: PveSvrCreatureComponent, damageData: InteralDamageData) {
        // 扣血会收到圣衣buff的影响，限制最大扣血量
        const buffComp = defender.buffComp;
        const map = buffComp.buffSpecialDatas.get(BuffSpecialType.LimitHurt);
        let limitHurtRate = 100;
        let isLimit = false;
        map?.forEach((buffDatas, buffId) =>
            buffDatas.forEach((buffData) => {
                limitHurtRate = Math.min(limitHurtRate, buffData.value);
                isLimit = true;
            })
        );
        if (isLimit) {
            limitHurtRate = limitHurtRate / 100;
            const maxDamage = defender.maxHp * limitHurtRate;
            damageData.curDamage = Math.min(maxDamage, damageData.curDamage);
        }

        this.onBeforeDecreaseHp(defender, damageData);
        defender.hp -= damageData.curDamage;

        this.pveServer.dispatchAll(
            defender.eid,
            SystemEvent.BTREE.ON_HP_DECREASE,
            damageData.curDamage
        );
        this.onAfterDecreaseHp(defender);
    }

    private onBeforeDecreaseHp(defender: PveSvrCreatureComponent, damageData: InteralDamageData) {
        this.pveServer.sender.updateHp(defender.eid, {
            hp: Math.max(0, defender.hp - damageData.curDamage),
            maxHp: defender.maxHp,
            subHp: damageData.curDamage,
            isCrit: damageData.isCrit,
        });
    }

    private onAfterDecreaseHp(defender: PveSvrCreatureComponent) {
        if (defender.isDie) {
            const ai = defender.getComponent(PveSvrAiComponent);
            if (ai) {
                if (ai.tree) {
                    PveSvrUtils.interuptAITree(ai);
                    ai.active = false;
                }
                defender.removeComponent(PveSvrAiComponent);
            }
            this.pveServer.moveStop(defender.eid);
            this.pveServer.playAnim(defender.eid, AnimName.DIE);

            this.pveServer.dispatchAll(
                defender.eid,
                SystemEvent.BTREE.ON_CREATURE_DIE,
                defender.eid
            );

            if (defender.eleComp.hasTag(ElementTag.PLAYER | ElementTag.LEADER)) {
                this.pveServer.stopMoveAll();
                PveSvrGameDefeatSystem.pauseAllAI(this.pveServer);
                // 玩家本人的领队死亡,显示战斗失败
                this.pveServer.sender.gameDefeat();
            } else {
                // 除了玩家本人，其他人死亡需要移除实体
                const delEle = defender.eleComp;

                this.pveServer.ecs.delay(2, delEle.eid, () => {
                    this.pveServer.removeElement(delEle);
                });
                delEle.removeComponent(PveSvrCreatureComponent);
                delEle.removeComponent(PveSvrAiComponent);
                delEle.removeComponent(PveSvrSkillLauncherComponent);
                delEle.removeComponent(PveSvrSoldierComponent);
            }
        } else {
            PveSvrUtils.launchVFXEffect(
                this.pveServer,
                defender.eid,
                80009, //特效_受击_通用
                defender.transformComp.position,
                0,
                true,
                { x: 0.1, y: 0.5, z: 0.1 },
                1
            );
        }
    }

    private calcuIsMiss(damageData: InteralDamageData): boolean {
        const hit = damageData.attacker.hit;
        const dodge = damageData.defender.dodge;
        //  1-（A的命中-B的闪避）=该次伤害被闪避概率
        // 闪避率最大值为80%
        // 闪避概率最小值为0%
        const missRate = Math.min(0.8, 1 - (hit - dodge));
        return Math.random() < missRate;
    }

    //计算一次伤害
    private calcuDamage(damageData: InteralDamageData) {
        if (damageData.attackerType === AttackType.Normal) {
            switch (damageData.damageType) {
                case DamageType.Normal:
                    // PVE·普攻伤害=【基础伤害值】*【最终普攻伤害率】*【最终暴击伤害率】*【PVE最终减伤】*【随机数】
                    this.calcuBasicDamage(damageData);
                    this.calcuGeneralAtkDamage(damageData);
                    this.calcuCritDamage(damageData);
                    this.calcuPveFinalDamage(damageData);
                    this.clacuRandomDamge(damageData);
                    break;
                case DamageType.Pursuit:
                    // PVE·连击伤害=【基础伤害值】*【最终连击伤害率】*【PVE最终减伤】*【随机数】
                    this.calcuBasicDamage(damageData);
                    this.calcuPursuitDamage(damageData);
                    this.calcuPveFinalDamage(damageData);
                    this.clacuRandomDamge(damageData);
                    break;
                case DamageType.Counter:
                    // PVE·反击伤害=【基础伤害值】*【最终反击伤害率】*【PVE最终减伤】*【随机数】
                    this.calcuBasicDamage(damageData);
                    this.calcuCounterDamage(damageData);
                    this.calcuPveFinalDamage(damageData);
                    this.clacuRandomDamge(damageData);
                    break;
                case DamageType.Sputter:
                    // PVE·溅射伤害=【基础伤害值】*【最终溅射伤害率】*【PVE最终减伤】*【随机数】
                    this.calcuBasicDamage(damageData);
                    this.calcuSputterDamage(damageData);
                    this.calcuPveFinalDamage(damageData);
                    this.clacuRandomDamge(damageData);
                    break;
                case DamageType.penetrate:
                    // PVE·穿透伤害=【基础伤害值】*【最终穿透伤害率】*【PVE最终减伤】*【随机数】
                    this.calcuBasicDamage(damageData);
                    this.calcuPenetrateDamage(damageData);
                    this.calcuPveFinalDamage(damageData);
                    this.clacuRandomDamge(damageData);
                    break;
                default:
                    console.error("unhandle DamageType", damageData.damageType, damageData);
                    break;
            }
        } else if (damageData.attackerType === AttackType.Skill) {
            // PVE·技能伤害=【基础伤害值】*【技能伤害率+技能伤害率修正】*【最终暴击伤害率】*【PVE最终减伤】*【随机数】
            this.calcuBasicDamage(damageData);
            this.calcuSkillDamage(damageData);
            this.calcuCritDamage(damageData);
            this.calcuPveFinalDamage(damageData);
            this.clacuRandomDamge(damageData);
        } else {
            // do nothing
        }
    }

    private getAttackerData(attackerEid: number): DamageAttacker | null {
        const eleComp = this.pveServer.ecs.getComponent(attackerEid, PveSvrElementComponent)!;
        if (!eleComp) {
            return null;
        }
        const attackData = new DamageAttacker();
        attackData.eid = attackerEid;
        if (eleComp.etype === BattleEntityType.SOLDIER_GROUP) {
            const solderGroup = eleComp.getComponent(PveSvrSoldierGroupComponent)!;
            const soldierEids = PveSvrSoldierGroupComponent.getSoldierEids(
                this.pveServer,
                solderGroup
            );
            const count = Object.keys(soldierEids).length;
            if (count > 0) {
                for (const idx in soldierEids) {
                    const eid = soldierEids[idx];
                    const creature = this.pveServer.ecs.getComponent(eid, PveSvrCreatureComponent)!;
                    attackData.atk += creature.atk;
                    attackData.hit += creature.hit;
                    attackData.generalAckUp += creature.generalAckUp;
                    attackData.criticalUp += creature.criticalUp;
                    attackData.criticalDamage += creature.criticalDamage;
                    attackData.finalAtkUp += creature.finalAtkUp;
                    attackData.pveAttackUp += creature.pveAttackUp;
                    attackData.pursuitUp += creature.pursuitUp;
                    attackData.pursuitDamage += creature.pursuitDamage;
                    attackData.counterUp += creature.counterUp;
                    attackData.counterDamage += creature.counterDamage;
                    attackData.sputterUp += creature.sputterUp;
                    attackData.sputterDamage += creature.sputterDamage;
                    attackData.penetrateUp += creature.penetrateUp;
                    attackData.penetrateDamage += creature.penetrateDamage;
                }

                attackData.atk /= count;
                attackData.hit /= count;
                attackData.generalAckUp /= count;
                attackData.criticalUp /= count;
                attackData.criticalDamage /= count;
                attackData.finalAtkUp /= count;
                attackData.pveAttackUp /= count;
                attackData.pursuitUp /= count;
                attackData.pursuitDamage /= count;
                attackData.counterUp /= count;
                attackData.counterDamage /= count;
                attackData.sputterUp /= count;
                attackData.sputterDamage /= count;
                attackData.penetrateUp /= count;
                attackData.penetrateDamage /= count;
            }
        } else {
            const creature = this.pveServer.ecs.getComponent(attackerEid, PveSvrCreatureComponent);
            if (!creature) {
                console.warn("getAttackerData::creature is null", attackerEid);
                return null;
            }
            attackData.atk += creature.atk;
            attackData.hit += creature.hit;
            attackData.generalAckUp += creature.generalAckUp;
            attackData.criticalUp += creature.criticalUp;
            attackData.criticalDamage += creature.criticalDamage;
            attackData.finalAtkUp += creature.finalAtkUp;
            attackData.pveAttackUp += creature.pveAttackUp;
            attackData.pursuitUp += creature.pursuitUp;
            attackData.pursuitDamage += creature.pursuitDamage;
            attackData.counterUp += creature.counterUp;
            attackData.counterDamage += creature.counterDamage;
            attackData.sputterUp += creature.sputterUp;
            attackData.sputterDamage += creature.sputterDamage;
            attackData.penetrateUp += creature.penetrateUp;
            attackData.penetrateDamage += creature.penetrateDamage;
        }

        return attackData;
    }

    private getDefenderData(defenderEid: number): DamageDefender | null {
        const defender = this.pveServer.ecs.getComponent(defenderEid, PveSvrCreatureComponent)!;
        if (!defender) {
            return null;
        }
        const defenderData = new DamageDefender();
        defenderData.eid = defender.eid;
        defenderData.defence = defender.defence;
        defenderData.dodge = defender.dodge;
        defenderData.generalAckDown = defender.generalAckDown;
        defenderData.criticalDown = defender.criticalDown;
        return defenderData;
    }

    // 计算攻击-基础伤害
    private calcuBasicDamage(damageData: InteralDamageData): InteralDamageData {
        const atk = damageData.attacker.atk;
        const def = damageData.defender.defence;
        // 普通攻击基础伤害=A单位攻击*（1-1/（1+A单位攻击/（5*B单位防御）））
        damageData.curDamage = atk * (1 - 1 / (1 + atk / (5 * def)));
        if (damageData.curDamage < 0) {
            console.error("calcuBasicDamage::damageData.curDamage < 0", damageData.curDamage);
            damageData.curDamage = 0;
        }
        return damageData;
    }

    // 计算攻击-最终普攻伤害
    private calcuGeneralAtkDamage(damageData: InteralDamageData): InteralDamageData {
        const up = damageData.attacker.generalAckUp;
        const down = damageData.defender.generalAckDown;
        // 最终普攻伤害率=1+A强化普攻伤害率-B弱化普攻伤害率
        // 最终普攻伤害率的最小值取10%
        const rate = Math.max(0.1, 1 + up - down);
        damageData.curDamage *= rate;

        if (damageData.curDamage < 0) {
            console.error("calcuGeneralAtkDamage::damageData.curDamage < 0", damageData.curDamage);
        }
        return damageData;
    }

    // 计算攻击-暴击伤害
    private calcuCritDamage(damageData: InteralDamageData): InteralDamageData {
        const up = damageData.attacker.criticalUp;
        const down = damageData.defender.criticalDown;

        const rate = Math.max(0, Math.min(1, up - down));
        const isCrit = Math.random() < rate;
        if (isCrit) {
            damageData.curDamage *= damageData.attacker.criticalDamage;
            damageData.isCrit = true;
        }

        if (damageData.curDamage < 0) {
            console.error("calcuCritDamage::damageData.curDamage < 0", damageData.curDamage);
        }
        return damageData;
    }

    private calcuPveFinalDamage(damageData: InteralDamageData): InteralDamageData {
        // PVE最终减伤=1+（A最终增伤 - B最终减伤） +（A的PVE增伤 -B的PVE减伤）
        // PVE最终减伤的最小值取10%
        const up = damageData.attacker.finalAtkUp;
        const pveUp = damageData.attacker.pveAttackUp;
        const down = damageData.defender.finalAtkDown;
        const pveDown = damageData.defender.pveFinalAtkDown;

        const rate = Math.max(0.1, 1 + (up - down) + (pveUp - pveDown));
        damageData.curDamage *= rate;

        if (damageData.curDamage < 0) {
            console.error("calcuPveFinalDamage::damageData.curDamage < 0", damageData.curDamage);
        }
        return damageData;
    }

    private clacuRandomDamge(damageData: InteralDamageData): InteralDamageData {
        // [0.95-1.05]
        const random = (95 + Math.random() * 10) / 100;
        damageData.curDamage *= random;
        return damageData;
    }

    //最终连击伤害率
    private calcuPursuitDamage(damageData: InteralDamageData): InteralDamageData {
        // 最终连击伤害率=1+A连击伤害率
        const pursuit = 1 + damageData.attacker.pursuitDamage;
        damageData.curDamage *= pursuit;
        return damageData;
    }

    //最终反击伤害率
    private calcuCounterDamage(damageData: InteralDamageData): InteralDamageData {
        // 最终反击伤害率=B反击伤害率
        const counter = damageData.attacker.counterDamage;
        damageData.curDamage *= counter;
        return damageData;
    }

    // 【最终溅射伤害率】
    private calcuSputterDamage(damageData: InteralDamageData): InteralDamageData {
        // 最终溅射伤害率=1+A溅射伤害率
        const sputter = 1 + damageData.attacker.sputterDamage;
        damageData.curDamage *= sputter;
        return damageData;
    }

    // 【最终穿透伤害率】
    private calcuPenetrateDamage(damageData: InteralDamageData): InteralDamageData {
        // 最终穿透伤害率=1+A穿透伤害率
        const penetrate = 1 + damageData.attacker.penetrateDamage;
        damageData.curDamage *= penetrate;
        return damageData;
    }

    // 【技能伤害率】
    private calcuSkillDamage(damageData: InteralDamageData): InteralDamageData {
        const skillRatio = damageData.skillRatio;
        damageData.curDamage *= skillRatio;
        return damageData;
    }
}

export enum AttackType {
    Normal = 1, //普攻伤害
    Skill = 2, //技能伤害
    Pure = 3, //纯粹伤害，不需要计算一系列的属性，直达生命值
}

export enum DamageType {
    Normal = 0, //普通
    Pursuit = 2, //追击
    Counter = 3, //反击
    Sputter = 4, //溅射
    penetrate = 5, //穿透
}

export interface IAttacker {
    atk: number; //攻击力
    hit: number; //命中
    generalAckUp: number; //强化普攻伤害
    criticalUp: number; //暴击率
    criticalDamage: number; //暴击伤害倍率
    finalAtkUp: number; //最终增伤
    pveAttackUp: number; //PVE最终增伤

    pursuitUp: number; //追击
    pursuitDamage: number; //追击伤害
    counterUp: number; //反击
    counterDamage: number; //反击伤害
    sputterUp: number; //溅射
    sputterDamage: number; //溅射伤害
    penetrateUp: number; //穿透
    penetrateDamage: number; //穿透伤害
}

export interface IDefender {
    defence: number;
    dodge: number; //闪避
    generalAckDown: number; //弱化普攻伤害
    criticalDown: number; //抵抗暴击
    finalAtkDown: number; //最终减伤
    pveFinalAtkDown: number; //PVE最终减伤

    pursuitDown: number; //抵抗追击
    counterDown: number; //抵抗反击
    sputterDown: number; //抵抗溅射
    penetrateDown: number; //抵抗穿透
}

class DamageAttacker implements IAttacker {
    eid: number = 0;

    atk: number = 0;
    hit: number = 0;
    generalAckUp: number = 0;
    criticalUp: number = 0;
    criticalDamage: number = 0;
    finalAtkUp: number = 0;
    pveAttackUp: number = 0;

    // optional
    pursuitUp: number = 0;
    pursuitDamage: number = 0;
    counterUp: number = 0;
    counterDamage: number = 0;
    sputterUp: number = 0;
    sputterDamage: number = 0;
    penetrateUp: number = 0;
    penetrateDamage: number = 0;
}

class DamageDefender implements IDefender {
    eid: number = 0;

    defence: number = 0;
    dodge: number = 0;
    generalAckDown: number = 0;
    criticalDown: number = 0;
    finalAtkDown: number = 0;
    pveFinalAtkDown: number = 0;

    // optional
    pursuitDown: number = 0;
    counterDown: number = 0;
    sputterDown: number = 0;
    penetrateDown: number = 0;
}

export interface DamageData {
    attacker: number; //不要随意修改名字，因为技能和buff行为树里会读取
    defender: number; //不要随意修改名字，因为技能和buff行为树里会读取
    isCrit: boolean; //不要随意修改名字，因为技能和buff行为树里会读取
    damage: number; //不要随意修改名字，因为技能和buff行为树里会读取
    attackType: AttackType; //不要随意修改名字，因为技能和buff行为树里会读取
    damageType: DamageType; //不要随意修改名字，因为技能和buff行为树里会读取
}

class InteralDamageData {
    attacker!: DamageAttacker;
    defender!: DamageDefender;

    attackerType: AttackType = AttackType.Normal; //传递的伤害值，需要继续经过传递
    damageType: DamageType = DamageType.Normal; //伤害类型，需要继续经过传递

    isCrit: boolean = false; //是否暴击

    skillRatio: number = 0; //技能伤害率

    curDamage: number = 0; //不代表最终伤害值，只是当前阶段算出的伤害值

    toData(): DamageData {
        return {
            attacker: this.attacker.eid,
            defender: this.defender.eid,
            isCrit: this.isCrit,
            damage: this.curDamage,
            attackType: this.attackerType,
            damageType: this.damageType,
        };
    }
}
