/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIHBoxStar } from "../UIHBoxStar";

/**
 * resources/prefab/character/upstar-preview/CharacterUpstarPreviewItem.lh
 */
export class CharacterUpStarPreviewItemBase extends Laya.Box {
    public imgBg!: Laya.Image;
    public starHBox!: UIHBoxStar;
    public imgStar0!: Laya.Image;
    public imgStar1!: Laya.Image;
    public imgStar2!: Laya.Image;
    public imgStar3!: Laya.Image;
    public imgStar4!: Laya.Image;
    public lblStarDesc!: Laya.Label;
}

