import { app } from "../../../../../../app";
import { Pool } from "../../../../../../core/pool";
import { BattleConf, PveEventType, PveNpcStateType } from "../../../../../../def/auto/battle";
import proto, { mainline } from "../../../../../../def/auto/proto";
import { TroopStation } from "../../../../../../def/auto/troop";
import { BattleBuildingRow } from "../../../../../../def/table";
import { TableUtil } from "../../../../../table/TableUtil";
import { PveAStarData } from "../../components/PveSvrAstarComponent";
import { PveSvrElementComponent } from "../../components/PveSvrElementComponent";
import { PveSvrBuildingStateData } from "./PveSvrBuildingStateData";
import { PveSvrCollectionStateData } from "./PveSvrCollectionStateData";
import { PveSvrEventStateData } from "./PveSvrEventStateData";
import { PveSvrHeroStateData } from "./PveSvrHeroStateData";
import { PveSvrMonsterTroopStateData } from "./PveSvrMonsterTroopStateData";
import { PveSvrNpcStateData } from "./PveSvrNpcStateData";
import { PveSvrSoldierGroupStateData } from "./PveSvrSoldierGroupStateData";
import { PveSvrSpoilsStateData } from "./PveSvrSpoilsStateData";
import { PveSvrTruckStateData } from "./PveSvrTruckStateData";

export class PveSvrStateData {
    /** 主角信息 */
    readonly hero: PveSvrHeroStateData = new PveSvrHeroStateData();

    /** 资源车信息 */
    readonly trucks: PveSvrTruckStateData[] = [];

    /** 士兵信息 */
    readonly soldierGroups: PveSvrSoldierGroupStateData[] = [];

    /** 事件的记录(领宝箱、解救士兵、解开迷雾) */
    readonly events: PveSvrEventStateData[] = [];

    /** 怪物部队数据的记录 */
    readonly monsterTroops: PveSvrMonsterTroopStateData[] = [];

    /** NPC数据的记录 */
    npcs: PveSvrNpcStateData[] = [];

    /** 建筑 */
    buildings: PveSvrBuildingStateData[] = [];

    /** 树木、石头、粮草 */
    collections: PveSvrCollectionStateData[] = [];

    /** 击杀怪物/Boss 掉落的战利品 */
    spoils: PveSvrSpoilsStateData[] = [];

    /** 缓存是否开启领域 */
    private _isIsOpenTerritory: boolean = false;

    /** 是否开启领域 */
    public get isOpenTerritory(): boolean {
        if (this._isIsOpenTerritory) {
            return true;
        }
        const events = this.events.filter((v) => !!v.eventVo);
        for (const event of events) {
            const eventVo = event.eventVo!;

            if (eventVo.eventType === PveEventType.UNLOCK_CLOUD) {
                if (event.isDeath && eventVo.key === BattleConf.PVE.UNLOCK_TERRITORY_CLOUD_ID) {
                    this._isIsOpenTerritory = true;
                    return true;
                }
            } else if (eventVo.eventType === PveEventType.TALK_TO_NPC) {
                if (event.isDeath && eventVo.key === BattleConf.PVE.UNLOCK_TERRITORY_CLOUD_ID) {
                    this._isIsOpenTerritory = true;
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * 获取新加入士兵应该跟随的阵位位置
     * 返回-1表示没有合适的位置
     *  */
    public getEmptySoldierPosByStation(station: TroopStation): number {
        const existSoldierIdxes: number[] = [];
        this.soldierGroups.forEach((group) => {
            group.soldiers.forEach((soldier) => {
                existSoldierIdxes.push(soldier.idx);
            });
        });
        const oneRowCount = BattleConf.PVE.SOLDIER_ONE_ROW_COUNT;
        const startIndex = [0, oneRowCount, oneRowCount * 2][station - 1]; // 前排 0 开始，中排5 开始 后排10开始
        for (let i = 0; i < oneRowCount; i++) {
            const tempIndex = startIndex + i;
            if (existSoldierIdxes.indexOf(tempIndex) === -1) {
                return tempIndex;
            }
        }
        return -1;
    }

    private firstUnlockEvents() {
        const eventIds = [11000]; //初始解锁拯救士兵事件
        eventIds.forEach((eventId) => {
            const eventLo = app.service.pve.eventLoMap.get(eventId);
            if (!eventLo) {
                console.error("eventLo is null", eventId);
                return;
            }
            if (eventLo.eventType !== PveEventType.RESCUE_SOLDIER) {
                console.error("eventLo.eventType is not RESCUE_SOLDIER", eventLo);
                return;
            }
            const soldierDatas = eventLo.soldierDatas;
            soldierDatas?.forEach((element) => {
                const tid = element.soldier;
                const station = element.pos;
                const soldierLo = app.service.hero.heroLoMap.get(tid);
                for (let i = 0; i < element.count; i++) {
                    const posIdx = this.getEmptySoldierPosByStation(station);
                    if (posIdx < 0) {
                        return;
                    }
                    if (this.hero.unlockSodiderIndexes.indexOf(posIdx) < 0) {
                        this.hero.unlockSodiderIndexes.push(posIdx);
                    }
                    this.addSoldier(posIdx, tid, 0, soldierLo.getConfHp(), 0, 0, 0);
                }
            });
        });
    }

    decode(mainLineData: mainline.IMainLineData): void {
        if (!mainLineData) {
            return;
        }
        const hero = mainLineData.hero;
        const curTime = app.service.network.serverTime;

        // 载入完成数据后，
        // 如果英雄已死亡，则进行满血复活,并且把士兵和车子位置设置为英雄位置 -- 临时处理
        if (hero) {
            this.hero.decode(hero as mainline.Charactor);
            mainLineData.soldierGroups?.forEach((v) => this.decodeSoldierGroup(v));
            mainLineData.trucks?.forEach((v) => {
                this.decodeTruck(v as mainline.Truck);
            });

            if (hero.health && hero.health.hp === 0) {
                const heroLo = app.service.hero.heroLoMap.get(hero.tid);
                const birthPointData = app.service.pve.minimap.getBirthPoint(1001);

                let hero_pos_x = 0;
                let hero_pos_y = 0;
                let hero_pos_rot = 0;
                if (birthPointData) {
                    hero_pos_x = birthPointData.x;
                    hero_pos_y = birthPointData.z;
                    hero_pos_rot = birthPointData.rot;
                }
                this.hero.pos.x = hero_pos_x;
                this.hero.pos.y = hero_pos_y;
                this.hero.rotation = hero_pos_rot;
                const maxHp = heroLo.getConfHp(this.hero.level);
                this.hero.hp = maxHp;
                this.hero.maxHp = maxHp;

                this.soldierGroups.forEach((v) => {
                    v.soldiers.forEach((s) => {
                        s.pos.x = hero_pos_x;
                        s.pos.y = hero_pos_y;
                        s.rotation = hero_pos_rot;
                    });
                });

                this.trucks.forEach((v) => {
                    v.pos.x = hero_pos_x;
                    v.pos.y = hero_pos_y;
                    v.rotation = hero_pos_rot;
                });
            }
        } else {
            // 初始解锁1个事件
            this.firstUnlockEvents();
        }

        // 载入完成数据后，需要把事件触发数据保存起来
        mainLineData.events?.forEach((v) => this.decodeEvent(v as mainline.Event));

        mainLineData.npcs?.forEach((v) => this.decodeNpc(v as mainline.Npc));

        // 记录地图怪物数据
        mainLineData.monsterTroops?.forEach((v) => {
            this.decodeMonsterTroop(v as mainline.MonsterTroop);
        });

        // 记录地图建筑数据
        mainLineData.buildings?.forEach((building) => {
            if (!building.key) {
                console.error("building key is null", building);
                return;
            }
            // 处理建筑等级
            let upgradeTime = building.upgradeTime ?? 0;
            let curLevel = building.level ?? 0;
            let levelCfg: BattleBuildingRow;
            const basecfg = app.service.table.battleBuilding[building.tid!];
            if (curLevel > 0) {
                const battle_entity = basecfg.battle_entity;
                const default_level = curLevel;
                levelCfg = TableUtil.getRow(app.service.table.battleBuilding, {
                    battle_entity,
                    default_level,
                })!;
            } else {
                levelCfg = basecfg;
            }

            const upgradeNeedTime = levelCfg.upgrade_need_time ?? 0;
            if (upgradeTime > 0 && upgradeNeedTime) {
                const upgradeEndTime = upgradeTime + upgradeNeedTime;
                if (curTime >= upgradeEndTime) {
                    // 处理建筑升级完成
                    curLevel = curLevel + 1;
                    upgradeTime = 0;
                    building.upgradeTime = 0;
                }
            }
            this.decodeBuildingData(building as mainline.Building);
        });

        // 记录地图可采集资源数据
        mainLineData.collections?.forEach((v) =>
            this.decodeCollectionData(v as mainline.Collection)
        );

        // 记录地图的战利品数据
        mainLineData.spoils?.forEach((v) => this.decodeSpoilsData(v as mainline.Spoils));
    }

    getMonsterTroopByKey(key: number) {
        return this.monsterTroops.find((v) => v.key === key);
    }

    getOrCreateMonsterTroopByKey(key: number, tid: number) {
        let monsterTroop = this.getMonsterTroopByKey(key);
        if (!monsterTroop) {
            monsterTroop = Pool.obtain(PveSvrMonsterTroopStateData);
            monsterTroop.key = key;
            monsterTroop.tid = tid;
            const row = TableUtil.getRow(app.service.table.monster.troop, { id: tid })!;
            monsterTroop.setReliveCD(row.fresh_time!);
            this.monsterTroops.push(monsterTroop);
        }
        return monsterTroop;
    }

    delMonsterTroopByKey(key: number) {
        const index = this.monsterTroops.findIndex((v) => v.key === key);
        if (index >= 0) {
            const monsterTroop = this.monsterTroops.splice(index, 1)[0];
            Pool.free(monsterTroop);
        }
    }

    getNpcByKey(key: number): PveSvrNpcStateData | undefined {
        return this.npcs.find((v) => v.key === key);
    }

    getNpcByTid(tid: number): PveSvrNpcStateData | undefined {
        return this.npcs.find((v) => v.tid === tid);
    }

    getOrCreateNpcByKey(
        key: number,
        x: number,
        z: number,
        tid: number,
        rot: number
    ): PveSvrNpcStateData {
        let npc = this.getNpcByKey(key);
        if (!npc) {
            npc = new PveSvrNpcStateData();
            npc.key = key;
            this.npcs.push(npc);
        }
        npc.x = x;
        npc.z = z;
        npc.tid = tid;
        return npc;
    }

    getBuildingByKey(key: number): PveSvrBuildingStateData | undefined {
        return this.buildings.find((v) => v.key === key);
    }

    getOrCreateBuildingByKey(key: number): PveSvrBuildingStateData {
        let building = this.getBuildingByKey(key);
        if (!building) {
            building = new PveSvrBuildingStateData();
            building.key = key;
            this.buildings.push(building);
        }
        return building;
    }

    public setBuildingUpgradeTime(key: number, upgradeTime: number): void {
        const building = this.getBuildingByKey(key)!;
        building.upgradeTime = upgradeTime;
    }

    getEventByKey(key: number): PveSvrEventStateData | undefined {
        return this.events.find((v) => v.key === key);
    }

    getOrCreateEventByKey(key: number): PveSvrEventStateData {
        let event = this.getEventByKey(key);
        if (!event) {
            event = new PveSvrEventStateData();
            event.key = key;
            this.events.push(event);
        }
        return event;
    }

    decodeSpoilsData(data: mainline.Spoils): PveSvrSpoilsStateData | undefined {
        const spoil = new PveSvrSpoilsStateData();
        spoil.decode(data);
        if (this.getSpoilsDataByPos(spoil.pos.x, spoil.pos.y)) {
            return;
        }
        this.spoils.push(spoil);
        return spoil;
    }

    /**
     * 添加一个战利品数据
     * 若传入的位置已经存在战利品，则添加失败返回null
     * 战利品过期，也添加失败
     */
    addSpoilsData(
        tid: number,
        dropTime: number,
        x: number,
        z: number
    ): PveSvrSpoilsStateData | null {
        if (this.getSpoilsDataByPos(x, z)) {
            return null;
        }
        const spoilsRecordData: PveSvrSpoilsStateData = new PveSvrSpoilsStateData();
        spoilsRecordData.tid = tid;
        spoilsRecordData.pos.x = x;
        spoilsRecordData.pos.y = z;
        spoilsRecordData.dropTime = dropTime;
        if (spoilsRecordData.isOverdue) {
            return null;
        }
        this.spoils.push(spoilsRecordData);
        return spoilsRecordData;
    }

    /**
     * 清理所有过期的战利品
     * @returns 已过期的战利品会被返回
     */
    clearAllOverdueSpoilsData(): PveSvrSpoilsStateData[] {
        const overdueArr: PveSvrSpoilsStateData[] = this.spoils.slice().filter((v) => v.isOverdue);
        this.spoils = this.spoils.filter((v) => !v.isOverdue);
        return overdueArr;
    }

    /** 根据位置获取掉落 */
    getSpoilsDataByPos(x: number, z: number) {
        return this.spoils.find(
            (v) => v.pos.x.toFixed(1) === x.toFixed(1) && v.pos.y.toFixed(1) === z.toFixed(1)
        );
    }

    /** 移除战利品数据 */
    removeSpoilsData(x: number, z: number): PveSvrSpoilsStateData | null {
        const index = this.spoils.findIndex(
            (v) => v.pos.x.toFixed(1) === x.toFixed(1) && v.pos.y.toFixed(1) === z.toFixed(1)
        );
        return index >= 0 ? this.spoils.splice(index, 1)[0] : null;
    }

    /**
     * 获取一个随机生成的位置，该位置必须没有战利品
     * @param radius 战利品掉落半径
     * @param monDiePosX 怪物死亡位置
     * @param monDiePosZ 怪物死亡位置
     * @param out 生成的显示对象位置，该位置未X10
     * @returns boolean 找到生成点时，返回true 循环一百次都未找到合适的位置时返回false
     */
    // note: 需要一个兜底方案，如果找不到合适的位置，就返回怪物死亡位置
    getSpoilsRandomPos(
        astarData: PveAStarData,
        radius: number,
        monDiePosX: number,
        monDiePosZ: number,
        out: Laya.Vector2
    ): boolean {
        for (let i = 0; i < 100; i++) {
            const addRadius = Math.floor(i / 10) * 0.5; // 为了确保多次找不到适合位置时，扩大半径查找适合的掉落点。
            // 随机角度, 随机距离
            const angle = Math.random() * Math.PI * 2;
            const distance = Math.random() * (radius + addRadius);
            const boxPosX = Math.cos(angle) * distance + monDiePosX;
            const boxPosZ = Math.sin(angle) * distance + monDiePosZ;
            const isBlock = astarData.isBlockAt(boxPosX, boxPosZ, true);
            if (isBlock) {
                // 为了确保战利品不能掉落在不可到达的障碍区域
                continue;
            }
            const spoilsRecordData = this.getSpoilsDataByPos(boxPosX, boxPosZ);
            if (!spoilsRecordData) {
                out.x = boxPosX;
                out.y = boxPosZ;
                return true;
            }
        }
        return false;
    }

    decodeMonsterTroop(data: mainline.MonsterTroop): PveSvrMonsterTroopStateData | undefined {
        const monsterTroop = this.getOrCreateMonsterTroopByKey(data.key, data.tid);
        monsterTroop.decode(data);
        return monsterTroop;
    }

    setMonsterTroopDeath(key: number, tid: number) {
        const monsterTroop = this.getOrCreateMonsterTroopByKey(key, tid);
        monsterTroop.tid = tid;
        monsterTroop.deathTime = Date.now() / 1000;
        monsterTroop.deathCount += 1;
        monsterTroop.monsters.length = 0;
    }

    decodeBuildingData(data: proto.mainline.Building) {
        let building = this.getBuildingByKey(data.key);
        if (!building) {
            building = Pool.obtain(PveSvrBuildingStateData);
            building.key = data.key;
            this.buildings.push(building);
        }
        building.decode(data);
    }

    getTruckByCollectType(collectType: number): PveSvrTruckStateData | undefined {
        return this.trucks.find((v) => v.collectType === collectType);
    }

    getCollectionByKey(key: number): PveSvrCollectionStateData | undefined {
        return this.collections.find((v) => v.key === key);
    }

    getOrCreateCollectionByKey(key: number): PveSvrCollectionStateData {
        let coll = this.getCollectionByKey(key);
        if (!coll) {
            coll = Pool.obtain(PveSvrCollectionStateData);
            coll.key = key;
            this.collections.push(coll);
        }
        return coll;
    }

    decodeCollectionData(data: proto.mainline.Collection) {
        let coll = this.getCollectionByKey(data.key);
        if (!coll) {
            coll = Pool.obtain(PveSvrCollectionStateData);
            coll.key = data.key;
            this.collections.push(coll);
        }
        coll.decode(data);
        return coll;
    }

    /**
     * @param key 例如 '10002_5.50_4.50'
     * @param hp 当前血量
     * @param maxHp 最大血量
     * @param deathTime 死亡的时间戳，单位秒, 0 表示没有死亡 deathTime 时，使用旧的死亡时间
     */
    setCollectionData(
        xIdx: number,
        yIdx: number,
        tid: number,
        hp: number,
        maxHp: number,
        deathTime?: number
    ): PveSvrCollectionStateData | null {
        let coll: PveSvrCollectionStateData | undefined = undefined;
        for (let i = 0; i < this.collections.length; i++) {
            const collection: PveSvrCollectionStateData = this.collections[i];
            if (collection.xIdx === xIdx && collection.yIdx === yIdx) {
                coll = collection;
                break;
            }
        }
        if (!coll) {
            coll = Pool.obtain(PveSvrCollectionStateData);
            const buildingVo = app.service.pve.buildingVoMap.get(tid)!;
            const fresh_time = buildingVo.freshTime;
            if (fresh_time > 0) {
                coll.setReliveCD(fresh_time);
            } else {
                coll.setReliveCD(Number.MAX_SAFE_INTEGER);
            }
            this.collections.push(coll);
        }
        coll.tid = tid;
        coll.xIdx = xIdx;
        coll.yIdx = yIdx;
        if (deathTime !== undefined) {
            coll.deathTime = deathTime;
        }
        coll.hp = hp;
        coll.maxHp = maxHp;
        return coll;
    }

    decodeTruck(data: proto.mainline.Truck) {
        let truck = this.trucks.find((v) => v.collectType === data.collectType);
        if (!truck) {
            truck = new PveSvrTruckStateData();
            this.trucks.push(truck);
        }
        truck.decode(data);
        return truck;
    }

    decodeSoldierGroup(data: proto.mainline.SoldierGroup) {
        let group = this.soldierGroups.find((v) => v.idx === data.idx);
        if (!group) {
            group = new PveSvrSoldierGroupStateData();
            this.soldierGroups.push(group);
        }
        group.decode(data);
        return group;
    }

    decodeNpc(data: proto.mainline.Npc) {
        let npc = this.getNpcByKey(data.key);
        if (!npc) {
            npc = new PveSvrNpcStateData();
            this.npcs.push(npc);
        }
        npc.decode(data);
        return npc;
    }

    /**
     * 设置事件(领宝箱、解救士兵)的触发时间
     * @param triggerTime 触发的时间戳，单位秒
     */
    decodeEvent(data: proto.mainline.Event) {
        let event = this.getEventByKey(data.key);
        if (!event) {
            event = new PveSvrEventStateData();
            this.events.push(event);
        }
        event.decode(data);
        return event;
    }

    delEventByKey(key: number) {
        const index = this.events.findIndex((v) => v.key === key);
        if (index >= 0) {
            this.events.splice(index, 1);
        }
    }

    setNpcState(element: PveSvrElementComponent, state: PveNpcStateType) {
        let npc = this.getNpcByKey(element.key);
        if (!npc) {
            npc = new PveSvrNpcStateData();
            this.npcs.push(npc);
        }
        npc.key = element.key;
        npc.tid = element.tid;
        npc.state = state;
        // npc.xIdx = element.spawnpoint.x | 0;
        // npc.yIdx = element.spawnpoint.z | 0;
    }

    setEventTriggerTime(element: PveSvrElementComponent, triggerTime: number) {
        let event = this.getEventByKey(element.key);
        if (!event) {
            event = new PveSvrEventStateData();
            this.events.push(event);
        }
        event.key = element.key;
        event.tid = element.tid;
        event.deathTime = triggerTime;
        event.xIdx = element.spawnpoint.x | 0;
        event.yIdx = element.spawnpoint.z | 0;
        return event;
    }

    /** 获取服务端需要的数据对象 */
    encode(): mainline.MainLineData {
        const _mainLineData = new mainline.MainLineData();
        // 英雄数据
        _mainLineData.hero = this.hero.encode();

        // 资源车数据
        let mainlinetrucks: mainline.Truck[] = _mainLineData.trucks;
        if (!mainlinetrucks) {
            mainlinetrucks = [];
            _mainLineData.trucks = mainlinetrucks;
        }
        if (mainlinetrucks.length > 0) {
            Pool.free(mainlinetrucks);
            mainlinetrucks = [];
            _mainLineData.trucks = mainlinetrucks;
        }
        for (let i = 0; i < this.trucks.length; i++) {
            const mainlinetruck = this.trucks[i].encode();
            mainlinetrucks.push(mainlinetruck);
        }

        // 士兵数据
        _mainLineData.soldierGroups = this.soldierGroups
            .map((v) => v.encode())
            .filter((v) => !!v) as mainline.SoldierGroup[];

        // 事件数据
        let mainlineevents: mainline.Event[] = _mainLineData.events;
        if (!mainlineevents) {
            mainlineevents = [];
            _mainLineData.events = mainlineevents;
        }
        if (mainlineevents.length > 0) {
            Pool.free(mainlineevents);
            mainlineevents = [];
            _mainLineData.events = mainlineevents;
        }
        for (let i = 0; i < this.events.length; i++) {
            const mainlineevent = this.events[i].encode();
            mainlineevents.push(mainlineevent);
        }

        // 怪物数据
        _mainLineData.monsterTroops = this.monsterTroops
            .map((v) => v.encode())
            .filter((v) => !!v) as mainline.MonsterTroop[];

        // 建筑数据
        _mainLineData.buildings = this.buildings
            .map((v) => v.encode())
            .filter((v) => !!v) as mainline.Building[];

        // Npc数据
        _mainLineData.npcs = this.npcs.map((v) => v.encode()).filter((v) => !!v) as mainline.Npc[];

        // 采集物数据
        _mainLineData.collections = this.collections
            .map((v) => v.encode())
            .filter((v) => !!v) as mainline.Collection[];

        // 击杀怪物或者Boss掉落的战利品
        _mainLineData.spoils = this.spoils
            .map((v) => v.encode())
            .filter((v) => !!v) as mainline.Spoils[];

        return _mainLineData;
    }

    getSoldier(soldierIdx: number) {
        const groupIdx = Math.floor(soldierIdx / BattleConf.PVE.SOLDIER_ONE_ROW_COUNT) + 1;
        const group = this.soldierGroups.find((v) => v.idx === groupIdx);
        return group?.soldiers.find((v) => v.idx === soldierIdx);
    }

    /** 添加一个士兵数据 */
    addSoldier(
        soldierIdx: number,
        tid: number,
        hp: number,
        maxHp: number,
        x: number,
        z: number,
        rot: number
    ) {
        const station: TroopStation =
            Math.floor(soldierIdx / BattleConf.PVE.SOLDIER_ONE_ROW_COUNT) + 1;

        let group = this.soldierGroups.find((v) => v.idx === station);
        if (!group) {
            group = new PveSvrSoldierGroupStateData();
            group.idx = station;
            group.tid = tid;
            this.soldierGroups.push(group);
        }
        group.addSoldier(soldierIdx, tid, hp, maxHp, x, z, rot);
    }

    /** 添加一个资源车数据 */
    addTruck(collectType: number, curAmount: number, x: number, z: number, rot: number): void {
        const data = new PveSvrTruckStateData();
        data.amount = curAmount;
        data.pos.x = x;
        data.pos.y = z;
        data.rotation = rot;
        data.collectType = collectType;
        this.trucks.push(data);
    }
}
