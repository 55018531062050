import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { Pool } from "../../../../../core/pool";
import { BattleConf } from "../../../../../def/auto/battle";
import { AnimName } from "../../../base/Animator";
import { BattlePveMapTransferItem, MapTransferData } from "../../PveDefs";
import { PveServer } from "../../PveServer";
import { PveSvrUtils } from "../../PveSvrUtils";
import { SkillSystemUtils } from "../../utils/skill/SkillSystemUtils";
import { PveSvrElementComponent } from "../components/PveSvrElementComponent";
import { MovementState, PveSvrMovementComponent } from "../components/PveSvrMovementComponent";
import { PveSvrTransformComponent } from "../components/PveSvrTransformComponent";
import { PveSvrGameDefeatSystem } from "./PveSvrGameDefeatSystem";
import { PveSvrStateSystem } from "./PveSvrStateSystem";

export class PveSvrMapTransferSystem extends ecs.System {
    declare context: PveServer;

    override update(dt: number): void {}

    public static async mapTransfer(pveServer: PveServer, data: BattlePveMapTransferItem) {
        const transferPos = app.service.pve.minimap.getTransferPos(data.cfg.id);
        if (!transferPos) {
            app.ui.toast("地图上未添加传送点:" + data.cfg.id);
            return;
        }

        const focusRole = pveServer.ctrlHeroEid;
        const heroElement = pveServer.ecs.getComponent(focusRole, PveSvrElementComponent)!;
        const movementComp = heroElement.getComponent(PveSvrMovementComponent)!;
        if (movementComp.state !== MovementState.STOP) {
            app.ui.toast("移动中不可传送");
            return;
        }

        await this.tpTransfer(pveServer, heroElement, transferPos);
    }

    public static async TPWaitTime(pveServer: PveServer, time: number) {
        await new Promise((resolve) => {
            pveServer.ecs.delay(time, "TPWaitTime", () => {
                resolve(1);
            });
        });
    }

    public static async tpTransfer(
        pveServer: PveServer,
        heroElement: PveSvrElementComponent,
        transferPos: MapTransferData
    ) {
        const yOffset = BattleConf.GROUND_OFFSET.OBJ_FLOOR;
        const transformComp = heroElement.getComponent(PveSvrTransformComponent)!;
        const x = transformComp.position.x;
        const z = transformComp.position.z;

        const tpDurTime = BattleConf.PVE.TP_TERRITORY_DURACTION_TIME;
        const overdueTime = pveServer.time + tpDurTime;
        // .回城过程中主角无敌（被打也不扣血），小兵可被攻击。
        SkillSystemUtils.setImmuneNegative(pveServer, heroElement.eid, overdueTime);
        // .回城过程中不可移动，不可释放技能，不可执行AI
        SkillSystemUtils.setImprisonment(pveServer, heroElement.eid, overdueTime);

        PveSvrUtils.launchVFXEffect(
            pveServer,
            heroElement.eid,
            80002,
            { x, y: yOffset, z },
            0,
            false,
            undefined,
            tpDurTime
        );
        PveSvrUtils.launchVFXEffect(
            pveServer,
            heroElement.eid,
            80003,
            { x, y: yOffset, z },
            0,
            false,
            undefined,
            tpDurTime
        );
        await PveSvrMapTransferSystem.TPWaitTime(pveServer, tpDurTime);
        PveSvrMapTransferSystem.transfer(pveServer, heroElement, transferPos);
        const toX = transferPos.x + 0.5;
        const toZ = transferPos.y + 0.5;
        PveSvrUtils.launchVFXEffect(
            pveServer,
            heroElement.eid,
            80004,
            { x: toX, y: yOffset, z: toZ },
            0,
            false,
            undefined,
            tpDurTime
        );
        PveSvrUtils.launchVFXEffect(
            pveServer,
            heroElement.eid,
            80005,
            { x: toX, y: yOffset, z: toZ },
            0,
            false,
            undefined,
            tpDurTime
        );
    }

    public static transfer(
        pveServer: PveServer,
        heroElement: PveSvrElementComponent,
        transferPos: MapTransferData
    ): void {
        const heroTransform = heroElement.getComponent(PveSvrTransformComponent)!;
        PveSvrStateSystem.flashHeroToPositionAndRotation(
            pveServer,
            transferPos.x,
            transferPos.y,
            transferPos.rot
        );
        heroTransform.position.x = transferPos.x;
        heroTransform.position.z = transferPos.y;
        heroTransform.rotation = transferPos.rot;

        const flashPosition = Pool.obtain(Laya.Vector3);
        flashPosition.cloneFrom(heroTransform.position);
        pveServer.sender.updateTransform(heroElement.eid, flashPosition, heroTransform.rotation);
        pveServer.sender.playAnim(heroElement.eid, AnimName.IDLE);
        PveSvrGameDefeatSystem.truckFlashToHeroPos(pveServer, heroElement);
        PveSvrGameDefeatSystem.soldierFlashToHeroPos(pveServer, heroElement);
    }
}
