import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";

type Input = [unknown[]];

type NodeArgs = {
    idx: number;
};

export class GetInputIndex extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const [inputs] = env.input as Input;
        if (!(inputs instanceof Array) || inputs.length === 0) {
            return "failure";
        }
        const idx = (node.args as NodeArgs).idx;
        if (idx < 0 || idx >= inputs.length) {
            return "failure";
        }
        env.output.push(inputs[idx]);

        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "GetInputIndex",
            type: "Action",
            desc: "返回输入的第N个数据! 从0数起，即第一个数据的索引为0",
            args: [{ name: "idx", type: "int", desc: "索引值" }],
            input: ["输入目标"],
            output: ["输出目标"],
            doc: `
                + 返回输入的第N个数据!, 输入数据必须是数组，否则返回失败
                + 索引数据过界也会返回失败
            `,
        };
    }
}
