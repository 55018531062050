const { regClass } = Laya;
import { app } from "../../../../../app";
import { PveContext } from "../../../pve/PveContext";
import { PveUIBDUpGradeComponent } from "../../../pve/ecs/components/PveUIBDUpGradeComponent";
import { BDUpgradeInfoUIBase } from "./BDUpgradeInfoUI.generated";

@regClass('NrRcGrXdR8ynoCBoXdU-4A')
export class BDUpgradeInfoUI extends BDUpgradeInfoUIBase {
    context: PveContext | undefined;

    comp: PveUIBDUpGradeComponent | undefined;

    /** 刷新进度显示 */
    public updateShow(): void {
        if (!this.comp || !this.context) return;
        const et = this.comp.upgradeEndTime;
        const st = this.comp.upgradeStartTime;
        // 总持续时间
        const duration = et - st;
        const currentTime = app.service.network.serverTime;
        // 经过的时间
        let passTime = currentTime - st;
        if (passTime > duration) {
            passTime = duration;
        }
        // 进度值
        let bi = passTime / duration;
        bi = Math.max(bi, 0);
        bi = Math.min(bi, 1);
        // this.progressBar.value = bi;

        const clockTime = this.context.time;
        this.clock.material.setFloat("u_TimeValue", clockTime);
        this.clock.material.setFloat("u_ProgressValue", bi);
    }
}
