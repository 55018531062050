/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/leader-skin/LeaderSkinSkillItem.lh
 */
export class LeaderSkinSkillItemBase extends Laya.Box {
    public boxOpen!: Laya.Box;
    public imgSkill!: Laya.Image;
    public labSkillLv!: Laya.Label;
    public boxClose!: Laya.Box;
    public imgLock!: Laya.Image;
    public imgAdd!: Laya.Image;
}

