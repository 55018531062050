import * as ecs from "../../../../../core/ecs";
import { BattleSide, BattleTroopState } from "../../../../../def/auto/battle";

/** 等待的部队 */
export class PnvnTroopComponent extends ecs.Component {
    /** 部队在世界中的 eid */
    worldEid: number = 0;

    rid: number = 0;
    aid: number = 0;
    name: string = "";
    side: BattleSide = BattleSide.LEFT;
    army: number = 0;

    state: BattleTroopState = BattleTroopState.NONE;
    stateTime: number = 0;

    // 位置信息
    curGrid: Laya.Vector3 = new Laya.Vector3();
    dstGrid: Laya.Vector3 = new Laya.Vector3();

    /** 正在交战的战斗id */
    battleUid: number = 0;

    members: (number | undefined)[] = [];
    // data!: proto.battle.TroopInfo;
}
