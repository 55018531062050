/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { IconUI } from "../../../misc/ui-runtime/IconUI";
import { UIInput } from "../../common/ui-runtime/UIInput";

/**
 * resources/prefab/bag/UIItemUseSelectItem.lh
 */
export class UIItemUseSelectItemBase extends Laya.Box {
    public imgBg!: Laya.Image;
    public itemIcon!: IconUI;
    public labName!: Laya.Label;
    public inputCom!: UIInput;
}

