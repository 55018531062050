import { app } from "../../../../app";
import { Mediator } from "../../../../core/ui-mediator";
import { SystemEvent } from "../../../../misc/system-event";
import { ui } from "../../../../misc/ui";
import { ActionMenuUI } from "../../ui-runtime/pvp/ActionMenuUI";
import { TroopPickerArgs } from "../../ui-runtime/pvp/TroopPickerUI";
import { PvpContext } from "../PvpContext";
import { PvpUIComponent } from "../ecs/components/PvpUIComponent";

@Laya.regClass('cRHVVeY0QxeQ11Elydnmcw')
export class PvpActionMenuMediator extends Mediator {
    declare owner: ActionMenuUI;

    private targetPos?: Laya.Vector3 | null;

    updatePos(ctx: PvpContext, currXZPos?: Laya.Vector3) {
        const pos = currXZPos ?? this.targetPos;
        if (!pos) {
            return;
        }
        const out = new Laya.Vector4();
        ctx.camera.worldToViewportPoint(pos, out);
        this.owner.pos(out.x - this.owner.width / 2, out.y - this.owner.height);
    }

    refresh(ctx: PvpContext, currXZPos: Laya.Vector3) {
        const uiComp = ctx.ecs.getSingletonComponent(PvpUIComponent);
        const hasResource = ctx.hasResouceAt(currXZPos);

        this.targetPos = currXZPos.clone();
        this.updatePos(ctx, currXZPos);

        this.owner.btnAttack.offAll();
        this.owner.btnAttack.onClick(() => {
            uiComp.resourceInfo?.removeSelf();
            this.owner.removeSelf();
            const args: TroopPickerArgs = {
                context: ctx,
                target: currXZPos,
                bAttack: !!hasResource,
            };
            app.ui.show(ui.PVP_TROOP_PICKER, args);
        });

        this.owner.btnMove.offAll();
        this.owner.btnMove.onClick(() => {
            uiComp.resourceInfo?.removeSelf();
            this.owner.removeSelf();
            const args: TroopPickerArgs = {
                context: ctx,
                target: currXZPos,
                bAttack: !!hasResource,
            };
            app.ui.show(ui.PVP_TROOP_PICKER, args);
        });

        if (ctx.hasEnemyAt(currXZPos) || hasResource) {
            this.owner.btnAttack.visible = true;
            this.owner.btnMove.visible = false;
        } else {
            this.owner.btnAttack.visible = false;
            this.owner.btnMove.visible = true;
        }

        if (hasResource) {
            app.event(SystemEvent.PVP.OPEN_RESOURCE_INFO, currXZPos);
        } else {
            uiComp.resourceInfo?.removeSelf();
        }
    }

    override onDisable(): void {
        this.owner.btnAttack.offAll();
        this.owner.btnMove.offAll();
        this.targetPos = null;
    }
}
