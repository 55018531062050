import { Mediator } from "../../../core/ui-mediator";
import { P1v1UI } from "../ui-runtime/P1v1UI";

//专门处理UI
@Laya.regClass('l_sT-bFVQ8y_SkqW28yw1g')
export class P1v1Mediator extends Mediator {
    declare owner: P1v1UI;

    override onAwake(): void {
        super.onAwake();

        this.owner.autoDestroyAtClosed = true;

        this.owner.on(Laya.Event.CLICK, () => {
            this.owner.close();
        });
    }
}
