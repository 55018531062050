import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { BattleConf } from "../../../../../def/auto/battle";
import { TroopStation } from "../../../../../def/auto/troop";
import { HeroLo } from "../../../../hero/vo/HeroLo";
import { PveServer } from "../../PveServer";
import { PveSvrAiComponent } from "./PveSvrAiComponent";
import { PveSvrElementComponent } from "./PveSvrElementComponent";
import { PveSvrSkillLauncherComponent } from "./PveSvrSkillLauncherComponent";
import { PveSvrTransformComponent } from "./PveSvrTransformComponent";
import { PveSvrTroopComponent } from "./PveSvrTroopComponent";

// 一排士兵的组件，专用，士兵释放技能以排为单位，所以士兵技能需要挂到这个组件上
export class PveSvrSoldierGroupComponent extends ecs.Component {
    static getSoldierEids(pveServer: PveServer, self: PveSvrSoldierGroupComponent) {
        const soldierEids: number[] = [];
        const troopComp = pveServer.ecs.getComponent(self.eleComp.troopEid, PveSvrTroopComponent)!;

        const station = self.station;
        for (
            let i = (station - 1) * BattleConf.PVE.SOLDIER_ONE_ROW_COUNT,
                max = station * BattleConf.PVE.SOLDIER_ONE_ROW_COUNT;
            i < max;
            i++
        ) {
            if (troopComp.members[i]) {
                soldierEids.push(troopComp.members[i]);
            }
        }
        return soldierEids;
    }

    override get dependencies() {
        return [
            PveSvrElementComponent,
            PveSvrTransformComponent,
            PveSvrSkillLauncherComponent,
            PveSvrAiComponent,
        ];
    }

    get eleComp() {
        return this.getComponent(PveSvrElementComponent)!;
    }

    get data(): HeroLo {
        const tid = this.eleComp.tid;
        return app.service.hero.heroLoMap.get(tid)!;
    }

    station: TroopStation | -1 = -1; // 所在阵位索引 1-3 分别表示前中后
}
