import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { BattleAid, PveEventType, PveProbingType } from "../../../../../def/auto/battle";
import { PveServer } from "../../PveServer";
import { PveSvrElementComponent } from "../components/PveSvrElementComponent";
import { PveSvrEventComponent } from "../components/PveSvrEventComponent";
import { PveSvrProbingComponent } from "../components/PveSvrProbingComponent";
import { PveSvrTransformComponent } from "../components/PveSvrTransformComponent";
import { PveSvrNpcSystem } from "./PveSvrNpcSystem";

const tempVector3A = new Laya.Vector3();
const tempVector3B = new Laya.Vector3();

/**
 * 探测系统
 * 负责探测进入和离开指定建筑
 */
export class PveSvrProbingSystem extends ecs.System {
    declare context: PveServer;

    override get interval() {
        /** 每0.3秒检查一次 */
        return 0.3;
    }

    override onCreate(): void {
        this.registerHandler(
            PveSvrEventComponent,
            this._onAddPveSvrEventComponent,
            this._onDelPveSvrEventComponent
        );
    }

    override update(dt: number): void {
        const heroEleComp = this.context.ecs.getComponent(
            this.context.ctrlHeroEid,
            PveSvrElementComponent
        );
        if (!heroEleComp) {
            return;
        }

        const focusRoleTF = heroEleComp.getComponent(PveSvrTransformComponent)!;
        const x = focusRoleTF.position.x;
        const z = focusRoleTF.position.z;

        tempVector3A.x = x;
        tempVector3A.y = 0;
        tempVector3A.z = z;

        this.ecs.getComponents(PveSvrProbingComponent).forEach((probingComp) => {
            const transformComp = probingComp.getComponent(PveSvrTransformComponent);
            if (!transformComp) {
                return;
            }
            let inRange = false;
            if (probingComp.isInTerritory) {
                // 在领域内算在范围内
                const inTerritory = app.service.pve.minimap.checkInTerritory(x, z);
                if (inTerritory) {
                    inRange = true;
                }
            } else {
                const tX = transformComp.position.x;
                const tZ = transformComp.position.z;
                tempVector3B.x = tX;
                tempVector3B.y = 0;
                tempVector3B.z = tZ;
                // 在范围内
                const dist = Laya.Vector3.distanceXZ(tempVector3A, tempVector3B);
                if (dist <= probingComp.probingRange) {
                    inRange = true;
                }
            }
            if (inRange !== probingComp.curInRange) {
                probingComp.curInRange = inRange;
                PveSvrProbingSystem.inRangeChange(this.context, probingComp, false);
            }
        });
    }

    /** 进出发生变化 */
    public static inRangeChange(
        pveServer: PveServer,
        probingComp: PveSvrProbingComponent,
        isHeroStartMove: boolean
    ): void {
        // 进出发生变化
        if (probingComp.probingType === PveProbingType.BUILDING) {
            pveServer.sender.onHeroEnterLeaveBuildingProbingRange(
                probingComp.eid,
                probingComp.curInRange
            );
        } else if (probingComp.probingType === PveProbingType.EVENT) {
            if (probingComp.curInRange) {
                pveServer.dispatchTrigger(probingComp.eid, "enter");
            } else {
                pveServer.dispatchTrigger(probingComp.eid, "leave");
            }
        } else if (probingComp.probingType === PveProbingType.NPC) {
            const eleComp = probingComp.getComponent(PveSvrElementComponent)!;
            const state = PveSvrNpcSystem.getNPCState(pveServer, eleComp.key, eleComp.tid);
            // NPC 相关
            pveServer.sender.onHeroEnterLeaveNpcProbingRange(
                probingComp.eid,
                probingComp.curInRange,
                state
            );
        }
    }

    private _onAddPveSvrEventComponent(event: PveSvrEventComponent) {
        const eventElement = event.getComponent(PveSvrElementComponent)!;
        const table = app.service.table;
        const eventVo = app.service.pve.eventLoMap.get(eventElement.tid)!;
        if (eventVo.eventType === PveEventType.RESCUE_SOLDIER) {
            const position = eventElement.spawnpoint;
            // 解救士兵的情况下，要加一个士兵在笼子里
            const soldierDatas = eventVo.soldierDatas!;
            const soldierTid = soldierDatas[0].soldier;
            const soldierRow = table.hero.hero[soldierTid];
            const soldierEntityRow = table.battleEntity[soldierRow.battle_entity];

            const entity = this.ecs.createEntity(this.context.obtainEid(), soldierEntityRow.etype);
            event.relatedEids.push(entity.eid);

            const element = entity.addComponent(PveSvrElementComponent);
            element.data = soldierEntityRow;
            element.tid = eventElement.tid;
            element.aid = BattleAid.NEUTRAL;
            element.spawnpoint.cloneFrom(position);

            const transform = entity.addComponent(PveSvrTransformComponent);
            transform.position.x = position.x;
            transform.position.z = position.z;

            this.context.sender.createElement({
                eid: element.eid,
                etype: element.etype,
                key: element.key,
                aid: element.aid,
                teid: element.data.id,
                tid: element.tid,
                position: transform.position,
                rotation: transform.rotation,
                level: -1,
            });
        }
    }

    private _onDelPveSvrEventComponent(event: PveSvrEventComponent) {
        event.relatedEids.forEach((eid) => {
            const element = this.ecs.getComponent(eid, PveSvrElementComponent);
            if (element) {
                this.context.removeElement(element);
            }
        });
        event.relatedEids.length = 0;
    }

    public static onHeroStartMove(pveServer: PveServer, heroEid: number): void {
        const heroEleComp = pveServer.ecs.getComponent(heroEid, PveSvrElementComponent);
        if (!heroEleComp) {
            return;
        }

        const focusRoleTF = heroEleComp.getComponent(PveSvrTransformComponent)!;
        const x = focusRoleTF.position.x;
        const z = focusRoleTF.position.z;

        pveServer.ecs.getComponents(PveSvrProbingComponent).forEach((probingComp) => {
            const transformComp = probingComp.getComponent(PveSvrTransformComponent);
            if (!transformComp) {
                return;
            }
            let inRange = false;
            if (probingComp.isInTerritory) {
                // 在领域内算在范围内
                const inTerritory = app.service.pve.minimap.checkInTerritory(x, z);
                if (inTerritory) {
                    inRange = true;
                }
            } else {
                const tX = transformComp.position.x;
                const tZ = transformComp.position.z;
                tempVector3B.x = tX;
                tempVector3B.y = 0;
                tempVector3B.z = tZ;
                // 在范围内
                const dist = Laya.Vector3.distanceXZ(tempVector3A, tempVector3B);
                if (dist <= probingComp.probingRange) {
                    inRange = true;
                }
            }
            if (inRange && inRange === probingComp.curInRange) {
                // 当前在范围内
                PveSvrProbingSystem.inRangeChange(pveServer, probingComp, true);
            }
        });
    }
}
