/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/common/input/UIInput.lh
 */
export class UIInputBase extends Laya.Box {
    public inputText!: Laya.TextInput;
    public minBtn!: Laya.Button;
    public addBtn!: Laya.Button;
}

