import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { SkillActiveUI } from "./ui-runtime/SkillActiveUI";

const { regClass, property } = Laya;

@regClass('jZHtTK3TR1eAlQaE_nPtRw') //技能激活弹窗
export class SkillActiveMediator extends Mediator {
    declare owner: SkillActiveUI;

    override onAwake(): void {
        super.onAwake();
        this.owner.btnActive.on(Laya.Event.CLICK, this, this.onClickAct);
        this.owner.imgClose.on(Laya.Event.CLICK, this.owner, this.owner.destroy);
        this.initPanel();
    }

    private onClickAct() {
        const data = this.owner.data;
        const actCost = data.active_cost;
        if (actCost) {
            const reward = actCost[0].id;
            const costCount = actCost[0].num;
            const owerCount = app.service.user.getMoneyCount(reward);
            if (owerCount >= costCount) {
                app.service.skill.requestActiveSkill(data.id);
                this.owner.destroy();
            } else {
                console.log("道具" + reward + "不足");
            }
        }
    }

    private initPanel() {
        const data = this.owner.data;
        this.owner.bgwin.setTitle("技能激活");
        this.owner.skillIcon.initIcon(data);

        const actCost = data.active_cost;
        if (actCost) {
            const reward = actCost[0];
            const costCount = actCost[0].num;
            const itemLo = app.service.bag.itemLoMap.get(reward.id);
            if (itemLo) {
                this.owner.icon.updateGoods(itemLo);
            }
            const owerCount = app.service.user.getMoneyCount(reward.id);
            const barValue = Math.min(1, owerCount / costCount);
            this.owner.ImageBar.value = barValue;
            this.owner.labBar.text = `${owerCount}/${costCount}`;
        }
    }
}
