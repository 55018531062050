/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { BaseBigWindowBg } from "../../common/ui-runtime/dialog/BaseBigWindowBg";

/**
 * resources/prefab/traincamp/UITrainCamp.lh
 */
export class UITrainCampBase extends Laya.Dialog {
    public bgwin!: BaseBigWindowBg;
    public scrollPanel!: Laya.Panel;
    public boxImgGroup!: Laya.Box;
    public imgAct!: Laya.Image;
    public boxNormal!: Laya.Box;
    public boxStrong!: Laya.Box;
    public btnclose!: Laya.Button;
    public icon1!: Laya.Image;
    public labIcon1!: Laya.Label;
    public icon2!: Laya.Image;
    public labIcon2!: Laya.Label;
    public boxMask!: Laya.Box;
    public boxTips!: Laya.Box;
    public txtFuncName!: Laya.Text;
    public txtFuncDesc!: Laya.Text;
    public boxIcon!: Laya.Box;
    public imgIcon!: Laya.Image;
    public labCount!: Laya.Text;
    public btnAct!: Laya.Button;
    public labCond!: Laya.Label;
    public labActed!: Laya.Label;
}
