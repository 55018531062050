import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { SystemEvent } from "../../../../../misc/system-event";
import { PnvnContext } from "../../PnvnContext";
import { PnvnCameraComponent } from "../components/PnvnCameraComponent";
import { PnvnTransformComponent } from "../components/PnvnTransformComponent";

const tmpRay: Laya.Ray = new Laya.Ray(new Laya.Vector3(), new Laya.Vector3());
const tmpRayMat: Laya.Matrix4x4 = new Laya.Matrix4x4();
const SMOOTH = true; //是否平滑移动

export class PnvnCameraSystem extends ecs.System {
    declare context: PnvnContext;

    override update(dt: number): void {
        const camera = this.ecs.getSingletonComponent(PnvnCameraComponent);

        let smooth = false;
        if (camera.focusEid) {
            const transform = this.ecs.getComponent(camera.focusEid, PnvnTransformComponent);
            if (transform) {
                camera.focus.cloneFrom(transform.position);
                smooth = true;
            }
        }
        if (
            smooth &&
            camera.curFocus &&
            camera.curFocus.x === camera.focus.x &&
            camera.curFocus.z === camera.focus.z
        ) {
            //不用移动
            return;
        }

        const cameraTransform = this.context.camera.transform;
        cameraTransform.position.cloneTo(tmpRay.origin);
        tmpRay.direction.set(0, 0, -1);
        Laya.Matrix4x4.createRotationQuaternion(cameraTransform.rotation, tmpRayMat);
        Laya.Vector3.transformCoordinate(tmpRay.direction, tmpRayMat, tmpRay.direction);
        tmpRay.direction.normalize();

        // 光从相机原点出发到地面所需时间
        const t = -tmpRay.origin.y / tmpRay.direction.y;
        tmpRay.direction.scale(t, tmpRay.direction);
        tmpRay.origin.vadd(tmpRay.direction, tmpRay.origin);
        if (SMOOTH && smooth) {
            if (!camera.curFocus) {
                camera.curFocus = new Laya.Vector3(
                    tmpRay.origin.x,
                    camera.focus.y,
                    tmpRay.origin.z
                );
            }
            const newX =
                Math.abs(camera.curFocus.x - camera.focus.x) > 0.0005
                    ? Laya.MathUtil.lerp(camera.curFocus.x, camera.focus.x, 0.1)
                    : camera.focus.x;
            const newZ =
                Math.abs(camera.curFocus.z - camera.focus.z) > 0.0005
                    ? Laya.MathUtil.lerp(camera.curFocus.z, camera.focus.z, 0.1)
                    : camera.focus.z;
            camera.curFocus.setValue(newX, camera.focus.y, newZ);
            camera.curFocus.vsub(tmpRay.origin, tmpRay.origin);
        } else {
            camera.focus.vsub(tmpRay.origin, tmpRay.origin);
            camera.curFocus = undefined;
        }
        tmpRay.origin.y = 0;

        tmpRay.origin.vadd(cameraTransform.position, tmpRay.origin);
        cameraTransform.position = tmpRay.origin;

        app.event(SystemEvent.PVP.CAMERA_UPDATE_POS);
    }
}
