import * as ecs from "../../../../../core/ecs";

export class RotationInterpolation {
    rotation: number = 0;
    percent: number = 1;
    rate: number = 1;
}

// 生命周期跟随Enity
export class PveTransformComponent extends ecs.Component {
    static readonly POSITION = 1 << 0;
    static readonly ROTATION = 1 << 1;
    static readonly ROTATION_QUAT = 1 << 2;

    private _flag: number = 0;

    get flag(): number {
        return this._flag;
    }

    set flag(value: number) {
        // ROTATION ROTATION_QUAT 互斥
        if (value & PveTransformComponent.ROTATION) {
            value &= ~PveTransformComponent.ROTATION_QUAT;
        } else if (value & PveTransformComponent.ROTATION_QUAT) {
            value &= ~PveTransformComponent.ROTATION;
        }
        this._flag = value;
    }

    readonly position: Laya.Vector3 = new Laya.Vector3();
    private _rotation: number = 0;

    get rotation() {
        return this._rotation;
    }

    set rotation(value: number) {
        this._rotation = ((value % 360) + 360) % 360; // 保证角度在0-360之间
    }

    readonly quaternion: Laya.Quaternion = new Laya.Quaternion();

    // 改变角度
    readonly rotationInterpolation: RotationInterpolation = new RotationInterpolation();
}
