/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { BaseRoundWindowBg } from "../../common/ui-runtime/dialog/BaseRoundWindowBg";
import { UIList } from "../../../core/ui/UIList";

/**
 * resources/prefab/login/UILoginServerList.lh
 */
export class UILoginServerListBase extends Laya.Dialog {
    public imgMask!: Laya.Image;
    public bgwin!: BaseRoundWindowBg;
    public listServerGroup!: UIList;
    public listServer!: UIList;
}

