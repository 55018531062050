const { regClass } = Laya;
import { app } from "../../../app";
import { BattleSkillRow } from "../../../def/table";
import { ImageRef } from "../../../misc/ImageRef";
import { SkillIconUIBase } from "./SkillIconUI.generated";

@regClass('31GHLQeNTLmoJdtrGVnf4A')
export class SkillIconUI extends SkillIconUIBase {
    public initIcon(skill: BattleSkillRow) {
        this.labName.text = skill.name;
        this.imgSkil.skin = app.service.skill.getSKillIcon(skill.id);
        const quality = skill.quality || 1;
        this.imgQuality.width = this.labName.text.length * 36 + 58;
        const qualityColorList = this.imgQuality.getComponent(ImageRef)!.texturePaths;
        this.imgQuality.skin = qualityColorList[quality - 1];
    }
}
