const { regClass } = Laya;
import { ItemVo } from "../vo/ItemVo";
import { UIItemUseBase } from "./UIItemUse.generated";

@regClass('FL76iE2hTf-0DZ6LhuWkfA')
export class UIItemUse extends UIItemUseBase {
    public itemData!: ItemVo;

    override open(closeOther?: boolean | undefined, param?: ItemVo): void {
        this.itemData = param!;
        super.open(closeOther, param);
    }
}
