import { app } from "../../../app";
import { ChestScoreRow } from "../../../def/table";
import { Vo } from "../../../misc/vo/Vo";
import { ChestBoxVo } from "./ChestBoxVo";

export abstract class ChestScoreVoBase<D> extends Vo<ChestScoreRow, D> {}

export class ChestScoreVo extends ChestScoreVoBase<never> {
    override clone(): Vo<ChestScoreRow, never> {
        return new ChestScoreVo(this._config);
    }

    override get key(): number {
        return this._config.id;
    }

    /** 是否是当前积分奖励 */
    public get isCurrent(): boolean {
        const scoreInfo = app.service.chest.scoreInfo;
        if (scoreInfo) {
            if (scoreInfo.scoreId === this.key) {
                return true;
            }
        }
        return false;
    }

    public get currentScore(): number {
        const scoreInfo = app.service.chest.scoreInfo;
        if (scoreInfo) {
            if (scoreInfo.scoreId === this.key) {
                return scoreInfo.score ?? 0;
            }
        }
        return 0;
    }

    public get needScore(): number {
        return this._config.reward_score;
    }

    public get isEnough(): boolean {
        if (this.currentScore >= this.needScore) {
            return true;
        }
        return false;
    }

    public get rewardVo(): ChestBoxVo {
        const vo = app.service.chest.chestBoxVoMap.get(this._config.chest_id)!;
        return vo;
    }
}
