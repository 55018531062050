/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIList } from "../../../core/ui/UIList";

/**
 * scenes/creator-role.ls
 */
export class CreatorRoleUIBase extends Laya.Scene {
    public btnCreatorRole!: Laya.Button;
    public textInputName!: Laya.TextInput;
    public btnRandomName!: Laya.Button;
    public imgHero!: Laya.Image;
    public btnWoman!: Laya.Button;
    public imgSelectedWoman!: Laya.Image;
    public btnMan!: Laya.Button;
    public imgSelectedMan!: Laya.Image;
    public listJob!: UIList;
    public labelJobName!: Laya.Label;
    public labelJobDesc!: Laya.Label;
}

