import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";

export class SetStance extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        env.context.setStance(env.owner);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "SetStance",
            type: "Action",
            desc: "设置攻击占位",
        };
    }
}
