import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { ui } from "../../misc/ui";
import { ChatService } from "../chat/ChatService";
import { HomeChatBox } from "./ui-runtime/HomeChatBox";

const { regClass, property } = Laya;

@regClass('F5kSR9cLQKKqxYgQWJ9Fng')
export class HomeChatMediator extends Mediator {
    //组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
    declare owner: HomeChatBox;

    override onStart(): void {
        this.initEvent();
        this.updateInfo();
    }

    initEvent() {
        this.owner.btn.on(Laya.Event.CLICK, () => {
            app.ui.show(ui.CHAT);
        });

        this.$(app).on(ChatService.CHAT_UPDATE, (oldIndex: number, nextIndex: number) => {
            this.addMsg(oldIndex, nextIndex);
        });
    }

    updateInfo() {
        const chatMsgs = [];
        const msgs = app.service.chat.chatMsgVoMap.toArray().reverse();
        for (const msg of msgs) {
            if (chatMsgs.length >= 2) {
                break;
            }
            chatMsgs.push({ msg: msg, role: app.service.chat.chatRoleVoMap.get(msg.rid) });
        }
        this.owner.virtuallyList.setArrayData(chatMsgs);
    }

    addMsg(oldIndex: number, nextIndex: number) {
        for (let i = oldIndex; i < nextIndex; i++) {
            const msg = app.service.chat.chatMsgVoMap.get(i);
            if (!msg) {
                console.warn("msg is null", i);
                continue;
            }
            this.owner.virtuallyList.addData(
                {
                    msg: msg,
                    role: app.service.chat.chatRoleVoMap.get(msg.rid),
                },
                i === nextIndex - 1
            );
        }
        this.owner.virtuallyList.scrollDown();
    }
}
