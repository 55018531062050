const { regClass } = Laya;
import { PvpContext } from "../../pvp/PvpContext";
import { TroopPickerUIBase } from "./TroopPickerUI.generated";

export interface TroopPickerArgs {
    context: PvpContext;
    target: Laya.Vector3;
    bAttack: boolean;
}

@regClass('NGMhNj-GSKWW4LAAIzvpqw')
export class TroopPickerUI extends TroopPickerUIBase {
    args!: TroopPickerArgs;

    override open(closeOther?: boolean | undefined, param?: TroopPickerArgs) {
        this.args = param!;
        super.open(closeOther, param);

        this.btnAutoBackHome.active = this.btnAutoBackHome.visible = this.args.bAttack;
    }
}
