import { Callback, Dispatcher, toEventType } from "./dispatcher";

export abstract class Service {
    protected _manager: Dispatcher;

    constructor(manager: Dispatcher) {
        this._manager = manager;
    }

    abstract onCreate(): void;

    abstract onStartInit(): void;

    abstract onDestroy(): void;

    protected handle(type: string | number, callback: Callback) {
        this._manager.on(toEventType(type), this, callback);
    }
}
