import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { PveSvrBuffComponent } from "../../ecs/components/PveSvrBuffComponent";
import { BuffTreeEnv } from "../../ecs/data/buff/BuffBase";
import { BuffSpecialData, BuffSpecialType } from "../../ecs/data/buff/special/BuffSpecialData";

type NodeInput = [number, number?];

type NodeArgs = {
    readonly type: BuffSpecialType;
    readonly value?: number;
};

const buffTypeOptions = [{ name: "扣血上限", value: BuffSpecialType.LimitHurt }];

export class AddBuffSpecial extends Process {
    override run(node: Node, env: BuffTreeEnv): Status {
        const buffComp = env.owner.getComponent(PveSvrBuffComponent)!;
        if (!buffComp) {
            console.error(node.tree.name, "AddBuffAttribute: cannot find PveSvrBuffComponent");
            return "failure";
        }

        const args = node.args as NodeArgs;
        const value = env.buffTree.buffArgs[0] ?? args.value ?? 0;
        const type = args.type;
        const buffId = env.buffTree.data.id;
        if (value === 0) {
            console.error(node.tree.name, "AddBuffFunctional: value is 0");
            return "failure";
        }
        const overdueTime = env.buffTree.overdueTime;
        const buff = new BuffSpecialData(env.buffTree.id, type, value, overdueTime);
        buffComp.addSpecialBuff(buffId, buff);

        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "AddBuffSpecial",
            type: "Action(Buff)" as "Action",
            desc: "添加特殊buff，只能在Buff行为树里使用",
            args: [
                { name: "type", type: "enum", desc: "Buff类型", options: buffTypeOptions },
                { name: "value", type: "float?", desc: "数值，优先从env中读取" },
            ],
            doc: `
                + 如果输入目标为空，则默认为行为树当前归属的实体`,
        };
    }
}
