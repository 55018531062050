import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { Pool } from "../../../../../core/pool";
import { TweenUtil } from "../../../../../core/utils/tween-util";
import { BattleEntityType } from "../../../../../def/auto/battle";
import { SystemEvent } from "../../../../../misc/system-event";
import { UseHomeUIInPve } from "../../../../home/UseHomeUIInPve";
import { PveDef, TruckFormation } from "../../../pve-server/PveDefs";
import { PveContext } from "../../PveContext";
import { PveElementComponent } from "../components/PveElementComponent";
import { PvePickUpChestComponent } from "../components/PvePickUpChestComponent";
import { PveTruckCollectComponent } from "../components/PveTruckCollectComponent";
import { PveTruckComponent } from "../components/PveTruckComponent";
import { PveUITruckHudComponent } from "../components/PveUITruckHudComponent";
import { PveGameObjectFlySystem } from "./PveGameObjectFlySystem";

export class PveCollectSystem extends ecs.System {
    declare context: PveContext;

    override onCreate() {
        super.onCreate();
        this.context
            .$(app)
            .on(SystemEvent.PVE.ON_ANIMATION_LOADED, this.onPVE_LOAD_ANIMATION_COMP, this);

        this.registerHandler(
            PveTruckCollectComponent,
            this._onAddPveTruckCollectComponent,
            this._onDelPveTruckCollectComponent
        );
        this.registerHandler(
            PvePickUpChestComponent,
            this._onAddPvePickUpChestComponent,
            this._onDelPvePickUpChestComponent
        );
    }

    override update(dt: number): void {
        this.ecs.getComponents(PveTruckCollectComponent).forEach((component) => {
            const data = component.flyingCollect;
            if (!data) {
                return;
            }
            if (data.obj.destroyed || data.pointParent.destroyed || data.gatherParent.destroyed) {
                this.ecs.removeEntity(component.eid);
                return;
            }
            const objPos = data.obj.transform.position;
            const targetPos = data.pointParent.transform.position;
            targetPos.y = (1 + data.finalPos.y) * 0.2;

            const totalDis = Laya.Vector3.distanceXZ(objPos, targetPos);
            const gapDis = data.speed * dt;

            if (gapDis < totalDis) {
                Laya.Vector3.lerp(objPos, targetPos, gapDis / totalDis, objPos);
                data.obj.transform.position = objPos;
                data.speed += 0.2;
            } else {
                data.obj.transform.localPosition = data.finalPos;
                data.obj.transform.localRotationEuler = Laya.Vector3.ZERO;
                data.obj.transform.localScale = Laya.Vector3.ONE;
                data.obj.removeSelf();
                data.gatherParent.addChild(data.obj);

                this.ecs.removeEntity(component.eid);

                const truckHeadInfoComp = this.ecs.getComponent(
                    component.truck,
                    PveUITruckHudComponent
                );
                const truckComp = this.ecs.getComponent(component.truck, PveTruckComponent);
                if (truckComp && truckHeadInfoComp && truckHeadInfoComp.view) {
                    truckHeadInfoComp.view.updateWithCollectCnt(truckComp.collectCnt);
                }
            }
        });
    }

    private async _onAddPveTruckCollectComponent(component: PveTruckCollectComponent) {
        const truck = this.ecs.getComponent(component.truck, PveElementComponent);
        if (!truck) {
            return;
        }
        const truckComp = truck.getComponent(PveTruckComponent);
        if (!truckComp) {
            return;
        }
        const view = truck.animation.view;
        const pointParent = view?.getChildByName("point") as Laya.Sprite3D;
        if (!pointParent) {
            return;
        }
        const gatherParent = view?.getChildByPath("anim/gather") as Laya.Sprite3D;
        if (!gatherParent) {
            return;
        }

        let path!: string;
        let formation!: Laya.Vector3[];
        let headHeight: number = 0;
        if (truckComp.collectType === BattleEntityType.WOOD) {
            path = "anim/gather-wood";
            formation = TruckFormation.WOOD;
            headHeight = 1.8;
        } else if (truckComp.collectType === BattleEntityType.FOOD) {
            path = "anim/gather-food";
            formation = TruckFormation.FOOD;
            headHeight = 0.6;
        } else if (truckComp.collectType === BattleEntityType.STONE) {
            path = "anim/gather-stone";
            formation = TruckFormation.STONE;
            headHeight = 1.0;
        }
        if (!path || !formation) {
            return;
        }

        const obj = (view?.getChildByPath(path) as Laya.Sprite3D).clone()!;
        obj.active = true;
        obj.transform.localRotationEulerY = Math.random() * 360;
        obj.transform.localScale = new Laya.Vector3(0.25, 0.25, 0.25);
        this.context.scene3D.addChild(obj);

        const idx = truckComp.collectObjs.length;
        truckComp.collectObjs.push(obj);

        const collection = this.ecs.getComponent(component.data.collection, PveElementComponent)!;
        const buildingRow = app.service.table.battleBuilding[collection.tid];
        const startPos = collection!.transform.position.clone();
        startPos.y = headHeight;
        obj.transform.position = startPos;

        const finalPos = formation[idx % formation.length].clone();
        const offsetY = Math.floor(idx / formation.length) * 0.35;
        finalPos.y += offsetY;

        const radius = Math.random() + 0.5;
        const rad = Math.random() * Math.PI * 2;
        Laya.Tween.to(
            obj.transform,
            {
                localPositionX: startPos.x + radius * Math.sin(rad),
                localPositionZ: startPos.z + radius * Math.cos(rad),
                localRotationEulerY: obj.transform.localRotationEulerY + 90,
            },
            500,
            Laya.Ease.cubicOut,
            Laya.Handler.create(component, () => {
                if (obj.destroyed || pointParent.destroyed) {
                    return;
                }
                component.flyingCollect = {
                    idx: idx,
                    obj: obj,
                    pointParent: pointParent,
                    gatherParent: gatherParent,
                    finalPos: finalPos,
                    speed: 1,
                };
            })
        );

        obj.name = idx + "_" + finalPos.x + "_" + finalPos.y + "_" + finalPos.z;
    }

    private _onDelPveTruckCollectComponent(component: PveTruckCollectComponent) {
        // TODO: remove view?
    }

    private async _onAddPvePickUpChestComponent(component: PvePickUpChestComponent) {
        const checker = component.checker;
        const com = this.context.owner.getComponent(UseHomeUIInPve)!;
        const screenPos = new Laya.Vector4();
        this.context.camera.worldToViewportPoint(component.starPos, screenPos);
        const sprite = com.homeScene.addChild(new Laya.Image(component.chestSkin));
        sprite.scale(0.6, 0.6);
        sprite.x = screenPos.x;
        sprite.y = screenPos.y;
        const point = com.chestBtn.localToGlobal(new Laya.Point(0, 0), false);
        TweenUtil.toBezier2(
            sprite,
            950,
            [
                new Laya.Point(sprite.x - 50, sprite.y - 100),
                new Laya.Point(sprite.x + 100, sprite.y - 600),
                new Laya.Point(point.x, point.y),
            ],
            () => {
                sprite.destroy();
                if (checker()) {
                    this.ecs.removeEntity(component.eid);
                }
            },
            Laya.Ease.sineIn
        );
    }

    private _onDelPvePickUpChestComponent(component: PvePickUpChestComponent) {}

    delCollectObj(eid: number, costToPosition?: Laya.Vector3) {
        const truck = this.ecs.getComponent(eid, PveElementComponent);
        if (!truck) {
            return;
        }
        const truckComp = truck.getComponent(PveTruckComponent);
        if (!truckComp) {
            return;
        }
        const obj = truckComp.collectObjs.pop();
        if (!costToPosition) {
            obj?.destroy();
        } else {
            // 飞到目标位置再移除
            const flySystem = this.context.ecs.getSystem(PveGameObjectFlySystem);
            if (obj && flySystem) {
                const startPoint: Laya.Vector3 = Pool.obtain(Laya.Vector3);
                startPoint.cloneFrom(obj.transform.position);
                const endPoint: Laya.Vector3 = Pool.obtain(Laya.Vector3);
                endPoint.cloneFrom(costToPosition);

                const endRandOffset = 0.1;
                const endRandOffsetX = Math.random() * endRandOffset - 0.5 * endRandOffset;
                const endRandOffsetY = Math.random() * endRandOffset;
                const endRandOffsetZ = Math.random() * endRandOffset - 0.5 * endRandOffset;
                endPoint.x += endRandOffsetX;
                endPoint.y += endRandOffsetY;
                endPoint.z += endRandOffsetZ;
                const delay = Math.random() * 0.5;
                flySystem!.createGameObjectFly(
                    obj,
                    startPoint,
                    endPoint,
                    5,
                    delay,
                    Laya.Handler.create(this, () => {
                        obj.destroy();
                    })
                );
            }
        }
    }

    private onPVE_LOAD_ANIMATION_COMP(eid: number): void {
        const truckComp = this.ecs.getComponent(eid, PveTruckComponent);
        if (!truckComp) return;

        const count = truckComp.collectCnt;
        const collectType = truckComp.collectType;
        if (count <= 0) return;

        const tarObjCnt = Math.floor(count / PveDef.COLLECT_CNT_PER_OBJ);
        for (let i = 0; i < tarObjCnt; i++) {
            const truck = truckComp.getComponent(PveElementComponent)!;
            const view = truck.animation.view!;
            const pointParent = view.getChildByName("point") as Laya.Sprite3D;
            if (!pointParent) {
                return;
            }
            const gatherParent = view.getChildByPath("anim/gather") as Laya.Sprite3D;
            if (!gatherParent) {
                return;
            }

            let path!: string;
            let formation!: Laya.Vector3[];
            if (collectType === BattleEntityType.WOOD) {
                path = "anim/gather-wood";
                formation = TruckFormation.WOOD;
            } else if (collectType === BattleEntityType.FOOD) {
                path = "anim/gather-food";
                formation = TruckFormation.FOOD;
            } else if (collectType === BattleEntityType.STONE) {
                path = "anim/gather-stone";
                formation = TruckFormation.STONE;
            }
            if (!path || !formation) {
                return;
            }

            const obj = (view?.getChildByPath(path) as Laya.Sprite3D).clone()!;
            obj.active = true;
            obj.transform.localRotationEuler = Laya.Vector3.ZERO;
            obj.transform.localScale = Laya.Vector3.ONE;

            const idx = truckComp.collectObjs.length;
            truckComp.collectObjs.push(obj);

            const finalPos = formation[idx % formation.length].clone();
            const offsetY = Math.floor(idx / formation.length) * 0.35;
            finalPos.y += offsetY;
            obj.transform.position = finalPos;

            gatherParent.addChild(obj);
        }
    }
}
