import { app } from "../../../../../app";
import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { BattleSkillRow } from "../../../../../def/table";
import { TableUtil } from "../../../../table/TableUtil";
import { SkillTreeEnv } from "../../ecs/components/PveSvrSkillLauncherComponent";

type NodeInput = [number?];

type NodeArgs = {
    readonly skill_id?: number;
};

export class GetSkillRadius extends Process {
    override run(node: Node, env: SkillTreeEnv): Status {
        const args = node.args as NodeArgs;
        let skillRow: BattleSkillRow | undefined = undefined;
        if (args.skill_id) {
            skillRow = TableUtil.getRow(app.service.table.battleSkill.skill, {
                id: args.skill_id,
            });
            if (!skillRow) {
                node.warn(`skill not found: ${args.skill_id}`);
                return "failure";
            }
        } else {
            skillRow = env.skill.data;
        }
        const radius = skillRow.radius ?? 0;
        env.output.push(radius);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "GetSkillRadius",
            type: "Action(Skill)" as "Action",
            desc: "获取技能攻击范围，只能在技能行为树里使用",
            args: [
                {
                    name: "skill_id",
                    type: "int?",
                    desc: "技能id，不传表示获取当前技能id",
                },
            ],
            output: ["攻击范围"],
            doc: `
                + 如果输入变量为空，则默认为行为树当前归属的实体`,
        };
    }
}
