/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

/**
 * resources/prefab/misc/alert.lh
 */
export class AlertUIBase extends Laya.Dialog {
    title!: Laya.Label;
    content!: Laya.Label;
    btnOK!: Laya.Button;
    btnNO!: Laya.Button;
}
