/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIList } from "../../../core/ui/UIList";

/**
 * resources/prefab/chest-box/UIChestBoxChooseHero.lh
 */
export class UIChestBoxChooseHeroBase extends Laya.Dialog {
    public itemList!: UIList;
}
