import proto from "../../../def/auto/proto";
import { Vo } from "../../../misc/vo/Vo";

export class LeaderSkinVo extends Vo<never, proto.leader_skin.LeaderSkin> {
    protected declare _data: Readonly<proto.leader_skin.LeaderSkin>;

    override get key(): number {
        return this._data.id;
    }

    constructor(data: proto.leader_skin.LeaderSkin) {
        super(undefined!, data);
    }

    override clone(): LeaderSkinVo {
        return new LeaderSkinVo(this._data);
    }

    public get id(): number {
        return this._data.id;
    }

    public get lv(): number {
        return this._data.lv;
    }

    public get hp(): number {
        return this._data.hp;
    }

    public get skills(): { [key: string]: proto.leader_skin.SkillInfo } {
        return this._data.skills;
    }
}
