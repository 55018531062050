/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/map-transfer/map-transfer-item.lh
 */
export class MapTransferItemUIBase extends Laya.Box {
    public img01!: Laya.Image;
    public text_name!: Laya.Label;
    public img_zhan!: Laya.Image;
    public text_zhan!: Laya.Label;
    public img_black!: Laya.Image;
    public img_lock!: Laya.Image;
    public img_curpos!: Laya.Image;
}

