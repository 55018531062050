import * as ecs from "../../../../../core/ecs";
import { AnimName } from "../../../base/Animator";
import { PnvnContext } from "../../PnvnContext";
import { PnvnMovementComponent } from "../components/PnvnMovementComponent";
import { PnvnTransformComponent } from "../components/PnvnTransformComponent";

export class PnvnMovementSystem extends ecs.System {
    declare context: PnvnContext;

    override onCreate() {}

    override update(dt: number) {
        this.ecs.getComponents(PnvnMovementComponent).forEach((movement) => {
            if (movement.speed <= 0) {
                return;
            }

            const transform = movement.getComponent(PnvnTransformComponent)!;
            const position = transform.position;
            const target = movement.target;
            const totalDis = Laya.Vector3.distance(position, target);
            const dtDis = movement.speed * dt;
            let dx: number = 0;
            let dz: number = 0;
            if (dtDis < totalDis) {
                const steplen = dtDis / totalDis;
                dx = (target.x - position.x) * steplen;
                dz = (target.z - position.z) * steplen;
            } else {
                dx = target.x - position.x;
                dz = target.z - position.z;
                movement.speed = 0;

                if (movement.targetRotation !== undefined) {
                    transform.rotation = movement.targetRotation;
                    transform.flags |= PnvnTransformComponent.ROTATION;
                    movement.targetRotation = undefined;
                }

                this.context.playAnim(movement.eid, AnimName.IDLE);
            }
            position.x += dx;
            position.z += dz;
            transform.flags |= PnvnTransformComponent.POSITION;
        });
    }
}
