/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIButton } from "../../../core/ui/UIButton";

/**
 * resources/prefab/alliance/UIAllianceContactLeader.lh
 */
export class UIAllianceContactLeaderBase extends Laya.Dialog {
    public btnSend!: UIButton;
    public content!: Laya.TextInput;
    public limit!: Laya.Label;
}

