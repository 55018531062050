/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/chest-box/sub/ChestBoxLeftUpBtn.lh
 */
export class ChestBoxLeftUpBtnBase extends Laya.Box {
    public img_icon!: Laya.Image;
    public lab_amount!: Laya.Label;
}

