import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { TimeFormatUtils } from "../../core/utils/time-format-util";
import proto from "../../def/auto/proto";
import { RecruitConf } from "../../def/auto/recruit";
import { ItemRow } from "../../def/table";
import { SystemEvent } from "../../misc/system-event";
import { ui } from "../../misc/ui";
import { TableUtil } from "../table/TableUtil";
import { DrawCardBtnItem } from "./ui-runtime/DrawCardBtnItem";
import { UIDrawCard } from "./ui-runtime/UIDrawCard";
import { UIDrawCardBuyArgs } from "./ui-runtime/UIDrawCardBuy";
import { UIDrawCardGainHeroArgs } from "./ui-runtime/UIDrawCardGainReward";
import { DrawCardLo } from "./vo/DrawCardLo";

const { regClass, property } = Laya;

@regClass('rovtgZQoQvKktX_UCZdtWg')
export class UIDrawCardMediator extends Mediator {
    //declare owner : Laya.Sprite3D;
    declare owner: UIDrawCard;
    @property(String)
    public text: string = "";

    //组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
    override onAwake(): void {
        super.onAwake();
        this.initUIEvent();
        this.initServiceEvent();
        this.initPanel();
    }

    private initUIEvent() {
        this.owner.btnClose.on(Laya.Event.CLICK, this.owner, this.owner.destroy);
        this.owner.boxCheck.on(Laya.Event.CLICK, this, this.onClickCheck);
        this.owner.boxPreview.on(Laya.Event.CLICK, this, this.onClickPreview);
        this.owner.boxReward.on(Laya.Event.CLICK, this, this.onClickReward);
        this.owner.imgTips.on(Laya.Event.CLICK, this, this.onClickTips);
        this.owner.boxMask.on(Laya.Event.CLICK, this, this.onClickMask);
        this.owner.listPool.renderHandler = new Laya.Handler(this, this.onPoolRender);
        this.owner.listPool.selectHandler = new Laya.Handler(this, this.onPoolSelRender);
        this.owner.btnOne.on(Laya.Event.CLICK, this, this.onClickOne);
        this.owner.btnTen.on(Laya.Event.CLICK, this, this.onClickTen);
        this.schedule(1, "drawCardLoop", this.onLoop, this);
    }

    private onLoop() {
        const index = this.owner.listPool.selectedIndex;
        const loData = this.owner.listPool.array[index] as DrawCardLo;
        const showText = loData.showFreeText;
        this.owner.labFreeTime.text = showText;
        const isShowFree = loData.checkIsShowFree();
        this.owner.btnOne.label = isShowFree ? "免费" : "抽一次";
        this.owner.boxOne.visible = !isShowFree;

        const box = this.owner.listPool.getChildAt(0);
        for (let i = 0; i < box.numChildren; i++) {
            const item = box.getChildAt(i) as DrawCardBtnItem;
            if (item.dataSource) {
                const lo = item.dataSource as DrawCardLo;
                const domwTime = lo.poolDownTimeStr;
                if (domwTime) {
                    item.labTime.text = domwTime;
                } else {
                    this.updatePoolList();
                }
            }
        }
    }

    private onClickOne() {
        const loData = this.owner.listPool.array[this.owner.listPool.selectedIndex] as DrawCardLo;
        const times = loData.reMainTime;
        if (times <= 0) {
            app.ui.toast("今日剩余招募次数已用完");
            return;
        }
        const isShowFree = loData.checkIsShowFree();
        if (isShowFree) {
            app.service.drawCard.requestRecruit(loData.id, RecruitConf.RECRUIT_TYPE.ONE);
        } else {
            const ticket = app.service.table.recruit.conf.RECRUIT_TICKET;
            const ownCount = app.service.bag.itemVoMap.getItemAmount(ticket);
            if (ownCount > 0) {
                app.service.drawCard.requestRecruit(loData.id, RecruitConf.RECRUIT_TYPE.ONE);
            } else {
                const username = Laya.LocalStorage.getItem("login:username");
                const dayisNoTipsKey = username + "DrayCardBuy";
                const serverTime = app.service.network.serverTime;
                const storageTime = parseInt(Laya.LocalStorage.getItem(dayisNoTipsKey));
                if (
                    !storageTime ||
                    TimeFormatUtils.timeFormatYYMMDD(serverTime) !==
                        TimeFormatUtils.timeFormatYYMMDD(storageTime)
                ) {
                    const buyCount = 1;
                    const costItemList = app.service.table.recruit.conf.TICKET_PRICE[0];
                    const costId = costItemList.id;
                    const costCount = costItemList.num;

                    const buyConfig = TableUtil.getRow<ItemRow>(app.service.table.item.item, {
                        id: ticket,
                    })!;

                    const costConfig = TableUtil.getRow<ItemRow>(app.service.table.item.item, {
                        id: costId,
                    })!;

                    app.ui.show(ui.DRAW_CARD_BUY, {
                        costId: costId,
                        contentVars: {
                            count: buyCount,
                            buyName: buyConfig.name,
                            cost: costCount * buyCount,
                            costName: costConfig.name,
                        },
                        contentStr:
                            "你缺少[color=#F2211C]{count=5}[/color]个{buyName=招募券}！是否要花费{cost=999999}{costName=招募券}购买并使用？",
                        dayisNoTipsKey: dayisNoTipsKey,
                        buyCallBack: this.buyOneCallBack,
                    } as UIDrawCardBuyArgs);
                } else {
                    this.buyOneCallBack();
                }
                app.service.drawCard.clickDrawCardType = RecruitConf.RECRUIT_TYPE.ONE;
            }
        }
    }

    private buyOneCallBack() {
        const costItemList = app.service.table.recruit.conf.TICKET_PRICE[0];
        const costId = costItemList.id;
        const costCount = costItemList.num;
        const buyCount = 1;
        const needCostCount = costCount * buyCount;
        const ownCostCount = app.service.bag.itemVoMap.getItemAmount(costId);
        if (ownCostCount >= needCostCount) {
            app.service.drawCard.requestBuyTicket(buyCount);
        } else {
            app.ui.toast("道具不足");
        }
    }

    private onClickTen() {
        const loData = this.owner.listPool.array[this.owner.listPool.selectedIndex] as DrawCardLo;
        const times = loData.reMainTime;
        if (times <= 0) {
            app.ui.toast("今日剩余招募次数已用完");
            return;
        }
        const type = RecruitConf.RECRUIT_TYPE.TEN;
        const ticket = app.service.table.recruit.conf.RECRUIT_TICKET;
        const ownCount = app.service.bag.itemVoMap.getItemAmount(ticket);
        if (ownCount >= 10) {
            app.service.drawCard.requestRecruit(loData.id, type);
        } else {
            const dayisNoTipsKey = "DrayCardBuy";
            const serverTime = app.service.network.serverTime;
            const storageTime = parseInt(Laya.LocalStorage.getItem(dayisNoTipsKey));
            if (
                !storageTime ||
                TimeFormatUtils.timeFormatYYMMDD(serverTime) !==
                    TimeFormatUtils.timeFormatYYMMDD(storageTime)
            ) {
                const buyCount = 10 - ownCount;
                const costItemList = app.service.table.recruit.conf.TICKET_PRICE[0];
                const costId = costItemList.id;
                const costCount = costItemList.num;

                const buyConfig = TableUtil.getRow<ItemRow>(app.service.table.item.item, {
                    id: ticket,
                })!;

                const costConfig = TableUtil.getRow<ItemRow>(app.service.table.item.item, {
                    id: costId,
                })!;
                app.ui.show(ui.DRAW_CARD_BUY, {
                    costId: costId,
                    contentVars: {
                        count: buyCount,
                        buyName: buyConfig.name,
                        cost: costCount * buyCount,
                        costName: costConfig.name,
                    },
                    contentStr:
                        "你缺少[color=#F2211C]{count=5}[/color]个{buyName=招募券}！是否要花费{cost=999999}{costName=招募券}购买并使用？",
                    dayisNoTipsKey: dayisNoTipsKey,
                    buyCallBack: this.buyCallBack,
                } as UIDrawCardBuyArgs);
            } else {
                this.buyCallBack();
            }
            app.service.drawCard.clickDrawCardType = RecruitConf.RECRUIT_TYPE.TEN;
        }
    }

    private buyCallBack(): void {
        const costItemList = app.service.table.recruit.conf.TICKET_PRICE[0];
        const costId = costItemList.id;
        const costCount = costItemList.num;
        const buyItemId = app.service.table.recruit.conf.RECRUIT_TICKET;
        const ownCount = app.service.bag.itemVoMap.getItemAmount(buyItemId);
        const buyCount = 10 - ownCount;
        const needCostCount = costCount * buyCount;
        const ownCostCount = app.service.bag.itemVoMap.getItemAmount(costId);
        if (ownCostCount >= needCostCount) {
            app.service.drawCard.requestBuyTicket(buyCount);
        } else {
            app.ui.toast("道具不足");
        }
    }

    private onPoolRender(item: DrawCardBtnItem, index: number) {
        const loData = item.dataSource as DrawCardLo;
        item.imgBg.skin = loData.btnicon;
        const domwTime = loData.poolDownTimeStr;
        if (domwTime) {
            item.labTime.text = domwTime;
        }
    }

    private onPoolSelRender(index: number) {
        this.updateSelRender(index);
    }

    private updateSelRender(index: number) {
        const loData = this.owner.listPool.array[index] as DrawCardLo;
        this.owner.imgBg.skin = loData.bgicon;
        const times = loData.reMainTime;
        this.owner.labtimes.text = `今日剩余招募次数：${times}`;
        const isShowFree = loData.checkIsShowFree();
        this.owner.btnOne.label = isShowFree ? "免费" : "抽一次";
        this.owner.boxOne.visible = !isShowFree;

        const time = loData.reMainGuaranteedTimes;
        this.owner.labdrawCount.text = `${time}`;
    }

    private onClickMask() {
        this.owner.boxMask.mouseEnabled = false;
        if (this.owner.boxDescribe) {
            this.owner.boxDescribe.visible = false;
        }
        if (this.owner.BoxRewardDescribe) {
            this.owner.BoxRewardDescribe.visible = false;
        }
    }

    private onClickTips() {
        this.owner.boxMask.mouseEnabled = true;
        this.owner.boxDescribe.visible = true;
    }

    private onClickPreview() {
        const itemData = this.owner.listPool.array[this.owner.listPool.selectedIndex] as DrawCardLo;
        app.ui.show(ui.DRAW_CARD_PREVIEW, itemData.id);
    }

    private onClickReward() {
        const credit = app.service.drawCard.getRewardCreditPro();
        if (credit >= 100) {
            app.service.drawCard.requestReceiveReward();
        } else {
            this.owner.boxMask.mouseEnabled = true;
            this.owner.BoxRewardDescribe.visible = true;
        }
    }

    private onClickCheck() {
        this.owner.imgGou.visible = !this.owner.imgGou.visible;
        const isSkipAni = this.owner.imgGou.visible;
        app.service.drawCard.isSkipAnim = isSkipAni;
    }

    private initServiceEvent() {
        this.$(app).on(SystemEvent.DRAWCARD.DrawCard, this.onDrawCard, this);
        this.$(app).on(SystemEvent.DRAWCARD.UPDATECOMMONINFO, this.updateCreditPro, this);
        this.$(app).on(SystemEvent.DRAWCARD.UPDATEPOOL, this.updatePoolList, this);
        this.$(app).on(SystemEvent.DRAWCARD.GAINREWARD, this.updateCreditPro, this);
        this.$(app).on(SystemEvent.UI.ITEM_SVR_UPDATE, this.updateCost, this);
        this.$(app).on(SystemEvent.USER_MONEY_UPDATE, this.updateCreditPro, this);
        this.$(app).on(SystemEvent.DRAWCARD.BUYITEM, this.updateBuyItemSucess, this);
    }

    private onDrawCard(rewards: proto.bag.ItemReward[]) {
        const loData = this.owner.listPool.array[this.owner.listPool.selectedIndex] as DrawCardLo;
        app.ui.show(ui.DRAW_CARD_GAIN_REWARD, {
            rewards: rewards,
            loData: loData,
        } as UIDrawCardGainHeroArgs);
    }

    private updateBuyItemSucess() {
        console.log("购买调用成功");
        const loData = this.owner.listPool.array[this.owner.listPool.selectedIndex] as DrawCardLo;
        const clickType = app.service.drawCard.clickDrawCardType;
        app.service.drawCard.requestRecruit(loData.id, clickType);
    }

    private initPanel() {
        this.owner.boxMask.mouseEnabled = false;
        this.owner.boxDescribe.visible = false;
        this.owner.BoxRewardDescribe.visible = false;
        const confDes = app.service.table.recruit.conf.RECRUIT_EXPLAIN;
        this.owner.labdecribe.text = confDes;
        this.owner.imgGou.visible = app.service.drawCard.isSkipAnim;

        const costId = app.service.table.recruit.conf.RECRUIT_TICKET;
        const itemLo = app.service.bag.itemLoMap.get(costId);
        this.owner.imgItemIcon.skin =
            this.owner.imgCostIcon2.skin =
            this.owner.imgCostIcon.skin =
                itemLo.iconUrl;

        this.updateCreditPro();
        this.updateCost();
        this.updatePoolList();
        this.onLoop();
    }

    private updateCost() {
        const costId = app.service.table.recruit.conf.RECRUIT_TICKET;
        const ownCount = app.service.bag.itemVoMap.getItemAmount(costId);
        this.owner.labCount.text = `${ownCount}`;
    }

    private updateCreditPro() {
        const credit = app.service.drawCard.getRewardCreditPro();
        this.owner.labRewardPro.text = `奖励进度(${credit.toFixed(2)}%)`;
        const showPro = Math.min(credit / 100, 1);

        this.owner.labCanget.visible = showPro === 1;
        this.owner.imgMask.graphics.clear();
        this.owner.imgMask.graphics.drawPie(45, 45, 45, -90, -90 + showPro * 360, "#ffffff");
    }

    private updatePoolList() {
        const poolList = app.service.drawCard.getPoolList();
        let index = 0;
        if (this.owner.listPool.selectedIndex && this.owner.listPool.selectedIndex > -1) {
            const oldData = this.owner.listPool.array[
                this.owner.listPool.selectedIndex
            ] as DrawCardLo;
            const selIndex = poolList.findIndex((V) => V.id === oldData.id);
            if (selIndex > -1) {
                index = selIndex;
            }
        }
        this.owner.listPool.array = poolList;

        this.owner.listPool.selectedIndex = index;
        this.updateSelRender(index);
    }

    //组件被启用后执行，例如节点被添加到舞台后
    //onEnable(): void {}

    //组件被禁用时执行，例如从节点从舞台移除后
    //onDisable(): void {}

    //第一次执行update之前执行，只会执行一次
    //onStart(): void {}

    //手动调用节点销毁时执行
    //onDestroy(): void {}

    //每帧更新时执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onUpdate(): void {}

    //每帧更新时执行，在update之后执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onLateUpdate(): void {}

    //鼠标点击后执行。与交互相关的还有onMouseDown等十多个函数，具体请参阅文档。
    //onMouseClick(): void {}
}
