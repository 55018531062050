import { Vo } from "../../../misc/vo/Vo";

export type IServerInfo = {
    url: string;
    name?: string;
    server_name: string;
    server_sn: string;
    state: number;
    state_desc: string;
};

export class ServerInfoVo extends Vo<never, IServerInfo> {
    protected declare _data: Readonly<IServerInfo>;

    constructor(data: IServerInfo) {
        super(undefined!, data);
    }

    override get key(): unknown {
        return this._data.server_sn;
    }

    get name(): string | undefined {
        return this._data.name;
    }

    get url(): string {
        return this._data.url;
    }

    get serverName(): string {
        return this._data.server_name;
    }

    get serverSn(): string {
        return this._data.server_sn;
    }

    get state(): number {
        return this._data.state;
    }

    get stateDesc(): string {
        return this._data.state_desc;
    }

    override clone(): ServerInfoVo {
        return new ServerInfoVo(this._data);
    }
}
