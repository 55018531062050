/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { BaseRoundWindowBg } from "../../common/ui-runtime/dialog/BaseRoundWindowBg";
import { UIRadioGroup } from "../../../core/ui/UIRadioGroup";

/**
 * resources/prefab/gm/UIGM.lh
 */
export class UIGMBase extends Laya.Dialog {
    public bgWin!: BaseRoundWindowBg;
    public textInput!: Laya.TextInput;
    public btnUse!: Laya.Button;
    public ViewStack!: Laya.ViewStack;
    public listSwitch!: Laya.List;
    public Label!: Laya.Label;
    public listGm!: Laya.List;
    //public Label!: Laya.Label;
    public soldierBtn!: Laya.Button;
    public printNode!: Laya.Button;
    public btnReset!: Laya.Button;
    public purchaseBtn!: Laya.Button;
    public btnTrain!: Laya.Button;
    public btnSkill!: Laya.Button;
    public tab!: UIRadioGroup;
}

