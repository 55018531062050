import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { BattleConf } from "../../../../../def/auto/battle";
import proto from "../../../../../def/auto/proto";
import { opcode } from "../../../../../def/auto/protocol";
import { P1v1Context } from "../../P1v1Context";
import { P1v1BattleReportComponent } from "../components/P1v1BattleReportComponent";

export class P1v1BattleReportSystem extends ecs.System {
    declare context: P1v1Context;

    private _time: number = 0;

    override onCreate() {}

    override update(dt: number) {
        const battleReport = this.ecs.getSingletonComponent(P1v1BattleReportComponent);
        const frame = this._time * BattleConf.LOGIC_FPS;
        if (battleReport?.frames) {
            const frames = battleReport.frames;
            while (frames.length) {
                const cmd = frames[0];
                if (cmd.frame <= frame) {
                    frames.shift();
                    console.log("battle report:", cmd);
                    app.event(
                        opcode.battle.notify_cmd,
                        proto.battle.notify_cmd.create({ frame: cmd })
                    );
                } else {
                    break;
                }
            }
        }
        this._time += dt;
    }
}
