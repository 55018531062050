import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { SlotSysType } from "../../def/auto/skill";
import { SkillRemoveItemUI } from "./ui-runtime/SkillRemoveItemUI";
import { SkillRemoveUI } from "./ui-runtime/SkillRemoveUI";

const { regClass, property } = Laya;

@regClass('ooj-K0l1Sb2FHpMv8Qkutg') //技能卸下弹窗
export class SkillRemoveMediator extends Mediator {
    declare owner: SkillRemoveUI;

    override onAwake(): void {
        super.onAwake();
        this.owner.imgClose.on(Laya.Event.CLICK, this.owner, this.owner.destroy);
        this.owner.listTeam.renderHandler = new Laya.Handler(this, this.onTeamListRender);
        this.initPanel();
    }

    private onTeamListRender(item: SkillRemoveItemUI, index: number) {
        const uidData = item.dataSource as { uid: number; sysType: number };
        if (uidData.sysType === SlotSysType.HERO) {
            const teamId = app.service.troop.getTeamIdxBySoliderIdx(uidData.uid);
            const teamName = teamId !== 0 ? `队伍${teamId}` : `无队伍`;
            item.labTeam.text = teamName;
        } else if (uidData.sysType === SlotSysType.LEADER_SKIN) {
            const teamId = app.service.troop.getTeamIdBySkinId(uidData.uid);
            const teamName = teamId !== 0 ? `队伍${teamId}` : `无队伍`;
            item.labTeam.text = teamName;
        }

        item.btnOff.on(Laya.Event.CLICK, this, this.onClickOff);
    }

    private onClickOff(e: Laya.Event) {
        const uidData = ((e.target as Laya.Button).parent as Laya.Box).dataSource as {
            uid: number;
            sysType: number;
        };
        const skilVo = this.owner.m_data!;
        let skillPos: number = 0;
        const skillId = skilVo.skillId;
        if (uidData.sysType === SlotSysType.LEADER_SKIN) {
            const skinData = app.service.leaderSkin.leaderSkinVoMap.get(uidData.uid)!;
            for (const key in skinData.skills) {
                if (skinData.skills[key].id === skillId) {
                    skillPos = parseInt(key);
                    break;
                }
            }
            app.service.skill.requestUnmountSkill(uidData.uid, skillPos, SlotSysType.LEADER_SKIN);
        } else if (uidData.sysType === SlotSysType.HERO) {
            const heroData = app.service.hero.heroVoMap.get(uidData.uid);
            if (heroData) {
                for (const key in heroData.skills) {
                    if (heroData.skills[key].id === skillId) {
                        skillPos = parseInt(key);
                        break;
                    }
                }
                app.service.skill.requestUnmountSkill(uidData.uid, skillPos, SlotSysType.HERO);
            }
        }
        this.owner.destroy();
    }

    private initPanel() {
        const skillVo = this.owner.m_data;
        const skillName = skillVo?.name || "";
        this.owner.bgwin.setTitle(skillName);
        const heroIdShowList = skillVo!.heroIdList;
        this.owner.listTeam.array = heroIdShowList;
    }
}
