/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { SkillIconUI } from "./SkillIconUI";

/**
 * resources/prefab/skill/SkillMapItem.lh
 */
export class SkillMapItemUIBase extends Laya.Box {
    public boxBase!: Laya.Box;
    public imgQuality!: Laya.Image;
    public labName!: Laya.Label;
    public imgType!: Laya.Image;
    public labType!: Laya.Label;
    public boxSouce!: Laya.Box;
    public labSource!: Laya.Label;
    public boxDetails!: Laya.Box;
    public boxGray!: Laya.Box;
    public skill_icon!: SkillIconUI;
    public imgType2!: Laya.Image;
    public labType2!: Laya.Label;
    public lablevelDesc!: Laya.Label;
    public labDesc!: Laya.Label;
    public labBtn!: Laya.Text;
    public boxSouce2!: Laya.Box;
    //public labSource!: Laya.Label;
    public btnGet!: Laya.Button;
}

