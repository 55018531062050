const { regClass } = Laya;
import { PveUIBase } from "./PveUI.generated";

@regClass('OyjG4JiZRlyz36pQ0bDvVA')
export class PveUI extends PveUIBase {
    private _grounds?: Laya.Sprite3D;
    private _shadows?: Laya.Sprite3D;
    private _navigationArrows?: Laya.Sprite3D;
    private _roles?: Laya.Sprite3D;
    private _objects?: Laya.Sprite3D;

    override onAwake(): void {
        super.onAwake();
        this.btnBackTown.visible = false;
    }

    override get scene3D(): Laya.Scene3D {
        return this._scene3D;
    }

    get grounds() {
        return (this._grounds ||= this.scene3D.getChildByName("grounds") as Laya.Sprite3D);
    }

    get shadows() {
        return (this._shadows ||= this.scene3D.getChildByName("shadows") as Laya.Sprite3D);
    }

    get navigationArrows() {
        return (this._navigationArrows ||= this.scene3D.getChildByName(
            "navigationArrows"
        ) as Laya.Sprite3D);
    }

    get roles() {
        return (this._roles ||= this.scene3D.getChildByName("roles") as Laya.Sprite3D);
    }

    get objects() {
        if (this._objects) return this._objects;
        const world_map = this.scene3D.getChildByName("world-map") as Laya.Sprite3D;
        return (this._objects ||= world_map.getChildByName("object") as Laya.Sprite3D);
    }
}
