const { regClass } = Laya;
import { ToastArgs } from "../ui";
import { ToastUIBase } from "./ToastUI.generated";

@regClass('ZnCqiJf4TseF6InatJedpQ')
export class ToastUI extends ToastUIBase {
    args!: ToastArgs;

    override open(closeOther?: boolean | undefined, param?: any): void {
        this.args = param;
        super.open(closeOther, param);
    }
}
