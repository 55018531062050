/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/skill/SkillFastList.lh
 */
export class SkillFastListUIBase extends Laya.Dialog {
    public imgClose!: Laya.Image;
    public panalScroller!: Laya.Panel;
    public vboxContent!: Laya.VBox;
    public btnSelect!: Laya.Button;
}

