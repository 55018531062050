import { BuffBaseData } from "./BuffBase";

export class BuffAttrbuteData extends BuffBaseData {
    readonly fromBuffTreeId: number = 0;

    readonly attrId: number;

    readonly value: number = 0;

    constructor(attrId: number, value: number, fromBuffTreeId: number, overdueTime: number = -1) {
        super(overdueTime);
        this.attrId = attrId;
        this.value = value;
        this.fromBuffTreeId = fromBuffTreeId;
    }
}
