import { app } from "../../../../app";
import { VoMap } from "../../../../misc/vo/VoMap";
import { TableUtil } from "../../../table/TableUtil";
import { MonsterLo } from "./MonsterLo";

export class MonsterLoMap extends VoMap<number, MonsterLo> {
    override get(id: number): MonsterLo {
        let ret = super.get(id);
        if (!ret) {
            const row = TableUtil.getRow(app.service.table.monster.prop, { id: id })!;
            if (!row) {
                throw new Error(`no monster prop id: ${id}`);
            }
            ret = new MonsterLo(row);
            this.set(id, ret);
        }
        return ret;
    }
}
