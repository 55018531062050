import { app } from "../../app";
import { VoMap } from "../../misc/vo/VoMap";
import { TaskVo } from "./vo/TaskVo";

export class TaskVoMap extends VoMap<number, TaskVo> {
    override get(taskId: number) {
        let lo = super.get(taskId);
        if (!lo) {
            lo = new TaskVo(app.service.table.task[taskId]);
            this.set(taskId, lo);
        }
        return lo;
    }

    getTasks(taskId: number) {
        return this.toArray().filter((vo) => vo.key === taskId);
    }

    getTasksWithType(taskType: number) {
        return this.toArray().filter((vo) => vo.taskType === taskType);
    }
}
