import { UIImageBar } from "../../../../core/ui/UIImageBar";
import { res } from "../../../../misc/res";
import { HeadInfoUIBase } from "./HeadInfoUI.generated";

const { regClass } = Laya;

export const enum HeadInfoStyle {
    OUR,
    FRIEND,
    ENEMY,
}

export type HeadInfoData = {
    name?: string;
    hp: number;
    maxHp: number;
    mp: number;
    maxMp: number;
    style: HeadInfoStyle;
    skinId: number;
};

@regClass('4eFIDaQ7SiC8oEINs36N4Q')
export class HeadInfoUI extends HeadInfoUIBase {
    private _data?: HeadInfoData;

    private _bg?: Laya.Image;
    private _hp?: UIImageBar;
    private _mp?: UIImageBar;

    override onEnable(): void {
        if (this._data) {
            this.update(this._data);
        }
    }

    update(data: HeadInfoData) {
        this._data = data;
        if (!this._hp) {
            this._bg = this.getChildByName("bg") as Laya.Image | undefined;
            this._hp = this.getChildByName("hp") as UIImageBar | undefined;
            this._mp = this.getChildByName("mp") as UIImageBar | undefined;

            if (this._data.style === HeadInfoStyle.ENEMY) {
                if (this._hp) {
                    this._hp.skin = res.battle.PVE_ENEMY_SMALL_HP_BAR;
                }
            }
            // if (this._data.style === HeadInfoStyle.ENEMY) {
            //     const atlasPath = res.battle.PVE_TROOP.slice(0, -6);
            //     if (this._bg) {
            //         this._bg.skin = `${atlasPath}/ui_fight_RoleInfoBg2.png`;
            //     }
            //     if (this._hp) {
            //         this._hp.skin = `${atlasPath}/sb_fight_Blood4.png`;
            //     }
            // }
        }
        if (this._hp) {
            this._hp.value = Math.max(0, Math.min(1, data.hp / data.maxHp));
        }
        if (this._mp) {
            this._mp.value = Math.max(0, Math.min(1, data.mp / data.maxMp));
        }
    }
}
