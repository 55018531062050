import { app } from "../../../app";
import { TroopState } from "../../../def/auto/world";
import { TroopTeamVo } from "../../troop/vo/TroopTeamVo";
import { PvpContext } from "./PvpContext";
import { PvpOwnerComponent } from "./ecs/components/PvpOwnerComponent";
import { PvpStateComponent } from "./ecs/components/PvpStateComponent";

/**
 *
 */
export const enum PvpCampStyle {
    NONE = -1,
    SELF = 0,
    FRIEND,
    ENEMY,
}

export class PVPUtils {
    /**
     * 检查目标阵营与自己的阵营关系
     * @param comp
     * @returns
     */
    public static CheckCampStyle(ctx: PvpContext, comp: PvpOwnerComponent): PvpCampStyle {
        // todo: app.service.user的rid和aid都还没初始化
        if (comp.rid === ctx.getMyRid()) {
            return PvpCampStyle.SELF;
        } else if (comp.aid === ctx.getMyAid()) {
            return PvpCampStyle.FRIEND;
        } else {
            return PvpCampStyle.ENEMY;
        }
    }

    public static GetSelfActionableTroopEids(ctx: PvpContext): number[] {
        const troopEids = app.service.troop.troopTeamVoMap
            .toArray()
            .filter((v: TroopTeamVo) => {
                if (!v.eid) {
                    return false;
                }
                const stateComp = ctx.ecs.getComponent(v.eid, PvpStateComponent);
                if (!stateComp) {
                    return false;
                }
                // 只有空闲、移动、驻守的部队才可以操作
                if (
                    stateComp.state === TroopState.IDLE ||
                    stateComp.state === TroopState.MOVE ||
                    stateComp.state === TroopState.INSIDE
                ) {
                    return true;
                } else {
                    return false;
                }
            })
            .map((v: TroopTeamVo) => {
                return v.eid;
            });
        return troopEids;
    }
}
