import * as ecs from "../../../../../core/ecs";
import { PveContext } from "../../PveContext";
import { PveTransformComponent } from "../components/PveTransformComponent";

export class PveMovementSystem extends ecs.System {
    declare context: PveContext;

    override update(dt: number): void {
        this.ecs.getComponents(PveTransformComponent).forEach((transform) => {
            if (transform.rotationInterpolation.percent < 1) {
                this._updateRotation(transform, dt);
            }
        });
    }

    private _updateRotation(transform: PveTransformComponent, dt: number) {
        const interpolation = transform.rotationInterpolation;
        const last = interpolation.percent;
        const ratio = Math.min(1, last + dt * interpolation.rate);
        interpolation.percent = ratio;
        const rotation = transform.rotation + interpolation.rotation * (ratio - last);
        transform.rotation = rotation;
        transform.flag |= PveTransformComponent.ROTATION;
    }
}
