/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIHBoxStar } from "../UIHBoxStar";

/**
 * resources/prefab/character/detail/CharacterMatDispBox.lh
 */
export class CharacterMatDispBoxBase extends Laya.Box {
    public boxMatItem!: Laya.Box;
    public imgQuality!: Laya.Image;
    public imgIcon!: Laya.Image;
    public starLayout!: UIHBoxStar;
    public imgStar0!: Laya.Image;
    public imgStar1!: Laya.Image;
    public imgStar2!: Laya.Image;
    public imgStar3!: Laya.Image;
    public imgStar4!: Laya.Image;
    public proMat!: Laya.ProgressBar;
    public lblMatPro!: Laya.Label;
    public lblMatDesc!: Laya.Label;
}

