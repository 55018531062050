import {
    Node,
    NodeDef,
    NodeVars as NodeVarsBase,
    Process,
    Status,
} from "../../../../../core/behavior3";
import { BattleAid } from "../../../../../def/auto/battle";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrCreatureComponent } from "../../ecs/components/PveSvrCreatureComponent";
import { ElementTag } from "../../ecs/components/PveSvrElementComponent";

enum AttrEnum {
    hp = 1,
}

const AttrOptions = [{ name: "hp", value: AttrEnum.hp }];

interface NodeArgs {
    readonly attr: AttrEnum;
    readonly distance: number;
    readonly leader?: boolean;
    readonly member?: boolean;
    readonly friend?: boolean;
    readonly max?: boolean;
}

interface NodeVars extends NodeVarsBase {
    tag: ElementTag;
}

export class FindTargetsByAttr extends Process {
    override init(node: Node) {
        const vars = node.vars as NodeVars;
        const args = node.args as unknown as NodeArgs;
        vars.tag = 0;
        if (args.leader) {
            vars.tag |= ElementTag.LEADER;
        }
        if (args.member) {
            vars.tag |= ElementTag.MEMBER;
        }
    }

    override run(node: Node, env: AiTreeEnv): Status {
        const args = node.args as unknown as NodeArgs;

        const retEids: { attr: number; eid: number }[] = [];
        const creatures = env.context.ecs.getComponents(PveSvrCreatureComponent);

        const distance = args.distance ?? 0;
        if (distance <= 0) {
            console.error("FindTargetByAttr: distance is invalid", distance);
            return "failure";
        }

        for (const [key, creature] of creatures) {
            const element = creature.eleComp;
            // 友方意味着，aid同为self和ally或者同为enemy或中立
            const isFriend =
                ((env.owner.aid === BattleAid.SELF || env.owner.aid === BattleAid.ALLY) &&
                    (element.aid === BattleAid.SELF || element.aid === BattleAid.ALLY)) ||
                ((env.owner.aid === BattleAid.ENEMY || env.owner.aid === BattleAid.NEUTRAL) &&
                    (element.aid === BattleAid.ENEMY || element.aid === BattleAid.NEUTRAL));
            if (!!args.friend !== isFriend) {
                continue;
            }

            if (
                Laya.Vector3.distanceXZ(
                    creature.transformComp.position,
                    env.owner.transform.position
                ) > distance
            ) {
                continue;
            }
            let attr = undefined;
            switch (args.attr) {
                case AttrEnum.hp:
                    attr = creature.hp;
                    break;
                default:
                    console.error("FindTargetsByAttr: unsupport attr", args.attr);
                    return "failure";
            }

            retEids.push({ attr, eid: creature.eid });
        }

        if (retEids.length === 0) {
            // console.error("FindTargetByAttr: no target found", args);
            return "failure";
        }

        retEids.sort((a, b) => {
            return args.max ? b.attr - a.attr : a.attr - b.attr;
        });
        env.output.push(retEids.map((e) => e.eid));
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "FindTargetsByAttr",
            type: "Condition",
            desc: "根据属性查找目标",
            args: [
                { name: "attr", type: "enum", desc: "属性", options: AttrOptions },
                { name: "distance", type: "float", desc: "距离" },
                { name: "leader", type: "boolean?", desc: "找领队" },
                { name: "member", type: "boolean?", desc: "找士兵" },
                { name: "friend", type: "boolean?", desc: "友方" },
                { name: "max", type: "boolean?", desc: "查找最大值？ false表示查找最小值" },
            ],
            output: ["目标单位"],
            doc: `
                + 没有找到返回失败
                + 默认查找敌对单位
                
                `,
        };
    }
}
