const { regClass, property } = Laya;

@Laya.regClass('mOAufzOWTgix_Gmp0LcM2g')
@Laya.classInfo({
    menu: "自定义",
    icon: "plugins/type-icons/node/RadioGroup.svg",
})
export class UIRadioGroup extends Laya.UIGroup {
    constructor() {
        super();
        this._labelColors = "";
        this._stateNum = 1;
    }

    @Laya.property({ visible: false, serializable: false })
    override get skin(): string {
        return super.skin;
    }

    override set skin(value: string) {
        super.skin = value;
    }

    @Laya.property({ visible: false, serializable: false })
    override get labelColors(): string {
        return "";
    }

    override set labelColors(value: string) {}

    @Laya.property({ visible: false, serializable: false })
    override get stateNum(): number {
        return 1;
    }

    override set stateNum(value: number) {}

    @Laya.property({ visible: false, serializable: false })
    override get labels(): string {
        return "";
    }

    override set labels(value: string) {}
}
