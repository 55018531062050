import * as ecs from "../../../../../core/ecs";
import { IReusable, Pool } from "../../../../../core/pool";

export enum PveSvrTransformFlag {
    NONE = 0,
    POSITION = 1 << 0,
    ROTATION = 1 << 1,
    ROTATION_QUAT = 1 << 2,
    CALCU_ROTATION = 1 << 3,
}

// 生命周期跟随Entity
@Pool.reusable
export class PveSvrTransformComponent extends ecs.Component implements IReusable {
    private _flag: PveSvrTransformFlag = PveSvrTransformFlag.NONE;

    get flag(): PveSvrTransformFlag {
        return this._flag;
    }

    set flag(value: number) {
        // ROTATION ROTATION_QUAT 互斥
        if (value & PveSvrTransformFlag.ROTATION) {
            value &= ~PveSvrTransformFlag.ROTATION_QUAT;
        } else if (value & PveSvrTransformFlag.ROTATION_QUAT) {
            value &= ~PveSvrTransformFlag.ROTATION;
        }
        this._flag = value;
    }

    readonly position: Laya.Vector3 = new Laya.Vector3();

    private _rotation: number = 0;

    get rotation() {
        return this._rotation;
    }

    set rotation(value: number) {
        this._rotation = ((value % 360) + 360) % 360; // 保证角度在0-360之间
    }

    readonly quaternion: Laya.Quaternion = new Laya.Quaternion();

    __unuse() {}

    __reuse() {
        this.rotation = 0;
        this.position.setValue(0, 0, 0);
        this.quaternion.setValue(0, 0, 0, 1);
    }
}
