import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { Pool } from "../../../../../core/pool";
import { StringUtil } from "../../../../../core/utils/string-util";
import { BattleAid, BattleEntityType } from "../../../../../def/auto/battle";
import { TableUtil } from "../../../../table/TableUtil";
import { lotteryWithRewardProbs } from "../../../base/Util";
import { BattleSpoilsLo } from "../../../pve/vo/BattleSpoilsLo";
import { ExplodeSpoilsData } from "../../PveDefs";
import { PveServer } from "../../PveServer";
import { PveSvrAStarComponent } from "../components/PveSvrAstarComponent";
import { ElementTag, PveSvrElementComponent } from "../components/PveSvrElementComponent";
import { PveSvrTransformComponent } from "../components/PveSvrTransformComponent";
import { PveSvrSpoilsStateData } from "../data/state-data/PveSvrSpoilsStateData";
import { PveSvrStateSystem } from "./PveSvrStateSystem";

const spoilTempPos = new Laya.Vector3();
const heroTempPos = new Laya.Vector3();
const spoilsRandomPos = new Laya.Vector2();
const tmpVector3 = new Laya.Vector3();

/** 战利品系统 */
export class PveSvrSpoilsSystem extends ecs.System {
    declare context: PveServer;

    override get interval() {
        return 0.2;
    }

    override update(dt: number): void {
        this.checkPickup();
    }

    /**
     * 检查拾取
     * 每次检查只拾取一个
     */
    private checkPickup(): void {
        const hero = this.ecs.getComponent(this.context.ctrlHeroEid, PveSvrElementComponent);
        if (!hero) return;
        const heroTransform = hero.getComponent(PveSvrTransformComponent)!;
        heroTempPos.cloneFrom(heroTransform.position);
        heroTempPos.y = 0;
        const nowTime = app.service.network.serverTime;

        const pveStateData = app.service.pve.mlData.pveStateData;

        const spoils: PveSvrSpoilsStateData[] = pveStateData.spoils;
        for (let i: number = 0; i < spoils.length; i++) {
            const spoil = spoils[i];
            const overTime = nowTime - spoil.dropTime;
            if (overTime < 2) {
                continue; // 为了避免爆出动画还没播放完成就拾取，给2秒的延迟拾取时间。
            }

            const spoilX = spoil.pos.x;
            const spoilZ = spoil.pos.y;
            spoilTempPos.x = spoilX;
            spoilTempPos.y = 0;
            spoilTempPos.z = spoilZ;
            const dist = Laya.Vector3.distance(spoilTempPos, heroTempPos);
            if (dist < 3) {
                // 战利品与主角距离小于0.5时，即可拾取
                this.pickupSpoils(spoil);
                // 拾取宝箱后，立刻进行保存
                PveSvrStateSystem.save(this.ecs);
                return;
            }
        }
    }

    /**
     * 拾取战利品
     *
     */
    private pickupSpoils(spoil: PveSvrSpoilsStateData): void {
        const pveStateData = app.service.pve.mlData.pveStateData;
        const removeData = pveStateData.removeSpoilsData(spoil.pos.x, spoil.pos.y);
        if (removeData) {
            spoilTempPos.x = spoil.pos.x;
            spoilTempPos.y = 0;
            spoilTempPos.z = spoil.pos.y;
            PveSvrSpoilsSystem.grubSpoils(this.context, spoil.tid, spoilTempPos);
            this.context.removeSpoils(spoil.tid, spoilTempPos);

            // 模拟发放奖励
            const spoilsLo: BattleSpoilsLo = removeData.spoilsRow;
            const hit = lotteryWithRewardProbs(spoilsLo.reward);
            if (hit) {
                const itemId = hit.id;
                const itemAmount = hit.num;
                const cmd = StringUtil.format("add_item {0} {1}", itemId, itemAmount);
                app.service.gm.requestGM(cmd);
            }

            Pool.free(removeData);
        }
    }

    public static grubSpoils(pveServer: PveServer, tid: number, position: Laya.Vector3) {
        // const key = pveServer.toElementKey(tid, position.x, position.z);
        const spolis = pveServer.find((element) => element.tag === ElementTag.SPOILS);

        if (spolis) {
            for (const v of spolis) {
                const tfComp = v.getComponent(PveSvrTransformComponent)!;
                if (tfComp.position.x === position.x && tfComp.position.z === position.z) {
                    pveServer.sender.grubSpoils(v.eid);
                    return;
                }
            }
        }
    }

    /**
     * 处理怪物部队死亡然后掉落战利品
     * (掉落战利品)
     * monPosX 怪物X轴位置
     * monPosZ 怪物Z轴位置
     */
    public static handleMonsterTroopDeathDropSpoils(
        pveServer: PveServer,
        monsterTroopTid: number,
        monDiePosX: number,
        monDiePosZ: number,
        deathTime: number
    ): void {
        const table = app.service.table;
        const monTroopRow = table.monster.troop[monsterTroopTid];
        if (!monTroopRow) return;

        // 掉落半径
        const radius = monTroopRow.drop_radius / 100;

        const dropCount = Math.randomInt(monTroopRow.drop_min_num, monTroopRow.drop_max_num);
        if (dropCount < 1) return;

        const drop_spoils = monTroopRow.drop_spoils!;
        const explodeSpoilsDatas: ExplodeSpoilsData[] = [];

        const astarComponent = pveServer.ecs.getSingletonComponent(PveSvrAStarComponent);
        const pveStateData = app.service.pve.mlData.pveStateData;
        for (let i = 0; i < dropCount; i++) {
            const hit_spoils = lotteryWithRewardProbs(drop_spoils);
            if (hit_spoils) {
                const spoilsid = hit_spoils.id;
                const spoilsRow = table.spoils[spoilsid];
                const getPosSuccess = pveStateData.getSpoilsRandomPos(
                    astarComponent.pveAStarData,
                    radius,
                    monDiePosX,
                    monDiePosZ,
                    spoilsRandomPos
                );
                if (getPosSuccess) {
                    // 成功找到生成宝箱的位置
                    // 添加到数据
                    const addData = pveStateData.addSpoilsData(
                        spoilsid,
                        deathTime,
                        spoilsRandomPos.x,
                        spoilsRandomPos.y
                    );

                    if (addData) {
                        explodeSpoilsDatas.push({
                            eid: 0,
                            spoilsRow: spoilsRow,
                            posX: spoilsRandomPos.x,
                            posZ: spoilsRandomPos.y,
                        });
                    }
                }
            }
        }

        if (explodeSpoilsDatas.length > 0) {
            this.explodeSpoilsItemAnima(pveServer, monDiePosX, monDiePosZ, explodeSpoilsDatas);
        }
    }

    public static handleCollectDropSpoils(
        pveServer: PveServer,
        collectPosX: number,
        collectPosZ: number,
        dropCount: number,
        spoilsid: number
    ) {
        const explodeSpoilsDatas: ExplodeSpoilsData[] = [];
        const dropRadius = 2;
        const spoilsRow = app.service.table.spoils[spoilsid];
        const pveStateData = app.service.pve.mlData.pveStateData;
        const curTime = app.service.network.serverTime;
        const astarComponent = pveServer.ecs.getSingletonComponent(PveSvrAStarComponent);
        for (let i = 0; i < dropCount; i++) {
            const getPosSuccess = pveStateData.getSpoilsRandomPos(
                astarComponent.pveAStarData,
                dropRadius,
                collectPosX,
                collectPosZ,
                spoilsRandomPos
            );
            if (getPosSuccess) {
                // 成功找到生成宝箱的位置
                // 添加到数据
                const addData = pveStateData.addSpoilsData(
                    spoilsid,
                    curTime,
                    spoilsRandomPos.x,
                    spoilsRandomPos.y
                );

                if (addData) {
                    explodeSpoilsDatas.push({
                        eid: 0,
                        spoilsRow: spoilsRow,
                        posX: spoilsRandomPos.x,
                        posZ: spoilsRandomPos.y,
                    });
                }
            }
        }
        if (explodeSpoilsDatas.length > 0) {
            this.explodeSpoilsItemAnima(pveServer, collectPosX, collectPosZ, explodeSpoilsDatas);
        }
    }

    /**
     * Boss 爆战利品动画
     * @param diePosX Boss/怪物 死亡时的位置
     * @param diePosZ Boss/怪物 死亡时的位置
     * @param boxCount 掉落的宝箱数量
     * @param radius 以怪物死亡位置为中心，宝箱出现的圆半径
     */
    public static explodeSpoilsItemAnima(
        pveServer: PveServer,
        diePosX: number,
        diePosZ: number,
        explodeSpoilsDatas: ExplodeSpoilsData[]
    ): void {
        const table = app.service.table;
        const battleEntityRow = TableUtil.getRow(table.battleEntity, {
            etype: BattleEntityType.SPOILS,
        })!;

        for (let i = 0; i < explodeSpoilsDatas.length; i++) {
            const data = explodeSpoilsDatas[i];
            const eid = pveServer.obtainEid();
            data.eid = eid;
            const boxEntity = pveServer.ecs.createEntity(eid, BattleEntityType.SPOILS);
            const element = boxEntity.addComponent(PveSvrElementComponent);
            element.tag = ElementTag.SPOILS;
            element.tid = data.spoilsRow.id; // 对应 spoils 表的id
            element.aid = BattleAid.NEUTRAL;

            element.data = battleEntityRow;

            const transform = boxEntity.addComponent(PveSvrTransformComponent);
            transform.position.x = data.posX;
            transform.position.z = data.posZ;
            pveServer.sender.createElement({
                eid: element.eid,
                etype: element.etype,
                key: element.key,
                aid: element.aid,
                teid: element.data.id,
                tid: element.tid,
                hp: -1,
                maxHp: -1,
                position: transform.position,
                rotation: transform.rotation,
                level: -1,
            });
        }

        // 播放爆开动画
        pveServer.sender.playExplodeSpoilsAnimation(diePosX, diePosZ, explodeSpoilsDatas);
    }

    /** 移除过期的战利品 */
    public static removeOverdueSpoils(
        pveServer: PveServer,
        overdueSpoils: PveSvrSpoilsStateData[]
    ): void {
        for (let i = 0; i < overdueSpoils.length; i++) {
            const overdueSpoil = overdueSpoils[i];
            tmpVector3.x = overdueSpoil.pos.x / 10;
            tmpVector3.y = 0;
            tmpVector3.z = overdueSpoil.pos.y / 10;
            pveServer.removeSpoils(overdueSpoil.tid, tmpVector3);
        }
        Pool.free(overdueSpoils);
    }
}
