const { regClass } = Laya;
import { app } from "../../../../../app";
import { SystemEvent } from "../../../../../misc/system-event";
import { PveContext } from "../../../pve/PveContext";
import { PveElementComponent } from "../../../pve/ecs/components/PveElementComponent";
import { PveUIProductionInfoComponent } from "../../../pve/ecs/components/PveUIProductionInfoComponent";
import { BDProductionInfoUIBase } from "./BDProductionInfoUI.generated";

@regClass('SSIL-K78SpubxPYUdlGURQ')
export class BDProductionInfoUI extends BDProductionInfoUIBase {
    context: PveContext | undefined;

    comp: PveUIProductionInfoComponent | undefined;

    private _lastUpdateTime: number = 0;
    private _lastUpdateCD: number = 1.0;

    private _onBuildingHarvestCompFunc?: () => void;

    override onAwake(): void {
        super.onAwake();
        this._onBuildingHarvestCompFunc = this.onBuildingHarvestComp.bind(this);
        app.on(SystemEvent.PVE.ON_BUILDING_HARVEST_COMP, this._onBuildingHarvestCompFunc);
    }

    private onBuildingHarvestComp(): void {
        this.updateShow();
    }

    override onDestroy(): void {
        app.off(SystemEvent.PVE.ON_BUILDING_HARVEST_COMP, this._onBuildingHarvestCompFunc);
    }

    public update(): void {
        if (!this.context) {
            return;
        }

        // 防止频繁刷新
        const curTime = this.context.time;
        const cdEndTime = this._lastUpdateTime + this._lastUpdateCD;
        if (this._lastUpdateTime > 0 && curTime < cdEndTime) {
            return;
        }
        this._lastUpdateTime = curTime;

        this.updateShow();
    }

    public updateShow(): void {
        if (!this.comp) {
            return;
        }
        const elementComp = this.comp.getComponent(PveElementComponent)!;
        const bdData = app.service.pve.mlData.pveStateData.getBuildingByKey(elementComp.key);
        if (!bdData) {
            return;
        }
        let productionTime = 0;
        if (bdData.harvestTime) {
            const curTime = app.service.network.serverTime;
            productionTime = curTime - bdData.harvestTime;
        }

        const rowLo = app.service.pve.buildingVoMap.getVoWithBattleEntityIdAndLevel(
            elementComp.teid,
            bdData.level
        )!;

        const progress = rowLo.getProgressWithProductionTime(productionTime);
        this.progressBar.value = progress;
        this.img_icon.skin = rowLo.iconUrl;
        this.img_full.visible = progress >= 0.9999;
    }
}
