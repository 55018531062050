import * as ecs from "../../../../../core/ecs";
import { res } from "../../../../../misc/res";
import { BuildingConfirmUI } from "../../../ui-runtime/pve/build/BuildingConfirmUI";

/**
 * 查看建筑确认UI组件
 */
export class PveUIBuildingConfirmComponent extends ecs.Component {
    readonly mounter: Laya.Sprite = new Laya.Sprite();

    view?: BuildingConfirmUI | null;

    /** 资源路径 */
    public get res(): string {
        return res.BATTLE_BUILDING_CONFIRM;
    }
}
