const { regClass } = Laya;
import { LeaderSkinRow } from "../../../def/table";
import { UILeaderSkinTeamBase } from "./UILeaderSkinTeam.generated";

@regClass('MeeAVMH3Sqa3_MlZ2vyADg')
export class UILeaderSkinTeam extends UILeaderSkinTeamBase {
    public cfgData: LeaderSkinRow | undefined;

    override open(closeOther?: boolean, param?: any): void {
        this.cfgData = param as LeaderSkinRow;
        super.open(closeOther, param);
    }
}
