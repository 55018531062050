import { app } from "../../../app";
import { Callback, Context, Tree, TreeData } from "../../../core/behavior3";
import * as ecs from "../../../core/ecs";
import { IVector3Like } from "../../../core/laya";
import { StringUtil } from "../../../core/utils/string-util";
import {
    BattleAid,
    BattleConf,
    BattleEntityType,
    PveBuildingType,
    PveEventType,
    PveNpcStateType,
} from "../../../def/auto/battle";
import { TroopStation } from "../../../def/auto/troop";
import { BattleBuildingRow } from "../../../def/table";
import { BezierHelper } from "../../../misc/utils/bezier/BezierHelper";
import { BagService } from "../../bag/BagService";
import { PlotDialogueWinMediator } from "../../plot/PlotDialogueWinMediator";
import { AnimName } from "../base/Animator";
import { IPveReceiver } from "../pve/PveReceiver";
import { PveMoveUtils } from "../pve/ecs/utils/PveMoveUtils";
import { TMUtil } from "../tilemap/tm-util";
import {
    BattlePveMapTransferItem,
    ILaunchBulletArgs,
    InteractiveBubbleActionType,
    InteractiveBubbleViewType,
    PveDef,
} from "./PveDefs";
import { PveSvrBattle } from "./PveSvrBattle";
import { PveSvrReceiver } from "./PveSvrReceiver";
import { PveSvrUtils } from "./PveSvrUtils";
import { BulletType } from "./btree/actions/CreateBullet";
import { ExpectGoToTargetData } from "./btree/actions/HitWallFindPath";
import { PveSvrAiComponent } from "./ecs/components/PveSvrAiComponent";
import { PveSvrAStarComponent } from "./ecs/components/PveSvrAstarComponent";
import { PveSvrBuildingComponent } from "./ecs/components/PveSvrBuildingComponent";
import { PveSvrBulletComponent } from "./ecs/components/PveSvrBulletCompoment";
import { PveSvrCollectionComponent } from "./ecs/components/PveSvrCollectionComponent";
import { PveSvrCommandComponent } from "./ecs/components/PveSvrCommandComponent";
import { PveSvrCreatureComponent } from "./ecs/components/PveSvrCreatureComponent";
import { ElementTag, PveSvrElementComponent } from "./ecs/components/PveSvrElementComponent";
import { EventTrigger, PveSvrEventComponent } from "./ecs/components/PveSvrEventComponent";
import { PveSvrMapTransferComponent } from "./ecs/components/PveSvrMapTransferComponent";
import {
    MovementCtrlType,
    MovementState,
    MovementUpdateType,
    PveSvrMovementComponent,
    TrackType,
} from "./ecs/components/PveSvrMovementComponent";
import { PveSvrOpenFunctionComponent } from "./ecs/components/PveSvrOpenFunctionComponent";
import { PveSvrProbingComponent } from "./ecs/components/PveSvrProbingComponent";
import { PveSvrProductionFactoryComponent } from "./ecs/components/PveSvrProductionFactoryComponent";
import { PveSvrRecycleComponent } from "./ecs/components/PveSvrRecycleComponent";
import { PveSvrRevivalComponent } from "./ecs/components/PveSvrRevivalComponent";
import { PveSvrSkillLauncherComponent, Skill } from "./ecs/components/PveSvrSkillLauncherComponent";
import { PveSvrTransformComponent } from "./ecs/components/PveSvrTransformComponent";
import { PveSvrTroopComponent } from "./ecs/components/PveSvrTroopComponent";
import { PveSvrTruckComponent } from "./ecs/components/PveSvrTruckComponent";
import { PveSvrCollectionStateData } from "./ecs/data/state-data/PveSvrCollectionStateData";
import { PveSvrMonsterTroopStateData } from "./ecs/data/state-data/PveSvrMonsterTroopStateData";
import { PveSvrAiSystem } from "./ecs/systems/PveSvrAiSystem";
import { PveSvrAStarSystem } from "./ecs/systems/PveSvrAstarSystem";
import { PveSvrBuildSystem } from "./ecs/systems/PveSvrBuildSystem";
import { PveSvrBulletSystem } from "./ecs/systems/PveSvrBulletSystem";
import { PveSvrCloudSystem } from "./ecs/systems/PveSvrCloudSystem";
import { PveSvrCollectSystem } from "./ecs/systems/PveSvrCollectSystem";
import { PveSvrCommandSystem } from "./ecs/systems/PveSvrCommandSystem";
import { PveSvrFightingSystem } from "./ecs/systems/PveSvrFightingSystem";
import { PveSvrGameDefeatSystem } from "./ecs/systems/PveSvrGameDefeatSystem";
import { PveSvrMapTransferSystem } from "./ecs/systems/PveSvrMapTransferSystem";
import { PveSvrMovementSystem } from "./ecs/systems/PveSvrMovementSystem";
import { PveSvrNaviArrowSystem } from "./ecs/systems/PveSvrNaviArrowSystem";
import { PveSvrNpcSystem } from "./ecs/systems/PveSvrNpcSystem";
import { PveSvrProbingSystem } from "./ecs/systems/PveSvrProbingSystem";
import { PveSvrRescueSoldierSystem } from "./ecs/systems/PveSvrRescueSoldierSystem";
import { PveSvrRevivalSystem } from "./ecs/systems/PveSvrRevivalSystem";
import { PveSvrSkillLauncherSystem } from "./ecs/systems/PveSvrSkillLauncherSystem";
import { PveSvrSkillSystem } from "./ecs/systems/PveSvrSkillSystem";
import { PveSvrSkillWarningSystem } from "./ecs/systems/PveSvrSkillWarningSystem";
import { PveSvrSpoilsSystem } from "./ecs/systems/PveSvrSpoilsSystem";
import { PveSvrStateSystem } from "./ecs/systems/PveSvrStateSystem";
import { PveSvrTerritorySystem } from "./ecs/systems/PveSvrTerritorySystem";
import { PveSvrVFXSystem } from "./ecs/systems/PveSvrVFXSystem";
import { AttackType, DamageData, DamagePipeline } from "./utils/skill/DamagePipeline";
import { SkillSystemUtils } from "./utils/skill/SkillSystemUtils";

const tmpVelocity = new Laya.Vector3();

const tmpT3d: Laya.Transform3D = new Laya.Transform3D();

export interface IPveServer {
    get destroyed(): boolean;
    get receiver(): PveSvrReceiver;
    destroy(): void;
    update(dt: number): void;
    doStart(): void;
}

export enum PVEServerState {
    Init = 0,
    Starting,
    Started,

    Running, // 运行中
    Pause, // 暂停

    End,
}

export class PveServer extends Context implements IPveServer {
    static create(sender: IPveReceiver): IPveServer {
        return new PveServer(sender);
    }

    // 玩家控制的角色eid ---后面用troopEid来取代，这个字段暂时保留
    get ctrlHeroEid(): number {
        return this.ecs.getComponent(this.troopEid, PveSvrTroopComponent)!.leaderEid;
    }

    get ctrlHero(): PveSvrElementComponent | undefined {
        return this.ecs.getComponent(this.ctrlHeroEid, PveSvrElementComponent);
    }

    private _damagePipeline: DamagePipeline;

    private _ecs: ecs.World;
    private _destroyed: boolean = false;
    private _eidCount: number = 0;

    private _sender: IPveReceiver;

    private _receiver: PveSvrReceiver;

    get sender(): IPveReceiver {
        return this._sender;
    }

    private _aiTrees: Map<string, Tree> = new Map();
    private _stanceMap: Map<number, PveSvrElementComponent> = new Map();
    readonly elements: Map<number, PveSvrElementComponent> = new Map();

    private _troopEid: number = 0;

    // 玩家控制的主线部队eid
    get troopEid() {
        return this._troopEid;
    }

    // 设置玩家控制的主线部队eid, 只能设置一次
    private setTroopEid(eid: number) {
        this._troopEid = eid;
    }

    private _state: PVEServerState = PVEServerState.Init;

    get state(): PVEServerState {
        return this._state;
    }

    protected constructor(sender: IPveReceiver) {
        super();
        this._sender = sender;

        this._ecs = new ecs.World(this);
        this._ecs.addSingletonComponent(PveSvrAStarComponent);
        this._ecs.addSingletonComponent(PveSvrCommandComponent);
        this._ecs.addSystem(PveSvrCommandSystem); //command系统必须在最前面
        this._ecs.addSystem(PveSvrAiSystem);
        this._ecs.addSystem(PveSvrMovementSystem);
        this._ecs.addSystem(PveSvrSkillLauncherSystem);
        this._ecs.addSystem(PveSvrSkillSystem);
        this._ecs.addSystem(PveSvrBulletSystem);
        this._ecs.addSystem(PveSvrSpoilsSystem);
        this._ecs.addSystem(PveSvrAStarSystem);
        this._ecs.addSystem(PveSvrStateSystem);
        this._ecs.addSystem(PveSvrSkillWarningSystem);
        this._ecs.addSystem(PveSvrRevivalSystem);
        this._ecs.addSystem(PveSvrMapTransferSystem);
        this._ecs.addSystem(PveSvrCollectSystem);
        this._ecs.addSystem(PveSvrNaviArrowSystem);
        this._ecs.addSystem(PveSvrGameDefeatSystem);
        this._ecs.addSystem(PveSvrRescueSoldierSystem);
        this._ecs.addSystem(PveSvrTerritorySystem);
        this._ecs.addSystem(PveSvrProbingSystem);
        this._ecs.addSystem(PveSvrBuildSystem);
        this._ecs.addSystem(PveSvrNpcSystem);
        this._ecs.addSystem(PveSvrFightingSystem);
        this._ecs.addSystem(PveSvrVFXSystem);

        this._receiver = new PveSvrReceiver(
            this._ecs.getSingletonComponent(PveSvrCommandComponent).commands
        );

        this._damagePipeline = new DamagePipeline(this);
    }

    // ExportNodes需要显式调用destroy，但是不需要保存任何东西
    destroy(isSave = true) {
        isSave && PveSvrStateSystem.save(this.ecs);
        this._ecs.destroy();
        this._destroyed = true;
        this._sender = null!;
    }

    get destroyed() {
        return this._destroyed;
    }

    // 指的是pveServer这个系统的运行时间
    override get time() {
        return this._ecs.time;
    }

    get receiver(): PveSvrReceiver {
        return this._receiver;
    }

    get ecs() {
        return this._ecs;
    }

    obtainEid() {
        return ++this._eidCount;
    }

    isFreeStance(element: PveSvrElementComponent, position: Laya.Vector3) {
        for (const target of this._stanceMap.values()) {
            if (target.aid === element.aid && target !== element) {
                const distance = Laya.Vector3.distanceXZ(target.transform.position, position);
                if (distance < 0.3) {
                    return false;
                }
            }
        }
        return true;
    }

    clearStance(element: PveSvrElementComponent) {
        this._stanceMap.delete(element.eid);
    }

    setStance(element: PveSvrElementComponent) {
        this._stanceMap.set(element.eid, element);
    }

    update(delta: number) {
        if (this.state === PVEServerState.Running) {
            this._ecs.update(delta);
        }
        this._drawDebug();
    }

    async loadAiTree(res: string) {
        let tree = this._aiTrees.get(res); // 从缓存中获取
        if (!tree) {
            const data = await app.loader.loadJson<TreeData>(res);
            // 重新获取，有可能同时加载，前面一个已经完成了
            tree = this._aiTrees.get(res);
            if (!tree) {
                tree = new Tree(this, data);
                this._aiTrees.set(res, tree);
            }
        }

        return tree;
    }

    private _drawDebug() {
        this._ecs.getComponents(PveSvrTransformComponent).forEach((value) => {
            const ai = value.getComponent(PveSvrAiComponent);
            if (ai && ai.tree?.env.debug) {
                this._sender.drawDebug(value.position.x, value.position.z, 5, 0x00ff00);
            } else {
                this._sender.drawDebug(value.position.x, value.position.z, 5, 0xff0000);
            }
        });
    }

    findElement(eid: number): PveSvrElementComponent | undefined {
        return eid ? this.ecs.getComponent(eid, PveSvrElementComponent) : undefined;
    }

    find(filter: (element: PveSvrElementComponent) => boolean) {
        let arr: PveSvrElementComponent[] | null = null;
        for (const v of this.ecs.getComponents(PveSvrElementComponent).values()) {
            if (filter(v)) {
                arr ||= [];
                arr.push(v);
            }
        }
        return arr;
    }

    calcFollowerPosition(
        transform: PveSvrTransformComponent,
        offset: IVector3Like,
        out: Laya.Vector3
    ) {
        tmpT3d.localPosition = transform.position;
        tmpT3d.localRotationEulerY = transform.rotation;
        out.cloneFrom(offset);
        tmpT3d.localToGlobal(out, out);
    }

    /**
     * 指定位置是否阻挡块或者迷雾云
     * @param x X坐标
     * @param z Z坐标
     * @param isAndCloud — true的话则包含云
     */
    isBlockAt(x: number, z: number, isAndCloud: boolean): boolean {
        const astarComponent = this.ecs.getSingletonComponent(PveSvrAStarComponent);
        return astarComponent.pveAStarData.isBlockAt(x, z, isAndCloud);
    }

    /**
     * 找到最接近的一个可以过去的寻路点
     * @param hero 主角
     * @param expectPos 期望的目标位置，但是是阻挡格
     * @param rightAnglePos 直角点的位置
     * @param out 随从需要移动的目标位置
     */
    findCloserMoveVaildPoint(
        hero: PveSvrElementComponent,
        expectPos: Laya.Vector3,
        rightAnglePos: Laya.Vector3,
        out: Laya.Vector3
    ): boolean {
        const astarComponent = this.ecs.getSingletonComponent(PveSvrAStarComponent);
        return astarComponent.pveAStarData.findCloserMoveVaildPoint(
            hero,
            expectPos,
            rightAnglePos,
            out
        );
    }

    /**
     * 查找路径
     * @param startX 开始X坐标
     * @param startY 开始Y坐标
     * @param endX 结束X坐标
     * @param endY 结束Y坐标
     * @param out 寻路结果输出到这个2d数组，注意使用完成需要 Pool.free ，路点包括开始点和结束点
     * @returns -1：不可到达目标点 0：开始点与目标点一致 其他：路径的长度
     */
    astarFindpath(
        startX: number,
        startY: number,
        endX: number,
        endY: number,
        out: Laya.Vector2[]
    ): number {
        const astarComponent = this.ecs.getSingletonComponent(PveSvrAStarComponent);
        return astarComponent.pveAStarData.astar.findPath(
            startX << 0,
            startY << 0,
            endX << 0,
            endY << 0,
            out
        );
    }

    /** 设置期望去的位置 */
    setExpectGoToTargetData(
        unit: PveSvrElementComponent,
        isLocalToHero: boolean,
        targetX: number,
        targetZ: number
    ): void {
        const ai = unit.getComponent(PveSvrAiComponent);
        if (ai && ai.tree) {
            let posData = ai.tree.env.get(PveDef.POS_DATA) as ExpectGoToTargetData | undefined;
            if (!posData) {
                posData = new ExpectGoToTargetData();
                ai.tree.env.set(PveDef.POS_DATA, posData);
            }
            posData.isLocalToHero = isLocalToHero;
            posData.targetX = targetX;
            posData.targetZ = targetZ;
        }
    }

    //-------------------------------------------------------------------------
    //------------------------------ICommandReceiver---------------------------
    //-------------------------------------------------------------------------
    public initSkill(
        owner: PveSvrElementComponent,
        launcher: PveSvrSkillLauncherComponent,
        isSkill: boolean,
        index: number,
        skillId?: number
    ) {
        if (skillId === undefined) {
            return;
        }

        const map = isSkill ? launcher.skills : launcher.atkSkills;
        if (map[index]) {
            console.error("技能已经存在", index, skillId, isSkill, map);
            return;
        }
        const skillRow = app.service.table.battleSkill.skill[skillId];
        if (!skillRow) {
            console.error("技能配置不存在", index, skillId, isSkill, map);
            return;
        }
        map[index] = new Skill(skillRow, owner);
    }

    /** 开始加载服务端记录的场景数据 */
    async doStart() {
        this._state = PVEServerState.Starting;
        // 等待场景数据加载完毕之后，进行开始建立场景
        await PveSvrStateSystem.load(this.ecs);
        this.start();

        // this.state = PVEServerState.Started; //现在无需判断暂停状态，先直接进入Running状态
        this._state = PVEServerState.Running;
    }

    private start() {
        PveSvrCloudSystem.setClearCloudsData(this, undefined);

        const troopComp = PveSvrBattle.createTroopEntity(this, true);
        this.setTroopEid(troopComp.eid);

        const heroElement = PveSvrBattle.initHero(this);
        troopComp.leaderEid = heroElement.eid;
        heroElement.troopEid = troopComp.eid;

        PveSvrBattle.initSoliderGroups(this, heroElement);
        PveSvrBattle.initTrucks(this, heroElement);
        PveSvrBattle.initSpoils(this);

        this._sender.setCtrlHeroEid(this.ctrlHeroEid);
    }

    /** 获取指定英雄带领的车子 */
    public getHeroTrucks(heroid: number): PveSvrTruckComponent[] {
        const ret: PveSvrTruckComponent[] = [];
        let troopComp: PveSvrTroopComponent | undefined = undefined;
        const troopComponents = this.ecs.getComponents(PveSvrTroopComponent);
        for (const [k, v] of troopComponents) {
            if (v.leaderEid === heroid) {
                troopComp = v;
                break;
            }
        }
        if (troopComp) {
            for (const etype in troopComp.truckEids) {
                const truckEid = troopComp.truckEids[etype];
                const truckComp = this.ecs.getComponent(truckEid, PveSvrTruckComponent);
                if (truckComp) {
                    ret.push(truckComp);
                }
            }
        }

        return ret;
    }

    /**
     *  添加一名士兵
     * hero 跟随的主角
     * idx 0~11 十二个士兵位置
     * tid 对应 soldider 表的id
     * hp 当前血量
     * maxHp 总血量
     *
     */
    public addSoldierWith(
        heroEle: PveSvrElementComponent,
        idx: number,
        tid: number,
        px: number,
        pz: number,
        hp?: number
    ) {
        return PveSvrBattle.addSoldierWith(this, heroEle, idx, tid, px, pz, hp);
    }

    /**
     * 获取指定队长带领的所有随从实体
     * 可能是资源车或者士兵
     */
    getAllMembersWithLeader(leaderEid: number): PveSvrElementComponent[] {
        const ret: PveSvrElementComponent[] = [];
        const troopComp = PveSvrUtils.getTroopByLeaderEid(this, leaderEid);
        if (troopComp) {
            this.ecs.getComponents(PveSvrElementComponent).forEach((eleComp) => {
                if (eleComp.troopEid === troopComp.eid) {
                    ret.push(eleComp);
                }
            });
        }

        return ret;
    }

    /**
     * 获取新加入士兵应该跟随的阵位位置
     * 返回-1表示没有合适的位置
     * //以pveStateData为判断数据源
     *  */
    public getEmptySoldierPosByStation(station: TroopStation): number {
        return app.service.pve.mlData.pveStateData.getEmptySoldierPosByStation(station);
    }

    removeElement(element: PveSvrElementComponent) {
        const eid = element.eid; //先缓存下来，后面需要

        this.clearStance(element);
        this.elements.delete(element.key);
        this.ecs.removeEntity(element.eid);
        this._sender.removeElement(eid);
    }

    rushStart(element: PveSvrElementComponent): void {
        this._sender.rushStart(element.eid);
    }

    moveVolecity(element: PveSvrElementComponent, velocity: Laya.Vector3) {
        const movement = element.getComponent(PveSvrMovementComponent);
        if (!movement) {
            console.error("moveStart: movement is null", element);
            return;
        }

        movement.updateType = MovementUpdateType.VELOCITY;
        movement.trackType = TrackType.NONE;
        velocity.y = 0;
        movement.velocity.cloneFrom(velocity);

        if (velocity.x !== 0 || velocity.z !== 0) {
            element.transform.rotation = Math.toDegree(Math.atan2(velocity.x, velocity.z));
            this.sender.setRotation(element.eid, element.transform.rotation, false);
        }

        movement.state = MovementState.START;
        this.playAnim(element.eid, AnimName.MOVE);
    }

    moveTo(element: PveSvrElementComponent, targetPos: Laya.Vector3, speed: number) {
        const movement = element.getComponent(PveSvrMovementComponent);
        if (!movement) {
            console.error("moveTo: movement is null", element);
            return;
        }

        movement.speed = speed;
        movement.targetPosition.cloneFrom(targetPos);
        movement.updateType = MovementUpdateType.TARGET_POSITION;

        const p0 = element.transform.position.clone();
        const p2 = targetPos.clone();

        if (movement.etype === BattleEntityType.BULLET) {
            const bulletComp = element.getComponent(PveSvrBulletComponent)!;
            p0.y = bulletComp.h;
            p2.y = bulletComp.h;
            const duration = Laya.Vector3.distanceXZ(p0, p2) / movement.speed;

            if (bulletComp.bulletType === BulletType.Fly) {
                const p1 = new Laya.Vector3(
                    (p0.x + p2.x) / 2,
                    (p0.y + p2.y) / 2 + 0.5,
                    (p0.z + p2.z) / 2
                );
                const cp = BezierHelper.getCtrlPoint(p0, p1, p2);

                movement.track = {
                    time: 0,
                    duration,
                    p0,
                    p1,
                    p2,
                    cp,
                };
                movement.trackType = TrackType.CURVE;
            } else {
                movement.track = {
                    time: 0,
                    duration,
                    p0,
                    p2,
                };
                movement.trackType = TrackType.LINE;
            }
        } else {
            movement.trackType = TrackType.NONE;
        }

        const dx = p2.x - p0.x;
        const dz = p2.z - p0.z;
        if (dx !== 0 || dz !== 0) {
            element.transform.rotation = PveMoveUtils.calcuRotation(dx, dz);
            this.sender.setRotation(element.eid, element.transform.rotation, true);
        }

        movement.state = MovementState.START;
        this.playAnim(element.eid, AnimName.MOVE);
    }

    moveFollow(element: PveSvrElementComponent, targetEid: number, duration: number) {
        const movement = element.getComponent(PveSvrMovementComponent);
        if (!movement) {
            console.error("moveStart: movement is null", element);
            return;
        }
        movement.updateType = MovementUpdateType.FOLLOW_TARGET;
        movement.trackType = TrackType.NONE;
        movement.followTarget.targetEid = targetEid;
        movement.followTarget.duration = duration;
        movement.followTarget.ratio = 0;
        movement.state = MovementState.START;
        this.playAnim(element.eid, AnimName.MOVE);
    }

    moveStop(eid: number) {
        const movement = this.ecs.getComponent(eid, PveSvrMovementComponent);
        const transform = this.ecs.getComponent(eid, PveSvrTransformComponent);
        if (!movement || !transform) {
            return;
        }
        movement.state = MovementState.STOP;
        movement.updateType = MovementUpdateType.NONE;
        this._sender.moveStop(eid, transform.position);
    }

    override on(event: string, callback: Callback<unknown[]>, caller: object): void;

    override on(event: string, target: number, callback: Callback<unknown[]>, caller: object): void;

    override on(
        event: string,
        callbackOrTarget: number | Callback<unknown[]>,
        callerOrCallback: Callback<unknown[]>,
        caller?: object | undefined
    ): void {
        super.on.call(this, event, callbackOrTarget, callerOrCallback, caller!);
    }

    override dispatchTarget(target: number, event: string, ...args: unknown[]): void {
        super.dispatchTarget(target, event, ...args);
    }

    dispatchAll(target: number, event: string, ...args: unknown[]): void {
        super.dispatch(event, ...args);
        super.dispatchTarget(target, event, ...args);
    }

    dispatchTrigger(elementEid: number, trigger: EventTrigger) {
        this._sender.dispatch(elementEid, trigger);
    }

    launchBullet(skill: Skill, bulletEntity: number, args: ILaunchBulletArgs) {
        return PveSvrBattle.launchBullet(this, skill, bulletEntity, args);
    }

    towardTo(element: PveSvrElementComponent, target: PveSvrElementComponent) {
        const p1 = element.transform.position;
        const p2 = target.transform.position;
        const x = p2.x - p1.x;
        const z = p2.z - p1.z;

        if (x !== 0 || z !== 0) {
            const rad = Math.atan2(x, z);
            const dest = (rad * 180) / Math.PI;
            this.setRotation(element, dest, false);
        }
    }

    private setRotation(
        element: PveSvrElementComponent,
        rotation: number,
        immediately: boolean = true
    ) {
        const { transform } = element;
        transform.rotation = rotation;
        this._sender.setRotation(element.eid, rotation, immediately);
    }

    setPosition(element: PveSvrElementComponent, position: Laya.Vector3) {
        element.transform.position.cloneFrom(position);
        this._sender.setPosition(element.eid, position);
    }

    playAnim(eid: number, anim: string) {
        this._sender.playAnim(eid, anim);
    }

    hurt(
        skill: Skill,
        defender: PveSvrCreatureComponent,
        attackType: AttackType,
        damage: number = 0
    ): void {
        if (!skill.owner.checker()) {
            return; //攻击者已经无效
        }

        switch (attackType) {
            case AttackType.Skill: {
                const skillRatio = (skill.data.coefficient[skill.lv - 1] ?? 0) / 100;
                this._damagePipeline.skillAtk(skill.owner.eid, defender.eid, skillRatio);
                break;
            }
            case AttackType.Normal:
                this._damagePipeline.normalAtk(skill.owner.eid, defender.eid);
                break;
            case AttackType.Pure:
                this._damagePipeline.pureAtk(skill.owner.eid, defender.eid, damage);
                break;
            default:
                break;
        }
    }

    heal(skill: Skill, enemy: PveSvrCreatureComponent, ratio: number = 1) {
        PveSvrBattle.heal(this, skill, enemy, ratio);
    }

    onGetHurt(damageData: DamageData) {
        // PveSvrBattle.onGetHurt(this, skill, attacker, ratio);
    }

    onDecreseHp(defenderEid: number, damage: number, isCrit: boolean) {}

    stopMoveAll(): void {
        const movements = this.ecs.getComponents(PveSvrMovementComponent);
        movements.forEach((movement) => {
            const creature = movement.getComponent(PveSvrCreatureComponent);
            // 死亡状态下，会播放死亡动画，move stop会播放idle动画
            if (creature && creature.isDie) {
                return;
            }
            this.moveStop(movement.eid);
            this.playAnim(movement.eid, AnimName.IDLE);
        });
    }

    /** 复活采集物(树木，粮草，石头) */
    reliveCollection(recordData: PveSvrCollectionStateData): void {
        const element = this.elements.get(recordData.key);
        if (element) {
            const healthComp = element.getComponent(PveSvrCollectionComponent)!;
            const buildingVo = app.service.pve.buildingVoMap.get(recordData.tid)!;
            healthComp.amount = buildingVo.collectAmount;
            //其实不是血量，而是采集进度
            healthComp.remain = recordData.hp;
            healthComp.max = recordData.maxHp;
            this._sender.updateHp(element.eid, {
                hp: healthComp.remain,
                maxHp: healthComp.max,
                subHp: 0,
            });
        }
        // const position = new Laya.Vector3(recordData.xIdx + 0.5, 0, recordData.yIdx + 0.5);
        // this.addCollection(recordData.tid, position);
    }

    /** 复活怪物编队*/
    reliveMonsterTroop(data: PveSvrMonsterTroopStateData): void {
        data.deathTime = 0; // 复活重置
        data.monsters.length = 0;
        const values = TMUtil.decodeKey(data.key);
        const position = new Laya.Vector3(values.x, 0, values.z);
        this.addMonsterTroop(data.key, data.tid, position);
    }

    /** 查找出静态的事件或者建筑 */
    findStaticElementByKey(key: number): PveSvrElementComponent | undefined {
        for (const comp of this._ecs.getComponents(PveSvrElementComponent).values()) {
            if (comp.key === key) {
                return comp;
            }
        }
        return undefined;
    }

    //#region command
    //-------------------------------------------------------------------------
    // 接收前端指令
    //-------------------------------------------------------------------------
    joystickStart(eid: number, dir: Laya.Vector3) {
        const element = this.findElement(eid);
        if (!element) {
            console.warn(`not found element: ${eid}`);
            return;
        }

        const creatureComp = element.getComponent(PveSvrCreatureComponent);
        if (creatureComp && creatureComp.hp <= 0) {
            return;
        }

        const isImprisonment = SkillSystemUtils.isImprisonment(this, element.eid);
        if (isImprisonment) {
            // 被禁锢不可操作
            return;
        }

        if (!app.service.network.connected) {
            // 网络未连接，不可操作。
            return;
        }

        if (app.service.plotTheatre.showPlotTheatreing) {
            // 播放剧情中不可操作
            return;
        }

        const ai = element.getComponent(PveSvrAiComponent)!;
        if (ai.active) {
            PveSvrUtils.interuptAITree(ai);
            ai.active = false;
        }

        const movement = element.getComponent(PveSvrMovementComponent)!;
        if (movement.ctrlType !== MovementCtrlType.WHEEL) {
            movement.ctrlType = MovementCtrlType.WHEEL;

            // 开始为所有队友标记为队长手操中标记
            const members = this.getAllMembersWithLeader(eid);
            for (let i = 0; i < members.length; i++) {
                const member = members[i];
                const memberAi = member.getComponent(PveSvrAiComponent);
                if (memberAi && memberAi.tree) {
                    memberAi.tree.env.set(PveDef.START_WHEEL_FLAG, true);
                }
            }
        }

        if (movement.state === MovementState.STOP) {
            // 开始移动
            PveSvrProbingSystem.onHeroStartMove(this, eid);
        }

        tmpVelocity.cloneFrom(dir);
        tmpVelocity.scale(BattleConf.PVE.MOVE_SPEED, tmpVelocity);
        this.moveVolecity(element, tmpVelocity);
    }

    click(x: number, z: number) {
        this.ecs.getComponents(PveSvrElementComponent).forEach((element) => {
            const ai = element.getComponent(PveSvrAiComponent);
            if (!ai || !ai.tree) {
                return;
            }
            const p1 = new Laya.Vector3(x, 0, z);
            const p2 = element.transform.position; // 不是所有的都有transform
            if (Laya.Vector3.distanceXZ(p1, p2) < 0.2) {
                ai.tree.env.debug = !ai.tree.env.debug;
            }
        });
    }

    joystickStop(eid: number, activeAI: boolean) {
        const element = this.ecs.getComponent(eid, PveSvrElementComponent);
        if (!element) {
            console.warn(`not found element: ${eid}`);
            return;
        }
        if (activeAI) {
            const ai = element.getComponent(PveSvrAiComponent);
            if (ai) {
                ai.active = true;
            }
        }

        const creatureComp = element.getComponent(PveSvrCreatureComponent);
        if (creatureComp && creatureComp.hp <= 0) {
            return;
        }

        const movement = element.getComponent(PveSvrMovementComponent)!;
        if (movement.ctrlType === MovementCtrlType.WHEEL) {
            movement.ctrlType = MovementCtrlType.AI;
            // 开始为所有队友取消队长手操中标记
            const members = this.getAllMembersWithLeader(eid);
            for (let i = 0; i < members.length; i++) {
                const member = members[i];
                const memberAi = member.getComponent(PveSvrAiComponent);
                if (memberAi && memberAi.tree) {
                    memberAi.tree.env.set(PveDef.START_WHEEL_FLAG, undefined);
                }
            }
        }
        this.moveStop(element.eid);
        this.playAnim(element.eid, AnimName.IDLE);
    }

    addNpc(key: number, tid: number, x: number, z: number, rotation: number) {
        if (this.elements.has(key)) {
            return;
        }

        const pveStateData = app.service.pve.mlData.pveStateData;
        const recordData = pveStateData.getNpcByKey(key);
        if (recordData) {
            const npcState = PveSvrNpcSystem.getNPCState(this, key, tid);
            if (npcState === PveNpcStateType.NPC_STATE_LEAVE) {
                // 处于移除状态的不添加
                recordData.inView = true;
                return;
            }
        }

        const table = app.service.table;
        const tableCfg = table.battleNpc[tid];
        const battle_entity_id = tableCfg.battle_entity;
        const battle_entity_cfg = table.battleEntity[battle_entity_id];
        if (battle_entity_cfg.etype !== BattleEntityType.NPC) {
            console.error(
                "addNpc: battle_entity_cfg.etype !== BattleEntityType.NPC",
                battle_entity_cfg.etype
            );
            return;
        }

        const etype = battle_entity_cfg.etype;

        const entity = this._ecs.createEntity(this.obtainEid(), etype);

        const element = entity.addComponent(PveSvrElementComponent);
        element.tag = ElementTag.NONE;
        element.tid = tid;
        element.data = battle_entity_cfg;
        element.aid = BattleAid.NEUTRAL;
        element.key = key;
        element.spawnpoint.set(x, 0, z);
        this.elements.set(key, element);

        const transform = entity.addComponent(PveSvrTransformComponent);
        transform.position.x = x;
        transform.position.z = z;
        transform.rotation = rotation;

        entity.addComponent(PveSvrMovementComponent);

        const pve_ai = element.data.pve_ai;
        if (pve_ai) {
            const ai = entity.addComponent(PveSvrAiComponent);
            ai.btree = `resources/data/btree/${pve_ai}.json`;
        }

        if (tableCfg.probing_type && tableCfg.probing_range && tableCfg.probing_range !== "0") {
            const probingComp = entity.addComponent(PveSvrProbingComponent);
            probingComp.probingType = tableCfg.probing_type;
            probingComp.probing_range = tableCfg.probing_range;
        }

        this._sender.createElement({
            eid: element.eid,
            etype: element.etype,
            key: element.key,
            aid: element.aid,
            teid: element.data.id,
            tid: element.tid,
            hp: -1,
            maxHp: -1,
            position: transform.position,
            rotation: transform.rotation,
            level: -1,
        });
    }

    addMonsterTroop(key: number, tid: number, position: Laya.Vector3) {
        PveSvrBattle.addMonsterTroop(this, key, tid, position);
    }

    addBuilding(key: number, tid: number, position: Laya.Vector3) {
        if (this.elements.has(key)) {
            return;
        }
        const table = app.service.table;
        const pveStateData = app.service.pve.mlData.pveStateData;
        const recordData = pveStateData.getBuildingByKey(key)!;
        let upgradeTime = 0;
        if (recordData.upgradeTime) {
            upgradeTime = recordData.upgradeTime;
        }
        let level = 0; // 默认等级是0
        if (recordData && recordData.level) {
            level = recordData.level;
        }

        let upgradeing: boolean = false;
        // 判断建筑是否升级中以及处理建筑后台完成
        if (upgradeTime && recordData) {
            const buildingRowCfg = recordData.levelCfg;
            const needTime = buildingRowCfg.upgrade_need_time ?? 0;
            const upgradeEndTime = upgradeTime + needTime;
            const curTime = app.service.network.serverTime;
            const upgradeRemainTime = upgradeEndTime - curTime;
            if (upgradeRemainTime > 0) {
                upgradeing = true;
            } else {
                // 升级已在后台完成
                if (recordData) {
                    PveSvrBuildSystem.onBuildingUpgradeComplete(this, recordData, upgradeEndTime);
                }
            }
        }

        let buildingRow: BattleBuildingRow;
        if (recordData) {
            buildingRow = recordData.levelCfg;
        } else {
            buildingRow = table.battleBuilding[tid];
        }
        const entityRow = table.battleEntity[buildingRow.battle_entity];
        const entity = this._ecs.createEntity(this.obtainEid(), entityRow.etype);

        const element = entity.addComponent(PveSvrElementComponent);
        element.tid = tid;
        element.aid = BattleAid.SELF;
        element.key = key;
        element.spawnpoint.cloneFrom(position);
        element.data = entityRow;
        this.elements.set(key, element);

        const transform = entity.addComponent(PveSvrTransformComponent);
        transform.position.x = position.x;
        transform.position.z = position.z;

        /** 新增探测英雄组件 */
        if (
            buildingRow.probing_type &&
            buildingRow.probing_range &&
            buildingRow.probing_range !== "0"
        ) {
            const probingComp = entity.addComponent(PveSvrProbingComponent);
            probingComp.probingType = buildingRow.probing_type;
            probingComp.probing_range = buildingRow.probing_range;
        }

        entity.addComponent(PveSvrBuildingComponent);

        if (
            buildingRow.building_type === PveBuildingType.REVIVAL ||
            buildingRow.building_type === PveBuildingType.REVIVAL_UPGRADE
        ) {
            // 士兵救援点
            entity.addComponent(PveSvrRevivalComponent);
        } else if (buildingRow.building_type === PveBuildingType.ASSETS_WAREHOUSE) {
            // 资源回收站
            entity.addComponent(PveSvrRecycleComponent);
        } else if (buildingRow.building_type === PveBuildingType.MAP_TRANSFER) {
            // 传送门
            entity.addComponent(PveSvrMapTransferComponent);
        } else if (buildingRow.building_type === PveBuildingType.PRODUCTION_FACTORY) {
            // 伐木场/矿场/麦田   (可建造和升级的资源工厂)
            entity.addComponent(PveSvrProductionFactoryComponent);
        } else if (buildingRow.building_type === PveBuildingType.OPEN_FUNCTION) {
            // (可打开指定功能)
            entity.addComponent(PveSvrOpenFunctionComponent);
        }

        this._sender.createElement({
            eid: element.eid,
            etype: element.etype,
            key: element.key,
            aid: element.aid,
            teid: element.data.id,
            tid: element.tid,
            hp: -1,
            maxHp: -1,
            position: transform.position,
            rotation: transform.rotation,
            level: level,
        });

        // 建筑是否升级中
        if (upgradeing) {
            this._sender.startUpgradeBuilding(element.eid, upgradeTime);
        }
    }

    removeSpoils(tid: number, position: Laya.Vector3) {
        const spolis = this.find((element) => element.tag === ElementTag.SPOILS);
        if (spolis) {
            for (const v of spolis) {
                if (v.tid === tid) {
                    const tfComp = v.getComponent(PveSvrTransformComponent)!;
                    if (tfComp.position.x === position.x && tfComp.position.z === position.z) {
                        this.removeElement(v);
                        return;
                    }
                }
            }
        }
    }

    addCollection(key: number, tid: number, position: Laya.Vector3) {
        if (this.elements.has(key)) {
            return;
        }
        const table = app.service.table;
        const buildingVo = app.service.pve.buildingVoMap.get(tid)!;
        const entityRow = table.battleEntity[buildingVo.battleEntity];

        const pveStateData = app.service.pve.mlData.pveStateData;
        const cd = buildingVo.customData!;
        let hp: number = cd.max_hp ?? 0;
        let maxHp: number = cd.max_hp ?? 0;
        const recordData = pveStateData.getCollectionByKey(key)!;
        if (!recordData.tid) {
            recordData.tid = tid;
            recordData.xIdx = position.x;
            recordData.yIdx = position.z;
            recordData.hp = hp;
            recordData.maxHp = maxHp;
        } else {
            hp = recordData.hp;
            maxHp = recordData.maxHp;
        }

        const entity = this._ecs.createEntity(this.obtainEid(), entityRow.etype);
        const element = entity.addComponent(PveSvrElementComponent);
        element.key = key;
        element.tid = tid;
        element.aid = BattleAid.ENEMY;
        element.spawnpoint.cloneFrom(position);

        const collectionComp = element.addComponent(PveSvrCollectionComponent);
        collectionComp.remain = hp;
        collectionComp.max = maxHp;
        collectionComp.amount = buildingVo.collectAmount;

        if (entity.etype === BattleEntityType.WOOD) {
            element.tag = ElementTag.WOOD;
        } else if (entity.etype === BattleEntityType.STONE) {
            element.tag = ElementTag.STONE;
        } else {
            element.tag = ElementTag.FOOD;
        }

        element.data = entityRow;
        this.elements.set(key, element);

        const transform = entity.addComponent(PveSvrTransformComponent);
        transform.position.x = position.x;
        transform.position.z = position.z;
        transform.rotation = 0;

        this._sender.createElement({
            eid: element.eid,
            etype: element.etype,
            key: element.key,
            aid: element.aid,
            teid: element.data.id,
            tid: element.tid,
            hp: collectionComp.remain,
            maxHp: collectionComp.max,
            position: transform.position,
            rotation: transform.rotation,
            level: -1,
        });
    }

    // public getEventElement(xIdx: number, yIdx: number): PveSvrElementComponent | undefined {
    //     const elementKey = this.toEventElementKey(xIdx + 0.5, yIdx + 0.5);
    //     return this.elements.get(elementKey);
    // }

    addEvent(key: number, tid: number, position: Laya.Vector3) {
        if (this.elements.has(key)) {
            return;
        }

        const pveStateData = app.service.pve.mlData.pveStateData;
        const recordData = pveStateData.getEventByKey(key);

        if (recordData) {
            recordData.tid = tid;
            recordData.xIdx = position.x | 0;
            recordData.yIdx = position.z | 0;
        }

        if (recordData && recordData.isDeath) {
            // 死亡的不添加
            recordData.inView = true;
            return;
        }

        const eventVo = app.service.pve.eventLoMap.get(tid)!;
        if (!eventVo) {
            console.log("事件表找不到id:" + tid + ",请检查！");
            return;
        }
        const entityRow = eventVo.entityRow;
        const entity = this._ecs.createEntity(this.obtainEid(), entityRow.etype);

        eventVo.addProbingComponent(entity);

        const element = entity.addComponent(PveSvrElementComponent);
        element.tid = tid;
        element.aid = BattleAid.NEUTRAL;
        element.key = key;
        element.data = entityRow;
        element.spawnpoint.cloneFrom(position);
        this.elements.set(key, element);

        const event = entity.addComponent(PveSvrEventComponent);
        event.data = eventVo;

        const transform = entity.addComponent(PveSvrTransformComponent);
        transform.position.x = position.x;
        transform.position.z = position.z;

        this._sender.createElement({
            eid: element.eid,
            etype: element.etype,
            key: element.key,
            aid: element.aid,
            teid: element.data.id,
            tid: element.tid,
            position: transform.position,
            rotation: transform.rotation,
            level: -1,
        });
    }

    grubItem(eid: number) {
        const hero = this.ecs.getComponent(this.ctrlHeroEid, PveSvrElementComponent);
        const event = this.ecs.getComponent(eid, PveSvrElementComponent);
        if (hero && event) {
            // 设置事件的触发时间
            const nowTime = app.service.network.serverTime;
            const pveStateData = app.service.pve.mlData.pveStateData;
            pveStateData.setEventTriggerTime(event, nowTime << 0);
            this._sender.grubChest(eid);
            this.removeElement(event);

            // 拾取宝箱后马上进行保存状态
            PveSvrStateSystem.save(this.ecs);
        }
    }

    removePlotTheatreEvent(eid: number) {
        const event = this.ecs.getComponent(eid, PveSvrElementComponent);
        if (event) {
            const nowTime = app.service.network.serverTime;
            const pveStateData = app.service.pve.mlData.pveStateData;
            pveStateData.setEventTriggerTime(event, nowTime << 0);
            this.removeElement(event);
        }
    }

    grubSpoils(tid: number, position: Laya.Vector3) {
        PveSvrSpoilsSystem.grubSpoils(this, tid, position);
    }

    navigationArrowPointTo(
        eid: number,
        posO: Laya.Vector3,
        targetPosA: Laya.Vector3,
        targetPosB: Laya.Vector3
    ): void {
        PveSvrNaviArrowSystem.navigationArrowPointTo(this, eid, posO, targetPosA, targetPosB);
    }

    hideNavigationArrow(eid: number): void {
        PveSvrNaviArrowSystem.hideNavigationArrow(this, eid);
    }

    heroReborn(resetPos: boolean) {
        PveSvrGameDefeatSystem.heroReborn(this, resetPos);
    }

    mapTransfer(data: BattlePveMapTransferItem): void {
        PveSvrMapTransferSystem.mapTransfer(this, data);
    }

    onClickBackToTerritory(): void {
        PveSvrTerritorySystem.onClickBackToTerritory(this);
    }

    onStartPlotTheatre(): void {
        const ctrlHero = this.ctrlHero;
        if (ctrlHero) {
            this.moveStop(ctrlHero.eid);
            this.playAnim(ctrlHero.eid, AnimName.IDLE);
        }
    }

    //#endregion command

    harvestCollection(eid: number): void {
        const ele = this.ecs.getComponent(eid, PveSvrElementComponent)!;
        const buildingCfg = app.service.table.battleBuilding[ele.tid]!;
        if (buildingCfg.building_type === PveBuildingType.PRODUCTION_FACTORY) {
            // 进行收获当前库存的资源
            // app.ui.toast("进行收获当前库存的资源");
            const bdData = app.service.pve.mlData.pveStateData.getBuildingByKey(ele.key)!;

            const curLo = app.service.pve.buildingVoMap.getVoWithBattleEntityIdAndLevel(
                buildingCfg.battle_entity,
                bdData.level
            )!;

            if (curLo.getIsCanHarvestWithtHarvestTime(bdData.harvestTime)) {
                // 当前可收获的资源数量
                const curHarvestAmount = curLo.getCurrentAmountWithHarvestTime(bdData.harvestTime);
                // 可以收获
                bdData.harvestTime = Math.round(app.service.network.serverTime);

                // 直接获取
                const itemId = curLo.customData!.item_id;
                const cmd = StringUtil.format("add_item {0} {1}", itemId, curHarvestAmount);
                app.service.gm.requestGM(cmd);

                this.sender.onHarvestCollectionSuccess(eid);

                // 进行保存
                PveSvrStateSystem.save(this.ecs);
            }
        }
    }

    getAllFightingEnemyTroops() {
        return PveSvrBattle.getAllFightingEnemyTroops(this);
    }

    getAllFightingEnemies() {
        return PveSvrBattle.getAllFightingEnemies(this);
    }

    interactiveBubbleAction(
        eid: number,
        bubbleViewType: InteractiveBubbleViewType,
        bubbleActionType: InteractiveBubbleActionType,
        param: unknown
    ): void {
        const hero = this.ctrlHero;
        if (!hero) return;

        const isLeaveBattle = PveSvrBattle.checkIsOutOfFight(this, hero.eid);
        if (!isLeaveBattle) {
            app.ui.toast("需要脱离战斗才可进行交互！");
            return;
        }

        const ele = this.ecs.getComponent(eid, PveSvrElementComponent)!;
        switch (bubbleActionType) {
            case InteractiveBubbleActionType.FOUNDATION_BUILD_ACTION_1:
                {
                    PveSvrBuildSystem.clickUpgradeBuilding(this, eid);
                }
                break;
            case InteractiveBubbleActionType.BUILDING_INTERACTIVE_ACTION_1:
                {
                    const buildingCfg = app.service.table.battleBuilding[ele.tid]!;
                    if (buildingCfg.building_type === PveBuildingType.PRODUCTION_FACTORY) {
                        this.sender.openCollectionFactoryWin(ele.eid);
                    } else if (buildingCfg.building_type === PveBuildingType.OPEN_FUNCTION) {
                        const buildingVo = app.service.pve.buildingVoMap.get(buildingCfg.id)!;
                        app.ui.jumpPanel(buildingVo.rourceLo.panelStr);
                    } else if (buildingCfg.building_type === PveBuildingType.MAP_TRANSFER) {
                        this.sender.openMapTransferWin(ele.eid);
                    }
                }
                break;
            case InteractiveBubbleActionType.CLOUD_INTERACTIVE_ACTION_1:
            case InteractiveBubbleActionType.RESCUS_INTERACTIVE_ACTION_1:
                {
                    const eventLo = app.service.pve.eventLoMap.get(ele.tid)!;
                    const tfComp = this._ecs.getComponent(eid, PveSvrTransformComponent)!;
                    const eventWorldPosition = tfComp.position;
                    const cost = eventLo.cost;
                    if (cost && cost.length > 0) {
                        // 检查扣费
                        const itemId = cost[0].id;
                        const itemLo = app.service.bag.itemLoMap.get(itemId);
                        const amountObj = app.service.pve.mlData.getCurrentAssetAmount(itemId);
                        const needAmount = cost[0].num;
                        const curHasAmount = amountObj.packAmount + amountObj.truckAmount;
                        if (curHasAmount < needAmount) {
                            // 不够
                            BagService.showNotEnoughCostTip(itemId, needAmount);
                            return;
                        }

                        if (eventLo.eventType === PveEventType.UNLOCK_CLOUD) {
                            PveSvrCloudSystem.clearCloudWithCost(
                                this,
                                eid,
                                amountObj,
                                needAmount,
                                eventWorldPosition,
                                eventLo.key
                            );
                        } else if (eventLo.eventType === PveEventType.RESCUE_SOLDIER) {
                            PveSvrRescueSoldierSystem.rescueSoldierWithCost(
                                this,
                                eid,
                                amountObj,
                                needAmount
                            );
                        }
                    }
                }
                break;
            case InteractiveBubbleActionType.NPC_INTERACTIVE_ACTION_1:
                {
                    this.setElementLookAtPlayer(eid);
                    PlotDialogueWinMediator.showPlot(
                        param as number,
                        new Laya.Handler(this, this.onNpcShowPlotComplete, [eid])
                    );
                }
                break;
            default:
                break;
        }
    }

    private onNpcShowPlotComplete(eid: number): void {
        PveSvrNpcSystem.onNpcShowPlotComplete(this, eid);
    }

    private setElementLookAtPlayer(eid: number): void {
        const ele = this.ecs.getComponent(eid, PveSvrElementComponent)!;
        const player = this.ecs.getComponent(this.ctrlHeroEid, PveSvrElementComponent)!;
        this.towardTo(ele, player);
    }
}
