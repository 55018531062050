import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { BattleConf } from "../../../../../def/auto/battle";
import { AnimName } from "../../../base/Animator";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrElementComponent } from "../../ecs/components/PveSvrElementComponent";
import { PveSvrMovementComponent } from "../../ecs/components/PveSvrMovementComponent";
import { PveSvrOffsetComponent } from "../../ecs/components/PveSvrOffsetComponent";
import { PveSvrTroopComponent } from "../../ecs/components/PveSvrTroopComponent";
import { PveSvrAiSystem } from "../../ecs/systems/PveSvrAiSystem";

const tmpTarget = new Laya.Vector3();
const tmpVelocity = new Laya.Vector3();

export class BackTeam extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const offsetComp = env.owner.getComponent(PveSvrOffsetComponent);
        if (!offsetComp) {
            console.warn(`OffsetComponent not found `);
            return "failure";
        }
        const troopComp = env.context.ecs.getComponent(offsetComp.troopEid, PveSvrTroopComponent)!;

        const leader = env.context.ecs.getComponent(troopComp.leaderEid, PveSvrElementComponent)!;
        const movement = env.owner.getComponent(PveSvrMovementComponent);
        const transform = env.owner.transform;
        if (!movement || !transform || !leader) {
            return "failure";
        }

        // 设置期望位置
        env.context.setExpectGoToTargetData(
            env.owner,
            true,
            offsetComp.offset.x,
            offsetComp.offset.z
        );

        const p1 = tmpTarget;
        const p0 = transform.position;
        env.context.calcFollowerPosition(leader.transform, offsetComp.offset, p1);
        const distance = Laya.Vector3.distanceXZ(p1, p0);
        // 与阵型位置在0.5格内认为已经归队了，剩下交给 FollowHero 节点
        if (distance <= 0.5) {
            env.context.playAnim(env.owner.eid, AnimName.IDLE);
            return "success";
        } else {
            const speed = distance / PveSvrAiSystem.TICK;
            let currSpeed = Math.max(movement.speed, BattleConf.PVE.MOVE_SPEED);
            if (currSpeed < speed) {
                currSpeed += 0.2;
            } else {
                currSpeed = speed;
            }
            if (currSpeed > BattleConf.PVE.MAX_BACK_SPEED) {
                currSpeed = BattleConf.PVE.MAX_BACK_SPEED;
            }
            env.context.moveTo(env.owner, p1, currSpeed);
            return node.yield(env);
        }
    }

    override get descriptor(): NodeDef {
        return {
            name: "BackTeam",
            type: "Action",
            desc: "士兵归队",
            doc: `
                + 士兵归队，归队过程中不执行其它命令`,
        };
    }
}
