const { regClass } = Laya;
import { ChatCellUI } from "./ChatCellUI";
import { UIChatBase } from "./UIChat.generated";

@regClass('qP6mu3SRSX2bwRGyYuLCtw')
export class UIChat extends UIChatBase {
    addNum = 0;
    tlChatCell: ChatCellUI[] = [];

    override onAwake(): void {
        super.onAwake();
    }
}
