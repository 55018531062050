import { app } from "../../app";
import { Service } from "../../core/service";
import { opcode } from "../../def/auto/protocol";
import { MessageError } from "./NetworkService";

export class ToastService extends Service {
    override onCreate() {
        this.handle(opcode.connection.msg_error, (data: MessageError) => {
            app.ui.toast(`${data.msg}[${data.name}]`);
            console.log(data.err, data.msg, data.name, data.opname);
        });
    }

    override onStartInit(): void {}

    override onDestroy() {}
}
