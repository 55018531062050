import { app } from "../../../app";
import * as ecs from "../../../core/ecs";
import { Pool } from "../../../core/pool";
import { Mediator } from "../../../core/ui-mediator";
import { res } from "../../../misc/res";
import { SystemEvent } from "../../../misc/system-event";
import { AnimClip, AnimName } from "../base/Animator";
import { PnvnUI } from "../ui-runtime/PnvnUI";
import { PnvnAnimationComponent } from "./ecs/components/PnvnAnimationComponent";
import { PnvnBattleFieldComponent } from "./ecs/components/PnvnBattleFieldComponent";
import { PnvnCameraComponent } from "./ecs/components/PnvnCameraComponent";
import { PnvnDebugComponent } from "./ecs/components/PnvnDebugComponent";
import { PnvnCameraSystem } from "./ecs/systems/PnvnCameraSystem";
import { PnvnCommandSystem } from "./ecs/systems/PnvnCommandSystem";
import { PnvnMovementSystem } from "./ecs/systems/PnvnMovementSystem";
import { PnvnRenderSystem } from "./ecs/systems/PnvnRenderSystem";

@Laya.regClass('297tbzoEQ6eSWaOnmryqlA')
export class PnvnContext extends Mediator {
    declare owner: PnvnUI;

    private _eidCount: number = 0;
    private _camera?: Laya.Camera;
    private _ecs!: ecs.World;

    get scene() {
        return this.owner.scene;
    }

    get scene3D() {
        return this.owner.scene3D;
    }

    get camera() {
        return (this._camera ||= this.scene3D.getChildByName("Main Camera") as Laya.Camera);
    }

    obtainEid() {
        return --this._eidCount;
    }

    override onDestroy() {
        Pool.clear(res.battle.PVP_DEBUG_TILE, (obj: Laya.Sprite3D) => obj.destroy());
        this._ecs.destroy();
        super.onDestroy();
    }

    override onAwake() {
        super.onAwake();

        this._ecs = new ecs.World(this);
        this._ecs.addSingletonComponent(PnvnDebugComponent);
        this._ecs.addSingletonComponent(PnvnCameraComponent);
        this._ecs.addSingletonComponent(PnvnBattleFieldComponent);
        this._ecs.addSystem(PnvnCommandSystem);
        this._ecs.addSystem(PnvnCameraSystem);
        this._ecs.addSystem(PnvnMovementSystem);
        this._ecs.addSystem(PnvnRenderSystem);

        this._loadBattle();
        this._initEvents();
    }

    get ecs() {
        return this._ecs;
    }

    override onUpdate() {
        super.onUpdate();
        this._ecs.update(Laya.timer.delta / 1000);
    }

    private async _loadBattle() {
        app.service.pvp.requestEnterScene(this.owner.args.battleUid);
    }

    private _initEvents() {
        this.$(app).on(SystemEvent.PNVN.TOUCH_AREA_MOUSE_DOWN, this._onTouchAreaMouseDown, this);
        this.$(app).on(SystemEvent.PNVN.TILEMAP_DRAG_START, this._onTilemapDragStart, this);
        this.$(app).on(SystemEvent.PNVN.TILEMAP_DRAG_MOVE, this._onTilemapDragMove, this);
        this.$(app).on(SystemEvent.PNVN.TILEMAP_DRAG_END, this._onTilemapDragEnd, this);
        this.$(app).on(SystemEvent.PNVN.TILEMAP_CLICK, this._onTilemapClick, this);
    }

    private _onTouchAreaMouseDown() {
        const camera = this.ecs.getSingletonComponent(PnvnCameraComponent);
        camera.focusEid = null;
    }

    private _onTilemapDragStart() {}

    private _onTilemapDragMove(offset: Laya.Vector3) {
        const camera = this.ecs.getSingletonComponent(PnvnCameraComponent);
        camera.focus.vadd(offset, camera.focus);
    }

    private _onTilemapDragEnd() {}

    private _onTilemapClick(clickAt: Laya.Vector3) {}

    playAnim(eid: number, name: AnimName) {
        const anim = this.ecs.getComponent(eid, PnvnAnimationComponent);
        const animator = anim?.animator;
        if (anim && animator) {
            switch (name) {
                case AnimName.ATTACK: {
                    const idx = Math.round(Math.random() * 1000) % 2;
                    const clip: AnimClip = idx > 0 ? "attack1" : "attack2";
                    animator.play(clip);
                    animator.nextPlay("idle");
                    break;
                }
                case AnimName.IDLE: {
                    anim.rushing = false;
                    animator.play("idle");
                    break;
                }
                case AnimName.MOVE: {
                    const clip = anim.rushing ? "run" : "move";
                    animator.play(clip);
                    break;
                }
                case AnimName.DIE: {
                    animator.play("die");
                    break;
                }
                case AnimName.SIEGE: {
                    animator.play("siege");
                    animator.nextPlay("idle");
                    break;
                }
            }
        }
    }
}
