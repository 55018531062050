/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { IconUI } from "../../../misc/ui-runtime/IconUI";

/**
 * resources/prefab/bag/UIItemTips.lh
 */
export class UIItemTipsBase extends Laya.Dialog {
    public boxMask!: Laya.Box;
    public iconItem!: IconUI;
    public labelName!: Laya.Label;
    public labCount!: Laya.Label;
    public labDesc!: Laya.Label;
    public btnUse!: Laya.Button;
}

