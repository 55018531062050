import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { Pool } from "../../../../../core/pool";
import { tween } from "../../../../../core/tween/tween";
import { Bezier3D } from "../../../../../core/utils/besier-3d";
import { StringUtil } from "../../../../../core/utils/string-util";
import { BattleEntityType, PveEventType, PveNpcStateType } from "../../../../../def/auto/battle";
import { MainlineConf } from "../../../../../def/auto/mainline";
import { res } from "../../../../../misc/res";
import { SystemEvent } from "../../../../../misc/system-event";
import { ui } from "../../../../../misc/ui";
import { AnimName } from "../../../base/Animator";
import { SpringAnimation } from "../../../base/SpringAnimation";
import {
    ElementCreator,
    ExplodeSpoilsData,
    PveDef,
    RecoverHp,
    TweenData,
    UpdateHp,
    UpdateTruck,
} from "../../../pve-server/PveDefs";
import { EventTrigger } from "../../../pve-server/ecs/components/PveSvrEventComponent";
import { TMUtil } from "../../../tilemap/tm-util";
import { PveContext } from "../../PveContext";
import { IPveReceiver } from "../../PveReceiver";
import { PveBulletComponent } from "../components/PveBulletComponent";
import { PveClearCloudComponent } from "../components/PveClearCloudComponent";
import { PveCommandComponent } from "../components/PveCommandComponent";
import { PveElementComponent } from "../components/PveElementComponent";
import { PveHeadInfoComponent } from "../components/PveHeadInfoComponent";
import { PveNavinArrowComponent } from "../components/PveNavinArrowComponent";
import { PvePickUpChestComponent } from "../components/PvePickUpChestComponent";
import { PveSpoilsComponent } from "../components/PveSpoilsComponent";
import { PveTransformComponent } from "../components/PveTransformComponent";
import { PveTruckCollectComponent } from "../components/PveTruckCollectComponent";
import { PveTruckComponent } from "../components/PveTruckComponent";
import { PveUINpcOperationConfirmComponent } from "../components/PveUINpcOperationConfirmComponent";
import { PveUITruckHudComponent } from "../components/PveUITruckHudComponent";
import { PveElementUtils } from "../utils/PveElementUtils";
import { PveMoveUtils } from "../utils/PveMoveUtils";
import { PveBuildingSystem } from "./PveBuildingSystem";
import { PveCollectSystem } from "./PveCollectSystem";
import { PveTerritorySystem } from "./PveTerritorySystem";

const tempDiePosVector3 = new Laya.Vector3();
const tempTargetPosVector3 = new Laya.Vector3();
const tempControlPosAVector3 = new Laya.Vector3();
const tempControlPosBVector3 = new Laya.Vector3();
const tempStartToEndVector3 = new Laya.Vector3();
const tmpInfoVector3 = new Laya.Vector3();

export class PveCommandSystem extends ecs.System implements IPveReceiver {
    declare context: PveContext;

    override update(dt: number): void {
        const cmdComp = this.ecs.getSingletonComponent(PveCommandComponent);
        if (cmdComp.commands.length > 0) {
            let length = cmdComp.commands.length;
            cmdComp.commands.forEach((cmd) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (this[cmd.name] as any)(...cmd.args);
                if (cmdComp.commands.length !== length) {
                    for (let i = length; i < cmdComp.commands.length; i++) {
                        const dropCmd = cmdComp.commands[i];
                        const err = new Error(
                            `PveCommandSystem drop command: ${dropCmd.name}, caused by ${cmd.name}(${cmd.args})`
                        );
                        console.error(err.stack);
                    }
                    length = cmdComp.commands.length;
                }
                cmd.args.forEach((arg) => {
                    if (typeof arg === "object") {
                        Pool.free(arg);
                    }
                });
            });
            cmdComp.commands.length = 0;
        }
    }

    setClearCloudKeys(keys: string[], needClearCloudKey: string | undefined): void {
        const keys_copy = keys.concat();
        const clearCloudComp = this.ecs.getSingletonComponent(PveClearCloudComponent);
        clearCloudComp.clearKeys = keys_copy;
        if (needClearCloudKey) {
            clearCloudComp.needClearCloudKey = needClearCloudKey;
        }
    }

    setCtrlHeroEid(eid: number) {
        this.context.ctrlHeroEid = eid;
    }

    createElement(data: ElementCreator) {
        PveElementUtils.createElement(this.context, data);
    }

    removeElement(eid: number): void {
        this.ecs.removeEntity(eid);
    }

    private _findElement(eid: number) {
        const element = this.ecs.getComponent(eid, PveElementComponent);
        if (!element) {
            console.warn(`not found entity: ${eid}`);
        }
        return element;
    }

    rushStart(eid: number) {
        const element = this._findElement(eid);
        if (element) {
            element.animation.rushing = true;
        }
    }

    moveStop(eid: number, position: Laya.Vector3) {
        const element = this._findElement(eid);
        if (element) {
            const { transform } = element;

            if (transform) {
                transform.position.cloneFrom(position);

                if (element.etype === BattleEntityType.BULLET) {
                    const bulletComp = element.getComponent(PveBulletComponent)!;
                    transform.position.y = bulletComp.h;
                }
                transform.flag |= PveTransformComponent.POSITION;
            }
        }
    }

    dispatch(eid: number, trigger: EventTrigger) {
        const element = this._findElement(eid);
        if (element) {
            // const event = app.service.table.battleEvent[element.tid];
            const eventLo = app.service.pve.eventLoMap.get(element.tid)!;

            // 救援士兵
            if (eventLo.eventType === PveEventType.RESCUE_SOLDIER) {
                if (trigger === "enter") {
                    app.event(SystemEvent.PVE.ON_RESCUE_ENTER, element.eid);
                } else if (trigger === "leave") {
                    app.event(SystemEvent.PVE.ON_RESCUE_LEAVE, element.eid);
                }
            } else if (eventLo.eventType === PveEventType.GAIN_CHEST) {
                this.context.sender.grubItem(eid);
                app.service.gm.requestGM(
                    "add_chest_box " + eventLo.reward![0] + " " + eventLo.reward![1]
                );
                const eventId = element.tid;
                app.service.pve.requestEvent(eventId);
                app.service.pve.requestDoTask(MainlineConf.TASK.PICK_CHEST, 1);
            } else if (eventLo.eventType === PveEventType.UNLOCK_CLOUD) {
                if (trigger === "enter") {
                    app.event(SystemEvent.PVE.ON_CLOUD_CONTROL_ENTER, element.eid);
                } else if (trigger === "leave") {
                    app.event(SystemEvent.PVE.ON_CLOUD_CONTROL_LEAVE, element.eid);
                }
            } else if (eventLo.eventType === PveEventType.PLOT) {
                const eventId = element.tid;
                this.context.sender.removePlotTheatreEvent(eid);
                app.service.pve.requestEvent(eventId);
                app.service.plotTheatre.showPlotTheatre(eventLo.plotTheatreId);
            }
        }
    }

    playAnim(eid: number, name: AnimName, bForce?: boolean) {
        const element = this._findElement(eid);
        const animator = element?.animation?.animator;
        if (element && animator) {
            switch (name) {
                case AnimName.ATTACK: {
                    const idx = Math.round(Math.random() * 1000) % 2;
                    const clip = idx > 0 ? "attack1" : "attack2";
                    animator.crossFade(clip, bForce);
                    animator.nextPlay("idle");
                    break;
                }
                case AnimName.IDLE:
                    element.animation.rushing = false;
                    animator.crossFade("idle", bForce);
                    break;
                case AnimName.MOVE: {
                    const clip = element.animation.rushing ? "run" : "move";
                    animator.crossFade(clip, bForce);
                    break;
                }
                case AnimName.DIE:
                    animator.crossFade("die", bForce);
                    break;
                case AnimName.CHOP:
                    animator.crossFade("chop", bForce);
                    animator.nextPlay("idle");
                    break;
                case AnimName.DIG:
                    animator.crossFade("dig", bForce);
                    animator.nextPlay("idle");
                    break;
                case AnimName.MOW:
                    animator.crossFade("mow", bForce);
                    animator.nextPlay("idle");
                    break;
                case AnimName.SKILL1_PRECAST:
                    animator.crossFade("skill1_precast", bForce);
                    break;
                case AnimName.SKILL1:
                    animator.crossFade("skill1", bForce);
                    break;
                case AnimName.VERTIGO:
                    animator.crossFade("vertigo", bForce);
                    break;
                case AnimName.SIEGE:
                    animator.crossFade("siege", bForce);
                    break;
                default:
                    console.warn(`playAnim fail: unhandle anime name: `, name);
                    break;
            }
        } else {
            // console.warn(`playAnim fail: not found entity: ${eid}`, name);
        }
    }

    private recoverHpTempVector4: Laya.Vector4 = new Laya.Vector4();

    async recoverHp(eid: number, data: RecoverHp) {
        const element = this._findElement(eid);
        if (element) {
            const { hp, maxHp, addHp } = data;
            const info = element.getComponent(PveHeadInfoComponent);
            if (info && info.view) {
                info.data.hp = hp;
                info.data.maxHp = maxHp;
                info.view.update(info.data);

                const checker = () => !!info.view;
                const prefab = await app.loader.loadPrefab(res.BATTLE_RECOVER_HP_NUM);
                if (!checker()) {
                    return;
                }

                const pos = this.recoverHpTempVector4;

                const skinCfg = app.service.table.skin[info.data.skinId];
                let model_height = 0;
                if (skinCfg && skinCfg.model_height) {
                    model_height = skinCfg.model_height;
                }

                tmpInfoVector3.x = element.transform.position.x;
                tmpInfoVector3.y = model_height;
                tmpInfoVector3.z = element.transform.position.z;
                this.context.camera.worldToViewportPoint(tmpInfoVector3, pos);

                const hpui = prefab.create() as Laya.Sprite;
                const hpTxt = hpui.getChildByName("anim").getChildByName("hp") as Laya.Text;
                hpTxt.text = "+" + addHp;

                hpui.pos(pos.x - info.view.width / 2, pos.y, true);
                this.context.owner.labels.addChild(hpui);
                hpui.scaleX = 0.5;
                hpui.scaleY = 0.5;
                tween(hpui)
                    .to(0.3, { scaleX: 1, scaleY: 1 }, { easing: "bounceOut" })
                    .delay(0.5)
                    .call(() => {
                        tween(hpui)
                            .to(0.5, { y: hpui.y - 50 })
                            .start();
                        tween(hpui).to(0.5, { alpha: 0 }).removeSelf().start();
                    })
                    .start();
            }
        }
    }

    async updateHp(eid: number, data: UpdateHp) {
        const element = this._findElement(eid);
        if (element) {
            const info = element.getComponent(PveHeadInfoComponent);
            if (info && info.view) {
                info.data.hp = data.hp;
                info.data.maxHp = data.maxHp;
                info.view.update(info.data);
                if (data.subHp) {
                    const checker = () => !!info.view;
                    const prefab = await app.loader.loadPrefab(
                        data.isCrit ? res.BATTLE_HP_NUM_X : res.BATTLE_HP_NUM
                    );
                    if (!checker()) {
                        return;
                    }
                    if (prefab) {
                        const pos = new Laya.Vector4();
                        const skinCfg = app.service.table.skin[info.data.skinId];
                        let model_height = 0;
                        if (skinCfg && skinCfg.model_height) {
                            model_height = skinCfg.model_height;
                        }
                        tmpInfoVector3.x = element.transform.position.x;
                        tmpInfoVector3.y = model_height;
                        tmpInfoVector3.z = element.transform.position.z;

                        this.context.camera.worldToViewportPoint(tmpInfoVector3, pos);
                        const hpui = prefab.create() as Laya.Sprite;
                        const hpTxt = hpui.getChildByName("anim").getChildByName("hp") as Laya.Text;
                        hpTxt.text = data.subHp.toFixed();
                        hpui.pos(pos.x - info.view.width / 2, pos.y, true);
                        this.context.owner.labels.addChild(hpui);
                        hpui.scaleX = 0.5;
                        hpui.scaleY = 0.5;
                        tween(hpui)
                            .to(0.3, { scaleX: 1, scaleY: 1 }, { easing: "bounceOut" })
                            .delay(0.5)
                            .call(() => {
                                tween(hpui)
                                    .to(0.5, { y: hpui.y - 50 })
                                    .start();
                                tween(hpui).to(0.5, { alpha: 0 }).removeSelf().start();
                            })
                            .start();
                    }
                }
                if (data.hp <= 0) {
                    info.view.visible = false;
                } else {
                    info.view.visible = true;
                }
            }

            const etype = element.etype;

            if (
                etype === BattleEntityType.WOOD ||
                etype === BattleEntityType.FOOD ||
                etype === BattleEntityType.STONE
            ) {
                this.updateCollectionHp(element, data.hp, data.maxHp, true);
            }
        }
    }

    updateCollectionHp(element: PveElementComponent, hp: number, hpMax: number, shake: boolean) {
        const buildingVo = app.service.pve.buildingVoMap.get(element.tid)!;
        if (element.etype === BattleEntityType.WOOD) {
            const anim = element.animation;
            if (anim.view) {
                const tree1 = anim.view.getChildByPath("anim/tree1") as Laya.Sprite3D;
                const tree2 = anim.view.getChildByPath("anim/tree2") as Laya.Sprite3D;
                const tree3 = anim.view.getChildByPath("anim/tree3") as Laya.Sprite3D;
                let state = 0;
                const stateArr = [hpMax, hpMax / 2, 0];
                for (let i = 0; i < stateArr.length - 1; i++) {
                    const curHp = stateArr[i];
                    const nextHp = stateArr[i + 1];
                    if (curHp >= hp && hp > nextHp) {
                        state = i;
                        break;
                    }
                }
                let showAni: boolean = false;
                if (hp === 0) {
                    tree1.active = false;
                    tree2.active = false;
                    if (buildingVo.freshTime) {
                        tree3.active = true;
                    } else {
                        tree3.active = false;
                    }
                } else if (state === 0) {
                    tree1.active = true;
                    tree2.active = false;
                    tree3.active = false;
                    showAni = true;
                } else if (state === 1) {
                    tree1.active = false;
                    tree2.active = true;
                    tree3.active = false;
                    showAni = true;
                }
                if (shake && showAni) {
                    // 显示动画
                    const treeAni = anim.view.getChildByPath("anim") as Laya.Sprite3D;
                    const aniComp = treeAni.getComponent(SpringAnimation);
                    if (aniComp) {
                        aniComp.startSpringAni();
                    }
                }
            }
        } else if (element.etype === BattleEntityType.STONE) {
            // 采集矿石
            const anim = element.animation;
            if (anim.view) {
                const crystal1 = anim.view.getChildByPath("anim/crystal1") as Laya.Sprite3D;
                const crystal2 = anim.view.getChildByPath("anim/crystal2") as Laya.Sprite3D;
                const crystal3 = anim.view.getChildByPath("anim/crystal3") as Laya.Sprite3D;
                let state = 0;
                const stateArr = [hpMax, hpMax / 2, 0];
                for (let i = 0; i < stateArr.length - 1; i++) {
                    const curHp = stateArr[i];
                    const nextHp = stateArr[i + 1];
                    if (curHp >= hp && hp > nextHp) {
                        state = i;
                        break;
                    }
                }
                let showAni: boolean = false;
                if (hp === 0) {
                    crystal1.active = false;
                    crystal2.active = false;
                    if (buildingVo.freshTime) {
                        crystal3.active = true;
                    } else {
                        crystal3.active = false;
                    }
                } else if (state === 0) {
                    crystal1.active = true;
                    crystal2.active = false;
                    crystal3.active = false;
                    showAni = true;
                } else if (state === 1) {
                    crystal1.active = false;
                    crystal2.active = true;
                    crystal3.active = false;
                    showAni = true;
                }
                if (shake && showAni) {
                    // 显示动画
                    const treeAni = anim.view.getChildByPath("anim") as Laya.Sprite3D;
                    const aniComp = treeAni.getComponent(SpringAnimation);
                    if (aniComp) {
                        aniComp.startSpringAni();
                    }
                }
            }
        } else if (element.etype === BattleEntityType.FOOD) {
            const anim = element.animation;
            if (anim.view) {
                const hpBi: number = hp / hpMax;

                const wheat_partArr: Laya.Sprite3D[] = [];
                const wheat_top = anim.view.getChildByPath("anim/wheat_top") as Laya.Sprite3D;
                const wheat_mid = anim.view.getChildByPath("anim/wheat_mid") as Laya.Sprite3D;
                const wheat_floor = anim.view.getChildByPath("anim/wheat_floor") as Laya.Sprite3D;
                for (let i = 0; i < 4; i++) {
                    wheat_partArr.push(wheat_mid.getChildAt(4 - i - 1) as Laya.Sprite3D);
                }
                for (let i = 0; i < 4; i++) {
                    wheat_partArr.push(wheat_top.getChildAt(4 - i - 1) as Laya.Sprite3D);
                }
                for (let i = 0; i < wheat_partArr.length; i++) {
                    const part = wheat_partArr[i];
                    const curBi = (i + 1) / wheat_partArr.length;
                    if (hpBi >= curBi) {
                        part.active = true;
                    } else {
                        part.active = false;
                    }
                }

                if (hpBi > 0) {
                    wheat_floor.active = true;
                } else {
                    if (buildingVo.freshTime) {
                        wheat_floor.active = true;
                    } else {
                        wheat_floor.active = false;
                    }
                }
            }
        } else {
            // const map = this.ecs.getSingletonComponent(PveMapComponent);
            // const objectElement = map.tilemap.getObjectElementByEid(element.eid);
            // if (!objectElement) {
            //     return;
            // }
            // const buildingRow = app.service.table.battleBuilding[element.tid];
            // const stateArr = buildingRow?.hp_state as Array<number>;
            // const textureArr = buildingRow?.hp_texture as Array<string>;
            // if (!stateArr || !textureArr) {
            //     return;
            // }
            // let textureName = undefined;
            // if (hp > 0) {
            //     for (let i = 0; i < stateArr.length - 1; i++) {
            //         const curHp = stateArr[i];
            //         const nextHp = stateArr[i + 1];
            //         if (curHp >= hp && hp > nextHp) {
            //             textureName = textureArr[i];
            //             break;
            //         }
            //     }
            // } else {
            //     if (buildingRow.die_hide) {
            //         textureName = undefined;
            //     } else {
            //         textureName = textureArr.at(-1);
            //     }
            // }
            // if (textureName) {
            //     objectElement?.draw();
            //     objectElement?.setTexture(textureName);
            //     if (shake) objectElement?.shake();
            // } else {
            //     objectElement?.erase();
            // }
        }
    }

    updateTruckHeadView(eid: number): void {
        const truckComp = this.ecs.getComponent(eid, PveTruckComponent);
        if (!truckComp) {
            return;
        }
        const truckHeadInfoComp = this.ecs.getComponent(eid, PveUITruckHudComponent);
        if (truckHeadInfoComp && truckHeadInfoComp.view) {
            truckHeadInfoComp.view.updateWithCollectCnt(truckComp.collectCnt);
        }
    }

    updateTruck(eid: number, data: UpdateTruck) {
        const truck = this._findElement(eid);
        if (!truck) {
            return;
        }
        const truckComp = truck.getComponent(PveTruckComponent);
        if (!truckComp) {
            return;
        }

        const curObjCnt = truckComp.collectObjCnt;
        const tarObjCnt = Math.floor(data.collectCnt / PveDef.COLLECT_CNT_PER_OBJ);
        let diffCnt = tarObjCnt - curObjCnt;

        if (diffCnt > 0) {
            while (diffCnt--) {
                const entity = this.ecs.createEntity(this.context.obtainEid(), 0);
                const component = entity.addComponent(PveTruckCollectComponent);
                component.truck = eid;
                component.data = data;
            }
        } else if (diffCnt < 0) {
            diffCnt = -diffCnt;
            while (diffCnt--) {
                const system = this.ecs.getSystem(PveCollectSystem);
                system?.delCollectObj(eid, data.costToPosition);
            }
        }
        truckComp.collectObjCnt = tarObjCnt;
        truckComp.collectCnt = data.collectCnt;
    }

    drawDebug(x: number, z: number, radius: number, color: number) {
        if (!TMUtil.DEBUG_MODE) {
            return;
        }
        const outPos = Pool.obtain(Laya.Vector4);
        const inPos = Pool.obtain(Laya.Vector3);
        inPos.x = x;
        inPos.z = z;
        this.context.camera.worldToViewportPoint(inPos, outPos);
        this.context.owner.debug.graphics.drawCircle(outPos.x, outPos.y, radius, null, color, 2);
    }

    setRotation(eid: number, rotation: number, immediately: boolean): void {
        const element = this._findElement(eid);
        if (element) {
            PveMoveUtils.setRotation(element, rotation, immediately);
        }
    }

    setPosition(eid: number, position: Laya.Vector3): void {
        const element = this._findElement(eid);
        if (element) {
            element.transform.position.cloneFrom(position);
            element.transform.flag |= PveTransformComponent.POSITION;
        }
    }

    grubSpoils(eid: number): void {
        const spoils = this._findElement(eid);
        if (!spoils) {
            return;
        }
        const spoilsLo = app.service.pve.spoilsLoMap.get(spoils.tid);
        if (!spoilsLo) return;
        if (spoilsLo.isChestBox) {
            const entity = this.ecs.createEntity(this.context.obtainEid(), 0);
            const component = entity.addComponent(PvePickUpChestComponent);
            component.starPos.cloneFrom(spoils.transform.position);
            component.chestSkin = spoilsLo.itemLo.iconUrl;
        } else {
            const ubb = StringUtil.str2UBB(
                "获得{0}+{1}",
                { image: spoilsLo.itemLo.smallIconUrl, width: 30, height: 30 },
                spoilsLo.rewardAmount
            );
            app.ui.toast(ubb);

            if (spoilsLo.isStone) {
                // 收集石头任务
                app.service.pve.requestDoTask(MainlineConf.TASK.COLLECT_ONE_STONE, 1);
            }
        }
    }

    grubChest(eid: number): void {
        const chest = this._findElement(eid);
        if (!chest) {
            return;
        }
        const entity = this.ecs.createEntity(this.context.obtainEid(), 0);
        const component = entity.addComponent(PvePickUpChestComponent);
        component.starPos.cloneFrom(chest.transform.position);
        const eventVo = app.service.pve.eventLoMap.get(chest.tid)!;
        const rewardChestId = eventVo.reward![0].id;
        component.chestSkin = `resources/atlas/chest/${app.service.table.chest.chest[rewardChestId].icon}_n.png`;
    }

    navigationArrowPointTo(
        eid: number,
        posO: Laya.Vector3,
        targetPosA: Laya.Vector3,
        targetPosB: Laya.Vector3
    ): void {
        const navigationArrow = this.ecs.getComponent(eid, PveNavinArrowComponent);
        if (navigationArrow) {
            if (targetPosA && targetPosB) {
                navigationArrow.hasTarget = true;
                navigationArrow.pointToPosO.cloneFrom(posO);
                navigationArrow.pointToPosA.cloneFrom(targetPosA);
                navigationArrow.pointToPosB.cloneFrom(targetPosB);
            } else {
                navigationArrow.hasTarget = false;
            }
        }
    }

    hideNavigationArrow(eid: number): void {
        const navigationArrow = this.ecs.getComponent(eid, PveNavinArrowComponent);
        if (navigationArrow) {
            navigationArrow.hasTarget = false;
        }
    }

    /** 爆宝箱动画 */
    playExplodeSpoilsAnimation(
        diePosX: number,
        diePosZ: number,
        explodeSpoilsDatas: ExplodeSpoilsData[]
    ): void {
        tempDiePosVector3.x = diePosX;
        tempDiePosVector3.y = 0;
        tempDiePosVector3.z = diePosZ;
        let delay: number = 0; // 延迟动画的秒数
        for (let i = 0; i < explodeSpoilsDatas.length; i++) {
            const data = explodeSpoilsDatas[i];
            tempTargetPosVector3.x = data.posX;
            tempTargetPosVector3.y = 0;
            tempTargetPosVector3.z = data.posZ;
            // 开始点和结束点之间的距离
            const dist = Laya.Vector3.distance(tempDiePosVector3, tempTargetPosVector3);
            this._updateExplodeSpoilsControlPos(tempDiePosVector3, tempTargetPosVector3, dist);
            const controlPoints: Laya.Vector3[] = [
                tempDiePosVector3,
                tempControlPosAVector3,
                tempControlPosBVector3,
                tempTargetPosVector3,
            ];
            const pList: number[] = [];
            for (let j = 0; j < controlPoints.length; j++) {
                pList.push(controlPoints[j].x);
                pList.push(controlPoints[j].y);
                pList.push(controlPoints[j].z);
            }
            const bezierPointsBase = Bezier3D.I.getBezierPoints(pList, 8, 3);
            const bezierPointsCount = bezierPointsBase.length / 3;
            const bezierPoints: Laya.Vector3[] = [];
            for (let j = 0; j < bezierPointsCount; j++) {
                const startIndex = j * 3;
                bezierPoints.push(
                    new Laya.Vector3(
                        bezierPointsBase[startIndex],
                        bezierPointsBase[startIndex + 1],
                        bezierPointsBase[startIndex + 2]
                    )
                );
            }
            const spoilsComponent = this.ecs.getComponent(data.eid, PveSpoilsComponent);
            if (spoilsComponent) {
                const tweenDatas = this.tweenPoints(bezierPoints);
                spoilsComponent.setTweenDatasAndStartTween2(
                    tweenDatas.td,
                    tweenDatas.totalLen,
                    delay
                );
            }
            delay += 0.15;
        }
    }

    private tweenPoints(points: Laya.Vector3[]): { td: TweenData[]; totalLen: number } {
        if (points.length < 2) return { td: [], totalLen: 0 };
        let totalLen = 0; // 曲线总长度
        const subLens: number[] = [];
        for (let i = 0; i < points.length - 1; i++) {
            const pointS = points[i];
            const pointE = points[i + 1];
            const subLen = Laya.Vector3.distance(pointS, pointE);
            subLens.push(subLen);
            totalLen += subLen;
        }
        let startLenTemp = 0;
        const tweenDatas: TweenData[] = [];
        for (let i = 0; i < points.length - 1; i++) {
            const pointS = points[i];
            const pointE = points[i + 1];
            const subLen = subLens[i];
            tweenDatas.push({
                startPoint: pointS,
                endPoint: pointE,
                startLen: startLenTemp,
                dist: subLen,
            });
            startLenTemp += subLen;
        }
        return { td: tweenDatas, totalLen: totalLen };
    }

    /**
     * 更新爆箱子移动曲线的贝塞尔控制点
     */
    private _updateExplodeSpoilsControlPos(
        startPos: Laya.Vector3,
        endPos: Laya.Vector3,
        dist: number
    ): void {
        const controlPosY = Math.max(dist * 2, 2);
        Laya.Vector3.subtract(endPos, startPos, tempStartToEndVector3);
        tempStartToEndVector3.normalize();
        // 靠近开始点的控制点
        tempControlPosAVector3.x = startPos.x + tempStartToEndVector3.x * dist * 0.25;
        tempControlPosAVector3.y = controlPosY;
        tempControlPosAVector3.z = startPos.z + tempStartToEndVector3.z * dist * 0.25;
        // 靠近结束点的控制点
        tempControlPosBVector3.x = startPos.x + tempStartToEndVector3.x * dist * 0.75;
        tempControlPosBVector3.y = controlPosY;
        tempControlPosBVector3.z = startPos.z + tempStartToEndVector3.z * dist * 0.75;
    }

    gameDefeat(): void {
        this.context.delay(2, () => {
            // 显示游戏失败的ui
            app.ui.show(ui.PVE_GAME_DEFEAT, this.context);
        });
    }

    updateTransform(
        eid: number,
        pos: Laya.Vector3,
        rotation: number | undefined,
        quaternion: Laya.Quaternion | undefined
    ): void {
        const elementComp = this.ecs.getComponent(eid, PveElementComponent);
        if (!elementComp) {
            return;
        }

        const transformComp = elementComp.transform;
        if (transformComp) {
            transformComp.position.cloneFrom(pos);
            transformComp.flag |= PveTransformComponent.POSITION;
            if (rotation !== undefined) {
                PveMoveUtils.setRotation(elementComp, rotation);
            } else if (quaternion !== undefined) {
                transformComp.quaternion.set(
                    quaternion.x,
                    quaternion.y,
                    quaternion.z,
                    quaternion.w
                );
                transformComp.flag |= PveTransformComponent.ROTATION_QUAT;
            }
        }
    }

    //#region Create Element
    private _onCreateSkillElement(eid: number, data: ElementCreator) {}
    //#endregion Create Element

    onHeroEnterLeaveBuildingProbingRange(buildingEid: number, isEnter: boolean): void {
        const elementComp = this.ecs.getComponent(buildingEid, PveElementComponent);
        if (elementComp) {
            const buildingCfg = app.service.table.battleBuilding[elementComp.tid];
            if (buildingCfg) {
                if (isEnter) {
                    app.event(SystemEvent.PVE.ON_BUILDING_ENTER, [
                        buildingCfg.battle_entity,
                        buildingCfg.building_type,
                        buildingEid,
                    ]);
                } else {
                    app.event(SystemEvent.PVE.ON_BUILDING_LEAVE, [
                        buildingCfg.battle_entity,
                        buildingCfg.building_type,
                        buildingEid,
                    ]);
                }
            }
        }
    }

    onHeroEnterLeaveNpcProbingRange(
        npcEid: number,
        isEnter: boolean,
        state: PveNpcStateType
    ): void {
        const elementComp = this.ecs.getComponent(npcEid, PveElementComponent);
        if (!elementComp) {
            return;
        }
        if (!isEnter) {
            // 离开npc探测范围
            elementComp.removeComponent(PveUINpcOperationConfirmComponent);
            return;
        }

        // 进入探测范围
        if (state === PveNpcStateType.NPC_STATE_LEAVE) {
            return;
        }

        const npcCfg = app.service.table.battleNpc[elementComp.tid];
        let state_plot: number[] | undefined;
        if (state === PveNpcStateType.NPC_STATE_0) {
            state_plot = npcCfg.state_plot_0;
        } else if (state === PveNpcStateType.NPC_STATE_1) {
            state_plot = npcCfg.state_plot_1;
        } else if (state === PveNpcStateType.NPC_STATE_2) {
            state_plot = npcCfg.state_plot_2;
        } else if (state === PveNpcStateType.NPC_STATE_3) {
            state_plot = npcCfg.state_plot_3;
        }

        if (!state_plot || state_plot.length < 1) {
            // 未配置对白
            return;
        }

        // 随机找一条对白进行沟通
        const randIndex = Math.floor(state_plot.length * Math.random());
        const confirmComp = elementComp.addComponent(PveUINpcOperationConfirmComponent);
        confirmComp.plotID = state_plot[randIndex];
    }

    setEnterLeaveTerritory(isEnter: boolean): void {
        PveTerritorySystem.onEnterLeaveTerritory(this.context, isEnter);
    }

    startUpgradeBuilding(buildingEid: number, upgradeTime: number): void {
        PveBuildingSystem.startUpgradeBuilding(this.context, buildingEid, upgradeTime);
    }

    upgradeBuildingComplete(newLevel: number, eid: number): void {
        PveBuildingSystem.upgradeBuildingComplete(this.context, newLevel, eid);
    }

    openCollectionFactoryWin(eid: number): void {
        PveBuildingSystem.openCollectionFactoryWin(this.context, eid);
    }

    openMapTransferWin(eid: number): void {
        PveBuildingSystem.openMapTransferWin(this.context);
    }

    onHarvestCollectionSuccess(eid: number): void {
        PveBuildingSystem.onHarvestCollectionSuccess(this.context, eid);
    }
}
