/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIRadioButton } from "../../../core/ui/UIRadioButton";

/**
 * resources/prefab/login/LoginSeverBtn.lh
 */
export class LoginServerBtnBase extends Laya.Box {
    public selectBox!: UIRadioButton;
}

