import { app } from "../../../app";
import { PlotTheatreRow } from "../../../def/table";
import { Vo } from "../../../misc/vo/Vo";
import { PlotTheatreActionsLo } from "./PlotTheatreActionsLo";

export class PlotTheatreLo extends Vo<PlotTheatreRow, never> {
    override get key(): number {
        return this._config.id;
    }

    override clone(): Vo<PlotTheatreRow, never> {
        return new PlotTheatreLo(this._config);
    }

    private _actions!: PlotTheatreActionsLo[];

    get actions(): PlotTheatreActionsLo[] {
        if (!this._actions) {
            const actionsIdArr: number[] = this._config.actions;
            this._actions = actionsIdArr.map(
                (id) => app.service.plotTheatre.plotTheatreActionsLoMap.get(id)!
            );
        }
        return this._actions;
    }
}
