/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { VirtuallyListUI } from "../../common/ui-runtime/VirtuallyListUI";

/**
 * resources/prefab/chat/chat.lh
 */
export class UIChatBase extends Laya.Dialog {
    btnSend!: Laya.Button;
    btnEmoji!: Laya.Button;
    inputMsg!: Laya.TextInput;
    virtuallyList!: VirtuallyListUI;
    boxEmoji!: Laya.Box;
    listEmoji!: Laya.List;
}
