import { TreeRunner } from "../../../../../core/behavior3";
import * as ecs from "../../../../../core/ecs";
import { BattleSkillRow } from "../../../../../def/table";
import { PveServer } from "../../PveServer";
import { AiTreeEnv } from "./PveSvrAiComponent";
import { PveSvrElementComponent } from "./PveSvrElementComponent";

export class SkillTreeEnv extends AiTreeEnv {
    readonly skill: Skill;

    constructor(context: PveServer, owner: PveSvrElementComponent, skill: Skill) {
        super(context, owner);
        this.owner = owner;
        this.skill = skill;
    }
}

export class Skill {
    private static __ID_IDX = 1;
    readonly id: number = 0;

    lastUpdate: number = 0;
    lastLaunch: number = 0;
    data: BattleSkillRow;
    btree: string;
    tree?: TreeRunner<SkillTreeEnv>;
    owner: PveSvrElementComponent; //hero
    running: boolean = false;
    lv: number = 1;

    constructor(data: BattleSkillRow, owner: PveSvrElementComponent) {
        this.id = Skill.__ID_IDX++;
        this.data = data;
        if (!data.lanuch_btree) {
            console.error(`skill ${data.id} has no launch btree`);
        }
        this.btree = `resources/data/btree/${data.lanuch_btree}.json`;
        this.owner = owner;
    }
}

/**
 * 普攻与普攻之间不计算公共cd
 * 普攻与技能，技能与技能之间计算公共cd
 * 计算公共cd时，需要同时满足技能内部cd以及公共cd，才能释放技能
 * 被动技能不计算cd，触发间隔由技能行为树内部控制
 */

/// 存放技能的组件，用于技能尝试释放。
export class PveSvrSkillLauncherComponent extends ecs.Component {
    static readonly PUBLIC_SKILL_CD = 1; //公共cd

    lastNormalAtkLaunchTime: number = 0; //上一次普攻释放时间
    lastSkillLaunchTime: number = 0; //上一次技能释放时间，用于公共cd
    readonly atkSkills: { [idx: number]: Skill } = {}; //1为近战普攻，2为远程普攻
    readonly skills: { [idx: number]: Skill } = {};
}
