import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { Pool } from "../../../../../core/pool";
import { Mesh, MeshFilter, Quad } from "../../../../../core/render/mesh-render";
import { res } from "../../../../../misc/res";
import { PvpContext } from "../../PvpContext";
import { PvpArrowsRenderCompnent } from "../components/PvpArrowsRenderCompnent";
import { PvpMapComponent } from "../components/PvpMapComponent";
import { PvpNaviArrowComponent } from "../components/PvpNaviArrowComponent";
import { PathArrowComponent } from "../components/PvpPathArrowComponent";
import { PvpTransformComponent } from "../components/PvpTransformComponent";

const tmpStart: Laya.Vector3 = new Laya.Vector3();
const tmpDest: Laya.Vector3 = new Laya.Vector3();
const tmpMiddle: Laya.Vector3 = new Laya.Vector3();
const tmpDir: Laya.Vector3 = new Laya.Vector3();

const TILE_WIDTH = 128;

export class PvpArrowSystem extends ecs.System {
    declare context: PvpContext;

    override async onCreate() {
        const checker = () => !this.ecs.destroyed;
        const tex = await app.loader.loadTexture2D(res.battle.PVE_3DUI_TEXTURE);
        const arrowTex1 = await app.loader.loadTexture(res.battle.PVE_ARROW_1);
        const arrowTex2 = await app.loader.loadTexture(res.battle.PVE_ARROW_2);
        const arrowTex3 = await app.loader.loadTexture(res.battle.PVE_ARROW_3);
        if (!checker()) {
            return;
        }

        const arrowRender = this.ecs.getSingletonComponent(PvpArrowsRenderCompnent);
        arrowRender.view = new Laya.Sprite3D();
        arrowRender.view.transform.localPositionY = 0.02;
        arrowRender.view.name = "arrow-render";
        arrowRender.textures.push(arrowTex1, arrowTex2, arrowTex3);

        const meshFilter = arrowRender.view.addComponent(MeshFilter);
        // TODO: 使用自动扩容？
        arrowRender.mesh = new Mesh(512);
        arrowRender.mesh.name = "arrow-mesh";
        meshFilter.sharedMesh = arrowRender.mesh;

        const meshRender = arrowRender.view.addComponent(Laya.MeshRenderer);
        const mat = new Laya.UnlitMaterial();
        mat.albedoTexture = tex;
        mat.materialRenderMode = Laya.MaterialRenderMode.RENDERMODE_TRANSPARENT;
        meshRender.material = mat;
        meshRender.enabled = false;

        this.context.owner.scene3D.addChild(arrowRender.view);

        this.registerHandler(
            PathArrowComponent,
            this._onAddPathArrowComponent,
            this._onDelPathArrowComponent
        );
        this.registerHandler(
            PvpNaviArrowComponent,
            this._onAddPvpNaviArrowComponent,
            this._onDelPvpNaviArrowComponent
        );
    }

    override onDestroy() {
        const render = this.ecs.getSingletonComponent(PvpArrowsRenderCompnent);
        render.view?.destroy();
        render.view = undefined;
        render.mesh?.destroy();
        render.mesh = undefined;
    }

    private _onAddPathArrowComponent(component: PathArrowComponent) {}

    private _onDelPathArrowComponent(component: PathArrowComponent) {
        const arrowRender = this.ecs.getSingletonComponent(PvpArrowsRenderCompnent);
        component.quads.forEach((quad) => {
            arrowRender.mesh?.removeQuad(quad);
        });
        Pool.free(component.quads);
    }

    private _onAddPvpNaviArrowComponent(component: PvpNaviArrowComponent) {}

    private _onDelPvpNaviArrowComponent(component: PvpNaviArrowComponent) {
        const arrowRender = this.ecs.getSingletonComponent(PvpArrowsRenderCompnent);
        component.quads.forEach((quad) => {
            arrowRender.mesh?.removeQuad(quad);
        });
        Pool.free(component.quads);
    }

    override update(dt: number) {
        // TODO：降低更新频率？
        this._drawNaviArrows();
        this._drawPathArrows();
    }

    private _drawNaviArrows() {
        const arrowRender = this.ecs.getSingletonComponent(PvpArrowsRenderCompnent);
        const map = this.ecs.getSingletonComponent(PvpMapComponent);
        const visionRect = map.tilemap.visionRect;
        const mesh = arrowRender.mesh;
        if (!mesh) {
            return;
        }
        this.ecs.getComponents(PvpNaviArrowComponent).forEach((arrow) => {
            const tex = arrowRender.textures[arrow.color];
            const w = tex.width / TILE_WIDTH;
            const h = tex.height / TILE_WIDTH;
            const transform = arrow.getComponent(PvpTransformComponent);
            tmpStart.cloneFrom(transform?.position ?? arrow.start);
            tmpDest.cloneFrom(arrow.dest);
            tmpDir.cloneFrom(tmpDest);
            tmpDir.vsub(tmpStart, tmpDir);
            tmpDir.normalize();
            let len = Laya.Vector3.distance(tmpStart, tmpDest);
            let stepX = tmpDir.x * w;
            let stepZ = tmpDir.z * w;
            const offsetX = (tmpDir.x * h) / 2;
            const offsetZ = (tmpDir.z * h) / 2;
            arrow.quads.forEach((v) => mesh.removeQuad(v));
            Pool.free(arrow.quads);
            while (len > 0) {
                tmpMiddle.cloneFrom(tmpDest);
                tmpMiddle.x -= stepX / 2;
                tmpMiddle.z -= stepZ / 2;
                if (visionRect.contains(tmpMiddle.x, tmpMiddle.z)) {
                    /**
                     * p0                    p1
                     * |                     | -->(offsetX, offsetZ)
                     * --------middle-------->dest
                     * |                     |
                     * p3                   p2
                     */
                    // 二维空间中, 一个向量(x, y)的垂直向量是(-y, x)
                    const quad = Pool.obtain(Quad);
                    const [p0, p1, p2, p3] = quad.vertices;
                    if (len < w) {
                        stepX = tmpDir.x * len;
                        stepZ = tmpDir.z * len;
                    }
                    p1.set(tmpDest.x + offsetZ, 0, tmpDest.z - offsetX);
                    p0.set(p1.x - stepX, 0, p1.z - stepZ);
                    p2.set(tmpDest.x - offsetZ, 0, tmpDest.z + offsetX);
                    p3.set(p2.x - stepX, 0, p2.z - stepZ);
                    quad.draw(p0, p1, p2, p3, tex);
                    mesh.addQuad(quad);
                    arrow.quads.push(quad);
                }
                tmpDest.x -= stepX;
                tmpDest.z -= stepZ;
                len -= w;
            }
        });
    }

    private _drawPathArrows() {}
}
