/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { SkillIconUI } from "./SkillIconUI";

/**
 * resources/prefab/skill/SkillFastListItem.lh
 */
export class SkillFastListItemUIBase extends Laya.Box {
    public boxBase!: Laya.Box;
    public imgQuality!: Laya.Image;
    public imgType!: Laya.Image;
    public labName!: Laya.Label;
    public labType!: Laya.Label;
    public labPro!: Laya.Label;
    public boxDetails!: Laya.Box;
    public skill_icon!: SkillIconUI;
    public imgType2!: Laya.Image;
    public labType2!: Laya.Label;
    public lablevelDesc!: Laya.Label;
    public labDesc!: Laya.Label;
    public labBtn!: Laya.Text;
    public btnUp!: Laya.Button;
    public btnOff!: Laya.Button;
}

