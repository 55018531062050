const { regClass } = Laya;
import { ImageRef } from "../../../../misc/ImageRef";
import { CharacterSelectTabBase } from "./CharacterSelectTab.generated";

export type UICharacterSelectTabConfData = {
    name: string;
    index: number;
    desc: string;
};
const SelectedLblOutColor = "#A87525";
const UnSelectedLblOutColor = "#2581A8";

const SelectedLblColor = "#FFFFFF";
const UnSelectedLblColor = "#15709C";

export const CharacterSelectTabConf: UICharacterSelectTabConfData[] = [
    {
        name: "all",
        index: 0,
        desc: "全",
    },
    {
        name: "knight",
        index: 1,
        desc: "骑",
    },
    {
        name: "infantry",
        index: 2,
        desc: "步",
    },
    {
        name: "lancer",
        index: 3,
        desc: "枪",
    },
    {
        name: "archer",
        index: 4,
        desc: "弓",
    },
    {
        name: "assist",
        index: 5,
        desc: "辅",
    },
];

@regClass('rweaD_IERM2Aza7VhBFshQ')
export class CharacterSelectTab extends CharacterSelectTabBase {
    onRender(index: number, data: UICharacterSelectTabConfData) {
        this.lblList.text = data.desc;
    }

    onSelect(bSelected: boolean, index: number) {
        this.lblList.stroke = bSelected ? 3 : 0;
        this.lblList.color = bSelected ? SelectedLblColor : UnSelectedLblColor;
        this.lblList.strokeColor = bSelected ? SelectedLblOutColor : UnSelectedLblOutColor;
        const textures = this.imgBg.getComponent(ImageRef)!.texturePaths;
        this.imgBg.skin = bSelected ? textures[0] : textures[1];
        this.imgSelect.visible = bSelected;
    }
}
