/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { IconUI } from "../../../misc/ui-runtime/IconUI";
import { UIHSlider } from "../../common/ui-runtime/UIHSlider";

/**
 * resources/prefab/bag/UIItemUseRandom.lh
 */
export class UIItemUseRandomBase extends Laya.Dialog {
    public imgMask!: Laya.Image;
    public iconItem!: IconUI;
    public labelName!: Laya.Label;
    public labCount!: Laya.Label;
    public labUseCount!: Laya.Label;
    public sliderCom!: UIHSlider;
    public btnUse!: Laya.Button;
    public listItem!: Laya.List;
}

