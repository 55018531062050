import * as ecs from "../../../../../core/ecs";

export class PvpOwnerComponent extends ecs.Component {
    aid: number = 0;//同盟 alliance id
    rid: number = 0;// 角色 id
    isRobot: boolean = false;
    roleName: string = "";
    allianceName: string = "";
    homeEid: number = 0;// 所在建筑 eid
    insideEid: number = 0;// 所在建筑内部 eid 需要隐藏部队模型
}
