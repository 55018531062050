import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { chest } from "../../def/auto/proto";
import { opcode } from "../../def/auto/protocol";
import { UIChestBoxChooseHero } from "./ui-runtime/UIChestBoxChooseHero";
import { ChestBoxChooseHeroItem } from "./ui-runtime/sub-choose-hero/ChestBoxChooseHeroItem";
const { regClass, property } = Laya;

@regClass('Z2SaYZsHShmuiaiVNtkiEg')
export class UIChestBoxChooseHeroMediator extends Mediator {
    declare owner: UIChestBoxChooseHero;

    override onAwake(): void {
        super.onAwake();
        this.owner.itemList.renderHandler = new Laya.Handler(this, this.onListItemRenderer);

        this.$(app).on(opcode.chest.s2c_switch_hero, this.onSwitchHero, this);
    }

    private onListItemRenderer(item: ChestBoxChooseHeroItem, index: number): void {
        // console.log("index:" + index + "data:" + item.dataSource);
        item.updateShow();
    }

    open(): void {
        this.updateShow();
    }

    public updateShow(): void {
        const heroIds = app.service.chest.heroIds;
        const loMap = app.service.hero.heroLoMap;
        const heroLos = heroIds.map((id) => {
            return loMap.get(id);
        });
        this.owner.itemList.array = heroLos;
    }

    private onSwitchHero(s2c: chest.s2c_switch_hero, c2s: chest.c2s_switch_hero): void {
        this.owner.close();
    }
}
