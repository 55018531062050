import proto from "../../../def/auto/proto";
import { RecruitPoolBaseRow } from "../../../def/table";
import { Vo } from "../../../misc/vo/Vo";

export class DrawCardVo extends Vo<RecruitPoolBaseRow, proto.recruit.PoolInfo> {
    protected declare _data: Readonly<proto.recruit.PoolInfo>;

    constructor(data: proto.recruit.PoolInfo) {
        super(undefined!, data);
    }

    override clone(): DrawCardVo {
        return new DrawCardVo(this._data);
    }

    override get key(): number {
        return this._data.poolId; //卡池id
    }

    //已使用免费次数
    get useFreeTimes() {
        return this._data.useFreeTimes;
    }

    //最近一次使用免费次数的时间
    get lastUseFreeTimes() {
        return this._data.lastUseFreeTime;
    }

    //当前保底周期抽奖次数
    get currentTime() {
        return this._data.currentTimes;
    }

    //今日抽奖总次数
    get times() {
        return this._data.times;
    }

    //最近一次抽奖的时间
    get lastRecruitTime() {
        return this._data.lastRecruitTime;
    }

    get openGuaranteed() {
        return this._data.openGuaranteed;
    }
}
