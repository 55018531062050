/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIList } from "../../../core/ui/UIList";
import { BaseBigWindowBg } from "../../common/ui-runtime/dialog/BaseBigWindowBg";
import { LeaderSkinSkillItem } from "./LeaderSkinSkillItem";

/**
 * resources/prefab/leader-skin/UILeaderSkin.lh
 */
export class UILeaderSkinBase extends Laya.Dialog {
    public bgwin!: BaseBigWindowBg;
    public imgHero!: Laya.Image;
    public skillItem1!: LeaderSkinSkillItem;
    public skillItem2!: LeaderSkinSkillItem;
    public skillItem3!: LeaderSkinSkillItem;
    public skillItem4!: LeaderSkinSkillItem;
    public btnSkin!: Laya.Button;
    public btnOff!: Laya.Button;
    public btnActive!: Laya.Button;
    public btnupgrade!: Laya.Button;
    public labName!: Laya.Label;
    public labLevel!: Laya.Label;
    public labNoTips!: Laya.Label;
    public labMax!: Laya.Label;
    public listHero!: UIList;
    public listAtt!: Laya.List;
    public btnClose!: Laya.Button;
}
