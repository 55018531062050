import * as ecs from "../../../../../core/ecs";
import { res } from "../../../../../misc/res";
import { BDProductionInfoUI } from "../../../ui-runtime/pve/build/BDProductionInfoUI";

/**
 * 资源产出中进度显示
 */
export class PveUIProductionInfoComponent extends ecs.Component {
    readonly mounter: Laya.Sprite = new Laya.Sprite();

    view?: BDProductionInfoUI | null;

    buildingTid: number = 0;

    /** 资源路径 */
    public get res(): string {
        return res.BATTLE_BD_PRODUCTION_INFO_CONFIRM;
    }
}
