import { app } from "../../../app";
import { StringUtil } from "../../../core/utils/string-util";
import { ItemType } from "../../../def/auto/item";
import proto from "../../../def/auto/proto";
import { SkillConf } from "../../../def/auto/skill";
import { AttrRow, ItemRowArgsLeadSKin, LeadSkinLevelRow, LeaderSkinRow } from "../../../def/table";
import { res } from "../../../misc/res";
import { Vo } from "../../../misc/vo/Vo";
import { TableUtil } from "../../table/TableUtil";

export class LeaderSkinLo extends Vo<LeaderSkinRow, never> {
    override get key() {
        return this._config.id;
    }

    override clone() {
        return new LeaderSkinLo(this._config);
    }

    public get id() {
        return this._config.id;
    }

    public get name() {
        return this._config.name;
    }

    public get lvProgrammer() {
        return this._config.lv_programme;
    }

    public get quality() {
        return this._config.quality;
    }

    public get skill1() {
        return this._config.skill1 || 0;
    }

    public get skill2() {
        return this._config.skill2 || 0;
    }

    public get skill3() {
        return this._config.skill3 || 0;
    }

    public get skill4() {
        return this._config.skill4 || 0;
    }

    public get modelId() {
        return this._config.modelId || 0;
    }

    public get skinCostItemId() {
        const id = this.id;
        const itemCfgs = app.service.table.item.item;
        for (const key in itemCfgs) {
            const itemCfg = itemCfgs[key];
            if (itemCfg.item_type === ItemType.LEADER_SKIN) {
                const args = itemCfg.args as ItemRowArgsLeadSKin;
                if (args.id === id) {
                    return itemCfg.id;
                }
            }
        }
        return 0;
    }

    public getHeroRes(): { midRes: string; fullRes: string } {
        const lo = this._config;
        const modelId = lo.modelId!;
        const modelCfg = TableUtil.getRow(app.service.table.skin, { id: modelId });
        const imgStr = modelCfg?.code_name || "";
        const midRes = StringUtil.format(res.HERO_ICON_FORMAT.MIDDLE, imgStr);
        const fullRes = StringUtil.format(res.HERO_ICON_FORMAT.FULL, imgStr);
        return { midRes: midRes, fullRes: fullRes };
    }

    public get smallIcon(): string {
        const lo = this._config;
        const modelId = lo.modelId!;
        const modelCfg = TableUtil.getRow(app.service.table.skin, { id: modelId });
        const imgStr = modelCfg?.code_name || "";
        const smallIcon = StringUtil.format(res.HERO_ICON_FORMAT.SMALL, imgStr);
        return smallIcon;
    }

    public checkIsCanActive(): boolean {
        const costCount = this.getUpgradeCount(0);
        const ownCount = app.service.bag.itemVoMap.getItemAmount(this.skinCostItemId);
        if (ownCount >= costCount) {
            return true;
        }
        return false;
    }

    //判断是否最大
    public checkIsMax() {
        const skinData = app.service.leaderSkin.getSkinDataById(this.id);
        if (skinData) {
            const costCount = this.getUpgradeCount(skinData.lv);
            return costCount === 0;
        }
        return false;
    }

    //获取下一级消耗物品的数量
    public getUpgradeCount(level: number) {
        const lvParam = this.lvProgrammer;
        let count = 0;
        const lvCfgs = app.service.table.leaderskin.skin_lv[lvParam];
        if (lvCfgs) {
            const levelCfg = lvCfgs[level];
            count = levelCfg.require_self_cnt || 0;
        }
        return count;
    }

    public getSkinSkillDatas(): { [key: string]: proto.leader_skin.SkillInfo } {
        let skillList: { [key: string]: proto.leader_skin.SkillInfo } = {};
        const skinData = app.service.leaderSkin.getSkinDataById(this.id);
        const slotMax = SkillConf.MAX_SLOT;
        if (skinData) {
            const skills = skinData.skills;
            skillList = skills;
        } else {
            const cfgSkills = [this.skill1, this.skill2, this.skill3, this.skill4];
            for (let pos = 1; pos <= slotMax; pos++) {
                const skillId = cfgSkills[pos - 1];
                if (skillId) {
                    skillList[pos] = { lv: 1, id: skillId };
                }
            }
        }
        return skillList;
    }

    public getAttList(): { arrtName: string; AttrValue: number }[] {
        const attrList: { arrtName: string; AttrValue: number }[] = [];
        const attrTypeList: string[] = ["atk", "hp", "defence"];
        const lv = app.service.leaderSkin.getSkinLevelById(this.id);
        for (const arrType of attrTypeList) {
            const attrRow = TableUtil.getRow(app.service.table.attr, <AttrRow>{
                define: arrType,
            })!;
            const AttrValue = this._getAttr(arrType, lv);
            const attrData: { arrtName: string; AttrValue: number } = {
                arrtName: attrRow.name,
                AttrValue: AttrValue,
            };
            attrList.push(attrData);
        }
        return attrList;
    }

    public getCurAndNextAttrList(): { arrtName: string; curVal: number; nextValue: number }[] {
        const attrList: { arrtName: string; curVal: number; nextValue: number }[] = [];
        const attrTypeList: string[] = ["atk", "hp", "defence"];
        const curLv = app.service.leaderSkin.getSkinLevelById(this.id);
        const nextLv = curLv + 1;
        for (const arrType of attrTypeList) {
            const attrRow = TableUtil.getRow(app.service.table.attr, <AttrRow>{
                define: arrType,
            })!;
            const AttrValue = this._getAttr(arrType, curLv);
            const nextValue = this._getAttr(arrType, nextLv);
            const attrData: { arrtName: string; curVal: number; nextValue: number } = {
                arrtName: attrRow.name,
                curVal: AttrValue,
                nextValue: nextValue,
            };
            attrList.push(attrData);
        }
        return attrList;
    }

    private _getAttr(attr: string, lv: number): number {
        if (this.cachedAttr[attr] && this.cachedAttr[attr][lv]) {
            return this.cachedAttr[attr][lv];
        }
        const ret = this.calcuAttr(attr, lv);
        if (!this.cachedAttr[attr]) {
            this.cachedAttr[attr] = {};
        }
        this.cachedAttr[attr][lv] = ret;
        return ret;
    }

    private calcuAttr(attr: string, lv: number): number {
        const attrRow = TableUtil.getRow(app.service.table.attr, { define: attr })!;
        const baseKey = `attr_lv${attrRow.id}` as keyof LeaderSkinRow;
        const attrId = this._config[baseKey] as number;
        if (attrId === undefined || typeof attrId !== "number") {
            console.error(`${attr}, no ${baseKey} in LeaderSkinRow`);
        }
        const totalValue = this.calcuLevelAttValue(lv, attrId);
        return totalValue;
    }

    private calcuLevelAttValue(lv: number, attrId: number): number {
        const lvParam = this.lvProgrammer;
        const skilLvCfgs = app.service.table.leaderskin.skin_lv[lvParam];
        let totalValue = 0;
        for (const key in skilLvCfgs) {
            const cfg = skilLvCfgs[key];
            if (lv >= cfg.lv) {
                const attrKey = `attr_grow${attrId}` as keyof LeadSkinLevelRow;
                const attValue = cfg[attrKey] as number;
                totalValue = attValue + totalValue;
            }
        }
        return totalValue;
    }

    private cachedAttr: { [attr: string]: { [lv: number]: number } } = {};
}
