import * as ecs from "../../../../../core/ecs";
import { Mesh } from "../../../../../core/render/mesh-render";

export const enum ArrowColor {
    GREEN = 0,
    BLUE = 1,
    RED = 2,
}

export class PvpArrowsRenderCompnent extends ecs.SingletonComponent {
    view?: Laya.Sprite3D;
    mesh?: Mesh;

    textures: Laya.Texture[] = [];
}
