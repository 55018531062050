import { app } from "../../../../../app";
import { Constructor } from "../../../../../core/dispatcher";
import * as ecs from "../../../../../core/ecs";
import { SystemEvent } from "../../../../../misc/system-event";
import { InteractiveBubbleConfirms } from "../../../pve-server/PveDefs";
import { PveContext } from "../../PveContext";
import { PveJoystickComponent } from "../components/PveJoystickComponent";

const tmpDir = new Laya.Vector3();

export class PveJoystickSystem extends ecs.System {
    declare context: PveContext;

    private _lastSendTime = 0;

    private _joystickAlpha = 0.3;

    override onCreate() {
        const joystick = this.ecs.getSingletonComponent(PveJoystickComponent);
        const owner = this.context.owner;
        joystick.initX = owner.joystick.x;
        joystick.initY = owner.joystick.y;
        joystick.initHeight = owner.joystickArea.height;
        owner.joystickGroup.on(Laya.Event.MOUSE_DOWN, this, this.onJoysticHandler);
        owner.joystickGroup.on(Laya.Event.MOUSE_MOVE, this, this.onJoysticHandler);
        owner.joystickGroup.on(Laya.Event.MOUSE_UP, this, this.onJoysticHandler);

        this.context.$(app).on(SystemEvent.ON_START_PLOT_THEATRE, this.onStartPlotTheatre, this);
        this.context.$(app).on(SystemEvent.ON_END_PLOT_THEATRE, this.onEndPlotTheatre, this);
    }

    private onStartPlotTheatre(): void {
        const owner = this.context.owner;
        owner.joystick.alpha = 0;
    }

    private onEndPlotTheatre(): void {
        const owner = this.context.owner;
        owner.joystick.alpha = this._joystickAlpha;
    }

    onJoysticHandler(e: Laya.Event) {
        if (app.service.plotTheatre.showPlotTheatreing) {
            return;
        }
        const joystick = this.ecs.getSingletonComponent(PveJoystickComponent);
        const owner = this.context.owner;
        if (!(joystick.pressStart || e.type === Laya.Event.MOUSE_DOWN)) {
            return;
        }
        let addIndex = owner.numChildren - 2;
        if (addIndex < 0) {
            addIndex = 0;
        }
        if (e.type === Laya.Event.MOUSE_DOWN) {
            joystick.pressStart = Laya.Point.create();
            joystick.pressStart.copy(owner.joystickGroup.getMousePoint());
            owner.joystick.x = joystick.pressStart.x;
            owner.joystick.y = joystick.pressStart.y;
            owner.joystick.alpha = 1;
            owner.joystickArea.height = Laya.stage.height;
            Laya.stage.addChild(owner.joystickGroup);
        } else if (e.type === Laya.Event.MOUSE_UP) {
            joystick.pressStart?.recover();
            joystick.pressStart = null;
            joystick.degree = Number.MAX_VALUE;
            owner.joystick.x = joystick.initX;
            owner.joystick.y = joystick.initY;
            owner.joystick.alpha = this._joystickAlpha;
            owner.indicator.x = 0;
            owner.indicator.y = 0;
            owner.joystickArea.height = joystick.initHeight;
            owner.addChildAt(owner.joystickGroup, addIndex);
            // 加入是否激活AI的判断
            const hasOpenConfirm = this.isHasOpenConfirmUI();
            const activeAI = !hasOpenConfirm; // 当显示弹出UI时，不需要激活AI
            this.context.sender.joystickStop(this.context.ctrlHeroEid, activeAI);
        } else {
            const maxOffset = owner.joystick.width / 2;
            const current = owner.joystickGroup.getMousePoint();
            current.x -= joystick.pressStart!.x;
            current.y -= joystick.pressStart!.y;
            const distance = current.distance(0, 0);
            if (distance > maxOffset) {
                const scale = maxOffset / distance;
                current.x *= scale;
                current.y *= scale;
            }
            owner.indicator.x = current.x;
            owner.indicator.y = current.y;

            const rad = Math.atan2(current.y, current.x) - Math.PI / 4;
            const degree = Math.floor(Math.toDegree(rad));
            if (Math.abs(joystick.degree - degree) >= 5) {
                joystick.degree = degree;
                tmpDir.z = Math.sin(Math.toRadian(degree));
                tmpDir.x = Math.cos(Math.toRadian(degree));
                this.context.sender.joystickStart(this.context.ctrlHeroEid, tmpDir);
            }
        }
    }

    isHasOpenConfirmUI(): boolean {
        const confirms: Constructor<ecs.Component>[] = InteractiveBubbleConfirms;
        for (const confirm of confirms) {
            const map = this.ecs.getComponents(confirm);
            if (map.size > 0) {
                return true;
            }
        }
        return false;
    }
}
