import { ILogger, IPureLogger, LogColor, LogLevel, LoggerOption } from "./ILogger";
import { IsLogInApp, LoggerConfig } from "./LoggerConfig";

export class ConsoleLogger implements ILogger {
    private logLevel: LogLevel = LogLevel.None;

    private logImpl: IPureLogger = null!;
    private tag: string = "";

    init(option: LoggerOption): ILogger {
        this.logImpl = option.logImpl;
        this.logLevel = option.logLevel;
        this.tag = option.tag ? `${option.tag}` : LoggerConfig.GetLogTag();
        return this;
    }

    updateLogLevel(logLevel: LogLevel): void {
        if (this.logLevel === logLevel) {
            return;
        }
        this.logLevel = logLevel;
    }

    updateLogImpl(logImpl: IPureLogger): void {
        if (this.logImpl === logImpl) {
            return;
        }
        this.logImpl = logImpl;
    }

    private isIgnoreTag(): boolean {
        return LoggerConfig.IsIgnoreLogger(this.tag);
    }

    // private browerInfo: IBrowser = null;

    private getLogPrefix(logLevel: number): string[] {
        const isInApp = IsLogInApp();

        switch (logLevel) {
            case LogLevel.Trace:
                return [`[${this.tag}-trace]`];
            case LogLevel.Debug:
                if (isInApp) {
                    return [`[${this.tag}-debug]`];
                } else {
                    return [`%c [${this.tag}-debug]`, `${LogColor.Debug}`];
                }
            case LogLevel.Log:
                if (isInApp) {
                    return [`[${this.tag}-log]`];
                } else {
                    return [`%c [${this.tag}-log]`, `${LogColor.Log}`];
                }
            case LogLevel.Info:
                if (isInApp) {
                    return [`[${this.tag}-info]`];
                } else {
                    return [`%c [${this.tag}-info]`, `${LogColor.Info}`];
                }
            case LogLevel.Preview:
                return [`%c [${this.tag}-preview]`, `${LogColor.Preview}`];
            case LogLevel.Warn:
                return [`[${this.tag}-warn]`];
            case LogLevel.Error:
                return [`[${this.tag}-error]`];
            default:
                return [`[${this.tag}-unknown]`];
        }
    }

    preview(...args: any[]): void {
        if (!Laya.LayaEnv.isPreview) {
            return;
        }
        if (this.isIgnoreTag()) {
            return;
        }
        if (this.logLevel > LogLevel.Preview) {
            return;
        }
        const prefgix = this.getLogPrefix(LogLevel.Preview);
        this.logImpl?.log(...this._formatArgsConsole(...prefgix, ...args));
    }

    trace(...args: any[]): void {
        if (this.isIgnoreTag()) {
            return;
        }
        if (this.logLevel > LogLevel.Trace) {
            return;
        }
        const prefgix = this.getLogPrefix(LogLevel.Trace);
        this.logImpl?.trace(...this._formatArgsConsole(...prefgix, ...args));
    }

    debug(...args: any[]): void {
        if (this.isIgnoreTag()) {
            return;
        }
        if (this.logLevel > LogLevel.Debug) {
            return;
        }
        const prefgix = this.getLogPrefix(LogLevel.Debug);
        this.logImpl?.debug(...this._formatArgsConsole(...prefgix, ...args));
    }

    log(...args: any[]): void {
        if (this.isIgnoreTag()) {
            return;
        }
        if (this.logLevel > LogLevel.Log) {
            return;
        }
        const prefgix = this.getLogPrefix(LogLevel.Log);
        this.logImpl?.log(...this._formatArgsConsole(...prefgix, ...args));
    }

    info(...args: any[]): void {
        if (this.isIgnoreTag()) {
            return;
        }
        if (this.logLevel > LogLevel.Info) {
            return;
        }
        const prefgix = this.getLogPrefix(LogLevel.Info);
        this.logImpl?.info(...this._formatArgsConsole(...prefgix, ...args));
    }

    warn(...args: any[]): void {
        if (this.isIgnoreTag()) {
            return;
        }
        if (this.logLevel > LogLevel.Warn) {
            return;
        }
        const prefgix = this.getLogPrefix(LogLevel.Warn);
        this.logImpl?.warn(...this._formatArgsConsole(...prefgix, ...args));
    }

    error(...args: any[]): void {
        // if (this.isIgnoreTag()) { return; } // NOTE: 不忽略error
        if (this.logLevel > LogLevel.Error) {
            return;
        }
        const prefgix = this.getLogPrefix(LogLevel.Log);
        this.logImpl?.error(...this._formatArgsConsole(...prefgix, ...args));
    }

    /**
     * 原生平台log上报模式
     */
    private _formatArgsNative(...args: any[]): string {
        if (!Laya.LayaEnv.isConch) {
            return "";
        }
        let str1: string = "";
        args.forEach((element) => {
            str1 += " " + JSON.stringify(element);
        });
        return str1;
    }

    /**
     * 原生平台上不能直接打印object和array，因此这里将object和array转换为字符串进行输出，方便在 对应平台的开发工具中（如: Android Studio Logcat） 中直接看 log 结果
     */
    private _formatArgsConsole(...args: any[]) {
        if (Laya.LayaEnv.isConch) {
            for (let i = 0; i < args.length; i++) {
                const arg = args[i];
                if (Array.isArray(arg) || typeof arg === "object") {
                    args[i] = JSON.stringify(arg);
                }
            }
        }
        return args;
    }
}
