/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/battle/pve-ui/building_func/bd_func_production_speed_item.lh
 */
export class BDFuncProductionSpeedItemUIBase extends Laya.Box {
    public img_jiantou!: Laya.Image;
    public leb_01!: Laya.Label;
    public leb_02!: Laya.Label;
}

