const { regClass } = Laya;
import { BaseRoundWindowBgBase } from "./BaseRoundWindowBg.generated";

@regClass('LZ7y4udPSeaFtsKk_xNAGQ')
export class BaseRoundWindowBg extends BaseRoundWindowBgBase {
    /** 设置窗口标题 */
    setTitle(title: string) {
        this.title.text = title;
    }
}
