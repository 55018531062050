import { app } from "../../app";
import { assert } from "../../core/dispatcher";
import { Mediator } from "../../core/ui-mediator";
import proto, { hero } from "../../def/auto/proto";
import { IconResHelper } from "../../misc/IconResHelper";
import { SystemEvent } from "../../misc/system-event";
import { ui } from "../../misc/ui";
import { CharacterDetailTab } from "./ui-runtime/detail/CharacterDetailTab";
import { CharacterMatDispBoxRenderArgs } from "./ui-runtime/detail/CharacterMatDispBox";
import { UICharacterDetail, UICharacterDetailArgs } from "./ui-runtime/detail/UICharacterDetail";
import { UICharacterSelectMatArgs } from "./ui-runtime/mat/UICharacterSelectMat";
import { UICharacterRebuildArgs } from "./ui-runtime/rebuild/UICharacterRebuild";
import { UICharacterUpStageArgs } from "./ui-runtime/upstage/UICharacterUpStage";
import { UICharacterUpStarPreviewArgs } from "./ui-runtime/upstar-preview/UICharacterUpStarPreview";
import { HeroJobs, HeroVo } from "./vo/HeroVo";

import { ImageRef } from "../../misc/ImageRef";
import { TableUtil } from "../table/TableUtil";

const { regClass, property } = Laya;

const listDetailTab = [["升级"], ["升星"], ["羁绊"], ["简介"]];

export interface IUpStarSelectData {
    uid: number;
    star: number;
    readonly selectSelfUids: number[];
    readonly selectedOtherUids: number[];
}

class FakeHeroVo extends HeroVo {
    // 构造一个uid为0的默认初始的HeroVo
    static Create(id: number, star: number): FakeHeroVo {
        const heroRow = TableUtil.getRow(app.service.table.hero.hero, {
            id: id,
        })!;
        const attrs: { [key: string]: number } = {};
        ["atk", "hp", "defence"]
            .map((v) => TableUtil.getRow(app.service.table.attr, { define: v })!)
            .forEach((v) => {
                const key = `attr_base${v.id}` as keyof typeof heroRow;
                attrs[v.id] = heroRow[key] as number;
            });

        const data: proto.hero.Hero = new proto.hero.Hero({
            uid: 0,
            id: id,
            lv: 1,
            star: star,
            stage: 0,
            attrs,
            heroMaterials: [],
        });
        return new FakeHeroVo(heroRow, data);
    }
}

@regClass('TiAYRJWsSvKPJ3bjHa2GEQ')
export class UICharacterDetailMediator extends Mediator {
    // 角色升星选择素材的缓存数据，用于在选择素材界面选择素材后返回时，更新界面
    static readonly UpStarSelectData: IUpStarSelectData = {
        uid: 0,
        star: -1,
        selectSelfUids: [] as const,
        selectedOtherUids: [] as const,
    };

    declare owner: UICharacterDetail;

    // curHeroVo 在使用的时候需要判断uid是否为0，为0时需要特殊处理
    private curHeroVo!: HeroVo | FakeHeroVo;

    override onAwake(): void {
        super.onAwake();

        this.owner.btnClose.clickHandler = new Laya.Handler(this, this.onClose);
        this.owner.btnLeft.clickHandler = new Laya.Handler(this, this.onClickBtnLeft);
        this.owner.btnRight.clickHandler = new Laya.Handler(this, this.onClickBtnRight);
        this.owner.btnRebuild.clickHandler = new Laya.Handler(this, this.onClickBtnRebuild);
        this.owner.btnUpgrade.clickHandler = new Laya.Handler(this, this.onClickBtnUpgrade);
        this.owner.btnUpStar.clickHandler = new Laya.Handler(this, this.onClickBtnUpStar);
        this.owner.btnLllusion.clickHandler = new Laya.Handler(this, this.onClickLeadSkin);

        this.owner.listDetailTab.selectedIndex = -1;
        this.owner.listDetailTab.selectHandler = new Laya.Handler(this, this.onSelectDetailTab);
        this.owner.listDetailTab.renderHandler = new Laya.Handler(this, this.onRenderDetailTab);
        this.owner.listDetailTab.selectCheck = this.onDetailTabSelectCheck;
        this.owner.listDetailTab.array = [];

        this.owner.btnUpStarTips.clickHandler = new Laya.Handler(this, this.onClickBtnUpStarTips);

        this.onMoneyUpdate();
        this.pageInitWith(this.owner.openArgs);

        this.listenEvens();
    }

    private listenEvens() {
        this.$(app).on(SystemEvent.CHARACTER.SVR_HERO_UPDATE, this._onCharacterSvrUpdate, this);
        this.$(app).on(SystemEvent.USER_MONEY_UPDATE, this.onMoneyUpdate, this);
        this.$(app).on(SystemEvent.SkILL.SKILL_UP_GRADE, this.refreshBoxSkill, this);
        this.$(app).on(SystemEvent.SkILL.SKILL_MOUNT, this.refreshBoxSkill, this);
        this.$(app).on(SystemEvent.SkILL.SKILL_UNMOUNT, this.refreshBoxSkill, this);
    }

    private onClose() {
        this.owner.destroy();
    }

    private _onCharacterSvrUpdate(resp: hero.notify_hero_update) {
        if (!this.curHeroVo.uid) {
            return;
        }
        if (!resp.heroList.find((v) => v.uid === this.curHeroVo.uid)) {
            // 更新与当前角色无关
            return;
        }

        const heroVo = app.service.hero.heroVoMap.get(this.curHeroVo.uid);
        assert(!!heroVo, `heroVo not found, uid=${this.curHeroVo.uid}`);
        this.refreshByHeroVo(heroVo, false);
    }

    private onSelectDetailTab(index: number) {
        // console.log("UICharacterDetailMediator.onSelectDetailTab", index);
        if (index < 0) {
            return;
        }

        this.owner.boxUpgrade.active = this.owner.boxUpgrade.visible = index === 0;
        this.owner.boxUpStar.active = this.owner.boxUpStar.visible = index === 1;
        if (index !== 1) {
            this.resetUpStarSelectData();
        }
        this.refreshTabBox(index);
    }

    private onRenderDetailTab(cell: CharacterDetailTab, index: number) {
        const data = this.owner.listDetailTab.array[index];
        cell.lblTitle.text = data[0];

        const isSelected = this.owner.listDetailTab.selectedIndex === index;
        cell.lblTitle.fontSize = isSelected ? 36 : 30;
        cell.lblTitle.bottom = isSelected ? 20 : 13;

        // if (index === this.owner.listDetailTab.array.length - 1) {
        //     this.owner.listDetailTab.renderHandler = null;
        //     this.owner.listDetailTab.selectedIndex = 0;
        // }
    }

    private onDetailTabSelectCheck(index: number): boolean {
        // console.log("UICharacterDetailMediator.onDetailTabSelectCheck", index);
        if (index < 2) {
            return true;
        }
        switch (index) {
            case 2:
            case 3:
                app.ui.toast(`功能${listDetailTab[index][0]}暂未开放`);
                break;
        }
        return false;
    }

    private refreshTabBox(index: number) {
        if (this.owner.boxUpgrade.visible) {
            this.refreshBoxUpgrade(this.curHeroVo);
        }
        if (this.owner.boxUpStar.visible) {
            this.refreshBoxUpStar(this.curHeroVo);
        }
    }

    private refreshBoxUpgrade(heroVo: HeroVo | FakeHeroVo) {
        const MAX_LEVEL = app.service.table.hero.conf.MAX_LEVEL;
        const lvLimit = app.service.table.hero.hero_stage.lv_limit[heroVo.stage];
        this.owner.lblLv.text = `等级：${heroVo.lv}/${lvLimit}`;

        this.owner.lblAtkValue.text = `${heroVo.atk}`;
        this.owner.lblHpValue.text = `${heroVo.hp}`;
        this.owner.lblDefendValue.text = `${heroVo.defence}`;

        const isMaxLv = heroVo.lv >= MAX_LEVEL;
        this.owner.lblMaxLevel.active = this.owner.lblMaxLevel.visible = isMaxLv;
        this.owner.btnUpgrade.active = this.owner.btnUpgrade.visible = !isMaxLv;

        this.owner.lblUpgradeResource.color = "#02ac16";
        const isFake = !heroVo.uid;
        if (isFake) {
            this.owner.lblUpgradeResource.text = "0";
            this.owner.btnUpgrade.disabled = true;
            this.owner.btnUpgrade.label = `升级`;
            return;
        }

        if (isMaxLv) {
            this.owner.lblUpgradeResource.text = "0";
            this.owner.btnUpgrade.disabled = true;
            this.owner.btnUpgrade.label = `已满级`;
            return;
        }

        const upgradeNeedResource = app.service.hero.calcuUpgradeLvNeedResource(
            heroVo.lv,
            heroVo.stage
        );
        this.owner.btnUpgrade.disabled = false;
        this.owner.lblUpgradeResource.text = `${upgradeNeedResource.needResCount}`;

        if (upgradeNeedResource.needUpStage) {
            this.owner.btnUpgrade.label = `升阶`;
        } else {
            if (upgradeNeedResource.targetLv > heroVo.lv) {
                this.owner.btnUpgrade.label = `升级${upgradeNeedResource.targetLv - heroVo.lv}次`;
            } else {
                this.owner.lblUpgradeResource.color = "#E53030"; //红色
                this.owner.btnUpgrade.label = "升级";
            }
        }

        this.refreshBoxSkill();
    }

    private refreshBoxUpStar(heroVo: HeroVo | FakeHeroVo) {
        const upStarRet = app.service.hero.calcuUpStarResult(heroVo.starProgramme, heroVo.star);
        // console.log("UICharacterDetailMediator.refreshBoxUpStar", upStarRet);
        const isFake = !heroVo.uid;
        const isMax = !upStarRet;
        this.owner.btnUpStar.disabled = isFake || isMax;
        this.owner.boxStarMatSame.active = this.owner.boxStarMatSame.visible = !isFake && !isMax;
        this.owner.boxStarMatStar.active = this.owner.boxStarMatStar.visible = !isFake && !isMax;
        this.owner.nextStarHBox.active = this.owner.nextStarHBox.visible = !isMax;

        this.refreshStar(
            [
                this.owner.imgPreStar0,
                this.owner.imgPreStar1,
                this.owner.imgPreStar2,
                this.owner.imgPreStar3,
                this.owner.imgPreStar4,
            ],
            heroVo.star
        );
        this.refreshStar(
            [
                this.owner.imgNextStar0,
                this.owner.imgNextStar1,
                this.owner.imgNextStar2,
                this.owner.imgNextStar3,
                this.owner.imgNextStar4,
            ],
            heroVo.star + 1
        );

        if (isMax) {
            // 星级已满, 不用再升星，显示已满星
            this.owner.lblNextStar.text = "";
            this.owner.btnUpStar.text.text = "已满星";
        } else {
            this.owner.lblNextStar.text = `星级效果：${upStarRet.nextStarEntryDesc}`;
            this.owner.btnUpStar.text.text = "升星";
            if (!isFake) {
                const upStarMat = app.service.hero.getUpStarMatHeroes(heroVo.uid);
                if (
                    UICharacterDetailMediator.UpStarSelectData.uid !== heroVo.uid ||
                    UICharacterDetailMediator.UpStarSelectData.star !== heroVo.star
                ) {
                    UICharacterDetailMediator.UpStarSelectData.uid = heroVo.uid;
                    UICharacterDetailMediator.UpStarSelectData.star = heroVo.star;
                    UICharacterDetailMediator.UpStarSelectData.selectSelfUids.length = 0; //重置
                    UICharacterDetailMediator.UpStarSelectData.selectedOtherUids.length = 0; //重置

                    if (upStarMat.requireSelfCnt) {
                        for (let i = 0; i < upStarMat.requireSelfCnt; i++) {
                            if (i >= upStarMat.selfMatHeroes.length) {
                                break;
                            }
                            UICharacterDetailMediator.UpStarSelectData.selectSelfUids.push(
                                upStarMat.selfMatHeroes[i].uid
                            );
                        }
                    }

                    if (upStarMat.requireHeroConf.length > 0) {
                        const need = upStarMat.requireHeroConf[0][1];
                        for (let i = 0; i < need; i++) {
                            if (i >= upStarMat.otherMatHeroes.length) {
                                break;
                            }
                            UICharacterDetailMediator.UpStarSelectData.selectedOtherUids.push(
                                upStarMat.otherMatHeroes[i].uid
                            );
                        }
                    }
                }

                this.owner.boxStarMatSame.active = this.owner.boxStarMatSame.visible =
                    !!upStarMat.requireSelfCnt;
                if (upStarMat.requireSelfCnt) {
                    const boxSameArgs: CharacterMatDispBoxRenderArgs = {
                        type: "upstar_self",
                        dispHeroVo:
                            UICharacterDetailMediator.UpStarSelectData.selectSelfUids.length > 0
                                ? app.service.hero.heroVoMap.get(
                                      UICharacterDetailMediator.UpStarSelectData.selectSelfUids[0]
                                  )!
                                : heroVo,
                        hasHeroCount:
                            UICharacterDetailMediator.UpStarSelectData.selectSelfUids.length,
                        needHeroCount: upStarMat.requireSelfCnt,
                    };
                    this.owner.boxStarMatSame.onRender(boxSameArgs);
                    this.owner.boxStarMatSame.offAll(Laya.Event.CLICK);
                    this.owner.boxStarMatSame.on(Laya.Event.CLICK, this, () => {
                        const availableUids = upStarMat.selfMatHeroes.map((v) => v.uid);
                        if (availableUids.length < upStarMat.requireSelfCnt) {
                            app.ui.toast("无适合的角色");
                            return;
                        }

                        const args: UICharacterSelectMatArgs = {
                            type: "upstar_self",
                            uid: heroVo.uid,
                            needCount: upStarMat.requireSelfCnt,
                            availableUids,
                            selectedUids: UICharacterDetailMediator.UpStarSelectData.selectSelfUids,
                        };
                        app.ui.show(ui.CHARACTER_SELECT_MAT, args);
                    });
                }

                this.owner.boxStarMatStar.active = this.owner.boxStarMatStar.visible =
                    !!upStarMat.requireHeroConf.length;
                if (upStarMat.requireHeroConf.length) {
                    const boxStarArgs: CharacterMatDispBoxRenderArgs = {
                        type: "upstar_other",
                        dispHeroVo:
                            UICharacterDetailMediator.UpStarSelectData.selectedOtherUids.length > 0
                                ? app.service.hero.heroVoMap.get(
                                      UICharacterDetailMediator.UpStarSelectData
                                          .selectedOtherUids[0]
                                  )!
                                : heroVo,
                        hasHeroCount:
                            UICharacterDetailMediator.UpStarSelectData.selectedOtherUids.length,
                        needHeroCount: upStarMat.requireHeroConf[0][1],
                    };
                    this.owner.boxStarMatStar.onRender(boxStarArgs);
                    this.owner.boxStarMatStar.offAll(Laya.Event.CLICK);
                    this.owner.boxStarMatStar.on(Laya.Event.CLICK, this, () => {
                        const availableUids = upStarMat.otherMatHeroes.map((v) => v.uid);
                        if (availableUids.length < upStarMat.requireHeroConf[0][1]) {
                            app.ui.toast("无适合的角色");
                            return;
                        }

                        const args: UICharacterSelectMatArgs = {
                            type: "upstar_other",
                            uid: heroVo.uid,
                            needCount: upStarMat.requireHeroConf[0][1],
                            availableUids,
                            selectedUids:
                                UICharacterDetailMediator.UpStarSelectData.selectedOtherUids,
                        };
                        app.ui.show(ui.CHARACTER_SELECT_MAT, args);
                    });
                }

                if (this.owner.boxStarMatSame.visible !== this.owner.boxStarMatStar.visible) {
                    this.owner.boxStarMatSame.centerX = 0;
                    this.owner.boxStarMatStar.centerX = 0;
                } else {
                    this.owner.boxStarMatSame.centerX = -120;
                    this.owner.boxStarMatStar.centerX = 120;
                }
            }
        }
    }

    private refreshStar(
        imgStars: [Laya.Image, Laya.Image, Laya.Image, Laya.Image, Laya.Image],
        star: number
    ) {
        const dispStar = star > 5 ? star - 5 : star;

        imgStars.forEach((v, i) => {
            const texturePaths = v.getComponent(ImageRef)!.texturePaths;
            v.skin = star > 5 ? texturePaths[1] : texturePaths[0];
            v.visible = i < dispStar;
        });
    }

    override onDisable(): void {
        this.resetUpStarSelectData();
    }

    private resetUpStarSelectData() {
        UICharacterDetailMediator.UpStarSelectData.uid = 0;
        UICharacterDetailMediator.UpStarSelectData.star = -1;
        UICharacterDetailMediator.UpStarSelectData.selectSelfUids.length = 0; //重置
        UICharacterDetailMediator.UpStarSelectData.selectedOtherUids.length = 0; //重置
    }

    private pageInitWith(args: UICharacterDetailArgs) {
        console.log("UICharacterDetailMediator.pageInitWith", args);
        if (args.type === "handbook" && !args.character) {
            // 构造一个uid为0的默认的HeroVo
            const character = FakeHeroVo.Create(args.handbook.id, args.handbook.star!);
            this.refreshByHeroVo(character);
        } else {
            this.refreshByHeroVo(args.character!);
        }
    }

    private refreshByHeroVo(heroVo: HeroVo | FakeHeroVo, resetSelectIndex = true) {
        console.log("UICharacterDetailMediator.refreshByHeroVo", heroVo);
        this.curHeroVo = heroVo;

        this.owner.listDetailTab.array = listDetailTab.slice(0, heroVo.isLeader ? 1 : undefined);

        const jobBgTextures = this.owner.imgJobBg.getComponent(ImageRef)!.texturePaths;
        this.owner.imgJobBg.skin = jobBgTextures[heroVo.quality];
        this.owner.lblJob.strokeColor = IconResHelper.GetStrokeColorByQuality(heroVo.quality);

        this.owner.imgCharacter.skin = heroVo.iconFull ?? "";

        if (this.curHeroVo.uid) {
            const heroList = app.service.hero.getAllHeroList();
            const curIndex = heroList.findIndex((v) => v.uid === heroVo.uid);
            this.owner.btnLeft.active = this.owner.btnLeft.visible = curIndex > 0;
            this.owner.btnRight.active = this.owner.btnRight.visible =
                curIndex < heroList.length - 1;
        } else {
            this.owner.btnLeft.active = this.owner.btnLeft.visible = false;
            this.owner.btnRight.active = this.owner.btnRight.visible = false;
        }

        this.owner.lblName.text = heroVo.name;
        this.owner.lblJob.text = heroVo.isLeader ? "统帅" : HeroJobs[heroVo.job - 1].desc;

        this.owner.btnRebuild.active = this.owner.btnRebuild.visible =
            !heroVo.isLeader && heroVo.lv > 1;

        const imgStars = [
            this.owner.imgStar0,
            this.owner.imgStar1,
            this.owner.imgStar2,
            this.owner.imgStar3,
            this.owner.imgStar4,
        ];
        imgStars.forEach((v, i) => (v.visible = i < heroVo.star));

        if (resetSelectIndex) {
            this.owner.listDetailTab.selectedIndex = -1; //这样才能强制刷新
            this.owner.listDetailTab.selectedIndex = 0;
        } else {
            this.onSelectDetailTab(this.owner.listDetailTab.selectedIndex);
        }
        this.owner.btnLllusion.visible = heroVo.isLeader;
    }

    private onClickBtnLeft() {
        const heroList = app.service.hero.getAllHeroList();
        const curIndex = heroList.findIndex((v) => v.uid === this.curHeroVo.uid);
        this.refreshByHeroVo(heroList[curIndex - 1]);
    }

    private onClickBtnRight() {
        const heroList = app.service.hero.getAllHeroList();
        const curIndex = heroList.findIndex((v) => v.uid === this.curHeroVo.uid);
        this.refreshByHeroVo(heroList[curIndex + 1]);
    }

    private onClickBtnUpgrade() {
        const upgradeNeedResource = app.service.hero.calcuUpgradeLvNeedResource(
            this.curHeroVo.lv,
            this.curHeroVo.stage
        );
        console.log(
            "UICharacterDetailMediator.onClickBtnUpgrade",
            upgradeNeedResource,
            this.curHeroVo
        );

        if (upgradeNeedResource.needUpStage) {
            app.ui.show(ui.CHARACTER_UP_STAGE, this.curHeroVo as UICharacterUpStageArgs);
            return;
        } else {
            if (upgradeNeedResource.targetLv > this.curHeroVo.lv) {
                app.service.hero.requestUpgradeLevel(
                    this.curHeroVo.uid,
                    upgradeNeedResource.targetLv
                );
            } else {
                app.ui.toast("道具不足");
            }
        }
    }

    private async onClickBtnUpStar() {
        const uid = UICharacterDetailMediator.UpStarSelectData.uid;
        const upStarMat = app.service.hero.getUpStarMatHeroes(uid);
        if (
            upStarMat.requireSelfCnt !==
                UICharacterDetailMediator.UpStarSelectData.selectSelfUids.length ||
            upStarMat.requireHeroConf[0][1] !==
                UICharacterDetailMediator.UpStarSelectData.selectedOtherUids.length
        ) {
            app.ui.toast("升星素材不足");
            return;
        }

        const materialHeroUids = ([] as number[]).concat(
            UICharacterDetailMediator.UpStarSelectData.selectSelfUids,
            UICharacterDetailMediator.UpStarSelectData.selectedOtherUids
        );
        const resp = await app.service.hero.requestUpgradeStar(uid, materialHeroUids);
        if (resp.err) {
            const service = app.service.hero;
            console.error(
                "requestUpgradeStar",
                service.heroVoMap.get(uid),
                materialHeroUids.map((v) => service.heroVoMap.get(v))
            );
        }
    }

    private onClickLeadSkin() {
        const uid = this.curHeroVo.uid;
        app.ui.show(ui.LEADER_SKIN, uid);
    }

    private onClickBtnUpStarTips() {
        const heroVo = this.curHeroVo;
        const args: UICharacterUpStarPreviewArgs = {
            id: heroVo.id,
            star: heroVo.star,
        };
        app.ui.show(ui.CHARACTER_UP_STAR_PREVIEW, args);
    }

    private onClickBtnRebuild() {
        console.log("UICharacterDetailMediator.onClickBtnRebuild");
        const args: UICharacterRebuildArgs = {
            uid: this.curHeroVo.uid,
        };
        app.ui.show(ui.CHARACTER_REBUILD, args);
    }

    private onMoneyUpdate() {
        const itemId = app.service.table.hero.conf.UPGRADE_LEVEL_ITEM_ID;
        const itemCount = app.service.user.moneyVoMap.get(itemId)?.count ?? 0;
        this.owner.lblResValue0.text = `${itemCount}`;
    }

    private refreshBoxSkill() {
        const skillBoxList = [
            this.owner.btnSkill0,
            this.owner.btnSkill1,
            this.owner.btnSkill2,
            this.owner.btnSkill3,
        ];
        const heroUid = this.curHeroVo.uid;

        for (let i = 0; i < skillBoxList.length; i++) {
            const boxSkil = skillBoxList[i];
            boxSkil.onRender(heroUid, i + 1);
        }
    }
}
