const { regClass } = Laya;
import { app } from "../../../../app";
import {
    SoliderKingRewardItem,
    SoliderKingRewardItemUIArgs,
} from "../../../soliderking/ui-runtime/SoliderKingRewardItem";
import { UICharacterFameRewardBase } from "./UICharacterFameReward.generated";

@regClass('_ef-hXu6R_SdD7_ukyXGWQ')
export class UICharacterFameReward extends UICharacterFameRewardBase {
    override onAwake(): void {
        super.onAwake();
        this.bthClose.on(Laya.Event.CLICK, this, this.close);

        this.listReward.renderHandler = new Laya.Handler(this, this.rewardItemRender);

        const fameStatus = app.service.hero.calcuFameStatus();
        const fameRows = app.service.table.hero.fame_stage.slice().reverse();
        this.listReward.array = fameRows.map((row, index) => {
            let ratio = fameStatus.recvFameStage >= row.id ? 1 : 0;
            if (row.id === fameStatus.curFameStageRow.id) {
                const nextFameStageRow = fameRows.find(
                    (r) => r.id === fameStatus.curFameStageRow.id + 1
                );
                if (nextFameStageRow) {
                    ratio =
                        (fameStatus.curFame - fameStatus.curFameStageRow.require_fame) /
                        (nextFameStageRow.require_fame - fameStatus.curFameStageRow.require_fame);
                }
            }

            return <SoliderKingRewardItemUIArgs>{
                type: "character_fame",
                data: {
                    conf: row,
                    bGot: fameStatus.recvFameStage >= row.id,
                    showBar: index !== 0,
                    ratio,
                },
            };
        });
        const targetIndex = fameStatus.recvFameStage;
        this.listReward.scrollTo(fameRows.length - targetIndex - 1);

        this.lblFame.text = `${fameStatus.curFame}`;
    }

    private rewardItemRender(cell: SoliderKingRewardItem, index: number) {
        const data = this.listReward.array[index] as SoliderKingRewardItemUIArgs;
        cell.updateInfo(data, -index);
    }
}
