import { BattleType } from "../../../def/auto/battle";
import { BattleSceneRow } from "../../../def/table";
import { Vo } from "../../../misc/vo/Vo";

export class BattleSceneConfigVo extends Vo<BattleSceneRow, never> {
    override get key(): number {
        return this._config.id;
    }

    get battleType(): BattleType {
        return this._config.battle_type;
    }

    get maxSlot(): number {
        return this._config.max_slot;
    }

    get maxWaitList(): number {
        return this._config.max_wait_list;
    }

    get width(): number {
        return this._config.width;
    }

    get height(): number {
        return this._config.height;
    }

    get gridWidth(): number {
        return this._config.grid_width;
    }

    get gridHeight(): number {
        return this._config.grid_height;
    }

    get leftBattleCol(): number {
        return this._config.left_battle_col;
    }

    get rightBattleCol(): number {
        return this._config.right_battle_col;
    }

    get wallPos() {
        return this._config.wall_pos;
    }

    override clone(): BattleSceneConfigVo {
        return new BattleSceneConfigVo(this._config);
    }
}
