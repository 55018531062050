@Laya.regClass('4-rKpXulRyec6IuydRZOOw')
@Laya.classInfo({
    menu: "自定义",
    icon: "plugins/type-icons/Node/Sprite3D.svg",
    worldType: "3d",
})
@Laya.runInEditor
// 与主摄像机同一方向，用于模拟粒子系统billborad效果
export class FollowMainCameraRotation extends Laya.Script {
    declare owner: Laya.Sprite3D;

    private get camera() {
        return (this.owner.scene.getChildByName("Main Camera") ??
            this.owner.scene
                .getChildByName("cameraNode")
                ?.getChildByName("Main Camera")) as Laya.Camera;
    }

    override onUpdate() {
        const camera = this.camera;
        if (camera) {
            // const forward = new Laya.Vector3();
            // camera.transform.getForward(forward);
            // const target = this.owner.transform.position.clone();
            // target.vadd(forward, target);
            // this.owner.transform.lookAt(target, Laya.Vector3.Up);
            this.owner.transform.rotation = camera.transform.rotation;
        }
    }
}
