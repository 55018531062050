/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/character/detail/char_detail_tab.lh
 */
export class CharacterDetailTabBase extends Laya.Box {
    public imgBg!: Laya.Image;
    public selectBox!: Laya.Image;
    public lblTitle!: Laya.Label;
}

