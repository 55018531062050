/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIHBoxStar } from "../../hero/ui-runtime/UIHBoxStar";

/**
 * resources/prefab/troop/PvpTroopListHeroItem.lh
 */
export class PvpTroopListHeroItemUIBase extends Laya.Box {
    public boxItem!: Laya.Box;
    public imgBg!: Laya.Image;
    public imgType!: Laya.Image;
    public imgHero!: Laya.Image;
    public boxDamage!: Laya.Box;
    public txtDamage!: Laya.Text;
    public boxStar!: UIHBoxStar;
    public labName!: Laya.Label;
    public labStar!: Laya.Label;
    public labJob!: Laya.Label;
    public imgAdd!: Laya.Image;
}

