import * as ecs from "../../../../../core/ecs";
import { TweenData } from "../../../pve-server/PveDefs";

export class PveSpoilsComponent extends ecs.Component {
    readonly mounter: Laya.Sprite3D = new Laya.Sprite3D("PveSpoilsComp");
    view?: Laya.Sprite3D | null;
    res!: string;

    totalLen: number = 0;

    delay: number = 0;

    /** 等待缓动的数据 */
    tweenDatas: TweenData[] | undefined;

    /** 当前位置 */
    curPos: Laya.Vector3 | undefined = undefined;

    /** 是否需要动画的标记 */
    needAniFlag: boolean = false;

    tweenTime: number = 0;

    aniOverTime: number = 0;

    setTweenDatasAndStartTween2(datas: TweenData[], totalLen: number, delay: number): void {
        this.delay = delay;
        this.totalLen = totalLen;
        this.tweenDatas = datas;
        this.needAniFlag = true;
    }
}
