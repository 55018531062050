/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIImageBar } from "../../../core/ui/UIImageBar";
import { UIList } from "../../../core/ui/UIList";

/**
 * resources/prefab/user/user.lh
 */
export class UserUIBase extends Laya.Dialog {
    public labelLv!: Laya.Label;
    public imgBar!: UIImageBar;
    public labelBarNum!: Laya.Label;
    public imgHeadBg!: Laya.Image;
    public imgHeadIcon!: Laya.Image;
    public labelId!: Laya.Label;
    public btnCopy!: Laya.Button;
    public labelUnionName!: Laya.Label;
    public btnUp!: Laya.Button;
    public labelName!: Laya.Label;
    public btnRename!: Laya.Button;
    public labelPower!: Laya.Label;
    public boxHero!: Laya.Box;
    public listTabMenu!: UIList;
    public btnSkin!: Laya.Button;
}
