/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIRadioGroup } from "../../../core/ui/UIRadioGroup";
import { UIList } from "../../../core/ui/UIList";

/**
 * resources/prefab/alliance/UIAllianceJoin.lh
 */
export class UIAllianceJoinBase extends Laya.Dialog {
    public tab!: UIRadioGroup;
    public joinPage!: Laya.Box;
    public searchText!: Laya.TextInput;
    public btnSearch!: Laya.Button;
    public applyPage!: Laya.Box;
    public applyTab!: UIRadioGroup;
    public applyList!: UIList;
}

