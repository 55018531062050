const { regClass } = Laya;
import { app } from "../../../app";
import { res } from "../../../misc/res";
import { SkillVo } from "../vo/SkillVo";
import { SkillOwnListItemUI } from "./SkillOwnListItemUI";
import { SkillOwnListUIBase } from "./SkillOwnListUI.generated";

@regClass('18xdRZEjTkuXVep2HWUcZA')
export class SkillOwnListUI extends SkillOwnListUIBase {
    private skillItemList: { [key: number]: SkillOwnListItemUI } = {}; //存放添加进入的

    private curClikItem: SkillOwnListItemUI | null = null;

    private vboxContentHeight: number = 0;

    constructor() {
        super();
    }

    async initList(skillDatas: SkillVo[]) {
        this.vboxContent.removeChildren();
        this.curClikItem = null;
        this.vboxContentHeight = 0;
        const checker = (): boolean => !this.vboxContent.destroyed;
        for (const skilData of skillDatas) {
            const prefab = await app.loader.loadPrefab(res.skill.SKILL_OWN_ITEM);
            if (!checker()) {
                return;
            }
            const itemBox = prefab.create() as SkillOwnListItemUI;
            const skillId = skilData.skillId;
            itemBox.isShowDetails(false);
            this.vboxContent.addChild(itemBox);
            this.skillItemList[skillId] = itemBox;
            itemBox.dataSource = skilData;
            itemBox.on(Laya.Event.CLICK, this, this.onClickItem);
        }
        this.vboxContent.height = this.vboxContentHeight =
            149 * skillDatas.length + this.vboxContent.space * (skillDatas.length - 1);
    }

    private onClickItem(e: Laya.Event) {
        const targe = e.currentTarget as SkillOwnListItemUI;
        if (this.curClikItem === targe) {
            this.curClikItem.isShowDetails(false);
            this.vboxContent.height = this.vboxContent.height - 111;
            this.curClikItem = null;
            return;
        }
        if (!this.curClikItem) {
            this.vboxContent.height = this.vboxContent.height + 111;
        }
        if (this.curClikItem) {
            this.curClikItem.isShowDetails(false);
        }
        this.curClikItem = targe;
        this.curClikItem.isShowDetails(true);
    }
}
