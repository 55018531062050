import { app } from "../../../../../../app";
import { mainline } from "../../../../../../def/auto/proto";
import { BattleSpoilsLo } from "../../../../pve/vo/BattleSpoilsLo";

export class PveSvrSpoilsStateData {
    /** 对应表 spoils.xlsx */
    tid: number = 0; //需要记录

    /** 对应的表数据 */
    get spoilsRow(): BattleSpoilsLo {
        return app.service.pve.spoilsLoMap.get(this.tid)!;
    }

    /**
     * 位置
     */
    pos: Laya.Vector2 = new Laya.Vector2(); //需要记录

    /** 掉落的时间戳，单位秒，用于计算清理时间 */
    dropTime: number = 0; //需要记录

    /** 是否过期,六个小时不拾取的掉落自动移除 */
    get isOverdue(): boolean {
        const cfg = app.service.table.spoils[this.tid];
        if (!cfg) return true; // 策划在表里删除了该战利品，则视为过期。
        const now = app.service.network.serverTime;
        const overdueTime = this.dropTime + cfg.delay_destroy;
        if (now >= overdueTime) {
            return true;
        }
        return false;
    }

    decode(data: mainline.Spoils) {
        this.tid = data.tid;
        this.dropTime = data.dropTime;
        this.pos.x = (data.pos?.x ?? 0) / 10;
        this.pos.y = (data.pos?.z ?? 0) / 10;
    }

    encode(): mainline.Spoils {
        const data = mainline.Spoils.create();
        data.tid = this.tid;
        data.dropTime = this.dropTime;

        data.pos = mainline.Position.create();
        data.pos.x = (this.pos.x * 10) | 0;
        data.pos.z = (this.pos.y * 10) | 0;
        return data;
    }
}
