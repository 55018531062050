const { regClass } = Laya;
import { UIEffectForWakeMediator } from "../UIEffectForWakeMediator";
import { UIEffectForWakeBase } from "./UIEffectForWake.generated";

@regClass('ILRNObfcQA2Bglpz7ho9Ug')
export class UIEffectForWake extends UIEffectForWakeBase {
    public get mediator(): UIEffectForWakeMediator | null {
        return this.getComponent(UIEffectForWakeMediator);
    }

    override onAwake(): void {
        super.onAwake();
    }
}
