import {
    Node,
    NodeDef,
    NodeVars as NodeVarsBase,
    Process,
    Status,
} from "../../../../../core/behavior3";
import { BattleAid } from "../../../../../def/auto/battle";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrCreatureComponent } from "../../ecs/components/PveSvrCreatureComponent";
import { ElementTag } from "../../ecs/components/PveSvrElementComponent";
import {
    PveSvrSkillLauncherComponent,
    SkillTreeEnv,
} from "../../ecs/components/PveSvrSkillLauncherComponent";
import { SkillOption } from "../BtreeDef";

interface NodeArgs {
    readonly radius?: number;
    readonly hero?: boolean;
    readonly soldier?: boolean;
    readonly friend?: boolean;
    readonly skillId?: number;
}

interface NodeVars extends NodeVarsBase {
    tag: ElementTag;
}

const SkillRangeOption = [{ name: "自定义范围", value: -2 }, ...SkillOption];

export class FindTargets extends Process {
    override init(node: Node) {
        const vars = node.vars as NodeVars;
        const args = node.args as unknown as NodeArgs;
        vars.tag = 0;
        if (args.hero) {
            vars.tag |= ElementTag.LEADER;
        }
        if (args.soldier) {
            vars.tag |= ElementTag.MEMBER;
        }
    }

    override run(node: Node, env: AiTreeEnv): Status {
        const args = node.args as unknown as NodeArgs;
        const vars = node.vars as NodeVars;
        let radius = 0;
        if (typeof args.skillId === "number" && args.skillId >= -1) {
            let skill;
            if (args.skillId === -1) {
                if (env instanceof SkillTreeEnv) {
                    radius = env.skill.data.radius ?? 0;
                } else {
                    node.error(`current env is not SkillTreeEnv`);
                }
            } else if (args.skillId == 0) {
                const launcher = env.owner.getComponent(PveSvrSkillLauncherComponent);
                if (launcher) {
                    for (const idx in launcher.atkSkills) {
                        const s = launcher.atkSkills[idx];
                        radius = Math.max(radius, s.data.radius ?? 0);
                    }
                }
            } else if (args.skillId > 0) {
                const launcher = env.owner.getComponent(PveSvrSkillLauncherComponent);
                skill = launcher?.skills[args.skillId];
                radius = skill?.data.radius ?? 0;
            }
        } else {
            radius = args.radius ?? 0;
        }

        if (radius <= 0) {
            console.error(`invalid radius: ${radius}`, args);
            return "failure"; // 半径为0，直接失败
        }

        const position = env.owner.transform.position;
        const arr = env.context.find((element) => {
            const creature = element.getComponent(PveSvrCreatureComponent);
            if (!creature) {
                return false;
            }
            // 友方意味着，aid同为self和ally或者同为enemy或中立
            const isFriend =
                ((env.owner.aid === BattleAid.SELF || env.owner.aid === BattleAid.ALLY) &&
                    (element.aid === BattleAid.SELF || element.aid === BattleAid.ALLY)) ||
                ((env.owner.aid === BattleAid.ENEMY || env.owner.aid === BattleAid.NEUTRAL) &&
                    (element.aid === BattleAid.ENEMY || element.aid === BattleAid.NEUTRAL));

            const isAlive = creature && !creature.isDie;
            if (element.tag & vars.tag && (args.friend ? isFriend : !isFriend) && isAlive) {
                const distance = Laya.Vector3.distanceXZ(element.transform.position, position);
                if (distance < radius) {
                    element.tmpDistance = distance;
                    return true;
                } else {
                    return false;
                }
            }

            return false;
        });
        if (arr && arr.length > 0) {
            arr.sort((a, b) => a.tmpDistance - b.tmpDistance);
            env.output.push(arr.map((e) => e.eid));
            return "success";
        } else {
            return "failure";
        }
    }

    override get descriptor(): NodeDef {
        return {
            name: "FindTargets",
            type: "Condition",
            desc: "给定的范围内查找多个目标",
            args: [
                { name: "hero", type: "boolean?", desc: "找英雄" },
                { name: "soldier", type: "boolean?", desc: "找士兵" },
                { name: "friend", type: "boolean?", desc: "友方" },
                { name: "skillId", type: "enum?", desc: "筛选范围", options: SkillRangeOption },
                { name: "radius", type: "float?", desc: "半径" },
                { name: "limit", type: "int?", desc: "数量限制" },
            ],
            output: ["目标单位"],
            doc: `
                + 没有找到返回失败
                + 默认查找敌对单位
                + 范围优先级，普攻>技能>半径
                + 如果找到多个取最近那个
                + 普攻 id 为 0，技能1 id 为 1，以次类推
                + etype 在 battle/battle_entity 表中有定义, 默认查找所有可攻击类型
                `,
        };
    }
}
