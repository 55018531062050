import { BattleConf } from "../../../../../../def/auto/battle";
import { mainline } from "../../../../../../def/auto/proto";
import { TroopStation } from "../../../../../../def/auto/troop";
import { PveSvrSoldierStateData } from "./PveSvrSoldierStateData";

/** 士兵组记录的数据 */
export class PveSvrSoldierGroupStateData {
    /** 所在阵位索引 1-3 分别表示前中后 */
    idx: TroopStation = 0;
    /** 配置表id */
    tid: number = 0;

    readonly soldiers: PveSvrSoldierStateData[] = [];

    inited: boolean = false;

    level: number = 1;

    stage: number = 0;

    decode(data: mainline.SoldierGroup) {
        this.idx = data.idx;
        this.tid = data.tid;
        this.soldiers.length = 0;
        this.level = data.level ?? 1;
        this.stage = data.stage ?? 0;
        data.soldiers.forEach((v) => {
            const soldier = new PveSvrSoldierStateData();
            soldier.setSoldierGroup(this);
            soldier.decode(v);
            // console.log("PveSvrSoldierGroupStateData decode", soldier);
            this.soldiers.push(soldier);
        });
        this.inited = true;
    }

    /** 获取服务端需要的数据 */
    encode(): mainline.Soldier {
        const soldierGroup = mainline.SoldierGroup.create();
        soldierGroup.idx = this.idx;
        soldierGroup.tid = this.tid;
        soldierGroup.level = this.level;
        soldierGroup.stage = this.stage;
        soldierGroup.soldiers = this.soldiers.map((v) => v.encode()).filter((v) => !!v);
        return soldierGroup;
    }

    addSoldier(
        soldierIdx: number,
        tid: number,
        hp: number,
        maxHp: number,
        x: number,
        z: number,
        rot: number
    ) {
        if (this.tid !== tid) {
            console.error("addSoldier group.tid !== tid", this.tid, tid);
            return;
        } else if (this.soldiers.length >= BattleConf.PVE.SOLDIER_ONE_ROW_COUNT) {
            console.error(
                `addSoldier group.soldiers.length >= ${BattleConf.PVE.SOLDIER_ONE_ROW_COUNT}`,
                this.soldiers.length
            );
            return;
        } else if (this.soldiers.find((v) => v.idx === soldierIdx)) {
            console.error(
                "addSoldier group.soldiers.find((v) => v.idx === soldierIdx)",
                soldierIdx
            );
            return;
        }

        const data = new PveSvrSoldierStateData();
        data.setSoldierGroup(this);
        data.idx = soldierIdx;
        data.pos.x = x;
        data.pos.y = z;
        data.rotation = rot;
        data.hp = hp;
        data.maxHp = maxHp;
        data.inited = true;
        // console.warn("PveSvrSoldierGroupStateData addSoldier", data);
        this.soldiers.push(data);
    }
}
