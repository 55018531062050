import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { BattleConf } from "../../../../../def/auto/battle";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";

interface NodeArgs {
    readonly x?: number;
    readonly y?: number;
    readonly rate?: number;
    readonly rushing?: boolean;
}

type NodeInput = [Laya.Vector3 | undefined];

export class MoveToPos extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const [target] = env.input as NodeInput;
        if (!target || !(target instanceof Laya.Vector3)) {
            node.error("no target position to move");
        }

        // 设置期望位置
        env.context.setExpectGoToTargetData(env.owner, false, target!.x, target!.z);

        const args = node.args as unknown as NodeArgs;
        const speed = BattleConf.PVE.MOVE_SPEED * (args.rate ?? 1);
        const p0 = env.owner.transform.position;
        const p1 = target as Laya.Vector3;
        if (args.rushing) {
            env.context.rushStart(env.owner);
        }
        env.context.moveTo(env.owner, p1, speed);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "MoveToPos",
            type: "Action",
            desc: "移动到坐标点",
            args: [
                { name: "x", type: "int?", desc: "x" },
                { name: "y", type: "int?", desc: "y" },
                { name: "rate", type: "float?", desc: "速率" },
                { name: "rushing", type: "boolean?", desc: "冲锋" },
            ],
            input: ["坐标"],
            doc: `
                + 优先输入变量`,
        };
    }
}
