/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { VirtuallyListNewUI } from "../../common/ui-runtime/VirtuallyListNewUI";

/**
 * resources/prefab/home/chat-box.lh
 */
export class HomeChatBoxBase extends Laya.Box {
    public virtuallyList!: VirtuallyListNewUI;
    public btn!: Laya.Button;
}
