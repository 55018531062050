import { AnimName } from "../../base/Animator";

export const SkillOption = [
    { name: "当前技能", value: -1 },
    { name: "普攻", value: 0 },
    { name: "技能1", value: 1 },
    { name: "技能2", value: 2 },
    { name: "技能3", value: 3 },
    { name: "技能4", value: 4 },
];

export const AnimNameOptions = [
    { name: "普攻", value: AnimName.ATTACK },
    { name: "移动", value: AnimName.MOVE },
    { name: "待机", value: AnimName.IDLE },
    { name: "技能蓄力", value: AnimName.SKILL1_PRECAST },
    { name: "技能攻击", value: AnimName.SKILL1 },
    { name: "冲锋", value: AnimName.RUN },
    { name: "眩晕", value: AnimName.VERTIGO },
    { name: "伐木", value: AnimName.CHOP },
    { name: "收割", value: AnimName.MOW },
    { name: "挖矿", value: AnimName.DIG },
];

export const NodeStyle = {
    runningAction: {
        icon: "./icons/running.svg",
        color: "#ffda00",
    },
};
