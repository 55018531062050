import * as ecs from "../../../../../core/ecs";
import { BezierHelper } from "../../../../../misc/utils/bezier/BezierHelper";
import { PveTransformComponent } from "../../../pve/ecs/components/PveTransformComponent";
import { PveMoveUtils } from "../../../pve/ecs/utils/PveMoveUtils";
import { PveDef } from "../../PveDefs";
import { PveServer } from "../../PveServer";
import { PveSvrUtils } from "../../PveSvrUtils";
import { PveSvrAiComponent } from "../components/PveSvrAiComponent";
import { PveSvrElementComponent } from "../components/PveSvrElementComponent";
import {
    MovementCtrlType,
    MovementState,
    MovementUpdateType,
    PveSvrMovementComponent,
    TrackType,
} from "../components/PveSvrMovementComponent";
import { PveSvrSoldierGroupComponent } from "../components/PveSvrSoldierGroupComponent";
import {
    PveSvrTransformComponent,
    PveSvrTransformFlag,
} from "../components/PveSvrTransformComponent";
import { PveSvrTroopComponent } from "../components/PveSvrTroopComponent";

const tmpOffset = new Laya.Vector3();

const tmpVector3 = new Laya.Vector3();

export class PveSvrMovementSystem extends ecs.System {
    declare context: PveServer;

    override update(dt: number): void {
        this.ecs.getComponents(PveSvrMovementComponent).forEach((movement) => {
            this._onMoveUpdate(movement, dt);
        });

        this.ecs.getComponents(PveSvrTroopComponent).forEach((troop) => {
            this._onUpdateTroop(troop);
        });

        this.ecs.getComponents(PveSvrSoldierGroupComponent).forEach((group) => {
            this._onUpdateSoldierGroup(group);
        });
    }

    private _onMoveUpdate(movement: PveSvrMovementComponent, dt: number) {
        if (movement.state === MovementState.STOP) {
            return;
        }

        const transform = movement.getComponent(PveSvrTransformComponent)!;
        transform.flag &= ~(PveSvrTransformFlag.ROTATION | PveSvrTransformFlag.ROTATION_QUAT);

        if (movement.updateType === MovementUpdateType.FOLLOW_TARGET) {
            this._onMoveUpdateByFollowTarget(movement, transform, dt);
        } else if (movement.trackType === TrackType.CURVE) {
            this._onMoveUpdateByCurve(movement, transform, dt);
        } else if (movement.trackType === TrackType.LINE) {
            this._onMoveUpdateByLine(movement, transform, dt);
        } else {
            this._onMoveUpdateByNormal(movement, transform, dt);
        }

        let rotation: number | undefined;
        let quaternion: Laya.Quaternion | undefined;

        if (transform.flag & PveSvrTransformFlag.ROTATION) {
            rotation = transform.rotation;
        } else if (transform.flag & PveSvrTransformFlag.ROTATION_QUAT) {
            quaternion = transform.quaternion;
        }

        this.context.sender.updateTransform(
            transform.eid,
            transform.position,
            rotation,
            quaternion
        );
    }

    private _adjustMoveDirection(
        movement: PveSvrMovementComponent,
        dx: number,
        dz: number,
        dt: number,
        transPos: Readonly<Laya.Vector3>,
        outOffset: Laya.Vector3
    ): void {
        if (movement.ctrlType === MovementCtrlType.AI) {
            outOffset.x = 0;
            outOffset.z = 0;

            // 存在AI组件的情况下，标记AI为需要寻路
            const ai = movement.getComponent(PveSvrAiComponent);
            if (ai) {
                PveSvrUtils.interuptAITree(ai);
                ai.tree?.env.set(PveDef.HIT_WALL_FLAG, true);
                ai.tree?.env.set(PveDef.FIND_PATH_MOVEING, false);
            }
        } else if (movement.ctrlType === MovementCtrlType.WHEEL) {
            const signDx = Math.sign(dx);
            const signDz = Math.sign(dz);
            // MovementCtrlType.WHEEL时，updateType只能是VELOCITY
            const speed = movement.velocity.length();
            const newDx = signDx * speed * dt;
            const newDz = signDz * speed * dt;
            const isBlockAtX = this.context.isBlockAt(transPos.x + newDx, transPos.z, true);
            const isBlockAtZ = this.context.isBlockAt(transPos.x, transPos.z + newDz, true);
            // 决定朝哪个方向进行滑行
            let isSliceToX = false;
            let isSliceToZ = false;
            if (!isBlockAtX && !isBlockAtZ) {
                // 挑长的距离
                if (Math.abs(dz) > Math.abs(dx)) {
                    isSliceToZ = true;
                } else {
                    isSliceToX = true;
                }
            } else if (isBlockAtX && isBlockAtZ) {
                isSliceToX = false;
                isSliceToZ = false;
            } else if (isBlockAtX) {
                isSliceToZ = true;
            } else if (isBlockAtZ) {
                isSliceToX = true;
            }

            if (isSliceToX) {
                // Z 轴上碰撞，X轴上没碰撞，则往X轴上移动，Z轴不移动
                outOffset.x = newDx;
                outOffset.z = 0;
            } else if (isSliceToZ) {
                // X 轴上碰撞，Z轴上没碰撞，则往Z轴上移动，X轴不移动
                outOffset.x = 0;
                outOffset.z = newDz;
            } else {
                outOffset.x = 0;
                outOffset.z = 0;
            }
        } else {
            throw new Error("unsupported movement type");
        }
    }

    private _onMoveUpdateByNormal(
        movement: PveSvrMovementComponent,
        transform: PveSvrTransformComponent,
        dt: number
    ) {
        const position = transform.position;

        let targetPos: Laya.Vector3 | undefined;
        let hitBlock = false; // 是否撞墙停下来
        let dx = 0;
        let dz = 0;

        // 先执行位置差值计算
        if (movement.updateType === MovementUpdateType.VELOCITY) {
            // 需要先预判目标点是否是阻挡格子,是的话需要停止移动
            dx = movement.velocity.x * dt;
            dz = movement.velocity.z * dt;
        } else if (movement.updateType === MovementUpdateType.TARGET_POSITION) {
            targetPos = movement.targetPosition;
            const totalDis = Laya.Vector3.distanceXZ(position, targetPos);
            const dtDis = movement.speed * dt;
            if (dtDis < totalDis) {
                const steplen = dtDis / totalDis;
                dx = (targetPos.x - position.x) * steplen;
                dz = (targetPos.z - position.z) * steplen;
            } else {
                dx = targetPos.x - position.x;
                dz = targetPos.z - position.z;
                movement.state = MovementState.STOP;
            }
        } else {
            console.error(
                "unsupported movement update type",
                MovementUpdateType[movement.updateType]
            );
            return;
        }

        // 撞墙检测：调整移动方向
        if (movement.shouldCollision) {
            // 判断是否有撞墙
            const isBlockAtXZ = this.context.isBlockAt(position.x + dx, position.z + dz, true);
            if (isBlockAtXZ) {
                hitBlock = true;
                // 调整移动方向
                this._adjustMoveDirection(movement, dx, dz, dt, position, tmpOffset);
            }
        }

        const newDx = hitBlock ? tmpOffset.x : dx;
        const newDz = hitBlock ? tmpOffset.z : dz;
        if (newDx === 0 && newDz === 0) {
            return;
        }
        position.x += newDx;
        position.z += newDz;

        if (transform.flag & PveSvrTransformFlag.CALCU_ROTATION) {
            const rotation = PveMoveUtils.calcuRotation(dx, dz);
            transform.rotation = rotation;
            transform.flag |= PveSvrTransformFlag.ROTATION;
            transform.flag &= ~PveSvrTransformFlag.CALCU_ROTATION;
        }
    }

    private _onMoveUpdateByCurve(
        movement: PveSvrMovementComponent,
        transform: PveSvrTransformComponent,
        dt: number
    ) {
        const track = movement.track;
        if (!track) {
            console.error("track is null", movement.eid);
            return;
        }

        const t = (track.time += dt);
        if (t >= track.duration) {
            transform.position.cloneFrom(track.p2);
            return;
        }

        const ratio = t / track.duration;

        const old = transform.position.clone();

        const p0 = track.p0;
        const p1 = track.p1!;
        const p2 = track.p2;
        const cp = track.cp!;

        BezierHelper.getBezierPoint(p0, cp, p2, ratio, tmpVector3);
        transform.position.cloneFrom(tmpVector3);

        const roQuat = new Laya.Quaternion();
        Laya.Quaternion.forwardLookAt(old, transform.position, Laya.Vector3.Up, roQuat);
        transform.quaternion.set(roQuat.x, roQuat.y, roQuat.z, roQuat.w);
        transform.flag |= PveTransformComponent.ROTATION_QUAT;
    }

    private _onMoveUpdateByLine(
        movement: PveSvrMovementComponent,
        transform: PveSvrTransformComponent,
        dt: number
    ) {
        const track = movement.track;
        if (!track) {
            console.error("track is null", movement.eid);
            return;
        }

        const t = (track.time += dt);
        if (t >= track.duration) {
            transform.position.cloneFrom(track.p2);
            return;
        }

        const ratio = t / track.duration;
        Laya.Vector3.lerp(track.p0, track.p2, ratio, tmpVector3);
        transform.position.cloneFrom(tmpVector3);
    }

    private _onMoveUpdateByFollowTarget(
        movement: PveSvrMovementComponent,
        transform: PveSvrTransformComponent,
        dt: number
    ) {
        const followTarget = movement.followTarget;

        const targetEle = this.context.ecs.getComponent(
            followTarget.targetEid,
            PveSvrElementComponent
        );
        if (!targetEle) {
            followTarget.targetEid = 0;
            movement.state = MovementState.STOP;
            return;
        }

        const targetTransform = targetEle.getComponent(PveSvrTransformComponent);
        if (!targetTransform) {
            movement.state = MovementState.STOP;
            throw new Error("targetTransform is null");
        }

        const targetPos = targetTransform.position;

        const curTime = followTarget.duration * followTarget.ratio;
        if (curTime + dt >= followTarget.duration) {
            transform.position.cloneFrom(targetPos);
            transform.flag |= PveTransformComponent.POSITION;
            followTarget.ratio = 1;
            movement.state = MovementState.STOP;
            return;
        }
        const moveRatio = dt / (followTarget.duration - curTime);
        const dx = (targetPos.x - transform.position.x) * moveRatio;
        const dz = (targetPos.z - transform.position.z) * moveRatio;
        transform.position.x += dx;
        transform.position.z += dz;
        transform.flag |= PveTransformComponent.POSITION;
        followTarget.ratio = Math.min(1, (curTime + dt) / followTarget.duration);
    }

    private _onUpdateTroop(troop: PveSvrTroopComponent) {
        const leaderEle = this.context.ecs.getComponent(troop.leaderEid, PveSvrElementComponent);
        if (!leaderEle) {
            return;
        }
        const troopTransform = troop.getComponent(PveSvrTransformComponent)!;
        troopTransform.position.x = leaderEle.transform.position.x;
        troopTransform.position.z = leaderEle.transform.position.z;
        troopTransform.rotation = leaderEle.transform.rotation;
    }

    private _onUpdateSoldierGroup(group: PveSvrSoldierGroupComponent) {
        const soldierEids = PveSvrSoldierGroupComponent.getSoldierEids(this.context, group);

        const transform = group.getComponent(PveSvrTransformComponent)!;
        if (soldierEids.length === 0) {
            console.error("soldier group has no soldier", group.eid);
            return;
        }

        const posSum = new Laya.Vector3();
        let rotationSum = 0;
        let count = 0;
        soldierEids.forEach((eid) => {
            const soldier = this.context.ecs.getComponent(eid, PveSvrElementComponent)!;
            posSum.x += soldier.transform.position.x;
            posSum.z += soldier.transform.position.z;

            rotationSum += soldier.transform.rotation;
            count++;
        });
        transform.position.set(posSum.x / count, 0, posSum.z / count);
        transform.rotation = rotationSum / count;
    }
}
