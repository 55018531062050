/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/alliance/UIAllianceInvited.lh
 */
export class UIAllianceInvitedBase extends Laya.Dialog {
    public title!: Laya.Label;
    public message!: Laya.TextInput;
}

