const { regClass } = Laya;
import { app } from "../../../../app";
import { StringUtil } from "../../../../core/utils/string-util";
import { MainlineConf } from "../../../../def/auto/mainline";
import { StyleConf } from "../../../../def/style";
import { Reward } from "../../../../def/table";
import { PveDefeatWindowUI } from "./PveDefeatWindowUI";
import { PveRebornWindowUIBase } from "./PveRebornWindowUI.generated";

@regClass('ibtTKeVWS3quzSmm-sBmDA')
export class PveRebornWindowUI extends PveRebornWindowUIBase {
    private defeatWin: PveDefeatWindowUI | undefined;

    private _ADS_COST_ITEM: Reward | undefined;

    private _ADS_COST_MONEY: Reward | undefined;

    public get ADS_COST_ITEM(): Reward {
        if (!this._ADS_COST_ITEM) {
            const consumeRow = app.service.table.define.consume["reborn_const_item"];
            this._ADS_COST_ITEM = consumeRow[0];
        }
        return this._ADS_COST_ITEM;
    }

    public get ADS_COST_MONEY(): Reward {
        if (!this._ADS_COST_MONEY) {
            const consumeRow = app.service.table.define.consume["reborn_const_money"];
            this._ADS_COST_MONEY = consumeRow[0];
        }
        return this._ADS_COST_MONEY;
    }

    override onAwake(): void {
        super.onAwake();
        this.btn_play_ads.on(Laya.Event.CLICK, this, this.onPlayAds);
        this.btn_reborn.on(Laya.Event.CLICK, this, this.onReborn);
        this.cost_text_btn.on(Laya.Event.CLICK, this, this.onTextBtn);

        this.updateShow();
    }

    private _adsPlaying: boolean = false;

    /** 观看广告 */
    private onPlayAds(): void {
        if (!this._adsPlaying) {
            app.ui.toast("观看广告中...");
            this._adsPlaying = true;
            Laya.timer.once(2000, this, this.onPlayAdsComp);
        }
    }

    async onPlayAdsComp() {
        app.ui.toast("观看广告完毕");
        this._adsPlaying = false;
        this.close();

        // 进行复活
        if (this.defeatWin) {
            await app.service.pve.requestReborn(MainlineConf.REBORN_COST.PLAY_ADS);
            this.defeatWin.close();
            this.defeatWin.context.sender.heroReborn(false);
        }
    }

    /** 消耗道具复活 */
    async onReborn() {
        if (this._adsPlaying) {
            app.ui.toast("观看广告中...");
            return;
        }
        // app.ui.toast("使用道具进行复活");
        const curHasAmount = app.service.bag.itemVoMap.getItemAmount(this.ADS_COST_ITEM.id);

        const needAmount = this.ADS_COST_ITEM.num;
        if (curHasAmount < needAmount) {
            const itemLo = app.service.bag.itemLoMap.get(this.ADS_COST_ITEM.id);
            const ubb = StringUtil.str2UBB(
                "消耗不足，需要{0}{1}",
                { image: itemLo.smallIconUrl, width: 30, height: 30 },
                needAmount
            );
            app.ui.toast(ubb);
            return;
        }

        this.close();
        // app.ui.toast("使用广告劵进行复活！");
        if (this.defeatWin) {
            await app.service.pve.requestReborn(MainlineConf.REBORN_COST.COST_ITEM);
            this.defeatWin.close();
            this.defeatWin.context.sender.heroReborn(false);
        }
    }

    /** 点击文本按钮 */
    async onTextBtn() {
        if (this._adsPlaying) {
            app.ui.toast("观看广告中...");
            return;
        }
        const moneyVo = app.service.user.moneyVoMap.get(this.ADS_COST_MONEY.id);
        if (!moneyVo) {
            return;
        }
        const curHasAmount = moneyVo.count;
        const needAmount = this.ADS_COST_MONEY.num;
        if (curHasAmount < needAmount) {
            const itemLo = app.service.bag.itemLoMap.get(this.ADS_COST_MONEY.id);
            const ubb = StringUtil.str2UBB(
                "消耗不足，需要{0}{1}",
                { image: itemLo.smallIconUrl, width: 30, height: 30 },
                needAmount
            );
            app.ui.toast(ubb);
            return;
        }

        // app.ui.toast("使用钻石进行复活");
        this.close();
        // app.ui.toast("使用广告劵进行复活！");
        if (this.defeatWin) {
            await app.service.pve.requestReborn(MainlineConf.REBORN_COST.COST_MONEY);
            this.defeatWin.close();
            this.defeatWin.context.sender.heroReborn(false);
        }
    }

    private updateShow(): void {
        const itemLo = app.service.bag.itemLoMap.get(this.ADS_COST_ITEM.id);
        this.img_cost_icon.skin = itemLo.iconUrl;

        const curHasAmount = app.service.bag.itemVoMap.getItemAmount(this.ADS_COST_ITEM.id);
        const needAmount = this.ADS_COST_ITEM.num;
        let color = StyleConf.COST_AMOUNT_COLOR.ENOUGH;
        if (curHasAmount < needAmount) {
            color = StyleConf.COST_AMOUNT_COLOR.NOT_ENOUGH;
        }
        const str = StringUtil.format(
            "([color={0}]{1}[/color]/{2})",
            color,
            curHasAmount,
            needAmount
        );
        this.cost_text_1.text = str;

        const moneyName = app.service.table.item.item[this.ADS_COST_MONEY.id].name;
        let costMoneyStr = "消耗 {0} {1}复活";
        costMoneyStr = StringUtil.format(costMoneyStr, this.ADS_COST_MONEY.num, moneyName);
        this.cost_text_btn.text = costMoneyStr;
    }

    override onOpened(param: PveDefeatWindowUI): void {
        this.defeatWin = param;
    }
}
