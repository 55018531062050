const { regClass } = Laya;
import { HeroStarRow } from "../../../../def/table";
import { ImageRef } from "../../../../misc/ImageRef";
import { CharacterUpStarPreviewItemBase } from "./CharacterUpStarPreviewItem.generated";

export interface CharacterUpStarPreviewItemArgs {
    config: HeroStarRow;
    bReached: boolean; //星级是否已达到
}

@regClass('8cHDxHttSl2LjQHz33aZqg')
export class CharacterUpStarPreviewItem extends CharacterUpStarPreviewItemBase {
    onRender(args: CharacterUpStarPreviewItemArgs, index: number) {
        this.imgBg.visible = index % 2 === 0;

        const dispStar = args.config.star > 5 ? args.config.star - 5 : args.config.star;

        const stars = [this.imgStar0, this.imgStar1, this.imgStar2, this.imgStar3, this.imgStar4];
        stars.forEach((star, i) => {
            star.visible = i < dispStar;
            const textures = star.getComponent(ImageRef)!.texturePaths;
            star.skin = args.config.star > 5 ? textures[1] : textures[0];
            star.gray = !args.bReached;
        });

        this.lblStarDesc.text = `${args.config.entry_desc}`;
        this.lblStarDesc.color = args.bReached ? "#02AC16" : "#3D4C60";
    }
}
