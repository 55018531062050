import { TreeRunner } from "../../../../../../core/behavior3";
import { BattleBuffRow } from "../../../../../../def/table";
import { PveServer } from "../../../PveServer";
import { AiTreeEnv } from "../../components/PveSvrAiComponent";
import { PveSvrElementComponent } from "../../components/PveSvrElementComponent";
import { Skill } from "../../components/PveSvrSkillLauncherComponent";

export class BuffTreeEnv extends AiTreeEnv {
    readonly skill: Skill;
    readonly buffTree: BuffTree;

    /**
     * 记录创建出来的buff，最后在buffTree被移除的时候，尝试清理掉这些buff
     */
    readonly createdBuff: BuffBaseData[] = [];

    constructor(
        context: PveServer,
        owner: PveSvrElementComponent,
        skill: Skill,
        buffTree: BuffTree
    ) {
        super(context, owner);
        this.owner = owner;
        this.skill = skill;
        this.buffTree = buffTree;
    }
}

export enum BuffTreeState {
    TRY_LOAD,
    LOADING,
    RUNNING,
}

export class BuffTree {
    private static __ID_IDX = 1;

    readonly id: number = 0;

    readonly data: BattleBuffRow;
    readonly btree: string = "";
    tree?: TreeRunner<BuffTreeEnv>;

    state: BuffTreeState = BuffTreeState.TRY_LOAD;

    readonly skill: Skill;

    readonly overdueTime: number = 0; //小于0表示永久
    readonly buffArgs: Readonly<number[]>;

    constructor(data: BattleBuffRow, skill: Skill, overdueTime: number, buffArgs: number[] = []) {
        this.id = BuffTree.__ID_IDX++;
        this.data = data;
        this.skill = skill;
        this.overdueTime = overdueTime;
        this.buffArgs = buffArgs;
        if (!data.btree) {
            console.error(`BuffTree: buffId ${data.id} btree not found`);
        }
        this.btree = `resources/data/btree/${data.btree}.json`;
    }
}

export class BuffBaseData {
    overdueTime: number = -1;

    constructor(overdueTime: number) {
        this.overdueTime = overdueTime;
    }
}
