import { RewardProb } from "../../../def/table";

export const calcVelocity = (
    p0: Laya.Vector3,
    p1: Laya.Vector3,
    speed: number,
    out: Laya.Vector3
) => {
    p1.vsub(p0, out);
    out.normalize();
    out.scale(speed, out);
    return out;
};

/**
 * 根据权重抽奖
 * 返回null表示没抽中
 */
export const lotteryWithRewardProbs = (rewardProbs: RewardProb[]): RewardProb | null => {
    let totalWeight: number = 0; // 总权重
    for (let i = 0; i < rewardProbs.length; i++) {
        totalWeight = totalWeight + rewardProbs[i].prob;
    }
    const rand = Math.random();
    let start: number = 0;
    let hit: RewardProb | null = null;
    for (let j = 0; j < rewardProbs.length; j++) {
        // 概率
        const probability = rewardProbs[j].prob / totalWeight;
        start = start + probability;
        if (rand < start) {
            hit = rewardProbs[j];
            break;
        }
    }
    if (hit) {
        return hit;
    }
    return null;
};
