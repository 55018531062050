import { app } from "../../../app";
import proto from "../../../def/auto/proto";
import { Vo } from "../../../misc/vo/Vo";
import { TableUtil } from "../../../system/table/TableUtil";

/**
 * troop
 * 编队列表数据
 */

export class TroopTeamVo extends Vo<never, proto.troop.Troop> {
    protected declare _data: Readonly<proto.troop.Troop>;

    constructor(data: proto.troop.Troop) {
        super(undefined!, data);
    }

    override clone(): TroopTeamVo {
        return new TroopTeamVo(this._data);
    }

    override get key(): number {
        return this._data.idx;
    }

    public get heroHp(): number {
        const herohp = this._data.hps[0];
        return herohp;
    }

    public get idx(): number {
        return this._data.idx;
    }

    public get eid(): number {
        return this._data.eid;
    }

    public get heroMaxHp() {
        const heromaxhp = this._data.heros[0].maxHp ?? 0;
        return heromaxhp;
    }

    public get hp(): number[] {
        return this._data.hps;
    }

    public get teamName(): string {
        return this._data.idx.toString();
    }

    public get leaderskinid(): number {
        return this._data.leaderSkinId;
    }

    public get power(): number {
        return 0;
    }

    public get isUnlock(): boolean {
        const teamidx = this._data.idx;
        const tabTroop = TableUtil.getRow(app.service.table.troop.troop_base, { id: teamidx });
        const heroLevel = app.service.user.profileInfo.lv;
        return heroLevel >= tabTroop!.require_role_lv;
    }

    public get unlockCond(): string {
        const teamidx = this._data.idx;
        const tabTroop = TableUtil.getRow(app.service.table.troop.troop_base, { id: teamidx });
        const level = tabTroop?.require_role_lv || 0;
        return `兵王等级${level}级开启`;
    }

    public getSoliderImg(index: number): string {
        let imgSrc = "";
        const soliderData = this.getSoliderDataByIdx(index + 1);
        if (soliderData && soliderData.uid) {
            imgSrc = "resources/atlas/character/img_hero_002.png";
        }
        return imgSrc;
    }

    public get heroIcon(): string {
        const heroUid = this._data.heros[0].uid!;
        const heroData = app.service.hero.heroVoMap.get(heroUid);
        if (this.leaderskinid) {
            const skinData = app.service.leaderSkin.leaderSkinLoMap.get(this.leaderskinid);
            const res = skinData.getHeroRes().fullRes;
            return res;
        } else {
            if (heroData) {
                return heroData.iconFull || "";
            }
        }

        return "";
    }

    public get heros() {
        return this._data.heros;
    }

    public getSoliderDataByIdx(index: number) {
        return this._data.heros[index];
    }

    public getSoliderHpByIdx(index: number): number {
        let hp = 0;
        if (this._data.hps[index]) {
            hp = this._data.hps[index];
        }
        return hp;
    }

    public getSoliderMaxHpByIdx(index: number): number {
        let maxHp = 0;
        if (this._data.heros[index]) {
            maxHp = this._data.heros[index].maxHp ?? 0;
        }
        return maxHp;
    }

    public getSoldierIdByPos(pos: number) {
        const soliderUid = this._data.heros[pos].uid || 0;
        if (soliderUid) {
            const soliderBagData = app.service.troop.getHeroDataByuid(soliderUid);
            return soliderBagData.id;
        }
        return 0;
    }
}
