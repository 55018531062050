/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/battle/pve-ui/build/bd_upgrade_info.lh
 */
export class BDUpgradeInfoUIBase extends Laya.Box {
    public clock!: Laya.Image;
}

