import { app } from "../../../app";
import proto from "../../../def/auto/proto";
import { BattleSkillRow, Reward } from "../../../def/table";
import { Vo } from "../../../misc/vo/Vo";

/**
 * troop
 * 技能数据
 */
export class SkillVo extends Vo<BattleSkillRow, proto.hero.SkillInfo> {
    protected declare _data: Readonly<proto.hero.SkillInfo>;

    constructor(config: BattleSkillRow, data: proto.hero.SkillInfo) {
        super(config, data);
    }

    override clone(): SkillVo {
        return new SkillVo(this._config, this._data);
    }

    override get key(): number {
        return this._data.id;
    }

    public get desc(): string {
        return this._config.desc;
    }

    public get name(): string {
        return this._config.name;
    }

    public get level(): number {
        return this._config.lv;
    }

    public get skillLevel(): number {
        return this._data.lv;
    }

    //前端技能显示类型
    public get typeskill(): number {
        return this._config.type_icon || 1;
    }

    public get activeItem(): Reward {
        return { id: 0, num: 0 };
    }

    public get isCanAct(): boolean {
        if (!app.service.skill.checkIsActed(this.skillId)) {
            const reward = this._config.active_cost;
            if (reward) {
                const rewardId = reward[0].id;
                const costNum = reward[0].num;
                if (app.service.user.getMoneyCount(rewardId) >= costNum) {
                    return true;
                }
            }
        }
        return false;
    }

    //是否已经激活
    public get isActed(): boolean {
        const isActed = app.service.skill.checkIsActed(this.skillId);
        return isActed;
    }

    //技能是否已经分配，拥有队伍
    public get heroIdList(): { uid: number; sysType: number }[] {
        const heroUidList = app.service.skill.getHeroUidBySkill(this.skillId);
        return heroUidList;
    }

    public get skillId(): number {
        return this._data.id;
    }

    public get quality(): number {
        return this._config.quality ?? 0;
    }

    public get isMax(): boolean {
        return this.level === this._config.args.length;
    }

    public get activecost(): Reward {
        return this._config.active_cost[0];
    }

    public get upgradecost(): Reward {
        return this._config.upgrade_cost[this.level - 1][0];
    }
}
