import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { UIDrawCardBuy } from "./ui-runtime/UIDrawCardBuy";

const { regClass, property } = Laya;

@regClass('sE_osUbyRKyxHRelWSqkyA')
export class UIDrawCardBuyMediator extends Mediator {
    declare owner: UIDrawCardBuy;

    @property(String)
    public text: string = "";

    //组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
    override onAwake(): void {
        super.onAwake();
        this.initPanel();
        this.owner.btnBuy.on(Laya.Event.CLICK, this, this.onClickBuy);
        this.owner.boxCheck.on(Laya.Event.CLICK, this, this.onClickCheck);
        this.owner.imgMask.on(Laya.Event.CLICK, this.owner, this.owner.destroy);
    }

    private onClickCheck() {
        this.owner.imgGou.visible = !this.owner.imgGou.visible;
        const dayisNoTipsKey = this.owner.args.dayisNoTipsKey;
        if (dayisNoTipsKey) {
            if (this.owner.imgGou.visible) {
                const servertime = app.service.network.serverTime;
                Laya.LocalStorage.setItem(dayisNoTipsKey, servertime.toString());
            } else {
                Laya.LocalStorage.setItem(dayisNoTipsKey, "");
            }
        }
    }

    private initPanel(): void {
        this.owner.bgwin.setTitle("购买确认");
        const args = this.owner.args;
        this.owner.labDesc.text = args.contentStr;
        if (args.contentVars) {
            for (const key in args.contentVars) {
                this.owner.labDesc.setVar(key, args.contentVars[key]);
            }
        }

        const ownCount = app.service.bag.itemVoMap.getItemAmount(args.costId);
        const costLo = app.service.bag.itemLoMap.get(args.costId)!;
        this.owner.imgCost.skin = costLo.iconUrl;
        this.owner.labCount.text = `${ownCount}`;
    }

    private onClickBuy() {
        if (this.owner.args.buyCallBack) {
            this.owner.args.buyCallBack();
            this.owner.destroy();
        }
    }

    //组件被启用后执行，例如节点被添加到舞台后
    //onEnable(): void {}

    //组件被禁用时执行，例如从节点从舞台移除后
    //onDisable(): void {}

    //第一次执行update之前执行，只会执行一次
    //onStart(): void {}

    //手动调用节点销毁时执行
    //onDestroy(): void {}

    //每帧更新时执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onUpdate(): void {}

    //每帧更新时执行，在update之后执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onLateUpdate(): void {}

    //鼠标点击后执行。与交互相关的还有onMouseDown等十多个函数，具体请参阅文档。
    //onMouseClick(): void {}
}
