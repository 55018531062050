/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

/**
 * resources/prefab/mail/mail.lh
 */
export class MailUIBase extends Laya.Dialog {
    Image!: Laya.Image;
    tabMenu!: Laya.Tab;
    listMail!: Laya.List;
    btnClose!: Laya.Button;
    btnReward!: Laya.Button;
    btnDelete!: Laya.Button;
}
