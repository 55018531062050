/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { HomeNaviMenuItem } from "./HomeNaviMenuItem";

/**
 * resources/prefab/home/HomeNaviMenu.lh
 */
export class HomeNaviMenuBase extends Laya.Box {
    public btnFight!: HomeNaviMenuItem;
    public btnRole!: HomeNaviMenuItem;
    public btnMain!: HomeNaviMenuItem;
    public btnChest!: HomeNaviMenuItem;
    public btnAlliance!: HomeNaviMenuItem;
}

