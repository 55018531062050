const { regClass } = Laya;
import { HeroVo } from "../../hero/vo/HeroVo";
import { UIDrawCardGainHeroBase } from "./UIDrawCardGainHero.generated";

@regClass('JAc-rS2QSBWUZfqFEsqoow')
export class UIDrawCardGainHero extends UIDrawCardGainHeroBase {
    public herovo!: HeroVo;

    override open(closeOther?: boolean, param?: HeroVo): void {
        this.zOrder = 100;
        this.herovo = param as HeroVo;
        super.open(closeOther, param);
    }
}
