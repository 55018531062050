import { LabelStyle } from "./LabelStyle";

const { regClass, property } = Laya;

@Laya.regClass('cpyW0WayS32sVbFHVdDyPA')
export class ButtonBase extends Laya.Button {
    private _normalLabelStyle: LabelStyle = new LabelStyle();
    private _selectedLabelStyle: LabelStyle = new LabelStyle();

    constructor() {
        super();
        this._labelColors = [];
        this._stateNum = 1;
    }

    private _makeLabelColors(color: string) {
        return [color, color, color].join(",");
    }

    @Laya.property({ type: LabelStyle, inspector: "label-style", caption: "正常样式" })
    get normalLabelStyle(): LabelStyle {
        return this._normalLabelStyle;
    }

    set normalLabelStyle(value: LabelStyle) {
        if (value) {
            this._normalLabelStyle = value;
            if (!this.selected) {
                this._updateLabelStyle(this._normalLabelStyle);
            }
        }
    }

    @Laya.property({ type: LabelStyle, inspector: "label-style", caption: "选中样式" })
    get selectedLabelStyle(): LabelStyle {
        return this._selectedLabelStyle;
    }

    set selectedLabelStyle(value: LabelStyle) {
        if (value) {
            this._selectedLabelStyle = value;
            if (this.selected) {
                this._updateLabelStyle(this._selectedLabelStyle);
            }
        }
    }

    private _updateLabelStyle(style: LabelStyle) {
        if (typeof style.color === "string") {
            this.labelColors = this._makeLabelColors(style.color);
        }

        if (typeof style.size === "number") {
            this.labelSize = style.size;
        }

        if (typeof style.stroke === "number") {
            this.labelStroke = style.stroke;
        }

        if (typeof style.padding === "string") {
            this.labelPadding = style.padding;
        }

        if (typeof style.skin === "string") {
            this.skin = style.skin;
        }

        if (typeof style.strokeColor === "string") {
            this.labelStrokeColor = style.strokeColor;
        }
    }

    override get selected(): boolean {
        return super.selected;
    }

    override set selected(value: boolean) {
        super.selected = value;
        if (value) {
            this._updateLabelStyle(this._selectedLabelStyle);
        } else {
            this._updateLabelStyle(this._normalLabelStyle);
        }
    }

    @Laya.property({ visible: false, serializable: false })
    override get skin(): string {
        return super.skin;
    }

    override set skin(value: string) {
        super.skin = value;
    }

    protected override _skinLoaded(tex: Laya.Texture | null): void {
        if (tex) {
            const url = Laya.URL.formatURL(this._skin, this._skinBaseUrl);
            if (!url.endsWith(tex.url)) {
                return;
            }
        }
        super._skinLoaded(tex);
    }

    @Laya.property({ type: Number, serializable: false, inspector: null })
    override get labelSize(): number {
        return super.labelSize;
    }

    override set labelSize(value: number) {
        super.labelSize = value;
    }

    @Laya.property({ type: String, serializable: false, inspector: null })
    override get labelPadding(): string {
        return super.labelPadding;
    }

    override set labelPadding(value: string) {
        super.labelPadding = value;
    }

    @Laya.property({ visible: true, serializable: false })
    override get labelColors(): string {
        return super.labelColors;
    }

    override set labelColors(value: string) {
        super.labelColors = value;
    }

    @Laya.property({ visible: true, serializable: false })
    override get labelStroke(): number {
        return super.labelStroke;
    }

    override set labelStroke(value: number) {
        super.labelStroke = value;
    }

    @Laya.property({ visible: true, serializable: false })
    override get labelStrokeColor(): string {
        return super.labelStrokeColor;
    }

    override set labelStrokeColor(value: string) {
        super.labelStrokeColor = value;
    }

    @Laya.property({ visible: false, serializable: false })
    override get stateNum(): number {
        return 1;
    }

    override set stateNum(value: number) {}
}
