import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { ImageRef } from "../../misc/ImageRef";
import { SystemEvent } from "../../misc/system-event";
import { ui } from "../../misc/ui";
import { AttrBase } from "../common/ui-runtime/attribute/AttrBase";
import { LeaderSkinItem } from "./ui-runtime/LeaderSkinItem";
import { UILeaderSkin } from "./ui-runtime/UILeaderSkin";
import { LeaderSkinLo } from "./vo/LeaderSkinLo";

const { regClass, property } = Laya;

@regClass('na6CvNoQSY-yTergRufTiQ')
export class UILeaderSkinMediator extends Mediator {
    declare owner: UILeaderSkin;

    //组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
    override onAwake(): void {
        super.onAwake();
        this.initUIEvent();
        this.initServiceEvent();
        this.updateList();
    }

    private initUIEvent() {
        this.owner.bgwin.setTitle("统帅幻化");
        this.owner.btnClose.on(Laya.Event.CLICK, this.owner, this.owner.destroy);
        this.owner.btnupgrade.on(Laya.Event.CLICK, this, this.onClickUpgrade);
        this.owner.btnActive.on(Laya.Event.CLICK, this, this.onClickActive);
        this.owner.btnSkin.on(Laya.Event.CLICK, this, this.onCickSkin);
        this.owner.btnOff.on(Laya.Event.CLICK, this, this.onClickOff);

        this.owner.listHero.renderHandler = new Laya.Handler(this, this.onListRender);
        this.owner.listHero.selectHandler = new Laya.Handler(this, this.onListSelRender);

        this.owner.listAtt.renderHandler = new Laya.Handler(this, this.onListAttRender);
    }

    private initServiceEvent() {
        this.$(app).on(SystemEvent.LEADERSKIN.LEADERSKINUPDATE, this.updateList, this);
        this.$(app).on(SystemEvent.UI.TROOP_INSTALL_SKIN, this.updateList, this);
    }

    private onListRender(item: LeaderSkinItem, index: number) {
        const loData = item.dataSource as LeaderSkinLo;
        const lv = app.service.leaderSkin.getSkinLevelById(loData.id);
        const skinTeamId = app.service.troop.getTeamIdBySkinId(loData.id);
        item.lablevel.text = `${lv}级`;
        const skinData = app.service.leaderSkin.getSkinDataById(loData.id);
        item.imgSkin.visible = skinData !== undefined;
        const texturePaths = item.imgBg.getComponent(ImageRef)!.texturePaths;
        item.imgBg.skin = texturePaths[loData.quality];
        const midRes = loData.getHeroRes().midRes;
        item.imgIcon.skin = midRes;
        item.boxGray.gray = skinData === undefined && !loData.checkIsCanActive();
        item.boxTroop.visible = skinTeamId !== 0;
        if (skinTeamId) {
            item.labTeam.text = `队伍${skinTeamId}`; //暂时只显示pvp队伍todo
        }
        const count = loData.getUpgradeCount(lv);
        const costId = loData.skinCostItemId;
        const owerCount = app.service.bag.itemVoMap.getItemAmount(costId);
        item.imgRedpoint.visible = owerCount >= count && count !== 0;
        if (count === 0) {
            //满级
            item.imgBar.value = 1;
            item.labBar.text = `已满级`;
        } else {
            item.imgBar.value = owerCount / count;
            item.labBar.text = `${owerCount}/${count}`;
        }
        if (!this.curSelItem && index === 0) {
            this, (this.curSelItem = item);
        }
        item.on(Laya.Event.CLICK, this, this.onClickItem);
    }

    private curSelItem: LeaderSkinItem | undefined;

    private onClickItem(e: Laya.Event) {
        const target = e.currentTarget as LeaderSkinItem;
        if (this.curSelItem === target) {
            const loData = target.dataSource as LeaderSkinLo;
            const skinData = app.service.leaderSkin.getSkinDataById(loData.id);
            const index = this.owner.listHero.selectedIndex;
            const selLoData = this.owner.listHero.array[index] as LeaderSkinLo;
            if (skinData !== undefined && selLoData.id === loData.id) {
                const isMax = loData.checkIsMax();
                if (isMax) {
                    app.ui.toast("已达到最高级");
                } else {
                    this.onClickUpgrade();
                }
            }
        } else {
            this.curSelItem = target;
        }
    }

    private onListSelRender(index: number) {
        this.updateSelInfo();
    }

    private onListAttRender(item: AttrBase, index: number) {
        const attrData = item.dataSource as { arrtName: string; AttrValue: number };
        item.onRender(attrData, index);
    }

    private updateSelInfo() {
        const index = this.owner.listHero.selectedIndex;
        const loData = this.owner.listHero.array[index] as LeaderSkinLo;
        const teamId = app.service.troop.getTeamIdBySkinId(loData.id);
        this.owner.labName.text = loData.name;
        const lv = app.service.leaderSkin.getSkinLevelById(loData.id);
        this.owner.labLevel.text = `等级:${lv}`;
        const fullRes = loData.getHeroRes().fullRes;
        this.owner.imgHero.skin = fullRes;

        const isMax = loData.checkIsMax();
        const skinData = app.service.leaderSkin.getSkinDataById(loData.id);
        this.owner.btnupgrade.visible = skinData !== undefined && !isMax;
        this.owner.btnSkin.visible = skinData !== undefined && teamId === 0;
        this.owner.btnOff.visible = teamId > 0;
        this.owner.labMax.visible = isMax;

        this.owner.btnActive.visible = skinData === undefined && loData.checkIsCanActive();
        this.owner.labNoTips.visible = skinData === undefined && !loData.checkIsCanActive();
        this.refreshBoxSkill(loData);
        this.updateAttr(loData);
    }

    private updateAttr(loData: LeaderSkinLo) {
        const arrtList = loData.getAttList();
        this.owner.listAtt.array = arrtList;
    }

    private onClickUpgrade() {
        const index = this.owner.listHero.selectedIndex;
        const loData = this.owner.listHero.array[index] as LeaderSkinLo;
        app.ui.show(ui.LEADER_SKIN_UPGRADE, loData.id);
    }

    private onClickActive() {
        const index = this.owner.listHero.selectedIndex;
        const loData = this.owner.listHero.array[index] as LeaderSkinLo;
        app.service.leaderSkin.requestUpgradeLv(loData.id);
    }

    private onCickSkin() {
        const index = this.owner.listHero.selectedIndex;
        const loData = this.owner.listHero.array[index] as LeaderSkinLo;
        const qualityCfg = app.service.table.leaderskin.quality.find(
            (v) => v.id === loData.quality
        );
        if (!qualityCfg) {
            return;
        }
        const needHeroStar = qualityCfg.require_hero_star;
        const heroData = app.service.hero.heroVoMap.get(this.owner.heroUid);
        if (heroData && heroData?.star >= needHeroStar) {
            app.ui.show(ui.LEADER_SKIN_TEAM_SELECT, loData);
        } else {
            app.ui.toast(`统帅需要先提升到${needHeroStar}星`);
        }
    }

    private onClickOff() {
        const index = this.owner.listHero.selectedIndex;
        const loData = this.owner.listHero.array[index] as LeaderSkinLo;
        const teamId = app.service.troop.getTeamIdBySkinId(loData.id);
        app.service.troop.requestTroopLeaderSkin(teamId, 0);
    }

    private updateList() {
        const skinList = app.service.leaderSkin.getSkinListSort();
        let selectedIndex = this.owner.listHero.selectedIndex || 0;
        if (selectedIndex) {
            const oldSelData = this.owner.listHero.array[selectedIndex] as LeaderSkinLo;
            const index = skinList.findIndex((v) => v.id === oldSelData.id);
            selectedIndex = index;
        }
        this.owner.listHero.array = skinList;
        this.owner.listHero.selectedIndex = selectedIndex;
        this.updateSelInfo();
    }

    private refreshBoxSkill(loData: LeaderSkinLo) {
        const skillBoxList = [
            this.owner.skillItem1,
            this.owner.skillItem2,
            this.owner.skillItem3,
            this.owner.skillItem4,
        ];

        const heroUid = this.owner.heroUid;
        const heroData = app.service.hero.heroVoMap.get(heroUid);
        const heroStar = heroData?.star || 0;

        for (let i = 0; i < skillBoxList.length; i++) {
            const boxSkil = skillBoxList[i];
            const unLockStar = app.service.table.battleSkill.skill_slot_unlock[i].require_star;
            const isUnlock = heroStar >= unLockStar;
            boxSkil.onRender(loData, isUnlock, i + 1);
        }
    }

    //组件被启用后执行，例如节点被添加到舞台后
    //onEnable(): void {}

    //组件被禁用时执行，例如从节点从舞台移除后
    //onDisable(): void {}

    //第一次执行update之前执行，只会执行一次
    //onStart(): void {}

    //手动调用节点销毁时执行
    //onDestroy(): void {}

    //每帧更新时执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onUpdate(): void {}

    //每帧更新时执行，在update之后执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onLateUpdate(): void {}

    //鼠标点击后执行。与交互相关的还有onMouseDown等十多个函数，具体请参阅文档。
    //onMouseClick(): void {}
}
