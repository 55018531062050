import { app } from "../../app";
import { Service } from "../../core/service";
import proto from "../../def/auto/proto";
import { errcode, opcode } from "../../def/auto/protocol";
import { SystemEvent } from "../../misc/system-event";
import { ui } from "../../misc/ui";
import { ChestBoxVoMap } from "../chest-box/ChestBoxVoMap";
import { ChestScoreVoMap } from "../chest-box/ChestScoreVoMap";
import { ChestBoxVo } from "../chest-box/vo/ChestBoxVo";
import { ChestScoreVo } from "../chest-box/vo/ChestScoreVo";
import { HeroLo } from "../hero/vo/HeroLo";

export class ChestService extends Service {
    static readonly CHEST_UPDATE = "chest-update";
    static readonly CHEST_SCORE_UPDATE = "chest-score-update";

    readonly chestBoxVoMap = new ChestBoxVoMap();
    readonly chestScoreVoMap = new ChestScoreVoMap();

    private _currentChooseChestBox: ChestBoxVo | undefined;

    public get currentChooseChestBox(): ChestBoxVo | undefined {
        return this._currentChooseChestBox;
    }

    public set currentChooseChestBox(value: ChestBoxVo | undefined) {
        this._currentChooseChestBox = value;
    }

    public get curChestScoreVo(): ChestScoreVo {
        const arr = this.chestScoreVoMap.toArray();
        const first = arr.find((v) => v.isCurrent);
        return first!;
    }

    scoreInfo!: proto.chest.ScoreInfo; //积分数据
    heroId!: number;
    heroIds: number[] = [];

    public get curHeroLo(): HeroLo {
        const lo = app.service.hero.heroLoMap.get(this.heroId)!;
        return lo;
    }

    override onCreate() {
        this.handle(opcode.chest.s2c_load, this._onLoad);
        this.handle(opcode.chest.s2c_open_chest, this._onOpenChest);
        this.handle(opcode.chest.s2c_score_receive, this._onScoreReceive);
        this.handle(opcode.chest.s2c_switch_hero, this._onSwitchHero);
        this.handle(opcode.chest.notify_chest, this._noNotify_chest);
        this.handle(opcode.chest.notify_chest_hero, this._noNotify_Hero);
    }

    override onStartInit(): void {
        this.chestBoxVoMap.initData();
        this.chestScoreVoMap.initData();
    }

    override onDestroy() {}

    private _onLoad(data: proto.chest.s2c_load) {
        if (data.err === errcode.OK) {
            if (data.score) {
                this.scoreInfo = data.score as proto.chest.ScoreInfo;
            } else {
                console.error("宝箱积分数据出现异常");
            }
            this.heroId = data.heroId;
            this.heroIds = data.heroIds;
        }
    }

    private _onOpenChest(data: proto.chest.s2c_open_chest) {
        this.scoreInfo = data.score as proto.chest.ScoreInfo;
        app.ui.show(ui.REWARD, data.rewards);
    }

    private _onScoreReceive(data: proto.chest.s2c_score_receive) {
        this.scoreInfo = data.score as proto.chest.ScoreInfo;
        // app.ui.show(ui.REWARD, data.rewards);
    }

    private _onSwitchHero(data: proto.chest.s2c_switch_hero, c2s: proto.chest.c2s_switch_hero) {
        this.heroId = c2s.heroId;
    }

    private _noNotify_chest(data: proto.chest.notify_chest) {
        if (data.score) {
            this.scoreInfo = data.score;
        }
        app.event(ChestService.CHEST_UPDATE);
    }

    private _noNotify_Hero(data: proto.chest.notify_chest_hero) {
        for (const id of data.heroIds) {
            if (this.heroIds.indexOf(id) === -1) {
                this.heroIds.push(id);
            }
        }
        app.event(SystemEvent.CHEST_BOX.UNLOCK_HERO_CHANGED);
    }

    public initChooseChestBox(): void {
        if (!this.currentChooseChestBox) {
            const firstChest = this.chestBoxVoMap.toArray().find((v) => v.amount > 0);
            if (firstChest) {
                this.currentChooseChestBox = firstChest;
            }
        }
    }

    // ------------------------------------------------------------------------
    // rpc call
    // ------------------------------------------------------------------------
    async load() {
        return await app.service.network.call(proto.chest.c2s_load.create(), proto.chest.s2c_load);
    }

    /**
     *请求打开宝箱
     * @param data
     */
    async requestOpenChest(chestId: number) {
        return await app.service.network.call(
            proto.chest.c2s_open_chest.create({ id: chestId }),
            proto.chest.s2c_open_chest
        );
    }

    /**
     *请求领取积分
     * @param data
     */
    async requestScoreReceive() {
        return await app.service.network.call(
            proto.chest.c2s_score_receive.create(),
            proto.chest.s2c_score_receive
        );
    }

    /**
     *请求切换武将
     * @param data
     */
    async requestSwitchHero(heroId: number) {
        return await app.service.network.call(
            proto.chest.c2s_switch_hero.create({ heroId }),
            proto.chest.s2c_switch_hero
        );
    }
}
