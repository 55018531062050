import { Constructor } from "../../../../../core/dispatcher";
import * as ecs from "../../../../../core/ecs";
import { BuffAttrbuteData } from "../data/buff/BuffAttributeData";
import { BuffBaseData, BuffTree } from "../data/buff/BuffBase";
import { BuffFunctionalData, BuffFunctionalType } from "../data/buff/functional/BuffFunctionalData";
import { BuffSpecialData, BuffSpecialType } from "../data/buff/special/BuffSpecialData";
import { PveSvrElementComponent } from "./PveSvrElementComponent";

// buff组件，用于buff的添加和移除。不与CreatureComponent合并的原因是，生物体肯定有buff组件，但非生物体也可能有buff组件
export class PveSvrBuffComponent extends ecs.Component {
    override get dependencies() {
        return [PveSvrElementComponent];
    }

    readonly buffs: Map<Constructor<BuffBaseData>, BuffBaseData[]> = new Map();

    readonly buffAttrDatas: { [attrId: number]: { [buffId: number]: BuffAttrbuteData[] } } = {};
    readonly buffAttrPercentDatas: { [attrId: number]: { [buffId: number]: BuffAttrbuteData[] } } =
        {};

    readonly buffFunctionalDatas: Map<
        BuffFunctionalType,
        Map<number /** buffId */, BuffFunctionalData[]>
    > = new Map();

    readonly buffSpecialDatas: Map<BuffSpecialType, Map<number /** buffId */, BuffSpecialData[]>> =
        new Map();

    readonly buffTrees: BuffTree[] = []; // tree 三个状态

    get eleComp() {
        return this.getComponent(PveSvrElementComponent)!;
    }

    addFunctionalBuff(buffId: number, buff: BuffFunctionalData) {
        const type = buff.type;
        let buffMap = this.buffFunctionalDatas.get(type);
        if (!buffMap) {
            buffMap = new Map();
            this.buffFunctionalDatas.set(type, buffMap);
        }
        let buffArr = buffMap.get(buffId);
        if (!buffArr) {
            buffArr = [];
            buffMap.set(buffId, buffArr);
        }
        buffArr.push(buff);
    }

    addSpecialBuff(buffId: number, buff: BuffSpecialData) {
        // console.warn("addSpecialBuff", buffId, buff);
        const type = buff.type;
        let buffMap = this.buffSpecialDatas.get(type);
        if (!buffMap) {
            buffMap = new Map();
            this.buffSpecialDatas.set(type, buffMap);
        }
        let buffArr = buffMap.get(buffId);
        if (!buffArr) {
            buffArr = [];
            buffMap.set(buffId, buffArr);
        }
        buffArr.push(buff);
    }
}
