const { regClass } = Laya;
import { ItemVo } from "../vo/ItemVo";
import { UIItemGetWayBase } from "./UIItemGetWay.generated";

@regClass('ff_4ik2MTzii2beb4PMYOw')
export class UIItemGetWay extends UIItemGetWayBase {
    public itemData!: ItemVo;

    override open(closeOther?: boolean | undefined, param?: ItemVo): void {
        this.itemData = param!;
        super.open(closeOther, param);
    }
}
