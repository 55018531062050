import { app } from "../../app";
import { Service } from "../../core/service";
import { StringUtil } from "../../core/utils/string-util";
import proto from "../../def/auto/proto";
import { errcode, opcode } from "../../def/auto/protocol";
import { SkillType, SlotSysType } from "../../def/auto/skill";
import { BattleSkillRow, LeaderSkinRow } from "../../def/table";
import { res } from "../../misc/res";
import { SystemEvent } from "../../misc/system-event";
import { TableUtil } from "../table/TableUtil";
import { SkillVo } from "./vo/SkillVo";
import { SkillVoMap } from "./vo/SkillVoMap";

export enum SkillQuality {
    Blue = 1, //C
    Purple = 2, //B
    Orange = 3, //A
    Red = 4, //S
}

export enum USkillType { //改一下名字，和Skill.ts的SkillType区分开
    Ability = 1, //主动
    Passive = 2, //被动
    AssAult = 3, //突击
    Cmmmand = 4, //指挥
    Internal_Affairs = 5, //内政
}
export const SkillTypeText: { [key: number]: { str: string; textColor: string } } = {
    [USkillType.Ability]: { str: "主动", textColor: "#A57B20" },
    [USkillType.Passive]: { str: "被动", textColor: "#0B679F" },
    [USkillType.AssAult]: { str: "突击", textColor: "#BE4242" },
    [USkillType.Cmmmand]: { str: "指挥", textColor: "#12962D" },
    [USkillType.Internal_Affairs]: {
        str: "内政",
        textColor: "#5B52D0",
    },
};

export const qualityTextColor: { [key: number]: string } = {
    [SkillQuality.Blue]: "#4B8FDD",
    [SkillQuality.Purple]: "#a15fe4",
    [SkillQuality.Orange]: "#DF8D18",
    [SkillQuality.Red]: "#E55E4D",
};
export class SkillService extends Service {
    public skillVoMap: SkillVoMap = new SkillVoMap(); //当前所有的已激活的技能列表

    public qualitySelList: { [key: number]: boolean } = {
        //品质筛选
        [SkillQuality.Red]: true,
        [SkillQuality.Orange]: true,
        [SkillQuality.Purple]: true,
        [SkillQuality.Blue]: true,
    };

    public typeSelList: { [key: number]: boolean } = {
        //类型筛选
        [USkillType.Ability]: true,
        [USkillType.Passive]: true,
        [USkillType.AssAult]: true,
        [USkillType.Cmmmand]: true,
        [USkillType.Internal_Affairs]: true,
    };

    public skillMapOwn: boolean = true;

    public mapQualitySelList: { [key: number]: boolean } = {
        //品质筛选
        [SkillQuality.Red]: true,
        [SkillQuality.Orange]: true,
        [SkillQuality.Purple]: true,
        [SkillQuality.Blue]: true,
    };

    public mapTypeSelList: { [key: number]: boolean } = {
        //类型筛选
        [USkillType.Ability]: true,
        [USkillType.Passive]: true,
        [USkillType.AssAult]: true,
        [USkillType.Cmmmand]: true,
        [USkillType.Internal_Affairs]: true,
    };

    override onCreate() {
        this.handle(opcode.skill.s2c_load_skills, this._onLoad);
        this.handle(opcode.skill.s2c_active_skill, this._onActSkill);
        this.handle(opcode.skill.s2c_mount_skill, this._onMountSkill);
        this.handle(opcode.skill.s2c_upgrade_skill, this._onUpGradeSkill);
        this.handle(opcode.skill.s2c_unmount_skill, this._onUnmountSkill);
    }

    override onStartInit(): void {}

    //激活技能
    private _onActSkill(data: proto.skill.s2c_active_skill, request: proto.skill.c2s_active_skill) {
        if (data.err === errcode.OK) {
            const skillId = request.skillId;
            const skillCfg = TableUtil.getRow(app.service.table.battleSkill.skill, {
                id: skillId,
            });
            if (skillCfg) {
                const skillData: proto.hero.SkillInfo = { id: skillId, lv: 1 };
                const skillVo = new SkillVo(skillCfg, skillData);
                this.skillVoMap.set(skillId, skillVo);
            }
            app.ui.toast(`激活成功`);
            app.event(SystemEvent.SkILL.SKILL_ACTIVE);
        }
    }

    //装配技能
    private _onMountSkill(data: proto.skill.s2c_mount_skill, request: proto.skill.c2s_mount_skill) {
        if (data.err === errcode.OK) {
            if (request.sysType === SlotSysType.HERO) {
                const heroUid = request.uid;
                const skillId = request.skillId;
                const pos = request.slotIdx;
                const skillData = this.getSkillDataById(skillId);
                const heroData = app.service.hero.heroVoMap.get(heroUid);
                if (heroData && skillData) {
                    heroData.skills[pos] = { id: skillId, lv: skillData.skillLevel };
                }
            } else if (request.sysType === SlotSysType.LEADER_SKIN) {
                const heroUid = request.uid;
                const skillId = request.skillId;
                const pos = request.slotIdx;
                const skillData = this.getSkillDataById(skillId);
                const skinData = app.service.leaderSkin.leaderSkinVoMap.get(heroUid);
                if (skinData && skillData) {
                    skinData.skills[pos] = { id: skillId, lv: skillData.skillLevel };
                }
            }

            app.ui.toast(`技能已装配`);
            app.event(SystemEvent.SkILL.SKILL_MOUNT);
        }
    }

    //卸下技能
    private _onUnmountSkill(
        data: proto.skill.s2c_unmount_skill,
        request: proto.skill.c2s_unmount_skill
    ) {
        if (data.err === errcode.OK) {
            if (request.sysType === SlotSysType.HERO) {
                const heroUid = request.uid;
                const skillPos = request.slotIdx;
                const heroData = app.service.hero.heroVoMap.get(heroUid);
                if (heroData) {
                    delete heroData.skills[skillPos];
                }
            } else if (request.sysType === SlotSysType.LEADER_SKIN) {
                const heroUid = request.uid;
                const skillPos = request.slotIdx;
                const skinData = app.service.leaderSkin.leaderSkinVoMap.get(heroUid);
                if (skinData) {
                    delete skinData.skills[skillPos];
                }
            }

            app.ui.toast(`已卸下`);
            app.event(SystemEvent.SkILL.SKILL_UNMOUNT);
        }
    }

    //技能升级
    private _onUpGradeSkill(
        data: proto.skill.s2c_upgrade_skill,
        request: proto.skill.c2s_upgrade_skill
    ) {
        if (data.err === errcode.OK) {
            if (request.sysType === SlotSysType.HERO) {
                const heroUid = request.uid;
                const skillPos = request.slotIdx;
                const heroData = app.service.hero.heroVoMap.get(heroUid);
                if (heroData) {
                    heroData.skills[skillPos].lv = heroData.skills[skillPos].lv! + 1;
                }
            } else if (request.sysType === SlotSysType.LEADER_SKIN) {
                const heroUid = request.uid;
                const skillPos = request.slotIdx;
                const skinData = app.service.leaderSkin.leaderSkinVoMap.get(heroUid);
                if (skinData) {
                    skinData.skills[skillPos].lv = skinData.skills[skillPos].lv! + 1;
                }
            }

            app.ui.toast(`升级成功`);
            app.event(SystemEvent.SkILL.SKILL_UP_GRADE);
        }
    }

    private _onLoad(data: proto.skill.s2c_load_skills) {
        if (data.err === errcode.OK) {
            this.skillVoMap.clear();
            for (const skillId of data.skills) {
                const skillCfg = TableUtil.getRow(app.service.table.battleSkill.skill, {
                    id: skillId,
                });
                if (skillCfg) {
                    const skillData: proto.hero.SkillInfo = { id: skillId, lv: 1 };
                    const skillVo = new SkillVo(skillCfg, skillData);
                    this.skillVoMap.set(skillId, skillVo);
                }
            }
            this.initHeroSkillList();
        }
    }

    //取所有英雄装配上的技能
    public initHeroSkillList() {
        for (const [_, heroData] of app.service.hero.heroVoMap) {
            if (heroData.skills) {
                for (const key in heroData.skills) {
                    const skillData = heroData.skills[key];
                    const skillId = skillData.id ?? 0;
                    const skillCfg = TableUtil.getRow(app.service.table.battleSkill.skill, {
                        id: skillId,
                    });
                    if (
                        !this.skillVoMap.get(skillId) &&
                        skillCfg &&
                        skillCfg.type !== SkillType.ATTACK
                    ) {
                        const skillVo = new SkillVo(skillCfg, skillData);
                        this.skillVoMap.set(skillId, skillVo);
                    }
                }
            }
        }
        const skinList = app.service.leaderSkin.leaderSkinVoMap.toArray();
        for (const skinData of skinList) {
            if (skinData.skills) {
                const id = skinData.id;
                const config = TableUtil.getRow<LeaderSkinRow>(app.service.table.leaderskin.skin, {
                    id,
                });
                const skill0 = config?.skill0 || undefined;
                for (const key in skinData.skills) {
                    const skillData = skinData.skills[key];
                    const skillId = skillData.id || 0;
                    if (!this.skillVoMap.get(skillId) && skillId !== skill0) {
                        const skillCfg = TableUtil.getRow(app.service.table.battleSkill.skill, {
                            id: skillId,
                        });
                        if (skillCfg) {
                            const skillVo = new SkillVo(skillCfg, skillData);
                            this.skillVoMap.set(skillId, skillVo);
                        }
                    }
                }
            }
        }
    }

    override onDestroy() {}

    //显示技能列表，包括获得相关碎片但未激活的
    public getSkillListSort() {
        let skillList = this.skillVoMap.toArray();
        const skillCfgs = app.service.table.battleSkill.skill;
        for (const key in skillCfgs) {
            const config = skillCfgs[key];
            if (!config.type) {
                continue;
            }
            const skillId = config.id;
            const actCost = config.active_cost;
            if (actCost && !this.skillVoMap.get(skillId)) {
                const rewardid = actCost[0].id;
                if (app.service.user.getMoneyCount(rewardid) > 0) {
                    const skillCfg = TableUtil.getRow(app.service.table.battleSkill.skill, {
                        id: skillId,
                    });
                    if (skillCfg) {
                        const skillData: proto.hero.SkillInfo = { id: skillId, lv: 1 };
                        const skillVo = new SkillVo(skillCfg, skillData);
                        skillList.push(skillVo);
                    }
                }
            }
        }
        skillList = skillList.filter((v) => {
            const qulity = v.quality;
            const skillType = v.typeskill || 1;
            const exit =
                (!qulity || this.qualitySelList[qulity]) &&
                (!skillType || this.typeSelList[skillType]);
            return exit;
        });
        skillList.sort((a: SkillVo, b: SkillVo) => {
            const aisAct = a.isCanAct ? 0 : 1;
            const bisAct = b.isCanAct ? 0 : 1;
            if (aisAct !== bisAct) {
                return aisAct - bisAct;
            } else {
                if (a.quality !== b.quality) {
                    return b.quality - a.quality;
                } else {
                    return b.skillId - a.skillId;
                }
            }
        });
        return skillList;
    }

    //判断技能已经激活
    public checkIsActed(skillId: number) {
        const skillData = this.skillVoMap.get(skillId);
        return skillData !== undefined;
    }

    //判断技能是否拥有
    public checkSkillOwn(skillId: number) {
        const ownSkillList = this.getSkillListSort();
        const skillData = ownSkillList.find((v) => v.skillId === skillId);
        return skillData !== undefined;
    }

    public checkSkillCanAct(cfg: BattleSkillRow) {
        if (cfg.active_cost) {
            const reward = cfg.active_cost[0];
            const ownCount = app.service.user.getMoneyCount(reward.id);
            return ownCount >= reward.num;
        }
        return false;
    }

    //判断是否为英雄或者幻化携带技能
    public getHeroUidBySkill(skillId: number): { uid: number; sysType: number }[] {
        const heroUidList: { uid: number; sysType: number }[] = [];
        const heroList = app.service.hero.heroVoMap.toArray();
        for (const heroData of heroList) {
            for (const key in heroData.skills) {
                if (heroData.skills[key].id === skillId) {
                    heroUidList.push({ uid: heroData.uid, sysType: SlotSysType.HERO });
                }
            }
        }
        const heroSkinList = app.service.leaderSkin.leaderSkinVoMap.toArray();
        for (const skinData of heroSkinList) {
            for (const key in skinData.skills) {
                if (skinData.skills[key].id === skillId) {
                    heroUidList.push({ uid: skinData.id, sysType: SlotSysType.LEADER_SKIN });
                }
            }
        }
        return heroUidList;
    }

    public getSkillMapSort(): BattleSkillRow[] {
        const skillMapCfg = app.service.table.battleSkill.skill;
        const skillMapList: BattleSkillRow[] = [];
        const isOwn = this.skillMapOwn;
        for (const key in skillMapCfg) {
            const config = skillMapCfg[key];
            if (!config.type) {
                continue;
            }
            const quality = config.quality || 0;
            const type = config.type_icon || 0;
            const own = this.checkSkillOwn(config.id);
            if (
                (!quality || this.mapQualitySelList[quality]) &&
                (!type || this.mapTypeSelList[type]) &&
                ((!isOwn && !own) || isOwn)
            ) {
                skillMapList.push(config);
            }
        }
        skillMapList.sort((a, b) => {
            const aSkillData = this.getSkillDataById(a.id);
            const bSkillData = this.getSkillDataById(b.id);
            const aisActed = aSkillData !== null ? 0 : 1;
            const bisActed = bSkillData !== null ? 0 : 1;
            const aSkillOwn = this.checkSkillOwn(a.id) ? 0 : 1;
            const bSkillOwn = this.checkSkillOwn(b.id) ? 0 : 1;
            const aQuality = a.quality || 0;
            const bQuality = b.quality || 0;
            if (aisActed !== bisActed) {
                return aisActed - bisActed;
            } else {
                if (aSkillOwn !== bSkillOwn) {
                    return aSkillOwn - bSkillOwn;
                } else {
                    if (aQuality !== bQuality) {
                        return bQuality - aQuality;
                    } else {
                        return b.id - a.id;
                    }
                }
            }
        });
        return skillMapList;
    }

    public getSKillIcon(skillId: number) {
        const cfg = TableUtil.getRow(app.service.table.battleSkill.skill, { id: skillId });
        if (cfg) {
            return StringUtil.format(res.skill.SKILL_ICON, cfg.icon);
        }
        return "";
    }

    //技能快捷列表
    public getSkillFastListSort() {
        let actedSkillList = this.skillVoMap.toArray();
        actedSkillList = actedSkillList.filter((v) => {
            const qulity = v.quality;
            const skillType = v.typeskill || 1;
            const exit =
                (!qulity || this.qualitySelList[qulity]) &&
                (!skillType || this.typeSelList[skillType]);
            return exit;
        });
        actedSkillList.sort((a, b) => {
            if (a.quality !== b.quality) {
                return b.quality - a.quality;
            } else {
                return b.skillId - a.skillId;
            }
        });
        return actedSkillList;
    }

    //获取已经激活的技能
    public getSkillDataById(id: number) {
        const skillData = this.skillVoMap.get(id);
        return skillData;
    }

    //自己拥有的列表
    public getSkillListBySel() {
        const skillList = this.getSkillListSort();
        const skillListSel: SkillVo[] = [];
        for (const skillData of skillList) {
            const skillType = skillData.typeskill;
            const quality = skillData.quality;
            if (this.getQuqlitySel(quality) && this.getTypeSel(skillType)) {
                skillListSel.push(skillData);
            }
        }
        return skillListSel;
    }

    public updateQualitySel(qulityKey: number, isSel: boolean) {
        this.qualitySelList[qulityKey] = isSel;
    }

    public getQuqlitySel(qulityKey: number): boolean {
        return this.qualitySelList[qulityKey];
    }

    public updateTypeSel(typeKey: number, isSel: boolean) {
        this.typeSelList[typeKey] = isSel;
    }

    public getTypeSel(qulityKey: number): boolean {
        return this.typeSelList[qulityKey];
    }

    public updateMapQualitySel(qulityKey: number, isSel: boolean) {
        this.mapQualitySelList[qulityKey] = isSel;
    }

    public getMapQualitySel(qulityKey: number): boolean {
        return this.mapQualitySelList[qulityKey];
    }

    public updateMapTypeSel(typeKey: number, isSel: boolean) {
        this.mapTypeSelList[typeKey] = isSel;
    }

    public getMapTypeSel(qulityKey: number) {
        return this.mapTypeSelList[qulityKey];
    }

    public getFirstLevelDesc(skilCfg: BattleSkillRow) {
        const args = skilCfg.args;
        const coefficient = skilCfg.coefficient;
        let showDesc: string = skilCfg.desc;
        if (args && args.length) {
            const fullArgs = args[0];
            for (const arg of fullArgs) {
                showDesc = showDesc.replace("#1", arg.toString());
            }
        }
        if (coefficient && coefficient.length) {
            const fullCoficient = coefficient[0];
            showDesc = showDesc.replace("@1", fullCoficient.toString());
        }
        return showDesc;
    }

    public getFullLevelDesc(skilCfg: BattleSkillRow) {
        const args = skilCfg.args;
        const coefficient = skilCfg.coefficient;
        let showDesc: string = skilCfg.desc;
        if (args && args.length) {
            const fullArgs = args[args.length - 1];
            for (const arg of fullArgs) {
                showDesc = showDesc.replace("#1", arg.toString());
            }
        }
        if (coefficient && coefficient.length) {
            const fullCoficient = coefficient[coefficient.length - 1];
            showDesc = showDesc.replace("@1", fullCoficient.toString());
        }
        return showDesc;
    }

    public getCurLevelSkillDesc(skilCfg: BattleSkillRow, lv: number) {
        const args = skilCfg.args;
        const coefficient = skilCfg.coefficient;
        let showDesc: string = skilCfg.desc;
        if (args && args.length) {
            const fullArgs = args[lv - 1];
            for (const arg of fullArgs) {
                showDesc = showDesc.replace("#1", arg.toString());
            }
        }
        if (coefficient && coefficient.length) {
            const fullCoficient = coefficient[lv - 1];
            showDesc = showDesc.replace("@1", fullCoficient.toString());
        }
        return showDesc;
    }

    public getSkillTypeText(skillType: number) {
        const skillShow = SkillTypeText;
        return skillShow[skillType].str;
    }

    public getSkillCfgById(skillId: number): BattleSkillRow | null {
        const skillCfg = TableUtil.getRow(app.service.table.battleSkill.skill, { id: skillId });
        if (skillCfg) {
            return skillCfg;
        }
        return null;
    }

    public async requestActiveSkill(skillId: number) {
        return await app.service.network.call(
            proto.skill.c2s_active_skill.create({ skillId: skillId }),
            proto.skill.s2c_active_skill
        );
    }

    //升级技能
    public async requestUpGradeSkill(heroUid: number, skillPos: number, sysType: number) {
        return await app.service.network.call(
            proto.skill.c2s_upgrade_skill.create({
                uid: heroUid,
                slotIdx: skillPos,
                sysType: sysType,
            }),
            proto.skill.s2c_upgrade_skill
        );
    }

    //装配技能
    public async requestMountSkill(
        heroUid: number,
        skillId: number,
        skillPos: number,
        sysType: number
    ) {
        return await app.service.network.call(
            proto.skill.c2s_mount_skill.create({
                uid: heroUid,
                skillId: skillId,
                slotIdx: skillPos,
                sysType: sysType,
            }),
            proto.skill.s2c_mount_skill
        );
    }

    public getQualityTextColor(quality: number) {
        const color = qualityTextColor[quality];
        return color;
    }

    public getTypeTextColor(type: number) {
        const color = SkillTypeText[type].textColor;
        return color;
    }

    public async requestUnmountSkill(heroUid: number, skillPos: number, sysType: number) {
        return await app.service.network.call(
            proto.skill.c2s_unmount_skill.create({
                uid: heroUid,
                slotIdx: skillPos,
                sysType: sysType,
            }),
            proto.skill.s2c_unmount_skill
        );
    }

    public async load() {
        return await app.service.network.call(
            proto.skill.c2s_load_skills.create(),
            proto.skill.s2c_load_skills
        );
    }
}
