const { regClass } = Laya;
import { PlotDialogueWinMediator } from "../PlotDialogueWinMediator";
import { PlotDialogueWinUIBase } from "./PlotDialogueWinUI.generated";

export type PlotDialogueParam = {
    plotId: number;
    callBack?: Laya.Handler;
};

@regClass('VIrclAYdSeCovNEJ_tC39g')
export class PlotDialogueWinUI extends PlotDialogueWinUIBase {
    public dialogueParam!: PlotDialogueParam;

    override open(closeOther?: boolean | undefined, param?: PlotDialogueParam): void {
        this.dialogueParam = param!;
        super.open(closeOther, param);
    }

    override onOpened(param: any): void {
        const med = this.mediator;
        if (med) {
            med.onOpened();
        }
    }

    public get mediator(): PlotDialogueWinMediator | null {
        return this.getComponent(PlotDialogueWinMediator);
    }
}
