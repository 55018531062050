import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { Pool } from "../../../../../core/pool";
import { EntityType } from "../../../../../def/auto/world";
import { res } from "../../../../../misc/res";
import { Animator } from "../../../base/Animator";
import { PvpContext } from "../../PvpContext";
import { VFXTroopSelectComp } from "../../ui/PvpVFXTroopSelectComp";
import { PvpMoveTargetVFXComponent } from "../components/PvpMoveTargetComponent";
import { PvpTransformComponent } from "../components/PvpTransformComponent";

import { BattleConf } from "../../../../../def/auto/battle";
import { SystemEvent } from "../../../../../misc/system-event";
import { TMPropKey } from "../../../tilemap/tm-def";
import { VFXTroopBattleComp } from "../../ui/PvpVFXTroopBattleComp";
import { PvpAnimationComponent } from "../components/PvpAnimationComponent";
import { PvpBattleComponent } from "../components/PvpBattleComponent";
import { PvpBoardComponent } from "../components/PvpBoardComponent";
import { PvpElementComponent } from "../components/PvpElementComponent";
import { PvpMapComponent } from "../components/PvpMapComponent";
import { PvpShadowComponent } from "../components/PvpShadowComponent";
import { PvpStartBattleVFXComponent } from "../components/PvpStartBattleVFXComponent";
import { PvpTroopBattleVFXComponent } from "../components/PvpTroopBattleVFXComponent";
import { PvpTroopComponent } from "../components/PvpTroopComponent";
import { PvpTroopSelectVFXComponent } from "../components/PvpTroopSelectVFXComponent";
import { PvpWagonComponent } from "../components/PvpWagonComponent";

/**
 * 与 PvpUISystem 的区别是：
 * PvpRenderSystem 专门负责Scene3D下的组件渲染
 * PvpUISystem 专门负责Scene2D UI的渲染
 */
export class PvpRenderSystem extends ecs.System {
    declare context: PvpContext;

    override onCreate(): void {
        this.registerHandler(
            PvpAnimationComponent,
            this._onAddAnimationComponent,
            this._onDelAnimationComponent
        );
        this.registerHandler(
            PvpShadowComponent,
            this._onAddShadowComponent,
            this._onDelShadowComponent
        );
        this.registerHandler(
            PvpBoardComponent,
            this._onAddBoardComponent,
            this._onDelBoardComponent
        );
        this.registerHandler(
            PvpTroopComponent,
            this._onAddTroopComponent,
            this._onDelTroopComponent
        );
        this.registerHandler(
            PvpTroopSelectVFXComponent,
            this._onAddTroopSelectComponent,
            this._onDelTroopSelectComponent
        );
        this.registerHandler(
            PvpTroopBattleVFXComponent,
            this._onAddTroopBattleVFXComponent,
            this._onDelTroopBattleVFXComponent
        );
        this.registerHandler(
            PvpBattleComponent,
            this._onAddBattleComponent,
            this._onDelBattleComponent
        );
        this.registerHandler(
            PvpStartBattleVFXComponent,
            this._onAddPvpStartBattleVFXComponent,
            this._onDelPvpStartBattleVFXComponent
        );
        this.registerHandler(
            PvpMoveTargetVFXComponent,
            this._onAddMoveTargetComponent,
            this._onDelMoveTargetComponent
        );
    }

    override update(dt: number): void {
        this.ecs.getComponents(PvpElementComponent).forEach((element) => {
            this._updateElementComponent(element);
        });
    }

    //#region AnimationComponent
    private async _onAddAnimationComponent(anim: PvpAnimationComponent) {
        this.context.owner.roles.addChild(anim.mounter);
        if (anim.res) {
            const checker = anim.checker;
            const prefab = await app.loader.loadPrefab(anim.res);
            if (!checker()) {
                return;
            }

            anim.view = prefab.create() as Laya.Sprite3D;
            anim.animator = anim.view.getComponent(Animator);
            if (anim.animator) {
                anim.animator.url = anim.res;
            }
            anim.mounter.addChild(anim.view);

            // TODO: 添加组件？
            const escortable = anim.getComponent(PvpWagonComponent);
            if (escortable) {
                anim.animator?.loopPlay("move");

                let formation: Laya.Vector3[];
                let path: string;
                const escortableRow = app.service.table.worldWagon[escortable.id];
                if (anim.etype === EntityType.WAGON) {
                    path = "anim/gather-stone";
                    formation = PvpWagonComponent.STONE;
                    if (anim.view) {
                        const gatherParent = anim.view.getChildByPath(
                            "anim/gather"
                        ) as Laya.Sprite3D;

                        for (let i = 0; i < escortableRow.layer * 4; i++) {
                            const obj = (anim.view.getChildByPath(path) as Laya.Sprite3D).clone();
                            const pos = obj.transform.position;
                            const idx = i % formation.length;
                            pos.x = formation[idx].x;
                            pos.y = formation[idx].y + Math.floor(i / formation.length) * 0.35;
                            pos.z = formation[idx].z;
                            obj.transform.position = pos;
                            obj.active = true;
                            gatherParent.addChild(obj);
                        }
                    }
                }
            }
        }
    }

    private _onDelAnimationComponent(comp: PvpAnimationComponent) {
        comp.view?.destroy();
        comp.view = null;
        comp.animator = null;
    }
    //#endregion AnimationComponent

    //#region ElementComponent
    private _updateElementComponent(element: PvpElementComponent) {
        const transform = element.getComponent(PvpTransformComponent)!;

        if (transform.flag & PvpTransformComponent.POSITION) {
            const { shadow, animation, troop } = element;

            // 更新阴影位置
            if (shadow?.view) {
                const position = shadow.view.transform.position;
                position.cloneFrom(transform.position);
                shadow.view.transform.position = position;
            }

            if (animation?.mounter) {
                // 更新动画位置
                animation.mounter.transform.position = transform.position;
                transform.flag &= ~PvpTransformComponent.POSITION;
            }
            if (troop?.mounter) {
                // 更新部队位置
                troop.mounter.transform.position = transform.position;
                transform.flag &= ~PvpTransformComponent.POSITION;
            }
        }

        if (transform.flag & PvpTransformComponent.ROTATION) {
            const { animation, troop } = element;

            if (animation?.view) {
                // 更新动画角度
                animation.view.transform.localRotationEulerY = transform.rotation;
                transform.flag &= ~PvpTransformComponent.ROTATION;
            }
            if (troop?.mounter) {
                // 更新部队角度
                troop.mounter.transform.localRotationEulerY = transform.rotation;
                transform.flag &= ~PvpTransformComponent.ROTATION;
            }
        }
    }
    //#endregion ElementComponent

    //#region ShadowComponent
    private async _onAddShadowComponent(shadow: PvpShadowComponent) {
        const checker = shadow.checker;
        const prefab = await app.loader.loadPrefab(shadow.res);
        if (!checker()) {
            return;
        }

        shadow.view = prefab.create() as Laya.Sprite3D;
        shadow.view.transform.localPositionY = 0.01;
        this.context.owner.shadows.addChild(shadow.view);
    }

    private _onDelShadowComponent(comp: PvpShadowComponent) {
        comp.view?.destroy();
        comp.view = null;
    }

    //#endregion ShadowComponent

    //#region BoardComponent
    private async _onAddBoardComponent(board: PvpBoardComponent) {
        const transform = board.getComponent(PvpTransformComponent)!;
        const element = board.getComponent(PvpElementComponent)!;

        const etype = element.etype;

        if (etype === EntityType.CITY) {
            board.props.set(TMPropKey.TextureKey, board.textureKey!);
            // const cfg = app.service.table.textureCfg[board.textureKey!]!;
            // offsetX = -cfg.tile_w / 2;
            // offsetZ = -cfg.tile_h / 2;
        } else if (etype === EntityType.MINE) {
            board.props.set(TMPropKey.TextureKey, board.textureKey!);
            // const cfg = app.service.table.textureCfg[board.textureKey!]!;
            // offsetX = -cfg.tile_w / 2;
            // offsetZ = -cfg.tile_h / 2;
        } else {
            console.warn(`unhandle etype: ${etype}`);
        }

        const gridX = Math.floor(transform.position.x);
        const gridY = Math.floor(transform.position.z);
        const map = this.ecs.getSingletonComponent(PvpMapComponent);
        map.tilemap.addObjectElement(board.eid, gridX, gridY, board.props);
    }

    private _onDelBoardComponent(comp: PvpBoardComponent) {
        const map = this.ecs.getSingletonComponent(PvpMapComponent);
        map.tilemap.delObjectElementByEid(comp.eid);
    }

    //#endregion BoardComponent

    //#region TroopComponent
    private async _onAddTroopComponent(troop: PvpTroopComponent) {
        this.context.owner.roles.addChild(troop.mounter);

        const table = app.service.table;
        if (!troop.heroId) {
            return;
        }
        const heroRow = table.hero.hero[troop.heroId];
        const heroEntityRow = table.worldEntity.models[heroRow.world_entity];
        const soldier1EntityRow = table.worldEntity.models[20000];
        const soldier2EntityRow = table.worldEntity.models[20001];
        const group = new Laya.Sprite3D();
        troop.mounter.addChild(group);

        const checker = troop.checker;
        const heroPrefable = await app.loader.loadPrefab(heroEntityRow.res);
        const soldier1Prefable = await app.loader.loadPrefab(soldier1EntityRow.res);
        const soldier2Prefable = await app.loader.loadPrefab(soldier2EntityRow.res);
        if (!checker()) {
            return;
        }

        troop.hero = heroPrefable.create() as Laya.Sprite3D;
        this._collectTroopAnimator(troop, troop.hero);
        group.addChild(troop.hero);
        group.transform.localPositionZ = 0.35;
        group.transform.localScaleX = 0.4;
        group.transform.localScaleY = 0.4;
        group.transform.localScaleZ = 0.4;

        const totalSoldierCount = BattleConf.PVE.SOLDIER_ONE_ROW_COUNT * 3;
        troop.formation.move.soldiers.forEach((p, idx) => {
            if (idx >= totalSoldierCount) {
                return;
            }
            let soldier: Laya.Sprite3D | null;
            if (idx < 4) {
                soldier = soldier1Prefable.create() as Laya.Sprite3D;
            } else {
                soldier = soldier2Prefable.create() as Laya.Sprite3D;
            }
            const position = soldier.transform.localPosition;
            position.cloneFrom(p);
            soldier.transform.localPosition = position;
            this._collectTroopAnimator(troop, soldier);
            group.addChild(soldier);
        });

        troop.view = group;
        app.event(SystemEvent.PVP.UPDATE_TROOP_ENTITY, troop.eid);
    }

    private _onDelTroopComponent(comp: PvpTroopComponent) {
        comp.removeComponent(PvpTroopSelectVFXComponent);
        comp.removeComponent(PvpTroopBattleVFXComponent);

        comp.hero?.destroy();
        comp.hero = null;
        comp.soldiers.forEach((soldier) => {
            soldier.destroy();
        });
        comp.soldiers.length = 0;
        comp.animators.length = 0;
        comp.view?.destroy();
        comp.view = null;

        comp.mounter.removeChildren();
        comp.mounter.destroy();
    }

    private _collectTroopAnimator(troop: PvpTroopComponent, view: Laya.Sprite3D) {
        const animator = view.getComponent(Animator);
        if (!animator) {
            console.error(`no animator in '${view.url}'`);
        } else {
            troop.animators.push(animator);
        }
    }
    //#endregion TroopComponent

    //#region TroopSelectComponent
    private async _onAddTroopSelectComponent(troopSelectComp: PvpTroopSelectVFXComponent) {
        // 去除其他部队的选中状态
        this.ecs.getComponents(PvpTroopSelectVFXComponent).forEach((comp) => {
            troopSelectComp.eid !== comp.eid && comp.removeComponent(PvpTroopSelectVFXComponent);
        });

        const checker = troopSelectComp.checker;
        const prefab = await app.loader.loadPrefab(res.VFX_PVP_TROOP_SELECT);
        if (!checker()) {
            return;
        }

        const troopComp = troopSelectComp.getComponent(PvpTroopComponent)!;
        const view = prefab.create() as Laya.Sprite3D;
        view.transform.localScale = Laya.Vector3._tempVector3.set(0.5, 0.5, 0.5);
        troopSelectComp.view = view;
        const vfxTroopSelectComp = view.getComponent(VFXTroopSelectComp);
        vfxTroopSelectComp?.setupColor(troopSelectComp.pStyle);
        troopComp.mounter.addChild(troopSelectComp.view);
    }

    private _onDelTroopSelectComponent(comp: PvpTroopSelectVFXComponent) {
        comp.view?.destroy();
        comp.view = null;
    }
    //#endregion TroopSelectComponent

    //#region MoveTargetComponent
    private _onDelMoveTargetComponent(moveComp: PvpMoveTargetVFXComponent) {
        if (moveComp.view) {
            Pool.free(moveComp.res, moveComp.view);
            moveComp.view = null!;
        }
    }

    private async _onAddMoveTargetComponent(moveComp: PvpMoveTargetVFXComponent) {
        this.context.owner.scene3D.addChild(moveComp.mounter);

        const checker = moveComp.checker;
        const prefab = await app.loader.loadPrefab(moveComp.res);
        if (!checker()) {
            return;
        }

        const target = moveComp.pos;
        const targetX = target.x;
        const targetZ = target.z;
        // load from Pool Or Res
        const view = Pool.obtain(moveComp.res, () => prefab.create()) as Laya.Sprite3D;
        moveComp.mounter.addChild(view);
        moveComp.view = view;
        Laya.Vector3._tempVector3.setValue(targetX, 0, targetZ);
        moveComp.mounter.transform.localPosition = Laya.Vector3._tempVector3;
    }
    //#endregion MoveTargetComponent

    //#region TroopBattleVFXComponent
    private async _onAddTroopBattleVFXComponent(comp: PvpTroopBattleVFXComponent) {
        const troopComp = comp.getComponent(PvpTroopComponent)!;
        // load from Pool Or Res
        const checker = comp.checker;
        const prefab = await app.loader.loadPrefab(comp.res);
        if (!checker()) {
            return;
        }

        const view = Pool.obtain(comp.res, () => prefab.create()) as Laya.Sprite3D;
        comp.view = view;

        const vfxTroopSelectComp = view.getComponent(VFXTroopBattleComp);
        vfxTroopSelectComp?.setupColor(comp.pStyle);

        if (troopComp.mounter.destroyed) {
            console.error(`troop view transform is null, eid: ${comp.eid}`, troopComp.mounter);
        }
        if (view.destroyed) {
            console.error(`vfx view transform is null, eid: ${comp.eid}`, view);
        }
        troopComp.mounter.addChild(comp.view);
    }

    private _onDelTroopBattleVFXComponent(comp: PvpTroopBattleVFXComponent) {
        if (comp.view) {
            !comp.view.destroyed && Pool.free(comp.res, comp.view);
            comp.view.removeSelf();
            comp.view = null!;
        }
    }
    //#endregion TroopBattleVFXComponent

    //#region PvpBattleComponent
    private _onAddBattleComponent(comp: PvpBattleComponent) {
        const fighter1 = this.ecs.getComponent(comp.fighterEid1, PvpTroopComponent)!;
        const fighter2 = this.ecs.getComponent(comp.fighterEid2, PvpTroopComponent)!;
        if (fighter1 && fighter2) {
            const trans1 = fighter1.getComponent(PvpTransformComponent)!;
            const trans2 = fighter2.getComponent(PvpTransformComponent)!;
            const pos1 = trans1.position;
            const pos2 = trans2.position;

            const svrTime = app.service.network.serverTime;
            // 2秒内的战斗，播放战斗特效
            if (Math.abs(svrTime - comp.startTime) < 2) {
                const vfxComp = comp.addComponent(PvpStartBattleVFXComponent);
                vfxComp.res = res.VFX_PVP_START_BATTLE;
                vfxComp.position.setValue(
                    (pos1.x + pos2.x) / 2,
                    vfxComp.position.y,
                    (pos1.z + pos2.z) / 2
                );
            }
        }
    }

    private _onDelBattleComponent(comp: PvpBattleComponent) {
        comp.removeComponent(PvpStartBattleVFXComponent);
    }
    //#endregion PvpBattleComponent

    //#region PvpStartBattleVFXComponent
    private async _onAddPvpStartBattleVFXComponent(comp: PvpStartBattleVFXComponent) {
        // load from Pool Or Res
        const checker = comp.checker;
        const prefab = await app.loader.loadPrefab(comp.res);
        if (!checker()) {
            return;
        }

        const view = Pool.obtain(comp.res, () => prefab.create()) as Laya.Sprite3D;
        view.transform.localPosition = comp.position;
        comp.view = view;
        this.context.owner.roles.addChild(comp.view);
    }

    private _onDelPvpStartBattleVFXComponent(comp: PvpStartBattleVFXComponent) {
        if (comp.view) {
            !comp.view.destroyed && Pool.free(comp.res, comp.view);
            comp.view.removeSelf();
            comp.view = null!;
        }
    }
    //#endregion PvpStartBattleVFXComponent
}
