/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/map-transfer/map-transfer-area-btn.lh
 */
export class MapTransferAreaBtnUIBase extends Laya.Box {
    public btn!: Laya.Button;
    public btn_text!: Laya.Label;
}

