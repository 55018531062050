/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { AllianceAvatar } from "./AllianceAvatar";

/**
 * resources/prefab/alliance/AllianceMemberItem.lh
 */
export class AllianceMemberItemBase extends Laya.Box {
    public avatar!: AllianceAvatar;
    public leaderName!: Laya.Label;
    public officer!: Laya.Label;
    public stateText!: Laya.Label;
    public onlineText!: Laya.Label;
    public powerText!: Laya.Label;
}

