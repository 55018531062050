export class AstarPathNode {
    /** 坐标 */
    point: Laya.Vector2 = new Laya.Vector2();

    get pointKey(): number {
        return (this.point.x << 20) | (this.point.y << 0);
    }

    // static nodeTotalCount: number = 0;

    constructor() {
        // AstarPathNode.nodeTotalCount++;
        // console.log("nodeTotalCount:" + AstarPathNode.nodeTotalCount);
    }

    /** 父节点 */
    parent: AstarPathNode | null = null;

    /** 开始节点到当前节点的距离 */
    g: number = 0;

    /** 当前节点到结束节点的距离（估算值，未考虑阻挡） */
    h: number = 0;

    /** g+h */
    f: number = 0;

    idx: number = 0;

    /** 是否位于开放列表中 */
    open: boolean = true;

    init(
        pX: number,
        pY: number,
        parent: AstarPathNode | null,
        g: number,
        h: number,
        width: number
    ) {
        this.point.x = pX;
        this.point.y = pY;
        this.parent = parent;
        this.idx = pY * width + pX;
        this.g = g;
        this.h = h;
        this.f = g + h;
    }

    equals(o: AstarPathNode): boolean {
        if (this === o) return true;
        if (o === null) return false;
        if (this.point.x === o.point.x && this.point.y === o.point.y) {
            return true;
        }
        return false;
    }

    /** 比大小 */
    compareTo(o: AstarPathNode): number {
        const x = this.f;
        const y = o.f;
        if (x === y) {
            return this.idx < o.idx ? -1 : this.idx === o.idx ? 0 : 1;
        }
        return x < y ? -1 : x === y ? 0 : 1;
    }

    /** Pool.free 回收时进行清理 */
    __unuse(): void {
        this.parent = null;
        this.point.x = 0;
        this.point.y = 0;
        this.g = 0;
        this.h = 0;
        this.f = 0;
        this.idx = 0;
        this.open = true;
    }
}
