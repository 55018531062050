import { app } from "../../../app";
import { IReusable, Pool } from "../../../core/pool";
import { Quad } from "../../../core/render/mesh-render";
import { tween } from "../../../core/tween/tween";
import { StringUtil } from "../../../core/utils/string-util";
import { BattleConf } from "../../../def/auto/battle";
import { res } from "../../../misc/res";
import { Tilemap } from "./tilemap";
import { TMAtlasName, TMLayerName, TMPropKey } from "./tm-def";
import { TMUtil } from "./tm-util";

@Pool.reusable
export class TMElementDescriptor implements IReusable {
    key: number = 0;
    tid: number = 0;
    x: number = 0;
    z: number = 0;
    rotation: number = 0;
    layer: TMLayerName = TMLayerName.None;

    constructor(element: TMElement) {
        this.__reuse(element);
    }

    __unuse() {}

    __reuse(element: TMElement) {
        this.key = element.key;
        this.tid = element.tid;
        this.x = element.placeX;
        this.z = element.placeY;
        this.rotation = element.rotation;
        this.layer = element.layerName;
    }
}

export abstract class TMElement {
    private static UID: number = 0;

    protected _tilemap!: Tilemap;

    private _uid!: number;
    private _gridX!: number;
    private _gridY!: number;
    private _props!: Map<string, unknown>;
    private _layerName!: TMLayerName;
    private _eid?: number;
    private _key: number = 0;

    get key() {
        return this._key;
    }

    get rotation(): number {
        return (this.props.get(TMPropKey.ROTATION) as number | undefined) ?? 0;
    }

    get tid(): number {
        return (this.props.get(TMPropKey.TID) as number | undefined) ?? 0;
    }

    get gridX(): number {
        return this._gridX;
    }

    get gridY(): number {
        return this._gridY;
    }

    get placeX() {
        return this._gridX + 0.5;
    }

    get placeY() {
        return this._gridY + 0.5;
    }

    get props(): Map<string, unknown> {
        return this._props;
    }

    get layerName(): TMLayerName {
        return this._layerName;
    }

    get eid(): number {
        return this._eid ?? 0;
    }

    protected get checker() {
        const uid = this._uid;
        const root = this._tilemap.getRoot();
        return () => uid === this._uid && root && !root.destroyed;
    }

    init(
        tilemap: Tilemap,
        gridX: number,
        gridY: number,
        props: Map<string, unknown>,
        layerName: TMLayerName,
        eid?: number
    ): void {
        this._uid = --TMElement.UID;
        this._tilemap = tilemap;
        this._gridX = gridX | 0;
        this._gridY = gridY | 0;
        this._props = props;
        this._layerName = layerName;
        this._eid = eid;
        this._key = TMUtil.encodeKey(layerName, gridX, gridY);
    }

    recover(): void {
        this.erase();
        this._uid = 0;
        Pool.free(this);
    }

    abstract draw(): void;
    abstract erase(): void;
}

export abstract class TMTileElemet extends TMElement {
    private _quad?: Quad;

    get gid(): number {
        return this.props.get(TMPropKey.Gid) as number;
    }

    private _MaterialMap: Map<string, Laya.Material> = new Map<string, Laya.Material>();

    async draw() {
        if (this._quad) {
            return;
        }

        const frame = this._tilemap.getAtlasFrameIdx(this.gid);
        if (!frame) {
            console.warn(`resource not found: gid=${this.gid}`);
            return;
        }
        if (frame.index === 0 && this.ignoreFirstFrame()) {
            return;
        }
        const checker = this.checker;
        const atlasPath = `resources/texture/world-map/${frame.image}`;
        const atlas = await app.loader.loadAtlas(atlasPath);

        if (!checker()) {
            return;
        }

        let mat: Laya.Material | undefined;

        if (!atlas.frames[frame.index]) {
            console.error(`no frame for index: ${frame.index} atlas=${atlasPath}`);
            return;
        }
        const baseSubPath = atlas.frames[frame.index].url;
        const baseTex = await app.loader.loadTexture(baseSubPath);
        // let groundNormalTex: Laya.Texture | undefined;
        // if (this.layerName === TMLayerName.Ground) {
        //     groundNormalTex = await app.loader.loadTexture(
        //         "resources/texture/world-map/ground_normal.png"
        //     );
        // }

        if (!checker()) {
            return;
        }

        // 水需要特殊处理Shader
        if (this.layerName === TMLayerName.River) {
            // 加载水遮罩和材质
            const riverMaskAtlas = await app.loader.loadAtlas(res.atlas.RIVER_MASK);
            const maskAtlasSubPath = riverMaskAtlas.frames[frame.index].url;
            const maskSubTex = await app.loader.loadTexture(maskAtlasSubPath);
            const riverMaterialsPath = res.materials.RIVER_MATERIAL_MATERIAL;
            mat = await app.loader.loadMaterial(riverMaterialsPath);

            if (!checker()) {
                return;
            }

            mat.setTexture("u_BaseTexture", baseTex.bitmap);
            mat.setTexture("u_MaskTexture", maskSubTex.bitmap);
        } else {
            if (this._MaterialMap.has(atlasPath)) {
                mat = this._MaterialMap.get(atlasPath);
            } else {
                let tileMaterialsPath = res.materials.TILE_MATERIAL;
                const renderMode = this.getRenderMode();
                if (renderMode === Laya.MaterialRenderMode.RENDERMODE_TRANSPARENT) {
                    tileMaterialsPath = res.materials.TILE_TRANSPARENT_MATERIAL;
                }
                if (this.layerName === TMLayerName.Ground) {
                    // 使用凹凸贴图
                    tileMaterialsPath = "resources/materials/ground_material.lmat";
                }
                const matClass = await app.loader.loadMaterial(tileMaterialsPath);

                if (!checker()) {
                    return;
                }

                mat = matClass.clone() as Laya.Material;
                mat.setTexture("u_BaseTexture", baseTex.bitmap);
                // if (groundNormalTex && this.layerName === TMLayerName.Ground) {
                //     // 使用凹凸贴图
                //     mat.setTexture("u_NormalTexture", groundNormalTex.bitmap);
                // }
                this._MaterialMap.set(atlasPath, mat);
            }
        }

        const offset = TMUtil.DEBUG_MODE ? 0.01 : 0;
        this._quad = Pool.obtain(Quad);
        this._quad.drawPlane(
            this.gridX + offset,
            this.getOffsetY(),
            this.gridY + offset,
            1 - offset * 2,
            1 - offset * 2,
            baseTex
        );

        this._tilemap.addQuad(this.layerName, this._quad, this.getRenderMode(), mat);
    }

    override erase() {
        if (this._quad) {
            this._tilemap.removeQuad(this.layerName, this._quad);
            Pool.free(this._quad);
        }
        this._quad = undefined;
    }

    abstract getAtlasName(): TMAtlasName;

    abstract getOffsetY(): number;
    abstract getRenderMode(): Laya.MaterialRenderMode;

    /** 道路河流的第一个贴图是空白贴图，用于地刷功能，渲染时应该跳过。 */
    abstract ignoreFirstFrame(): boolean;
}

export class TMGroundElement extends TMTileElemet {
    override getAtlasName(): TMAtlasName {
        return TMAtlasName.Ground;
    }

    override getOffsetY(): number {
        return BattleConf.GROUND_OFFSET.GROUND;
    }

    override getRenderMode(): Laya.MaterialRenderMode {
        return Laya.MaterialRenderMode.RENDERMODE_OPAQUE;
    }

    override ignoreFirstFrame(): boolean {
        return false;
    }
}

export class TMRoadElement extends TMTileElemet {
    override getAtlasName(): TMAtlasName {
        return TMAtlasName.Road;
    }

    override getOffsetY(): number {
        return BattleConf.GROUND_OFFSET.ROAD;
    }

    override getRenderMode(): Laya.MaterialRenderMode {
        return Laya.MaterialRenderMode.RENDERMODE_TRANSPARENT;
    }

    override ignoreFirstFrame(): boolean {
        return true;
    }
}

export class TMRiverElement extends TMTileElemet {
    override getAtlasName(): TMAtlasName {
        return TMAtlasName.River;
    }

    override getOffsetY(): number {
        return BattleConf.GROUND_OFFSET.RIVER;
    }

    override getRenderMode(): Laya.MaterialRenderMode {
        return Laya.MaterialRenderMode.RENDERMODE_TRANSPARENT;
    }

    override ignoreFirstFrame(): boolean {
        return true;
    }
}

export class TMStaticElement extends TMElement {
    private _root?: Laya.Sprite3D;
    private _startX: number = 0;
    private _startY: number = 0;

    get startX(): number {
        return this._startX;
    }

    get startY(): number {
        return this._startY;
    }

    get gid(): number {
        return this.props.get(TMPropKey.Gid) as number;
    }

    get width(): number {
        return 1;
    }

    get height(): number {
        return 1;
    }

    override async draw() {
        if (this._root) {
            return;
        }
        const checker = this.checker;
        const prefab = await app.loader.loadPrefab(
            "resources/prefab/world-map/static/static-obj.lh"
        );
        const resName = this._tilemap.getTextureResName(this.gid);
        if (!resName) {
            console.error(`no texture for gidX: ${this.gridX} y: ${this.gridY}`);
            return;
        }
        const path = StringUtil.format("resources/texture/world-map/static/{0}.png", resName);
        const texture = await app.loader.loadTexture2D(path);

        if (!checker()) {
            return;
        }

        const texture_width = texture.width;
        const texture_height = texture.height;

        this._root = prefab.create() as Laya.Sprite3D;

        const sprite = this._root.getChildAt(0) as Laya.Sprite3D;
        TMUtil.setStaticBoardScaleAndOffset(sprite, texture_width, texture_height);

        this._startX = Math.floor(this.gridX);
        this._startY = Math.floor(this.gridY);

        const objPos = this._root.transform.position;
        objPos.x = this._startX + 0.5;
        objPos.y = 0;
        objPos.z = this._startY + 0.5;
        this._root.transform.position = objPos;

        const cameraTrans = this._tilemap.context.cameraNode.transform;
        sprite.transform.localRotationEulerX = cameraTrans.localRotationEulerX;
        sprite.transform.localRotationEulerY = cameraTrans.localRotationEulerY;

        let mat: Laya.Material | undefined;
        if (this._MaterialMap.has(path)) {
            mat = this._MaterialMap.get(path)!;
        } else {
            const matClass = await app.loader.loadMaterial(res.materials.TILE_STATIC_MATERIAL);
            if (!checker()) {
                return;
            }
            mat = matClass.clone() as Laya.Material;
            mat.setTexture("u_BaseTexture", texture);
            this._MaterialMap.set(path, mat);
        }

        const renderer = sprite.getComponent(Laya.MeshRenderer)!;
        // mat.materialRenderMode = Laya.MaterialRenderMode.RENDERMODE_TRANSPARENT;
        renderer.material = mat;

        this._root.name = this.startX + "_" + this.startY + " | " + resName;
        const addToLayer = this._tilemap.getRoot().getChildByName(this.layerName) as Laya.Sprite3D;
        this._root.layer = addToLayer.layer;
        sprite.layer = addToLayer.layer;
        addToLayer.addChild(this._root);
    }

    private _MaterialMap: Map<string, Laya.Material> = new Map<string, Laya.Material>();

    override erase() {
        this._root?.destroy();
        this._root = undefined;
        this._startX = 0;
        this._startY = 0;
    }
}

export class TMCloudElement extends TMElement {
    // private _board?: Laya.Sprite3D;
    private _quad?: Quad;

    private _drawing: boolean = false;

    private _resName: string = "";

    get gid(): number {
        return this.props.get(TMPropKey.Gid) as number;
    }

    private _resIndexKey: string = "";

    get resIndexKey(): string {
        if (this._resIndexKey) return this._resIndexKey;
        // 在编辑器里的名字
        const editorResName = this._tilemap.getTextureResName(this.gid);
        const resNameArr = editorResName.split("_");
        this._resIndexKey = resNameArr[resNameArr.length - 1];
        return this._resIndexKey;
    }

    override async draw() {
        if (this._quad || this._drawing) {
            return;
        }
        this._drawing = true;

        const checker = this.checker;
        const defaultRes = "img_cloud_block";
        const path = StringUtil.format("resources/texture/world-map/object/{0}.png", defaultRes);
        const texture = await app.loader.loadTexture(path);

        if (!checker()) {
            return;
        }

        this._quad = Pool.obtain(Quad);
        this._quad.drawPlane(this.gridX - 0.2, 0.2, this.gridY - 0.2, 1 + 0.4, 1 + 0.4, texture);
        this._tilemap.addQuad(
            this.layerName,
            this._quad,
            Laya.MaterialRenderMode.RENDERMODE_TRANSPARENT
        );

        this._drawing = false;
    }

    override erase() {
        // this._board?.destroy();
        // this._board = undefined;
        if (this._quad) {
            this._tilemap.removeQuad(this.layerName, this._quad);
            Pool.free(this._quad);
        }
        this._quad = undefined;
        this._resName = "";
        this._drawing = false;
        this._resIndexKey = "";
    }
}

export class TMObjectElement extends TMElement {
    private _board?: Laya.Sprite3D;
    private _startX: number = 0;
    private _startY: number = 0;
    private _resName: string = "";
    private _drawing: boolean = false;
    private _originPos: Laya.Vector3 = new Laya.Vector3();

    private _texture_width: number = 0;
    private _texture_height: number = 0;

    get startX(): number {
        return this._startX;
    }

    get startY(): number {
        return this._startY;
    }

    get width(): number {
        return 1;
    }

    get height(): number {
        return 1;
    }

    override async draw() {
        if (this._board || this._drawing) {
            return;
        }
        this._drawing = true;

        const checker = this.checker;
        const prefab = await app.loader.loadPrefab(
            "resources/prefab/world-map/object/object-obj.lh"
        );

        this._board = prefab.create() as Laya.Sprite3D;

        if (!checker()) {
            return;
        }

        const sprite = this._board.getChildAt(0) as Laya.Sprite3D;
        const defaultRes = this.props.get(TMPropKey.TextureKey) as string;
        // const textureCfg = app.service.table.textureCfg[defaultRes];

        const objPos = this._board.transform.position;
        objPos.x = this.placeX;
        objPos.y = 0;
        objPos.z = this.placeY;
        this._board.transform.position = objPos;

        const cameraTrans = this._tilemap.context.cameraNode.transform;
        sprite.transform.localRotationEulerX = cameraTrans.localRotationEulerX;
        sprite.transform.localRotationEulerY = cameraTrans.localRotationEulerY;

        await this.setTexture(this._resName || defaultRes, true);

        if (!checker()) {
            return;
        }

        TMUtil.setBoardScaleAndOffset(
            sprite,
            defaultRes,
            this._texture_width,
            this._texture_height
        );

        this._originPos.cloneFrom(sprite.transform.localPosition);

        this._startX = this.gridX;
        this._startY = this.gridY;

        this._board.name = this.startX + "_" + this.startY + " | " + defaultRes;
        this._tilemap.getRoot().getChildByName(this.layerName).addChild(this._board);

        this._drawing = false;
    }

    async setTexture(resName: string, force: boolean = false) {
        if (this._resName === resName && !force) {
            return;
        }
        this._resName = resName;

        const checker = this.checker;

        const path = StringUtil.format("resources/texture/world-map/object/{0}.png", resName);
        const texture = await app.loader.loadTexture2D(path);

        if (!checker()) {
            return;
        }

        this._texture_width = texture.width;
        this._texture_height = texture.height;

        if (!this._board) {
            return;
        }
        const sprite = this._board.getChildAt(0) as Laya.Sprite3D;
        const renderer = sprite.getComponent(Laya.MeshRenderer)!;

        const mat = new Laya.UnlitMaterial();
        mat.albedoTexture = texture;
        mat.materialRenderMode = Laya.MaterialRenderMode.RENDERMODE_TRANSPARENT;
        renderer.material = mat;
    }

    shake() {
        if (!this._board) {
            return;
        }
        const sprite = this._board.getChildAt(0) as Laya.Sprite3D;
        const spritePos = sprite.transform.localPosition;
        spritePos.x = this._originPos.x;
        spritePos.y = this._originPos.y;
        spritePos.z = this._originPos.z;
        sprite.transform.localPosition = spritePos;

        tween(sprite.transform)
            .to(0.05, {
                localPositionX: this._originPos.x + 0.03,
                localPositionZ: this._originPos.z - 0.03,
            })
            .to(0.05, {
                localPositionX: this._originPos.x - 0.03,
                localPositionZ: this._originPos.z + 0.03,
            })
            .to(0.05, {
                localPositionX: this._originPos.x + 0.02,
                localPositionZ: this._originPos.z - 0.02,
            })
            .to(0.05, {
                localPositionX: this._originPos.x - 0.02,
                localPositionZ: this._originPos.z + 0.02,
            })
            .to(0.05, {
                localPositionX: this._originPos.x + 0.01,
                localPositionZ: this._originPos.z - 0.01,
            })
            .to(0.05, {
                localPositionX: this._originPos.x - 0.01,
                localPositionZ: this._originPos.z + 0.01,
            })
            .to(0.05, {
                localPositionX: this._originPos.x,
                localPositionZ: this._originPos.z,
            })
            .start();
    }

    override erase() {
        this._board?.destroy();
        this._board = undefined;
        this._startX = 0;
        this._startY = 0;
        this._resName = "";
        this._drawing = false;
        this._originPos.toDefault();
    }
}

export abstract class TMDebugElement extends TMElement {
    private _debugQuads?: Quad[];

    override async draw() {
        if (!TMUtil.DEBUG_MODE || this._debugQuads?.length) {
            return;
        }

        const checker = this.checker;
        await app.loader.loadAtlas(res.battle.DEBUG_ALTAS);
        const tex = await app.loader.loadTexture(this.texture);

        if (!checker()) {
            return;
        }

        const x = this.gridX;
        const z = this.gridY;
        const [w, h] = this.size;

        this._debugQuads ||= [];

        const offsetY: number = BattleConf.GROUND_OFFSET.DEBUG_TITLE;
        for (let i = 0; i < w; i++) {
            for (let j = 0; j < h; j++) {
                const quad = Pool.obtain(Quad);
                quad.drawPlane(x + i, offsetY, z + j, 1, 1, tex);
                this._debugQuads.push(quad);
                this._tilemap.addQuad(
                    TMLayerName.Debug,
                    quad,
                    Laya.MaterialRenderMode.RENDERMODE_TRANSPARENT
                );
            }
        }
    }

    override erase() {
        if (this._debugQuads) {
            this._debugQuads.forEach((quad) => {
                this._tilemap.removeQuad(TMLayerName.Debug, quad);
            });
            Pool.free(this._debugQuads);
        }
    }

    protected get size(): [number, number] {
        return [1, 1];
    }

    protected abstract get texture(): string;
}

export class TMBuildingElement extends TMDebugElement {
    protected override get texture() {
        return res.battle.DEUBG_BUILDING;
    }

    protected override get size(): [number, number] {
        // let textureCfg = undefined;
        // const mode = this._tilemap.context.mode;
        // if (mode === TMMode.PVE) {
        //     const buildingRow = app.service.table.battleBuilding[this.id];
        //     textureCfg = app.service.table.textureCfg[buildingRow.texture_key];
        // } else if (mode === TMMode.PVP) {
        //     // TODO：先临时读 battle_building 表，后面需要改读 world_building 表
        //     const buildingRow = app.service.table.battleBuilding[this.id];
        //     textureCfg = app.service.table.textureCfg[buildingRow.texture_key];
        // }
        // if (textureCfg) {
        //     return [textureCfg.tile_w, textureCfg.tile_h];
        // } else {

        // }
        return [1, 1];
    }
}

export class TMMonsterElement extends TMDebugElement {
    protected override get texture() {
        return res.battle.DEUBG_MONSTER;
    }
}

export class TMNpcElement extends TMDebugElement {
    protected override get texture() {
        return res.battle.DEUBG_NPC;
    }
}

export class TMEventElement extends TMDebugElement {
    protected override get texture() {
        return res.battle.DEUBG_EVENT;
    }
}

export class TMBlockElement extends TMDebugElement {
    protected override get texture() {
        return res.battle.DEUBG_BLOCK;
    }
}
