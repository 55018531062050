const { regClass } = Laya;
import { ChestBoxVo } from "../../vo/ChestBoxVo";
import { ChestBoxProbabilityTipsBase } from "./ChestBoxProbabilityTips.generated";

@regClass('EZTsFMJuQKGqw95ynO9oPg')
export class ChestBoxProbabilityTips extends ChestBoxProbabilityTipsBase {
    public updateShow(vo: ChestBoxVo | undefined): void {
        if (vo) {
            this.visible = true;
            this.lab_tips.text = vo.probabilityTips;
        } else {
            this.visible = false;
        }
    }
}
