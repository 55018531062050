import { app } from "../../../app";
import { Service } from "../../../core/service";
import proto, { mainline } from "../../../def/auto/proto";
import { errcode, opcode } from "../../../def/auto/protocol";
import { MainLineProgressData } from "./MainLineProgressData";
import { PveMainLineData } from "./PveMainLineData";
import { PveTMWorldMinimap } from "./PveTMWorldMinimap";
import { BattleBuildingVo } from "./vo/BattleBuildingVo";
import { BattleBuildingVoMap } from "./vo/BattleBuildingVoMap";
import { BattleEventLo } from "./vo/BattleEventLo";
import { BattleEventLoMap } from "./vo/BattleEventLoMap";
import { BattleSpoilsLoMap } from "./vo/BattleSpoilsLoMap";
import { MonsterLoMap } from "./vo/MonsterLoMap";

export class PveService extends Service {
    minimap: PveTMWorldMinimap = new PveTMWorldMinimap();

    mainLineProgressData: MainLineProgressData = new MainLineProgressData();

    readonly mlData: PveMainLineData = new PveMainLineData();

    buildingVoMap!: BattleBuildingVoMap;

    readonly eventLoMap: BattleEventLoMap = new BattleEventLoMap();

    readonly monsterLoMap: MonsterLoMap = new MonsterLoMap();

    readonly spoilsLoMap: BattleSpoilsLoMap = new BattleSpoilsLoMap();

    override onCreate() {
        this.handle(opcode.mainline.s2c_save, this._onSave);
        this.handle(opcode.mainline.s2c_event, this._onEvent);
        this.handle(opcode.mainline.s2c_reborn, this._onReborn);
        this.handle(opcode.mainline.s2c_do_task, this._onToTask);
        this.handle(opcode.mainline.s2c_area_progress_draw, this._onAreaProgressDraw);
        this.handle(opcode.mainline.s2c_area_progress_reward, this._onAreaProgressReward);
        this.handle(opcode.mainline.s2c_upgrade_building, this._onUpgradeBuilding);
    }

    /** 获取指定传送点id是否已经开启 */
    public getTransferIdIsOpen(transferId: number): boolean {
        const buildings = app.service.pve.mlData.pveStateData.buildings;
        for (let i = 0; i < buildings.length; i++) {
            const building = buildings[i];
            if (building.level > 0) {
                const bdVo = this.buildingVoMap.get(building.tid)!;
                if (bdVo.areaTransferId === transferId) {
                    return true;
                }
            }
        }
        return false;
    }

    override onStartInit(): void {
        this.buildingVoMap = new BattleBuildingVoMap();
        const battleBuildingTable = app.service.table.battleBuilding;
        for (const key in battleBuildingTable) {
            const row = battleBuildingTable[key];
            const vo: BattleBuildingVo = new BattleBuildingVo(row);
            this.buildingVoMap.set(row.id, vo);
        }

        const battleEventTable = app.service.table.battleEvent;
        for (const key in battleEventTable) {
            const row = battleEventTable[key];
            const vo: BattleEventLo = new BattleEventLo(row);
            this.eventLoMap.set(row.id, vo);
        }

        this.spoilsLoMap.getAllLo();
    }

    override onDestroy() {}

    private _onUpgradeBuilding(response: mainline.s2c_upgrade_building): void {
        if (response.err !== errcode.OK) {
            console.warn("mainline.s2c_upgrade_building error: " + response.err);
            return;
        }
    }

    private _onAreaProgressReward(response: mainline.s2c_area_progress_reward): void {
        if (response.err !== errcode.OK) {
            console.warn("mainline.s2c_area_progress_reward error: " + response.err);
            return;
        }
        this.mainLineProgressData.areaProgressList = response.areaProgressList;
    }

    private _onAreaProgressDraw(
        response: mainline.s2c_area_progress_draw,
        request: mainline.c2s_area_progress_draw
    ): void {
        if (response.err !== errcode.OK) {
            console.warn("mainline.s2c_area_progress_draw error: " + response.err);
            return;
        }
        const list = this.mainLineProgressData.areaProgressList;
        for (let i = 0; i < list.length; i++) {
            const item = list[i];
            if (item.area === request.area) {
                item.awardedIndex = request.index;
                return;
            }
        }
        const newObj: proto.mainline.IAreaProgress = {
            area: request.area,
            awardedIndex: request.index,
        };
        list.push(newObj);
    }

    private _onToTask(response: mainline.s2c_do_task) {
        if (response.err !== errcode.OK) {
            console.warn("mainline.s2c_do_task error: " + response.err);
            return;
        }
    }

    private _onReborn(response: mainline.s2c_reborn) {
        if (response.err !== errcode.OK) {
            console.warn("mainline.s2c_reborn error: " + response.err);
            return;
        }
    }

    private _onEvent(response: mainline.s2c_event) {
        if (response.err !== errcode.OK) {
            console.warn("mainline.s2c_event error: " + response.err);
            return;
        }
    }

    private _onSave(response: mainline.s2c_save) {
        if (response.err !== errcode.OK) {
            console.warn("mainline.s2c_load error: " + response.err);
            // return;
        }
        // app.ui.toast("已储存当前状态");
    }

    private _onLoad(response: mainline.s2c_load, request: mainline.c2s_load) {
        if (response.err !== errcode.OK) {
            console.warn("mainline.s2c_load error: " + response.err);
            return;
        }
        if (!response.data) {
            console.warn("mainline.s2c_load data is null");
            return;
        }
        this.mlData.mainLineData = response.data;
        // app.event(Event.PVE_LOAD_RECORD_STATES_S2C, this.mainLineData);
    }

    async requestLoad() {
        const req = proto.mainline.c2s_load.create();
        const resp = await app.service.network.call(req, proto.mainline.s2c_load);
        this._onLoad(resp, req);
    }

    async requestDoTask(type: number, amount: number, argId: number = 0) {
        // const data: mainline.Ic2s_load = null;
        return app.service.network.call(
            proto.mainline.c2s_do_task.create({ type, amount, argId }),
            proto.mainline.s2c_do_task
        );
    }

    /** 请求领取区域探索度奖励 */
    async requestAreaProgressDraw(areaId: number, index: number) {
        return app.service.network.call(
            proto.mainline.c2s_area_progress_draw.create({ area: areaId, index: index }),
            proto.mainline.s2c_area_progress_draw
        );
    }

    async requestAreaProgressReward() {
        return app.service.network.call(
            proto.mainline.c2s_area_progress_reward.create(),
            proto.mainline.s2c_area_progress_reward
        );
    }

    async requestUpgradeBuilding(buildingId: number) {
        return app.service.network.call(
            proto.mainline.c2s_upgrade_building.create({ buildingId: buildingId }),
            proto.mainline.s2c_upgrade_building
        );
    }

    // ------------------------------------------------------------------------
    // rpc call
    // ------------------------------------------------------------------------

    /**
     * type 参考 MainlineConf.REBORN_COST
     */
    async requestReborn(type: number) {
        return app.service.network.call(
            proto.mainline.c2s_reborn.create({ type: type }),
            proto.mainline.s2c_reborn
        );
    }

    async requestSave(data: mainline.MainLineData) {
        this.mlData.mainLineData = data;
        return app.service.network.call(
            proto.mainline.c2s_save.create({ data: data }),
            proto.mainline.s2c_save
        );
    }

    async requestEvent(evnetId: number, amount: number = 1) {
        return app.service.network.call(
            proto.mainline.c2s_event.create({
                eventId: evnetId,
                amount: amount,
            }),
            proto.mainline.s2c_event
        );
    }
}
