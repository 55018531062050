import { app } from "../../../../../app";
import { Callback } from "../../../../../core/dispatcher";
import * as ecs from "../../../../../core/ecs";
import { Pool } from "../../../../../core/pool";
import proto from "../../../../../def/auto/proto";
import { opcode } from "../../../../../def/auto/protocol";
import { EntityAction, EntityType, TroopState } from "../../../../../def/auto/world";
import { pvpFormation } from "../../../../../def/formation";
import { SystemEvent } from "../../../../../misc/system-event";
import { AnimName } from "../../../base/Animator";
import { PvpContext } from "../../PvpContext";
import { PvpAnimationComponent } from "../components/PvpAnimationComponent";
import { PvpBattleComponent } from "../components/PvpBattleComponent";
import { PvpBoardComponent } from "../components/PvpBoardComponent";
import { PvpElementComponent } from "../components/PvpElementComponent";
import { PvpMovementComponent } from "../components/PvpMovementComponent";
import { PvpNaviArrowComponent } from "../components/PvpNaviArrowComponent";
import { PvpOwnerComponent } from "../components/PvpOwnerComponent";
import { PvpSiegeVehicleComponent } from "../components/PvpSiegeVehicleComponent";
import { PvpStateComponent } from "../components/PvpStateComponent";
import { PvpTransformComponent } from "../components/PvpTransformComponent";
import { PvpTroopComponent } from "../components/PvpTroopComponent";
import { PvpWagonComponent } from "../components/PvpWagonComponent";

export class PvpCommandSystem extends ecs.System {
    declare context: PvpContext;

    override onCreate() {
        this.handle(opcode.world.notify_actions, this._notifyActions);
        this.handle(opcode.world.notify_roles, this._notifyRoles);
        this.handle(opcode.world.notify_alliances, this._notifyAlliances);
    }

    private handle(op: number, callback: Callback) {
        this.context.$(app).on(op, callback, this);
    }

    private _notifyActions(notify: proto.world.notify_actions) {
        notify.actions.sort((a, b) => {
            if (a.addEntity?.entity && b.addEntity?.entity) {
                const entityA = a.addEntity!.entity as proto.world.Entity;
                const entityB = b.addEntity!.entity as proto.world.Entity;
                if (entityA.etype === entityB.etype) {
                    return entityA.eid - entityB.eid;
                } else {
                    return entityA.etype - entityB.etype;
                }
            } else if (a.addEntity) {
                return -1;
            } else if (b.addEntity) {
                return 1;
            } else {
                return 0;
            }
        });
        for (const cmd of notify.actions) {
            if (cmd.action === EntityAction.ADD_ENTITY) {
                this._addEntity(cmd.addEntity!.entity as proto.world.Entity);
            } else if (cmd.action === EntityAction.UPDATE_ENTITY) {
                this._updateEntity(cmd.updateEntity?.entity as proto.world.Entity);
            } else if (cmd.action === EntityAction.DEL_ENTITY) {
                this._delEntity(cmd.delEntity!.eid as number);
            } else if (cmd.action === EntityAction.MOVE) {
                this._moveAction(cmd.move as proto.world.MoveAction);
            } else {
                console.warn(`unhandle action: ${cmd.action}`);
            }
        }
    }

    private _notifyRoles(notify: proto.world.notify_roles) {
        console.log("notify roles", notify);
    }

    private _notifyAlliances(notify: proto.world.notify_alliances) {}

    private _addEntity(cmd: proto.world.Entity) {
        if (this.ecs.getEntity(cmd.eid)) {
            console.error(`duplicate add entity: eid=${cmd.eid} etype=${cmd.etype}`);
            return;
        }

        this.ecs.createEntity(cmd.eid, cmd.etype);
        this._updateEntity(cmd);
    }

    private _updateEntity(cmd: proto.world.Entity) {
        const entity = this._findEntity(cmd.eid, "update entity");
        if (!entity) {
            return;
        }

        // NOTE: 注意：cmd里字段的执行顺序有可能会影响后续compnent的添加顺序
        if (entity.etype === EntityType.TROOP) {
            this._tryUpdateCmdElement(entity, cmd);
            this._tryUpdateCmdState(entity, cmd);
            this._tryUpdateCmdPos(entity, cmd);
            this._tryUpdateCmdOwner(entity, cmd);
            this._tryUpdateCmdTroop(entity, cmd);
            this._tryUpdateCmdMove(entity, cmd);

            app.event(SystemEvent.PVP.UPDATE_TROOP_ENTITY, entity.eid);
        } else if (entity.etype === EntityType.CITY) {
            this._tryUpdateCmdElement(entity, cmd);
            this._tryUpdateCmdPos(entity, cmd);
            this._tryUpdateCmdOwner(entity, cmd);
            const board = entity.addComponent(PvpBoardComponent);
            board.textureKey = "map_biulding_castle07";
        } else if (entity.etype === EntityType.BATTLE) {
            this._tryUpdateCmdElement(entity, cmd);
            this._tryUpdateCmdPos(entity, cmd);
            this._tryUpdateCmdBattle(entity, cmd);
        } else if (entity.etype === EntityType.MONSTER) {
            this._tryUpdateCmdElement(entity, cmd);
            this._tryUpdateCmdState(entity, cmd);
            this._tryUpdateCmdPos(entity, cmd);
            this._tryUpdateCmdOwner(entity, cmd);
            this._tryUpdateCmdTroop(entity, cmd);
            this._tryUpdateCmdMove(entity, cmd);

            app.event(SystemEvent.PVP.UPDATE_TROOP_ENTITY, entity.eid);
        } else if (entity.etype === EntityType.MINE) {
            this._tryUpdateCmdElement(entity, cmd);
            this._tryUpdateCmdPos(entity, cmd);
            const board = entity.addComponent(PvpBoardComponent);
            board.textureKey = "map_gather_iron1_1";
        } else if (entity.etype === EntityType.WAGON) {
            this._tryUpdateCmdElement(entity, cmd);
            this._tryUpdateCmdPos(entity, cmd);
            this._tryUpdateCmdOwner(entity, cmd);
            this._tryUpdateCmdWagon(entity, cmd);
            this._tryUpdateCmdMove(entity, cmd);
            this._tryUpdateCmdState(entity, cmd);
        } else if (entity.etype === EntityType.SIEGE_ENGINE) {
            this._tryUpdateCmdElement(entity, cmd);
            this._tryUpdateCmdPos(entity, cmd);
            this._tryUpdateCmdOwner(entity, cmd);
            this._tryUpdateCmdMove(entity, cmd);
            this._tryUpdateCmdSiegeVehicle(entity, cmd);
        } else {
            console.warn(`unhandle entity type: ${entity.etype}`);
        }
    }

    private _delEntity(eid: number) {
        this.ecs.removeEntity(eid);
    }

    private _moveAction(cmd: proto.world.MoveAction) {
        const element = this._findElement(cmd.eid, "move");
        if (element && cmd.path.length > 1) {
            const transform = element.transform;
            const movement = element.addComponent(PvpMovementComponent);
            transform.position.x = cmd.curPos?.x ?? 0;
            transform.position.z = cmd.curPos?.y ?? 0;
            transform.flag |= PvpTransformComponent.POSITION;
            movement.speed = cmd.speed;
            movement.startTime = cmd.startMs / 1000;
            Pool.free(movement.paths);
            cmd.path.forEach((p) => {
                const pos = Pool.obtain(Laya.Vector3);
                pos.set(p.x ?? 0, 0, p.y ?? 0);
                movement.paths.push(pos);
            });
            movement.index = 0;
            movement.flag |= PvpMovementComponent.UPDATE;

            if (movement.paths.length > 0) {
                // 添加目标特效到目标位置
                // element.removeComponent(PvpMoveTargetVFXComponent);
                // const moveTargetComp = element.addComponent(PvpMoveTargetVFXComponent);
                // moveTargetComp.res = res.VFX_PVP_MOVE_TARGET;
                // moveTargetComp.pos.cloneFrom(movement.paths.at(-1)!);
            }

            const naviArrow = element.addComponent(PvpNaviArrowComponent);
            naviArrow.start.cloneFrom(movement.paths[0]);
            naviArrow.dest.cloneFrom(movement.paths.at(-1)!);

            if (element.etype === EntityType.TROOP || element.etype === EntityType.MONSTER) {
                const stateComp = movement.getComponent(PvpStateComponent);
                if (stateComp && stateComp.state !== TroopState.MOVE) {
                    app.event(SystemEvent.PVP.UPDATE_TROOP_ANIM, [cmd.eid, AnimName.MOVE]);
                }
            }
        }
    }

    private _findElement(eid: number, usage: string) {
        const element = this.ecs.getComponent(eid, PvpElementComponent);
        if (!element) {
            console.warn(`not found entity in '${usage}': ${eid}`);
        }
        return element;
    }

    private _findEntity(eid: number, usage: string) {
        const element = this.ecs.getEntity(eid);
        if (!element) {
            console.warn(`not found entity in '${usage}': ${eid}`);
        }
        return element;
    }

    private _tryUpdateCmdElement(entity: ecs.Entity, cmd: proto.world.Entity) {
        const element = entity.addComponent(PvpElementComponent);
        // TODO: 使用配置
        element.tableId = 10001;
    }

    private _tryUpdateCmdPos(entity: ecs.Entity, cmd: proto.world.Entity) {
        const data = cmd.pos as proto.world.Position;
        if (!data) {
            return;
        }
        const transform = entity.addComponent(PvpTransformComponent);
        transform.position.x = data.x;
        transform.position.z = data.y;
        transform.flag |= PvpTransformComponent.POSITION;
    }

    private _tryUpdateCmdOwner(entity: ecs.Entity, cmd: proto.world.Entity) {
        const data = cmd.owner as proto.world.OwnerComponent;
        if (!data) {
            return;
        }
        const owner = entity.addComponent(PvpOwnerComponent);
        owner.aid = data.aid;
        owner.rid = data.rid;
        owner.isRobot = data.isRobot;
        owner.roleName = data.roleName;
        owner.allianceName = data.allianceName;
        owner.homeEid = data.homeEid;
        owner.insideEid = data.insideEid;
    }

    private _tryUpdateCmdTroop(entity: ecs.Entity, cmd: proto.world.Entity) {
        const data = cmd.troop as proto.world.TroopComponent;
        if (!data) {
            return;
        }
        const troop = entity.addComponent(PvpTroopComponent);
        troop.formation = pvpFormation;
        troop.heroId = data.heroId;
        troop.battleEid = data.battleEid;
        troop.maxHp = data.maxHp;
        troop.hp = data.hp;
    }

    private _tryUpdateCmdMove(entity: ecs.Entity, cmd: proto.world.Entity) {
        const data = cmd.move as proto.world.MoveComponent;
        if (!data) {
            return;
        }

        const transform = entity.addComponent(PvpTransformComponent);
        this._moveAction(
            proto.world.MoveAction.create({
                eid: entity.eid,
                path: data.path,
                speed: data.speed,
                curPos: { x: transform.position.x, y: transform.position.z },
                startMs: data.startMs,
            })
        );
    }

    private _tryUpdateCmdState(entity: ecs.Entity, cmd: proto.world.Entity) {
        const data = cmd.state as proto.world.StateComponent;
        if (!data) {
            return;
        }
        const state = entity.addComponent(PvpStateComponent);
        state.state = data.state;
        state.cmd = data.cmd;
        console.log(cmd.eid, cmd.etype, state.state, cmd.state);
    }

    private _tryUpdateCmdBattle(entity: ecs.Entity, cmd: proto.world.Entity) {
        const data = cmd.battle as proto.world.BattleComponent;
        if (!data) {
            return;
        }
        if (data.fighterEid1 && data.fighterEid2) {
            const battle = entity.addComponent(PvpBattleComponent);
            battle.sceneUid = data.sceneUid;
            battle.fighterEid1 = data.fighterEid1;
            battle.fighterEid2 = data.fighterEid2;
            battle.startTime = data.startMs / 1000;
            this._startBattle(battle);
        } else {
            console.warn(
                `battle component error:${entity.eid}, ${data.fighterEid1}, ${data.fighterEid2}`
            );
        }
    }

    private _tryUpdateCmdWagon(entity: ecs.Entity, cmd: proto.world.Entity) {
        const data = cmd.wagon as proto.world.WagonComponent;
        if (!data) {
            return;
        }
        const wagon = entity.addComponent(PvpWagonComponent);
        wagon.id = data.id;

        const animation = entity.addComponent(PvpAnimationComponent);
        animation.res = app.service.table.worldEntity.models[29000].res;
    }

    private _tryUpdateCmdSiegeVehicle(entity: ecs.Entity, cmd: proto.world.Entity) {
        entity.addComponent(PvpSiegeVehicleComponent);

        const animation = entity.addComponent(PvpAnimationComponent);
        // TODO: 暂时用资源车代替
        animation.res = app.service.table.worldEntity.models[29000].res;
        console.log("siege vehicle:", entity.getComponent(PvpTransformComponent)?.position);
    }

    private _startBattle(battle: PvpBattleComponent): boolean {
        const element1 = this._findElement(battle.fighterEid1, "start battle");
        const element2 = this._findElement(battle.fighterEid2, "start battle");
        if (element1 && element2) {
            if (element1.etype === EntityType.SIEGE_ENGINE) {
                console.log("siege engine");
            } else {
                const battlePosition = battle.getComponent(PvpTransformComponent)!.position;
                const fighter1 = element1.getComponent(PvpTransformComponent)!;
                const fighter2 = element2.getComponent(PvpTransformComponent)!;
                fighter1.position.cloneFrom(battlePosition);
                fighter1.rotation = 135; // 左边
                fighter1.flag |= PvpTransformComponent.ROTATION | PvpTransformComponent.POSITION;
                fighter2.position.cloneFrom(battlePosition);
                fighter2.rotation = -45; // 右边
                fighter2.flag |= PvpTransformComponent.ROTATION | PvpTransformComponent.POSITION;
            }

            return true;
        } else {
            return false;
        }
    }
}
