/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIList } from "../../../core/ui/UIList";
import { BaseBigWindowBg } from "../../common/ui-runtime/dialog/BaseBigWindowBg";
import { SkillMapUI } from "./SkillMapUI";
import { SkillOwnListUI } from "./SkillOwnListUI";

/**
 * resources/prefab/skill/UISkillMain.lh
 */
export class UISkillMainBase extends Laya.Dialog {
    public bgWin!: BaseBigWindowBg;
    public btn_close!: Laya.Button;
    public viewStack!: Laya.ViewStack;
    public item0!: SkillOwnListUI;
    public item1!: SkillMapUI;
    public imgCost!: Laya.Image;
    public btnSel!: Laya.Button;
    public labCount!: Laya.Label;
    public boxSwitch!: Laya.Box;
    public listSwitch!: UIList;
}
