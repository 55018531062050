import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";

type Input = [unknown[]];

type NodeArgs = {
    key: number;
};

export class GetInputValueByKey extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const [inputs] = env.input as Input;
        if (typeof inputs !== "object" || inputs === null) {
            return "failure";
        }
        const key = (node.args as NodeArgs).key;
        if (!inputs.hasOwnProperty(key)) {
            return "failure";
        }

        env.output.push(inputs[key]);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "GetInputValueByKey",
            type: "Action",
            desc: "返回输入对象的某个key对应的Value",
            args: [{ name: "key", type: "string", desc: "索引Key" }],
            input: ["输入对象"],
            output: ["输出值"],
            doc: `
                + 返回输入对象的某个key对应的Value!, 输入数据必须是对象，否则返回失败
                + 索引key不存在也会返回失败
            `,
        };
    }
}
