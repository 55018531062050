import { app } from "../../../app";
import { assert } from "../../../core/dispatcher";
import { IVector3Like } from "../../../core/laya";
import { BattleAid, BattleConf, BattleEntityType } from "../../../def/auto/battle";
import { TroopStation } from "../../../def/auto/troop";
import { TableUtil } from "../../table/TableUtil";
import { ILaunchBulletArgs, PveDef } from "./PveDefs";
import { PveServer } from "./PveServer";
import { PveSvrAiComponent } from "./ecs/components/PveSvrAiComponent";
import { PveSvrBuffComponent } from "./ecs/components/PveSvrBuffComponent";
import { PveSvrBulletComponent } from "./ecs/components/PveSvrBulletCompoment";
import { PveSvrCreatureComponent } from "./ecs/components/PveSvrCreatureComponent";
import { ElementTag, PveSvrElementComponent } from "./ecs/components/PveSvrElementComponent";
import { PveSvrMovementComponent } from "./ecs/components/PveSvrMovementComponent";
import { PveSvrNaviArrowComponent } from "./ecs/components/PveSvrNaviArrowComponent";
import { PveSvrOffsetComponent } from "./ecs/components/PveSvrOffsetComponent";
import { PveSvrSkillLauncherComponent, Skill } from "./ecs/components/PveSvrSkillLauncherComponent";
import { PveSvrSoldierComponent } from "./ecs/components/PveSvrSoldierComponent";
import { PveSvrSoldierGroupComponent } from "./ecs/components/PveSvrSoldierGroupComponent";
import { PveSvrTransformComponent } from "./ecs/components/PveSvrTransformComponent";
import { PveSvrTroopComponent } from "./ecs/components/PveSvrTroopComponent";
import { PveSvrTruckComponent } from "./ecs/components/PveSvrTruckComponent";
import { PveSvrSpoilsStateData } from "./ecs/data/state-data/PveSvrSpoilsStateData";
import { PveSvrStateSystem } from "./ecs/systems/PveSvrStateSystem";

export class PveSvrBattle {
    static launchBullet(
        ctx: PveServer,
        skill: Skill,
        bulletEntity: number,
        args: ILaunchBulletArgs
    ) {
        const entity = ctx.ecs.createEntity(ctx.obtainEid(), BattleEntityType.BULLET);

        const element = entity.addComponent(PveSvrElementComponent);
        element.data = app.service.table.battleEntity[bulletEntity];
        element.tid = skill.data.id;

        const transform = entity.addComponent(PveSvrTransformComponent);
        transform.position.cloneFrom(skill.owner.transform.position);

        const mvComp = entity.addComponent(PveSvrMovementComponent);
        mvComp.shouldCollision = false; //子弹不需要处理碰撞

        const bullet = entity.addComponent(PveSvrBulletComponent);
        bullet.skill = skill;
        bullet.bulletType = args.type;
        bullet.speed = args.speed ?? 0;
        bullet.h = args.h ?? 0;
        bullet.follow = args.follow ?? false;
        bullet.followEid = args.followEid;

        if (element.data.pve_ai) {
            bullet.btree = `resources/data/btree/${element.data.pve_ai}.json`;
        }
        ctx.sender.createElement({
            eid: element.eid,
            etype: element.etype,
            key: element.key,
            aid: element.aid,
            teid: element.data.id,
            tid: element.tid,
            position: transform.position,
            rotation: transform.rotation,
            bulletArgs: args,
            level: -1,
        });
    }

    static heal(ctx: PveServer, skill: Skill, target: PveSvrCreatureComponent, ratio: number = 1) {
        if (target.isDie) {
            return;
        }

        const isCrit = Math.ceil(Math.random() * 10000) % 5 === 0;
        const subHp = isCrit ? 25 : 10;
        const healHp = target.maxHp >= target.hp + subHp ? subHp : target.maxHp - target.hp;
        target.hp = target.hp + healHp;

        ctx.sender.updateHp(target.eid, {
            hp: target.hp,
            maxHp: target.maxHp,
            subHp: healHp,
            isCrit: isCrit,
        });
    }

    /** 检测单位是否处于脱战战斗中
     *  没有攻击目标，而且N秒内没被攻击到，就算脱战
     */
    static checkIsOutOfFight(ctx: PveServer, eid: number): boolean {
        const creatureComp = ctx.ecs.getComponent(eid, PveSvrCreatureComponent);
        assert(!!creatureComp, `creatureComp is null, ${eid}`);
        return creatureComp.isOutOfFight;
    }

    //#region init troop and creative

    // 创建部队
    static createTroopEntity(ctx: PveServer, isSelf: boolean): PveSvrTroopComponent {
        const entity = ctx.ecs.createEntity(ctx.obtainEid(), BattleEntityType.TROOP);
        const troopComp = entity.addComponent(PveSvrTroopComponent);
        troopComp.isSelf = isSelf;
        troopComp.formation = app.service.table.formation.f1; // todo：这些信息应该根据参数来决定

        troopComp.addComponent(PveSvrElementComponent)!;
        troopComp.addComponent(PveSvrTransformComponent)!;

        return troopComp;
    }

    // 创建主角应该由创建部队的时候创建
    public static initHero(pveServer: PveServer): PveSvrElementComponent {
        // 创建主角
        const entity = pveServer.ecs.createEntity(pveServer.obtainEid(), BattleEntityType.HERO);

        const element = entity.addComponent(PveSvrElementComponent);
        const creatureComp = entity.addComponent(PveSvrCreatureComponent);
        entity.addComponent(PveSvrBuffComponent);
        const pveStateData = app.service.pve.mlData.pveStateData;
        const heroData = pveStateData.hero;
        if (heroData.tid === 0) {
            heroData.tid = 1; //默认主角tid
        }
        const heroLo = app.service.hero.heroLoMap.get(heroData.tid);
        creatureComp.lv = heroData.level;

        const configMaxHp = heroLo.getConfHp(heroData.level);
        creatureComp.hp = heroData.inited ? heroData.hp : configMaxHp;
        creatureComp.maxHp = heroData.inited ? heroData.maxHp : configMaxHp;

        creatureComp.atk = heroLo.getConfAtk(heroData.level);
        creatureComp.defence = heroLo.getConfDefence(heroData.level);

        element.tag = ElementTag.LEADER | ElementTag.PLAYER;
        element.tid = heroData.tid;
        element.aid = BattleAid.SELF;
        element.ownerRid = app.service.user.rid;
        element.data = heroLo.battleEntityRow;

        const transform = entity.addComponent(PveSvrTransformComponent);
        PveSvrStateSystem.setHeroPositionAndRotation(pveServer, transform);

        entity.addComponent(PveSvrMovementComponent);
        entity.addComponent(PveSvrNaviArrowComponent);

        const ai = entity.addComponent(PveSvrAiComponent);
        ai.btree = `resources/data/btree/${element.data.pve_ai}.json`;
        ai.active = false;

        const launcher = entity.addComponent(PveSvrSkillLauncherComponent);
        pveServer.initSkill(element, launcher, false, 1, heroLo.atk1);
        pveServer.initSkill(element, launcher, false, 2, heroLo.atk2);

        pveServer.initSkill(element, launcher, true, 1, heroLo.skill1);
        pveServer.initSkill(element, launcher, true, 2, heroLo.skill2);
        pveServer.initSkill(element, launcher, true, 3, heroLo.skill3);
        pveServer.initSkill(element, launcher, true, 4, heroLo.skill4);

        pveServer.sender.createElement({
            eid: element.eid,
            etype: element.etype,
            key: element.key,
            aid: element.aid,
            teid: element.data.id,
            tid: element.tid,
            hp: creatureComp.hp,
            maxHp: creatureComp.maxHp,
            position: transform.position,
            rotation: transform.rotation,
            level: -1,
        });

        return element;
    }

    public static initSoliderGroups(pveServer: PveServer, hero: PveSvrElementComponent): void {
        const pveStateData = app.service.pve.mlData.pveStateData;
        const soldierGroups = pveStateData.soldierGroups;
        const heroData = pveStateData.hero;
        if (soldierGroups.length === 0 || heroData.unlockSodiderIndexes.length === 0) {
            return; // 没有士兵组
        }
        soldierGroups.forEach((g) => {
            g.soldiers.forEach((soldier) => {
                if (
                    !heroData.isUnlockTroop &&
                    heroData.unlockSodiderIndexes.indexOf(soldier.idx) < 0
                ) {
                    //没解锁
                    return;
                }

                if (soldier.maxHp && soldier.hp) {
                    pveServer.addSoldierWith(
                        hero,
                        soldier.idx,
                        g.tid,
                        soldier.pos.x,
                        soldier.pos.y,
                        soldier.hp
                    );
                }
            });
        });
    }

    private static addSoldierGroup(
        pveServer: PveServer,
        troopEid: number,
        tid: number,
        station: TroopStation,
        px: number,
        pz: number
    ): PveSvrSoldierGroupComponent {
        const heroLo = app.service.hero.heroLoMap.get(tid);

        const soldierGroupEntity = pveServer.ecs.createEntity(
            pveServer.obtainEid(),
            BattleEntityType.SOLDIER_GROUP
        );
        const groupEle = soldierGroupEntity.addComponent(PveSvrElementComponent);
        groupEle.troopEid = pveServer.troopEid;
        groupEle.data = heroLo.battleEntityRow;
        groupEle.aid = BattleAid.SELF;
        groupEle.tid = tid;

        const transformComp = soldierGroupEntity.addComponent(PveSvrTransformComponent);
        transformComp.position.set(px, 0, pz);

        const soldierGroupComp = soldierGroupEntity.addComponent(PveSvrSoldierGroupComponent);
        soldierGroupComp.station = station;
        const launcher = soldierGroupEntity.addComponent(PveSvrSkillLauncherComponent);
        pveServer.initSkill(groupEle, launcher, true, 1, heroLo.skill1);
        pveServer.initSkill(groupEle, launcher, true, 2, heroLo.skill2);
        pveServer.initSkill(groupEle, launcher, true, 3, heroLo.skill3);
        pveServer.initSkill(groupEle, launcher, true, 4, heroLo.skill4);

        const ai = soldierGroupEntity.addComponent(PveSvrAiComponent);
        ai.btree = `resources/data/btree/pve/soldier_group.json`; //固定值，就不需要从表里读取了

        return soldierGroupComp;
    }

    /**
     *  添加一名士兵
     * hero 跟随的主角
     * idx 0~14 十五个士兵位置
     * tid 对应 soldider 表的id
     * hp 当前血量
     */
    static addSoldierWith(
        pveServer: PveServer,
        heroEle: PveSvrElementComponent,
        idx: number,
        tid: number,
        px: number,
        pz: number,
        hp?: number
    ): PveSvrSoldierComponent | undefined {
        const heroLo = app.service.hero.heroLoMap.get(tid);

        const troopComp = pveServer.ecs.getComponent(pveServer.troopEid, PveSvrTroopComponent)!;
        const station = 1 + Math.floor(idx / BattleConf.PVE.SOLDIER_ONE_ROW_COUNT);

        let soldierGroupComp: PveSvrSoldierGroupComponent | undefined;
        for (
            let i = (station - 1) * BattleConf.PVE.SOLDIER_ONE_ROW_COUNT,
                max = station * BattleConf.PVE.SOLDIER_ONE_ROW_COUNT;
            i < max;
            i++
        ) {
            const eid = troopComp.members[i];
            if (eid) {
                const soldier = pveServer.ecs.getComponent(eid, PveSvrSoldierComponent)!;
                const groupComp = pveServer.ecs.getComponent(
                    soldier.groupEid,
                    PveSvrSoldierGroupComponent
                )!;
                soldierGroupComp = groupComp;
                break;
            }
        }

        if (!soldierGroupComp) {
            soldierGroupComp = PveSvrBattle.addSoldierGroup(
                pveServer,
                pveServer.troopEid,
                tid,
                station,
                px,
                pz
            );
        } else if (soldierGroupComp.data.id !== tid) {
            console.error("士兵组不匹配", soldierGroupComp.data.id, tid);
            return undefined;
        } else if (troopComp.members[idx]) {
            console.error("已经有士兵在这个位置了", idx);
            return undefined;
        }

        const entity = pveServer.ecs.createEntity(pveServer.obtainEid(), BattleEntityType.SOLDIER);
        const element = entity.addComponent(PveSvrElementComponent);
        element.tag = ElementTag.MEMBER | (heroEle.tag & ElementTag.PLAYER);
        element.tid = tid;
        element.ownerRid = heroEle.ownerRid;
        element.aid = BattleAid.SELF;
        element.troopEid = pveServer.troopEid;

        const creatureComp = entity.addComponent(PveSvrCreatureComponent);
        entity.addComponent(PveSvrBuffComponent);

        const configMaxHp = heroLo.getConfHp();
        creatureComp.maxHp = configMaxHp;
        creatureComp.hp = hp ?? configMaxHp;
        creatureComp.atk = heroLo.getConfAtk();
        creatureComp.defence = heroLo.getConfDefence();

        element.data = heroLo.battleEntityRow;

        const soldierComp = entity.addComponent(PveSvrSoldierComponent);
        entity.addComponent(PveSvrMovementComponent);
        soldierComp.groupEid = soldierGroupComp.eid;

        const offsetCom = entity.addComponent(PveSvrOffsetComponent);
        offsetCom.index = idx;
        offsetCom.offset = troopComp.formation.soldiers[idx];

        const transform = entity.addComponent(PveSvrTransformComponent);
        transform.position.x = px;
        transform.position.z = pz;

        const launcher = entity.addComponent(PveSvrSkillLauncherComponent);
        pveServer.initSkill(element, launcher, false, 1, heroLo.atk1); //士兵本身只会普攻了，放技能是由士兵组来放的
        pveServer.initSkill(element, launcher, false, 1, heroLo.atk2); //士兵本身只会普攻了，放技能是由士兵组来放的

        const ai = entity.addComponent(PveSvrAiComponent);
        ai.btree = `resources/data/btree/${element.data.pve_ai}.json`;

        troopComp.members[idx] = entity.eid;

        pveServer.sender.createElement({
            eid: element.eid,
            etype: element.etype,
            key: element.key,
            aid: element.aid,
            teid: element.data.id,
            tid: element.tid,
            hp: creatureComp.hp,
            maxHp: creatureComp.maxHp,
            position: transform.position,
            rotation: transform.rotation,
            level: -1,
        });
        return soldierComp;
    }

    public static initTrucks(pveServer: PveServer, hero: PveSvrElementComponent): void {
        const troop = pveServer.ecs.getComponent(pveServer.troopEid, PveSvrTroopComponent)!;
        troop.formation.trucks.forEach((value, idx) => {
            const entity = pveServer.ecs.createEntity(
                pveServer.obtainEid(),
                BattleEntityType.TRUCK
            );

            const element = entity.addComponent(PveSvrElementComponent);
            element.tag = ElementTag.TRUCK | ElementTag.PLAYER;
            element.aid = BattleAid.SELF;
            element.data = app.service.table.battleEntity[29000];
            element.ownerRid = app.service.user.rid;
            element.troopEid = pveServer.troopEid;

            const truck = entity.addComponent(PveSvrTruckComponent);
            truck.collectType = [
                BattleEntityType.WOOD,
                BattleEntityType.FOOD,
                BattleEntityType.STONE,
            ][idx];
            troop.truckEids[truck.collectType] = truck.eid;

            const offsetCom = entity.addComponent(PveSvrOffsetComponent);
            offsetCom.index = PveDef.SOLDIER_COUNT + idx;
            offsetCom.offset = value;

            const transform = entity.addComponent(PveSvrTransformComponent);
            PveSvrStateSystem.setTruckPosAndAmount(
                pveServer,
                truck.collectType,
                value,
                transform,
                hero.transform,
                truck
            );

            const ai = entity.addComponent(PveSvrAiComponent);
            ai.btree = `resources/data/btree/${element.data.pve_ai}.json`;

            entity.addComponent(PveSvrMovementComponent);

            pveServer.sender.createElement({
                eid: element.eid,
                etype: element.etype,
                key: element.key,
                aid: element.aid,
                teid: element.data.id,
                tid: element.tid,
                hp: -1,
                maxHp: -1,
                position: transform.position,
                rotation: transform.rotation,
                collectCnt: truck.collectCnt,
                collectType: truck.collectType,
                followEid: hero.eid,
                level: -1,
            });
        });
    }

    public static initSpoils(pveServer: PveServer): void {
        const pveStateData = app.service.pve.mlData.pveStateData;
        const spoils: PveSvrSpoilsStateData[] = pveStateData.spoils;
        const table = app.service.table;
        const battleEntityRow = TableUtil.getRow(table.battleEntity, {
            etype: BattleEntityType.SPOILS,
        })!;

        for (let i = 0; i < spoils.length; i++) {
            const spoil = spoils[i];
            const spoilEntity = pveServer.ecs.createEntity(
                pveServer.obtainEid(),
                BattleEntityType.SPOILS
            );
            const element = spoilEntity.addComponent(PveSvrElementComponent);
            // element.addComponent(PveSvrSpolisComponent);

            element.tag = ElementTag.SPOILS;
            element.tid = spoil.tid; // 对应 spoils 表的id
            element.aid = BattleAid.NEUTRAL;
            element.data = battleEntityRow;
            const transform = spoilEntity.addComponent(PveSvrTransformComponent);
            transform.position.x = spoil.pos.x;
            transform.position.y = 0;
            transform.position.z = spoil.pos.y;
            pveServer.sender.createElement({
                eid: element.eid,
                etype: element.etype,
                key: element.key,
                aid: element.aid,
                teid: element.data.id,
                tid: element.tid,
                hp: -1,
                maxHp: -1,
                position: transform.position,
                rotation: transform.rotation,
                level: -1,
            });
        }
    }

    // note: 复活怪物以troop为单位，不会单独复活troop里的某个怪物
    public static addMonsterTroop(
        pveServer: PveServer,
        key: number,
        tid: number,
        pos: Laya.Vector3
    ) {
        if (pveServer.elements.has(key)) {
            // 调用整个接口时不应该有重复的key，报告个错误
            console.warn("addMonsterTroop key is already exist", key, pveServer.elements);
            return;
        }
        const table = app.service.table;
        const monsterTroopRow = TableUtil.getRow(table.monster.troop, { id: tid });
        if (!monsterTroopRow) {
            console.error("addMonsterTroop:: monster troop row is null", tid);
            return;
        }

        const tempPos = new Laya.Vector3();
        const pveStateData = app.service.pve.mlData.pveStateData;
        const monsterTroopStateData = pveStateData.getOrCreateMonsterTroopByKey(key, tid);
        if (monsterTroopStateData.isDeath) {
            if (!monsterTroopStateData.isShouldRelive) {
                // 死亡的不添加
                monsterTroopStateData.tid = tid;
                monsterTroopStateData.inView = true;
                return;
            } else {
                // 复活
                monsterTroopStateData.deathTime = 0;
            }
        }

        const troopComp = PveSvrBattle.createTroopEntity(pveServer, false);
        const element = troopComp.getComponent(PveSvrElementComponent)!;
        element.key = key;
        element.tid = tid;
        element.spawnpoint.cloneFrom(pos);

        const formation = monsterTroopRow.formation
            ? table.formation[monsterTroopRow.formation as keyof typeof table.formation]
            : null;

        // 添加leader
        const leaderStateData = monsterTroopStateData.monsters.find((v) => v.idx === 0);
        // rdLeaderData.hp === 0 意味着这个leader已经死亡，没有记录数据或者hp不为0的情况下才添加
        if (!leaderStateData || leaderStateData.hp) {
            const leaderTid = monsterTroopRow.leader;
            const leaderOffset: Readonly<IVector3Like> = formation
                ? formation.hero
                : Laya.Vector3.ZERO;
            // position 首先读取存储的数据，如果没有，就使用默认的
            tempPos.x = leaderStateData ? leaderStateData.pos.x : pos.x;
            tempPos.y = 0;
            tempPos.z = leaderStateData ? leaderStateData.pos.y : pos.z;

            const leaderEle = PveSvrBattle.addMonster(
                key,
                pveServer,
                leaderTid,
                true,
                tempPos,
                leaderStateData?.hp ?? 0,
                0,
                new Laya.Vector3(leaderOffset.x, leaderOffset.y, leaderOffset.z),
                troopComp.eid,
                monsterTroopRow.isBoss === 1
            );

            if (leaderEle) {
                const transform = leaderEle.getComponent(PveSvrTransformComponent)!;
                const creature = leaderEle.getComponent(PveSvrCreatureComponent)!;
                monsterTroopStateData.setMonsterData(
                    0,
                    creature.hp,
                    creature.maxHp,
                    transform.position.x,
                    transform.position.z,
                    transform.rotation
                );
            }
        }

        // 添加小怪
        if (formation) {
            const tryAddMemberMonster = (__tid: number | undefined, __station: TroopStation) => {
                if (!__tid) {
                    return;
                }
                const startIndex = [undefined, 0, 4, 8][__station]!;
                for (let i = startIndex; i < startIndex + 4; i++) {
                    const offset = formation.soldiers.find((v) => v.index === i);

                    if (offset) {
                        const idx = i + 1;
                        const rdMonsterData = monsterTroopStateData.monsters.find(
                            (v) => v.idx === idx
                        );
                        if (rdMonsterData && !rdMonsterData.hp) {
                            // 死亡的不添加
                            continue;
                        }

                        tempPos.x = rdMonsterData ? rdMonsterData.pos.x : pos.x + offset.x;
                        tempPos.y = 0;
                        tempPos.z = rdMonsterData ? rdMonsterData.pos.y : pos.z + offset.z;
                        const monsterEle = PveSvrBattle.addMonster(
                            key,
                            pveServer,
                            __tid,
                            false,
                            tempPos,
                            rdMonsterData?.hp ?? 0,
                            i,
                            new Laya.Vector3(offset.x, offset.y, offset.z),
                            troopComp.eid,
                            false
                        );

                        if (monsterEle) {
                            const transform = monsterEle.getComponent(PveSvrTransformComponent)!;
                            const creature = monsterEle.getComponent(PveSvrCreatureComponent)!;
                            monsterTroopStateData.setMonsterData(
                                idx,
                                creature.hp,
                                creature.maxHp,
                                transform.position.x,
                                transform.position.z,
                                transform.rotation
                            );
                        }
                    }
                }
            };

            tryAddMemberMonster(monsterTroopRow.front, TroopStation.FRONT);
            tryAddMemberMonster(monsterTroopRow.middle, TroopStation.MIDDLE);
            tryAddMemberMonster(monsterTroopRow.back, TroopStation.BACK);
        }

        if (!troopComp.leaderEid && Object.keys(troopComp.members).length === 0) {
            // 部队没有leader也没有小怪，直接删除
            pveServer.removeElement(element);
        } else {
            pveServer.elements.set(key, troopComp.eleComp);
        }
    }

    private static addMonster(
        key: number,
        pveServer: PveServer,
        tid: number,
        isLeader: boolean,
        position: IVector3Like,
        curHp: number | 0,
        index: number, //编队里的位置
        offset: IVector3Like, //编队里的偏移
        troopEid: number,
        isBoss: boolean
    ) {
        // console.warn("addMonster ", key, tid, isLeader, position, curHp, index, offset, troopEid);
        const troopComp = pveServer.ecs.getComponent(troopEid, PveSvrTroopComponent)!;

        if (index === 0) {
            if (troopComp.leaderEid) {
                console.error("已经有leader在这个位置了", index, troopComp.leaderEid);
                return;
            }
        } else if (troopComp.members[index]) {
            console.error("已经有小怪在这个位置了", index, troopComp.members);
            return;
        }

        const monsterLo = app.service.pve.monsterLoMap.get(tid);

        const maxHp = monsterLo.getHp();
        // const maxHp = monsterLo.getHp() * 60; //测试，现在怪实在太弱了
        const hp = curHp > 0 ? curHp : maxHp;

        const etype = isBoss ? BattleEntityType.BOSS : BattleEntityType.MONSTER;
        const entity = pveServer.ecs.createEntity(pveServer.obtainEid(), etype);

        const element = entity.addComponent(PveSvrElementComponent);
        const creatureComp = entity.addComponent(PveSvrCreatureComponent);
        entity.addComponent(PveSvrBuffComponent);
        creatureComp.maxHp = maxHp;
        creatureComp.hp = hp;
        creatureComp.atk = monsterLo.getAtk();
        // creatureComp.atk = monsterLo.getAtk() * 20; //测试，现在怪实在太弱了
        creatureComp.defence = monsterLo.getDefence();

        element.tag = isLeader ? ElementTag.LEADER : ElementTag.MEMBER;
        element.tid = tid;
        element.aid = BattleAid.ENEMY;
        element.spawnpoint.cloneFrom(position);
        element.troopEid = troopEid;
        if (index === 0) {
            troopComp.leaderEid = entity.eid;
        } else {
            troopComp.members[index] = entity.eid;
        }

        const table = app.service.table;
        element.data = table.battleEntity[monsterLo.battleEntityId];

        const launcher = entity.addComponent(PveSvrSkillLauncherComponent);
        //普攻
        pveServer.initSkill(element, launcher, false, 1, monsterLo.skill0);
        //技能
        pveServer.initSkill(element, launcher, true, 1, monsterLo.skill1);
        pveServer.initSkill(element, launcher, true, 2, monsterLo.skill2);
        pveServer.initSkill(element, launcher, true, 3, monsterLo.skill3);
        pveServer.initSkill(element, launcher, true, 4, monsterLo.skill4);

        const transform = entity.addComponent(PveSvrTransformComponent);
        transform.position.x = position.x;
        transform.position.z = position.z;
        transform.rotation = 0;

        entity.addComponent(PveSvrMovementComponent);
        const offsetComp = entity.addComponent(PveSvrOffsetComponent);
        offsetComp.index = index;
        offsetComp.offset = offset;

        const ai = entity.addComponent(PveSvrAiComponent);
        ai.btree = `resources/data/btree/${element.data.pve_ai}.json`;

        pveServer.sender.createElement({
            eid: element.eid,
            etype: element.etype,
            key: element.key,
            aid: element.aid,
            teid: element.data.id,
            tid: element.tid,
            hp: creatureComp.hp,
            maxHp: creatureComp.maxHp,
            position: transform.position,
            rotation: transform.rotation,
            level: -1,
        });

        return element;
    }

    //#endregion

    static getAllFightingEnemyTroops(ctx: PveServer): PveSvrTroopComponent[] {
        const ret: PveSvrTroopComponent[] = [];
        const troops = ctx.ecs.getComponents(PveSvrTroopComponent);
        troops.forEach((t) => {
            if (t.isSelf) {
                return;
            }
            const allEids = [t.leaderEid, ...Object.values(t.members)].filter((eid) => eid !== 0);
            for (const idx in allEids) {
                const eid = allEids[idx];
                const creature = ctx.ecs.getComponent(eid, PveSvrCreatureComponent);
                if (creature && !creature.isOutOfFight) {
                    ret.push(t);
                    break;
                }
            }
        });
        return ret;
    }

    static getAllFightingEnemies(ctx: PveServer): PveSvrCreatureComponent[] {
        const ret: PveSvrCreatureComponent[] = [];
        const troops = ctx.ecs.getComponents(PveSvrTroopComponent);
        troops.forEach((t) => {
            if (t.isSelf) {
                return;
            }

            const creature = ctx.ecs.getComponent(t.leaderEid, PveSvrCreatureComponent);
            if (creature && !creature.isOutOfFight) {
                ret.push(creature);
            }

            for (const idx in t.members) {
                const eid = t.members[idx];
                const creature = ctx.ecs.getComponent(eid, PveSvrCreatureComponent);
                if (creature && !creature.isOutOfFight) {
                    ret.push(creature);
                }
            }
        });
        return ret;
    }
}
