import {
    Node,
    NodeDef,
    NodeVars as NodeVarsBase,
    Process,
    Status,
} from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";

interface NodeArgs {
    readonly duration: number;
}

interface NodeVars extends NodeVarsBase {
    startTime?: number;
    targetEid?: number;
}

type NodeInput = [number | undefined];

export class Follow extends Process {
    override init(node: Node): void {}

    override run(node: Node, env: AiTreeEnv): Status {
        const [targetEid] = env.input as NodeInput;
        const targetEle = env.context.findElement(targetEid ?? 0);

        if (!targetEle) {
            env.context.moveStop(env.owner.eid);
            return "failure";
        }
        const args = node.args as unknown as NodeArgs;
        const duration = args.duration ?? 1;
        env.context.moveFollow(env.owner, targetEle.eid, duration);

        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "Follow",
            type: "Action",
            desc: "追踪指定目标",
            input: ["目标"],
            args: [{ name: "duration", type: "float?", desc: "指定时间，秒" }],
            doc: `
                + 追踪指定目标
                + 参数:
                    + duration: 指定时间内飞到目标身上
            `,
        };
    }
}
