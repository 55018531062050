import { app } from "../../../app";
import * as ecs from "../../../core/ecs";
import { Pool } from "../../../core/pool";
import { Mediator } from "../../../core/ui-mediator";
import { Debug } from "../../../misc/debug";
import { res } from "../../../misc/res";
import { SystemEvent } from "../../../misc/system-event";
import { MapTransferData, TMWorldMinimap } from "../pve-server/PveDefs";
import { IPveServer, PveServer } from "../pve-server/PveServer";
import { IPveSvrReceiver } from "../pve-server/PveSvrReceiver";
import { ITMContext, TMMode } from "../tilemap/tm-def";
import {
    TMBuildingElement,
    TMDebugElement,
    TMElement,
    TMElementDescriptor,
    TMEventElement,
    TMMonsterElement,
    TMNpcElement,
    TMTileElemet,
} from "../tilemap/tm-element";
import { PveUI } from "../ui-runtime/PveUI";
import { PveReceiver } from "./PveReceiver";
import { PveTMWorldMinimap } from "./PveTMWorldMinimap";
import { PveCameraComponent } from "./ecs/components/PveCameraComponent";
import { PveClearCloudComponent } from "./ecs/components/PveClearCloudComponent";
import { PveCommandComponent } from "./ecs/components/PveCommandComponent";
import { PveElementComponent } from "./ecs/components/PveElementComponent";
import { PveJoystickComponent } from "./ecs/components/PveJoystickComponent";
import { PveMapComponent } from "./ecs/components/PveMapComponent";
import { PveTransformComponent } from "./ecs/components/PveTransformComponent";
import { PveBuildingSystem } from "./ecs/systems/PveBuildingSystem";
import { PveCameraSystem } from "./ecs/systems/PveCameraSystem";
import { PveCloudSystem } from "./ecs/systems/PveCloudSystem";
import { PveCollectSystem } from "./ecs/systems/PveCollectSystem";
import { PveCommandSystem } from "./ecs/systems/PveCommandSystem";
import { PveGameObjectFlySystem } from "./ecs/systems/PveGameObjectFlySystem";
import { PveJoystickSystem } from "./ecs/systems/PveJoystickSystem";
import { PveMapSystem } from "./ecs/systems/PveMapSystem";
import { PveMovementSystem } from "./ecs/systems/PveMovementSystem";
import { PveNavigationArrowSystem } from "./ecs/systems/PveNaviArrowSystem";
import { PveNpcSystem } from "./ecs/systems/PveNpcSystem";
import { PveRenderSystem } from "./ecs/systems/PveRenderSystem";
import { PveRescueConfirmSystem } from "./ecs/systems/PveRescueConfirmSystem";
import { PveSkillWarningSystem } from "./ecs/systems/PveSkillWarningSystem";
import { PveSpoilsSystem } from "./ecs/systems/PveSpoilsSystem";

@Laya.regClass('QXhbpQzgSSOfiEiZilv8jg')
export class PveContext extends Mediator implements ITMContext {
    declare owner: PveUI;

    ctrlHeroEid: number = 0;

    private _sender!: IPveSvrReceiver;

    private _pveServer!: IPveServer;

    private _eidCount: number = 0;
    private _ecs!: ecs.World;
    private _time: number = 0;
    private _camera!: Laya.Camera;
    private _cameraNode!: Laya.Sprite3D;

    public get ecs(): ecs.World {
        return this._ecs;
    }

    get scene() {
        return this.owner.scene;
    }

    get scene3D() {
        return this.owner.scene3D;
    }

    get camera() {
        return (this._camera ||= this.cameraNode.getChildByName("Main Camera") as Laya.Camera);
    }

    get cameraNode() {
        return (this._cameraNode ||= this.scene3D.getChildByName("cameraNode") as Laya.Sprite3D);
    }

    get sender() {
        return this._sender;
    }

    get mapDir() {
        return "resources/data/tilemap/pve";
    }

    get mode() {
        return TMMode.PVE;
    }

    get time() {
        return this._time;
    }

    override async onAwake() {
        // TODO: 增加加载过程表现，资源加载完成以后再进行游戏。
        await this.loadAssets();

        this._ecs = new ecs.World(this);
        this._ecs.addSingletonComponent(PveCameraComponent);
        this._ecs.addSingletonComponent(PveClearCloudComponent);
        this._ecs.addSingletonComponent(PveJoystickComponent);
        this._ecs.addSingletonComponent(PveMapComponent);
        this._ecs.addSingletonComponent(PveCommandComponent);
        this._ecs.addSystem(PveCommandSystem); //command system 必须在最前面
        this._ecs.addSystem(PveJoystickSystem);
        this._ecs.addSystem(PveMovementSystem);
        this._ecs.addSystem(PveCameraSystem);
        this._ecs.addSystem(PveRenderSystem);
        this._ecs.addSystem(PveMapSystem);
        this._ecs.addSystem(PveCollectSystem);
        this._ecs.addSystem(PveSpoilsSystem);
        this._ecs.addSystem(PveNavigationArrowSystem);
        this._ecs.addSystem(PveSkillWarningSystem);
        this._ecs.addSystem(PveRescueConfirmSystem);
        this._ecs.addSystem(PveCloudSystem);
        this._ecs.addSystem(PveBuildingSystem);
        this._ecs.addSystem(PveNpcSystem);
        this._ecs.addSystem(PveGameObjectFlySystem);
        const receiver = new PveReceiver(
            this._ecs.getSingletonComponent(PveCommandComponent).commands
        );
        this._pveServer = PveServer.create(receiver);
        this._sender = this._pveServer.receiver;

        this.owner.mapClickArea.on(Laya.Event.CLICK, this, this.onMapClickHandler);
        this.$(app).on(SystemEvent.TILEMAP_DEBUG_MODE_UPDATE, this.onTilemapDebugModeUpdate, this);

        this.$(app).on(SystemEvent.PVE.JOYSTICK_UPDATE, () => {
            this.owner.joystickGroup.visible = Debug.PVE_JOYSTICK;
        });

        this.$(app).on(SystemEvent.PVE.GM_CMD, (text: string) => {
            this.sender.gmCmd(text);
        });

        this._pveServer.doStart();

        this.$(app).on(SystemEvent.ON_START_PLOT_THEATRE, this.onStartPlotTheatre, this);
    }

    /** 播放剧情要停止玩家移动 */
    private onStartPlotTheatre(): void {
        this._sender.onStartPlotTheatre();
    }

    private async loadAssets() {
        const checker = () => !this.owner.destroyed;
        await app.loader.loadPrefab(res.BATTLE_HP_NUM);
        await app.loader.loadPrefab(res.BATTLE_RECOVER_HP_NUM);
        await app.loader.loadPrefab(res.BATTLE_HP_NUM_X);
        const worldMinimap: TMWorldMinimap = await app.loader.loadJson(
            res.TILEMAP_PVE_WORLD_MINIMAP
        );
        if (!checker()) {
            return;
        }
        app.service.pve.minimap.minimapData = worldMinimap;
    }

    // override async onStart() {

    // }

    override onDestroy() {
        // console.log("PveContext onDestroy");
        this._pveServer.destroy();
        this._pveServer = null!;
        this._ecs.destroy();
        this._ecs = null!;
        this._sender = null!;
        super.onDestroy();
    }

    override onUpdate() {
        if (!this._ecs) {
            return;
        }
        // 限制执行逻辑的dt大小，dt过大会导致一些逻辑异常
        let remainDt = app.delta;
        while (remainDt > 0) {
            const nextDt = Math.min(remainDt, 0.2);
            this.owner.debug.graphics.clear();
            this._pveServer.update(nextDt);
            this._ecs.update(nextDt);
            this.superUpdate(nextDt);
            this._time += nextDt;
            remainDt -= nextDt;
        }
    }

    obtainEid() {
        return --this._eidCount;
    }

    onAddElement(element: TMElement) {
        if (
            element instanceof TMMonsterElement ||
            element instanceof TMBuildingElement ||
            element instanceof TMEventElement ||
            element instanceof TMNpcElement
        ) {
            this.sender.addElement(Pool.obtain(TMElementDescriptor, element));
        }
    }

    onDelElement(element: TMElement) {
        if (
            element instanceof TMMonsterElement ||
            element instanceof TMBuildingElement ||
            element instanceof TMEventElement ||
            element instanceof TMNpcElement
        ) {
            this.sender.removeElement(Pool.obtain(TMElementDescriptor, element));
        }
    }

    onMapClickHandler() {
        const point = new Laya.Vector2();
        point.x = Laya.stage.mouseX;
        point.y = Laya.stage.mouseY;

        const ray = new Laya.Ray(new Laya.Vector3(), new Laya.Vector3());
        this.camera.viewportPointToRay(point, ray);

        const t = -ray.origin.y / ray.direction.y;
        ray.direction.scale(t, ray.direction);

        const groundPos = new Laya.Vector3();
        ray.origin.vadd(ray.direction, groundPos);

        this.sender.click(groundPos.x, groundPos.z);
    }

    onTilemapDebugModeUpdate() {
        const map = this._ecs.getSingletonComponent(PveMapComponent);
        map.tilemap.getAllMap().forEach((element) => {
            if (element instanceof TMTileElemet || element instanceof TMDebugElement) {
                element.erase();
                element.draw();
            }
        });
    }

    /** 获取玩家当前最接近的区域传送点 */
    getCurrentCloserTransferPoint(): {
        areaId: number | undefined;
        mapTransfer: MapTransferData | null;
    } {
        const heroElement = this.ecs.getComponent(this.ctrlHeroEid, PveElementComponent)!;
        const heroTransform = heroElement.getComponent(PveTransformComponent)!;
        const myPosition: Laya.Vector3 = heroTransform.position;
        const minimap: PveTMWorldMinimap = app.service.pve.minimap;
        return minimap.getClosestAreaIDAndMapTransfer(myPosition.x, myPosition.z);
    }

    /** 查找出静态的事件或者建筑 */
    findStaticElementByKey(key: number): PveElementComponent | undefined {
        for (const comp of this._ecs.getComponents(PveElementComponent).values()) {
            if (comp.key === key) {
                return comp;
            }
        }
        return undefined;
    }
}
