const { regClass } = Laya;
import { app } from "../../../app";
import { ImageRef } from "../../../misc/ImageRef";
import { ui } from "../../../misc/ui";
import { SkillVo } from "../vo/SkillVo";
import { SkillFastListItemUIBase } from "./SkillFastListItemUI.generated";

@regClass('Ot0dZejsRYGp_ZBHhFCu9Q')
export class SkillFastListItemUI extends SkillFastListItemUIBase {
    public isShowFirst: boolean = true;
    private heroUid: number = 0;
    private skillPos: number = 0;
    private sysType: number = 0;
    private m_data: SkillVo | null = null;

    override onAwake(): void {
        super.onAwake();
        this.labBtn.on(Laya.Event.CLICK, this, this.onClickLab);
        this.btnUp.on(Laya.Event.CLICK, this, this.onClickUp);
        this.btnOff.on(Laya.Event.CLICK, this, this.onClickOff);
    }

    private onClickLab(e: Laya.Event) {
        e.stopPropagation();
        this.isShowFirst = !this.isShowFirst;
        this.showDesc();
    }

    public setData(heroUid: number, skillPos: number, sysType: number) {
        this.heroUid = heroUid;
        this.skillPos = skillPos;
        this.sysType = sysType;
    }

    private onClickUp(e: Laya.Event) {
        e.stopPropagation();
        const skillId = this.m_data!.skillId;
        app.service.skill.requestMountSkill(this.heroUid, skillId, this.skillPos, this.sysType);
    }

    private onClickOff(e: Laya.Event) {
        e.stopPropagation();
        const data = this.m_data!;
        app.ui.show(ui.SKILL_REMOVE, data);
    }

    override set_dataSource(value: SkillVo): void {
        const skillData = value;
        this.m_data = value;
        this.labDesc.text = skillData.desc;
        this.labName.text = skillData.name;

        this.btnOff.visible = skillData.isActed && skillData.heroIdList.length > 0;
        this.btnUp.visible = skillData.isActed && !skillData.heroIdList.length;
        this.skill_icon.initIcon(app.service.table.battleSkill.skill[value.key]);
        this.isShowFirst = true;
        this.showDesc();
        this.labType.text = this.labType2.text = app.service.skill.getSkillTypeText(
            skillData.typeskill
        );
        this.labType.color = this.labType2.color = app.service.skill.getTypeTextColor(
            skillData.typeskill || 1
        );
        const imgQualityList = this.imgQuality.getComponent(ImageRef)!.texturePaths;
        this.imgQuality.skin = imgQualityList[skillData.quality - 1];
        this.labName.color = app.service.skill.getQualityTextColor(skillData.quality);
        this.labPro.visible = !skillData.isActed && !skillData.isCanAct;
        if (this.labPro.visible) {
            const actCost = value.activecost;
            const ownerCount = app.service.user.getMoneyCount(actCost.id);
            this.labPro.text = `已收集${ownerCount}/${actCost.num}`;
        }
        const typeTextures = this.imgType.getComponent(ImageRef)!.texturePaths;
        this.imgType.skin = this.imgType2.skin = typeTextures[skillData.typeskill - 1];
    }

    public showDesc() {
        const isFirst = this.isShowFirst;
        const data = this.m_data as SkillVo;
        this.labDesc.text = isFirst
            ? app.service.skill.getFirstLevelDesc(app.service.table.battleSkill.skill[data.key])
            : app.service.skill.getFullLevelDesc(app.service.table.battleSkill.skill[data.key]);
        this.labBtn.text = isFirst ? `满级效果` : "一级效果";
        this.lablevelDesc.text = isFirst ? "一级效果" : "满级效果";
    }

    isShowDetails(isShow: boolean) {
        this.boxBase.visible = !isShow;
        this.boxDetails.visible = isShow;
        this.height = isShow ? 260 : 149;
        this.btnOff.y = this.btnUp.y = isShow ? 52 : 71;
    }
}
