const { regClass } = Laya;
import { app } from "../../../app";
import proto from "../../../def/auto/proto";
import { SlotSysType } from "../../../def/auto/skill";
import { ui } from "../../../misc/ui";
import { SkillDetailsUIArgs } from "../../skill/ui-runtime/SkillDetailsUI";
import { SkillFastListUIArgs } from "../../skill/ui-runtime/SkillFastListUI";
import { LeaderSkinLo } from "../vo/LeaderSkinLo";
import { LeaderSkinSkillItemBase } from "./LeaderSkinSkillItem.generated";

@regClass('zqQ4dUxITM-TS90Ww_ApEA')
export class LeaderSkinSkillItem extends LeaderSkinSkillItemBase {
    private skillPos: number = 0;
    private skillData: proto.hero.SkillInfo | undefined;
    public loData: LeaderSkinLo | undefined;

    override onAwake(): void {
        super.onAwake();
        this.on(Laya.Event.CLICK, this, this.onClickItem);
    }

    // todo： 后续有技能了再修正逻辑
    onRender(loData: LeaderSkinLo, isUnlock: boolean, pos: number) {
        this.skillPos = pos;
        this.loData = loData;
        this.skillData = this.getSKillData(loData, pos);
        this.imgAdd.active = this.imgAdd.visible = this.skillData === undefined && isUnlock;
        this.imgLock.active = this.imgLock.visible = !isUnlock;

        this.boxOpen.active = this.boxOpen.visible = this.skillData !== undefined;
        this.boxClose.active = this.boxClose.visible = this.skillData === undefined;
        if (this.skillData) {
            this.labSkillLv.text = `${this.skillData.lv}`;
            this.imgSkill.skin = app.service.skill.getSKillIcon(this.skillData.id!);
        }
    }

    private getSKillData(loData: LeaderSkinLo, pos: number): proto.hero.SkillInfo | undefined {
        const skillList = loData.getSkinSkillDatas();
        const skillData = skillList[pos];
        return skillData;
    }

    private onClickItem() {
        if (this.imgLock.visible) {
            const unLockStar =
                app.service.table.battleSkill.skill_slot_unlock[this.skillPos - 1].require_star;
            app.ui.toast(`需要统帅达到${unLockStar}星`);
            return;
        }
        if (!this.loData) return;
        if (this.imgAdd.visible) {
            const skillList = app.service.skill.getSkillFastListSort();
            if (skillList.length > 0) {
                app.ui.show(ui.SKILL_FAST_LIST, {
                    uid: this.loData.id,
                    skillPos: this.skillPos,
                    sysType: SlotSysType.LEADER_SKIN,
                } as SkillFastListUIArgs);
            } else {
                app.ui.toast("当前无可穿戴技能");
            }

            return;
        }
        const skinData = app.service.leaderSkin.getSkinDataById(this.loData.id);
        const isActive = skinData !== undefined;
        if (this.skillData) {
            const isReplace = this.loData.skill1 !== this.skillData.id && this.skillPos !== 1; //固定技能skill1不允许被替换
            app.ui.show(ui.SKILL_DETAILS, {
                skillData: this.skillData,
                heroUid: this.loData.id,
                skillPos: this.skillPos,
                isReplace: isReplace,
                sysType: SlotSysType.LEADER_SKIN,
                isActive: isActive,
            } as SkillDetailsUIArgs);
        }
    }
}
