import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { PveEventType } from "../../../../../def/auto/battle";
import { MainlineConf } from "../../../../../def/auto/mainline";
import { TroopStation } from "../../../../../def/auto/troop";
import { CollectionAmountData } from "../../PveDefs";
import { PveServer } from "../../PveServer";
import { PveSvrCreatureComponent } from "../components/PveSvrCreatureComponent";
import { PveSvrElementComponent } from "../components/PveSvrElementComponent";
import { PveSvrTransformComponent } from "../components/PveSvrTransformComponent";
import { PveSvrCollectSystem } from "./PveSvrCollectSystem";
import { PveSvrStateSystem } from "./PveSvrStateSystem";

export class PveSvrRescueSoldierSystem extends ecs.System {
    declare context: PveServer;

    private static rescueSoldier(pveServer: PveServer, eid: number) {
        const hero = pveServer.ecs.getComponent(pveServer.ctrlHeroEid, PveSvrElementComponent);
        const event = pveServer.ecs.getComponent(eid, PveSvrElementComponent);
        if (hero && event) {
            // 设置事件的触发时间
            const nowTime = app.service.network.serverTime;
            const pveStateData = app.service.pve.mlData.pveStateData;
            pveStateData.setEventTriggerTime(event, nowTime << 0);

            const eventVo = app.service.pve.eventLoMap.get(event.tid)!;
            if (eventVo.eventType !== PveEventType.RESCUE_SOLDIER) {
                console.error("事件类型不匹配", eventVo.eventType, PveEventType.RESCUE_SOLDIER);
                return;
            }

            const soldiderDatas = eventVo.soldierDatas;
            soldiderDatas?.forEach((element) => {
                for (let i = 0; i < element.count; i++) {
                    this.rescueSingleSoldierByEvent(
                        pveServer,
                        hero,
                        event,
                        element.soldier,
                        element.pos
                    );
                }
            });
            app.ui.toast("成功救助士兵");
            pveServer.removeElement(event);
            // 解救士兵后马上进行保存状态
            PveSvrStateSystem.save(pveServer.ecs);
            app.service.pve.requestDoTask(MainlineConf.TASK.RESCUS_SOLDIER, 1, eventVo.key);
        }
    }

    /** 解救单个士兵 */
    private static rescueSingleSoldierByEvent(
        pveServer: PveServer,
        hero: PveSvrElementComponent,
        eventElement: PveSvrElementComponent,
        tid: number,
        station: TroopStation
    ) {
        const posIndex = pveServer.getEmptySoldierPosByStation(station);
        if (posIndex < 0) {
            return;
        }
        const pveStateData = app.service.pve.mlData.pveStateData;

        const heroData = app.service.pve.mlData.pveStateData.hero;
        heroData.unlockSodiderIndexes.push(posIndex);

        const eveTrans = eventElement.getComponent(PveSvrTransformComponent)!;
        const px = eveTrans.position.x;
        const pz = eveTrans.position.z;
        const soldierComp = pveServer.addSoldierWith(hero, posIndex, tid, px, pz);
        if (soldierComp) {
            const transform = soldierComp.getComponent(PveSvrTransformComponent)!;
            const creature = soldierComp.getComponent(PveSvrCreatureComponent)!;
            pveStateData.addSoldier(
                posIndex,
                tid,
                creature.hp,
                creature.maxHp,
                transform.position.x,
                transform.position.z,
                transform.rotation
            );
        }
    }

    public static async rescueSoldierWithCost(
        pveServer: PveServer,
        eid: number,
        amountObj: CollectionAmountData,
        needAmount: number
    ) {
        const element = pveServer.ecs.getComponent(eid, PveSvrElementComponent)!;
        await PveSvrCollectSystem.costFormTruck(
            pveServer,
            element.transform.position,
            amountObj,
            needAmount
        );
        this.rescueSoldier(pveServer, eid);
        await app.service.pve.requestEvent(element.tid);
    }

    // TODO: 自制一个延时执行
    public static async waitTime(time: number) {
        await new Promise((resolve) => {
            Laya.timer.once(time, this, () => {
                resolve(1);
            });
        });
    }
}
