const { regClass } = Laya;
import { app } from "../../../../app";
import { ui } from "../../../../misc/ui";
import { PveContext } from "../../pve/PveContext";
import { PveDefeatWindowUIBase } from "./PveDefeatWindowUI.generated";

@regClass('OsgjXBeESp2TwkuV01biKg')
export class PveDefeatWindowUI extends PveDefeatWindowUIBase {
    declare context: PveContext;

    override onAwake(): void {
        super.onAwake();
        this.btn_reborn.on(Laya.Event.CLICK, this, this.onReborn);
        this.btn_giveup.on(Laya.Event.CLICK, this, this.onGiveup);
    }

    override onOpened(param: PveContext): void {
        this.context = param;
    }

    /** 原地复活 */
    private onReborn(): void {
        if (!this.context) return;
        app.ui.show(ui.PVE_REBORN_WIN, this);
    }

    /** 回到城镇 */
    private onGiveup(): void {
        if (!this.context) return;
        // app.ui.toast("回到城镇");
        this.close();
        this.context.sender.heroReborn(true);
    }
}
