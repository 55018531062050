import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { AnimName, Animator } from "../../../base/Animator";
import { PnvnContext } from "../../PnvnContext";
import { PnvnAnimationComponent } from "../components/PnvnAnimationComponent";
import { PnvnDebugBlockComponent } from "../components/PnvnDebugComponent";
import { PnvnMovementComponent } from "../components/PnvnMovementComponent";
import { PnvnTransformComponent } from "../components/PnvnTransformComponent";
import { PnvnTroopComponent } from "../components/PnvnTroopComponent";

export class PnvnRenderSystem extends ecs.System {
    declare context: PnvnContext;

    override onCreate(): void {
        this.registerHandler(
            PnvnAnimationComponent,
            this._onAddPnvnAnimationComponent,
            this._onDelPnvnAnimationComponent
        );
        this.registerHandler(
            PnvnTroopComponent,
            this._onAddPnvnTroopComponent,
            this._onDelPnvnTroopComponent
        );
        this.registerHandler(
            PnvnDebugBlockComponent,
            this._onAddPnvnDebugBlockComponent,
            this._onDelPnvnDebugBlockComponent
        );
    }

    override update(dt: number) {
        this.ecs.getComponents(PnvnAnimationComponent).forEach((anim) => {
            this._updatePosition(anim);
        });
    }

    private _updatePosition(comp: ecs.Component & { mounter: Laya.Sprite3D }) {
        const transform = comp.getComponent(PnvnTransformComponent)!;
        const mounterTransform = comp.mounter.transform;

        if (transform.flags & PnvnTransformComponent.POSITION) {
            mounterTransform.position = transform.position;
            transform.flags &= ~PnvnTransformComponent.POSITION;
        }

        if (transform.flags & PnvnTransformComponent.ROTATION) {
            mounterTransform.localRotationEulerY = transform.rotation;
            transform.flags &= ~PnvnTransformComponent.ROTATION;
        }
    }

    //#region PnvnAnimationComponent
    private async _onAddPnvnAnimationComponent(anim: PnvnAnimationComponent) {
        this.context.scene3D.addChild(anim.mounter);

        const checker = anim.checker;
        const prefab = await app.loader.loadPrefab(anim.res);
        if (!checker()) {
            return;
        }

        anim.view = prefab.create() as Laya.Sprite3D;
        anim.animator = anim.view.getComponent(Animator);
        if (anim.animator) {
            // TOOD: 配置缩放大小？
            // const owner = anim.animator.owner as Laya.Sprite3D;
            // owner.transform.localScaleX = 1.5;
            // owner.transform.localScaleY = 1.5;
            // owner.transform.localScaleZ = 1.5;

            anim.animator.url = anim.res;
        }
        anim.mounter.addChild(anim.view);

        const movement = anim.getComponent(PnvnMovementComponent)!;
        if (movement.speed > 0) {
            this.context.playAnim(anim.eid, AnimName.MOVE);
        }
    }

    private _onDelPnvnAnimationComponent(anim: PnvnAnimationComponent) {
        anim.view?.destroy();
        anim.view = null;
        anim.animator = null;
        anim.mounter.removeChildren();
        anim.mounter.destroy();
    }
    //#endregion

    //#region PnvnTroopComponent
    private _onAddPnvnTroopComponent(troop: PnvnTroopComponent) {}

    private _onDelPnvnTroopComponent(troop: PnvnTroopComponent) {}
    //#endregion

    private async _onAddPnvnDebugBlockComponent(debug: PnvnDebugBlockComponent) {
        const checker = debug.checker;
        const prefab = await app.loader.loadPrefab(debug.res);
        if (!checker()) {
            return;
        }

        const anim = debug.getComponent(PnvnAnimationComponent)!;
        debug.view = prefab.create() as Laya.Sprite3D;
        anim.mounter.addChild(debug.view);
    }

    private _onDelPnvnDebugBlockComponent(debug: PnvnDebugBlockComponent) {
        debug.view?.destroy();
        debug.view = null;
    }
}
