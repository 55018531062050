import { app } from "../../../../../app";
import { Callback } from "../../../../../core/dispatcher";
import * as ecs from "../../../../../core/ecs";
import { Pool } from "../../../../../core/pool";
import { tween } from "../../../../../core/tween/tween";
import { BattleCommand, BattleEntityType } from "../../../../../def/auto/battle";
import proto from "../../../../../def/auto/proto";
import { errcode, opcode } from "../../../../../def/auto/protocol";
import { res } from "../../../../../misc/res";
import { AnimName } from "../../../base/Animator";
import { P1v1Context } from "../../P1v1Context";
import { P1v1AnimationComponent } from "../components/P1v1AnimationComponent";
import { P1v1BattleReportComponent } from "../components/P1v1BattleReportComponent";
import { P1v1MovementComponent } from "../components/P1v1MovementComponent";
import { P1v1TransformComponent } from "../components/P1v1TransformComponent";
import { Constructor } from "./../../../../../core/dispatcher";

export class P1v1CommandSystem extends ecs.System {
    declare context: P1v1Context;

    override onCreate() {
        this.handle(opcode.battle.notify_cmd, this._onNotifyCmd);
        this.handle(opcode.battle.s2c_enter_slot, this._onEnter);
    }

    private handle(op: number, callback: Callback) {
        this.context.$(app).on(op, callback, this);
    }

    private _onEnter(data: proto.battle.s2c_enter_slot) {
        if (data.err === errcode.OK) {
            const battle = data.battle as proto.battle.Battle;
            for (const v of battle.entities) {
                this._addEntity(v as proto.battle.BattleEntity);
            }
        }
    }

    private _onNotifyCmd(notify: proto.battle.notify_cmd) {
        if (!notify.frame) {
            return;
        }
        for (const cmd of notify.frame.cmds!) {
            if (cmd.cmdType === BattleCommand.ADD_ENTITY) {
                this._addEntity(cmd.addEntity?.entity as proto.battle.BattleEntity);
            } else if (cmd.cmdType === BattleCommand.DEL_ENTITY) {
                this._delEntity(cmd.delEntity as proto.battle.cmd_del_entity);
            } else if (cmd.cmdType === BattleCommand.ATTACK) {
                this._attack(cmd.attack as proto.battle.cmd_attack);
            } else if (cmd.cmdType === BattleCommand.MOVE_STOP) {
                this._moveStop(cmd.moveStop as proto.battle.cmd_move_stop);
            } else if (cmd.cmdType === BattleCommand.MOVE_TO) {
                this._moveTo(cmd.moveTo as proto.battle.cmd_move_to);
            } else if (cmd.cmdType === BattleCommand.FORCE_TO) {
                this._forceTo(cmd.forceTo as proto.battle.cmd_force_to);
            } else if (cmd.cmdType === BattleCommand.UNDER_ATK) {
                this._underAttack(cmd.underAtk as proto.battle.cmd_under_atk);
            } else {
                console.warn(`unhandle cmd type: ${cmd.cmdType}`);
            }
        }
        if (notify.frame.debugPoints) {
            this._drawDebugs(notify.frame.debugPoints as proto.battle.DebugInfo[]);
        }
    }

    private _addEntity(cmd: proto.battle.BattleEntity) {
        const data = app.service.table.battleEntity[cmd.entityId];
        const entity = this.ecs.createEntity(cmd.eid, data.etype);

        const transform = entity.addComponent(P1v1TransformComponent);
        transform.position.set(cmd.y, 0, cmd.x);
        transform.rotation = cmd.rotation;
        transform.flags |= P1v1TransformComponent.POSITION | P1v1TransformComponent.ROTATION;

        entity.addComponent(P1v1MovementComponent);

        const animation = entity.addComponent(P1v1AnimationComponent);
        const skinCfg = app.service.table.skin[data.skin_id!];
        const resPath = skinCfg.prefeb_res || skinCfg.prefeb_baked_res;

        animation.res = resPath!;
        this.context.owner.scene3D.addChild(animation.mounter);
    }

    private _delEntity(cmd: proto.battle.cmd_del_entity) {
        const entity = this.ecs.getEntity(cmd.eid);
        if (
            entity &&
            (entity.etype === BattleEntityType.SOLDIER || entity.etype === BattleEntityType.HERO)
        ) {
            this.context.playAnim(cmd.eid, AnimName.DIE);
            const movement = this._findComponent(cmd.eid, P1v1MovementComponent)!;
            movement.speed = 0;
            this.ecs.delay(1, `#${cmd.eid}.die`, () => {
                this.ecs.removeEntity(cmd.eid);
            });
        } else {
            this.ecs.removeEntity(cmd.eid);
        }
    }

    private _attack(cmd: proto.battle.cmd_attack) {
        this.context.playAnim(cmd.eid, AnimName.ATTACK);
        this.towardTo(cmd.eid, cmd.targetEid);
    }

    private _moveStop(cmd: proto.battle.cmd_move_stop) {
        const movement = this._findComponent(cmd.eid, P1v1MovementComponent);
        const transform = movement?.getComponent(P1v1TransformComponent);
        if (movement && transform) {
            movement.speed = 0;
            transform.position.x = cmd.y;
            transform.position.z = cmd.x;
            transform.flags |= P1v1TransformComponent.POSITION;
            this.context.playAnim(cmd.eid, AnimName.IDLE);
        }
    }

    private _moveTo(cmd: proto.battle.cmd_move_to) {
        const movement = this._findComponent(cmd.eid, P1v1MovementComponent);
        const transform = movement?.getComponent(P1v1TransformComponent);
        if (movement && transform) {
            movement.speed = cmd.speed;
            movement.target.set(cmd.targetY, 0, cmd.targetX);

            transform.rotation = Math.toDegree(
                Math.atan2(
                    movement.target.x - transform.position.x,
                    movement.target.z - transform.position.z
                )
            );
            transform.flags |= P1v1TransformComponent.ROTATION;

            this.context.playAnim(cmd.eid, AnimName.MOVE);
        }
    }

    private _forceTo(cmd: proto.battle.cmd_force_to) {
        const movement = this._findComponent(cmd.eid, P1v1MovementComponent);
        if (movement) {
            movement.speed = cmd.speed;
            movement.target.set(cmd.targetY, 0, cmd.targetX);
        }
    }

    private async _underAttack(cmd: proto.battle.cmd_under_atk) {
        const transform = this._findComponent(cmd.eid, P1v1TransformComponent);
        if (transform && cmd.subHp) {
            const checker = transform.checker;
            const prefab = await app.loader.loadPrefab(
                cmd.critical ? res.BATTLE_HP_NUM_X : res.BATTLE_HP_NUM
            );
            if (!checker()) {
                return;
            }

            if (prefab) {
                const pos = new Laya.Vector4();
                this.context.camera.worldToViewportPoint(transform.position, pos);
                const hpui = prefab.create() as Laya.Sprite;
                const hpTxt = hpui.getChildByName("anim").getChildByName("hp") as Laya.Text;
                const labels = this.context.owner.labels;
                const p = new Laya.Point(pos.x, pos.y + this.context.owner.battle.y);
                labels.globalToLocal(p, false);
                hpTxt.text = cmd.subHp.toFixed();
                hpui.pos(p.x, p.y, true);
                labels.addChild(hpui);
                hpui.scaleX = 0.5;
                hpui.scaleY = 0.5;
                tween(hpui)
                    .to(0.3, { scaleX: 1, scaleY: 1 }, { easing: "bounceOut" })
                    .delay(0.5)
                    .call(() => {
                        tween(hpui)
                            .to(0.5, { y: hpui.y - 50 })
                            .start();
                        tween(hpui).to(0.5, { alpha: 0 }).removeSelf().start();
                    })
                    .start();
            }
        }
    }

    towardTo(eid: number, target: number) {
        const obj1 = this._findComponent(eid, P1v1TransformComponent);
        const obj2 = this._findComponent(target, P1v1TransformComponent);
        if (obj1 && obj2) {
            const p1 = obj1.position;
            const p2 = obj2.position;
            obj1.rotation = Math.toDegree(Math.atan2(p2.x - p1.x, p2.z - p1.z));
            obj1.flags |= P1v1TransformComponent.ROTATION;
        }
    }

    private _findComponent<T extends ecs.Component>(eid: number, cls: Constructor<T>) {
        const comp = this.ecs.getComponent(eid, cls);
        if (!comp) {
            console.warn(`compoment '${cls.name}' not found for eid ${eid}`);
        }
        return comp;
    }

    private async _drawDebugs(points: proto.battle.DebugInfo[]) {
        const checker = () => !this.ecs.destroyed;
        const prefab = await app.loader.loadPrefab(res.battle.PVP_DEBUG_TILE);
        if (!checker()) {
            return;
        }

        const battleReport = this.ecs.getSingletonComponent(P1v1BattleReportComponent);
        Pool.free(res.battle.PVP_DEBUG_TILE, battleReport.debugs);
        points.forEach((value) => {
            const debug = Pool.obtain(
                res.battle.PVP_DEBUG_TILE,
                () => prefab.create() as Laya.Sprite3D
            );
            const position = debug.transform.position;
            position.x = value.y;
            position.z = value.x;
            debug.transform.position = position;
            debug.transform.localScale = new Laya.Vector3(0.2, 0.2, 0.2);
            battleReport.debugs.push(debug);
            this.context.updateLayer(debug);
            this.context.scene3D.addChild(debug);
        });
    }
}
