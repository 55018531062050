const { regClass, property } = Laya;

export enum HorizonAlign {
    Left = 0,
    Center = 1,
    Right = 2,
}

@regClass('uJQN9b85QBuPcHEyQNjCvw')
@Laya.classInfo({
    menu: "自定义",
})
export class UIHBoxStar extends Laya.LayoutBox {
    // 作为给五个星星布局的容器, 以后再做通用的Layout box组件

    private _horizonAlign: HorizonAlign = HorizonAlign.Center;

    public get horizonAlign() {
        return this._horizonAlign;
    }

    @property(HorizonAlign)
    public set horizonAlign(v: HorizonAlign) {
        this._horizonAlign = v;
        this.callLater(this.changeItems);
    }

    private _uspace: number = 0;

    public get uspace() {
        return this._uspace;
    }

    @property(Number)
    public set uspace(v: number) {
        if (this._uspace !== v) {
            this._uspace = v;
            this.callLater(this.changeItems);
        }
    }

    forceUpdate() {
        this.callLater(this.changeItems);
    }

    /**
     * @inheritDoc
     * @override
     */
    protected override changeItems(): void {
        this._itemChanged = false;
        const items: Laya.UIComponent[] = [];
        let maxWidth = 0;

        for (let i = 0, n = this.numChildren; i < n; i++) {
            const item = <Laya.UIComponent>this.getChildAt(i);
            if (item && item.visible) {
                items.push(item);
                maxWidth += item.width * item.scaleX + (i > 0 ? this.uspace : 0);
            }
        }
        let startX = 0;
        switch (this.horizonAlign) {
            case HorizonAlign.Left:
                startX = 0;
                break;
            case HorizonAlign.Center:
                startX = (this.width - maxWidth) / 2;
                break;
            case HorizonAlign.Right:
                startX = this.width - maxWidth;
                break;
        }
        for (let i = 0, n = items.length; i < n; i++) {
            const item = items[i];
            item.x = startX;
            startX += item.width * item.scaleX + this.uspace;
        }
        this._sizeChanged();
    }
}
