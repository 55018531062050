import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";

export class CalcSpawnDistance extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const spawnpoint = env.owner.spawnpoint;
        const positioin = env.owner.transform.position;
        env.output.push(Laya.Vector3.distanceXZ(spawnpoint, positioin));
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "CalcSpawnDistance",
            type: "Action",
            desc: "计算与出生点之间的距离",
            output: ["距离"],
        };
    }
}
