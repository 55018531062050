import * as ecs from "../../../../../core/ecs";
import { PnvnTroopComponent } from "./PnvnTroopComponent";

export class PnvnBattleFieldComponent extends ecs.SingletonComponent {
    leftTroops: PnvnTroopComponent[] = [];
    rightTroops: PnvnTroopComponent[] = [];

    // world_eid => Troop
    troopMap: Map<number, PnvnTroopComponent> = new Map();
}
