/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIImageBar } from "../../../../core/ui/UIImageBar";

/**
 * resources/prefab/battle/pvp-ui/pvp-head-info1.lh
 */
export class PvpHeadInfoUIBase extends Laya.Box {
    public imgBg!: Laya.Image;
    public avatar!: Laya.Image;
    public sprPro!: UIImageBar;
    public imgType!: Laya.Image;
    public lblCost!: Laya.Label;
    public lblTroopName!: Laya.Label;
}
