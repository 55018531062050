/**
 * Vo: view object
 * 1. _config为读表配置，_data为服务器数据。这两个字段均不应该直接操作，获取内部数据应该通过get方法
 * 2. Vo的子类必须实现getKey方法，返回一个唯一的key，该Key用于在VoMap中作为key
 * 3. Vo只有数据，不包含逻辑，逻辑应该在对应VoMap中实现，最后调用__setData函数将数据传递给Vo
 * 4. VoMap是Vo的集合，VoMap的key是Vo的getKey方法返回的key
 */
export abstract class Vo<C, D> {
    protected _config: Readonly<C>;
    protected _data?: Readonly<D>;

    constructor(config: C, data?: D) {
        this._config = config;
        this._data = data;
    }

    // 只能由对应的VoMap/Service调用
    __setData(data: D) {
        this._data = data;
    }

    abstract get key(): unknown;

    abstract clone(): Vo<C, D>;
}
