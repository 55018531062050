import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { BattleConf } from "../../../../../def/auto/battle";
import { AnimName } from "../../../base/Animator";
import { calcVelocity } from "../../../base/Util";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrElementComponent } from "../../ecs/components/PveSvrElementComponent";
import {
    MovementState,
    MovementUpdateType,
    PveSvrMovementComponent,
} from "../../ecs/components/PveSvrMovementComponent";
import { PveSvrOffsetComponent } from "../../ecs/components/PveSvrOffsetComponent";
import { PveSvrTroopComponent } from "../../ecs/components/PveSvrTroopComponent";
import { PveSvrAiSystem } from "../../ecs/systems/PveSvrAiSystem";

const tmpTarget = new Laya.Vector3();
const tmpVelocity = new Laya.Vector3();

export class FollowHero extends Process {
    override init(node: Node) {}

    override run(node: Node, env: AiTreeEnv): Status {
        const offsetComp = env.owner.getComponent(PveSvrOffsetComponent);
        if (!offsetComp) {
            console.warn(`OffsetComponent not found `);
            return "failure";
        }

        const troopComp = env.context.ecs.getComponent(offsetComp.troopEid, PveSvrTroopComponent);
        if (!troopComp) {
            return "failure";
        }

        const leader = env.context.ecs.getComponent(troopComp.leaderEid, PveSvrElementComponent)!;

        const movement = env.owner.getComponent(PveSvrMovementComponent)!;
        const transform = env.owner.transform;

        const p1 = tmpTarget;
        const p0 = transform.position;

        // 设置期望位置
        env.context.setExpectGoToTargetData(
            env.owner,
            true,
            offsetComp.offset.x,
            offsetComp.offset.z
        );

        env.context.calcFollowerPosition(leader.transform, offsetComp.offset, p1);

        if (env.context.isBlockAt(p1.x, p1.z, true)) {
            // 预期位置是阻挡，避免来回往复撞墙问题
            if (Laya.Vector3.distanceXZ(p1, p0) < 3) {
                env.context.moveStop(env.owner.eid);
                env.context.playAnim(env.owner.eid, AnimName.IDLE);
                return "success";
            }
        }

        const distance = Laya.Vector3.distanceXZ(p1, p0);
        if (distance <= 0.05) {
            if (movement.updateType === MovementUpdateType.VELOCITY) {
                movement.velocity.setValue(0, 0, 0);
                const leaderMovement = leader.getComponent(PveSvrMovementComponent)!;
                if (leaderMovement.state === MovementState.STOP) {
                    env.context.moveStop(env.owner.eid);
                    env.context.playAnim(env.owner.eid, AnimName.IDLE);
                } else {
                    env.context.moveVolecity(env.owner, movement.velocity); //原地空跑？
                }
            }
        } else {
            const speed = distance / PveSvrAiSystem.TICK;
            let currSpeed = Math.max(movement.velocity.length(), BattleConf.PVE.MOVE_SPEED);
            if (currSpeed < speed) {
                currSpeed += 0.1;
            } else {
                currSpeed = speed;
            }
            if (currSpeed > BattleConf.PVE.MAX_FOLLOW_SPEED) {
                currSpeed = BattleConf.PVE.MAX_FOLLOW_SPEED;
            }
            calcVelocity(p0, p1, currSpeed, tmpVelocity);
            env.context.moveVolecity(env.owner, tmpVelocity);
        }

        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "FollowHero",
            type: "Action",
            desc: "跟随主将",
            doc: `
                + 跟随自身主将`,
        };
    }
}
