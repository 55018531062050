import { chat } from "../../../def/auto/proto";
import { Vo } from "../../../misc/vo/Vo";

export class ChatRoleVo extends Vo<never, chat.ChatRole> {
    protected declare _data: chat.ChatRole;

    constructor(data: chat.ChatRole) {
        super(undefined!);
        this._data = data;
    }

    override clone(): ChatRoleVo {
        return new ChatRoleVo(this._data);
    }

    override get key() {
        return this._data.rid;
    }

    get rid() {
        return this._data.rid;
    }

    get name() {
        return this._data.name;
    }
}
