/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { AllianceAvatar } from "./AllianceAvatar";

/**
 * resources/prefab/alliance/AllianceMemberLeader.lh
 */
export class AllianceMemberLeaderBase extends Laya.Box {
    public avatar!: AllianceAvatar;
    public leaderName!: Laya.Label;
    public stateText!: Laya.Label;
    public impeachCount!: Laya.Label;
    public levelText!: Laya.Label;
}

