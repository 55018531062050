/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/battle/pvp-nvn/camp-info-my.lh
 */
export class PnvnCampInfoUIBase extends Laya.Box {
    public icon!: Laya.Image;
    public hp!: Laya.Image;
    public hpTxt!: Laya.Label;
}

