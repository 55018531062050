import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { SystemEvent } from "../../misc/system-event";
import { ui } from "../../misc/ui";
import { CharacterCard, CharacterCardData } from "./ui-runtime/character/CharacterCard";
import {
    CharacterSelectTab,
    CharacterSelectTabConf,
} from "./ui-runtime/character/CharacterSelectTab";
import { CharacterSwitchTab } from "./ui-runtime/character/CharacterSwitchTab";
import { UICharacter } from "./ui-runtime/character/UICharacter";
import { UICharacterDetailArgs } from "./ui-runtime/detail/UICharacterDetail";
import { HeroVo } from "./vo/HeroVo";

const { regClass, property } = Laya;

@regClass('ZB9Zn0nDRgiVQWvzfqfnpg')
export class UICharacterMediator extends Mediator {
    public declare owner: UICharacter;

    private selectedJobIdxArr: number[] = [];

    override onAwake() {
        super.onAwake();

        this.owner.boxLeader.on(Laya.Event.CLICK, this, this.onClickBoxLeader);
        this.owner.btnHandbookReward.clickHandler = new Laya.Handler(
            this,
            this.onClickBtnHandbookReward
        );

        this.owner.listSwitch.selectedIndex = -1;
        this.owner.listSwitch.selectHandler = new Laya.Handler(this, this.onSelectSwitchTab);
        this.owner.listSwitch.renderHandler = new Laya.Handler(this, this.onRenderSwitchTab);
        this.owner.listSwitch.array = [["列表"], ["图鉴"]];

        this.owner.listSelect.selectedIndex = -1;
        this.owner.listSelect.selectHandler = new Laya.Handler(this, this.onSelectJobTab);
        this.owner.listSelect.renderHandler = new Laya.Handler(this, this.onRenderJobTab);
        this.owner.listSelect.array = CharacterSelectTabConf;

        this.owner.listCharacter.selectedIndex = -1;
        this.owner.listCharacter.selectHandler = new Laya.Handler(this, this.onSelectCharacterCard);
        this.owner.listCharacter.renderHandler = new Laya.Handler(this, this.onRenderCharacterCard);
        this.owner.listCharacter.array = [];
        this.listenEvens();

        if (app.service.hero.bLoaded) {
            this.onCharacterRefresh();
        }

        this.onMoneyUpdate();
    }

    private listenEvens() {
        this.$(app).on(SystemEvent.CHARACTER.SVR_LOAD, this.onCharacterRefresh, this);
        this.$(app).on(SystemEvent.CHARACTER.SVR_HERO_UPDATE, this.onCharacterRefresh, this);
        this.$(app).on(SystemEvent.CHARACTER.SVR_HANDBOOK_UPDATE, this.onCharacterRefresh, this);
        this.$(app).on(SystemEvent.CHARACTER.SVR_FAME_UPDATE, this.refreshHandbookContent, this);
        this.$(app).on(SystemEvent.USER_MONEY_UPDATE, this.onMoneyUpdate, this);
    }

    private onRenderSwitchTab(cell: CharacterSwitchTab, index: number) {
        cell.lblList.text = this.owner.listSwitch.array[index][0];
        if (index === this.owner.listSwitch.array.length - 1) {
            this.owner.listSwitch.renderHandler = null;
            this.owner.listSwitch.selectedIndex = 0;
        }
    }

    private onSelectSwitchTab(index: number) {
        const childCount = this.owner.listSwitch.array.length;
        for (let i = 0; i < childCount; i++) {
            const cell = this.owner.listSwitch.getCell(i) as CharacterSwitchTab;
            cell.onSelect(i === index, i);
        }

        const bHandbook = index === 1;
        this.owner.boxLeader.active = this.owner.boxLeader.visible = !bHandbook;

        this.owner.boxFame.visible = this.owner.boxFame.active = bHandbook;

        this.owner.imgHandbookTitle.visible = bHandbook;

        this.owner.btnHandbookReward.active = this.owner.btnHandbookReward.visible = bHandbook;

        if (bHandbook) {
            this.refreshHandbookContent();
        } else {
            this.refreshLeaderCard(app.service.hero.getLeaderHero());
        }

        //character card logic
        this.onSelectJobTab(0);
    }

    private onRenderJobTab(cell: CharacterSelectTab, index: number) {
        const data = this.owner.listSelect.array[index];
        cell.onRender(index, data);
        if (index === this.owner.listSelect.array.length - 1) {
            this.owner.listSelect.renderHandler = null;
            this.owner.listSelect.selectedIndex = 0;
        }
    }

    private onSelectJobTab(index: number) {
        if (index < 0) {
            return;
        }
        if (index === 0) {
            this.selectedJobIdxArr.length = 0;
        } else {
            const selectedJobIdx = this.selectedJobIdxArr.indexOf(index);
            if (selectedJobIdx >= 0) {
                this.selectedJobIdxArr.splice(selectedJobIdx, 1);
            } else {
                this.selectedJobIdxArr.push(index);
            }
        }

        const childCount = this.owner.listSelect.array.length;
        for (let i = 0; i < childCount; i++) {
            const cell = this.owner.listSelect.getCell(i) as CharacterSelectTab;
            cell.onSelect(i === index, i);
            if (i === 0) {
                cell.onSelect(this.selectedJobIdxArr.length === 0, i);
            } else {
                cell.onSelect(
                    this.selectedJobIdxArr.length > 0 && this.selectedJobIdxArr.indexOf(i) >= 0,
                    i
                );
            }
        }
        this.owner.listSelect.selectedIndex = -1;

        this._resetListCharacterDataSource();
    }

    private onCharacterRefresh() {
        // 1. refresh leader
        const isHandbook = this.owner.listSwitch.selectedIndex === 1;
        if (!isHandbook) {
            const service = app.service.hero;
            const leader = service.getLeaderHero();
            if (!leader) {
                console.error("no leader");
            } else {
                this.refreshLeaderCard(leader);
            }
        }

        // 2. refresh list
        this._resetListCharacterDataSource();
    }

    private refreshLeaderCard(leader: HeroVo) {
        console.log("refreshLeaderCard");
        this.owner.lblLeaderName.text = leader.name;
        this.owner.lblLeaderLv.text = leader.lv.toString();

        const imgStars = [
            this.owner.imgLeaderStar0,
            this.owner.imgLeaderStar1,
            this.owner.imgLeaderStar2,
            this.owner.imgLeaderStar3,
            this.owner.imgLeaderStar4,
        ];
        for (let i = 0; i < imgStars.length; i++) {
            imgStars[i].active = imgStars[i].visible = i < leader.star;
        }

        this.owner.imgLeader.skin = leader.iconFull ?? "";
    }

    private onSelectCharacterCard(index: number) {
        // do nothing
    }

    private refreshHandbookContent() {
        const fameStatus = app.service.hero.calcuFameStatus();
        // console.log("fameStatus", fameStatus);
        const bCanReward = fameStatus.curFame >= fameStatus.nextRecvFameStageRow.require_fame;
        const bRecvMaxStage =
            fameStatus.recvFameStage === app.service.table.hero.conf.MAX_FAME_STAGE;

        this.owner.lblFameProgress.active = this.owner.lblFameProgress.visible = !bRecvMaxStage;
        this.owner.lblFameDesc.ubb = true;
        if (bRecvMaxStage) {
            this.owner.lblFameDesc.text = "已领取完所有奖励";
            this.owner.boxFameReward.gray = false;
            this.owner.sprFameRedPoint.visible = false;
            this.owner.proFame.value = 1;
        } else {
            // 需要根据领取状态显示不同的文案
            this.owner.lblFameDesc.text = bCanReward
                ? "可领取收集奖励"
                : `还差 [b]${Math.max(
                      0,
                      fameStatus.nextRecvFameStageRow.require_fame - fameStatus.curFame
                  )}[/b] 名声可领取收集奖励`;
            // console.log("fameStatus text", this.owner.lblFameDesc.text);
            this.owner.lblFameProgress.text = `(${fameStatus.curFame}/${fameStatus.nextRecvFameStageRow.require_fame})`;
            this.owner.proFame.value = Math.min(
                1,
                fameStatus.curFame / fameStatus.nextRecvFameStageRow.require_fame
            );
            this.owner.boxFameReward.gray = !bCanReward;
            this.owner.sprFameRedPoint.visible = bCanReward;
        }

        const handbookArr = app.service.hero.getHandbookHeroList([]);
        const allHandbookCount = handbookArr.length;
        const collectedHandbookCount = handbookArr.filter((v) => v.isUnlock).length;
        this.owner.lblCollectProgress.ubb = true;
        this.owner.lblCollectProgress.text = `将领收集：[b]${collectedHandbookCount}/${allHandbookCount}[/b]`;

        this.owner.boxFameReward.offAll(Laya.Event.CLICK);
        this.owner.boxFameReward.on(Laya.Event.CLICK, this, () => {
            if (bRecvMaxStage) {
                return;
            }
            if (bCanReward) {
                app.service.hero.requestFameReward();
            } else {
                app.ui.show(ui.CHARACTER_FAME_REWARD);
            }
        });
    }

    private onRenderCharacterCard(cell: CharacterCard, index: number) {
        const vo = this.owner.listCharacter.array[index];
        cell.onRender(vo, index);
    }

    private _resetListCharacterDataSource() {
        const isHandbook = this.owner.listSwitch.selectedIndex === 1;

        const heroList = app.service.hero.getNormalHeroList(this.selectedJobIdxArr);
        const list: CharacterCardData[] = [];
        if (isHandbook) {
            const handbookList = app.service.hero.getHandbookHeroList(this.selectedJobIdxArr);
            handbookList.forEach((hb) => {
                const character = heroList
                    .filter((v) => {
                        return v.id === hb.id && v.star === hb.star; //只有历史最高星的角色才显示
                    })
                    .sort((a, b) => b.star - a.star)[0];
                list.push({ type: "handbook", character, handbook: hb });
            });
        } else {
            heroList.forEach((v) => {
                list.push({ type: "character", character: v });
            });
        }

        this.owner.listCharacter.array = list;
    }

    private onMoneyUpdate() {
        const itemId = app.service.table.hero.conf.UPGRADE_LEVEL_ITEM_ID;
        const itemCount = app.service.user.moneyVoMap.get(itemId)?.count ?? 0;
        this.owner.lblResValue0.text = `${itemCount}`;
    }

    private onClickBoxLeader() {
        const leaderVo = app.service.hero.getLeaderHero();
        if (!leaderVo) {
            console.error("no leader");
            return;
        }
        const data: UICharacterDetailArgs = {
            type: "character",
            character: leaderVo,
        };
        app.ui.show(ui.CHARACTER_DETAIL, data);
    }

    private onClickBtnHandbookReward() {
        const handbooks = app.service.hero.getHandbookHeroList([]);
        const idList = handbooks
            .filter((v) => v.isUnlock && v.star! > v.awardedStar!)
            .map((v) => v.id);
        if (idList.length === 0) {
            app.ui.toast("没有可领取的奖励");
            return;
        }
        app.service.hero.requestHandbookReward(idList);
    }
}
