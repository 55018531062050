import { app } from "../../../../../app";
import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { BattleAid, BattleEntityType } from "../../../../../def/auto/battle";
import { BattleEntityRow } from "../../../../../def/table";
import { TableUtil } from "../../../../table/TableUtil";
import { SkillSelectorArgs } from "../../PveDefs";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrElementComponent } from "../../ecs/components/PveSvrElementComponent";
import { PveSvrSkillWarningComponent } from "../../ecs/components/PveSvrSkillWarningComponent";
import { PveSvrTransformComponent } from "../../ecs/components/PveSvrTransformComponent";

interface NodeArgs {
    shape: "fan" | "rect";
    duration: number;
    distance: number;
    width: number;
    battle_entity: number;
}

type NodeInput = [Laya.Vector3 | undefined, number | undefined];

const tempPosition = new Laya.Vector3();

/** 创建技能预警 */
export class CreateSkillWarning extends Process {
    override init(node: Node): void {
        // const args = node.args as CreateSkillWarningArgs;
        // if(!app.service.table.skillSelector[args.selector_cfg_id])
        // {
        //     throw new Error(`no skillSelector id: ${args.selector_cfg_id}`);
        // }
    }

    override run(node: Node, env: AiTreeEnv): Status {
        const args = node.args as unknown as NodeArgs;
        let [position, rotation] = env.input as NodeInput;
        // todo: 应该把这个逻辑提取到一个函数里，通过PveServer里调用

        // 不输入时，默认使用自己的位置和旋转
        let transformComp: PveSvrTransformComponent | undefined;
        let ownerEntityId = 0;
        if (position === undefined) {
            ownerEntityId = env.owner.eid;
            transformComp = env.owner.getComponent(PveSvrTransformComponent);
            if (transformComp) {
                tempPosition.cloneFrom(transformComp.position);
                position = tempPosition;
            }
        }
        if (rotation === undefined) {
            ownerEntityId = env.owner.eid;
            if (!transformComp) {
                transformComp = env.owner.getComponent(PveSvrTransformComponent);
            }
            if (transformComp) {
                rotation = transformComp.rotation;
            }
        }

        // 战斗实体
        let battleEntityRow: BattleEntityRow;
        if (args.battle_entity) {
            battleEntityRow = app.service.table.battleEntity[args.battle_entity];
        } else {
            battleEntityRow = TableUtil.getRow(app.service.table.battleEntity, {
                etype: BattleEntityType.SKILL_WARNING,
            })!;
        }

        const skillSelectorArgs: SkillSelectorArgs = {
            shape: args.shape,
            distance: args.distance,
            width: args.width,
            duration: args.duration,
            ownerEntityId: ownerEntityId,
        };
        const pveSvr = env.context;
        // 创建一个预警显示对象
        const skillWarningEntity = pveSvr.ecs.createEntity(
            pveSvr.obtainEid(),
            BattleEntityType.SKILL_WARNING
        );
        const element = skillWarningEntity.addComponent(PveSvrElementComponent);
        element.tid = 0;
        element.aid = BattleAid.NEUTRAL;
        element.data = battleEntityRow;

        const transform = skillWarningEntity.addComponent(PveSvrTransformComponent);
        transform.position.set(position!.x, position!.y, position!.z);
        transform.rotation = rotation ?? 0;

        const swComp = skillWarningEntity.addComponent(PveSvrSkillWarningComponent);
        swComp.startTime = pveSvr.time;
        swComp.skillSelectorArgs = skillSelectorArgs;

        pveSvr.sender.createElement({
            eid: element.eid,
            etype: element.etype,
            key: element.key,
            aid: element.aid,
            teid: element.data.id,
            tid: element.tid,
            hp: -1,
            maxHp: -1,
            position: transform.position,
            rotation: transform.rotation,
            skillSelectorArgs: skillSelectorArgs,
            level: -1,
        });
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "CreateSkillWarning",
            type: "Action",
            desc: "创建技能预警",
            args: [
                {
                    name: "shape",
                    type: "enum",
                    desc: "形状",
                    options: [
                        { name: "扇形", value: "fan" },
                        { name: "矩形", value: "rect" },
                    ],
                },
                { name: "duration", type: "float", desc: "持续时间(秒)" },
                { name: "distance", type: "float", desc: "距离" },
                { name: "width", type: "float", desc: "宽度/角度" },
                { name: "battle_entity", type: "int?", desc: "战斗实体" },
            ],
            input: ["位置?", "旋转角度?"],
            doc: `
            创建技能预警提示
            + 位置和旋转角度不输入时，则取树当前归属的实体的位置和旋转。
            + 技能预警位置和旋转绑定在行为树所有者位置
            + 矩形情况下， 宽度以地图格子为单位。
            + 扇形情况下， 以角度为单位，表示扇形角度。
            + 持续时间结束时，自动移除预警提示实体。
            `,
        };
    }
}
