import { app } from "../../app";
import { Service } from "../../core/service";
import { PlotTheatreActionType } from "../../def/auto/battle";
import { SystemEvent } from "../../misc/system-event";
import { ui } from "../../misc/ui";
import { PlotDialogueWinMediator } from "../plot/PlotDialogueWinMediator";
import { PlotTheatreActionsLoMap } from "./PlotTheatreActionsLoMap";
import { PlotTheatreLoMap } from "./PlotTheatreLoMap";
import { PlotTheatreActionsLo } from "./lo/PlotTheatreActionsLo";

export class PlotTheatreService extends Service {
    showPlotTheatreing: boolean = false;

    private _playActionIndex: number = 0;

    private _actions!: PlotTheatreActionsLo[];

    readonly plotTheatreLoMap: PlotTheatreLoMap = new PlotTheatreLoMap();
    readonly plotTheatreActionsLoMap: PlotTheatreActionsLoMap = new PlotTheatreActionsLoMap();

    override onCreate(): void {}

    override onStartInit(): void {
        this.plotTheatreLoMap.getAllLo();
        this.plotTheatreActionsLoMap.getAllLo();
    }

    override onDestroy(): void {}

    /** 显示剧场剧情 */
    public showPlotTheatre(id: number): void {
        if (this.showPlotTheatreing) {
            console.log("正在播放剧情中");
            return;
        }

        app.event(SystemEvent.ON_START_PLOT_THEATRE);

        app.ui.show(ui.UI_PLOT_THEATRE_ALPHA);

        this.showPlotTheatreing = true;
        this._playActionIndex = 0;
        // app.ui.toast("开始播放剧情:" + id);

        const lo = this.plotTheatreLoMap.get(id)!;
        this._actions = lo.actions;

        this.playNextAction();

        // Laya.timer.once(2000, this, () => {
        //     this.showPlotTheatreing = false;
        //     app.ui.toast("结束播放剧情:" + id);
        // });
    }

    private playNextAction(): void {
        if (this._playActionIndex >= this._actions.length) {
            this.endPlotTheatre();
            return;
        } else {
            const action = this._actions[this._playActionIndex];
            this.playAction(action);
        }
    }

    private playAction(action: PlotTheatreActionsLo): void {
        const type = action.type;
        if (type === PlotTheatreActionType.SHOW_PLOT) {
            // 播放对白
            PlotDialogueWinMediator.showPlot(
                action.plotId,
                new Laya.Handler(this, this.onShowPlotComp)
            );
        } else if (type === PlotTheatreActionType.PLAY_UI_EFFECT) {
            if (action.uiEffectId === 1) {
                app.ui.show(ui.UI_EFFECT_OPEN_EYE);
            }
            // 播放UI特效
            Laya.timer.once(action.duraction * 1000, this, this.onWaitComp);
        } else if (type === PlotTheatreActionType.PVE_MOVE_CAMERA) {
            const toX: number = action.customData?.to_pos![0] ?? 0;
            const toZ: number = action.customData?.to_pos![1] ?? 0;
            // 移动pve的摄像机
            app.event(SystemEvent.PVE.CAMERA_MOVE_TO, [toX, toZ]);
            Laya.timer.once(action.duraction * 1000, this, this.onWaitComp);
        } else if (type === PlotTheatreActionType.WAIT) {
            // 等待一段时间
            Laya.timer.once(action.duraction * 1000, this, this.onWaitComp);
        }
    }

    private onWaitComp(): void {
        this._playActionIndex++;
        this.playNextAction();
    }

    private onShowPlotComp(): void {
        this._playActionIndex++;
        this.playNextAction();
    }

    private endPlotTheatre(): void {
        // app.ui.toast("结束播放剧情:" + this._curPlotTheatreLo!.key);
        this.showPlotTheatreing = false;
        this._playActionIndex = 0;
        app.event(SystemEvent.PVE.RESET_CAMERA_TO_HERO);
        app.event(SystemEvent.ON_END_PLOT_THEATRE);
    }
}
