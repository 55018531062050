import { BuffBaseData } from "../BuffBase";

export enum BuffFunctionalType {
    None = 0,
    DecreaseFinalHurt = 1,
}

export class BuffFunctionalData extends BuffBaseData {
    readonly fromBuffTreeId: number = 0;

    readonly type: BuffFunctionalType = BuffFunctionalType.None;

    readonly value: number = 0;

    constructor(
        fromBuffTreeId: number,
        type: BuffFunctionalType,
        value: number,
        overdueTime: number = -1
    ) {
        super(overdueTime);
        this.type = type;
        this.value = value;
        this.fromBuffTreeId = fromBuffTreeId;
    }
}
