/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/common/attribute/AttrrBase.lh
 */
export class AttrBaseBase extends Laya.Box {
    public imgBg!: Laya.Image;
    public labAtt!: Laya.Label;
    public labAttValue!: Laya.Label;
}

