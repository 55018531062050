import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { ServerType } from "../../def/auto/cluster";
import { errcode } from "../../def/auto/protocol";
import { SystemEvent } from "../../misc/system-event";
import { ui } from "../../misc/ui";
import { UIAllianceHome } from "../alliance/ui-runtime/UIAllianceHome";
import { PveUI } from "../battle/ui-runtime/PveUI";
import { PvpUI } from "../battle/ui-runtime/PvpUI";
import { LoginPvpSelectorArgs } from "../login/ui-runtime/UILoginPvpSelector";
import { PvpServerVo } from "../login/vo/PvpServerVo";
import { UseHomeUIInPve } from "./UseHomeUIInPve";
import { HomeNaviMenuItem } from "./ui-runtime/HomeNaviMenuItem";
import { HomeUI } from "./ui-runtime/HomeUI";

const enum MenuAction {
    PVE,
    PVP,
    ALLIANCE,
    TROOP,
    CHEST,
}

@Laya.regClass('vucHsvLtQa-Utc8b2g3vBQ')
export class HomeMediator extends Mediator {
    declare owner: HomeUI;

    private _currentBtn?: HomeNaviMenuItem;
    private _pve?: PveUI;
    private _pvp?: PvpUI;
    private _alliance?: UIAllianceHome;
    private _loading: boolean = false;

    private _dialog: Laya.Dialog | null = null;

    private _menuAction: MenuAction = MenuAction.PVE;

    override onAwake() {
        this._initBtn();
        this._initServiceEvent();
        this._initNaviMenu();

        // 默认显示主线任务
        this._openPve();

        this.$(app).on(SystemEvent.PVP.ON_LOAD_PVP_NVN, this._loadPvpNvN, this);
        this.$(app).on(SystemEvent.PVP.ON_UNLOAD_PVP_NVN, this._unloadPvpNvN, this);

        this.$(app).on(SystemEvent.SWITCH_PVP_SUCCESS, this._onSwitchPvpSuccess, this);
        this.$(app).on(SystemEvent.ALLIANCE.OPEN_ALLIANCE, this._openAlliance, this);
    }

    override onDestroy() {
        this._pve?.destroy();
        this._pve = undefined;
        this._pvp?.destroy();
        this._pvp = undefined;
        super.onDestroy();
    }

    private _initServiceEvent() {
        this.$(app).on(SystemEvent.UI.TROOP_OPEN, (val: { tabIndex: number }) => {
            this._openTroop(val);
        });
    }

    override onKeyDown(evt: Laya.Event): void {
        if (evt.ctrlKey && evt.keyCode === Laya.Keyboard.B) {
            app.ui.show(ui.GM);
        }
    }

    private _initBtn() {
        this.owner.btnMoreMenu.on(Laya.Event.CLICK, () => {
            this.owner.rightMenu.visible = true;
        });

        //this.owner.SavePveStateButton.on(Laya.Event.CLICK, () => {});
    }

    //-------------------------------------------------------------------------
    // 处理导航功能
    //-------------------------------------------------------------------------
    private _initNaviMenu() {
        const naviMenu = this.owner.naviMenu;
        naviMenu.btnChest.on(Laya.Event.CLICK, async () => {
            if (this._loading) {
                return;
            }
            if (this._currentBtn !== naviMenu.btnChest) {
                this._closeBox();
                this._activatePveOrPvp(false);
                this._currentBtn = naviMenu.btnChest;
                this.owner.taskBox.visible = false;
                this._currentBtn.selected = true;
                this._dialog = await app.ui.show(ui.CHEST);
            }
        });
        naviMenu.btnFight.on(Laya.Event.CLICK, async () => {
            if (this._loading) {
                return;
            }
            if (this._currentBtn !== naviMenu.btnFight) {
                if (app.service.user.serverType === ServerType.PVE) {
                    const serversResponse = await app.service.user.requestLoadPvpServers();
                    if (serversResponse.err !== errcode.OK) {
                        app.service.network.toastError(serversResponse.err);
                        return;
                    }
                    const servers = serversResponse.servers.map((v) => new PvpServerVo(v));
                    if (servers.length > 1) {
                        app.ui.show(ui.LOGIN_PVP_SELECTOR, {
                            list: servers,
                            callback: (server: PvpServerVo) => {
                                this._joinPvpServer(server);
                            },
                        } as LoginPvpSelectorArgs);
                    } else {
                        this._joinPvpServer(servers[0]);
                    }
                } else {
                    this._openPvp();
                }
            }
        });
        naviMenu.btnMain.on(Laya.Event.CLICK, () => {
            if (this._loading) {
                return;
            }
            if (this._currentBtn !== naviMenu.btnMain) {
                this._activatePveOrPvp(true);
                this.owner.taskBox.visible = true;
                this._closeBox();
                this._currentBtn = naviMenu.btnMain;
                this._currentBtn.selected = true;
                this._loadPve();
            }
        });
        // naviMenu.btnSoldier.on(Laya.Event.CLICK, async () => {
        //     this._openTroop();
        // });
        naviMenu.btnAlliance.on(Laya.Event.CLICK, () => {
            if (app.service.alliance.allianceInfo?.allianceId) {
                // 已经再盟内
            } else if (app.service.alliance.commonInfo?.lastQuitTime) {
                // 曾经入过盟
            } else {
                // 未入过盟
                app.ui.show(ui.ALLIANCE_INTRO);
            }
        });
        naviMenu.btnRole.on(Laya.Event.CLICK, async () => {
            if (this._currentBtn !== naviMenu.btnRole) {
                this._closeBox();
                this._activatePveOrPvp(false);
                this.owner.taskBox.visible = false;
                this._currentBtn = naviMenu.btnRole;
                this._currentBtn.selected = true;
                this._dialog = await app.ui.show(ui.CHARACTER);
            }
        });

        this.owner.btnDrawCard.on(Laya.Event.CLICK, async () => {
            app.ui.show(ui.DRAW_CARD);
        });

        this._currentBtn = naviMenu.btnMain;
        this._currentBtn.selected = true;
    }

    private _loadPvpNvN() {
        this._pvp?.destroy();
        this._pvp = undefined;
        this.owner.visible = false;
    }

    private _unloadPvpNvN() {
        this._loadPvp();
        this.owner.visible = true;
        // TODO：需要设置为false?
        // this.owner.active = false;
    }

    private async _openTroop(openData?: { tabIndex: number }) {
        // if (this._currentBtn !== this.owner.naviMenu.btnSoldier) {
        //     this._closeBox();
        //     this._activatePveOrPvp(false);
        //     // this._currentBtn = this.owner.naviMenu.btnSoldier;
        //     this._currentBtn.selected = true;
        //     // this._dialog = await app.ui.show(ui.TROOP, openData);//打开 troop new UI???
        // }
    }

    private _closeBox() {
        if (this._currentBtn === this.owner.naviMenu.btnMain) {
            this.owner.taskBox.stopCom();
        } else {
            this.owner.taskBox.enableCom();
        }
        if (this._currentBtn) {
            this._currentBtn.selected = false;
            this._currentBtn = undefined;
            if (this._dialog) {
                this._dialog.destroy();
                this._dialog = null;
            }
        }
    }

    private _activatePveOrPvp(active: boolean) {
        if (this._pve) {
            if (this._pve.active !== active) {
                this._pve.active = active;
                this._pve.visible = active;
                this._pve.scene3D.visible = active;
                this._pve.scene3D.active = active;
            }
        } else if (this._pvp) {
            if (this._pvp.active !== active) {
                this._pvp.active = active;
                this._pvp.visible = active;
                this._pvp.scene3D.visible = active;
                this._pvp.scene3D.active = active;
            }
        }
        // this.owner.bg.visible = !active;
    }

    private _onChangeMenu(action: MenuAction) {
        switch (action) {
            case MenuAction.PVE:
                break;
            case MenuAction.PVP:
                break;
            case MenuAction.ALLIANCE:
                break;
            case MenuAction.TROOP:
            case MenuAction.CHEST:
                break;
        }
    }

    //-------------------------------------------------------------------------
    // 处理PVE功能
    //-------------------------------------------------------------------------
    private _openPve() {
        this._loadPve();
    }

    private _loadPve() {
        if (!this._pve) {
            this._loading = true;
            app.ui.load(ui.PVE)?.then((scene) => {
                this._loading = false;
                // this._closeBox();
                this.owner.btnMoreMenu.visible = true;
                // this.owner.moneyBox.visible = true;
                this._pvp?.destroy();
                this._pvp = undefined;
                this._pve = scene as PveUI;
                const menuCom = this._pve.addComponent(UseHomeUIInPve);
                menuCom.chestBtn = this.owner.naviMenu.btnChest;
                menuCom.homeScene = this.owner;
                this._pve.width = this.owner.width;
                this._pve.height = this.owner.height;
                this.owner.battle.addChild(this._pve);
                Laya.stage.addChildAt(this._pve.scene3D, 0);
            });
        }
    }

    private _setPveActive(active: boolean) {
        if (this._pve) {
            if (this._pve.active !== active) {
                this._pve.active = active;
                this._pve.visible = active;
                this._pve.scene3D.visible = active;
                this._pve.scene3D.active = active;
            }
        }
    }

    //-------------------------------------------------------------------------
    // 处理PVP功能
    //-------------------------------------------------------------------------
    private _openPvp() {
        const naviMenu = this.owner.naviMenu;
        this._activatePveOrPvp(true);
        this.owner.taskBox.visible = false;
        this._closeBox();
        this._currentBtn = naviMenu.btnFight;
        this._currentBtn.selected = true;
        this._loadPvp();
    }

    private _loadPvp() {
        if (!this._pvp) {
            this._loading = true;
            app.ui.load(ui.PVP)?.then((scene) => {
                this._loading = false;
                // this._closeBox();
                this._pve?.destroy();
                this._pve = undefined;
                this._pvp = scene as PvpUI;
                this._pvp.width = this.owner.width;
                this._pvp.height = this.owner.height;

                this.owner.btnMoreMenu.visible = false;
                // this.owner.moneyBox.visible = false;

                this.owner.battle.addChild(this._pvp);
                Laya.stage.addChildAt(this._pvp.scene3D, 0);
            });
        }
    }

    private _setPvpActive(active: boolean) {
        if (this._pvp) {
            if (this._pvp.active !== active) {
                this._pvp.active = active;
                this._pvp.visible = active;
                this._pvp.scene3D.visible = active;
                this._pvp.scene3D.active = active;
            }
        }
    }

    private async _joinPvpServer(data: PvpServerVo) {
        // TODO： 显示连接对框
        const response = await app.service.user.requestJoinPvpServer({
            name: data.name,
        });
        if (response.err !== errcode.OK) {
            app.service.network.toastError(response.err);
            return;
        }
        app.service.network.close();
        app.service.login.switchToPvp(response.url);
    }

    private _onSwitchPvpSuccess() {
        this._openPvp();
    }

    //-------------------------------------------------------------------------
    // 处理联盟功能
    //-------------------------------------------------------------------------
    private _openAlliance() {}
}
