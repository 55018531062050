const { regClass, property } = Laya;

export enum RotationEulerAxis {
    x = 0,
    y = 1,
    z = 2,
}

@regClass('SpQg1FA2QDmye95Zn9jc2g')
export class SpringAnimation extends Laya.Script {
    /** 初始速度 */
    @Laya.property({ type: Number })
    private initVelocity: number = 30;

    /** 当前速度 */
    private velocity: number = 0;

    /** 当前位移值 */
    private displacement: number = 0;

    /** 弹性(值越大幅度越大) */
    @Laya.property({ type: Number })
    private spring: number = 1800;

    /** 阻尼(值越大越快静止) */
    @Laya.property({ type: Number })
    private damp: number = 20;

    @Laya.property({ type: RotationEulerAxis })
    private axis: RotationEulerAxis = RotationEulerAxis.z;

    @Laya.property({ type: Number })
    private maxEulerValue: number = 15;

    /** 开始弹簧动画 */
    startSpringAni(): void {
        this.velocity = this.initVelocity;
    }

    override onUpdate(): void {
        if (this.velocity === 0) {
            return;
        }
        const dt = Laya.timer.delta / 1000;
        const val = this.updateSpring(dt);
        const o = this.owner;
        if (o instanceof Laya.Sprite3D) {
            const tf = o.transform;
            if (this.axis === RotationEulerAxis.z) {
                tf.localRotationEulerZ = this.maxEulerValue * val;
                if (tf.localRotationEulerZ < 0.01) {
                    tf.localRotationEulerZ = 0;
                    this.velocity = 0;
                }
            } else if (this.axis === RotationEulerAxis.x) {
                tf.localRotationEulerX = this.maxEulerValue * val;
                if (tf.localRotationEulerX < 0.01) {
                    tf.localRotationEulerX = 0;
                    this.velocity = 0;
                }
            } else if (this.axis === RotationEulerAxis.y) {
                tf.localRotationEulerY = this.maxEulerValue * val;
                if (tf.localRotationEulerY < 0.01) {
                    tf.localRotationEulerY = 0;
                    this.velocity = 0;
                }
            }
        }
    }

    private updateSpring(dt: number): number {
        if (this.velocity > 0) {
            const force = -this.spring * this.displacement - this.damp * this.velocity;
            this.velocity += force * dt;
            this.displacement += this.velocity * dt;
        } else {
            return 0;
        }
        return this.displacement;
    }
}
