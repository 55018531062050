const { regClass } = Laya;
import { CharacterCardData } from "../character/CharacterCard";
import { UICharacterDetailBase } from "./UICharacterDetail.generated";

export type UICharacterDetailArgs = CharacterCardData;

@regClass('msL7edddTquOpAbq9wrDmQ')
export class UICharacterDetail extends UICharacterDetailBase {
    private _openArgs!: UICharacterDetailArgs;

    get openArgs(): UICharacterDetailArgs {
        return this._openArgs;
    }

    override open(closeOther?: boolean, param?: any): void {
        this._openArgs = param;
        super.open(closeOther, param);
    }
}
