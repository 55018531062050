const { regClass } = Laya;
import { app } from "../../../../app";
import { Reward } from "../../../../def/table";
import { ItemLo } from "../../../bag/vo/ItemLo";

import { CostMidItemIconBase } from "./CostMidItemIcon.generated";

@regClass('7wFd_XVAQfuxMoeNheURVQ')
export class CostMidItemIcon extends CostMidItemIconBase {
    public updateShow(itemLo: ItemLo, needAmount: number, hasAmount: number): void {
        this.itemIcon.setItemLo(itemLo);
        const bi: number = Math.min(hasAmount / needAmount, 1.0);
        this.progressBar.value = bi;
        this.lab_01.text = hasAmount + "/" + needAmount;
    }

    public updateWithListReward() {
        const r = this.dataSource as Reward;
        const itemLo = app.service.bag.itemLoMap.get(r.id);
        const hasAmount = app.service.bag.itemVoMap.getItemAmount(r.id);
        const needAmount = r.num;
        this.updateShow(itemLo, needAmount, hasAmount);
    }
}
