const { regClass } = Laya;
import { ItemVo } from "../vo/ItemVo";
import { BagItemBase } from "./BagItem.generated";

@regClass('LHiRa-FITCqxoc_pRxQ6DQ')
export class BagItem extends BagItemBase {
    override onAwake(): void {
        super.onAwake();
    }

    updateGoods(vo: ItemVo) {
        if (vo) {
            this.itemIcon.setItemVo(vo);
        }
    }

    set itemNumber(num: string) {
        this.labelNumber.text = num;
    }

    set itemName(name: string) {
        this.labelNumber.text = name;
    }
}
