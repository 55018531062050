import { app } from "../../../../../app";
import { Callback, Constructor } from "../../../../../core/dispatcher";
import * as ecs from "../../../../../core/ecs";
import { IVector3Like } from "../../../../../core/laya";
import { Pool } from "../../../../../core/pool";
import { tween } from "../../../../../core/tween/tween";
import {
    BattleCommand,
    BattleConf,
    BattleEntityType,
    BattleSide,
    BattleTroopState,
} from "../../../../../def/auto/battle";
import proto from "../../../../../def/auto/proto";
import { errcode, opcode } from "../../../../../def/auto/protocol";
import { res } from "../../../../../misc/res";
import { AnimName } from "../../../base/Animator";
import { PnvnContext } from "../../PnvnContext";
import { PnvnAnimationComponent } from "../components/PnvnAnimationComponent";
import { PnvnDebugBlockComponent, PnvnDebugComponent } from "../components/PnvnDebugComponent";
import { PnvnElementComponent } from "../components/PnvnElementComponent";
import { PnvnMovementComponent } from "../components/PnvnMovementComponent";
import { PnvnTransformComponent } from "../components/PnvnTransformComponent";
import { PnvnTroopComponent } from "../components/PnvnTroopComponent";

const tmpVec3Offset = new Laya.Vector3();

const makeTroopEid = (worldEid: number) => -1000000 - worldEid;

const TODO_RM_LEFT = "resources/prefab/battle/pvp-nvn/TodoRmLeftSide.lh";
const TODO_RM_RIGHT = "resources/prefab/battle/pvp-nvn/TodoRmRightSide.lh";

const SCENE_CONFIG = 1;

export class PnvnCommandSystem extends ecs.System {
    declare context: PnvnContext;

    override onCreate() {
        this.handle(opcode.battle.s2c_enter_scene, this._onEnterScene);
        this.handle(opcode.battle.notify_cmd, this._onNotifyCmd);
        this.handle(opcode.battle.notify_scene, this._onNotifyScene);

        // TODO: DEBUG
        // this.context.camera.fieldOfView = 92;
        app.service.network.ignoreLog(opcode.battle.notify_cmd);
    }

    private handle(op: number, callback: Callback) {
        this.context.$(app).on(op, callback, this);
    }

    private _onEnterScene(data: proto.battle.s2c_enter_scene) {
        if (data.err === errcode.OK) {
            data.scene?.troops?.forEach((value) => {
                this._loadTroop(value);
            });
            data.battles.forEach((value) => {
                this._loadBattleTroop(value);
            });
        }
    }

    private _onNotifyCmd(notify: proto.battle.notify_cmd) {
        if (!notify.frame) {
            return;
        }
        for (const cmd of notify.frame.cmds!) {
            if (cmd.cmdType === BattleCommand.ADD_ENTITY) {
                this._addEntity(cmd.addEntity?.entity as proto.battle.BattleEntity);
            } else if (cmd.cmdType === BattleCommand.DEL_ENTITY) {
                this._cmdDelEntity(cmd.delEntity as proto.battle.cmd_del_entity);
            } else if (cmd.cmdType === BattleCommand.ATTACK) {
                this._cmdAttack(cmd.attack as proto.battle.cmd_attack);
            } else if (cmd.cmdType === BattleCommand.MOVE_STOP) {
                this._moveStop(cmd.moveStop as proto.battle.cmd_move_stop);
            } else if (cmd.cmdType === BattleCommand.MOVE_TO) {
                this._cmdMoveTo(cmd.moveTo as proto.battle.cmd_move_to);
            } else if (cmd.cmdType === BattleCommand.FORCE_TO) {
                this._cmdForceTo(cmd.forceTo as proto.battle.cmd_force_to);
            } else if (cmd.cmdType === BattleCommand.UNDER_ATK) {
                this._cmdUnderAttack(cmd.underAtk as proto.battle.cmd_under_atk);
            } else if (cmd.cmdType === BattleCommand.PLAY_ANIM) {
                this._cmdPlayAnim(cmd.playAnim as proto.battle.cmd_play_anim);
            } else {
                console.warn(`unhandle cmd type: ${cmd.cmdType}`);
            }
        }
        if (notify.frame.debugPoints) {
            this._drawDebugs(notify.frame.debugPoints);
        }
    }

    private _addEntity(cmd: proto.battle.BattleEntity) {
        const troopEid = makeTroopEid(cmd.worldEid);
        const troop = this._findComponent(troopEid, PnvnTroopComponent, "add entity");
        if (!troop) {
            return;
        }

        let entity = this.ecs.getEntity(cmd.eid);
        if (!entity) {
            const data = app.service.table.battleEntity[cmd.entityId];
            entity = this.ecs.createEntity(cmd.eid, data.etype);
        }

        troop.state = BattleTroopState.FIGHTING;

        this._addTransformComponent(entity, troop, cmd);
        this._addMovementComponent(entity, cmd);
        this._addAnimationComponent(entity, cmd.entityId);
        this._addElementComponent(entity, troop, cmd);
        this._addDebugComponent(entity, troop);
    }

    private _cmdDelEntity(cmd: proto.battle.cmd_del_entity) {
        const entity = this.ecs.getEntity(cmd.eid);
        const element = this._findComponent(cmd.eid, PnvnElementComponent, "del entity");

        if (element) {
            const troop = this._findComponent(element.troopEid, PnvnTroopComponent, "del entity")!;
            const idx = troop.members.indexOf(cmd.eid);
            if (idx >= 0) {
                troop.members[idx] = undefined;
            }

            if (element && element.hp > 0) {
                //console.error("delete entity error:", element.eid, troop);
            }
        }
        if (
            entity &&
            (entity.etype === BattleEntityType.SOLDIER || entity.etype === BattleEntityType.HERO)
        ) {
            this.context.playAnim(cmd.eid, AnimName.DIE);
            const movement = this._findComponent(cmd.eid, PnvnMovementComponent, "del entity")!;
            movement.speed = 0;
            this.context.playAnim(cmd.eid, AnimName.DIE);
            this.ecs.delay(1, `#${cmd.eid}.die`, () => {
                this.ecs.removeEntity(cmd.eid);
            });
        } else {
            this.ecs.removeEntity(cmd.eid);
        }
    }

    private _cmdAttack(cmd: proto.battle.cmd_attack) {
        // this.context.playAnim(cmd.eid, AnimName.ATTACK);
        this.towardTo(cmd.eid, cmd.targetEid);
    }

    private _cmdPlayAnim(cmd: proto.battle.cmd_play_anim) {
        this.context.playAnim(cmd.eid, cmd.name as AnimName);
    }

    private _moveStop(cmd: proto.battle.cmd_move_stop) {
        const element = this._findComponent(cmd.eid, PnvnElementComponent, "move stop");
        if (!element) {
            return;
        }
        const troop = this._findComponent(element.troopEid, PnvnTroopComponent, "move stop")!;
        const movement = this._findComponent(cmd.eid, PnvnMovementComponent, "move stop");
        const transform = movement?.getComponent(PnvnTransformComponent);
        if (movement && transform) {
            this._calcOffset(troop, troop.curGrid, tmpVec3Offset);
            movement.speed = 0;
            transform.position.x = -cmd.y + tmpVec3Offset.x;
            transform.position.z = -cmd.x + tmpVec3Offset.z;
            transform.flags |= PnvnTransformComponent.POSITION;
            this.context.playAnim(cmd.eid, AnimName.IDLE);
        }
    }

    private _cmdMoveTo(cmd: proto.battle.cmd_move_to) {
        const element = this._findComponent(cmd.eid, PnvnElementComponent, "move to");
        if (!element) {
            return;
        }
        const troop = this._findComponent(element.troopEid, PnvnTroopComponent, "move to")!;
        this._calcOffset(troop, troop.curGrid, tmpVec3Offset);
        this._moveTo(
            element,
            cmd.speed,
            -cmd.targetY + tmpVec3Offset.x,
            -cmd.targetX + tmpVec3Offset.z
        );
    }

    private _moveTo(
        element: PnvnElementComponent,
        speed: number,
        x: number,
        z: number,
        rotation?: number
    ) {
        const movement = element.getComponent(PnvnMovementComponent)!;
        const transform = element.getComponent(PnvnTransformComponent)!;

        movement.speed = speed;
        movement.target.x = x;
        movement.target.z = z;
        movement.targetRotation = rotation;

        transform.rotation = Math.toDegree(
            Math.atan2(
                movement.target.x - transform.position.x,
                movement.target.z - transform.position.z
            )
        );
        transform.flags |= PnvnTransformComponent.ROTATION;

        this.context.playAnim(element.eid, AnimName.MOVE);
    }

    private _cmdForceTo(cmd: proto.battle.cmd_force_to) {
        const element = this._findComponent(cmd.eid, PnvnElementComponent, "force to");
        if (!element) {
            return;
        }
        const troop = this._findComponent(element.troopEid, PnvnTroopComponent, "force to")!;
        const movement = this._findComponent(cmd.eid, PnvnMovementComponent, "force to");
        if (movement) {
            movement.speed = cmd.speed;
            this._calcOffset(troop, troop.curGrid, tmpVec3Offset);
            movement.target.x = -cmd.targetY + tmpVec3Offset.x;
            movement.target.z = -cmd.targetX + tmpVec3Offset.z;
        }
    }

    private async _cmdUnderAttack(cmd: proto.battle.cmd_under_atk) {
        const transform = this._findComponent(cmd.eid, PnvnTransformComponent, "under attack");
        if (transform && cmd.subHp) {
            const element = transform.getComponent(PnvnElementComponent)!;
            element.hp -= cmd.subHp;
            const checker = transform.checker;
            const prefab = await app.loader.loadPrefab(
                cmd.critical ? res.BATTLE_HP_NUM_X : res.BATTLE_HP_NUM
            );
            if (!checker()) {
                return;
            }

            const pos = new Laya.Vector4();
            this.context.camera.worldToViewportPoint(transform.position, pos);
            const hpui = prefab.create() as Laya.Sprite;
            const hpTxt = hpui.getChildByName("anim").getChildByName("hp") as Laya.Text;
            const labels = this.context.owner.labels;
            const p = new Laya.Point(pos.x, pos.y + this.context.owner.battle.y);
            labels.globalToLocal(p, false);
            hpTxt.text = cmd.subHp.toFixed();
            hpui.pos(p.x, p.y, true);
            labels.addChild(hpui);
            hpui.scaleX = 0.5;
            hpui.scaleY = 0.5;
            tween(hpui)
                .to(0.3, { scaleX: 1, scaleY: 1 }, { easing: "bounceOut" })
                .delay(0.5)
                .call(() => {
                    tween(hpui)
                        .to(0.5, { y: hpui.y - 50 })
                        .start();
                    tween(hpui).to(0.5, { alpha: 0 }).removeSelf().start();
                })
                .start();
        }
    }

    towardTo(eid: number, target: number) {
        const obj1 = this._findComponent(eid, PnvnTransformComponent, "toward to");
        const obj2 = this._findComponent(target, PnvnTransformComponent, "toward to");
        if (obj1 && obj2) {
            const p1 = obj1.position;
            const p2 = obj2.position;
            obj1.rotation = Math.toDegree(Math.atan2(p2.x - p1.x, p2.z - p1.z));
            obj1.flags |= PnvnTransformComponent.ROTATION;
        }
    }

    private async _drawDebugs(points: proto.battle.DebugInfo[]) {
        const checker = () => !this.ecs.destroyed;
        const prefab = await app.loader.loadPrefab(res.battle.PVP_DEBUG_TILE);
        if (!checker()) {
            return;
        }

        const debugComp = this.ecs.getSingletonComponent(PnvnDebugComponent);
        Pool.free(res.battle.PVP_DEBUG_TILE, debugComp.debugs);
        points.forEach((value) => {
            const debug = Pool.obtain(
                res.battle.PVP_DEBUG_TILE,
                () => prefab.create() as Laya.Sprite3D
            );
            const position = debug.transform.position;
            position.x = value.y;
            position.z = value.x;
            debugComp.debugs.push(debug);
            debug.transform.position = position;
            debug.transform.localScale = new Laya.Vector3(0.2, 0.2, 0.2);
            this.context.scene3D.addChild(debug);
        });
    }

    private _onNotifyScene(notify: proto.battle.notify_scene) {
        notify.newTroops.forEach((v) => this._loadTroop(v));
        notify.updateTroops.forEach((v) => this._updateTroop(v));
        notify.delTroops.forEach((worldEid) => this._deleteTroop(worldEid));
    }

    private _loadTroop(info: proto.battle.TroopFullInfo) {
        const troopEid = makeTroopEid(info.worldEid);
        const troop = this.ecs.createEntity(troopEid, 0).addComponent(PnvnTroopComponent);
        const config = app.service.pvp.sceneConfigVoMap.get(SCENE_CONFIG);
        troop.worldEid = info.worldEid;
        troop.rid = info.rid;
        troop.aid = info.aid;
        troop.name = info.name;
        troop.side = info.side;
        troop.army = info.army;
        troop.curGrid.x = (info.curGrid / config.width) | 0;
        troop.curGrid.z = info.curGrid % config.width;
        if (troop.state === BattleTroopState.MOVING) {
            troop.dstGrid.x = (info.dstGrid / config.width) | 0;
            troop.dstGrid.z = info.dstGrid % config.width;
        } else {
            troop.dstGrid.cloneFrom(troop.curGrid);
        }
        troop.battleUid = info.battleUid;
        troop.state = info.state;
        troop.stateTime = info.stateMs / 1000;

        const totalSoldierCount = BattleConf.PVE.SOLDIER_ONE_ROW_COUNT * 3;
        for (let i = 0; i <= totalSoldierCount; i++) {
            const eid = info.eids[i] as number | undefined;
            const entityId = info.entityIds[i] as number | undefined;
            troop.members[i] = eid;
            if (eid === undefined || entityId === undefined) {
                continue;
            }
            if (troop.state !== BattleTroopState.FIGHTING) {
                const battleEntity = app.service.table.battleEntity[entityId];
                const entity = this.ecs.createEntity(eid, battleEntity.etype);
                this._addTransformComponent(entity, troop, i);
                this._addMovementComponent(entity);
                this._addElementComponent(entity, troop);
                this._addDebugComponent(entity, troop);
                this._addAnimationComponent(entity, entityId);
            }
        }
    }

    private _updateTroop(info: proto.battle.TroopNotifyInfo) {
        const troopEid = makeTroopEid(info.worldEid);
        const troop = this._findComponent(troopEid, PnvnTroopComponent, "update troop");
        if (troop) {
            const config = app.service.pvp.sceneConfigVoMap.get(SCENE_CONFIG);
            troop.army = info.army;
            troop.state = info.state;
            troop.stateTime = info.stateMs / 1000;
            troop.battleUid = info.battleUid;
            troop.curGrid.x = (info.curGrid / config.width) | 0;
            troop.curGrid.z = info.curGrid % config.width;
            if (troop.state === BattleTroopState.MOVING) {
                troop.dstGrid.x = (info.dstGrid / config.width) | 0;
                troop.dstGrid.z = info.dstGrid % config.width;
            } else {
                troop.dstGrid.cloneFrom(troop.curGrid);
            }

            troop.members.forEach((eid) => {
                if (!eid) {
                    return;
                }
                const entity = this.ecs.getEntity(eid);
                if (!entity) {
                    return;
                }
                this._updateMovementComponent(entity, troop);
            });

            if (info.state === BattleTroopState.SIEGE) {
                this._playSiegeAnimation(troop);
            }
        }
    }

    private _deleteTroop(worldEid: number) {
        const troopEid = makeTroopEid(worldEid);
        const troop = this._findComponent(troopEid, PnvnTroopComponent, "delete troop");
        if (troop) {
            troop.members.forEach((eid) => eid && this.ecs.removeEntity(eid));

            this.ecs.removeEntity(troop.eid);
        }
    }

    private _playSiegeAnimation(troop: PnvnTroopComponent) {
        troop.members.forEach((eid) => {
            if (!eid) {
                return;
            }
            const entity = this.ecs.getEntity(eid);
            if (!entity) {
                return;
            }

            this.context.playAnim(eid, AnimName.SIEGE);
        });
    }

    private _loadBattleTroop(info: proto.battle.Battle) {
        const aliveMap: Map<number, PnvnTroopComponent> = new Map();
        info.entities.forEach((value) => {
            const troopEid = makeTroopEid(value.worldEid);
            const troop = this._findComponent(troopEid, PnvnTroopComponent, "load battle troop");
            if (!troop) {
                return;
            }
            const eid = value.eid;
            const battleEntity = app.service.table.battleEntity[value.entityId];
            const entity = this.ecs.createEntity(eid, battleEntity.etype);

            aliveMap.set(eid, troop);

            this._addTransformComponent(entity, troop, value);
            this._addAnimationComponent(entity, value.entityId);
            this._addElementComponent(entity, troop, value);
            this._addDebugComponent(entity, troop);
            this._addMovementComponent(entity, value);
        });
        for (const troop of aliveMap.values()) {
            troop.members.forEach((eid, idx) => {
                if (eid && !aliveMap.has(eid)) {
                    troop.members[idx] = undefined;
                }
            });
        }
    }

    private _calcOffset(troop: PnvnTroopComponent, grid: IVector3Like, out: IVector3Like) {
        const config = app.service.pvp.sceneConfigVoMap.get(SCENE_CONFIG);
        out.y = 0;
        out.x = (grid.x - config.height / 2) * config.gridHeight;
        if (troop.state === BattleTroopState.FIGHTING) {
            const battleWidth = config.rightBattleCol - config.leftBattleCol + 1;
            out.z = ((battleWidth / 2) % 1) * config.gridWidth;
        } else {
            /**
             *  -x <----------------------------------- +x  服务器坐标(xy)
             *
             *
             * 阵型数据：
             *                       S  S  S
             *                    H  S  S  S
             *                       S  S  S
             * Z坐标取反：
             *           S  S  S
             *           S  S  S  H
             *           S  S  S
             *
             * Z坐标取反后右移1格：
             *                     S  S  S
             *                     S  S  S  H
             *                     S  S  S
             *
             *  +z <----------------------------------- -z  客户端坐标(zx)
             *    |    100     |     99     |
             */
            const sideOffset = troop.side === BattleSide.LEFT ? 1 : 0;
            const center = (config.rightBattleCol + config.leftBattleCol) / 2;
            /**
             * 99为战斗中间格
             *  -x <---------------|------------------- +x  服务器坐标(xy)
             *              |  98  |  99  | 100  |
             * 左右翻转：
             *              | 100  |  99  |  98  |
             * 左移一格：
             *       | 100  |  99  |  98  |
             *                     |
             *  +z <---------------|-------------------- -z  客户端坐标(zx)
             *       | 100  |  99  |  98  |
             */
            const centerOffset = -(grid.z - center) + 1;
            out.z = (centerOffset - sideOffset) * config.gridWidth;
        }
    }

    private _calcGridPosition(
        troop: PnvnTroopComponent,
        formationOffset: IVector3Like,
        gridOffset: IVector3Like,
        out: IVector3Like
    ) {
        if (troop.side === BattleSide.LEFT) {
            out.x = -formationOffset.x + gridOffset.x;
            out.z = -formationOffset.z + gridOffset.z;
            out.y = 180;
        } else if (troop.side === BattleSide.RIGHT) {
            out.x = -formationOffset.x + gridOffset.x;
            out.z = formationOffset.z + gridOffset.z;
            out.y = 0;
        } else {
            console.warn(`unknown side: ${troop.side}`);
        }
    }

    private _findComponent<T extends ecs.Component>(
        eid: number,
        cls: Constructor<T>,
        usage: string
    ) {
        const comp = this.ecs.getComponent(eid, cls);
        if (!comp) {
            console.warn(`compoment '${cls.name}' not found in '${usage}': ${eid}`);
        }
        return comp;
    }

    private _addTransformComponent(
        entity: ecs.Entity,
        troop: PnvnTroopComponent,
        cmdOrIndex?: proto.battle.BattleEntity | number
    ) {
        const transform = entity.addComponent(PnvnTransformComponent);

        if (troop.state === BattleTroopState.FIGHTING) {
            const cmd = cmdOrIndex as proto.battle.BattleEntity | undefined;
            if (cmd) {
                this._calcOffset(troop, troop.curGrid, tmpVec3Offset);
                transform.position.x = -cmd.y + tmpVec3Offset.x;
                transform.position.z = -cmd.x + tmpVec3Offset.z;
                transform.flags |= PnvnTransformComponent.POSITION;
                transform.rotation = cmd.rotation + 180;
                transform.flags |= PnvnTransformComponent.ROTATION;
            }
        } else {
            const index = cmdOrIndex as number;
            const formation = app.service.table.formation.f2;
            const offset = index === 0 ? formation.hero : formation.soldiers[index - 1];
            const dest = new Laya.Vector3();
            this._calcOffset(troop, troop.curGrid, tmpVec3Offset);
            this._calcGridPosition(troop, offset, tmpVec3Offset, dest);
            transform.position.x = dest.x;
            transform.position.z = dest.z;
            transform.flags |= PnvnTransformComponent.POSITION;
            transform.rotation = dest.y;
            transform.flags |= PnvnTransformComponent.ROTATION;
            this.context.playAnim(transform.eid, AnimName.IDLE);
        }
    }

    private _updateMovementComponent(entity: ecs.Entity, troop: PnvnTroopComponent) {
        if (troop.state === BattleTroopState.MOVING || troop.state === BattleTroopState.RESET) {
            const transform = entity.getComponent(PnvnTransformComponent)!;
            const element = entity.getComponent(PnvnElementComponent)!;
            const index = troop.members.indexOf(entity.eid);
            const formation = app.service.table.formation.f2;
            const offset = index === 0 ? formation.hero : formation.soldiers[index - 1];
            const dest = new Laya.Vector3();
            this._calcOffset(troop, troop.dstGrid, tmpVec3Offset);
            this._calcGridPosition(troop, offset, tmpVec3Offset, dest);
            if (troop.stateTime > 0.3) {
                const speed = Math.max(0.5, transform.position.distanceXZ(dest) / troop.stateTime);
                this._moveTo(element, speed, dest.x, dest.z, dest.y);
            } else {
                transform.position.x = dest.x;
                transform.position.z = dest.z;
                transform.flags |= PnvnTransformComponent.POSITION;
                transform.rotation = dest.y;
                transform.flags |= PnvnTransformComponent.ROTATION;
                this.context.playAnim(element.eid, AnimName.IDLE);
            }
        }
    }

    private _addMovementComponent(entity: ecs.Entity, cmd?: proto.battle.BattleEntity) {
        const movement = entity.addComponent(PnvnMovementComponent);

        // 有可能在执行网格移动，进入战斗后，要立即到达战斗的位置，停止更新之前的位置
        movement.speed = 0;
        movement.targetRotation = undefined;

        if (cmd?.move) {
            const move = cmd.move as proto.battle.MoveInfo;
            if (move && move.speed > 0) {
                if (move.force) {
                    this._cmdForceTo(
                        proto.battle.cmd_force_to.create({
                            eid: cmd.eid,
                            targetX: move.targetX,
                            targetY: move.targetY,
                            speed: move.speed,
                        })
                    );
                } else {
                    this._cmdMoveTo(
                        proto.battle.cmd_move_to.create({
                            eid: cmd.eid,
                            targetX: move.targetX,
                            targetY: move.targetY,
                            speed: move.speed,
                        })
                    );
                }
            }
        }
    }

    private _addAnimationComponent(entity: ecs.Entity, sn: number) {
        const battleEntity = app.service.table.battleEntity[sn];
        if (!battleEntity) {
            console.error(`battleEntity ${sn} not found`);
        }
        const animation = entity.addComponent(PnvnAnimationComponent);
        const skinCfg = app.service.table.skin[battleEntity.skin_id!];
        const resPath = skinCfg.prefeb_res || skinCfg.prefeb_baked_res;
        animation.res = resPath!;
    }

    private _addElementComponent(
        entity: ecs.Entity,
        troop: PnvnTroopComponent,
        cmd?: proto.battle.BattleEntity
    ) {
        const element = entity.addComponent(PnvnElementComponent);
        element.troopEid = troop.eid;
        if (cmd) {
            element.hp = cmd.hp;
            element.maxHp = cmd.maxHp;
        }
    }

    private _addDebugComponent(entity: ecs.Entity, troop: PnvnTroopComponent) {
        const debug = entity.addComponent(PnvnDebugBlockComponent);
        debug.res = troop.side === BattleSide.LEFT ? TODO_RM_LEFT : TODO_RM_RIGHT;
    }
}
