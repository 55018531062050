import { app } from "../app";
import { AnyFunction, Callback, assert, toEventType } from "./dispatcher";

type Listener = {
    type: string;
    target: Laya.EventDispatcher;
    callback: AnyFunction;
    thisArg?: unknown;
};

const DEFAULT_TAG = "__internal_tag__";

type LinkedEntry = {
    prev: LinkedEntry;
    next: LinkedEntry;
    tag: string;
    time: number;
    interval?: number;
    callback?: Callback | null;
    thisArg?: unknown | null;
};

type LinkedList = {
    head: LinkedEntry;
    tail: LinkedEntry;
};

class EventAgent {
    target!: Laya.EventDispatcher;

    constructor(readonly listeners: Listener[]) {}

    on(type: string | number, callback: Callback, thisArg?: unknown) {
        type = toEventType(type);
        this.target.on(type, thisArg, callback);
        this.listeners.push({
            type,
            target: this.target,
            callback,
            thisArg,
        });
    }
}

export class Mediator extends Laya.Script {
    private _timer = {
        timestamp: 0,
        delayer: null! as LinkedList,
        scheduler: null! as LinkedList,
    };

    private _agent: EventAgent;

    private _listeners: Listener[] = [];

    constructor() {
        super();
        this._agent = new EventAgent(this._listeners);
        this.clear();
    }

    $(target: Laya.EventDispatcher) {
        this._agent.target = target;
        return this._agent;
    }

    override onDestroy() {
        console.debug(`[DEBUG] destroy ${this.constructor.name}`);
        for (const listener of this._listeners) {
            const { type, target, callback, thisArg } = listener;
            target.off(type, thisArg, callback);
        }
        super.onDestroy();
    }

    override onUpdate(): void {
        this.superUpdate(app.delta);
    }

    protected superUpdate(dt: number) {
        this._updateTimer(dt);
    }

    // ----------------------------------------------------------------
    // timer api
    // ----------------------------------------------------------------
    private _clearDelays() {
        const entry = {
            tag: "__delay_head__",
            time: Number.MAX_VALUE,
            prev: null!,
            next: null!,
        } as LinkedEntry;
        entry.next = entry;
        entry.prev = entry;
        this._timer.delayer = { head: entry, tail: entry };
    }

    private _clearSchedules() {
        const entry = {
            tag: "__schedule_head__",
            time: Number.MAX_VALUE,
            interval: 0,
            prev: null!,
            next: null!,
        } as LinkedEntry;
        entry.next = entry;
        entry.prev = entry;
        this._timer.scheduler = { head: entry, tail: entry };
    }

    clear() {
        this._clearDelays();
        this._clearSchedules();
    }

    delay(time: number, callback: Callback, thisArg?: unknown): void;

    delay(time: number, tag: string, callback: Callback, thisArg?: unknown): void;

    delay(time: number, callbackOrTag: string | Callback, callback?: Callback, thisArg?: unknown) {
        let tag: string;
        if (typeof callbackOrTag === "string") {
            tag = callbackOrTag;
            assert(tag !== DEFAULT_TAG, "tag error");
        } else {
            callback = callbackOrTag;
            tag = DEFAULT_TAG;
        }
        assert(callback !== undefined, "no callback");

        this.killDelay(tag);

        const entry: LinkedEntry = {
            tag: tag,
            time: time + this._timer.timestamp,
            callback: callback,
            thisArg: thisArg,
            prev: null!,
            next: null!,
        };

        let prev = this._timer.delayer.tail.prev;
        if (prev !== this._timer.delayer.head) {
            while (prev.time > entry.time) {
                prev = prev.prev;
            }
        }
        const next = prev.next;
        entry.prev = prev;
        entry.next = next;
        prev.next = entry;
        next.prev = entry;
    }

    killDelay(tag: string) {
        if (tag !== DEFAULT_TAG) {
            let current = this._timer.delayer.head.next;
            while (current !== this._timer.delayer.head) {
                if (tag === current.tag && current.callback) {
                    current.callback = null;
                }
                current = current.next;
            }
        }
    }

    killAll() {
        this._clearDelays();
    }

    schedule(interval: number, callback: Callback, thisArg?: unknown): void;

    schedule(interval: number, tag: string, callback: Callback, thisArg?: unknown): void;

    schedule(
        time: number,
        callbackOrTag: string | Callback,
        callback?: Callback,
        thisArg?: unknown
    ) {
        let tag: string;
        if (typeof callbackOrTag === "string") {
            tag = callbackOrTag;
            assert(tag !== DEFAULT_TAG, "tag error");
        } else {
            callback = callbackOrTag;
            tag = DEFAULT_TAG;
        }
        assert(callback !== undefined, "no callback");
        const entry: LinkedEntry = {
            tag: tag,
            time: time + this._timer.timestamp,
            interval: time,
            callback: callback,
            thisArg: thisArg,
            prev: null!,
            next: null!,
        };

        const prev = this._timer.scheduler.tail.prev;
        const next = prev.next;
        entry.prev = prev;
        entry.next = next;
        prev.next = entry;
        next.prev = entry;
    }

    unschedule(tag: string) {
        assert(tag !== DEFAULT_TAG, "tag error");
        let current = this._timer.scheduler.head.next;
        while (current !== this._timer.scheduler.head) {
            if (tag === current.tag && current.callback) {
                current.callback = null;
            }
            current = current.next;
        }
    }

    protected _updateTimer(delta: number) {
        const timestamp = this._timer.timestamp + delta;
        this._timer.timestamp = timestamp;

        const delayer = this._timer.delayer;
        const scheduler = this._timer.scheduler;

        if (delayer.head.next !== delayer.head) {
            let current = delayer.head.next;
            while (timestamp >= current.time) {
                const { callback, thisArg } = current;
                current.callback = null;
                thisArg ? callback?.call(thisArg) : callback?.();
                current = current.next;
            }
            if (current !== delayer.head.next) {
                delayer.head.next = current;
                current.prev = delayer.head;
            }
        }
        if (scheduler.head.next !== scheduler.head) {
            let current = scheduler.head.next;
            while (current !== scheduler.head) {
                const { callback, thisArg } = current;
                if (callback) {
                    while (timestamp > current.time) {
                        if (current.interval === 0) {
                            current.time = timestamp;
                            thisArg ? callback?.call(thisArg) : callback?.();
                            break;
                        } else {
                            current.time += current.interval!;
                            thisArg ? callback?.call(thisArg) : callback?.();
                        }
                    }
                } else {
                    current.prev.next = current.next;
                    current.next.prev = current.prev;
                }
                current = current.next;
            }
        }
    }
}
