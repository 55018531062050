/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { BaseRoundWindowBg } from "../../common/ui-runtime/dialog/BaseRoundWindowBg";

/**
 * resources/prefab/draw-card/UIDrawCardPreview.lh
 */
export class UIDrawCardPreviewBase extends Laya.Dialog {
    public imgMask!: Laya.Image;
    public bgwin!: BaseRoundWindowBg;
    public listReward!: Laya.List;
}

