import * as ecs from "../../../../../core/ecs";

export class PvpWagonComponent extends ecs.Component {
    static readonly WOOD: Laya.Vector3[] = [
        new Laya.Vector3(0.6, 0, 0),
        new Laya.Vector3(0.2, 0, 0),
        new Laya.Vector3(-0.2, 0, 0),
        new Laya.Vector3(-0.6, 0, 0),
    ];
    static readonly FOOD: Laya.Vector3[] = [
        new Laya.Vector3(0.35, 0, 0.5),
        new Laya.Vector3(-0.35, 0, 0.5),
        new Laya.Vector3(0.35, 0, -0.5),
        new Laya.Vector3(-0.35, 0, -0.5),
    ];
    static readonly STONE: Laya.Vector3[] = [
        new Laya.Vector3(0.35, 0, 0.5),
        new Laya.Vector3(-0.35, 0, 0.5),
        new Laya.Vector3(0.35, 0, -0.5),
        new Laya.Vector3(-0.35, 0, -0.5),
    ];

    id: number = 0;
}
