import { app } from "../../../app";
import { StringUtil } from "../../../core/utils/string-util";
import { ItemType } from "../../../def/auto/item";
import proto from "../../../def/auto/proto";
import { QualityColorArray } from "../../../def/style";
import { ItemRow, ItemRowArgsLeadSKin, itemRowArgsHero } from "../../../def/table";
import { res } from "../../../misc/res";
import { Vo } from "../../../misc/vo/Vo";
import { ItemRourceLo } from "./ItemRourceLo";

export abstract class ItemVoBase<D> extends Vo<ItemRow, D> {
    get id() {
        return this._config.id;
    }

    get name() {
        return this._config.name;
    }

    get smallIconUrl(): string {
        let iconUrl = "";
        const skinId: number | undefined = this._config.skin_id;
        if (skinId) {
            const skinLo = app.service.table.skin[skinId];
            iconUrl = StringUtil.format(res.ITEM_SMALL_ICON_PATH, skinLo.code_name);
        }
        return iconUrl;
    }

    get iconUrl(): string {
        let iconUrl = "";
        const itemType = this.itemType;
        if (itemType === ItemType.HERO) {
            const args = this.args as itemRowArgsHero;
            const heroId = args.hero_id;
            const heroLo = app.service.hero.heroLoMap.get(heroId)!;
            iconUrl = heroLo.iconSmall || "";
        } else if (itemType === ItemType.LEADER_SKIN) {
            const args = this.args as ItemRowArgsLeadSKin;
            const skinId = args.id;
            const skinLo = app.service.leaderSkin.leaderSkinLoMap.get(skinId);
            iconUrl = skinLo.smallIcon;
        } else {
            const skinId: number | undefined = this._config.skin_id;
            if (skinId) {
                const skinLo = app.service.table.skin[skinId];
                iconUrl = StringUtil.format(res.ITEM_MID_ICON_PATH, skinLo.code_name);
            }
        }
        return iconUrl;
    }

    get bigIconUrl(): string {
        let iconUrl = "";
        const skinId: number | undefined = this._config.skin_id;
        if (skinId) {
            const skinLo = app.service.table.skin[skinId];
            iconUrl = StringUtil.format(res.ITEM_BIG_ICON_PATH, skinLo.code_name);
        }
        return iconUrl;
    }

    get modelPrefebSkinUrl(): string {
        let url = "";
        const skinId: number | undefined = this._config.skin_id;
        if (skinId) {
            const skinLo = app.service.table.skin[skinId];
            if (skinLo.prefeb_res) {
                url = skinLo.prefeb_res;
            } else if (skinLo.prefeb_baked_res) {
                url = skinLo.prefeb_baked_res;
            }
        }
        return url;
    }

    get quality(): number {
        return this._config.quality;
    }

    get qualityTextColor(): string {
        return QualityColorArray[this.quality];
    }

    get composite(): boolean {
        return this._config.item_type === ItemType.MATERIAL;
    }

    get itemType(): number {
        return this._config.item_type;
    }

    get use() {
        return undefined;
    }

    get args() {
        return this._config.args;
    }

    get stack() {
        return this._config.stack === 1;
    }

    get isMoneyItem() {
        return (
            this.itemType === ItemType.STONE ||
            this.itemType === ItemType.FOOD ||
            this.itemType === ItemType.BREAD ||
            this.itemType === ItemType.WOOD ||
            this.itemType === ItemType.BOARD ||
            this.itemType === ItemType.COIN ||
            this.itemType === ItemType.DIAMOND
        );
    }

    get isExpItem() {
        return this.itemType === ItemType.EXP;
    }

    get isChestItem() {
        return this.itemType === ItemType.CHEST;
    }

    get desc(): string {
        return this._config.desc;
    }

    get itemSource(): ItemRourceLo[] {
        const itemRources = this._config.item_source;
        const itemRourceList: ItemRourceLo[] = [];
        for (const rource of itemRources) {
            const itemLo = app.service.bag.itemRourceLoMap.get(rource)!;
            itemRourceList.push(itemLo);
        }
        return itemRourceList;
    }
}

export class ItemVo extends ItemVoBase<proto.bag.Item> {
    protected declare _data: Readonly<proto.bag.Item>;

    constructor(config: ItemRow, data: proto.bag.Item) {
        super(config, data);
    }

    override clone(): ItemVo {
        return new ItemVo(this._config, this._data);
    }

    override get key(): number {
        return this.uid;
    }

    get uid() {
        return this._data.uid;
    }

    get count() {
        return this._data.num;
    }
}
