import { app } from "../../../../../../app";

export interface Healthable {
    /** 当前血量 */
    hp: number;

    /** 最大血量 */
    maxHp: number;
}

/** 可以根据时间复活的都继承这个数据类 */
export class ReliveableRecordData {
    /** 死亡时的时间戳，单位秒，用于计算复活刷新时间 */
    deathTime: number = 0;

    /** 复活间隔 */
    protected reliveCD: number = Number.MAX_SAFE_INTEGER;

    /**
     * 设置复活cd(单位秒)
     * 不设置的话，默认无限长的复活时间
     */
    setReliveCD(cd: number): void {
        this.reliveCD = cd;
    }

    /** 当前是否是死亡状态 */
    get isDeath(): boolean {
        if (this.deathTime === 0) return false;
        // 服务器当前时间戳单位秒
        const curTime = app.service.network.serverTime;
        if (curTime > this.reliveTime) {
            // 当前时间已经超过复活时间，则返回未死亡状态
            return false;
        }
        return true;
    }

    /**
     * 返回复活的时间戳,单位秒
     * 没有死亡时，返回非常大的值
     */
    get reliveTime(): number {
        if (this.deathTime === 0) return Number.MAX_SAFE_INTEGER;
        const cd = this.reliveCD;
        if (cd === Number.MAX_SAFE_INTEGER) {
            return Number.MAX_SAFE_INTEGER;
        }
        return this.deathTime + cd;
    }
}
