/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { BaseRoundWindowBg } from "../../common/ui-runtime/dialog/BaseRoundWindowBg";
import { SkillIconUI } from "./SkillIconUI";

/**
 * resources/prefab/skill/SkillDetails.lh
 */
export class SkillDetailsUIBase extends Laya.Dialog {
    public imgClose!: Laya.Image;
    public bgwin!: BaseRoundWindowBg;
    public skillicon!: SkillIconUI;
    public imgType!: Laya.Image;
    public labSpecial!: Laya.Label;
    public labType!: Laya.Label;
    public labLevel!: Laya.Label;
    public labDesc!: Laya.Label;
    public labCost!: Laya.Label;
    public boxCost!: Laya.HBox;
    public labCount!: Laya.Label;
    public imgIcon!: Laya.Image;
    public btnUplevel!: Laya.Button;
    public btnReplace!: Laya.Button;
    public labMax!: Laya.Label;
}

