/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIButton } from "../../../core/ui/UIButton";
import { UIRadioGroup } from "../../../core/ui/UIRadioGroup";

/**
 * resources/prefab/alliance/UIAllianceInviteMember.lh
 */
export class UIAllianceAppointBase extends Laya.Dialog {
    public btnOk!: UIButton;
    public list!: UIRadioGroup;
}

