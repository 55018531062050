export const LoggerGamePrefix: string = "Game";

export function IsLogInApp(): boolean {
    return Laya.LayaEnv.isConch;
}

export class LoggerConfig {
    static readonly DefaultLogTag: string = "Common";

    static GetLogTag(tag?: string): string {
        tag = tag || this.DefaultLogTag;
        return LoggerGamePrefix + "::" + tag;
    }

    private static IgnoreLoggerTag: string[] = [];

    // tag需要先使用GetLogTag转换
    static AddIgnoreLogger(tag: string) {
        if (this.IgnoreLoggerTag.indexOf(tag) < 0) {
            this.IgnoreLoggerTag.push(tag);
        }
    }

    static IsIgnoreLogger(tag: string): boolean {
        return this.IgnoreLoggerTag.indexOf(tag) >= 0;
    }

    // tag需要先使用GetLogTag转换
    static RemoveIgnoreLogger(tag: string) {
        const index = this.IgnoreLoggerTag.indexOf(tag);
        if (index >= 0) {
            this.IgnoreLoggerTag.splice(index, 1);
        }
    }
}
