import * as ecs from "../../../../../core/ecs";

export class P1v1TransformComponent extends ecs.Component {
    static readonly POSITION = 1 << 0;
    static readonly ROTATION = 1 << 1;

    flags: number = 0;
    position: Laya.Vector3 = new Laya.Vector3();
    rotation: number = 0;
}
