/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/character/character/CharacterSelectTab.lh
 */
export class CharacterSelectTabBase extends Laya.Box {
    public imgBg!: Laya.Image;
    public imgSelect!: Laya.Image;
    public lblList!: Laya.Label;
}

