import { app } from "../app";
import { StringUtil } from "../core/utils/string-util";
import { Reward } from "../def/table";

export class RewardCostUtil {
    /** 获取是否足够扣除 */
    public static checkEnoughCost(costs: Reward[] | undefined, showTips: boolean = false): boolean {
        if (!costs) return true;
        for (let i = 0; i < costs.length; i++) {
            const needAmount = costs[i].num;
            const lo = app.service.bag.itemLoMap.get(costs[i].id);
            const num = app.service.bag.itemVoMap.getItemAmount(costs[i].id);
            // if (lo.isMoneyItem) {
            //     num = app.service.user.getMoneyCount(lo.id);
            // } else {
            //     num =
            // }
            if (num < needAmount) {
                if (showTips) {
                    const ubb = StringUtil.str2UBB(
                        "消耗不足，需要{0}{1}",
                        { image: lo.iconUrl, width: 30, height: 30 },
                        needAmount
                    );
                    app.ui.toast(ubb);
                }
                return false;
            }
        }
        return true;
    }
}
