import { app } from "../../../app";
import { Mediator } from "../../../core/ui-mediator";
import { SystemEvent } from "../../../misc/system-event";
import { ui } from "../../../misc/ui";
import { PveUI } from "../ui-runtime/PveUI";
import { PveContext } from "./PveContext";

@Laya.regClass('W8MKBh7xS0mAEYqw2BpdAQ')
export class PveMediator extends Mediator {
    declare owner: PveUI;

    public get pveContext(): PveContext | null {
        return this.owner.getComponent(PveContext);
    }

    override onAwake() {
        this.$(app).on(SystemEvent.PVE.ON_ENTER_LEAVE_TERRITORY, this._onEnterLeaveTerritory, this);
        this.owner.btnBackTown.onClick(() => {
            const context = this.pveContext;
            if (context) {
                context.sender.onClickBackToTerritory();
            }
        });
        this.owner.btnEditTroop.on(Laya.Event.CLICK, () => {
            const troopData = app.service.troop.troopTeamVoMap.get(1);
            if (troopData) {
                app.ui.show(ui.TROOP_SELECT, troopData);
            } else {
                app.ui.toast("当前pve编队未开启");
            }
        });
        this.$(Laya.stage).on(Laya.Event.MOUSE_WHEEL, this._onMouseWheel, this);
    }

    private _onEnterLeaveTerritory(isEnter: boolean): void {
        // 处理进入和离开
        if (isEnter) {
            // app.ui.toast("玩家进入领域,隐藏回城按钮");
            this.owner.btnBackTown.visible = false;
        } else {
            // app.ui.toast("玩家离开领域,显示回城按钮");
            this.owner.btnBackTown.visible = true;
        }
    }

    private _onMouseWheel(e: Laya.Event): void {
        const camera = this.owner.scene3D.getChildByPath("cameraNode/Main Camera") as Laya.Camera;
        const dt = e.delta / 2;
        let fov = camera.fieldOfView + dt;
        fov = Math.min(80, Math.max(fov, 15));
        camera.fieldOfView = fov;
    }
}
