import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { chest } from "../../def/auto/proto";
import { opcode } from "../../def/auto/protocol";
import { SystemEvent } from "../../misc/system-event";
import { UIChestBox } from "./ui-runtime/UIChestBox";
import { ChestBoxVo } from "./vo/ChestBoxVo";
const { regClass, property } = Laya;

@regClass('LKmuub4XTUitcfp6egsNGA')
export class UI_ChestBoxMediator extends Mediator {
    declare owner: UIChestBox;

    override onAwake(): void {
        super.onAwake();
        this.$(app).on(
            SystemEvent.CHEST_BOX.ON_CHOOSE_CHEST_CHANGED,
            this.onChooseChestChange,
            this
        );

        this.$(app).on(SystemEvent.UI.ITEM_SVR_UPDATE, this.onItemUpdate, this);
        this.$(app).on(opcode.chest.s2c_score_receive, this.onScoreReceive, this);
        this.$(app).on(opcode.chest.s2c_switch_hero, this.onSwitchHero, this);
    }

    onOpened(): void {
        this.updateShow();
    }

    private onChooseChestChange(vo: ChestBoxVo): void {
        this.updateShow();
    }

    private onItemUpdate(): void {
        this.updateShow();
    }

    async onClickOpenBtn() {
        const vo = app.service.chest.currentChooseChestBox;
        if (!vo) {
            app.ui.toast("暂无可打开的宝箱！");
            return;
        }

        if (vo.amount < 1) {
            app.ui.toast("宝箱已被打开！");
            return;
        }

        await app.service.chest.requestOpenChest(vo.key);
        this.updateShow();
    }

    public updateShow(): void {
        const vo = app.service.chest.currentChooseChestBox;
        this.owner.downBlock.updateShow();
        this.owner.boxStack.updateShow(vo);
        this.owner.tips.updateShow(vo);
        this.owner.leftUpBtn.updateShow();
        this.owner.boxHero.updateShow();
        if (vo && vo.amount > 0) {
            this.owner.openBtn.gray = false;
        } else {
            this.owner.openBtn.gray = true;
        }
    }

    private onScoreReceive(s2c: chest.s2c_score_receive, c2s: chest.c2s_score_receive): void {
        this.updateShow();
    }

    private onSwitchHero(s2c: chest.s2c_switch_hero, c2s: chest.c2s_switch_hero): void {
        this.owner.boxHero.updateShow();
    }
}
