import { IVector3Like } from "../core/laya";

export type Formation = { hero: IVector3Like; soldiers: IVector3Like[] };

// export const formation: Readonly<IVector3Like>[] = [
//     // 第一排士兵
//     { x: 0.2, y: 0, z: -0.6 },
//     { x: -0.2, y: 0, z: -0.6 },
//     { x: 0.6, y: 0, z: -0.6 },
//     { x: -0.6, y: 0, z: -0.6 },
//     // 第二排士x
//     { x: 0.2, y: 0, z: -1.2 },
//     { x: -0.2, y: 0, z: -1.2 },
//     { x: 0.6, y: 0, z: -1.2 },
//     { x: -0.6, y: 0, z: -1.2 },
//     // 第三排士x
//     { x: 0.2, y: 0, z: -1.8 },
//     { x: -0.2, y: 0, z: -1.8 },
//     { x: 0.6, y: 0, z: -1.8 },
//     { x: -0.6, y: 0, z: -1.8 },
//     // 三种资源车
//     { x: 0.4, y: 0, z: -2.6 },
//     { x: -0.4, y: 0, z: -2.6 },
// ];

export const pvpFormation = {
    move: {
        hero: { x: 0, y: 0, z: 0 },
        soldiers: [
            // 第一排士兵
            { x: 0.2, y: 0, z: -0.6 },
            { x: -0.2, y: 0, z: -0.6 },
            { x: 0.6, y: 0, z: -0.6 },
            { x: -0.6, y: 0, z: -0.6 },
            // 第二排士x
            { x: 0.2, y: 0, z: -1.2 },
            { x: -0.2, y: 0, z: -1.2 },
            { x: 0.6, y: 0, z: -1.2 },
            { x: -0.6, y: 0, z: -1.2 },
            // 第三排士x
            { x: 0.2, y: 0, z: -1.8 },
            { x: -0.2, y: 0, z: -1.8 },
            { x: 0.6, y: 0, z: -1.8 },
            { x: -0.6, y: 0, z: -1.8 },
        ],
    },
    fight: {
        hero: { x: 0, y: 0, z: -1.6 },
        soldiers: [
            // 第一排士兵
            { x: 0.2, y: 0, z: -1.2 },
            { x: -0.2, y: 0, z: -1.2 },
            { x: 0.6, y: 0, z: -1.2 },
            { x: -0.6, y: 0, z: -1.2 },
            // 第二排士x
            { x: 0.2, y: 0, z: -1.8 },
            { x: -0.2, y: 0, z: -1.8 },
            { x: 0.6, y: 0, z: -1.8 },
            { x: -0.6, y: 0, z: -1.8 },
            // 第三排士x
            { x: 0.2, y: 0, z: -2.2 },
            { x: -0.2, y: 0, z: -2.2 },
            { x: 0.6, y: 0, z: -2.2 },
            { x: -0.6, y: 0, z: -2.2 },
        ],
    },
};
