import { Constructor } from "../../../core/dispatcher";
import * as ecs from "../../../core/ecs";
import { BattlePveMapTransferRow, SpoilsRow } from "../../../def/table";
import { SecondOrderDynamicsV3 } from "../base/SecondOrderDynamics";
import { PveUIBuildConfirmComponent } from "../pve/ecs/components/PveUIBuildConfirmComponent";
import { PveUIBuildingConfirmComponent } from "../pve/ecs/components/PveUIBuildingConfirmComponent";
import { PveUICloudComponent } from "../pve/ecs/components/PveUICloudComponent";
import { PveUINpcOperationConfirmComponent } from "../pve/ecs/components/PveUINpcOperationConfirmComponent";
import { PveUIRescueComponent } from "../pve/ecs/components/PveUIRescueComponent";
import { BulletType } from "./btree/actions/CreateBullet";

export class PveDef {
    static readonly COLLECT_CNT_PER_OBJ = 1; // 每个资源对象相当于多少资源数量

    static readonly COLLECT_WOOD_ID = 30601; // 木头对应的道具id
    static readonly COLLECT_FOOD_ID = 30701; // 粮食对应的道具id
    static readonly COLLECT_STONE_ID = 30801; // 石头对应的道具id

    static readonly SOLDIER_COUNT = 15;

    /**
     * 标记是否撞墙，撞墙后需要先停下来，然后再A星寻路到目的地
     * 手操模式下不会设置为true
     * 撞墙设置成true，到达下个寻路路点位置后，才会设置成false
     */
    static readonly HIT_WALL_FLAG = "__hitWallFlag";
    /** 是否处于寻路移动中 */
    static readonly FIND_PATH_MOVEING = "__findPathMoveing";

    /**
     * 是否开始手操标记
     * 手操标记后，需要使正在战斗状态的士兵马上进行脱战归队
     */
    static readonly START_WHEEL_FLAG = "start_wheel_flag";

    // static readonly FIND_PATH_MOVE_TIME = "__findPathMoveTime";

    /** 期望前往的位置(撞墙后，A星寻路需要目的地位置信息) */
    static readonly POS_DATA = "__posData";
    /** 移动的路点数组Laya.Vector2[] */
    static readonly MOVE_PATH_POINTS = "__movePathPoints";

    static readonly SKILL_TARGET_EIDS = "__skill_target_eids__";
}

export interface ElementCreator {
    eid: number;
    etype: number;
    key: number; //和PveSvrElementComponent的key对应

    // 同盟id
    aid: number;

    teid: number; // id in battle_entity.xlsx
    tid: number; // 配置表id

    maxHp?: number;
    hp?: number;
    position: Laya.Vector3;
    rotation: number;

    // TODO: 根据实体类型移除此代码？
    animation?: boolean;

    level: number;

    collectType?: number; // 资源车上采集物的实体类型
    collectCnt?: number; // 资源车上的采集物数量
    followEid?: number; // 资源车跟随的实体

    skillSelectorArgs?: SkillSelectorArgs; // 技能预警对象需要的信息

    bulletArgs?: ILaunchBulletArgs; // 子弹需要的信息
}

export interface ILaunchBulletArgs {
    h?: number;
    type: BulletType;
    speed?: number;
    follow?: boolean;
    followEid: number;
}

/** 技能目标选择参数 */
export type SkillSelectorArgs = {
    shape: "fan" | "rect";
    distance: number;
    width: number;
    /** 预警提示持续时间，单位秒 */
    duration: number;
    battle_entity?: number;
    /** 技能释放者 */
    ownerEntityId: number;
};

export interface UpdateHp {
    hp: number;
    maxHp: number;
    subHp?: number;
    isCrit?: boolean;
}

export interface RecoverHp {
    hp: number;
    maxHp: number;
    addHp: number;
}

export interface UpdateTruck {
    // 被采集的树eid
    collection: number;
    /** 当前数量 */
    collectCnt: number;
    /** 用于被扣除的资源要飞去的位置 */
    costToPosition?: Laya.Vector3;
}

export class TruckFormation {
    static readonly WOOD: Laya.Vector3[] = [
        new Laya.Vector3(0.6, 0, 0),
        new Laya.Vector3(0.2, 0, 0),
        new Laya.Vector3(-0.2, 0, 0),
        new Laya.Vector3(-0.6, 0, 0),
    ];
    static readonly FOOD: Laya.Vector3[] = [
        new Laya.Vector3(0.35, 0, 0.5),
        new Laya.Vector3(-0.35, 0, 0.5),
        new Laya.Vector3(0.35, 0, -0.5),
        new Laya.Vector3(-0.35, 0, -0.5),
    ];
    static readonly STONE: Laya.Vector3[] = [
        new Laya.Vector3(0.35, 0, 0.5),
        new Laya.Vector3(-0.35, 0, 0.5),
        new Laya.Vector3(0.35, 0, -0.5),
        new Laya.Vector3(-0.35, 0, -0.5),
    ];
}

/** 爆战利品的数据结构 */
export type ExplodeSpoilsData = {
    eid: number;
    spoilsRow: SpoilsRow;
    posX: number;
    posZ: number;
};

export type TweenData = {
    startPoint: Laya.Vector3;
    endPoint: Laya.Vector3;
    startLen: number;
    dist: number;
};

export type TMWorldCloudData = {
    [gid: string]: { data: number[]; max_xy: [number, number]; min_xy: [number, number] };
};

/** 世界地图阻挡 */
export interface TMWorldMinimap {
    blocks: {
        data: number[];
        height: number;
        stride: number;
        width: number;
    };
    custom: [{ name: string; pros?: { rot?: number }; x: number; y: number }];
    cloud: { cloud_firstgid: number; data_map: TMWorldCloudData };
    count: {
        [areaId: string]: {
            eventIds: [{ id: number; c: number }];
            monsterIds: [{ id: number; c: number }];
        };
    };
}

/** 当前身上的资源 */
export type CollectionAmountData = {
    itemId: number; // 资源id
    truckAmount: number; // 车上包含的数量
    packAmount: number; // 身上包含的数量
};

/** 游戏对象飞行数据 */
export type GameObjectFlyData = {
    startPoint: Laya.Vector3 | undefined;
    endPoint: Laya.Vector3 | undefined;
    topPoint: Laya.Vector3 | undefined;
    start2TopVec: Laya.Vector3 | undefined;
    top2EndVec: Laya.Vector3 | undefined;
    start2TopVecLen: number;
    top2EndVecLen: number;
    totalLen: number;
    startTime: number;
    flyTime: number;
    endCallBack: Laya.Handler;
    resultPoint: Laya.Vector3 | undefined;
    sod: SecondOrderDynamicsV3 | undefined;
    delay: number;
};

export type BirthPointData = { x: number; z: number; rot: number };

export type MapBlockPos = { mapX: number; mapY: number };

export type MapTransferData = { id: number; x: number; y: number; rot: number };

export type BattlePveMapTransferItem = {
    areaId: number;
    transferId: number;
    areaTransferId: number;
    cfg: BattlePveMapTransferRow;
};

// 区域探索进度
export type AreaProgressItemData = {
    index: number;
    icon: string;
    name: string;
    curVal: number;
    maxVal: number;
};

export type TerritoryStartEnd = {
    startX: number;
    startY: number;
    endX: number;
    endY: number;
};

/** 界面里交互气泡的类型 */
export enum InteractiveBubbleViewType {
    FOUNDATION_BUILD_VIEW = 1, // 与地基交互
    BUILDING_INTERACTIVE_VIEW = 2, //与成品建筑交互
    NPC_INTERACTIVE_VIEW = 3, //与npc交互
    CLOUD_INTERACTIVE_VIEW = 4, //与迷雾事件交互
    RESCUS_INTERACTIVE_VIEW = 5, //与解救士兵事件交互
}

/** 气泡内的具体交互操作类型 */
export enum InteractiveBubbleActionType {
    FOUNDATION_BUILD_ACTION_1 = 1, //地基xxx建筑进行建造
    BUILDING_INTERACTIVE_ACTION_1 = 2, //与成品xxx建筑交互
    NPC_INTERACTIVE_ACTION_1 = 3, //与npc对话
    CLOUD_INTERACTIVE_ACTION_1 = 4, //点击解开迷雾
    RESCUS_INTERACTIVE_ACTION_1 = 5, //点击解救士兵
}

export const InteractiveBubbleConfirms: Constructor<ecs.Component>[] = [
    PveUIBuildConfirmComponent,
    PveUIBuildingConfirmComponent,
    PveUINpcOperationConfirmComponent,
    PveUICloudComponent,
    PveUIRescueComponent,
];
