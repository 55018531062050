import * as ecs from "../../../../../core/ecs";
import { PveAnimationComponent } from "./PveAnimationComponent";
import { PveTransformComponent } from "./PveTransformComponent";

export class PveElementComponent extends ecs.Component {
    // 缓存组件方便快速访问？
    private _transform: PveTransformComponent | null = null;
    private _animation: PveAnimationComponent | null = null;

    get transform() {
        return (this._transform ||= this.getComponent(PveTransformComponent)!);
    }

    get animation() {
        return (this._animation ||= this.getComponent(PveAnimationComponent)!);
    }

    /** entity 表格中实体 id */
    teid: number = 0; // short for table_entity_id
    /** hero, soldier, monster, event, building, spoils 表格中的数据 id */
    tid: number = 0; // short for table_id

    key: number = 0; //对应PveSvrElementComponent的key
}
