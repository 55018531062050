const { regClass } = Laya;
import { app } from "../../../app";
import { StringUtil } from "../../../core/utils/string-util";
import { errcode } from "../../../def/auto/protocol";
import { RenameUIBase } from "./RenameUI.generated";

@regClass('p46K5KHcQkeGkRRDYiub4A')
export class RenameUI extends RenameUIBase {
    override onAwake(): void {
        super.onAwake();
        this.labelName.text = app.service.user.profileInfo.name;
        const consume = app.service.table.define.consume["rename"];
        const itemLo = app.service.bag.itemLoMap.get(consume[0].id);
        const ubb = StringUtil.str2UBB(
            "{0}{1}",
            { image: itemLo.smallIconUrl, width: 30, height: 30 },
            consume[0].num
        );
        this.labelBtnName.text = ubb;
        this.btn.on(Laya.Event.CLICK, this, async () => {
            if (this.labelName.text !== "") {
                await app.service.user
                    .requestRename({ name: this.labelName.text })
                    .then((data: any) => {
                        if (data.err === errcode.OK) {
                            this.close();
                        }
                    });
            }
        });
    }
}
