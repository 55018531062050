import * as ecs from "../../../../../core/ecs";
import { Pool } from "../../../../../core/pool";
import { TweenData } from "../../../pve-server/PveDefs";
import { PveContext } from "../../PveContext";
import { PveSpoilsComponent } from "../components/PveSpoilsComponent";
export class PveSpoilsSystem extends ecs.System {
    declare context: PveContext;

    override update(dt: number): void {
        this.ecs.getComponents(PveSpoilsComponent).forEach((spoilsComp) => {
            if (!spoilsComp.view) return;
            if (!spoilsComp.tweenDatas) return;
            if (spoilsComp.needAniFlag) {
                spoilsComp.needAniFlag = false;
                // 开始动画
                spoilsComp.aniOverTime = 0;
                spoilsComp.tweenTime = spoilsComp.totalLen / 8;
                if (!spoilsComp.curPos) {
                    spoilsComp.curPos = Pool.obtain(Laya.Vector3);
                    spoilsComp.curPos.cloneFrom(spoilsComp.tweenDatas[0].startPoint);
                }
                spoilsComp.view.active = false;
            } else if (spoilsComp.curPos) {
                // 动画进行中，未结束
                spoilsComp.aniOverTime += dt;
                if (spoilsComp.aniOverTime < spoilsComp.delay) {
                    return;
                }
                if (!spoilsComp.view.active) {
                    spoilsComp.view.active = true;
                }
                const progress = this.getProgress(
                    spoilsComp.aniOverTime,
                    spoilsComp.delay,
                    spoilsComp.tweenTime
                );

                if (progress >= 1) {
                    this.tweenComplete(spoilsComp);
                    return;
                }
                const curLen = spoilsComp.totalLen * progress;
                let curTweenData: TweenData | undefined;
                for (let i = 0; i < spoilsComp.tweenDatas.length; i++) {
                    const td = spoilsComp.tweenDatas[i];
                    if (curLen >= td.startLen && curLen < td.startLen + td.dist) {
                        curTweenData = td;
                        break;
                    }
                }
                if (curTweenData) {
                    const overScurLen = curLen - curTweenData.startLen;
                    const overProgress = overScurLen / curTweenData.dist;
                    Laya.Vector3.lerp(
                        curTweenData.startPoint,
                        curTweenData.endPoint,
                        overProgress,
                        spoilsComp.curPos
                    );
                } else {
                    this.tweenComplete(spoilsComp);
                    return;
                }
            }
            if (spoilsComp.curPos) {
                spoilsComp.view.transform.position = spoilsComp.curPos;
            }
        });
    }

    /** 曲线变换 */
    private getProgress(aniOverTime: number, delay: number, tweenTime: number): number {
        let x = (aniOverTime - delay) / tweenTime;
        if (x > 1) x = 1;
        if (x <= 0.5) {
            // 上半段快到慢
            return Math.sqrt(x) * 0.7;
        }
        // 下半段慢到快
        return 1.45 - Math.sqrt(1.2 - Math.pow(x, 1.8));
    }

    private tweenComplete(spoilsComp: PveSpoilsComponent): void {
        // 动画结束
        spoilsComp.curPos!.cloneFrom(
            spoilsComp.tweenDatas![spoilsComp.tweenDatas!.length - 1].endPoint
        );
        spoilsComp.view!.transform.position = spoilsComp.curPos!;
        Pool.free(spoilsComp.curPos);
        spoilsComp.curPos = undefined;
        spoilsComp.tweenDatas = undefined;
    }
}
