/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { TroopStateFocusUI } from "./TroopStateFocusUI";
import { TroopStateNormalUI } from "./TroopStateNormalUI";
import { TroopStateNoneUI } from "./TroopStateNoneUI";

/**
 * resources/prefab/battle/pvp-ui/troop-state.lh
 */
export class TroopStateUIBase extends Laya.Sprite {
    focusState!: TroopStateFocusUI;
    normalState!: TroopStateNormalUI;
    noneState!: TroopStateNoneUI;
}
