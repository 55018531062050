import { app } from "../../../../app";
import { Mediator } from "../../../../core/ui-mediator";
import { errcode } from "../../../../def/auto/protocol";
import { ui } from "../../../../misc/ui";
import { P1v1UIArgs } from "../../ui-runtime/P1v1UI";
import { BattleReportUI } from "../../ui-runtime/pvp/BattleReportUI";

@Laya.regClass('ydw_cgNXShC7ZMGr5VTkhg')
export class BattleReportMediator extends Mediator {
    declare owner: BattleReportUI;

    override onAwake() {
        this._loadBattleReport();
    }

    private async _loadBattleReport() {
        const data = await app.service.pvp.requestLoadReportList();
        if (data.err === errcode.OK) {
            this.owner.list.renderHandler = new Laya.Handler(
                this,
                (item: Laya.Box, index: number) => {
                    const url = data.urlList[index];
                    const name = url.substring(url.lastIndexOf("/") + 1);
                    (item.getChildByName("label") as Laya.Label).text = name;
                }
            );
            this.owner.list.selectHandler = new Laya.Handler(this, async (index: number) => {
                this.owner.destroy();
                const url = data.urlList[index];
                app.ui.load(ui.PVP_1v1)?.then((p1v1) => {
                    p1v1.open(false, { url: url } as P1v1UIArgs);
                });
            });
            this.owner.list.array = data.urlList;
        }
    }
}
