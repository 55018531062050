import { app } from "../../../../app";
import { MonsterPropRow } from "../../../../def/table";
import { Vo } from "../../../../misc/vo/Vo";
import { TableUtil } from "../../../table/TableUtil";

export class MonsterLo extends Vo<MonsterPropRow, never> {
    // 等级先不生效
    private static calcuAttr(
        row: MonsterPropRow,
        attr: "atk" | "hp" | "defence",
        lv: number = 1
    ): number {
        const attrRow = TableUtil.getRow(app.service.table.attr, { define: attr })!;
        const baseKey = `attr_base${attrRow.id}` as keyof MonsterPropRow;
        const ret = row[baseKey];
        if (ret === undefined || typeof ret !== "number") {
            console.error(`${attr}, no ${baseKey} in monster prop id: ${row}`);
        }
        return row[baseKey] as number;
    }

    private cachedAttr: { [attr: string]: { [lv: number]: number } } = {};

    override get key() {
        return this._config.id;
    }

    override clone() {
        return new MonsterLo(this._config);
    }

    get battleEntityId() {
        return this._config.battle_entity;
    }

    get skill0() {
        return this._config.skill0;
    }

    get skill1() {
        return this._config.skill1;
    }

    get skill2() {
        return this._config.skill2;
    }

    get skill3() {
        return this._config.skill3;
    }

    get skill4() {
        return this._config.skill4;
    }

    getAtk(lv = 1) {
        return this._getAttr("atk", lv);
    }

    getHp(lv = 1) {
        return this._getAttr("hp", lv);
    }

    getDefence(lv = 1) {
        return this._getAttr("defence", lv);
    }

    private _getAttr(attr: "atk" | "hp" | "defence", lv = 1) {
        if (this.cachedAttr[attr] && this.cachedAttr[attr][lv]) {
            return this.cachedAttr[attr][lv];
        }
        const ret = MonsterLo.calcuAttr(this._config, attr, lv);
        if (!this.cachedAttr[attr]) {
            this.cachedAttr[attr] = {};
        }
        this.cachedAttr[attr][lv] = ret;
        return ret;
    }
}
