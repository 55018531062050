/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * scenes/login.ls
 */
export class LoginUIBase extends Laya.Scene {
    public imgBg!: Laya.Image;
    public boxCluster!: Laya.Box;
    public inputAccount!: Laya.TextInput;
    public btnLogin!: Laya.Button;
    public labAgree!: Laya.Label;
    public boxCheck!: Laya.Box;
    public img_gou!: Laya.Image;
    public btnSelectServer!: Laya.Box;
    public imgState!: Laya.Image;
    public labelServerName!: Laya.Label;
    public listServer!: Laya.List;
    public listItemBox!: Laya.Box;
}

