import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { BattleEntityType } from "../../../../../def/auto/battle";
import { SkillTreeEnv } from "../../ecs/components/PveSvrSkillLauncherComponent";
import { PveSvrSoldierGroupComponent } from "../../ecs/components/PveSvrSoldierGroupComponent";

type NodeInput = [number?];

export class GetSoldierGroupStation extends Process {
    override run(node: Node, env: SkillTreeEnv): Status {
        let soldierGroupComp: PveSvrSoldierGroupComponent | undefined;
        const [inputEid] = env.input as NodeInput;
        if (inputEid) {
            soldierGroupComp = env.context.ecs.getComponent(inputEid, PveSvrSoldierGroupComponent);
        } else {
            if (env.owner.etype !== BattleEntityType.SOLDIER_GROUP) {
                console.error(
                    `GetSoldierGroupStation: owner ${env.owner.eid} is not a soldier group`
                );
                return "failure";
            }
            soldierGroupComp = env.owner.getComponent(PveSvrSoldierGroupComponent);
        }

        if (!soldierGroupComp) {
            console.error(
                `GetSoldierGroupStation: target ${inputEid} or owner ${env.owner.eid} not found`
            );
            return "failure";
        }

        env.output.push(soldierGroupComp.station);
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "GetSoldierGroupStation",
            type: "Action(Skill)" as "Action",
            desc: "获取本身的士兵组位置",
            input: ["目标士兵组?"],
            output: ["士兵组位置"],
            doc: `
                + 优先取输入的士兵组，如果没有输入则获取本身的士兵组
            `,
        };
    }
}
