import { Constructor } from "../../../../../core/dispatcher";
import * as ecs from "../../../../../core/ecs";
import { Formation } from "../../../../../def/formation";
import { Animator } from "../../../base/Animator";
import { PvpOwnerComponent } from "./PvpOwnerComponent";
import { PvpStateComponent } from "./PvpStateComponent";
import { PvpTransformComponent } from "./PvpTransformComponent";

class DebugRenderable3D extends ecs.Component {
    // As Renderable3D
    readonly mounter: Laya.Sprite3D = new Laya.Sprite3D();

    res!: string;
    view?: Laya.Sprite3D | null;
}

export class PvpTroopComponent extends DebugRenderable3D {
    override view?: Laya.Sprite3D | null;
    hero?: Laya.Sprite3D | null;
    soldiers: Laya.Sprite3D[] = [];
    animators: Animator[] = [];

    formation!: { move: Formation; fight: Formation };

    heroId: number = 0;
    soldierId: number[] = [];
    battleEid: number = 0;
    maxHp: number = 0;
    hp: number = 0;

    override get dependencies(): Constructor<ecs.Component>[] {
        return [
            PvpTransformComponent,
            /** 有移动时才有 */
            // PvpMovementComponent,
            PvpOwnerComponent,
            PvpTroopComponent,
            PvpStateComponent,
        ];
    }
}
