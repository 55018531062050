import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { GM_PurchaseItem } from "./ui-runtime/GM_PurchaseItem";
import { UI_GM_Purchase } from "./ui-runtime/UI_GM_Purchase";
const { regClass, property } = Laya;

@regClass('LR6BVI_bRaidrvAy8wF55Q')
export class GMPurchaseMediator extends Mediator {
    declare owner: UI_GM_Purchase;

    open(): void {
        this.owner.itemList.renderHandler = new Laya.Handler(this, this.onListItemRenderer);
        this.updateShow();
    }

    private onListItemRenderer(item: GM_PurchaseItem, index: number): void {
        // console.log("index:" + index + "data:" + item.dataSource);
        item.updateShow();
    }

    private updateShow(): void {
        const allLo = app.service.bag.itemLoMap.getAllItemLo();
        this.owner.itemList.array = allLo;
    }

    public onClickSearchBtn(): void {
        const search = this.owner.searchInput.text;
        if (!search) {
            this.updateShow();
            return;
        }
        const allLo = app.service.bag.itemLoMap.getAllItemLo();
        const searchLo = allLo.filter((lo) => lo.name.indexOf(search) !== -1);
        this.owner.itemList.array = searchLo;
    }
}
