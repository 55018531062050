/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { BaseRoundWindowBg } from "../../../../common/ui-runtime/dialog/BaseRoundWindowBg";
import { BDFuncLevelBlockUI } from "./BDFuncLevelBlockUI";
import { BDFuncProductionProgressBlockUI } from "./BDFuncProductionProgressBlockUI";
import { BDFuncProductionSpeedBlockUI } from "./BDFuncProductionSpeedBlockUI";

/**
 * resources/prefab/battle/pve-ui/building_func/ui_bd_collection_factory.lh
 */
export class UIBDCollectionFactoryBase extends Laya.Dialog {
    public windowBG!: BaseRoundWindowBg;
    public levelBlock!: BDFuncLevelBlockUI;
    public speedBlock!: BDFuncProductionSpeedBlockUI;
    public progressBlock!: BDFuncProductionProgressBlockUI;
    public btn_upgrade!: Laya.Button;
    public img_clock!: Laya.Image;
    public lab_clock!: Laya.Label;
    public btn_add_speed!: Laya.Button;
    public lab_01!: Laya.Label;
    public costList!: Laya.List;
}
