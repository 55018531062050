/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { ChestBoxDownBlock } from "./sub/ChestBoxDownBlock";
import { ChestBoxHero } from "./sub/ChestBoxHero";
import { ChestBoxLeftUpBtn } from "./sub/ChestBoxLeftUpBtn";
import { ChestBoxProbabilityTips } from "./sub/ChestBoxProbabilityTips";
import { ChestBoxStack } from "./sub/ChestBoxStack";

/**
 * resources/prefab/chest-box/UI_ChestBox.lh
 */
export class UIChestBoxBase extends Laya.Dialog {
    public downBlock!: ChestBoxDownBlock;
    public openBtn!: Laya.Button;
    public boxStack!: ChestBoxStack;
    public boxHero!: ChestBoxHero;
    public tips!: ChestBoxProbabilityTips;
    public leftUpBtn!: ChestBoxLeftUpBtn;
}
