import { Node, NodeDef } from "../../../../../core/behavior3/node";
import { Process, Status } from "../../../../../core/behavior3/process";
import { TreeEnv } from "../../../../../core/behavior3/tree-env";

const enum SignAction {
    POSITIVE = 1,
    NEGATIVE = 2,
    INVERTED = 3,
}

interface NodeArgs {
    readonly sign: SignAction;
    readonly value: string;
}

type NodeInput = [number];

export class NumberSign extends Process {
    override init(node: Node): void {}

    override run(node: Node, env: TreeEnv): Status {
        const args = node.args as unknown as NodeArgs;
        const [value] = env.input as NodeInput;
        if (!(typeof value === "number") || Number.isNaN(value)) {
            node.warn(`input value ${value} is not a number`);
            return "failure";
        }
        switch (args.sign) {
            case SignAction.POSITIVE:
                env.output.push(Math.abs(value));
                break;
            case SignAction.NEGATIVE:
                env.output.push(-Math.abs(value));
                break;
            case SignAction.INVERTED:
                env.output.push(-value);
                break;
            default:
                node.warn(`invalid sign action ${args.sign}`);
                env.output.push(value);
        }
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "NumberSign",
            type: "Action",
            desc: "给number值进行符号转换,输入值必须为number类型",
            args: [
                {
                    name: "sign",
                    type: "enum",
                    desc: "取值方式",
                    options: [
                        { name: "取正", value: SignAction.POSITIVE },
                        { name: "取负", value: SignAction.NEGATIVE },
                        { name: "取反", value: SignAction.INVERTED },
                    ],
                },
            ],
            input: ["值"],
            output: ["计算结果"],
            doc: `
                + 做简单的数值公式计算，返回结果到输出`,
        };
    }
}
