import { Pool } from "../../../core/pool";
import { TMElementDescriptor } from "../tilemap/tm-element";
import {
    BattlePveMapTransferItem,
    InteractiveBubbleActionType,
    InteractiveBubbleViewType,
} from "./PveDefs";

export interface IPveSvrReceiver {
    joystickStart(eid: number, dir: Laya.Vector3): void;
    joystickStop(eid: number, activeAI: boolean): void;

    addElement(descriptor: TMElementDescriptor): void;
    removeElement(descriptor: TMElementDescriptor): void;

    grubItem(eid: number): void;
    removePlotTheatreEvent(eid: number): void;
    click(x: number, z: number): void;
    heroReborn(resetPos: boolean): void;
    mapTransfer(data: BattlePveMapTransferItem): void;

    gmCmd(cmd: string): void;

    onClickBackToTerritory(): void;

    onStartPlotTheatre(): void;

    harvestCollection(eid: number): void;

    interactiveBubbleAction(
        eid: number,
        bubbleViewType: InteractiveBubbleViewType,
        bubbleActionType: InteractiveBubbleActionType,
        param: unknown
    ): void;
}

export interface IPveSvrCommandData<T extends keyof IPveSvrReceiver> {
    name: T;
    args: Parameters<IPveSvrReceiver[T]>;
}

const copyV3 = (v3: Laya.Vector3) => {
    const v = Pool.obtain(Laya.Vector3);
    v.cloneFrom(v3);
    return v;
};

export class PveSvrReceiver implements IPveSvrReceiver {
    constructor(private _commands: IPveSvrCommandData<keyof IPveSvrReceiver>[]) {}

    private _push<T extends keyof IPveSvrReceiver>(cmd: IPveSvrCommandData<T>) {
        this._commands.push(cmd);
    }

    joystickStart(eid: number, dir: Laya.Vector3): void {
        this._push({
            name: "joystickStart",
            args: [eid, copyV3(dir)],
        });
    }

    joystickStop(eid: number, activeAI: boolean): void {
        this._push({ name: "joystickStop", args: [eid, activeAI] });
    }

    addElement(descriptor: TMElementDescriptor): void {
        this._push({ name: "addElement", args: [descriptor] });
    }

    removeElement(descriptor: TMElementDescriptor): void {
        this._push({ name: "removeElement", args: [descriptor] });
    }

    grubItem(eid: number): void {
        this._push({ name: "grubItem", args: [eid] });
    }

    click(x: number, z: number): void {
        this._push({ name: "click", args: [x, z] });
    }

    heroReborn(resetPos: boolean): void {
        this._push({ name: "heroReborn", args: [resetPos] });
    }

    mapTransfer(data: BattlePveMapTransferItem): void {
        this._push({ name: "mapTransfer", args: [data] });
    }

    gmCmd(cmd: string): void {
        this._push({ name: "gmCmd", args: [cmd] });
    }

    onClickBackToTerritory(): void {
        this._push({ name: "onClickBackToTerritory", args: [] });
    }

    onStartPlotTheatre(): void {
        this._push({ name: "onStartPlotTheatre", args: [] });
    }

    harvestCollection(eid: number): void {
        this._commands.push({ name: "harvestCollection", args: [eid] });
    }

    removePlotTheatreEvent(eid: number): void {
        this._commands.push({ name: "removePlotTheatreEvent", args: [eid] });
    }

    interactiveBubbleAction(
        eid: number,
        bubbleViewType: InteractiveBubbleViewType,
        bubbleActionType: InteractiveBubbleActionType,
        param: unknown
    ): void {
        this._commands.push({
            name: "interactiveBubbleAction",
            args: [eid, bubbleViewType, bubbleActionType, param],
        });
    }
}
