import { app } from "../../../app";
import { VoMap } from "../../../misc/vo/VoMap";
import { TableUtil } from "../../table/TableUtil";
import { LeaderSkinLo } from "./LeaderSkinLo";

export class LeaderSkinLoMap extends VoMap<number, LeaderSkinLo> {
    override get(id: number): LeaderSkinLo {
        let ret = super.get(id);
        if (!ret) {
            const row = TableUtil.getRow(app.service.table.leaderskin.skin, { id: id })!;
            if (!row) {
                throw new Error(`no monster prop id: ${id}`);
            }
            ret = new LeaderSkinLo(row);
            this.set(id, ret);
        }
        return ret;
    }
}
