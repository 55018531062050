export class TimeFormatUtils {
    /**
     * 格式化时间显示
     * @param sec 总时间，单位为秒
     * @param fmt 时间格式
     * 这里传递进来的是一个总时间，不是时间戳，表示共有多少年、多少个月，多少天，多少个小时，多少秒
     * 例如：
     * 1、timeFormat(10000,"YYYY年MM月dd日 h:m:s") 输出：'0年00月00日 0:0:10'：表示共有10秒
     * 2、timeFormat(10000000000,"YYYY年MM月dd日 h:m:s") '0年03月25日 17:46:40' 表示共有3个月，25天，17个小时，46分40秒
     */
    public static totalTimeFormat(sec: number, fmt: string = "YY-MM-dd h:m:s"): string {
        let d: boolean = true;
        const totalSec = sec | 0;
        const totalMin = (sec / 60) | 0;
        const totalHour = (sec / 3600) | 0;
        const totalDay = (sec / 3600 / 24) | 0;
        const totalMonth = (sec / 3600 / 24 / 30) | 0;
        const totalYear = (sec / 3600 / 24 / 30 / 12) | 0;
        const format = (reg: RegExp, value: number) => {
            const match = fmt.match(reg)?.[1];
            if (match) {
                const valueStr = value.toFixed(0);
                const addZeroStr = value < 10 ? "0" + value : valueStr;
                fmt = fmt.replace(match, match.length === 2 ? addZeroStr : valueStr);
                return true;
            } else {
                return false;
            }
        };
        d = format(/(Y+)/, totalYear) ? false : d;
        d = format(/(M+)/, d ? totalMonth : totalMonth % 12) ? false : d;
        d = format(/(d+)/, d ? totalDay : totalDay % 30) ? false : d;
        d = format(/(h+)/, d ? totalHour : totalHour % 24) ? false : d;
        d = format(/(m+)/, d ? totalMin : totalMin % 60) ? false : d;
        d = format(/(s+)/, d ? totalSec : totalSec % 60);

        return fmt;
    }

    /**
     * 格式化时间显示
     * @param time 时间戳
     * 返回剩余时间显示，显示格式天时分秒 如果大于24小时只显示到天
     */
    public static timeFormatReMain(time: number): string {
        const totalSec = time % 60 | 0;
        const totalMin = Math.floor((time % 3600) / 60) | 0;
        const totalHour = Math.floor((time % (3600 * 24)) / 3600) | 0;
        const totalDay = Math.floor((time % (3600 * 24 * 30)) / (3600 * 24)) | 0;

        const totalMonth = Math.floor((time % (3600 * 24 * 30 * 12)) / (3600 * 24 * 30)) | 0;

        const totalYear = Math.floor(time / (3600 * 24 * 30 * 12)) | 0;
        let fmt: string = "";

        fmt += totalYear ? totalYear + "年" : "";
        fmt +=
            totalMonth || totalYear
                ? (totalMonth > 10 ? `${totalMonth}` : `0${totalMonth}`) + "月"
                : "";
        fmt +=
            totalDay || totalMonth || totalYear
                ? (totalDay > 10 ? `${totalDay}` : `0${totalDay}`) + "天"
                : "";
        if (!totalDay && !totalMonth && !totalYear) {
            fmt +=
                totalHour || totalDay || totalMonth || totalYear
                    ? (totalHour > 10 ? `${totalHour}` : `0${totalHour}`) + "时"
                    : "";
            fmt +=
                totalMin || totalHour || totalDay || totalMonth || totalYear
                    ? (totalMin > 10 ? `${totalMin}` : `0${totalMin}`) + "分"
                    : "";
            fmt += (totalSec > 10 ? `${totalSec}` : `0${totalSec}`) + "秒";
        }
        return fmt;
    }

    //显示年月日
    public static timeFormatYYMMDD(time: number, line: string = "-") {
        const totalDay = Math.floor((time % (3600 * 24 * 30)) / (3600 * 24)) | 0;

        const totalMonth = Math.floor((time % (3600 * 24 * 30 * 12)) / (3600 * 24 * 30)) | 0;

        const totalYear = Math.floor(time / (3600 * 24 * 30 * 12)) | 0;

        const fmt = totalYear + line + totalMonth + line + totalDay;
        return fmt;
    }
}
