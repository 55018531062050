import { NodeDef } from "../../../core/behavior3";
import { PveServer } from "./PveServer";

export class ExportNodes extends PveServer {
    constructor() {
        super(null!);
    }

    stringify() {
        const descriptors: NodeDef[] = [];
        for (const v of this._processResolvers.values()) {
            descriptors.push(v.descriptor);
        }
        descriptors.sort((a, b) => a.name.localeCompare(b.name));
        let str = JSON.stringify(descriptors, null, 2);
        str = str.replace(/"doc": "\\n +/g, '"doc": "');
        str = str.replace(/\\n +/g, "\\n");
        return str;
    }
}
