import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { BattleConf } from "../../../../../def/auto/battle";
import { AnimName } from "../../../base/Animator";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import {
    MovementUpdateType,
    PveSvrMovementComponent,
} from "../../ecs/components/PveSvrMovementComponent";
import { NodeStyle } from "../BtreeDef";

export class BackSpawnpoint extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const speed = node.resume(env) as number | undefined;

        const transform = env.owner.transform;

        const p1 = env.owner.spawnpoint;
        const p0 = transform.position;
        const distance = Laya.Vector3.distanceXZ(p1, p0);

        if (distance <= 0.01) {
            env.context.moveStop(env.owner.eid);
            env.context.playAnim(env.owner.eid, AnimName.IDLE);
            return "success";
        }
        const movement = env.owner.getComponent(PveSvrMovementComponent);
        if (!movement) {
            return "failure";
        }
        if (
            !speed ||
            movement.updateType !== MovementUpdateType.TARGET_POSITION ||
            movement.speed === 0
        ) {
            env.context.moveTo(env.owner, p1, BattleConf.PVE.MOVE_SPEED);
        }
        return node.yield(env, BattleConf.PVE.MOVE_SPEED);
    }

    override get descriptor(): NodeDef {
        return {
            name: "BackSpawnpoint",
            type: "Action (PVE)" as NodeDef["type"],
            desc: "回归出生点",
            icon: NodeStyle.runningAction.icon,
            color: NodeStyle.runningAction.color,
            doc: `
                + 士兵归队，归队过程中，主执行其它命令`,
        };
    }
}
