import { Callback } from "../../../core/dispatcher";
import { UIHSliderBase } from "./UIHSlider.generated";

const { regClass, property } = Laya;
@Laya.regClass('zj1-gZcISYCAYO8QP5qBPQ')
export class UIHSlider extends UIHSliderBase {
    private _onSliderChange!: Callback;

    private _globalSacle!: Laya.Point;

    private _maxMove: number = 0;

    private _tx: number = 0;
    private _ty: number = 0;

    private _max: number = 0;
    private _min: number = 0;

    private _value: number = 0;

    isVertical: boolean = false; //是否水平的滑动条

    private barInitPoint!: Laya.Point;

    override onAwake(): void {
        super.onAwake();
        this.on(Laya.Event.CLICK, () => {});

        this.addBtn.on(Laya.Event.CLICK, this, this.onAddBtn);
        this.minBtn.on(Laya.Event.CLICK, this, this.onMinBtn);
        this.updateBtnType();
        this.barInitPoint = Laya.Point.create().setTo(this.imgBar.x, this.imgBar.y);
        this.imgBar.on(Laya.Event.MOUSE_DOWN, this, this.onBarMouseDown);

        this._maxMove = this.isVertical
            ? this.imgBg.height - this.imgBar.height / 2
            : this.imgBg.width - this.imgBar.width / 2;
    }

    get max() {
        return this._max;
    }

    get min() {
        return this._min;
    }

    public setSlider(min: number, max: number, value: number) {
        this._min = min;
        this._max = max;
        this._value = value;
        this.callLater(this.changeValue);
        this.updateBtnType();
    }

    get value() {
        return this._value;
    }

    private onBarMouseDown(e: Laya.Event) {
        this._tx = e.stageX;
        this._ty = e.stageY;
        this._globalSacle || (this._globalSacle = new Laya.Point());
        this._globalSacle.setTo(this.globalScaleX || 0.01, this.globalScaleY || 0.01);
        Laya.stage.on(Laya.Event.MOUSE_MOVE, this, this.mouseMove);
        Laya.stage.once(Laya.Event.MOUSE_UP, this, this.mouseUp);
        Laya.stage.once(Laya.Event.MOUSE_OUT, this, this.mouseUp);
    }

    mouseUp(e: Laya.Event) {
        const stage = Laya.stage;
        stage.off(Laya.Event.MOUSE_MOVE, this, this.mouseMove);
        stage.off(Laya.Event.MOUSE_UP, this, this.mouseUp);
        stage.off(Laya.Event.MOUSE_OUT, this, this.mouseUp);
    }

    private sendChangeEvent() {
        if (this._onSliderChange) {
            this._onSliderChange();
        }
    }

    private mouseMove() {
        const stage = Laya.stage;
        const oldValue = this._value;
        if (this.isVertical) {
            this.imgBar.y += (stage.mouseY - this._ty) / this._globalSacle.y;
            if (this.imgBar.y - this.barInitPoint.y > this._maxMove) {
                this.imgBar.y = this._maxMove + this.barInitPoint.y;
            } else if (this.imgBar.y < this.barInitPoint.y) {
                this.imgBar.y = this.barInitPoint.y;
            }
            this._value = (this.imgBar.y / this._maxMove) * (this._max - this._min) + this._min;
            this.imgPro.height = this.imgBar.y + 0.5 * this.imgBar.height;
        } else {
            this.imgBar.x += (stage.mouseX - this._tx) / this._globalSacle.x;
            if (this.imgBar.x - this.barInitPoint.x > this._maxMove) {
                this.imgBar.x = this.barInitPoint.x + this._maxMove;
            } else if (this.imgBar.x < this.barInitPoint.x) {
                this.imgBar.x = this.barInitPoint.x;
            }
            const value =
                ((this.imgBar.x - this.barInitPoint.x) / this._maxMove) * (this._max - this._min) +
                this._min;
            this._value = Math.floor(value);
            this.imgPro.width = this.imgBar.x - this.barInitPoint.x + 0.5 * this.imgBar.width;
        }
        this._tx = stage.mouseX;
        this._ty = stage.mouseY;
        if (this._value !== oldValue) {
            this.sendChangeEvent();
        }
        this.updateBtnType();
    }

    changeValue() {
        if (this._max >= this._min) {
            this._value =
                this._value > this._max
                    ? this._max
                    : this._value < this._min
                    ? this._min
                    : this._value;
        } else {
            this._value =
                this._value > this._min
                    ? this._min
                    : this._value < this._max
                    ? this._max
                    : this._value;
        }
        let num = this._max - this._min;
        if (num === 0) {
            num = 1;
        }
        if (this.isVertical) {
            this.imgBar.y = ((this._value - this._min) / num) * this._maxMove + this.barInitPoint.y;
            this.imgPro.height = this.imgBar.y - this.barInitPoint.y + 0.5 * this.imgBar.height;
        } else {
            this.imgBar.x = ((this._value - this._min) / num) * this._maxMove + this.barInitPoint.x;
            this.imgPro.width = this.imgBar.x - this.barInitPoint.x + 0.5 * this.imgBar.width;
        }
    }

    private onAddBtn() {
        this._value++;
        this.updateBtnType();
        this.changeValue();
        if (this._onSliderChange) {
            this._onSliderChange();
        }
    }

    private onMinBtn() {
        this._value--;
        this.updateBtnType();
        this.changeValue();
        if (this._onSliderChange) {
            this._onSliderChange();
        }
    }

    private updateBtnType() {
        this.addBtn.disabled = this.value >= this.max;
        this.minBtn.disabled = this.value <= this.min;
    }

    set onSliderChange(func: Callback) {
        this._onSliderChange = func;
    }
}
