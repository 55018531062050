import "./utils/math-util";

export type Constructor<T, A extends any[] = any[]> = new (...args: A) => T;

export type ConstructorType<T> = {
    [K in keyof T]?: T[K];
};

export interface Callback<A extends any[] = any[]> {
    (...any: A): void;
}

export type ContextChecker = () => boolean;

// eslint-disable-next-line
export type AnyFunction = Function;

export const toEventType = (type: string | number) => {
    if (typeof type === "number") {
        return `number-event:${type}`;
    } else {
        return type;
    }
};

export class Dispatcher extends Laya.EventDispatcher {
    override event(type: number, data?: any): boolean;

    override event(type: string, data?: any): boolean;

    override event(type: string | number, data?: any): boolean {
        return super.event(toEventType(type), data);
    }
}

export function assert(condition: boolean, message?: string): asserts condition {
    if (!condition) {
        throw new Error(`assert: ${message}`);
    }
}
