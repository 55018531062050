import { BattleConf } from "../../../../../../def/auto/battle";
import { mainline } from "../../../../../../def/auto/proto";

/** 主角记录的数据 */
export class PveSvrHeroStateData {
    /**
     * 位置
     */
    pos: Laya.Vector2 = new Laya.Vector2();

    /** 朝向 */
    rotation: number = 0;

    /** 当前血量 */
    hp: number = 0;

    /** 最大血量 */
    maxHp: number = 0;

    inited: boolean = false;

    tid: number = 0;

    level: number = 1;

    stage: number = 0;

    readonly unlockSodiderIndexes: number[] = [];

    isUnlockTroop: boolean = false; //是否解锁PVE编队，意味着编队读取PVE编队配置

    encode() {
        if (!this.tid) {
            return undefined;
        }

        const data = mainline.Charactor.create();
        data.tid = this.tid;

        data.pos = mainline.Position.create();
        data.pos.x = (this.pos.x * 10) | 0;
        data.pos.z = (this.pos.y * 10) | 0;
        data.pos.rotation = ((this.rotation % 360) + 360) % 360 | 0;

        data.health = mainline.Health.create();
        data.health.hp = Math.min(this.maxHp, Math.max(0, this.hp)) | 0;
        data.health.maxHp = this.maxHp | 0;
        data.level = this.level;
        data.stage = this.stage;

        const count = BattleConf.PVE.SOLDIER_ONE_ROW_COUNT * 3 + 1;
        if (this.isUnlockTroop) {
            data.unlockSoldiers = 1 << count;
        } else {
            data.unlockSoldiers = this.unlockSodiderIndexes.reduce((acc, cur) => {
                return acc | (1 << cur);
            }, 0);
        }
        // console.log("PveSvrHeroStateData encode", data);
        return data;
    }

    decode(data: mainline.Charactor) {
        this.tid = data.tid;
        this.pos.x = (data.pos?.x ?? 0) / 10;
        this.pos.y = (data.pos?.z ?? 0) / 10;
        this.rotation = data.pos?.rotation ?? 0;
        this.maxHp = data.health?.maxHp ?? 0;
        this.hp = Math.min(this.maxHp, data.health?.hp ?? 0);
        const count = BattleConf.PVE.SOLDIER_ONE_ROW_COUNT * 3 + 1;
        this.isUnlockTroop = data.unlockSoldiers & (1 << count) ? true : false;
        const totalSoldierCount = BattleConf.PVE.SOLDIER_ONE_ROW_COUNT * 3;
        if (!this.isUnlockTroop) {
            for (let i = 0; i < totalSoldierCount; i++) {
                if (data.unlockSoldiers & (1 << i)) {
                    this.unlockSodiderIndexes.push(i);
                }
            }
        }
        this.level = data.level ?? 1;
        this.stage = data.stage ?? 0;
        // console.log("PveSvrHeroStateData decode", this);
        this.inited = true;
    }
}
