import { app } from "../../../app";
import { Constructor } from "../../../core/dispatcher";
import * as ecs from "../../../core/ecs";
import { Pool } from "../../../core/pool";
import { Mediator } from "../../../core/ui-mediator";
import proto from "../../../def/auto/proto";
import { errcode, opcode } from "../../../def/auto/protocol";
import { EntityType } from "../../../def/auto/world";
import { res } from "../../../misc/res";
import { SystemEvent } from "../../../misc/system-event";
import { ui } from "../../../misc/ui";
import { ITMContext, TMMode } from "../tilemap/tm-def";
import { TMDebugElement, TMElement, TMTileElemet } from "../tilemap/tm-element";
import { P1v1UIArgs } from "../ui-runtime/P1v1UI";
import { PnvnUIArgs } from "../ui-runtime/PnvnUI";
import { PvpUI } from "../ui-runtime/PvpUI";
import { PvpArrowsRenderCompnent } from "./ecs/components/PvpArrowsRenderCompnent";
import { PvpBattleComponent } from "./ecs/components/PvpBattleComponent";
import { PvpBoardComponent } from "./ecs/components/PvpBoardComponent";
import { PvpCameraComponent } from "./ecs/components/PvpCameraComponent";
import { PvpMapComponent } from "./ecs/components/PvpMapComponent";
import { PvpMovementComponent } from "./ecs/components/PvpMovementComponent";
import { PvpOwnerComponent } from "./ecs/components/PvpOwnerComponent";
import { PvpTransformComponent } from "./ecs/components/PvpTransformComponent";
import { PvpTroopComponent } from "./ecs/components/PvpTroopComponent";
import { PvpUIComponent } from "./ecs/components/PvpUIComponent";
import { PvpArrowSystem } from "./ecs/systems/PvpArrowSystem";
import { PvpBattleSystem } from "./ecs/systems/PvpBattleSystem";
import { PvpCameraSystem } from "./ecs/systems/PvpCameraSystem";
import { PvpCommandSystem } from "./ecs/systems/PvpCommandSystem";
import { PvpMapSystem } from "./ecs/systems/PvpMapSystem";
import { PvpMovementSystem } from "./ecs/systems/PvpMovementSystem";
import { PvpRenderSystem } from "./ecs/systems/PvpRenderSystem";
import { PvpTroopSystem } from "./ecs/systems/PvpTroopSystem";
import { PvpUISystem } from "./ecs/systems/PvpUISystem";

@Laya.regClass('TfqGD8kRSI-W93k8NRKBIw')
export class PvpContext extends Mediator implements ITMContext {
    declare owner: PvpUI;

    /** 我的主城 eid */
    homeEid: number = 0;
    troopEids: number[] = [];

    private _ecs!: ecs.World;
    private _camera!: Laya.Camera;

    private _cameraNode!: Laya.Sprite3D;

    get scene() {
        return this.owner.scene;
    }

    get scene3D() {
        return this.owner.scene3D;
    }

    get camera() {
        return (this._camera ||= this.scene3D.getChildByName("Main Camera") as Laya.Camera);
    }

    get cameraNode() {
        return (this._cameraNode ||= this.scene3D.getChildByName("cameraNode") as Laya.Sprite3D);
    }

    get mapDir() {
        return "resources/data/tilemap/pvp";
    }

    get mode() {
        return TMMode.PVP;
    }

    get ecs() {
        return this._ecs;
    }

    onAddElement(element: TMElement): void {}

    onDelElement(element: TMElement): void {}

    override onDestroy() {
        app.service.pvp.requestCloseViewport();
        this._ecs.destroy();
        Pool.clear(res.VFX_PVP_MOVE_TARGET, (obj: Laya.Sprite3D) => {
            obj.destroy();
        });
        super.onDestroy();
    }

    override onAwake() {
        super.onAwake();
        this._ecs = new ecs.World(this);
        this._ecs.addSingletonComponent(PvpCameraComponent);
        this._ecs.addSingletonComponent(PvpMapComponent);
        this._ecs.addSingletonComponent(PvpArrowsRenderCompnent);
        const uiComp = this._ecs.addSingletonComponent(PvpUIComponent);
        this._ecs.addSystem(PvpCommandSystem);
        this._ecs.addSystem(PvpMapSystem);
        this._ecs.addSystem(PvpMovementSystem);
        this._ecs.addSystem(PvpCameraSystem);
        this._ecs.addSystem(PvpRenderSystem);
        this._ecs.addSystem(PvpArrowSystem);
        this._ecs.addSystem(PvpTroopSystem);
        this._ecs.addSystem(PvpBattleSystem);
        this._ecs.addSystem(PvpUISystem);

        const selectedTile = this.scene3D.getChildByPath("world-map/selected") as Laya.Sprite3D;
        const debugFocus = this.scene3D.getChildByPath("world-map/debugFocus") as Laya.Sprite3D;
        selectedTile.active = false;
        uiComp.selectedTile = selectedTile;
        debugFocus.active = false;
        uiComp.debugFocus = debugFocus;

        this._startGame();

        app.loader.loadPrefab(res.BATTLE_HP_NUM);
        app.loader.loadPrefab(res.BATTLE_RECOVER_HP_NUM);
        app.loader.loadPrefab(res.BATTLE_HP_NUM_X);
        this.listenEvents();
    }

    private listenEvents() {
        this.$(app).on(SystemEvent.TILEMAP_DEBUG_MODE_UPDATE, this._onTilemapDebugModeUpdate, this);
        this.$(app).on(SystemEvent.PVP.TOUCH_AREA_MOUSE_DOWN, this._onTouchAreaMouseDown, this);
        this.$(app).on(SystemEvent.PVP.TILEMAP_DRAG_START, this._onTilemapDragStart, this);
        this.$(app).on(SystemEvent.PVP.TILEMAP_DRAG_MOVE, this._onTilemapDragMove, this);
        this.$(app).on(SystemEvent.PVP.TILEMAP_DRAG_END, this._onTilemapDragEnd, this);
        this.$(app).on(SystemEvent.PVP.TILEMAP_CLICK, this._onTilemapClick, this);
        this.$(app).on(SystemEvent.UI.ON_CLICK_TROOP_STATE_UI, this._onClickTroopStateUI, this);

        this.$(app).on(
            opcode.troop.s2c_load,
            () => {
                app.event(SystemEvent.PVP.S2C_TROOP_LOAD, this);
            },
            this
        );
    }

    override onUpdate() {
        this.owner.debug.graphics.clear();
        super.onUpdate();
        this._ecs.update(Laya.timer.delta / 1000);
    }

    private _onTilemapDebugModeUpdate() {
        const map = this._ecs.getSingletonComponent(PvpMapComponent);
        map.tilemap.getAllMap().forEach((element) => {
            if (element instanceof TMTileElemet) {
                element.erase();
                element.draw();
            }
        });
        map.tilemap.getAllMap().forEach((element) => {
            if (element instanceof TMDebugElement) {
                element.erase();
                element.draw();
            }
        });
    }

    private _loadP1v1(battle: PvpBattleComponent) {
        app.ui.load(ui.PVP_1v1)?.then((p1v1) => {
            p1v1.open(false, { battleUid: battle.sceneUid } as P1v1UIArgs);
        });
    }

    private _loadPnvn(battle: PvpBattleComponent) {
        app.ui.load(ui.PVP_NVN)?.then((pnvn) => {
            app.event(SystemEvent.PVP.ON_LOAD_PVP_NVN);
            pnvn.open(false, { battleUid: battle.sceneUid } as PnvnUIArgs);
        });
    }

    private async _startGame() {
        const data = await app.service.pvp.requestLoad();
        if (data.err !== errcode.OK) {
            console.log(`load pvp world error: ${data.err}`);
            return;
        }

        const troopLoadData = await app.service.troop.load();
        this.troopEids.push(...troopLoadData.troopList.map((troop) => troop.eid ?? 0));

        this.homeEid = data.homeEid;
        const camera = this._ecs.getSingletonComponent(PvpCameraComponent);
        const homePos = data.homePos as proto.world.Position;
        camera.focus.set(homePos.x, 0, homePos.y);
        app.service.pvp.requestChangeViewport(camera.focus);
    }

    // todo: 这些辅助函数可以写到一个工具类里作为静态函数？参数传入PvpContext就行
    hasEnemyAt(pos: Laya.Vector3) {
        const atX = Math.floor(pos.x);
        const atZ = Math.floor(pos.z);
        const myAid = this.getMyAid();
        for (const [_, troop] of this._ecs.getComponents(PvpTroopComponent)) {
            const position = troop.getComponent(PvpTransformComponent)!.position;
            const owner = troop.getComponent(PvpOwnerComponent)!;
            const x = Math.floor(position.x);
            const z = Math.floor(position.z);
            if (atX === x && atZ === z && owner.aid !== myAid) {
                return true;
            }
        }
        return false;
    }

    hasComponentAt<T extends ecs.Component>(
        cls: Constructor<T>,
        pos: Laya.Vector3,
        filter?: (component: T) => boolean
    ): boolean {
        const atX = Math.floor(pos.x);
        const atZ = Math.floor(pos.z);
        for (const [_, component] of this._ecs.getComponents(cls)) {
            if (filter && !filter(component)) {
                continue;
            }
            const position = component.getComponent(PvpTransformComponent)?.position;
            if (position) {
                const x = Math.floor(position.x);
                const z = Math.floor(position.z);
                if (atX === x && atZ === z) {
                    return true;
                }
            }
        }
        return false;
    }

    getComponentAt<T extends ecs.Component>(
        cls: Constructor<T>,
        pos: Readonly<Laya.Vector3>,
        filter?: (component: T) => boolean
    ): T | null {
        const atX = Math.floor(pos.x);
        const atZ = Math.floor(pos.z);
        for (const component of this._ecs.getComponents(cls).values()) {
            if (filter && !filter(component)) {
                continue;
            }
            const position = component.getComponent(PvpTransformComponent)?.position;
            if (position) {
                const x = Math.floor(position.x);
                const z = Math.floor(position.z);
                if (atX === x && atZ === z) {
                    return component;
                }
            }
        }
        return null;
    }

    getAllComponentAt<T extends ecs.Component>(
        cls: Constructor<T>,
        pos: Readonly<Laya.Vector3>,
        filter?: (component: T) => boolean
    ): T[] {
        const atX = Math.floor(pos.x);
        const atZ = Math.floor(pos.z);
        const ret: T[] = [];
        for (const component of this._ecs.getComponents(cls).values()) {
            if (filter && !filter(component)) {
                continue;
            }
            const position = component.getComponent(PvpTransformComponent)?.position;
            if (position) {
                const x = Math.floor(position.x);
                const z = Math.floor(position.z);
                if (atX === x && atZ === z) {
                    ret.push(component);
                }
            }
        }
        return ret;
    }

    hasResouceAt(pos: Laya.Vector3) {
        return this.hasComponentAt(
            PvpBoardComponent,
            pos,
            (component) => component.etype === EntityType.MINE
        );
    }

    getMyAid() {
        const eid = app.service.troop.troopTeamVoMap.toArray().find((t) => !!t.eid)?.eid;
        if (!eid) {
            // TODO: 无部队时要处理
            return 0;
        }
        const owner = this._ecs.getComponent(eid, PvpOwnerComponent);
        if (owner) {
            return owner.aid;
        } else {
            return 0;
        }
    }

    getMyRid() {
        return app.service.user.rid;
    }

    //#region event
    private _onClickTroopStateUI() {
        const uiComp = this.ecs.getSingletonComponent(PvpUIComponent);
        uiComp.actionMenu?.removeSelf();
    }

    private _onTouchAreaMouseDown() {
        const camera = this.ecs.getSingletonComponent(PvpCameraComponent);
        camera.focusEid = null;
        const uiComp = this.ecs.getSingletonComponent(PvpUIComponent);
        uiComp.actionMenu?.removeSelf();
        uiComp.resourceInfo?.removeSelf();
    }

    private _onTilemapDragStart() {
        const uiComp = this.ecs.getSingletonComponent(PvpUIComponent);
        uiComp.actionMenu?.removeSelf();
        uiComp.resourceInfo?.removeSelf();
    }

    private _onTilemapDragMove(offset: Laya.Vector3) {
        const camera = this.ecs.getSingletonComponent(PvpCameraComponent);
        camera.focus.vadd(offset, camera.focus);
        const uiComp = this.ecs.getSingletonComponent(PvpUIComponent);
        if (uiComp.selectedTile?.active) {
            uiComp.selectedTile.active = false;
        }
    }

    private _onTilemapDragEnd() {
        const camera = this.ecs.getSingletonComponent(PvpCameraComponent);
        const uiComp = this.ecs.getSingletonComponent(PvpUIComponent);

        // 拖动事件
        const position = uiComp.debugFocus.transform.position;
        position.cloneFrom(camera.focus);
        position.x = Math.floor(position.x);
        position.z = Math.floor(position.z);
        uiComp.debugFocus.transform.position = position;
        app.service.pvp.requestChangeViewport(camera.focus);
    }

    private _onTilemapClick(clickAt: Laya.Vector3) {
        const uiComp = this.ecs.getSingletonComponent(PvpUIComponent);
        // 点击事件
        console.log("select tile:", clickAt.x, clickAt.z);

        Laya.Vector3._tempVector3.setValue(
            clickAt.x,
            uiComp.selectedTile.transform.position.y,
            clickAt.z
        );
        uiComp.selectedTile.transform.position = Laya.Vector3._tempVector3;
        uiComp.selectedTile.active = true;

        const battle = this.getComponentAt(PvpBattleComponent, clickAt);
        if (battle) {
            // this._loadP1v1(battle);
            this._loadPnvn(battle);
        }

        // 获取当前各自所有的部队，显示部队信息
        const selectedEid = this.trySelectTroop(clickAt);
        if (!selectedEid) {
            // 显示菜单
            app.event(SystemEvent.PVP.OPEN_ACTION_MENU, clickAt);
            app.event(SystemEvent.PVP.SELECT_TROOP, [0, "MAP"]);
        } else {
            const moveComp = this._ecs.getComponent(selectedEid, PvpMovementComponent);
            if (!moveComp || moveComp.speed === 0) {
                // 显示菜单
                app.event(SystemEvent.PVP.OPEN_ACTION_MENU, clickAt);
            }

            // 如果选中了部队，显示部队信息..  通过发射事件去实现
            app.event(SystemEvent.PVP.SELECT_TROOP, [selectedEid, "MAP"]);
        }
    }
    //#endregion event

    allTroopsAt(pos: Readonly<Laya.Vector3>): number[] {
        const troopComps = this.getAllComponentAt(PvpTroopComponent, pos, (comp) => {
            return !!comp.getComponent(PvpOwnerComponent);
        });
        return troopComps.map((comp) => comp.eid);
    }

    private trySelectTroop(currXZPos: Readonly<Laya.Vector3>): number {
        const troopEids = this.allTroopsAt(currXZPos);
        if (!troopEids || troopEids.length === 0) {
            return 0;
        }
        troopEids.sort(); // todo: 按优先级排序，self, friend, enemy
        for (let i = 0; i < troopEids.length; i++) {
            const eid = troopEids[i];
            const troopComp = this._ecs.getComponent(eid, PvpTroopComponent);
            const ownerComp = this._ecs.getComponent(eid, PvpOwnerComponent);
            if (!troopComp || !ownerComp) {
                continue;
            }
            return eid;
        }
        return 0;
    }
}
