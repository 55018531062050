/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/common/item-icon/base_mid_item_icon.lh
 */
export class BaseMidItemIconBase extends Laya.Box {
    public img_bg!: Laya.Image;
    public img_icon!: Laya.Image;
}

