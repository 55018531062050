const { regClass } = Laya;
import { ItemVo } from "../vo/ItemVo";
import { UIItemTipsBase } from "./UIItemTips.generated";
@regClass('mXUur_sbSE-pL5tYzZFkKA')
export class UIItemTips extends UIItemTipsBase {
    public itemData!: ItemVo;

    // // isShowEffect = false;
    // override onAwake(): void {
    //     this.boxMask.on(Laya.Event.CLICK, () => {
    //         this.close();
    //     });
    // }

    override open(closeOther?: boolean | undefined, param?: ItemVo): void {
        this.itemData = param!;
        super.open(closeOther, param);
        // Laya.Dialog.manager.open(this, closeOther, false);
        // Laya.Dialog.lock(false);
    }
}
