import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { PveEventType, PveNpcStateType } from "../../../../../def/auto/battle";
import { MainlineConf } from "../../../../../def/auto/mainline";
import { SystemEvent } from "../../../../../misc/system-event";
import { TaskState, TaskVo } from "../../../../task/vo/TaskVo";
import { BattleEventLo } from "../../../pve/vo/BattleEventLo";
import { TMLayerName } from "../../../tilemap/tm-def";
import { TMUtil } from "../../../tilemap/tm-util";
import { PveServer } from "../../PveServer";
import { PveSvrElementComponent } from "../components/PveSvrElementComponent";
import { PveSvrCloudSystem } from "./PveSvrCloudSystem";
import { PveSvrStateSystem } from "./PveSvrStateSystem";

export class PveSvrNpcSystem extends ecs.System {
    declare context: PveServer;

    override onCreate(): void {
        app.on(SystemEvent.TASK_NEW_NPC_TASK, this, this.onNewNpcTaskReceive);
    }

    override onDestroy(): void {
        app.off(SystemEvent.TASK_NEW_NPC_TASK, this, this.onNewNpcTaskReceive);
    }

    /** 接取到新的npc任务，在场景上添加npc */
    private onNewNpcTaskReceive(newNpcTasks: TaskVo[]): void {
        for (let i = 0; i < newNpcTasks.length; i++) {
            const newNpcTask = newNpcTasks[i];
            const npcId = newNpcTask.npcId!;
            const pveStateData = app.service.pve.mlData.pveStateData;
            const npcStateData = pveStateData.getNpcByTid(npcId)!;
            this.context.addNpc(
                npcStateData.key,
                npcStateData.tid,
                npcStateData.x,
                npcStateData.z,
                npcStateData.rot
            );
        }
    }

    /**
     * 获取npc的状态
     * 玩家的状态可能受到事件的影响。进行变更。
     */
    public static getNPCState(context: PveServer, key: number, npcTid: number): PveNpcStateType {
        // 在npc的位置获取npc对话事件
        const eventKey = TMUtil.convertKey(key, TMLayerName.Event);
        const pveStateData = app.service.pve.mlData.pveStateData;
        const eventRecordData = pveStateData.getEventByKey(eventKey);
        let eventVo: BattleEventLo | undefined;
        if (eventRecordData) {
            if (!eventRecordData.isDeath) {
                eventVo = eventRecordData.eventVo;
            }
        } else {
            const elementComp = context.elements.get(eventKey);
            if (elementComp) {
                eventVo = app.service.pve.eventLoMap.get(elementComp.tid);
            }
        }
        if (eventVo && eventVo.eventType === PveEventType.TALK_TO_NPC) {
            // 存在对话事件,使用事件指定的state
            const cd = eventVo.customData;
            if (cd && cd.change_state_to) {
                return cd.change_state_to;
            }
        }

        const npcCfg = app.service.table.battleNpc[npcTid];
        const npcId = npcCfg.id;
        const npcTask = app.service.task.getNpcTaskWithNpcId(npcId);
        if (npcTask) {
            const taskState = npcTask.taskState;
            if (taskState === TaskState.NOT_RECEIVED) {
                // 还没接任务
                return PveNpcStateType.NPC_STATE_LEAVE;
            } else if (taskState === TaskState.COMPLETED) {
                const p = npcTask.talkToNpcTaskParam!;
                return p.finish_state_to || 0;
            } else if (taskState === TaskState.IN_PROGRESS) {
                const p = npcTask.talkToNpcTaskParam!;
                return p.change_state_to || 0;
            }
        }

        // 其次才是读取记录里的state
        let state = PveNpcStateType.NPC_STATE_0;
        const npcRecordData = pveStateData.getNpcByKey(key);
        if (npcRecordData) {
            state = npcRecordData.state;
        }
        return state;
    }

    /**
     * NPC显示对白完成，
     * 如果对白与事件有关，则完成对应事件
     */
    public static onNpcShowPlotComplete(context: PveServer, npcEid: number): void {
        const npcElementComp = context.ecs.getComponent(npcEid, PveSvrElementComponent)!;
        const pveStateData = app.service.pve.mlData.pveStateData;
        const npcTid = npcElementComp.tid;
        const npcTask = app.service.task.getNpcTaskWithNpcId(npcTid);
        let needSave = false;
        if (npcTask) {
            // Npc 任务
            const taskState = npcTask.taskState;
            if (taskState === TaskState.IN_PROGRESS) {
                const p = npcTask.talkToNpcTaskParam!;
                if (p.finish_state_to) {
                    pveStateData.setNpcState(npcElementComp, p.finish_state_to);
                    needSave = true;
                    // 完成对话后，切换npc到指定的状态
                    if (p.finish_state_to === PveNpcStateType.NPC_STATE_LEAVE) {
                        // 移除npc
                        context.removeElement(npcElementComp);
                    }
                }
                // 完成任务
                app.service.pve.requestDoTask(MainlineConf.TASK.TALK_TO_NPC, 1.0, npcTid);
            }
        }

        const eventKey = TMUtil.convertKey(npcElementComp.key, TMLayerName.Event);
        const eventEleComp = context.elements.get(eventKey);
        if (eventEleComp) {
            const eventVo = app.service.pve.eventLoMap.get(eventEleComp.tid)!;
            if (eventVo.eventType === PveEventType.TALK_TO_NPC) {
                const cd = eventVo.customData;
                if (cd) {
                    if (cd.finish_open_cloud) {
                        PveSvrCloudSystem.clearCloud(context, eventEleComp.eid, false);
                    } else {
                        // 设置事件的触发时间
                        const nowTime = app.service.network.serverTime;
                        pveStateData.setEventTriggerTime(eventEleComp, nowTime << 0);
                        // 需要完成该事件
                        app.service.pve.requestEvent(eventVo.key);
                    }
                    if (cd.finish_state_to !== undefined) {
                        // 完成对话后，切换npc到指定的状态
                        pveStateData.setNpcState(npcElementComp, cd.finish_state_to);
                        needSave = true;
                        if (cd.finish_state_to === PveNpcStateType.NPC_STATE_LEAVE) {
                            // 移除npc
                            context.removeElement(npcElementComp);
                        }
                    }
                }
            }
        }

        if (needSave) {
            PveSvrStateSystem.save(context.ecs);
        }
    }
}
