import * as ecs from "../../../../../core/ecs";
import { IReusable } from "../../../../../core/pool";

export class PveSvrCollectionComponent extends ecs.Component implements IReusable {
    /** 总资源储量 */
    amount: number = 0;
    /** 总需要砍的次数 */
    max: number = 0;
    /** 剩余需要砍的次数 */
    remain: number = 0;

    __unuse(): void {}

    __reuse() {}
}
