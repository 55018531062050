const { regClass } = Laya;
import { AreaProgressEnterBtnMediator } from "../../../pve/map-transfer/AreaProgressEnterBtnMediator";
import { AreaProgressEnterBtnUIBase } from "./AreaProgressEnterBtnUI.generated";

@regClass('BUofgl6-TcaDSehV-AUmKg')
export class AreaProgressEnterBtnUI extends AreaProgressEnterBtnUIBase {
    override onAwake(): void {
        super.onAwake();
    }

    public get mediator(): AreaProgressEnterBtnMediator | null {
        return this.getComponent(AreaProgressEnterBtnMediator);
    }
}
