import { app } from "../../../../../app";
import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { PveSvrBuffComponent } from "../../ecs/components/PveSvrBuffComponent";
import { BuffAttrbuteData } from "../../ecs/data/buff/BuffAttributeData";
import { BuffTreeEnv } from "../../ecs/data/buff/BuffBase";

let AttrOptions: { name: string; value: number }[];

function getAttrOptions() {
    if (AttrOptions) {
        return AttrOptions;
    }
    AttrOptions = [];
    for (const key in app.service.table.attr) {
        const row = app.service.table.attr[key];
        AttrOptions.push({ name: row.define!, value: row.id });
    }
}

type NodeInput = [number?];

type NodeArgs = {
    readonly attr: number;
    readonly percent?: boolean;
    readonly value?: number;
};

export class AddBuffAttribute extends Process {
    override run(node: Node, env: BuffTreeEnv): Status {
        const args = node.args as NodeArgs;
        const input = env.input as NodeInput;
        const value = input[0] ?? env.buffTree.buffArgs[0] ?? args.value ?? 0;
        const percent = args.percent ?? false;
        if (value === 0) {
            console.error(node.tree.name, "AddBuffAttribute: value is 0");
            return "failure";
        }

        const buffId = env.buffTree.data.id;
        const attrId = args.attr;
        const buffComp = env.owner.getComponent(PveSvrBuffComponent)!;
        if (!buffComp) {
            console.error(node.tree.name, "AddBuffAttribute: cannot find PveSvrBuffComponent");
            return "failure";
        }

        const attr2BuffData = percent
            ? (buffComp.buffAttrPercentDatas[attrId] = buffComp.buffAttrPercentDatas[attrId] ?? {})
            : (buffComp.buffAttrDatas[attrId] = buffComp.buffAttrDatas[attrId] ?? {});

        const buffDataArr = (attr2BuffData[buffId] = attr2BuffData[buffId] ?? []);

        const overdueTime = env.buffTree.overdueTime;
        const buffData = new BuffAttrbuteData(attrId, value, env.buffTree.id, overdueTime);
        buffDataArr.push(buffData);

        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "AddBuffAttribute",
            type: "Action(Buff)" as "Action",
            desc: "添加属性，只能在Buff行为树里使用",
            input: ["输入值?"],
            args: [
                { name: "attr", type: "enum", desc: "属性", options: getAttrOptions() },
                { name: "percent", type: "boolean?", desc: "是否按百分比?" },
                { name: "value", type: "float?", desc: "数值" },
            ],
            doc: `
                + 如果输入目标为空，则默认为行为树当前归属的实体
                + 输入值优先从input中读取，其次从env中读取，最后从args中读取`,
        };
    }
}
