import proto from "../../../def/auto/proto";
import { Vo } from "../../../misc/vo/Vo";

export class PvpServerVo extends Vo<never, proto.user.PvpServer> {
    protected declare _data: Readonly<proto.user.PvpServer>;

    constructor(data: proto.user.PvpServer) {
        super(undefined!, data);
    }

    override get key(): string {
        return this._data.name;
    }

    get name(): string {
        return this._data.name;
    }

    get desc(): string {
        return this._data.desc;
    }

    override clone(): PvpServerVo {
        return new PvpServerVo(this._data);
    }
}
