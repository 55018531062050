const { regClass } = Laya;
import { TroopTeamVo } from "../vo/TroopTeamVo";
import { UITroopSelectBase } from "./UITroopSelect.generated";

@regClass('gQll5EeFRcGaVD7j1_xfgg')
export class UITroopSelect extends UITroopSelectBase {
    public data!: TroopTeamVo;

    override open(closeOther?: boolean, param?: any): void {
        this.data = param;
        super.open(closeOther, param);
    }
}
