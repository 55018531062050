const { regClass } = Laya;
import { ItemVo } from "../vo/ItemVo";
import { UIItemUseRandomBase } from "./UIItemUseRandom.generated";

@regClass('Mj1h6adGQECD-wYm9TWM4Q')
export class UIItemUseRandom extends UIItemUseRandomBase {
    public itemData!: ItemVo;

    override open(closeOther?: boolean, param?: ItemVo): void {
        this.itemData = param!;
        super.open(closeOther, param);
    }
}
