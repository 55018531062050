import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { ImageRef } from "../../misc/ImageRef";
import { SystemEvent } from "../../misc/system-event";
import {
    CharacterSelectTab,
    CharacterSelectTabConf,
} from "../hero/ui-runtime/character/CharacterSelectTab";
import { HeroVo } from "../hero/vo/HeroVo";
import { TroopSoliderItem } from "./ui-runtime/TroopSoliderItem";
import { TroopHeroItem } from "./ui-runtime/TroopheroItem";

import { UITroopSelect } from "./ui-runtime/UITroopSelect";

const { regClass, property } = Laya;

@regClass('NdnOEKMDRsODBI0nRplX2A')
export class UITroopSelectMediator extends Mediator {
    declare owner: UITroopSelect;
    private startPoint = Laya.Point.create().setTo(0, 0);
    private curClickItem: TroopHeroItem | null = null;
    private isLongClick: boolean = false;
    //组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
    private listScrollerValue: number = 0;

    private selectedJobIdxArr: number[] = [];

    override onAwake(): void {
        super.onAwake();
        this.initUIEvent();
        this.initServiceEvent();
        app.service.troop.initEditTroop(this.owner.data);
        this.initInfo();
    }

    private initUIEvent() {
        this.owner.listSelect.renderHandler = new Laya.Handler(this, this.onBtnListRender);
        this.owner.listSelect.selectHandler = new Laya.Handler(this, this.onBtnSelect);

        this.owner.listselhero.renderHandler = new Laya.Handler(this, this.onHeroListRender);
        this.owner.listselhero.repeatY = 3;
        this.owner.listselhero.elasticEnabled = true;

        this.owner.btnOneKeyOn.on(Laya.Event.CLICK, this, this.onClickOneKeyOn);
        this.owner.btnOneKeyOff.on(Laya.Event.CLICK, this, this.onClickOnkeyOff);
        this.owner.btnclose.on(Laya.Event.CLICK, this.owner, this.owner.destroy);

        this.owner.dragItem.on(Laya.Event.DRAG_START, this, this.onDragStart);
        this.owner.dragItem.on(Laya.Event.DRAG_MOVE, this, this.onDragMove);
        this.owner.dragItem.on(Laya.Event.DRAG_END, this, this.onDragEnd);
    }

    private onClickOneKeyOn() {
        const soliderList = app.service.hero.getTroopSoliderListSort([]);
        if (soliderList.length) {
            app.service.troop.oneKeyEditSoldier();
            app.service.troop.requestEditTroop();
        } else {
            app.ui.toast("当前没有可上阵的角色");
        }
    }

    private onClickOnkeyOff() {
        if (app.service.troop.checkTroopCanOff()) {
            app.service.troop.onkeyOffSolider();
            app.service.troop.requestEditTroop();
        } else {
            app.ui.toast("当前没有上阵的角色");
        }
    }

    private initServiceEvent() {
        this.$(app).on(SystemEvent.UI.TROOP_INIT, this.updateInfo, this);
        this.$(app).on(SystemEvent.UI.TROOP_EDIT, this.updateInfo, this);
    }

    private onDragStart(e: Laya.Event) {
        if (!this.curClickItem) {
            return;
        }
        console.log("1111111开始拖拽");
    }

    private onDragMove(e: Laya.Event) {
        if (!this.curClickItem) {
            return;
        }
    }

    private onDragEnd() {
        if (!this.curClickItem) {
            return;
        }
        const dragIdx = this.getDragIdx();
        if (dragIdx > 0) {
            //移动交换数据
            console.log("拖拽成功到第" + dragIdx + "队");
            this.curClickItem.visible = true;
            this.owner.dragItem.stopDrag();
            this.owner.dragItem.visible = false;
            const heroData = this.curClickItem.dataSource as HeroVo;

            app.service.troop.dragTroopUp(heroData, dragIdx);
            app.service.troop.requestEditTroop();
        } else {
            console.log("拖拽失败");
            this.owner.dragItem.stopDrag();
            this.curClickItem.visible = true;
            this.owner.dragItem.visible = false;
        }
        this.curClickItem = null;
    }

    private getDragIdx(): number {
        let dragIdx = -1;
        const boxList = [this.owner.dragBox1, this.owner.dragBox2, this.owner.dragBox3];
        for (let i = 0; i < boxList.length; i++) {
            const dragBox = boxList[i];
            const areaX = dragBox.x;
            const areaY = dragBox.y;
            const areaWidth = dragBox.width;
            const areaHeight = dragBox.height;
            const isSucess =
                this.owner.dragItem.y >= areaY &&
                this.owner.dragItem.y <= areaY + areaHeight &&
                this.owner.dragItem.x >= areaX &&
                this.owner.dragItem.x <= areaX + areaWidth;

            if (isSucess) {
                dragIdx = i + 1;
                break;
            }
        }
        return dragIdx;
    }

    private onBtnListRender(cell: CharacterSelectTab, index: number) {
        const data = this.owner.listSelect.array[index];
        cell.onRender(index, data);
        if (index === this.owner.listSelect.array.length - 1) {
            this.owner.listSelect.renderHandler = null;
            this.owner.listSelect.selectedIndex = 0;
        }
    }

    private onBtnSelect(index: number) {
        if (index < 0) {
            return;
        }
        if (index === 0) {
            this.selectedJobIdxArr.length = 0;
        } else {
            const selectedJobIdx = this.selectedJobIdxArr.indexOf(index);
            if (selectedJobIdx >= 0) {
                this.selectedJobIdxArr.splice(selectedJobIdx, 1);
            } else {
                this.selectedJobIdxArr.push(index);
            }
        }

        const childCount = this.owner.listSelect.array.length;
        for (let i = 0; i < childCount; i++) {
            const cell = this.owner.listSelect.getCell(i) as CharacterSelectTab;
            cell.onSelect(i === index, i);
            if (i === 0) {
                cell.onSelect(this.selectedJobIdxArr.length === 0, i);
            } else {
                cell.onSelect(
                    this.selectedJobIdxArr.length > 0 && this.selectedJobIdxArr.indexOf(i) >= 0,
                    i
                );
            }
        }
        this.owner.listSelect.selectedIndex = -1;
        this.updateSoliderList();
    }

    private updateSoliderList() {
        const soliderArr: HeroVo[] = app.service.hero.getTroopSoliderListSort(
            this.selectedJobIdxArr
        );
        this.owner.listselhero.array = soliderArr;
    }

    private onHeroListRender(item: TroopHeroItem, index: number) {
        const data = item.dataSource as HeroVo;

        const battleTeamName = app.service.troop.getSoliderBattleTeam(data);
        if (battleTeamName) {
            item.boxTroop.visible = true;
            item.labbattle.text = battleTeamName;
        } else {
            item.boxTroop.visible = false;
            item.labbattle.text = "";
        }
        for (let i = 0; i < 5; i++) {
            const star = item.boxStar.getChildAt(i) as Laya.Image;
            star.visible = i < data.star;
        }
        item.labstarnum.text = `${data.star}`;
        item.labName.text = data.name;
        const textPaths = item.imgType.getComponent(ImageRef)!.texturePaths;
        item.imgType.skin = textPaths[data.quality];
        item.labJob.text = data.jobDesc;
        item.imgIcon.skin = data.iconMiddle || "";
        const textPath = item.imgBg.getComponent(ImageRef)!.texturePaths;
        item.imgBg.skin = textPath[data.quality];
        item.on(Laya.Event.CLICK, this, this.onStarClick);
        item.on(Laya.Event.MOUSE_DOWN, this, this._onMousDown);
        item.on(Laya.Event.MOUSE_UP, this, this._onMouseUp);
    }

    private updateHeroData(item: TroopHeroItem, data: HeroVo) {
        const battleTeamName = app.service.troop.getSoliderBattleTeam(data);
        if (battleTeamName) {
            item.boxTroop.visible = true;
            item.labbattle.text = battleTeamName;
        } else {
            item.boxTroop.visible = false;
        }
        for (let i = 0; i < 5; i++) {
            const star = item.boxStar.getChildAt(i) as Laya.Image;
            star.visible = i < data.star;
        }
        item.labName.text = data.name;
        item.labstarnum.text = `${data.star}`;
        const textPaths = item.imgType.getComponent(ImageRef)!.texturePaths;
        item.imgType.skin = textPaths[data.quality];
        item.labJob.text = data.jobDesc;
        item.imgIcon.skin = data.iconMiddle || "";
        const textPath = item.imgBg.getComponent(ImageRef)!.texturePaths;
        item.imgBg.skin = textPath[data.quality];
    }

    private _onMouseUp() {
        if (!this.curClickItem) {
            return;
        }
        this.killDelay("dragTroop");
        this.curClickItem.visible = true;
        this.owner.dragItem.visible = false;
    }

    private onStarClick(e: Laya.Event) {
        if (!this.curClickItem) {
            return;
        }
        // console.log("点击了");
        this.killDelay("dragTroop");
        this.curClickItem.visible = true;
        this.owner.dragItem.visible = false;
        const soliderData = this.curClickItem.dataSource as HeroVo;
        const teamIdx = app.service.troop.getTeamIdxBySoliderIdx(soliderData.uid);
        if (teamIdx && teamIdx !== app.service.troop.curEditTeam!.idx) {
            return; //已上阵不支持编辑
        }
        if (teamIdx === app.service.troop.curEditTeam!.idx) {
            app.service.troop.troopOff(soliderData);
        } else {
            const emptyIdx = app.service.troop.getTroopEmptyIdx();
            if (emptyIdx === -1) {
                app.ui.toast("当前无合适位置");
                return;
            }
            app.service.troop.troopUp(soliderData);
        }
        app.service.troop.requestEditTroop();
    }

    private _onMousDown(e: Laya.Event) {
        const taret = e.currentTarget as TroopHeroItem;
        const heroData = taret.dataSource as HeroVo;
        this.curClickItem = taret;
        const teamIdx = app.service.troop.getTeamIdxBySoliderIdx(heroData.uid);
        if (teamIdx) {
            return; //已上阵不支持编辑
        }
        this.listScrollerValue = this.owner.listselhero.scrollBar?.value || 0;
        this.killDelay("dragTroop");
        this.delay(0.4, "dragTroop", () => this.onClickLong());
    }

    private onClickLong() {
        if (!this.curClickItem) {
            return;
        }
        const nowlistValue = this.owner.listselhero.scrollBar?.value || 0;
        if (Math.abs(nowlistValue - this.listScrollerValue) > 10) {
            return;
        }
        // console.log("开始拖拽生效");

        this.startPoint = Laya.Point.create().setTo(0, 0);
        (<TroopHeroItem>this.curClickItem).localToGlobal(this.startPoint);
        this.updateHeroData(this.owner.dragItem, this.curClickItem.dataSource);
        this.owner.dragItem.pos(
            this.startPoint.x + this.owner.dragItem.width / 2,
            this.startPoint.y + this.owner.dragItem.height / 2
        );
        this.owner.listselhero.scrollBar?.stopScroll();
        this.owner.dragItem.visible = true;
        Laya.Tween.to(
            this.owner.dragItem,
            { scaleX: 1.2, scaleY: 1.2 },
            300,
            Laya.Ease.cubicOut,
            Laya.Handler.create(this, () => {
                Laya.Tween.to(
                    this.owner.dragItem,
                    { scaleX: 1, scaleY: 1 },
                    150,
                    Laya.Ease.cubicOut,
                    Laya.Handler.create(this, () => {
                        Laya.Tween.clearAll(this.owner.dragItem);
                    })
                );
            })
        );
        this.isLongClick = true;
        this.curClickItem.visible = false;
        this.owner.dragItem.startDrag();
    }

    private initInfo() {
        this.owner.listSelect.array = CharacterSelectTabConf;
        this.owner.dragItem.visible = false;
        this.owner.bgwin.setTitle("部队编辑");
        this.owner.dragItem.stopDrag();
        this.owner.listSelect.selectedIndex = -1;
        this.updateTroopInfo();
    }

    private updateInfo() {
        this.updateSoliderList();
        this.updateTroopInfo();
    }

    private updateTroopInfo() {
        const idx = app.service.troop.curEditTeam!.idx!;
        const troopTeamVo = app.service.troop.troopTeamVoMap.get(idx);
        if (troopTeamVo) {
            this.owner.labpower.text = troopTeamVo.power.toString();
            this.owner.imgbar.value = troopTeamVo.heroHp / troopTeamVo.heroMaxHp;
            this.owner.img_hero.skin = troopTeamVo.heroIcon;
            const itemList: TroopSoliderItem[] = [
                this.owner.troopsolideritem1,
                this.owner.troopsolideritem2,
                this.owner.troopsolideritem3,
            ];
            for (let i = 0; i < 3; i++) {
                const itemBox = itemList[i];
                const imgList = [
                    itemBox.imghero1,
                    itemBox.imghero2,
                    itemBox.imghero3,
                    itemBox.imghero4,
                ];
                const imgBarList = [
                    itemBox.imgbar1,
                    itemBox.imgbar2,
                    itemBox.imgbar3,
                    itemBox.imgbar4,
                ];
                const boxBarList = [
                    itemBox.boxBar1,
                    itemBox.boxBar2,
                    itemBox.boxBar3,
                    itemBox.boxBar4,
                ];
                const imgCircleList = [
                    itemBox.imgCicle1,
                    itemBox.imgCicle2,
                    itemBox.imgCicle3,
                    itemBox.imgCicle4,
                ];
                const maxhp = troopTeamVo.getSoliderMaxHpByIdx(i + 1);
                const [img1, img2] = this.owner.getComponent(ImageRef)!.texturePaths;
                for (let k = 0; k < 4; k++) {
                    const imgSrc = troopTeamVo.getSoliderImg(i);
                    imgList[k].skin = imgSrc;
                    const hp = troopTeamVo.getSoliderHpByIdx(i * 4 + k + 1);
                    imgBarList[k].value = hp / maxhp;
                    boxBarList[k].visible = maxhp > 0;

                    imgCircleList[k].skin = maxhp > 0 ? img1 : img2;
                }
            }
        }
    }

    //组件被启用后执行，例如节点被添加到舞台后
    //onEnable(): void {}

    //组件被禁用时执行，例如从节点从舞台移除后
    //onDisable(): void {}

    //第一次执行update之前执行，只会执行一次
    //onStart(): void {}

    //手动调用节点销毁时执行
    override onDestroy(): void {
        Laya.Tween.clearAll(this.owner.dragItem);
    }

    //每帧更新时执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onUpdate(): void {}

    //每帧更新时执行，在update之后执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onLateUpdate(): void {}

    //鼠标点击后执行。与交互相关的还有onMouseDown等十多个函数，具体请参阅文档。
    //onMouseClick(): void {}
}
