/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/user/rename.lh
 */
export class RenameUIBase extends Laya.Dialog {
    public labelName!: Laya.TextInput;
    public btn!: Laya.Button;
    public labelBtnName!: Laya.Label;
}

