import { Node, NodeDef, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { FindTargets } from "./FindTargets";

interface NodeArgs {
    readonly radius?: number;
}

export class IsReachPos extends FindTargets {
    override run(node: Node, env: AiTreeEnv): Status {
        const [targetPos] = env.input;
        if (!(targetPos instanceof Laya.Vector3)) {
            node.warn("no target position to move");
            return "failure";
        }
        const epsilon = (node.args as NodeArgs).radius ?? 0.1;
        const bHit = Laya.Vector3.distanceXZ(env.owner.transform.position, targetPos) < epsilon;
        return bHit ? "success" : "failure";
    }

    override get descriptor(): NodeDef {
        return {
            name: "IsReachPos",
            type: "Condition",
            desc: "是否到达位置",
            args: [{ name: "radius", type: "float?", desc: "距离误差" }],
            input: ["目标单位"],
            doc: `
                + 如果有输入目标单位，则根据半径判断。
                + 否则根据外部的设置Flag判断。
                + 命中目标返回成功，否则返回失败
                `,
        };
    }
}
