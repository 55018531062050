import { app } from "../../app";
import { Service } from "../../core/service";
import proto, { task } from "../../def/auto/proto";
import { errcode, errmsg, opcode } from "../../def/auto/protocol";
import { TaskType } from "../../def/auto/task";
import { SystemEvent } from "../../misc/system-event";
import { TaskVoMap } from "./TaskVoMap";
import { TaskVo } from "./vo/TaskVo";

export class TaskService extends Service {
    readonly taskVoMap = new TaskVoMap();

    readonly npcTaskVoMapNpcId = new TaskVoMap();
    readonly npcTaskVoMapTaskId = new TaskVoMap();

    public taskInfoMap: Map<number, task.TaskInfo> = new Map();

    private removeTaskInfo(taskId: number): void {
        this.taskInfoMap.delete(taskId);
    }

    public getTaskInfoWithTaskId(taskId: number): task.TaskInfo | undefined {
        if (this.taskInfoMap.has(taskId)) {
            return this.taskInfoMap.get(taskId);
        }
        return undefined;
    }

    /**
     * 获取当前进行中的主线vo
     * 主线完结的话，返回undefined
     */
    public getCurMainTaskVo(): TaskVo | undefined {
        const mainTasks = this.taskVoMap.getTasksWithType(1);
        for (let i = 0; i < mainTasks.length; i++) {
            const mt = mainTasks[i];
            if (mt.data) {
                return mt;
            }
        }
        return undefined;
    }

    override onCreate() {
        this.handle(opcode.task.s2c_load, this._onLoad);
        this.handle(opcode.task.s2c_receive_reward, this._onreceiveReward);
        this.handle(opcode.task.notify_tasks, this._noNotifyTasks);
        this.handle(opcode.task.notify_remove_tasks, this._noNotifyRemoveTasks);
    }

    override onStartInit(): void {
        const rows = app.service.table.task;
        for (const k in rows) {
            const row = rows[k];
            const tvo = new TaskVo(row);
            this.taskVoMap.set(row.id, tvo);
            if (tvo.talkToNpcTaskParam) {
                this.npcTaskVoMapNpcId.set(tvo.npcId!, tvo);
                this.npcTaskVoMapTaskId.set(tvo.key, tvo);
            }
        }
    }

    /** 检查是否是任务Npc */
    public getNpcTaskWithNpcId(npcId: number) {
        if (this.npcTaskVoMapNpcId.has(npcId)) {
            return this.npcTaskVoMapNpcId.get(npcId);
        }
        return undefined;
    }

    override onDestroy() {}

    private _onLoad(data: proto.task.s2c_load, request: proto.task.c2s_load) {
        if (data.err === errcode.OK) {
            const tasks = data.tasks as task.TaskInfo[];
            tasks.forEach((v) => {
                this.taskInfoMap.set(v.id, v);
            });
        } else {
            console.log(errmsg[data.err as errcode]);
        }
    }

    private _onreceiveReward(data: proto.task.s2c_receive_reward) {
        if (data.err !== errcode.OK) {
            console.log(errmsg[data.err as errcode]);
        }
    }

    private _noNotifyTasks(data: proto.task.notify_tasks) {
        const newNpcTaskVoArr: TaskVo[] = [];
        for (const taskInfo of data.tasks) {
            this.taskInfoMap.set(taskInfo.id, taskInfo);
            if (!taskInfo.finish && taskInfo.num === 0) {
                // 接取到新的任务
                const taskVo = this.npcTaskVoMapTaskId.get(taskInfo.id);
                if (taskVo) {
                    const npcId = taskVo.npcId!;
                    const pveStateData = app.service.pve.mlData.pveStateData;
                    const npcStateData = pveStateData.getNpcByTid(npcId);
                    if (npcStateData && npcStateData.inView) {
                        // 需要npc是在视野内
                        newNpcTaskVoArr.push(taskVo);
                    }
                }
            }

            const taskRow = app.service.table.task[Number(taskInfo.id)];
            if (taskRow.type === TaskType.MAIN) {
                app.event(SystemEvent.TASK_MAIN_UPDATE);
            }
        }

        app.event(SystemEvent.TASK_UPDATE);
        if (newNpcTaskVoArr.length > 0) {
            // console.log("接取到新的npc对话任务!");
            app.event(SystemEvent.TASK_NEW_NPC_TASK, [newNpcTaskVoArr]);
        }
    }

    private _noNotifyRemoveTasks(data: proto.task.notify_remove_tasks) {
        for (const taskId of data.taskIds) {
            this.removeTaskInfo(taskId);
        }
        app.event(SystemEvent.TASK_REMOVE);
    }

    // ------------------------------------------------------------------------
    // rpc call
    // ------------------------------------------------------------------------
    async load(data: proto.task.Ic2s_load) {
        return await app.service.network.call(
            proto.task.c2s_load.create(data),
            proto.task.s2c_load
        );
    }

    async requestReceiveReward(data: proto.task.Ic2s_receive_reward) {
        return await app.service.network.call(
            proto.task.c2s_receive_reward.create(data),
            proto.task.s2c_receive_reward
        );
    }
}
