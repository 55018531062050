/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/chest-box/sub/ChestBoxStack.lh
 */
export class ChestBoxStackBase extends Laya.Box {
    public box_10!: Laya.Image;
    public box_09!: Laya.Image;
    public box_08!: Laya.Image;
    public box_07!: Laya.Image;
    public box_06!: Laya.Image;
    public box_05!: Laya.Image;
    public box_04!: Laya.Image;
    public box_03!: Laya.Image;
    public box_01!: Laya.Image;
    public box_02!: Laya.Image;
}

