/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/skill/SkillOwnList.lh
 */
export class SkillOwnListUIBase extends Laya.Box {
    public panalScroller!: Laya.Panel;
    public vboxContent!: Laya.VBox;
}

