/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIImageBar } from "../../../core/ui/UIImageBar";
import { UIList } from "../../../core/ui/UIList";
import { BaseBigWindowBg } from "../../common/ui-runtime/dialog/BaseBigWindowBg";
import { TroopSoliderItem } from "./TroopSoliderItem";
import { TroopHeroItem } from "./TroopheroItem";

/**
 * resources/prefab/troop/UITroopSelect.lh
 */
export class UITroopSelectBase extends Laya.Dialog {
    public bgwin!: BaseBigWindowBg;
    public boxtouch!: Laya.Box;
    public troopsolideritem1!: TroopSoliderItem;
    public troopsolideritem2!: TroopSoliderItem;
    public troopsolideritem3!: TroopSoliderItem;
    public dragBox1!: Laya.Box;
    public dragBox2!: Laya.Box;
    public dragBox3!: Laya.Box;
    public btnOneKeyOff!: Laya.Button;
    public btnOneKeyOn!: Laya.Button;
    public btnclose!: Laya.Button;
    public img_hero!: Laya.Image;
    public imgbar!: UIImageBar;
    public listSelect!: UIList;
    public listselhero!: Laya.List;
    public dragItem!: TroopHeroItem;
    public labpower!: Laya.Label;
}
