import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { errcode } from "../../def/auto/protocol";
import { SystemEvent } from "../../misc/system-event";
import { UIAllianceIntro } from "./ui-runtime/UIAllianceIntro";

const { regClass, property } = Laya;

@regClass('-004eectTgCvZiMKKjngUQ')
export class UIAllianceIntroMediator extends Mediator {
    declare owner: UIAllianceIntro;

    override onAwake(): void {
        super.onAwake();

        this.owner.btnGo.on(Laya.Event.CLICK, this, this._joinAlliance);
    }

    private async _joinAlliance() {
        const response = await app.service.alliance.requestJoinSystemAlliance();
        if (response.err === errcode.OK) {
            this.owner.close();
            app.event(SystemEvent.ALLIANCE.OPEN_ALLIANCE);
        } else {
            app.service.network.toastError(response.err);
        }
    }
}
