import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { ImageRef } from "../../misc/ImageRef";
import { LoginServerBtn } from "./ui-runtime/LoginServerBtn";
import { LoginServerListItem } from "./ui-runtime/LoginServerListItem";
import { UILoginServerList } from "./ui-runtime/UILoginServerList";
import { ServerInfoVo } from "./vo/ServerInfoVo";

const { regClass, property } = Laya;

@regClass('hg63TJ90RhCaRDZw0FbjqA')
export class UILoginServerListMediator extends Mediator {
    declare owner: UILoginServerList;

    override onAwake(): void {
        super.onAwake();
        this.owner.listServer.mouseHandler = new Laya.Handler(this, this.listClick);
        this.owner.listServer.renderHandler = new Laya.Handler(this, this.onServerListRender);

        this.owner.listServerGroup.renderHandler = new Laya.Handler(this, this.onServerGroupRender);
        this.owner.listServerGroup.selectHandler = new Laya.Handler(this, this.onSelGroupRender);

        this.owner.imgMask.on(Laya.Event.CLICK, () => {
            this.owner.destroy();
        });
        this.owner.bgwin.setTitle("选择服务器");
        this.updateServerGroup();
    }

    listClick(evt: Laya.Event, index: number) {
        if (evt.type === Laya.Event.CLICK) {
            this.owner.destroy();
            this.owner.backFunc.runWith(this.owner.tlServerList[index]);
        }
    }

    onServerListRender(cell: LoginServerListItem, index: number) {
        const serverData = cell.dataSource as ServerInfoVo;
        const [lv, hong] = cell.imgState.getComponent(ImageRef)!.texturePaths;
        cell.imgState.skin = serverData.state === 3 ? lv : hong;
        cell.labServerName.text = serverData.serverName;
        cell.labUserName.text = serverData.name || "";
        cell.labCur.visible = serverData.serverSn === app.service.login.serverSn;
    }

    private onServerGroupRender(cell: LoginServerBtn, index: number) {
        const serverGroupStr = cell.dataSource as string;
        cell.selectBox.label = serverGroupStr;
    }

    private onSelGroupRender(index: number) {
        let serverList: ServerInfoVo[] = [];
        if (index === 0) {
            const myServerList = this.owner.tlServerList.filter((v) => v.name);
            serverList = myServerList;
        } else {
            serverList = this.owner.tlServerList;
        }
        this.owner.listServer.array = serverList;
        const last = serverList.findIndex((v) => v.serverSn === app.service.login.serverSn);
        this.owner.listServer.selectedIndex = last === -1 ? 0 : last;
    }

    private updateServerGroup() {
        const serverGroupList = ["我的服务器", "服务器列表"];
        this.owner.listServerGroup.array = serverGroupList;
        const myServerList = this.owner.tlServerList.filter((v) => v.name);
        if (myServerList.length) {
            this.owner.listServerGroup.selectedIndex = 0;
        } else {
            this.owner.listServerGroup.selectedIndex = 1;
        }
    }
}
