const { regClass } = Laya;
import { BattlePveMapAreaRow } from "../../../../../def/table";
import { UIAreaProgressMediator } from "../../../pve/map-transfer/UIAreaProgressMediator";
import { UIAreaProgressBase } from "./UIAreaProgress.generated";

@regClass('wJURJ-lTQSm3nAX-o0iwXg')
export class UIAreaProgress extends UIAreaProgressBase {
    public areaItem?: BattlePveMapAreaRow;

    override open(closeOther?: boolean | undefined, param?: any): void {
        this.areaItem = param;
        super.open(closeOther, param);
    }

    public get mediator(): UIAreaProgressMediator | null {
        return this.getComponent(UIAreaProgressMediator);
    }
}
