import { app } from "../../../../app";
import { StringUtil } from "../../../../core/utils/string-util";
import {
    BattleBuildingRow,
    BattleSkinRow,
    BuildingCustomData,
    Reward,
} from "../../../../def/table";
import { res } from "../../../../misc/res";
import { RewardCostUtil } from "../../../../misc/reward-cost-util";
import { Vo } from "../../../../misc/vo/Vo";
import { ItemRourceLo } from "../../../bag/vo/ItemRourceLo";
import { PveDef } from "../../pve-server/PveDefs";

export class BattleBuildingVo extends Vo<BattleBuildingRow, never> {
    override get key(): number {
        return this._config.id;
    }

    override clone(): Vo<BattleBuildingRow, never> {
        return new BattleBuildingVo(this._config);
    }

    private _customData: BuildingCustomData | undefined;

    /** 当前等级 */
    public get level(): number {
        return this._config.default_level ?? 0;
    }

    public get titleName(): string {
        return this._config.title_name ?? "";
    }

    public get battleEntity(): number {
        return this._config.battle_entity;
    }

    public get upgradeNeedTime(): number {
        return this._config.upgrade_need_time ?? 0;
    }

    public get buildingType(): number {
        return this._config.building_type ?? 1;
    }

    public get effectiveable(): boolean {
        if (!this.hasFoundation) {
            return true; // 无地基的默认有效
        }
        if (this.level > 0) {
            return true; // 有地基的，大于1级才有效
        }
        return false;
    }

    /** 是否是可以建造建筑 */
    public get hasFoundation(): boolean {
        if (this.level > 0) {
            return true;
        }
        if (this.isFoundation) {
            return true;
        }
        return false;
    }

    public get upgradeCost(): Reward[] | undefined {
        return this._config.upgrade_cost;
    }

    /** 升级需要的消耗是否是来自货车 */
    public get upgradeCostFromTruck(): boolean {
        const cost = this.upgradeCost;
        if (cost && cost.length > 0) {
            if (cost[0].id === PveDef.COLLECT_FOOD_ID) {
                return true;
            } else if (cost[0].id === PveDef.COLLECT_WOOD_ID) {
                return true;
            }
        }
        return false;
    }

    /** 生产数据 */
    public get customData(): BuildingCustomData | undefined {
        if (this._customData) {
            return this._customData;
        }
        if (this._config.custom) {
            try {
                this._customData = JSON.parse(this._config.custom);
            } catch (error) {
                console.log("custom json出错" + this._config.custom);
            }
            return this._customData;
        }
        return undefined;
    }

    /** 最大暂存资源数量 */
    public get storageLimit(): number {
        return this.customData?.limit ?? 0;
    }

    /** 最低可收获的数量 */
    public get minHarvest(): number {
        return this.customData?.min_harvest ?? 10;
    }

    public checkUpgradeLevelEnough(showTips: boolean): boolean {
        const needLevel = this._config.upgrade_need_level ?? 0;
        if (needLevel <= 0) return true;
        const myLevel = app.service.user.profileInfo.lv || 0;
        if (myLevel < needLevel) {
            if (!showTips) {
                app.ui.toast(`当前等级不足，需要达到${needLevel}级！`);
            }
            return false;
        }
        return true;
    }

    /** 是否是地基 */
    public get isFoundation(): boolean {
        if (this.level > 0) {
            return false;
        }
        const hd = this.customData;
        if (hd && hd.foundation) {
            return true;
        }
        return false;
    }

    public get uiAvatarRes(): string {
        const c = this.skinCfg;
        if (c) {
            let path = res.battle.PVE_BUILDING_UI_AVATAR;
            path = StringUtil.format(path, c.code_name);
            return path;
        }
        return "";
    }

    public get skinRes(): string {
        const skinId = this.skinId;
        if (skinId) {
            const prefeb_baked_res = app.service.table.skin[skinId].prefeb_baked_res;
            if (prefeb_baked_res) {
                return prefeb_baked_res;
            }
            const prefeb_res = app.service.table.skin[skinId].prefeb_res;
            if (prefeb_res) {
                return prefeb_res;
            }
        }
        return "";
    }

    public get skinCfg(): BattleSkinRow | undefined {
        const skinId = this.skinId;
        if (skinId) {
            return app.service.table.skin[skinId];
        }
        return undefined;
    }

    /** 建筑对应的皮肤id */
    public get skinId(): number | undefined {
        const hd = this.customData;
        if (hd && hd.skin_id) {
            return hd.skin_id;
        }
        return undefined;
    }

    /** 对应资源总存量 */
    public get collectAmount(): number {
        const hd = this.customData;
        if (hd && hd.amount) {
            return hd.amount;
        }
        return 0;
    }

    /** 根据最后收获的时间获取当前是否可以收获 */
    public getIsCanHarvestWithtHarvestTime(lastHarvestTime: number): boolean {
        if (this.level === 0) return false;
        const curAmount = this.getCurrentAmountWithHarvestTime(lastHarvestTime);
        if (curAmount >= this.minHarvest) {
            return true;
        }
        return false;
    }

    private _hoursProductionAmount: number = 0;

    /** 每小时生产资源的数量 */
    public get hoursProductionAmount(): number {
        if (this._hoursProductionAmount > 0) {
            return this._hoursProductionAmount;
        }
        if (this.customData) {
            // 单次生产数量
            const onceAmount = this.customData.once_amount!;
            // 间隔秒数
            const interval = this.customData.interval!;
            // 一个小时生产的次数
            const genTime = Math.floor(3600 / interval);
            this._hoursProductionAmount = onceAmount * genTime;
        }
        return this._hoursProductionAmount;
    }

    private _nextLevel: BattleBuildingVo | undefined;

    /** 下一级 */
    public get nextLevel(): BattleBuildingVo | undefined {
        if (this._nextLevel) {
            return this._nextLevel;
        }
        if (this.upgradeNeedTime) {
            this._nextLevel = app.service.pve.buildingVoMap.getVoWithBattleEntityIdAndLevel(
                this.battleEntity,
                this.level + 1
            );
            return this._nextLevel;
        }
        return undefined;
    }

    /** 是否满级 */
    public get isMaxLevel(): boolean {
        if (this.nextLevel) {
            return false;
        }
        return true;
    }

    public get freshTime(): number {
        const cd = this.customData;
        if (cd) {
            return cd.fresh_time ?? 0;
        }
        return 0;
    }

    private _totalNeedTime: number = 0;

    /** 获取资源从头到结束满采需要消耗的时间（单位:秒） */
    public get totalNeedTime(): number {
        if (this._totalNeedTime > 0) {
            return this._totalNeedTime;
        }
        if (!this.customData) return 0;

        const limit = this.storageLimit;
        const onceAmount = this.customData!.once_amount!;
        // 补满需要生产次数
        const count = Math.ceil(limit / onceAmount);
        const interval = this.customData!.interval!;
        this._totalNeedTime = count * interval;
        return this._totalNeedTime;
    }

    /** 根据生产持续时间，获取当前进度值 0~1（productionTime 单位:秒） */
    public getProgressWithHarvestTime(lastHarvestTime: number): number {
        if (lastHarvestTime < 1) return 0;
        const curTime = app.service.network.serverTime;
        const productionTime = curTime - lastHarvestTime;
        return this.getProgressWithProductionTime(productionTime);
    }

    /** 根据生产持续时间，获取当前进度值 0~1（productionTime 单位:秒） */
    public getProgressWithProductionTime(productionTime: number): number {
        const needTime = this.totalNeedTime;
        if (needTime === 0) return 0;
        // 进度值
        const bi = productionTime / needTime;
        // 返回进度值不可大于1
        return Math.min(bi, 1);
    }

    /** 根据上次收获的时间，获取当前已生产的资源数量（单位:秒）*/
    public getCurrentAmountWithHarvestTime(lastHarvestTime: number): number {
        if (lastHarvestTime < 1) return 0;
        const curTime = app.service.network.serverTime;
        const productionTime = curTime - lastHarvestTime;
        return this.getCurrentAmountWithProductionTime(productionTime);
    }

    /** 根据生产持续时间，获取当前已生产的资源数量（单位:秒）*/
    public getCurrentAmountWithProductionTime(productionTime: number): number {
        if (!this.customData) return 0;
        const interval = this.customData.interval!;
        const count = Math.floor(productionTime / interval);
        const onceAmount = this.customData!.once_amount!;

        const curMax = count * onceAmount;
        const limit = this.storageLimit;
        const curAmount = Math.min(curMax, limit);
        return curAmount;
    }

    /** 获取距离升级成功剩余时间(单位:秒) */
    public getUpgradeRemainTime(upgradeTime: number): number {
        const needTime = this._config.upgrade_need_time!;
        const curTime = app.service.network.serverTime;
        const upgradeEndTime = upgradeTime + needTime;
        const upgradeRemainTime = upgradeEndTime - curTime;
        return Math.max(upgradeRemainTime, 0);
    }

    public get iconUrl(): string {
        const rewardCode = this.customData!.item_id!;
        const itemLo = app.service.bag.itemLoMap.get(rewardCode);
        return itemLo.iconUrl;
    }

    /** 检查是否足够扣费 */
    public ckeckEnoughUpgradeCost(showTips: boolean = false): boolean {
        const cost = this._config.upgrade_cost;
        return RewardCostUtil.checkEnoughCost(cost, showTips);
    }

    /** 传送门专属，目标传送点id */
    public get areaTransferId(): number {
        const cd = this.customData;
        if (cd) {
            return cd.transfer_id ?? 0;
        }
        return 0;
    }

    /** 功能跳转id */
    public get itemSourceId(): number {
        const cd = this.customData;
        if (cd) {
            return cd.item_source_id ?? 0;
        }
        return 0;
    }

    public get rourceLo(): ItemRourceLo {
        return app.service.bag.itemRourceLoMap.get(this.itemSourceId)!;
    }
}
