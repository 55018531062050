/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/battle/pve-defeat/pve-reborn-window.lh
 */
export class PveRebornWindowUIBase extends Laya.Dialog {
    public btn_play_ads!: Laya.Button;
    public btn_reborn!: Laya.Button;
    public cost_text_btn!: Laya.Label;
    public img_cost_icon!: Laya.Image;
    public cost_text_1!: Laya.Label;
}

