/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { BaseMidItemIcon } from "../../common/ui-runtime/item-icon/BaseMidItemIcon";

/**
 * resources/prefab/gm/GMPurchaseItem.lh
 */
export class GM_PurchaseItemBase extends Laya.Box {
    public itemIcon!: BaseMidItemIcon;
    public buyBtn!: Laya.Button;
    public itemName!: Laya.Label;
    public input_amount!: Laya.TextInput;
}

