/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIButton } from "../../../core/ui/UIButton";
import { UIList } from "../../../core/ui/UIList";

/**
 * scenes/pvp.ls
 */
export class PvpUIBase extends Laya.Scene {
    public debug!: Laya.Sprite;
    public troops!: Laya.Box;
    public labels!: Laya.Box;
    public mapTouchArea!: Laya.Box;
    public btnReport!: Laya.Box;
    public btnMap!: Laya.Box;
    public btnEditTroops!: UIButton;
    public troopGroup!: UIList;
}

