const { regClass } = Laya;
import { PveContext } from "../../../pve/PveContext";
import { UIMapTransferMediator } from "../../../pve/map-transfer/UIMapTransferMediator";
import { UIMapTransferBase } from "./UIMapTransfer.generated";

@regClass('PrL2ba6tQ6y7LkMveH7SVA')
export class UIMapTransfer extends UIMapTransferBase {
    context!: PveContext;

    override open(closeOther?: boolean | undefined, param?: any): void {
        this.context = param;
        super.open(closeOther, param);
    }

    public get mediator(): UIMapTransferMediator | null {
        return this.getComponent(UIMapTransferMediator);
    }
}
