import { app } from "../../../../app";
import { IconResHelper } from "../../../../misc/IconResHelper";
import { ImageRef } from "../../../../misc/ImageRef";
import { ui } from "../../../../misc/ui";
import { HeroHandbookVo } from "../../vo/HeroHandbookVo";
import { HeroVo } from "../../vo/HeroVo";
import { UICharacterDetailArgs } from "../detail/UICharacterDetail";
import { CharacterCardBase } from "./CharacterCard.generated";

const { regClass } = Laya;

export type CharacterCardData =
    | {
          type: "character";
          character: HeroVo;
      }
    | {
          type: "handbook";
          handbook: HeroHandbookVo;
          character?: HeroVo; //解锁最高星的角色，不一定仍然拥有，可能已经被吞噬
      };

@regClass('1BF_pRgVSPap8GCwtNPDug')
export class CharacterCard extends CharacterCardBase {
    onRender(data: CharacterCardData, index: number) {
        const imgStars = [
            this.imgStar0,
            this.imgStar1,
            this.imgStar2,
            this.imgStar3,
            this.imgStar4,
        ];
        imgStars.forEach((v) => {
            v.active = v.visible = false;
        });
        this.boxStars.forceUpdate();
        const isHandbook = data.type === "handbook";
        this.imgTeamBg.visible = false;
        this.imgTeamBg2.visible = false;
        this.lblLv.visible = !isHandbook;
        this.boxReward.visible = false;

        const quality = isHandbook ? data.handbook.quality : data.character.quality;
        const texturePaths = this.imgQuality.getComponent(ImageRef)!.texturePaths;

        this.imgQuality.skin = texturePaths[quality];
        const jobTypetexture = this.imgJobBg.getComponent(ImageRef)!.texturePaths;
        this.imgJobBg.skin = jobTypetexture[quality];
        this.lblJob.strokeColor = IconResHelper.GetStrokeColorByQuality(quality);

        if (isHandbook) {
            const handbook = data.handbook!;
            this.lblName.text = handbook.name;
            this.lblJob.text = handbook.jobDesc;
            this.imgHero.skin = handbook.iconBig ?? "";

            if (!handbook.isUnlock) {
                // 图鉴未解锁
                this.gray = true;

                const star = handbook.baseStar! > 5 ? handbook.baseStar! - 5 : handbook.baseStar!;
                for (let i = 0; i < star; i++) {
                    imgStars[i].visible = true;
                    const starTextures = imgStars[i].getComponent(ImageRef)!.texturePaths;
                    imgStars[i].skin = handbook.baseStar! > 5 ? starTextures[1] : starTextures[0];
                }
            } else {
                this.gray = false;
                const awardedStar = Math.max(0, handbook.baseStar - 1, handbook.awardedStar!);
                if (handbook.star! > awardedStar) {
                    this.boxReward.visible = true;
                    const reward = app.service.hero.calcuHandbookStarReward(
                        handbook.starProgramme,
                        handbook.star!,
                        awardedStar!
                    );
                    this.lblReward.text = `${reward[0] ? reward[0].count : 0}`; //todo: 临时这样读取
                }

                const star = handbook.star! > 5 ? handbook.star! - 5 : handbook.star!;
                for (let i = 0; i < star; i++) {
                    imgStars[i].visible = true;
                    const starTextures = imgStars[i].getComponent(ImageRef)!.texturePaths;
                    imgStars[i].skin = handbook.star! > 5 ? starTextures[1] : starTextures[0];
                }
            }
        } else {
            this.gray = false;

            const character = data.character!;
            this.lblName.text = character.name;
            this.lblJob.text = character.jobDesc;
            this.lblLv.text = `${character.lv}`;
            this.imgHero.skin = character.iconBig ?? "";
            const star = character.star > 5 ? character.star - 5 : character.star;
            for (let i = 0; i < star; i++) {
                imgStars[i].visible = true;
                const starTextures = imgStars[i].getComponent(ImageRef)!.texturePaths;
                imgStars[i].skin = character.star! > 5 ? starTextures[1] : starTextures[0];
            }

            // pvp team idx
            const pvpTeamIdx = app.service.troop.getTeamIdxBySoliderIdx(character.uid);
            if (pvpTeamIdx) {
                this.imgTeamBg.visible = true;
                this.lblTeam.text = `${pvpTeamIdx}`;
            }
        }

        this.offAll(Laya.Event.CLICK);
        this.on(Laya.Event.CLICK, this, this.onClickEvent, [data]);
    }

    private onClickEvent(data: CharacterCardData) {
        if (data.type === "handbook") {
            if (!data.handbook.isUnlock) {
                return;
            } else if (data.handbook.star! > data.handbook.awardedStar!) {
                // 有奖励
                app.service.hero.requestHandbookReward([data.handbook.id]);
                return;
            }
        }

        app.ui.show(ui.CHARACTER_DETAIL, data as UICharacterDetailArgs);
    }
}
