const { regClass } = Laya;
import { app } from "../../../../app";
import { HeroLo } from "../../../hero/vo/HeroLo";
import { ChestBoxChooseHeroItemBase } from "./ChestBoxChooseHeroItem.generated";

@regClass('gdQMjjE7TOmLtzUBzoS93A')
export class ChestBoxChooseHeroItem extends ChestBoxChooseHeroItemBase {
    override onAwake(): void {
        super.onAwake();
        this.itemIcon.onClick(this.onClickItemIcon.bind(this));
    }

    public get data(): HeroLo {
        return this.dataSource as HeroLo;
    }

    public get isSelected(): boolean {
        const curHeroId = app.service.chest.heroId;
        if (curHeroId === this.data.id) {
            return true;
        }
        return false;
    }

    public updateShow(): void {
        const d = this.data;
        this.itemIcon.setHeroLo(d);
        if (this.isSelected) {
            this.img_mask.visible = true;
        } else {
            this.img_mask.visible = false;
        }
        this.lab_name.text = d.name;
    }

    private onClickItemIcon(): void {
        if (this.isSelected) {
            return;
        }
        app.service.chest.requestSwitchHero(this.data.key);
    }
}
