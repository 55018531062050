import { Node, NodeDef, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrCreatureComponent } from "../../ecs/components/PveSvrCreatureComponent";
import { FindTargets } from "./FindTargets";

export class IsTargetDie extends FindTargets {
    override run(node: Node, env: AiTreeEnv): Status {
        const [targetEids] = env.input as [number[] | number];
        const eid = targetEids instanceof Array ? targetEids[0] : targetEids;
        if (!eid) {
            node.warn(`invalid targetEids: ${eid}`);
            return "success";
        }

        const target = env.context.ecs.getComponent(eid, PveSvrCreatureComponent);
        if (!target) {
            node.warn(`invalid targetEid: ${eid}, no PveSvrCreatureComponent`);
            return "failure";
        }

        return target.isDie ? "success" : "failure";
    }

    override get descriptor(): NodeDef {
        return {
            name: "IsTargetDie",
            type: "Condition",
            desc: "是否target死亡",
            args: [],
            input: ["目标单位"],
            doc: `
                + target活着返回失败
                + target死亡返回成功
                `,
        };
    }
}
