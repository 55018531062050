/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/battle/pve-ui/build/build-confirm.lh
 */
export class BuildConfirmUIBase extends Laya.Box {
    public costIcon!: Laya.Image;
    public needCostLabel!: Laya.Label;
    public buildButton!: Laya.Button;
}

