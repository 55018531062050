const { regClass } = Laya;
import { GMPurchaseMediator } from "../GMPurchaseMediator";
import { UI_GM_PurchaseBase } from "./UI_GM_Purchase.generated";

@regClass('clJRJFRVTf6YW3bqUIb4Bw')
export class UI_GM_Purchase extends UI_GM_PurchaseBase {
    override onAwake(): void {
        super.onAwake();
        this.searchBtn.onClick(this.onClickSearchBtn.bind(this));
    }

    public get mediator(): GMPurchaseMediator | null {
        return this.getComponent(GMPurchaseMediator);
    }

    override open(closeOther?: boolean | undefined, param?: any): void {
        super.open(closeOther, param);
        const m = this.mediator;
        if (m) {
            m.open();
        }
    }

    private onClickSearchBtn(): void {
        this.mediator?.onClickSearchBtn();
    }
}
