import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrCollectionComponent } from "../../ecs/components/PveSvrCollectionComponent";
import { ElementTag } from "../../ecs/components/PveSvrElementComponent";
import { PveSvrTroopComponent } from "../../ecs/components/PveSvrTroopComponent";
import { PveSvrTruckComponent } from "../../ecs/components/PveSvrTruckComponent";

interface NodeArgs {
    readonly radius: number;
}

export class FindVaildableCollectionTarget extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const args = node.args as unknown as NodeArgs;
        const radius = args.radius;

        const troop = env.context.ecs.getComponent(env.context.troopEid, PveSvrTroopComponent);
        if (!troop) {
            // 没有队伍
            console.warn("没有队伍");
            return "failure";
        }

        const trucks = Object.values(troop.truckEids).map((eid) =>
            env.context.ecs.getComponent(eid, PveSvrTruckComponent)
        );

        // if (!trucks) {
        //     // 找不到车子
        //     return "failure";
        // }

        let tag = 0; // |  | ;

        const allTags = [ElementTag.WOOD, ElementTag.FOOD, ElementTag.STONE];
        const removeTags = [];
        if (trucks) {
            for (const truck of trucks) {
                if (truck && truck.isFull) {
                    // 资源车未满
                    removeTags.push(truck.collectTypeTag);
                }
            }
        }

        for (let i = 0; i < allTags.length; i++) {
            const t = allTags[i];
            if (removeTags.indexOf(t) === -1) {
                tag |= t;
            }
        }

        // if (tag === 0) {
        //     return "failure"; // 全部车子资源已满
        // }

        const position = env.owner.transform.position;

        const arr = env.context.find((element) => {
            if (element.tag & tag) {
                const collectionComp = element.getComponent(PveSvrCollectionComponent);
                if (!collectionComp || collectionComp.remain < 1) {
                    return false;
                }
                const distance = Laya.Vector3.distanceXZ(element.transform.position, position);
                if (distance < radius) {
                    element.tmpDistance = distance;
                    return true;
                } else {
                    return false;
                }
            }

            return false;
        });

        if (arr && arr.length > 0) {
            arr.sort((a, b) => a.tmpDistance - b.tmpDistance);
            env.output.push(arr[0].eid);
            return "success";
        }
        return "failure";
    }

    override get descriptor(): NodeDef {
        return {
            name: "FindVaildableCollectionTarget",
            type: "Condition",
            desc: "给定的范围内查找最近的一个可采集物对象",
            args: [{ name: "radius", type: "float", desc: "半径" }],
            output: ["目标单位"],
            doc: `
                + 没有找到返回失败
                + 车子满了返回失败
                + 返回符合采集条件的最近一个采集对象
                `,
        };
    }
}
