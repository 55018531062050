import { Node, NodeDef, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { FindTargets } from "./FindTargets";

type FindTargetsOutput = [unknown[]];

export class FindOneTarget extends FindTargets {
    override run(node: Node, env: AiTreeEnv): Status {
        const status = super.run(node, env);
        if (status === "success") {
            const [targets] = env.output as FindTargetsOutput;
            env.output.length = 0;
            env.output.push(targets[0]);
        }
        return status;
    }

    override get descriptor(): NodeDef {
        const descriptor = super.descriptor;
        descriptor.name = "FindOneTarget";
        descriptor.desc = "给定的范围内查找1个目标";
        descriptor.args = descriptor.args?.filter((v) => v.name !== "limit");
        return descriptor;
    }
}
