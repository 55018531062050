import * as ecs from "../../../../../core/ecs";
import { Tilemap } from "../../../tilemap/tilemap";
import { PveContext } from "../../PveContext";
import { PveCameraComponent } from "../components/PveCameraComponent";
import { PveClearCloudComponent } from "../components/PveClearCloudComponent";
import { PveMapComponent } from "../components/PveMapComponent";

export class PveMapSystem extends ecs.System {
    declare context: PveContext;

    override onCreate() {
        const map = this.ecs.getSingletonComponent(PveMapComponent);
        map.tilemap = new Tilemap(this.context);
        map.tilemap.onCreate();
    }

    override onDestroy() {
        const map = this.ecs.getSingletonComponent(PveMapComponent);
        map.tilemap.destroy();
    }

    override update(dt: number) {
        const map = this.ecs.getSingletonComponent(PveMapComponent);
        const camera = this.ecs.getSingletonComponent(PveCameraComponent);
        const clearCloudComp = this.ecs.getSingletonComponent(PveClearCloudComponent);
        map.tilemap.cloudClearKeys = clearCloudComp.clearKeys;
        map.tilemap.update(camera.focus);
        if (clearCloudComp.needClearCloudKey) {
            // 需要刷新
            map.tilemap.clearCloud(clearCloudComp.needClearCloudKey);
            clearCloudComp.needClearCloudKey = undefined;
        }
    }
}
