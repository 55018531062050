/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/home/HomeRightMenu.lh
 */
export class HomeRightMenuBase extends Laya.Box {
    public imgClose!: Laya.Image;
    public vboxContent!: Laya.VBox;
    public btnBag!: Laya.Box;
    public btnNotice!: Laya.Box;
    public btnRank!: Laya.Box;
    public btnShop!: Laya.Box;
}

