import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { TableUtil } from "../../../../table/TableUtil";
import { IAttacker, IDefender } from "../../utils/skill/DamagePipeline";
import { PveSvrStateDataUtils } from "../data/state-data/PveSvrStateDataUtils";
import { PveSvrBuffComponent } from "./PveSvrBuffComponent";
import { PveSvrElementComponent } from "./PveSvrElementComponent";
import { PveSvrTransformComponent } from "./PveSvrTransformComponent";

/**
 * 生命体组件
 * 包含生命，可以攻击，被攻击的单位，视为生命体，包括英雄，士兵，怪物等
 *
 * 所在Entity肯定包含的组件有：
 * - @PveSvrElementComponent
 * - @PveSvrBuffComponent
 */
export class PveSvrCreatureComponent
    extends ecs.Component
    implements Required<IAttacker>, Required<IDefender>
{
    private static GOT_HURT_TIMESTAMP_DELAY = 3; //second
    private static ATK_TARGET_TIMESTAMP_DELAY = 3; //second

    /** 检测单位是否处于脱战战斗中
     *  没有攻击目标，而且N秒内没被攻击到，就算脱战
     */
    static checkIsOutOfFight(creature: PveSvrCreatureComponent) {
        creature._isOutOfFight = !creature.atkTargetEid && !creature.isRecentlyGotHurt;
        return creature._isOutOfFight;
    }

    override get dependencies() {
        return [PveSvrElementComponent, PveSvrBuffComponent, PveSvrTransformComponent];
    }

    get eleComp() {
        return this.getComponent(PveSvrElementComponent)!;
    }

    get buffComp() {
        return this.getComponent(PveSvrBuffComponent)!;
    }

    get transformComp() {
        return this.getComponent(PveSvrTransformComponent)!;
    }

    // 生命值
    private _hp: number = 0;
    private _lastGetHurtTimestamp: number = 0; //最近受伤的时间戳

    get isDie() {
        return this.hp <= 0;
    }

    // 最大生命值
    private _maxHp: number = 0;

    get maxHp() {
        return this._maxHp;
    }

    set maxHp(value: number) {
        this._maxHp = Math.floor(value);
    }

    get hp() {
        return this._hp;
    }

    set hp(value: number) {
        const oldHp = this.hp;
        if (this.maxHp > 0 && value > this.maxHp) {
            value = this.maxHp;
        }
        this._hp = value | 0; //血量必须是整数
        PveSvrStateDataUtils.updateHp(this, this._hp);

        if (this._hp < oldHp) {
            this._lastGetHurtTimestamp = this.time;
        }
    }

    // 最近是否受到伤害
    get isRecentlyGotHurt(): boolean {
        return (
            this._lastGetHurtTimestamp > 0 &&
            this.time - this._lastGetHurtTimestamp <
                PveSvrCreatureComponent.GOT_HURT_TIMESTAMP_DELAY
        );
    }

    private _isOutOfFight: boolean = true;

    get isOutOfFight() {
        return this._isOutOfFight;
    }

    // 等级
    lv: number = 1;

    // // 移动速度
    // moveSpeed: number = 0;

    // 仇恨目标
    private _atkTargetEid: number = 0;
    private _atkTargetEidTimestamp: number = 0; // 仇恨目标时间戳

    get atkTargetEid() {
        if (
            this._atkTargetEid &&
            this.time - this._atkTargetEidTimestamp >
                PveSvrCreatureComponent.ATK_TARGET_TIMESTAMP_DELAY
        ) {
            // 仇恨目标过期
            this._atkTargetEid = 0;
        }
        return this._atkTargetEid;
    }

    set atkTargetEid(value: number) {
        this._atkTargetEid = value;
        this._atkTargetEidTimestamp = this.time;
    }

    //#region  IAttacker
    // 攻击力
    private _atk: number = 10;

    get atk() {
        const baseAtk = this._atk;
        const buffAtkSum = this.getBuffAttrSum("atk", false);
        const buffAtkPercentSum = this.getBuffAttrSum("atk", true) / 100;
        const ret = baseAtk * (1 + buffAtkPercentSum) + buffAtkSum;
        // return this.eleComp.aid === BattleAid.SELF ? 1 : ret; //测试
        return ret;
    }

    set atk(value: number) {
        this._atk = value;
    }

    hit: number = 100;

    finalAtkUp: number = 0;
    generalAckUp: number = 0;
    pveAttackUp: number = 0;
    criticalUp: number = 0.2;
    criticalDamage: number = 1.5;
    pursuitUp: number = 0;
    pursuitDamage: number = 0;
    counterUp: number = 0;
    counterDamage: number = 0;
    sputterUp: number = 0;
    sputterDamage: number = 0;
    penetrateUp: number = 0;
    penetrateDamage: number = 0;
    //#endregion

    //#region IDefender
    // 防御力
    private _defence: number = 0;

    get defence() {
        const baseDef = this._defence;
        const buffDefSum = this.getBuffAttrSum("defence", false);
        const buffDefPercentSum = this.getBuffAttrSum("defence", true) / 100;
        return baseDef * (1 + buffDefPercentSum) + buffDefSum;
    }

    set defence(value: number) {
        this._defence = value;
    }

    dodge: number = 0;

    finalAtkDown: number = 0;
    pveFinalAtkDown: number = 0;

    generalAckDown: number = 0;
    criticalDown: number = 0;
    pursuitDown: number = 0;
    counterDown: number = 0;
    sputterDown: number = 0;
    penetrateDown: number = 0;

    //#endregion IDefender

    getBuffAttrSum(attr: "hp" | "atk" | "defence", isPercent: boolean) {
        const allBuffDatas = isPercent
            ? this.buffComp.buffAttrPercentDatas
            : this.buffComp.buffAttrDatas;

        const attrId = TableUtil.getRow(app.service.table.attr, { define: attr })!.id;
        let sum = 0;
        if (allBuffDatas[attrId]) {
            for (const buffId in allBuffDatas[attrId]) {
                const buffDataArr = allBuffDatas[attrId][buffId];
                sum += buffDataArr.reduce((s, buffData) => {
                    return s + buffData.value;
                }, 0);
            }
        }
        return sum;
    }

    // // 攻击速度
    // atkSpeed: number = 0;

    // // 攻击范围
    // atkRange: number = 0;

    // // 攻击间隔
    // atkInterval: number = 0;
}
