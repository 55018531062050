/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/map-transfer/area-progress-list-item.lh
 */
export class AreaProgressListItemUIBase extends Laya.Box {
    public img_01!: Laya.Image;
    public img_icon!: Laya.Image;
    public text_name!: Laya.Label;
    public text_value!: Laya.Label;
}

