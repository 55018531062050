/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/leader-skin/UILeaderSkinTeam.lh
 */
export class UILeaderSkinTeamBase extends Laya.Dialog {
    public imgMask!: Laya.Image;
    public listTeam!: Laya.List;
    public btnsure!: Laya.Button;
}

