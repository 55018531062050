import { app } from "../../../../app";
import { Mediator } from "../../../../core/ui-mediator";
import proto from "../../../../def/auto/proto";
import { TroopState } from "../../../../def/auto/world";
import { SystemEvent } from "../../../../misc/system-event";
import { TroopTeamVo } from "../../../troop/vo/TroopTeamVo";
import { TroopPickItemUI } from "../../ui-runtime/pvp/TroopPickItemUI";
import { TroopPickerUI } from "../../ui-runtime/pvp/TroopPickerUI";
import { StateResDir, StateResMap } from "../../ui-runtime/pvp/TroopStateUI";
import { PVPUtils } from "../PVPUtils";
import { PvpStateComponent } from "../ecs/components/PvpStateComponent";
import { PvpTroopComponent } from "../ecs/components/PvpTroopComponent";

@Laya.regClass('aCjW4vHLQQ-u13j6OzOrAg')
export class TroopPickerMediator extends Mediator {
    declare owner: TroopPickerUI;

    private bAutoBackHome: boolean = false;

    override onAwake() {
        const args = this.owner.args;
        this.owner.btnBack.onClick(() => {
            this.owner.destroy();
        });
        this.owner.btnGo.onClick(() => {
            const array = this.owner.troopList.array;
            const troopEids: number[] = array ?? [];
            const selectEid = troopEids[this.owner.troopList.selectedIndex];
            this.owner.destroy(); //note: destroy会将troopList.array置为null
            if (!selectEid) {
                console.warn("TroopPickerMediator btnGo.onClick no selectEid");
                return;
            }

            app.service.pvp.requestTroopMoveTo(
                selectEid,
                {
                    x: args.target.x,
                    y: args.target.z,
                },
                this.bAutoBackHome
            );
        });

        this.owner.on(Laya.Event.CLICK, () => {
            this.owner.destroy();
        });

        this.refreshTroopList();

        this.owner.btnAutoBackHome.on(Laya.Event.CLICK, this, this.onAutoBackHome);
        this.owner.imgSelected.visible = this.bAutoBackHome;

        this.$(app).on(SystemEvent.PVP.UPDATE_TROOP_ENTITY, this.refreshTroopList, this);
    }

    private refreshTroopList() {
        const troopEids = PVPUtils.GetSelfActionableTroopEids(this.owner.args.context);
        if (!troopEids?.length) {
            this.owner.troopList.array = [];
            return;
        }
        const lastEid = this.owner.troopList.array?.[this.owner.troopList.selectedIndex] ?? -1;

        this.owner.troopList.array = troopEids;
        this.owner.troopList.selectHandler = new Laya.Handler(this, this.selectHandler);
        this.owner.troopList.renderHandler = new Laya.Handler(this, this.renderHandler);
        const index = troopEids.indexOf(lastEid);
        this.owner.troopList.selectedIndex = index === -1 ? 0 : index;
        this.owner.troopList.refresh();
    }

    private selectHandler(index: number) {
        // console.log("selectHandler", index);
        const data: TroopTeamVo = this.owner.troopList.array[index];
        this.requestTroopMoveCost(data.eid ?? 0, this.owner.args.target, index);
    }

    private renderHandler(item: TroopPickItemUI, index: number) {
        // console.log("renderHandler", item, index);
        const data: number = item.dataSource ?? this.owner.troopList.array[index];
        if (!data) {
            Laya.LayaEnv.isPreview && console.warn("renderHandler", item, index);
            return;
        }
        const troopEid = data;

        let troopStateSrc = "";
        const stateComp = this.owner.args.context.ecs.getComponent(troopEid, PvpStateComponent);
        if (stateComp) {
            const stateRes = StateResMap.get(stateComp.state ?? 0);
            const dir = StateResDir;
            if (stateRes) {
                troopStateSrc = `${dir}/${stateRes.focus}`;
            }
        }
        if (troopStateSrc) {
            item.imgTroopState.skin = troopStateSrc;
        } else {
            console.warn("renderHandler unhandle troop state", index, troopEid, stateComp?.state);
        }

        const troopComp = this.owner.args.context.ecs.getComponent(troopEid, PvpTroopComponent);
        if (troopComp) {
            item.troopHp.value = troopComp.maxHp ? troopComp.hp / troopComp.maxHp : 0;
            item.txtTroopHp.text = `${troopComp.hp}`;
        } else {
            console.warn("renderHandler unhandle troop component", index, troopEid);
        }
        const voTroop = app.service.troop.troopTeamVoMap
            .toArray()
            .find((value: TroopTeamVo, key: string | number) => {
                return value.eid === troopEid;
            });

        if (voTroop?.eid) {
            const heroName = app.service.hero.heroVoMap.get(voTroop.eid)?.name;
            item.txtTroopName.text = `${heroName ?? index + 1}队`;
        } else {
            console.warn("renderHandler unhandle heroUid", index, troopEid);
        }
    }

    private async requestTroopMoveCost(
        troopEid: number,
        dest: Laya.Vector3,
        selectedIndex: number
    ) {
        if (!troopEid) {
            return;
        }
        if (this.owner.troopList.selectedIndex !== selectedIndex) {
            return;
        }

        const ret = await app.service.network.call(
            proto.world.c2s_calc_troop_move_to.create({
                troopEid: troopEid,
                dstPos: {
                    x: dest.x,
                    y: dest.z,
                },
            }),
            proto.world.s2c_calc_troop_move_to
        );

        const stateComp = this.owner.args.context.ecs.getComponent(troopEid, PvpStateComponent);
        if (stateComp) {
            if (stateComp.state === TroopState.MOVE) {
                Laya.timer.once(1000, this, this.requestTroopMoveCost, [
                    troopEid,
                    dest,
                    selectedIndex,
                ]);
            }
        }

        // todo: 根据troopEid获取TroopComponent判断部队当前的状态，看是否需要缓存cost数据
        // console.log("requestTroopMoveCost", ret);
        if (ret.err) {
            this.owner.txtTroopCost.text = "--:--:--";
        } else {
            const sec = Math.floor(ret.costMs / 1000) % 60;
            const min = Math.floor(ret.costMs / 1000 / 60) % 60;
            const hour = Math.floor(ret.costMs / 1000 / 60 / 60);
            this.owner.txtTroopCost.text = `${hour < 10 ? "0" + hour : hour}:${
                min < 10 ? "0" + min : min
            }:${sec < 10 ? "0" + sec : sec}`;
        }
    }

    private onAutoBackHome() {
        if (!this.owner.args.bAttack) {
            return;
        }

        this.bAutoBackHome = !this.bAutoBackHome;
        this.owner.imgSelected.visible = this.bAutoBackHome;
    }
}
