/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/skill/SkillRemoveItem.lh
 */
export class SkillRemoveItemUIBase extends Laya.Box {
    public labTeam!: Laya.Label;
    public labState!: Laya.Label;
    public btnOff!: Laya.Button;
}

