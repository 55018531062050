import { app } from "../../../../../app";
import * as ecs from "../../../../../core/ecs";
import { SystemEvent } from "../../../../../misc/system-event";
import { TableUtil } from "../../../../table/TableUtil";
import { TaskVo } from "../../../../task/vo/TaskVo";
import { PveServer } from "../../PveServer";
import { PveSvrElementComponent } from "../components/PveSvrElementComponent";
import { PveSvrEventComponent } from "../components/PveSvrEventComponent";
import { PveSvrNaviArrowComponent } from "../components/PveSvrNaviArrowComponent";
import { PveSvrTransformComponent } from "../components/PveSvrTransformComponent";

export class PveSvrNaviArrowSystem extends ecs.System {
    declare context: PveServer;

    override onCreate(): void {
        // 点击任务"前往"
        app.on(SystemEvent.CLICK_GO_TASK_SHOW_WEAK_GUIDE, (param: TaskVo) => {
            this.onClickGoTaskShowWeakGuide(param);
        });
    }

    override onDestroy(): void {}

    /** 显示前往任务的弱指引 */
    onClickGoTaskShowWeakGuide(param: TaskVo): void {
        const hero = this.ecs.getComponent(this.context.ctrlHeroEid, PveSvrElementComponent);
        if (!hero) return;

        const navigationArrow = this.ecs.getComponent(hero.eid, PveSvrNaviArrowComponent);
        if (!navigationArrow) return;

        const taskRow = TableUtil.getRow(app.service.table.task, { id: param.key });
        let guide_id = taskRow?.guide_id;
        guide_id = 1;
        if (guide_id) {
            if (guide_id === 1) {
                // 去捡宝箱
                // 获取距离最近的一个宝箱
                const eventEntity = this.getClosestEvent();
                if (eventEntity) {
                    const position: Laya.Vector3 | undefined =
                        eventEntity.getComponent(PveSvrTransformComponent)?.position;
                    if (position) {
                        navigationArrow.setAstarDestination(position.x, position.z);
                    }
                } else {
                    app.ui.toast("地图已无更多宝箱！");
                }
                //
            }
        }
    }

    private tmpVector3: Laya.Vector3 = new Laya.Vector3();

    /**
     * 获取最接近玩家的一个事件实体
     * (目前存在问题。未加载的地图区域是不存在实体的)
     */
    private getClosestEvent(): PveSvrEventComponent | null {
        const hero = this.ecs.getComponent(this.context.ctrlHeroEid, PveSvrElementComponent);
        if (!hero) return null;
        this.tmpVector3.x = hero.transform.position.x;
        this.tmpVector3.y = 0;
        this.tmpVector3.z = hero.transform.position.z;
        const allEvents = this.ecs.getComponents(PveSvrEventComponent);
        let minDisEntity: PveSvrEventComponent | null = null;
        let minDistance: number = 999999999;
        allEvents.forEach((event) => {
            const transform = event.getComponent(PveSvrTransformComponent);
            if (transform) {
                const distance = Laya.Vector3.distanceSquared(this.tmpVector3, transform.position);
                if (distance < minDistance) {
                    minDistance = distance;
                    minDisEntity = event;
                }
            }
        });
        return minDisEntity;
    }

    /**
     * 让目标单位的导航指引箭头指向targetPos
     * @param targetPosA 如果不显示导航箭头，则传null
     * @param targetPosB 如果不显示导航箭头，则传null
     * 为了玩家已经超过指引A点导致的指引掉头问题，加多一个B点指引
     */
    public static navigationArrowPointTo(
        pveServer: PveServer,
        eid: number,
        posO: Laya.Vector3,
        targetPosA: Laya.Vector3,
        targetPosB: Laya.Vector3
    ): void {
        pveServer.sender.navigationArrowPointTo(eid, posO, targetPosA, targetPosB);
    }

    /** 到达目的地时需要隐藏指引箭头 */
    public static hideNavigationArrow(pveServer: PveServer, eid: number): void {
        pveServer.sender.hideNavigationArrow(eid);
    }
}
