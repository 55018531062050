import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { LAST_ADJUST_DIR } from "../actions/AdjustStance";

export class IsFreeStance extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const owner = env.owner;
        if (env.context.isFreeStance(owner, owner.transform.position)) {
            env.set(LAST_ADJUST_DIR, undefined);
            return "success";
        } else {
            return "failure";
        }
    }

    override get descriptor(): NodeDef {
        return {
            name: "IsFreeStance",
            type: "Condition",
            desc: "判断攻击位置",
            doc: `+ 判断当前的位置是不是合理的攻击位置`,
        };
    }
}
