import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { PveSvrUtils } from "../../PveSvrUtils";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrTransformComponent } from "../../ecs/components/PveSvrTransformComponent";

interface NodeArgs {
    entity_id: number;
    delay_delete?: number;
    src_x?: number;
    src_y?: number;
    src_z?: number;
    attch_target?: boolean;
}

type Input = [number | number[]];

export class CreateVFX extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const args = node.args as unknown as NodeArgs;
        const [inputEids] = env.input as Input;
        const inputEid = inputEids instanceof Array ? inputEids[0] : inputEids;
        const targetEle = inputEid ? env.context.findElement(inputEid) : env.owner;
        if (!targetEle) {
            console.error("CreateVFX targetEle is null", inputEid);
            return "failure";
        }
        const targetTransform = targetEle.getComponent(PveSvrTransformComponent)!;
        const targetPos = targetTransform.position;
        const x = args.src_x ?? 0;
        const y = args.src_y ?? 0;
        const z = args.src_z ?? 0;
        const targetRot = targetTransform.rotation;
        PveSvrUtils.launchVFXEffect(
            env.context,
            targetEle.eid,
            args.entity_id,
            targetPos,
            targetRot,
            !!args.attch_target,
            { x, y, z },
            args.delay_delete
        );
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "CreateVFX",
            type: "Action",
            desc: "创建特效VFX",
            args: [
                { name: "entity_id", type: "int", desc: "特效实体id" },
                { name: "delay_delete", type: "float?", desc: "延迟删除" },
                { name: "attch_target", type: "boolean?", desc: "是否跟随目标" },
                { name: "src_x", type: "float?", desc: "起点x坐标(相对特效目标)" },
                { name: "src_y", type: "float?", desc: "起点y坐标(相对特效目标)" },
                { name: "src_z", type: "float?", desc: "起点z坐标(相对特效目标)" },
            ],
            input: ["特效目标?"],
            doc: `
            ## 创建特效VFX
            + 输入变量【特效目标】如果不传表示特效目标是自身
            + 参数【起点坐标(相对特效目标)】
            `,
        };
    }
}
