const { regClass } = Laya;
import { PveContext } from "../../../pve/PveContext";
import { UIBDCollectionFactoryMediator } from "../../../pve/building_func/UIBDCollectionFactoryMediator";
import { UIBDCollectionFactoryBase } from "./UIBDCollectionFactory.generated";

export type OpenParam = { context: PveContext; eid: number };

@regClass('Za-yoedyTX-4FS8RQJM5nw')
export class UIBDCollectionFactory extends UIBDCollectionFactoryBase {
    public openParam?: OpenParam;

    public get mediator(): UIBDCollectionFactoryMediator | null {
        return this.getComponent(UIBDCollectionFactoryMediator);
    }

    override onAwake(): void {
        super.onAwake();
        this.btn_upgrade.onClick(this.onClickUpgradeBtn.bind(this));
        this.btn_add_speed.onClick(this.onClickAddSpeedBtn.bind(this));
    }

    /** 点击升级按钮 */
    private onClickUpgradeBtn(): void {
        if (this.mediator) {
            this.mediator.onClickUpgradeBtn();
        }
    }

    /** 点击加速按钮 */
    private onClickAddSpeedBtn(): void {
        if (this.mediator) {
            this.mediator.onClickAddSpeedBtn();
        }
    }

    override open(closeOther?: boolean | undefined, param?: OpenParam): void {
        this.openParam = param;
        super.open(closeOther, param);
        const m = this.mediator;
        if (m) {
            m.open();
        }
    }

    override onClosed(type?: string | undefined): void {
        const m = this.mediator;
        if (m) {
            m.onClosed();
        }
    }

    override onOpened(): void {}
}
