import { Node, NodeDef, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrElementComponent } from "../../ecs/components/PveSvrElementComponent";
import { FindTargets } from "./FindTargets";

export class IsSameTroop extends FindTargets {
    override run(node: Node, env: AiTreeEnv): Status {
        const [targetEid] = env.input as [number[] | number];
        if (!(typeof targetEid === "number")) {
            node.warn(`invalid targetEids: ${targetEid}`);
            return "failure";
        }

        const target = env.context.ecs.getComponent(targetEid, PveSvrElementComponent);
        if (!target) {
            node.warn(`invalid targetEid: ${targetEid}, no PveSvrElementComponent`);
            return "failure";
        }

        return target.troopEid === env.owner.troopEid ? "success" : "failure";
    }

    override get descriptor(): NodeDef {
        return {
            name: "IsSameTroop",
            type: "Condition",
            desc: "目标与自己是否同一编队",
            args: [],
            input: ["目标单位"],
            doc: `
                + 同一编队返回成功
                + 不同编队返回失败
                `,
        };
    }
}
