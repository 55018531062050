import { app } from "../../../app";
import { PveEventType } from "../../../def/auto/battle";
import {
    BirthPointData,
    MapTransferData,
    TMWorldCloudData,
    TMWorldMinimap,
    TerritoryStartEnd,
} from "../pve-server/PveDefs";
import { TMUtil } from "../tilemap/tm-util";

const tempVector2A: Laya.Vector2 = new Laya.Vector2();
const tempVector2B: Laya.Vector2 = new Laya.Vector2();

export class PveTMWorldMinimap {
    private _transferIdsMap: Map<number, MapTransferData[]> | undefined;
    private _transferIdsMap2: Map<number, MapTransferData> | undefined;
    private _transferAreaIdsMap: Map<number, MapTransferData[]> | undefined;

    /** 记录地图块区域id */
    private _areaIDMap: Map<number, number> | undefined;

    private _minimapData!: TMWorldMinimap;

    public get minimapData(): TMWorldMinimap {
        return this._minimapData!;
    }

    public set minimapData(value: TMWorldMinimap) {
        this._minimapData = value;
        this.initMapData();
    }

    /** 缓存 isBlock 的计算结果 */
    private _staticWorldBlocksMap: Map<number, boolean> = new Map<number, boolean>();

    isStaticBlockAt(x: number, z: number): boolean {
        const gridX = x << 0;
        const gridY = z << 0;
        const key = (gridX << 20) | (gridY << 0);
        const worldBlocks = this.minimapData.blocks;
        let isBlockVal = this._staticWorldBlocksMap.get(key);
        if (isBlockVal === undefined) {
            const idx = gridX + gridY * worldBlocks.width;
            const mergeIdx = Math.floor(idx / worldBlocks.stride);
            const bitOffset = idx % worldBlocks.stride;
            const blocks = worldBlocks.data;
            isBlockVal = ((blocks[mergeIdx] >> bitOffset) & 1) === 1;
            this._staticWorldBlocksMap.set(key, isBlockVal);
        }
        if (isBlockVal) {
            return true;
        }
        return false;
    }

    staticWorldBlockDatas!: number[][];

    private initMapData(): void {
        this.staticWorldBlockDatas = [];
        const blocks = this.minimapData.blocks;
        const xNum: number = blocks.width;
        const yNum: number = blocks.height;
        for (let xIndex = 0; xIndex < xNum; xIndex++) {
            const col: number[] = [];
            for (let yIndex = 0; yIndex < yNum; yIndex++) {
                const isB = this.isStaticBlockAt(xIndex, yIndex);
                col.push(isB ? 1 : 0);
            }
            this.staticWorldBlockDatas.push(col);
        }
    }

    private _cloudData?: Map<number, string>;

    /**
     * 云迷雾数据
     * key 是 x和y组合值
     * value是01~20的组序号
     */
    public get cloudData(): Map<number, string> {
        if (!this._cloudData) {
            this._cloudData = PveTMWorldMinimap.getCloudData(this.minimapData);
        }
        return this._cloudData!;
    }

    public static getCloudData(minimapData: TMWorldMinimap): Map<number, string> {
        const worldCloudMap = new Map<number, string>();
        const cloud_map: TMWorldCloudData = minimapData.cloud.data_map;
        const cloud_firstgid = minimapData.cloud.cloud_firstgid;
        if (cloud_firstgid && cloud_map) {
            for (const gid in cloud_map) {
                const gidOffset = Number(gid) - cloud_firstgid; // 0 是一个云区域
                const cd = cloud_map[gid];
                const minX = cd.min_xy[0];
                const minY = cd.min_xy[1];
                const xNum = cd.max_xy[0] - minX + 1;
                const yNum = cd.max_xy[1] - minY + 1;
                for (let i_y = 0; i_y < yNum; i_y++) {
                    for (let i_x = 0; i_x < xNum; i_x++) {
                        const gridX = minX + i_x;
                        const gridY = minY + i_y;
                        const key = (gridX << 20) | (gridY << 0);
                        if (!worldCloudMap.has(key)) {
                            const idx = i_x + i_y * xNum;
                            const mergeIdx = Math.floor(idx / 32);
                            const bitOffset = idx % 32;
                            const blocks = cd.data;
                            const isBlockVal = ((blocks[mergeIdx] >> bitOffset) & 1) === 1;
                            if (isBlockVal) {
                                let cloudKey = "" + (gidOffset + 1);
                                if (cloudKey.length < 2) {
                                    cloudKey = "0" + cloudKey;
                                }
                                worldCloudMap.set(key, cloudKey);
                            }
                        }
                        // else {
                        /*
                            let cloudKey = "" + (gidOffset + 1);
                            if (cloudKey.length < 2) {
                                cloudKey = "0" + cloudKey;
                            }
                            console.log(
                                "gridX:" +
                                    gridX +
                                    ", gridY:" +
                                    gridY +
                                    " Error" +
                                    worldCloudMap.get(key) +
                                    ", cloudKey:" +
                                    cloudKey
                            );*/
                        // }
                    }
                }
            }
        }
        return worldCloudMap;
    }

    /** 检查指定格子坐标是否位于自己的领域内 */
    public checkInTerritory(gridX: number, gridY: number): boolean {
        const { startX, startY, endX, endY } = this.getTerritoryStartEnd;
        if (gridX >= startX && gridX <= endX && gridY >= startY && gridY <= endY) {
            return true;
        }
        return false;
    }

    private _TerritoryStartEnd: TerritoryStartEnd | undefined;

    /** 领地的开始格子坐标和结束坐标 */
    public get getTerritoryStartEnd(): TerritoryStartEnd {
        if (!this._TerritoryStartEnd) {
            let startX: number = 0;
            let startY: number = 0;
            let endX: number = 0;
            let endY: number = 0;
            const customs = this.minimapData.custom;
            if (customs && customs.length > 0) {
                for (let i = 0; i < customs.length; i++) {
                    const custom = customs[i];
                    if (custom.name === "map_territory_leftup") {
                        startX = custom.x;
                        startY = custom.y;
                    } else if (custom.name === "map_territory_rightdown") {
                        endX = custom.x;
                        endY = custom.y;
                    }
                }
            }
            this._TerritoryStartEnd = { startX, startY, endX, endY };
        }
        return this._TerritoryStartEnd;
    }

    private _BirthPointMap: Map<number, BirthPointData> = new Map<number, BirthPointData>();

    /** 获取出生点的位置 */
    public getBirthPoint(cfgId: number): { x: number; z: number; rot: number } | undefined {
        if (this._BirthPointMap.has(cfgId)) {
            return this._BirthPointMap.get(cfgId)!;
        }
        const customs = this.minimapData.custom;
        if (customs && customs.length > 0) {
            for (let i = 0; i < customs.length; i++) {
                const custom = customs[i];
                if (custom.name.indexOf("birth_point") !== -1) {
                    const nameStrArr = custom.name.split("_");
                    if (nameStrArr.length > 1) {
                        const id = nameStrArr[nameStrArr.length - 1];
                        if (cfgId === Number(id)) {
                            const birthPointData = {
                                x: custom.x,
                                z: custom.y,
                                rot: custom.pros?.rot ?? 0,
                            };
                            this._BirthPointMap.set(cfgId, birthPointData);
                            return birthPointData;
                        }
                    }
                }
            }
        }
        return undefined;
    }

    /** 根据格子位置获取区域id */
    public getAreaIDWithGridPos(gridX: number, gridY: number): number | undefined {
        const blockInfo = TMUtil.findArea(gridX, gridY);
        return blockInfo?.id;
    }

    /** 获取最接近的一个传送点 */
    public getClosestAreaIDAndMapTransfer(
        gridPosX: number,
        gridPosY: number
    ): { areaId: number | undefined; mapTransfer: MapTransferData | null } {
        const gridX = gridPosX << 0;
        const gridY = gridPosY << 0;
        const areaId = this.getAreaIDWithGridPos(gridX, gridY);
        const blockIdx = TMUtil.findArea(gridX, gridY)?.id ?? 0;
        const areaTransferIds = this.getAreaTransferIds(blockIdx);
        tempVector2A.x = gridX;
        tempVector2A.y = gridY;
        let minDistance = Number.MAX_SAFE_INTEGER;
        let closerAreaTransfer: MapTransferData | undefined;
        if (areaTransferIds.length > 0) {
            for (const areaTransfer of areaTransferIds) {
                tempVector2B.x = areaTransfer.x;
                tempVector2B.y = areaTransfer.y;
                const dis = Laya.Vector2.distance(tempVector2A, tempVector2B);
                if (dis < minDistance) {
                    minDistance = dis;
                    closerAreaTransfer = areaTransfer;
                }
            }
        }
        if (closerAreaTransfer) {
            return { areaId: areaId, mapTransfer: closerAreaTransfer };
        }
        return { areaId: areaId, mapTransfer: null };
    }

    /** 获取指定区域所有传送点id */
    public getAreaTransferIds(blockIdx: number): MapTransferData[] {
        this.initTransferIdsMap();

        if (this._transferIdsMap!.has(blockIdx)) {
            return this._transferIdsMap!.get(blockIdx)!;
        }
        return [];
    }

    getAreaTransferIdsWithAreaId(areaId: number): MapTransferData[] {
        this.initTransferIdsMap();
        return this._transferAreaIdsMap!.get(areaId) || [];
    }

    private initTransferIdsMap(): void {
        if (!this._transferIdsMap) {
            this._transferIdsMap = new Map<number, MapTransferData[]>();
            this._transferIdsMap2 = new Map<number, MapTransferData>();
            this._transferAreaIdsMap = new Map<number, MapTransferData[]>();
            const customs = this.minimapData.custom;
            if (customs && customs.length > 0) {
                for (let i = 0; i < customs.length; i++) {
                    const custom = customs[i];
                    if (custom.name.indexOf("map_transfer_") !== -1) {
                        const nameStrArr = custom.name.split("_");
                        const rot = custom.pros?.rot ?? 0;
                        if (nameStrArr.length > 1) {
                            const id = nameStrArr[nameStrArr.length - 1];
                            const blockIdx = TMUtil.findArea(custom.x, custom.y)?.id ?? 0;
                            if (!this._transferIdsMap.has(blockIdx)) {
                                this._transferIdsMap.set(blockIdx, []);
                            }
                            const tId = Number(id);
                            const areaId = Number(id.slice(0, -2));
                            if (!this._transferAreaIdsMap.has(areaId)) {
                                this._transferAreaIdsMap.set(areaId, []);
                            }

                            const mapTransferData = { id: tId, x: custom.x, y: custom.y, rot: rot };
                            this._transferIdsMap.get(blockIdx)!.push(mapTransferData);
                            this._transferIdsMap2.set(tId, mapTransferData);
                            this._transferAreaIdsMap.get(areaId)!.push(mapTransferData);
                        }
                    }
                }
            }
        }
    }

    /** 根据指定传送点id获取传送点位置 */
    public getTransferPos(transferId: number): MapTransferData | undefined {
        this.initTransferIdsMap();
        return this._transferIdsMap2!.get(transferId);
    }

    private _AreaChestCountMap: Map<number, number> = new Map<number, number>();
    private _AreaUnLockCloudCountMap: Map<number, number> = new Map<number, number>();
    private _AreaRecusSoldierdCountMap: Map<number, number> = new Map<number, number>();
    private _AreaBossCountMap: Map<number, number> = new Map<number, number>();

    /** 获取指定区域的宝箱数量 */
    public getAreaChestCount(areaId: number): number {
        if (this._AreaChestCountMap.has(areaId)) {
            return this._AreaChestCountMap.get(areaId)!;
        }
        const countObj = this.minimapData.count;
        if (!countObj || !countObj[areaId]) return 0;
        const eventIds = countObj[areaId].eventIds;
        if (!eventIds || eventIds.length < 1) return 0;
        let totalCount = 0;
        for (let i = 0; i < eventIds.length; i++) {
            const event = eventIds[i];
            const eventVo = app.service.pve.eventLoMap.get(event.id);
            if (!eventVo) {
                console.error("在区域" + areaId + "上,存在事件Id(" + event.id + ")在表里找不到!!");
                continue;
            }
            if (eventVo.eventType === PveEventType.GAIN_CHEST) {
                totalCount = totalCount + event.c;
            }
        }
        this._AreaChestCountMap.set(areaId, totalCount);
        return totalCount;
    }

    /** 获取指定区域解锁云事件数量 */
    public getAreaUnLockCloudCount(areaId: number): number {
        if (this._AreaUnLockCloudCountMap.has(areaId)) {
            return this._AreaUnLockCloudCountMap.get(areaId)!;
        }
        const countObj = this.minimapData.count;
        if (!countObj || !countObj[areaId]) return 0;
        const eventIds = countObj[areaId].eventIds;
        if (!eventIds || eventIds.length < 1) return 0;
        let totalCount = 0;
        for (let i = 0; i < eventIds.length; i++) {
            const event = eventIds[i];
            const eventVo = app.service.pve.eventLoMap.get(event.id);
            if (!eventVo) {
                console.error("在区域" + areaId + "上,存在事件Id(" + event.id + ")在表里找不到!!");
                continue;
            }
            let isUnlockCloud = false;
            if (eventVo.eventType === PveEventType.UNLOCK_CLOUD) {
                isUnlockCloud = true;
            } else if (eventVo.eventType === PveEventType.TALK_TO_NPC) {
                const cd = eventVo.customData!;
                if (cd.finish_open_cloud) {
                    isUnlockCloud = true;
                }
            }
            if (isUnlockCloud) {
                totalCount = totalCount + event.c;
            }
        }
        this._AreaUnLockCloudCountMap.set(areaId, totalCount);
        return totalCount;
    }

    /** 获取指定区域解救士兵事件数量 */
    public getAreaRecusSoilderCount(areaId: number): number {
        if (this._AreaRecusSoldierdCountMap.has(areaId)) {
            return this._AreaRecusSoldierdCountMap.get(areaId)!;
        }
        const countObj = this.minimapData.count;
        if (!countObj || !countObj[areaId]) return 0;
        const eventIds = countObj[areaId].eventIds;
        if (!eventIds || eventIds.length < 1) return 0;
        let totalCount = 0;
        for (let i = 0; i < eventIds.length; i++) {
            const event = eventIds[i];
            const eventVo = app.service.pve.eventLoMap.get(event.id);
            if (!eventVo) {
                console.error(
                    "在区域" + areaId + "上,存在事件Id(" + event.id + ")在事件表里找不到!!"
                );
                continue;
            }
            if (eventVo.eventType === PveEventType.RESCUE_SOLDIER) {
                totalCount = totalCount + event.c;
            }
        }
        this._AreaRecusSoldierdCountMap.set(areaId, totalCount);
        return totalCount;
    }

    /** 获取指定区域Boss数量 */
    public getAreaBossCount(areaId: number): number {
        if (this._AreaBossCountMap.has(areaId)) {
            return this._AreaBossCountMap.get(areaId)!;
        }
        const countObj = this.minimapData.count;
        if (!countObj || !countObj[areaId]) return 0;
        const monsterIds = countObj[areaId].monsterIds;
        if (!monsterIds || monsterIds.length < 1) return 0;
        const monsterCfg = app.service.table.monster;
        let totalCount = 0;
        for (let i = 0; i < monsterIds.length; i++) {
            const monster = monsterIds[i];
            const monsterC = monsterCfg.troop[monster.id];
            if (!monsterC) {
                console.error(
                    "在区域" + areaId + "上,存在怪物Id(" + monster.id + ")在怪物表里找不到!!"
                );
                continue;
            }
            if (monsterC.isBoss) {
                totalCount = totalCount + monster.c;
            }
        }
        this._AreaBossCountMap.set(areaId, totalCount);
        return totalCount;
    }

    // public getAreaIdWithEventId(er): number | undefined {
    //     const countObj = this.minimapData.count;
    //     if (!countObj) return 0;
    //     // for
    //     return undefined;
    // }
}
