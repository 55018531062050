import ByteBuffer from "bytebuffer";
import { app } from "../../app";
import { Constructor } from "../../core/dispatcher";
import { Service } from "../../core/service";
import { Socket } from "../../core/socket";
import { user } from "../../def/auto/proto";
import { errcode, errmsg, errname, opcode, registerProtocols } from "../../def/auto/protocol";
import { SystemEvent } from "../../misc/system-event";

type PromiseDescriptor = {
    resolve: (value: any) => void;
    reject: (reason?: any) => void;
    request: any;
};

interface MessageWriter {
    finish(): Uint8Array;
}

interface MessageObject {
    err?: number;
}

export type ProtocolDescriptor = {
    op: number;
    typeURL: string;
    encode: (message: any, writer?: unknown) => MessageWriter;
    decode: (reader: Uint8Array, length?: number) => MessageObject;
};

export type MessageError = {
    op: number;
    opname: string;
    err: number;
    msg: string;
    name: string;
};

const protocols: { [key: number | string]: ProtocolDescriptor | undefined } = {};

export const register = (descriptor: ProtocolDescriptor) => {
    protocols[descriptor.op] = descriptor;
    protocols[descriptor.typeURL] = descriptor;
};

const HEADER_LENGTH = 6; // |-len(2)-|-op(2)-|-sid(2)-|

export class NetworkService extends Service {
    static readonly CONNECTING = Socket.CONNECTING;
    static readonly OPEN = Socket.OPEN;
    static readonly CLOSING = Socket.CLOSING;
    static readonly CLOSED = Socket.CLOSED;
    id?: string;
    uuid?: string;

    // 网络延时
    private _rtt: number = -1;

    private _callbacks: { [id: number]: PromiseDescriptor | undefined } = {};
    private _url: string | null = null;
    // 0: server notify
    // 1~2^15: message id
    private _session: number = 0;
    private _socket: Socket | null = null;
    private _status: number = NetworkService.CLOSED;
    private _buffer: ByteBuffer = new ByteBuffer(1 << 16);
    private _packetSize: number = 0;
    private _ignoredLog: { [k: number]: boolean } = {};
    private _timeOffset: number = 0;

    override onCreate() {
        if (Object.keys(protocols).length === 0) {
            registerProtocols();
        }
        Laya.timer.loop(2000, this, this._pingPong);
    }

    override onStartInit(): void {}

    override onDestroy() {
        Laya.timer.clear(this, this._pingPong);
        this.close();
    }

    private _formatTime() {
        const date = new Date(this.serverTime * 1000);
        return `[${date.toLocaleDateString("zh-CN", {
            second: "2-digit",
            minute: "2-digit",
            hour: "2-digit",
        })}]`;
    }

    toastError(err: number) {
        const msg = errmsg[err] ?? "<NOTFOUND>";
        app.ui.toast(`${msg}[0x${err.toString(16)}]`);
    }

    connect(url: string) {
        if (this.connected && this._url === url) {
            console.log(`already connect to ${this._url}`);
            return;
        }

        this._url = url;

        if (this.connected && this._socket) {
            this.close();
        }

        this._socket = new Socket(url);
        this._status = NetworkService.CONNECTING;

        this._socket.onerror = () => {
            this.clear();
            this._event(opcode.connection.ioerror);
        };
        this._socket.onopen = () => {
            this._status = NetworkService.OPEN;
            this._event(opcode.connection.connected);
            if (this._socket) {
                this._socket.onclose = () => {
                    this.clear();
                    this._event(opcode.connection.disconnected);
                    app.event(SystemEvent.DISCONNECTED);
                };
            }
        };
        this._socket.onmessage = (e) => this.onMessage(e);
    }

    private _event(op: number, data?: unknown) {
        // dispatch to service
        this._manager.event(op, data);

        // dispatch to app
        app.event(op, data);
    }

    get connected() {
        return this._status === NetworkService.OPEN;
    }

    get status() {
        return this._status;
    }

    private clear() {
        this._callbacks = {};
        this._status = NetworkService.CLOSED;
        this._socket = null;
    }

    close() {
        if (this._socket) {
            this._socket.onclose = null;
            this._socket.onmessage = null;
            this._socket.onerror = null;
            this._socket.onopen = null;
            this._socket.close();
            this.clear();
        }
    }

    /** 单次网络时延（秒） */
    get rtt() {
        return this._rtt;
    }

    /** 服务器时间（秒） */
    get serverTime() {
        return (Date.now() + this._timeOffset) / 1000;
    }

    private pingpongCount = 0;
    private MAX_PINGPONG_COUNT = 5;

    private async _pingPong() {
        if (this.connected) {
            try {
                const startTime = Laya.timer.currTimer;
                const response = await this.call(user.c2s_ping.create({}), user.s2c_ping);
                this._rtt = (Laya.timer.currTimer - startTime) / 2000;
                const curTimeOffset = response.serverMs + this.rtt - Date.now();
                this._timeOffset =
                    (this._timeOffset * this.pingpongCount + curTimeOffset) /
                    (this.pingpongCount + 1);
                this.pingpongCount = Math.min(this.MAX_PINGPONG_COUNT, this.pingpongCount + 1);
            } catch (err) {
                this._rtt = -1;
            }
        }
    }

    send<T extends object>(message: T) {
        this.call(message, null!);
    }

    // shoud use try catch
    async call<T extends object, R>(message: T, _: Constructor<R>) {
        return new Promise<R>((resolve, reject) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
            const typeURL = (message.constructor as any).getTypeUrl() as string;
            const protocol = protocols[typeURL];

            if (!protocol) {
                return reject({
                    err: errcode.NO_DESCRIPTOR,
                    message: `descriptor not found: ${typeURL}`,
                });
            }

            if (!this.connected) {
                console.error(errmsg[errcode.DISCONNECTED]);
                return;
            }

            if (this._session >= 1 << 16) {
                this._session = 0;
            }

            // TODO: 发布版本中移除
            if (!this._ignoredLog[protocol.op]) {
                console.log(`${this._formatTime()} Request: ${protocol.typeURL}`, message);
            }

            const session = ++this._session;
            const data = protocol.encode(message).finish();
            const len = HEADER_LENGTH + data.length;
            const packet = new ByteBuffer(len);
            packet.writeShort(len);
            packet.writeShort(protocol.op);
            packet.writeShort(session);
            packet.writeBytes(data);

            this._socket?.send(packet.buffer);

            this._callbacks[session] = { resolve, reject, request: message };
        });
    }

    private onMessage(e: MessageEvent<unknown>) {
        this._buffer.append(e.data as ArrayBuffer);
        this.decodeMessage();
    }

    private decodeMessage() {
        const buffSize = this._buffer.offset;
        if (this._packetSize === 0 && buffSize >= 2) {
            this._buffer.mark(buffSize);
            this._buffer.offset = 0;
            this._packetSize = this._buffer.readShort();
            this._buffer.reset();
        }
        if (this._packetSize > 0 && buffSize >= this._packetSize) {
            this._buffer.mark(buffSize);
            this._buffer.offset = 0;
            this._buffer.skip(2); // skip packet len

            const op = this._buffer.readShort();
            const session = this._buffer.readShort();
            const protocol = protocols[op];
            const len = this._packetSize - 6;
            this._buffer.skip(len);

            if (!protocol) {
                console.log(`unknonw opcode: ${op}`);
                this.ajustBuffer();
                return;
            }

            const uint8buff = new Uint8Array(this._buffer.buffer, 6, len);
            const message = protocol.decode(uint8buff);

            // TODO: 发布版本中移除
            if (!this._ignoredLog[protocol.op]) {
                console.log(`${this._formatTime()} Response: ${protocol.typeURL}`, message);
            }

            const promise = this._callbacks[session];

            if (message.err) {
                this._event(opcode.connection.msg_error, {
                    op: op,
                    opname: protocol.typeURL,
                    err: message.err,
                    msg: errmsg[message.err as keyof typeof errmsg],
                    name: errname[message.err as keyof typeof errmsg],
                } as MessageError);
            }

            this._event(protocol.op, [message, promise?.request]);

            // rpc call
            if (promise) {
                delete this._callbacks[session];
                promise.resolve(message);
            }
            this.ajustBuffer();
        }
    }

    private ajustBuffer() {
        this._packetSize = 0;

        if (this._buffer.offset < this._buffer.markedOffset) {
            const len = this._buffer.markedOffset - this._buffer.offset;
            const tmp = new ByteBuffer(len);
            this._buffer.copyTo(tmp, 0, this._buffer.offset, this._buffer.markedOffset);
            this._buffer.mark(0);
            this._buffer.reset();
            this._buffer.append(tmp);
            this.decodeMessage();
        } else {
            this._buffer.mark(0);
            this._buffer.reset();
        }
    }

    ignoreLog(op: number) {
        this._ignoredLog[op] = true;
    }
}
