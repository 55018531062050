import * as ecs from "../../../../../core/ecs";

export class PveSvrNaviArrowComponent extends ecs.Component {
    readonly tmpDestinationPoint: Laya.Vector3 = new Laya.Vector3();

    /** 需要指向的地图上坐标 */
    readonly pointToPosO: Laya.Vector3 = new Laya.Vector3(0, 0, 0);
    readonly pointToPosA: Laya.Vector3 = new Laya.Vector3(0, 0, 0);
    readonly pointToPosB: Laya.Vector3 = new Laya.Vector3(0, 0, 0);

    pointABArr: Laya.Vector3[] = [this.pointToPosO, this.pointToPosA, this.pointToPosB];

    /** 每0.2秒刷新一次A星寻路 */
    updateAstarDelayTime: number = 0.2;

    /** 是否存在需要指向的目标点 */
    hasTarget: boolean = false;

    /** 经过的时间 */
    overTime: number = 0;

    /**
     * 设置地图上的坐标点为目的地，然后指向A星寻路路径的下一个导航点
     * (注意目的地不能是障碍点)
     * @param destinationPosX 目的地X坐标
     * @param destinationPosZ 目的地Z坐标
     */
    setAstarDestination(destinationPosX: number, destinationPosZ: number): void {
        this.tmpDestinationPoint.x = destinationPosX;
        this.tmpDestinationPoint.z = destinationPosZ;
        this.hasTarget = true;
    }
}
