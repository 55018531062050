import { app } from "../../app";
import { Service } from "../../core/service";
import proto from "../../def/auto/proto";

export class RechargeService extends Service {
    override onCreate() {}

    override onStartInit(): void {}

    override onDestroy() {}

    // ------------------------------------------------------------------------
    // rpc call
    // ------------------------------------------------------------------------
    async loadRechargeShop(data: proto.recharge.Ic2s_load_recharge) {
        return await app.service.network.call(
            proto.recharge.c2s_load_recharge.create(data),
            proto.recharge.s2c_load_recharge
        );
    }
}
