import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";

export class MoveForward extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "MoveForward",
            type: "Action",
            desc: "向前移动(pvp专用)",
            doc: `
                + 往战场的中心点移动`,
        };
    }
}
