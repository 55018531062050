import { app } from "../../app";
import { ItemType } from "../../def/auto/item";
import { ui } from "../../misc/ui";
import { BagItem } from "./ui-runtime/BagItem";
import { ItemVo } from "./vo/ItemVo";

const { regClass, property } = Laya;

@regClass('u86nqbe5SN2_gs5JCGVAtQ')
export class BagItemMediator extends Laya.Script {
    declare owner: BagItem;

    @property(Boolean)
    isNoShowTips!: boolean;

    //组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
    override onAwake(): void {
        super.onAwake();
        this.owner.on(Laya.Event.CLICK, (evn: Laya.Event) => {
            this.onIconClick(evn);
            Laya.SoundManager.playSound("");
        });
    }

    onIconClick(evn: Laya.Event) {
        if (this.isNoShowTips) {
            return;
        }
        const itemData = this.owner.dataSource as ItemVo;
        if (!itemData) {
            return;
        }
        //有背包数据
        if (itemData.itemType === ItemType.MATERIAL) {
            app.ui.show(ui.BAG_ITEM_COMPOSE, itemData);
        } else if (itemData.itemType === ItemType.CHEST) {
            //打开简单使用弹窗
            app.ui.show(ui.BAG_ITEM_USE, itemData);
        } else if (itemData.itemType === ItemType.RANDOM_CHEST) {
            //打开随机获得道具弹窗
            app.ui.show(ui.BAG_ITEM_USE_RANDOM, itemData);
        } else if (itemData.itemType === ItemType.OPTIONAL_CHEST) {
            //打开道具自选弹窗
            app.ui.show(ui.BAG_ITEM_USE_SELECT, itemData);
        } else {
            app.ui.show(ui.ITEM_TIPS, itemData);
        }
    }
}
