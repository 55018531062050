/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */


/**
 * resources/prefab/login/LoginServerListItem.lh
 */
export class LoginServerListItemBase extends Laya.Box {
    public imgState!: Laya.Image;
    public imgNew!: Laya.Image;
    public labServerName!: Laya.Label;
    public labUserName!: Laya.Label;
    public labCur!: Laya.Label;
}

