import { app } from "../../app";
import { Mediator } from "../../core/ui-mediator";
import { SystemEvent } from "../../misc/system-event";
import { ui } from "../../misc/ui";
import { SkillFastListUI } from "./ui-runtime/SkillFastListUI";
const { regClass, property } = Laya;

@regClass('u87DDBTQQJCedRS48isxjg')
export class SkillFastListMediator extends Mediator {
    declare owner: SkillFastListUI;

    override onAwake(): void {
        super.onAwake();
        this.owner.imgClose.on(Laya.Event.CLICK, this.owner, this.owner.destroy);
        this.owner.btnSelect.on(Laya.Event.CLICK, this, this.onClickSelect);
        this.$(app).on(SystemEvent.SkILL.SKILL_MOUNT, this.initPanel, this);
        this.$(app).on(SystemEvent.SkILL.SKILL_UNMOUNT, this.initPanel, this);
        this.initPanel();
    }

    private initPanel() {
        const skillListData = app.service.skill.getSkillFastListSort();
        this.owner.initList(skillListData);
    }

    private onClickSelect() {
        app.ui.show(ui.SKILL_SCREEN);
    }
}
