import { Node, NodeDef, Process, Status } from "../../../../../core/behavior3";
import { AiTreeEnv } from "../../ecs/components/PveSvrAiComponent";
import { PveSvrCreatureComponent } from "../../ecs/components/PveSvrCreatureComponent";

interface NodeArgs {}

type NodeInput = [number];

export class SetAtkTarget extends Process {
    override run(node: Node, env: AiTreeEnv): Status {
        const creatureComp = env.owner.getComponent(PveSvrCreatureComponent);
        if (!creatureComp) {
            node.warn(`invalid target eid: ${env.owner.eid}, cannot find PveSvrCreatureComponent`);
            return "failure";
        }
        const [targetEid] = env.input as NodeInput;
        creatureComp.atkTargetEid = targetEid;
        return "success";
    }

    override get descriptor(): NodeDef {
        return {
            name: "SetAtkTarget",
            type: "Action",
            desc: "设置攻击目标",
            input: ["攻击目标"],
            args: [],
        };
    }
}
