/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { UIList } from "../../../../core/ui/UIList";

/**
 * resources/prefab/character/fame_reward/uicharacter_fame_reward.lh
 */
export class UICharacterFameRewardBase extends Laya.Dialog {
    public listReward!: UIList;
    public lblFame!: Laya.Label;
    public bthClose!: Laya.Button;
}
