import { app } from "../../../app";
import * as ecs from "../../../core/ecs";
import { Pool } from "../../../core/pool";
import { Mediator } from "../../../core/ui-mediator";
import proto from "../../../def/auto/proto";
import { res } from "../../../misc/res";
import { AnimClip, AnimName } from "../base/Animator";
import { P1v1UI } from "../ui-runtime/P1v1UI";
import { P1v1AnimationComponent } from "./ecs/components/P1v1AnimationComponent";
import { P1v1BattleReportComponent } from "./ecs/components/P1v1BattleReportComponent";
import { P1v1BattleReportSystem } from "./ecs/systems/P1v1BattleReportSystem";
import { P1v1CommandSystem } from "./ecs/systems/P1v1CommandSystem";
import { P1v1MovementSystem } from "./ecs/systems/P1v1MovementSystem";
import { P1v1RenderSystem } from "./ecs/systems/P1v1RenderSystem";

@Laya.regClass('eeyeD3lHTcG58hOrhDTwpw')
export class P1v1Context extends Mediator {
    declare owner: P1v1UI;

    private _camera?: Laya.Camera;
    private _ecs!: ecs.World;

    get scene() {
        return this.owner.scene;
    }

    get scene3D() {
        return this.owner.scene3D;
    }

    get camera() {
        return (this._camera ||= this.scene3D.getChildByName("Main Camera") as Laya.Camera);
    }

    override onDestroy() {
        Pool.clear(res.battle.PVP_DEBUG_TILE, (obj: Laya.Sprite3D) => obj.destroy());
        this._ecs.destroy();
        super.onDestroy();
    }

    override onAwake() {
        super.onAwake();

        this._ecs = new ecs.World(this);
        this._ecs.addSingletonComponent(P1v1BattleReportComponent);
        this._ecs.addSystem(P1v1CommandSystem);
        this._ecs.addSystem(P1v1BattleReportSystem);
        this._ecs.addSystem(P1v1RenderSystem);
        this._ecs.addSystem(P1v1MovementSystem);

        this._loadBattle();
    }

    get ecs() {
        return this._ecs;
    }

    override onUpdate() {
        super.onUpdate();
        this._ecs.update(Laya.timer.delta / 1000);
    }

    updateLayer(obj: Laya.Sprite3D) {
        obj.layer = this.camera.layer;
        for (let i = 0; i < obj.numChildren; i++) {
            this.updateLayer(obj.getChildAt(i) as Laya.Sprite3D);
        }
    }

    private async _loadBattle() {
        if (this.owner.args.battleUid) {
            app.service.pvp.requestEnterSlot(this.owner.args.battleUid, 1);
        } else {
            this._loadBattleReport(this.owner.args.url!);
        }
    }

    private async _loadBattleReport(url: string) {
        const checker = () => !this.destroyed;
        const buffer = await app.loader.loadBuffer(url);
        if (!checker()) {
            return;
        }

        const frames = proto.battle.BattleReport.decode(new Uint8Array(buffer));
        console.log("battle frame:", frames);
        const battleReport = this._ecs.getSingletonComponent(P1v1BattleReportComponent);
        battleReport.frames = frames.frames as proto.battle.BattleFrame[];
    }

    playAnim(eid: number, name: AnimName) {
        const anim = this.ecs.getComponent(eid, P1v1AnimationComponent);
        const animator = anim?.animator;
        if (anim && animator) {
            switch (name) {
                case AnimName.ATTACK: {
                    const idx = Math.round(Math.random() * 1000) % 2;
                    const clip: AnimClip = idx > 0 ? "attack1" : "attack2";
                    animator.play(clip);
                    animator.nextPlay("idle");
                    break;
                }
                case AnimName.IDLE:
                    anim.rushing = false;
                    animator.play("idle");
                    break;
                case AnimName.MOVE: {
                    const clip = anim.rushing ? "run" : "move";
                    animator.play(clip);
                    break;
                }
                case AnimName.DIE:
                    animator.play("die");
                    break;
            }
        }
    }
}
