export module res {
    // 表格数据
    export const TABLE_ALLIANCE_TABLE = "resources/data/alliance.json";
    export const TABLE_ATTR_TABLE = "resources/data/attr.json";
    export const TABLE_BATTLE_BUILDING_TABLE = "resources/data/battle/battle_building.json";
    export const TABLE_BATTLE_ENTITY_TABLE = "resources/data/battle/battle_entity.json";
    export const TABLE_BATTLE_EVENT_TABLE = "resources/data/battle/battle_event.json";
    export const TABLE_BATTLE_NPC_TABLE = "resources/data/battle/battle_npc.json";
    export const TABLE_BATTLE_TABLE = "resources/data/battle/battle.json";
    export const TABLE_BATTLE_SKILL_TABLE = "resources/data/battle/battle_skill.json";
    export const TABLE_BATTLE_PVE_MAP_TABLE = "resources/data/battle/battle_pve_map.json";
    export const TABLE_PLOT_TABLE = "resources/data/plot.json";
    export const TABLE_PLOT_THEATRE_TABLE = "resources/data/plot_theatre.json";
    export const TABLE_SPOILS_TABLE = "resources/data/spoils.json";
    export const TABLE_SKIN_TABLE = "resources/data/battle/battle_skin.json";
    export const TABLE_BUFF_TABLE = "resources/data/battle/battle_buff.json";
    export const TABLE_CHEST_TABLE = "resources/data/chest.json";
    export const TABLE_EMOJI_TABLE = "resources/data/emoji.json";
    export const TABLE_ENTITY_TABLE = "resources/data/world/world_entity.json";
    export const TABLE_WORLD_WAGON_TABLE = "resources/data/world/world_wagon.json";
    export const TABLE_EQUIP_TABLE = "resources/data/equip.json";
    export const TABLE_HERO_TABLE = "resources/data/hero.json";
    export const TABLE_ITEM_TABLE = "resources/data/item.json";
    export const TABLE_MAIL_TABLE = "resources/data/mail.json";
    export const TABLE_MONEY_TABLE = "resources/data/money.json";
    export const TABLE_MONSTER_TABLE = "resources/data/monster.json";
    export const TABLE_ROLE_TABLE = "resources/data/role.json";
    export const TABLE_SHOP_TABLE = "resources/data/activity/shop.json";
    export const TABLE_SKILL_SELECTOR_TABLE = "resources/data/skill_selector.json";
    export const TABLE_SOLDIER_TABLE = "resources/data/soldier.json";
    export const TABLE_TASK_TABLE = "resources/data/task.json";
    export const TABLE_BOARD_TEXTURE_TABLE = "resources/data/board_texture.json";
    export const TABLE_FORMATION_TABLE = "resources/data/formation.json";
    export const TABLE_DEFINE_TABLE = "resources/data/define.json";

    export const TABLE_RECHARGE_TABLE = "resources/data/activity/recharge.json";
    export const TABLE_TROOP_TABLE = "resources/data/troop.json";

    export const TABLE_TALENT_TABLE = "resources/data/grow/talent.json";
    export const LEADER_SKIN_TABLE = "resources/data/leader_skin.json";
    export const TABLE_ITEM_ROURCE_TABLE = "resources/data/item_source.json";
    export const TABLE_ClUSTER_TABLE = "resources/data/cluster.json";
    export const TABLE_RECRUIT_TABLE = "resources/data/recruit.json";

    export const BATTLE_HP_NUM = "resources/prefab/battle/pve-ui/hp-num.lh";
    export const BATTLE_RECOVER_HP_NUM = "resources/prefab/battle/pve-ui/hp-recover-num.lh";
    export const BATTLE_HP_NUM_X = "resources/prefab/battle/pve-ui/hp-num-x.lh";
    export const BATTLE_HEAD_INFO1 = "resources/prefab/battle/pve-ui/head-info1.lh";
    export const BATTLE_HEAD_INFO3 = "resources/prefab/battle/pve-ui/head-info3.lh";

    export const ITEM_MID_ICON_PATH = "resources/texture/icon_item/120x120/{0}.png";
    export const ITEM_SMALL_ICON_PATH = "resources/texture/icon_item/60x60/{0}.png";
    export const ITEM_BIG_ICON_PATH = "resources/texture/icon_item/256x256/{0}.png";

    export const SOLIDER_KING_ICON = "resources/atlas/soliderking/{0}.png";

    export const MAP_TRANSFER_ITEMS = "resources/atlas/map-transfer-items/{0}.png";

    export const DRAW_CARD_ICON = "resources/atlas/draw_card/{0}.png";
    export const DRAW_CARD_BIG_BG = "resources/texture/draw_card/{0}.png";

    export const BATTLE_RESCUE_SOLDIER_CONFIRM =
        "resources/prefab/battle/pve-ui/rescue-soldier-confirm.lh";
    export const BATTLE_CLOUD_CONFIRM = "resources/prefab/battle/pve-ui/cloud-confirm.lh";
    export const BATTLE_BUILD_CONFIRM = "resources/prefab/battle/pve-ui/build/build-confirm.lh";
    export const BATTLE_BD_UPGRADE_CONFIRM =
        "resources/prefab/battle/pve-ui/build/bd_upgrade_info.lh";
    export const BATTLE_BD_PRODUCTION_INFO_CONFIRM =
        "resources/prefab/battle/pve-ui/build/bd_production_info.lh";
    export const BATTLE_BUILDING_CONFIRM =
        "resources/prefab/battle/pve-ui/build/building-confirm.lh";
    export const BATTLE_NPC_OP_CONFIRM =
        "resources/prefab/battle/pve-ui/npc/npc_operation_confirm.lh";
    export const BATTLE_MAP_TRANSFER_CONFIRM =
        "resources/prefab/battle/pve-ui/map-transfer-confirm.lh";

    export const BATTLE_TRUCK_HEAD_INFO_WOOD =
        "resources/prefab/battle/pve-ui/truck-head-info-wood.lh";
    export const BATTLE_TRUCK_HEAD_INFO_FOOD =
        "resources/prefab/battle/pve-ui/truck-head-info-food.lh";
    export const BATTLE_TRUCK_HEAD_INFO_STONE =
        "resources/prefab/battle/pve-ui/truck-head-info-stone.lh";

    export const BATTLE_ROLE_SHADOW = "resources/prefab/battle/pve-ui/role-shadow.lh";
    export const BATTLE_ROLE_NAVIGATION_ARROW =
        "resources/prefab/battle/pve-ui/role-navigation-arrow.lh";

    export const BATTLE_SKILL_WARNING_FAN =
        "resources/prefab/battle/skill-warning/skill-warning-fan.lh";
    export const BATTLE_SKILL_WARNING_RECT =
        "resources/prefab/battle/skill-warning/skill-warning-rect.lh";

    export const BATTLE_BD_COMMON_INFO = "resources/prefab/battle/pve-ui/build/bd_common_info.lh";

    //#region PVP Resource
    export const BATTLE_PVP_HEAD_INFO1 = "resources/prefab/battle/pvp-ui/pvp-head-info1.lh";

    export const VFX_PVP_MOVE_TARGET = "resources/prefab/battle/vfx/ufx_zuobiao.lh";
    export const VFX_PVP_TROOP_SELECT = "resources/prefab/battle/vfx/ufx_danwei_yellow.lh";
    export const VFX_PVP_TROOP_BATTLE = "resources/prefab/battle/vfx/ufx_buduichongtu001_red.lh";
    export const VFX_PVP_START_BATTLE = "resources/prefab/battle/vfx/ufx_chongtuchufa001.lh";
    //#endregion PVP Resource

    /** 地图阻挡格子 */
    export const TILEMAP_PVE_WORLD_MINIMAP = "resources/data/tilemap/pve/world-minimap.json";

    export module battle {
        export const PVP_ACTION_MENU = "resources/prefab/battle/pvp-ui/action-menu.lh";
        export const PVP_RESOUCE_INFO = "resources/prefab/battle/pvp-ui/resource-info.lh";
        export const PVP_TROOP_STATE = "resources/prefab/battle/pvp-ui/troop-state.lh";
        export const PVP_DEBUG_TILE = "resources/prefab/battle/pvp-ui/pvp-debug-tile.lh";

        export const PVE_TROOP = "resources/texture/battle/pve-troop.atlas";
        export const PVE_3DUI_TEXTURE = "resources/texture/battle/pvp-3dui.png";

        export const PVE_ARROW_1 = "resources/texture/battle/pvp-3dui/ui_xingjun_lv.png";
        export const PVE_ARROW_2 = "resources/texture/battle/pvp-3dui/ui_xingjun_lan.png";
        export const PVE_ARROW_3 = "resources/texture/battle/pvp-3dui/ui_xingjun_lan.png";
        export const PVE_ENEMY_SMALL_HP_BAR = "resources/texture/battle/ui_shapan_blood3.png";

        export const PVE_WORLD_MAP_OBJECT = "resources/texture/world-map/object/{0}.png";
        export const PVE_BUILDING_UI_AVATAR = "resources/texture/battle/buildings/{0}.png";

        export const DEBUG_ALTAS = "resources/texture/battle/debug.atlas";
        export const DEUBG_BLOCK = "resources/texture/battle/debug/hong.png";
        export const DEUBG_MONSTER = "resources/texture/battle/debug/fen.png";
        export const DEUBG_NPC = "resources/texture/battle/debug/lan.png";
        export const DEUBG_BUILDING = "resources/texture/battle/debug/lan.png";
        export const DEUBG_EVENT = "resources/texture/battle/debug/huang.png";
    }

    export module atlas {
        export const RIVER_MASK = "resources/texture/world-map/atlas/river_mask.atlas";
    }

    export module skill {
        export const Skill_MAP_ITEM = "resources/prefab/skill/SkillMapItem.lh";
        export const SKILL_OWN_ITEM = "resources/prefab/skill/SkillOwnListItem.lh";
        export const SKILL_FAST_ITEM = "resources/prefab/skill/SkillFastListItem.lh";
        export const SKILL_TYPE_ICON = "resources/atlas/common/icon/{0}.png";
        export const SKILL_ICON = "resources/texture/icon_skill/{0}.png";
    }

    export module trainCamp {
        export const TRAINCAMP_POWER_ITEM = "resources/prefab/traincamp/PowerTalentItem.lh";
        export const TRAINCAMP_NORMAL_ITEM = "resources/prefab/traincamp/NormalTalentItem.lh";
        export const TRAINCAMP_TALENT_ITEM = "resources/prefab/traincamp/TalentListItem.lh";
        export const TRAINCAMP_IMAGE = `resources/atlas/img_icon_tianfu_120/{0}.png`;
    }

    export module troop {
        export const TROOP_HERO_FRAME = "resources/atlas/common/frame/{0}.png";
        export const TROOP_HERO_QUALITY = "resources/atlas/common/{0}.png";
        export const TROOP_SEL_BTN = "resources/atlas/common/{0}.png";
    }

    export module materials {
        export const TILE_MATERIAL = "resources/materials/tile_material.lmat";
        export const TILE_STATIC_MATERIAL = "resources/materials/tile_static_material.lmat";
        export const TILE_TRANSPARENT_MATERIAL =
            "resources/materials/tile_transparent_material.lmat";
        export const RIVER_MATERIAL_MATERIAL = "resources/materials/river_materials.lmat";
    }

    export const HERO_ICON_FORMAT = {
        SMALL: "resources/texture/img_hero/hero01/img_icon_hero01_{0}.png",
        MIDDLE: "resources/texture/img_hero/hero02/img_icon_hero02_{0}.png",
        BIG: "resources/texture/img_hero/hero03/img_icon_hero03_{0}.png",
        FULL: "resources/texture/img_hero/hero04/img_icon_hero04_{0}.png",
    };
}
