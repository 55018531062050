import * as ecs from "../../../../../core/ecs";
import { PvpCampStyle } from "../../PVPUtils";

// 部队选中特效
export class PvpTroopSelectVFXComponent extends ecs.Component {
    static readonly UPDATE = 1 << 0;

    view?: Laya.Sprite3D | null;
    pStyle: PvpCampStyle = PvpCampStyle.NONE;
    flag: number = 0;//update flag
}
