import { app } from "../../app";
import { Service } from "../../core/service";
import proto from "../../def/auto/proto";
import { GeneratedShop1Row } from "../../def/auto/table.generated";
import { Shop1Row } from "../../def/table";
export interface ShopItem {
    cmdData: proto.shop.ItemInfo;
    refData: Shop1Row;
}
export class ShopService extends Service {
    override onCreate() {}

    override onStartInit(): void {}

    override onDestroy() {}

    getShopItemLimit(ref: GeneratedShop1Row): number {
        if (ref.limit_day) {
            return ref.limit_day;
        } else if (ref.limit_week) {
            return ref.limit_week;
        }
        return 0;
    }

    /**
     *
     * @param shopItem 商城格子信息
     * @returns num = 可购买数量 tips 不可购买时的提示信息
     */
    getShopItemBuyNum(shopItem: ShopItem): { num: number; tips: string } {
        let num = 0;
        let tips = "";
        const cost = shopItem.refData.cost;
        const costBagNum = cost ? app.service.bag.itemVoMap.getItemAmount(cost[0].id) : 0;
        const maxBuyNum = cost ? Math.floor(costBagNum / cost[0].count) : 1;
        const limitNum = this.getShopItemLimit(shopItem.refData);
        if (!maxBuyNum) {
            tips = `道具不足····后续增加获取道具弹窗`;
        } else if (Math.min(limitNum, maxBuyNum) - shopItem.cmdData.buyNum) {
            tips = `已达购买上限`;
        }
        num = limitNum === 0 ? maxBuyNum : Math.min(limitNum, maxBuyNum) - shopItem.cmdData.buyNum;
        return { num: num, tips: tips };
    }

    // ------------------------------------------------------------------------
    // rpc call
    // ------------------------------------------------------------------------
    async load(data: proto.shop.Ic2s_load) {
        return await app.service.network.call(
            proto.shop.c2s_load.create(data),
            proto.shop.s2c_load
        );
    }

    //请求购买
    async requestBuy(data: proto.shop.Ic2s_buy) {
        return await app.service.network.call(proto.shop.c2s_buy.create(data), proto.shop.s2c_buy);
    }
}
