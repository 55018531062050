/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { AllianceJoinItem } from "./AllianceJoinItem";

/**
 * resources/prefab/alliance/AllianceListItem.lh
 */
export class AllianceListItemBase extends Laya.Box {
    public item!: AllianceJoinItem;
}

