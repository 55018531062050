import { appBase as AppBase } from "./app.generated";
import { Constructor, Dispatcher } from "./core/dispatcher";
import { Loader } from "./core/loader";
import { Service } from "./core/service";
import { TweenSystem } from "./core/tween/tween-system";
import { UIManager } from "./core/ui-manager";
import { opcode } from "./def/auto/protocol";
import { ui } from "./misc/ui";
import { BagService } from "./system/bag/BagService";
import { PveService } from "./system/battle/pve/PveService";
import { PvpService } from "./system/battle/pvp/PvpService";
import { ChatService } from "./system/chat/ChatService";
import { ChestService } from "./system/chest/ChestService";
import { GmService } from "./system/gm/GmService";
import { HeroService } from "./system/hero/HeroService";
import { LoginService } from "./system/login/LoginService";
import { MailService } from "./system/mail/MailService";
import { NetworkService } from "./system/network/NetworkService";
import { ToastService } from "./system/network/ToastService";
import { RechargeService } from "./system/shop/RechargeService";
import { ShopService } from "./system/shop/ShopService";
import { TableService } from "./system/table/TableService";
import { TaskService } from "./system/task/TaskService";
import { UserService } from "./system/user/UserService";

import "./3rd/3rd";
import { UIAdaptManager } from "./core/ui-adapt-manager";
import { LogLevel } from "./misc/Logger/ILogger";
import { LoggerManager } from "./misc/Logger/Logger";
import { AllianceService } from "./system/alliance/AllianceService";
import { DrawCardService } from "./system/drawCard/DrawCardService";
import { LeaderSkinService } from "./system/leaderskin/LeaderSkinService";
import { PlotTheatreService } from "./system/plot-theatre/PlotTheatreService";
import { SkillService } from "./system/skill/SkillService";
import { TrainCampService } from "./system/traincamp/TrainCampService";
import { TroopService } from "./system/troop/TroopService";

@Laya.regClass('e60XQm7tTY2BwFAdxb8D1g')
export class Main extends AppBase {
    override async onAwake() {
        LoggerManager.Init(LogLevel.All);
        // new BehaviorTest().start();
        //打包解开更换字体
        // Laya.Config.defaultFont = "resources/font/FZZHUNYUAN-M02SB.TTF";

        if (Laya.Browser.onPC) {
            Laya.stage.scaleMode = Laya.Stage.SCALE_SHOWALL;
            Laya.stage.alignH = Laya.Stage.ALIGN_CENTER;
        }
        // logger.log(new ExportNodes().stringify());

        ui.register();
        app.init();
    }
}

class ServiceManager extends Dispatcher {
    readonly alliance: AllianceService;
    readonly bag: BagService;
    readonly chat: ChatService;
    readonly chest: ChestService;
    readonly drawCard: DrawCardService;
    readonly gm: GmService;
    readonly hero: HeroService;
    readonly leaderSkin: LeaderSkinService;
    readonly login: LoginService;
    readonly mail: MailService;
    readonly network: NetworkService;
    readonly pve: PveService;
    readonly pvp: PvpService;
    readonly recharge: RechargeService;
    readonly shop: ShopService;
    readonly skill: SkillService;
    readonly table: TableService;
    readonly task: TaskService;
    readonly toast: ToastService;
    readonly trainCamp: TrainCampService;
    readonly troop: TroopService;
    readonly user: UserService;
    readonly plotTheatre: PlotTheatreService;

    private _services: Service[] = [];

    constructor() {
        super();
        this.network = this._createService(NetworkService);
        this.alliance = this._createService(AllianceService);
        this.bag = this._createService(BagService);
        this.chat = this._createService(ChatService);
        this.chest = this._createService(ChestService);
        this.drawCard = this._createService(DrawCardService);
        this.gm = this._createService(GmService);
        this.hero = this._createService(HeroService);
        this.leaderSkin = this._createService(LeaderSkinService);
        this.login = this._createService(LoginService);
        this.mail = this._createService(MailService);
        this.pve = this._createService(PveService);
        this.pvp = this._createService(PvpService);
        this.recharge = this._createService(RechargeService);
        this.shop = this._createService(ShopService);
        this.skill = this._createService(SkillService);
        this.table = this._createService(TableService);
        this.task = this._createService(TaskService);
        this.toast = this._createService(ToastService);
        this.trainCamp = this._createService(TrainCampService);
        this.troop = this._createService(TroopService);
        this.user = this._createService(UserService);
        this.plotTheatre = this._createService(PlotTheatreService);

        this._services.forEach((service) => service.onCreate());
        // ignore log
        this.network.ignoreLog(opcode.user.c2s_ping);
        this.network.ignoreLog(opcode.user.s2c_ping);
    }

    private _createService<T extends Service>(type: Constructor<T>): T {
        const service = new type(this);
        this._services.push(service);
        return service;
    }

    destroy() {
        this._services.forEach((service) => service.onDestroy());
        this._services.length = 0;
    }

    public servicesStartInitialized(): void {
        this._services.forEach((service) => service.onStartInit());
    }
}

class App extends Dispatcher {
    private static _inited = false;

    private _delta: number = 0;
    private _ui = new UIManager();
    private _loader = new Loader();
    private _uiAdapt = new UIAdaptManager();

    // services
    private _service!: ServiceManager;

    constructor() {
        super();
        const win = window as unknown as { app: App };
        win.app = this;
    }

    /** 帧时间差，秒 */
    get delta() {
        return this._delta;
    }

    get ui() {
        return this._ui;
    }

    get loader() {
        return this._loader;
    }

    get uiAdapt() {
        return this._uiAdapt;
    }

    get service() {
        return this._service;
    }

    async query<T>(name: string, ...args: unknown[]): Promise<T | undefined> {
        if (!app.hasListener(name)) {
            console.warn(`no handler for query: ${name}`);
            return Promise.resolve(undefined);
        }
        return new Promise((resolve) => {
            app.event(name, [resolve, ...args]);
        });
    }

    init() {
        if (App._inited) {
            return;
        }
        App._inited = true;

        // 更新缓动
        let lastTimestame = Laya.Render.getTimestamp();
        Laya.timer.frameLoop(1, this, () => {
            TweenSystem.update(Laya.timer.delta / 1000);

            const timestamp = Laya.Render.getTimestamp();
            this._delta = (timestamp - lastTimestame) / 1000;
            lastTimestame = timestamp;
        });

        this._service = new ServiceManager();

        const manager = Laya.Dialog.manager;
        manager.name = "dialog_root";
        app.uiAdapt.init();
        app.ui.open(ui.LOGIN_SCENE);

        // 放在第一个加载中加载
        app.service.table.load();
    }

    public servicesStartInitialized(): void {
        this.service.servicesStartInitialized();
    }
}

export const app = new App();
