const { regClass } = Laya;
import { ImageRef } from "../../../../misc/ImageRef";
import { CharacterRebuildItemBase } from "./CharacterRebuildItem.generated";

export interface CharacterRebuildItemData {
    quality: number;
    iconSkin: string;
    count: number;
}

@regClass('Vm_wYRZdQ4-daurdvdPa_g')
export class CharacterRebuildItem extends CharacterRebuildItemBase {
    onRender(data: CharacterRebuildItemData, index: number): void {
        this.lblCount.text = `${data.count}`;
        const texturepaths = this.imgQuality.getComponent(ImageRef)!.texturePaths;
        const qualitySkin = texturepaths[data.quality];
        this.imgQuality.skin = qualitySkin;
    }
}
