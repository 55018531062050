import * as ecs from "../../../../../core/ecs";
import { P1v1Context } from "../../P1v1Context";
import { P1v1MovementComponent } from "../components/P1v1MovementComponent";
import { P1v1TransformComponent } from "../components/P1v1TransformComponent";

const tmpVector3 = new Laya.Vector3();

export class P1v1MovementSystem extends ecs.System {
    declare context: P1v1Context;

    override update(dt: number): void {
        this.ecs.getComponents(P1v1MovementComponent).forEach((movement) => {
            if (movement.speed <= 0) {
                return;
            }

            const transform = movement.getComponent(P1v1TransformComponent)!;
            const position = transform.position;
            const target = movement.target;
            const totalDis = Laya.Vector3.distance(position, target);
            const dtDis = movement.speed * dt;
            let dx: number = 0;
            let dz: number = 0;
            if (dtDis < totalDis) {
                const steplen = dtDis / totalDis;
                dx = (target.x - position.x) * steplen;
                dz = (target.z - position.z) * steplen;
            } else {
                dx = target.x - position.x;
                dz = target.z - position.z;
                movement.speed = 0;
            }
            position.x += dx;
            position.z += dz;
            transform.flags |= P1v1TransformComponent.POSITION;
        });
    }
}
