const { regClass } = Laya;
import { UILeaderSkinBase } from "./UILeaderSkin.generated";

@regClass('T5go0jjkQs2Ux7PoddSvbQ')
export class UILeaderSkin extends UILeaderSkinBase {
    public heroUid: number = 0;

    override open(closeOther?: boolean, param?: any): void {
        this.heroUid = param as number;
        super.open(closeOther, param);
    }
}
