/**This class is automatically generated by LayaAirIDE, please do not make any modifications. */

import { P1v1TroopInfoUI } from "./p1v1/P1v1TroopInfoUI";

/**
 * scenes/pvp-nvn.ls
 */
export class P1v1UIBase extends Laya.Scene {
    public battle!: Laya.Box;
    public debug!: Laya.Sprite;
    public troops!: Laya.Box;
    public labels!: Laya.Box;
    public btnSwitch!: Laya.Image;
    public btnSearch!: Laya.Image;
    public info!: Laya.Box;
    public enemy!: P1v1TroopInfoUI;
    public my!: P1v1TroopInfoUI;
}
